import { useState, useEffect, useContext } from "react";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ToolContext } from "../context/ToolContext";
import { useMsal } from "@azure/msal-react";

const SavedStudiesV2 = ({
  userNameInput,
  setUserNameInput,
  area,
  group,
  loadingPDF,
  fileName,
  studyFilters,
  setFileName,
  setArea,
  setGroup,
  saveFile,
  exportNetwork,
  exportPDF,
  saveNetworkEnabled,
  isSaving,
}) => {
  const SAVEBUTTONID = "btnSaveDatabase";
  const JSONBUTTONID = "btnSaveJson";
  const PDFBUTTONID = "btnSavePdf";

  const [, setShowUnsaved] = useState(false);
  const { accounts } = useMsal();
  const { toolState, setToolState } = useContext(ToolContext);
  const [, setSaveState] = useState(false);
  const [, setJsonState] = useState(false);
  const [, setPdfState] = useState(false);

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const saveFileClicked = () => {
    saveFile();
  };

  const selectGroup = (e) => {
    var location = e.target.value;
    setGroup(location);

    const areas = getAreasForGroup(location);

    if (areas.length === 1) {
      setArea(areas[0].id);
    } else {
      setArea("");
    }
  };

  const selectArea = (e) => {
    var location = e.target.value;
    setArea(location);
  };

  const unSavedChange = (e) => {
    if (e.target.value === "" && area === "") {
      setShowUnsaved(false);
    } else {
      setShowUnsaved(true);
    }
    if (e.target.id === "inputFilename") {
      setFileName(e.target.value);
    }
  };

  useEffect(() => {
    if (accounts.length > 0) {
      setUserNameInput(accounts[0].name);
    }
  }, [accounts]);

  const validateInput = (e) => {
    switch (e.target.id) {
      case SAVEBUTTONID:
        setSaveState(!fileName || !area || !userNameInput ? false : true);
        break;
      case JSONBUTTONID:
        setJsonState(!fileName || !userNameInput ? false : true);
        break;
      case PDFBUTTONID:
        setPdfState(!fileName || !userNameInput ? false : true);
        break;
      default:
        break;
    }
  };

  const canSave = () => {
    if (fileName && group !== "" && area !== "") return true;
  };

  const validateSave = () => {
    if (saveNetworkEnabled) {
      saveFileClicked();
    } else {
      setToolStateProp("alert", [
        {
          description:
            "Central load/save/delete disabled, please use import/export",
        },
      ]);
    }
  };

  const getAreasForGroup = (selectedGroup) => {
    return studyFilters.filters.Areas.filter(
      (area) => area.parentId === selectedGroup
    ).sort((area) => area.sortOrder);
  };

  const getFilteredAreas = () => {
    if (
      !(
        studyFilters &&
        studyFilters.filters &&
        studyFilters.filters.Areas &&
        studyFilters.filters.Areas.length > 0
      ) ||
      isAreasDisabled()
    ) {
      return [];
    }

    if (hasSingleGroup()) {
      return studyFilters.filters.Areas.sort((area) => area.sortOrder);
    }

    return getAreasForGroup(group);
  };

  const isAreasDisabled = () => {
    return group === "" && !hasSingleGroup();
  };

  const hasGroups = () =>
    studyFilters &&
    studyFilters.filters &&
    studyFilters.filters.Groups &&
    studyFilters.filters.Groups.length > 0;
  const hasAreas = () =>
    studyFilters &&
    studyFilters.filters &&
    studyFilters.filters.Areas &&
    studyFilters.filters.Areas.length > 0;
  const hasSingleGroup = () =>
    hasGroups() && studyFilters.filters.Groups.length === 1;
  const hasSingleArea = () => hasAreas() && getFilteredAreas().length === 1;

  const GroupsDropdown = () => {
    if (hasGroups()) {
      return (
        <Input
          type="select"
          name="inputGroup"
          id="inputGroup"
          style={{ fontSize: 13 }}
          onChange={(e) => selectGroup(e)}
          value={group}
        >
          <option key="" value="">
            Select Folder
          </option>
          {studyFilters.filters.Groups.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      );
    }
    return (
      <div className="text-muted mb-2 ml-1" style={{ fontSize: 14 }}>
        No existing folders
      </div>
    );
  };

  const AreasDropdown = () => {
    const areas = getFilteredAreas();
    if (hasGroups() && areas.length > 0) {
      return (
        <Input
          type="select"
          name="inputArea"
          id="inputArea"
          style={{ fontSize: 13 }}
          onChange={(e) => selectArea(e)}
          value={area}
          disabled={isAreasDisabled()}
        >
          <option key="" value="">
            Select Area
          </option>
          {areas.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      );
    }
  };

  return (
    <>
      <div className="text-white">
        <div className="container mt-3 mb-3">
          {!hasSingleGroup() && (
            <div className="row mt-2">
              <div className="col">{GroupsDropdown()}</div>
            </div>
          )}
          {!hasSingleArea() && (
            <div className={`row ${!hasSingleGroup() ? "mt-2" : ""}`}>
              <div className="col">{AreasDropdown()}</div>
            </div>
          )}
          <div
            className={`row ${
              !hasSingleGroup() || !hasSingleArea() ? "mt-2" : ""
            }`}
          >
            <div className="col d-flex">
              <Input
                style={{ width: 200 }}
                type="text"
                autoComplete="off"
                id="inputFilename"
                placeholder="Untitled Study"
                bsSize="sm"
                value={fileName}
                onChange={(e) => unSavedChange(e)}
              />
              <div id="btnSaveDatabaseDiv" className="ml-2">
                <button
                  type="button"
                  id="btnSaveDatabase"
                  style={{
                    cursor: fileName && area !== "" ? "pointer" : "default",
                    width: 50,
                  }}
                  disabled={!fileName || area === ""}
                  className={"align-items-center btn btn-sm btn-primary"}
                  onMouseEnter={(e) => {
                    validateInput(e);
                  }}
                  onMouseLeave={() => setSaveState(false)}
                  onClick={() => {
                    if (canSave() && !isSaving) {
                      validateSave();
                    }
                  }}
                >
                  {isSaving ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div id="btnSaveJsonDiv">
                <button
                  type="button"
                  id="btnSaveJson"
                  style={{
                    cursor: fileName ? "pointer" : "default",
                    width: 50,
                  }}
                  disabled={!fileName}
                  className="align-items-center btn btn-primary btn-sm ml-2"
                  onMouseEnter={(e) => {
                    validateInput(e);
                  }}
                  onMouseLeave={() => setJsonState(false)}
                  onClick={() => {
                    if (fileName) {
                      exportNetwork();
                    }
                  }}
                >
                  PC
                </button>
              </div>
              <div id="btnSavePdfDiv">
                <button
                  type="button"
                  id="btnSavePdf"
                  style={{
                    cursor: fileName ? "pointer" : "default",
                    width: 50,
                  }}
                  disabled={!fileName}
                  className="align-items-center btn btn-primary btn-sm ml-2"
                  onMouseEnter={(e) => {
                    validateInput(e);
                  }}
                  onMouseLeave={() => setPdfState(false)}
                  onClick={() => {
                    if (fileName && !loadingPDF) {
                      exportPDF();
                    }
                  }}
                >
                  {loadingPDF ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Pdf"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedStudiesV2;
