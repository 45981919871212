import { GT_OSGB } from "./geoTools2";

export const gridRefToSetView = (map, searchValue) => {
  const osgb = new GT_OSGB();
  var prefixExpression =
    "^((([sS]|[nN])[a-hA-Hj-zJ-Z])|(([tT]|[oO])[abfglmqrvwABFGLMQRVW])|([hH][l-zL-Z])|([jJ][lmqrvwLMQRVW]))$";
  var gridRef = searchValue.replace(/ |,/g, "");
  if (gridRef.substring(0, 2).match(prefixExpression)) {
    osgb.parseGridRef(gridRef.toUpperCase());
  } else {
    osgb.setGridCoordinates(
      gridRef.substring(0, gridRef.length / 2),
      gridRef.substring(gridRef.length / 2)
    );
  }

  const wgs84 = osgb.getWGS84();
  map.setView([wgs84.latitude, wgs84.longitude]);
};