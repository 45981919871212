export const checkForDefaults = (allAssets) => {
  let defaults = false;
  allAssets.forEach(({ asset }) => {
    Object.entries(asset).forEach(([key, value]) => {
      if (key.endsWith("IsDefault") && value) {
        defaults = true;
      }
    });

    if (asset.styles.type === "groupedConnection") {
      asset.groupedConnectionPoints.forEach((gcp) => {
        Object.entries(gcp).forEach(([key, value]) => {
          if (key.endsWith("IsDefault") && value) {
            defaults = true;
          }
        });
      });
    }
  });
  return defaults;
};
