import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import * as layout from "../constants/layout";
import { store } from "../app/store";
import { useSelector } from "react-redux";
import { resetNetworkToolState } from "../utils/networkFunctions";
import { undoAction, redoAction } from "../app/undoable"

const IconDropDrownMenu = ({
  menuItems,
  selectedItem,
  activeCategory,
  selectItem,
}) => {
  const { formState } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clientSettings } = formState;
  const [dropdownOpen, setOpen] = useState([
    ...menuItems.map((category) => false),
  ]);
  const [navbarHeight, setNavbarHeight] = useState(layout.NAVBAR_HEIGHT);

  const past = useSelector((state) => state.network.past);
  const future = useSelector((state) => state.network.future);

  const handleWindowSizeChange = () => {
    const navbar = document.getElementById("ea_navbar");
    setNavbarHeight(navbar.clientHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const dropdownToggle = (categoryIndex) => {
    let _dropdownOpen = [...dropdownOpen];
    _dropdownOpen[categoryIndex] = !dropdownOpen[categoryIndex];
    setOpen(_dropdownOpen);
  };

  const undo = () => {
    const _toolState = toolState;
    resetNetworkToolState(_toolState);
    setToolState(_toolState);
    store.dispatch(undoAction());
  }
  const redo = () => {
    const _toolState = toolState;
    resetNetworkToolState(_toolState);
    setToolState(_toolState);
    store.dispatch(redoAction());
  }

  return (
    <div
      className="mb-2"
      style={{ marginTop: navbarHeight - layout.NAVBAR_HEIGHT }}
    >
      <ButtonGroup vertical>
        {menuItems.map((category, categoryIndex) => (
          <ButtonDropdown
            key={categoryIndex}
            isOpen={dropdownOpen[categoryIndex]}
            toggle={() => dropdownToggle(categoryIndex)}
            direction="right"
          >
            <Button
              id={`icon${category.items[selectedItem[categoryIndex]].id}`}
              active={activeCategory === categoryIndex}
              color="dark"
              onClick={() => selectItem(categoryIndex)}
            >
              <i
                className={`icon-${category.items[selectedItem[categoryIndex]].class
                  }${category.items[selectedItem[categoryIndex]].class === "node"
                    ? "-tool"
                    : ""
                  }`}
              ></i>
            </Button>
            <DropdownToggle
              split
              color="dark"
              id={`toggle${category.items[selectedItem[categoryIndex]].type}`}
            />
            <DropdownMenu color="dark">
              {category.items
                .filter(
                  (p) =>
                    !p.requiredFeature ||
                    clientSettings.Features[p.requiredFeature]
                )
                .map((item, itemIndex) => (
                  <DropdownItem
                    id={`item${item.id}`}
                    key={itemIndex}
                    tag="a"
                    onClick={() => selectItem(categoryIndex, item, itemIndex)}
                  >
                    <i
                      className={`icon-${item.class}${item.class === "node" ? "-tool" : ""
                        }`}
                    ></i>{" "}
                    {item.name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </ButtonDropdown>
        ))}
        {clientSettings.Features.HistoryModeEnabled && <ButtonDropdown className="horizontal-buttons">
          <Button
            id="undo-button"
            color="dark"
            title="Undo"
            disabled={past.length === 0}
            onClick={undo}
          ><i
            className={"icon-undo-solid"}
          ></i></Button>
          <Button
            id="redo-button"
            color="dark"
            title="Redo"
            disabled={future.length === 0}
            onClick={redo}
          ><i
            className={"icon-redo-solid"}
          ></i></Button>
        </ButtonDropdown>
        }
      </ButtonGroup>
    </div>
  );
};

export default IconDropDrownMenu;
