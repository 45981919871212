// @flow

import ConductingEquipment from "../cim/conductingEquipment";
import { createRagCableFromConductingEquipment } from "./ragCableFactory";
import {
  createRagTransformerFromConductingEquipment,
  createRagTransformerFromSubstation,
} from "./ragTransformerFactory";
import { ACLineSegmentType, PowerTransformerType } from "../cim/cimFactory";

export function createRagNetworkFromResponse(response: any) {
  const cableCimObjects = response.network.conductingEquipment.filter(
    (ce) => ce.type === ACLineSegmentType
  );
  const transformerCimObjects = response.network.conductingEquipment.filter(
    (ce) => ce.type === PowerTransformerType
  );

  const cables = cableCimObjects.map((o) =>
    createRagCableFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties)
    )
  );

  const transformers = transformerCimObjects.map((o) =>
    createRagTransformerFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties)
    )
  );

  return {
    transformers: transformers,
    cables: cables,
  };
}

export function createRagNetworkFromSubstation(substation: any) {
  const transformer = createRagTransformerFromSubstation(substation);
  return {
    transformers: [transformer],
    cables: [],
  };
}
