import settingsIcon from "../images/settings.svg";
import CheckboxBtn from "./CheckboxBtn";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  NavbarText,
} from "reactstrap";

const Settings = () => {
  return (
    <div className="d-flex align-items-center mt-1">
      <UncontrolledDropdown>
        <NavbarText className="py-0">
          <DropdownToggle
            id="settingsToggle"
            tag="span"
            className="nav-link py-0"
          >
            <div className="d-flex dark align-items-center navbar-icon">
              <img
                src={settingsIcon}
                alt=""
                style={{
                  height: 15,
                  width: 15,
                }}
              />
            </div>
          </DropdownToggle>
        </NavbarText>
        <DropdownMenu
          className="mt-3"
          right
          style={{
            background: "black",
            padding: 10,
            border: "gray 1px solid",
            width: 360,
            borderRadius: 10,
            fontSize: 12,
          }}
        >
          <CheckboxBtn />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
export default Settings;
