import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./../App.css";
import { Button, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { v4 as uuid } from "uuid";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import * as statusConstants from "../constants/status";
import * as linkBoxConstants from "../constants/linkBox";
import { listConnectedCables } from "../utils/linkBoxFunctions";
import { updateGroupedConnection } from "../app/networkSlice";

const LinkBoxSelector = () => {
  const { toolState } = useContext(ToolContext);
  const { formState, dispatch } = useContext(FormContext);
  const { groupedConnectionProperties, clientSettings } = formState;
  const { linkBox } = groupedConnectionProperties;
  const { clickedAsset } = toolState;

  const cables = useSelector((state) => state.network.present.cables);

  const dispatchRedux = useDispatch();

  const selectList = [
    { name: linkBoxConstants.TWOWAY },
    { name: linkBoxConstants.FOURWAY },
  ];

  const cablesClosed = () => linkBox?.connectivity?.closed ?? [];

  const updateNumberOfWays = (e) => {
    const value = e.target.value;

    let connectivity = {};

    if (clientSettings.Features.ConfigurableLinkBoxEnabled) {
      connectivity = {
        closed: [],
      };
    }

    const newValue = {
      ...linkBox,
      numberOfWays: value,
      twoWayConnectivityIsClosed: false,
      connectivity: connectivity,
    };
    updateNode(newValue);
  };

  const updateStatus = (e) => {
    const value = e.target.value;

    const newValue = { ...linkBox, status: value };
    updateNode(newValue);
  };

  const toggleCableConnectivity = (cableId) => {
    if (cableId === undefined) {
      return;
    }

    let connectivity = { ...linkBox.connectivity };

    if (!connectivity) {
      connectivity = {
        closed: [],
      };
    }

    connectivity.closed ??= [];

    const isClosed = cablesClosed().includes(cableId);

    if (isClosed) {
      connectivity.closed = connectivity.closed.filter((c) => c !== cableId);
    } else {
      if (!connectivity.closed) {
        connectivity.closed = [];
      }
      connectivity.closed = [...connectivity.closed, cableId];
    }

    const newValue = { ...linkBox, connectivity: connectivity };
    updateNode(newValue);
  };

  const updateConnectivity = (e) => {
    if (!clientSettings.Features.ConfigurableLinkBoxEnabled) {
      return;
    }

    const isClosed = e.target.value === linkBoxConstants.CLOSED;

    const newValue = { ...linkBox, twoWayConnectivityIsClosed: isClosed };
    updateNode(newValue);
  };

  const addOrRemoveLinkedBox = (e) => {
    e.preventDefault();
    if (linkBox) {
      updateNode(null);
      return;
    }

    const formData = new FormData(e.target);
    const newValue = {
      id: uuid(),
      numberOfWays: formData.get("numberOfWays"),
      status: statusConstants.NEW,
    };

    updateNode(newValue);
  };

  const updateNode = (value) => {
    dispatch({
      form: "groupedConnectionProperties",
      field: "linkBox",
      value: value,
      type: "UPDATE_FIELD",
    });
    if (clickedAsset) {
      dispatchRedux(
        updateGroupedConnection({
          id: clickedAsset.id,
          name: "linkBox",
          value,
        }),
      );
    }
  };

  const StatusBox = ({ cableId, title, left }) => {
    const isClosed = cablesClosed().includes(cableId);

    const borderClass =
      cableId !== undefined
        ? isClosed
          ? "border-success"
          : "border-danger"
        : "";

    return (
      <button
        onClick={() => {
          toggleCableConnectivity(cableId);
        }}
        disabled={cableId === undefined}
        style={{ alignSelf: left ? "flex-end" : "flex-start" }}
        className={`d-flex align-items-center my-2 linkbox-connectivity-button ${
          left ? "flex-row" : "flex-row-reverse"
        }`}
        title={title}
      >
        <div
          className={`bg-dark p-2 text-light border ${borderClass} linkbox-status-title`}
        >
          {title}
        </div>
        {isClosed ? (
          <div className="bg-dark linkbox-correct" />
        ) : (
          <>
            <div className="bg-dark linkbox-start" />
            <div
              className="bg-dark linkbox-open-line"
              style={{
                transformOrigin: left ? "left" : "right",
              }}
            />
            <div className="bg-dark linkbox-end" />
          </>
        )}
      </button>
    );
  };

  return (
    <div
      className="bg-white-alpha"
      style={{
        padding: ".75rem",
        margin: ".75rem 0",
        marginBottom: "1rem",
      }}
    >
      <h6
        className="text-light"
        style={{
          fontSize: ".75rem",
          textTransform: "uppercase",
          fontWeight: 200,
        }}
      >
        Add Link Box
      </h6>
      <form onSubmit={addOrRemoveLinkedBox}>
        <div className="text-light d-flex">
          <Input
            type="select"
            name="numberOfWays"
            className="w-100"
            value={linkBox?.numberOfWays}
            onChange={updateNumberOfWays}
          >
            {selectList.map((item) => (
              <option key={item.name} value={item.name}>
                {item.name}
              </option>
            ))}
          </Input>

          <Button
            size="sm"
            color={linkBox ? "danger" : "success"}
            className="ml-2"
          >
            <FontAwesomeIcon icon={linkBox ? faMinus : faPlus} />
          </Button>
        </div>
        <div>
          {linkBox && (
            <>
              <div className="pt-2">
                <Label for="status">New/Existing</Label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={linkBox?.status}
                  onChange={updateStatus}
                >
                  <option value={statusConstants.NEW}>New</option>
                  <option value={statusConstants.EXISTING}>Existing</option>
                  <option value={statusConstants.REPLACEMENT}>
                    Replacement
                  </option>
                </Input>
              </div>
              {clientSettings.Features.ConfigurableLinkBoxEnabled &&
                linkBox.numberOfWays === linkBoxConstants.TWOWAY && (
                  <div className="pt-2">
                    <Label for="connectivity">Connectivity</Label>
                    <Input
                      type="select"
                      name="connectivity"
                      id="connectivity"
                      value={
                        linkBox?.twoWayConnectivityIsClosed === true
                          ? linkBoxConstants.CLOSED
                          : linkBoxConstants.OPEN
                      }
                      onChange={updateConnectivity}
                    >
                      <option value={linkBoxConstants.OPEN}>Open</option>
                      <option value={linkBoxConstants.CLOSED}>Closed</option>
                    </Input>
                  </div>
                )}

              {clientSettings.Features.ConfigurableLinkBoxEnabled &&
                linkBox.numberOfWays === linkBoxConstants.FOURWAY && (
                  <>
                    <Label className="pt-2">Connectivity</Label>

                    <div className="d-flex justify-content-center align-items-center flex-row">
                      <div className="d-flex flex-column">
                        {listConnectedCables(cables, clickedAsset)
                          .slice(0, 2)
                          .map(({ cableId, title }, index) => (
                            <StatusBox
                              left={true}
                              cableId={cableId}
                              title={title}
                              key={`connectivity_${index}`}
                            />
                          ))}
                      </div>
                      <div
                        className="bg-dark"
                        style={{
                          width: 2,
                          height: 160,
                        }}
                      />
                      <div className="d-flex flex-column">
                        {listConnectedCables(cables, clickedAsset)
                          .slice(2, 4)
                          .map(({ cableId, title }, index) => (
                            <StatusBox
                              left={false}
                              cableId={cableId}
                              title={title}
                              key={`connectivity_${index + 2}`}
                            />
                          ))}
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default LinkBoxSelector;
