import getAssetStyleInfo from "../context/AssetStyleInfo";
import {
  isWelder,
  isTransformer,
  isMotor,
  isNode,
  isCable,
} from "./networkFunctions";
import { GroupedConnection } from "../model/viewModel/groupedConnection";
import { addGroupedConnection, deleteGroupedConnection, updateCable, updateGroupedConnection } from "../app/networkSlice";

export const refineRingfencedAssets = (cables, ringfenced) => {
  ringfenced
    .filter(
      (node) =>
        isNode(node) || isWelder(node) || isTransformer(node) || isMotor(node)
    )
    .forEach((node) => {
      if (!allCablesConnectedToNodeAreRingfenced(cables, ringfenced, node)) {
        const index = ringfenced.indexOf(node);
        if (index > -1) ringfenced.splice(index, 1);
      }
    });

  return ringfenced;
};

const allCablesConnectedToNodeAreRingfenced = (cables, ringfenced, node) => {
  const networkCables = cables.filter(
    (c) => c.startAssetId === node.id || c.endAssetId === node.id
  );

  const ringfencedCables = ringfenced.filter(
    (c) =>
      isCable(c) && (c.startAssetId === node.id || c.endAssetId === node.id)
  );

  return networkCables?.length === ringfencedCables?.length;
};

export const removeGroupedConnection = (cables, ringfenced, asset, selected, groupedConnections, dispatchRedux) => {
  const index = groupedConnections.findIndex(
    (x) => x.id === asset.id
  );

  if (index > -1) {
    const isConnected = cables.some(
      (c) => c.startAssetId === asset.id || c.endAssetId === asset.id
    );

    let canRemoveNode = false;

    if (selected === "All") {
      canRemoveNode = allCablesConnectedToNodeAreRingfenced(
        cables,
        ringfenced,
        asset
      );
    }

    let groupedConnection = groupedConnections[index];

    if (groupedConnection.styles.class === "connection") {
      groupedConnection.groupedConnectionPoints.forEach((gcp) => {
        cables
          .filter((c) => c.startAssetId === gcp.id)
          .forEach((c) => dispatchRedux(updateCable({ id: c.id, name: "startAssetId", value: gcp.parentId })));
      });
    }

    dispatchRedux(updateGroupedConnection({ id: groupedConnection.id, name: "styles", value: getAssetStyleInfo("node") }));
    dispatchRedux(updateGroupedConnection({ id: groupedConnection.id, name: "groupedConnectionPoints", value: [] }));

    if (!isConnected || canRemoveNode) {
      dispatchRedux(deleteGroupedConnection(groupedConnection.id));
    }
  }
};

export const removePointOfConnection = (reference, cables, asset, dispatchRedux) => {
  const isConnected = cables.some(
      (c) => c.startAssetId === asset.id || c.endAssetId === asset.id
    );

    if (isConnected) {
      const groupedConnection = createGroupedConnection(reference, asset);
      dispatchRedux(addGroupedConnection(groupedConnection));
    }
};

const createGroupedConnection = (reference, asset) => {
  const groupedConnection = new GroupedConnection(asset.id, "", [], "", reference.groundTypeOverrideDefaults.groundTypeOverride);
  groupedConnection.geometry = asset.geometry;
  groupedConnection.status = "Existing";
  groupedConnection.styles = getAssetStyleInfo("node");
  groupedConnection.groupedConnectionPoints = [];
  return groupedConnection;
};
