import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { FormContext } from "../context/FormContext";

const CrownNavbar = () => {
  const { formState } = useContext(FormContext);
  const { crown } = formState;

  return (
    <div
      className="d-flex justify-content-start ml-4 navbar-nav text-white"
      style={{ fontSize: 15 }}
    >
      <Container>
        <Row>
          <Col>
            <div>
              <span className="navbar-header">Enquiry:</span> {crown.enquiryRef}
            </div>
          </Col>
          <Col>
            <div>
              <span className="navbar-header">Scheme:</span> {crown.schemeId}
            </div>
          </Col>
          <Col>
            <div>
              <span className="navbar-header">Version:</span>{" "}
              {crown.schemeVersion}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CrownNavbar;
