import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";
import { IMotorProperties, MotorProperties } from "./motorProperties";

export class Motor extends Asset implements IMotorProperties {
  constructor(id: string, motorProperties: MotorProperties) {
    super(id);
    this.rating = motorProperties.rating;
    this.annotation = motorProperties.annotation;
    this.ratingIsDefault = motorProperties.ratingIsDefault;
    this.ratingUnit = motorProperties.ratingUnit;
    this.numberOfPhases = motorProperties.numberOfPhases;
    this.numberOfPhasesIsDefault = motorProperties.numberOfPhasesIsDefault;
    this.starter = motorProperties.starter;
    this.starterIsDefault = motorProperties.starterIsDefault;
    this.startingPowerFactor = motorProperties.startingPowerFactor;
    this.startingPowerFactorIsDefault =
      motorProperties.startingPowerFactorIsDefault;
    this.startingCurrentOverride = motorProperties.startingCurrentOverride;
    this.numberOfStarts = motorProperties.numberOfStarts;
    this.numberOfStartsIsDefault = motorProperties.numberOfStartsIsDefault;
    this.numberOfStartsUnit = motorProperties.numberOfStartsUnit;
    this.efficiency = motorProperties.efficiency;
    this.efficiencyIsDefault = motorProperties.efficiencyIsDefault;
    this.hvFaultLevel = motorProperties.hvFaultLevel;
    this.xrRatio = motorProperties.xrRatio;
    this.geometry = motorProperties.geometry;
    this.styles = motorProperties.styles;
    this.maxVoltDropPercent = motorProperties.maxVoltDropPercent;
    this.maxVoltDropPercentPassResult =
      motorProperties.maxVoltDropPercentPassResult;
    this.maxVoltRisePercent = motorProperties.maxVoltRisePercent;
    this.maxVoltRiseTotal = motorProperties.maxVoltRiseTotal;
    this.maxVoltRiseGeneration = motorProperties.maxVoltRiseGeneration;
    this.maxVoltRiseUnbalance = motorProperties.maxVoltRiseUnbalance;
    this.earthLoopImpedance = motorProperties.earthLoopImpedance;
    this.threePhaseFaultCurrent = motorProperties.threePhaseFaultCurrent;
    this.phaseToPhaseFaultCurrent = motorProperties.phaseToPhaseFaultCurrent;
    this.singlePhaseToEarthFaultCurrent =
      motorProperties.singlePhaseToEarthFaultCurrent;
    this.flickerPassResult = motorProperties.flickerPassResult;
    this.flickerDeltaVoltage = motorProperties.flickerDeltaVoltage;
    this.flickerP28Limit = motorProperties.flickerP28Limit;
    this.flickerStartingPassResult = motorProperties.flickerStartingPassResult;
    this.flickerStartingDeltaVoltage =
      motorProperties.flickerStartingDeltaVoltage;
    this.impedanceLoopOperating = motorProperties.impedanceLoopOperating;
    this.impedanceLoopOperatingPassResult =
      motorProperties.impedanceLoopOperatingPassResult;
    this.impedanceSourceLoopOperating =
      motorProperties.impedanceSourceLoopOperating;
    this.impedanceSourceLoopOperatingPassResult =
      motorProperties.impedanceSourceLoopOperatingPassResult;
    this.impedanceLoopFault = motorProperties.impedanceLoopFault;
    this.impedancePhaseOperating = motorProperties.impedancePhaseOperating;
    this.impedanceSourcePhaseOperating =
      motorProperties.impedanceSourcePhaseOperating;
    this.impedanceSourcePhasePhaseOperating =
      motorProperties.impedanceSourcePhasePhaseOperating;
    this.impedancePhaseFault = motorProperties.impedancePhaseFault;
    this.resistanceLoopOperating = motorProperties.resistanceLoopOperating;
    this.resistanceSourceLoopOperating =
      motorProperties.resistanceSourceLoopOperating;
    this.resistanceLoopFault = motorProperties.resistanceLoopFault;
    this.resistancePhaseOperating = motorProperties.resistancePhaseOperating;
    this.resistanceSourcePhaseOperating =
      motorProperties.resistanceSourcePhaseOperating;
    this.resistanceSourcePhasePhaseOperating =
      motorProperties.resistanceSourcePhasePhaseOperating;
    this.resistancePhaseFault = motorProperties.resistancePhaseFault;
    this.reactanceLoopOperating = motorProperties.reactanceLoopOperating;
    this.reactanceSourceLoopOperating =
      motorProperties.reactanceSourceLoopOperating;
    this.reactanceLoopFault = motorProperties.reactanceLoopFault;
    this.reactancePhaseOperating = motorProperties.reactancePhaseOperating;
    this.reactanceSourcePhaseOperating =
      motorProperties.reactanceSourcePhaseOperating;
    this.reactanceSourcePhasePhaseOperating =
      motorProperties.reactanceSourcePhasePhaseOperating;
    this.reactancePhaseFault = motorProperties.reactancePhaseFault;
    this.AllmaxVoltDropPercent = motorProperties.AllmaxVoltDropPercent;
    this.AllmaxVoltDropPercentPeriod =
      motorProperties.AllmaxVoltDropPercentPeriod;
    this.AllmaxVoltDropPercentPassResult =
      motorProperties.AllmaxVoltDropPercentPassResult;
    this.AllmaxVoltDropPhase = motorProperties.AllmaxVoltDropPhase;
    this.AllmaxVoltRisePercent = motorProperties.AllmaxVoltRisePercent;
    this.AllmaxVoltRiseTotal = motorProperties.AllmaxVoltRiseTotal;
    this.AllmaxVoltRiseGeneration = motorProperties.AllmaxVoltRiseGeneration;
    this.AllmaxVoltRiseUnbalance = motorProperties.AllmaxVoltRiseUnbalance;
    this.AllmaxVoltRisePercentPeriod =
      motorProperties.AllmaxVoltRisePercentPeriod;
    this.AllmaxVoltRisePhase = motorProperties.AllmaxVoltRisePhase;
    this.AllmaxVoltage = motorProperties.AllmaxVoltage;
    this.AllmaxVoltagePhase1 = motorProperties.AllmaxVoltagePhase1;
    this.AllmaxVoltagePhase2 = motorProperties.AllmaxVoltagePhase2;
    this.AllmaxVoltagePhase3 = motorProperties.AllmaxVoltagePhase3;
    this.AllminVoltage = motorProperties.AllminVoltage;
    this.AllminVoltagePhase1 = motorProperties.AllminVoltagePhase1;
    this.AllminVoltagePhase2 = motorProperties.AllminVoltagePhase2;
    this.AllminVoltagePhase3 = motorProperties.AllminVoltagePhase3;
    this.wintermaxVoltDropPercent = motorProperties.wintermaxVoltDropPercent;
    this.wintermaxVoltDropPercentPeriod =
      motorProperties.wintermaxVoltDropPercentPeriod;
    this.wintermaxVoltDropPercentPassResult =
      motorProperties.wintermaxVoltDropPercentPassResult;
    this.wintermaxVoltDropPhase = motorProperties.wintermaxVoltDropPhase;
    this.wintermaxVoltRisePercent = motorProperties.wintermaxVoltRisePercent;
    this.wintermaxVoltRiseTotal = motorProperties.wintermaxVoltRiseTotal;
    this.wintermaxVoltRiseGeneration =
      motorProperties.wintermaxVoltRiseGeneration;
    this.wintermaxVoltRiseUnbalance =
      motorProperties.wintermaxVoltRiseUnbalance;
    this.wintermaxVoltRisePercentPeriod =
      motorProperties.wintermaxVoltRisePercentPeriod;
    this.wintermaxVoltRisePhase = motorProperties.wintermaxVoltRisePhase;
    this.wintermaxVoltage = motorProperties.wintermaxVoltage;
    this.wintermaxVoltagePhase1 = motorProperties.wintermaxVoltagePhase1;
    this.wintermaxVoltagePhase2 = motorProperties.wintermaxVoltagePhase2;
    this.wintermaxVoltagePhase3 = motorProperties.wintermaxVoltagePhase3;
    this.winterminVoltage = motorProperties.winterminVoltage;
    this.winterminVoltagePhase1 = motorProperties.winterminVoltagePhase1;
    this.winterminVoltagePhase2 = motorProperties.winterminVoltagePhase2;
    this.winterminVoltagePhase3 = motorProperties.winterminVoltagePhase3;
    this.summermaxVoltDropPercent = motorProperties.summermaxVoltDropPercent;
    this.summermaxVoltDropPercentPeriod =
      motorProperties.summermaxVoltDropPercentPeriod;
    this.summermaxVoltDropPercentPassResult =
      motorProperties.summermaxVoltDropPercentPassResult;
    this.summermaxVoltDropPhase = motorProperties.summermaxVoltDropPhase;
    this.summermaxVoltRisePercent = motorProperties.summermaxVoltRisePercent;
    this.summermaxVoltRiseTotal = motorProperties.summermaxVoltRiseTotal;
    this.summermaxVoltRiseGeneration =
      motorProperties.summermaxVoltRiseGeneration;
    this.summermaxVoltRiseUnbalance =
      motorProperties.summermaxVoltRiseUnbalance;
    this.summermaxVoltRisePercentPeriod =
      motorProperties.summermaxVoltRisePercentPeriod;
    this.summermaxVoltRisePhase = motorProperties.summermaxVoltRisePhase;
    this.summermaxVoltage = motorProperties.summermaxVoltage;
    this.summermaxVoltagePhase1 = motorProperties.summermaxVoltagePhase1;
    this.summermaxVoltagePhase2 = motorProperties.summermaxVoltagePhase2;
    this.summermaxVoltagePhase3 = motorProperties.summermaxVoltagePhase3;
    this.summerminVoltage = motorProperties.summerminVoltage;
    this.summerminVoltagePhase1 = motorProperties.summerminVoltagePhase1;
    this.summerminVoltagePhase2 = motorProperties.summerminVoltagePhase2;
    this.summerminVoltagePhase3 = motorProperties.summerminVoltagePhase3;
    this.phaseImpedance = motorProperties.phaseImpedance;
    this.loopImpedance = motorProperties.loopImpedance;
    this.phaseAllocation = motorProperties.phaseAllocation;
    this.nodeNumber = motorProperties.nodeNumber;
    this.objectId = motorProperties.objectId;
  }

  [key: string]: any;
  rating: number;
  annotation: string;
  ratingIsDefault: Boolean;
  ratingUnit: string;
  numberOfPhases: number;
  numberOfPhasesIsDefault: Boolean;
  starter: string;
  starterIsDefault: Boolean;
  startingPowerFactor: number;
  startingPowerFactorIsDefault: Boolean;
  startingCurrentOverride: number | null;
  numberOfStarts: number;
  numberOfStartsIsDefault: Boolean;
  numberOfStartsUnit: string;
  efficiency: number;
  efficiencyIsDefault: Boolean;
  hvFaultLevel: number;
  xrRatio: number;
  geometry: LatLng;
  styles: any;
  maxVoltDropPercent: number | null;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRisePercent: number | null;
  maxVoltRiseTotal: number | null;
  maxVoltRiseGeneration: number | null;
  maxVoltRiseUnbalance: number | null;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: number | null;
  AllmaxVoltDropPercentPeriod: number | null;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: number | null;
  AllmaxVoltRisePercent: number | null;
  AllmaxVoltRiseTotal: number | null;
  AllmaxVoltRiseGeneration: number | null;
  AllmaxVoltRiseUnbalance: number | null;
  AllmaxVoltRisePercentPeriod: number | null;
  AllmaxVoltRisePhase: number | null;
  AllmaxVoltage: number | null;
  AllmaxVoltagePhase1: number | null;
  AllmaxVoltagePhase2: number | null;
  AllmaxVoltagePhase3: number | null;
  AllminVoltage: number | null;
  AllminVoltagePhase1: number | null;
  AllminVoltagePhase2: number | null;
  AllminVoltagePhase3: number | null;
  wintermaxVoltDropPercent: number | null;
  wintermaxVoltDropPercentPeriod: number | null;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: number | null;
  wintermaxVoltRisePercent: number | null;
  wintermaxVoltRiseTotal: number | null;
  wintermaxVoltRiseGeneration: number | null;
  wintermaxVoltRiseUnbalance: number | null;
  wintermaxVoltRisePercentPeriod: number | null;
  wintermaxVoltRisePhase: number | null;
  wintermaxVoltage: number | null;
  wintermaxVoltagePhase1: number | null;
  wintermaxVoltagePhase2: number | null;
  wintermaxVoltagePhase3: number | null;
  winterminVoltage: number | null;
  winterminVoltagePhase1: number | null;
  winterminVoltagePhase2: number | null;
  winterminVoltagePhase3: number | null;
  summermaxVoltDropPercent: number | null;
  summermaxVoltDropPercentPeriod: number | null;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: number | null;
  summermaxVoltRisePercent: number | null;
  summermaxVoltRiseTotal: number | null;
  summermaxVoltRiseGeneration: number | null;
  summermaxVoltRiseUnbalance: number | null;
  summermaxVoltRisePercentPeriod: number | null;
  summermaxVoltRisePhase: number | null;
  summermaxVoltage: number | null;
  summermaxVoltagePhase1: number | null;
  summermaxVoltagePhase2: number | null;
  summermaxVoltagePhase3: number | null;
  summerminVoltage: number | null;
  summerminVoltagePhase1: number | null;
  summerminVoltagePhase2: number | null;
  summerminVoltagePhase3: number | null;
  phaseImpedance: number | null;
  loopImpedance: number | null;
  phaseAllocation: string;
  nodeNumber: number;
  objectId: string;
}
