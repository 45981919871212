import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Welder } from "../model/viewModel/welder";
import { Motor } from "../model/viewModel/motor";
import { PointOfConnection } from "../model/viewModel/pointOfConnection";
import { Transformer } from "../model/viewModel/transformer";
import { GroupedConnectionPoint } from "../model/viewModel/groupedConnectionPoint";
import { ConnectionPoint } from "../model/viewModel/connectionPoint";
import { GroupedConnection } from "../model/viewModel/groupedConnection";
import { Node } from "../model/viewModel/node";
import { Cable } from "../model/viewModel/cable";
import { LatLng } from "../model/viewModel/simpleTypes";
import getAssetStyleInfo from "../context/AssetStyleInfo";

export interface NetworkState {
  welders: Welder[];
  motors: Motor[];
  pointOfConnections: PointOfConnection[];
  transformers: Transformer[];
  groupedConnections: GroupedConnection[];
  nodes: Node[];
  connectionPoints: ConnectionPoint[];
  cables: Cable[];
  networkChanged: Boolean;
  hasClearedResults: Boolean;
  title: string | null;
  hideMap: Boolean | null;
}
export interface UpdateState {
  id: string;
  name: string;
  value: any | null;
}
export interface AddChild {
  id: string;
  child: GroupedConnectionPoint;
}
export interface AddSubChild {
  id: string;
  groupedConnectionPointId: string;
  isGroupUpdate: Boolean;
  child: ConnectionPoint;
}
export interface UpdateChild {
  id: string;
  childUpdate: UpdateState;
  isGroupUpdate: Boolean;
  parentId: string | null;
}
export interface RemoveChild {
  id: string;
  consumerId: string;
  childConsumerId: string | null;
  isGroupUpdate: Boolean;
}
export interface Reference {
  filterResults: FilterResult[];
}
export interface FilterResult {
  info: FilterResultInfo[];
}
export interface FilterResultInfo {
  name: string;
  value: string;
  season: string;
  options: FilterResultInfoOption[];
}
export interface FilterResultInfoOption {
  name: string;
  value: string;
}

export const initialState: NetworkState = {
  welders: [],
  motors: [],
  pointOfConnections: [],
  transformers: [],
  groupedConnections: [],
  nodes: [],
  connectionPoints: [],
  cables: [],
  networkChanged: false,
  hasClearedResults: false,
  title: null,
  hideMap: false
};

const setChanged = (state: NetworkState) => {
  if (!state.networkChanged) {
    state.networkChanged = true;
  }
};

const repointCables = (
  state: NetworkState,
  consumerId: string,
  groupedConnectionPoints: GroupedConnectionPoint[]
) => {
  const replaceCableIds = state.cables.some(
    (c) => c.startAssetId === consumerId || c.endAssetId === consumerId
  );
  if (replaceCableIds) {
    if (groupedConnectionPoints.length > 0) {
      const replaceWithId = groupedConnectionPoints[0].id;
      for (const c of state.cables) {
        if (c.startAssetId === consumerId) {
          c.startAssetId = replaceWithId;
        } else if (c.endAssetId === consumerId) {
          c.endAssetId = replaceWithId;
        }
      }
    }
  }
};

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    clearResults: (state, action: PayloadAction<string[]>) => {
      if (state.hasClearedResults) {
        return;
      }

      const results = action.payload;

      state.welders = state.welders.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.motors = state.motors.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.pointOfConnections = state.pointOfConnections.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.transformers = state.transformers.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.groupedConnections = state.groupedConnections.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.nodes = state.nodes.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.connectionPoints = state.connectionPoints.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.cables = state.cables.map((p) => {
        const newItem = { ...p };
        results.forEach((result) => {
          if (newItem.hasOwnProperty(result)) {
            newItem[result] = null;
          }
        });
        return newItem;
      });

      state.hasClearedResults = true;
    },
    clearReduxNetwork: (state) => {
      state.welders = [];
      state.motors = [];
      state.pointOfConnections = [];
      state.transformers = [];
      state.groupedConnections = [];
      state.nodes = [];
      state.connectionPoints = [];
      state.cables = [];
      state.networkChanged = false;
      state.hasClearedResults = true;
      state.title = null;
      state.hideMap = false;
    },
    resolveAllDefaults: (state) => {
      function resolve(asset: any) {
        Object.entries(asset).forEach(([key, value]) => {
          if (key.endsWith("IsDefault") && value) {
            asset[key] = false;
          }
        });
        if (asset.groupedConnectionPoints) {
          asset.groupedConnectionPoints.forEach(
            (gcp: GroupedConnectionPoint) => {
              Object.entries(gcp).forEach(([key, value]) => {
                if (key.endsWith("IsDefault") && value) {
                  gcp[key] = false;
                }
              });
            }
          );
        }
        return asset;
      }

      state.welders = state.welders.map((p) => resolve({ ...p }));
      state.motors = state.motors.map((p) => resolve({ ...p }));
      state.pointOfConnections = state.pointOfConnections.map((p) =>
        resolve({ ...p })
      );
      state.transformers = state.transformers.map((p) => resolve({ ...p }));
      state.groupedConnections = state.groupedConnections.map((p) =>
        resolve({ ...p })
      );
      state.nodes = state.nodes.map((p) => resolve({ ...p }));
      state.connectionPoints = state.connectionPoints.map((p) =>
        resolve({ ...p })
      );
      state.cables = state.cables.map((p) => resolve({ ...p }));
    },
    setAsSaved: (state) => {
      state.networkChanged = false;
    },
    addWelder: (state, action: PayloadAction<Welder>) => {
      state.welders = [...state.welders, action.payload];
      setChanged(state);
    },
    addWelders: (state, action: PayloadAction<Welder[]>) => {
      state.welders = [...state.welders, ...action.payload];
      setChanged(state);
    },
    updateWelder: (state, action: PayloadAction<UpdateState>) => {
      state.welders = state.welders.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deleteWelder: (state, action: PayloadAction<string>) => {
      state.welders = state.welders.filter((p) => p.id !== action.payload);
      setChanged(state);
    },
    clearWelders: (state) => {
      state.welders = [];
      setChanged(state);
    },
    replaceWelders: (state, action: PayloadAction<Welder[]>) => {
      if (action.payload) {
        state.welders = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    addMotor: (state, action: PayloadAction<Motor>) => {
      state.motors = [...state.motors, action.payload];
      setChanged(state);
    },
    addMotors: (state, action: PayloadAction<Motor[]>) => {
      state.motors = [...state.motors, ...action.payload];
      setChanged(state);
    },
    updateMotor: (state, action: PayloadAction<UpdateState>) => {
      state.motors = state.motors.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deleteMotor: (state, action: PayloadAction<string>) => {
      state.motors = state.motors.filter((p) => p.id !== action.payload);
      setChanged(state);
    },
    clearMotors: (state) => {
      state.motors = [];
      setChanged(state);
    },
    replaceMotors: (state, action: PayloadAction<Motor[]>) => {
      if (action.payload) {
        state.motors = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    addPointOfConnection: (state, action: PayloadAction<PointOfConnection>) => {
      state.pointOfConnections = [...state.pointOfConnections, action.payload];
      setChanged(state);
    },
    addPointOfConnections: (
      state,
      action: PayloadAction<PointOfConnection[]>
    ) => {
      state.pointOfConnections = [
        ...state.pointOfConnections,
        ...action.payload,
      ];
      setChanged(state);
    },
    updatePointOfConnection: (state, action: PayloadAction<UpdateState>) => {
      state.pointOfConnections = state.pointOfConnections.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deletePointOfConnection: (state, action: PayloadAction<string>) => {
      state.pointOfConnections = state.pointOfConnections.filter(
        (p) => p.id !== action.payload
      );
      setChanged(state);
    },
    clearPointOfConnections: (state) => {
      state.pointOfConnections = [];
      setChanged(state);
    },
    replacePointOfConnections: (
      state,
      action: PayloadAction<PointOfConnection[]>
    ) => {
      if (action.payload) {
        state.pointOfConnections = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    addTransformer: (state, action: PayloadAction<Transformer>) => {
      state.transformers = [...state.transformers, action.payload];
      setChanged(state);
    },
    addTransformers: (state, action: PayloadAction<Transformer[]>) => {
      state.transformers = [...state.transformers, ...action.payload];
      setChanged(state);
    },
    updateTransformer: (state, action: PayloadAction<UpdateState>) => {
      state.transformers = state.transformers.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deleteTransformer: (state, action: PayloadAction<string>) => {
      state.transformers = state.transformers.filter(
        (p) => p.id !== action.payload
      );
      setChanged(state);
    },
    clearTransformers: (state) => {
      state.transformers = [];
      setChanged(state);
    },
    replaceTransformers: (state, action: PayloadAction<Transformer[]>) => {
      if (action.payload) {
        state.transformers = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    addTransformerChild: (state, action: PayloadAction<AddChild>) => {
      if (action.payload) {
        const transformer = state.transformers.find(
          (p) => p.id === action.payload.id
        );
        if (transformer) {
          transformer.groupedConnectionPoints.push(action.payload.child);
          setChanged(state);
        }
      }
    },
    addTransformerSubChild: (state, action: PayloadAction<AddSubChild>) => {
      if (action.payload) {
        const transformer = state.transformers.find(
          (p) => p.id === action.payload.id
        );
        if (transformer) {
          const parent = transformer.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              action.payload.groupedConnectionPointId
          );
          if (
            parent &&
            !parent.subGroupConnectionPoints.some(
              (p) => p.id === action.payload.child.id
            )
          ) {
            parent.subGroupConnectionPoints.push(action.payload.child);
            setChanged(state);
          }
        }
      }
    },
    updateTransformerChild: (state, action: PayloadAction<UpdateChild>) => {
      if (action.payload) {
        const transformer = state.transformers.find(
          (p) => p.id === action.payload.id
        );
        if (transformer) {
          const parent = transformer.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              (action.payload.parentId ?? action.payload.childUpdate.id)
          );
          if (parent) {
            if (action.payload.parentId) {
              const subGroup = parent.subGroupConnectionPoints.find(
                (p) => p.id === action.payload.childUpdate.id
              );
              if (subGroup) {
                subGroup[action.payload.childUpdate.name] =
                  action.payload.childUpdate.value;
              }
            } else {
              parent[action.payload.childUpdate.name] =
                action.payload.childUpdate.value;
            }
            setChanged(state);
          }
        }
      }
    },
    removeTransformerChild: (state, action: PayloadAction<RemoveChild>) => {
      if (action.payload) {
        const transformer = state.transformers.find(
          (p) => p.id === action.payload.id
        );
        if (transformer) {
          const parent = transformer.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              action.payload.consumerId
          );
          if (parent) {
            if (action.payload.childConsumerId) {
              parent.subGroupConnectionPoints =
                parent.subGroupConnectionPoints.filter(
                  (p) =>
                    (action.payload.isGroupUpdate ? p.groupId : p.id) !==
                    action.payload.childConsumerId
                );
            } else {
              transformer.groupedConnectionPoints =
                transformer.groupedConnectionPoints.filter(
                  (p) =>
                    (action.payload.isGroupUpdate ? p.groupId : p.id) !==
                    action.payload.consumerId
                );

              repointCables(
                state,
                action.payload.consumerId,
                transformer.groupedConnectionPoints
              );
            }
            setChanged(state);
          }
        }
      }
    },
    addGroupedConnection: (state, action: PayloadAction<GroupedConnection>) => {
      state.groupedConnections = [...state.groupedConnections, action.payload];
      setChanged(state);
    },
    addGroupedConnections: (
      state,
      action: PayloadAction<GroupedConnection[]>
    ) => {
      state.groupedConnections = [
        ...state.groupedConnections,
        ...action.payload,
      ];
      setChanged(state);
    },
    updateGroupedConnection: (state, action: PayloadAction<UpdateState>) => {
      state.groupedConnections = state.groupedConnections.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    updateGroupedConnectionPole: (
      state,
      action: PayloadAction<UpdateState>
    ) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload.id
        );
        if (groupedConnection) {
          groupedConnection.pole[action.payload.name] = action.payload.value;
          setChanged(state);
        }
      }
    },
    deleteGroupedConnection: (state, action: PayloadAction<string>) => {
      state.groupedConnections = state.groupedConnections.filter(
        (p) => p.id !== action.payload
      );
      setChanged(state);
    },
    clearGroupedConnections: (state) => {
      state.groupedConnections = [];
      setChanged(state);
    },
    replaceGroupedConnections: (
      state,
      action: PayloadAction<GroupedConnection[]>
    ) => {
      if (action.payload) {
        state.groupedConnections = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    deleteGroupedConnectionPoints: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload
        );
        if (groupedConnection) {
          groupedConnection.groupedConnectionPoints = [];
          setChanged(state);
        }
      }
    },
    updateGroupedConnectionLinkBoxConnectivity: (
      state,
      action: PayloadAction<UpdateState>
    ) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload.id
        );
        if (groupedConnection?.linkBox?.connectivity) {
          groupedConnection.linkBox.connectivity[action.payload.name] =
            action.payload.value;
          setChanged(state);
        }
      }
    },
    addGroupedConnectionChild: (state, action: PayloadAction<AddChild>) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload.id
        );
        if (groupedConnection) {
          groupedConnection.groupedConnectionPoints.push(action.payload.child);
          if (groupedConnection.groupedConnectionPoints.length === 1) {
            groupedConnection.styles = getAssetStyleInfo(
              action.payload.child.styles.class
            );
          }
          setChanged(state);
        }
      }
    },
    addGroupedConnectionSubChild: (
      state,
      action: PayloadAction<AddSubChild>
    ) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload.id
        );
        if (groupedConnection) {
          const parent = groupedConnection.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              action.payload.groupedConnectionPointId
          );
          if (
            parent &&
            !parent.subGroupConnectionPoints.some(
              (p) => p.id === action.payload.child.id
            )
          ) {
            parent.subGroupConnectionPoints.push(action.payload.child);
            setChanged(state);
          }
        }
      }
    },
    updateGroupedConnectionChild: (
      state,
      action: PayloadAction<UpdateChild>
    ) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload.id
        );
        if (groupedConnection) {
          const parent = groupedConnection.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              (action.payload.parentId ?? action.payload.childUpdate.id)
          );
          if (parent) {
            if (action.payload.parentId) {
              const subGroup = parent.subGroupConnectionPoints.find(
                (p) => p.id === action.payload.childUpdate.id
              );
              if (subGroup) {
                subGroup[action.payload.childUpdate.name] =
                  action.payload.childUpdate.value;
              }
            } else {
              parent[action.payload.childUpdate.name] =
                action.payload.childUpdate.value;
            }
            setChanged(state);
          }
        }
      }
    },
    removeGroupedConnectionChild: (
      state,
      action: PayloadAction<RemoveChild>
    ) => {
      if (action.payload) {
        const groupedConnection = state.groupedConnections.find(
          (p) => p.id === action.payload.id
        );
        if (groupedConnection) {
          const parent = groupedConnection.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              action.payload.consumerId
          );
          if (parent) {
            if (action.payload.childConsumerId) {
              parent.subGroupConnectionPoints =
                parent.subGroupConnectionPoints.filter(
                  (p) =>
                    (action.payload.isGroupUpdate ? p.groupId : p.id) !==
                    action.payload.childConsumerId
                );
            } else {
              groupedConnection.groupedConnectionPoints =
                groupedConnection.groupedConnectionPoints.filter(
                  (p) =>
                    (action.payload.isGroupUpdate ? p.groupId : p.id) !==
                    action.payload.consumerId
                );

              if (groupedConnection.groupedConnectionPoints.length > 0) {
                repointCables(
                  state,
                  action.payload.consumerId,
                  groupedConnection.groupedConnectionPoints
                );
              } else {
                groupedConnection.styles = getAssetStyleInfo("node");
              }
            }
            setChanged(state);
          }
        }
      }
    },
    addNode: (state, action: PayloadAction<Node>) => {
      state.nodes = [...state.nodes, action.payload];
      setChanged(state);
    },
    addNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = [...state.nodes, ...action.payload];
      setChanged(state);
    },
    updateNode: (state, action: PayloadAction<UpdateState>) => {
      state.nodes = state.nodes.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deleteNode: (state, action: PayloadAction<string>) => {
      state.nodes = state.nodes.filter((p) => p.id !== action.payload);
      setChanged(state);
    },
    clearNodes: (state) => {
      state.nodes = [];
      setChanged(state);
    },
    replaceNodes: (state, action: PayloadAction<Node[]>) => {
      if (action.payload) {
        state.nodes = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    addConnectionPoint: (state, action: PayloadAction<ConnectionPoint>) => {
      state.connectionPoints = [...state.connectionPoints, action.payload];
      setChanged(state);
    },
    addConnectionPoints: (state, action: PayloadAction<ConnectionPoint[]>) => {
      state.connectionPoints = [...state.connectionPoints, ...action.payload];
      setChanged(state);
    },
    updateConnectionPoint: (state, action: PayloadAction<UpdateState>) => {
      state.connectionPoints = state.connectionPoints.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deleteConnectionPoint: (state, action: PayloadAction<string>) => {
      state.connectionPoints = state.connectionPoints.filter(
        (p) => p.id !== action.payload
      );
      setChanged(state);
    },
    clearConnectionPoints: (state) => {
      state.connectionPoints = [];
      setChanged(state);
    },
    replaceConnectionPoints: (
      state,
      action: PayloadAction<ConnectionPoint[]>
    ) => {
      if (action.payload) {
        state.connectionPoints = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    addCable: (state, action: PayloadAction<Cable>) => {
      state.cables = [...state.cables, action.payload];
      setChanged(state);
    },
    addCables: (state, action: PayloadAction<Cable[]>) => {
      state.cables = [...state.cables, ...action.payload];
      setChanged(state);
    },
    updateCable: (state, action: PayloadAction<UpdateState>) => {
      state.cables = state.cables.map((p) =>
        p.id === action.payload.id
          ? { ...p, [action.payload.name]: action.payload.value }
          : p
      );
      setChanged(state);
    },
    deleteCable: (state, action: PayloadAction<string>) => {
      state.cables = state.cables.filter((p) => p.id !== action.payload);
      setChanged(state);
    },
    clearCables: (state) => {
      state.cables = [];
      setChanged(state);
    },
    replaceCables: (state, action: PayloadAction<Cable[]>) => {
      if (action.payload) {
        state.cables = [...action.payload];
        state.hasClearedResults = false;
        setChanged(state);
      }
    },
    updateCableArray: (state, action: PayloadAction<UpdateArrayState>) => {
      if (action.payload) {
        const cable = state.cables.find((p) => p.id === action.payload.id);
        if (cable) {
          cable[action.payload.arrayProperty][action.payload.index][
            action.payload.name
          ] = action.payload.value;
          setChanged(state);
        }
      }
    },
    addCableChild: (state, action: PayloadAction<AddChild>) => {
      if (action.payload) {
        const cable = state.cables.find((p) => p.id === action.payload.id);
        if (cable) {
          cable.groupedConnectionPoints.push(action.payload.child);
          setChanged(state);
        }
      }
    },
    addCableSubChild: (state, action: PayloadAction<AddSubChild>) => {
      if (action.payload) {
        const cable = state.cables.find((p) => p.id === action.payload.id);
        if (cable) {
          const parent = cable.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              action.payload.groupedConnectionPointId
          );
          if (
            parent &&
            !parent.subGroupConnectionPoints.some(
              (p) => p.id === action.payload.child.id
            )
          ) {
            parent.subGroupConnectionPoints.push(action.payload.child);
            setChanged(state);
          }
        }
      }
    },
    updateCableChild: (state, action: PayloadAction<UpdateChild>) => {
      if (action.payload) {
        const cable = state.cables.find((p) => p.id === action.payload.id);
        if (cable) {
          const parent = cable.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              (action.payload.parentId ?? action.payload.childUpdate.id)
          );
          if (parent) {
            if (action.payload.parentId) {
              const subGroup = parent.subGroupConnectionPoints.find(
                (p) => p.id === action.payload.childUpdate.id
              );
              if (subGroup) {
                subGroup[action.payload.childUpdate.name] =
                  action.payload.childUpdate.value;
              }
            } else {
              parent[action.payload.childUpdate.name] =
                action.payload.childUpdate.value;
            }
            setChanged(state);
          }
        }
      }
    },
    removeCableChild: (state, action: PayloadAction<RemoveChild>) => {
      if (action.payload) {
        const cable = state.cables.find((p) => p.id === action.payload.id);
        if (cable) {
          const parent = cable.groupedConnectionPoints.find(
            (p) =>
              (action.payload.isGroupUpdate ? p.groupId : p.id) ===
              action.payload.consumerId
          );
          if (parent) {
            if (action.payload.childConsumerId) {
              parent.subGroupConnectionPoints =
                parent.subGroupConnectionPoints.filter(
                  (p) =>
                    (action.payload.isGroupUpdate ? p.groupId : p.id) !==
                    action.payload.childConsumerId
                );
            } else {
              cable.groupedConnectionPoints =
                cable.groupedConnectionPoints.filter(
                  (p) =>
                    (action.payload.isGroupUpdate ? p.groupId : p.id) !==
                    action.payload.consumerId
                );

              repointCables(
                state,
                action.payload.consumerId,
                cable.groupedConnectionPoints
              );
            }
            setChanged(state);
          }
        }
      }
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setHideMap: (state, action: PayloadAction<Boolean>) => {
      state.hideMap = action.payload;
    },
    resetFeederNumbers: (state) => {
      const transformerIds = state.transformers.map(p => p.id);

      state.cables
        .filter((cable) => !transformerIds.includes(cable.startAssetId) && !transformerIds.includes(cable.endAssetId))
        .forEach((cable) => {
          cable.feederNumber = null;
        });
    },
  },
});

export interface UpdateArrayState {
  id: string;
  arrayProperty: string;
  index: number;
  name: string;
  value: any | null;
}
export const {
  clearResults,
  clearReduxNetwork,
  resolveAllDefaults,
  setAsSaved,
  addWelder,
  addWelders,
  updateWelder,
  deleteWelder,
  clearWelders,
  replaceWelders,
  addMotor,
  addMotors,
  updateMotor,
  deleteMotor,
  clearMotors,
  replaceMotors,
  addPointOfConnection,
  addPointOfConnections,
  updatePointOfConnection,
  deletePointOfConnection,
  clearPointOfConnections,
  replacePointOfConnections,
  addTransformer,
  addTransformers,
  updateTransformer,
  deleteTransformer,
  clearTransformers,
  replaceTransformers,
  addTransformerChild,
  addTransformerSubChild,
  updateTransformerChild,
  removeTransformerChild,
  addGroupedConnection,
  addGroupedConnections,
  updateGroupedConnection,
  updateGroupedConnectionPole,
  deleteGroupedConnection,
  clearGroupedConnections,
  replaceGroupedConnections,
  deleteGroupedConnectionPoints,
  updateGroupedConnectionLinkBoxConnectivity,
  addGroupedConnectionChild,
  addGroupedConnectionSubChild,
  updateGroupedConnectionChild,
  removeGroupedConnectionChild,
  addNode,
  addNodes,
  updateNode,
  deleteNode,
  clearNodes,
  replaceNodes,
  addConnectionPoint,
  addConnectionPoints,
  updateConnectionPoint,
  deleteConnectionPoint,
  clearConnectionPoints,
  replaceConnectionPoints,
  addCable,
  addCables,
  updateCable,
  deleteCable,
  clearCables,
  replaceCables,
  updateCableArray,
  addCableChild,
  addCableSubChild,
  updateCableChild,
  removeCableChild,
  setTitle,
  setHideMap,
  resetFeederNumbers,
} = networkSlice.actions;

export const checkReduxNetworkPopulated = (state: RootState) =>
  state.network.present.welders.length > 0 ||
  state.network.present.motors.length > 0 ||
  state.network.present.pointOfConnections.length > 0 ||
  state.network.present.transformers.length > 0 ||
  state.network.present.groupedConnections.length > 0 ||
  state.network.present.nodes.length > 0 ||
  state.network.present.connectionPoints.length > 0 ||
  state.network.present.cables.length > 0;

export const getAllGeometries = (state: RootState) =>
  state.network.present.welders
    .map((p) => p.geometry as LatLng | LatLng[])
    .concat(state.network.present.motors.map((p) => p.geometry))
    .concat(state.network.present.pointOfConnections.map((p) => p.geometry))
    .concat(state.network.present.transformers.map((p) => p.geometry))
    .concat(state.network.present.groupedConnections.map((p) => p.geometry))
    .concat(state.network.present.nodes.map((p) => p.geometry))
    .concat(state.network.present.connectionPoints.map((p) => p.geometry))
    .concat(state.network.present.cables.map((p) => p.geometry));

export const getAllGeometryAssets = (state: RootState) =>
  state.network.present.welders
    .map((p) => ({
      geometry: p.geometry as LatLng | LatLng[],
      asset: p as any,
    }))
    .concat(
      state.network.present.motors.map((p) => ({ geometry: p.geometry, asset: p }))
    )
    .concat(
      state.network.present.pointOfConnections.map((p) => ({
        geometry: p.geometry,
        asset: p,
      }))
    )
    .concat(
      state.network.present.transformers.map((p) => ({
        geometry: p.geometry,
        asset: p,
      }))
    )
    .concat(
      state.network.present.groupedConnections.map((p) => ({
        geometry: p.geometry,
        asset: p,
      }))
    )
    .concat(
      state.network.present.nodes.map((p) => ({ geometry: p.geometry, asset: p }))
    )
    .concat(
      state.network.present.connectionPoints.map((p) => ({
        geometry: p.geometry,
        asset: p,
      }))
    )
    .concat(
      state.network.present.cables.map((p) => ({ geometry: p.geometry, asset: p }))
    );

export const findAll = (state: RootState) =>
  state.network.present.welders
    .map((p) => ({ type: "welders", asset: p as any }))
    .concat(state.network.present.motors.map((p) => ({ type: "motors", asset: p })))
    .concat(
      state.network.present.pointOfConnections.map((p) => ({
        type: "pointOfConnections",
        asset: p,
      }))
    )
    .concat(
      state.network.present.transformers.map((p) => ({
        type: "transformers",
        asset: p,
      }))
    )
    .concat(
      state.network.present.groupedConnections.map((p) => ({
        type: "groupedConnections",
        asset: p,
      }))
    )
    .concat(state.network.present.nodes.map((p) => ({ type: "nodes", asset: p })))
    .concat(
      state.network.present.connectionPoints.map((p) => ({
        type: "connectionPoints",
        asset: p,
      }))
    )
    .concat(state.network.present.cables.map((p) => ({ type: "cables", asset: p })));

export const buildNetwork = (state: RootState) => {
  return {
    transformers: state.network.present.transformers,
    nodes: state.network.present.nodes,
    connectionPoints: state.network.present.connectionPoints,
    groupedConnections: state.network.present.groupedConnections,
    cables: state.network.present.cables,
    motors: state.network.present.motors,
    welders: state.network.present.welders,
    pointOfConnections: state.network.present.pointOfConnections,
    runtime: { hideMap: state.network.present.hideMap },
  };
};

export const findWelder = (state: RootState, id: string) =>
  state.network.present.welders.find((p) => p.id === id);
export const findMotor = (state: RootState, id: string) =>
  state.network.present.motors.find((p) => p.id === id);
export const findPointOfConnection = (state: RootState, id: string) =>
  state.network.present.pointOfConnections.find((p) => p.id === id);
export const findTransformer = (state: RootState, id: string) =>
  state.network.present.transformers.find((p) => p.id === id);
export const findGroupedConnection = (state: RootState, id: string) =>
  state.network.present.groupedConnections.find((p) => p.id === id);
export const findNode = (state: RootState, id: string) =>
  state.network.present.nodes.find((p) => p.id === id);
export const findConnectionPoint = (state: RootState, id: string) =>
  state.network.present.connectionPoints.find((p) => p.id === id);
export const findCable = (state: RootState, id: string) =>
  state.network.present.cables.find((p) => p.id === id);

export default networkSlice.reducer;
