// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { RagCable, RagCableProperties } from "./ragCable";
import { getLatLngArrayFromWktLineString } from "./geometryWktConverter";

export function createRagCableFromConductingEquipment(
  conductingEquipment: ConductingEquipment
): RagCable {
  const cableProperties = new RagCableProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  cableProperties.geometry = getLatLngArrayFromWktLineString(geometryString);

  cableProperties.cableGroup =
    conductingEquipment.getPropertyValue("cableGroup");
  cableProperties.styles = getAssetStyleInfo(
    conductingEquipment.getPropertyValue("class")
  );
  cableProperties.cascadingRAG =
    conductingEquipment.getPropertyValue("cascadingRAG");
  cableProperties.nonCascadingRAG =
    conductingEquipment.getPropertyValue("nonCascadingRAG");

  return new RagCable(conductingEquipment.id, cableProperties);
}
