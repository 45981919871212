import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import { offsetPosition } from "../utils/offsetPosition";
import "./CounterBadge.css";

const LctBadge = ({ asset, value, position, lctType, onClick }) => {
	const map = useMap();

	const styleBadge = (value) => {
		const badge = L.divIcon({
			className: "countMarker",
			html: ReactDOMServer.renderToString(
				<div className={`badge rounded-pill text-light p-0`}>
					<span
						style={{
							display: "inline-block",
							fontSize: ".9rem",
							padding: ".125rem .25rem",
						}}
					>
						<small>
							<i className={`icon-${lctType}`}></i>
						</small>
					</span>
				</div>
			),
		});
		return badge;
	};

	return (
		<Marker
			eventHandlers={{
				click: (e) => {
					onClick(asset);
				},
			}}
			id={asset.id}
			className='counter-badge'
			position={offsetPosition(map, asset.geometry, position[0], position[1])}
			icon={styleBadge(value && value)}
		/>
	);
};

export default LctBadge;
