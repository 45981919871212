import { TransformerProperties } from "../model/viewModel/transformerProperties";

export const getTransformerProperties = (
  nameplateRating,
  nameplateRatingIsDefault,
  cyclicRating,
  mounting,
  geometry,
  systemVoltage,
  numberOfPhases,
  numberOfPhasesIsDefault,
  fuseSelection,
  fuseSelectionIsDefault,
  fuseRating,
  autoSelect,
  groupedConnectionPoints,
  hvFaultLevel,
  hvFaultLevelIsDefault,
  xrRatio,
  annotation,
  xrRatioIsDefault,
  ohEnvironment,
  poleTermLength,
  isDefaultPoleTermLength,
  designVoltage
) => {
  let transformerProperties: TransformerProperties =
    new TransformerProperties();
  transformerProperties.nameplateRating = nameplateRating;
  transformerProperties.nameplateRatingIsDefault = nameplateRatingIsDefault;
  transformerProperties.cyclicRating = cyclicRating;
  transformerProperties.mounting = mounting;
  transformerProperties.geometry = geometry;
  transformerProperties.systemVoltage = systemVoltage;
  transformerProperties.numberOfPhases = numberOfPhases;
  transformerProperties.numberOfPhasesIsDefault = numberOfPhasesIsDefault;
  transformerProperties.fuseSelection = fuseSelection;
  transformerProperties.fuseSelectionIsDefault = fuseSelectionIsDefault;
  transformerProperties.fuseRating = fuseRating;
  transformerProperties.autoSelect = autoSelect;
  transformerProperties.groupedConnectionPoints = (
    groupedConnectionPoints ?? []
  ).map((p) => ({
    ...p,
    subGroupConnectionPoints: [...(p.subGroupConnectionPoints ?? [])],
  }));
  transformerProperties.hvFaultLevel = hvFaultLevel;
  transformerProperties.hvFaultLevelIsDefault = hvFaultLevelIsDefault;
  transformerProperties.xrRatio = xrRatio;
  transformerProperties.annotation = annotation;
  transformerProperties.xrRatioIsDefault = xrRatioIsDefault;
  transformerProperties.ohEnvironment = ohEnvironment;
  transformerProperties.poleTermLength = poleTermLength;
  transformerProperties.isDefaultPoleTermLength = isDefaultPoleTermLength;
  transformerProperties.designVoltage = designVoltage;
  return transformerProperties;
};
