import React from "react";
import { useMap, Pane } from "react-leaflet";
import { isVisible } from "../utils/networkFunctions";
import DetectAsset from "./DetectAsset";

const DetectNetwork = ({ detectNetworks, handleSelect, clickedAsset }) => {
  const map = useMap();
  const bounds = map.getBounds();

  return (
    <>
      <Pane name="detect-network" style={{ zIndex: "600" }}>
        {detectNetworks.length > 0 &&
          detectNetworks
            .filter((n) => n.show === true)
            .map((detectNetwork) =>
              detectNetwork.faultMarkers.map((marker) => (
                <DetectAsset
                  key={marker.id}
                  asset={marker}
                  network={detectNetwork}
                ></DetectAsset>
              ))
            )}
      </Pane>
      {detectNetworks.length > 0 &&
        detectNetworks
          .filter((obj) => obj.show === true)
          .map((detectNetwork) =>
            detectNetwork.cables.map((route) =>
              route
                .filter((p) => isVisible(bounds, p.geometry))
                .map((cable) => (
                  <DetectAsset
                    key={cable.id}
                    asset={cable}
                    network={detectNetwork}
                    onClick={handleSelect}
                    highlighted={clickedAsset && cable.id === clickedAsset.id}
                  />
                ))
            )
          )}
    </>
  );
};

export default DetectNetwork;
{
}
