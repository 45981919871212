import React, { useContext, useRef, useState, useEffect } from "react";
import { MapContainer, TileLayer, Pane } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import { v4 as uuid } from "uuid";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import ControlTabs from "./ControlTabs";
import MapControls from "./MapControls";
import Navbar from "./Navbar";
import AlertModal from "./AlertModal";
import { isEmpty } from "../utils/isEmpty";

import GeoJsonLoader from "./GeoJsonLoader";
import Spinner from "./Spinner";
import * as layout from "../constants/layout";

const ConnectLV = () => {
  const [id, setId] = useState();
  const { formState } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clientSettings, reference } = formState;
  const [navbarHeight, setNavbarHeight] = useState(layout.NAVBAR_HEIGHT);
  const showTiles = useSelector((state) => state.settings.showMapBackground);
  const geoJson = useSelector((state) => state.settings.geoJson);
  const hideMap = useSelector((state) => state.network.hideMap);

  let mapRef = useRef();

  const saveLeafletMap = () => {
    const ref = mapRef.current;
    if (ref && toolState.leafletMap !== ref.leafletElement) {
      setToolState({ leafletMap: ref.leafletElement });
    }
  };

  saveLeafletMap();

  const _onMoveEnd = (e) => {
    setId(uuid());
  };

  const { isLoading } = toolState;

  const handleWindowSizeChange = () => {
    const navbar = document.getElementById("ea_navbar");
    setNavbarHeight(navbar.clientHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Navbar />
      {reference && (
        <>
          {clientSettings.Features.SideBarEnabled && (
            <Sidebar position={navbarHeight}>
              <ControlTabs />
            </Sidebar>
          )}
        </>
      )}
      {clientSettings.Map && (
        <MapContainer
          id="map"
          center={[
            clientSettings.Map.DefaultMapCenterLat,
            clientSettings.Map.DefaultMapCenterLng,
          ]}
          zoom={clientSettings.Map.DefaultZoomLevel}
          doubleClickZoom={false}
          zoomControl={false}
          attributionControl={false}
          minZoom={clientSettings.Map.MinZoomLevel}
          maxZoom={clientSettings.Map.MaxZoomLevel}
          onMoveEnd={_onMoveEnd}
          whenCreated={(mapInstance) =>
            setToolState({ leafletMap: mapInstance })
          }
        >
          <Pane name="tile-layer" style={{ zIndex: "-1" }}>
            {clientSettings.Features.MapTilesEnabled && showTiles && !hideMap && (
              <TileLayer
                url={clientSettings.Urls.EndPoints.MapTiles}
                maxNativeZoom={parseInt(clientSettings.Map.MaxNativeZoomLevel)}
                maxZoom={24}
              />
            )}
            {[clientSettings.General.GeoServerLayers]
              .filter((f) => geoJson.includes(f.name))
              .map((layer) => (
                <GeoJsonLoader id={id} layer={layer} />
              ))}
          </Pane>
          <MapControls />
        </MapContainer>
      )}
      {!isEmpty(toolState.alert) && <AlertModal />}
      {isLoading && <Spinner>{isLoading}</Spinner>}
    </>
  );
};

export default ConnectLV;
