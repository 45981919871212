import { CableProperties } from "../model/viewModel/cableProperties";

export const getCableProperties = (
  geometry,
  startAssetId,
  endAssetId,
  length,
  cableType,
  cableTypeIsDefault,
  cableGroup,
  cableGroupIsDefault,
  numberOfPhases,
  numberOfPhasesIsDefault,
  overrideLength,
  autoSelect,
  groupedConnectionPoints,
  ducting,
  fuseSelection,
  annotation,
  status,
  groundTypeOverrides,
  fusewayStatus
) => {
  let cableProperties: CableProperties = new CableProperties();
  cableProperties.geometry = geometry;
  cableProperties.startAssetId = startAssetId;
  cableProperties.endAssetId = endAssetId;
  cableProperties.length = length;
  cableProperties.cableType = cableType;
  cableProperties.cableTypeIsDefault = cableTypeIsDefault;
  cableProperties.cableGroup = cableGroup;
  cableProperties.cableGroupIsDefault = cableGroupIsDefault;
  cableProperties.numberOfPhases = numberOfPhases;
  cableProperties.numberOfPhasesIsDefault = numberOfPhasesIsDefault;
  cableProperties.overrideLength = overrideLength;
  cableProperties.autoSelect = autoSelect;
  cableProperties.groupedConnectionPoints = (groupedConnectionPoints ?? []).map(
    (p) => ({
      ...p,
      subGroupConnectionPoints: [...(p.subGroupConnectionPoints ?? [])],
    })
  );
  cableProperties.ducting = ducting;
  cableProperties.fuseSelection = fuseSelection;
  cableProperties.annotation = annotation;
  cableProperties.status = status;
  cableProperties.groundTypeOverrides = groundTypeOverrides;
  cableProperties.fusewayStatus = fusewayStatus;
  return cableProperties;
};
