import { DomEvent } from "leaflet";
// import { AppMode, selectApp, setMouseOverControl } from "../../app/appSlice";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./Control.css";

interface IControl {
  children: any;
  positionX: string;
  positionY: string;
  size: string;
  zIndex?: number;
  cssClasses?: string;
}

const Control = ({
  children,
  positionX,
  positionY,
  size,
  zIndex = 1000,
  cssClasses = "",
}: IControl) => {
  // const dispatch = useAppDispatch();
  // const app = useAppSelector(selectApp);
  // const { appMode } = app;

  const refContainer = (element: HTMLDivElement) => {
    if (element) {
      DomEvent.disableClickPropagation(element).disableScrollPropagation(
        element
      );
    }
  };

  // const onMouseOver = () => {
  //   dispatch(setMouseOverControl(true));
  // };

  // const onMouseOut = () => {
  //   dispatch(setMouseOverControl(false));
  // };

  return (
    <div
      ref={refContainer}
      className={`control control-${positionX} control-margin-${positionX}-${size} control-${positionY} control-margin-${positionY}-${size} ${cssClasses}`}
      style={{ zIndex: zIndex }}
      // onMouseOver={onMouseOver}
      // onMouseOut={onMouseOut}
    >
      <div>{children}</div>
    </div>
  );
};

export default Control;
