import * as cable from "../constants/cable";
import * as alert from "../constants/alert";
import * as statusConstants from "../constants/status";

const CODE_W101 = "W101";
const CODE_W102 = "W102";
const CODE_W103 = "W103";
const CODE_W104 = "W104";
const CODE_E101 = "E101";
const CODE_E102 = "E102";

export const checkOverheadLineLength = (c, reference, clientSettings) => {
  let warning = {};

  if (!clientSettings.Features.CostingEnabled || c.status === statusConstants.EXISTING) {
    return warning;
  }

  const cableLength =
    c.overrideLength !== null && c.overrideLength > 0
      ? c.overrideLength
      : c.length;

  if (c.cableGroup === cable.GROUP_MAINS_OH) {
    if (cableLength > reference.maxOHCableLengthsInMetres.mains) {
      warning = {
        code: CODE_W101,
        level: alert.LEVEL_WARNING,
        link: c.id,
        description: `Overhead mains conductor(s) exceed the maximum span length (${reference.maxOHCableLengthsInMetres.mains}m).`,
      };
    }
  }
  if (c.cableGroup === cable.GROUP_SERVICE_OH) {
    if (
      c.cableType === cable.TYPE_ABC35 &&
      cableLength > reference.maxOHCableLengthsInMetres.serviceABC35
    ) {
      warning = {
        code: CODE_W102,
        level: alert.LEVEL_WARNING,
        link: c.id,
        description: `Overhead 'ABC 35' service conductor(s) exceed the maximum span length (${reference.maxOHCableLengthsInMetres.serviceABC35}m).`,
      };
    } else if (
      c.numberOfPhases === cable.SINGLE_PHASE &&
      cableLength > reference.maxOHCableLengthsInMetres.singlePhaseService
    ) {
      warning = {
        code: CODE_W103,
        level: alert.LEVEL_WARNING,
        link: c.id,
        description: `Overhead single phase service conductor(s) exceed the maximum span length (${reference.maxOHCableLengthsInMetres.singlePhaseService}m).`,
      };
    } else if (
      c.numberOfPhases !== cable.SINGLE_PHASE &&
      c.cableType !== cable.TYPE_ABC35 &&
      cableLength > reference.maxOHCableLengthsInMetres.service
    ) {
      warning = {
        code: CODE_W104,
        level: alert.LEVEL_WARNING,
        link: c.id,
        description: `Overhead service conductor(s) exceed the maximum span length (${reference.maxOHCableLengthsInMetres.service}m).`,
      };
    }
  }

  return warning;
};

export const checkCableHasGroundTypeOverrides = (c) => {
  let error = {};

  if (!c.groundTypeOverrides || c.groundTypeOverrides.length === 0) {
    error = {
      code: CODE_E101,
      level: alert.LEVEL_ERROR,
      link: c.id,
      description: "Cable does not have ground type overrides",
    };
  }

  return error;
};

export const checkCableLengthMatchesGroundTypeOverrides = (c) => {
  let error = {};

  const groundTypeOverridesLength = c.groundTypeOverrides
    .map((g) => g.length)
    .reduce((l1, l2) => parseFloat(l1 ?? 0) + parseFloat(l2 ?? 0));

  const overrideLength = c.overrideLength > 0 ? c.overrideLength : c.length;

  if (
    parseFloat(overrideLength).toFixed(2) !==
    parseFloat(groundTypeOverridesLength).toFixed(2)
  ) {
    error = {
      code: CODE_E102,
      level: alert.LEVEL_ERROR,
      link: c.id,
      description: "Cable length is not equal to ground type overrides",
    };
  }

  return error;
};

export const getWarningMessage = (errors, reference) => {
  let message =
    "The following warnings have been found. Press OK to continue with the assessment or Cancel to amend the network.";

  if (errors.length > 1) return message;

  switch (errors[0].code) {
    case CODE_W101:
      message = `Mains overhead conductor exceeds the maximum span length (${reference.maxOHCableLengthsInMetres.mains}m). Press OK to continue or Cancel to amend the network;`;
      break;
    case CODE_W102:
      message = `'ABC 35' overhead service conductor exceeds the maximum span length (${reference.maxOHCableLengthsInMetres.serviceABC35}m). Press OK to continue or Cancel to amend the network;`;
      break;
    case CODE_W103:
      message = `Single phase service overhead conductor exceeds the maximum span length (${reference.maxOHCableLengthsInMetres.singlePhaseService}m). Press OK to continue or Cancel to amend the network;`;
      break;
    case CODE_W104:
      message = `Service overhead conductor exceeds the maximum span length (${reference.maxOHCableLengthsInMetres.service}m). Press OK to continue or Cancel to amend the network;`;
      break;
    default:
      message = `${errors[0].description} Press OK to continue or Cancel to amend the network;`;
  }
  return message;
};
