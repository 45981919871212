import { useState, useContext, useEffect } from "react";
import { Input, InputGroup, ListGroupItem, Label, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import RadioBtnGrp from "./RadioBtnGrp";
import { v4 as uuid } from "uuid";
import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import SpinnerIcon from "./SpinnerIcon";
import ColorPalette from "./ColorPalette";
import {
  mapCableRouteBackToTransformer,
  getFaultMarker,
} from "../utils/impedanceFunctions";
import { DEFAULT_FAULT_COLOR } from "../constants/colors";
import { useSelector } from "react-redux";
import { checkReduxNetworkPopulated } from "../app/networkSlice";

const LOOP = "Loop";
const PHASE = "Phase";

const impedanceTypes = {
  name: "impedanceType",
  obj: [
    { id: "loop", value: LOOP, color: "light" },
    { id: "phase", value: PHASE, color: "light" },
  ],
};

const Detect = () => {
  const { dispatch, formState } = useContext(FormContext);
  const { toolState } = useContext(ToolContext);
  const { showResults, assessmentRunning, assessmentFailed } = toolState;
  const { detect, detectNetworks } = formState;

  const [impedanceType, setImpedanceType] = useState(LOOP);
  const [canLocateFaults, setCanLocateFaults] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));
  const networkChanged = useSelector((state) => state.network.present.networkChanged);
  const transformers = useSelector((state) => state.network.present.transformers);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const cables = useSelector((state) => state.network.present.cables);

  useEffect(() => {
    if (!detect.impedances || !detect.impedances?.length) {
      createDefaultImpedance();
    }
  }, []);

  useEffect(() => {
    if (successfulAssessment() && hasImpedanceInput()) {
      setCanLocateFaults(true);
    } else {
      setCanLocateFaults(false);
      clearResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentRunning, showResults, networkChanged, isReduxNetworkPopulated, detect, assessmentFailed]);

  const successfulAssessment = () => {
    return (
      isReduxNetworkPopulated &&
      !assessmentRunning &&
      showResults &&
      !assessmentFailed
    );
  };

  const toggleImpedanceType = (e) => {
    setImpedanceType(e.value);
  };

  const addImpedance = () => {
    let impedances = [];
    let impedanceId = uuid();
    if (!detect.impedances || !detect.impedances.length) {
      impedances = [
        {
          id: impedanceId,
          impedance: 0,
        },
      ];
    } else {
      impedances = [
        ...detect.impedances,
        {
          id: impedanceId,
          impedance: 0,
        },
      ];
    }

    updateImpedances(impedances);
  };

  const removeImpedance = (id) => {
    var impedances = detect.impedances.filter((x) => x.id !== id);
    updateImpedances(impedances);
  };

  const updateImpedance = (e, index) => {
    e.target.value = setImpedanceTo3DecimalPlaces(e);
    const impedance = detect.impedances[index];
    impedance.impedance = e.target.value;
    detect.impedances[index] = impedance;
    updateImpedances(detect.impedances);
  };

  const setImpedanceTo3DecimalPlaces = (e) => {
    var start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,3})?)/g.exec(val);
    const value = match[1] + match[2];
    e.target.value = value;
    if (val.length > 0) {
      e.target.value = Number(value).toFixed(3);
      e.target.setSelectionRange(start, start);
    }
    return e.target.value;
  };

  const updateImpedances = (value) => {
    dispatch({
      form: "detect",
      field: "impedances",
      value: value,
      type: "UPDATE_FIELD",
    });

    dispatch({
      obj: detect,
      type: "SAVE_NETWORK",
    });
  };

  const hasImpedanceInput = () => {
    return detect.impedances?.some((x) => parseFloat(x.impedance) > 0);
  };

  const locateFaults = () => {
    setShowErrorMsg(false);

    const _formState = { ...formState };

    detect.impedances
      .filter(
        (impedance) =>
          impedance.id !==
          _formState.detectNetworks.find(
            (detectNetwork) =>
              detectNetwork.impedance.impedance === impedance.impedance &&
              detectNetwork.impedanceType === impedanceType
          )?.impedance?.id
      )
      .forEach((impedance) => {
        let markers = [];
        let routes = [];

        cables.forEach((cable) => {
          let nearNode;
          let farNode;

          if (cable.nearNodeNumber === "1") {
            nearNode = transformers.find(
              (t) => t.nodeNumber === cable.nearNodeNumber
            );
          } else {
            nearNode = groupedConnections.find(
              (gc) => gc.nodeNumber === cable.nearNodeNumber
            );
          }

          if (cable.farNodeNumber === "1") {
            farNode = transformers.find(
              (t) => t.nodeNumber === cable.farNodeNumber
            );
          } else {
            farNode = groupedConnections.find(
              (gc) => gc.nodeNumber === cable.farNodeNumber
            );
          }

          if (nearNode && farNode) {
            const nearImpedance =
              impedanceType === LOOP
                ? nearNode?.loopImpedance
                  ? nearNode?.loopImpedance
                  : 0
                : nearNode?.phaseImpedance
                ? nearNode?.phaseImpedance
                : 0;

            const farImpedance =
              impedanceType === LOOP
                ? farNode?.loopImpedance
                  ? farNode?.loopImpedance
                  : 0
                : farNode?.phaseImpedance
                ? farNode?.phaseImpedance
                : 0;

            if (
              (parseFloat(impedance.impedance) <= parseFloat(nearImpedance) &&
                parseFloat(impedance.impedance) >= parseFloat(farImpedance)) ||
              (parseFloat(impedance.impedance) <= parseFloat(farImpedance) &&
                parseFloat(impedance.impedance) >= parseFloat(nearImpedance))
            ) {
              let asset = JSON.parse(JSON.stringify(cable));
              asset.impedance = impedance;
              asset.impedanceType = impedanceType;

              asset.nearNode = groupedConnections.find(
                (gc) => gc.nodeNumber === asset.nearNodeNumber
              );

              asset.farNode = groupedConnections.find(
                (gc) => gc.nodeNumber === asset.farNodeNumber
              );

              const route = mapCableRouteBackToTransformer(cables, asset);
              routes.push(route);
              markers.push(
                getFaultMarker(asset, route, transformers, groupedConnections, DEFAULT_FAULT_COLOR)
              );
            }
          }
        });

        if (markers.length === 0) {
          setShowErrorMsg(true);
          return;
        }
        const detectNetwork = {
          detectNetwork: {
            id: uuid(),
            cables: routes,
            show: true,
            faultMarkers: markers,
            impedance: impedance,
            color: DEFAULT_FAULT_COLOR,
            impedanceType: impedanceType,
          },
        };

        _formState.detectNetworks.push(
          JSON.parse(JSON.stringify(detectNetwork.detectNetwork))
        );
      });

    saveNetwork(_formState.detectNetworks);
  };

  const toggleFault = (id) => {
    const _formState = { ...formState };
    const index = _formState.detectNetworks.findIndex((x) => x.id === id);
    let detectNetwork = detectNetworks[index];
    detectNetwork.show = !detectNetwork.show;
    _formState.detectNetworks[index] = detectNetwork;
    saveNetwork(_formState.detectNetworks);
  };

  const handleSelectedColor = (color, selectedDetectNetwork) => {
    const _formState = { ...formState };
    const index = _formState.detectNetworks.findIndex(
      (x) => x.id === selectedDetectNetwork.id
    );
    let detectNetwork = detectNetworks[index];
    detectNetwork.color = color;
    detectNetwork.faultMarkers.map((marker) => (marker.color = color));
    _formState.detectNetworks[index] = detectNetwork;
    saveNetwork(_formState.detectNetworks);
  };

  const clearInputs = () => {
    clearImpedanceValues();
    createDefaultImpedance();
  };

  const createDefaultImpedance = () => {
    const impedanceId = uuid();
    const impedances = [
      {
        id: impedanceId,
        impedance: 0,
      },
    ];
    updateImpedances(impedances);
  };

  const clearImpedanceValues = () => {
    dispatch({
      form: "detect",
      obj: {},
      type: "CLEAR_NETWORK",
    });
  };

  const clearResults = () => {
    setShowErrorMsg(false);
    dispatch({
      form: "detectNetworks",
      obj: [],
      type: "CLEAR_NETWORK",
    });
  };

  const saveNetwork = (detectNetworks) => {
    dispatch({
      form: "detectNetworks",
      obj: detectNetworks,
      type: "REPLACE_STATE4",
    });

    dispatch({
      obj: detectNetworks,
      type: "SAVE_NETWORK",
    });
  };

  return (
    <>
      <ListGroupItem className="bg-dark text-uppercase">
        Locate Fault
      </ListGroupItem>
      <div>
        <InputGroup className="pt-3 pb-3 text-white">
          <div className="container">
            <div
              className="d-flex justify-content-start text-white"
              style={{
                marginLeft: 5,
                marginTop: 0,
                marginBottom: 20,
              }}
            >
              Please load a network and specific feeder (File tab), run
              assessment (button above), then locate faults here.
            </div>
            <div className="d-flex mt-1 pl-1 mr-1">
              <Label
                className="pt-1"
                for="impedanceValue"
                style={{
                  width: "100%",
                }}
              >
                Impedance Value(s) (mΩ)
              </Label>
              <Button
                size="sm"
                color="success"
                className="ml-1"
                style={{ width: "2rem" }}
                disabled={detect?.impedances?.length === 5}
                onClick={() => addImpedance()}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
            {detect?.impedances &&
              detect?.impedances.map((impedance, index) => (
                <div key={impedance.id} className="d-flex mt-2 ml-1 mr-1">
                  <Input
                    autoFocus={true}
                    id={`input-${impedance.id}`}
                    name="impedanceValue"
                    className="no-spinner"
                    placeholder="0.000"
                    style={{ height: 30, marginTop: -1, marginRight: 7 }}
                    onChange={(e) => updateImpedance(e, index)}
                  />
                  <Button
                    size="sm"
                    color="danger"
                    style={{ height: 30, width: 34, marginTop: -1 }}
                    onClick={() => removeImpedance(impedance.id)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                </div>
              ))}
            <div className="row pt-2 ml-1 mr-1 mt-2">
              <Label for="impedanceType">Impedance Type</Label>
              <RadioBtnGrp
                items={impedanceTypes}
                changeValue={toggleImpedanceType}
                selectedValue={impedanceType}
              />
            </div>
            <div className="d-flex justify-content-end mt-3 mr-1">
              <button
                type="button"
                id="clearFaults"
                width={62}
                className="align-items-center btn btn-primary btn-sm mr-2"
                disabled={
                  detectNetworks?.length === 0 &&
                  detect?.impedances?.length === 1 &&
                  detect?.impedances[0].impedance === 0
                }
                onClick={() => clearInputs()}
              >
                Clear
              </button>
              <button
                type="button"
                id="locateFaults"
                width={62}
                className="align-items-center btn btn-primary btn-sm"
                disabled={!canLocateFaults}
                onClick={() => locateFaults()}
              >
                Locate Faults
              </button>
            </div>
            <div
              className={`${
                successfulAssessment() ? "d-none" : "d-flex"
              } justify-content-start text-warning`}
              style={{
                marginRight: 5,
                marginTop: 10,
                marginBottom: -10,
              }}
            >
              <i
                className="icon-info-circle-solid"
                style={{ marginTop: 3, marginLeft: 5 }}
              />
              <span className="ml-1">
                You must run a successful assessment before locating faults
              </span>
            </div>
          </div>
        </InputGroup>
      </div>
      <div className="pt-1 pb-3 text-white">
        <ListGroupItem className="bg-dark text-uppercase">
          Show Results
        </ListGroupItem>
        <div
          className={`${
            showErrorMsg ? "d-flex" : "d-none"
          } justify-content-start text-warning`}
          style={{
            marginLeft: 25,
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          No faults found for one or more impedance value
        </div>
        {detectNetworks?.length > 0 &&
          detectNetworks.map((detectNetwork) => (
            <ListGroupItem
              tag="a"
              key={detectNetwork.id}
              id={detectNetwork.id}
              action
              className="d-flex justify-content-between align-items-center text-light"
            >
              <div className="d-flex" style={{ fontSize: ".875rem" }}>
                <div style={{ width: 50 }}>{detectNetwork.impedanceType}</div>
                <span>{":"}</span>
                <div style={{ marginLeft: 10 }}>
                  {detectNetwork.impedance.impedance}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center text-light">
                <ColorPalette
                  selectedNetwork={detectNetwork}
                  handleSelectedColor={handleSelectedColor}
                />
                <div
                  onClick={() => toggleFault(detectNetwork.id)}
                  style={{ marginLeft: 5 }}
                >
                  <SpinnerIcon flag={detectNetwork.show} showSpinner={false} />
                </div>
              </div>
            </ListGroupItem>
          ))}
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="clearResults"
          width={62}
          className="align-items-center btn btn-primary btn-sm"
          disabled={detectNetworks?.length === 0}
          style={{ marginRight: 18 }}
          onClick={() => clearResults()}
        >
          Clear Results
        </button>
      </div>
    </>
  );
};

export default Detect;
