import { FormGroup, Input, Label } from "reactstrap";
import * as statusConstants from "../../constants/status";

const StatusUpdate = ({ minimised, setMinimised, value, setValue }) => {

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label for="status" onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>Status
      </Label>
      {!minimised && (
        <FormGroup>
          <Input
            type="select"
            name="status"
            id="status"
            value={value}
            onChange={(e) => setValue(e.value ?? e.target.value)}
          >
            <option value={statusConstants.NEW}>New</option>
            <option value={statusConstants.EXISTING}>Existing</option>
            <option value={statusConstants.REPLACEMENT}>Replacement</option>
          </Input>
        </FormGroup>)}
    </div>
  );
};

export default StatusUpdate;
