import { useState, useContext } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { v4 as uuid } from "uuid";

import { loadNetworkJson } from "../services/apiService";
import { setConstraints } from "../services/constraintsService";
import { FormContext } from "../context/FormContext";
import { createNetworkFromAssessmentResponse } from "../model/viewModel/networkFactory";
import { ToolContext } from "../context/ToolContext";
import WarningModal from "./WarningModal";
import MultipleTransfomersModal from "./MultipleTransfomersModal";
import {
  resetNetworkToolState,
  resetNetworkElements,
  centreNetworkOnMap,
} from "../utils/networkFunctions";
import { useSelector, useDispatch } from "react-redux";
import {
  clearReduxNetwork,
  checkReduxNetworkPopulated,
  addWelders,
  addMotors,
  addPointOfConnections,
  addTransformers,
  addGroupedConnections,
  addNodes,
  addConnectionPoints,
  addCables,
  replaceWelders,
  replaceMotors,
  replacePointOfConnections,
  replaceTransformers,
  replaceGroupedConnections,
  replaceNodes,
  replaceConnectionPoints,
  replaceCables,
  setAsSaved,
  setHideMap,
} from "../app/networkSlice";
import { store } from "../app/store";
import {
  clearHistoryAction,
  endBatchAction,
  startBatchAction,
} from "../app/undoable";

const FeederFilter = ({ changeNetwork }) => {
  const [, setFaults] = useState([]);
  const { formState, dispatch } = useContext(FormContext);
  const { reference, clientSettings } = formState;
  const { toolState, setToolState } = useContext(ToolContext);
  const { filteredFeeders, feederList } = toolState;
  const { searchValue } = toolState;
  const { mainsOnly } = toolState;
  const { electricOfficeOnly, leafletMap, useAntData } = toolState;
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showAcceptToConnectMessage, setShowAcceptToConnectMessage] = useState(
    [],
  );
  const [showMultipleTransfomersModal, setShowMultipleTransfomersModal] =
    useState(false);
  const [transformerCount, setTransformerCount] = useState(0);
  const dispatchRedux = useDispatch();

  const isReduxNetworkPopulated = useSelector((state) =>
    checkReduxNetworkPopulated(state),
  );
  const networkChanged = useSelector(
    (state) => state.network.present.networkChanged,
  );

  const transformers = useSelector(
    (state) => state.network.present.transformers,
  );
  const hideMap = useSelector((state) => state.network.present.hideMap);

  const { accounts } = useMsal();
  const mainsOnlyToggle = () => {
    setToolState({
      mainsOnly: !toolState.mainsOnly,
    });
  };
  const electricOfficeOnlyToggle = () => {
    setToolState({
      electricOfficeOnly: !toolState.electricOfficeOnly,
    });
  };

  const loadNetwork = async () => {
    const currentSearchValue = getCurrentSearchValue();

    if (transformers.some((p) => p.substationId === currentSearchValue)) {
      setToolState({
        alert: {
          type: "error",
          className: "danger",
          messages: [{ description: "Substation has already been loaded" }],
        },
        isLoading: false,
      });
      return;
    }

    const _transformerCount = transformers.length;
    const multipleTransformersEnabled =
      clientSettings.Features.MultipleTransformersEnabled;

    if (_transformerCount && multipleTransformersEnabled) {
      setShowMultipleTransfomersModal(true);
      setTransformerCount(_transformerCount);
    } else {
      searchNetwork(false, currentSearchValue);
    }
  };

  const getCurrentSearchValue = () => {
    const substationSearch = document.getElementById("substation-search");
    const value = searchValue ?? substationSearch.value;
    return Array.isArray(value)
      ? value.length > 0
        ? value[0].key
        : ""
      : value;
  };

  const searchNetwork = async (add) => {
    const currentSearchValue = getCurrentSearchValue();

    if (!currentSearchValue) return;

    setShowMultipleTransfomersModal(false);
    if (add === undefined) return;

    setToolState({
      isLoading: "Loading network",
    });

    const account = accounts[0];
    const response = await loadNetworkJson(
      currentSearchValue,
      filteredFeeders,
      mainsOnly,
      electricOfficeOnly,
      account,
      useAntData,
    );

    if (response?.data?.network?.messages?.length) {
      setToolState({
        alert: {
          type: "error",
          className: "danger",
          messages: response.data.network.messages,
        },
        isLoading: false,
      });
      return;
    } else {
      const network = createNetworkFromAssessmentResponse(
        reference,
        response,
        currentSearchValue,
      );
      changeNetwork();
      const allNewGeometries = addOrReplaceNetworkState(network, add);
      replaceRagNetworksState(network);
      setToolState({
        isLoading: false,
        ringfenced: [],
        ringfencedFiltered: [],
        ringfencedTypes: [],
        disableBoundary: false,
        showResults: false,
        showNearbyAssets: { disabled: false, substations: false },
      });
      centreNetworkOnMap(network, allNewGeometries, leafletMap);
      if (response?.data?.acceptToConnect?.connections?.length > 0) {
        setShowAcceptToConnectMessage(
          response.data.acceptToConnect.connections.map(
            (p) => p.enquiryReference,
          ),
        );
      }
    }
  };

  const onCheckboxBtnClick = (selected) => {
    const index = filteredFeeders.indexOf(selected);
    if (index < 0) {
      filteredFeeders.push(selected);
    } else {
      filteredFeeders.splice(index, 1);
    }
    setToolState({
      filteredFeeders: [...filteredFeeders],
    });
  };

  const addOrReplaceNetworkState = (network, add) => {
    const _formState = { ...formState };
    const constraints = setConstraints(reference, _formState, network);
    const network2 = constraints.network;

    store.dispatch(clearHistoryAction());
    store.dispatch(startBatchAction());
    try {
      if (add) {
        dispatchRedux(addWelders(network2.welders));
        dispatchRedux(addMotors(network2.motors));
        dispatchRedux(addPointOfConnections(network2.pointOfConnections));
        dispatchRedux(addTransformers(network2.transformers));
        dispatchRedux(addGroupedConnections(network2.groupedConnections));
        dispatchRedux(addNodes(network2.nodes));
        dispatchRedux(addConnectionPoints(network2.connectionPoints));
        dispatchRedux(addCables(network2.cables));
        if (!hideMap) {
          dispatchRedux(setHideMap(network2.runtime?.hideMap === true));
        }
      } else {
        dispatchRedux(replaceWelders(network2.welders));
        dispatchRedux(replaceMotors(network2.motors));
        dispatchRedux(replacePointOfConnections(network2.pointOfConnections));
        dispatchRedux(replaceTransformers(network2.transformers));
        dispatchRedux(replaceGroupedConnections(network2.groupedConnections));
        dispatchRedux(replaceNodes(network2.nodes));
        dispatchRedux(replaceConnectionPoints(network2.connectionPoints));
        dispatchRedux(replaceCables(network2.cables));
        dispatchRedux(setHideMap(network2.runtime?.hideMap === true));
      }
      dispatchRedux(setAsSaved());
    } finally {
      store.dispatch(endBatchAction());
    }

    const allNewGeometries = (network2.welders ?? [])
      .map((p) => p.geometry)
      .concat((network2.motors ?? []).map((p) => p.geometry))
      .concat((network2.pointOfConnections ?? []).map((p) => p.geometry))
      .concat((network2.transformers ?? []).map((p) => p.geometry))
      .concat((network2.groupedConnections ?? []).map((p) => p.geometry))
      .concat((network2.nodes ?? []).map((p) => p.geometry))
      .concat((network2.connectionPoints ?? []).map((p) => p.geometry))
      .concat((network2.cables ?? []).map((p) => p.geometry));

    _formState.network = {};
    _formState.network.existing = false;
    _formState.network.loaded = true;

    setFaults(constraints.constraints);

    return allNewGeometries;
  };

  const replaceRagNetworksState = () => {
    const _formState = { ...formState };
    _formState.ragNetworks = [];
    dispatch({
      form: "ragNetworks",
      obj: _formState.ragNetworks,
      type: "REPLACE_STATE4",
    });
    if (networkChanged === false) {
      dispatch({
        obj: _formState.ragNetworks,
        type: "SAVE_NETWORK",
      });
    }
  };

  const clearNetwork = (canProcess) => {
    setShowWarningModal(false);

    if (canProcess !== true) {
      return;
    }

    const _toolState = toolState;

    dispatchRedux(clearReduxNetwork());
    resetNetworkToolState(_toolState);
    resetNetworkElements();

    setToolState(_toolState);
  };

  const {
    UseAcceptToConnectDataEnabled,
    ImportNetworkMainsOnlyOptionEnabled,
    ImportNetworkEOSearchOptionEnabled,
    CROWNEnabled,
  } = clientSettings.Features;

  return (
    <>
      {UseAcceptToConnectDataEnabled &&
        showAcceptToConnectMessage?.length > 0 && (
          <WarningModal
            action={() => setShowAcceptToConnectMessage([])}
            msg={`The following future connections have been accepted on substation ${getCurrentSearchValue()}:`}
            messages={showAcceptToConnectMessage.map((p, i) => {
              return { code: `AcceptToConnect_${i}`, description: p };
            })}
            hideYesButton={true}
            dismissLabel="OK"
          />
        )}
      {feederList && (
        <>
          {showWarningModal && (
            <WarningModal
              item={true}
              action={clearNetwork}
              msg={"Clicking OK will clear the network - are you sure?"}
              yesLabel="OK"
              dismissLabel="Cancel"
            />
          )}
          {showMultipleTransfomersModal && (
            <MultipleTransfomersModal
              action={searchNetwork}
              transformerCount={transformerCount}
            />
          )}
          {feederList.length > 0 && (
            <ListGroup flush>
              {feederList.map((feeder) => (
                <ListGroupItem
                  key={feeder.feederNumber}
                  tag="a"
                  className={`d-flex justify-content-between ${
                    filteredFeeders.includes(feeder.feederNumber)
                      ? "text-black"
                      : ""
                  }`}
                  onClick={() => onCheckboxBtnClick(feeder.feederNumber)}
                >
                  <span>
                    <span>Feeder {feeder.feederNumber}</span>
                    <span
                      className="badge badge-primary ml-3 pl-4 pr-4"
                      style={{ fontSize: "100%" }}
                    >
                      Known Customers (
                      {feeder.properties.reduce(
                        (a, c) => a + parseInt(c.value),
                        0,
                      )}
                      )
                    </span>
                  </span>
                  <span className="list-group-item-icon">
                    <i
                      className={`icon-${
                        filteredFeeders.includes(feeder.feederNumber)
                          ? "cross"
                          : "check"
                      }-solid`}
                    ></i>
                  </span>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}

          {feederList.length === 0 && (
            <>
              <div
                className={`d-flex justify-content-start text-warning`}
                style={{
                  marginLeft: 16,
                  marginTop: 5,
                  marginBottom: 7,
                }}
              >
                No feeders found, please click Load button to load transformer
                only
              </div>
            </>
          )}

          <ListGroupItem
            className={`list-group-item-btn-grp d-flex justify-content-start align-items-center pr-3 ${
              feederList.length > 0 ? "" : "border-0"
            }`}
          >
            {feederList.length > 0 && (
              <>
                {ImportNetworkMainsOnlyOptionEnabled && (
                  <div className="show-nearby-assets pr-2">
                    <span onClick={() => mainsOnlyToggle()}>
                      <i
                        className={`icon-${
                          toolState.mainsOnly ? "check" : "cross"
                        }-solid pr-2`}
                      ></i>
                      {`Mains only`}
                    </span>
                  </div>
                )}
                {ImportNetworkEOSearchOptionEnabled && (
                  <div className="show-nearby-assets">
                    <span onClick={() => electricOfficeOnlyToggle()}>
                      <i
                        className={`icon-${
                          toolState.electricOfficeOnly ? "check" : "cross"
                        }-solid pr-2`}
                      ></i>
                      {`Electric Office only`}
                    </span>
                  </div>
                )}
              </>
            )}
            <div className="d-flex justify-content-end flex-fill">
              {CROWNEnabled && (
                <button
                  style={{ width: 62 }}
                  color="primary"
                  className="align-items-center btn btn-primary btn-sm mr-2"
                  onClick={() => setShowWarningModal(true)}
                  disabled={!isReduxNetworkPopulated}
                >
                  Cancel
                </button>
              )}
              <button
                style={{ width: 62 }}
                color="primary"
                className="align-items-center btn btn-primary btn-sm"
                onClick={loadNetwork}
              >
                Load
              </button>
            </div>
          </ListGroupItem>
        </>
      )}
    </>
  );
};

export default FeederFilter;
