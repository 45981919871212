import { useContext, useEffect } from "react";

import { useMap } from "react-leaflet";
import { LatLng } from "leaflet";

import { ToolContext } from "../context/ToolContext";

const MapNavigate = () => {
  const { toolState } = useContext(ToolContext);
  const { navigateToLatLng } = toolState;
  const map = useMap();

  useEffect(() => {
    if (!navigateToLatLng) {
      return;
    }

    const latLng = new LatLng(navigateToLatLng.lat, navigateToLatLng.lng);
    map.setView(latLng);
  }, [navigateToLatLng]);

  return null;
};

export default MapNavigate;
