/*eslint-disable*/
/**
 * @licstart The following is the entire license notice for the
 * JavaScript code in this page
 *
 * Copyright 2023 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * @licend The above is the entire license notice for the
 * JavaScript code in this page
 */
!(function webpackUniversalModuleDefinition(e, t) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = t())
    : "function" == typeof define && define.amd
    ? define("pdfjs-dist/build/pdf.worker", [], t)
    : "object" == typeof exports
    ? (exports["pdfjs-dist/build/pdf.worker"] = t())
    : (e["pdfjs-dist/build/pdf.worker"] = e.pdfjsWorker = t());
})(globalThis, () =>
  (() => {
    "use strict";
    var e = [
        ,
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.WorkerTask = t.WorkerMessageHandler = void 0;
          var r = a(2),
            n = a(3),
            i = a(4),
            s = a(6),
            o = a(69),
            c = a(63),
            l = a(100),
            h = a(101),
            u = a(102);
          class WorkerTask {
            constructor(e) {
              this.name = e;
              this.terminated = !1;
              this._capability = (0, r.createPromiseCapability)();
            }
            get finished() {
              return this._capability.promise;
            }
            finish() {
              this._capability.resolve();
            }
            terminate() {
              this.terminated = !0;
            }
            ensureNotTerminated() {
              if (this.terminated)
                throw new Error("Worker task was terminated");
            }
          }
          t.WorkerTask = WorkerTask;
          class WorkerMessageHandler {
            static setup(e, t) {
              let a = !1;
              e.on("test", function (t) {
                if (!a) {
                  a = !0;
                  e.send("test", t instanceof Uint8Array);
                }
              });
              e.on("configure", function (e) {
                (0, r.setVerbosityLevel)(e.verbosity);
              });
              e.on("GetDocRequest", function (e) {
                return WorkerMessageHandler.createDocumentHandler(e, t);
              });
            }
            static createDocumentHandler(e, t) {
              let a,
                d = !1,
                f = null;
              const g = new Set(),
                p = (0, r.getVerbosityLevel)(),
                { docId: m, apiVersion: b } = e,
                y = "3.4.120";
              if (b !== y)
                throw new Error(
                  `The API version "${b}" does not match the Worker version "${y}".`
                );
              const w = [];
              for (const e in []) w.push(e);
              if (w.length)
                throw new Error(
                  "The `Array.prototype` contains unexpected enumerable properties: " +
                    w.join(", ") +
                    "; thus breaking e.g. `for...in` iteration of `Array`s."
                );
              if ("undefined" == typeof ReadableStream) {
                const e =
                  "The browser/environment lacks native support for critical functionality used by the PDF.js library (e.g. `ReadableStream`); ";
                if (l.isNodeJS)
                  throw new Error(e + "please use a `legacy`-build instead.");
                throw new Error(e + "please update to a supported browser.");
              }
              const S = m + "_worker";
              let x = new h.MessageHandler(S, m, t);
              function ensureNotTerminated() {
                if (d) throw new Error("Worker was terminated");
              }
              function startWorkerTask(e) {
                g.add(e);
              }
              function finishWorkerTask(e) {
                e.finish();
                g.delete(e);
              }
              async function loadDocument(e) {
                await a.ensureDoc("checkHeader");
                await a.ensureDoc("parseStartXRef");
                await a.ensureDoc("parse", [e]);
                await a.ensureDoc("checkFirstPage", [e]);
                await a.ensureDoc("checkLastPage", [e]);
                const t = await a.ensureDoc("isPureXfa");
                if (t) {
                  const e = new WorkerTask("loadXfaFonts");
                  startWorkerTask(e);
                  await Promise.all([
                    a
                      .loadXfaFonts(x, e)
                      .catch((e) => {})
                      .then(() => finishWorkerTask(e)),
                    a.loadXfaImages(),
                  ]);
                }
                const [r, n] = await Promise.all([
                  a.ensureDoc("numPages"),
                  a.ensureDoc("fingerprints"),
                ]);
                return {
                  numPages: r,
                  fingerprints: n,
                  htmlForXfa: t ? await a.ensureDoc("htmlForXfa") : null,
                };
              }
              function getPdfManager({
                data: e,
                password: t,
                disableAutoFetch: a,
                rangeChunkSize: i,
                length: o,
                docBaseUrl: c,
                enableXfa: l,
                evaluatorOptions: h,
              }) {
                const d = {
                    source: null,
                    disableAutoFetch: a,
                    docBaseUrl: c,
                    docId: m,
                    enableXfa: l,
                    evaluatorOptions: h,
                    handler: x,
                    length: o,
                    password: t,
                    rangeChunkSize: i,
                  },
                  g = (0, r.createPromiseCapability)();
                let p;
                if (e) {
                  try {
                    d.source = e;
                    p = new s.LocalPdfManager(d);
                    g.resolve(p);
                  } catch (e) {
                    g.reject(e);
                  }
                  return g.promise;
                }
                let b,
                  y = [];
                try {
                  b = new u.PDFWorkerStream(x);
                } catch (e) {
                  g.reject(e);
                  return g.promise;
                }
                const w = b.getFullReader();
                w.headersReady
                  .then(function () {
                    if (w.isRangeSupported) {
                      d.source = b;
                      d.length = w.contentLength;
                      d.disableAutoFetch =
                        d.disableAutoFetch || w.isStreamingSupported;
                      p = new s.NetworkPdfManager(d);
                      for (const e of y) p.sendProgressiveData(e);
                      y = [];
                      g.resolve(p);
                      f = null;
                    }
                  })
                  .catch(function (e) {
                    g.reject(e);
                    f = null;
                  });
                let S = 0;
                new Promise(function (e, t) {
                  const readChunk = function ({ value: e, done: a }) {
                    try {
                      ensureNotTerminated();
                      if (a) {
                        p ||
                          (function () {
                            const e = (0, n.arrayBuffersToBytes)(y);
                            o &&
                              e.length !== o &&
                              (0, r.warn)(
                                "reported HTTP length is different from actual"
                              );
                            try {
                              d.source = e;
                              p = new s.LocalPdfManager(d);
                              g.resolve(p);
                            } catch (e) {
                              g.reject(e);
                            }
                            y = [];
                          })();
                        f = null;
                        return;
                      }
                      S += e.byteLength;
                      w.isStreamingSupported ||
                        x.send("DocProgress", {
                          loaded: S,
                          total: Math.max(S, w.contentLength || 0),
                        });
                      p ? p.sendProgressiveData(e) : y.push(e);
                      w.read().then(readChunk, t);
                    } catch (e) {
                      t(e);
                    }
                  };
                  w.read().then(readChunk, t);
                }).catch(function (e) {
                  g.reject(e);
                  f = null;
                });
                f = function (e) {
                  b.cancelAllRequests(e);
                };
                return g.promise;
              }
              x.on("GetPage", function (e) {
                return a.getPage(e.pageIndex).then(function (e) {
                  return Promise.all([
                    a.ensure(e, "rotate"),
                    a.ensure(e, "ref"),
                    a.ensure(e, "userUnit"),
                    a.ensure(e, "view"),
                  ]).then(function ([e, t, a, r]) {
                    return { rotate: e, ref: t, userUnit: a, view: r };
                  });
                });
              });
              x.on("GetPageIndex", function (e) {
                const t = i.Ref.get(e.num, e.gen);
                return a.ensureCatalog("getPageIndex", [t]);
              });
              x.on("GetDestinations", function (e) {
                return a.ensureCatalog("destinations");
              });
              x.on("GetDestination", function (e) {
                return a.ensureCatalog("getDestination", [e.id]);
              });
              x.on("GetPageLabels", function (e) {
                return a.ensureCatalog("pageLabels");
              });
              x.on("GetPageLayout", function (e) {
                return a.ensureCatalog("pageLayout");
              });
              x.on("GetPageMode", function (e) {
                return a.ensureCatalog("pageMode");
              });
              x.on("GetViewerPreferences", function (e) {
                return a.ensureCatalog("viewerPreferences");
              });
              x.on("GetOpenAction", function (e) {
                return a.ensureCatalog("openAction");
              });
              x.on("GetAttachments", function (e) {
                return a.ensureCatalog("attachments");
              });
              x.on("GetJavaScript", function (e) {
                return a.ensureCatalog("javaScript");
              });
              x.on("GetDocJSActions", function (e) {
                return a.ensureCatalog("jsActions");
              });
              x.on("GetPageJSActions", function ({ pageIndex: e }) {
                return a.getPage(e).then(function (e) {
                  return a.ensure(e, "jsActions");
                });
              });
              x.on("GetOutline", function (e) {
                return a.ensureCatalog("documentOutline");
              });
              x.on("GetOptionalContentConfig", function (e) {
                return a.ensureCatalog("optionalContentConfig");
              });
              x.on("GetPermissions", function (e) {
                return a.ensureCatalog("permissions");
              });
              x.on("GetMetadata", function (e) {
                return Promise.all([
                  a.ensureDoc("documentInfo"),
                  a.ensureCatalog("metadata"),
                ]);
              });
              x.on("GetMarkInfo", function (e) {
                return a.ensureCatalog("markInfo");
              });
              x.on("GetData", function (e) {
                return a.requestLoadedStream().then(function (e) {
                  return e.bytes;
                });
              });
              x.on("GetAnnotations", function ({ pageIndex: e, intent: t }) {
                return a.getPage(e).then(function (a) {
                  const r = new WorkerTask(`GetAnnotations: page ${e}`);
                  startWorkerTask(r);
                  return a.getAnnotationsData(x, r, t).then(
                    (e) => {
                      finishWorkerTask(r);
                      return e;
                    },
                    (e) => {
                      finishWorkerTask(r);
                      throw e;
                    }
                  );
                });
              });
              x.on("GetFieldObjects", function (e) {
                return a.ensureDoc("fieldObjects");
              });
              x.on("HasJSActions", function (e) {
                return a.ensureDoc("hasJSActions");
              });
              x.on("GetCalculationOrderIds", function (e) {
                return a.ensureDoc("calculationOrderIds");
              });
              x.on(
                "SaveDocument",
                function ({
                  isPureXfa: e,
                  numPages: t,
                  annotationStorage: s,
                  filename: o,
                }) {
                  const l = [
                      a.requestLoadedStream(),
                      a.ensureCatalog("acroForm"),
                      a.ensureCatalog("acroFormRef"),
                      a.ensureDoc("xref"),
                      a.ensureDoc("startXRef"),
                    ],
                    h = e ? null : (0, n.getNewAnnotationsMap)(s);
                  if (h)
                    for (const [e, t] of h)
                      l.push(
                        a.getPage(e).then((a) => {
                          const r = new WorkerTask(`Save (editor): page ${e}`);
                          return a
                            .saveNewAnnotations(x, r, t)
                            .finally(function () {
                              finishWorkerTask(r);
                            });
                        })
                      );
                  if (e) l.push(a.serializeXfaData(s));
                  else
                    for (let e = 0; e < t; e++)
                      l.push(
                        a.getPage(e).then(function (t) {
                          const a = new WorkerTask(`Save: page ${e}`);
                          return t.save(x, a, s).finally(function () {
                            finishWorkerTask(a);
                          });
                        })
                      );
                  return Promise.all(l).then(function ([t, a, n, s, l, ...h]) {
                    let u = [],
                      d = null;
                    if (e) {
                      d = h[0];
                      if (!d) return t.bytes;
                    } else {
                      u = h.flat(2);
                      if (0 === u.length) return t.bytes;
                    }
                    const f =
                        n &&
                        a instanceof i.Dict &&
                        u.some((e) => e.needAppearances),
                      g = (a instanceof i.Dict && a.get("XFA")) || null;
                    let p = null,
                      m = !1;
                    if (Array.isArray(g)) {
                      for (let e = 0, t = g.length; e < t; e += 2)
                        if ("datasets" === g[e]) {
                          p = g[e + 1];
                          m = !0;
                        }
                      null === p && (p = s.getNewTemporaryRef());
                    } else g && (0, r.warn)("Unsupported XFA type.");
                    let b = Object.create(null);
                    if (s.trailer) {
                      const e = Object.create(null),
                        t = s.trailer.get("Info") || null;
                      t instanceof i.Dict &&
                        t.forEach((t, a) => {
                          "string" == typeof a &&
                            (e[t] = (0, r.stringToPDFString)(a));
                        });
                      b = {
                        rootRef: s.trailer.getRaw("Root") || null,
                        encryptRef: s.trailer.getRaw("Encrypt") || null,
                        newRef: s.getNewTemporaryRef(),
                        infoRef: s.trailer.getRaw("Info") || null,
                        info: e,
                        fileIds: s.trailer.get("ID") || null,
                        startXRef: l,
                        filename: o,
                      };
                    }
                    try {
                      return (0,
                      c.incrementalUpdate)({ originalData: t.bytes, xrefInfo: b, newRefs: u, xref: s, hasXfa: !!g, xfaDatasetsRef: p, hasXfaDatasetsEntry: m, needAppearances: f, acroFormRef: n, acroForm: a, xfaData: d });
                    } finally {
                      s.resetNewTemporaryRef();
                    }
                  });
                }
              );
              x.on("GetOperatorList", function (e, t) {
                const n = e.pageIndex;
                a.getPage(n).then(function (a) {
                  const i = new WorkerTask(`GetOperatorList: page ${n}`);
                  startWorkerTask(i);
                  const s = p >= r.VerbosityLevel.INFOS ? Date.now() : 0;
                  a.getOperatorList({
                    handler: x,
                    sink: t,
                    task: i,
                    intent: e.intent,
                    cacheKey: e.cacheKey,
                    annotationStorage: e.annotationStorage,
                  }).then(
                    function (e) {
                      finishWorkerTask(i);
                      s &&
                        (0, r.info)(
                          `page=${n + 1} - getOperatorList: time=${
                            Date.now() - s
                          }ms, len=${e.length}`
                        );
                      t.close();
                    },
                    function (e) {
                      finishWorkerTask(i);
                      i.terminated || t.error(e);
                    }
                  );
                });
              });
              x.on("GetTextContent", function (e, t) {
                const n = e.pageIndex;
                a.getPage(n).then(function (a) {
                  const i = new WorkerTask("GetTextContent: page " + n);
                  startWorkerTask(i);
                  const s = p >= r.VerbosityLevel.INFOS ? Date.now() : 0;
                  a.extractTextContent({
                    handler: x,
                    task: i,
                    sink: t,
                    includeMarkedContent: e.includeMarkedContent,
                    combineTextItems: e.combineTextItems,
                  }).then(
                    function () {
                      finishWorkerTask(i);
                      s &&
                        (0, r.info)(
                          `page=${n + 1} - getTextContent: time=` +
                            (Date.now() - s) +
                            "ms"
                        );
                      t.close();
                    },
                    function (e) {
                      finishWorkerTask(i);
                      i.terminated || t.error(e);
                    }
                  );
                });
              });
              x.on("GetStructTree", function (e) {
                return a.getPage(e.pageIndex).then(function (e) {
                  return a.ensure(e, "getStructTree");
                });
              });
              x.on("FontFallback", function (e) {
                return a.fontFallback(e.id, x);
              });
              x.on("Cleanup", function (e) {
                return a.cleanup(!0);
              });
              x.on("Terminate", function (e) {
                d = !0;
                const t = [];
                if (a) {
                  a.terminate(new r.AbortException("Worker was terminated."));
                  const e = a.cleanup();
                  t.push(e);
                  a = null;
                } else (0, o.clearGlobalCaches)();
                f && f(new r.AbortException("Worker was terminated."));
                for (const e of g) {
                  t.push(e.finished);
                  e.terminate();
                }
                return Promise.all(t).then(function () {
                  x.destroy();
                  x = null;
                });
              });
              x.on("Ready", function (t) {
                !(function setupDoc(e) {
                  function onSuccess(e) {
                    ensureNotTerminated();
                    x.send("GetDoc", { pdfInfo: e });
                  }
                  function onFailure(e) {
                    ensureNotTerminated();
                    if (e instanceof r.PasswordException) {
                      const t = new WorkerTask(
                        `PasswordException: response ${e.code}`
                      );
                      startWorkerTask(t);
                      x.sendWithPromise("PasswordRequest", e)
                        .then(function ({ password: e }) {
                          finishWorkerTask(t);
                          a.updatePassword(e);
                          pdfManagerReady();
                        })
                        .catch(function () {
                          finishWorkerTask(t);
                          x.send("DocException", e);
                        });
                    } else
                      e instanceof r.InvalidPDFException ||
                      e instanceof r.MissingPDFException ||
                      e instanceof r.UnexpectedResponseException ||
                      e instanceof r.UnknownErrorException
                        ? x.send("DocException", e)
                        : x.send(
                            "DocException",
                            new r.UnknownErrorException(e.message, e.toString())
                          );
                  }
                  function pdfManagerReady() {
                    ensureNotTerminated();
                    loadDocument(!1).then(onSuccess, function (e) {
                      ensureNotTerminated();
                      e instanceof n.XRefParseException
                        ? a.requestLoadedStream().then(function () {
                            ensureNotTerminated();
                            loadDocument(!0).then(onSuccess, onFailure);
                          })
                        : onFailure(e);
                    });
                  }
                  ensureNotTerminated();
                  getPdfManager(e)
                    .then(function (e) {
                      if (d) {
                        e.terminate(
                          new r.AbortException("Worker was terminated.")
                        );
                        throw new Error("Worker was terminated");
                      }
                      a = e;
                      a.requestLoadedStream(!0).then((e) => {
                        x.send("DataLoaded", { length: e.bytes.byteLength });
                      });
                    })
                    .then(pdfManagerReady, onFailure);
                })(e);
                e = null;
              });
              return S;
            }
            static initializeFromPort(e) {
              const t = new h.MessageHandler("worker", "main", e);
              WorkerMessageHandler.setup(t, e);
              t.send("ready", null);
            }
          }
          t.WorkerMessageHandler = WorkerMessageHandler;
          "undefined" == typeof window &&
            !l.isNodeJS &&
            "undefined" != typeof self &&
            (function isMessagePort(e) {
              return "function" == typeof e.postMessage && "onmessage" in e;
            })(self) &&
            WorkerMessageHandler.initializeFromPort(self);
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.VerbosityLevel =
            t.Util =
            t.UnknownErrorException =
            t.UnexpectedResponseException =
            t.UNSUPPORTED_FEATURES =
            t.TextRenderingMode =
            t.RenderingIntentFlag =
            t.PermissionFlag =
            t.PasswordResponses =
            t.PasswordException =
            t.PageActionEventType =
            t.OPS =
            t.MissingPDFException =
            t.LINE_FACTOR =
            t.LINE_DESCENT_FACTOR =
            t.InvalidPDFException =
            t.ImageKind =
            t.IDENTITY_MATRIX =
            t.FormatError =
            t.FeatureTest =
            t.FONT_IDENTITY_MATRIX =
            t.DocumentActionEventType =
            t.CMapCompressionType =
            t.BaseException =
            t.BASELINE_FACTOR =
            t.AnnotationType =
            t.AnnotationStateModelType =
            t.AnnotationReviewState =
            t.AnnotationReplyType =
            t.AnnotationMode =
            t.AnnotationMarkedState =
            t.AnnotationFlag =
            t.AnnotationFieldFlag =
            t.AnnotationEditorType =
            t.AnnotationEditorPrefix =
            t.AnnotationEditorParamsType =
            t.AnnotationBorderStyleType =
            t.AnnotationActionEventType =
            t.AbortException =
              void 0;
          t.assert = function assert(e, t) {
            e || unreachable(t);
          };
          t.bytesToString = function bytesToString(e) {
            ("object" == typeof e && null !== e && void 0 !== e.length) ||
              unreachable("Invalid argument for bytesToString");
            const t = e.length,
              a = 8192;
            if (t < a) return String.fromCharCode.apply(null, e);
            const r = [];
            for (let n = 0; n < t; n += a) {
              const i = Math.min(n + a, t),
                s = e.subarray(n, i);
              r.push(String.fromCharCode.apply(null, s));
            }
            return r.join("");
          };
          t.createPromiseCapability = function createPromiseCapability() {
            const e = Object.create(null);
            let t = !1;
            Object.defineProperty(e, "settled", { get: () => t });
            e.promise = new Promise(function (a, r) {
              e.resolve = function (e) {
                t = !0;
                a(e);
              };
              e.reject = function (e) {
                t = !0;
                r(e);
              };
            });
            return e;
          };
          t.createValidAbsoluteUrl = function createValidAbsoluteUrl(
            e,
            t = null,
            a = null
          ) {
            if (!e) return null;
            try {
              if (a && "string" == typeof e) {
                if (a.addDefaultProtocol && e.startsWith("www.")) {
                  const t = e.match(/\./g);
                  t && t.length >= 2 && (e = `http://${e}`);
                }
                if (a.tryConvertEncoding)
                  try {
                    e = stringToUTF8String(e);
                  } catch (e) {}
              }
              const r = t ? new URL(e, t) : new URL(e);
              if (
                (function _isValidProtocol(e) {
                  if (!e) return !1;
                  switch (e.protocol) {
                    case "http:":
                    case "https:":
                    case "ftp:":
                    case "mailto:":
                    case "tel:":
                      return !0;
                    default:
                      return !1;
                  }
                })(r)
              )
                return r;
            } catch (e) {}
            return null;
          };
          t.getModificationDate = function getModificationDate(e = new Date()) {
            return [
              e.getUTCFullYear().toString(),
              (e.getUTCMonth() + 1).toString().padStart(2, "0"),
              e.getUTCDate().toString().padStart(2, "0"),
              e.getUTCHours().toString().padStart(2, "0"),
              e.getUTCMinutes().toString().padStart(2, "0"),
              e.getUTCSeconds().toString().padStart(2, "0"),
            ].join("");
          };
          t.getVerbosityLevel = function getVerbosityLevel() {
            return r;
          };
          t.info = function info(e) {
            r >= a.INFOS && console.log(`Info: ${e}`);
          };
          t.isArrayBuffer = function isArrayBuffer(e) {
            return (
              "object" == typeof e && null !== e && void 0 !== e.byteLength
            );
          };
          t.isArrayEqual = function isArrayEqual(e, t) {
            if (e.length !== t.length) return !1;
            for (let a = 0, r = e.length; a < r; a++)
              if (e[a] !== t[a]) return !1;
            return !0;
          };
          t.objectFromMap = function objectFromMap(e) {
            const t = Object.create(null);
            for (const [a, r] of e) t[a] = r;
            return t;
          };
          t.objectSize = function objectSize(e) {
            return Object.keys(e).length;
          };
          t.setVerbosityLevel = function setVerbosityLevel(e) {
            Number.isInteger(e) && (r = e);
          };
          t.shadow = shadow;
          t.string32 = function string32(e) {
            return String.fromCharCode(
              (e >> 24) & 255,
              (e >> 16) & 255,
              (e >> 8) & 255,
              255 & e
            );
          };
          t.stringToBytes = stringToBytes;
          t.stringToPDFString = function stringToPDFString(e) {
            if (e[0] >= "ï") {
              let t;
              "þ" === e[0] && "ÿ" === e[1]
                ? (t = "utf-16be")
                : "ÿ" === e[0] && "þ" === e[1]
                ? (t = "utf-16le")
                : "ï" === e[0] && "»" === e[1] && "¿" === e[2] && (t = "utf-8");
              if (t)
                try {
                  const a = new TextDecoder(t, { fatal: !0 }),
                    r = stringToBytes(e);
                  return a.decode(r);
                } catch (e) {
                  warn(`stringToPDFString: "${e}".`);
                }
            }
            const t = [];
            for (let a = 0, r = e.length; a < r; a++) {
              const r = s[e.charCodeAt(a)];
              t.push(r ? String.fromCharCode(r) : e.charAt(a));
            }
            return t.join("");
          };
          t.stringToUTF8String = stringToUTF8String;
          t.unreachable = unreachable;
          t.utf8StringToString = function utf8StringToString(e) {
            return unescape(encodeURIComponent(e));
          };
          t.warn = warn;
          t.IDENTITY_MATRIX = [1, 0, 0, 1, 0, 0];
          t.FONT_IDENTITY_MATRIX = [0.001, 0, 0, 0.001, 0, 0];
          t.LINE_FACTOR = 1.35;
          t.LINE_DESCENT_FACTOR = 0.35;
          t.BASELINE_FACTOR = 0.25925925925925924;
          t.RenderingIntentFlag = {
            ANY: 1,
            DISPLAY: 2,
            PRINT: 4,
            SAVE: 8,
            ANNOTATIONS_FORMS: 16,
            ANNOTATIONS_STORAGE: 32,
            ANNOTATIONS_DISABLE: 64,
            OPLIST: 256,
          };
          t.AnnotationMode = {
            DISABLE: 0,
            ENABLE: 1,
            ENABLE_FORMS: 2,
            ENABLE_STORAGE: 3,
          };
          t.AnnotationEditorPrefix = "pdfjs_internal_editor_";
          t.AnnotationEditorType = {
            DISABLE: -1,
            NONE: 0,
            FREETEXT: 3,
            INK: 15,
          };
          t.AnnotationEditorParamsType = {
            FREETEXT_SIZE: 1,
            FREETEXT_COLOR: 2,
            FREETEXT_OPACITY: 3,
            INK_COLOR: 11,
            INK_THICKNESS: 12,
            INK_OPACITY: 13,
          };
          t.PermissionFlag = {
            PRINT: 4,
            MODIFY_CONTENTS: 8,
            COPY: 16,
            MODIFY_ANNOTATIONS: 32,
            FILL_INTERACTIVE_FORMS: 256,
            COPY_FOR_ACCESSIBILITY: 512,
            ASSEMBLE: 1024,
            PRINT_HIGH_QUALITY: 2048,
          };
          t.TextRenderingMode = {
            FILL: 0,
            STROKE: 1,
            FILL_STROKE: 2,
            INVISIBLE: 3,
            FILL_ADD_TO_PATH: 4,
            STROKE_ADD_TO_PATH: 5,
            FILL_STROKE_ADD_TO_PATH: 6,
            ADD_TO_PATH: 7,
            FILL_STROKE_MASK: 3,
            ADD_TO_PATH_FLAG: 4,
          };
          t.ImageKind = { GRAYSCALE_1BPP: 1, RGB_24BPP: 2, RGBA_32BPP: 3 };
          t.AnnotationType = {
            TEXT: 1,
            LINK: 2,
            FREETEXT: 3,
            LINE: 4,
            SQUARE: 5,
            CIRCLE: 6,
            POLYGON: 7,
            POLYLINE: 8,
            HIGHLIGHT: 9,
            UNDERLINE: 10,
            SQUIGGLY: 11,
            STRIKEOUT: 12,
            STAMP: 13,
            CARET: 14,
            INK: 15,
            POPUP: 16,
            FILEATTACHMENT: 17,
            SOUND: 18,
            MOVIE: 19,
            WIDGET: 20,
            SCREEN: 21,
            PRINTERMARK: 22,
            TRAPNET: 23,
            WATERMARK: 24,
            THREED: 25,
            REDACT: 26,
          };
          t.AnnotationStateModelType = { MARKED: "Marked", REVIEW: "Review" };
          t.AnnotationMarkedState = { MARKED: "Marked", UNMARKED: "Unmarked" };
          t.AnnotationReviewState = {
            ACCEPTED: "Accepted",
            REJECTED: "Rejected",
            CANCELLED: "Cancelled",
            COMPLETED: "Completed",
            NONE: "None",
          };
          t.AnnotationReplyType = { GROUP: "Group", REPLY: "R" };
          t.AnnotationFlag = {
            INVISIBLE: 1,
            HIDDEN: 2,
            PRINT: 4,
            NOZOOM: 8,
            NOROTATE: 16,
            NOVIEW: 32,
            READONLY: 64,
            LOCKED: 128,
            TOGGLENOVIEW: 256,
            LOCKEDCONTENTS: 512,
          };
          t.AnnotationFieldFlag = {
            READONLY: 1,
            REQUIRED: 2,
            NOEXPORT: 4,
            MULTILINE: 4096,
            PASSWORD: 8192,
            NOTOGGLETOOFF: 16384,
            RADIO: 32768,
            PUSHBUTTON: 65536,
            COMBO: 131072,
            EDIT: 262144,
            SORT: 524288,
            FILESELECT: 1048576,
            MULTISELECT: 2097152,
            DONOTSPELLCHECK: 4194304,
            DONOTSCROLL: 8388608,
            COMB: 16777216,
            RICHTEXT: 33554432,
            RADIOSINUNISON: 33554432,
            COMMITONSELCHANGE: 67108864,
          };
          t.AnnotationBorderStyleType = {
            SOLID: 1,
            DASHED: 2,
            BEVELED: 3,
            INSET: 4,
            UNDERLINE: 5,
          };
          t.AnnotationActionEventType = {
            E: "Mouse Enter",
            X: "Mouse Exit",
            D: "Mouse Down",
            U: "Mouse Up",
            Fo: "Focus",
            Bl: "Blur",
            PO: "PageOpen",
            PC: "PageClose",
            PV: "PageVisible",
            PI: "PageInvisible",
            K: "Keystroke",
            F: "Format",
            V: "Validate",
            C: "Calculate",
          };
          t.DocumentActionEventType = {
            WC: "WillClose",
            WS: "WillSave",
            DS: "DidSave",
            WP: "WillPrint",
            DP: "DidPrint",
          };
          t.PageActionEventType = { O: "PageOpen", C: "PageClose" };
          const a = { ERRORS: 0, WARNINGS: 1, INFOS: 5 };
          t.VerbosityLevel = a;
          t.CMapCompressionType = { NONE: 0, BINARY: 1 };
          t.OPS = {
            dependency: 1,
            setLineWidth: 2,
            setLineCap: 3,
            setLineJoin: 4,
            setMiterLimit: 5,
            setDash: 6,
            setRenderingIntent: 7,
            setFlatness: 8,
            setGState: 9,
            save: 10,
            restore: 11,
            transform: 12,
            moveTo: 13,
            lineTo: 14,
            curveTo: 15,
            curveTo2: 16,
            curveTo3: 17,
            closePath: 18,
            rectangle: 19,
            stroke: 20,
            closeStroke: 21,
            fill: 22,
            eoFill: 23,
            fillStroke: 24,
            eoFillStroke: 25,
            closeFillStroke: 26,
            closeEOFillStroke: 27,
            endPath: 28,
            clip: 29,
            eoClip: 30,
            beginText: 31,
            endText: 32,
            setCharSpacing: 33,
            setWordSpacing: 34,
            setHScale: 35,
            setLeading: 36,
            setFont: 37,
            setTextRenderingMode: 38,
            setTextRise: 39,
            moveText: 40,
            setLeadingMoveText: 41,
            setTextMatrix: 42,
            nextLine: 43,
            showText: 44,
            showSpacedText: 45,
            nextLineShowText: 46,
            nextLineSetSpacingShowText: 47,
            setCharWidth: 48,
            setCharWidthAndBounds: 49,
            setStrokeColorSpace: 50,
            setFillColorSpace: 51,
            setStrokeColor: 52,
            setStrokeColorN: 53,
            setFillColor: 54,
            setFillColorN: 55,
            setStrokeGray: 56,
            setFillGray: 57,
            setStrokeRGBColor: 58,
            setFillRGBColor: 59,
            setStrokeCMYKColor: 60,
            setFillCMYKColor: 61,
            shadingFill: 62,
            beginInlineImage: 63,
            beginImageData: 64,
            endInlineImage: 65,
            paintXObject: 66,
            markPoint: 67,
            markPointProps: 68,
            beginMarkedContent: 69,
            beginMarkedContentProps: 70,
            endMarkedContent: 71,
            beginCompat: 72,
            endCompat: 73,
            paintFormXObjectBegin: 74,
            paintFormXObjectEnd: 75,
            beginGroup: 76,
            endGroup: 77,
            beginAnnotation: 80,
            endAnnotation: 81,
            paintImageMaskXObject: 83,
            paintImageMaskXObjectGroup: 84,
            paintImageXObject: 85,
            paintInlineImageXObject: 86,
            paintInlineImageXObjectGroup: 87,
            paintImageXObjectRepeat: 88,
            paintImageMaskXObjectRepeat: 89,
            paintSolidColorImageMask: 90,
            constructPath: 91,
          };
          t.UNSUPPORTED_FEATURES = {
            forms: "forms",
            javaScript: "javaScript",
            signatures: "signatures",
            smask: "smask",
            shadingPattern: "shadingPattern",
            errorTilingPattern: "errorTilingPattern",
            errorExtGState: "errorExtGState",
            errorXObject: "errorXObject",
            errorFontLoadType3: "errorFontLoadType3",
            errorFontState: "errorFontState",
            errorFontMissing: "errorFontMissing",
            errorFontTranslate: "errorFontTranslate",
            errorColorSpace: "errorColorSpace",
            errorOperatorList: "errorOperatorList",
            errorFontToUnicode: "errorFontToUnicode",
            errorFontLoadNative: "errorFontLoadNative",
            errorFontBuildPath: "errorFontBuildPath",
            errorFontGetPath: "errorFontGetPath",
            errorMarkedContent: "errorMarkedContent",
            errorContentSubStream: "errorContentSubStream",
          };
          t.PasswordResponses = { NEED_PASSWORD: 1, INCORRECT_PASSWORD: 2 };
          let r = a.WARNINGS;
          function warn(e) {
            r >= a.WARNINGS && console.log(`Warning: ${e}`);
          }
          function unreachable(e) {
            throw new Error(e);
          }
          function shadow(e, t, a, r = !1) {
            Object.defineProperty(e, t, {
              value: a,
              enumerable: !r,
              configurable: !0,
              writable: !1,
            });
            return a;
          }
          const n = (function BaseExceptionClosure() {
            function BaseException(e, t) {
              this.constructor === BaseException &&
                unreachable("Cannot initialize BaseException.");
              this.message = e;
              this.name = t;
            }
            BaseException.prototype = new Error();
            BaseException.constructor = BaseException;
            return BaseException;
          })();
          t.BaseException = n;
          t.PasswordException = class PasswordException extends n {
            constructor(e, t) {
              super(e, "PasswordException");
              this.code = t;
            }
          };
          t.UnknownErrorException = class UnknownErrorException extends n {
            constructor(e, t) {
              super(e, "UnknownErrorException");
              this.details = t;
            }
          };
          t.InvalidPDFException = class InvalidPDFException extends n {
            constructor(e) {
              super(e, "InvalidPDFException");
            }
          };
          t.MissingPDFException = class MissingPDFException extends n {
            constructor(e) {
              super(e, "MissingPDFException");
            }
          };
          t.UnexpectedResponseException = class UnexpectedResponseException extends (
            n
          ) {
            constructor(e, t) {
              super(e, "UnexpectedResponseException");
              this.status = t;
            }
          };
          t.FormatError = class FormatError extends n {
            constructor(e) {
              super(e, "FormatError");
            }
          };
          t.AbortException = class AbortException extends n {
            constructor(e) {
              super(e, "AbortException");
            }
          };
          function stringToBytes(e) {
            "string" != typeof e &&
              unreachable("Invalid argument for stringToBytes");
            const t = e.length,
              a = new Uint8Array(t);
            for (let r = 0; r < t; ++r) a[r] = 255 & e.charCodeAt(r);
            return a;
          }
          t.FeatureTest = class FeatureTest {
            static get isLittleEndian() {
              return shadow(
                this,
                "isLittleEndian",
                (function isLittleEndian() {
                  const e = new Uint8Array(4);
                  e[0] = 1;
                  return 1 === new Uint32Array(e.buffer, 0, 1)[0];
                })()
              );
            }
            static get isEvalSupported() {
              return shadow(
                this,
                "isEvalSupported",
                (function isEvalSupported() {
                  try {
                    new Function("");
                    return !0;
                  } catch (e) {
                    return !1;
                  }
                })()
              );
            }
            static get isOffscreenCanvasSupported() {
              return shadow(
                this,
                "isOffscreenCanvasSupported",
                "undefined" != typeof OffscreenCanvas
              );
            }
            static get platform() {
              return "undefined" == typeof navigator
                ? shadow(this, "platform", { isWin: !1, isMac: !1 })
                : shadow(this, "platform", {
                    isWin: navigator.platform.includes("Win"),
                    isMac: navigator.platform.includes("Mac"),
                  });
            }
          };
          const i = [...Array(256).keys()].map((e) =>
            e.toString(16).padStart(2, "0")
          );
          class Util {
            static makeHexColor(e, t, a) {
              return `#${i[e]}${i[t]}${i[a]}`;
            }
            static scaleMinMax(e, t) {
              let a;
              if (e[0]) {
                if (e[0] < 0) {
                  a = t[0];
                  t[0] = t[1];
                  t[1] = a;
                }
                t[0] *= e[0];
                t[1] *= e[0];
                if (e[3] < 0) {
                  a = t[2];
                  t[2] = t[3];
                  t[3] = a;
                }
                t[2] *= e[3];
                t[3] *= e[3];
              } else {
                a = t[0];
                t[0] = t[2];
                t[2] = a;
                a = t[1];
                t[1] = t[3];
                t[3] = a;
                if (e[1] < 0) {
                  a = t[2];
                  t[2] = t[3];
                  t[3] = a;
                }
                t[2] *= e[1];
                t[3] *= e[1];
                if (e[2] < 0) {
                  a = t[0];
                  t[0] = t[1];
                  t[1] = a;
                }
                t[0] *= e[2];
                t[1] *= e[2];
              }
              t[0] += e[4];
              t[1] += e[4];
              t[2] += e[5];
              t[3] += e[5];
            }
            static transform(e, t) {
              return [
                e[0] * t[0] + e[2] * t[1],
                e[1] * t[0] + e[3] * t[1],
                e[0] * t[2] + e[2] * t[3],
                e[1] * t[2] + e[3] * t[3],
                e[0] * t[4] + e[2] * t[5] + e[4],
                e[1] * t[4] + e[3] * t[5] + e[5],
              ];
            }
            static applyTransform(e, t) {
              return [
                e[0] * t[0] + e[1] * t[2] + t[4],
                e[0] * t[1] + e[1] * t[3] + t[5],
              ];
            }
            static applyInverseTransform(e, t) {
              const a = t[0] * t[3] - t[1] * t[2];
              return [
                (e[0] * t[3] - e[1] * t[2] + t[2] * t[5] - t[4] * t[3]) / a,
                (-e[0] * t[1] + e[1] * t[0] + t[4] * t[1] - t[5] * t[0]) / a,
              ];
            }
            static getAxialAlignedBoundingBox(e, t) {
              const a = Util.applyTransform(e, t),
                r = Util.applyTransform(e.slice(2, 4), t),
                n = Util.applyTransform([e[0], e[3]], t),
                i = Util.applyTransform([e[2], e[1]], t);
              return [
                Math.min(a[0], r[0], n[0], i[0]),
                Math.min(a[1], r[1], n[1], i[1]),
                Math.max(a[0], r[0], n[0], i[0]),
                Math.max(a[1], r[1], n[1], i[1]),
              ];
            }
            static inverseTransform(e) {
              const t = e[0] * e[3] - e[1] * e[2];
              return [
                e[3] / t,
                -e[1] / t,
                -e[2] / t,
                e[0] / t,
                (e[2] * e[5] - e[4] * e[3]) / t,
                (e[4] * e[1] - e[5] * e[0]) / t,
              ];
            }
            static singularValueDecompose2dScale(e) {
              const t = [e[0], e[2], e[1], e[3]],
                a = e[0] * t[0] + e[1] * t[2],
                r = e[0] * t[1] + e[1] * t[3],
                n = e[2] * t[0] + e[3] * t[2],
                i = e[2] * t[1] + e[3] * t[3],
                s = (a + i) / 2,
                o = Math.sqrt((a + i) ** 2 - 4 * (a * i - n * r)) / 2,
                c = s + o || 1,
                l = s - o || 1;
              return [Math.sqrt(c), Math.sqrt(l)];
            }
            static normalizeRect(e) {
              const t = e.slice(0);
              if (e[0] > e[2]) {
                t[0] = e[2];
                t[2] = e[0];
              }
              if (e[1] > e[3]) {
                t[1] = e[3];
                t[3] = e[1];
              }
              return t;
            }
            static intersect(e, t) {
              const a = Math.max(Math.min(e[0], e[2]), Math.min(t[0], t[2])),
                r = Math.min(Math.max(e[0], e[2]), Math.max(t[0], t[2]));
              if (a > r) return null;
              const n = Math.max(Math.min(e[1], e[3]), Math.min(t[1], t[3])),
                i = Math.min(Math.max(e[1], e[3]), Math.max(t[1], t[3]));
              return n > i ? null : [a, n, r, i];
            }
            static bezierBoundingBox(e, t, a, r, n, i, s, o) {
              const c = [],
                l = [[], []];
              let h, u, d, f, g, p, m, b;
              for (let l = 0; l < 2; ++l) {
                if (0 === l) {
                  u = 6 * e - 12 * a + 6 * n;
                  h = -3 * e + 9 * a - 9 * n + 3 * s;
                  d = 3 * a - 3 * e;
                } else {
                  u = 6 * t - 12 * r + 6 * i;
                  h = -3 * t + 9 * r - 9 * i + 3 * o;
                  d = 3 * r - 3 * t;
                }
                if (Math.abs(h) < 1e-12) {
                  if (Math.abs(u) < 1e-12) continue;
                  f = -d / u;
                  0 < f && f < 1 && c.push(f);
                } else {
                  m = u * u - 4 * d * h;
                  b = Math.sqrt(m);
                  if (!(m < 0)) {
                    g = (-u + b) / (2 * h);
                    0 < g && g < 1 && c.push(g);
                    p = (-u - b) / (2 * h);
                    0 < p && p < 1 && c.push(p);
                  }
                }
              }
              let y,
                w = c.length;
              const S = w;
              for (; w--; ) {
                f = c[w];
                y = 1 - f;
                l[0][w] =
                  y * y * y * e +
                  3 * y * y * f * a +
                  3 * y * f * f * n +
                  f * f * f * s;
                l[1][w] =
                  y * y * y * t +
                  3 * y * y * f * r +
                  3 * y * f * f * i +
                  f * f * f * o;
              }
              l[0][S] = e;
              l[1][S] = t;
              l[0][S + 1] = s;
              l[1][S + 1] = o;
              l[0].length = l[1].length = S + 2;
              return [
                Math.min(...l[0]),
                Math.min(...l[1]),
                Math.max(...l[0]),
                Math.max(...l[1]),
              ];
            }
          }
          t.Util = Util;
          const s = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 728, 711, 710, 729, 733, 731, 730, 732, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8226, 8224,
            8225, 8230, 8212, 8211, 402, 8260, 8249, 8250, 8722, 8240, 8222,
            8220, 8221, 8216, 8217, 8218, 8482, 64257, 64258, 321, 338, 352,
            376, 381, 305, 322, 339, 353, 382, 0, 8364,
          ];
          function stringToUTF8String(e) {
            return decodeURIComponent(escape(e));
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XRefParseException =
            t.XRefEntryException =
            t.ParserEOFException =
            t.PDF_VERSION_REGEXP =
            t.MissingDataException =
              void 0;
          t.arrayBuffersToBytes = function arrayBuffersToBytes(e) {
            const t = e.length;
            if (0 === t) return new Uint8Array(0);
            if (1 === t) return new Uint8Array(e[0]);
            let a = 0;
            for (let r = 0; r < t; r++) a += e[r].byteLength;
            const r = new Uint8Array(a);
            let n = 0;
            for (let a = 0; a < t; a++) {
              const t = new Uint8Array(e[a]);
              r.set(t, n);
              n += t.byteLength;
            }
            return r;
          };
          t.collectActions = function collectActions(e, t, a) {
            const i = Object.create(null),
              s = getInheritableProperty({
                dict: t,
                key: "AA",
                stopWhenFound: !1,
              });
            if (s)
              for (let t = s.length - 1; t >= 0; t--) {
                const r = s[t];
                if (r instanceof n.Dict)
                  for (const t of r.getKeys()) {
                    const s = a[t];
                    if (!s) continue;
                    const o = [];
                    _collectJS(r.getRaw(t), e, o, new n.RefSet());
                    o.length > 0 && (i[s] = o);
                  }
              }
            if (t.has("A")) {
              const a = [];
              _collectJS(t.get("A"), e, a, new n.RefSet());
              a.length > 0 && (i.Action = a);
            }
            return (0, r.objectSize)(i) > 0 ? i : null;
          };
          t.encodeToXmlString = function encodeToXmlString(e) {
            const t = [];
            let a = 0;
            for (let r = 0, n = e.length; r < n; r++) {
              const n = e.codePointAt(r);
              if (32 <= n && n <= 126) {
                const i = o[n];
                if (i) {
                  a < r && t.push(e.substring(a, r));
                  t.push(i);
                  a = r + 1;
                }
              } else {
                a < r && t.push(e.substring(a, r));
                t.push(`&#x${n.toString(16).toUpperCase()};`);
                n > 55295 && (n < 57344 || n > 65533) && r++;
                a = r + 1;
              }
            }
            if (0 === t.length) return e;
            a < e.length && t.push(e.substring(a, e.length));
            return t.join("");
          };
          t.escapePDFName = function escapePDFName(e) {
            const t = [];
            let a = 0;
            for (let r = 0, n = e.length; r < n; r++) {
              const n = e.charCodeAt(r);
              if (
                n < 33 ||
                n > 126 ||
                35 === n ||
                40 === n ||
                41 === n ||
                60 === n ||
                62 === n ||
                91 === n ||
                93 === n ||
                123 === n ||
                125 === n ||
                47 === n ||
                37 === n
              ) {
                a < r && t.push(e.substring(a, r));
                t.push(`#${n.toString(16)}`);
                a = r + 1;
              }
            }
            if (0 === t.length) return e;
            a < e.length && t.push(e.substring(a, e.length));
            return t.join("");
          };
          t.escapeString = function escapeString(e) {
            return e.replace(/([()\\\n\r])/g, (e) =>
              "\n" === e ? "\\n" : "\r" === e ? "\\r" : `\\${e}`
            );
          };
          t.getArrayLookupTableFactory = function getArrayLookupTableFactory(
            e
          ) {
            let t;
            return function () {
              if (e) {
                let a = e();
                e = null;
                t = Object.create(null);
                for (let e = 0, r = a.length; e < r; e += 2) t[a[e]] = a[e + 1];
                a = null;
              }
              return t;
            };
          };
          t.getInheritableProperty = getInheritableProperty;
          t.getLookupTableFactory = function getLookupTableFactory(e) {
            let t;
            return function () {
              if (e) {
                t = Object.create(null);
                e(t);
                e = null;
              }
              return t;
            };
          };
          t.getNewAnnotationsMap = function getNewAnnotationsMap(e) {
            if (!e) return null;
            const t = new Map();
            for (const [a, n] of e) {
              if (!a.startsWith(r.AnnotationEditorPrefix)) continue;
              let e = t.get(n.pageIndex);
              if (!e) {
                e = [];
                t.set(n.pageIndex, e);
              }
              e.push(n);
            }
            return t.size > 0 ? t : null;
          };
          t.getRotationMatrix = function getRotationMatrix(e, t, a) {
            switch (e) {
              case 90:
                return [0, 1, -1, 0, t, 0];
              case 180:
                return [-1, 0, 0, -1, t, a];
              case 270:
                return [0, -1, 1, 0, 0, a];
              default:
                throw new Error("Invalid rotation");
            }
          };
          t.isAscii = function isAscii(e) {
            return /^[\x00-\x7F]*$/.test(e);
          };
          t.isWhiteSpace = function isWhiteSpace(e) {
            return 32 === e || 9 === e || 13 === e || 10 === e;
          };
          t.log2 = function log2(e) {
            if (e <= 0) return 0;
            return Math.ceil(Math.log2(e));
          };
          t.numberToString = function numberToString(e) {
            if (Number.isInteger(e)) return e.toString();
            const t = Math.round(100 * e);
            if (t % 100 == 0) return (t / 100).toString();
            if (t % 10 == 0) return e.toFixed(1);
            return e.toFixed(2);
          };
          t.parseXFAPath = function parseXFAPath(e) {
            const t = /(.+)\[(\d+)\]$/;
            return e.split(".").map((e) => {
              const a = e.match(t);
              return a
                ? { name: a[1], pos: parseInt(a[2], 10) }
                : { name: e, pos: 0 };
            });
          };
          t.readInt8 = function readInt8(e, t) {
            return (e[t] << 24) >> 24;
          };
          t.readUint16 = function readUint16(e, t) {
            return (e[t] << 8) | e[t + 1];
          };
          t.readUint32 = function readUint32(e, t) {
            return (
              ((e[t] << 24) | (e[t + 1] << 16) | (e[t + 2] << 8) | e[t + 3]) >>>
              0
            );
          };
          t.recoverJsURL = function recoverJsURL(e) {
            const t = new RegExp(
              "^\\s*(" +
                ["app.launchURL", "window.open", "xfa.host.gotoURL"]
                  .join("|")
                  .split(".")
                  .join("\\.") +
                ")\\((?:'|\")([^'\"]*)(?:'|\")(?:,\\s*(\\w+)\\)|\\))",
              "i"
            ).exec(e);
            if (t && t[2]) {
              const e = t[2];
              let a = !1;
              "true" === t[3] && "app.launchURL" === t[1] && (a = !0);
              return { url: e, newWindow: a };
            }
            return null;
          };
          t.stringToUTF16HexString = function stringToUTF16HexString(e) {
            const t = [];
            for (let a = 0, r = e.length; a < r; a++) {
              const r = e.charCodeAt(a);
              t.push(
                ((r >> 8) & 255).toString(16).padStart(2, "0"),
                (255 & r).toString(16).padStart(2, "0")
              );
            }
            return t.join("");
          };
          t.stringToUTF16String = function stringToUTF16String(e, t = !1) {
            const a = [];
            t && a.push("þÿ");
            for (let t = 0, r = e.length; t < r; t++) {
              const r = e.charCodeAt(t);
              a.push(
                String.fromCharCode((r >> 8) & 255),
                String.fromCharCode(255 & r)
              );
            }
            return a.join("");
          };
          t.toRomanNumerals = function toRomanNumerals(e, t = !1) {
            (0, r.assert)(
              Number.isInteger(e) && e > 0,
              "The number should be a positive integer."
            );
            const a = [];
            let n;
            for (; e >= 1e3; ) {
              e -= 1e3;
              a.push("M");
            }
            n = (e / 100) | 0;
            e %= 100;
            a.push(s[n]);
            n = (e / 10) | 0;
            e %= 10;
            a.push(s[10 + n]);
            a.push(s[20 + e]);
            const i = a.join("");
            return t ? i.toLowerCase() : i;
          };
          t.validateCSSFont = function validateCSSFont(e) {
            const t = new Set([
                "100",
                "200",
                "300",
                "400",
                "500",
                "600",
                "700",
                "800",
                "900",
                "1000",
                "normal",
                "bold",
                "bolder",
                "lighter",
              ]),
              { fontFamily: a, fontWeight: n, italicAngle: i } = e;
            if (/^".*"$/.test(a)) {
              if (/[^\\]"/.test(a.slice(1, a.length - 1))) {
                (0, r.warn)(
                  `XFA - FontFamily contains some unescaped ": ${a}.`
                );
                return !1;
              }
            } else if (/^'.*'$/.test(a)) {
              if (/[^\\]'/.test(a.slice(1, a.length - 1))) {
                (0, r.warn)(
                  `XFA - FontFamily contains some unescaped ': ${a}.`
                );
                return !1;
              }
            } else
              for (const e of a.split(/[ \t]+/))
                if (/^(\d|(-(\d|-)))/.test(e) || !/^[\w-\\]+$/.test(e)) {
                  (0, r.warn)(
                    `XFA - FontFamily contains some invalid <custom-ident>: ${a}.`
                  );
                  return !1;
                }
            const s = n ? n.toString() : "";
            e.fontWeight = t.has(s) ? s : "400";
            const o = parseFloat(i);
            e.italicAngle = isNaN(o) || o < -90 || o > 90 ? "14" : i.toString();
            return !0;
          };
          var r = a(2),
            n = a(4),
            i = a(5);
          t.PDF_VERSION_REGEXP = /^[1-9]\.\d$/;
          class MissingDataException extends r.BaseException {
            constructor(e, t) {
              super(`Missing data [${e}, ${t})`, "MissingDataException");
              this.begin = e;
              this.end = t;
            }
          }
          t.MissingDataException = MissingDataException;
          class ParserEOFException extends r.BaseException {
            constructor(e) {
              super(e, "ParserEOFException");
            }
          }
          t.ParserEOFException = ParserEOFException;
          class XRefEntryException extends r.BaseException {
            constructor(e) {
              super(e, "XRefEntryException");
            }
          }
          t.XRefEntryException = XRefEntryException;
          class XRefParseException extends r.BaseException {
            constructor(e) {
              super(e, "XRefParseException");
            }
          }
          t.XRefParseException = XRefParseException;
          function getInheritableProperty({
            dict: e,
            key: t,
            getArray: a = !1,
            stopWhenFound: r = !0,
          }) {
            let i;
            const s = new n.RefSet();
            for (; e instanceof n.Dict && (!e.objId || !s.has(e.objId)); ) {
              e.objId && s.put(e.objId);
              const n = a ? e.getArray(t) : e.get(t);
              if (void 0 !== n) {
                if (r) return n;
                i || (i = []);
                i.push(n);
              }
              e = e.get("Parent");
            }
            return i;
          }
          const s = [
            "",
            "C",
            "CC",
            "CCC",
            "CD",
            "D",
            "DC",
            "DCC",
            "DCCC",
            "CM",
            "",
            "X",
            "XX",
            "XXX",
            "XL",
            "L",
            "LX",
            "LXX",
            "LXXX",
            "XC",
            "",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
          ];
          function _collectJS(e, t, a, s) {
            if (!e) return;
            let o = null;
            if (e instanceof n.Ref) {
              if (s.has(e)) return;
              o = e;
              s.put(o);
              e = t.fetch(e);
            }
            if (Array.isArray(e)) for (const r of e) _collectJS(r, t, a, s);
            else if (e instanceof n.Dict) {
              if ((0, n.isName)(e.get("S"), "JavaScript")) {
                const t = e.get("JS");
                let n;
                t instanceof i.BaseStream
                  ? (n = t.getString())
                  : "string" == typeof t && (n = t);
                n = n && (0, r.stringToPDFString)(n).replace(/\u0000/g, "");
                n && a.push(n);
              }
              _collectJS(e.getRaw("Next"), t, a, s);
            }
            o && s.remove(o);
          }
          const o = {
            60: "&lt;",
            62: "&gt;",
            38: "&amp;",
            34: "&quot;",
            39: "&apos;",
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.RefSetCache =
            t.RefSet =
            t.Ref =
            t.Name =
            t.EOF =
            t.Dict =
            t.Cmd =
            t.CIRCULAR_REF =
              void 0;
          t.clearPrimitiveCaches = function clearPrimitiveCaches() {
            o._clearCache();
            s._clearCache();
            l._clearCache();
          };
          t.isCmd = function isCmd(e, t) {
            return e instanceof o && (void 0 === t || e.cmd === t);
          };
          t.isDict = function isDict(e, t) {
            return (
              e instanceof Dict && (void 0 === t || isName(e.get("Type"), t))
            );
          };
          t.isName = isName;
          t.isRefsEqual = function isRefsEqual(e, t) {
            return e.num === t.num && e.gen === t.gen;
          };
          var r = a(2);
          const n = Symbol("CIRCULAR_REF");
          t.CIRCULAR_REF = n;
          const i = Symbol("EOF");
          t.EOF = i;
          const s = (function NameClosure() {
            let e = Object.create(null);
            class Name {
              constructor(e) {
                this.name = e;
              }
              static get(t) {
                return e[t] || (e[t] = new Name(t));
              }
              static _clearCache() {
                e = Object.create(null);
              }
            }
            return Name;
          })();
          t.Name = s;
          const o = (function CmdClosure() {
            let e = Object.create(null);
            class Cmd {
              constructor(e) {
                this.cmd = e;
              }
              static get(t) {
                return e[t] || (e[t] = new Cmd(t));
              }
              static _clearCache() {
                e = Object.create(null);
              }
            }
            return Cmd;
          })();
          t.Cmd = o;
          const c = function nonSerializableClosure() {
            return c;
          };
          class Dict {
            constructor(e = null) {
              this._map = Object.create(null);
              this.xref = e;
              this.objId = null;
              this.suppressEncryption = !1;
              this.__nonSerializable__ = c;
            }
            assignXref(e) {
              this.xref = e;
            }
            get size() {
              return Object.keys(this._map).length;
            }
            get(e, t, a) {
              let r = this._map[e];
              if (void 0 === r && void 0 !== t) {
                r = this._map[t];
                void 0 === r && void 0 !== a && (r = this._map[a]);
              }
              return r instanceof l && this.xref
                ? this.xref.fetch(r, this.suppressEncryption)
                : r;
            }
            async getAsync(e, t, a) {
              let r = this._map[e];
              if (void 0 === r && void 0 !== t) {
                r = this._map[t];
                void 0 === r && void 0 !== a && (r = this._map[a]);
              }
              return r instanceof l && this.xref
                ? this.xref.fetchAsync(r, this.suppressEncryption)
                : r;
            }
            getArray(e, t, a) {
              let r = this._map[e];
              if (void 0 === r && void 0 !== t) {
                r = this._map[t];
                void 0 === r && void 0 !== a && (r = this._map[a]);
              }
              r instanceof l &&
                this.xref &&
                (r = this.xref.fetch(r, this.suppressEncryption));
              if (Array.isArray(r)) {
                r = r.slice();
                for (let e = 0, t = r.length; e < t; e++)
                  r[e] instanceof l &&
                    this.xref &&
                    (r[e] = this.xref.fetch(r[e], this.suppressEncryption));
              }
              return r;
            }
            getRaw(e) {
              return this._map[e];
            }
            getKeys() {
              return Object.keys(this._map);
            }
            getRawValues() {
              return Object.values(this._map);
            }
            set(e, t) {
              this._map[e] = t;
            }
            has(e) {
              return void 0 !== this._map[e];
            }
            forEach(e) {
              for (const t in this._map) e(t, this.get(t));
            }
            static get empty() {
              const e = new Dict(null);
              e.set = (e, t) => {
                (0, r.unreachable)(
                  "Should not call `set` on the empty dictionary."
                );
              };
              return (0, r.shadow)(this, "empty", e);
            }
            static merge({ xref: e, dictArray: t, mergeSubDicts: a = !1 }) {
              const r = new Dict(e),
                n = new Map();
              for (const e of t)
                if (e instanceof Dict)
                  for (const [t, r] of Object.entries(e._map)) {
                    let e = n.get(t);
                    if (void 0 === e) {
                      e = [];
                      n.set(t, e);
                    } else if (!(a && r instanceof Dict)) continue;
                    e.push(r);
                  }
              for (const [t, a] of n) {
                if (1 === a.length || !(a[0] instanceof Dict)) {
                  r._map[t] = a[0];
                  continue;
                }
                const n = new Dict(e);
                for (const e of a)
                  for (const [t, a] of Object.entries(e._map))
                    void 0 === n._map[t] && (n._map[t] = a);
                n.size > 0 && (r._map[t] = n);
              }
              n.clear();
              return r.size > 0 ? r : Dict.empty;
            }
          }
          t.Dict = Dict;
          const l = (function RefClosure() {
            let e = Object.create(null);
            class Ref {
              constructor(e, t) {
                this.num = e;
                this.gen = t;
              }
              toString() {
                return 0 === this.gen
                  ? `${this.num}R`
                  : `${this.num}R${this.gen}`;
              }
              static get(t, a) {
                const r = 0 === a ? `${t}R` : `${t}R${a}`;
                return e[r] || (e[r] = new Ref(t, a));
              }
              static _clearCache() {
                e = Object.create(null);
              }
            }
            return Ref;
          })();
          t.Ref = l;
          class RefSet {
            constructor(e = null) {
              this._set = new Set(e && e._set);
            }
            has(e) {
              return this._set.has(e.toString());
            }
            put(e) {
              this._set.add(e.toString());
            }
            remove(e) {
              this._set.delete(e.toString());
            }
            [Symbol.iterator]() {
              return this._set.values();
            }
            clear() {
              this._set.clear();
            }
          }
          t.RefSet = RefSet;
          class RefSetCache {
            constructor() {
              this._map = new Map();
            }
            get size() {
              return this._map.size;
            }
            get(e) {
              return this._map.get(e.toString());
            }
            has(e) {
              return this._map.has(e.toString());
            }
            put(e, t) {
              this._map.set(e.toString(), t);
            }
            putAlias(e, t) {
              this._map.set(e.toString(), this.get(t));
            }
            [Symbol.iterator]() {
              return this._map.values();
            }
            clear() {
              this._map.clear();
            }
          }
          t.RefSetCache = RefSetCache;
          function isName(e, t) {
            return e instanceof s && (void 0 === t || e.name === t);
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.BaseStream = void 0;
          var r = a(2);
          class BaseStream {
            constructor() {
              this.constructor === BaseStream &&
                (0, r.unreachable)("Cannot initialize BaseStream.");
            }
            get length() {
              (0, r.unreachable)("Abstract getter `length` accessed");
            }
            get isEmpty() {
              (0, r.unreachable)("Abstract getter `isEmpty` accessed");
            }
            get isDataLoaded() {
              return (0, r.shadow)(this, "isDataLoaded", !0);
            }
            getByte() {
              (0, r.unreachable)("Abstract method `getByte` called");
            }
            getBytes(e) {
              (0, r.unreachable)("Abstract method `getBytes` called");
            }
            peekByte() {
              const e = this.getByte();
              -1 !== e && this.pos--;
              return e;
            }
            peekBytes(e) {
              const t = this.getBytes(e);
              this.pos -= t.length;
              return t;
            }
            getUint16() {
              const e = this.getByte(),
                t = this.getByte();
              return -1 === e || -1 === t ? -1 : (e << 8) + t;
            }
            getInt32() {
              return (
                (this.getByte() << 24) +
                (this.getByte() << 16) +
                (this.getByte() << 8) +
                this.getByte()
              );
            }
            getByteRange(e, t) {
              (0, r.unreachable)("Abstract method `getByteRange` called");
            }
            getString(e) {
              return (0, r.bytesToString)(this.getBytes(e));
            }
            skip(e) {
              this.pos += e || 1;
            }
            reset() {
              (0, r.unreachable)("Abstract method `reset` called");
            }
            moveStart() {
              (0, r.unreachable)("Abstract method `moveStart` called");
            }
            makeSubStream(e, t, a = null) {
              (0, r.unreachable)("Abstract method `makeSubStream` called");
            }
            getBaseStreams() {
              return null;
            }
          }
          t.BaseStream = BaseStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.NetworkPdfManager = t.LocalPdfManager = void 0;
          var r = a(2),
            n = a(7),
            i = a(3),
            s = a(9),
            o = a(8);
          class BasePdfManager {
            constructor(e) {
              this.constructor === BasePdfManager &&
                (0, r.unreachable)("Cannot initialize BasePdfManager.");
              this._docBaseUrl = (function parseDocBaseUrl(e) {
                if (e) {
                  const t = (0, r.createValidAbsoluteUrl)(e);
                  if (t) return t.href;
                  (0, r.warn)(`Invalid absolute docBaseUrl: "${e}".`);
                }
                return null;
              })(e.docBaseUrl);
              this._docId = e.docId;
              this._password = e.password;
              this.enableXfa = e.enableXfa;
              this.evaluatorOptions = e.evaluatorOptions;
            }
            get docId() {
              return this._docId;
            }
            get password() {
              return this._password;
            }
            get docBaseUrl() {
              const e = this.pdfDocument.catalog;
              return (0, r.shadow)(
                this,
                "docBaseUrl",
                e.baseUrl || this._docBaseUrl
              );
            }
            ensureDoc(e, t) {
              return this.ensure(this.pdfDocument, e, t);
            }
            ensureXRef(e, t) {
              return this.ensure(this.pdfDocument.xref, e, t);
            }
            ensureCatalog(e, t) {
              return this.ensure(this.pdfDocument.catalog, e, t);
            }
            getPage(e) {
              return this.pdfDocument.getPage(e);
            }
            fontFallback(e, t) {
              return this.pdfDocument.fontFallback(e, t);
            }
            loadXfaFonts(e, t) {
              return this.pdfDocument.loadXfaFonts(e, t);
            }
            loadXfaImages() {
              return this.pdfDocument.loadXfaImages();
            }
            serializeXfaData(e) {
              return this.pdfDocument.serializeXfaData(e);
            }
            cleanup(e = !1) {
              return this.pdfDocument.cleanup(e);
            }
            async ensure(e, t, a) {
              (0, r.unreachable)("Abstract method `ensure` called");
            }
            requestRange(e, t) {
              (0, r.unreachable)("Abstract method `requestRange` called");
            }
            requestLoadedStream(e = !1) {
              (0, r.unreachable)(
                "Abstract method `requestLoadedStream` called"
              );
            }
            sendProgressiveData(e) {
              (0, r.unreachable)(
                "Abstract method `sendProgressiveData` called"
              );
            }
            updatePassword(e) {
              this._password = e;
            }
            terminate(e) {
              (0, r.unreachable)("Abstract method `terminate` called");
            }
          }
          t.LocalPdfManager = class LocalPdfManager extends BasePdfManager {
            constructor(e) {
              super(e);
              const t = new o.Stream(e.source);
              this.pdfDocument = new s.PDFDocument(this, t);
              this._loadedStreamPromise = Promise.resolve(t);
            }
            async ensure(e, t, a) {
              const r = e[t];
              return "function" == typeof r ? r.apply(e, a) : r;
            }
            requestRange(e, t) {
              return Promise.resolve();
            }
            requestLoadedStream(e = !1) {
              return this._loadedStreamPromise;
            }
            terminate(e) {}
          };
          t.NetworkPdfManager = class NetworkPdfManager extends BasePdfManager {
            constructor(e) {
              super(e);
              this.streamManager = new n.ChunkedStreamManager(e.source, {
                msgHandler: e.handler,
                length: e.length,
                disableAutoFetch: e.disableAutoFetch,
                rangeChunkSize: e.rangeChunkSize,
              });
              this.pdfDocument = new s.PDFDocument(
                this,
                this.streamManager.getStream()
              );
            }
            async ensure(e, t, a) {
              try {
                const r = e[t];
                return "function" == typeof r ? r.apply(e, a) : r;
              } catch (r) {
                if (!(r instanceof i.MissingDataException)) throw r;
                await this.requestRange(r.begin, r.end);
                return this.ensure(e, t, a);
              }
            }
            requestRange(e, t) {
              return this.streamManager.requestRange(e, t);
            }
            requestLoadedStream(e = !1) {
              return this.streamManager.requestAllChunks(e);
            }
            sendProgressiveData(e) {
              this.streamManager.onReceiveData({ chunk: e });
            }
            terminate(e) {
              this.streamManager.abort(e);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ChunkedStreamManager = t.ChunkedStream = void 0;
          var r = a(3),
            n = a(2),
            i = a(8);
          class ChunkedStream extends i.Stream {
            constructor(e, t, a) {
              super(new Uint8Array(e), 0, e, null);
              this.chunkSize = t;
              this._loadedChunks = new Set();
              this.numChunks = Math.ceil(e / t);
              this.manager = a;
              this.progressiveDataLength = 0;
              this.lastSuccessfulEnsureByteChunk = -1;
            }
            getMissingChunks() {
              const e = [];
              for (let t = 0, a = this.numChunks; t < a; ++t)
                this._loadedChunks.has(t) || e.push(t);
              return e;
            }
            get numChunksLoaded() {
              return this._loadedChunks.size;
            }
            get isDataLoaded() {
              return this.numChunksLoaded === this.numChunks;
            }
            onReceiveData(e, t) {
              const a = this.chunkSize;
              if (e % a != 0) throw new Error(`Bad begin offset: ${e}`);
              const r = e + t.byteLength;
              if (r % a != 0 && r !== this.bytes.length)
                throw new Error(`Bad end offset: ${r}`);
              this.bytes.set(new Uint8Array(t), e);
              const n = Math.floor(e / a),
                i = Math.floor((r - 1) / a) + 1;
              for (let e = n; e < i; ++e) this._loadedChunks.add(e);
            }
            onReceiveProgressiveData(e) {
              let t = this.progressiveDataLength;
              const a = Math.floor(t / this.chunkSize);
              this.bytes.set(new Uint8Array(e), t);
              t += e.byteLength;
              this.progressiveDataLength = t;
              const r =
                t >= this.end ? this.numChunks : Math.floor(t / this.chunkSize);
              for (let e = a; e < r; ++e) this._loadedChunks.add(e);
            }
            ensureByte(e) {
              if (e < this.progressiveDataLength) return;
              const t = Math.floor(e / this.chunkSize);
              if (
                !(t > this.numChunks) &&
                t !== this.lastSuccessfulEnsureByteChunk
              ) {
                if (!this._loadedChunks.has(t))
                  throw new r.MissingDataException(e, e + 1);
                this.lastSuccessfulEnsureByteChunk = t;
              }
            }
            ensureRange(e, t) {
              if (e >= t) return;
              if (t <= this.progressiveDataLength) return;
              const a = Math.floor(e / this.chunkSize);
              if (a > this.numChunks) return;
              const n = Math.min(
                Math.floor((t - 1) / this.chunkSize) + 1,
                this.numChunks
              );
              for (let i = a; i < n; ++i)
                if (!this._loadedChunks.has(i))
                  throw new r.MissingDataException(e, t);
            }
            nextEmptyChunk(e) {
              const t = this.numChunks;
              for (let a = 0; a < t; ++a) {
                const r = (e + a) % t;
                if (!this._loadedChunks.has(r)) return r;
              }
              return null;
            }
            hasChunk(e) {
              return this._loadedChunks.has(e);
            }
            getByte() {
              const e = this.pos;
              if (e >= this.end) return -1;
              e >= this.progressiveDataLength && this.ensureByte(e);
              return this.bytes[this.pos++];
            }
            getBytes(e) {
              const t = this.bytes,
                a = this.pos,
                r = this.end;
              if (!e) {
                r > this.progressiveDataLength && this.ensureRange(a, r);
                return t.subarray(a, r);
              }
              let n = a + e;
              n > r && (n = r);
              n > this.progressiveDataLength && this.ensureRange(a, n);
              this.pos = n;
              return t.subarray(a, n);
            }
            getByteRange(e, t) {
              e < 0 && (e = 0);
              t > this.end && (t = this.end);
              t > this.progressiveDataLength && this.ensureRange(e, t);
              return this.bytes.subarray(e, t);
            }
            makeSubStream(e, t, a = null) {
              t
                ? e + t > this.progressiveDataLength &&
                  this.ensureRange(e, e + t)
                : e >= this.progressiveDataLength && this.ensureByte(e);
              function ChunkedStreamSubstream() {}
              ChunkedStreamSubstream.prototype = Object.create(this);
              ChunkedStreamSubstream.prototype.getMissingChunks = function () {
                const e = this.chunkSize,
                  t = Math.floor(this.start / e),
                  a = Math.floor((this.end - 1) / e) + 1,
                  r = [];
                for (let e = t; e < a; ++e)
                  this._loadedChunks.has(e) || r.push(e);
                return r;
              };
              Object.defineProperty(
                ChunkedStreamSubstream.prototype,
                "isDataLoaded",
                {
                  get() {
                    return (
                      this.numChunksLoaded === this.numChunks ||
                      0 === this.getMissingChunks().length
                    );
                  },
                  configurable: !0,
                }
              );
              const r = new ChunkedStreamSubstream();
              r.pos = r.start = e;
              r.end = e + t || this.end;
              r.dict = a;
              return r;
            }
            getBaseStreams() {
              return [this];
            }
          }
          t.ChunkedStream = ChunkedStream;
          t.ChunkedStreamManager = class ChunkedStreamManager {
            constructor(e, t) {
              this.length = t.length;
              this.chunkSize = t.rangeChunkSize;
              this.stream = new ChunkedStream(
                this.length,
                this.chunkSize,
                this
              );
              this.pdfNetworkStream = e;
              this.disableAutoFetch = t.disableAutoFetch;
              this.msgHandler = t.msgHandler;
              this.currRequestId = 0;
              this._chunksNeededByRequest = new Map();
              this._requestsByChunk = new Map();
              this._promisesByRequest = new Map();
              this.progressiveDataLength = 0;
              this.aborted = !1;
              this._loadedStreamCapability = (0, n.createPromiseCapability)();
            }
            sendRequest(e, t) {
              const a = this.pdfNetworkStream.getRangeReader(e, t);
              a.isStreamingSupported ||
                (a.onProgress = this.onProgress.bind(this));
              let n = [],
                i = 0;
              return new Promise((e, t) => {
                const readChunk = ({ value: s, done: o }) => {
                  try {
                    if (o) {
                      const t = (0, r.arrayBuffersToBytes)(n);
                      n = null;
                      e(t);
                      return;
                    }
                    i += s.byteLength;
                    a.isStreamingSupported && this.onProgress({ loaded: i });
                    n.push(s);
                    a.read().then(readChunk, t);
                  } catch (e) {
                    t(e);
                  }
                };
                a.read().then(readChunk, t);
              }).then((t) => {
                this.aborted || this.onReceiveData({ chunk: t, begin: e });
              });
            }
            requestAllChunks(e = !1) {
              if (!e) {
                const e = this.stream.getMissingChunks();
                this._requestChunks(e);
              }
              return this._loadedStreamCapability.promise;
            }
            _requestChunks(e) {
              const t = this.currRequestId++,
                a = new Set();
              this._chunksNeededByRequest.set(t, a);
              for (const t of e) this.stream.hasChunk(t) || a.add(t);
              if (0 === a.size) return Promise.resolve();
              const r = (0, n.createPromiseCapability)();
              this._promisesByRequest.set(t, r);
              const i = [];
              for (const e of a) {
                let a = this._requestsByChunk.get(e);
                if (!a) {
                  a = [];
                  this._requestsByChunk.set(e, a);
                  i.push(e);
                }
                a.push(t);
              }
              if (i.length > 0) {
                const e = this.groupChunks(i);
                for (const t of e) {
                  const e = t.beginChunk * this.chunkSize,
                    a = Math.min(t.endChunk * this.chunkSize, this.length);
                  this.sendRequest(e, a).catch(r.reject);
                }
              }
              return r.promise.catch((e) => {
                if (!this.aborted) throw e;
              });
            }
            getStream() {
              return this.stream;
            }
            requestRange(e, t) {
              t = Math.min(t, this.length);
              const a = this.getBeginChunk(e),
                r = this.getEndChunk(t),
                n = [];
              for (let e = a; e < r; ++e) n.push(e);
              return this._requestChunks(n);
            }
            requestRanges(e = []) {
              const t = [];
              for (const a of e) {
                const e = this.getBeginChunk(a.begin),
                  r = this.getEndChunk(a.end);
                for (let a = e; a < r; ++a) t.includes(a) || t.push(a);
              }
              t.sort(function (e, t) {
                return e - t;
              });
              return this._requestChunks(t);
            }
            groupChunks(e) {
              const t = [];
              let a = -1,
                r = -1;
              for (let n = 0, i = e.length; n < i; ++n) {
                const i = e[n];
                a < 0 && (a = i);
                if (r >= 0 && r + 1 !== i) {
                  t.push({ beginChunk: a, endChunk: r + 1 });
                  a = i;
                }
                n + 1 === e.length &&
                  t.push({ beginChunk: a, endChunk: i + 1 });
                r = i;
              }
              return t;
            }
            onProgress(e) {
              this.msgHandler.send("DocProgress", {
                loaded: this.stream.numChunksLoaded * this.chunkSize + e.loaded,
                total: this.length,
              });
            }
            onReceiveData(e) {
              const t = e.chunk,
                a = void 0 === e.begin,
                r = a ? this.progressiveDataLength : e.begin,
                n = r + t.byteLength,
                i = Math.floor(r / this.chunkSize),
                s =
                  n < this.length
                    ? Math.floor(n / this.chunkSize)
                    : Math.ceil(n / this.chunkSize);
              if (a) {
                this.stream.onReceiveProgressiveData(t);
                this.progressiveDataLength = n;
              } else this.stream.onReceiveData(r, t);
              this.stream.isDataLoaded &&
                this._loadedStreamCapability.resolve(this.stream);
              const o = [];
              for (let e = i; e < s; ++e) {
                const t = this._requestsByChunk.get(e);
                if (t) {
                  this._requestsByChunk.delete(e);
                  for (const a of t) {
                    const t = this._chunksNeededByRequest.get(a);
                    t.has(e) && t.delete(e);
                    t.size > 0 || o.push(a);
                  }
                }
              }
              if (!this.disableAutoFetch && 0 === this._requestsByChunk.size) {
                let e;
                if (1 === this.stream.numChunksLoaded) {
                  const t = this.stream.numChunks - 1;
                  this.stream.hasChunk(t) || (e = t);
                } else e = this.stream.nextEmptyChunk(s);
                Number.isInteger(e) && this._requestChunks([e]);
              }
              for (const e of o) {
                const t = this._promisesByRequest.get(e);
                this._promisesByRequest.delete(e);
                t.resolve();
              }
              this.msgHandler.send("DocProgress", {
                loaded: this.stream.numChunksLoaded * this.chunkSize,
                total: this.length,
              });
            }
            onError(e) {
              this._loadedStreamCapability.reject(e);
            }
            getBeginChunk(e) {
              return Math.floor(e / this.chunkSize);
            }
            getEndChunk(e) {
              return Math.floor((e - 1) / this.chunkSize) + 1;
            }
            abort(e) {
              this.aborted = !0;
              this.pdfNetworkStream &&
                this.pdfNetworkStream.cancelAllRequests(e);
              for (const t of this._promisesByRequest.values()) t.reject(e);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.StringStream = t.Stream = t.NullStream = void 0;
          var r = a(5),
            n = a(2);
          class Stream extends r.BaseStream {
            constructor(e, t, a, r) {
              super();
              this.bytes = e instanceof Uint8Array ? e : new Uint8Array(e);
              this.start = t || 0;
              this.pos = this.start;
              this.end = t + a || this.bytes.length;
              this.dict = r;
            }
            get length() {
              return this.end - this.start;
            }
            get isEmpty() {
              return 0 === this.length;
            }
            getByte() {
              return this.pos >= this.end ? -1 : this.bytes[this.pos++];
            }
            getBytes(e) {
              const t = this.bytes,
                a = this.pos,
                r = this.end;
              if (!e) return t.subarray(a, r);
              let n = a + e;
              n > r && (n = r);
              this.pos = n;
              return t.subarray(a, n);
            }
            getByteRange(e, t) {
              e < 0 && (e = 0);
              t > this.end && (t = this.end);
              return this.bytes.subarray(e, t);
            }
            reset() {
              this.pos = this.start;
            }
            moveStart() {
              this.start = this.pos;
            }
            makeSubStream(e, t, a = null) {
              return new Stream(this.bytes.buffer, e, t, a);
            }
          }
          t.Stream = Stream;
          t.StringStream = class StringStream extends Stream {
            constructor(e) {
              super((0, n.stringToBytes)(e));
            }
          };
          t.NullStream = class NullStream extends Stream {
            constructor() {
              super(new Uint8Array(0));
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Page = t.PDFDocument = void 0;
          var r = a(10),
            n = a(2),
            i = a(3),
            s = a(4),
            o = a(49),
            c = a(5),
            l = a(65),
            h = a(67),
            u = a(69),
            d = a(98),
            f = a(15),
            g = a(8),
            p = a(73),
            m = a(60),
            b = a(13),
            y = a(17),
            w = a(72),
            S = a(63),
            x = a(74),
            C = a(99);
          const k = [0, 0, 612, 792];
          class Page {
            constructor({
              pdfManager: e,
              xref: t,
              pageIndex: a,
              pageDict: r,
              ref: n,
              globalIdFactory: i,
              fontCache: s,
              builtInCMapCache: o,
              standardFontDataCache: c,
              globalImageCache: l,
              nonBlendModesSet: h,
              xfaFactory: u,
            }) {
              this.pdfManager = e;
              this.pageIndex = a;
              this.pageDict = r;
              this.xref = t;
              this.ref = n;
              this.fontCache = s;
              this.builtInCMapCache = o;
              this.standardFontDataCache = c;
              this.globalImageCache = l;
              this.nonBlendModesSet = h;
              this.evaluatorOptions = e.evaluatorOptions;
              this.resourcesPromise = null;
              this.xfaFactory = u;
              const d = { obj: 0 };
              this._localIdFactory = class extends i {
                static createObjId() {
                  return `p${a}_${++d.obj}`;
                }
                static getPageObjId() {
                  return `page${n.toString()}`;
                }
              };
            }
            _getInheritableProperty(e, t = !1) {
              const a = (0, i.getInheritableProperty)({
                dict: this.pageDict,
                key: e,
                getArray: t,
                stopWhenFound: !1,
              });
              return Array.isArray(a)
                ? 1 !== a.length && a[0] instanceof s.Dict
                  ? s.Dict.merge({ xref: this.xref, dictArray: a })
                  : a[0]
                : a;
            }
            get content() {
              return this.pageDict.getArray("Contents");
            }
            get resources() {
              const e = this._getInheritableProperty("Resources");
              return (0, n.shadow)(
                this,
                "resources",
                e instanceof s.Dict ? e : s.Dict.empty
              );
            }
            _getBoundingBox(e) {
              if (this.xfaData) return this.xfaData.bbox;
              let t = this._getInheritableProperty(e, !0);
              if (Array.isArray(t) && 4 === t.length) {
                t = n.Util.normalizeRect(t);
                if (t[2] - t[0] > 0 && t[3] - t[1] > 0) return t;
                (0, n.warn)(`Empty, or invalid, /${e} entry.`);
              }
              return null;
            }
            get mediaBox() {
              return (0, n.shadow)(
                this,
                "mediaBox",
                this._getBoundingBox("MediaBox") || k
              );
            }
            get cropBox() {
              return (0, n.shadow)(
                this,
                "cropBox",
                this._getBoundingBox("CropBox") || this.mediaBox
              );
            }
            get userUnit() {
              let e = this.pageDict.get("UserUnit");
              ("number" != typeof e || e <= 0) && (e = 1);
              return (0, n.shadow)(this, "userUnit", e);
            }
            get view() {
              const { cropBox: e, mediaBox: t } = this;
              if (e !== t && !(0, n.isArrayEqual)(e, t)) {
                const a = n.Util.intersect(e, t);
                if (a && a[2] - a[0] > 0 && a[3] - a[1] > 0)
                  return (0, n.shadow)(this, "view", a);
                (0, n.warn)("Empty /CropBox and /MediaBox intersection.");
              }
              return (0, n.shadow)(this, "view", t);
            }
            get rotate() {
              let e = this._getInheritableProperty("Rotate") || 0;
              e % 90 != 0
                ? (e = 0)
                : e >= 360
                ? (e %= 360)
                : e < 0 && (e = ((e % 360) + 360) % 360);
              return (0, n.shadow)(this, "rotate", e);
            }
            _onSubStreamError(e, t, a) {
              if (!this.evaluatorOptions.ignoreErrors) throw t;
              e.send("UnsupportedFeature", {
                featureId: n.UNSUPPORTED_FEATURES.errorContentSubStream,
              });
              (0, n.warn)(
                `getContentStream - ignoring sub-stream (${a}): "${t}".`
              );
            }
            getContentStream(e) {
              return this.pdfManager
                .ensure(this, "content")
                .then((t) =>
                  t instanceof c.BaseStream
                    ? t
                    : Array.isArray(t)
                    ? new y.StreamsSequenceStream(
                        t,
                        this._onSubStreamError.bind(this, e)
                      )
                    : new g.NullStream()
                );
            }
            get xfaData() {
              return (0, n.shadow)(
                this,
                "xfaData",
                this.xfaFactory
                  ? { bbox: this.xfaFactory.getBoundingBox(this.pageIndex) }
                  : null
              );
            }
            async saveNewAnnotations(e, t, a) {
              if (this.xfaFactory)
                throw new Error("XFA: Cannot save new annotations.");
              const n = new b.PartialEvaluator({
                  xref: this.xref,
                  handler: e,
                  pageIndex: this.pageIndex,
                  idFactory: this._localIdFactory,
                  fontCache: this.fontCache,
                  builtInCMapCache: this.builtInCMapCache,
                  standardFontDataCache: this.standardFontDataCache,
                  globalImageCache: this.globalImageCache,
                  options: this.evaluatorOptions,
                }),
                i = this.pageDict,
                s = this.annotations.slice(),
                o = await r.AnnotationFactory.saveNewAnnotations(n, t, a);
              for (const { ref: e } of o.annotations) s.push(e);
              const c = i.get("Annots");
              i.set("Annots", s);
              const l = [];
              let h = null;
              this.xref.encrypt &&
                (h = this.xref.encrypt.createCipherTransform(
                  this.ref.num,
                  this.ref.gen
                ));
              (0, S.writeObject)(this.ref, i, l, h);
              c && i.set("Annots", c);
              const u = o.dependencies;
              u.push({ ref: this.ref, data: l.join("") }, ...o.annotations);
              return u;
            }
            save(e, t, a) {
              const r = new b.PartialEvaluator({
                xref: this.xref,
                handler: e,
                pageIndex: this.pageIndex,
                idFactory: this._localIdFactory,
                fontCache: this.fontCache,
                builtInCMapCache: this.builtInCMapCache,
                standardFontDataCache: this.standardFontDataCache,
                globalImageCache: this.globalImageCache,
                options: this.evaluatorOptions,
              });
              return this._parsedAnnotations.then(function (e) {
                const i = [];
                for (const s of e)
                  s.mustBePrinted(a) &&
                    i.push(
                      s.save(r, t, a).catch(function (e) {
                        (0,
                        n.warn)(`save - ignoring annotation data during "${t.name}" task: "${e}".`);
                        return null;
                      })
                    );
                return Promise.all(i).then(function (e) {
                  return e.filter((e) => !!e);
                });
              });
            }
            loadResources(e) {
              this.resourcesPromise ||
                (this.resourcesPromise = this.pdfManager.ensure(
                  this,
                  "resources"
                ));
              return this.resourcesPromise.then(() =>
                new p.ObjectLoader(this.resources, e, this.xref).load()
              );
            }
            getOperatorList({
              handler: e,
              sink: t,
              task: a,
              intent: s,
              cacheKey: o,
              annotationStorage: c = null,
            }) {
              const l = this.getContentStream(e),
                h = this.loadResources([
                  "ColorSpace",
                  "ExtGState",
                  "Font",
                  "Pattern",
                  "Properties",
                  "Shading",
                  "XObject",
                ]),
                u = new b.PartialEvaluator({
                  xref: this.xref,
                  handler: e,
                  pageIndex: this.pageIndex,
                  idFactory: this._localIdFactory,
                  fontCache: this.fontCache,
                  builtInCMapCache: this.builtInCMapCache,
                  standardFontDataCache: this.standardFontDataCache,
                  globalImageCache: this.globalImageCache,
                  options: this.evaluatorOptions,
                }),
                d = this.xfaFactory ? null : (0, i.getNewAnnotationsMap)(c);
              let f = Promise.resolve(null);
              if (d) {
                const e = d.get(this.pageIndex);
                e && (f = r.AnnotationFactory.printNewAnnotations(u, a, e));
              }
              const g = Promise.all([l, h]).then(([r]) => {
                const n = new m.OperatorList(s, t);
                e.send("StartRenderPage", {
                  transparency: u.hasBlendModes(
                    this.resources,
                    this.nonBlendModesSet
                  ),
                  pageIndex: this.pageIndex,
                  cacheKey: o,
                });
                return u
                  .getOperatorList({
                    stream: r,
                    task: a,
                    resources: this.resources,
                    operatorList: n,
                  })
                  .then(function () {
                    return n;
                  });
              });
              return Promise.all([g, this._parsedAnnotations, f]).then(
                function ([e, t, r]) {
                  r && (t = t.concat(r));
                  if (
                    0 === t.length ||
                    s & n.RenderingIntentFlag.ANNOTATIONS_DISABLE
                  ) {
                    e.flush(!0);
                    return { length: e.totalLength };
                  }
                  const i = !!(s & n.RenderingIntentFlag.ANNOTATIONS_FORMS),
                    o = !!(s & n.RenderingIntentFlag.ANY),
                    l = !!(s & n.RenderingIntentFlag.DISPLAY),
                    h = !!(s & n.RenderingIntentFlag.PRINT),
                    d = [];
                  for (const e of t)
                    (o ||
                      (l && e.mustBeViewed(c)) ||
                      (h && e.mustBePrinted(c))) &&
                      d.push(
                        e.getOperatorList(u, a, s, i, c).catch(function (e) {
                          (0,
                          n.warn)(`getOperatorList - ignoring annotation data during "${a.name}" task: "${e}".`);
                          return {
                            opList: null,
                            separateForm: !1,
                            separateCanvas: !1,
                          };
                        })
                      );
                  return Promise.all(d).then(function (t) {
                    let a = !1,
                      r = !1;
                    for (const {
                      opList: n,
                      separateForm: i,
                      separateCanvas: s,
                    } of t) {
                      e.addOpList(n);
                      i && (a = i);
                      s && (r = s);
                    }
                    e.flush(!0, { form: a, canvas: r });
                    return { length: e.totalLength };
                  });
                }
              );
            }
            extractTextContent({
              handler: e,
              task: t,
              includeMarkedContent: a,
              sink: r,
              combineTextItems: n,
            }) {
              const i = this.getContentStream(e),
                s = this.loadResources([
                  "ExtGState",
                  "Font",
                  "Properties",
                  "XObject",
                ]);
              return Promise.all([i, s]).then(([i]) =>
                new b.PartialEvaluator({
                  xref: this.xref,
                  handler: e,
                  pageIndex: this.pageIndex,
                  idFactory: this._localIdFactory,
                  fontCache: this.fontCache,
                  builtInCMapCache: this.builtInCMapCache,
                  standardFontDataCache: this.standardFontDataCache,
                  globalImageCache: this.globalImageCache,
                  options: this.evaluatorOptions,
                }).getTextContent({
                  stream: i,
                  task: t,
                  resources: this.resources,
                  includeMarkedContent: a,
                  combineTextItems: n,
                  sink: r,
                  viewBox: this.view,
                })
              );
            }
            async getStructTree() {
              const e = await this.pdfManager.ensureCatalog("structTreeRoot");
              if (!e) return null;
              return (
                await this.pdfManager.ensure(this, "_parseStructTree", [e])
              ).serializable;
            }
            _parseStructTree(e) {
              const t = new w.StructTreePage(e, this.pageDict);
              t.parse();
              return t;
            }
            async getAnnotationsData(e, t, a) {
              const r = await this._parsedAnnotations;
              if (0 === r.length) return [];
              const i = [],
                s = [];
              let o;
              const c = !!(a & n.RenderingIntentFlag.ANY),
                l = !!(a & n.RenderingIntentFlag.DISPLAY),
                h = !!(a & n.RenderingIntentFlag.PRINT);
              for (const a of r) {
                const r = c || (l && a.viewable);
                (r || (h && a.printable)) && s.push(a.data);
                if (a.hasTextContent && r) {
                  o ||
                    (o = new b.PartialEvaluator({
                      xref: this.xref,
                      handler: e,
                      pageIndex: this.pageIndex,
                      idFactory: this._localIdFactory,
                      fontCache: this.fontCache,
                      builtInCMapCache: this.builtInCMapCache,
                      standardFontDataCache: this.standardFontDataCache,
                      globalImageCache: this.globalImageCache,
                      options: this.evaluatorOptions,
                    }));
                  i.push(
                    a.extractTextContent(o, t, this.view).catch(function (e) {
                      (0,
                      n.warn)(`getAnnotationsData - ignoring textContent during "${t.name}" task: "${e}".`);
                    })
                  );
                }
              }
              await Promise.all(i);
              return s;
            }
            get annotations() {
              const e = this._getInheritableProperty("Annots");
              return (0, n.shadow)(
                this,
                "annotations",
                Array.isArray(e) ? e : []
              );
            }
            get _parsedAnnotations() {
              const e = this.pdfManager.ensure(this, "annotations").then(() => {
                const e = [];
                for (const t of this.annotations)
                  e.push(
                    r.AnnotationFactory.create(
                      this.xref,
                      t,
                      this.pdfManager,
                      this._localIdFactory,
                      !1
                    ).catch(function (e) {
                      (0, n.warn)(`_parsedAnnotations: "${e}".`);
                      return null;
                    })
                  );
                return Promise.all(e).then(function (e) {
                  if (0 === e.length) return e;
                  const t = [];
                  let a;
                  for (const n of e)
                    if (n)
                      if (n instanceof r.PopupAnnotation) {
                        a || (a = []);
                        a.push(n);
                      } else t.push(n);
                  a && t.push(...a);
                  return t;
                });
              });
              return (0, n.shadow)(this, "_parsedAnnotations", e);
            }
            get jsActions() {
              const e = (0, i.collectActions)(
                this.xref,
                this.pageDict,
                n.PageActionEventType
              );
              return (0, n.shadow)(this, "jsActions", e);
            }
          }
          t.Page = Page;
          const v = new Uint8Array([37, 80, 68, 70, 45]),
            F = new Uint8Array([115, 116, 97, 114, 116, 120, 114, 101, 102]),
            O = new Uint8Array([101, 110, 100, 111, 98, 106]),
            T = "\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0";
          function find(e, t, a = 1024, r = !1) {
            const n = t.length,
              i = e.peekBytes(a),
              s = i.length - n;
            if (s <= 0) return !1;
            if (r) {
              const a = n - 1;
              let r = i.length - 1;
              for (; r >= a; ) {
                let s = 0;
                for (; s < n && i[r - s] === t[a - s]; ) s++;
                if (s >= n) {
                  e.pos += r - a;
                  return !0;
                }
                r--;
              }
            } else {
              let a = 0;
              for (; a <= s; ) {
                let r = 0;
                for (; r < n && i[a + r] === t[r]; ) r++;
                if (r >= n) {
                  e.pos += a;
                  return !0;
                }
                a++;
              }
            }
            return !1;
          }
          t.PDFDocument = class PDFDocument {
            constructor(e, t) {
              if (t.length <= 0)
                throw new n.InvalidPDFException(
                  "The PDF file is empty, i.e. its size is zero bytes."
                );
              this.pdfManager = e;
              this.stream = t;
              this.xref = new C.XRef(t, e);
              this._pagePromises = new Map();
              this._version = null;
              const a = { font: 0 };
              this._globalIdFactory = class {
                static getDocId() {
                  return `g_${e.docId}`;
                }
                static createFontId() {
                  return "f" + ++a.font;
                }
                static createObjId() {
                  (0, n.unreachable)("Abstract method `createObjId` called.");
                }
                static getPageObjId() {
                  (0, n.unreachable)("Abstract method `getPageObjId` called.");
                }
              };
            }
            parse(e) {
              this.xref.parse(e);
              this.catalog = new h.Catalog(this.pdfManager, this.xref);
            }
            get linearization() {
              let e = null;
              try {
                e = f.Linearization.create(this.stream);
              } catch (e) {
                if (e instanceof i.MissingDataException) throw e;
                (0, n.info)(e);
              }
              return (0, n.shadow)(this, "linearization", e);
            }
            get startXRef() {
              const e = this.stream;
              let t = 0;
              if (this.linearization) {
                e.reset();
                find(e, O) && (t = e.pos + 6 - e.start);
              } else {
                const a = 1024,
                  r = F.length;
                let n = !1,
                  s = e.end;
                for (; !n && s > 0; ) {
                  s -= a - r;
                  s < 0 && (s = 0);
                  e.pos = s;
                  n = find(e, F, a, !0);
                }
                if (n) {
                  e.skip(9);
                  let a;
                  do {
                    a = e.getByte();
                  } while ((0, i.isWhiteSpace)(a));
                  let r = "";
                  for (; a >= 32 && a <= 57; ) {
                    r += String.fromCharCode(a);
                    a = e.getByte();
                  }
                  t = parseInt(r, 10);
                  isNaN(t) && (t = 0);
                }
              }
              return (0, n.shadow)(this, "startXRef", t);
            }
            checkHeader() {
              const e = this.stream;
              e.reset();
              if (!find(e, v)) return;
              e.moveStart();
              e.skip(v.length);
              let t,
                a = "";
              for (; (t = e.getByte()) > 32 && a.length < 7; )
                a += String.fromCharCode(t);
              i.PDF_VERSION_REGEXP.test(a)
                ? (this._version = a)
                : (0, n.warn)(`Invalid PDF header version: ${a}`);
            }
            parseStartXRef() {
              this.xref.setStartXRef(this.startXRef);
            }
            get numPages() {
              let e = 0;
              e = this.catalog.hasActualNumPages
                ? this.catalog.numPages
                : this.xfaFactory
                ? this.xfaFactory.getNumPages()
                : this.linearization
                ? this.linearization.numPages
                : this.catalog.numPages;
              return (0, n.shadow)(this, "numPages", e);
            }
            _hasOnlyDocumentSignatures(e, t = 0) {
              return (
                !!Array.isArray(e) &&
                e.every((e) => {
                  if (!((e = this.xref.fetchIfRef(e)) instanceof s.Dict))
                    return !1;
                  if (e.has("Kids")) {
                    if (++t > 10) {
                      (0, n.warn)(
                        "_hasOnlyDocumentSignatures: maximum recursion depth reached"
                      );
                      return !1;
                    }
                    return this._hasOnlyDocumentSignatures(e.get("Kids"), t);
                  }
                  const a = (0, s.isName)(e.get("FT"), "Sig"),
                    r = e.get("Rect"),
                    i = Array.isArray(r) && r.every((e) => 0 === e);
                  return a && i;
                })
              );
            }
            get _xfaStreams() {
              const e = this.catalog.acroForm;
              if (!e) return null;
              const t = e.get("XFA"),
                a = {
                  "xdp:xdp": "",
                  template: "",
                  datasets: "",
                  config: "",
                  connectionSet: "",
                  localeSet: "",
                  stylesheet: "",
                  "/xdp:xdp": "",
                };
              if (t instanceof c.BaseStream && !t.isEmpty) {
                a["xdp:xdp"] = t;
                return a;
              }
              if (!Array.isArray(t) || 0 === t.length) return null;
              for (let e = 0, r = t.length; e < r; e += 2) {
                let n;
                n = 0 === e ? "xdp:xdp" : e === r - 2 ? "/xdp:xdp" : t[e];
                if (!a.hasOwnProperty(n)) continue;
                const i = this.xref.fetchIfRef(t[e + 1]);
                i instanceof c.BaseStream && !i.isEmpty && (a[n] = i);
              }
              return a;
            }
            get xfaDatasets() {
              const e = this._xfaStreams;
              if (!e) return (0, n.shadow)(this, "xfaDatasets", null);
              for (const t of ["datasets", "xdp:xdp"]) {
                const a = e[t];
                if (a)
                  try {
                    const e = { [t]: (0, n.stringToUTF8String)(a.getString()) };
                    return (0, n.shadow)(
                      this,
                      "xfaDatasets",
                      new d.DatasetReader(e)
                    );
                  } catch (e) {
                    (0, n.warn)("XFA - Invalid utf-8 string.");
                    break;
                  }
              }
              return (0, n.shadow)(this, "xfaDatasets", null);
            }
            get xfaData() {
              const e = this._xfaStreams;
              if (!e) return null;
              const t = Object.create(null);
              for (const [a, r] of Object.entries(e))
                if (r)
                  try {
                    t[a] = (0, n.stringToUTF8String)(r.getString());
                  } catch (e) {
                    (0, n.warn)("XFA - Invalid utf-8 string.");
                    return null;
                  }
              return t;
            }
            get xfaFactory() {
              let e;
              this.pdfManager.enableXfa &&
                this.catalog.needsRendering &&
                this.formInfo.hasXfa &&
                !this.formInfo.hasAcroForm &&
                (e = this.xfaData);
              return (0, n.shadow)(
                this,
                "xfaFactory",
                e ? new x.XFAFactory(e) : null
              );
            }
            get isPureXfa() {
              return !!this.xfaFactory && this.xfaFactory.isValid();
            }
            get htmlForXfa() {
              return this.xfaFactory ? this.xfaFactory.getPages() : null;
            }
            async loadXfaImages() {
              const e = await this.pdfManager.ensureCatalog("xfaImages");
              if (!e) return;
              const t = e.getKeys(),
                a = new p.ObjectLoader(e, t, this.xref);
              await a.load();
              const r = new Map();
              for (const a of t) {
                const t = e.get(a);
                t instanceof c.BaseStream && r.set(a, t.getBytes());
              }
              this.xfaFactory.setImages(r);
            }
            async loadXfaFonts(e, t) {
              const a = await this.pdfManager.ensureCatalog("acroForm");
              if (!a) return;
              const r = await a.getAsync("DR");
              if (!(r instanceof s.Dict)) return;
              const c = new p.ObjectLoader(r, ["Font"], this.xref);
              await c.load();
              const l = r.get("Font");
              if (!(l instanceof s.Dict)) return;
              const h = Object.assign(
                Object.create(null),
                this.pdfManager.evaluatorOptions
              );
              h.useSystemFonts = !1;
              const u = new b.PartialEvaluator({
                  xref: this.xref,
                  handler: e,
                  pageIndex: -1,
                  idFactory: this._globalIdFactory,
                  fontCache: this.catalog.fontCache,
                  builtInCMapCache: this.catalog.builtInCMapCache,
                  standardFontDataCache: this.catalog.standardFontDataCache,
                  options: h,
                }),
                d = new m.OperatorList(),
                f = [],
                g = {
                  get font() {
                    return f.at(-1);
                  },
                  set font(e) {
                    f.push(e);
                  },
                  clone() {
                    return this;
                  },
                },
                y = new Map();
              l.forEach((e, t) => {
                y.set(e, t);
              });
              const w = [];
              for (const [e, a] of y) {
                const o = a.get("FontDescriptor");
                if (!(o instanceof s.Dict)) continue;
                let c = o.get("FontFamily");
                c = c.replace(/[ ]+(\d)/g, "$1");
                const l = {
                  fontFamily: c,
                  fontWeight: o.get("FontWeight"),
                  italicAngle: -o.get("ItalicAngle"),
                };
                (0, i.validateCSSFont)(l) &&
                  w.push(
                    u
                      .handleSetFont(
                        r,
                        [s.Name.get(e), 1],
                        null,
                        d,
                        t,
                        g,
                        null,
                        l
                      )
                      .catch(function (e) {
                        (0, n.warn)(`loadXfaFonts: "${e}".`);
                        return null;
                      })
                  );
              }
              await Promise.all(w);
              const S = this.xfaFactory.setFonts(f);
              if (!S) return;
              h.ignoreErrors = !0;
              w.length = 0;
              f.length = 0;
              const x = new Set();
              for (const e of S)
                (0, o.getXfaFontName)(`${e}-Regular`) || x.add(e);
              x.size && S.push("PdfJS-Fallback");
              for (const e of S)
                if (!x.has(e))
                  for (const a of [
                    { name: "Regular", fontWeight: 400, italicAngle: 0 },
                    { name: "Bold", fontWeight: 700, italicAngle: 0 },
                    { name: "Italic", fontWeight: 400, italicAngle: 12 },
                    { name: "BoldItalic", fontWeight: 700, italicAngle: 12 },
                  ]) {
                    const i = `${e}-${a.name}`,
                      c = (0, o.getXfaFontDict)(i);
                    w.push(
                      u
                        .handleSetFont(
                          r,
                          [s.Name.get(i), 1],
                          null,
                          d,
                          t,
                          g,
                          c,
                          {
                            fontFamily: e,
                            fontWeight: a.fontWeight,
                            italicAngle: a.italicAngle,
                          }
                        )
                        .catch(function (e) {
                          (0, n.warn)(`loadXfaFonts: "${e}".`);
                          return null;
                        })
                    );
                  }
              await Promise.all(w);
              this.xfaFactory.appendFonts(f, x);
            }
            async serializeXfaData(e) {
              return this.xfaFactory ? this.xfaFactory.serializeData(e) : null;
            }
            get version() {
              return this.catalog.version || this._version;
            }
            get formInfo() {
              const e = {
                  hasFields: !1,
                  hasAcroForm: !1,
                  hasXfa: !1,
                  hasSignatures: !1,
                },
                t = this.catalog.acroForm;
              if (!t) return (0, n.shadow)(this, "formInfo", e);
              try {
                const a = t.get("Fields"),
                  r = Array.isArray(a) && a.length > 0;
                e.hasFields = r;
                const n = t.get("XFA");
                e.hasXfa =
                  (Array.isArray(n) && n.length > 0) ||
                  (n instanceof c.BaseStream && !n.isEmpty);
                const i = !!(1 & t.get("SigFlags")),
                  s = i && this._hasOnlyDocumentSignatures(a);
                e.hasAcroForm = r && !s;
                e.hasSignatures = i;
              } catch (e) {
                if (e instanceof i.MissingDataException) throw e;
                (0, n.warn)(`Cannot fetch form information: "${e}".`);
              }
              return (0, n.shadow)(this, "formInfo", e);
            }
            get documentInfo() {
              const e = {
                PDFFormatVersion: this.version,
                Language: this.catalog.lang,
                EncryptFilterName: this.xref.encrypt
                  ? this.xref.encrypt.filterName
                  : null,
                IsLinearized: !!this.linearization,
                IsAcroFormPresent: this.formInfo.hasAcroForm,
                IsXFAPresent: this.formInfo.hasXfa,
                IsCollectionPresent: !!this.catalog.collection,
                IsSignaturesPresent: this.formInfo.hasSignatures,
              };
              let t;
              try {
                t = this.xref.trailer.get("Info");
              } catch (e) {
                if (e instanceof i.MissingDataException) throw e;
                (0, n.info)("The document information dictionary is invalid.");
              }
              if (!(t instanceof s.Dict))
                return (0, n.shadow)(this, "documentInfo", e);
              for (const a of t.getKeys()) {
                const r = t.get(a);
                switch (a) {
                  case "Title":
                  case "Author":
                  case "Subject":
                  case "Keywords":
                  case "Creator":
                  case "Producer":
                  case "CreationDate":
                  case "ModDate":
                    if ("string" == typeof r) {
                      e[a] = (0, n.stringToPDFString)(r);
                      continue;
                    }
                    break;
                  case "Trapped":
                    if (r instanceof s.Name) {
                      e[a] = r;
                      continue;
                    }
                    break;
                  default:
                    let t;
                    switch (typeof r) {
                      case "string":
                        t = (0, n.stringToPDFString)(r);
                        break;
                      case "number":
                      case "boolean":
                        t = r;
                        break;
                      default:
                        r instanceof s.Name && (t = r);
                    }
                    if (void 0 === t) {
                      (0, n.warn)(
                        `Bad value, for custom key "${a}", in Info: ${r}.`
                      );
                      continue;
                    }
                    e.Custom || (e.Custom = Object.create(null));
                    e.Custom[a] = t;
                    continue;
                }
                (0, n.warn)(`Bad value, for key "${a}", in Info: ${r}.`);
              }
              return (0, n.shadow)(this, "documentInfo", e);
            }
            get fingerprints() {
              function validate(e) {
                return "string" == typeof e && e.length > 0 && e !== T;
              }
              function hexString(e) {
                const t = [];
                for (const a of e) {
                  const e = a.toString(16);
                  t.push(e.padStart(2, "0"));
                }
                return t.join("");
              }
              const e = this.xref.trailer.get("ID");
              let t, a;
              if (Array.isArray(e) && validate(e[0])) {
                t = (0, n.stringToBytes)(e[0]);
                e[1] !== e[0] &&
                  validate(e[1]) &&
                  (a = (0, n.stringToBytes)(e[1]));
              } else
                t = (0, l.calculateMD5)(
                  this.stream.getByteRange(0, 1024),
                  0,
                  1024
                );
              return (0, n.shadow)(this, "fingerprints", [
                hexString(t),
                a ? hexString(a) : null,
              ]);
            }
            async _getLinearizationPage(e) {
              const { catalog: t, linearization: a, xref: r } = this,
                i = s.Ref.get(a.objectNumberFirst, 0);
              try {
                const e = await r.fetchAsync(i);
                if (e instanceof s.Dict) {
                  let a = e.getRaw("Type");
                  a instanceof s.Ref && (a = await r.fetchAsync(a));
                  if (
                    (0, s.isName)(a, "Page") ||
                    (!e.has("Type") && !e.has("Kids"))
                  ) {
                    t.pageKidsCountCache.has(i) ||
                      t.pageKidsCountCache.put(i, 1);
                    t.pageIndexCache.has(i) || t.pageIndexCache.put(i, 0);
                    return [e, i];
                  }
                }
                throw new n.FormatError(
                  "The Linearization dictionary doesn't point to a valid Page dictionary."
                );
              } catch (a) {
                (0, n.warn)(`_getLinearizationPage: "${a.message}".`);
                return t.getPageDict(e);
              }
            }
            getPage(e) {
              const t = this._pagePromises.get(e);
              if (t) return t;
              const { catalog: a, linearization: r, xfaFactory: n } = this;
              let i;
              i = n
                ? Promise.resolve([s.Dict.empty, null])
                : r && r.pageFirst === e
                ? this._getLinearizationPage(e)
                : a.getPageDict(e);
              i = i.then(
                ([t, r]) =>
                  new Page({
                    pdfManager: this.pdfManager,
                    xref: this.xref,
                    pageIndex: e,
                    pageDict: t,
                    ref: r,
                    globalIdFactory: this._globalIdFactory,
                    fontCache: a.fontCache,
                    builtInCMapCache: a.builtInCMapCache,
                    standardFontDataCache: a.standardFontDataCache,
                    globalImageCache: a.globalImageCache,
                    nonBlendModesSet: a.nonBlendModesSet,
                    xfaFactory: n,
                  })
              );
              this._pagePromises.set(e, i);
              return i;
            }
            async checkFirstPage(e = !1) {
              if (!e)
                try {
                  await this.getPage(0);
                } catch (e) {
                  if (e instanceof i.XRefEntryException) {
                    this._pagePromises.delete(0);
                    await this.cleanup();
                    throw new i.XRefParseException();
                  }
                }
            }
            async checkLastPage(e = !1) {
              const { catalog: t, pdfManager: a } = this;
              t.setActualNumPages();
              let r;
              try {
                await Promise.all([
                  a.ensureDoc("xfaFactory"),
                  a.ensureDoc("linearization"),
                  a.ensureCatalog("numPages"),
                ]);
                if (this.xfaFactory) return;
                r = this.linearization
                  ? this.linearization.numPages
                  : t.numPages;
                if (!Number.isInteger(r))
                  throw new n.FormatError("Page count is not an integer.");
                if (r <= 1) return;
                await this.getPage(r - 1);
              } catch (s) {
                this._pagePromises.delete(r - 1);
                await this.cleanup();
                if (s instanceof i.XRefEntryException && !e)
                  throw new i.XRefParseException();
                (0, n.warn)(
                  `checkLastPage - invalid /Pages tree /Count: ${r}.`
                );
                let o;
                try {
                  o = await t.getAllPageDicts(e);
                } catch (a) {
                  if (a instanceof i.XRefEntryException && !e)
                    throw new i.XRefParseException();
                  t.setActualNumPages(1);
                  return;
                }
                for (const [e, [r, n]] of o) {
                  let i;
                  if (r instanceof Error) {
                    i = Promise.reject(r);
                    i.catch(() => {});
                  } else
                    i = Promise.resolve(
                      new Page({
                        pdfManager: a,
                        xref: this.xref,
                        pageIndex: e,
                        pageDict: r,
                        ref: n,
                        globalIdFactory: this._globalIdFactory,
                        fontCache: t.fontCache,
                        builtInCMapCache: t.builtInCMapCache,
                        standardFontDataCache: t.standardFontDataCache,
                        globalImageCache: t.globalImageCache,
                        nonBlendModesSet: t.nonBlendModesSet,
                        xfaFactory: null,
                      })
                    );
                  this._pagePromises.set(e, i);
                }
                t.setActualNumPages(o.size);
              }
            }
            fontFallback(e, t) {
              return this.catalog.fontFallback(e, t);
            }
            async cleanup(e = !1) {
              return this.catalog
                ? this.catalog.cleanup(e)
                : (0, u.clearGlobalCaches)();
            }
            _collectFieldObjects(e, t, a) {
              const i = this.xref.fetchIfRef(t);
              if (i.has("T")) {
                const t = (0, n.stringToPDFString)(i.get("T"));
                e = "" === e ? t : `${e}.${t}`;
              }
              a.has(e) || a.set(e, []);
              a.get(e).push(
                r.AnnotationFactory.create(
                  this.xref,
                  t,
                  this.pdfManager,
                  this._localIdFactory,
                  !0
                )
                  .then((e) => e && e.getFieldObject())
                  .catch(function (e) {
                    (0, n.warn)(`_collectFieldObjects: "${e}".`);
                    return null;
                  })
              );
              if (i.has("Kids")) {
                const t = i.get("Kids");
                for (const r of t) this._collectFieldObjects(e, r, a);
              }
            }
            get fieldObjects() {
              if (!this.formInfo.hasFields)
                return (0, n.shadow)(
                  this,
                  "fieldObjects",
                  Promise.resolve(null)
                );
              const e = Object.create(null),
                t = new Map();
              for (const e of this.catalog.acroForm.get("Fields"))
                this._collectFieldObjects("", e, t);
              const a = [];
              for (const [r, n] of t)
                a.push(
                  Promise.all(n).then((t) => {
                    (t = t.filter((e) => !!e)).length > 0 && (e[r] = t);
                  })
                );
              return (0, n.shadow)(
                this,
                "fieldObjects",
                Promise.all(a).then(() => e)
              );
            }
            get hasJSActions() {
              const e = this.pdfManager.ensureDoc("_parseHasJSActions");
              return (0, n.shadow)(this, "hasJSActions", e);
            }
            async _parseHasJSActions() {
              const [e, t] = await Promise.all([
                this.pdfManager.ensureCatalog("jsActions"),
                this.pdfManager.ensureDoc("fieldObjects"),
              ]);
              return (
                !!e ||
                (!!t &&
                  Object.values(t).some((e) =>
                    e.some((e) => null !== e.actions)
                  ))
              );
            }
            get calculationOrderIds() {
              const e = this.catalog.acroForm;
              if (!e || !e.has("CO"))
                return (0, n.shadow)(this, "calculationOrderIds", null);
              const t = e.get("CO");
              if (!Array.isArray(t) || 0 === t.length)
                return (0, n.shadow)(this, "calculationOrderIds", null);
              const a = [];
              for (const e of t) e instanceof s.Ref && a.push(e.toString());
              return 0 === a.length
                ? (0, n.shadow)(this, "calculationOrderIds", null)
                : (0, n.shadow)(this, "calculationOrderIds", a);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PopupAnnotation =
            t.MarkupAnnotation =
            t.AnnotationFactory =
            t.AnnotationBorderStyle =
            t.Annotation =
              void 0;
          t.getQuadPoints = getQuadPoints;
          var r = a(2),
            n = a(3),
            i = a(11),
            s = a(4),
            o = a(63),
            c = a(5),
            l = a(58),
            h = a(67),
            u = a(12),
            d = a(70),
            f = a(73),
            g = a(60),
            p = a(8),
            m = a(74);
          t.AnnotationFactory = class AnnotationFactory {
            static create(e, t, a, r, n) {
              return Promise.all([
                a.ensureCatalog("acroForm"),
                a.ensureCatalog("baseUrl"),
                a.ensureCatalog("attachments"),
                a.ensureDoc("xfaDatasets"),
                n ? this._getPageIndex(e, t, a) : -1,
              ]).then(([i, s, o, c, l]) =>
                a.ensure(this, "_create", [e, t, a, r, i, o, c, n, l])
              );
            }
            static _create(e, t, a, i, o, c = null, l, h, u = -1) {
              const d = e.fetchIfRef(t);
              if (!(d instanceof s.Dict)) return;
              const f =
                t instanceof s.Ref ? t.toString() : `annot_${i.createObjId()}`;
              let g = d.get("Subtype");
              g = g instanceof s.Name ? g.name : null;
              const p = o instanceof s.Dict ? o : s.Dict.empty,
                m = {
                  xref: e,
                  ref: t,
                  dict: d,
                  subtype: g,
                  id: f,
                  pdfManager: a,
                  acroForm: p,
                  attachments: c,
                  xfaDatasets: l,
                  collectFields: h,
                  needAppearances: !h && !0 === p.get("NeedAppearances"),
                  pageIndex: u,
                  isOffscreenCanvasSupported:
                    r.FeatureTest.isOffscreenCanvasSupported &&
                    a.evaluatorOptions.isOffscreenCanvasSupported,
                };
              switch (g) {
                case "Link":
                  return new LinkAnnotation(m);
                case "Text":
                  return new TextAnnotation(m);
                case "Widget":
                  let e = (0, n.getInheritableProperty)({ dict: d, key: "FT" });
                  e = e instanceof s.Name ? e.name : null;
                  switch (e) {
                    case "Tx":
                      return new TextWidgetAnnotation(m);
                    case "Btn":
                      return new ButtonWidgetAnnotation(m);
                    case "Ch":
                      return new ChoiceWidgetAnnotation(m);
                    case "Sig":
                      return new SignatureWidgetAnnotation(m);
                  }
                  (0, r.warn)(
                    `Unimplemented widget field type "${e}", falling back to base field type.`
                  );
                  return new WidgetAnnotation(m);
                case "Popup":
                  return new PopupAnnotation(m);
                case "FreeText":
                  return new FreeTextAnnotation(m);
                case "Line":
                  return new LineAnnotation(m);
                case "Square":
                  return new SquareAnnotation(m);
                case "Circle":
                  return new CircleAnnotation(m);
                case "PolyLine":
                  return new PolylineAnnotation(m);
                case "Polygon":
                  return new PolygonAnnotation(m);
                case "Caret":
                  return new CaretAnnotation(m);
                case "Ink":
                  return new InkAnnotation(m);
                case "Highlight":
                  return new HighlightAnnotation(m);
                case "Underline":
                  return new UnderlineAnnotation(m);
                case "Squiggly":
                  return new SquigglyAnnotation(m);
                case "StrikeOut":
                  return new StrikeOutAnnotation(m);
                case "Stamp":
                  return new StampAnnotation(m);
                case "FileAttachment":
                  return new FileAttachmentAnnotation(m);
                default:
                  h ||
                    (g
                      ? (0, r.warn)(
                          `Unimplemented annotation type "${g}", falling back to base annotation.`
                        )
                      : (0, r.warn)(
                          "Annotation is missing the required /Subtype."
                        ));
                  return new Annotation(m);
              }
            }
            static async _getPageIndex(e, t, a) {
              try {
                const r = await e.fetchIfRefAsync(t);
                if (!(r instanceof s.Dict)) return -1;
                const n = r.getRaw("P");
                if (!(n instanceof s.Ref)) return -1;
                return await a.ensureCatalog("getPageIndex", [n]);
              } catch (e) {
                (0, r.warn)(`_getPageIndex: "${e}".`);
                return -1;
              }
            }
            static async saveNewAnnotations(e, t, a) {
              const n = e.xref;
              let i;
              const c = [],
                l = [];
              for (const h of a)
                switch (h.annotationType) {
                  case r.AnnotationEditorType.FREETEXT:
                    if (!i) {
                      const e = new s.Dict(n);
                      e.set("BaseFont", s.Name.get("Helvetica"));
                      e.set("Type", s.Name.get("Font"));
                      e.set("Subtype", s.Name.get("Type1"));
                      e.set("Encoding", s.Name.get("WinAnsiEncoding"));
                      const t = [];
                      i = n.getNewTemporaryRef();
                      (0, o.writeObject)(i, e, t, null);
                      c.push({ ref: i, data: t.join("") });
                    }
                    l.push(
                      FreeTextAnnotation.createNewAnnotation(n, h, c, {
                        evaluator: e,
                        task: t,
                        baseFontRef: i,
                      })
                    );
                    break;
                  case r.AnnotationEditorType.INK:
                    l.push(InkAnnotation.createNewAnnotation(n, h, c));
                }
              return { annotations: await Promise.all(l), dependencies: c };
            }
            static async printNewAnnotations(e, t, a) {
              if (!a) return null;
              const n = e.xref,
                i = [],
                s =
                  r.FeatureTest.isOffscreenCanvasSupported &&
                  e.options.isOffscreenCanvasSupported;
              for (const o of a)
                switch (o.annotationType) {
                  case r.AnnotationEditorType.FREETEXT:
                    i.push(
                      FreeTextAnnotation.createNewPrintAnnotation(n, o, {
                        evaluator: e,
                        task: t,
                        isOffscreenCanvasSupported: s,
                      })
                    );
                    break;
                  case r.AnnotationEditorType.INK:
                    i.push(
                      InkAnnotation.createNewPrintAnnotation(n, o, {
                        isOffscreenCanvasSupported: s,
                      })
                    );
                }
              return Promise.all(i);
            }
          };
          function getRgbColor(e, t = new Uint8ClampedArray(3)) {
            if (!Array.isArray(e)) return t;
            const a = t || new Uint8ClampedArray(3);
            switch (e.length) {
              case 0:
                return null;
              case 1:
                u.ColorSpace.singletons.gray.getRgbItem(e, 0, a, 0);
                return a;
              case 3:
                u.ColorSpace.singletons.rgb.getRgbItem(e, 0, a, 0);
                return a;
              case 4:
                u.ColorSpace.singletons.cmyk.getRgbItem(e, 0, a, 0);
                return a;
              default:
                return t;
            }
          }
          function getPdfColorArray(e) {
            return Array.from(e, (e) => e / 255);
          }
          function getQuadPoints(e, t) {
            const a = e.getArray("QuadPoints");
            if (!Array.isArray(a) || 0 === a.length || a.length % 8 > 0)
              return null;
            const r = [];
            for (let e = 0, n = a.length / 8; e < n; e++) {
              let n = 1 / 0,
                i = -1 / 0,
                s = 1 / 0,
                o = -1 / 0;
              for (let t = 8 * e, r = 8 * e + 8; t < r; t += 2) {
                const e = a[t],
                  r = a[t + 1];
                n = Math.min(e, n);
                i = Math.max(e, i);
                s = Math.min(r, s);
                o = Math.max(r, o);
              }
              if (null !== t && (n < t[0] || i > t[2] || s < t[1] || o > t[3]))
                return null;
              r.push([
                { x: n, y: o },
                { x: i, y: o },
                { x: n, y: s },
                { x: i, y: s },
              ]);
            }
            return r;
          }
          function getTransformMatrix(e, t, a) {
            const [n, i, s, o] = r.Util.getAxialAlignedBoundingBox(t, a);
            if (n === s || i === o) return [1, 0, 0, 1, e[0], e[1]];
            const c = (e[2] - e[0]) / (s - n),
              l = (e[3] - e[1]) / (o - i);
            return [c, 0, 0, l, e[0] - n * c, e[1] - i * l];
          }
          class Annotation {
            constructor(e) {
              const { dict: t, xref: a } = e;
              this.setTitle(t.get("T"));
              this.setContents(t.get("Contents"));
              this.setModificationDate(t.get("M"));
              this.setFlags(t.get("F"));
              this.setRectangle(t.getArray("Rect"));
              this.setColor(t.getArray("C"));
              this.setBorderStyle(t);
              this.setAppearance(t);
              this.setOptionalContent(t);
              const i = t.get("MK");
              this.setBorderAndBackgroundColors(i);
              this.setRotation(i);
              this._streams = [];
              this.appearance && this._streams.push(this.appearance);
              this.data = {
                annotationFlags: this.flags,
                borderStyle: this.borderStyle,
                color: this.color,
                backgroundColor: this.backgroundColor,
                borderColor: this.borderColor,
                rotation: this.rotation,
                contentsObj: this._contents,
                hasAppearance: !!this.appearance,
                id: e.id,
                modificationDate: this.modificationDate,
                rect: this.rectangle,
                subtype: e.subtype,
                hasOwnCanvas: !1,
              };
              if (e.collectFields) {
                const i = t.get("Kids");
                if (Array.isArray(i)) {
                  const e = [];
                  for (const t of i) t instanceof s.Ref && e.push(t.toString());
                  0 !== e.length && (this.data.kidIds = e);
                }
                this.data.actions = (0, n.collectActions)(
                  a,
                  t,
                  r.AnnotationActionEventType
                );
                this.data.fieldName = this._constructFieldName(t);
                this.data.pageIndex = e.pageIndex;
              }
              this._isOffscreenCanvasSupported = e.isOffscreenCanvasSupported;
              this._fallbackFontDict = null;
              this._needAppearances = !1;
            }
            _hasFlag(e, t) {
              return !!(e & t);
            }
            _isViewable(e) {
              return (
                !this._hasFlag(e, r.AnnotationFlag.INVISIBLE) &&
                !this._hasFlag(e, r.AnnotationFlag.NOVIEW)
              );
            }
            _isPrintable(e) {
              return (
                this._hasFlag(e, r.AnnotationFlag.PRINT) &&
                !this._hasFlag(e, r.AnnotationFlag.INVISIBLE)
              );
            }
            mustBeViewed(e) {
              const t = e && e.get(this.data.id);
              return t && void 0 !== t.hidden
                ? !t.hidden
                : this.viewable &&
                    !this._hasFlag(this.flags, r.AnnotationFlag.HIDDEN);
            }
            mustBePrinted(e) {
              const t = e && e.get(this.data.id);
              return t && void 0 !== t.print ? t.print : this.printable;
            }
            get viewable() {
              return (
                null !== this.data.quadPoints &&
                (0 === this.flags || this._isViewable(this.flags))
              );
            }
            get printable() {
              return (
                null !== this.data.quadPoints &&
                0 !== this.flags &&
                this._isPrintable(this.flags)
              );
            }
            _parseStringHelper(e) {
              const t = "string" == typeof e ? (0, r.stringToPDFString)(e) : "";
              return {
                str: t,
                dir: t && "rtl" === (0, l.bidi)(t).dir ? "rtl" : "ltr",
              };
            }
            setDefaultAppearance(e) {
              const t =
                (0, n.getInheritableProperty)({ dict: e.dict, key: "DA" }) ||
                e.acroForm.get("DA");
              this._defaultAppearance = "string" == typeof t ? t : "";
              this.data.defaultAppearanceData = (0, i.parseDefaultAppearance)(
                this._defaultAppearance
              );
            }
            setTitle(e) {
              this._title = this._parseStringHelper(e);
            }
            setContents(e) {
              this._contents = this._parseStringHelper(e);
            }
            setModificationDate(e) {
              this.modificationDate = "string" == typeof e ? e : null;
            }
            setFlags(e) {
              this.flags = Number.isInteger(e) && e > 0 ? e : 0;
            }
            hasFlag(e) {
              return this._hasFlag(this.flags, e);
            }
            setRectangle(e) {
              Array.isArray(e) && 4 === e.length
                ? (this.rectangle = r.Util.normalizeRect(e))
                : (this.rectangle = [0, 0, 0, 0]);
            }
            setColor(e) {
              this.color = getRgbColor(e);
            }
            setLineEndings(e) {
              this.lineEndings = ["None", "None"];
              if (Array.isArray(e) && 2 === e.length)
                for (let t = 0; t < 2; t++) {
                  const a = e[t];
                  if (a instanceof s.Name)
                    switch (a.name) {
                      case "None":
                        continue;
                      case "Square":
                      case "Circle":
                      case "Diamond":
                      case "OpenArrow":
                      case "ClosedArrow":
                      case "Butt":
                      case "ROpenArrow":
                      case "RClosedArrow":
                      case "Slash":
                        this.lineEndings[t] = a.name;
                        continue;
                    }
                  (0, r.warn)(`Ignoring invalid lineEnding: ${a}`);
                }
            }
            setRotation(e) {
              this.rotation = 0;
              if (e instanceof s.Dict) {
                let t = e.get("R") || 0;
                if (Number.isInteger(t) && 0 !== t) {
                  t %= 360;
                  t < 0 && (t += 360);
                  t % 90 == 0 && (this.rotation = t);
                }
              }
            }
            setBorderAndBackgroundColors(e) {
              if (e instanceof s.Dict) {
                this.borderColor = getRgbColor(e.getArray("BC"), null);
                this.backgroundColor = getRgbColor(e.getArray("BG"), null);
              } else this.borderColor = this.backgroundColor = null;
            }
            setBorderStyle(e) {
              this.borderStyle = new AnnotationBorderStyle();
              if (e instanceof s.Dict)
                if (e.has("BS")) {
                  const t = e.get("BS"),
                    a = t.get("Type");
                  if (!a || (0, s.isName)(a, "Border")) {
                    this.borderStyle.setWidth(t.get("W"), this.rectangle);
                    this.borderStyle.setStyle(t.get("S"));
                    this.borderStyle.setDashArray(t.getArray("D"));
                  }
                } else if (e.has("Border")) {
                  const t = e.getArray("Border");
                  if (Array.isArray(t) && t.length >= 3) {
                    this.borderStyle.setHorizontalCornerRadius(t[0]);
                    this.borderStyle.setVerticalCornerRadius(t[1]);
                    this.borderStyle.setWidth(t[2], this.rectangle);
                    4 === t.length && this.borderStyle.setDashArray(t[3], !0);
                  }
                } else this.borderStyle.setWidth(0);
            }
            setAppearance(e) {
              this.appearance = null;
              const t = e.get("AP");
              if (!(t instanceof s.Dict)) return;
              const a = t.get("N");
              if (a instanceof c.BaseStream) {
                this.appearance = a;
                return;
              }
              if (!(a instanceof s.Dict)) return;
              const r = e.get("AS");
              if (!(r instanceof s.Name && a.has(r.name))) return;
              const n = a.get(r.name);
              n instanceof c.BaseStream && (this.appearance = n);
            }
            setOptionalContent(e) {
              this.oc = null;
              const t = e.get("OC");
              t instanceof s.Name
                ? (0, r.warn)(
                    "setOptionalContent: Support for /Name-entry is not implemented."
                  )
                : t instanceof s.Dict && (this.oc = t);
            }
            loadResources(e, t) {
              return t.dict.getAsync("Resources").then((t) => {
                if (!t) return;
                return new f.ObjectLoader(t, e, t.xref)
                  .load()
                  .then(function () {
                    return t;
                  });
              });
            }
            async getOperatorList(e, t, a, n, i) {
              const o = this.data;
              let c = this.appearance;
              const l = !!(
                this.data.hasOwnCanvas && a & r.RenderingIntentFlag.DISPLAY
              );
              if (!c) {
                if (!l)
                  return {
                    opList: new g.OperatorList(),
                    separateForm: !1,
                    separateCanvas: !1,
                  };
                c = new p.StringStream("");
                c.dict = new s.Dict();
              }
              const h = c.dict,
                u = await this.loadResources(
                  [
                    "ExtGState",
                    "ColorSpace",
                    "Pattern",
                    "Shading",
                    "XObject",
                    "Font",
                  ],
                  c
                ),
                d = h.getArray("BBox") || [0, 0, 1, 1],
                f = h.getArray("Matrix") || [1, 0, 0, 1, 0, 0],
                m = getTransformMatrix(o.rect, d, f),
                b = new g.OperatorList();
              let y;
              this.oc && (y = await e.parseMarkedContentProps(this.oc, null));
              void 0 !== y && b.addOp(r.OPS.beginMarkedContentProps, ["OC", y]);
              b.addOp(r.OPS.beginAnnotation, [o.id, o.rect, m, f, l]);
              await e.getOperatorList({
                stream: c,
                task: t,
                resources: u,
                operatorList: b,
                fallbackFontDict: this._fallbackFontDict,
              });
              b.addOp(r.OPS.endAnnotation, []);
              void 0 !== y && b.addOp(r.OPS.endMarkedContent, []);
              this.reset();
              return { opList: b, separateForm: !1, separateCanvas: l };
            }
            async save(e, t, a) {
              return null;
            }
            get hasTextContent() {
              return !1;
            }
            async extractTextContent(e, t, a) {
              if (!this.appearance) return;
              const r = await this.loadResources(
                  ["ExtGState", "Font", "Properties", "XObject"],
                  this.appearance
                ),
                n = [],
                i = [],
                s = {
                  desiredSize: Math.Infinity,
                  ready: !0,
                  enqueue(e, t) {
                    for (const t of e.items) {
                      i.push(t.str);
                      if (t.hasEOL) {
                        n.push(i.join(""));
                        i.length = 0;
                      }
                    }
                  },
                };
              await e.getTextContent({
                stream: this.appearance,
                task: t,
                resources: r,
                includeMarkedContent: !0,
                combineTextItems: !0,
                sink: s,
                viewBox: a,
              });
              this.reset();
              i.length && n.push(i.join(""));
              n.length > 0 && (this.data.textContent = n);
            }
            getFieldObject() {
              return this.data.kidIds
                ? {
                    id: this.data.id,
                    actions: this.data.actions,
                    name: this.data.fieldName,
                    strokeColor: this.data.borderColor,
                    fillColor: this.data.backgroundColor,
                    type: "",
                    kidIds: this.data.kidIds,
                    page: this.data.pageIndex,
                    rotation: this.rotation,
                  }
                : null;
            }
            reset() {
              for (const e of this._streams) e.reset();
            }
            _constructFieldName(e) {
              if (!e.has("T") && !e.has("Parent")) {
                (0, r.warn)(
                  "Unknown field name, falling back to empty field name."
                );
                return "";
              }
              if (!e.has("Parent")) return (0, r.stringToPDFString)(e.get("T"));
              const t = [];
              e.has("T") && t.unshift((0, r.stringToPDFString)(e.get("T")));
              let a = e;
              const n = new s.RefSet();
              e.objId && n.put(e.objId);
              for (; a.has("Parent"); ) {
                a = a.get("Parent");
                if (!(a instanceof s.Dict) || (a.objId && n.has(a.objId)))
                  break;
                a.objId && n.put(a.objId);
                if (a.has("T")) {
                  const e = (0, r.stringToPDFString)(a.get("T"));
                  e.startsWith("#") || t.unshift(e);
                }
              }
              return t.join(".");
            }
          }
          t.Annotation = Annotation;
          class AnnotationBorderStyle {
            constructor() {
              this.width = 1;
              this.style = r.AnnotationBorderStyleType.SOLID;
              this.dashArray = [3];
              this.horizontalCornerRadius = 0;
              this.verticalCornerRadius = 0;
            }
            setWidth(e, t = [0, 0, 0, 0]) {
              if (e instanceof s.Name) this.width = 0;
              else if ("number" == typeof e) {
                if (e > 0) {
                  const a = (t[2] - t[0]) / 2,
                    n = (t[3] - t[1]) / 2;
                  if (a > 0 && n > 0 && (e > a || e > n)) {
                    (0, r.warn)(
                      `AnnotationBorderStyle.setWidth - ignoring width: ${e}`
                    );
                    e = 1;
                  }
                }
                this.width = e;
              }
            }
            setStyle(e) {
              if (e instanceof s.Name)
                switch (e.name) {
                  case "S":
                    this.style = r.AnnotationBorderStyleType.SOLID;
                    break;
                  case "D":
                    this.style = r.AnnotationBorderStyleType.DASHED;
                    break;
                  case "B":
                    this.style = r.AnnotationBorderStyleType.BEVELED;
                    break;
                  case "I":
                    this.style = r.AnnotationBorderStyleType.INSET;
                    break;
                  case "U":
                    this.style = r.AnnotationBorderStyleType.UNDERLINE;
                }
            }
            setDashArray(e, t = !1) {
              if (Array.isArray(e) && e.length > 0) {
                let a = !0,
                  r = !0;
                for (const t of e) {
                  if (!(+t >= 0)) {
                    a = !1;
                    break;
                  }
                  t > 0 && (r = !1);
                }
                if (a && !r) {
                  this.dashArray = e;
                  t && this.setStyle(s.Name.get("D"));
                } else this.width = 0;
              } else e && (this.width = 0);
            }
            setHorizontalCornerRadius(e) {
              Number.isInteger(e) && (this.horizontalCornerRadius = e);
            }
            setVerticalCornerRadius(e) {
              Number.isInteger(e) && (this.verticalCornerRadius = e);
            }
          }
          t.AnnotationBorderStyle = AnnotationBorderStyle;
          class MarkupAnnotation extends Annotation {
            constructor(e) {
              super(e);
              const { dict: t } = e;
              if (t.has("IRT")) {
                const e = t.getRaw("IRT");
                this.data.inReplyTo = e instanceof s.Ref ? e.toString() : null;
                const a = t.get("RT");
                this.data.replyType =
                  a instanceof s.Name ? a.name : r.AnnotationReplyType.REPLY;
              }
              if (this.data.replyType === r.AnnotationReplyType.GROUP) {
                const e = t.get("IRT");
                this.setTitle(e.get("T"));
                this.data.titleObj = this._title;
                this.setContents(e.get("Contents"));
                this.data.contentsObj = this._contents;
                if (e.has("CreationDate")) {
                  this.setCreationDate(e.get("CreationDate"));
                  this.data.creationDate = this.creationDate;
                } else this.data.creationDate = null;
                if (e.has("M")) {
                  this.setModificationDate(e.get("M"));
                  this.data.modificationDate = this.modificationDate;
                } else this.data.modificationDate = null;
                this.data.hasPopup = e.has("Popup");
                if (e.has("C")) {
                  this.setColor(e.getArray("C"));
                  this.data.color = this.color;
                } else this.data.color = null;
              } else {
                this.data.titleObj = this._title;
                this.setCreationDate(t.get("CreationDate"));
                this.data.creationDate = this.creationDate;
                this.data.hasPopup = t.has("Popup");
                t.has("C") || (this.data.color = null);
              }
              t.has("RC") &&
                (this.data.richText = m.XFAFactory.getRichTextAsHtml(
                  t.get("RC")
                ));
            }
            setCreationDate(e) {
              this.creationDate = "string" == typeof e ? e : null;
            }
            _setDefaultAppearance({
              xref: e,
              extra: t,
              strokeColor: a,
              fillColor: r,
              blendMode: n,
              strokeAlpha: i,
              fillAlpha: o,
              pointsCallback: c,
            }) {
              let l = Number.MAX_VALUE,
                h = Number.MAX_VALUE,
                u = Number.MIN_VALUE,
                d = Number.MIN_VALUE;
              const f = ["q"];
              t && f.push(t);
              a && f.push(`${a[0]} ${a[1]} ${a[2]} RG`);
              r && f.push(`${r[0]} ${r[1]} ${r[2]} rg`);
              let g = this.data.quadPoints;
              g ||
                (g = [
                  [
                    { x: this.rectangle[0], y: this.rectangle[3] },
                    { x: this.rectangle[2], y: this.rectangle[3] },
                    { x: this.rectangle[0], y: this.rectangle[1] },
                    { x: this.rectangle[2], y: this.rectangle[1] },
                  ],
                ]);
              for (const e of g) {
                const [t, a, r, n] = c(f, e);
                l = Math.min(l, t);
                u = Math.max(u, a);
                h = Math.min(h, r);
                d = Math.max(d, n);
              }
              f.push("Q");
              const m = new s.Dict(e),
                b = new s.Dict(e);
              b.set("Subtype", s.Name.get("Form"));
              const y = new p.StringStream(f.join(" "));
              y.dict = b;
              m.set("Fm0", y);
              const w = new s.Dict(e);
              n && w.set("BM", s.Name.get(n));
              "number" == typeof i && w.set("CA", i);
              "number" == typeof o && w.set("ca", o);
              const S = new s.Dict(e);
              S.set("GS0", w);
              const x = new s.Dict(e);
              x.set("ExtGState", S);
              x.set("XObject", m);
              const C = new s.Dict(e);
              C.set("Resources", x);
              const k = (this.data.rect = [l, h, u, d]);
              C.set("BBox", k);
              this.appearance = new p.StringStream("/GS0 gs /Fm0 Do");
              this.appearance.dict = C;
              this._streams.push(this.appearance, y);
            }
            static async createNewAnnotation(e, t, a, r) {
              const n = e.getNewTemporaryRef(),
                i = await this.createNewAppearanceStream(t, e, r),
                s = [];
              let c;
              if (i) {
                const r = e.getNewTemporaryRef();
                c = this.createNewDict(t, e, { apRef: r });
                const n = e.encrypt
                  ? e.encrypt.createCipherTransform(r.num, r.gen)
                  : null;
                (0, o.writeObject)(r, i, s, n);
                a.push({ ref: r, data: s.join("") });
              } else c = this.createNewDict(t, e, {});
              s.length = 0;
              const l = e.encrypt
                ? e.encrypt.createCipherTransform(n.num, n.gen)
                : null;
              (0, o.writeObject)(n, c, s, l);
              return { ref: n, data: s.join("") };
            }
            static async createNewPrintAnnotation(e, t, a) {
              const r = await this.createNewAppearanceStream(t, e, a),
                n = this.createNewDict(t, e, { ap: r });
              return new this.prototype.constructor({
                dict: n,
                xref: e,
                isOffscreenCanvasSupported: a.isOffscreenCanvasSupported,
              });
            }
          }
          t.MarkupAnnotation = MarkupAnnotation;
          class WidgetAnnotation extends Annotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e,
                i = this.data;
              this.ref = e.ref;
              this._needAppearances = e.needAppearances;
              i.annotationType = r.AnnotationType.WIDGET;
              void 0 === i.fieldName &&
                (i.fieldName = this._constructFieldName(t));
              void 0 === i.actions &&
                (i.actions = (0, n.collectActions)(
                  a,
                  t,
                  r.AnnotationActionEventType
                ));
              let o = (0, n.getInheritableProperty)({
                dict: t,
                key: "V",
                getArray: !0,
              });
              i.fieldValue = this._decodeFormValue(o);
              const c = (0, n.getInheritableProperty)({
                dict: t,
                key: "DV",
                getArray: !0,
              });
              i.defaultFieldValue = this._decodeFormValue(c);
              if (void 0 === o && e.xfaDatasets) {
                const t = this._title.str;
                if (t) {
                  this._hasValueFromXFA = !0;
                  i.fieldValue = o = e.xfaDatasets.getValue(t);
                }
              }
              void 0 === o &&
                null !== i.defaultFieldValue &&
                (i.fieldValue = i.defaultFieldValue);
              i.alternativeText = (0, r.stringToPDFString)(t.get("TU") || "");
              this.setDefaultAppearance(e);
              i.hasAppearance =
                (this._needAppearances &&
                  void 0 !== i.fieldValue &&
                  null !== i.fieldValue) ||
                i.hasAppearance;
              const l = (0, n.getInheritableProperty)({ dict: t, key: "FT" });
              i.fieldType = l instanceof s.Name ? l.name : null;
              const h = (0, n.getInheritableProperty)({ dict: t, key: "DR" }),
                u = e.acroForm.get("DR"),
                d = this.appearance && this.appearance.dict.get("Resources");
              this._fieldResources = {
                localResources: h,
                acroFormResources: u,
                appearanceResources: d,
                mergedResources: s.Dict.merge({
                  xref: a,
                  dictArray: [h, d, u],
                  mergeSubDicts: !0,
                }),
              };
              i.fieldFlags = (0, n.getInheritableProperty)({
                dict: t,
                key: "Ff",
              });
              (!Number.isInteger(i.fieldFlags) || i.fieldFlags < 0) &&
                (i.fieldFlags = 0);
              i.readOnly = this.hasFieldFlag(r.AnnotationFieldFlag.READONLY);
              i.required = this.hasFieldFlag(r.AnnotationFieldFlag.REQUIRED);
              i.hidden = this._hasFlag(
                i.annotationFlags,
                r.AnnotationFlag.HIDDEN
              );
            }
            _decodeFormValue(e) {
              return Array.isArray(e)
                ? e
                    .filter((e) => "string" == typeof e)
                    .map((e) => (0, r.stringToPDFString)(e))
                : e instanceof s.Name
                ? (0, r.stringToPDFString)(e.name)
                : "string" == typeof e
                ? (0, r.stringToPDFString)(e)
                : null;
            }
            hasFieldFlag(e) {
              return !!(this.data.fieldFlags & e);
            }
            getRotationMatrix(e) {
              const t = e ? e.get(this.data.id) : void 0;
              let a = t && t.rotation;
              void 0 === a && (a = this.rotation);
              if (0 === a) return r.IDENTITY_MATRIX;
              const i = this.data.rect[2] - this.data.rect[0],
                s = this.data.rect[3] - this.data.rect[1];
              return (0, n.getRotationMatrix)(a, i, s);
            }
            getBorderAndBackgroundAppearances(e) {
              const t = e ? e.get(this.data.id) : void 0;
              let a = t && t.rotation;
              void 0 === a && (a = this.rotation);
              if (!this.backgroundColor && !this.borderColor) return "";
              const r = this.data.rect[2] - this.data.rect[0],
                n = this.data.rect[3] - this.data.rect[1],
                s =
                  0 === a || 180 === a
                    ? `0 0 ${r} ${n} re`
                    : `0 0 ${n} ${r} re`;
              let o = "";
              this.backgroundColor &&
                (o = `${(0, i.getPdfColor)(this.backgroundColor, !0)} ${s} f `);
              if (this.borderColor) {
                o += `${this.borderStyle.width || 1} w ${(0, i.getPdfColor)(
                  this.borderColor,
                  !1
                )} ${s} S `;
              }
              return o;
            }
            async getOperatorList(e, t, a, n, i) {
              if (n && !(this instanceof SignatureWidgetAnnotation))
                return {
                  opList: new g.OperatorList(),
                  separateForm: !0,
                  separateCanvas: !1,
                };
              if (!this._hasText) return super.getOperatorList(e, t, a, n, i);
              const s = await this._getAppearance(e, t, a, i);
              if (this.appearance && null === s)
                return super.getOperatorList(e, t, a, n, i);
              const o = new g.OperatorList();
              if (!this._defaultAppearance || null === s)
                return { opList: o, separateForm: !1, separateCanvas: !1 };
              const c = [
                  0,
                  0,
                  this.data.rect[2] - this.data.rect[0],
                  this.data.rect[3] - this.data.rect[1],
                ],
                l = getTransformMatrix(this.data.rect, c, [1, 0, 0, 1, 0, 0]);
              let h;
              this.oc && (h = await e.parseMarkedContentProps(this.oc, null));
              void 0 !== h && o.addOp(r.OPS.beginMarkedContentProps, ["OC", h]);
              o.addOp(r.OPS.beginAnnotation, [
                this.data.id,
                this.data.rect,
                l,
                this.getRotationMatrix(i),
                !1,
              ]);
              const u = new p.StringStream(s);
              await e.getOperatorList({
                stream: u,
                task: t,
                resources: this._fieldResources.mergedResources,
                operatorList: o,
              });
              o.addOp(r.OPS.endAnnotation, []);
              void 0 !== h && o.addOp(r.OPS.endMarkedContent, []);
              return { opList: o, separateForm: !1, separateCanvas: !1 };
            }
            _getMKDict(e) {
              const t = new s.Dict(null);
              e && t.set("R", e);
              this.borderColor &&
                t.set("BC", getPdfColorArray(this.borderColor));
              this.backgroundColor &&
                t.set("BG", getPdfColorArray(this.backgroundColor));
              return t.size > 0 ? t : null;
            }
            amendSavedDict(e, t) {}
            async save(e, t, a) {
              const i = a ? a.get(this.data.id) : void 0;
              let c = i && i.value,
                l = i && i.rotation;
              if (c === this.data.fieldValue || void 0 === c) {
                if (!this._hasValueFromXFA && void 0 === l) return null;
                c = c || this.data.fieldValue;
              }
              if (
                void 0 === l &&
                !this._hasValueFromXFA &&
                Array.isArray(c) &&
                Array.isArray(this.data.fieldValue) &&
                c.length === this.data.fieldValue.length &&
                c.every((e, t) => e === this.data.fieldValue[t])
              )
                return null;
              void 0 === l && (l = this.rotation);
              let h = null;
              if (!this._needAppearances) {
                h = await this._getAppearance(
                  e,
                  t,
                  r.RenderingIntentFlag.SAVE,
                  a
                );
                if (null === h) return null;
              }
              let u = !1;
              if (h && h.needAppearances) {
                u = !0;
                h = null;
              }
              const { xref: d } = e,
                f = d.fetchIfRef(this.ref);
              if (!(f instanceof s.Dict)) return null;
              const g = new s.Dict(d);
              for (const e of f.getKeys()) "AP" !== e && g.set(e, f.getRaw(e));
              const m = { path: this.data.fieldName, value: c },
                encoder = (e) =>
                  (0, n.isAscii)(e) ? e : (0, n.stringToUTF16String)(e, !0);
              g.set("V", Array.isArray(c) ? c.map(encoder) : encoder(c));
              this.amendSavedDict(a, g);
              const b = this._getMKDict(l);
              b && g.set("MK", b);
              const y = d.encrypt,
                w = y
                  ? y.createCipherTransform(this.ref.num, this.ref.gen)
                  : null,
                S = [],
                x = [{ ref: this.ref, data: "", xfa: m, needAppearances: u }];
              if (null !== h) {
                const e = d.getNewTemporaryRef(),
                  t = new s.Dict(d);
                g.set("AP", t);
                t.set("N", e);
                let n = null;
                y && (n = y.createCipherTransform(e.num, e.gen));
                const i = this._getSaveFieldResources(d),
                  c = new p.StringStream(h),
                  l = (c.dict = new s.Dict(d));
                l.set("Subtype", s.Name.get("Form"));
                l.set("Resources", i);
                l.set("BBox", [
                  0,
                  0,
                  this.data.rect[2] - this.data.rect[0],
                  this.data.rect[3] - this.data.rect[1],
                ]);
                const u = this.getRotationMatrix(a);
                u !== r.IDENTITY_MATRIX && l.set("Matrix", u);
                (0, o.writeObject)(e, c, S, n);
                x.push({
                  ref: e,
                  data: S.join(""),
                  xfa: null,
                  needAppearances: !1,
                });
                S.length = 0;
              }
              g.set("M", `D:${(0, r.getModificationDate)()}`);
              (0, o.writeObject)(this.ref, g, S, w);
              x[0].data = S.join("");
              return x;
            }
            async _getAppearance(e, t, a, s) {
              if (this.hasFieldFlag(r.AnnotationFieldFlag.PASSWORD))
                return null;
              const o = s ? s.get(this.data.id) : void 0;
              let c, l;
              if (o) {
                c = o.formattedValue || o.value;
                l = o.rotation;
              }
              if (
                void 0 === l &&
                void 0 === c &&
                !this._needAppearances &&
                (!this._hasValueFromXFA || this.appearance)
              )
                return null;
              const h = this.getBorderAndBackgroundAppearances(s);
              if (void 0 === c) {
                c = this.data.fieldValue;
                if (!c) return `/Tx BMC q ${h}Q EMC`;
              }
              Array.isArray(c) && 1 === c.length && (c = c[0]);
              (0, r.assert)(
                "string" == typeof c,
                "Expected `value` to be a string."
              );
              c = c.trim();
              if (this.data.combo) {
                const e = this.data.options.find(
                  ({ exportValue: e }) => c === e
                );
                c = (e && e.displayValue) || c;
              }
              if ("" === c) return `/Tx BMC q ${h}Q EMC`;
              void 0 === l && (l = this.rotation);
              let u,
                d = -1;
              if (this.data.multiLine) {
                u = c.split(/\r\n?|\n/).map((e) => e.normalize("NFC"));
                d = u.length;
              } else u = [c.replace(/\r\n?|\n/, "").normalize("NFC")];
              let f = this.data.rect[3] - this.data.rect[1],
                g = this.data.rect[2] - this.data.rect[0];
              (90 !== l && 270 !== l) || ([g, f] = [f, g]);
              this._defaultAppearance ||
                (this.data.defaultAppearanceData = (0,
                i.parseDefaultAppearance)(
                  (this._defaultAppearance = "/Helvetica 0 Tf 0 g")
                ));
              let p,
                m,
                b,
                y = await WidgetAnnotation._getFontData(
                  e,
                  t,
                  this.data.defaultAppearanceData,
                  this._fieldResources.mergedResources
                );
              const w = [];
              let S = !1;
              for (const e of u) {
                const t = y.encodeString(e);
                t.length > 1 && (S = !0);
                w.push(t.join(""));
              }
              if (S && a & r.RenderingIntentFlag.SAVE)
                return { needAppearances: !0 };
              if (S && this._isOffscreenCanvasSupported) {
                const a = this.data.comb ? "monospace" : "sans-serif",
                  r = new i.FakeUnicodeFont(e.xref, a),
                  s = r.createFontResources(u.join("")),
                  o = s.getRaw("Font");
                if (this._fieldResources.mergedResources.has("Font")) {
                  const e = this._fieldResources.mergedResources.get("Font");
                  for (const t of o.getKeys()) e.set(t, o.getRaw(t));
                } else this._fieldResources.mergedResources.set("Font", o);
                const l = r.fontName.name;
                y = await WidgetAnnotation._getFontData(
                  e,
                  t,
                  { fontName: l, fontSize: 0 },
                  s
                );
                for (let e = 0, t = w.length; e < t; e++)
                  w[e] = (0, n.stringToUTF16String)(u[e]);
                const h = Object.assign(
                  Object.create(null),
                  this.data.defaultAppearanceData
                );
                this.data.defaultAppearanceData.fontSize = 0;
                this.data.defaultAppearanceData.fontName = l;
                [p, m, b] = this._computeFontSize(f - 2, g - 4, c, y, d);
                this.data.defaultAppearanceData = h;
              } else {
                this._isOffscreenCanvasSupported ||
                  (0, r.warn)(
                    "_getAppearance: OffscreenCanvas is not supported, annotation may not render correctly."
                  );
                [p, m, b] = this._computeFontSize(f - 2, g - 4, c, y, d);
              }
              let x = y.descent;
              x = isNaN(x)
                ? r.BASELINE_FACTOR * b
                : Math.max(r.BASELINE_FACTOR * b, Math.abs(x) * m);
              const C = Math.min(Math.floor((f - m) / 2), 1),
                k = this.data.textAlignment;
              if (this.data.multiLine)
                return this._getMultilineAppearance(
                  p,
                  w,
                  y,
                  m,
                  g,
                  f,
                  k,
                  2,
                  C,
                  x,
                  b,
                  s
                );
              if (this.data.comb)
                return this._getCombAppearance(
                  p,
                  y,
                  w[0],
                  m,
                  g,
                  f,
                  2,
                  C,
                  x,
                  b,
                  s
                );
              const v = C + x;
              if (0 === k || k > 2)
                return (
                  `/Tx BMC q ${h}BT ` +
                  p +
                  ` 1 0 0 1 ${(0, n.numberToString)(2)} ${(0, n.numberToString)(
                    v
                  )} Tm (${(0, n.escapeString)(w[0])}) Tj ET Q EMC`
                );
              return (
                `/Tx BMC q ${h}BT ` +
                p +
                ` 1 0 0 1 0 0 Tm ${this._renderText(
                  w[0],
                  y,
                  m,
                  g,
                  k,
                  { shift: 0 },
                  2,
                  v
                )} ET Q EMC`
              );
            }
            static async _getFontData(e, t, a, r) {
              const n = new g.OperatorList(),
                i = {
                  font: null,
                  clone() {
                    return this;
                  },
                },
                { fontName: o, fontSize: c } = a;
              await e.handleSetFont(
                r,
                [o && s.Name.get(o), c],
                null,
                n,
                t,
                i,
                null
              );
              return i.font;
            }
            _getTextWidth(e, t) {
              return t.charsToGlyphs(e).reduce((e, t) => e + t.width, 0) / 1e3;
            }
            _computeFontSize(e, t, a, n, s) {
              let { fontSize: o } = this.data.defaultAppearanceData,
                c = (o || 12) * r.LINE_FACTOR,
                l = Math.round(e / c);
              if (!o) {
                const roundWithTwoDigits = (e) => Math.floor(100 * e) / 100;
                if (-1 === s) {
                  const i = this._getTextWidth(a, n);
                  o = roundWithTwoDigits(
                    Math.min(e / r.LINE_FACTOR, i > t ? t / i : 1 / 0)
                  );
                  l = 1;
                } else {
                  const i = a.split(/\r\n?|\n/),
                    h = [];
                  for (const e of i) {
                    const t = n.encodeString(e).join(""),
                      a = n.charsToGlyphs(t),
                      r = n.getCharPositions(t);
                    h.push({ line: t, glyphs: a, positions: r });
                  }
                  const isTooBig = (a) => {
                    let r = 0;
                    for (const i of h) {
                      r += this._splitLine(null, n, a, t, i).length * a;
                      if (r > e) return !0;
                    }
                    return !1;
                  };
                  l = Math.max(l, s);
                  for (;;) {
                    c = e / l;
                    o = roundWithTwoDigits(c / r.LINE_FACTOR);
                    if (!isTooBig(o)) break;
                    l++;
                  }
                }
                const { fontName: h, fontColor: u } =
                  this.data.defaultAppearanceData;
                this._defaultAppearance = (0, i.createDefaultAppearance)({
                  fontSize: o,
                  fontName: h,
                  fontColor: u,
                });
              }
              return [this._defaultAppearance, o, e / l];
            }
            _renderText(e, t, a, r, i, s, o, c) {
              let l;
              if (1 === i) {
                l = (r - this._getTextWidth(e, t) * a) / 2;
              } else if (2 === i) {
                l = r - this._getTextWidth(e, t) * a - o;
              } else l = o;
              const h = (0, n.numberToString)(l - s.shift);
              s.shift = l;
              return `${h} ${(c = (0, n.numberToString)(c))} Td (${(0,
              n.escapeString)(e)}) Tj`;
            }
            _getSaveFieldResources(e) {
              const {
                  localResources: t,
                  appearanceResources: a,
                  acroFormResources: r,
                } = this._fieldResources,
                n =
                  this.data.defaultAppearanceData &&
                  this.data.defaultAppearanceData.fontName;
              if (!n) return t || s.Dict.empty;
              for (const e of [t, a])
                if (e instanceof s.Dict) {
                  const t = e.get("Font");
                  if (t instanceof s.Dict && t.has(n)) return e;
                }
              if (r instanceof s.Dict) {
                const a = r.get("Font");
                if (a instanceof s.Dict && a.has(n)) {
                  const r = new s.Dict(e);
                  r.set(n, a.getRaw(n));
                  const i = new s.Dict(e);
                  i.set("Font", r);
                  return s.Dict.merge({
                    xref: e,
                    dictArray: [i, t],
                    mergeSubDicts: !0,
                  });
                }
              }
              return t || s.Dict.empty;
            }
            getFieldObject() {
              return null;
            }
          }
          class TextWidgetAnnotation extends WidgetAnnotation {
            constructor(e) {
              super(e);
              this._hasText = !0;
              const t = e.dict;
              "string" != typeof this.data.fieldValue &&
                (this.data.fieldValue = "");
              let a = (0, n.getInheritableProperty)({ dict: t, key: "Q" });
              (!Number.isInteger(a) || a < 0 || a > 2) && (a = null);
              this.data.textAlignment = a;
              let i = (0, n.getInheritableProperty)({ dict: t, key: "MaxLen" });
              (!Number.isInteger(i) || i < 0) && (i = 0);
              this.data.maxLen = i;
              this.data.multiLine = this.hasFieldFlag(
                r.AnnotationFieldFlag.MULTILINE
              );
              this.data.comb =
                this.hasFieldFlag(r.AnnotationFieldFlag.COMB) &&
                !this.hasFieldFlag(r.AnnotationFieldFlag.MULTILINE) &&
                !this.hasFieldFlag(r.AnnotationFieldFlag.PASSWORD) &&
                !this.hasFieldFlag(r.AnnotationFieldFlag.FILESELECT) &&
                0 !== this.data.maxLen;
              this.data.doNotScroll = this.hasFieldFlag(
                r.AnnotationFieldFlag.DONOTSCROLL
              );
            }
            _getCombAppearance(e, t, a, r, i, s, o, c, l, h, u) {
              const d = i / this.data.maxLen,
                f = this.getBorderAndBackgroundAppearances(u),
                g = [],
                p = t.getCharPositions(a);
              for (const [e, t] of p)
                g.push(`(${(0, n.escapeString)(a.substring(e, t))}) Tj`);
              const m = g.join(` ${(0, n.numberToString)(d)} 0 Td `);
              return (
                `/Tx BMC q ${f}BT ` +
                e +
                ` 1 0 0 1 ${(0, n.numberToString)(o)} ${(0, n.numberToString)(
                  c + l
                )} Tm ${m} ET Q EMC`
              );
            }
            _getMultilineAppearance(e, t, a, r, i, s, o, c, l, h, u, d) {
              const f = [],
                g = i - 2 * c,
                p = { shift: 0 };
              for (let e = 0, n = t.length; e < n; e++) {
                const n = t[e],
                  s = this._splitLine(n, a, r, g);
                for (let t = 0, n = s.length; t < n; t++) {
                  const n = s[t],
                    d = 0 === e && 0 === t ? -l - (u - h) : -u;
                  f.push(this._renderText(n, a, r, i, o, p, c, d));
                }
              }
              const m = this.getBorderAndBackgroundAppearances(d),
                b = f.join("\n");
              return (
                `/Tx BMC q ${m}BT ` +
                e +
                ` 1 0 0 1 0 ${(0, n.numberToString)(s)} Tm ${b} ET Q EMC`
              );
            }
            _splitLine(e, t, a, r, n = {}) {
              e = n.line || e;
              const i = n.glyphs || t.charsToGlyphs(e);
              if (i.length <= 1) return [e];
              const s = n.positions || t.getCharPositions(e),
                o = a / 1e3,
                c = [];
              let l = -1,
                h = -1,
                u = -1,
                d = 0,
                f = 0;
              for (let t = 0, a = i.length; t < a; t++) {
                const [a, n] = s[t],
                  g = i[t],
                  p = g.width * o;
                if (" " === g.unicode)
                  if (f + p > r) {
                    c.push(e.substring(d, a));
                    d = a;
                    f = p;
                    l = -1;
                    u = -1;
                  } else {
                    f += p;
                    l = a;
                    h = n;
                    u = t;
                  }
                else if (f + p > r)
                  if (-1 !== l) {
                    c.push(e.substring(d, h));
                    d = h;
                    t = u + 1;
                    l = -1;
                    f = 0;
                  } else {
                    c.push(e.substring(d, a));
                    d = a;
                    f = p;
                  }
                else f += p;
              }
              d < e.length && c.push(e.substring(d, e.length));
              return c;
            }
            getFieldObject() {
              return {
                id: this.data.id,
                value: this.data.fieldValue,
                defaultValue: this.data.defaultFieldValue || "",
                multiline: this.data.multiLine,
                password: this.hasFieldFlag(r.AnnotationFieldFlag.PASSWORD),
                charLimit: this.data.maxLen,
                comb: this.data.comb,
                editable: !this.data.readOnly,
                hidden: this.data.hidden,
                name: this.data.fieldName,
                rect: this.data.rect,
                actions: this.data.actions,
                page: this.data.pageIndex,
                strokeColor: this.data.borderColor,
                fillColor: this.data.backgroundColor,
                rotation: this.rotation,
                type: "text",
              };
            }
          }
          class ButtonWidgetAnnotation extends WidgetAnnotation {
            constructor(e) {
              super(e);
              this.checkedAppearance = null;
              this.uncheckedAppearance = null;
              this.data.checkBox =
                !this.hasFieldFlag(r.AnnotationFieldFlag.RADIO) &&
                !this.hasFieldFlag(r.AnnotationFieldFlag.PUSHBUTTON);
              this.data.radioButton =
                this.hasFieldFlag(r.AnnotationFieldFlag.RADIO) &&
                !this.hasFieldFlag(r.AnnotationFieldFlag.PUSHBUTTON);
              this.data.pushButton = this.hasFieldFlag(
                r.AnnotationFieldFlag.PUSHBUTTON
              );
              this.data.isTooltipOnly = !1;
              if (this.data.checkBox) this._processCheckBox(e);
              else if (this.data.radioButton) this._processRadioButton(e);
              else if (this.data.pushButton) {
                this.data.hasOwnCanvas = !0;
                this._processPushButton(e);
              } else
                (0, r.warn)("Invalid field flags for button widget annotation");
            }
            async getOperatorList(e, t, a, n, i) {
              if (this.data.pushButton)
                return super.getOperatorList(e, t, a, !1, i);
              let s = null,
                o = null;
              if (i) {
                const e = i.get(this.data.id);
                s = e ? e.value : null;
                o = e ? e.rotation : null;
              }
              if (null === s && this.appearance)
                return super.getOperatorList(e, t, a, n, i);
              null == s &&
                (s = this.data.checkBox
                  ? this.data.fieldValue === this.data.exportValue
                  : this.data.fieldValue === this.data.buttonValue);
              const c = s ? this.checkedAppearance : this.uncheckedAppearance;
              if (c) {
                const s = this.appearance,
                  l = c.dict.getArray("Matrix") || r.IDENTITY_MATRIX;
                o && c.dict.set("Matrix", this.getRotationMatrix(i));
                this.appearance = c;
                const h = super.getOperatorList(e, t, a, n, i);
                this.appearance = s;
                c.dict.set("Matrix", l);
                return h;
              }
              return {
                opList: new g.OperatorList(),
                separateForm: !1,
                separateCanvas: !1,
              };
            }
            async save(e, t, a) {
              return this.data.checkBox
                ? this._saveCheckbox(e, t, a)
                : this.data.radioButton
                ? this._saveRadioButton(e, t, a)
                : null;
            }
            async _saveCheckbox(e, t, a) {
              if (!a) return null;
              const n = a.get(this.data.id);
              let i = n && n.rotation,
                c = n && n.value;
              if (void 0 === i) {
                if (void 0 === c) return null;
                if ((this.data.fieldValue === this.data.exportValue) === c)
                  return null;
              }
              const l = e.xref.fetchIfRef(this.ref);
              if (!(l instanceof s.Dict)) return null;
              void 0 === i && (i = this.rotation);
              void 0 === c &&
                (c = this.data.fieldValue === this.data.exportValue);
              const h = {
                  path: this.data.fieldName,
                  value: c ? this.data.exportValue : "",
                },
                u = s.Name.get(c ? this.data.exportValue : "Off");
              l.set("V", u);
              l.set("AS", u);
              l.set("M", `D:${(0, r.getModificationDate)()}`);
              const d = this._getMKDict(i);
              d && l.set("MK", d);
              const f = e.xref.encrypt;
              let g = null;
              f && (g = f.createCipherTransform(this.ref.num, this.ref.gen));
              const p = [`${this.ref.num} ${this.ref.gen} obj\n`];
              (0, o.writeDict)(l, p, g);
              p.push("\nendobj\n");
              return [{ ref: this.ref, data: p.join(""), xfa: h }];
            }
            async _saveRadioButton(e, t, a) {
              if (!a) return null;
              const n = a.get(this.data.id);
              let i = n && n.rotation,
                c = n && n.value;
              if (void 0 === i) {
                if (void 0 === c) return null;
                if ((this.data.fieldValue === this.data.buttonValue) === c)
                  return null;
              }
              const l = e.xref.fetchIfRef(this.ref);
              if (!(l instanceof s.Dict)) return null;
              void 0 === c &&
                (c = this.data.fieldValue === this.data.buttonValue);
              void 0 === i && (i = this.rotation);
              const h = {
                  path: this.data.fieldName,
                  value: c ? this.data.buttonValue : "",
                },
                u = s.Name.get(c ? this.data.buttonValue : "Off");
              let d = null;
              const f = e.xref.encrypt;
              if (c)
                if (this.parent instanceof s.Ref) {
                  const t = e.xref.fetch(this.parent);
                  let a = null;
                  f &&
                    (a = f.createCipherTransform(
                      this.parent.num,
                      this.parent.gen
                    ));
                  t.set("V", u);
                  d = [`${this.parent.num} ${this.parent.gen} obj\n`];
                  (0, o.writeDict)(t, d, a);
                  d.push("\nendobj\n");
                } else this.parent instanceof s.Dict && this.parent.set("V", u);
              l.set("AS", u);
              l.set("M", `D:${(0, r.getModificationDate)()}`);
              const g = this._getMKDict(i);
              g && l.set("MK", g);
              let p = null;
              f && (p = f.createCipherTransform(this.ref.num, this.ref.gen));
              const m = [`${this.ref.num} ${this.ref.gen} obj\n`];
              (0, o.writeDict)(l, m, p);
              m.push("\nendobj\n");
              const b = [{ ref: this.ref, data: m.join(""), xfa: h }];
              null !== d &&
                b.push({ ref: this.parent, data: d.join(""), xfa: null });
              return b;
            }
            _getDefaultCheckedAppearance(e, t) {
              const a = this.data.rect[2] - this.data.rect[0],
                i = this.data.rect[3] - this.data.rect[1],
                o = [0, 0, a, i],
                c = 0.8 * Math.min(a, i);
              let l, h;
              if ("check" === t) {
                l = { width: 0.755 * c, height: 0.705 * c };
                h = "3";
              } else if ("disc" === t) {
                l = { width: 0.791 * c, height: 0.705 * c };
                h = "l";
              } else
                (0, r.unreachable)(
                  `_getDefaultCheckedAppearance - unsupported type: ${t}`
                );
              const u = `q BT /PdfJsZaDb ${c} Tf 0 g ${(0, n.numberToString)(
                  (a - l.width) / 2
                )} ${(0, n.numberToString)(
                  (i - l.height) / 2
                )} Td (${h}) Tj ET Q`,
                d = new s.Dict(e.xref);
              d.set("FormType", 1);
              d.set("Subtype", s.Name.get("Form"));
              d.set("Type", s.Name.get("XObject"));
              d.set("BBox", o);
              d.set("Matrix", [1, 0, 0, 1, 0, 0]);
              d.set("Length", u.length);
              const f = new s.Dict(e.xref),
                g = new s.Dict(e.xref);
              g.set("PdfJsZaDb", this.fallbackFontDict);
              f.set("Font", g);
              d.set("Resources", f);
              this.checkedAppearance = new p.StringStream(u);
              this.checkedAppearance.dict = d;
              this._streams.push(this.checkedAppearance);
            }
            _processCheckBox(e) {
              const t = e.dict.get("AP");
              if (!(t instanceof s.Dict)) return;
              const a = t.get("N");
              if (!(a instanceof s.Dict)) return;
              const r = this._decodeFormValue(e.dict.get("AS"));
              "string" == typeof r && (this.data.fieldValue = r);
              const n =
                  null !== this.data.fieldValue &&
                  "Off" !== this.data.fieldValue
                    ? this.data.fieldValue
                    : "Yes",
                i = a.getKeys();
              if (0 === i.length) i.push("Off", n);
              else if (1 === i.length)
                "Off" === i[0] ? i.push(n) : i.unshift("Off");
              else if (i.includes(n)) {
                i.length = 0;
                i.push("Off", n);
              } else {
                const e = i.find((e) => "Off" !== e);
                i.length = 0;
                i.push("Off", e);
              }
              i.includes(this.data.fieldValue) ||
                (this.data.fieldValue = "Off");
              this.data.exportValue = i[1];
              const o = a.get(this.data.exportValue);
              this.checkedAppearance = o instanceof c.BaseStream ? o : null;
              const l = a.get("Off");
              this.uncheckedAppearance = l instanceof c.BaseStream ? l : null;
              this.checkedAppearance
                ? this._streams.push(this.checkedAppearance)
                : this._getDefaultCheckedAppearance(e, "check");
              this.uncheckedAppearance &&
                this._streams.push(this.uncheckedAppearance);
              this._fallbackFontDict = this.fallbackFontDict;
            }
            _processRadioButton(e) {
              this.data.fieldValue = this.data.buttonValue = null;
              const t = e.dict.get("Parent");
              if (t instanceof s.Dict) {
                this.parent = e.dict.getRaw("Parent");
                const a = t.get("V");
                a instanceof s.Name &&
                  (this.data.fieldValue = this._decodeFormValue(a));
              }
              const a = e.dict.get("AP");
              if (!(a instanceof s.Dict)) return;
              const r = a.get("N");
              if (!(r instanceof s.Dict)) return;
              for (const e of r.getKeys())
                if ("Off" !== e) {
                  this.data.buttonValue = this._decodeFormValue(e);
                  break;
                }
              const n = r.get(this.data.buttonValue);
              this.checkedAppearance = n instanceof c.BaseStream ? n : null;
              const i = r.get("Off");
              this.uncheckedAppearance = i instanceof c.BaseStream ? i : null;
              this.checkedAppearance
                ? this._streams.push(this.checkedAppearance)
                : this._getDefaultCheckedAppearance(e, "disc");
              this.uncheckedAppearance &&
                this._streams.push(this.uncheckedAppearance);
              this._fallbackFontDict = this.fallbackFontDict;
            }
            _processPushButton(e) {
              if (
                e.dict.has("A") ||
                e.dict.has("AA") ||
                this.data.alternativeText
              ) {
                this.data.isTooltipOnly = !e.dict.has("A") && !e.dict.has("AA");
                h.Catalog.parseDestDictionary({
                  destDict: e.dict,
                  resultObj: this.data,
                  docBaseUrl: e.pdfManager.docBaseUrl,
                  docAttachments: e.attachments,
                });
              } else
                (0, r.warn)(
                  "Push buttons without action dictionaries are not supported"
                );
            }
            getFieldObject() {
              let e,
                t = "button";
              if (this.data.checkBox) {
                t = "checkbox";
                e = this.data.exportValue;
              } else if (this.data.radioButton) {
                t = "radiobutton";
                e = this.data.buttonValue;
              }
              return {
                id: this.data.id,
                value: this.data.fieldValue || "Off",
                defaultValue: this.data.defaultFieldValue,
                exportValues: e,
                editable: !this.data.readOnly,
                name: this.data.fieldName,
                rect: this.data.rect,
                hidden: this.data.hidden,
                actions: this.data.actions,
                page: this.data.pageIndex,
                strokeColor: this.data.borderColor,
                fillColor: this.data.backgroundColor,
                rotation: this.rotation,
                type: t,
              };
            }
            get fallbackFontDict() {
              const e = new s.Dict();
              e.set("BaseFont", s.Name.get("ZapfDingbats"));
              e.set("Type", s.Name.get("FallbackType"));
              e.set("Subtype", s.Name.get("FallbackType"));
              e.set("Encoding", s.Name.get("ZapfDingbatsEncoding"));
              return (0, r.shadow)(this, "fallbackFontDict", e);
            }
          }
          class ChoiceWidgetAnnotation extends WidgetAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.indices = t.getArray("I");
              this.hasIndices =
                Array.isArray(this.indices) && this.indices.length > 0;
              this.data.options = [];
              const i = (0, n.getInheritableProperty)({ dict: t, key: "Opt" });
              if (Array.isArray(i))
                for (let e = 0, t = i.length; e < t; e++) {
                  const t = a.fetchIfRef(i[e]),
                    r = Array.isArray(t);
                  this.data.options[e] = {
                    exportValue: this._decodeFormValue(
                      r ? a.fetchIfRef(t[0]) : t
                    ),
                    displayValue: this._decodeFormValue(
                      r ? a.fetchIfRef(t[1]) : t
                    ),
                  };
                }
              if (this.hasIndices) {
                this.data.fieldValue = [];
                const e = this.data.options.length;
                for (const t of this.indices)
                  Number.isInteger(t) &&
                    t >= 0 &&
                    t < e &&
                    this.data.fieldValue.push(this.data.options[t].exportValue);
              } else
                "string" == typeof this.data.fieldValue
                  ? (this.data.fieldValue = [this.data.fieldValue])
                  : this.data.fieldValue || (this.data.fieldValue = []);
              this.data.combo = this.hasFieldFlag(r.AnnotationFieldFlag.COMBO);
              this.data.multiSelect = this.hasFieldFlag(
                r.AnnotationFieldFlag.MULTISELECT
              );
              this._hasText = !0;
            }
            getFieldObject() {
              const e = this.data.combo ? "combobox" : "listbox",
                t =
                  this.data.fieldValue.length > 0
                    ? this.data.fieldValue[0]
                    : null;
              return {
                id: this.data.id,
                value: t,
                defaultValue: this.data.defaultFieldValue,
                editable: !this.data.readOnly,
                name: this.data.fieldName,
                rect: this.data.rect,
                numItems: this.data.fieldValue.length,
                multipleSelection: this.data.multiSelect,
                hidden: this.data.hidden,
                actions: this.data.actions,
                items: this.data.options,
                page: this.data.pageIndex,
                strokeColor: this.data.borderColor,
                fillColor: this.data.backgroundColor,
                rotation: this.rotation,
                type: e,
              };
            }
            amendSavedDict(e, t) {
              if (!this.hasIndices) return;
              const a = e ? e.get(this.data.id) : void 0;
              let r = a && a.value;
              Array.isArray(r) || (r = [r]);
              const n = [],
                { options: i } = this.data;
              for (let e = 0, t = 0, a = i.length; e < a; e++)
                if (i[e].exportValue === r[t]) {
                  n.push(e);
                  t += 1;
                }
              t.set("I", n);
            }
            async _getAppearance(e, t, a, n) {
              if (this.data.combo) return super._getAppearance(e, t, a, n);
              let s, o;
              const c = n ? n.get(this.data.id) : void 0;
              if (c) {
                o = c.rotation;
                s = c.value;
              }
              if (void 0 === o && void 0 === s && !this._needAppearances)
                return null;
              void 0 === s
                ? (s = this.data.fieldValue)
                : Array.isArray(s) || (s = [s]);
              let l = this.data.rect[3] - this.data.rect[1],
                h = this.data.rect[2] - this.data.rect[0];
              (90 !== o && 270 !== o) || ([h, l] = [l, h]);
              const u = this.data.options.length,
                d = [];
              for (let e = 0; e < u; e++) {
                const { exportValue: t } = this.data.options[e];
                s.includes(t) && d.push(e);
              }
              this._defaultAppearance ||
                (this.data.defaultAppearanceData = (0,
                i.parseDefaultAppearance)(
                  (this._defaultAppearance = "/Helvetica 0 Tf 0 g")
                ));
              const f = await WidgetAnnotation._getFontData(
                e,
                t,
                this.data.defaultAppearanceData,
                this._fieldResources.mergedResources
              );
              let g,
                { fontSize: p } = this.data.defaultAppearanceData;
              if (p) g = this._defaultAppearance;
              else {
                const e = (l - 1) / u;
                let t,
                  a = -1;
                for (const { displayValue: e } of this.data.options) {
                  const r = this._getTextWidth(e, f);
                  if (r > a) {
                    a = r;
                    t = e;
                  }
                }
                [g, p] = this._computeFontSize(e, h - 4, t, f, -1);
              }
              const m = p * r.LINE_FACTOR,
                b = (m - p) / 2,
                y = Math.floor(l / m);
              let w;
              if (1 === d.length) {
                const e = d[0];
                w = e - (e % y);
              } else w = d.length ? d[0] : 0;
              const S = Math.min(w + y + 1, u),
                x = ["/Tx BMC q", `1 1 ${h} ${l} re W n`];
              if (d.length) {
                x.push("0.600006 0.756866 0.854904 rg");
                for (const e of d)
                  w <= e &&
                    e < S &&
                    x.push(`1 ${l - (e - w + 1) * m} ${h} ${m} re f`);
              }
              x.push("BT", g, `1 0 0 1 0 ${l} Tm`);
              const C = { shift: 0 };
              for (let e = w; e < S; e++) {
                const { displayValue: t } = this.data.options[e],
                  a = e === w ? b : 0;
                x.push(this._renderText(t, f, p, h, 0, C, 2, -m + a));
              }
              x.push("ET Q EMC");
              return x.join("\n");
            }
          }
          class SignatureWidgetAnnotation extends WidgetAnnotation {
            constructor(e) {
              super(e);
              this.data.fieldValue = null;
            }
            getFieldObject() {
              return {
                id: this.data.id,
                value: null,
                page: this.data.pageIndex,
                type: "signature",
              };
            }
          }
          class TextAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t } = e;
              this.data.annotationType = r.AnnotationType.TEXT;
              if (this.data.hasAppearance) this.data.name = "NoIcon";
              else {
                this.data.rect[1] = this.data.rect[3] - 22;
                this.data.rect[2] = this.data.rect[0] + 22;
                this.data.name = t.has("Name") ? t.get("Name").name : "Note";
              }
              if (t.has("State")) {
                this.data.state = t.get("State") || null;
                this.data.stateModel = t.get("StateModel") || null;
              } else {
                this.data.state = null;
                this.data.stateModel = null;
              }
            }
          }
          class LinkAnnotation extends Annotation {
            constructor(e) {
              super(e);
              this.data.annotationType = r.AnnotationType.LINK;
              const t = getQuadPoints(e.dict, this.rectangle);
              t && (this.data.quadPoints = t);
              this.data.borderColor = this.data.borderColor || this.data.color;
              h.Catalog.parseDestDictionary({
                destDict: e.dict,
                resultObj: this.data,
                docBaseUrl: e.pdfManager.docBaseUrl,
                docAttachments: e.attachments,
              });
            }
          }
          class PopupAnnotation extends Annotation {
            constructor(e) {
              super(e);
              const { dict: t } = e;
              this.data.annotationType = r.AnnotationType.POPUP;
              let a = t.get("Parent");
              if (!a) {
                (0, r.warn)(
                  "Popup annotation has a missing or invalid parent annotation."
                );
                return;
              }
              const n = a.get("Subtype");
              this.data.parentType = n instanceof s.Name ? n.name : null;
              const i = t.getRaw("Parent");
              this.data.parentId = i instanceof s.Ref ? i.toString() : null;
              const o = a.getArray("Rect");
              Array.isArray(o) && 4 === o.length
                ? (this.data.parentRect = r.Util.normalizeRect(o))
                : (this.data.parentRect = [0, 0, 0, 0]);
              const c = a.get("RT");
              (0, s.isName)(c, r.AnnotationReplyType.GROUP) &&
                (a = a.get("IRT"));
              if (a.has("M")) {
                this.setModificationDate(a.get("M"));
                this.data.modificationDate = this.modificationDate;
              } else this.data.modificationDate = null;
              if (a.has("C")) {
                this.setColor(a.getArray("C"));
                this.data.color = this.color;
              } else this.data.color = null;
              if (!this.viewable) {
                const e = a.get("F");
                this._isViewable(e) && this.setFlags(e);
              }
              this.setTitle(a.get("T"));
              this.data.titleObj = this._title;
              this.setContents(a.get("Contents"));
              this.data.contentsObj = this._contents;
              a.has("RC") &&
                (this.data.richText = m.XFAFactory.getRichTextAsHtml(
                  a.get("RC")
                ));
            }
          }
          t.PopupAnnotation = PopupAnnotation;
          class FreeTextAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { xref: t } = e;
              this.data.annotationType = r.AnnotationType.FREETEXT;
              this.setDefaultAppearance(e);
              if (!this.appearance && this._isOffscreenCanvasSupported) {
                const a = e.dict.get("CA"),
                  r = new i.FakeUnicodeFont(t, "sans-serif"),
                  n = this.data.defaultAppearanceData;
                this.appearance = r.createAppearance(
                  this._contents.str,
                  this.rectangle,
                  this.rotation,
                  n.fontSize || 10,
                  n.fontColor,
                  a
                );
                this._streams.push(
                  this.appearance,
                  i.FakeUnicodeFont.toUnicodeStream
                );
              } else
                this._isOffscreenCanvasSupported ||
                  (0, r.warn)(
                    "FreeTextAnnotation: OffscreenCanvas is not supported, annotation may not render correctly."
                  );
            }
            get hasTextContent() {
              return !!this.appearance;
            }
            static createNewDict(e, t, { apRef: a, ap: o }) {
              const {
                  color: c,
                  fontSize: l,
                  rect: h,
                  rotation: u,
                  user: d,
                  value: f,
                } = e,
                g = new s.Dict(t);
              g.set("Type", s.Name.get("Annot"));
              g.set("Subtype", s.Name.get("FreeText"));
              g.set("CreationDate", `D:${(0, r.getModificationDate)()}`);
              g.set("Rect", h);
              const p = `/Helv ${l} Tf ${(0, i.getPdfColor)(c, !0)}`;
              g.set("DA", p);
              g.set(
                "Contents",
                (0, n.isAscii)(f) ? f : (0, n.stringToUTF16String)(f, !0)
              );
              g.set("F", 4);
              g.set("Border", [0, 0, 0]);
              g.set("Rotate", u);
              d &&
                g.set(
                  "T",
                  (0, n.isAscii)(d) ? d : (0, n.stringToUTF16String)(d, !0)
                );
              if (a || o) {
                const e = new s.Dict(t);
                g.set("AP", e);
                a ? e.set("N", a) : e.set("N", o);
              }
              return g;
            }
            static async createNewAppearanceStream(e, t, a) {
              const { baseFontRef: o, evaluator: c, task: l } = a,
                { color: h, fontSize: u, rect: d, rotation: f, value: g } = e,
                m = new s.Dict(t),
                b = new s.Dict(t);
              if (o) b.set("Helv", o);
              else {
                const e = new s.Dict(t);
                e.set("BaseFont", s.Name.get("Helvetica"));
                e.set("Type", s.Name.get("Font"));
                e.set("Subtype", s.Name.get("Type1"));
                e.set("Encoding", s.Name.get("WinAnsiEncoding"));
                b.set("Helv", e);
              }
              m.set("Font", b);
              const y = await WidgetAnnotation._getFontData(
                  c,
                  l,
                  { fontName: "Helvetica", fontSize: u },
                  m
                ),
                [w, S, x, C] = d;
              let k = x - w,
                v = C - S;
              f % 180 != 0 && ([k, v] = [v, k]);
              const F = g.split("\n"),
                O = u / 1e3;
              let T = -1 / 0;
              const M = [];
              for (let e of F) {
                const t = y.encodeString(e);
                if (t.length > 1) return null;
                e = t.join("");
                M.push(e);
                let a = 0;
                const r = y.charsToGlyphs(e);
                for (const e of r) a += e.width * O;
                T = Math.max(T, a);
              }
              let E = 1;
              T > k && (E = k / T);
              let D = 1;
              const N = r.LINE_FACTOR * u,
                R = r.LINE_DESCENT_FACTOR * u,
                L = N * F.length;
              L > v && (D = v / L);
              const j = u * Math.min(E, D),
                $ = [
                  "q",
                  `0 0 ${(0, n.numberToString)(k)} ${(0, n.numberToString)(
                    v
                  )} re W n`,
                  "BT",
                  `1 0 0 1 0 ${(0, n.numberToString)(v + R)} Tm 0 Tc ${(0,
                  i.getPdfColor)(h, !0)}`,
                  `/Helv ${(0, n.numberToString)(j)} Tf`,
                ],
                _ = (0, n.numberToString)(N);
              for (const e of M)
                $.push(`0 -${_} Td (${(0, n.escapeString)(e)}) Tj`);
              $.push("ET", "Q");
              const U = $.join("\n"),
                X = new s.Dict(t);
              X.set("FormType", 1);
              X.set("Subtype", s.Name.get("Form"));
              X.set("Type", s.Name.get("XObject"));
              X.set("BBox", [0, 0, k, v]);
              X.set("Resources", m);
              if (f) {
                const e = (0, n.getRotationMatrix)(f, k, v);
                X.set("Matrix", e);
              }
              const H = new p.StringStream(U);
              H.dict = X;
              return H;
            }
          }
          class LineAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.LINE;
              const n = t.getArray("L");
              this.data.lineCoordinates = r.Util.normalizeRect(n);
              this.setLineEndings(t.getArray("LE"));
              this.data.lineEndings = this.lineEndings;
              if (!this.appearance) {
                const e = this.color ? getPdfColorArray(this.color) : [0, 0, 0],
                  i = t.get("CA"),
                  s = getRgbColor(t.getArray("IC"), null),
                  o = s ? getPdfColorArray(s) : null,
                  c = o ? i : null,
                  l = this.borderStyle.width || 1,
                  h = 2 * l,
                  u = [
                    this.data.lineCoordinates[0] - h,
                    this.data.lineCoordinates[1] - h,
                    this.data.lineCoordinates[2] + h,
                    this.data.lineCoordinates[3] + h,
                  ];
                r.Util.intersect(this.rectangle, u) || (this.rectangle = u);
                this._setDefaultAppearance({
                  xref: a,
                  extra: `${l} w`,
                  strokeColor: e,
                  fillColor: o,
                  strokeAlpha: i,
                  fillAlpha: c,
                  pointsCallback: (e, t) => {
                    e.push(`${n[0]} ${n[1]} m`, `${n[2]} ${n[3]} l`, "S");
                    return [t[0].x - l, t[1].x + l, t[3].y - l, t[1].y + l];
                  },
                });
              }
            }
          }
          class SquareAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.SQUARE;
              if (!this.appearance) {
                const e = this.color ? getPdfColorArray(this.color) : [0, 0, 0],
                  r = t.get("CA"),
                  n = getRgbColor(t.getArray("IC"), null),
                  i = n ? getPdfColorArray(n) : null,
                  s = i ? r : null;
                if (0 === this.borderStyle.width && !i) return;
                this._setDefaultAppearance({
                  xref: a,
                  extra: `${this.borderStyle.width} w`,
                  strokeColor: e,
                  fillColor: i,
                  strokeAlpha: r,
                  fillAlpha: s,
                  pointsCallback: (e, t) => {
                    const a = t[2].x + this.borderStyle.width / 2,
                      r = t[2].y + this.borderStyle.width / 2,
                      n = t[3].x - t[2].x - this.borderStyle.width,
                      s = t[1].y - t[3].y - this.borderStyle.width;
                    e.push(`${a} ${r} ${n} ${s} re`);
                    i ? e.push("B") : e.push("S");
                    return [t[0].x, t[1].x, t[3].y, t[1].y];
                  },
                });
              }
            }
          }
          class CircleAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.CIRCLE;
              if (!this.appearance) {
                const e = this.color ? getPdfColorArray(this.color) : [0, 0, 0],
                  r = t.get("CA"),
                  n = getRgbColor(t.getArray("IC"), null),
                  i = n ? getPdfColorArray(n) : null,
                  s = i ? r : null;
                if (0 === this.borderStyle.width && !i) return;
                const o = (4 / 3) * Math.tan(Math.PI / 8);
                this._setDefaultAppearance({
                  xref: a,
                  extra: `${this.borderStyle.width} w`,
                  strokeColor: e,
                  fillColor: i,
                  strokeAlpha: r,
                  fillAlpha: s,
                  pointsCallback: (e, t) => {
                    const a = t[0].x + this.borderStyle.width / 2,
                      r = t[0].y - this.borderStyle.width / 2,
                      n = t[3].x - this.borderStyle.width / 2,
                      s = t[3].y + this.borderStyle.width / 2,
                      c = a + (n - a) / 2,
                      l = r + (s - r) / 2,
                      h = ((n - a) / 2) * o,
                      u = ((s - r) / 2) * o;
                    e.push(
                      `${c} ${s} m`,
                      `${c + h} ${s} ${n} ${l + u} ${n} ${l} c`,
                      `${n} ${l - u} ${c + h} ${r} ${c} ${r} c`,
                      `${c - h} ${r} ${a} ${l - u} ${a} ${l} c`,
                      `${a} ${l + u} ${c - h} ${s} ${c} ${s} c`,
                      "h"
                    );
                    i ? e.push("B") : e.push("S");
                    return [t[0].x, t[1].x, t[3].y, t[1].y];
                  },
                });
              }
            }
          }
          class PolylineAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.POLYLINE;
              this.data.vertices = [];
              if (!(this instanceof PolygonAnnotation)) {
                this.setLineEndings(t.getArray("LE"));
                this.data.lineEndings = this.lineEndings;
              }
              const n = t.getArray("Vertices");
              if (Array.isArray(n)) {
                for (let e = 0, t = n.length; e < t; e += 2)
                  this.data.vertices.push({ x: n[e], y: n[e + 1] });
                if (!this.appearance) {
                  const e = this.color
                      ? getPdfColorArray(this.color)
                      : [0, 0, 0],
                    n = t.get("CA"),
                    i = this.borderStyle.width || 1,
                    s = 2 * i,
                    o = [1 / 0, 1 / 0, -1 / 0, -1 / 0];
                  for (const e of this.data.vertices) {
                    o[0] = Math.min(o[0], e.x - s);
                    o[1] = Math.min(o[1], e.y - s);
                    o[2] = Math.max(o[2], e.x + s);
                    o[3] = Math.max(o[3], e.y + s);
                  }
                  r.Util.intersect(this.rectangle, o) || (this.rectangle = o);
                  this._setDefaultAppearance({
                    xref: a,
                    extra: `${i} w`,
                    strokeColor: e,
                    strokeAlpha: n,
                    pointsCallback: (e, t) => {
                      const a = this.data.vertices;
                      for (let t = 0, r = a.length; t < r; t++)
                        e.push(`${a[t].x} ${a[t].y} ${0 === t ? "m" : "l"}`);
                      e.push("S");
                      return [t[0].x, t[1].x, t[3].y, t[1].y];
                    },
                  });
                }
              }
            }
          }
          class PolygonAnnotation extends PolylineAnnotation {
            constructor(e) {
              super(e);
              this.data.annotationType = r.AnnotationType.POLYGON;
            }
          }
          class CaretAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              this.data.annotationType = r.AnnotationType.CARET;
            }
          }
          class InkAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.INK;
              this.data.inkLists = [];
              const n = t.getArray("InkList");
              if (Array.isArray(n)) {
                for (let e = 0, t = n.length; e < t; ++e) {
                  this.data.inkLists.push([]);
                  for (let t = 0, r = n[e].length; t < r; t += 2)
                    this.data.inkLists[e].push({
                      x: a.fetchIfRef(n[e][t]),
                      y: a.fetchIfRef(n[e][t + 1]),
                    });
                }
                if (!this.appearance) {
                  const e = this.color
                      ? getPdfColorArray(this.color)
                      : [0, 0, 0],
                    n = t.get("CA"),
                    i = this.borderStyle.width || 1,
                    s = 2 * i,
                    o = [1 / 0, 1 / 0, -1 / 0, -1 / 0];
                  for (const e of this.data.inkLists)
                    for (const t of e) {
                      o[0] = Math.min(o[0], t.x - s);
                      o[1] = Math.min(o[1], t.y - s);
                      o[2] = Math.max(o[2], t.x + s);
                      o[3] = Math.max(o[3], t.y + s);
                    }
                  r.Util.intersect(this.rectangle, o) || (this.rectangle = o);
                  this._setDefaultAppearance({
                    xref: a,
                    extra: `${i} w`,
                    strokeColor: e,
                    strokeAlpha: n,
                    pointsCallback: (e, t) => {
                      for (const t of this.data.inkLists) {
                        for (let a = 0, r = t.length; a < r; a++)
                          e.push(`${t[a].x} ${t[a].y} ${0 === a ? "m" : "l"}`);
                        e.push("S");
                      }
                      return [t[0].x, t[1].x, t[3].y, t[1].y];
                    },
                  });
                }
              }
            }
            static createNewDict(e, t, { apRef: a, ap: n }) {
              const { paths: i, rect: o, rotation: c } = e,
                l = new s.Dict(t);
              l.set("Type", s.Name.get("Annot"));
              l.set("Subtype", s.Name.get("Ink"));
              l.set("CreationDate", `D:${(0, r.getModificationDate)()}`);
              l.set("Rect", o);
              l.set(
                "InkList",
                i.map((e) => e.points)
              );
              l.set("F", 4);
              l.set("Border", [0, 0, 0]);
              l.set("Rotate", c);
              const h = new s.Dict(t);
              l.set("AP", h);
              a ? h.set("N", a) : h.set("N", n);
              return l;
            }
            static async createNewAppearanceStream(e, t, a) {
              const {
                  color: r,
                  rect: o,
                  rotation: c,
                  paths: l,
                  thickness: h,
                  opacity: u,
                } = e,
                [d, f, g, m] = o;
              let b = g - d,
                y = m - f;
              c % 180 != 0 && ([b, y] = [y, b]);
              const w = [`${h} w 1 J 1 j`, `${(0, i.getPdfColor)(r, !1)}`];
              1 !== u && w.push("/R0 gs");
              const S = [];
              for (const { bezier: e } of l) {
                S.length = 0;
                S.push(
                  `${(0, n.numberToString)(e[0])} ${(0, n.numberToString)(
                    e[1]
                  )} m`
                );
                for (let t = 2, a = e.length; t < a; t += 6) {
                  const a = e
                    .slice(t, t + 6)
                    .map(n.numberToString)
                    .join(" ");
                  S.push(`${a} c`);
                }
                S.push("S");
                w.push(S.join("\n"));
              }
              const x = w.join("\n"),
                C = new s.Dict(t);
              C.set("FormType", 1);
              C.set("Subtype", s.Name.get("Form"));
              C.set("Type", s.Name.get("XObject"));
              C.set("BBox", [0, 0, b, y]);
              C.set("Length", x.length);
              if (c) {
                const e = (0, n.getRotationMatrix)(c, b, y);
                C.set("Matrix", e);
              }
              if (1 !== u) {
                const e = new s.Dict(t),
                  a = new s.Dict(t),
                  r = new s.Dict(t);
                r.set("CA", u);
                r.set("Type", s.Name.get("ExtGState"));
                a.set("R0", r);
                e.set("ExtGState", a);
                C.set("Resources", e);
              }
              const k = new p.StringStream(x);
              k.dict = C;
              return k;
            }
          }
          class HighlightAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.HIGHLIGHT;
              if ((this.data.quadPoints = getQuadPoints(t, null))) {
                const e =
                  this.appearance && this.appearance.dict.get("Resources");
                if (!this.appearance || !e || !e.has("ExtGState")) {
                  this.appearance &&
                    (0, r.warn)(
                      "HighlightAnnotation - ignoring built-in appearance stream."
                    );
                  const e = this.color
                      ? getPdfColorArray(this.color)
                      : [1, 1, 0],
                    n = t.get("CA");
                  this._setDefaultAppearance({
                    xref: a,
                    fillColor: e,
                    blendMode: "Multiply",
                    fillAlpha: n,
                    pointsCallback: (e, t) => {
                      e.push(
                        `${t[0].x} ${t[0].y} m`,
                        `${t[1].x} ${t[1].y} l`,
                        `${t[3].x} ${t[3].y} l`,
                        `${t[2].x} ${t[2].y} l`,
                        "f"
                      );
                      return [t[0].x, t[1].x, t[3].y, t[1].y];
                    },
                  });
                }
              } else this.data.hasPopup = !1;
            }
          }
          class UnderlineAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.UNDERLINE;
              if ((this.data.quadPoints = getQuadPoints(t, null))) {
                if (!this.appearance) {
                  const e = this.color
                      ? getPdfColorArray(this.color)
                      : [0, 0, 0],
                    r = t.get("CA");
                  this._setDefaultAppearance({
                    xref: a,
                    extra: "[] 0 d 1 w",
                    strokeColor: e,
                    strokeAlpha: r,
                    pointsCallback: (e, t) => {
                      e.push(
                        `${t[2].x} ${t[2].y} m`,
                        `${t[3].x} ${t[3].y} l`,
                        "S"
                      );
                      return [t[0].x, t[1].x, t[3].y, t[1].y];
                    },
                  });
                }
              } else this.data.hasPopup = !1;
            }
          }
          class SquigglyAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.SQUIGGLY;
              if ((this.data.quadPoints = getQuadPoints(t, null))) {
                if (!this.appearance) {
                  const e = this.color
                      ? getPdfColorArray(this.color)
                      : [0, 0, 0],
                    r = t.get("CA");
                  this._setDefaultAppearance({
                    xref: a,
                    extra: "[] 0 d 1 w",
                    strokeColor: e,
                    strokeAlpha: r,
                    pointsCallback: (e, t) => {
                      const a = (t[0].y - t[2].y) / 6;
                      let r = a,
                        n = t[2].x;
                      const i = t[2].y,
                        s = t[3].x;
                      e.push(`${n} ${i + r} m`);
                      do {
                        n += 2;
                        r = 0 === r ? a : 0;
                        e.push(`${n} ${i + r} l`);
                      } while (n < s);
                      e.push("S");
                      return [t[2].x, s, i - 2 * a, i + 2 * a];
                    },
                  });
                }
              } else this.data.hasPopup = !1;
            }
          }
          class StrikeOutAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e;
              this.data.annotationType = r.AnnotationType.STRIKEOUT;
              if ((this.data.quadPoints = getQuadPoints(t, null))) {
                if (!this.appearance) {
                  const e = this.color
                      ? getPdfColorArray(this.color)
                      : [0, 0, 0],
                    r = t.get("CA");
                  this._setDefaultAppearance({
                    xref: a,
                    extra: "[] 0 d 1 w",
                    strokeColor: e,
                    strokeAlpha: r,
                    pointsCallback: (e, t) => {
                      e.push(
                        (t[0].x + t[2].x) / 2 +
                          " " +
                          (t[0].y + t[2].y) / 2 +
                          " m",
                        (t[1].x + t[3].x) / 2 +
                          " " +
                          (t[1].y + t[3].y) / 2 +
                          " l",
                        "S"
                      );
                      return [t[0].x, t[1].x, t[3].y, t[1].y];
                    },
                  });
                }
              } else this.data.hasPopup = !1;
            }
          }
          class StampAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              this.data.annotationType = r.AnnotationType.STAMP;
            }
          }
          class FileAttachmentAnnotation extends MarkupAnnotation {
            constructor(e) {
              super(e);
              const { dict: t, xref: a } = e,
                n = new d.FileSpec(t.get("FS"), a);
              this.data.annotationType = r.AnnotationType.FILEATTACHMENT;
              this.data.file = n.serializable;
              const i = t.get("Name");
              this.data.name =
                i instanceof s.Name
                  ? (0, r.stringToPDFString)(i.name)
                  : "PushPin";
            }
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.FakeUnicodeFont = void 0;
          t.createDefaultAppearance = function createDefaultAppearance({
            fontSize: e,
            fontName: t,
            fontColor: a,
          }) {
            return `/${(0, n.escapePDFName)(t)} ${e} Tf ${getPdfColor(a, !0)}`;
          };
          t.getPdfColor = getPdfColor;
          t.parseDefaultAppearance = function parseDefaultAppearance(e) {
            return new DefaultAppearanceEvaluator(e).parse();
          };
          var r = a(4),
            n = a(3),
            i = a(2),
            s = a(12),
            o = a(13),
            c = a(8);
          class DefaultAppearanceEvaluator extends o.EvaluatorPreprocessor {
            constructor(e) {
              super(new c.StringStream(e));
            }
            parse() {
              const e = { fn: 0, args: [] },
                t = {
                  fontSize: 0,
                  fontName: "",
                  fontColor: new Uint8ClampedArray(3),
                };
              try {
                for (;;) {
                  e.args.length = 0;
                  if (!this.read(e)) break;
                  if (0 !== this.savedStatesDepth) continue;
                  const { fn: a, args: n } = e;
                  switch (0 | a) {
                    case i.OPS.setFont:
                      const [e, a] = n;
                      e instanceof r.Name && (t.fontName = e.name);
                      "number" == typeof a && a > 0 && (t.fontSize = a);
                      break;
                    case i.OPS.setFillRGBColor:
                      s.ColorSpace.singletons.rgb.getRgbItem(
                        n,
                        0,
                        t.fontColor,
                        0
                      );
                      break;
                    case i.OPS.setFillGray:
                      s.ColorSpace.singletons.gray.getRgbItem(
                        n,
                        0,
                        t.fontColor,
                        0
                      );
                      break;
                    case i.OPS.setFillColorSpace:
                      s.ColorSpace.singletons.cmyk.getRgbItem(
                        n,
                        0,
                        t.fontColor,
                        0
                      );
                  }
                }
              } catch (e) {
                (0, i.warn)(
                  `parseDefaultAppearance - ignoring errors: "${e}".`
                );
              }
              return t;
            }
          }
          function getPdfColor(e, t) {
            if (e[0] === e[1] && e[1] === e[2]) {
              const a = e[0] / 255;
              return `${(0, n.numberToString)(a)} ${t ? "g" : "G"}`;
            }
            return (
              Array.from(e, (e) => (0, n.numberToString)(e / 255)).join(" ") +
              " " +
              (t ? "rg" : "RG")
            );
          }
          class FakeUnicodeFont {
            constructor(e, t) {
              this.xref = e;
              this.widths = null;
              this.firstChar = 1 / 0;
              this.lastChar = -1 / 0;
              this.fontFamily = t;
              const a = new OffscreenCanvas(1, 1);
              this.ctxMeasure = a.getContext("2d");
              FakeUnicodeFont._fontNameId || (FakeUnicodeFont._fontNameId = 1);
              this.fontName = r.Name.get(
                `InvalidPDFjsFont_${t}_${FakeUnicodeFont._fontNameId++}`
              );
            }
            get toUnicodeRef() {
              if (!FakeUnicodeFont._toUnicodeRef) {
                const e =
                    "/CIDInit /ProcSet findresource begin\n12 dict begin\nbegincmap\n/CIDSystemInfo\n<< /Registry (Adobe)\n/Ordering (UCS) /Supplement 0 >> def\n/CMapName /Adobe-Identity-UCS def\n/CMapType 2 def\n1 begincodespacerange\n<0000> <FFFF>\nendcodespacerange\n1 beginbfrange\n<0000> <FFFF> <0000>\nendbfrange\nendcmap CMapName currentdict /CMap defineresource pop end end",
                  t = (FakeUnicodeFont.toUnicodeStream = new c.StringStream(e)),
                  a = new r.Dict(this.xref);
                t.dict = a;
                a.set("Length", e.length);
                FakeUnicodeFont._toUnicodeRef =
                  this.xref.getNewPersistentRef(t);
              }
              return FakeUnicodeFont._toUnicodeRef;
            }
            get fontDescriptorRef() {
              if (!FakeUnicodeFont._fontDescriptorRef) {
                const e = new r.Dict(this.xref);
                e.set("Type", r.Name.get("FontDescriptor"));
                e.set("FontName", this.fontName);
                e.set("FontFamily", "MyriadPro Regular");
                e.set("FontBBox", [0, 0, 0, 0]);
                e.set("FontStretch", r.Name.get("Normal"));
                e.set("FontWeight", 400);
                e.set("ItalicAngle", 0);
                FakeUnicodeFont._fontDescriptorRef =
                  this.xref.getNewPersistentRef(e);
              }
              return FakeUnicodeFont._fontDescriptorRef;
            }
            get descendantFontRef() {
              const e = new r.Dict(this.xref);
              e.set("BaseFont", this.fontName);
              e.set("Type", r.Name.get("Font"));
              e.set("Subtype", r.Name.get("CIDFontType0"));
              e.set("CIDToGIDMap", r.Name.get("Identity"));
              e.set("FirstChar", this.firstChar);
              e.set("LastChar", this.lastChar);
              e.set("FontDescriptor", this.fontDescriptorRef);
              e.set("DW", 1e3);
              const t = [],
                a = [...this.widths.entries()].sort();
              let n = null,
                i = null;
              for (const [e, r] of a)
                if (n)
                  if (e === n + i.length) i.push(r);
                  else {
                    t.push(n, i);
                    n = e;
                    i = [r];
                  }
                else {
                  n = e;
                  i = [r];
                }
              n && t.push(n, i);
              e.set("W", t);
              const s = new r.Dict(this.xref);
              s.set("Ordering", "Identity");
              s.set("Registry", "Adobe");
              s.set("Supplement", 0);
              e.set("CIDSystemInfo", s);
              return this.xref.getNewPersistentRef(e);
            }
            get baseFontRef() {
              const e = new r.Dict(this.xref);
              e.set("BaseFont", this.fontName);
              e.set("Type", r.Name.get("Font"));
              e.set("Subtype", r.Name.get("Type0"));
              e.set("Encoding", r.Name.get("Identity-H"));
              e.set("DescendantFonts", [this.descendantFontRef]);
              e.set("ToUnicode", this.toUnicodeRef);
              return this.xref.getNewPersistentRef(e);
            }
            get resources() {
              const e = new r.Dict(this.xref),
                t = new r.Dict(this.xref);
              t.set(this.fontName.name, this.baseFontRef);
              e.set("Font", t);
              return e;
            }
            _createContext() {
              this.widths = new Map();
              this.ctxMeasure.font = `1000px ${this.fontFamily}`;
              return this.ctxMeasure;
            }
            createFontResources(e) {
              const t = this._createContext();
              for (const a of e.split(/\r\n?|\n/))
                for (const e of a.split("")) {
                  const a = e.charCodeAt(0);
                  if (this.widths.has(a)) continue;
                  const r = t.measureText(e),
                    n = Math.ceil(r.width);
                  this.widths.set(a, n);
                  this.firstChar = Math.min(a, this.firstChar);
                  this.lastChar = Math.max(a, this.lastChar);
                }
              return this.resources;
            }
            createAppearance(e, t, a, s, o, l) {
              const h = this._createContext(),
                u = [];
              let d = -1 / 0;
              for (const t of e.split(/\r\n?|\n/)) {
                u.push(t);
                const e = h.measureText(t).width;
                d = Math.max(d, e);
                for (const e of t.split("")) {
                  const t = e.charCodeAt(0);
                  let a = this.widths.get(t);
                  if (void 0 === a) {
                    const r = h.measureText(e);
                    a = Math.ceil(r.width);
                    this.widths.set(t, a);
                    this.firstChar = Math.min(t, this.firstChar);
                    this.lastChar = Math.max(t, this.lastChar);
                  }
                }
              }
              d *= s / 1e3;
              const [f, g, p, m] = t;
              let b = p - f,
                y = m - g;
              a % 180 != 0 && ([b, y] = [y, b]);
              let w = 1;
              d > b && (w = b / d);
              let S = 1;
              const x = i.LINE_FACTOR * s,
                C = i.LINE_DESCENT_FACTOR * s,
                k = x * u.length;
              k > y && (S = y / k);
              const v = s * Math.min(w, S),
                F = [
                  "q",
                  `0 0 ${(0, n.numberToString)(b)} ${(0, n.numberToString)(
                    y
                  )} re W n`,
                  "BT",
                  `1 0 0 1 0 ${(0, n.numberToString)(
                    y + C
                  )} Tm 0 Tc ${getPdfColor(o, !0)}`,
                  `/${this.fontName.name} ${(0, n.numberToString)(v)} Tf`,
                ],
                { resources: O } = this;
              if (
                1 !== (l = "number" == typeof l && l >= 0 && l <= 1 ? l : 1)
              ) {
                F.push("/R0 gs");
                const e = new r.Dict(this.xref),
                  t = new r.Dict(this.xref);
                t.set("ca", l);
                t.set("CA", l);
                t.set("Type", r.Name.get("ExtGState"));
                e.set("R0", t);
                O.set("ExtGState", e);
              }
              const T = (0, n.numberToString)(x);
              for (const e of u)
                F.push(`0 -${T} Td <${(0, n.stringToUTF16HexString)(e)}> Tj`);
              F.push("ET", "Q");
              const M = F.join("\n"),
                E = new r.Dict(this.xref);
              E.set("Subtype", r.Name.get("Form"));
              E.set("Type", r.Name.get("XObject"));
              E.set("BBox", [0, 0, b, y]);
              E.set("Length", M.length);
              E.set("Resources", O);
              if (a) {
                const e = (0, n.getRotationMatrix)(a, b, y);
                E.set("Matrix", e);
              }
              const D = new c.StringStream(M);
              D.dict = E;
              return D;
            }
          }
          t.FakeUnicodeFont = FakeUnicodeFont;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ColorSpace = void 0;
          var r = a(2),
            n = a(4),
            i = a(5),
            s = a(3);
          class ColorSpace {
            constructor(e, t) {
              this.constructor === ColorSpace &&
                (0, r.unreachable)("Cannot initialize ColorSpace.");
              this.name = e;
              this.numComps = t;
            }
            getRgb(e, t) {
              const a = new Uint8ClampedArray(3);
              this.getRgbItem(e, t, a, 0);
              return a;
            }
            getRgbItem(e, t, a, n) {
              (0, r.unreachable)("Should not call ColorSpace.getRgbItem");
            }
            getRgbBuffer(e, t, a, n, i, s, o) {
              (0, r.unreachable)("Should not call ColorSpace.getRgbBuffer");
            }
            getOutputLength(e, t) {
              (0, r.unreachable)("Should not call ColorSpace.getOutputLength");
            }
            isPassthrough(e) {
              return !1;
            }
            isDefaultDecode(e, t) {
              return ColorSpace.isDefaultDecode(e, this.numComps);
            }
            fillRgb(e, t, a, r, n, i, s, o, c) {
              const l = t * a;
              let h = null;
              const u = 1 << s,
                d = a !== n || t !== r;
              if (this.isPassthrough(s)) h = o;
              else if (
                1 === this.numComps &&
                l > u &&
                "DeviceGray" !== this.name &&
                "DeviceRGB" !== this.name
              ) {
                const t = s <= 8 ? new Uint8Array(u) : new Uint16Array(u);
                for (let e = 0; e < u; e++) t[e] = e;
                const a = new Uint8ClampedArray(3 * u);
                this.getRgbBuffer(t, 0, u, a, 0, s, 0);
                if (d) {
                  h = new Uint8Array(3 * l);
                  let e = 0;
                  for (let t = 0; t < l; ++t) {
                    const r = 3 * o[t];
                    h[e++] = a[r];
                    h[e++] = a[r + 1];
                    h[e++] = a[r + 2];
                  }
                } else {
                  let t = 0;
                  for (let r = 0; r < l; ++r) {
                    const n = 3 * o[r];
                    e[t++] = a[n];
                    e[t++] = a[n + 1];
                    e[t++] = a[n + 2];
                    t += c;
                  }
                }
              } else if (d) {
                h = new Uint8ClampedArray(3 * l);
                this.getRgbBuffer(o, 0, l, h, 0, s, 0);
              } else this.getRgbBuffer(o, 0, r * i, e, 0, s, c);
              if (h)
                if (d)
                  !(function resizeRgbImage(e, t, a, r, n, i, s) {
                    s = 1 !== s ? 0 : s;
                    const o = a / n,
                      c = r / i;
                    let l,
                      h = 0;
                    const u = new Uint16Array(n),
                      d = 3 * a;
                    for (let e = 0; e < n; e++) u[e] = 3 * Math.floor(e * o);
                    for (let a = 0; a < i; a++) {
                      const r = Math.floor(a * c) * d;
                      for (let a = 0; a < n; a++) {
                        l = r + u[a];
                        t[h++] = e[l++];
                        t[h++] = e[l++];
                        t[h++] = e[l++];
                        h += s;
                      }
                    }
                  })(h, e, t, a, r, n, c);
                else {
                  let t = 0,
                    a = 0;
                  for (let n = 0, s = r * i; n < s; n++) {
                    e[t++] = h[a++];
                    e[t++] = h[a++];
                    e[t++] = h[a++];
                    t += c;
                  }
                }
            }
            get usesZeroToOneRange() {
              return (0, r.shadow)(this, "usesZeroToOneRange", !0);
            }
            static _cache(e, t, a, r) {
              if (!a)
                throw new Error(
                  'ColorSpace._cache - expected "localColorSpaceCache" argument.'
                );
              if (!r)
                throw new Error(
                  'ColorSpace._cache - expected "parsedColorSpace" argument.'
                );
              let i, s;
              if (e instanceof n.Ref) {
                s = e;
                e = t.fetch(e);
              }
              e instanceof n.Name && (i = e.name);
              (i || s) && a.set(i, s, r);
            }
            static getCached(e, t, a) {
              if (!a)
                throw new Error(
                  'ColorSpace.getCached - expected "localColorSpaceCache" argument.'
                );
              if (e instanceof n.Ref) {
                const r = a.getByRef(e);
                if (r) return r;
                try {
                  e = t.fetch(e);
                } catch (e) {
                  if (e instanceof s.MissingDataException) throw e;
                }
              }
              if (e instanceof n.Name) {
                const t = a.getByName(e.name);
                if (t) return t;
              }
              return null;
            }
            static async parseAsync({
              cs: e,
              xref: t,
              resources: a = null,
              pdfFunctionFactory: r,
              localColorSpaceCache: n,
            }) {
              const i = this._parse(e, t, a, r);
              this._cache(e, t, n, i);
              return i;
            }
            static parse({
              cs: e,
              xref: t,
              resources: a = null,
              pdfFunctionFactory: r,
              localColorSpaceCache: n,
            }) {
              const i = this.getCached(e, t, n);
              if (i) return i;
              const s = this._parse(e, t, a, r);
              this._cache(e, t, n, s);
              return s;
            }
            static _parse(e, t, a = null, i) {
              if ((e = t.fetchIfRef(e)) instanceof n.Name)
                switch (e.name) {
                  case "G":
                  case "DeviceGray":
                    return this.singletons.gray;
                  case "RGB":
                  case "DeviceRGB":
                    return this.singletons.rgb;
                  case "CMYK":
                  case "DeviceCMYK":
                    return this.singletons.cmyk;
                  case "Pattern":
                    return new PatternCS(null);
                  default:
                    if (a instanceof n.Dict) {
                      const r = a.get("ColorSpace");
                      if (r instanceof n.Dict) {
                        const s = r.get(e.name);
                        if (s) {
                          if (s instanceof n.Name)
                            return this._parse(s, t, a, i);
                          e = s;
                          break;
                        }
                      }
                    }
                    throw new r.FormatError(
                      `Unrecognized ColorSpace: ${e.name}`
                    );
                }
              if (Array.isArray(e)) {
                const n = t.fetchIfRef(e[0]).name;
                let s, o, u, d, f, g;
                switch (n) {
                  case "G":
                  case "DeviceGray":
                    return this.singletons.gray;
                  case "RGB":
                  case "DeviceRGB":
                    return this.singletons.rgb;
                  case "CMYK":
                  case "DeviceCMYK":
                    return this.singletons.cmyk;
                  case "CalGray":
                    s = t.fetchIfRef(e[1]);
                    d = s.getArray("WhitePoint");
                    f = s.getArray("BlackPoint");
                    g = s.get("Gamma");
                    return new c(d, f, g);
                  case "CalRGB":
                    s = t.fetchIfRef(e[1]);
                    d = s.getArray("WhitePoint");
                    f = s.getArray("BlackPoint");
                    g = s.getArray("Gamma");
                    const p = s.getArray("Matrix");
                    return new l(d, f, g, p);
                  case "ICCBased":
                    const m = t.fetchIfRef(e[1]).dict;
                    o = m.get("N");
                    const b = m.get("Alternate");
                    if (b) {
                      const e = this._parse(b, t, a, i);
                      if (e.numComps === o) return e;
                      (0, r.warn)(
                        "ICCBased color space: Ignoring incorrect /Alternate entry."
                      );
                    }
                    if (1 === o) return this.singletons.gray;
                    if (3 === o) return this.singletons.rgb;
                    if (4 === o) return this.singletons.cmyk;
                    break;
                  case "Pattern":
                    u = e[1] || null;
                    u && (u = this._parse(u, t, a, i));
                    return new PatternCS(u);
                  case "I":
                  case "Indexed":
                    u = this._parse(e[1], t, a, i);
                    const y = t.fetchIfRef(e[2]) + 1,
                      w = t.fetchIfRef(e[3]);
                    return new IndexedCS(u, y, w);
                  case "Separation":
                  case "DeviceN":
                    const S = t.fetchIfRef(e[1]);
                    o = Array.isArray(S) ? S.length : 1;
                    u = this._parse(e[2], t, a, i);
                    const x = i.create(e[3]);
                    return new AlternateCS(o, u, x);
                  case "Lab":
                    s = t.fetchIfRef(e[1]);
                    d = s.getArray("WhitePoint");
                    f = s.getArray("BlackPoint");
                    const C = s.getArray("Range");
                    return new h(d, f, C);
                  default:
                    throw new r.FormatError(
                      `Unimplemented ColorSpace object: ${n}`
                    );
                }
              }
              throw new r.FormatError(`Unrecognized ColorSpace object: ${e}`);
            }
            static isDefaultDecode(e, t) {
              if (!Array.isArray(e)) return !0;
              if (2 * t !== e.length) {
                (0, r.warn)("The decode map is not the correct length");
                return !0;
              }
              for (let t = 0, a = e.length; t < a; t += 2)
                if (0 !== e[t] || 1 !== e[t + 1]) return !1;
              return !0;
            }
            static get singletons() {
              return (0, r.shadow)(this, "singletons", {
                get gray() {
                  return (0, r.shadow)(this, "gray", new DeviceGrayCS());
                },
                get rgb() {
                  return (0, r.shadow)(this, "rgb", new DeviceRgbCS());
                },
                get cmyk() {
                  return (0, r.shadow)(this, "cmyk", new o());
                },
              });
            }
          }
          t.ColorSpace = ColorSpace;
          class AlternateCS extends ColorSpace {
            constructor(e, t, a) {
              super("Alternate", e);
              this.base = t;
              this.tintFn = a;
              this.tmpBuf = new Float32Array(t.numComps);
            }
            getRgbItem(e, t, a, r) {
              const n = this.tmpBuf;
              this.tintFn(e, t, n, 0);
              this.base.getRgbItem(n, 0, a, r);
            }
            getRgbBuffer(e, t, a, r, n, i, s) {
              const o = this.tintFn,
                c = this.base,
                l = 1 / ((1 << i) - 1),
                h = c.numComps,
                u = c.usesZeroToOneRange,
                d = (c.isPassthrough(8) || !u) && 0 === s;
              let f = d ? n : 0;
              const g = d ? r : new Uint8ClampedArray(h * a),
                p = this.numComps,
                m = new Float32Array(p),
                b = new Float32Array(h);
              let y, w;
              for (y = 0; y < a; y++) {
                for (w = 0; w < p; w++) m[w] = e[t++] * l;
                o(m, 0, b, 0);
                if (u) for (w = 0; w < h; w++) g[f++] = 255 * b[w];
                else {
                  c.getRgbItem(b, 0, g, f);
                  f += h;
                }
              }
              d || c.getRgbBuffer(g, 0, a, r, n, 8, s);
            }
            getOutputLength(e, t) {
              return this.base.getOutputLength(
                (e * this.base.numComps) / this.numComps,
                t
              );
            }
          }
          class PatternCS extends ColorSpace {
            constructor(e) {
              super("Pattern", null);
              this.base = e;
            }
            isDefaultDecode(e, t) {
              (0, r.unreachable)("Should not call PatternCS.isDefaultDecode");
            }
          }
          class IndexedCS extends ColorSpace {
            constructor(e, t, a) {
              super("Indexed", 1);
              this.base = e;
              this.highVal = t;
              const n = e.numComps * t;
              this.lookup = new Uint8Array(n);
              if (a instanceof i.BaseStream) {
                const e = a.getBytes(n);
                this.lookup.set(e);
              } else {
                if ("string" != typeof a)
                  throw new r.FormatError(
                    `IndexedCS - unrecognized lookup table: ${a}`
                  );
                for (let e = 0; e < n; ++e)
                  this.lookup[e] = 255 & a.charCodeAt(e);
              }
            }
            getRgbItem(e, t, a, r) {
              const n = this.base.numComps,
                i = e[t] * n;
              this.base.getRgbBuffer(this.lookup, i, 1, a, r, 8, 0);
            }
            getRgbBuffer(e, t, a, r, n, i, s) {
              const o = this.base,
                c = o.numComps,
                l = o.getOutputLength(c, s),
                h = this.lookup;
              for (let i = 0; i < a; ++i) {
                const a = e[t++] * c;
                o.getRgbBuffer(h, a, 1, r, n, 8, s);
                n += l;
              }
            }
            getOutputLength(e, t) {
              return this.base.getOutputLength(e * this.base.numComps, t);
            }
            isDefaultDecode(e, t) {
              if (!Array.isArray(e)) return !0;
              if (2 !== e.length) {
                (0, r.warn)("Decode map length is not correct");
                return !0;
              }
              if (!Number.isInteger(t) || t < 1) {
                (0, r.warn)("Bits per component is not correct");
                return !0;
              }
              return 0 === e[0] && e[1] === (1 << t) - 1;
            }
          }
          class DeviceGrayCS extends ColorSpace {
            constructor() {
              super("DeviceGray", 1);
            }
            getRgbItem(e, t, a, r) {
              const n = 255 * e[t];
              a[r] = a[r + 1] = a[r + 2] = n;
            }
            getRgbBuffer(e, t, a, r, n, i, s) {
              const o = 255 / ((1 << i) - 1);
              let c = t,
                l = n;
              for (let t = 0; t < a; ++t) {
                const t = o * e[c++];
                r[l++] = t;
                r[l++] = t;
                r[l++] = t;
                l += s;
              }
            }
            getOutputLength(e, t) {
              return e * (3 + t);
            }
          }
          class DeviceRgbCS extends ColorSpace {
            constructor() {
              super("DeviceRGB", 3);
            }
            getRgbItem(e, t, a, r) {
              a[r] = 255 * e[t];
              a[r + 1] = 255 * e[t + 1];
              a[r + 2] = 255 * e[t + 2];
            }
            getRgbBuffer(e, t, a, r, n, i, s) {
              if (8 === i && 0 === s) {
                r.set(e.subarray(t, t + 3 * a), n);
                return;
              }
              const o = 255 / ((1 << i) - 1);
              let c = t,
                l = n;
              for (let t = 0; t < a; ++t) {
                r[l++] = o * e[c++];
                r[l++] = o * e[c++];
                r[l++] = o * e[c++];
                l += s;
              }
            }
            getOutputLength(e, t) {
              return ((e * (3 + t)) / 3) | 0;
            }
            isPassthrough(e) {
              return 8 === e;
            }
          }
          const o = (function DeviceCmykCSClosure() {
              function convertToRgb(e, t, a, r, n) {
                const i = e[t] * a,
                  s = e[t + 1] * a,
                  o = e[t + 2] * a,
                  c = e[t + 3] * a;
                r[n] =
                  255 +
                  i *
                    (-4.387332384609988 * i +
                      54.48615194189176 * s +
                      18.82290502165302 * o +
                      212.25662451639585 * c -
                      285.2331026137004) +
                  s *
                    (1.7149763477362134 * s -
                      5.6096736904047315 * o +
                      -17.873870861415444 * c -
                      5.497006427196366) +
                  o *
                    (-2.5217340131683033 * o -
                      21.248923337353073 * c +
                      17.5119270841813) +
                  c * (-21.86122147463605 * c - 189.48180835922747);
                r[n + 1] =
                  255 +
                  i *
                    (8.841041422036149 * i +
                      60.118027045597366 * s +
                      6.871425592049007 * o +
                      31.159100130055922 * c -
                      79.2970844816548) +
                  s *
                    (-15.310361306967817 * s +
                      17.575251261109482 * o +
                      131.35250912493976 * c -
                      190.9453302588951) +
                  o *
                    (4.444339102852739 * o +
                      9.8632861493405 * c -
                      24.86741582555878) +
                  c * (-20.737325471181034 * c - 187.80453709719578);
                r[n + 2] =
                  255 +
                  i *
                    (0.8842522430003296 * i +
                      8.078677503112928 * s +
                      30.89978309703729 * o -
                      0.23883238689178934 * c -
                      14.183576799673286) +
                  s *
                    (10.49593273432072 * s +
                      63.02378494754052 * o +
                      50.606957656360734 * c -
                      112.23884253719248) +
                  o *
                    (0.03296041114873217 * o +
                      115.60384449646641 * c -
                      193.58209356861505) +
                  c * (-22.33816807309886 * c - 180.12613974708367);
              }
              return class DeviceCmykCS extends ColorSpace {
                constructor() {
                  super("DeviceCMYK", 4);
                }
                getRgbItem(e, t, a, r) {
                  convertToRgb(e, t, 1, a, r);
                }
                getRgbBuffer(e, t, a, r, n, i, s) {
                  const o = 1 / ((1 << i) - 1);
                  for (let i = 0; i < a; i++) {
                    convertToRgb(e, t, o, r, n);
                    t += 4;
                    n += 3 + s;
                  }
                }
                getOutputLength(e, t) {
                  return ((e / 4) * (3 + t)) | 0;
                }
              };
            })(),
            c = (function CalGrayCSClosure() {
              function convertToRgb(e, t, a, r, n, i) {
                const s = (t[a] * i) ** e.G,
                  o = e.YW * s,
                  c = Math.max(295.8 * o ** 0.3333333333333333 - 40.8, 0);
                r[n] = c;
                r[n + 1] = c;
                r[n + 2] = c;
              }
              return class CalGrayCS extends ColorSpace {
                constructor(e, t, a) {
                  super("CalGray", 1);
                  if (!e)
                    throw new r.FormatError(
                      "WhitePoint missing - required for color space CalGray"
                    );
                  t = t || [0, 0, 0];
                  a = a || 1;
                  this.XW = e[0];
                  this.YW = e[1];
                  this.ZW = e[2];
                  this.XB = t[0];
                  this.YB = t[1];
                  this.ZB = t[2];
                  this.G = a;
                  if (this.XW < 0 || this.ZW < 0 || 1 !== this.YW)
                    throw new r.FormatError(
                      `Invalid WhitePoint components for ${this.name}, no fallback available`
                    );
                  if (this.XB < 0 || this.YB < 0 || this.ZB < 0) {
                    (0, r.info)(
                      `Invalid BlackPoint for ${this.name}, falling back to default.`
                    );
                    this.XB = this.YB = this.ZB = 0;
                  }
                  (0 === this.XB && 0 === this.YB && 0 === this.ZB) ||
                    (0, r.warn)(
                      `${this.name}, BlackPoint: XB: ${this.XB}, YB: ${this.YB}, ZB: ${this.ZB}, only default values are supported.`
                    );
                  if (this.G < 1) {
                    (0, r.info)(
                      `Invalid Gamma: ${this.G} for ${this.name}, falling back to default.`
                    );
                    this.G = 1;
                  }
                }
                getRgbItem(e, t, a, r) {
                  convertToRgb(this, e, t, a, r, 1);
                }
                getRgbBuffer(e, t, a, r, n, i, s) {
                  const o = 1 / ((1 << i) - 1);
                  for (let i = 0; i < a; ++i) {
                    convertToRgb(this, e, t, r, n, o);
                    t += 1;
                    n += 3 + s;
                  }
                }
                getOutputLength(e, t) {
                  return e * (3 + t);
                }
              };
            })(),
            l = (function CalRGBCSClosure() {
              const e = new Float32Array([
                  0.8951, 0.2664, -0.1614, -0.7502, 1.7135, 0.0367, 0.0389,
                  -0.0685, 1.0296,
                ]),
                t = new Float32Array([
                  0.9869929, -0.1470543, 0.1599627, 0.4323053, 0.5183603,
                  0.0492912, -0.0085287, 0.0400428, 0.9684867,
                ]),
                a = new Float32Array([
                  3.2404542, -1.5371385, -0.4985314, -0.969266, 1.8760108,
                  0.041556, 0.0556434, -0.2040259, 1.0572252,
                ]),
                n = new Float32Array([1, 1, 1]),
                i = new Float32Array(3),
                s = new Float32Array(3),
                o = new Float32Array(3),
                c = (24 / 116) ** 3 / 8;
              function matrixProduct(e, t, a) {
                a[0] = e[0] * t[0] + e[1] * t[1] + e[2] * t[2];
                a[1] = e[3] * t[0] + e[4] * t[1] + e[5] * t[2];
                a[2] = e[6] * t[0] + e[7] * t[1] + e[8] * t[2];
              }
              function sRGBTransferFunction(e) {
                return e <= 0.0031308
                  ? adjustToRange(0, 1, 12.92 * e)
                  : e >= 0.99554525
                  ? 1
                  : adjustToRange(0, 1, 1.055 * e ** (1 / 2.4) - 0.055);
              }
              function adjustToRange(e, t, a) {
                return Math.max(e, Math.min(t, a));
              }
              function decodeL(e) {
                return e < 0
                  ? -decodeL(-e)
                  : e > 8
                  ? ((e + 16) / 116) ** 3
                  : e * c;
              }
              function convertToRgb(r, c, l, h, u, d) {
                const f = adjustToRange(0, 1, c[l] * d),
                  g = adjustToRange(0, 1, c[l + 1] * d),
                  p = adjustToRange(0, 1, c[l + 2] * d),
                  m = 1 === f ? 1 : f ** r.GR,
                  b = 1 === g ? 1 : g ** r.GG,
                  y = 1 === p ? 1 : p ** r.GB,
                  w = r.MXA * m + r.MXB * b + r.MXC * y,
                  S = r.MYA * m + r.MYB * b + r.MYC * y,
                  x = r.MZA * m + r.MZB * b + r.MZC * y,
                  C = s;
                C[0] = w;
                C[1] = S;
                C[2] = x;
                const k = o;
                !(function normalizeWhitePointToFlat(a, r, n) {
                  if (1 === a[0] && 1 === a[2]) {
                    n[0] = r[0];
                    n[1] = r[1];
                    n[2] = r[2];
                    return;
                  }
                  const s = n;
                  matrixProduct(e, r, s);
                  const o = i;
                  !(function convertToFlat(e, t, a) {
                    a[0] = (1 * t[0]) / e[0];
                    a[1] = (1 * t[1]) / e[1];
                    a[2] = (1 * t[2]) / e[2];
                  })(a, s, o);
                  matrixProduct(t, o, n);
                })(r.whitePoint, C, k);
                const v = s;
                !(function compensateBlackPoint(e, t, a) {
                  if (0 === e[0] && 0 === e[1] && 0 === e[2]) {
                    a[0] = t[0];
                    a[1] = t[1];
                    a[2] = t[2];
                    return;
                  }
                  const r = decodeL(0),
                    n = (1 - r) / (1 - decodeL(e[0])),
                    i = 1 - n,
                    s = (1 - r) / (1 - decodeL(e[1])),
                    o = 1 - s,
                    c = (1 - r) / (1 - decodeL(e[2])),
                    l = 1 - c;
                  a[0] = t[0] * n + i;
                  a[1] = t[1] * s + o;
                  a[2] = t[2] * c + l;
                })(r.blackPoint, k, v);
                const F = o;
                !(function normalizeWhitePointToD65(a, r, n) {
                  const s = n;
                  matrixProduct(e, r, s);
                  const o = i;
                  !(function convertToD65(e, t, a) {
                    a[0] = (0.95047 * t[0]) / e[0];
                    a[1] = (1 * t[1]) / e[1];
                    a[2] = (1.08883 * t[2]) / e[2];
                  })(a, s, o);
                  matrixProduct(t, o, n);
                })(n, v, F);
                const O = s;
                matrixProduct(a, F, O);
                h[u] = 255 * sRGBTransferFunction(O[0]);
                h[u + 1] = 255 * sRGBTransferFunction(O[1]);
                h[u + 2] = 255 * sRGBTransferFunction(O[2]);
              }
              return class CalRGBCS extends ColorSpace {
                constructor(e, t, a, n) {
                  super("CalRGB", 3);
                  if (!e)
                    throw new r.FormatError(
                      "WhitePoint missing - required for color space CalRGB"
                    );
                  t = t || new Float32Array(3);
                  a = a || new Float32Array([1, 1, 1]);
                  n = n || new Float32Array([1, 0, 0, 0, 1, 0, 0, 0, 1]);
                  const i = e[0],
                    s = e[1],
                    o = e[2];
                  this.whitePoint = e;
                  const c = t[0],
                    l = t[1],
                    h = t[2];
                  this.blackPoint = t;
                  this.GR = a[0];
                  this.GG = a[1];
                  this.GB = a[2];
                  this.MXA = n[0];
                  this.MYA = n[1];
                  this.MZA = n[2];
                  this.MXB = n[3];
                  this.MYB = n[4];
                  this.MZB = n[5];
                  this.MXC = n[6];
                  this.MYC = n[7];
                  this.MZC = n[8];
                  if (i < 0 || o < 0 || 1 !== s)
                    throw new r.FormatError(
                      `Invalid WhitePoint components for ${this.name}, no fallback available`
                    );
                  if (c < 0 || l < 0 || h < 0) {
                    (0, r.info)(
                      `Invalid BlackPoint for ${this.name} [${c}, ${l}, ${h}], falling back to default.`
                    );
                    this.blackPoint = new Float32Array(3);
                  }
                  if (this.GR < 0 || this.GG < 0 || this.GB < 0) {
                    (0, r.info)(
                      `Invalid Gamma [${this.GR}, ${this.GG}, ${this.GB}] for ${this.name}, falling back to default.`
                    );
                    this.GR = this.GG = this.GB = 1;
                  }
                }
                getRgbItem(e, t, a, r) {
                  convertToRgb(this, e, t, a, r, 1);
                }
                getRgbBuffer(e, t, a, r, n, i, s) {
                  const o = 1 / ((1 << i) - 1);
                  for (let i = 0; i < a; ++i) {
                    convertToRgb(this, e, t, r, n, o);
                    t += 3;
                    n += 3 + s;
                  }
                }
                getOutputLength(e, t) {
                  return ((e * (3 + t)) / 3) | 0;
                }
              };
            })(),
            h = (function LabCSClosure() {
              function fn_g(e) {
                let t;
                t = e >= 6 / 29 ? e ** 3 : (108 / 841) * (e - 4 / 29);
                return t;
              }
              function decode(e, t, a, r) {
                return a + (e * (r - a)) / t;
              }
              function convertToRgb(e, t, a, r, n, i) {
                let s = t[a],
                  o = t[a + 1],
                  c = t[a + 2];
                if (!1 !== r) {
                  s = decode(s, r, 0, 100);
                  o = decode(o, r, e.amin, e.amax);
                  c = decode(c, r, e.bmin, e.bmax);
                }
                o > e.amax ? (o = e.amax) : o < e.amin && (o = e.amin);
                c > e.bmax ? (c = e.bmax) : c < e.bmin && (c = e.bmin);
                const l = (s + 16) / 116,
                  h = l + o / 500,
                  u = l - c / 200,
                  d = e.XW * fn_g(h),
                  f = e.YW * fn_g(l),
                  g = e.ZW * fn_g(u);
                let p, m, b;
                if (e.ZW < 1) {
                  p = 3.1339 * d + -1.617 * f + -0.4906 * g;
                  m = -0.9785 * d + 1.916 * f + 0.0333 * g;
                  b = 0.072 * d + -0.229 * f + 1.4057 * g;
                } else {
                  p = 3.2406 * d + -1.5372 * f + -0.4986 * g;
                  m = -0.9689 * d + 1.8758 * f + 0.0415 * g;
                  b = 0.0557 * d + -0.204 * f + 1.057 * g;
                }
                n[i] = 255 * Math.sqrt(p);
                n[i + 1] = 255 * Math.sqrt(m);
                n[i + 2] = 255 * Math.sqrt(b);
              }
              return class LabCS extends ColorSpace {
                constructor(e, t, a) {
                  super("Lab", 3);
                  if (!e)
                    throw new r.FormatError(
                      "WhitePoint missing - required for color space Lab"
                    );
                  t = t || [0, 0, 0];
                  a = a || [-100, 100, -100, 100];
                  this.XW = e[0];
                  this.YW = e[1];
                  this.ZW = e[2];
                  this.amin = a[0];
                  this.amax = a[1];
                  this.bmin = a[2];
                  this.bmax = a[3];
                  this.XB = t[0];
                  this.YB = t[1];
                  this.ZB = t[2];
                  if (this.XW < 0 || this.ZW < 0 || 1 !== this.YW)
                    throw new r.FormatError(
                      "Invalid WhitePoint components, no fallback available"
                    );
                  if (this.XB < 0 || this.YB < 0 || this.ZB < 0) {
                    (0, r.info)("Invalid BlackPoint, falling back to default");
                    this.XB = this.YB = this.ZB = 0;
                  }
                  if (this.amin > this.amax || this.bmin > this.bmax) {
                    (0, r.info)("Invalid Range, falling back to defaults");
                    this.amin = -100;
                    this.amax = 100;
                    this.bmin = -100;
                    this.bmax = 100;
                  }
                }
                getRgbItem(e, t, a, r) {
                  convertToRgb(this, e, t, !1, a, r);
                }
                getRgbBuffer(e, t, a, r, n, i, s) {
                  const o = (1 << i) - 1;
                  for (let i = 0; i < a; i++) {
                    convertToRgb(this, e, t, o, r, n);
                    t += 3;
                    n += 3 + s;
                  }
                }
                getOutputLength(e, t) {
                  return ((e * (3 + t)) / 3) | 0;
                }
                isDefaultDecode(e, t) {
                  return !0;
                }
                get usesZeroToOneRange() {
                  return (0, r.shadow)(this, "usesZeroToOneRange", !1);
                }
              };
            })();
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PartialEvaluator = t.EvaluatorPreprocessor = void 0;
          var r = a(2),
            n = a(14),
            i = a(4),
            s = a(32),
            o = a(35),
            c = a(39),
            l = a(48),
            h = a(49),
            u = a(40),
            d = a(55),
            f = a(15),
            g = a(57),
            p = a(8),
            m = a(5),
            b = a(58),
            y = a(12),
            w = a(17),
            S = a(36),
            x = a(37),
            C = a(3),
            k = a(43),
            v = a(38),
            F = a(59),
            O = a(60),
            T = a(61);
          const M = Object.freeze({
              maxImageSize: -1,
              disableFontFace: !1,
              ignoreErrors: !1,
              isEvalSupported: !0,
              isOffscreenCanvasSupported: !0,
              fontExtraProperties: !1,
              useSystemFonts: !0,
              cMapUrl: null,
              standardFontDataUrl: null,
            }),
            E = 1,
            D = 2,
            N = 10,
            R = Promise.resolve();
          function normalizeBlendMode(e, t = !1) {
            if (Array.isArray(e)) {
              for (const t of e) {
                const e = normalizeBlendMode(t, !0);
                if (e) return e;
              }
              (0, r.warn)(`Unsupported blend mode Array: ${e}`);
              return "source-over";
            }
            if (!(e instanceof i.Name)) return t ? null : "source-over";
            switch (e.name) {
              case "Normal":
              case "Compatible":
                return "source-over";
              case "Multiply":
                return "multiply";
              case "Screen":
                return "screen";
              case "Overlay":
                return "overlay";
              case "Darken":
                return "darken";
              case "Lighten":
                return "lighten";
              case "ColorDodge":
                return "color-dodge";
              case "ColorBurn":
                return "color-burn";
              case "HardLight":
                return "hard-light";
              case "SoftLight":
                return "soft-light";
              case "Difference":
                return "difference";
              case "Exclusion":
                return "exclusion";
              case "Hue":
                return "hue";
              case "Saturation":
                return "saturation";
              case "Color":
                return "color";
              case "Luminosity":
                return "luminosity";
            }
            if (t) return null;
            (0, r.warn)(`Unsupported blend mode: ${e.name}`);
            return "source-over";
          }
          function incrementCachedImageMaskCount(e) {
            e.fn === r.OPS.paintImageMaskXObject &&
              e.args[0] &&
              e.args[0].count > 0 &&
              e.args[0].count++;
          }
          class TimeSlotManager {
            static get TIME_SLOT_DURATION_MS() {
              return (0, r.shadow)(this, "TIME_SLOT_DURATION_MS", 20);
            }
            static get CHECK_TIME_EVERY() {
              return (0, r.shadow)(this, "CHECK_TIME_EVERY", 100);
            }
            constructor() {
              this.reset();
            }
            check() {
              if (++this.checked < TimeSlotManager.CHECK_TIME_EVERY) return !1;
              this.checked = 0;
              return this.endTime <= Date.now();
            }
            reset() {
              this.endTime = Date.now() + TimeSlotManager.TIME_SLOT_DURATION_MS;
              this.checked = 0;
            }
          }
          class PartialEvaluator {
            constructor({
              xref: e,
              handler: t,
              pageIndex: a,
              idFactory: r,
              fontCache: n,
              builtInCMapCache: i,
              standardFontDataCache: s,
              globalImageCache: o,
              options: c = null,
            }) {
              this.xref = e;
              this.handler = t;
              this.pageIndex = a;
              this.idFactory = r;
              this.fontCache = n;
              this.builtInCMapCache = i;
              this.standardFontDataCache = s;
              this.globalImageCache = o;
              this.options = c || M;
              this.parsingType3Font = !1;
              this._fetchBuiltInCMapBound = this.fetchBuiltInCMap.bind(this);
            }
            get _pdfFunctionFactory() {
              const e = new d.PDFFunctionFactory({
                xref: this.xref,
                isEvalSupported: this.options.isEvalSupported,
              });
              return (0, r.shadow)(this, "_pdfFunctionFactory", e);
            }
            clone(e = null) {
              const t = Object.create(this);
              t.options = Object.assign(Object.create(null), this.options, e);
              return t;
            }
            hasBlendModes(e, t) {
              if (!(e instanceof i.Dict)) return !1;
              if (e.objId && t.has(e.objId)) return !1;
              const a = new i.RefSet(t);
              e.objId && a.put(e.objId);
              const n = [e],
                s = this.xref;
              for (; n.length; ) {
                const e = n.shift(),
                  t = e.get("ExtGState");
                if (t instanceof i.Dict)
                  for (let e of t.getRawValues()) {
                    if (e instanceof i.Ref) {
                      if (a.has(e)) continue;
                      try {
                        e = s.fetch(e);
                      } catch (t) {
                        a.put(e);
                        (0, r.info)(
                          `hasBlendModes - ignoring ExtGState: "${t}".`
                        );
                        continue;
                      }
                    }
                    if (!(e instanceof i.Dict)) continue;
                    e.objId && a.put(e.objId);
                    const t = e.get("BM");
                    if (t instanceof i.Name) {
                      if ("Normal" !== t.name) return !0;
                    } else if (void 0 !== t && Array.isArray(t))
                      for (const e of t)
                        if (e instanceof i.Name && "Normal" !== e.name)
                          return !0;
                  }
                const o = e.get("XObject");
                if (o instanceof i.Dict)
                  for (let e of o.getRawValues()) {
                    if (e instanceof i.Ref) {
                      if (a.has(e)) continue;
                      try {
                        e = s.fetch(e);
                      } catch (t) {
                        a.put(e);
                        (0, r.info)(
                          `hasBlendModes - ignoring XObject: "${t}".`
                        );
                        continue;
                      }
                    }
                    if (!(e instanceof m.BaseStream)) continue;
                    e.dict.objId && a.put(e.dict.objId);
                    const t = e.dict.get("Resources");
                    if (t instanceof i.Dict && (!t.objId || !a.has(t.objId))) {
                      n.push(t);
                      t.objId && a.put(t.objId);
                    }
                  }
              }
              for (const e of a) t.put(e);
              return !1;
            }
            async fetchBuiltInCMap(e) {
              const t = this.builtInCMapCache.get(e);
              if (t) return t;
              let a;
              if (null !== this.options.cMapUrl) {
                const t = `${this.options.cMapUrl}${e}.bcmap`,
                  n = await fetch(t);
                if (!n.ok)
                  throw new Error(
                    `fetchBuiltInCMap: failed to fetch file "${t}" with "${n.statusText}".`
                  );
                a = {
                  cMapData: new Uint8Array(await n.arrayBuffer()),
                  compressionType: r.CMapCompressionType.BINARY,
                };
              } else
                a = await this.handler.sendWithPromise("FetchBuiltInCMap", {
                  name: e,
                });
              a.compressionType !== r.CMapCompressionType.NONE &&
                this.builtInCMapCache.set(e, a);
              return a;
            }
            async fetchStandardFontData(e) {
              const t = this.standardFontDataCache.get(e);
              if (t) return new p.Stream(t);
              if (
                this.options.useSystemFonts &&
                "Symbol" !== e &&
                "ZapfDingbats" !== e
              )
                return null;
              const a = (0, c.getFontNameToFileMap)()[e];
              let n;
              if (null !== this.options.standardFontDataUrl) {
                const e = `${this.options.standardFontDataUrl}${a}`,
                  t = await fetch(e);
                t.ok
                  ? (n = await t.arrayBuffer())
                  : (0, r.warn)(
                      `fetchStandardFontData: failed to fetch file "${e}" with "${t.statusText}".`
                    );
              } else
                try {
                  n = await this.handler.sendWithPromise(
                    "FetchStandardFontData",
                    { filename: a }
                  );
                } catch (e) {
                  (0, r.warn)(
                    `fetchStandardFontData: failed to fetch file "${a}" with "${e}".`
                  );
                }
              if (!n) return null;
              this.standardFontDataCache.set(e, n);
              return new p.Stream(n);
            }
            async buildFormXObject(e, t, a, n, s, o, c) {
              const l = t.dict,
                h = l.getArray("Matrix");
              let u,
                d,
                f = l.getArray("BBox");
              f =
                Array.isArray(f) && 4 === f.length
                  ? r.Util.normalizeRect(f)
                  : null;
              l.has("OC") &&
                (u = await this.parseMarkedContentProps(l.get("OC"), e));
              void 0 !== u && n.addOp(r.OPS.beginMarkedContentProps, ["OC", u]);
              const g = l.get("Group");
              if (g) {
                d = {
                  matrix: h,
                  bbox: f,
                  smask: a,
                  isolated: !1,
                  knockout: !1,
                };
                const t = g.get("S");
                let s = null;
                if ((0, i.isName)(t, "Transparency")) {
                  d.isolated = g.get("I") || !1;
                  d.knockout = g.get("K") || !1;
                  if (g.has("CS")) {
                    const t = g.getRaw("CS"),
                      a = y.ColorSpace.getCached(t, this.xref, c);
                    s =
                      a ||
                      (await this.parseColorSpace({
                        cs: t,
                        resources: e,
                        localColorSpaceCache: c,
                      }));
                  }
                }
                if (a && a.backdrop) {
                  s = s || y.ColorSpace.singletons.rgb;
                  a.backdrop = s.getRgb(a.backdrop, 0);
                }
                n.addOp(r.OPS.beginGroup, [d]);
              }
              const p = g ? [h, null] : [h, f];
              n.addOp(r.OPS.paintFormXObjectBegin, p);
              return this.getOperatorList({
                stream: t,
                task: s,
                resources: l.get("Resources") || e,
                operatorList: n,
                initialState: o,
              }).then(function () {
                n.addOp(r.OPS.paintFormXObjectEnd, []);
                g && n.addOp(r.OPS.endGroup, [d]);
                void 0 !== u && n.addOp(r.OPS.endMarkedContent, []);
              });
            }
            _sendImgData(e, t, a = !1) {
              const r = t ? [t.bitmap || t.data.buffer] : null;
              return this.parsingType3Font || a
                ? this.handler.send("commonobj", [e, "Image", t], r)
                : this.handler.send("obj", [e, this.pageIndex, "Image", t], r);
            }
            async buildPaintImageXObject({
              resources: e,
              image: t,
              isInline: a = !1,
              operatorList: n,
              cacheKey: i,
              localImageCache: s,
              localColorSpaceCache: o,
            }) {
              const c = t.dict,
                l = c.objId,
                h = c.get("W", "Width"),
                u = c.get("H", "Height");
              if (!h || "number" != typeof h || !u || "number" != typeof u) {
                (0, r.warn)("Image dimensions are missing, or not numbers.");
                return;
              }
              const d = this.options.maxImageSize;
              if (-1 !== d && h * u > d) {
                const e =
                  "Image exceeded maximum allowed size and was removed.";
                if (this.options.ignoreErrors) {
                  (0, r.warn)(e);
                  return;
                }
                throw new Error(e);
              }
              let f;
              c.has("OC") &&
                (f = await this.parseMarkedContentProps(c.get("OC"), e));
              let g, p;
              if (c.get("IM", "ImageMask") || !1) {
                const e = c.get("I", "Interpolate"),
                  a = (h + 7) >> 3,
                  o = t.getBytes(a * u),
                  d = c.getArray("D", "Decode");
                if (this.parsingType3Font) {
                  g = T.PDFImage.createRawMask({
                    imgArray: o,
                    width: h,
                    height: u,
                    imageIsFromDecodeStream: t instanceof w.DecodeStream,
                    inverseDecode: !!d && d[0] > 0,
                    interpolate: e,
                  });
                  g.cached = !!i;
                  p = [g];
                  n.addImageOps(r.OPS.paintImageMaskXObject, p, f);
                  i &&
                    s.set(i, l, {
                      fn: r.OPS.paintImageMaskXObject,
                      args: p,
                      optionalContent: f,
                    });
                  return;
                }
                g = T.PDFImage.createMask({
                  imgArray: o,
                  width: h,
                  height: u,
                  imageIsFromDecodeStream: t instanceof w.DecodeStream,
                  inverseDecode: !!d && d[0] > 0,
                  interpolate: e,
                  isOffscreenCanvasSupported:
                    this.options.isOffscreenCanvasSupported,
                });
                if (g.isSingleOpaquePixel) {
                  n.addImageOps(r.OPS.paintSolidColorImageMask, [], f);
                  i &&
                    s.set(i, l, {
                      fn: r.OPS.paintSolidColorImageMask,
                      args: [],
                      optionalContent: f,
                    });
                  return;
                }
                const m = `mask_${this.idFactory.createObjId()}`;
                n.addDependency(m);
                this._sendImgData(m, g);
                p = [
                  {
                    data: m,
                    width: g.width,
                    height: g.height,
                    interpolate: g.interpolate,
                    count: 1,
                  },
                ];
                n.addImageOps(r.OPS.paintImageMaskXObject, p, f);
                i &&
                  s.set(i, l, {
                    fn: r.OPS.paintImageMaskXObject,
                    args: p,
                    optionalContent: f,
                  });
                return;
              }
              const m = c.get("SM", "SMask") || !1,
                b = c.get("Mask") || !1;
              if (a && !m && !b && h + u < 200) {
                const i = new T.PDFImage({
                  xref: this.xref,
                  res: e,
                  image: t,
                  isInline: a,
                  pdfFunctionFactory: this._pdfFunctionFactory,
                  localColorSpaceCache: o,
                });
                g = i.createImageData(!0);
                n.addImageOps(r.OPS.paintInlineImageXObject, [g], f);
                return;
              }
              let y = `img_${this.idFactory.createObjId()}`,
                S = !1;
              if (this.parsingType3Font)
                y = `${this.idFactory.getDocId()}_type3_${y}`;
              else if (l) {
                S = this.globalImageCache.shouldCache(l, this.pageIndex);
                S && (y = `${this.idFactory.getDocId()}_${y}`);
              }
              n.addDependency(y);
              p = [y, h, u];
              T.PDFImage.buildImage({
                xref: this.xref,
                res: e,
                image: t,
                isInline: a,
                pdfFunctionFactory: this._pdfFunctionFactory,
                localColorSpaceCache: o,
              })
                .then((e) => {
                  g = e.createImageData(!1);
                  i &&
                    l &&
                    S &&
                    this.globalImageCache.addByteSize(l, g.data.length);
                  return this._sendImgData(y, g, S);
                })
                .catch((e) => {
                  (0, r.warn)(`Unable to decode image "${y}": "${e}".`);
                  return this._sendImgData(y, null, S);
                });
              n.addImageOps(r.OPS.paintImageXObject, p, f);
              if (i) {
                s.set(i, l, {
                  fn: r.OPS.paintImageXObject,
                  args: p,
                  optionalContent: f,
                });
                if (l) {
                  (0, r.assert)(!a, "Cannot cache an inline image globally.");
                  this.globalImageCache.addPageIndex(l, this.pageIndex);
                  S &&
                    this.globalImageCache.setData(l, {
                      objId: y,
                      fn: r.OPS.paintImageXObject,
                      args: p,
                      optionalContent: f,
                      byteSize: 0,
                    });
                }
              }
            }
            handleSMask(e, t, a, r, n, i) {
              const s = e.get("G"),
                o = { subtype: e.get("S").name, backdrop: e.get("BC") },
                c = e.get("TR");
              if ((0, d.isPDFFunction)(c)) {
                const e = this._pdfFunctionFactory.create(c),
                  t = new Uint8Array(256),
                  a = new Float32Array(1);
                for (let r = 0; r < 256; r++) {
                  a[0] = r / 255;
                  e(a, 0, a, 0);
                  t[r] = (255 * a[0]) | 0;
                }
                o.transferMap = t;
              }
              return this.buildFormXObject(t, s, o, a, r, n.state.clone(), i);
            }
            handleTransferFunction(e) {
              let t;
              if (Array.isArray(e)) t = e;
              else {
                if (!(0, d.isPDFFunction)(e)) return null;
                t = [e];
              }
              const a = [];
              let r = 0,
                n = 0;
              for (const e of t) {
                const t = this.xref.fetchIfRef(e);
                r++;
                if ((0, i.isName)(t, "Identity")) {
                  a.push(null);
                  continue;
                }
                if (!(0, d.isPDFFunction)(t)) return null;
                const s = this._pdfFunctionFactory.create(t),
                  o = new Uint8Array(256),
                  c = new Float32Array(1);
                for (let e = 0; e < 256; e++) {
                  c[0] = e / 255;
                  s(c, 0, c, 0);
                  o[e] = (255 * c[0]) | 0;
                }
                a.push(o);
                n++;
              }
              return (1 !== r && 4 !== r) || 0 === n ? null : a;
            }
            handleTilingType(e, t, a, n, s, o, c, h) {
              const u = new O.OperatorList(),
                d = i.Dict.merge({
                  xref: this.xref,
                  dictArray: [s.get("Resources"), a],
                });
              return this.getOperatorList({
                stream: n,
                task: c,
                resources: d,
                operatorList: u,
              })
                .then(function () {
                  const a = u.getIR(),
                    r = (0, l.getTilingPatternIR)(a, s, t);
                  o.addDependencies(u.dependencies);
                  o.addOp(e, r);
                  s.objId &&
                    h.set(null, s.objId, { operatorListIR: a, dict: s });
                })
                .catch((e) => {
                  if (!(e instanceof r.AbortException)) {
                    if (!this.options.ignoreErrors) throw e;
                    this.handler.send("UnsupportedFeature", {
                      featureId: r.UNSUPPORTED_FEATURES.errorTilingPattern,
                    });
                    (0, r.warn)(`handleTilingType - ignoring pattern: "${e}".`);
                  }
                });
            }
            handleSetFont(e, t, a, n, o, c, l = null, h = null) {
              const u = t && t[0] instanceof i.Name ? t[0].name : null;
              return this.loadFont(u, a, e, l, h)
                .then((t) =>
                  t.font.isType3Font
                    ? t
                        .loadType3Data(this, e, o)
                        .then(function () {
                          n.addDependencies(t.type3Dependencies);
                          return t;
                        })
                        .catch((e) => {
                          this.handler.send("UnsupportedFeature", {
                            featureId:
                              r.UNSUPPORTED_FEATURES.errorFontLoadType3,
                          });
                          return new TranslatedFont({
                            loadedName: "g_font_error",
                            font: new s.ErrorFont(
                              `Type3 font load error: ${e}`
                            ),
                            dict: t.font,
                            evaluatorOptions: this.options,
                          });
                        })
                    : t
                )
                .then((e) => {
                  c.font = e.font;
                  e.send(this.handler);
                  return e.loadedName;
                });
            }
            handleText(e, t) {
              const a = t.font,
                n = a.charsToGlyphs(e);
              if (a.data) {
                (!!(
                  t.textRenderingMode & r.TextRenderingMode.ADD_TO_PATH_FLAG
                ) ||
                  "Pattern" === t.fillColorSpace.name ||
                  a.disableFontFace ||
                  this.options.disableFontFace) &&
                  PartialEvaluator.buildFontPaths(
                    a,
                    n,
                    this.handler,
                    this.options
                  );
              }
              return n;
            }
            ensureStateFont(e) {
              if (e.font) return;
              const t = new r.FormatError(
                "Missing setFont (Tf) operator before text rendering operator."
              );
              if (!this.options.ignoreErrors) throw t;
              this.handler.send("UnsupportedFeature", {
                featureId: r.UNSUPPORTED_FEATURES.errorFontState,
              });
              (0, r.warn)(`ensureStateFont: "${t}".`);
            }
            async setGState({
              resources: e,
              gState: t,
              operatorList: a,
              cacheKey: n,
              task: s,
              stateManager: o,
              localGStateCache: c,
              localColorSpaceCache: l,
            }) {
              const h = t.objId;
              let u = !0;
              const d = [];
              let f = Promise.resolve();
              for (const n of t.getKeys()) {
                const c = t.get(n);
                switch (n) {
                  case "Type":
                    break;
                  case "LW":
                  case "LC":
                  case "LJ":
                  case "ML":
                  case "D":
                  case "RI":
                  case "FL":
                  case "CA":
                  case "ca":
                    d.push([n, c]);
                    break;
                  case "Font":
                    u = !1;
                    f = f.then(() =>
                      this.handleSetFont(e, null, c[0], a, s, o.state).then(
                        function (e) {
                          a.addDependency(e);
                          d.push([n, [e, c[1]]]);
                        }
                      )
                    );
                    break;
                  case "BM":
                    d.push([n, normalizeBlendMode(c)]);
                    break;
                  case "SMask":
                    if ((0, i.isName)(c, "None")) {
                      d.push([n, !1]);
                      break;
                    }
                    if (c instanceof i.Dict) {
                      u = !1;
                      f = f.then(() => this.handleSMask(c, e, a, s, o, l));
                      d.push([n, !0]);
                    } else (0, r.warn)("Unsupported SMask type");
                    break;
                  case "TR":
                    const t = this.handleTransferFunction(c);
                    d.push([n, t]);
                    break;
                  case "OP":
                  case "op":
                  case "OPM":
                  case "BG":
                  case "BG2":
                  case "UCR":
                  case "UCR2":
                  case "TR2":
                  case "HT":
                  case "SM":
                  case "SA":
                  case "AIS":
                  case "TK":
                    (0, r.info)("graphic state operator " + n);
                    break;
                  default:
                    (0, r.info)("Unknown graphic state operator " + n);
                }
              }
              return f.then(function () {
                d.length > 0 && a.addOp(r.OPS.setGState, [d]);
                u && c.set(n, h, d);
              });
            }
            loadFont(e, t, a, n = null, o = null) {
              const errorFont = async () =>
                  new TranslatedFont({
                    loadedName: "g_font_error",
                    font: new s.ErrorFont(`Font "${e}" is not available.`),
                    dict: t,
                    evaluatorOptions: this.options,
                  }),
                c = this.xref;
              let l;
              if (t) t instanceof i.Ref && (l = t);
              else {
                const t = a.get("Font");
                t && (l = t.getRaw(e));
              }
              if (!l) {
                const a = `Font "${e || (t && t.toString())}" is not available`;
                if (!this.options.ignoreErrors && !this.parsingType3Font) {
                  (0, r.warn)(`${a}.`);
                  return errorFont();
                }
                this.handler.send("UnsupportedFeature", {
                  featureId: r.UNSUPPORTED_FEATURES.errorFontMissing,
                });
                (0, r.warn)(
                  `${a} -- attempting to fallback to a default font.`
                );
                l = n || PartialEvaluator.fallbackFontDict;
              }
              if (this.parsingType3Font && this.type3FontRefs.has(l))
                return errorFont();
              if (this.fontCache.has(l)) return this.fontCache.get(l);
              if (!((t = c.fetchIfRef(l)) instanceof i.Dict))
                return errorFont();
              if (t.cacheKey && this.fontCache.has(t.cacheKey))
                return this.fontCache.get(t.cacheKey);
              const h = (0, r.createPromiseCapability)();
              let u;
              try {
                u = this.preEvaluateFont(t);
                u.cssFontInfo = o;
              } catch (e) {
                (0, r.warn)(`loadFont - preEvaluateFont failed: "${e}".`);
                return errorFont();
              }
              const { descriptor: d, hash: f } = u,
                g = l instanceof i.Ref;
              let p;
              g && (p = `f${l.toString()}`);
              if (f && d instanceof i.Dict) {
                d.fontAliases || (d.fontAliases = Object.create(null));
                const e = d.fontAliases;
                if (e[f]) {
                  const t = e[f].aliasRef;
                  if (g && t && this.fontCache.has(t)) {
                    this.fontCache.putAlias(l, t);
                    return this.fontCache.get(l);
                  }
                } else e[f] = { fontID: this.idFactory.createFontId() };
                g && (e[f].aliasRef = l);
                p = e[f].fontID;
              }
              if (g) this.fontCache.put(l, h.promise);
              else {
                p || (p = this.idFactory.createFontId());
                t.cacheKey = `cacheKey_${p}`;
                this.fontCache.put(t.cacheKey, h.promise);
              }
              (0, r.assert)(
                p && p.startsWith("f"),
                'The "fontID" must be (correctly) defined.'
              );
              t.loadedName = `${this.idFactory.getDocId()}_${p}`;
              this.translateFont(u)
                .then((e) => {
                  h.resolve(
                    new TranslatedFont({
                      loadedName: t.loadedName,
                      font: e,
                      dict: t,
                      evaluatorOptions: this.options,
                    })
                  );
                })
                .catch((e) => {
                  this.handler.send("UnsupportedFeature", {
                    featureId: r.UNSUPPORTED_FEATURES.errorFontTranslate,
                  });
                  (0, r.warn)(`loadFont - translateFont failed: "${e}".`);
                  h.resolve(
                    new TranslatedFont({
                      loadedName: t.loadedName,
                      font: new s.ErrorFont(e instanceof Error ? e.message : e),
                      dict: t,
                      evaluatorOptions: this.options,
                    })
                  );
                });
              return h.promise;
            }
            buildPath(e, t, a, n = !1) {
              const i = e.length - 1;
              a || (a = []);
              if (i < 0 || e.fnArray[i] !== r.OPS.constructPath) {
                if (n) {
                  (0, r.warn)(
                    `Encountered path operator "${t}" inside of a text object.`
                  );
                  e.addOp(r.OPS.save, null);
                }
                let i;
                switch (t) {
                  case r.OPS.rectangle:
                    const e = a[0] + a[2],
                      t = a[1] + a[3];
                    i = [
                      Math.min(a[0], e),
                      Math.max(a[0], e),
                      Math.min(a[1], t),
                      Math.max(a[1], t),
                    ];
                    break;
                  case r.OPS.moveTo:
                  case r.OPS.lineTo:
                    i = [a[0], a[0], a[1], a[1]];
                    break;
                  default:
                    i = [1 / 0, -1 / 0, 1 / 0, -1 / 0];
                }
                e.addOp(r.OPS.constructPath, [[t], a, i]);
                n && e.addOp(r.OPS.restore, null);
              } else {
                const n = e.argsArray[i];
                n[0].push(t);
                n[1].push(...a);
                const s = n[2];
                switch (t) {
                  case r.OPS.rectangle:
                    const e = a[0] + a[2],
                      t = a[1] + a[3];
                    s[0] = Math.min(s[0], a[0], e);
                    s[1] = Math.max(s[1], a[0], e);
                    s[2] = Math.min(s[2], a[1], t);
                    s[3] = Math.max(s[3], a[1], t);
                    break;
                  case r.OPS.moveTo:
                  case r.OPS.lineTo:
                    s[0] = Math.min(s[0], a[0]);
                    s[1] = Math.max(s[1], a[0]);
                    s[2] = Math.min(s[2], a[1]);
                    s[3] = Math.max(s[3], a[1]);
                }
              }
            }
            parseColorSpace({ cs: e, resources: t, localColorSpaceCache: a }) {
              return y.ColorSpace.parseAsync({
                cs: e,
                xref: this.xref,
                resources: t,
                pdfFunctionFactory: this._pdfFunctionFactory,
                localColorSpaceCache: a,
              }).catch((e) => {
                if (e instanceof r.AbortException) return null;
                if (this.options.ignoreErrors) {
                  this.handler.send("UnsupportedFeature", {
                    featureId: r.UNSUPPORTED_FEATURES.errorColorSpace,
                  });
                  (0, r.warn)(`parseColorSpace - ignoring ColorSpace: "${e}".`);
                  return null;
                }
                throw e;
              });
            }
            parseShading({
              shading: e,
              resources: t,
              localColorSpaceCache: a,
              localShadingPatternCache: r,
            }) {
              let n = r.get(e);
              if (!n) {
                const i = l.Pattern.parseShading(
                  e,
                  this.xref,
                  t,
                  this.handler,
                  this._pdfFunctionFactory,
                  a
                ).getIR();
                n = `pattern_${this.idFactory.createObjId()}`;
                r.set(e, n);
                this.handler.send("obj", [n, this.pageIndex, "Pattern", i]);
              }
              return n;
            }
            handleColorN(e, t, a, n, s, o, c, h, u, d) {
              const f = a.pop();
              if (f instanceof i.Name) {
                const g = s.getRaw(f.name),
                  p = g instanceof i.Ref && u.getByRef(g);
                if (p)
                  try {
                    const r = n.base ? n.base.getRgb(a, 0) : null,
                      i = (0, l.getTilingPatternIR)(
                        p.operatorListIR,
                        p.dict,
                        r
                      );
                    e.addOp(t, i);
                    return;
                  } catch (e) {}
                const b = this.xref.fetchIfRef(g);
                if (b) {
                  const i = b instanceof m.BaseStream ? b.dict : b,
                    s = i.get("PatternType");
                  if (s === E) {
                    const r = n.base ? n.base.getRgb(a, 0) : null;
                    return this.handleTilingType(t, r, o, b, i, e, c, u);
                  }
                  if (s === D) {
                    const a = i.get("Shading"),
                      r = i.getArray("Matrix"),
                      n = this.parseShading({
                        shading: a,
                        resources: o,
                        localColorSpaceCache: h,
                        localShadingPatternCache: d,
                      });
                    e.addOp(t, ["Shading", n, r]);
                    return;
                  }
                  throw new r.FormatError(`Unknown PatternType: ${s}`);
                }
              }
              throw new r.FormatError(`Unknown PatternName: ${f}`);
            }
            _parseVisibilityExpression(e, t, a) {
              if (++t > 10) {
                (0, r.warn)("Visibility expression is too deeply nested");
                return;
              }
              const n = e.length,
                s = this.xref.fetchIfRef(e[0]);
              if (!(n < 2) && s instanceof i.Name) {
                switch (s.name) {
                  case "And":
                  case "Or":
                  case "Not":
                    a.push(s.name);
                    break;
                  default:
                    (0, r.warn)(
                      `Invalid operator ${s.name} in visibility expression`
                    );
                    return;
                }
                for (let r = 1; r < n; r++) {
                  const n = e[r],
                    s = this.xref.fetchIfRef(n);
                  if (Array.isArray(s)) {
                    const e = [];
                    a.push(e);
                    this._parseVisibilityExpression(s, t, e);
                  } else n instanceof i.Ref && a.push(n.toString());
                }
              } else (0, r.warn)("Invalid visibility expression");
            }
            async parseMarkedContentProps(e, t) {
              let a;
              if (e instanceof i.Name) {
                a = t.get("Properties").get(e.name);
              } else {
                if (!(e instanceof i.Dict))
                  throw new r.FormatError(
                    "Optional content properties malformed."
                  );
                a = e;
              }
              const n = a.get("Type").name;
              if ("OCG" === n) return { type: n, id: a.objId };
              if ("OCMD" === n) {
                const e = a.get("VE");
                if (Array.isArray(e)) {
                  const t = [];
                  this._parseVisibilityExpression(e, 0, t);
                  if (t.length > 0) return { type: "OCMD", expression: t };
                }
                const t = a.get("OCGs");
                if (Array.isArray(t) || t instanceof i.Dict) {
                  const e = [];
                  if (Array.isArray(t)) for (const a of t) e.push(a.toString());
                  else e.push(t.objId);
                  return {
                    type: n,
                    ids: e,
                    policy:
                      a.get("P") instanceof i.Name ? a.get("P").name : null,
                    expression: null,
                  };
                }
                if (t instanceof i.Ref) return { type: n, id: t.toString() };
              }
              return null;
            }
            getOperatorList({
              stream: e,
              task: t,
              resources: a,
              operatorList: n,
              initialState: s = null,
              fallbackFontDict: o = null,
            }) {
              a = a || i.Dict.empty;
              s = s || new EvalState();
              if (!n)
                throw new Error(
                  'getOperatorList: missing "operatorList" parameter'
                );
              const c = this,
                l = this.xref;
              let h = !1;
              const u = new g.LocalImageCache(),
                d = new g.LocalColorSpaceCache(),
                f = new g.LocalGStateCache(),
                p = new g.LocalTilingPatternCache(),
                b = new Map(),
                w = a.get("XObject") || i.Dict.empty,
                S = a.get("Pattern") || i.Dict.empty,
                x = new StateManager(s),
                C = new EvaluatorPreprocessor(e, l, x),
                k = new TimeSlotManager();
              function closePendingRestoreOPS(e) {
                for (let e = 0, t = C.savedStatesDepth; e < t; e++)
                  n.addOp(r.OPS.restore, []);
              }
              return new Promise(function promiseBody(e, s) {
                const next = function (t) {
                  Promise.all([t, n.ready]).then(function () {
                    try {
                      promiseBody(e, s);
                    } catch (e) {
                      s(e);
                    }
                  }, s);
                };
                t.ensureNotTerminated();
                k.reset();
                const g = {};
                let v, F, O, T, M, E;
                for (; !(v = k.check()); ) {
                  g.args = null;
                  if (!C.read(g)) break;
                  let e = g.args,
                    s = g.fn;
                  switch (0 | s) {
                    case r.OPS.paintXObject:
                      E = e[0] instanceof i.Name;
                      M = e[0].name;
                      if (E) {
                        const t = u.getByName(M);
                        if (t) {
                          n.addImageOps(t.fn, t.args, t.optionalContent);
                          incrementCachedImageMaskCount(t);
                          e = null;
                          continue;
                        }
                      }
                      next(
                        new Promise(function (e, s) {
                          if (!E)
                            throw new r.FormatError(
                              "XObject must be referred to by name."
                            );
                          let o = w.getRaw(M);
                          if (o instanceof i.Ref) {
                            const t = u.getByRef(o);
                            if (t) {
                              n.addImageOps(t.fn, t.args, t.optionalContent);
                              incrementCachedImageMaskCount(t);
                              e();
                              return;
                            }
                            const a = c.globalImageCache.getData(
                              o,
                              c.pageIndex
                            );
                            if (a) {
                              n.addDependency(a.objId);
                              n.addImageOps(a.fn, a.args, a.optionalContent);
                              e();
                              return;
                            }
                            o = l.fetch(o);
                          }
                          if (!(o instanceof m.BaseStream))
                            throw new r.FormatError(
                              "XObject should be a stream"
                            );
                          const h = o.dict.get("Subtype");
                          if (!(h instanceof i.Name))
                            throw new r.FormatError(
                              "XObject should have a Name subtype"
                            );
                          if ("Form" !== h.name)
                            if ("Image" !== h.name) {
                              if ("PS" !== h.name)
                                throw new r.FormatError(
                                  `Unhandled XObject subtype ${h.name}`
                                );
                              (0, r.info)("Ignored XObject subtype PS");
                              e();
                            } else
                              c.buildPaintImageXObject({
                                resources: a,
                                image: o,
                                operatorList: n,
                                cacheKey: M,
                                localImageCache: u,
                                localColorSpaceCache: d,
                              }).then(e, s);
                          else {
                            x.save();
                            c.buildFormXObject(
                              a,
                              o,
                              null,
                              n,
                              t,
                              x.state.clone(),
                              d
                            ).then(function () {
                              x.restore();
                              e();
                            }, s);
                          }
                        }).catch(function (e) {
                          if (!(e instanceof r.AbortException)) {
                            if (!c.options.ignoreErrors) throw e;
                            c.handler.send("UnsupportedFeature", {
                              featureId: r.UNSUPPORTED_FEATURES.errorXObject,
                            });
                            (0, r.warn)(
                              `getOperatorList - ignoring XObject: "${e}".`
                            );
                          }
                        })
                      );
                      return;
                    case r.OPS.setFont:
                      var D = e[1];
                      next(
                        c
                          .handleSetFont(a, e, null, n, t, x.state, o)
                          .then(function (e) {
                            n.addDependency(e);
                            n.addOp(r.OPS.setFont, [e, D]);
                          })
                      );
                      return;
                    case r.OPS.beginText:
                      h = !0;
                      break;
                    case r.OPS.endText:
                      h = !1;
                      break;
                    case r.OPS.endInlineImage:
                      var N = e[0].cacheKey;
                      if (N) {
                        const t = u.getByName(N);
                        if (t) {
                          n.addImageOps(t.fn, t.args, t.optionalContent);
                          incrementCachedImageMaskCount(t);
                          e = null;
                          continue;
                        }
                      }
                      next(
                        c.buildPaintImageXObject({
                          resources: a,
                          image: e[0],
                          isInline: !0,
                          operatorList: n,
                          cacheKey: N,
                          localImageCache: u,
                          localColorSpaceCache: d,
                        })
                      );
                      return;
                    case r.OPS.showText:
                      if (!x.state.font) {
                        c.ensureStateFont(x.state);
                        continue;
                      }
                      e[0] = c.handleText(e[0], x.state);
                      break;
                    case r.OPS.showSpacedText:
                      if (!x.state.font) {
                        c.ensureStateFont(x.state);
                        continue;
                      }
                      var L = [],
                        j = x.state;
                      for (const t of e[0])
                        "string" == typeof t
                          ? L.push(...c.handleText(t, j))
                          : "number" == typeof t && L.push(t);
                      e[0] = L;
                      s = r.OPS.showText;
                      break;
                    case r.OPS.nextLineShowText:
                      if (!x.state.font) {
                        c.ensureStateFont(x.state);
                        continue;
                      }
                      n.addOp(r.OPS.nextLine);
                      e[0] = c.handleText(e[0], x.state);
                      s = r.OPS.showText;
                      break;
                    case r.OPS.nextLineSetSpacingShowText:
                      if (!x.state.font) {
                        c.ensureStateFont(x.state);
                        continue;
                      }
                      n.addOp(r.OPS.nextLine);
                      n.addOp(r.OPS.setWordSpacing, [e.shift()]);
                      n.addOp(r.OPS.setCharSpacing, [e.shift()]);
                      e[0] = c.handleText(e[0], x.state);
                      s = r.OPS.showText;
                      break;
                    case r.OPS.setTextRenderingMode:
                      x.state.textRenderingMode = e[0];
                      break;
                    case r.OPS.setFillColorSpace: {
                      const t = y.ColorSpace.getCached(e[0], l, d);
                      if (t) {
                        x.state.fillColorSpace = t;
                        continue;
                      }
                      next(
                        c
                          .parseColorSpace({
                            cs: e[0],
                            resources: a,
                            localColorSpaceCache: d,
                          })
                          .then(function (e) {
                            e && (x.state.fillColorSpace = e);
                          })
                      );
                      return;
                    }
                    case r.OPS.setStrokeColorSpace: {
                      const t = y.ColorSpace.getCached(e[0], l, d);
                      if (t) {
                        x.state.strokeColorSpace = t;
                        continue;
                      }
                      next(
                        c
                          .parseColorSpace({
                            cs: e[0],
                            resources: a,
                            localColorSpaceCache: d,
                          })
                          .then(function (e) {
                            e && (x.state.strokeColorSpace = e);
                          })
                      );
                      return;
                    }
                    case r.OPS.setFillColor:
                      T = x.state.fillColorSpace;
                      e = T.getRgb(e, 0);
                      s = r.OPS.setFillRGBColor;
                      break;
                    case r.OPS.setStrokeColor:
                      T = x.state.strokeColorSpace;
                      e = T.getRgb(e, 0);
                      s = r.OPS.setStrokeRGBColor;
                      break;
                    case r.OPS.setFillGray:
                      x.state.fillColorSpace = y.ColorSpace.singletons.gray;
                      e = y.ColorSpace.singletons.gray.getRgb(e, 0);
                      s = r.OPS.setFillRGBColor;
                      break;
                    case r.OPS.setStrokeGray:
                      x.state.strokeColorSpace = y.ColorSpace.singletons.gray;
                      e = y.ColorSpace.singletons.gray.getRgb(e, 0);
                      s = r.OPS.setStrokeRGBColor;
                      break;
                    case r.OPS.setFillCMYKColor:
                      x.state.fillColorSpace = y.ColorSpace.singletons.cmyk;
                      e = y.ColorSpace.singletons.cmyk.getRgb(e, 0);
                      s = r.OPS.setFillRGBColor;
                      break;
                    case r.OPS.setStrokeCMYKColor:
                      x.state.strokeColorSpace = y.ColorSpace.singletons.cmyk;
                      e = y.ColorSpace.singletons.cmyk.getRgb(e, 0);
                      s = r.OPS.setStrokeRGBColor;
                      break;
                    case r.OPS.setFillRGBColor:
                      x.state.fillColorSpace = y.ColorSpace.singletons.rgb;
                      e = y.ColorSpace.singletons.rgb.getRgb(e, 0);
                      break;
                    case r.OPS.setStrokeRGBColor:
                      x.state.strokeColorSpace = y.ColorSpace.singletons.rgb;
                      e = y.ColorSpace.singletons.rgb.getRgb(e, 0);
                      break;
                    case r.OPS.setFillColorN:
                      T = x.state.fillColorSpace;
                      if ("Pattern" === T.name) {
                        next(
                          c.handleColorN(
                            n,
                            r.OPS.setFillColorN,
                            e,
                            T,
                            S,
                            a,
                            t,
                            d,
                            p,
                            b
                          )
                        );
                        return;
                      }
                      e = T.getRgb(e, 0);
                      s = r.OPS.setFillRGBColor;
                      break;
                    case r.OPS.setStrokeColorN:
                      T = x.state.strokeColorSpace;
                      if ("Pattern" === T.name) {
                        next(
                          c.handleColorN(
                            n,
                            r.OPS.setStrokeColorN,
                            e,
                            T,
                            S,
                            a,
                            t,
                            d,
                            p,
                            b
                          )
                        );
                        return;
                      }
                      e = T.getRgb(e, 0);
                      s = r.OPS.setStrokeRGBColor;
                      break;
                    case r.OPS.shadingFill:
                      var $ = a.get("Shading");
                      if (!$)
                        throw new r.FormatError("No shading resource found");
                      var _ = $.get(e[0].name);
                      if (!_)
                        throw new r.FormatError("No shading object found");
                      e = [
                        c.parseShading({
                          shading: _,
                          resources: a,
                          localColorSpaceCache: d,
                          localShadingPatternCache: b,
                        }),
                      ];
                      s = r.OPS.shadingFill;
                      break;
                    case r.OPS.setGState:
                      E = e[0] instanceof i.Name;
                      M = e[0].name;
                      if (E) {
                        const t = f.getByName(M);
                        if (t) {
                          t.length > 0 && n.addOp(r.OPS.setGState, [t]);
                          e = null;
                          continue;
                        }
                      }
                      next(
                        new Promise(function (e, s) {
                          if (!E)
                            throw new r.FormatError(
                              "GState must be referred to by name."
                            );
                          const o = a.get("ExtGState");
                          if (!(o instanceof i.Dict))
                            throw new r.FormatError(
                              "ExtGState should be a dictionary."
                            );
                          const l = o.get(M);
                          if (!(l instanceof i.Dict))
                            throw new r.FormatError(
                              "GState should be a dictionary."
                            );
                          c.setGState({
                            resources: a,
                            gState: l,
                            operatorList: n,
                            cacheKey: M,
                            task: t,
                            stateManager: x,
                            localGStateCache: f,
                            localColorSpaceCache: d,
                          }).then(e, s);
                        }).catch(function (e) {
                          if (!(e instanceof r.AbortException)) {
                            if (!c.options.ignoreErrors) throw e;
                            c.handler.send("UnsupportedFeature", {
                              featureId: r.UNSUPPORTED_FEATURES.errorExtGState,
                            });
                            (0, r.warn)(
                              `getOperatorList - ignoring ExtGState: "${e}".`
                            );
                          }
                        })
                      );
                      return;
                    case r.OPS.moveTo:
                    case r.OPS.lineTo:
                    case r.OPS.curveTo:
                    case r.OPS.curveTo2:
                    case r.OPS.curveTo3:
                    case r.OPS.closePath:
                    case r.OPS.rectangle:
                      c.buildPath(n, s, e, h);
                      continue;
                    case r.OPS.markPoint:
                    case r.OPS.markPointProps:
                    case r.OPS.beginCompat:
                    case r.OPS.endCompat:
                      continue;
                    case r.OPS.beginMarkedContentProps:
                      if (!(e[0] instanceof i.Name)) {
                        (0, r.warn)(
                          `Expected name for beginMarkedContentProps arg0=${e[0]}`
                        );
                        continue;
                      }
                      if ("OC" === e[0].name) {
                        next(
                          c
                            .parseMarkedContentProps(e[1], a)
                            .then((e) => {
                              n.addOp(r.OPS.beginMarkedContentProps, ["OC", e]);
                            })
                            .catch((e) => {
                              if (!(e instanceof r.AbortException)) {
                                if (!c.options.ignoreErrors) throw e;
                                c.handler.send("UnsupportedFeature", {
                                  featureId:
                                    r.UNSUPPORTED_FEATURES.errorMarkedContent,
                                });
                                (0, r.warn)(
                                  `getOperatorList - ignoring beginMarkedContentProps: "${e}".`
                                );
                              }
                            })
                        );
                        return;
                      }
                      e = [
                        e[0].name,
                        e[1] instanceof i.Dict ? e[1].get("MCID") : null,
                      ];
                      break;
                    case r.OPS.beginMarkedContent:
                    case r.OPS.endMarkedContent:
                    default:
                      if (null !== e) {
                        for (
                          F = 0, O = e.length;
                          F < O && !(e[F] instanceof i.Dict);
                          F++
                        );
                        if (F < O) {
                          (0, r.warn)(
                            "getOperatorList - ignoring operator: " + s
                          );
                          continue;
                        }
                      }
                  }
                  n.addOp(s, e);
                }
                if (v) next(R);
                else {
                  closePendingRestoreOPS();
                  e();
                }
              }).catch((e) => {
                if (!(e instanceof r.AbortException)) {
                  if (!this.options.ignoreErrors) throw e;
                  this.handler.send("UnsupportedFeature", {
                    featureId: r.UNSUPPORTED_FEATURES.errorOperatorList,
                  });
                  (0, r.warn)(
                    `getOperatorList - ignoring errors during "${t.name}" task: "${e}".`
                  );
                  closePendingRestoreOPS();
                }
              });
            }
            getTextContent({
              stream: e,
              task: t,
              resources: a,
              stateManager: n = null,
              combineTextItems: s = !1,
              includeMarkedContent: o = !1,
              sink: c,
              seenStyles: l = new Set(),
              viewBox: h,
              markedContentData: u = null,
            }) {
              a = a || i.Dict.empty;
              n = n || new StateManager(new TextState());
              o && (u = u || { level: 0 });
              const d = { items: [], styles: Object.create(null) },
                f = {
                  initialized: !1,
                  str: [],
                  totalWidth: 0,
                  totalHeight: 0,
                  width: 0,
                  height: 0,
                  vertical: !1,
                  prevTransform: null,
                  textAdvanceScale: 0,
                  spaceInFlowMin: 0,
                  spaceInFlowMax: 0,
                  trackingSpaceMin: 1 / 0,
                  negativeSpaceMax: -1 / 0,
                  notASpace: -1 / 0,
                  transform: null,
                  fontName: null,
                  hasEOL: !1,
                },
                p = [" ", " "];
              let y = 0;
              function saveLastChar(e) {
                const t = (y + 1) % 2,
                  a = " " !== p[y] && " " === p[t];
                p[y] = e;
                y = t;
                return a;
              }
              function resetLastChars() {
                p[0] = p[1] = " ";
                y = 0;
              }
              const w = 0.1,
                S = 0.03,
                x = -0.2,
                C = 0.1,
                k = 0.6,
                v = this,
                F = this.xref,
                O = [];
              let T = null;
              const M = new g.LocalImageCache(),
                E = new g.LocalGStateCache(),
                D = new EvaluatorPreprocessor(e, F, n);
              let L;
              function getCurrentTextTransform() {
                const e = L.font,
                  t = [
                    L.fontSize * L.textHScale,
                    0,
                    0,
                    L.fontSize,
                    0,
                    L.textRise,
                  ];
                if (
                  e.isType3Font &&
                  (L.fontSize <= 1 || e.isCharBBox) &&
                  !(0, r.isArrayEqual)(L.fontMatrix, r.FONT_IDENTITY_MATRIX)
                ) {
                  const a = e.bbox[3] - e.bbox[1];
                  a > 0 && (t[3] *= a * L.fontMatrix[3]);
                }
                return r.Util.transform(
                  L.ctm,
                  r.Util.transform(L.textMatrix, t)
                );
              }
              function ensureTextContentItem() {
                if (f.initialized) return f;
                const { font: e, loadedName: t } = L;
                if (!l.has(t)) {
                  l.add(t);
                  d.styles[t] = {
                    fontFamily: e.fallbackName,
                    ascent: e.ascent,
                    descent: e.descent,
                    vertical: e.vertical,
                  };
                }
                f.fontName = t;
                const a = (f.transform = getCurrentTextTransform());
                if (e.vertical) {
                  f.width = f.totalWidth = Math.hypot(a[0], a[1]);
                  f.height = f.totalHeight = 0;
                  f.vertical = !0;
                } else {
                  f.width = f.totalWidth = 0;
                  f.height = f.totalHeight = Math.hypot(a[2], a[3]);
                  f.vertical = !1;
                }
                const r = Math.hypot(L.textLineMatrix[0], L.textLineMatrix[1]),
                  n = Math.hypot(L.ctm[0], L.ctm[1]);
                f.textAdvanceScale = n * r;
                f.trackingSpaceMin = L.fontSize * w;
                f.notASpace = L.fontSize * S;
                f.negativeSpaceMax = L.fontSize * x;
                f.spaceInFlowMin = L.fontSize * C;
                f.spaceInFlowMax = L.fontSize * k;
                f.hasEOL = !1;
                f.initialized = !0;
                return f;
              }
              function updateAdvanceScale() {
                if (!f.initialized) return;
                const e = Math.hypot(L.textLineMatrix[0], L.textLineMatrix[1]),
                  t = Math.hypot(L.ctm[0], L.ctm[1]) * e;
                if (t !== f.textAdvanceScale) {
                  if (f.vertical) {
                    f.totalHeight += f.height * f.textAdvanceScale;
                    f.height = 0;
                  } else {
                    f.totalWidth += f.width * f.textAdvanceScale;
                    f.width = 0;
                  }
                  f.textAdvanceScale = t;
                }
              }
              function handleSetFont(e, n) {
                return v
                  .loadFont(e, n, a)
                  .then(function (e) {
                    return e.font.isType3Font
                      ? e
                          .loadType3Data(v, a, t)
                          .catch(function () {})
                          .then(function () {
                            return e;
                          })
                      : e;
                  })
                  .then(function (e) {
                    L.loadedName = e.loadedName;
                    L.font = e.font;
                    L.fontMatrix = e.font.fontMatrix || r.FONT_IDENTITY_MATRIX;
                  });
              }
              function applyInverseRotation(e, t, a) {
                const r = Math.hypot(a[0], a[1]);
                return [(a[0] * e + a[1] * t) / r, (a[2] * e + a[3] * t) / r];
              }
              function compareWithLastPosition() {
                const e = getCurrentTextTransform();
                let t = e[4],
                  a = e[5];
                const r = t - h[0],
                  n = a - h[1];
                if (r < 0 || r > h[2] || n < 0 || n > h[3]) return !1;
                if (!s || !L.font || !f.prevTransform) return !0;
                let i = f.prevTransform[4],
                  o = f.prevTransform[5];
                if (i === t && o === a) return !0;
                let c = -1;
                e[0] && 0 === e[1] && 0 === e[2]
                  ? (c = e[0] > 0 ? 0 : 180)
                  : e[1] &&
                    0 === e[0] &&
                    0 === e[3] &&
                    (c = e[1] > 0 ? 90 : 270);
                switch (c) {
                  case 0:
                    break;
                  case 90:
                    [t, a] = [a, t];
                    [i, o] = [o, i];
                    break;
                  case 180:
                    [t, a, i, o] = [-t, -a, -i, -o];
                    break;
                  case 270:
                    [t, a] = [-a, -t];
                    [i, o] = [-o, -i];
                    break;
                  default:
                    [t, a] = applyInverseRotation(t, a, e);
                    [i, o] = applyInverseRotation(i, o, f.prevTransform);
                }
                if (L.font.vertical) {
                  const e = (o - a) / f.textAdvanceScale,
                    r = t - i,
                    n = Math.sign(f.height);
                  if (e < n * f.negativeSpaceMax) {
                    if (Math.abs(r) > 0.5 * f.width) {
                      appendEOL();
                      return !0;
                    }
                    resetLastChars();
                    flushTextContentItem();
                    return !0;
                  }
                  if (Math.abs(r) > f.width) {
                    appendEOL();
                    return !0;
                  }
                  e <= n * f.notASpace && resetLastChars();
                  if (e <= n * f.trackingSpaceMin) f.height += e;
                  else if (!addFakeSpaces(e, f.prevTransform, n))
                    if (0 === f.str.length) {
                      resetLastChars();
                      d.items.push({
                        str: " ",
                        dir: "ltr",
                        width: 0,
                        height: Math.abs(e),
                        transform: f.prevTransform,
                        fontName: f.fontName,
                        hasEOL: !1,
                      });
                    } else f.height += e;
                  return !0;
                }
                const l = (t - i) / f.textAdvanceScale,
                  u = a - o,
                  g = Math.sign(f.width);
                if (l < g * f.negativeSpaceMax) {
                  if (Math.abs(u) > 0.5 * f.height) {
                    appendEOL();
                    return !0;
                  }
                  resetLastChars();
                  flushTextContentItem();
                  return !0;
                }
                if (Math.abs(u) > f.height) {
                  appendEOL();
                  return !0;
                }
                l <= g * f.notASpace && resetLastChars();
                if (l <= g * f.trackingSpaceMin) f.width += l;
                else if (!addFakeSpaces(l, f.prevTransform, g))
                  if (0 === f.str.length) {
                    resetLastChars();
                    d.items.push({
                      str: " ",
                      dir: "ltr",
                      width: Math.abs(l),
                      height: 0,
                      transform: f.prevTransform,
                      fontName: f.fontName,
                      hasEOL: !1,
                    });
                  } else f.width += l;
                return !0;
              }
              function buildTextContentItem({ chars: e, extraSpacing: t }) {
                const a = L.font;
                if (!e) {
                  const e = L.charSpacing + t;
                  e &&
                    (a.vertical
                      ? L.translateTextMatrix(0, -e)
                      : L.translateTextMatrix(e * L.textHScale, 0));
                  return;
                }
                const r = a.charsToGlyphs(e),
                  n = L.fontMatrix[0] * L.fontSize;
                for (let e = 0, i = r.length; e < i; e++) {
                  const s = r[e],
                    { category: o } = s;
                  if (o.isInvisibleFormatMark) continue;
                  let c = L.charSpacing + (e + 1 === i ? t : 0),
                    l = s.width;
                  a.vertical && (l = s.vmetric ? s.vmetric[0] : -l);
                  let h = l * n;
                  if (o.isWhitespace) {
                    if (a.vertical) {
                      c += -h + L.wordSpacing;
                      L.translateTextMatrix(0, -c);
                    } else {
                      c += h + L.wordSpacing;
                      L.translateTextMatrix(c * L.textHScale, 0);
                    }
                    saveLastChar(" ");
                    continue;
                  }
                  if (!o.isZeroWidthDiacritic && !compareWithLastPosition())
                    continue;
                  const u = ensureTextContentItem();
                  o.isZeroWidthDiacritic && (h = 0);
                  if (a.vertical) {
                    L.translateTextMatrix(0, h);
                    h = Math.abs(h);
                    u.height += h;
                  } else {
                    h *= L.textHScale;
                    L.translateTextMatrix(h, 0);
                    u.width += h;
                  }
                  h && (u.prevTransform = getCurrentTextTransform());
                  const d = s.normalizedUnicode;
                  saveLastChar(d) && u.str.push(" ");
                  u.str.push(d);
                  c &&
                    (a.vertical
                      ? L.translateTextMatrix(0, -c)
                      : L.translateTextMatrix(c * L.textHScale, 0));
                }
              }
              function appendEOL() {
                resetLastChars();
                if (f.initialized) {
                  f.hasEOL = !0;
                  flushTextContentItem();
                } else
                  d.items.push({
                    str: "",
                    dir: "ltr",
                    width: 0,
                    height: 0,
                    transform: getCurrentTextTransform(),
                    fontName: L.loadedName,
                    hasEOL: !0,
                  });
              }
              function addFakeSpaces(e, t, a) {
                if (a * f.spaceInFlowMin <= e && e <= a * f.spaceInFlowMax) {
                  if (f.initialized) {
                    resetLastChars();
                    f.str.push(" ");
                  }
                  return !1;
                }
                const r = f.fontName;
                let n = 0;
                if (f.vertical) {
                  n = e;
                  e = 0;
                }
                flushTextContentItem();
                resetLastChars();
                d.items.push({
                  str: " ",
                  dir: "ltr",
                  width: Math.abs(e),
                  height: Math.abs(n),
                  transform: t || getCurrentTextTransform(),
                  fontName: r,
                  hasEOL: !1,
                });
                return !0;
              }
              function flushTextContentItem() {
                if (f.initialized && f.str) {
                  f.vertical
                    ? (f.totalHeight += f.height * f.textAdvanceScale)
                    : (f.totalWidth += f.width * f.textAdvanceScale);
                  d.items.push(
                    (function runBidiTransform(e) {
                      const t = e.str.join(""),
                        a = (0, b.bidi)(t, -1, e.vertical);
                      return {
                        str: a.str,
                        dir: a.dir,
                        width: Math.abs(e.totalWidth),
                        height: Math.abs(e.totalHeight),
                        transform: e.transform,
                        fontName: e.fontName,
                        hasEOL: e.hasEOL,
                      };
                    })(f)
                  );
                  f.initialized = !1;
                  f.str.length = 0;
                }
              }
              function enqueueChunk(e = !1) {
                const t = d.items.length;
                if (0 !== t && !(e && t < N)) {
                  c.enqueue(d, t);
                  d.items = [];
                  d.styles = Object.create(null);
                }
              }
              const j = new TimeSlotManager();
              return new Promise(function promiseBody(e, f) {
                const next = function (t) {
                  enqueueChunk(!0);
                  Promise.all([t, c.ready]).then(function () {
                    try {
                      promiseBody(e, f);
                    } catch (e) {
                      f(e);
                    }
                  }, f);
                };
                t.ensureNotTerminated();
                j.reset();
                const g = {};
                let p,
                  b = [];
                for (; !(p = j.check()); ) {
                  b.length = 0;
                  g.args = b;
                  if (!D.read(g)) break;
                  L = n.state;
                  const e = g.fn;
                  b = g.args;
                  switch (0 | e) {
                    case r.OPS.setFont:
                      var y = b[0].name,
                        w = b[1];
                      if (L.font && y === L.fontName && w === L.fontSize) break;
                      flushTextContentItem();
                      L.fontName = y;
                      L.fontSize = w;
                      next(handleSetFont(y, null));
                      return;
                    case r.OPS.setTextRise:
                      L.textRise = b[0];
                      break;
                    case r.OPS.setHScale:
                      L.textHScale = b[0] / 100;
                      break;
                    case r.OPS.setLeading:
                      L.leading = b[0];
                      break;
                    case r.OPS.moveText:
                      L.translateTextLineMatrix(b[0], b[1]);
                      L.textMatrix = L.textLineMatrix.slice();
                      break;
                    case r.OPS.setLeadingMoveText:
                      L.leading = -b[1];
                      L.translateTextLineMatrix(b[0], b[1]);
                      L.textMatrix = L.textLineMatrix.slice();
                      break;
                    case r.OPS.nextLine:
                      L.carriageReturn();
                      break;
                    case r.OPS.setTextMatrix:
                      L.setTextMatrix(b[0], b[1], b[2], b[3], b[4], b[5]);
                      L.setTextLineMatrix(b[0], b[1], b[2], b[3], b[4], b[5]);
                      updateAdvanceScale();
                      break;
                    case r.OPS.setCharSpacing:
                      L.charSpacing = b[0];
                      break;
                    case r.OPS.setWordSpacing:
                      L.wordSpacing = b[0];
                      break;
                    case r.OPS.beginText:
                      L.textMatrix = r.IDENTITY_MATRIX.slice();
                      L.textLineMatrix = r.IDENTITY_MATRIX.slice();
                      break;
                    case r.OPS.showSpacedText:
                      if (!n.state.font) {
                        v.ensureStateFont(n.state);
                        continue;
                      }
                      const e = ((L.font.vertical ? 1 : -1) * L.fontSize) / 1e3,
                        f = b[0];
                      for (let t = 0, a = f.length; t < a - 1; t++) {
                        const a = f[t];
                        if ("string" == typeof a) O.push(a);
                        else if ("number" == typeof a && 0 !== a) {
                          const t = O.join("");
                          O.length = 0;
                          buildTextContentItem({
                            chars: t,
                            extraSpacing: a * e,
                          });
                        }
                      }
                      const g = f.at(-1);
                      "string" == typeof g && O.push(g);
                      if (O.length > 0) {
                        const e = O.join("");
                        O.length = 0;
                        buildTextContentItem({ chars: e, extraSpacing: 0 });
                      }
                      break;
                    case r.OPS.showText:
                      if (!n.state.font) {
                        v.ensureStateFont(n.state);
                        continue;
                      }
                      buildTextContentItem({ chars: b[0], extraSpacing: 0 });
                      break;
                    case r.OPS.nextLineShowText:
                      if (!n.state.font) {
                        v.ensureStateFont(n.state);
                        continue;
                      }
                      L.carriageReturn();
                      buildTextContentItem({ chars: b[0], extraSpacing: 0 });
                      break;
                    case r.OPS.nextLineSetSpacingShowText:
                      if (!n.state.font) {
                        v.ensureStateFont(n.state);
                        continue;
                      }
                      L.wordSpacing = b[0];
                      L.charSpacing = b[1];
                      L.carriageReturn();
                      buildTextContentItem({ chars: b[2], extraSpacing: 0 });
                      break;
                    case r.OPS.paintXObject:
                      flushTextContentItem();
                      T || (T = a.get("XObject") || i.Dict.empty);
                      var S = b[0] instanceof i.Name,
                        x = b[0].name;
                      if (S && M.getByName(x)) break;
                      next(
                        new Promise(function (e, d) {
                          if (!S)
                            throw new r.FormatError(
                              "XObject must be referred to by name."
                            );
                          let f = T.getRaw(x);
                          if (f instanceof i.Ref) {
                            if (M.getByRef(f)) {
                              e();
                              return;
                            }
                            if (v.globalImageCache.getData(f, v.pageIndex)) {
                              e();
                              return;
                            }
                            f = F.fetch(f);
                          }
                          if (!(f instanceof m.BaseStream))
                            throw new r.FormatError(
                              "XObject should be a stream"
                            );
                          const g = f.dict.get("Subtype");
                          if (!(g instanceof i.Name))
                            throw new r.FormatError(
                              "XObject should have a Name subtype"
                            );
                          if ("Form" !== g.name) {
                            M.set(x, f.dict.objId, !0);
                            e();
                            return;
                          }
                          const p = n.state.clone(),
                            b = new StateManager(p),
                            y = f.dict.getArray("Matrix");
                          Array.isArray(y) && 6 === y.length && b.transform(y);
                          enqueueChunk();
                          const w = {
                            enqueueInvoked: !1,
                            enqueue(e, t) {
                              this.enqueueInvoked = !0;
                              c.enqueue(e, t);
                            },
                            get desiredSize() {
                              return c.desiredSize;
                            },
                            get ready() {
                              return c.ready;
                            },
                          };
                          v.getTextContent({
                            stream: f,
                            task: t,
                            resources: f.dict.get("Resources") || a,
                            stateManager: b,
                            combineTextItems: s,
                            includeMarkedContent: o,
                            sink: w,
                            seenStyles: l,
                            viewBox: h,
                            markedContentData: u,
                          }).then(function () {
                            w.enqueueInvoked || M.set(x, f.dict.objId, !0);
                            e();
                          }, d);
                        }).catch(function (e) {
                          if (!(e instanceof r.AbortException)) {
                            if (!v.options.ignoreErrors) throw e;
                            (0, r.warn)(
                              `getTextContent - ignoring XObject: "${e}".`
                            );
                          }
                        })
                      );
                      return;
                    case r.OPS.setGState:
                      S = b[0] instanceof i.Name;
                      x = b[0].name;
                      if (S && E.getByName(x)) break;
                      next(
                        new Promise(function (e, t) {
                          if (!S)
                            throw new r.FormatError(
                              "GState must be referred to by name."
                            );
                          const n = a.get("ExtGState");
                          if (!(n instanceof i.Dict))
                            throw new r.FormatError(
                              "ExtGState should be a dictionary."
                            );
                          const s = n.get(x);
                          if (!(s instanceof i.Dict))
                            throw new r.FormatError(
                              "GState should be a dictionary."
                            );
                          const o = s.get("Font");
                          if (o) {
                            flushTextContentItem();
                            L.fontName = null;
                            L.fontSize = o[1];
                            handleSetFont(null, o[0]).then(e, t);
                          } else {
                            E.set(x, s.objId, !0);
                            e();
                          }
                        }).catch(function (e) {
                          if (!(e instanceof r.AbortException)) {
                            if (!v.options.ignoreErrors) throw e;
                            (0, r.warn)(
                              `getTextContent - ignoring ExtGState: "${e}".`
                            );
                          }
                        })
                      );
                      return;
                    case r.OPS.beginMarkedContent:
                      flushTextContentItem();
                      if (o) {
                        u.level++;
                        d.items.push({
                          type: "beginMarkedContent",
                          tag: b[0] instanceof i.Name ? b[0].name : null,
                        });
                      }
                      break;
                    case r.OPS.beginMarkedContentProps:
                      flushTextContentItem();
                      if (o) {
                        u.level++;
                        let e = null;
                        b[1] instanceof i.Dict && (e = b[1].get("MCID"));
                        d.items.push({
                          type: "beginMarkedContentProps",
                          id: Number.isInteger(e)
                            ? `${v.idFactory.getPageObjId()}_mcid${e}`
                            : null,
                          tag: b[0] instanceof i.Name ? b[0].name : null,
                        });
                      }
                      break;
                    case r.OPS.endMarkedContent:
                      flushTextContentItem();
                      if (o) {
                        if (0 === u.level) break;
                        u.level--;
                        d.items.push({ type: "endMarkedContent" });
                      }
                  }
                  if (d.items.length >= c.desiredSize) {
                    p = !0;
                    break;
                  }
                }
                if (p) next(R);
                else {
                  flushTextContentItem();
                  enqueueChunk();
                  e();
                }
              }).catch((e) => {
                if (!(e instanceof r.AbortException)) {
                  if (!this.options.ignoreErrors) throw e;
                  (0, r.warn)(
                    `getTextContent - ignoring errors during "${t.name}" task: "${e}".`
                  );
                  flushTextContentItem();
                  enqueueChunk();
                }
              });
            }
            extractDataStructures(e, t, a) {
              const n = this.xref;
              let s;
              const c = this.readToUnicode(
                a.toUnicode || e.get("ToUnicode") || t.get("ToUnicode")
              );
              if (a.composite) {
                const t = e.get("CIDSystemInfo");
                t instanceof i.Dict &&
                  (a.cidSystemInfo = {
                    registry: (0, r.stringToPDFString)(t.get("Registry")),
                    ordering: (0, r.stringToPDFString)(t.get("Ordering")),
                    supplement: t.get("Supplement"),
                  });
                try {
                  const t = e.get("CIDToGIDMap");
                  t instanceof m.BaseStream && (s = t.getBytes());
                } catch (e) {
                  if (!this.options.ignoreErrors) throw e;
                  (0, r.warn)(
                    `extractDataStructures - ignoring CIDToGIDMap data: "${e}".`
                  );
                }
              }
              const l = [];
              let h,
                u = null;
              if (e.has("Encoding")) {
                h = e.get("Encoding");
                if (h instanceof i.Dict) {
                  u = h.get("BaseEncoding");
                  u = u instanceof i.Name ? u.name : null;
                  if (h.has("Differences")) {
                    const e = h.get("Differences");
                    let t = 0;
                    for (const a of e) {
                      const e = n.fetchIfRef(a);
                      if ("number" == typeof e) t = e;
                      else {
                        if (!(e instanceof i.Name))
                          throw new r.FormatError(
                            `Invalid entry in 'Differences' array: ${e}`
                          );
                        l[t++] = e.name;
                      }
                    }
                  }
                } else if (h instanceof i.Name) u = h.name;
                else {
                  const e = "Encoding is not a Name nor a Dict";
                  if (!this.options.ignoreErrors) throw new r.FormatError(e);
                  (0, r.warn)(e);
                }
                "MacRomanEncoding" !== u &&
                  "MacExpertEncoding" !== u &&
                  "WinAnsiEncoding" !== u &&
                  (u = null);
              }
              if (u) a.defaultEncoding = (0, o.getEncoding)(u);
              else {
                const e = !!(a.flags & S.FontFlags.Symbolic),
                  t = !!(a.flags & S.FontFlags.Nonsymbolic);
                h = o.StandardEncoding;
                "TrueType" !== a.type || t || (h = o.WinAnsiEncoding);
                if (e) {
                  h = o.MacRomanEncoding;
                  (a.file && !a.isInternalFont) ||
                    (/Symbol/i.test(a.name)
                      ? (h = o.SymbolSetEncoding)
                      : /Dingbats|Wingdings/i.test(a.name) &&
                        (h = o.ZapfDingbatsEncoding));
                }
                a.defaultEncoding = h;
              }
              a.differences = l;
              a.baseEncodingName = u;
              a.hasEncoding = !!u || l.length > 0;
              a.dict = e;
              return c
                .then((e) => {
                  a.toUnicode = e;
                  return this.buildToUnicode(a);
                })
                .then((e) => {
                  a.toUnicode = e;
                  s && (a.cidToGidMap = this.readCidToGidMap(s, e));
                  return a;
                });
            }
            _simpleFontToUnicode(e, t = !1) {
              (0, r.assert)(!e.composite, "Must be a simple font.");
              const a = [],
                n = e.defaultEncoding.slice(),
                i = e.baseEncodingName,
                s = e.differences;
              for (const e in s) {
                const t = s[e];
                ".notdef" !== t && (n[e] = t);
              }
              const c = (0, x.getGlyphsUnicode)();
              for (const r in n) {
                let s = n[r];
                if ("" === s) continue;
                let l = c[s];
                if (void 0 !== l) {
                  a[r] = String.fromCharCode(l);
                  continue;
                }
                let h = 0;
                switch (s[0]) {
                  case "G":
                    3 === s.length && (h = parseInt(s.substring(1), 16));
                    break;
                  case "g":
                    5 === s.length && (h = parseInt(s.substring(1), 16));
                    break;
                  case "C":
                  case "c":
                    if (s.length >= 3 && s.length <= 4) {
                      const a = s.substring(1);
                      if (t) {
                        h = parseInt(a, 16);
                        break;
                      }
                      h = +a;
                      if (Number.isNaN(h) && Number.isInteger(parseInt(a, 16)))
                        return this._simpleFontToUnicode(e, !0);
                    }
                    break;
                  case "u":
                    l = (0, v.getUnicodeForGlyph)(s, c);
                    -1 !== l && (h = l);
                }
                if (h > 0 && h <= 1114111 && Number.isInteger(h)) {
                  if (i && h === +r) {
                    const e = (0, o.getEncoding)(i);
                    if (e && (s = e[r])) {
                      a[r] = String.fromCharCode(c[s]);
                      continue;
                    }
                  }
                  a[r] = String.fromCodePoint(h);
                }
              }
              return a;
            }
            async buildToUnicode(e) {
              e.hasIncludedToUnicodeMap =
                !!e.toUnicode && e.toUnicode.length > 0;
              if (e.hasIncludedToUnicodeMap) {
                !e.composite &&
                  e.hasEncoding &&
                  (e.fallbackToUnicode = this._simpleFontToUnicode(e));
                return e.toUnicode;
              }
              if (!e.composite)
                return new u.ToUnicodeMap(this._simpleFontToUnicode(e));
              if (
                e.composite &&
                ((e.cMap.builtInCMap && !(e.cMap instanceof n.IdentityCMap)) ||
                  ("Adobe" === e.cidSystemInfo.registry &&
                    ("GB1" === e.cidSystemInfo.ordering ||
                      "CNS1" === e.cidSystemInfo.ordering ||
                      "Japan1" === e.cidSystemInfo.ordering ||
                      "Korea1" === e.cidSystemInfo.ordering)))
              ) {
                const { registry: t, ordering: a } = e.cidSystemInfo,
                  s = i.Name.get(`${t}-${a}-UCS2`),
                  o = await n.CMapFactory.create({
                    encoding: s,
                    fetchBuiltInCMap: this._fetchBuiltInCMapBound,
                    useCMap: null,
                  }),
                  c = [];
                e.cMap.forEach(function (e, t) {
                  if (t > 65535)
                    throw new r.FormatError("Max size of CID is 65,535");
                  const a = o.lookup(t);
                  a &&
                    (c[e] = String.fromCharCode(
                      (a.charCodeAt(0) << 8) + a.charCodeAt(1)
                    ));
                });
                return new u.ToUnicodeMap(c);
              }
              return new u.IdentityToUnicodeMap(e.firstChar, e.lastChar);
            }
            readToUnicode(e) {
              return e
                ? e instanceof i.Name
                  ? n.CMapFactory.create({
                      encoding: e,
                      fetchBuiltInCMap: this._fetchBuiltInCMapBound,
                      useCMap: null,
                    }).then(function (e) {
                      return e instanceof n.IdentityCMap
                        ? new u.IdentityToUnicodeMap(0, 65535)
                        : new u.ToUnicodeMap(e.getMap());
                    })
                  : e instanceof m.BaseStream
                  ? n.CMapFactory.create({
                      encoding: e,
                      fetchBuiltInCMap: this._fetchBuiltInCMapBound,
                      useCMap: null,
                    }).then(
                      function (e) {
                        if (e instanceof n.IdentityCMap)
                          return new u.IdentityToUnicodeMap(0, 65535);
                        const t = new Array(e.length);
                        e.forEach(function (e, a) {
                          if ("number" == typeof a) {
                            t[e] = String.fromCodePoint(a);
                            return;
                          }
                          const r = [];
                          for (let e = 0; e < a.length; e += 2) {
                            const t =
                              (a.charCodeAt(e) << 8) | a.charCodeAt(e + 1);
                            if (55296 != (63488 & t)) {
                              r.push(t);
                              continue;
                            }
                            e += 2;
                            const n =
                              (a.charCodeAt(e) << 8) | a.charCodeAt(e + 1);
                            r.push(((1023 & t) << 10) + (1023 & n) + 65536);
                          }
                          t[e] = String.fromCodePoint(...r);
                        });
                        return new u.ToUnicodeMap(t);
                      },
                      (e) => {
                        if (e instanceof r.AbortException) return null;
                        if (this.options.ignoreErrors) {
                          this.handler.send("UnsupportedFeature", {
                            featureId:
                              r.UNSUPPORTED_FEATURES.errorFontToUnicode,
                          });
                          (0, r.warn)(
                            `readToUnicode - ignoring ToUnicode data: "${e}".`
                          );
                          return null;
                        }
                        throw e;
                      }
                    )
                  : Promise.resolve(null)
                : Promise.resolve(null);
            }
            readCidToGidMap(e, t) {
              const a = [];
              for (let r = 0, n = e.length; r < n; r++) {
                const n = (e[r++] << 8) | e[r],
                  i = r >> 1;
                (0 !== n || t.has(i)) && (a[i] = n);
              }
              return a;
            }
            extractWidths(e, t, a) {
              const r = this.xref;
              let n = [],
                s = 0;
              const o = [];
              let c, l, h, u, d, f, g, p;
              if (a.composite) {
                s = e.has("DW") ? e.get("DW") : 1e3;
                p = e.get("W");
                if (p)
                  for (l = 0, h = p.length; l < h; l++) {
                    f = r.fetchIfRef(p[l++]);
                    g = r.fetchIfRef(p[l]);
                    if (Array.isArray(g))
                      for (u = 0, d = g.length; u < d; u++)
                        n[f++] = r.fetchIfRef(g[u]);
                    else {
                      const e = r.fetchIfRef(p[++l]);
                      for (u = f; u <= g; u++) n[u] = e;
                    }
                  }
                if (a.vertical) {
                  let t = e.getArray("DW2") || [880, -1e3];
                  c = [t[1], 0.5 * s, t[0]];
                  t = e.get("W2");
                  if (t)
                    for (l = 0, h = t.length; l < h; l++) {
                      f = r.fetchIfRef(t[l++]);
                      g = r.fetchIfRef(t[l]);
                      if (Array.isArray(g))
                        for (u = 0, d = g.length; u < d; u++)
                          o[f++] = [
                            r.fetchIfRef(g[u++]),
                            r.fetchIfRef(g[u++]),
                            r.fetchIfRef(g[u]),
                          ];
                      else {
                        const e = [
                          r.fetchIfRef(t[++l]),
                          r.fetchIfRef(t[++l]),
                          r.fetchIfRef(t[++l]),
                        ];
                        for (u = f; u <= g; u++) o[u] = e;
                      }
                    }
                }
              } else {
                const o = a.firstChar;
                p = e.get("Widths");
                if (p) {
                  u = o;
                  for (l = 0, h = p.length; l < h; l++)
                    n[u++] = r.fetchIfRef(p[l]);
                  s = parseFloat(t.get("MissingWidth")) || 0;
                } else {
                  const t = e.get("BaseFont");
                  if (t instanceof i.Name) {
                    const e = this.getBaseFontMetrics(t.name);
                    n = this.buildCharCodeToWidth(e.widths, a);
                    s = e.defaultWidth;
                  }
                }
              }
              let m = !0,
                b = s;
              for (const e in n) {
                const t = n[e];
                if (t)
                  if (b) {
                    if (b !== t) {
                      m = !1;
                      break;
                    }
                  } else b = t;
              }
              m && (a.flags |= S.FontFlags.FixedPitch);
              a.defaultWidth = s;
              a.widths = n;
              a.defaultVMetrics = c;
              a.vmetrics = o;
            }
            isSerifFont(e) {
              const t = e.split("-")[0];
              return t in (0, c.getSerifFonts)() || /serif/gi.test(t);
            }
            getBaseFontMetrics(e) {
              let t = 0,
                a = Object.create(null),
                r = !1;
              let n = (0, c.getStdFontMap)()[e] || e;
              const i = (0, k.getMetrics)();
              n in i || (n = this.isSerifFont(e) ? "Times-Roman" : "Helvetica");
              const s = i[n];
              if ("number" == typeof s) {
                t = s;
                r = !0;
              } else a = s();
              return { defaultWidth: t, monospace: r, widths: a };
            }
            buildCharCodeToWidth(e, t) {
              const a = Object.create(null),
                r = t.differences,
                n = t.defaultEncoding;
              for (let t = 0; t < 256; t++)
                t in r && e[r[t]]
                  ? (a[t] = e[r[t]])
                  : t in n && e[n[t]] && (a[t] = e[n[t]]);
              return a;
            }
            preEvaluateFont(e) {
              const t = e;
              let a = e.get("Subtype");
              if (!(a instanceof i.Name))
                throw new r.FormatError("invalid font Subtype");
              let n,
                s,
                o = !1;
              if ("Type0" === a.name) {
                const t = e.get("DescendantFonts");
                if (!t)
                  throw new r.FormatError("Descendant fonts are not specified");
                if (
                  !(
                    (e = Array.isArray(t)
                      ? this.xref.fetchIfRef(t[0])
                      : t) instanceof i.Dict
                  )
                )
                  throw new r.FormatError(
                    "Descendant font is not a dictionary."
                  );
                a = e.get("Subtype");
                if (!(a instanceof i.Name))
                  throw new r.FormatError("invalid font Subtype");
                o = !0;
              }
              const c = e.get("FirstChar") || 0,
                l = e.get("LastChar") || (o ? 65535 : 255),
                h = e.get("FontDescriptor");
              if (h) {
                n = new F.MurmurHash3_64();
                const a = t.getRaw("Encoding");
                if (a instanceof i.Name) n.update(a.name);
                else if (a instanceof i.Ref) n.update(a.toString());
                else if (a instanceof i.Dict)
                  for (const e of a.getRawValues())
                    if (e instanceof i.Name) n.update(e.name);
                    else if (e instanceof i.Ref) n.update(e.toString());
                    else if (Array.isArray(e)) {
                      const t = e.length,
                        a = new Array(t);
                      for (let r = 0; r < t; r++) {
                        const t = e[r];
                        t instanceof i.Name
                          ? (a[r] = t.name)
                          : ("number" == typeof t || t instanceof i.Ref) &&
                            (a[r] = t.toString());
                      }
                      n.update(a.join());
                    }
                n.update(`${c}-${l}`);
                s = e.get("ToUnicode") || t.get("ToUnicode");
                if (s instanceof m.BaseStream) {
                  const e = s.str || s,
                    t = e.buffer
                      ? new Uint8Array(e.buffer.buffer, 0, e.bufferLength)
                      : new Uint8Array(
                          e.bytes.buffer,
                          e.start,
                          e.end - e.start
                        );
                  n.update(t);
                } else s instanceof i.Name && n.update(s.name);
                const r = e.get("Widths") || t.get("Widths");
                if (Array.isArray(r)) {
                  const e = [];
                  for (const t of r)
                    ("number" == typeof t || t instanceof i.Ref) &&
                      e.push(t.toString());
                  n.update(e.join());
                }
                if (o) {
                  n.update("compositeFont");
                  const a = e.get("W") || t.get("W");
                  if (Array.isArray(a)) {
                    const e = [];
                    for (const t of a)
                      if ("number" == typeof t || t instanceof i.Ref)
                        e.push(t.toString());
                      else if (Array.isArray(t)) {
                        const a = [];
                        for (const e of t)
                          ("number" == typeof e || e instanceof i.Ref) &&
                            a.push(e.toString());
                        e.push(`[${a.join()}]`);
                      }
                    n.update(e.join());
                  }
                  const r = e.getRaw("CIDToGIDMap") || t.getRaw("CIDToGIDMap");
                  r instanceof i.Name
                    ? n.update(r.name)
                    : r instanceof i.Ref
                    ? n.update(r.toString())
                    : r instanceof m.BaseStream && n.update(r.peekBytes());
                }
              }
              return {
                descriptor: h,
                dict: e,
                baseDict: t,
                composite: o,
                type: a.name,
                firstChar: c,
                lastChar: l,
                toUnicode: s,
                hash: n ? n.hexdigest() : "",
              };
            }
            async translateFont({
              descriptor: e,
              dict: t,
              baseDict: a,
              composite: o,
              type: l,
              firstChar: u,
              lastChar: d,
              toUnicode: f,
              cssFontInfo: g,
            }) {
              const m = "Type3" === l;
              let b;
              if (!e) {
                if (!m) {
                  let e = t.get("BaseFont");
                  if (!(e instanceof i.Name))
                    throw new r.FormatError("Base font is not specified");
                  e = e.name.replace(/[,_]/g, "-");
                  const n = this.getBaseFontMetrics(e),
                    o = e.split("-")[0],
                    h =
                      (this.isSerifFont(o) ? S.FontFlags.Serif : 0) |
                      (n.monospace ? S.FontFlags.FixedPitch : 0) |
                      ((0, c.getSymbolsFonts)()[o]
                        ? S.FontFlags.Symbolic
                        : S.FontFlags.Nonsymbolic);
                  b = {
                    type: l,
                    name: e,
                    loadedName: a.loadedName,
                    widths: n.widths,
                    defaultWidth: n.defaultWidth,
                    isSimulatedFlags: !0,
                    flags: h,
                    firstChar: u,
                    lastChar: d,
                    toUnicode: f,
                    xHeight: 0,
                    capHeight: 0,
                    italicAngle: 0,
                    isType3Font: m,
                  };
                  const g = t.get("Widths"),
                    p = (0, c.getStandardFontName)(e);
                  let y = null;
                  if (p) {
                    b.isStandardFont = !0;
                    y = await this.fetchStandardFontData(p);
                    b.isInternalFont = !!y;
                  }
                  return this.extractDataStructures(t, t, b).then((t) => {
                    if (g) {
                      const e = [];
                      let a = u;
                      for (const t of g) e[a++] = this.xref.fetchIfRef(t);
                      t.widths = e;
                    } else t.widths = this.buildCharCodeToWidth(n.widths, t);
                    return new s.Font(e, y, t);
                  });
                }
                (e = new i.Dict(null)).set("FontName", i.Name.get(l));
                e.set("FontBBox", t.getArray("FontBBox") || [0, 0, 0, 0]);
              }
              let y,
                w,
                x,
                C,
                k,
                v = e.get("FontName"),
                F = t.get("BaseFont");
              "string" == typeof v && (v = i.Name.get(v));
              "string" == typeof F && (F = i.Name.get(F));
              if (!m) {
                const e = v && v.name,
                  t = F && F.name;
                if (e !== t) {
                  (0, r.info)(
                    `The FontDescriptor's FontName is "${e}" but should be the same as the Font's BaseFont "${t}".`
                  );
                  e && t && t.startsWith(e) && (v = F);
                }
              }
              v = v || F;
              if (!(v instanceof i.Name))
                throw new r.FormatError("invalid font name");
              try {
                y = e.get("FontFile", "FontFile2", "FontFile3");
              } catch (e) {
                if (!this.options.ignoreErrors) throw e;
                (0, r.warn)(
                  `translateFont - fetching "${v.name}" font file: "${e}".`
                );
                y = new p.NullStream();
              }
              let O = !1,
                T = !1,
                M = null;
              if (y) {
                if (y.dict) {
                  const e = y.dict.get("Subtype");
                  e instanceof i.Name && (w = e.name);
                  x = y.dict.get("Length1");
                  C = y.dict.get("Length2");
                  k = y.dict.get("Length3");
                }
              } else if (g) {
                const e = (0, h.getXfaFontName)(v.name);
                if (e) {
                  g.fontFamily = `${g.fontFamily}-PdfJS-XFA`;
                  g.metrics = e.metrics || null;
                  M = e.factors || null;
                  y = await this.fetchStandardFontData(e.name);
                  T = !!y;
                  a = t = (0, h.getXfaFontDict)(v.name);
                  o = !0;
                }
              } else if (!m) {
                const e = (0, c.getStandardFontName)(v.name);
                if (e) {
                  O = !0;
                  y = await this.fetchStandardFontData(e);
                  T = !!y;
                }
              }
              b = {
                type: l,
                name: v.name,
                subtype: w,
                file: y,
                length1: x,
                length2: C,
                length3: k,
                isStandardFont: O,
                isInternalFont: T,
                loadedName: a.loadedName,
                composite: o,
                fixedPitch: !1,
                fontMatrix: t.getArray("FontMatrix") || r.FONT_IDENTITY_MATRIX,
                firstChar: u,
                lastChar: d,
                toUnicode: f,
                bbox: e.getArray("FontBBox") || t.getArray("FontBBox"),
                ascent: e.get("Ascent"),
                descent: e.get("Descent"),
                xHeight: e.get("XHeight") || 0,
                capHeight: e.get("CapHeight") || 0,
                flags: e.get("Flags"),
                italicAngle: e.get("ItalicAngle") || 0,
                isType3Font: m,
                cssFontInfo: g,
                scaleFactors: M,
              };
              if (o) {
                const e = a.get("Encoding");
                e instanceof i.Name && (b.cidEncoding = e.name);
                const t = await n.CMapFactory.create({
                  encoding: e,
                  fetchBuiltInCMap: this._fetchBuiltInCMapBound,
                  useCMap: null,
                });
                b.cMap = t;
                b.vertical = b.cMap.vertical;
              }
              return this.extractDataStructures(t, a, b).then((a) => {
                this.extractWidths(t, e, a);
                return new s.Font(v.name, y, a);
              });
            }
            static buildFontPaths(e, t, a, n) {
              function buildPath(t) {
                const i = `${e.loadedName}_path_${t}`;
                try {
                  if (e.renderer.hasBuiltPath(t)) return;
                  a.send("commonobj", [i, "FontPath", e.renderer.getPathJs(t)]);
                } catch (e) {
                  if (n.ignoreErrors) {
                    a.send("UnsupportedFeature", {
                      featureId: r.UNSUPPORTED_FEATURES.errorFontBuildPath,
                    });
                    (0, r.warn)(
                      `buildFontPaths - ignoring ${i} glyph: "${e}".`
                    );
                    return;
                  }
                  throw e;
                }
              }
              for (const e of t) {
                buildPath(e.fontChar);
                const t = e.accent;
                t && t.fontChar && buildPath(t.fontChar);
              }
            }
            static get fallbackFontDict() {
              const e = new i.Dict();
              e.set("BaseFont", i.Name.get("PDFJS-FallbackFont"));
              e.set("Type", i.Name.get("FallbackType"));
              e.set("Subtype", i.Name.get("FallbackType"));
              e.set("Encoding", i.Name.get("WinAnsiEncoding"));
              return (0, r.shadow)(this, "fallbackFontDict", e);
            }
          }
          t.PartialEvaluator = PartialEvaluator;
          class TranslatedFont {
            constructor({
              loadedName: e,
              font: t,
              dict: a,
              evaluatorOptions: r,
            }) {
              this.loadedName = e;
              this.font = t;
              this.dict = a;
              this._evaluatorOptions = r || M;
              this.type3Loaded = null;
              this.type3Dependencies = t.isType3Font ? new Set() : null;
              this.sent = !1;
            }
            send(e) {
              if (!this.sent) {
                this.sent = !0;
                e.send("commonobj", [
                  this.loadedName,
                  "Font",
                  this.font.exportData(
                    this._evaluatorOptions.fontExtraProperties
                  ),
                ]);
              }
            }
            fallback(e) {
              if (this.font.data) {
                this.font.disableFontFace = !0;
                PartialEvaluator.buildFontPaths(
                  this.font,
                  this.font.glyphCacheValues,
                  e,
                  this._evaluatorOptions
                );
              }
            }
            loadType3Data(e, t, a) {
              if (this.type3Loaded) return this.type3Loaded;
              if (!this.font.isType3Font)
                throw new Error("Must be a Type3 font.");
              const n = e.clone({ ignoreErrors: !1 });
              n.parsingType3Font = !0;
              const s = new i.RefSet(e.type3FontRefs);
              this.dict.objId &&
                !s.has(this.dict.objId) &&
                s.put(this.dict.objId);
              n.type3FontRefs = s;
              const o = this.font,
                c = this.type3Dependencies;
              let l = Promise.resolve();
              const h = this.dict.get("CharProcs"),
                u = this.dict.get("Resources") || t,
                d = Object.create(null),
                f = r.Util.normalizeRect(o.bbox || [0, 0, 0, 0]),
                g = f[2] - f[0],
                p = f[3] - f[1],
                m = Math.hypot(g, p);
              for (const e of h.getKeys())
                l = l.then(() => {
                  const t = h.get(e),
                    i = new O.OperatorList();
                  return n
                    .getOperatorList({
                      stream: t,
                      task: a,
                      resources: u,
                      operatorList: i,
                    })
                    .then(() => {
                      i.fnArray[0] === r.OPS.setCharWidthAndBounds &&
                        this._removeType3ColorOperators(i, m);
                      d[e] = i.getIR();
                      for (const e of i.dependencies) c.add(e);
                    })
                    .catch(function (t) {
                      (0,
                      r.warn)(`Type3 font resource "${e}" is not available.`);
                      const a = new O.OperatorList();
                      d[e] = a.getIR();
                    });
                });
              this.type3Loaded = l.then(() => {
                o.charProcOperatorList = d;
                if (this._bbox) {
                  o.isCharBBox = !0;
                  o.bbox = this._bbox;
                }
              });
              return this.type3Loaded;
            }
            _removeType3ColorOperators(e, t = NaN) {
              const a = r.Util.normalizeRect(e.argsArray[0].slice(2)),
                n = a[2] - a[0],
                i = a[3] - a[1],
                s = Math.hypot(n, i);
              if (0 === n || 0 === i) {
                e.fnArray.splice(0, 1);
                e.argsArray.splice(0, 1);
              } else if (0 === t || Math.round(s / t) >= 10) {
                this._bbox || (this._bbox = [1 / 0, 1 / 0, -1 / 0, -1 / 0]);
                this._bbox[0] = Math.min(this._bbox[0], a[0]);
                this._bbox[1] = Math.min(this._bbox[1], a[1]);
                this._bbox[2] = Math.max(this._bbox[2], a[2]);
                this._bbox[3] = Math.max(this._bbox[3], a[3]);
              }
              let o = 0,
                c = e.length;
              for (; o < c; ) {
                switch (e.fnArray[o]) {
                  case r.OPS.setCharWidthAndBounds:
                    break;
                  case r.OPS.setStrokeColorSpace:
                  case r.OPS.setFillColorSpace:
                  case r.OPS.setStrokeColor:
                  case r.OPS.setStrokeColorN:
                  case r.OPS.setFillColor:
                  case r.OPS.setFillColorN:
                  case r.OPS.setStrokeGray:
                  case r.OPS.setFillGray:
                  case r.OPS.setStrokeRGBColor:
                  case r.OPS.setFillRGBColor:
                  case r.OPS.setStrokeCMYKColor:
                  case r.OPS.setFillCMYKColor:
                  case r.OPS.shadingFill:
                  case r.OPS.setRenderingIntent:
                    e.fnArray.splice(o, 1);
                    e.argsArray.splice(o, 1);
                    c--;
                    continue;
                  case r.OPS.setGState:
                    const [t] = e.argsArray[o];
                    let a = 0,
                      n = t.length;
                    for (; a < n; ) {
                      const [e] = t[a];
                      switch (e) {
                        case "TR":
                        case "TR2":
                        case "HT":
                        case "BG":
                        case "BG2":
                        case "UCR":
                        case "UCR2":
                          t.splice(a, 1);
                          n--;
                          continue;
                      }
                      a++;
                    }
                }
                o++;
              }
            }
          }
          class StateManager {
            constructor(e = new EvalState()) {
              this.state = e;
              this.stateStack = [];
            }
            save() {
              const e = this.state;
              this.stateStack.push(this.state);
              this.state = e.clone();
            }
            restore() {
              const e = this.stateStack.pop();
              e && (this.state = e);
            }
            transform(e) {
              this.state.ctm = r.Util.transform(this.state.ctm, e);
            }
          }
          class TextState {
            constructor() {
              this.ctm = new Float32Array(r.IDENTITY_MATRIX);
              this.fontName = null;
              this.fontSize = 0;
              this.loadedName = null;
              this.font = null;
              this.fontMatrix = r.FONT_IDENTITY_MATRIX;
              this.textMatrix = r.IDENTITY_MATRIX.slice();
              this.textLineMatrix = r.IDENTITY_MATRIX.slice();
              this.charSpacing = 0;
              this.wordSpacing = 0;
              this.leading = 0;
              this.textHScale = 1;
              this.textRise = 0;
            }
            setTextMatrix(e, t, a, r, n, i) {
              const s = this.textMatrix;
              s[0] = e;
              s[1] = t;
              s[2] = a;
              s[3] = r;
              s[4] = n;
              s[5] = i;
            }
            setTextLineMatrix(e, t, a, r, n, i) {
              const s = this.textLineMatrix;
              s[0] = e;
              s[1] = t;
              s[2] = a;
              s[3] = r;
              s[4] = n;
              s[5] = i;
            }
            translateTextMatrix(e, t) {
              const a = this.textMatrix;
              a[4] = a[0] * e + a[2] * t + a[4];
              a[5] = a[1] * e + a[3] * t + a[5];
            }
            translateTextLineMatrix(e, t) {
              const a = this.textLineMatrix;
              a[4] = a[0] * e + a[2] * t + a[4];
              a[5] = a[1] * e + a[3] * t + a[5];
            }
            carriageReturn() {
              this.translateTextLineMatrix(0, -this.leading);
              this.textMatrix = this.textLineMatrix.slice();
            }
            clone() {
              const e = Object.create(this);
              e.textMatrix = this.textMatrix.slice();
              e.textLineMatrix = this.textLineMatrix.slice();
              e.fontMatrix = this.fontMatrix.slice();
              return e;
            }
          }
          class EvalState {
            constructor() {
              this.ctm = new Float32Array(r.IDENTITY_MATRIX);
              this.font = null;
              this.textRenderingMode = r.TextRenderingMode.FILL;
              this.fillColorSpace = y.ColorSpace.singletons.gray;
              this.strokeColorSpace = y.ColorSpace.singletons.gray;
            }
            clone() {
              return Object.create(this);
            }
          }
          class EvaluatorPreprocessor {
            static get opMap() {
              const e = (0, C.getLookupTableFactory)(function (e) {
                e.w = { id: r.OPS.setLineWidth, numArgs: 1, variableArgs: !1 };
                e.J = { id: r.OPS.setLineCap, numArgs: 1, variableArgs: !1 };
                e.j = { id: r.OPS.setLineJoin, numArgs: 1, variableArgs: !1 };
                e.M = { id: r.OPS.setMiterLimit, numArgs: 1, variableArgs: !1 };
                e.d = { id: r.OPS.setDash, numArgs: 2, variableArgs: !1 };
                e.ri = {
                  id: r.OPS.setRenderingIntent,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.i = { id: r.OPS.setFlatness, numArgs: 1, variableArgs: !1 };
                e.gs = { id: r.OPS.setGState, numArgs: 1, variableArgs: !1 };
                e.q = { id: r.OPS.save, numArgs: 0, variableArgs: !1 };
                e.Q = { id: r.OPS.restore, numArgs: 0, variableArgs: !1 };
                e.cm = { id: r.OPS.transform, numArgs: 6, variableArgs: !1 };
                e.m = { id: r.OPS.moveTo, numArgs: 2, variableArgs: !1 };
                e.l = { id: r.OPS.lineTo, numArgs: 2, variableArgs: !1 };
                e.c = { id: r.OPS.curveTo, numArgs: 6, variableArgs: !1 };
                e.v = { id: r.OPS.curveTo2, numArgs: 4, variableArgs: !1 };
                e.y = { id: r.OPS.curveTo3, numArgs: 4, variableArgs: !1 };
                e.h = { id: r.OPS.closePath, numArgs: 0, variableArgs: !1 };
                e.re = { id: r.OPS.rectangle, numArgs: 4, variableArgs: !1 };
                e.S = { id: r.OPS.stroke, numArgs: 0, variableArgs: !1 };
                e.s = { id: r.OPS.closeStroke, numArgs: 0, variableArgs: !1 };
                e.f = { id: r.OPS.fill, numArgs: 0, variableArgs: !1 };
                e.F = { id: r.OPS.fill, numArgs: 0, variableArgs: !1 };
                e["f*"] = { id: r.OPS.eoFill, numArgs: 0, variableArgs: !1 };
                e.B = { id: r.OPS.fillStroke, numArgs: 0, variableArgs: !1 };
                e["B*"] = {
                  id: r.OPS.eoFillStroke,
                  numArgs: 0,
                  variableArgs: !1,
                };
                e.b = {
                  id: r.OPS.closeFillStroke,
                  numArgs: 0,
                  variableArgs: !1,
                };
                e["b*"] = {
                  id: r.OPS.closeEOFillStroke,
                  numArgs: 0,
                  variableArgs: !1,
                };
                e.n = { id: r.OPS.endPath, numArgs: 0, variableArgs: !1 };
                e.W = { id: r.OPS.clip, numArgs: 0, variableArgs: !1 };
                e["W*"] = { id: r.OPS.eoClip, numArgs: 0, variableArgs: !1 };
                e.BT = { id: r.OPS.beginText, numArgs: 0, variableArgs: !1 };
                e.ET = { id: r.OPS.endText, numArgs: 0, variableArgs: !1 };
                e.Tc = {
                  id: r.OPS.setCharSpacing,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.Tw = {
                  id: r.OPS.setWordSpacing,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.Tz = { id: r.OPS.setHScale, numArgs: 1, variableArgs: !1 };
                e.TL = { id: r.OPS.setLeading, numArgs: 1, variableArgs: !1 };
                e.Tf = { id: r.OPS.setFont, numArgs: 2, variableArgs: !1 };
                e.Tr = {
                  id: r.OPS.setTextRenderingMode,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.Ts = { id: r.OPS.setTextRise, numArgs: 1, variableArgs: !1 };
                e.Td = { id: r.OPS.moveText, numArgs: 2, variableArgs: !1 };
                e.TD = {
                  id: r.OPS.setLeadingMoveText,
                  numArgs: 2,
                  variableArgs: !1,
                };
                e.Tm = {
                  id: r.OPS.setTextMatrix,
                  numArgs: 6,
                  variableArgs: !1,
                };
                e["T*"] = { id: r.OPS.nextLine, numArgs: 0, variableArgs: !1 };
                e.Tj = { id: r.OPS.showText, numArgs: 1, variableArgs: !1 };
                e.TJ = {
                  id: r.OPS.showSpacedText,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e["'"] = {
                  id: r.OPS.nextLineShowText,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e['"'] = {
                  id: r.OPS.nextLineSetSpacingShowText,
                  numArgs: 3,
                  variableArgs: !1,
                };
                e.d0 = { id: r.OPS.setCharWidth, numArgs: 2, variableArgs: !1 };
                e.d1 = {
                  id: r.OPS.setCharWidthAndBounds,
                  numArgs: 6,
                  variableArgs: !1,
                };
                e.CS = {
                  id: r.OPS.setStrokeColorSpace,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.cs = {
                  id: r.OPS.setFillColorSpace,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.SC = {
                  id: r.OPS.setStrokeColor,
                  numArgs: 4,
                  variableArgs: !0,
                };
                e.SCN = {
                  id: r.OPS.setStrokeColorN,
                  numArgs: 33,
                  variableArgs: !0,
                };
                e.sc = { id: r.OPS.setFillColor, numArgs: 4, variableArgs: !0 };
                e.scn = {
                  id: r.OPS.setFillColorN,
                  numArgs: 33,
                  variableArgs: !0,
                };
                e.G = { id: r.OPS.setStrokeGray, numArgs: 1, variableArgs: !1 };
                e.g = { id: r.OPS.setFillGray, numArgs: 1, variableArgs: !1 };
                e.RG = {
                  id: r.OPS.setStrokeRGBColor,
                  numArgs: 3,
                  variableArgs: !1,
                };
                e.rg = {
                  id: r.OPS.setFillRGBColor,
                  numArgs: 3,
                  variableArgs: !1,
                };
                e.K = {
                  id: r.OPS.setStrokeCMYKColor,
                  numArgs: 4,
                  variableArgs: !1,
                };
                e.k = {
                  id: r.OPS.setFillCMYKColor,
                  numArgs: 4,
                  variableArgs: !1,
                };
                e.sh = { id: r.OPS.shadingFill, numArgs: 1, variableArgs: !1 };
                e.BI = {
                  id: r.OPS.beginInlineImage,
                  numArgs: 0,
                  variableArgs: !1,
                };
                e.ID = {
                  id: r.OPS.beginImageData,
                  numArgs: 0,
                  variableArgs: !1,
                };
                e.EI = {
                  id: r.OPS.endInlineImage,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.Do = { id: r.OPS.paintXObject, numArgs: 1, variableArgs: !1 };
                e.MP = { id: r.OPS.markPoint, numArgs: 1, variableArgs: !1 };
                e.DP = {
                  id: r.OPS.markPointProps,
                  numArgs: 2,
                  variableArgs: !1,
                };
                e.BMC = {
                  id: r.OPS.beginMarkedContent,
                  numArgs: 1,
                  variableArgs: !1,
                };
                e.BDC = {
                  id: r.OPS.beginMarkedContentProps,
                  numArgs: 2,
                  variableArgs: !1,
                };
                e.EMC = {
                  id: r.OPS.endMarkedContent,
                  numArgs: 0,
                  variableArgs: !1,
                };
                e.BX = { id: r.OPS.beginCompat, numArgs: 0, variableArgs: !1 };
                e.EX = { id: r.OPS.endCompat, numArgs: 0, variableArgs: !1 };
                e.BM = null;
                e.BD = null;
                e.true = null;
                e.fa = null;
                e.fal = null;
                e.fals = null;
                e.false = null;
                e.nu = null;
                e.nul = null;
                e.null = null;
              });
              return (0, r.shadow)(this, "opMap", e());
            }
            static get MAX_INVALID_PATH_OPS() {
              return (0, r.shadow)(this, "MAX_INVALID_PATH_OPS", 10);
            }
            constructor(e, t, a = new StateManager()) {
              this.parser = new f.Parser({
                lexer: new f.Lexer(e, EvaluatorPreprocessor.opMap),
                xref: t,
              });
              this.stateManager = a;
              this.nonProcessedArgs = [];
              this._isPathOp = !1;
              this._numInvalidPathOPS = 0;
            }
            get savedStatesDepth() {
              return this.stateManager.stateStack.length;
            }
            read(e) {
              let t = e.args;
              for (;;) {
                const a = this.parser.getObj();
                if (a instanceof i.Cmd) {
                  const n = a.cmd,
                    i = EvaluatorPreprocessor.opMap[n];
                  if (!i) {
                    (0, r.warn)(`Unknown command "${n}".`);
                    continue;
                  }
                  const s = i.id,
                    o = i.numArgs;
                  let c = null !== t ? t.length : 0;
                  this._isPathOp || (this._numInvalidPathOPS = 0);
                  this._isPathOp = s >= r.OPS.moveTo && s <= r.OPS.endPath;
                  if (i.variableArgs)
                    c > o &&
                      (0, r.info)(
                        `Command ${n}: expected [0, ${o}] args, but received ${c} args.`
                      );
                  else {
                    if (c !== o) {
                      const e = this.nonProcessedArgs;
                      for (; c > o; ) {
                        e.push(t.shift());
                        c--;
                      }
                      for (; c < o && 0 !== e.length; ) {
                        null === t && (t = []);
                        t.unshift(e.pop());
                        c++;
                      }
                    }
                    if (c < o) {
                      const e = `command ${n}: expected ${o} args, but received ${c} args.`;
                      if (
                        this._isPathOp &&
                        ++this._numInvalidPathOPS >
                          EvaluatorPreprocessor.MAX_INVALID_PATH_OPS
                      )
                        throw new r.FormatError(`Invalid ${e}`);
                      (0, r.warn)(`Skipping ${e}`);
                      null !== t && (t.length = 0);
                      continue;
                    }
                  }
                  this.preprocessCommand(s, t);
                  e.fn = s;
                  e.args = t;
                  return !0;
                }
                if (a === i.EOF) return !1;
                if (null !== a) {
                  null === t && (t = []);
                  t.push(a);
                  if (t.length > 33)
                    throw new r.FormatError("Too many arguments");
                }
              }
            }
            preprocessCommand(e, t) {
              switch (0 | e) {
                case r.OPS.save:
                  this.stateManager.save();
                  break;
                case r.OPS.restore:
                  this.stateManager.restore();
                  break;
                case r.OPS.transform:
                  this.stateManager.transform(t);
              }
            }
          }
          t.EvaluatorPreprocessor = EvaluatorPreprocessor;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.IdentityCMap = t.CMapFactory = t.CMap = void 0;
          var r = a(2),
            n = a(4),
            i = a(5),
            s = a(15),
            o = a(3),
            c = a(8);
          const l = [
              "Adobe-GB1-UCS2",
              "Adobe-CNS1-UCS2",
              "Adobe-Japan1-UCS2",
              "Adobe-Korea1-UCS2",
              "78-EUC-H",
              "78-EUC-V",
              "78-H",
              "78-RKSJ-H",
              "78-RKSJ-V",
              "78-V",
              "78ms-RKSJ-H",
              "78ms-RKSJ-V",
              "83pv-RKSJ-H",
              "90ms-RKSJ-H",
              "90ms-RKSJ-V",
              "90msp-RKSJ-H",
              "90msp-RKSJ-V",
              "90pv-RKSJ-H",
              "90pv-RKSJ-V",
              "Add-H",
              "Add-RKSJ-H",
              "Add-RKSJ-V",
              "Add-V",
              "Adobe-CNS1-0",
              "Adobe-CNS1-1",
              "Adobe-CNS1-2",
              "Adobe-CNS1-3",
              "Adobe-CNS1-4",
              "Adobe-CNS1-5",
              "Adobe-CNS1-6",
              "Adobe-GB1-0",
              "Adobe-GB1-1",
              "Adobe-GB1-2",
              "Adobe-GB1-3",
              "Adobe-GB1-4",
              "Adobe-GB1-5",
              "Adobe-Japan1-0",
              "Adobe-Japan1-1",
              "Adobe-Japan1-2",
              "Adobe-Japan1-3",
              "Adobe-Japan1-4",
              "Adobe-Japan1-5",
              "Adobe-Japan1-6",
              "Adobe-Korea1-0",
              "Adobe-Korea1-1",
              "Adobe-Korea1-2",
              "B5-H",
              "B5-V",
              "B5pc-H",
              "B5pc-V",
              "CNS-EUC-H",
              "CNS-EUC-V",
              "CNS1-H",
              "CNS1-V",
              "CNS2-H",
              "CNS2-V",
              "ETHK-B5-H",
              "ETHK-B5-V",
              "ETen-B5-H",
              "ETen-B5-V",
              "ETenms-B5-H",
              "ETenms-B5-V",
              "EUC-H",
              "EUC-V",
              "Ext-H",
              "Ext-RKSJ-H",
              "Ext-RKSJ-V",
              "Ext-V",
              "GB-EUC-H",
              "GB-EUC-V",
              "GB-H",
              "GB-V",
              "GBK-EUC-H",
              "GBK-EUC-V",
              "GBK2K-H",
              "GBK2K-V",
              "GBKp-EUC-H",
              "GBKp-EUC-V",
              "GBT-EUC-H",
              "GBT-EUC-V",
              "GBT-H",
              "GBT-V",
              "GBTpc-EUC-H",
              "GBTpc-EUC-V",
              "GBpc-EUC-H",
              "GBpc-EUC-V",
              "H",
              "HKdla-B5-H",
              "HKdla-B5-V",
              "HKdlb-B5-H",
              "HKdlb-B5-V",
              "HKgccs-B5-H",
              "HKgccs-B5-V",
              "HKm314-B5-H",
              "HKm314-B5-V",
              "HKm471-B5-H",
              "HKm471-B5-V",
              "HKscs-B5-H",
              "HKscs-B5-V",
              "Hankaku",
              "Hiragana",
              "KSC-EUC-H",
              "KSC-EUC-V",
              "KSC-H",
              "KSC-Johab-H",
              "KSC-Johab-V",
              "KSC-V",
              "KSCms-UHC-H",
              "KSCms-UHC-HW-H",
              "KSCms-UHC-HW-V",
              "KSCms-UHC-V",
              "KSCpc-EUC-H",
              "KSCpc-EUC-V",
              "Katakana",
              "NWP-H",
              "NWP-V",
              "RKSJ-H",
              "RKSJ-V",
              "Roman",
              "UniCNS-UCS2-H",
              "UniCNS-UCS2-V",
              "UniCNS-UTF16-H",
              "UniCNS-UTF16-V",
              "UniCNS-UTF32-H",
              "UniCNS-UTF32-V",
              "UniCNS-UTF8-H",
              "UniCNS-UTF8-V",
              "UniGB-UCS2-H",
              "UniGB-UCS2-V",
              "UniGB-UTF16-H",
              "UniGB-UTF16-V",
              "UniGB-UTF32-H",
              "UniGB-UTF32-V",
              "UniGB-UTF8-H",
              "UniGB-UTF8-V",
              "UniJIS-UCS2-H",
              "UniJIS-UCS2-HW-H",
              "UniJIS-UCS2-HW-V",
              "UniJIS-UCS2-V",
              "UniJIS-UTF16-H",
              "UniJIS-UTF16-V",
              "UniJIS-UTF32-H",
              "UniJIS-UTF32-V",
              "UniJIS-UTF8-H",
              "UniJIS-UTF8-V",
              "UniJIS2004-UTF16-H",
              "UniJIS2004-UTF16-V",
              "UniJIS2004-UTF32-H",
              "UniJIS2004-UTF32-V",
              "UniJIS2004-UTF8-H",
              "UniJIS2004-UTF8-V",
              "UniJISPro-UCS2-HW-V",
              "UniJISPro-UCS2-V",
              "UniJISPro-UTF8-V",
              "UniJISX0213-UTF32-H",
              "UniJISX0213-UTF32-V",
              "UniJISX02132004-UTF32-H",
              "UniJISX02132004-UTF32-V",
              "UniKS-UCS2-H",
              "UniKS-UCS2-V",
              "UniKS-UTF16-H",
              "UniKS-UTF16-V",
              "UniKS-UTF32-H",
              "UniKS-UTF32-V",
              "UniKS-UTF8-H",
              "UniKS-UTF8-V",
              "V",
              "WP-Symbol",
            ],
            h = 2 ** 24 - 1;
          class CMap {
            constructor(e = !1) {
              this.codespaceRanges = [[], [], [], []];
              this.numCodespaceRanges = 0;
              this._map = [];
              this.name = "";
              this.vertical = !1;
              this.useCMap = null;
              this.builtInCMap = e;
            }
            addCodespaceRange(e, t, a) {
              this.codespaceRanges[e - 1].push(t, a);
              this.numCodespaceRanges++;
            }
            mapCidRange(e, t, a) {
              if (t - e > h)
                throw new Error(
                  "mapCidRange - ignoring data above MAX_MAP_RANGE."
                );
              for (; e <= t; ) this._map[e++] = a++;
            }
            mapBfRange(e, t, a) {
              if (t - e > h)
                throw new Error(
                  "mapBfRange - ignoring data above MAX_MAP_RANGE."
                );
              const r = a.length - 1;
              for (; e <= t; ) {
                this._map[e++] = a;
                const t = a.charCodeAt(r) + 1;
                t > 255
                  ? (a =
                      a.substring(0, r - 1) +
                      String.fromCharCode(a.charCodeAt(r - 1) + 1) +
                      "\0")
                  : (a = a.substring(0, r) + String.fromCharCode(t));
              }
            }
            mapBfRangeToArray(e, t, a) {
              if (t - e > h)
                throw new Error(
                  "mapBfRangeToArray - ignoring data above MAX_MAP_RANGE."
                );
              const r = a.length;
              let n = 0;
              for (; e <= t && n < r; ) {
                this._map[e] = a[n++];
                ++e;
              }
            }
            mapOne(e, t) {
              this._map[e] = t;
            }
            lookup(e) {
              return this._map[e];
            }
            contains(e) {
              return void 0 !== this._map[e];
            }
            forEach(e) {
              const t = this._map,
                a = t.length;
              if (a <= 65536)
                for (let r = 0; r < a; r++) void 0 !== t[r] && e(r, t[r]);
              else for (const a in t) e(a, t[a]);
            }
            charCodeOf(e) {
              const t = this._map;
              if (t.length <= 65536) return t.indexOf(e);
              for (const a in t) if (t[a] === e) return 0 | a;
              return -1;
            }
            getMap() {
              return this._map;
            }
            readCharCode(e, t, a) {
              let r = 0;
              const n = this.codespaceRanges;
              for (let i = 0, s = n.length; i < s; i++) {
                r = ((r << 8) | e.charCodeAt(t + i)) >>> 0;
                const s = n[i];
                for (let e = 0, t = s.length; e < t; ) {
                  const t = s[e++],
                    n = s[e++];
                  if (r >= t && r <= n) {
                    a.charcode = r;
                    a.length = i + 1;
                    return;
                  }
                }
              }
              a.charcode = 0;
              a.length = 1;
            }
            getCharCodeLength(e) {
              const t = this.codespaceRanges;
              for (let a = 0, r = t.length; a < r; a++) {
                const r = t[a];
                for (let t = 0, n = r.length; t < n; ) {
                  const n = r[t++],
                    i = r[t++];
                  if (e >= n && e <= i) return a + 1;
                }
              }
              return 1;
            }
            get length() {
              return this._map.length;
            }
            get isIdentityCMap() {
              if ("Identity-H" !== this.name && "Identity-V" !== this.name)
                return !1;
              if (65536 !== this._map.length) return !1;
              for (let e = 0; e < 65536; e++) if (this._map[e] !== e) return !1;
              return !0;
            }
          }
          t.CMap = CMap;
          class IdentityCMap extends CMap {
            constructor(e, t) {
              super();
              this.vertical = e;
              this.addCodespaceRange(t, 0, 65535);
            }
            mapCidRange(e, t, a) {
              (0, r.unreachable)("should not call mapCidRange");
            }
            mapBfRange(e, t, a) {
              (0, r.unreachable)("should not call mapBfRange");
            }
            mapBfRangeToArray(e, t, a) {
              (0, r.unreachable)("should not call mapBfRangeToArray");
            }
            mapOne(e, t) {
              (0, r.unreachable)("should not call mapCidOne");
            }
            lookup(e) {
              return Number.isInteger(e) && e <= 65535 ? e : void 0;
            }
            contains(e) {
              return Number.isInteger(e) && e <= 65535;
            }
            forEach(e) {
              for (let t = 0; t <= 65535; t++) e(t, t);
            }
            charCodeOf(e) {
              return Number.isInteger(e) && e <= 65535 ? e : -1;
            }
            getMap() {
              const e = new Array(65536);
              for (let t = 0; t <= 65535; t++) e[t] = t;
              return e;
            }
            get length() {
              return 65536;
            }
            get isIdentityCMap() {
              (0, r.unreachable)("should not access .isIdentityCMap");
            }
          }
          t.IdentityCMap = IdentityCMap;
          const u = (function BinaryCMapReaderClosure() {
              function hexToInt(e, t) {
                let a = 0;
                for (let r = 0; r <= t; r++) a = (a << 8) | e[r];
                return a >>> 0;
              }
              function hexToStr(e, t) {
                return 1 === t
                  ? String.fromCharCode(e[0], e[1])
                  : 3 === t
                  ? String.fromCharCode(e[0], e[1], e[2], e[3])
                  : String.fromCharCode.apply(null, e.subarray(0, t + 1));
              }
              function addHex(e, t, a) {
                let r = 0;
                for (let n = a; n >= 0; n--) {
                  r += e[n] + t[n];
                  e[n] = 255 & r;
                  r >>= 8;
                }
              }
              function incHex(e, t) {
                let a = 1;
                for (let r = t; r >= 0 && a > 0; r--) {
                  a += e[r];
                  e[r] = 255 & a;
                  a >>= 8;
                }
              }
              const e = 16;
              class BinaryCMapStream {
                constructor(e) {
                  this.buffer = e;
                  this.pos = 0;
                  this.end = e.length;
                  this.tmpBuf = new Uint8Array(19);
                }
                readByte() {
                  return this.pos >= this.end ? -1 : this.buffer[this.pos++];
                }
                readNumber() {
                  let e,
                    t = 0;
                  do {
                    const a = this.readByte();
                    if (a < 0)
                      throw new r.FormatError("unexpected EOF in bcmap");
                    e = !(128 & a);
                    t = (t << 7) | (127 & a);
                  } while (!e);
                  return t;
                }
                readSigned() {
                  const e = this.readNumber();
                  return 1 & e ? ~(e >>> 1) : e >>> 1;
                }
                readHex(e, t) {
                  e.set(this.buffer.subarray(this.pos, this.pos + t + 1));
                  this.pos += t + 1;
                }
                readHexNumber(e, t) {
                  let a;
                  const n = this.tmpBuf;
                  let i = 0;
                  do {
                    const e = this.readByte();
                    if (e < 0)
                      throw new r.FormatError("unexpected EOF in bcmap");
                    a = !(128 & e);
                    n[i++] = 127 & e;
                  } while (!a);
                  let s = t,
                    o = 0,
                    c = 0;
                  for (; s >= 0; ) {
                    for (; c < 8 && n.length > 0; ) {
                      o |= n[--i] << c;
                      c += 7;
                    }
                    e[s] = 255 & o;
                    s--;
                    o >>= 8;
                    c -= 8;
                  }
                }
                readHexSigned(e, t) {
                  this.readHexNumber(e, t);
                  const a = 1 & e[t] ? 255 : 0;
                  let r = 0;
                  for (let n = 0; n <= t; n++) {
                    r = ((1 & r) << 8) | e[n];
                    e[n] = (r >> 1) ^ a;
                  }
                }
                readString() {
                  const e = this.readNumber();
                  let t = "";
                  for (let a = 0; a < e; a++)
                    t += String.fromCharCode(this.readNumber());
                  return t;
                }
              }
              return class BinaryCMapReader {
                async process(t, a, r) {
                  const n = new BinaryCMapStream(t),
                    i = n.readByte();
                  a.vertical = !!(1 & i);
                  let s = null;
                  const o = new Uint8Array(e),
                    c = new Uint8Array(e),
                    l = new Uint8Array(e),
                    h = new Uint8Array(e),
                    u = new Uint8Array(e);
                  let d, f;
                  for (; (f = n.readByte()) >= 0; ) {
                    const t = f >> 5;
                    if (7 === t) {
                      switch (31 & f) {
                        case 0:
                          n.readString();
                          break;
                        case 1:
                          s = n.readString();
                      }
                      continue;
                    }
                    const r = !!(16 & f),
                      i = 15 & f;
                    if (i + 1 > e)
                      throw new Error(
                        "BinaryCMapReader.process: Invalid dataSize."
                      );
                    const g = 1,
                      p = n.readNumber();
                    switch (t) {
                      case 0:
                        n.readHex(o, i);
                        n.readHexNumber(c, i);
                        addHex(c, o, i);
                        a.addCodespaceRange(
                          i + 1,
                          hexToInt(o, i),
                          hexToInt(c, i)
                        );
                        for (let e = 1; e < p; e++) {
                          incHex(c, i);
                          n.readHexNumber(o, i);
                          addHex(o, c, i);
                          n.readHexNumber(c, i);
                          addHex(c, o, i);
                          a.addCodespaceRange(
                            i + 1,
                            hexToInt(o, i),
                            hexToInt(c, i)
                          );
                        }
                        break;
                      case 1:
                        n.readHex(o, i);
                        n.readHexNumber(c, i);
                        addHex(c, o, i);
                        n.readNumber();
                        for (let e = 1; e < p; e++) {
                          incHex(c, i);
                          n.readHexNumber(o, i);
                          addHex(o, c, i);
                          n.readHexNumber(c, i);
                          addHex(c, o, i);
                          n.readNumber();
                        }
                        break;
                      case 2:
                        n.readHex(l, i);
                        d = n.readNumber();
                        a.mapOne(hexToInt(l, i), d);
                        for (let e = 1; e < p; e++) {
                          incHex(l, i);
                          if (!r) {
                            n.readHexNumber(u, i);
                            addHex(l, u, i);
                          }
                          d = n.readSigned() + (d + 1);
                          a.mapOne(hexToInt(l, i), d);
                        }
                        break;
                      case 3:
                        n.readHex(o, i);
                        n.readHexNumber(c, i);
                        addHex(c, o, i);
                        d = n.readNumber();
                        a.mapCidRange(hexToInt(o, i), hexToInt(c, i), d);
                        for (let e = 1; e < p; e++) {
                          incHex(c, i);
                          if (r) o.set(c);
                          else {
                            n.readHexNumber(o, i);
                            addHex(o, c, i);
                          }
                          n.readHexNumber(c, i);
                          addHex(c, o, i);
                          d = n.readNumber();
                          a.mapCidRange(hexToInt(o, i), hexToInt(c, i), d);
                        }
                        break;
                      case 4:
                        n.readHex(l, g);
                        n.readHex(h, i);
                        a.mapOne(hexToInt(l, g), hexToStr(h, i));
                        for (let e = 1; e < p; e++) {
                          incHex(l, g);
                          if (!r) {
                            n.readHexNumber(u, g);
                            addHex(l, u, g);
                          }
                          incHex(h, i);
                          n.readHexSigned(u, i);
                          addHex(h, u, i);
                          a.mapOne(hexToInt(l, g), hexToStr(h, i));
                        }
                        break;
                      case 5:
                        n.readHex(o, g);
                        n.readHexNumber(c, g);
                        addHex(c, o, g);
                        n.readHex(h, i);
                        a.mapBfRange(
                          hexToInt(o, g),
                          hexToInt(c, g),
                          hexToStr(h, i)
                        );
                        for (let e = 1; e < p; e++) {
                          incHex(c, g);
                          if (r) o.set(c);
                          else {
                            n.readHexNumber(o, g);
                            addHex(o, c, g);
                          }
                          n.readHexNumber(c, g);
                          addHex(c, o, g);
                          n.readHex(h, i);
                          a.mapBfRange(
                            hexToInt(o, g),
                            hexToInt(c, g),
                            hexToStr(h, i)
                          );
                        }
                        break;
                      default:
                        throw new Error(
                          `BinaryCMapReader.process - unknown type: ${t}`
                        );
                    }
                  }
                  return s ? r(s) : a;
                }
              };
            })(),
            d = (function CMapFactoryClosure() {
              function strToInt(e) {
                let t = 0;
                for (let a = 0; a < e.length; a++)
                  t = (t << 8) | e.charCodeAt(a);
                return t >>> 0;
              }
              function expectString(e) {
                if ("string" != typeof e)
                  throw new r.FormatError("Malformed CMap: expected string.");
              }
              function expectInt(e) {
                if (!Number.isInteger(e))
                  throw new r.FormatError("Malformed CMap: expected int.");
              }
              function parseBfChar(e, t) {
                for (;;) {
                  let a = t.getObj();
                  if (a === n.EOF) break;
                  if ((0, n.isCmd)(a, "endbfchar")) return;
                  expectString(a);
                  const r = strToInt(a);
                  a = t.getObj();
                  expectString(a);
                  const i = a;
                  e.mapOne(r, i);
                }
              }
              function parseBfRange(e, t) {
                for (;;) {
                  let a = t.getObj();
                  if (a === n.EOF) break;
                  if ((0, n.isCmd)(a, "endbfrange")) return;
                  expectString(a);
                  const r = strToInt(a);
                  a = t.getObj();
                  expectString(a);
                  const i = strToInt(a);
                  a = t.getObj();
                  if (Number.isInteger(a) || "string" == typeof a) {
                    const t = Number.isInteger(a) ? String.fromCharCode(a) : a;
                    e.mapBfRange(r, i, t);
                  } else {
                    if (!(0, n.isCmd)(a, "[")) break;
                    {
                      a = t.getObj();
                      const s = [];
                      for (; !(0, n.isCmd)(a, "]") && a !== n.EOF; ) {
                        s.push(a);
                        a = t.getObj();
                      }
                      e.mapBfRangeToArray(r, i, s);
                    }
                  }
                }
                throw new r.FormatError("Invalid bf range.");
              }
              function parseCidChar(e, t) {
                for (;;) {
                  let a = t.getObj();
                  if (a === n.EOF) break;
                  if ((0, n.isCmd)(a, "endcidchar")) return;
                  expectString(a);
                  const r = strToInt(a);
                  a = t.getObj();
                  expectInt(a);
                  const i = a;
                  e.mapOne(r, i);
                }
              }
              function parseCidRange(e, t) {
                for (;;) {
                  let a = t.getObj();
                  if (a === n.EOF) break;
                  if ((0, n.isCmd)(a, "endcidrange")) return;
                  expectString(a);
                  const r = strToInt(a);
                  a = t.getObj();
                  expectString(a);
                  const i = strToInt(a);
                  a = t.getObj();
                  expectInt(a);
                  const s = a;
                  e.mapCidRange(r, i, s);
                }
              }
              function parseCodespaceRange(e, t) {
                for (;;) {
                  let a = t.getObj();
                  if (a === n.EOF) break;
                  if ((0, n.isCmd)(a, "endcodespacerange")) return;
                  if ("string" != typeof a) break;
                  const r = strToInt(a);
                  a = t.getObj();
                  if ("string" != typeof a) break;
                  const i = strToInt(a);
                  e.addCodespaceRange(a.length, r, i);
                }
                throw new r.FormatError("Invalid codespace range.");
              }
              function parseWMode(e, t) {
                const a = t.getObj();
                Number.isInteger(a) && (e.vertical = !!a);
              }
              function parseCMapName(e, t) {
                const a = t.getObj();
                a instanceof n.Name && (e.name = a.name);
              }
              async function parseCMap(e, t, a, i) {
                let s, c;
                e: for (;;)
                  try {
                    const a = t.getObj();
                    if (a === n.EOF) break;
                    if (a instanceof n.Name) {
                      "WMode" === a.name
                        ? parseWMode(e, t)
                        : "CMapName" === a.name && parseCMapName(e, t);
                      s = a;
                    } else if (a instanceof n.Cmd)
                      switch (a.cmd) {
                        case "endcmap":
                          break e;
                        case "usecmap":
                          s instanceof n.Name && (c = s.name);
                          break;
                        case "begincodespacerange":
                          parseCodespaceRange(e, t);
                          break;
                        case "beginbfchar":
                          parseBfChar(e, t);
                          break;
                        case "begincidchar":
                          parseCidChar(e, t);
                          break;
                        case "beginbfrange":
                          parseBfRange(e, t);
                          break;
                        case "begincidrange":
                          parseCidRange(e, t);
                      }
                  } catch (e) {
                    if (e instanceof o.MissingDataException) throw e;
                    (0, r.warn)("Invalid cMap data: " + e);
                    continue;
                  }
                !i && c && (i = c);
                return i ? extendCMap(e, a, i) : e;
              }
              async function extendCMap(e, t, a) {
                e.useCMap = await createBuiltInCMap(a, t);
                if (0 === e.numCodespaceRanges) {
                  const t = e.useCMap.codespaceRanges;
                  for (let a = 0; a < t.length; a++)
                    e.codespaceRanges[a] = t[a].slice();
                  e.numCodespaceRanges = e.useCMap.numCodespaceRanges;
                }
                e.useCMap.forEach(function (t, a) {
                  e.contains(t) || e.mapOne(t, e.useCMap.lookup(t));
                });
                return e;
              }
              async function createBuiltInCMap(e, t) {
                if ("Identity-H" === e) return new IdentityCMap(!1, 2);
                if ("Identity-V" === e) return new IdentityCMap(!0, 2);
                if (!l.includes(e)) throw new Error("Unknown CMap name: " + e);
                if (!t)
                  throw new Error("Built-in CMap parameters are not provided.");
                const { cMapData: a, compressionType: n } = await t(e),
                  i = new CMap(!0);
                if (n === r.CMapCompressionType.BINARY)
                  return new u().process(a, i, (e) => extendCMap(i, t, e));
                if (n === r.CMapCompressionType.NONE) {
                  const e = new s.Lexer(new c.Stream(a));
                  return parseCMap(i, e, t, null);
                }
                throw new Error(`Invalid CMap "compressionType" value: ${n}`);
              }
              return {
                async create(e) {
                  const t = e.encoding,
                    a = e.fetchBuiltInCMap,
                    r = e.useCMap;
                  if (t instanceof n.Name) return createBuiltInCMap(t.name, a);
                  if (t instanceof i.BaseStream) {
                    const e = await parseCMap(new CMap(), new s.Lexer(t), a, r);
                    return e.isIdentityCMap ? createBuiltInCMap(e.name, a) : e;
                  }
                  throw new Error("Encoding required.");
                },
              };
            })();
          t.CMapFactory = d;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Parser = t.Linearization = t.Lexer = void 0;
          var r = a(2),
            n = a(4),
            i = a(3),
            s = a(16),
            o = a(18),
            c = a(19),
            l = a(21),
            h = a(22),
            u = a(25),
            d = a(27),
            f = a(29),
            g = a(8),
            p = a(30),
            m = a(31);
          class Parser {
            constructor({
              lexer: e,
              xref: t,
              allowStreams: a = !1,
              recoveryMode: r = !1,
            }) {
              this.lexer = e;
              this.xref = t;
              this.allowStreams = a;
              this.recoveryMode = r;
              this.imageCache = Object.create(null);
              this._imageId = 0;
              this.refill();
            }
            refill() {
              this.buf1 = this.lexer.getObj();
              this.buf2 = this.lexer.getObj();
            }
            shift() {
              if (this.buf2 instanceof n.Cmd && "ID" === this.buf2.cmd) {
                this.buf1 = this.buf2;
                this.buf2 = null;
              } else {
                this.buf1 = this.buf2;
                this.buf2 = this.lexer.getObj();
              }
            }
            tryShift() {
              try {
                this.shift();
                return !0;
              } catch (e) {
                if (e instanceof i.MissingDataException) throw e;
                return !1;
              }
            }
            getObj(e = null) {
              const t = this.buf1;
              this.shift();
              if (t instanceof n.Cmd)
                switch (t.cmd) {
                  case "BI":
                    return this.makeInlineImage(e);
                  case "[":
                    const a = [];
                    for (
                      ;
                      !(0, n.isCmd)(this.buf1, "]") && this.buf1 !== n.EOF;

                    )
                      a.push(this.getObj(e));
                    if (this.buf1 === n.EOF) {
                      if (this.recoveryMode) return a;
                      throw new i.ParserEOFException(
                        "End of file inside array."
                      );
                    }
                    this.shift();
                    return a;
                  case "<<":
                    const s = new n.Dict(this.xref);
                    for (
                      ;
                      !(0, n.isCmd)(this.buf1, ">>") && this.buf1 !== n.EOF;

                    ) {
                      if (!(this.buf1 instanceof n.Name)) {
                        (0, r.info)(
                          "Malformed dictionary: key must be a name object"
                        );
                        this.shift();
                        continue;
                      }
                      const t = this.buf1.name;
                      this.shift();
                      if (this.buf1 === n.EOF) break;
                      s.set(t, this.getObj(e));
                    }
                    if (this.buf1 === n.EOF) {
                      if (this.recoveryMode) return s;
                      throw new i.ParserEOFException(
                        "End of file inside dictionary."
                      );
                    }
                    if ((0, n.isCmd)(this.buf2, "stream"))
                      return this.allowStreams ? this.makeStream(s, e) : s;
                    this.shift();
                    return s;
                  default:
                    return t;
                }
              if (Number.isInteger(t)) {
                if (
                  Number.isInteger(this.buf1) &&
                  (0, n.isCmd)(this.buf2, "R")
                ) {
                  const e = n.Ref.get(t, this.buf1);
                  this.shift();
                  this.shift();
                  return e;
                }
                return t;
              }
              return "string" == typeof t && e ? e.decryptString(t) : t;
            }
            findDefaultInlineStreamEnd(e) {
              const t = this.lexer,
                a = e.pos;
              let s,
                o,
                c = 0;
              for (; -1 !== (s = e.getByte()); )
                if (0 === c) c = 69 === s ? 1 : 0;
                else if (1 === c) c = 73 === s ? 2 : 0;
                else if (32 === s || 10 === s || 13 === s) {
                  o = e.pos;
                  const a = e.peekBytes(10);
                  for (let e = 0, t = a.length; e < t; e++) {
                    s = a[e];
                    if (
                      (0 !== s || 0 === a[e + 1]) &&
                      10 !== s &&
                      13 !== s &&
                      (s < 32 || s > 127)
                    ) {
                      c = 0;
                      break;
                    }
                  }
                  if (2 !== c) continue;
                  if (t.knownCommands) {
                    const e = t.peekObj();
                    e instanceof n.Cmd && !t.knownCommands[e.cmd] && (c = 0);
                  } else
                    (0, r.warn)(
                      "findDefaultInlineStreamEnd - `lexer.knownCommands` is undefined."
                    );
                  if (2 === c) break;
                } else c = 0;
              if (-1 === s) {
                (0, r.warn)(
                  "findDefaultInlineStreamEnd: Reached the end of the stream without finding a valid EI marker"
                );
                if (o) {
                  (0, r.warn)(
                    '... trying to recover by using the last "EI" occurrence.'
                  );
                  e.skip(-(e.pos - o));
                }
              }
              let l = 4;
              e.skip(-l);
              s = e.peekByte();
              e.skip(l);
              (0, i.isWhiteSpace)(s) || l--;
              return e.pos - l - a;
            }
            findDCTDecodeInlineStreamEnd(e) {
              const t = e.pos;
              let a,
                n,
                i = !1;
              for (; -1 !== (a = e.getByte()); )
                if (255 === a) {
                  switch (e.getByte()) {
                    case 0:
                      break;
                    case 255:
                      e.skip(-1);
                      break;
                    case 217:
                      i = !0;
                      break;
                    case 192:
                    case 193:
                    case 194:
                    case 195:
                    case 197:
                    case 198:
                    case 199:
                    case 201:
                    case 202:
                    case 203:
                    case 205:
                    case 206:
                    case 207:
                    case 196:
                    case 204:
                    case 218:
                    case 219:
                    case 220:
                    case 221:
                    case 222:
                    case 223:
                    case 224:
                    case 225:
                    case 226:
                    case 227:
                    case 228:
                    case 229:
                    case 230:
                    case 231:
                    case 232:
                    case 233:
                    case 234:
                    case 235:
                    case 236:
                    case 237:
                    case 238:
                    case 239:
                    case 254:
                      n = e.getUint16();
                      n > 2 ? e.skip(n - 2) : e.skip(-2);
                  }
                  if (i) break;
                }
              const s = e.pos - t;
              if (-1 === a) {
                (0, r.warn)(
                  "Inline DCTDecode image stream: EOI marker not found, searching for /EI/ instead."
                );
                e.skip(-s);
                return this.findDefaultInlineStreamEnd(e);
              }
              this.inlineStreamSkipEI(e);
              return s;
            }
            findASCII85DecodeInlineStreamEnd(e) {
              const t = e.pos;
              let a;
              for (; -1 !== (a = e.getByte()); )
                if (126 === a) {
                  const t = e.pos;
                  a = e.peekByte();
                  for (; (0, i.isWhiteSpace)(a); ) {
                    e.skip();
                    a = e.peekByte();
                  }
                  if (62 === a) {
                    e.skip();
                    break;
                  }
                  if (e.pos > t) {
                    const t = e.peekBytes(2);
                    if (69 === t[0] && 73 === t[1]) break;
                  }
                }
              const n = e.pos - t;
              if (-1 === a) {
                (0, r.warn)(
                  "Inline ASCII85Decode image stream: EOD marker not found, searching for /EI/ instead."
                );
                e.skip(-n);
                return this.findDefaultInlineStreamEnd(e);
              }
              this.inlineStreamSkipEI(e);
              return n;
            }
            findASCIIHexDecodeInlineStreamEnd(e) {
              const t = e.pos;
              let a;
              for (; -1 !== (a = e.getByte()) && 62 !== a; );
              const n = e.pos - t;
              if (-1 === a) {
                (0, r.warn)(
                  "Inline ASCIIHexDecode image stream: EOD marker not found, searching for /EI/ instead."
                );
                e.skip(-n);
                return this.findDefaultInlineStreamEnd(e);
              }
              this.inlineStreamSkipEI(e);
              return n;
            }
            inlineStreamSkipEI(e) {
              let t,
                a = 0;
              for (; -1 !== (t = e.getByte()); )
                if (0 === a) a = 69 === t ? 1 : 0;
                else if (1 === a) a = 73 === t ? 2 : 0;
                else if (2 === a) break;
            }
            makeInlineImage(e) {
              const t = this.lexer,
                a = t.stream,
                i = Object.create(null);
              let s;
              for (; !(0, n.isCmd)(this.buf1, "ID") && this.buf1 !== n.EOF; ) {
                if (!(this.buf1 instanceof n.Name))
                  throw new r.FormatError(
                    "Dictionary key must be a name object"
                  );
                const t = this.buf1.name;
                this.shift();
                if (this.buf1 === n.EOF) break;
                i[t] = this.getObj(e);
              }
              -1 !== t.beginInlineImagePos &&
                (s = a.pos - t.beginInlineImagePos);
              const o = this.xref.fetchIfRef(i.F || i.Filter);
              let c;
              if (o instanceof n.Name) c = o.name;
              else if (Array.isArray(o)) {
                const e = this.xref.fetchIfRef(o[0]);
                e instanceof n.Name && (c = e.name);
              }
              const l = a.pos;
              let h, u;
              switch (c) {
                case "DCT":
                case "DCTDecode":
                  h = this.findDCTDecodeInlineStreamEnd(a);
                  break;
                case "A85":
                case "ASCII85Decode":
                  h = this.findASCII85DecodeInlineStreamEnd(a);
                  break;
                case "AHx":
                case "ASCIIHexDecode":
                  h = this.findASCIIHexDecodeInlineStreamEnd(a);
                  break;
                default:
                  h = this.findDefaultInlineStreamEnd(a);
              }
              if (h < 1e3 && s > 0) {
                const e = a.pos;
                a.pos = t.beginInlineImagePos;
                u = (function getInlineImageCacheKey(e) {
                  const t = [],
                    a = e.length;
                  let r = 0;
                  for (; r < a - 1; ) t.push((e[r++] << 8) | e[r++]);
                  r < a && t.push(e[r]);
                  return a + "_" + String.fromCharCode.apply(null, t);
                })(a.getBytes(s + h));
                a.pos = e;
                const r = this.imageCache[u];
                if (void 0 !== r) {
                  this.buf2 = n.Cmd.get("EI");
                  this.shift();
                  r.reset();
                  return r;
                }
              }
              const d = new n.Dict(this.xref);
              for (const e in i) d.set(e, i[e]);
              let f = a.makeSubStream(l, h, d);
              e && (f = e.createStream(f, h));
              f = this.filter(f, d, h);
              f.dict = d;
              if (void 0 !== u) {
                f.cacheKey = "inline_img_" + ++this._imageId;
                this.imageCache[u] = f;
              }
              this.buf2 = n.Cmd.get("EI");
              this.shift();
              return f;
            }
            _findStreamLength(e, t) {
              const { stream: a } = this.lexer;
              a.pos = e;
              const r = t.length;
              for (; a.pos < a.end; ) {
                const n = a.peekBytes(2048),
                  i = n.length - r;
                if (i <= 0) break;
                let s = 0;
                for (; s < i; ) {
                  let i = 0;
                  for (; i < r && n[s + i] === t[i]; ) i++;
                  if (i >= r) {
                    a.pos += s;
                    return a.pos - e;
                  }
                  s++;
                }
                a.pos += i;
              }
              return -1;
            }
            makeStream(e, t) {
              const a = this.lexer;
              let s = a.stream;
              a.skipToNextLine();
              const o = s.pos - 1;
              let c = e.get("Length");
              if (!Number.isInteger(c)) {
                (0, r.info)(`Bad length "${c && c.toString()}" in stream.`);
                c = 0;
              }
              s.pos = o + c;
              a.nextChar();
              if (this.tryShift() && (0, n.isCmd)(this.buf2, "endstream"))
                this.shift();
              else {
                const e = new Uint8Array([
                  101, 110, 100, 115, 116, 114, 101, 97, 109,
                ]);
                let t = this._findStreamLength(o, e);
                if (t < 0) {
                  const a = 1;
                  for (let n = 1; n <= a; n++) {
                    const a = e.length - n,
                      c = e.slice(0, a),
                      l = this._findStreamLength(o, c);
                    if (l >= 0) {
                      const e = s.peekBytes(a + 1)[a];
                      if (!(0, i.isWhiteSpace)(e)) break;
                      (0, r.info)(
                        `Found "${(0, r.bytesToString)(
                          c
                        )}" when searching for endstream command.`
                      );
                      t = l;
                      break;
                    }
                  }
                  if (t < 0)
                    throw new r.FormatError("Missing endstream command.");
                }
                c = t;
                a.nextChar();
                this.shift();
                this.shift();
              }
              this.shift();
              s = s.makeSubStream(o, c, e);
              t && (s = t.createStream(s, c));
              s = this.filter(s, e, c);
              s.dict = e;
              return s;
            }
            filter(e, t, a) {
              let i = t.get("F", "Filter"),
                s = t.get("DP", "DecodeParms");
              if (i instanceof n.Name) {
                Array.isArray(s) &&
                  (0, r.warn)(
                    "/DecodeParms should not be an Array, when /Filter is a Name."
                  );
                return this.makeFilter(e, i.name, a, s);
              }
              let o = a;
              if (Array.isArray(i)) {
                const t = i,
                  a = s;
                for (let c = 0, l = t.length; c < l; ++c) {
                  i = this.xref.fetchIfRef(t[c]);
                  if (!(i instanceof n.Name))
                    throw new r.FormatError(`Bad filter name "${i}"`);
                  s = null;
                  Array.isArray(a) &&
                    c in a &&
                    (s = this.xref.fetchIfRef(a[c]));
                  e = this.makeFilter(e, i.name, o, s);
                  o = null;
                }
              }
              return e;
            }
            makeFilter(e, t, a, n) {
              if (0 === a) {
                (0, r.warn)(`Empty "${t}" stream.`);
                return new g.NullStream();
              }
              try {
                switch (t) {
                  case "Fl":
                  case "FlateDecode":
                    return n
                      ? new p.PredictorStream(new l.FlateStream(e, a), a, n)
                      : new l.FlateStream(e, a);
                  case "LZW":
                  case "LZWDecode":
                    let t = 1;
                    if (n) {
                      n.has("EarlyChange") && (t = n.get("EarlyChange"));
                      return new p.PredictorStream(
                        new f.LZWStream(e, a, t),
                        a,
                        n
                      );
                    }
                    return new f.LZWStream(e, a, t);
                  case "DCT":
                  case "DCTDecode":
                    return new u.JpegStream(e, a, n);
                  case "JPX":
                  case "JPXDecode":
                    return new d.JpxStream(e, a, n);
                  case "A85":
                  case "ASCII85Decode":
                    return new s.Ascii85Stream(e, a);
                  case "AHx":
                  case "ASCIIHexDecode":
                    return new o.AsciiHexStream(e, a);
                  case "CCF":
                  case "CCITTFaxDecode":
                    return new c.CCITTFaxStream(e, a, n);
                  case "RL":
                  case "RunLengthDecode":
                    return new m.RunLengthStream(e, a);
                  case "JBIG2Decode":
                    return new h.Jbig2Stream(e, a, n);
                }
                (0, r.warn)(`Filter "${t}" is not supported.`);
                return e;
              } catch (e) {
                if (e instanceof i.MissingDataException) throw e;
                (0, r.warn)(`Invalid stream: "${e}"`);
                return new g.NullStream();
              }
            }
          }
          t.Parser = Parser;
          const b = [
            1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 2, 0, 0, 2, 2, 0, 0, 0, 0,
            0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2,
            0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0,
          ];
          function toHexDigit(e) {
            return e >= 48 && e <= 57
              ? 15 & e
              : (e >= 65 && e <= 70) || (e >= 97 && e <= 102)
              ? 9 + (15 & e)
              : -1;
          }
          class Lexer {
            constructor(e, t = null) {
              this.stream = e;
              this.nextChar();
              this.strBuf = [];
              this.knownCommands = t;
              this._hexStringNumWarn = 0;
              this.beginInlineImagePos = -1;
            }
            nextChar() {
              return (this.currentChar = this.stream.getByte());
            }
            peekChar() {
              return this.stream.peekByte();
            }
            getNumber() {
              let e = this.currentChar,
                t = !1,
                a = 0,
                n = 0;
              if (45 === e) {
                n = -1;
                e = this.nextChar();
                45 === e && (e = this.nextChar());
              } else if (43 === e) {
                n = 1;
                e = this.nextChar();
              }
              if (10 === e || 13 === e)
                do {
                  e = this.nextChar();
                } while (10 === e || 13 === e);
              if (46 === e) {
                a = 10;
                e = this.nextChar();
              }
              if (e < 48 || e > 57) {
                const t = `Invalid number: ${String.fromCharCode(
                  e
                )} (charCode ${e})`;
                if ((0, i.isWhiteSpace)(e) || -1 === e) {
                  (0, r.info)(`Lexer.getNumber - "${t}".`);
                  return 0;
                }
                throw new r.FormatError(t);
              }
              n = n || 1;
              let s = e - 48,
                o = 0,
                c = 1;
              for (; (e = this.nextChar()) >= 0; )
                if (e >= 48 && e <= 57) {
                  const r = e - 48;
                  if (t) o = 10 * o + r;
                  else {
                    0 !== a && (a *= 10);
                    s = 10 * s + r;
                  }
                } else if (46 === e) {
                  if (0 !== a) break;
                  a = 1;
                } else if (45 === e)
                  (0, r.warn)(
                    "Badly formatted number: minus sign in the middle"
                  );
                else {
                  if (69 !== e && 101 !== e) break;
                  e = this.peekChar();
                  if (43 === e || 45 === e) {
                    c = 45 === e ? -1 : 1;
                    this.nextChar();
                  } else if (e < 48 || e > 57) break;
                  t = !0;
                }
              0 !== a && (s /= a);
              t && (s *= 10 ** (c * o));
              return n * s;
            }
            getString() {
              let e = 1,
                t = !1;
              const a = this.strBuf;
              a.length = 0;
              let n = this.nextChar();
              for (;;) {
                let i = !1;
                switch (0 | n) {
                  case -1:
                    (0, r.warn)("Unterminated string");
                    t = !0;
                    break;
                  case 40:
                    ++e;
                    a.push("(");
                    break;
                  case 41:
                    if (0 == --e) {
                      this.nextChar();
                      t = !0;
                    } else a.push(")");
                    break;
                  case 92:
                    n = this.nextChar();
                    switch (n) {
                      case -1:
                        (0, r.warn)("Unterminated string");
                        t = !0;
                        break;
                      case 110:
                        a.push("\n");
                        break;
                      case 114:
                        a.push("\r");
                        break;
                      case 116:
                        a.push("\t");
                        break;
                      case 98:
                        a.push("\b");
                        break;
                      case 102:
                        a.push("\f");
                        break;
                      case 92:
                      case 40:
                      case 41:
                        a.push(String.fromCharCode(n));
                        break;
                      case 48:
                      case 49:
                      case 50:
                      case 51:
                      case 52:
                      case 53:
                      case 54:
                      case 55:
                        let e = 15 & n;
                        n = this.nextChar();
                        i = !0;
                        if (n >= 48 && n <= 55) {
                          e = (e << 3) + (15 & n);
                          n = this.nextChar();
                          if (n >= 48 && n <= 55) {
                            i = !1;
                            e = (e << 3) + (15 & n);
                          }
                        }
                        a.push(String.fromCharCode(e));
                        break;
                      case 13:
                        10 === this.peekChar() && this.nextChar();
                        break;
                      case 10:
                        break;
                      default:
                        a.push(String.fromCharCode(n));
                    }
                    break;
                  default:
                    a.push(String.fromCharCode(n));
                }
                if (t) break;
                i || (n = this.nextChar());
              }
              return a.join("");
            }
            getName() {
              let e, t;
              const a = this.strBuf;
              a.length = 0;
              for (; (e = this.nextChar()) >= 0 && !b[e]; )
                if (35 === e) {
                  e = this.nextChar();
                  if (b[e]) {
                    (0, r.warn)(
                      "Lexer_getName: NUMBER SIGN (#) should be followed by a hexadecimal number."
                    );
                    a.push("#");
                    break;
                  }
                  const n = toHexDigit(e);
                  if (-1 !== n) {
                    t = e;
                    e = this.nextChar();
                    const i = toHexDigit(e);
                    if (-1 === i) {
                      (0, r.warn)(
                        `Lexer_getName: Illegal digit (${String.fromCharCode(
                          e
                        )}) in hexadecimal number.`
                      );
                      a.push("#", String.fromCharCode(t));
                      if (b[e]) break;
                      a.push(String.fromCharCode(e));
                      continue;
                    }
                    a.push(String.fromCharCode((n << 4) | i));
                  } else a.push("#", String.fromCharCode(e));
                } else a.push(String.fromCharCode(e));
              a.length > 127 &&
                (0, r.warn)(
                  `Name token is longer than allowed by the spec: ${a.length}`
                );
              return n.Name.get(a.join(""));
            }
            _hexStringWarn(e) {
              5 != this._hexStringNumWarn++
                ? this._hexStringNumWarn > 5 ||
                  (0, r.warn)(`getHexString - ignoring invalid character: ${e}`)
                : (0, r.warn)(
                    "getHexString - ignoring additional invalid characters."
                  );
            }
            getHexString() {
              const e = this.strBuf;
              e.length = 0;
              let t,
                a,
                n = this.currentChar,
                i = !0;
              this._hexStringNumWarn = 0;
              for (;;) {
                if (n < 0) {
                  (0, r.warn)("Unterminated hex string");
                  break;
                }
                if (62 === n) {
                  this.nextChar();
                  break;
                }
                if (1 !== b[n]) {
                  if (i) {
                    t = toHexDigit(n);
                    if (-1 === t) {
                      this._hexStringWarn(n);
                      n = this.nextChar();
                      continue;
                    }
                  } else {
                    a = toHexDigit(n);
                    if (-1 === a) {
                      this._hexStringWarn(n);
                      n = this.nextChar();
                      continue;
                    }
                    e.push(String.fromCharCode((t << 4) | a));
                  }
                  i = !i;
                  n = this.nextChar();
                } else n = this.nextChar();
              }
              return e.join("");
            }
            getObj() {
              let e = !1,
                t = this.currentChar;
              for (;;) {
                if (t < 0) return n.EOF;
                if (e) (10 !== t && 13 !== t) || (e = !1);
                else if (37 === t) e = !0;
                else if (1 !== b[t]) break;
                t = this.nextChar();
              }
              switch (0 | t) {
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 43:
                case 45:
                case 46:
                  return this.getNumber();
                case 40:
                  return this.getString();
                case 47:
                  return this.getName();
                case 91:
                  this.nextChar();
                  return n.Cmd.get("[");
                case 93:
                  this.nextChar();
                  return n.Cmd.get("]");
                case 60:
                  t = this.nextChar();
                  if (60 === t) {
                    this.nextChar();
                    return n.Cmd.get("<<");
                  }
                  return this.getHexString();
                case 62:
                  t = this.nextChar();
                  if (62 === t) {
                    this.nextChar();
                    return n.Cmd.get(">>");
                  }
                  return n.Cmd.get(">");
                case 123:
                  this.nextChar();
                  return n.Cmd.get("{");
                case 125:
                  this.nextChar();
                  return n.Cmd.get("}");
                case 41:
                  this.nextChar();
                  throw new r.FormatError(`Illegal character: ${t}`);
              }
              let a = String.fromCharCode(t);
              if (t < 32 || t > 127) {
                const e = this.peekChar();
                if (e >= 32 && e <= 127) {
                  this.nextChar();
                  return n.Cmd.get(a);
                }
              }
              const i = this.knownCommands;
              let s = i && void 0 !== i[a];
              for (; (t = this.nextChar()) >= 0 && !b[t]; ) {
                const e = a + String.fromCharCode(t);
                if (s && void 0 === i[e]) break;
                if (128 === a.length)
                  throw new r.FormatError(
                    `Command token too long: ${a.length}`
                  );
                a = e;
                s = i && void 0 !== i[a];
              }
              if ("true" === a) return !0;
              if ("false" === a) return !1;
              if ("null" === a) return null;
              "BI" === a && (this.beginInlineImagePos = this.stream.pos);
              return n.Cmd.get(a);
            }
            peekObj() {
              const e = this.stream.pos,
                t = this.currentChar,
                a = this.beginInlineImagePos;
              let n;
              try {
                n = this.getObj();
              } catch (e) {
                if (e instanceof i.MissingDataException) throw e;
                (0, r.warn)(`peekObj: ${e}`);
              }
              this.stream.pos = e;
              this.currentChar = t;
              this.beginInlineImagePos = a;
              return n;
            }
            skipToNextLine() {
              let e = this.currentChar;
              for (; e >= 0; ) {
                if (13 === e) {
                  e = this.nextChar();
                  10 === e && this.nextChar();
                  break;
                }
                if (10 === e) {
                  this.nextChar();
                  break;
                }
                e = this.nextChar();
              }
            }
          }
          t.Lexer = Lexer;
          t.Linearization = class Linearization {
            static create(e) {
              function getInt(e, t, a = !1) {
                const r = e.get(t);
                if (Number.isInteger(r) && (a ? r >= 0 : r > 0)) return r;
                throw new Error(
                  `The "${t}" parameter in the linearization dictionary is invalid.`
                );
              }
              const t = new Parser({ lexer: new Lexer(e), xref: null }),
                a = t.getObj(),
                r = t.getObj(),
                i = t.getObj(),
                s = t.getObj();
              let o, c;
              if (
                !(
                  Number.isInteger(a) &&
                  Number.isInteger(r) &&
                  (0, n.isCmd)(i, "obj") &&
                  s instanceof n.Dict &&
                  "number" == typeof (o = s.get("Linearized")) &&
                  o > 0
                )
              )
                return null;
              if ((c = getInt(s, "L")) !== e.length)
                throw new Error(
                  'The "L" parameter in the linearization dictionary does not equal the stream length.'
                );
              return {
                length: c,
                hints: (function getHints(e) {
                  const t = e.get("H");
                  let a;
                  if (Array.isArray(t) && (2 === (a = t.length) || 4 === a)) {
                    for (let e = 0; e < a; e++) {
                      const a = t[e];
                      if (!(Number.isInteger(a) && a > 0))
                        throw new Error(
                          `Hint (${e}) in the linearization dictionary is invalid.`
                        );
                    }
                    return t;
                  }
                  throw new Error(
                    "Hint array in the linearization dictionary is invalid."
                  );
                })(s),
                objectNumberFirst: getInt(s, "O"),
                endFirst: getInt(s, "E"),
                numPages: getInt(s, "N"),
                mainXRefEntriesOffset: getInt(s, "T"),
                pageFirst: s.has("P") ? getInt(s, "P", !0) : 0,
              };
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Ascii85Stream = void 0;
          var r = a(17),
            n = a(3);
          class Ascii85Stream extends r.DecodeStream {
            constructor(e, t) {
              t && (t *= 0.8);
              super(t);
              this.str = e;
              this.dict = e.dict;
              this.input = new Uint8Array(5);
            }
            readBlock() {
              const e = this.str;
              let t = e.getByte();
              for (; (0, n.isWhiteSpace)(t); ) t = e.getByte();
              if (-1 === t || 126 === t) {
                this.eof = !0;
                return;
              }
              const a = this.bufferLength;
              let r, i;
              if (122 === t) {
                r = this.ensureBuffer(a + 4);
                for (i = 0; i < 4; ++i) r[a + i] = 0;
                this.bufferLength += 4;
              } else {
                const s = this.input;
                s[0] = t;
                for (i = 1; i < 5; ++i) {
                  t = e.getByte();
                  for (; (0, n.isWhiteSpace)(t); ) t = e.getByte();
                  s[i] = t;
                  if (-1 === t || 126 === t) break;
                }
                r = this.ensureBuffer(a + i - 1);
                this.bufferLength += i - 1;
                if (i < 5) {
                  for (; i < 5; ++i) s[i] = 117;
                  this.eof = !0;
                }
                let o = 0;
                for (i = 0; i < 5; ++i) o = 85 * o + (s[i] - 33);
                for (i = 3; i >= 0; --i) {
                  r[a + i] = 255 & o;
                  o >>= 8;
                }
              }
            }
          }
          t.Ascii85Stream = Ascii85Stream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.StreamsSequenceStream = t.DecodeStream = void 0;
          var r = a(5),
            n = a(8);
          const i = new Uint8Array(0);
          class DecodeStream extends r.BaseStream {
            constructor(e) {
              super();
              this._rawMinBufferLength = e || 0;
              this.pos = 0;
              this.bufferLength = 0;
              this.eof = !1;
              this.buffer = i;
              this.minBufferLength = 512;
              if (e)
                for (; this.minBufferLength < e; ) this.minBufferLength *= 2;
            }
            get isEmpty() {
              for (; !this.eof && 0 === this.bufferLength; ) this.readBlock();
              return 0 === this.bufferLength;
            }
            ensureBuffer(e) {
              const t = this.buffer;
              if (e <= t.byteLength) return t;
              let a = this.minBufferLength;
              for (; a < e; ) a *= 2;
              const r = new Uint8Array(a);
              r.set(t);
              return (this.buffer = r);
            }
            getByte() {
              const e = this.pos;
              for (; this.bufferLength <= e; ) {
                if (this.eof) return -1;
                this.readBlock();
              }
              return this.buffer[this.pos++];
            }
            getBytes(e) {
              const t = this.pos;
              let a;
              if (e) {
                this.ensureBuffer(t + e);
                a = t + e;
                for (; !this.eof && this.bufferLength < a; ) this.readBlock();
                const r = this.bufferLength;
                a > r && (a = r);
              } else {
                for (; !this.eof; ) this.readBlock();
                a = this.bufferLength;
              }
              this.pos = a;
              return this.buffer.subarray(t, a);
            }
            reset() {
              this.pos = 0;
            }
            makeSubStream(e, t, a = null) {
              if (void 0 === t) for (; !this.eof; ) this.readBlock();
              else {
                const a = e + t;
                for (; this.bufferLength <= a && !this.eof; ) this.readBlock();
              }
              return new n.Stream(this.buffer, e, t, a);
            }
            getBaseStreams() {
              return this.str ? this.str.getBaseStreams() : null;
            }
          }
          t.DecodeStream = DecodeStream;
          t.StreamsSequenceStream = class StreamsSequenceStream extends (
            DecodeStream
          ) {
            constructor(e, t = null) {
              let a = 0;
              for (const t of e)
                a +=
                  t instanceof DecodeStream ? t._rawMinBufferLength : t.length;
              super(a);
              this.streams = e;
              this._onError = t;
            }
            readBlock() {
              const e = this.streams;
              if (0 === e.length) {
                this.eof = !0;
                return;
              }
              const t = e.shift();
              let a;
              try {
                a = t.getBytes();
              } catch (e) {
                if (this._onError) {
                  this._onError(e, t.dict && t.dict.objId);
                  return;
                }
                throw e;
              }
              const r = this.bufferLength,
                n = r + a.length;
              this.ensureBuffer(n).set(a, r);
              this.bufferLength = n;
            }
            getBaseStreams() {
              const e = [];
              for (const t of this.streams) {
                const a = t.getBaseStreams();
                a && e.push(...a);
              }
              return e.length > 0 ? e : null;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.AsciiHexStream = void 0;
          var r = a(17);
          class AsciiHexStream extends r.DecodeStream {
            constructor(e, t) {
              t && (t *= 0.5);
              super(t);
              this.str = e;
              this.dict = e.dict;
              this.firstDigit = -1;
            }
            readBlock() {
              const e = this.str.getBytes(8e3);
              if (!e.length) {
                this.eof = !0;
                return;
              }
              const t = (e.length + 1) >> 1,
                a = this.ensureBuffer(this.bufferLength + t);
              let r = this.bufferLength,
                n = this.firstDigit;
              for (const t of e) {
                let e;
                if (t >= 48 && t <= 57) e = 15 & t;
                else {
                  if (!((t >= 65 && t <= 70) || (t >= 97 && t <= 102))) {
                    if (62 === t) {
                      this.eof = !0;
                      break;
                    }
                    continue;
                  }
                  e = 9 + (15 & t);
                }
                if (n < 0) n = e;
                else {
                  a[r++] = (n << 4) | e;
                  n = -1;
                }
              }
              if (n >= 0 && this.eof) {
                a[r++] = n << 4;
                n = -1;
              }
              this.firstDigit = n;
              this.bufferLength = r;
            }
          }
          t.AsciiHexStream = AsciiHexStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.CCITTFaxStream = void 0;
          var r = a(20),
            n = a(17),
            i = a(4);
          class CCITTFaxStream extends n.DecodeStream {
            constructor(e, t, a) {
              super(t);
              this.str = e;
              this.dict = e.dict;
              a instanceof i.Dict || (a = i.Dict.empty);
              const n = { next: () => e.getByte() };
              this.ccittFaxDecoder = new r.CCITTFaxDecoder(n, {
                K: a.get("K"),
                EndOfLine: a.get("EndOfLine"),
                EncodedByteAlign: a.get("EncodedByteAlign"),
                Columns: a.get("Columns"),
                Rows: a.get("Rows"),
                EndOfBlock: a.get("EndOfBlock"),
                BlackIs1: a.get("BlackIs1"),
              });
            }
            readBlock() {
              for (; !this.eof; ) {
                const e = this.ccittFaxDecoder.readNextChar();
                if (-1 === e) {
                  this.eof = !0;
                  return;
                }
                this.ensureBuffer(this.bufferLength + 1);
                this.buffer[this.bufferLength++] = e;
              }
            }
          }
          t.CCITTFaxStream = CCITTFaxStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.CCITTFaxDecoder = void 0;
          var r = a(2);
          const n = -1,
            i = [
              [-1, -1],
              [-1, -1],
              [7, 8],
              [7, 7],
              [6, 6],
              [6, 6],
              [6, 5],
              [6, 5],
              [4, 0],
              [4, 0],
              [4, 0],
              [4, 0],
              [4, 0],
              [4, 0],
              [4, 0],
              [4, 0],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [3, 3],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
              [1, 2],
            ],
            s = [
              [-1, -1],
              [12, -2],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [11, 1792],
              [11, 1792],
              [12, 1984],
              [12, 2048],
              [12, 2112],
              [12, 2176],
              [12, 2240],
              [12, 2304],
              [11, 1856],
              [11, 1856],
              [11, 1920],
              [11, 1920],
              [12, 2368],
              [12, 2432],
              [12, 2496],
              [12, 2560],
            ],
            o = [
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [8, 29],
              [8, 29],
              [8, 30],
              [8, 30],
              [8, 45],
              [8, 45],
              [8, 46],
              [8, 46],
              [7, 22],
              [7, 22],
              [7, 22],
              [7, 22],
              [7, 23],
              [7, 23],
              [7, 23],
              [7, 23],
              [8, 47],
              [8, 47],
              [8, 48],
              [8, 48],
              [6, 13],
              [6, 13],
              [6, 13],
              [6, 13],
              [6, 13],
              [6, 13],
              [6, 13],
              [6, 13],
              [7, 20],
              [7, 20],
              [7, 20],
              [7, 20],
              [8, 33],
              [8, 33],
              [8, 34],
              [8, 34],
              [8, 35],
              [8, 35],
              [8, 36],
              [8, 36],
              [8, 37],
              [8, 37],
              [8, 38],
              [8, 38],
              [7, 19],
              [7, 19],
              [7, 19],
              [7, 19],
              [8, 31],
              [8, 31],
              [8, 32],
              [8, 32],
              [6, 1],
              [6, 1],
              [6, 1],
              [6, 1],
              [6, 1],
              [6, 1],
              [6, 1],
              [6, 1],
              [6, 12],
              [6, 12],
              [6, 12],
              [6, 12],
              [6, 12],
              [6, 12],
              [6, 12],
              [6, 12],
              [8, 53],
              [8, 53],
              [8, 54],
              [8, 54],
              [7, 26],
              [7, 26],
              [7, 26],
              [7, 26],
              [8, 39],
              [8, 39],
              [8, 40],
              [8, 40],
              [8, 41],
              [8, 41],
              [8, 42],
              [8, 42],
              [8, 43],
              [8, 43],
              [8, 44],
              [8, 44],
              [7, 21],
              [7, 21],
              [7, 21],
              [7, 21],
              [7, 28],
              [7, 28],
              [7, 28],
              [7, 28],
              [8, 61],
              [8, 61],
              [8, 62],
              [8, 62],
              [8, 63],
              [8, 63],
              [8, 0],
              [8, 0],
              [8, 320],
              [8, 320],
              [8, 384],
              [8, 384],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 10],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [5, 11],
              [7, 27],
              [7, 27],
              [7, 27],
              [7, 27],
              [8, 59],
              [8, 59],
              [8, 60],
              [8, 60],
              [9, 1472],
              [9, 1536],
              [9, 1600],
              [9, 1728],
              [7, 18],
              [7, 18],
              [7, 18],
              [7, 18],
              [7, 24],
              [7, 24],
              [7, 24],
              [7, 24],
              [8, 49],
              [8, 49],
              [8, 50],
              [8, 50],
              [8, 51],
              [8, 51],
              [8, 52],
              [8, 52],
              [7, 25],
              [7, 25],
              [7, 25],
              [7, 25],
              [8, 55],
              [8, 55],
              [8, 56],
              [8, 56],
              [8, 57],
              [8, 57],
              [8, 58],
              [8, 58],
              [6, 192],
              [6, 192],
              [6, 192],
              [6, 192],
              [6, 192],
              [6, 192],
              [6, 192],
              [6, 192],
              [6, 1664],
              [6, 1664],
              [6, 1664],
              [6, 1664],
              [6, 1664],
              [6, 1664],
              [6, 1664],
              [6, 1664],
              [8, 448],
              [8, 448],
              [8, 512],
              [8, 512],
              [9, 704],
              [9, 768],
              [8, 640],
              [8, 640],
              [8, 576],
              [8, 576],
              [9, 832],
              [9, 896],
              [9, 960],
              [9, 1024],
              [9, 1088],
              [9, 1152],
              [9, 1216],
              [9, 1280],
              [9, 1344],
              [9, 1408],
              [7, 256],
              [7, 256],
              [7, 256],
              [7, 256],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 2],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [4, 3],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 128],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 8],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [5, 9],
              [6, 16],
              [6, 16],
              [6, 16],
              [6, 16],
              [6, 16],
              [6, 16],
              [6, 16],
              [6, 16],
              [6, 17],
              [6, 17],
              [6, 17],
              [6, 17],
              [6, 17],
              [6, 17],
              [6, 17],
              [6, 17],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 4],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [6, 14],
              [6, 14],
              [6, 14],
              [6, 14],
              [6, 14],
              [6, 14],
              [6, 14],
              [6, 14],
              [6, 15],
              [6, 15],
              [6, 15],
              [6, 15],
              [6, 15],
              [6, 15],
              [6, 15],
              [6, 15],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [5, 64],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
              [4, 7],
            ],
            c = [
              [-1, -1],
              [-1, -1],
              [12, -2],
              [12, -2],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [11, 1792],
              [11, 1792],
              [11, 1792],
              [11, 1792],
              [12, 1984],
              [12, 1984],
              [12, 2048],
              [12, 2048],
              [12, 2112],
              [12, 2112],
              [12, 2176],
              [12, 2176],
              [12, 2240],
              [12, 2240],
              [12, 2304],
              [12, 2304],
              [11, 1856],
              [11, 1856],
              [11, 1856],
              [11, 1856],
              [11, 1920],
              [11, 1920],
              [11, 1920],
              [11, 1920],
              [12, 2368],
              [12, 2368],
              [12, 2432],
              [12, 2432],
              [12, 2496],
              [12, 2496],
              [12, 2560],
              [12, 2560],
              [10, 18],
              [10, 18],
              [10, 18],
              [10, 18],
              [10, 18],
              [10, 18],
              [10, 18],
              [10, 18],
              [12, 52],
              [12, 52],
              [13, 640],
              [13, 704],
              [13, 768],
              [13, 832],
              [12, 55],
              [12, 55],
              [12, 56],
              [12, 56],
              [13, 1280],
              [13, 1344],
              [13, 1408],
              [13, 1472],
              [12, 59],
              [12, 59],
              [12, 60],
              [12, 60],
              [13, 1536],
              [13, 1600],
              [11, 24],
              [11, 24],
              [11, 24],
              [11, 24],
              [11, 25],
              [11, 25],
              [11, 25],
              [11, 25],
              [13, 1664],
              [13, 1728],
              [12, 320],
              [12, 320],
              [12, 384],
              [12, 384],
              [12, 448],
              [12, 448],
              [13, 512],
              [13, 576],
              [12, 53],
              [12, 53],
              [12, 54],
              [12, 54],
              [13, 896],
              [13, 960],
              [13, 1024],
              [13, 1088],
              [13, 1152],
              [13, 1216],
              [10, 64],
              [10, 64],
              [10, 64],
              [10, 64],
              [10, 64],
              [10, 64],
              [10, 64],
              [10, 64],
            ],
            l = [
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [8, 13],
              [11, 23],
              [11, 23],
              [12, 50],
              [12, 51],
              [12, 44],
              [12, 45],
              [12, 46],
              [12, 47],
              [12, 57],
              [12, 58],
              [12, 61],
              [12, 256],
              [10, 16],
              [10, 16],
              [10, 16],
              [10, 16],
              [10, 17],
              [10, 17],
              [10, 17],
              [10, 17],
              [12, 48],
              [12, 49],
              [12, 62],
              [12, 63],
              [12, 30],
              [12, 31],
              [12, 32],
              [12, 33],
              [12, 40],
              [12, 41],
              [11, 22],
              [11, 22],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [8, 14],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 10],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [7, 11],
              [9, 15],
              [9, 15],
              [9, 15],
              [9, 15],
              [9, 15],
              [9, 15],
              [9, 15],
              [9, 15],
              [12, 128],
              [12, 192],
              [12, 26],
              [12, 27],
              [12, 28],
              [12, 29],
              [11, 19],
              [11, 19],
              [11, 20],
              [11, 20],
              [12, 34],
              [12, 35],
              [12, 36],
              [12, 37],
              [12, 38],
              [12, 39],
              [11, 21],
              [11, 21],
              [12, 42],
              [12, 43],
              [10, 0],
              [10, 0],
              [10, 0],
              [10, 0],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
              [7, 12],
            ],
            h = [
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [-1, -1],
              [6, 9],
              [6, 8],
              [5, 7],
              [5, 7],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 6],
              [4, 5],
              [4, 5],
              [4, 5],
              [4, 5],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 1],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [3, 4],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 3],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
              [2, 2],
            ];
          t.CCITTFaxDecoder = class CCITTFaxDecoder {
            constructor(e, t = {}) {
              if (!e || "function" != typeof e.next)
                throw new Error(
                  'CCITTFaxDecoder - invalid "source" parameter.'
                );
              this.source = e;
              this.eof = !1;
              this.encoding = t.K || 0;
              this.eoline = t.EndOfLine || !1;
              this.byteAlign = t.EncodedByteAlign || !1;
              this.columns = t.Columns || 1728;
              this.rows = t.Rows || 0;
              let a,
                r = t.EndOfBlock;
              null == r && (r = !0);
              this.eoblock = r;
              this.black = t.BlackIs1 || !1;
              this.codingLine = new Uint32Array(this.columns + 1);
              this.refLine = new Uint32Array(this.columns + 2);
              this.codingLine[0] = this.columns;
              this.codingPos = 0;
              this.row = 0;
              this.nextLine2D = this.encoding < 0;
              this.inputBits = 0;
              this.inputBuf = 0;
              this.outputBits = 0;
              this.rowsDone = !1;
              for (; 0 === (a = this._lookBits(12)); ) this._eatBits(1);
              1 === a && this._eatBits(12);
              if (this.encoding > 0) {
                this.nextLine2D = !this._lookBits(1);
                this._eatBits(1);
              }
            }
            readNextChar() {
              if (this.eof) return -1;
              const e = this.refLine,
                t = this.codingLine,
                a = this.columns;
              let i, s, o, c, l;
              if (0 === this.outputBits) {
                this.rowsDone && (this.eof = !0);
                if (this.eof) return -1;
                this.err = !1;
                let o, l, h;
                if (this.nextLine2D) {
                  for (c = 0; t[c] < a; ++c) e[c] = t[c];
                  e[c++] = a;
                  e[c] = a;
                  t[0] = 0;
                  this.codingPos = 0;
                  i = 0;
                  s = 0;
                  for (; t[this.codingPos] < a; ) {
                    o = this._getTwoDimCode();
                    switch (o) {
                      case 0:
                        this._addPixels(e[i + 1], s);
                        e[i + 1] < a && (i += 2);
                        break;
                      case 1:
                        o = l = 0;
                        if (s) {
                          do {
                            o += h = this._getBlackCode();
                          } while (h >= 64);
                          do {
                            l += h = this._getWhiteCode();
                          } while (h >= 64);
                        } else {
                          do {
                            o += h = this._getWhiteCode();
                          } while (h >= 64);
                          do {
                            l += h = this._getBlackCode();
                          } while (h >= 64);
                        }
                        this._addPixels(t[this.codingPos] + o, s);
                        t[this.codingPos] < a &&
                          this._addPixels(t[this.codingPos] + l, 1 ^ s);
                        for (; e[i] <= t[this.codingPos] && e[i] < a; ) i += 2;
                        break;
                      case 7:
                        this._addPixels(e[i] + 3, s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case 5:
                        this._addPixels(e[i] + 2, s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case 3:
                        this._addPixels(e[i] + 1, s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case 2:
                        this._addPixels(e[i], s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case 8:
                        this._addPixelsNeg(e[i] - 3, s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          i > 0 ? --i : ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case 6:
                        this._addPixelsNeg(e[i] - 2, s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          i > 0 ? --i : ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case 4:
                        this._addPixelsNeg(e[i] - 1, s);
                        s ^= 1;
                        if (t[this.codingPos] < a) {
                          i > 0 ? --i : ++i;
                          for (; e[i] <= t[this.codingPos] && e[i] < a; )
                            i += 2;
                        }
                        break;
                      case n:
                        this._addPixels(a, 0);
                        this.eof = !0;
                        break;
                      default:
                        (0, r.info)("bad 2d code");
                        this._addPixels(a, 0);
                        this.err = !0;
                    }
                  }
                } else {
                  t[0] = 0;
                  this.codingPos = 0;
                  s = 0;
                  for (; t[this.codingPos] < a; ) {
                    o = 0;
                    if (s)
                      do {
                        o += h = this._getBlackCode();
                      } while (h >= 64);
                    else
                      do {
                        o += h = this._getWhiteCode();
                      } while (h >= 64);
                    this._addPixels(t[this.codingPos] + o, s);
                    s ^= 1;
                  }
                }
                let u = !1;
                this.byteAlign && (this.inputBits &= -8);
                if (this.eoblock || this.row !== this.rows - 1) {
                  o = this._lookBits(12);
                  if (this.eoline)
                    for (; o !== n && 1 !== o; ) {
                      this._eatBits(1);
                      o = this._lookBits(12);
                    }
                  else
                    for (; 0 === o; ) {
                      this._eatBits(1);
                      o = this._lookBits(12);
                    }
                  if (1 === o) {
                    this._eatBits(12);
                    u = !0;
                  } else o === n && (this.eof = !0);
                } else this.rowsDone = !0;
                if (!this.eof && this.encoding > 0 && !this.rowsDone) {
                  this.nextLine2D = !this._lookBits(1);
                  this._eatBits(1);
                }
                if (this.eoblock && u && this.byteAlign) {
                  o = this._lookBits(12);
                  if (1 === o) {
                    this._eatBits(12);
                    if (this.encoding > 0) {
                      this._lookBits(1);
                      this._eatBits(1);
                    }
                    if (this.encoding >= 0)
                      for (c = 0; c < 4; ++c) {
                        o = this._lookBits(12);
                        1 !== o && (0, r.info)("bad rtc code: " + o);
                        this._eatBits(12);
                        if (this.encoding > 0) {
                          this._lookBits(1);
                          this._eatBits(1);
                        }
                      }
                    this.eof = !0;
                  }
                } else if (this.err && this.eoline) {
                  for (;;) {
                    o = this._lookBits(13);
                    if (o === n) {
                      this.eof = !0;
                      return -1;
                    }
                    if (o >> 1 == 1) break;
                    this._eatBits(1);
                  }
                  this._eatBits(12);
                  if (this.encoding > 0) {
                    this._eatBits(1);
                    this.nextLine2D = !(1 & o);
                  }
                }
                t[0] > 0
                  ? (this.outputBits = t[(this.codingPos = 0)])
                  : (this.outputBits = t[(this.codingPos = 1)]);
                this.row++;
              }
              if (this.outputBits >= 8) {
                l = 1 & this.codingPos ? 0 : 255;
                this.outputBits -= 8;
                if (0 === this.outputBits && t[this.codingPos] < a) {
                  this.codingPos++;
                  this.outputBits = t[this.codingPos] - t[this.codingPos - 1];
                }
              } else {
                o = 8;
                l = 0;
                do {
                  if ("number" != typeof this.outputBits)
                    throw new r.FormatError(
                      'Invalid /CCITTFaxDecode data, "outputBits" must be a number.'
                    );
                  if (this.outputBits > o) {
                    l <<= o;
                    1 & this.codingPos || (l |= 255 >> (8 - o));
                    this.outputBits -= o;
                    o = 0;
                  } else {
                    l <<= this.outputBits;
                    1 & this.codingPos || (l |= 255 >> (8 - this.outputBits));
                    o -= this.outputBits;
                    this.outputBits = 0;
                    if (t[this.codingPos] < a) {
                      this.codingPos++;
                      this.outputBits =
                        t[this.codingPos] - t[this.codingPos - 1];
                    } else if (o > 0) {
                      l <<= o;
                      o = 0;
                    }
                  }
                } while (o);
              }
              this.black && (l ^= 255);
              return l;
            }
            _addPixels(e, t) {
              const a = this.codingLine;
              let n = this.codingPos;
              if (e > a[n]) {
                if (e > this.columns) {
                  (0, r.info)("row is wrong length");
                  this.err = !0;
                  e = this.columns;
                }
                (1 & n) ^ t && ++n;
                a[n] = e;
              }
              this.codingPos = n;
            }
            _addPixelsNeg(e, t) {
              const a = this.codingLine;
              let n = this.codingPos;
              if (e > a[n]) {
                if (e > this.columns) {
                  (0, r.info)("row is wrong length");
                  this.err = !0;
                  e = this.columns;
                }
                (1 & n) ^ t && ++n;
                a[n] = e;
              } else if (e < a[n]) {
                if (e < 0) {
                  (0, r.info)("invalid code");
                  this.err = !0;
                  e = 0;
                }
                for (; n > 0 && e < a[n - 1]; ) --n;
                a[n] = e;
              }
              this.codingPos = n;
            }
            _findTableCode(e, t, a, r) {
              const i = r || 0;
              for (let r = e; r <= t; ++r) {
                let e = this._lookBits(r);
                if (e === n) return [!0, 1, !1];
                r < t && (e <<= t - r);
                if (!i || e >= i) {
                  const t = a[e - i];
                  if (t[0] === r) {
                    this._eatBits(r);
                    return [!0, t[1], !0];
                  }
                }
              }
              return [!1, 0, !1];
            }
            _getTwoDimCode() {
              let e,
                t = 0;
              if (this.eoblock) {
                t = this._lookBits(7);
                e = i[t];
                if (e && e[0] > 0) {
                  this._eatBits(e[0]);
                  return e[1];
                }
              } else {
                const e = this._findTableCode(1, 7, i);
                if (e[0] && e[2]) return e[1];
              }
              (0, r.info)("Bad two dim code");
              return n;
            }
            _getWhiteCode() {
              let e,
                t = 0;
              if (this.eoblock) {
                t = this._lookBits(12);
                if (t === n) return 1;
                e = t >> 5 == 0 ? s[t] : o[t >> 3];
                if (e[0] > 0) {
                  this._eatBits(e[0]);
                  return e[1];
                }
              } else {
                let e = this._findTableCode(1, 9, o);
                if (e[0]) return e[1];
                e = this._findTableCode(11, 12, s);
                if (e[0]) return e[1];
              }
              (0, r.info)("bad white code");
              this._eatBits(1);
              return 1;
            }
            _getBlackCode() {
              let e, t;
              if (this.eoblock) {
                e = this._lookBits(13);
                if (e === n) return 1;
                t =
                  e >> 7 == 0
                    ? c[e]
                    : e >> 9 == 0 && e >> 7 != 0
                    ? l[(e >> 1) - 64]
                    : h[e >> 7];
                if (t[0] > 0) {
                  this._eatBits(t[0]);
                  return t[1];
                }
              } else {
                let e = this._findTableCode(2, 6, h);
                if (e[0]) return e[1];
                e = this._findTableCode(7, 12, l, 64);
                if (e[0]) return e[1];
                e = this._findTableCode(10, 13, c);
                if (e[0]) return e[1];
              }
              (0, r.info)("bad black code");
              this._eatBits(1);
              return 1;
            }
            _lookBits(e) {
              let t;
              for (; this.inputBits < e; ) {
                if (-1 === (t = this.source.next()))
                  return 0 === this.inputBits
                    ? n
                    : (this.inputBuf << (e - this.inputBits)) &
                        (65535 >> (16 - e));
                this.inputBuf = (this.inputBuf << 8) | t;
                this.inputBits += 8;
              }
              return (
                (this.inputBuf >> (this.inputBits - e)) & (65535 >> (16 - e))
              );
            }
            _eatBits(e) {
              (this.inputBits -= e) < 0 && (this.inputBits = 0);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.FlateStream = void 0;
          var r = a(17),
            n = a(2);
          const i = new Int32Array([
              16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15,
            ]),
            s = new Int32Array([
              3, 4, 5, 6, 7, 8, 9, 10, 65547, 65549, 65551, 65553, 131091,
              131095, 131099, 131103, 196643, 196651, 196659, 196667, 262211,
              262227, 262243, 262259, 327811, 327843, 327875, 327907, 258, 258,
              258,
            ]),
            o = new Int32Array([
              1, 2, 3, 4, 65541, 65543, 131081, 131085, 196625, 196633, 262177,
              262193, 327745, 327777, 393345, 393409, 459009, 459137, 524801,
              525057, 590849, 591361, 657409, 658433, 724993, 727041, 794625,
              798721, 868353, 876545,
            ]),
            c = [
              new Int32Array([
                459008, 524368, 524304, 524568, 459024, 524400, 524336, 590016,
                459016, 524384, 524320, 589984, 524288, 524416, 524352, 590048,
                459012, 524376, 524312, 589968, 459028, 524408, 524344, 590032,
                459020, 524392, 524328, 59e4, 524296, 524424, 524360, 590064,
                459010, 524372, 524308, 524572, 459026, 524404, 524340, 590024,
                459018, 524388, 524324, 589992, 524292, 524420, 524356, 590056,
                459014, 524380, 524316, 589976, 459030, 524412, 524348, 590040,
                459022, 524396, 524332, 590008, 524300, 524428, 524364, 590072,
                459009, 524370, 524306, 524570, 459025, 524402, 524338, 590020,
                459017, 524386, 524322, 589988, 524290, 524418, 524354, 590052,
                459013, 524378, 524314, 589972, 459029, 524410, 524346, 590036,
                459021, 524394, 524330, 590004, 524298, 524426, 524362, 590068,
                459011, 524374, 524310, 524574, 459027, 524406, 524342, 590028,
                459019, 524390, 524326, 589996, 524294, 524422, 524358, 590060,
                459015, 524382, 524318, 589980, 459031, 524414, 524350, 590044,
                459023, 524398, 524334, 590012, 524302, 524430, 524366, 590076,
                459008, 524369, 524305, 524569, 459024, 524401, 524337, 590018,
                459016, 524385, 524321, 589986, 524289, 524417, 524353, 590050,
                459012, 524377, 524313, 589970, 459028, 524409, 524345, 590034,
                459020, 524393, 524329, 590002, 524297, 524425, 524361, 590066,
                459010, 524373, 524309, 524573, 459026, 524405, 524341, 590026,
                459018, 524389, 524325, 589994, 524293, 524421, 524357, 590058,
                459014, 524381, 524317, 589978, 459030, 524413, 524349, 590042,
                459022, 524397, 524333, 590010, 524301, 524429, 524365, 590074,
                459009, 524371, 524307, 524571, 459025, 524403, 524339, 590022,
                459017, 524387, 524323, 589990, 524291, 524419, 524355, 590054,
                459013, 524379, 524315, 589974, 459029, 524411, 524347, 590038,
                459021, 524395, 524331, 590006, 524299, 524427, 524363, 590070,
                459011, 524375, 524311, 524575, 459027, 524407, 524343, 590030,
                459019, 524391, 524327, 589998, 524295, 524423, 524359, 590062,
                459015, 524383, 524319, 589982, 459031, 524415, 524351, 590046,
                459023, 524399, 524335, 590014, 524303, 524431, 524367, 590078,
                459008, 524368, 524304, 524568, 459024, 524400, 524336, 590017,
                459016, 524384, 524320, 589985, 524288, 524416, 524352, 590049,
                459012, 524376, 524312, 589969, 459028, 524408, 524344, 590033,
                459020, 524392, 524328, 590001, 524296, 524424, 524360, 590065,
                459010, 524372, 524308, 524572, 459026, 524404, 524340, 590025,
                459018, 524388, 524324, 589993, 524292, 524420, 524356, 590057,
                459014, 524380, 524316, 589977, 459030, 524412, 524348, 590041,
                459022, 524396, 524332, 590009, 524300, 524428, 524364, 590073,
                459009, 524370, 524306, 524570, 459025, 524402, 524338, 590021,
                459017, 524386, 524322, 589989, 524290, 524418, 524354, 590053,
                459013, 524378, 524314, 589973, 459029, 524410, 524346, 590037,
                459021, 524394, 524330, 590005, 524298, 524426, 524362, 590069,
                459011, 524374, 524310, 524574, 459027, 524406, 524342, 590029,
                459019, 524390, 524326, 589997, 524294, 524422, 524358, 590061,
                459015, 524382, 524318, 589981, 459031, 524414, 524350, 590045,
                459023, 524398, 524334, 590013, 524302, 524430, 524366, 590077,
                459008, 524369, 524305, 524569, 459024, 524401, 524337, 590019,
                459016, 524385, 524321, 589987, 524289, 524417, 524353, 590051,
                459012, 524377, 524313, 589971, 459028, 524409, 524345, 590035,
                459020, 524393, 524329, 590003, 524297, 524425, 524361, 590067,
                459010, 524373, 524309, 524573, 459026, 524405, 524341, 590027,
                459018, 524389, 524325, 589995, 524293, 524421, 524357, 590059,
                459014, 524381, 524317, 589979, 459030, 524413, 524349, 590043,
                459022, 524397, 524333, 590011, 524301, 524429, 524365, 590075,
                459009, 524371, 524307, 524571, 459025, 524403, 524339, 590023,
                459017, 524387, 524323, 589991, 524291, 524419, 524355, 590055,
                459013, 524379, 524315, 589975, 459029, 524411, 524347, 590039,
                459021, 524395, 524331, 590007, 524299, 524427, 524363, 590071,
                459011, 524375, 524311, 524575, 459027, 524407, 524343, 590031,
                459019, 524391, 524327, 589999, 524295, 524423, 524359, 590063,
                459015, 524383, 524319, 589983, 459031, 524415, 524351, 590047,
                459023, 524399, 524335, 590015, 524303, 524431, 524367, 590079,
              ]),
              9,
            ],
            l = [
              new Int32Array([
                327680, 327696, 327688, 327704, 327684, 327700, 327692, 327708,
                327682, 327698, 327690, 327706, 327686, 327702, 327694, 0,
                327681, 327697, 327689, 327705, 327685, 327701, 327693, 327709,
                327683, 327699, 327691, 327707, 327687, 327703, 327695, 0,
              ]),
              5,
            ];
          class FlateStream extends r.DecodeStream {
            constructor(e, t) {
              super(t);
              this.str = e;
              this.dict = e.dict;
              const a = e.getByte(),
                r = e.getByte();
              if (-1 === a || -1 === r)
                throw new n.FormatError(
                  `Invalid header in flate stream: ${a}, ${r}`
                );
              if (8 != (15 & a))
                throw new n.FormatError(
                  `Unknown compression method in flate stream: ${a}, ${r}`
                );
              if (((a << 8) + r) % 31 != 0)
                throw new n.FormatError(
                  `Bad FCHECK in flate stream: ${a}, ${r}`
                );
              if (32 & r)
                throw new n.FormatError(
                  `FDICT bit set in flate stream: ${a}, ${r}`
                );
              this.codeSize = 0;
              this.codeBuf = 0;
            }
            getBits(e) {
              const t = this.str;
              let a,
                r = this.codeSize,
                i = this.codeBuf;
              for (; r < e; ) {
                if (-1 === (a = t.getByte()))
                  throw new n.FormatError("Bad encoding in flate stream");
                i |= a << r;
                r += 8;
              }
              a = i & ((1 << e) - 1);
              this.codeBuf = i >> e;
              this.codeSize = r -= e;
              return a;
            }
            getCode(e) {
              const t = this.str,
                a = e[0],
                r = e[1];
              let i,
                s = this.codeSize,
                o = this.codeBuf;
              for (; s < r && -1 !== (i = t.getByte()); ) {
                o |= i << s;
                s += 8;
              }
              const c = a[o & ((1 << r) - 1)],
                l = c >> 16,
                h = 65535 & c;
              if (l < 1 || s < l)
                throw new n.FormatError("Bad encoding in flate stream");
              this.codeBuf = o >> l;
              this.codeSize = s - l;
              return h;
            }
            generateHuffmanTable(e) {
              const t = e.length;
              let a,
                r = 0;
              for (a = 0; a < t; ++a) e[a] > r && (r = e[a]);
              const n = 1 << r,
                i = new Int32Array(n);
              for (let s = 1, o = 0, c = 2; s <= r; ++s, o <<= 1, c <<= 1)
                for (let r = 0; r < t; ++r)
                  if (e[r] === s) {
                    let e = 0,
                      t = o;
                    for (a = 0; a < s; ++a) {
                      e = (e << 1) | (1 & t);
                      t >>= 1;
                    }
                    for (a = e; a < n; a += c) i[a] = (s << 16) | r;
                    ++o;
                  }
              return [i, r];
            }
            readBlock() {
              let e, t;
              const a = this.str;
              let r,
                h,
                u = this.getBits(3);
              1 & u && (this.eof = !0);
              u >>= 1;
              if (0 === u) {
                let t;
                if (-1 === (t = a.getByte()))
                  throw new n.FormatError("Bad block header in flate stream");
                let r = t;
                if (-1 === (t = a.getByte()))
                  throw new n.FormatError("Bad block header in flate stream");
                r |= t << 8;
                if (-1 === (t = a.getByte()))
                  throw new n.FormatError("Bad block header in flate stream");
                let i = t;
                if (-1 === (t = a.getByte()))
                  throw new n.FormatError("Bad block header in flate stream");
                i |= t << 8;
                if (i !== (65535 & ~r) && (0 !== r || 0 !== i))
                  throw new n.FormatError(
                    "Bad uncompressed block length in flate stream"
                  );
                this.codeBuf = 0;
                this.codeSize = 0;
                const s = this.bufferLength,
                  o = s + r;
                e = this.ensureBuffer(o);
                this.bufferLength = o;
                if (0 === r) -1 === a.peekByte() && (this.eof = !0);
                else {
                  const t = a.getBytes(r);
                  e.set(t, s);
                  t.length < r && (this.eof = !0);
                }
                return;
              }
              if (1 === u) {
                r = c;
                h = l;
              } else {
                if (2 !== u)
                  throw new n.FormatError("Unknown block type in flate stream");
                {
                  const e = this.getBits(5) + 257,
                    a = this.getBits(5) + 1,
                    n = this.getBits(4) + 4,
                    s = new Uint8Array(i.length);
                  let o;
                  for (o = 0; o < n; ++o) s[i[o]] = this.getBits(3);
                  const c = this.generateHuffmanTable(s);
                  t = 0;
                  o = 0;
                  const l = e + a,
                    u = new Uint8Array(l);
                  let d, f, g;
                  for (; o < l; ) {
                    const e = this.getCode(c);
                    if (16 === e) {
                      d = 2;
                      f = 3;
                      g = t;
                    } else if (17 === e) {
                      d = 3;
                      f = 3;
                      g = t = 0;
                    } else {
                      if (18 !== e) {
                        u[o++] = t = e;
                        continue;
                      }
                      d = 7;
                      f = 11;
                      g = t = 0;
                    }
                    let a = this.getBits(d) + f;
                    for (; a-- > 0; ) u[o++] = g;
                  }
                  r = this.generateHuffmanTable(u.subarray(0, e));
                  h = this.generateHuffmanTable(u.subarray(e, l));
                }
              }
              e = this.buffer;
              let d = e ? e.length : 0,
                f = this.bufferLength;
              for (;;) {
                let a = this.getCode(r);
                if (a < 256) {
                  if (f + 1 >= d) {
                    e = this.ensureBuffer(f + 1);
                    d = e.length;
                  }
                  e[f++] = a;
                  continue;
                }
                if (256 === a) {
                  this.bufferLength = f;
                  return;
                }
                a -= 257;
                a = s[a];
                let n = a >> 16;
                n > 0 && (n = this.getBits(n));
                t = (65535 & a) + n;
                a = this.getCode(h);
                a = o[a];
                n = a >> 16;
                n > 0 && (n = this.getBits(n));
                const i = (65535 & a) + n;
                if (f + t >= d) {
                  e = this.ensureBuffer(f + t);
                  d = e.length;
                }
                for (let a = 0; a < t; ++a, ++f) e[f] = e[f - i];
              }
            }
          }
          t.FlateStream = FlateStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Jbig2Stream = void 0;
          var r = a(5),
            n = a(17),
            i = a(4),
            s = a(23),
            o = a(2);
          class Jbig2Stream extends n.DecodeStream {
            constructor(e, t, a) {
              super(t);
              this.stream = e;
              this.dict = e.dict;
              this.maybeLength = t;
              this.params = a;
            }
            get bytes() {
              return (0, o.shadow)(
                this,
                "bytes",
                this.stream.getBytes(this.maybeLength)
              );
            }
            ensureBuffer(e) {}
            readBlock() {
              if (this.eof) return;
              const e = new s.Jbig2Image(),
                t = [];
              if (this.params instanceof i.Dict) {
                const e = this.params.get("JBIG2Globals");
                if (e instanceof r.BaseStream) {
                  const a = e.getBytes();
                  t.push({ data: a, start: 0, end: a.length });
                }
              }
              t.push({ data: this.bytes, start: 0, end: this.bytes.length });
              const a = e.parseChunks(t),
                n = a.length;
              for (let e = 0; e < n; e++) a[e] ^= 255;
              this.buffer = a;
              this.bufferLength = n;
              this.eof = !0;
            }
          }
          t.Jbig2Stream = Jbig2Stream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Jbig2Image = void 0;
          var r = a(2),
            n = a(3),
            i = a(24),
            s = a(20);
          class Jbig2Error extends r.BaseException {
            constructor(e) {
              super(`JBIG2 error: ${e}`, "Jbig2Error");
            }
          }
          class ContextCache {
            getContexts(e) {
              return e in this ? this[e] : (this[e] = new Int8Array(65536));
            }
          }
          class DecodingContext {
            constructor(e, t, a) {
              this.data = e;
              this.start = t;
              this.end = a;
            }
            get decoder() {
              const e = new i.ArithmeticDecoder(
                this.data,
                this.start,
                this.end
              );
              return (0, r.shadow)(this, "decoder", e);
            }
            get contextCache() {
              const e = new ContextCache();
              return (0, r.shadow)(this, "contextCache", e);
            }
          }
          const o = 2 ** 31 - 1,
            c = -(2 ** 31);
          function decodeInteger(e, t, a) {
            const r = e.getContexts(t);
            let n = 1;
            function readBits(e) {
              let t = 0;
              for (let i = 0; i < e; i++) {
                const e = a.readBit(r, n);
                n = n < 256 ? (n << 1) | e : (511 & ((n << 1) | e)) | 256;
                t = (t << 1) | e;
              }
              return t >>> 0;
            }
            const i = readBits(1),
              s = readBits(1)
                ? readBits(1)
                  ? readBits(1)
                    ? readBits(1)
                      ? readBits(1)
                        ? readBits(32) + 4436
                        : readBits(12) + 340
                      : readBits(8) + 84
                    : readBits(6) + 20
                  : readBits(4) + 4
                : readBits(2);
            let l;
            0 === i ? (l = s) : s > 0 && (l = -s);
            return l >= c && l <= o ? l : null;
          }
          function decodeIAID(e, t, a) {
            const r = e.getContexts("IAID");
            let n = 1;
            for (let e = 0; e < a; e++) {
              n = (n << 1) | t.readBit(r, n);
            }
            return a < 31 ? n & ((1 << a) - 1) : 2147483647 & n;
          }
          const l = [
              "SymbolDictionary",
              null,
              null,
              null,
              "IntermediateTextRegion",
              null,
              "ImmediateTextRegion",
              "ImmediateLosslessTextRegion",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              "PatternDictionary",
              null,
              null,
              null,
              "IntermediateHalftoneRegion",
              null,
              "ImmediateHalftoneRegion",
              "ImmediateLosslessHalftoneRegion",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              "IntermediateGenericRegion",
              null,
              "ImmediateGenericRegion",
              "ImmediateLosslessGenericRegion",
              "IntermediateGenericRefinementRegion",
              null,
              "ImmediateGenericRefinementRegion",
              "ImmediateLosslessGenericRefinementRegion",
              null,
              null,
              null,
              null,
              "PageInformation",
              "EndOfPage",
              "EndOfStripe",
              "EndOfFile",
              "Profiles",
              "Tables",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              "Extension",
            ],
            h = [
              [
                { x: -1, y: -2 },
                { x: 0, y: -2 },
                { x: 1, y: -2 },
                { x: -2, y: -1 },
                { x: -1, y: -1 },
                { x: 0, y: -1 },
                { x: 1, y: -1 },
                { x: 2, y: -1 },
                { x: -4, y: 0 },
                { x: -3, y: 0 },
                { x: -2, y: 0 },
                { x: -1, y: 0 },
              ],
              [
                { x: -1, y: -2 },
                { x: 0, y: -2 },
                { x: 1, y: -2 },
                { x: 2, y: -2 },
                { x: -2, y: -1 },
                { x: -1, y: -1 },
                { x: 0, y: -1 },
                { x: 1, y: -1 },
                { x: 2, y: -1 },
                { x: -3, y: 0 },
                { x: -2, y: 0 },
                { x: -1, y: 0 },
              ],
              [
                { x: -1, y: -2 },
                { x: 0, y: -2 },
                { x: 1, y: -2 },
                { x: -2, y: -1 },
                { x: -1, y: -1 },
                { x: 0, y: -1 },
                { x: 1, y: -1 },
                { x: -2, y: 0 },
                { x: -1, y: 0 },
              ],
              [
                { x: -3, y: -1 },
                { x: -2, y: -1 },
                { x: -1, y: -1 },
                { x: 0, y: -1 },
                { x: 1, y: -1 },
                { x: -4, y: 0 },
                { x: -3, y: 0 },
                { x: -2, y: 0 },
                { x: -1, y: 0 },
              ],
            ],
            u = [
              {
                coding: [
                  { x: 0, y: -1 },
                  { x: 1, y: -1 },
                  { x: -1, y: 0 },
                ],
                reference: [
                  { x: 0, y: -1 },
                  { x: 1, y: -1 },
                  { x: -1, y: 0 },
                  { x: 0, y: 0 },
                  { x: 1, y: 0 },
                  { x: -1, y: 1 },
                  { x: 0, y: 1 },
                  { x: 1, y: 1 },
                ],
              },
              {
                coding: [
                  { x: -1, y: -1 },
                  { x: 0, y: -1 },
                  { x: 1, y: -1 },
                  { x: -1, y: 0 },
                ],
                reference: [
                  { x: 0, y: -1 },
                  { x: -1, y: 0 },
                  { x: 0, y: 0 },
                  { x: 1, y: 0 },
                  { x: 0, y: 1 },
                  { x: 1, y: 1 },
                ],
              },
            ],
            d = [39717, 1941, 229, 405],
            f = [32, 8];
          function decodeBitmap(e, t, a, r, n, i, s, o) {
            if (e) {
              return decodeMMRBitmap(
                new Reader(o.data, o.start, o.end),
                t,
                a,
                !1
              );
            }
            if (
              0 === r &&
              !i &&
              !n &&
              4 === s.length &&
              3 === s[0].x &&
              -1 === s[0].y &&
              -3 === s[1].x &&
              -1 === s[1].y &&
              2 === s[2].x &&
              -2 === s[2].y &&
              -2 === s[3].x &&
              -2 === s[3].y
            )
              return (function decodeBitmapTemplate0(e, t, a) {
                const r = a.decoder,
                  n = a.contextCache.getContexts("GB"),
                  i = [];
                let s, o, c, l, h, u, d;
                for (o = 0; o < t; o++) {
                  h = i[o] = new Uint8Array(e);
                  u = o < 1 ? h : i[o - 1];
                  d = o < 2 ? h : i[o - 2];
                  s =
                    (d[0] << 13) |
                    (d[1] << 12) |
                    (d[2] << 11) |
                    (u[0] << 7) |
                    (u[1] << 6) |
                    (u[2] << 5) |
                    (u[3] << 4);
                  for (c = 0; c < e; c++) {
                    h[c] = l = r.readBit(n, s);
                    s =
                      ((31735 & s) << 1) |
                      (c + 3 < e ? d[c + 3] << 11 : 0) |
                      (c + 4 < e ? u[c + 4] << 4 : 0) |
                      l;
                  }
                }
                return i;
              })(t, a, o);
            const c = !!i,
              l = h[r].concat(s);
            l.sort(function (e, t) {
              return e.y - t.y || e.x - t.x;
            });
            const u = l.length,
              f = new Int8Array(u),
              g = new Int8Array(u),
              p = [];
            let m,
              b,
              y = 0,
              w = 0,
              S = 0,
              x = 0;
            for (b = 0; b < u; b++) {
              f[b] = l[b].x;
              g[b] = l[b].y;
              w = Math.min(w, l[b].x);
              S = Math.max(S, l[b].x);
              x = Math.min(x, l[b].y);
              b < u - 1 && l[b].y === l[b + 1].y && l[b].x === l[b + 1].x - 1
                ? (y |= 1 << (u - 1 - b))
                : p.push(b);
            }
            const C = p.length,
              k = new Int8Array(C),
              v = new Int8Array(C),
              F = new Uint16Array(C);
            for (m = 0; m < C; m++) {
              b = p[m];
              k[m] = l[b].x;
              v[m] = l[b].y;
              F[m] = 1 << (u - 1 - b);
            }
            const O = -w,
              T = -x,
              M = t - S,
              E = d[r];
            let D = new Uint8Array(t);
            const N = [],
              R = o.decoder,
              L = o.contextCache.getContexts("GB");
            let j,
              $,
              _,
              U,
              X,
              H = 0,
              q = 0;
            for (let e = 0; e < a; e++) {
              if (n) {
                H ^= R.readBit(L, E);
                if (H) {
                  N.push(D);
                  continue;
                }
              }
              D = new Uint8Array(D);
              N.push(D);
              for (j = 0; j < t; j++) {
                if (c && i[e][j]) {
                  D[j] = 0;
                  continue;
                }
                if (j >= O && j < M && e >= T) {
                  q = (q << 1) & y;
                  for (b = 0; b < C; b++) {
                    $ = e + v[b];
                    _ = j + k[b];
                    U = N[$][_];
                    if (U) {
                      U = F[b];
                      q |= U;
                    }
                  }
                } else {
                  q = 0;
                  X = u - 1;
                  for (b = 0; b < u; b++, X--) {
                    _ = j + f[b];
                    if (_ >= 0 && _ < t) {
                      $ = e + g[b];
                      if ($ >= 0) {
                        U = N[$][_];
                        U && (q |= U << X);
                      }
                    }
                  }
                }
                const a = R.readBit(L, q);
                D[j] = a;
              }
            }
            return N;
          }
          function decodeRefinement(e, t, a, r, n, i, s, o, c) {
            let l = u[a].coding;
            0 === a && (l = l.concat([o[0]]));
            const h = l.length,
              d = new Int32Array(h),
              g = new Int32Array(h);
            let p;
            for (p = 0; p < h; p++) {
              d[p] = l[p].x;
              g[p] = l[p].y;
            }
            let m = u[a].reference;
            0 === a && (m = m.concat([o[1]]));
            const b = m.length,
              y = new Int32Array(b),
              w = new Int32Array(b);
            for (p = 0; p < b; p++) {
              y[p] = m[p].x;
              w[p] = m[p].y;
            }
            const S = r[0].length,
              x = r.length,
              C = f[a],
              k = [],
              v = c.decoder,
              F = c.contextCache.getContexts("GR");
            let O = 0;
            for (let a = 0; a < t; a++) {
              if (s) {
                O ^= v.readBit(F, C);
                if (O) throw new Jbig2Error("prediction is not supported");
              }
              const t = new Uint8Array(e);
              k.push(t);
              for (let s = 0; s < e; s++) {
                let o,
                  c,
                  l = 0;
                for (p = 0; p < h; p++) {
                  o = a + g[p];
                  c = s + d[p];
                  o < 0 || c < 0 || c >= e
                    ? (l <<= 1)
                    : (l = (l << 1) | k[o][c]);
                }
                for (p = 0; p < b; p++) {
                  o = a + w[p] - i;
                  c = s + y[p] - n;
                  o < 0 || o >= x || c < 0 || c >= S
                    ? (l <<= 1)
                    : (l = (l << 1) | r[o][c]);
                }
                const u = v.readBit(F, l);
                t[s] = u;
              }
            }
            return k;
          }
          function decodeTextRegion(
            e,
            t,
            a,
            r,
            n,
            i,
            s,
            o,
            c,
            l,
            h,
            u,
            d,
            f,
            g,
            p,
            m,
            b,
            y
          ) {
            if (e && t)
              throw new Jbig2Error("refinement with Huffman is not supported");
            const w = [];
            let S, x;
            for (S = 0; S < r; S++) {
              x = new Uint8Array(a);
              if (n) for (let e = 0; e < a; e++) x[e] = n;
              w.push(x);
            }
            const C = m.decoder,
              k = m.contextCache;
            let v = e ? -f.tableDeltaT.decode(y) : -decodeInteger(k, "IADT", C),
              F = 0;
            S = 0;
            for (; S < i; ) {
              v += e ? f.tableDeltaT.decode(y) : decodeInteger(k, "IADT", C);
              F += e ? f.tableFirstS.decode(y) : decodeInteger(k, "IAFS", C);
              let r = F;
              for (;;) {
                let n = 0;
                s > 1 && (n = e ? y.readBits(b) : decodeInteger(k, "IAIT", C));
                const i = s * v + n,
                  F = e ? f.symbolIDTable.decode(y) : decodeIAID(k, C, c),
                  O = t && (e ? y.readBit() : decodeInteger(k, "IARI", C));
                let T = o[F],
                  M = T[0].length,
                  E = T.length;
                if (O) {
                  const e = decodeInteger(k, "IARDW", C),
                    t = decodeInteger(k, "IARDH", C);
                  M += e;
                  E += t;
                  T = decodeRefinement(
                    M,
                    E,
                    g,
                    T,
                    (e >> 1) + decodeInteger(k, "IARDX", C),
                    (t >> 1) + decodeInteger(k, "IARDY", C),
                    !1,
                    p,
                    m
                  );
                }
                const D = i - (1 & u ? 0 : E - 1),
                  N = r - (2 & u ? M - 1 : 0);
                let R, L, j;
                if (l) {
                  for (R = 0; R < E; R++) {
                    x = w[N + R];
                    if (!x) continue;
                    j = T[R];
                    const e = Math.min(a - D, M);
                    switch (d) {
                      case 0:
                        for (L = 0; L < e; L++) x[D + L] |= j[L];
                        break;
                      case 2:
                        for (L = 0; L < e; L++) x[D + L] ^= j[L];
                        break;
                      default:
                        throw new Jbig2Error(`operator ${d} is not supported`);
                    }
                  }
                  r += E - 1;
                } else {
                  for (L = 0; L < E; L++) {
                    x = w[D + L];
                    if (x) {
                      j = T[L];
                      switch (d) {
                        case 0:
                          for (R = 0; R < M; R++) x[N + R] |= j[R];
                          break;
                        case 2:
                          for (R = 0; R < M; R++) x[N + R] ^= j[R];
                          break;
                        default:
                          throw new Jbig2Error(
                            `operator ${d} is not supported`
                          );
                      }
                    }
                  }
                  r += M - 1;
                }
                S++;
                const $ = e
                  ? f.tableDeltaS.decode(y)
                  : decodeInteger(k, "IADS", C);
                if (null === $) break;
                r += $ + h;
              }
            }
            return w;
          }
          function readSegmentHeader(e, t) {
            const a = {};
            a.number = (0, n.readUint32)(e, t);
            const r = e[t + 4],
              i = 63 & r;
            if (!l[i]) throw new Jbig2Error("invalid segment type: " + i);
            a.type = i;
            a.typeName = l[i];
            a.deferredNonRetain = !!(128 & r);
            const s = !!(64 & r),
              o = e[t + 5];
            let c = (o >> 5) & 7;
            const h = [31 & o];
            let u = t + 6;
            if (7 === o) {
              c = 536870911 & (0, n.readUint32)(e, u - 1);
              u += 3;
              let t = (c + 7) >> 3;
              h[0] = e[u++];
              for (; --t > 0; ) h.push(e[u++]);
            } else if (5 === o || 6 === o)
              throw new Jbig2Error("invalid referred-to flags");
            a.retainBits = h;
            let d = 4;
            a.number <= 256 ? (d = 1) : a.number <= 65536 && (d = 2);
            const f = [];
            let p, m;
            for (p = 0; p < c; p++) {
              let t;
              t =
                1 === d
                  ? e[u]
                  : 2 === d
                  ? (0, n.readUint16)(e, u)
                  : (0, n.readUint32)(e, u);
              f.push(t);
              u += d;
            }
            a.referredTo = f;
            if (s) {
              a.pageAssociation = (0, n.readUint32)(e, u);
              u += 4;
            } else a.pageAssociation = e[u++];
            a.length = (0, n.readUint32)(e, u);
            u += 4;
            if (4294967295 === a.length) {
              if (38 !== i)
                throw new Jbig2Error("invalid unknown segment length");
              {
                const t = readRegionSegmentInformation(e, u),
                  r = !!(1 & e[u + g]),
                  n = 6,
                  i = new Uint8Array(n);
                if (!r) {
                  i[0] = 255;
                  i[1] = 172;
                }
                i[2] = (t.height >>> 24) & 255;
                i[3] = (t.height >> 16) & 255;
                i[4] = (t.height >> 8) & 255;
                i[5] = 255 & t.height;
                for (p = u, m = e.length; p < m; p++) {
                  let t = 0;
                  for (; t < n && i[t] === e[p + t]; ) t++;
                  if (t === n) {
                    a.length = p + n;
                    break;
                  }
                }
                if (4294967295 === a.length)
                  throw new Jbig2Error("segment end was not found");
              }
            }
            a.headerEnd = u;
            return a;
          }
          function readSegments(e, t, a, r) {
            const n = [];
            let i = a;
            for (; i < r; ) {
              const a = readSegmentHeader(t, i);
              i = a.headerEnd;
              const r = { header: a, data: t };
              if (!e.randomAccess) {
                r.start = i;
                i += a.length;
                r.end = i;
              }
              n.push(r);
              if (51 === a.type) break;
            }
            if (e.randomAccess)
              for (let e = 0, t = n.length; e < t; e++) {
                n[e].start = i;
                i += n[e].header.length;
                n[e].end = i;
              }
            return n;
          }
          function readRegionSegmentInformation(e, t) {
            return {
              width: (0, n.readUint32)(e, t),
              height: (0, n.readUint32)(e, t + 4),
              x: (0, n.readUint32)(e, t + 8),
              y: (0, n.readUint32)(e, t + 12),
              combinationOperator: 7 & e[t + 16],
            };
          }
          const g = 17;
          function processSegment(e, t) {
            const a = e.header,
              r = e.data,
              i = e.end;
            let s,
              o,
              c,
              l,
              h = e.start;
            switch (a.type) {
              case 0:
                const e = {},
                  t = (0, n.readUint16)(r, h);
                e.huffman = !!(1 & t);
                e.refinement = !!(2 & t);
                e.huffmanDHSelector = (t >> 2) & 3;
                e.huffmanDWSelector = (t >> 4) & 3;
                e.bitmapSizeSelector = (t >> 6) & 1;
                e.aggregationInstancesSelector = (t >> 7) & 1;
                e.bitmapCodingContextUsed = !!(256 & t);
                e.bitmapCodingContextRetained = !!(512 & t);
                e.template = (t >> 10) & 3;
                e.refinementTemplate = (t >> 12) & 1;
                h += 2;
                if (!e.huffman) {
                  l = 0 === e.template ? 4 : 1;
                  o = [];
                  for (c = 0; c < l; c++) {
                    o.push({
                      x: (0, n.readInt8)(r, h),
                      y: (0, n.readInt8)(r, h + 1),
                    });
                    h += 2;
                  }
                  e.at = o;
                }
                if (e.refinement && !e.refinementTemplate) {
                  o = [];
                  for (c = 0; c < 2; c++) {
                    o.push({
                      x: (0, n.readInt8)(r, h),
                      y: (0, n.readInt8)(r, h + 1),
                    });
                    h += 2;
                  }
                  e.refinementAt = o;
                }
                e.numberOfExportedSymbols = (0, n.readUint32)(r, h);
                h += 4;
                e.numberOfNewSymbols = (0, n.readUint32)(r, h);
                h += 4;
                s = [e, a.number, a.referredTo, r, h, i];
                break;
              case 6:
              case 7:
                const u = {};
                u.info = readRegionSegmentInformation(r, h);
                h += g;
                const d = (0, n.readUint16)(r, h);
                h += 2;
                u.huffman = !!(1 & d);
                u.refinement = !!(2 & d);
                u.logStripSize = (d >> 2) & 3;
                u.stripSize = 1 << u.logStripSize;
                u.referenceCorner = (d >> 4) & 3;
                u.transposed = !!(64 & d);
                u.combinationOperator = (d >> 7) & 3;
                u.defaultPixelValue = (d >> 9) & 1;
                u.dsOffset = (d << 17) >> 27;
                u.refinementTemplate = (d >> 15) & 1;
                if (u.huffman) {
                  const e = (0, n.readUint16)(r, h);
                  h += 2;
                  u.huffmanFS = 3 & e;
                  u.huffmanDS = (e >> 2) & 3;
                  u.huffmanDT = (e >> 4) & 3;
                  u.huffmanRefinementDW = (e >> 6) & 3;
                  u.huffmanRefinementDH = (e >> 8) & 3;
                  u.huffmanRefinementDX = (e >> 10) & 3;
                  u.huffmanRefinementDY = (e >> 12) & 3;
                  u.huffmanRefinementSizeSelector = !!(16384 & e);
                }
                if (u.refinement && !u.refinementTemplate) {
                  o = [];
                  for (c = 0; c < 2; c++) {
                    o.push({
                      x: (0, n.readInt8)(r, h),
                      y: (0, n.readInt8)(r, h + 1),
                    });
                    h += 2;
                  }
                  u.refinementAt = o;
                }
                u.numberOfSymbolInstances = (0, n.readUint32)(r, h);
                h += 4;
                s = [u, a.referredTo, r, h, i];
                break;
              case 16:
                const f = {},
                  p = r[h++];
                f.mmr = !!(1 & p);
                f.template = (p >> 1) & 3;
                f.patternWidth = r[h++];
                f.patternHeight = r[h++];
                f.maxPatternIndex = (0, n.readUint32)(r, h);
                h += 4;
                s = [f, a.number, r, h, i];
                break;
              case 22:
              case 23:
                const m = {};
                m.info = readRegionSegmentInformation(r, h);
                h += g;
                const b = r[h++];
                m.mmr = !!(1 & b);
                m.template = (b >> 1) & 3;
                m.enableSkip = !!(8 & b);
                m.combinationOperator = (b >> 4) & 7;
                m.defaultPixelValue = (b >> 7) & 1;
                m.gridWidth = (0, n.readUint32)(r, h);
                h += 4;
                m.gridHeight = (0, n.readUint32)(r, h);
                h += 4;
                m.gridOffsetX = 4294967295 & (0, n.readUint32)(r, h);
                h += 4;
                m.gridOffsetY = 4294967295 & (0, n.readUint32)(r, h);
                h += 4;
                m.gridVectorX = (0, n.readUint16)(r, h);
                h += 2;
                m.gridVectorY = (0, n.readUint16)(r, h);
                h += 2;
                s = [m, a.referredTo, r, h, i];
                break;
              case 38:
              case 39:
                const y = {};
                y.info = readRegionSegmentInformation(r, h);
                h += g;
                const w = r[h++];
                y.mmr = !!(1 & w);
                y.template = (w >> 1) & 3;
                y.prediction = !!(8 & w);
                if (!y.mmr) {
                  l = 0 === y.template ? 4 : 1;
                  o = [];
                  for (c = 0; c < l; c++) {
                    o.push({
                      x: (0, n.readInt8)(r, h),
                      y: (0, n.readInt8)(r, h + 1),
                    });
                    h += 2;
                  }
                  y.at = o;
                }
                s = [y, r, h, i];
                break;
              case 48:
                const S = {
                  width: (0, n.readUint32)(r, h),
                  height: (0, n.readUint32)(r, h + 4),
                  resolutionX: (0, n.readUint32)(r, h + 8),
                  resolutionY: (0, n.readUint32)(r, h + 12),
                };
                4294967295 === S.height && delete S.height;
                const x = r[h + 16];
                (0, n.readUint16)(r, h + 17);
                S.lossless = !!(1 & x);
                S.refinement = !!(2 & x);
                S.defaultPixelValue = (x >> 2) & 1;
                S.combinationOperator = (x >> 3) & 3;
                S.requiresBuffer = !!(32 & x);
                S.combinationOperatorOverride = !!(64 & x);
                s = [S];
                break;
              case 49:
              case 50:
              case 51:
              case 62:
                break;
              case 53:
                s = [a.number, r, h, i];
                break;
              default:
                throw new Jbig2Error(
                  `segment type ${a.typeName}(${a.type}) is not implemented`
                );
            }
            const u = "on" + a.typeName;
            u in t && t[u].apply(t, s);
          }
          function processSegments(e, t) {
            for (let a = 0, r = e.length; a < r; a++) processSegment(e[a], t);
          }
          class SimpleSegmentVisitor {
            onPageInformation(e) {
              this.currentPageInfo = e;
              const t = (e.width + 7) >> 3,
                a = new Uint8ClampedArray(t * e.height);
              e.defaultPixelValue && a.fill(255);
              this.buffer = a;
            }
            drawBitmap(e, t) {
              const a = this.currentPageInfo,
                r = e.width,
                n = e.height,
                i = (a.width + 7) >> 3,
                s = a.combinationOperatorOverride
                  ? e.combinationOperator
                  : a.combinationOperator,
                o = this.buffer,
                c = 128 >> (7 & e.x);
              let l,
                h,
                u,
                d,
                f = e.y * i + (e.x >> 3);
              switch (s) {
                case 0:
                  for (l = 0; l < n; l++) {
                    u = c;
                    d = f;
                    for (h = 0; h < r; h++) {
                      t[l][h] && (o[d] |= u);
                      u >>= 1;
                      if (!u) {
                        u = 128;
                        d++;
                      }
                    }
                    f += i;
                  }
                  break;
                case 2:
                  for (l = 0; l < n; l++) {
                    u = c;
                    d = f;
                    for (h = 0; h < r; h++) {
                      t[l][h] && (o[d] ^= u);
                      u >>= 1;
                      if (!u) {
                        u = 128;
                        d++;
                      }
                    }
                    f += i;
                  }
                  break;
                default:
                  throw new Jbig2Error(`operator ${s} is not supported`);
              }
            }
            onImmediateGenericRegion(e, t, a, r) {
              const n = e.info,
                i = new DecodingContext(t, a, r),
                s = decodeBitmap(
                  e.mmr,
                  n.width,
                  n.height,
                  e.template,
                  e.prediction,
                  null,
                  e.at,
                  i
                );
              this.drawBitmap(n, s);
            }
            onImmediateLosslessGenericRegion() {
              this.onImmediateGenericRegion(...arguments);
            }
            onSymbolDictionary(e, t, a, r, i, s) {
              let o, c;
              if (e.huffman) {
                o = (function getSymbolDictionaryHuffmanTables(e, t, a) {
                  let r,
                    n,
                    i,
                    s,
                    o = 0;
                  switch (e.huffmanDHSelector) {
                    case 0:
                    case 1:
                      r = getStandardTable(e.huffmanDHSelector + 4);
                      break;
                    case 3:
                      r = getCustomHuffmanTable(o, t, a);
                      o++;
                      break;
                    default:
                      throw new Jbig2Error("invalid Huffman DH selector");
                  }
                  switch (e.huffmanDWSelector) {
                    case 0:
                    case 1:
                      n = getStandardTable(e.huffmanDWSelector + 2);
                      break;
                    case 3:
                      n = getCustomHuffmanTable(o, t, a);
                      o++;
                      break;
                    default:
                      throw new Jbig2Error("invalid Huffman DW selector");
                  }
                  if (e.bitmapSizeSelector) {
                    i = getCustomHuffmanTable(o, t, a);
                    o++;
                  } else i = getStandardTable(1);
                  s = e.aggregationInstancesSelector
                    ? getCustomHuffmanTable(o, t, a)
                    : getStandardTable(1);
                  return {
                    tableDeltaHeight: r,
                    tableDeltaWidth: n,
                    tableBitmapSize: i,
                    tableAggregateInstances: s,
                  };
                })(e, a, this.customTables);
                c = new Reader(r, i, s);
              }
              let l = this.symbols;
              l || (this.symbols = l = {});
              const h = [];
              for (const e of a) {
                const t = l[e];
                t && h.push(...t);
              }
              const u = new DecodingContext(r, i, s);
              l[t] = (function decodeSymbolDictionary(
                e,
                t,
                a,
                r,
                i,
                s,
                o,
                c,
                l,
                h,
                u,
                d
              ) {
                if (e && t)
                  throw new Jbig2Error(
                    "symbol refinement with Huffman is not supported"
                  );
                const f = [];
                let g = 0,
                  p = (0, n.log2)(a.length + r);
                const m = u.decoder,
                  b = u.contextCache;
                let y, w;
                if (e) {
                  y = getStandardTable(1);
                  w = [];
                  p = Math.max(p, 1);
                }
                for (; f.length < r; ) {
                  g += e
                    ? s.tableDeltaHeight.decode(d)
                    : decodeInteger(b, "IADH", m);
                  let r = 0,
                    n = 0;
                  const i = e ? w.length : 0;
                  for (;;) {
                    const i = e
                      ? s.tableDeltaWidth.decode(d)
                      : decodeInteger(b, "IADW", m);
                    if (null === i) break;
                    r += i;
                    n += r;
                    let y;
                    if (t) {
                      const n = decodeInteger(b, "IAAI", m);
                      if (n > 1)
                        y = decodeTextRegion(
                          e,
                          t,
                          r,
                          g,
                          0,
                          n,
                          1,
                          a.concat(f),
                          p,
                          0,
                          0,
                          1,
                          0,
                          s,
                          l,
                          h,
                          u,
                          0,
                          d
                        );
                      else {
                        const e = decodeIAID(b, m, p),
                          t = decodeInteger(b, "IARDX", m),
                          n = decodeInteger(b, "IARDY", m);
                        y = decodeRefinement(
                          r,
                          g,
                          l,
                          e < a.length ? a[e] : f[e - a.length],
                          t,
                          n,
                          !1,
                          h,
                          u
                        );
                      }
                      f.push(y);
                    } else if (e) w.push(r);
                    else {
                      y = decodeBitmap(!1, r, g, o, !1, null, c, u);
                      f.push(y);
                    }
                  }
                  if (e && !t) {
                    const e = s.tableBitmapSize.decode(d);
                    d.byteAlign();
                    let t;
                    if (0 === e) t = readUncompressedBitmap(d, n, g);
                    else {
                      const a = d.end,
                        r = d.position + e;
                      d.end = r;
                      t = decodeMMRBitmap(d, n, g, !1);
                      d.end = a;
                      d.position = r;
                    }
                    const a = w.length;
                    if (i === a - 1) f.push(t);
                    else {
                      let e,
                        r,
                        n,
                        s,
                        o,
                        c = 0;
                      for (e = i; e < a; e++) {
                        s = w[e];
                        n = c + s;
                        o = [];
                        for (r = 0; r < g; r++) o.push(t[r].subarray(c, n));
                        f.push(o);
                        c = n;
                      }
                    }
                  }
                }
                const S = [],
                  x = [];
                let C,
                  k,
                  v = !1;
                const F = a.length + r;
                for (; x.length < F; ) {
                  let t = e ? y.decode(d) : decodeInteger(b, "IAEX", m);
                  for (; t--; ) x.push(v);
                  v = !v;
                }
                for (C = 0, k = a.length; C < k; C++) x[C] && S.push(a[C]);
                for (let e = 0; e < r; C++, e++) x[C] && S.push(f[e]);
                return S;
              })(
                e.huffman,
                e.refinement,
                h,
                e.numberOfNewSymbols,
                e.numberOfExportedSymbols,
                o,
                e.template,
                e.at,
                e.refinementTemplate,
                e.refinementAt,
                u,
                c
              );
            }
            onImmediateTextRegion(e, t, a, r, i) {
              const s = e.info;
              let o, c;
              const l = this.symbols,
                h = [];
              for (const e of t) {
                const t = l[e];
                t && h.push(...t);
              }
              const u = (0, n.log2)(h.length);
              if (e.huffman) {
                c = new Reader(a, r, i);
                o = (function getTextRegionHuffmanTables(e, t, a, r, n) {
                  const i = [];
                  for (let e = 0; e <= 34; e++) {
                    const t = n.readBits(4);
                    i.push(new HuffmanLine([e, t, 0, 0]));
                  }
                  const s = new HuffmanTable(i, !1);
                  i.length = 0;
                  for (let e = 0; e < r; ) {
                    const t = s.decode(n);
                    if (t >= 32) {
                      let a, r, s;
                      switch (t) {
                        case 32:
                          if (0 === e)
                            throw new Jbig2Error(
                              "no previous value in symbol ID table"
                            );
                          r = n.readBits(2) + 3;
                          a = i[e - 1].prefixLength;
                          break;
                        case 33:
                          r = n.readBits(3) + 3;
                          a = 0;
                          break;
                        case 34:
                          r = n.readBits(7) + 11;
                          a = 0;
                          break;
                        default:
                          throw new Jbig2Error(
                            "invalid code length in symbol ID table"
                          );
                      }
                      for (s = 0; s < r; s++) {
                        i.push(new HuffmanLine([e, a, 0, 0]));
                        e++;
                      }
                    } else {
                      i.push(new HuffmanLine([e, t, 0, 0]));
                      e++;
                    }
                  }
                  n.byteAlign();
                  const o = new HuffmanTable(i, !1);
                  let c,
                    l,
                    h,
                    u = 0;
                  switch (e.huffmanFS) {
                    case 0:
                    case 1:
                      c = getStandardTable(e.huffmanFS + 6);
                      break;
                    case 3:
                      c = getCustomHuffmanTable(u, t, a);
                      u++;
                      break;
                    default:
                      throw new Jbig2Error("invalid Huffman FS selector");
                  }
                  switch (e.huffmanDS) {
                    case 0:
                    case 1:
                    case 2:
                      l = getStandardTable(e.huffmanDS + 8);
                      break;
                    case 3:
                      l = getCustomHuffmanTable(u, t, a);
                      u++;
                      break;
                    default:
                      throw new Jbig2Error("invalid Huffman DS selector");
                  }
                  switch (e.huffmanDT) {
                    case 0:
                    case 1:
                    case 2:
                      h = getStandardTable(e.huffmanDT + 11);
                      break;
                    case 3:
                      h = getCustomHuffmanTable(u, t, a);
                      u++;
                      break;
                    default:
                      throw new Jbig2Error("invalid Huffman DT selector");
                  }
                  if (e.refinement)
                    throw new Jbig2Error(
                      "refinement with Huffman is not supported"
                    );
                  return {
                    symbolIDTable: o,
                    tableFirstS: c,
                    tableDeltaS: l,
                    tableDeltaT: h,
                  };
                })(e, t, this.customTables, h.length, c);
              }
              const d = new DecodingContext(a, r, i),
                f = decodeTextRegion(
                  e.huffman,
                  e.refinement,
                  s.width,
                  s.height,
                  e.defaultPixelValue,
                  e.numberOfSymbolInstances,
                  e.stripSize,
                  h,
                  u,
                  e.transposed,
                  e.dsOffset,
                  e.referenceCorner,
                  e.combinationOperator,
                  o,
                  e.refinementTemplate,
                  e.refinementAt,
                  d,
                  e.logStripSize,
                  c
                );
              this.drawBitmap(s, f);
            }
            onImmediateLosslessTextRegion() {
              this.onImmediateTextRegion(...arguments);
            }
            onPatternDictionary(e, t, a, r, n) {
              let i = this.patterns;
              i || (this.patterns = i = {});
              const s = new DecodingContext(a, r, n);
              i[t] = (function decodePatternDictionary(e, t, a, r, n, i) {
                const s = [];
                if (!e) {
                  s.push({ x: -t, y: 0 });
                  0 === n &&
                    s.push({ x: -3, y: -1 }, { x: 2, y: -2 }, { x: -2, y: -2 });
                }
                const o = decodeBitmap(e, (r + 1) * t, a, n, !1, null, s, i),
                  c = [];
                for (let e = 0; e <= r; e++) {
                  const r = [],
                    n = t * e,
                    i = n + t;
                  for (let e = 0; e < a; e++) r.push(o[e].subarray(n, i));
                  c.push(r);
                }
                return c;
              })(
                e.mmr,
                e.patternWidth,
                e.patternHeight,
                e.maxPatternIndex,
                e.template,
                s
              );
            }
            onImmediateHalftoneRegion(e, t, a, r, i) {
              const s = this.patterns[t[0]],
                o = e.info,
                c = new DecodingContext(a, r, i),
                l = (function decodeHalftoneRegion(
                  e,
                  t,
                  a,
                  r,
                  i,
                  s,
                  o,
                  c,
                  l,
                  h,
                  u,
                  d,
                  f,
                  g,
                  p
                ) {
                  if (o) throw new Jbig2Error("skip is not supported");
                  if (0 !== c)
                    throw new Jbig2Error(
                      `operator "${c}" is not supported in halftone region`
                    );
                  const m = [];
                  let b, y, w;
                  for (b = 0; b < i; b++) {
                    w = new Uint8Array(r);
                    if (s) for (y = 0; y < r; y++) w[y] = s;
                    m.push(w);
                  }
                  const S = t.length,
                    x = t[0],
                    C = x[0].length,
                    k = x.length,
                    v = (0, n.log2)(S),
                    F = [];
                  if (!e) {
                    F.push({ x: a <= 1 ? 3 : 2, y: -1 });
                    0 === a &&
                      F.push(
                        { x: -3, y: -1 },
                        { x: 2, y: -2 },
                        { x: -2, y: -2 }
                      );
                  }
                  const O = [];
                  let T, M, E, D, N, R, L, j, $, _, U;
                  e && (T = new Reader(p.data, p.start, p.end));
                  for (b = v - 1; b >= 0; b--) {
                    M = e
                      ? decodeMMRBitmap(T, l, h, !0)
                      : decodeBitmap(!1, l, h, a, !1, null, F, p);
                    O[b] = M;
                  }
                  for (E = 0; E < h; E++)
                    for (D = 0; D < l; D++) {
                      N = 0;
                      R = 0;
                      for (y = v - 1; y >= 0; y--) {
                        N ^= O[y][E][D];
                        R |= N << y;
                      }
                      L = t[R];
                      j = (u + E * g + D * f) >> 8;
                      $ = (d + E * f - D * g) >> 8;
                      if (j >= 0 && j + C <= r && $ >= 0 && $ + k <= i)
                        for (b = 0; b < k; b++) {
                          U = m[$ + b];
                          _ = L[b];
                          for (y = 0; y < C; y++) U[j + y] |= _[y];
                        }
                      else {
                        let e, t;
                        for (b = 0; b < k; b++) {
                          t = $ + b;
                          if (!(t < 0 || t >= i)) {
                            U = m[t];
                            _ = L[b];
                            for (y = 0; y < C; y++) {
                              e = j + y;
                              e >= 0 && e < r && (U[e] |= _[y]);
                            }
                          }
                        }
                      }
                    }
                  return m;
                })(
                  e.mmr,
                  s,
                  e.template,
                  o.width,
                  o.height,
                  e.defaultPixelValue,
                  e.enableSkip,
                  e.combinationOperator,
                  e.gridWidth,
                  e.gridHeight,
                  e.gridOffsetX,
                  e.gridOffsetY,
                  e.gridVectorX,
                  e.gridVectorY,
                  c
                );
              this.drawBitmap(o, l);
            }
            onImmediateLosslessHalftoneRegion() {
              this.onImmediateHalftoneRegion(...arguments);
            }
            onTables(e, t, a, r) {
              let i = this.customTables;
              i || (this.customTables = i = {});
              i[e] = (function decodeTablesSegment(e, t, a) {
                const r = e[t],
                  i = 4294967295 & (0, n.readUint32)(e, t + 1),
                  s = 4294967295 & (0, n.readUint32)(e, t + 5),
                  o = new Reader(e, t + 9, a),
                  c = 1 + ((r >> 1) & 7),
                  l = 1 + ((r >> 4) & 7),
                  h = [];
                let u,
                  d,
                  f = i;
                do {
                  u = o.readBits(c);
                  d = o.readBits(l);
                  h.push(new HuffmanLine([f, u, d, 0]));
                  f += 1 << d;
                } while (f < s);
                u = o.readBits(c);
                h.push(new HuffmanLine([i - 1, u, 32, 0, "lower"]));
                u = o.readBits(c);
                h.push(new HuffmanLine([s, u, 32, 0]));
                if (1 & r) {
                  u = o.readBits(c);
                  h.push(new HuffmanLine([u, 0]));
                }
                return new HuffmanTable(h, !1);
              })(t, a, r);
            }
          }
          class HuffmanLine {
            constructor(e) {
              if (2 === e.length) {
                this.isOOB = !0;
                this.rangeLow = 0;
                this.prefixLength = e[0];
                this.rangeLength = 0;
                this.prefixCode = e[1];
                this.isLowerRange = !1;
              } else {
                this.isOOB = !1;
                this.rangeLow = e[0];
                this.prefixLength = e[1];
                this.rangeLength = e[2];
                this.prefixCode = e[3];
                this.isLowerRange = "lower" === e[4];
              }
            }
          }
          class HuffmanTreeNode {
            constructor(e) {
              this.children = [];
              if (e) {
                this.isLeaf = !0;
                this.rangeLength = e.rangeLength;
                this.rangeLow = e.rangeLow;
                this.isLowerRange = e.isLowerRange;
                this.isOOB = e.isOOB;
              } else this.isLeaf = !1;
            }
            buildTree(e, t) {
              const a = (e.prefixCode >> t) & 1;
              if (t <= 0) this.children[a] = new HuffmanTreeNode(e);
              else {
                let r = this.children[a];
                r || (this.children[a] = r = new HuffmanTreeNode(null));
                r.buildTree(e, t - 1);
              }
            }
            decodeNode(e) {
              if (this.isLeaf) {
                if (this.isOOB) return null;
                const t = e.readBits(this.rangeLength);
                return this.rangeLow + (this.isLowerRange ? -t : t);
              }
              const t = this.children[e.readBit()];
              if (!t) throw new Jbig2Error("invalid Huffman data");
              return t.decodeNode(e);
            }
          }
          class HuffmanTable {
            constructor(e, t) {
              t || this.assignPrefixCodes(e);
              this.rootNode = new HuffmanTreeNode(null);
              for (let t = 0, a = e.length; t < a; t++) {
                const a = e[t];
                a.prefixLength > 0 &&
                  this.rootNode.buildTree(a, a.prefixLength - 1);
              }
            }
            decode(e) {
              return this.rootNode.decodeNode(e);
            }
            assignPrefixCodes(e) {
              const t = e.length;
              let a = 0;
              for (let r = 0; r < t; r++) a = Math.max(a, e[r].prefixLength);
              const r = new Uint32Array(a + 1);
              for (let a = 0; a < t; a++) r[e[a].prefixLength]++;
              let n,
                i,
                s,
                o = 1,
                c = 0;
              r[0] = 0;
              for (; o <= a; ) {
                c = (c + r[o - 1]) << 1;
                n = c;
                i = 0;
                for (; i < t; ) {
                  s = e[i];
                  if (s.prefixLength === o) {
                    s.prefixCode = n;
                    n++;
                  }
                  i++;
                }
                o++;
              }
            }
          }
          const p = {};
          function getStandardTable(e) {
            let t,
              a = p[e];
            if (a) return a;
            switch (e) {
              case 1:
                t = [
                  [0, 1, 4, 0],
                  [16, 2, 8, 2],
                  [272, 3, 16, 6],
                  [65808, 3, 32, 7],
                ];
                break;
              case 2:
                t = [
                  [0, 1, 0, 0],
                  [1, 2, 0, 2],
                  [2, 3, 0, 6],
                  [3, 4, 3, 14],
                  [11, 5, 6, 30],
                  [75, 6, 32, 62],
                  [6, 63],
                ];
                break;
              case 3:
                t = [
                  [-256, 8, 8, 254],
                  [0, 1, 0, 0],
                  [1, 2, 0, 2],
                  [2, 3, 0, 6],
                  [3, 4, 3, 14],
                  [11, 5, 6, 30],
                  [-257, 8, 32, 255, "lower"],
                  [75, 7, 32, 126],
                  [6, 62],
                ];
                break;
              case 4:
                t = [
                  [1, 1, 0, 0],
                  [2, 2, 0, 2],
                  [3, 3, 0, 6],
                  [4, 4, 3, 14],
                  [12, 5, 6, 30],
                  [76, 5, 32, 31],
                ];
                break;
              case 5:
                t = [
                  [-255, 7, 8, 126],
                  [1, 1, 0, 0],
                  [2, 2, 0, 2],
                  [3, 3, 0, 6],
                  [4, 4, 3, 14],
                  [12, 5, 6, 30],
                  [-256, 7, 32, 127, "lower"],
                  [76, 6, 32, 62],
                ];
                break;
              case 6:
                t = [
                  [-2048, 5, 10, 28],
                  [-1024, 4, 9, 8],
                  [-512, 4, 8, 9],
                  [-256, 4, 7, 10],
                  [-128, 5, 6, 29],
                  [-64, 5, 5, 30],
                  [-32, 4, 5, 11],
                  [0, 2, 7, 0],
                  [128, 3, 7, 2],
                  [256, 3, 8, 3],
                  [512, 4, 9, 12],
                  [1024, 4, 10, 13],
                  [-2049, 6, 32, 62, "lower"],
                  [2048, 6, 32, 63],
                ];
                break;
              case 7:
                t = [
                  [-1024, 4, 9, 8],
                  [-512, 3, 8, 0],
                  [-256, 4, 7, 9],
                  [-128, 5, 6, 26],
                  [-64, 5, 5, 27],
                  [-32, 4, 5, 10],
                  [0, 4, 5, 11],
                  [32, 5, 5, 28],
                  [64, 5, 6, 29],
                  [128, 4, 7, 12],
                  [256, 3, 8, 1],
                  [512, 3, 9, 2],
                  [1024, 3, 10, 3],
                  [-1025, 5, 32, 30, "lower"],
                  [2048, 5, 32, 31],
                ];
                break;
              case 8:
                t = [
                  [-15, 8, 3, 252],
                  [-7, 9, 1, 508],
                  [-5, 8, 1, 253],
                  [-3, 9, 0, 509],
                  [-2, 7, 0, 124],
                  [-1, 4, 0, 10],
                  [0, 2, 1, 0],
                  [2, 5, 0, 26],
                  [3, 6, 0, 58],
                  [4, 3, 4, 4],
                  [20, 6, 1, 59],
                  [22, 4, 4, 11],
                  [38, 4, 5, 12],
                  [70, 5, 6, 27],
                  [134, 5, 7, 28],
                  [262, 6, 7, 60],
                  [390, 7, 8, 125],
                  [646, 6, 10, 61],
                  [-16, 9, 32, 510, "lower"],
                  [1670, 9, 32, 511],
                  [2, 1],
                ];
                break;
              case 9:
                t = [
                  [-31, 8, 4, 252],
                  [-15, 9, 2, 508],
                  [-11, 8, 2, 253],
                  [-7, 9, 1, 509],
                  [-5, 7, 1, 124],
                  [-3, 4, 1, 10],
                  [-1, 3, 1, 2],
                  [1, 3, 1, 3],
                  [3, 5, 1, 26],
                  [5, 6, 1, 58],
                  [7, 3, 5, 4],
                  [39, 6, 2, 59],
                  [43, 4, 5, 11],
                  [75, 4, 6, 12],
                  [139, 5, 7, 27],
                  [267, 5, 8, 28],
                  [523, 6, 8, 60],
                  [779, 7, 9, 125],
                  [1291, 6, 11, 61],
                  [-32, 9, 32, 510, "lower"],
                  [3339, 9, 32, 511],
                  [2, 0],
                ];
                break;
              case 10:
                t = [
                  [-21, 7, 4, 122],
                  [-5, 8, 0, 252],
                  [-4, 7, 0, 123],
                  [-3, 5, 0, 24],
                  [-2, 2, 2, 0],
                  [2, 5, 0, 25],
                  [3, 6, 0, 54],
                  [4, 7, 0, 124],
                  [5, 8, 0, 253],
                  [6, 2, 6, 1],
                  [70, 5, 5, 26],
                  [102, 6, 5, 55],
                  [134, 6, 6, 56],
                  [198, 6, 7, 57],
                  [326, 6, 8, 58],
                  [582, 6, 9, 59],
                  [1094, 6, 10, 60],
                  [2118, 7, 11, 125],
                  [-22, 8, 32, 254, "lower"],
                  [4166, 8, 32, 255],
                  [2, 2],
                ];
                break;
              case 11:
                t = [
                  [1, 1, 0, 0],
                  [2, 2, 1, 2],
                  [4, 4, 0, 12],
                  [5, 4, 1, 13],
                  [7, 5, 1, 28],
                  [9, 5, 2, 29],
                  [13, 6, 2, 60],
                  [17, 7, 2, 122],
                  [21, 7, 3, 123],
                  [29, 7, 4, 124],
                  [45, 7, 5, 125],
                  [77, 7, 6, 126],
                  [141, 7, 32, 127],
                ];
                break;
              case 12:
                t = [
                  [1, 1, 0, 0],
                  [2, 2, 0, 2],
                  [3, 3, 1, 6],
                  [5, 5, 0, 28],
                  [6, 5, 1, 29],
                  [8, 6, 1, 60],
                  [10, 7, 0, 122],
                  [11, 7, 1, 123],
                  [13, 7, 2, 124],
                  [17, 7, 3, 125],
                  [25, 7, 4, 126],
                  [41, 8, 5, 254],
                  [73, 8, 32, 255],
                ];
                break;
              case 13:
                t = [
                  [1, 1, 0, 0],
                  [2, 3, 0, 4],
                  [3, 4, 0, 12],
                  [4, 5, 0, 28],
                  [5, 4, 1, 13],
                  [7, 3, 3, 5],
                  [15, 6, 1, 58],
                  [17, 6, 2, 59],
                  [21, 6, 3, 60],
                  [29, 6, 4, 61],
                  [45, 6, 5, 62],
                  [77, 7, 6, 126],
                  [141, 7, 32, 127],
                ];
                break;
              case 14:
                t = [
                  [-2, 3, 0, 4],
                  [-1, 3, 0, 5],
                  [0, 1, 0, 0],
                  [1, 3, 0, 6],
                  [2, 3, 0, 7],
                ];
                break;
              case 15:
                t = [
                  [-24, 7, 4, 124],
                  [-8, 6, 2, 60],
                  [-4, 5, 1, 28],
                  [-2, 4, 0, 12],
                  [-1, 3, 0, 4],
                  [0, 1, 0, 0],
                  [1, 3, 0, 5],
                  [2, 4, 0, 13],
                  [3, 5, 1, 29],
                  [5, 6, 2, 61],
                  [9, 7, 4, 125],
                  [-25, 7, 32, 126, "lower"],
                  [25, 7, 32, 127],
                ];
                break;
              default:
                throw new Jbig2Error(`standard table B.${e} does not exist`);
            }
            for (let e = 0, a = t.length; e < a; e++)
              t[e] = new HuffmanLine(t[e]);
            a = new HuffmanTable(t, !0);
            p[e] = a;
            return a;
          }
          class Reader {
            constructor(e, t, a) {
              this.data = e;
              this.start = t;
              this.end = a;
              this.position = t;
              this.shift = -1;
              this.currentByte = 0;
            }
            readBit() {
              if (this.shift < 0) {
                if (this.position >= this.end)
                  throw new Jbig2Error("end of data while reading bit");
                this.currentByte = this.data[this.position++];
                this.shift = 7;
              }
              const e = (this.currentByte >> this.shift) & 1;
              this.shift--;
              return e;
            }
            readBits(e) {
              let t,
                a = 0;
              for (t = e - 1; t >= 0; t--) a |= this.readBit() << t;
              return a;
            }
            byteAlign() {
              this.shift = -1;
            }
            next() {
              return this.position >= this.end
                ? -1
                : this.data[this.position++];
            }
          }
          function getCustomHuffmanTable(e, t, a) {
            let r = 0;
            for (let n = 0, i = t.length; n < i; n++) {
              const i = a[t[n]];
              if (i) {
                if (e === r) return i;
                r++;
              }
            }
            throw new Jbig2Error("can't find custom Huffman table");
          }
          function readUncompressedBitmap(e, t, a) {
            const r = [];
            for (let n = 0; n < a; n++) {
              const a = new Uint8Array(t);
              r.push(a);
              for (let r = 0; r < t; r++) a[r] = e.readBit();
              e.byteAlign();
            }
            return r;
          }
          function decodeMMRBitmap(e, t, a, r) {
            const n = {
                K: -1,
                Columns: t,
                Rows: a,
                BlackIs1: !0,
                EndOfBlock: r,
              },
              i = new s.CCITTFaxDecoder(e, n),
              o = [];
            let c,
              l = !1;
            for (let e = 0; e < a; e++) {
              const e = new Uint8Array(t);
              o.push(e);
              let a = -1;
              for (let r = 0; r < t; r++) {
                if (a < 0) {
                  c = i.readNextChar();
                  if (-1 === c) {
                    c = 0;
                    l = !0;
                  }
                  a = 7;
                }
                e[r] = (c >> a) & 1;
                a--;
              }
            }
            if (r && !l) {
              const e = 5;
              for (let t = 0; t < e && -1 !== i.readNextChar(); t++);
            }
            return o;
          }
          t.Jbig2Image = class Jbig2Image {
            parseChunks(e) {
              return (function parseJbig2Chunks(e) {
                const t = new SimpleSegmentVisitor();
                for (let a = 0, r = e.length; a < r; a++) {
                  const r = e[a];
                  processSegments(readSegments({}, r.data, r.start, r.end), t);
                }
                return t.buffer;
              })(e);
            }
            parse(e) {
              throw new Error("Not implemented: Jbig2Image.parse");
            }
          };
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ArithmeticDecoder = void 0;
          const a = [
            { qe: 22017, nmps: 1, nlps: 1, switchFlag: 1 },
            { qe: 13313, nmps: 2, nlps: 6, switchFlag: 0 },
            { qe: 6145, nmps: 3, nlps: 9, switchFlag: 0 },
            { qe: 2753, nmps: 4, nlps: 12, switchFlag: 0 },
            { qe: 1313, nmps: 5, nlps: 29, switchFlag: 0 },
            { qe: 545, nmps: 38, nlps: 33, switchFlag: 0 },
            { qe: 22017, nmps: 7, nlps: 6, switchFlag: 1 },
            { qe: 21505, nmps: 8, nlps: 14, switchFlag: 0 },
            { qe: 18433, nmps: 9, nlps: 14, switchFlag: 0 },
            { qe: 14337, nmps: 10, nlps: 14, switchFlag: 0 },
            { qe: 12289, nmps: 11, nlps: 17, switchFlag: 0 },
            { qe: 9217, nmps: 12, nlps: 18, switchFlag: 0 },
            { qe: 7169, nmps: 13, nlps: 20, switchFlag: 0 },
            { qe: 5633, nmps: 29, nlps: 21, switchFlag: 0 },
            { qe: 22017, nmps: 15, nlps: 14, switchFlag: 1 },
            { qe: 21505, nmps: 16, nlps: 14, switchFlag: 0 },
            { qe: 20737, nmps: 17, nlps: 15, switchFlag: 0 },
            { qe: 18433, nmps: 18, nlps: 16, switchFlag: 0 },
            { qe: 14337, nmps: 19, nlps: 17, switchFlag: 0 },
            { qe: 13313, nmps: 20, nlps: 18, switchFlag: 0 },
            { qe: 12289, nmps: 21, nlps: 19, switchFlag: 0 },
            { qe: 10241, nmps: 22, nlps: 19, switchFlag: 0 },
            { qe: 9217, nmps: 23, nlps: 20, switchFlag: 0 },
            { qe: 8705, nmps: 24, nlps: 21, switchFlag: 0 },
            { qe: 7169, nmps: 25, nlps: 22, switchFlag: 0 },
            { qe: 6145, nmps: 26, nlps: 23, switchFlag: 0 },
            { qe: 5633, nmps: 27, nlps: 24, switchFlag: 0 },
            { qe: 5121, nmps: 28, nlps: 25, switchFlag: 0 },
            { qe: 4609, nmps: 29, nlps: 26, switchFlag: 0 },
            { qe: 4353, nmps: 30, nlps: 27, switchFlag: 0 },
            { qe: 2753, nmps: 31, nlps: 28, switchFlag: 0 },
            { qe: 2497, nmps: 32, nlps: 29, switchFlag: 0 },
            { qe: 2209, nmps: 33, nlps: 30, switchFlag: 0 },
            { qe: 1313, nmps: 34, nlps: 31, switchFlag: 0 },
            { qe: 1089, nmps: 35, nlps: 32, switchFlag: 0 },
            { qe: 673, nmps: 36, nlps: 33, switchFlag: 0 },
            { qe: 545, nmps: 37, nlps: 34, switchFlag: 0 },
            { qe: 321, nmps: 38, nlps: 35, switchFlag: 0 },
            { qe: 273, nmps: 39, nlps: 36, switchFlag: 0 },
            { qe: 133, nmps: 40, nlps: 37, switchFlag: 0 },
            { qe: 73, nmps: 41, nlps: 38, switchFlag: 0 },
            { qe: 37, nmps: 42, nlps: 39, switchFlag: 0 },
            { qe: 21, nmps: 43, nlps: 40, switchFlag: 0 },
            { qe: 9, nmps: 44, nlps: 41, switchFlag: 0 },
            { qe: 5, nmps: 45, nlps: 42, switchFlag: 0 },
            { qe: 1, nmps: 45, nlps: 43, switchFlag: 0 },
            { qe: 22017, nmps: 46, nlps: 46, switchFlag: 0 },
          ];
          t.ArithmeticDecoder = class ArithmeticDecoder {
            constructor(e, t, a) {
              this.data = e;
              this.bp = t;
              this.dataEnd = a;
              this.chigh = e[t];
              this.clow = 0;
              this.byteIn();
              this.chigh =
                ((this.chigh << 7) & 65535) | ((this.clow >> 9) & 127);
              this.clow = (this.clow << 7) & 65535;
              this.ct -= 7;
              this.a = 32768;
            }
            byteIn() {
              const e = this.data;
              let t = this.bp;
              if (255 === e[t])
                if (e[t + 1] > 143) {
                  this.clow += 65280;
                  this.ct = 8;
                } else {
                  t++;
                  this.clow += e[t] << 9;
                  this.ct = 7;
                  this.bp = t;
                }
              else {
                t++;
                this.clow += t < this.dataEnd ? e[t] << 8 : 65280;
                this.ct = 8;
                this.bp = t;
              }
              if (this.clow > 65535) {
                this.chigh += this.clow >> 16;
                this.clow &= 65535;
              }
            }
            readBit(e, t) {
              let r = e[t] >> 1,
                n = 1 & e[t];
              const i = a[r],
                s = i.qe;
              let o,
                c = this.a - s;
              if (this.chigh < s)
                if (c < s) {
                  c = s;
                  o = n;
                  r = i.nmps;
                } else {
                  c = s;
                  o = 1 ^ n;
                  1 === i.switchFlag && (n = o);
                  r = i.nlps;
                }
              else {
                this.chigh -= s;
                if (0 != (32768 & c)) {
                  this.a = c;
                  return n;
                }
                if (c < s) {
                  o = 1 ^ n;
                  1 === i.switchFlag && (n = o);
                  r = i.nlps;
                } else {
                  o = n;
                  r = i.nmps;
                }
              }
              do {
                0 === this.ct && this.byteIn();
                c <<= 1;
                this.chigh =
                  ((this.chigh << 1) & 65535) | ((this.clow >> 15) & 1);
                this.clow = (this.clow << 1) & 65535;
                this.ct--;
              } while (0 == (32768 & c));
              this.a = c;
              e[t] = (r << 1) | n;
              return o;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.JpegStream = void 0;
          var r = a(17),
            n = a(4),
            i = a(26),
            s = a(2);
          class JpegStream extends r.DecodeStream {
            constructor(e, t, a) {
              let r;
              for (; -1 !== (r = e.getByte()); )
                if (255 === r) {
                  e.skip(-1);
                  break;
                }
              super(t);
              this.stream = e;
              this.dict = e.dict;
              this.maybeLength = t;
              this.params = a;
            }
            get bytes() {
              return (0, s.shadow)(
                this,
                "bytes",
                this.stream.getBytes(this.maybeLength)
              );
            }
            ensureBuffer(e) {}
            readBlock() {
              if (this.eof) return;
              const e = { decodeTransform: void 0, colorTransform: void 0 },
                t = this.dict.getArray("D", "Decode");
              if (this.forceRGB && Array.isArray(t)) {
                const a = this.dict.get("BPC", "BitsPerComponent") || 8,
                  r = t.length,
                  n = new Int32Array(r);
                let i = !1;
                const s = (1 << a) - 1;
                for (let e = 0; e < r; e += 2) {
                  n[e] = (256 * (t[e + 1] - t[e])) | 0;
                  n[e + 1] = (t[e] * s) | 0;
                  (256 === n[e] && 0 === n[e + 1]) || (i = !0);
                }
                i && (e.decodeTransform = n);
              }
              if (this.params instanceof n.Dict) {
                const t = this.params.get("ColorTransform");
                Number.isInteger(t) && (e.colorTransform = t);
              }
              const a = new i.JpegImage(e);
              a.parse(this.bytes);
              const r = a.getData({
                width: this.drawWidth,
                height: this.drawHeight,
                forceRGB: this.forceRGB,
                isSourcePDF: !0,
              });
              this.buffer = r;
              this.bufferLength = r.length;
              this.eof = !0;
            }
          }
          t.JpegStream = JpegStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.JpegImage = void 0;
          var r = a(2),
            n = a(3);
          class JpegError extends r.BaseException {
            constructor(e) {
              super(`JPEG error: ${e}`, "JpegError");
            }
          }
          class DNLMarkerError extends r.BaseException {
            constructor(e, t) {
              super(e, "DNLMarkerError");
              this.scanLines = t;
            }
          }
          class EOIMarkerError extends r.BaseException {
            constructor(e) {
              super(e, "EOIMarkerError");
            }
          }
          const i = new Uint8Array([
              0, 1, 8, 16, 9, 2, 3, 10, 17, 24, 32, 25, 18, 11, 4, 5, 12, 19,
              26, 33, 40, 48, 41, 34, 27, 20, 13, 6, 7, 14, 21, 28, 35, 42, 49,
              56, 57, 50, 43, 36, 29, 22, 15, 23, 30, 37, 44, 51, 58, 59, 52,
              45, 38, 31, 39, 46, 53, 60, 61, 54, 47, 55, 62, 63,
            ]),
            s = 4017,
            o = 799,
            c = 3406,
            l = 2276,
            h = 1567,
            u = 3784,
            d = 5793,
            f = 2896;
          function buildHuffmanTable(e, t) {
            let a,
              r,
              n = 0,
              i = 16;
            for (; i > 0 && !e[i - 1]; ) i--;
            const s = [{ children: [], index: 0 }];
            let o,
              c = s[0];
            for (a = 0; a < i; a++) {
              for (r = 0; r < e[a]; r++) {
                c = s.pop();
                c.children[c.index] = t[n];
                for (; c.index > 0; ) c = s.pop();
                c.index++;
                s.push(c);
                for (; s.length <= a; ) {
                  s.push((o = { children: [], index: 0 }));
                  c.children[c.index] = o.children;
                  c = o;
                }
                n++;
              }
              if (a + 1 < i) {
                s.push((o = { children: [], index: 0 }));
                c.children[c.index] = o.children;
                c = o;
              }
            }
            return s[0].children;
          }
          function getBlockBufferOffset(e, t, a) {
            return 64 * ((e.blocksPerLine + 1) * t + a);
          }
          function decodeScan(e, t, a, s, o, c, l, h, u, d = !1) {
            const f = a.mcusPerLine,
              g = a.progressive,
              p = t;
            let m = 0,
              b = 0;
            function readBit() {
              if (b > 0) {
                b--;
                return (m >> b) & 1;
              }
              m = e[t++];
              if (255 === m) {
                const r = e[t++];
                if (r) {
                  if (220 === r && d) {
                    t += 2;
                    const r = (0, n.readUint16)(e, t);
                    t += 2;
                    if (r > 0 && r !== a.scanLines)
                      throw new DNLMarkerError(
                        "Found DNL marker (0xFFDC) while parsing scan data",
                        r
                      );
                  } else if (217 === r) {
                    if (d) {
                      const e = x * (8 === a.precision ? 8 : 0);
                      if (e > 0 && Math.round(a.scanLines / e) >= 5)
                        throw new DNLMarkerError(
                          "Found EOI marker (0xFFD9) while parsing scan data, possibly caused by incorrect `scanLines` parameter",
                          e
                        );
                    }
                    throw new EOIMarkerError(
                      "Found EOI marker (0xFFD9) while parsing scan data"
                    );
                  }
                  throw new JpegError(
                    `unexpected marker ${((m << 8) | r).toString(16)}`
                  );
                }
              }
              b = 7;
              return m >>> 7;
            }
            function decodeHuffman(e) {
              let t = e;
              for (;;) {
                t = t[readBit()];
                switch (typeof t) {
                  case "number":
                    return t;
                  case "object":
                    continue;
                }
                throw new JpegError("invalid huffman sequence");
              }
            }
            function receive(e) {
              let t = 0;
              for (; e > 0; ) {
                t = (t << 1) | readBit();
                e--;
              }
              return t;
            }
            function receiveAndExtend(e) {
              if (1 === e) return 1 === readBit() ? 1 : -1;
              const t = receive(e);
              return t >= 1 << (e - 1) ? t : t + (-1 << e) + 1;
            }
            let y = 0;
            let w,
              S = 0;
            let x = 0;
            function decodeMcu(e, t, a, r, n) {
              const i = a % f;
              x = ((a / f) | 0) * e.v + r;
              const s = i * e.h + n;
              t(e, getBlockBufferOffset(e, x, s));
            }
            function decodeBlock(e, t, a) {
              x = (a / e.blocksPerLine) | 0;
              const r = a % e.blocksPerLine;
              t(e, getBlockBufferOffset(e, x, r));
            }
            const C = s.length;
            let k, v, F, O, T, M;
            M = g
              ? 0 === c
                ? 0 === h
                  ? function decodeDCFirst(e, t) {
                      const a = decodeHuffman(e.huffmanTableDC),
                        r = 0 === a ? 0 : receiveAndExtend(a) << u;
                      e.blockData[t] = e.pred += r;
                    }
                  : function decodeDCSuccessive(e, t) {
                      e.blockData[t] |= readBit() << u;
                    }
                : 0 === h
                ? function decodeACFirst(e, t) {
                    if (y > 0) {
                      y--;
                      return;
                    }
                    let a = c;
                    const r = l;
                    for (; a <= r; ) {
                      const r = decodeHuffman(e.huffmanTableAC),
                        n = 15 & r,
                        s = r >> 4;
                      if (0 === n) {
                        if (s < 15) {
                          y = receive(s) + (1 << s) - 1;
                          break;
                        }
                        a += 16;
                        continue;
                      }
                      a += s;
                      const o = i[a];
                      e.blockData[t + o] = receiveAndExtend(n) * (1 << u);
                      a++;
                    }
                  }
                : function decodeACSuccessive(e, t) {
                    let a = c;
                    const r = l;
                    let n,
                      s,
                      o = 0;
                    for (; a <= r; ) {
                      const r = t + i[a],
                        c = e.blockData[r] < 0 ? -1 : 1;
                      switch (S) {
                        case 0:
                          s = decodeHuffman(e.huffmanTableAC);
                          n = 15 & s;
                          o = s >> 4;
                          if (0 === n)
                            if (o < 15) {
                              y = receive(o) + (1 << o);
                              S = 4;
                            } else {
                              o = 16;
                              S = 1;
                            }
                          else {
                            if (1 !== n)
                              throw new JpegError("invalid ACn encoding");
                            w = receiveAndExtend(n);
                            S = o ? 2 : 3;
                          }
                          continue;
                        case 1:
                        case 2:
                          if (e.blockData[r])
                            e.blockData[r] += c * (readBit() << u);
                          else {
                            o--;
                            0 === o && (S = 2 === S ? 3 : 0);
                          }
                          break;
                        case 3:
                          if (e.blockData[r])
                            e.blockData[r] += c * (readBit() << u);
                          else {
                            e.blockData[r] = w << u;
                            S = 0;
                          }
                          break;
                        case 4:
                          e.blockData[r] &&
                            (e.blockData[r] += c * (readBit() << u));
                      }
                      a++;
                    }
                    if (4 === S) {
                      y--;
                      0 === y && (S = 0);
                    }
                  }
              : function decodeBaseline(e, t) {
                  const a = decodeHuffman(e.huffmanTableDC),
                    r = 0 === a ? 0 : receiveAndExtend(a);
                  e.blockData[t] = e.pred += r;
                  let n = 1;
                  for (; n < 64; ) {
                    const a = decodeHuffman(e.huffmanTableAC),
                      r = 15 & a,
                      s = a >> 4;
                    if (0 === r) {
                      if (s < 15) break;
                      n += 16;
                      continue;
                    }
                    n += s;
                    const o = i[n];
                    e.blockData[t + o] = receiveAndExtend(r);
                    n++;
                  }
                };
            let E,
              D,
              N,
              R,
              L = 0;
            D =
              1 === C
                ? s[0].blocksPerLine * s[0].blocksPerColumn
                : f * a.mcusPerColumn;
            for (; L <= D; ) {
              const a = o ? Math.min(D - L, o) : D;
              if (a > 0) {
                for (v = 0; v < C; v++) s[v].pred = 0;
                y = 0;
                if (1 === C) {
                  k = s[0];
                  for (T = 0; T < a; T++) {
                    decodeBlock(k, M, L);
                    L++;
                  }
                } else
                  for (T = 0; T < a; T++) {
                    for (v = 0; v < C; v++) {
                      k = s[v];
                      N = k.h;
                      R = k.v;
                      for (F = 0; F < R; F++)
                        for (O = 0; O < N; O++) decodeMcu(k, M, L, F, O);
                    }
                    L++;
                  }
              }
              b = 0;
              E = findNextFileMarker(e, t);
              if (!E) break;
              if (E.invalid) {
                const e = a > 0 ? "unexpected" : "excessive";
                (0, r.warn)(
                  `decodeScan - ${e} MCU data, current marker is: ${E.invalid}`
                );
                t = E.offset;
              }
              if (!(E.marker >= 65488 && E.marker <= 65495)) break;
              t += 2;
            }
            return t - p;
          }
          function quantizeAndInverse(e, t, a) {
            const r = e.quantizationTable,
              n = e.blockData;
            let i, g, p, m, b, y, w, S, x, C, k, v, F, O, T, M, E;
            if (!r) throw new JpegError("missing required Quantization Table.");
            for (let e = 0; e < 64; e += 8) {
              x = n[t + e];
              C = n[t + e + 1];
              k = n[t + e + 2];
              v = n[t + e + 3];
              F = n[t + e + 4];
              O = n[t + e + 5];
              T = n[t + e + 6];
              M = n[t + e + 7];
              x *= r[e];
              if (0 != (C | k | v | F | O | T | M)) {
                C *= r[e + 1];
                k *= r[e + 2];
                v *= r[e + 3];
                F *= r[e + 4];
                O *= r[e + 5];
                T *= r[e + 6];
                M *= r[e + 7];
                i = (d * x + 128) >> 8;
                g = (d * F + 128) >> 8;
                p = k;
                m = T;
                b = (f * (C - M) + 128) >> 8;
                S = (f * (C + M) + 128) >> 8;
                y = v << 4;
                w = O << 4;
                i = (i + g + 1) >> 1;
                g = i - g;
                E = (p * u + m * h + 128) >> 8;
                p = (p * h - m * u + 128) >> 8;
                m = E;
                b = (b + w + 1) >> 1;
                w = b - w;
                S = (S + y + 1) >> 1;
                y = S - y;
                i = (i + m + 1) >> 1;
                m = i - m;
                g = (g + p + 1) >> 1;
                p = g - p;
                E = (b * l + S * c + 2048) >> 12;
                b = (b * c - S * l + 2048) >> 12;
                S = E;
                E = (y * o + w * s + 2048) >> 12;
                y = (y * s - w * o + 2048) >> 12;
                w = E;
                a[e] = i + S;
                a[e + 7] = i - S;
                a[e + 1] = g + w;
                a[e + 6] = g - w;
                a[e + 2] = p + y;
                a[e + 5] = p - y;
                a[e + 3] = m + b;
                a[e + 4] = m - b;
              } else {
                E = (d * x + 512) >> 10;
                a[e] = E;
                a[e + 1] = E;
                a[e + 2] = E;
                a[e + 3] = E;
                a[e + 4] = E;
                a[e + 5] = E;
                a[e + 6] = E;
                a[e + 7] = E;
              }
            }
            for (let e = 0; e < 8; ++e) {
              x = a[e];
              C = a[e + 8];
              k = a[e + 16];
              v = a[e + 24];
              F = a[e + 32];
              O = a[e + 40];
              T = a[e + 48];
              M = a[e + 56];
              if (0 != (C | k | v | F | O | T | M)) {
                i = (d * x + 2048) >> 12;
                g = (d * F + 2048) >> 12;
                p = k;
                m = T;
                b = (f * (C - M) + 2048) >> 12;
                S = (f * (C + M) + 2048) >> 12;
                y = v;
                w = O;
                i = 4112 + ((i + g + 1) >> 1);
                g = i - g;
                E = (p * u + m * h + 2048) >> 12;
                p = (p * h - m * u + 2048) >> 12;
                m = E;
                b = (b + w + 1) >> 1;
                w = b - w;
                S = (S + y + 1) >> 1;
                y = S - y;
                i = (i + m + 1) >> 1;
                m = i - m;
                g = (g + p + 1) >> 1;
                p = g - p;
                E = (b * l + S * c + 2048) >> 12;
                b = (b * c - S * l + 2048) >> 12;
                S = E;
                E = (y * o + w * s + 2048) >> 12;
                y = (y * s - w * o + 2048) >> 12;
                w = E;
                x = i + S;
                M = i - S;
                C = g + w;
                T = g - w;
                k = p + y;
                O = p - y;
                v = m + b;
                F = m - b;
                x < 16 ? (x = 0) : x >= 4080 ? (x = 255) : (x >>= 4);
                C < 16 ? (C = 0) : C >= 4080 ? (C = 255) : (C >>= 4);
                k < 16 ? (k = 0) : k >= 4080 ? (k = 255) : (k >>= 4);
                v < 16 ? (v = 0) : v >= 4080 ? (v = 255) : (v >>= 4);
                F < 16 ? (F = 0) : F >= 4080 ? (F = 255) : (F >>= 4);
                O < 16 ? (O = 0) : O >= 4080 ? (O = 255) : (O >>= 4);
                T < 16 ? (T = 0) : T >= 4080 ? (T = 255) : (T >>= 4);
                M < 16 ? (M = 0) : M >= 4080 ? (M = 255) : (M >>= 4);
                n[t + e] = x;
                n[t + e + 8] = C;
                n[t + e + 16] = k;
                n[t + e + 24] = v;
                n[t + e + 32] = F;
                n[t + e + 40] = O;
                n[t + e + 48] = T;
                n[t + e + 56] = M;
              } else {
                E = (d * x + 8192) >> 14;
                E = E < -2040 ? 0 : E >= 2024 ? 255 : (E + 2056) >> 4;
                n[t + e] = E;
                n[t + e + 8] = E;
                n[t + e + 16] = E;
                n[t + e + 24] = E;
                n[t + e + 32] = E;
                n[t + e + 40] = E;
                n[t + e + 48] = E;
                n[t + e + 56] = E;
              }
            }
          }
          function buildComponentData(e, t) {
            const a = t.blocksPerLine,
              r = t.blocksPerColumn,
              n = new Int16Array(64);
            for (let e = 0; e < r; e++)
              for (let r = 0; r < a; r++) {
                quantizeAndInverse(t, getBlockBufferOffset(t, e, r), n);
              }
            return t.blockData;
          }
          function findNextFileMarker(e, t, a = t) {
            const r = e.length - 1;
            let i = a < t ? a : t;
            if (t >= r) return null;
            const s = (0, n.readUint16)(e, t);
            if (s >= 65472 && s <= 65534)
              return { invalid: null, marker: s, offset: t };
            let o = (0, n.readUint16)(e, i);
            for (; !(o >= 65472 && o <= 65534); ) {
              if (++i >= r) return null;
              o = (0, n.readUint16)(e, i);
            }
            return { invalid: s.toString(16), marker: o, offset: i };
          }
          t.JpegImage = class JpegImage {
            constructor({
              decodeTransform: e = null,
              colorTransform: t = -1,
            } = {}) {
              this._decodeTransform = e;
              this._colorTransform = t;
            }
            parse(e, { dnlScanLines: t = null } = {}) {
              function readDataBlock() {
                const t = (0, n.readUint16)(e, o);
                o += 2;
                let a = o + t - 2;
                const i = findNextFileMarker(e, a, o);
                if (i && i.invalid) {
                  (0, r.warn)(
                    "readDataBlock - incorrect length, current marker is: " +
                      i.invalid
                  );
                  a = i.offset;
                }
                const s = e.subarray(o, a);
                o += s.length;
                return s;
              }
              function prepareComponents(e) {
                const t = Math.ceil(e.samplesPerLine / 8 / e.maxH),
                  a = Math.ceil(e.scanLines / 8 / e.maxV);
                for (const r of e.components) {
                  const n = Math.ceil(
                      (Math.ceil(e.samplesPerLine / 8) * r.h) / e.maxH
                    ),
                    i = Math.ceil((Math.ceil(e.scanLines / 8) * r.v) / e.maxV),
                    s = t * r.h,
                    o = 64 * (a * r.v) * (s + 1);
                  r.blockData = new Int16Array(o);
                  r.blocksPerLine = n;
                  r.blocksPerColumn = i;
                }
                e.mcusPerLine = t;
                e.mcusPerColumn = a;
              }
              let a,
                s,
                o = 0,
                c = null,
                l = null,
                h = 0;
              const u = [],
                d = [],
                f = [];
              let g = (0, n.readUint16)(e, o);
              o += 2;
              if (65496 !== g) throw new JpegError("SOI not found");
              g = (0, n.readUint16)(e, o);
              o += 2;
              e: for (; 65497 !== g; ) {
                let p, m, b;
                switch (g) {
                  case 65504:
                  case 65505:
                  case 65506:
                  case 65507:
                  case 65508:
                  case 65509:
                  case 65510:
                  case 65511:
                  case 65512:
                  case 65513:
                  case 65514:
                  case 65515:
                  case 65516:
                  case 65517:
                  case 65518:
                  case 65519:
                  case 65534:
                    const y = readDataBlock();
                    65504 === g &&
                      74 === y[0] &&
                      70 === y[1] &&
                      73 === y[2] &&
                      70 === y[3] &&
                      0 === y[4] &&
                      (c = {
                        version: { major: y[5], minor: y[6] },
                        densityUnits: y[7],
                        xDensity: (y[8] << 8) | y[9],
                        yDensity: (y[10] << 8) | y[11],
                        thumbWidth: y[12],
                        thumbHeight: y[13],
                        thumbData: y.subarray(14, 14 + 3 * y[12] * y[13]),
                      });
                    65518 === g &&
                      65 === y[0] &&
                      100 === y[1] &&
                      111 === y[2] &&
                      98 === y[3] &&
                      101 === y[4] &&
                      (l = {
                        version: (y[5] << 8) | y[6],
                        flags0: (y[7] << 8) | y[8],
                        flags1: (y[9] << 8) | y[10],
                        transformCode: y[11],
                      });
                    break;
                  case 65499:
                    const w = (0, n.readUint16)(e, o);
                    o += 2;
                    const S = w + o - 2;
                    let x;
                    for (; o < S; ) {
                      const t = e[o++],
                        a = new Uint16Array(64);
                      if (t >> 4 == 0)
                        for (m = 0; m < 64; m++) {
                          x = i[m];
                          a[x] = e[o++];
                        }
                      else {
                        if (t >> 4 != 1)
                          throw new JpegError("DQT - invalid table spec");
                        for (m = 0; m < 64; m++) {
                          x = i[m];
                          a[x] = (0, n.readUint16)(e, o);
                          o += 2;
                        }
                      }
                      u[15 & t] = a;
                    }
                    break;
                  case 65472:
                  case 65473:
                  case 65474:
                    if (a)
                      throw new JpegError("Only single frame JPEGs supported");
                    o += 2;
                    a = {};
                    a.extended = 65473 === g;
                    a.progressive = 65474 === g;
                    a.precision = e[o++];
                    const C = (0, n.readUint16)(e, o);
                    o += 2;
                    a.scanLines = t || C;
                    a.samplesPerLine = (0, n.readUint16)(e, o);
                    o += 2;
                    a.components = [];
                    a.componentIds = {};
                    const k = e[o++];
                    let v = 0,
                      F = 0;
                    for (p = 0; p < k; p++) {
                      const t = e[o],
                        r = e[o + 1] >> 4,
                        n = 15 & e[o + 1];
                      v < r && (v = r);
                      F < n && (F = n);
                      const i = e[o + 2];
                      b = a.components.push({
                        h: r,
                        v: n,
                        quantizationId: i,
                        quantizationTable: null,
                      });
                      a.componentIds[t] = b - 1;
                      o += 3;
                    }
                    a.maxH = v;
                    a.maxV = F;
                    prepareComponents(a);
                    break;
                  case 65476:
                    const O = (0, n.readUint16)(e, o);
                    o += 2;
                    for (p = 2; p < O; ) {
                      const t = e[o++],
                        a = new Uint8Array(16);
                      let r = 0;
                      for (m = 0; m < 16; m++, o++) r += a[m] = e[o];
                      const n = new Uint8Array(r);
                      for (m = 0; m < r; m++, o++) n[m] = e[o];
                      p += 17 + r;
                      (t >> 4 == 0 ? f : d)[15 & t] = buildHuffmanTable(a, n);
                    }
                    break;
                  case 65501:
                    o += 2;
                    s = (0, n.readUint16)(e, o);
                    o += 2;
                    break;
                  case 65498:
                    const T = 1 == ++h && !t;
                    o += 2;
                    const M = e[o++],
                      E = [];
                    for (p = 0; p < M; p++) {
                      const t = e[o++],
                        r = a.componentIds[t],
                        n = a.components[r];
                      n.index = t;
                      const i = e[o++];
                      n.huffmanTableDC = f[i >> 4];
                      n.huffmanTableAC = d[15 & i];
                      E.push(n);
                    }
                    const D = e[o++],
                      N = e[o++],
                      R = e[o++];
                    try {
                      const t = decodeScan(
                        e,
                        o,
                        a,
                        E,
                        s,
                        D,
                        N,
                        R >> 4,
                        15 & R,
                        T
                      );
                      o += t;
                    } catch (t) {
                      if (t instanceof DNLMarkerError) {
                        (0, r.warn)(
                          `${t.message} -- attempting to re-parse the JPEG image.`
                        );
                        return this.parse(e, { dnlScanLines: t.scanLines });
                      }
                      if (t instanceof EOIMarkerError) {
                        (0, r.warn)(
                          `${t.message} -- ignoring the rest of the image data.`
                        );
                        break e;
                      }
                      throw t;
                    }
                    break;
                  case 65500:
                    o += 4;
                    break;
                  case 65535:
                    255 !== e[o] && o--;
                    break;
                  default:
                    const L = findNextFileMarker(e, o - 2, o - 3);
                    if (L && L.invalid) {
                      (0, r.warn)(
                        "JpegImage.parse - unexpected data, current marker is: " +
                          L.invalid
                      );
                      o = L.offset;
                      break;
                    }
                    if (!L || o >= e.length - 1) {
                      (0, r.warn)(
                        "JpegImage.parse - reached the end of the image data without finding an EOI marker (0xFFD9)."
                      );
                      break e;
                    }
                    throw new JpegError(
                      "JpegImage.parse - unknown marker: " + g.toString(16)
                    );
                }
                g = (0, n.readUint16)(e, o);
                o += 2;
              }
              this.width = a.samplesPerLine;
              this.height = a.scanLines;
              this.jfif = c;
              this.adobe = l;
              this.components = [];
              for (const e of a.components) {
                const t = u[e.quantizationId];
                t && (e.quantizationTable = t);
                this.components.push({
                  index: e.index,
                  output: buildComponentData(0, e),
                  scaleX: e.h / a.maxH,
                  scaleY: e.v / a.maxV,
                  blocksPerLine: e.blocksPerLine,
                  blocksPerColumn: e.blocksPerColumn,
                });
              }
              this.numComponents = this.components.length;
            }
            _getLinearizedBlockData(e, t, a = !1) {
              const r = this.width / e,
                n = this.height / t;
              let i,
                s,
                o,
                c,
                l,
                h,
                u,
                d,
                f,
                g,
                p,
                m = 0;
              const b = this.components.length,
                y = e * t * b,
                w = new Uint8ClampedArray(y),
                S = new Uint32Array(e),
                x = 4294967288;
              let C;
              for (u = 0; u < b; u++) {
                i = this.components[u];
                s = i.scaleX * r;
                o = i.scaleY * n;
                m = u;
                p = i.output;
                c = (i.blocksPerLine + 1) << 3;
                if (s !== C) {
                  for (l = 0; l < e; l++) {
                    d = 0 | (l * s);
                    S[l] = ((d & x) << 3) | (7 & d);
                  }
                  C = s;
                }
                for (h = 0; h < t; h++) {
                  d = 0 | (h * o);
                  g = (c * (d & x)) | ((7 & d) << 3);
                  for (l = 0; l < e; l++) {
                    w[m] = p[g + S[l]];
                    m += b;
                  }
                }
              }
              let k = this._decodeTransform;
              a ||
                4 !== b ||
                k ||
                (k = new Int32Array([
                  -256, 255, -256, 255, -256, 255, -256, 255,
                ]));
              if (k)
                for (u = 0; u < y; )
                  for (d = 0, f = 0; d < b; d++, u++, f += 2)
                    w[u] = ((w[u] * k[f]) >> 8) + k[f + 1];
              return w;
            }
            get _isColorConversionNeeded() {
              return this.adobe
                ? !!this.adobe.transformCode
                : 3 === this.numComponents
                ? 0 !== this._colorTransform &&
                  (82 !== this.components[0].index ||
                    71 !== this.components[1].index ||
                    66 !== this.components[2].index)
                : 1 === this._colorTransform;
            }
            _convertYccToRgb(e) {
              let t, a, r;
              for (let n = 0, i = e.length; n < i; n += 3) {
                t = e[n];
                a = e[n + 1];
                r = e[n + 2];
                e[n] = t - 179.456 + 1.402 * r;
                e[n + 1] = t + 135.459 - 0.344 * a - 0.714 * r;
                e[n + 2] = t - 226.816 + 1.772 * a;
              }
              return e;
            }
            _convertYcckToRgb(e) {
              let t,
                a,
                r,
                n,
                i = 0;
              for (let s = 0, o = e.length; s < o; s += 4) {
                t = e[s];
                a = e[s + 1];
                r = e[s + 2];
                n = e[s + 3];
                e[i++] =
                  a *
                    (-660635669420364e-19 * a +
                      0.000437130475926232 * r -
                      54080610064599e-18 * t +
                      0.00048449797120281 * n -
                      0.154362151871126) -
                  122.67195406894 +
                  r *
                    (-0.000957964378445773 * r +
                      0.000817076911346625 * t -
                      0.00477271405408747 * n +
                      1.53380253221734) +
                  t *
                    (0.000961250184130688 * t -
                      0.00266257332283933 * n +
                      0.48357088451265) +
                  n * (-0.000336197177618394 * n + 0.484791561490776);
                e[i++] =
                  107.268039397724 +
                  a *
                    (219927104525741e-19 * a -
                      0.000640992018297945 * r +
                      0.000659397001245577 * t +
                      0.000426105652938837 * n -
                      0.176491792462875) +
                  r *
                    (-0.000778269941513683 * r +
                      0.00130872261408275 * t +
                      0.000770482631801132 * n -
                      0.151051492775562) +
                  t *
                    (0.00126935368114843 * t -
                      0.00265090189010898 * n +
                      0.25802910206845) +
                  n * (-0.000318913117588328 * n - 0.213742400323665);
                e[i++] =
                  a *
                    (-0.000570115196973677 * a -
                      263409051004589e-19 * r +
                      0.0020741088115012 * t -
                      0.00288260236853442 * n +
                      0.814272968359295) -
                  20.810012546947 +
                  r *
                    (-153496057440975e-19 * r -
                      0.000132689043961446 * t +
                      0.000560833691242812 * n -
                      0.195152027534049) +
                  t *
                    (0.00174418132927582 * t -
                      0.00255243321439347 * n +
                      0.116935020465145) +
                  n * (-0.000343531996510555 * n + 0.24165260232407);
              }
              return e.subarray(0, i);
            }
            _convertYcckToCmyk(e) {
              let t, a, r;
              for (let n = 0, i = e.length; n < i; n += 4) {
                t = e[n];
                a = e[n + 1];
                r = e[n + 2];
                e[n] = 434.456 - t - 1.402 * r;
                e[n + 1] = 119.541 - t + 0.344 * a + 0.714 * r;
                e[n + 2] = 481.816 - t - 1.772 * a;
              }
              return e;
            }
            _convertCmykToRgb(e) {
              let t,
                a,
                r,
                n,
                i = 0;
              for (let s = 0, o = e.length; s < o; s += 4) {
                t = e[s];
                a = e[s + 1];
                r = e[s + 2];
                n = e[s + 3];
                e[i++] =
                  255 +
                  t *
                    (-6747147073602441e-20 * t +
                      0.0008379262121013727 * a +
                      0.0002894718188643294 * r +
                      0.003264231057537806 * n -
                      1.1185611867203937) +
                  a *
                    (26374107616089405e-21 * a -
                      8626949158638572e-20 * r -
                      0.0002748769067499491 * n -
                      0.02155688794978967) +
                  r *
                    (-3878099212869363e-20 * r -
                      0.0003267808279485286 * n +
                      0.0686742238595345) -
                  n * (0.0003361971776183937 * n + 0.7430659151342254);
                e[i++] =
                  255 +
                  t *
                    (0.00013596372813588848 * t +
                      0.000924537132573585 * a +
                      0.00010567359618683593 * r +
                      0.0004791864687436512 * n -
                      0.3109689587515875) +
                  a *
                    (-0.00023545346108370344 * a +
                      0.0002702845253534714 * r +
                      0.0020200308977307156 * n -
                      0.7488052167015494) +
                  r *
                    (6834815998235662e-20 * r +
                      0.00015168452363460973 * n -
                      0.09751927774728933) -
                  n * (0.0003189131175883281 * n + 0.7364883807733168);
                e[i++] =
                  255 +
                  t *
                    (13598650411385307e-21 * t +
                      0.00012423956175490851 * a +
                      0.0004751985097583589 * r -
                      36729317476630422e-22 * n -
                      0.05562186980264034) +
                  a *
                    (0.00016141380598724676 * a +
                      0.0009692239130725186 * r +
                      0.0007782692450036253 * n -
                      0.44015232367526463) +
                  r *
                    (5.068882914068769e-7 * r +
                      0.0017778369011375071 * n -
                      0.7591454649749609) -
                  n * (0.0003435319965105553 * n + 0.7063770186160144);
              }
              return e.subarray(0, i);
            }
            getData({
              width: e,
              height: t,
              forceRGB: a = !1,
              isSourcePDF: r = !1,
            }) {
              if (this.numComponents > 4)
                throw new JpegError("Unsupported color mode");
              const n = this._getLinearizedBlockData(e, t, r);
              if (1 === this.numComponents && a) {
                const e = new Uint8ClampedArray(3 * n.length);
                let t = 0;
                for (const a of n) {
                  e[t++] = a;
                  e[t++] = a;
                  e[t++] = a;
                }
                return e;
              }
              if (3 === this.numComponents && this._isColorConversionNeeded)
                return this._convertYccToRgb(n);
              if (4 === this.numComponents) {
                if (this._isColorConversionNeeded)
                  return a
                    ? this._convertYcckToRgb(n)
                    : this._convertYcckToCmyk(n);
                if (a) return this._convertCmykToRgb(n);
              }
              return n;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.JpxStream = void 0;
          var r = a(17),
            n = a(28),
            i = a(2);
          class JpxStream extends r.DecodeStream {
            constructor(e, t, a) {
              super(t);
              this.stream = e;
              this.dict = e.dict;
              this.maybeLength = t;
              this.params = a;
            }
            get bytes() {
              return (0, i.shadow)(
                this,
                "bytes",
                this.stream.getBytes(this.maybeLength)
              );
            }
            ensureBuffer(e) {}
            readBlock() {
              if (this.eof) return;
              const e = new n.JpxImage();
              e.parse(this.bytes);
              const t = e.width,
                a = e.height,
                r = e.componentsCount,
                i = e.tiles.length;
              if (1 === i) this.buffer = e.tiles[0].items;
              else {
                const n = new Uint8ClampedArray(t * a * r);
                for (let a = 0; a < i; a++) {
                  const i = e.tiles[a],
                    s = i.width,
                    o = i.height,
                    c = i.left,
                    l = i.top,
                    h = i.items;
                  let u = 0,
                    d = (t * l + c) * r;
                  const f = t * r,
                    g = s * r;
                  for (let e = 0; e < o; e++) {
                    const e = h.subarray(u, u + g);
                    n.set(e, d);
                    u += g;
                    d += f;
                  }
                }
                this.buffer = n;
              }
              this.bufferLength = this.buffer.length;
              this.eof = !0;
            }
          }
          t.JpxStream = JpxStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.JpxImage = void 0;
          var r = a(2),
            n = a(3),
            i = a(24);
          class JpxError extends r.BaseException {
            constructor(e) {
              super(`JPX error: ${e}`, "JpxError");
            }
          }
          const s = { LL: 0, LH: 1, HL: 1, HH: 2 };
          t.JpxImage = class JpxImage {
            constructor() {
              this.failOnCorruptedImage = !1;
            }
            parse(e) {
              if (65359 === (0, n.readUint16)(e, 0)) {
                this.parseCodestream(e, 0, e.length);
                return;
              }
              const t = e.length;
              let a = 0;
              for (; a < t; ) {
                let i = 8,
                  s = (0, n.readUint32)(e, a);
                const o = (0, n.readUint32)(e, a + 4);
                a += i;
                if (1 === s) {
                  s =
                    4294967296 * (0, n.readUint32)(e, a) +
                    (0, n.readUint32)(e, a + 4);
                  a += 8;
                  i += 8;
                }
                0 === s && (s = t - a + i);
                if (s < i) throw new JpxError("Invalid box field size");
                const c = s - i;
                let l = !0;
                switch (o) {
                  case 1785737832:
                    l = !1;
                    break;
                  case 1668246642:
                    const t = e[a];
                    if (1 === t) {
                      const t = (0, n.readUint32)(e, a + 3);
                      switch (t) {
                        case 16:
                        case 17:
                        case 18:
                          break;
                        default:
                          (0, r.warn)("Unknown colorspace " + t);
                      }
                    } else 2 === t && (0, r.info)("ICC profile not supported");
                    break;
                  case 1785737827:
                    this.parseCodestream(e, a, a + c);
                    break;
                  case 1783636e3:
                    218793738 !== (0, n.readUint32)(e, a) &&
                      (0, r.warn)("Invalid JP2 signature");
                    break;
                  case 1783634458:
                  case 1718909296:
                  case 1920099697:
                  case 1919251232:
                  case 1768449138:
                    break;
                  default:
                    const i = String.fromCharCode(
                      (o >> 24) & 255,
                      (o >> 16) & 255,
                      (o >> 8) & 255,
                      255 & o
                    );
                    (0, r.warn)(`Unsupported header type ${o} (${i}).`);
                }
                l && (a += c);
              }
            }
            parseImageProperties(e) {
              let t = e.getByte();
              for (; t >= 0; ) {
                const a = t;
                t = e.getByte();
                if (65361 === ((a << 8) | t)) {
                  e.skip(4);
                  const t = e.getInt32() >>> 0,
                    a = e.getInt32() >>> 0,
                    r = e.getInt32() >>> 0,
                    n = e.getInt32() >>> 0;
                  e.skip(16);
                  const i = e.getUint16();
                  this.width = t - r;
                  this.height = a - n;
                  this.componentsCount = i;
                  this.bitsPerComponent = 8;
                  return;
                }
              }
              throw new JpxError("No size marker found in JPX stream");
            }
            parseCodestream(e, t, a) {
              const i = {};
              let s = !1;
              try {
                let o = t;
                for (; o + 1 < a; ) {
                  const t = (0, n.readUint16)(e, o);
                  o += 2;
                  let a,
                    c,
                    l,
                    h,
                    u,
                    d,
                    f = 0;
                  switch (t) {
                    case 65359:
                      i.mainHeader = !0;
                      break;
                    case 65497:
                      break;
                    case 65361:
                      f = (0, n.readUint16)(e, o);
                      const g = {};
                      g.Xsiz = (0, n.readUint32)(e, o + 4);
                      g.Ysiz = (0, n.readUint32)(e, o + 8);
                      g.XOsiz = (0, n.readUint32)(e, o + 12);
                      g.YOsiz = (0, n.readUint32)(e, o + 16);
                      g.XTsiz = (0, n.readUint32)(e, o + 20);
                      g.YTsiz = (0, n.readUint32)(e, o + 24);
                      g.XTOsiz = (0, n.readUint32)(e, o + 28);
                      g.YTOsiz = (0, n.readUint32)(e, o + 32);
                      const p = (0, n.readUint16)(e, o + 36);
                      g.Csiz = p;
                      const m = [];
                      a = o + 38;
                      for (let t = 0; t < p; t++) {
                        const t = {
                          precision: 1 + (127 & e[a]),
                          isSigned: !!(128 & e[a]),
                          XRsiz: e[a + 1],
                          YRsiz: e[a + 2],
                        };
                        a += 3;
                        calculateComponentDimensions(t, g);
                        m.push(t);
                      }
                      i.SIZ = g;
                      i.components = m;
                      calculateTileGrids(i, m);
                      i.QCC = [];
                      i.COC = [];
                      break;
                    case 65372:
                      f = (0, n.readUint16)(e, o);
                      const b = {};
                      a = o + 2;
                      c = e[a++];
                      switch (31 & c) {
                        case 0:
                          h = 8;
                          u = !0;
                          break;
                        case 1:
                          h = 16;
                          u = !1;
                          break;
                        case 2:
                          h = 16;
                          u = !0;
                          break;
                        default:
                          throw new Error("Invalid SQcd value " + c);
                      }
                      b.noQuantization = 8 === h;
                      b.scalarExpounded = u;
                      b.guardBits = c >> 5;
                      l = [];
                      for (; a < f + o; ) {
                        const t = {};
                        if (8 === h) {
                          t.epsilon = e[a++] >> 3;
                          t.mu = 0;
                        } else {
                          t.epsilon = e[a] >> 3;
                          t.mu = ((7 & e[a]) << 8) | e[a + 1];
                          a += 2;
                        }
                        l.push(t);
                      }
                      b.SPqcds = l;
                      if (i.mainHeader) i.QCD = b;
                      else {
                        i.currentTile.QCD = b;
                        i.currentTile.QCC = [];
                      }
                      break;
                    case 65373:
                      f = (0, n.readUint16)(e, o);
                      const y = {};
                      a = o + 2;
                      let w;
                      if (i.SIZ.Csiz < 257) w = e[a++];
                      else {
                        w = (0, n.readUint16)(e, a);
                        a += 2;
                      }
                      c = e[a++];
                      switch (31 & c) {
                        case 0:
                          h = 8;
                          u = !0;
                          break;
                        case 1:
                          h = 16;
                          u = !1;
                          break;
                        case 2:
                          h = 16;
                          u = !0;
                          break;
                        default:
                          throw new Error("Invalid SQcd value " + c);
                      }
                      y.noQuantization = 8 === h;
                      y.scalarExpounded = u;
                      y.guardBits = c >> 5;
                      l = [];
                      for (; a < f + o; ) {
                        const t = {};
                        if (8 === h) {
                          t.epsilon = e[a++] >> 3;
                          t.mu = 0;
                        } else {
                          t.epsilon = e[a] >> 3;
                          t.mu = ((7 & e[a]) << 8) | e[a + 1];
                          a += 2;
                        }
                        l.push(t);
                      }
                      y.SPqcds = l;
                      i.mainHeader
                        ? (i.QCC[w] = y)
                        : (i.currentTile.QCC[w] = y);
                      break;
                    case 65362:
                      f = (0, n.readUint16)(e, o);
                      const S = {};
                      a = o + 2;
                      const x = e[a++];
                      S.entropyCoderWithCustomPrecincts = !!(1 & x);
                      S.sopMarkerUsed = !!(2 & x);
                      S.ephMarkerUsed = !!(4 & x);
                      S.progressionOrder = e[a++];
                      S.layersCount = (0, n.readUint16)(e, a);
                      a += 2;
                      S.multipleComponentTransform = e[a++];
                      S.decompositionLevelsCount = e[a++];
                      S.xcb = 2 + (15 & e[a++]);
                      S.ycb = 2 + (15 & e[a++]);
                      const C = e[a++];
                      S.selectiveArithmeticCodingBypass = !!(1 & C);
                      S.resetContextProbabilities = !!(2 & C);
                      S.terminationOnEachCodingPass = !!(4 & C);
                      S.verticallyStripe = !!(8 & C);
                      S.predictableTermination = !!(16 & C);
                      S.segmentationSymbolUsed = !!(32 & C);
                      S.reversibleTransformation = e[a++];
                      if (S.entropyCoderWithCustomPrecincts) {
                        const t = [];
                        for (; a < f + o; ) {
                          const r = e[a++];
                          t.push({ PPx: 15 & r, PPy: r >> 4 });
                        }
                        S.precinctsSizes = t;
                      }
                      const k = [];
                      S.selectiveArithmeticCodingBypass &&
                        k.push("selectiveArithmeticCodingBypass");
                      S.terminationOnEachCodingPass &&
                        k.push("terminationOnEachCodingPass");
                      S.verticallyStripe && k.push("verticallyStripe");
                      S.predictableTermination &&
                        k.push("predictableTermination");
                      if (k.length > 0) {
                        s = !0;
                        (0, r.warn)(
                          `JPX: Unsupported COD options (${k.join(", ")}).`
                        );
                      }
                      if (i.mainHeader) i.COD = S;
                      else {
                        i.currentTile.COD = S;
                        i.currentTile.COC = [];
                      }
                      break;
                    case 65424:
                      f = (0, n.readUint16)(e, o);
                      d = {};
                      d.index = (0, n.readUint16)(e, o + 2);
                      d.length = (0, n.readUint32)(e, o + 4);
                      d.dataEnd = d.length + o - 2;
                      d.partIndex = e[o + 8];
                      d.partsCount = e[o + 9];
                      i.mainHeader = !1;
                      if (0 === d.partIndex) {
                        d.COD = i.COD;
                        d.COC = i.COC.slice(0);
                        d.QCD = i.QCD;
                        d.QCC = i.QCC.slice(0);
                      }
                      i.currentTile = d;
                      break;
                    case 65427:
                      d = i.currentTile;
                      if (0 === d.partIndex) {
                        initializeTile(i, d.index);
                        buildPackets(i);
                      }
                      f = d.dataEnd - o;
                      parseTilePackets(i, e, o, f);
                      break;
                    case 65363:
                      (0, r.warn)(
                        "JPX: Codestream code 0xFF53 (COC) is not implemented."
                      );
                    case 65365:
                    case 65367:
                    case 65368:
                    case 65380:
                      f = (0, n.readUint16)(e, o);
                      break;
                    default:
                      throw new Error(
                        "Unknown codestream code: " + t.toString(16)
                      );
                  }
                  o += f;
                }
              } catch (e) {
                if (s || this.failOnCorruptedImage)
                  throw new JpxError(e.message);
                (0, r.warn)(`JPX: Trying to recover from: "${e.message}".`);
              }
              this.tiles = (function transformComponents(e) {
                const t = e.SIZ,
                  a = e.components,
                  r = t.Csiz,
                  n = [];
                for (let t = 0, i = e.tiles.length; t < i; t++) {
                  const i = e.tiles[t],
                    s = [];
                  for (let t = 0; t < r; t++) s[t] = transformTile(e, i, t);
                  const o = s[0],
                    c = new Uint8ClampedArray(o.items.length * r),
                    l = {
                      left: o.left,
                      top: o.top,
                      width: o.width,
                      height: o.height,
                      items: c,
                    };
                  let h,
                    u,
                    d,
                    f,
                    g,
                    p,
                    m,
                    b = 0;
                  if (
                    i.codingStyleDefaultParameters.multipleComponentTransform
                  ) {
                    const e = 4 === r,
                      t = s[0].items,
                      n = s[1].items,
                      o = s[2].items,
                      l = e ? s[3].items : null;
                    h = a[0].precision - 8;
                    u = 0.5 + (128 << h);
                    const y = i.components[0],
                      w = r - 3;
                    f = t.length;
                    if (y.codingStyleParameters.reversibleTransformation)
                      for (d = 0; d < f; d++, b += w) {
                        g = t[d] + u;
                        p = n[d];
                        m = o[d];
                        const e = g - ((m + p) >> 2);
                        c[b++] = (e + m) >> h;
                        c[b++] = e >> h;
                        c[b++] = (e + p) >> h;
                      }
                    else
                      for (d = 0; d < f; d++, b += w) {
                        g = t[d] + u;
                        p = n[d];
                        m = o[d];
                        c[b++] = (g + 1.402 * m) >> h;
                        c[b++] = (g - 0.34413 * p - 0.71414 * m) >> h;
                        c[b++] = (g + 1.772 * p) >> h;
                      }
                    if (e)
                      for (d = 0, b = 3; d < f; d++, b += 4)
                        c[b] = (l[d] + u) >> h;
                  } else
                    for (let e = 0; e < r; e++) {
                      const t = s[e].items;
                      h = a[e].precision - 8;
                      u = 0.5 + (128 << h);
                      for (b = e, d = 0, f = t.length; d < f; d++) {
                        c[b] = (t[d] + u) >> h;
                        b += r;
                      }
                    }
                  n.push(l);
                }
                return n;
              })(i);
              this.width = i.SIZ.Xsiz - i.SIZ.XOsiz;
              this.height = i.SIZ.Ysiz - i.SIZ.YOsiz;
              this.componentsCount = i.SIZ.Csiz;
            }
          };
          function calculateComponentDimensions(e, t) {
            e.x0 = Math.ceil(t.XOsiz / e.XRsiz);
            e.x1 = Math.ceil(t.Xsiz / e.XRsiz);
            e.y0 = Math.ceil(t.YOsiz / e.YRsiz);
            e.y1 = Math.ceil(t.Ysiz / e.YRsiz);
            e.width = e.x1 - e.x0;
            e.height = e.y1 - e.y0;
          }
          function calculateTileGrids(e, t) {
            const a = e.SIZ,
              r = [];
            let n;
            const i = Math.ceil((a.Xsiz - a.XTOsiz) / a.XTsiz),
              s = Math.ceil((a.Ysiz - a.YTOsiz) / a.YTsiz);
            for (let e = 0; e < s; e++)
              for (let t = 0; t < i; t++) {
                n = {};
                n.tx0 = Math.max(a.XTOsiz + t * a.XTsiz, a.XOsiz);
                n.ty0 = Math.max(a.YTOsiz + e * a.YTsiz, a.YOsiz);
                n.tx1 = Math.min(a.XTOsiz + (t + 1) * a.XTsiz, a.Xsiz);
                n.ty1 = Math.min(a.YTOsiz + (e + 1) * a.YTsiz, a.Ysiz);
                n.width = n.tx1 - n.tx0;
                n.height = n.ty1 - n.ty0;
                n.components = [];
                r.push(n);
              }
            e.tiles = r;
            for (let e = 0, i = a.Csiz; e < i; e++) {
              const a = t[e];
              for (let t = 0, i = r.length; t < i; t++) {
                const i = {};
                n = r[t];
                i.tcx0 = Math.ceil(n.tx0 / a.XRsiz);
                i.tcy0 = Math.ceil(n.ty0 / a.YRsiz);
                i.tcx1 = Math.ceil(n.tx1 / a.XRsiz);
                i.tcy1 = Math.ceil(n.ty1 / a.YRsiz);
                i.width = i.tcx1 - i.tcx0;
                i.height = i.tcy1 - i.tcy0;
                n.components[e] = i;
              }
            }
          }
          function getBlocksDimensions(e, t, a) {
            const r = t.codingStyleParameters,
              n = {};
            if (r.entropyCoderWithCustomPrecincts) {
              n.PPx = r.precinctsSizes[a].PPx;
              n.PPy = r.precinctsSizes[a].PPy;
            } else {
              n.PPx = 15;
              n.PPy = 15;
            }
            n.xcb_ =
              a > 0 ? Math.min(r.xcb, n.PPx - 1) : Math.min(r.xcb, n.PPx);
            n.ycb_ =
              a > 0 ? Math.min(r.ycb, n.PPy - 1) : Math.min(r.ycb, n.PPy);
            return n;
          }
          function buildPrecincts(e, t, a) {
            const r = 1 << a.PPx,
              n = 1 << a.PPy,
              i = 0 === t.resLevel,
              s = 1 << (a.PPx + (i ? 0 : -1)),
              o = 1 << (a.PPy + (i ? 0 : -1)),
              c =
                t.trx1 > t.trx0
                  ? Math.ceil(t.trx1 / r) - Math.floor(t.trx0 / r)
                  : 0,
              l =
                t.try1 > t.try0
                  ? Math.ceil(t.try1 / n) - Math.floor(t.try0 / n)
                  : 0,
              h = c * l;
            t.precinctParameters = {
              precinctWidth: r,
              precinctHeight: n,
              numprecinctswide: c,
              numprecinctshigh: l,
              numprecincts: h,
              precinctWidthInSubband: s,
              precinctHeightInSubband: o,
            };
          }
          function buildCodeblocks(e, t, a) {
            const r = a.xcb_,
              n = a.ycb_,
              i = 1 << r,
              s = 1 << n,
              o = t.tbx0 >> r,
              c = t.tby0 >> n,
              l = (t.tbx1 + i - 1) >> r,
              h = (t.tby1 + s - 1) >> n,
              u = t.resolution.precinctParameters,
              d = [],
              f = [];
            let g, p, m, b;
            for (p = c; p < h; p++)
              for (g = o; g < l; g++) {
                m = {
                  cbx: g,
                  cby: p,
                  tbx0: i * g,
                  tby0: s * p,
                  tbx1: i * (g + 1),
                  tby1: s * (p + 1),
                };
                m.tbx0_ = Math.max(t.tbx0, m.tbx0);
                m.tby0_ = Math.max(t.tby0, m.tby0);
                m.tbx1_ = Math.min(t.tbx1, m.tbx1);
                m.tby1_ = Math.min(t.tby1, m.tby1);
                b =
                  Math.floor((m.tbx0_ - t.tbx0) / u.precinctWidthInSubband) +
                  Math.floor((m.tby0_ - t.tby0) / u.precinctHeightInSubband) *
                    u.numprecinctswide;
                m.precinctNumber = b;
                m.subbandType = t.type;
                m.Lblock = 3;
                if (m.tbx1_ <= m.tbx0_ || m.tby1_ <= m.tby0_) continue;
                d.push(m);
                let e = f[b];
                if (void 0 !== e) {
                  g < e.cbxMin
                    ? (e.cbxMin = g)
                    : g > e.cbxMax && (e.cbxMax = g);
                  p < e.cbyMin
                    ? (e.cbxMin = p)
                    : p > e.cbyMax && (e.cbyMax = p);
                } else
                  f[b] = e = { cbxMin: g, cbyMin: p, cbxMax: g, cbyMax: p };
                m.precinct = e;
              }
            t.codeblockParameters = {
              codeblockWidth: r,
              codeblockHeight: n,
              numcodeblockwide: l - o + 1,
              numcodeblockhigh: h - c + 1,
            };
            t.codeblocks = d;
            t.precincts = f;
          }
          function createPacket(e, t, a) {
            const r = [],
              n = e.subbands;
            for (let e = 0, a = n.length; e < a; e++) {
              const a = n[e].codeblocks;
              for (let e = 0, n = a.length; e < n; e++) {
                const n = a[e];
                n.precinctNumber === t && r.push(n);
              }
            }
            return { layerNumber: a, codeblocks: r };
          }
          function LayerResolutionComponentPositionIterator(e) {
            const t = e.SIZ,
              a = e.currentTile.index,
              r = e.tiles[a],
              n = r.codingStyleDefaultParameters.layersCount,
              i = t.Csiz;
            let s = 0;
            for (let e = 0; e < i; e++)
              s = Math.max(
                s,
                r.components[e].codingStyleParameters.decompositionLevelsCount
              );
            let o = 0,
              c = 0,
              l = 0,
              h = 0;
            this.nextPacket = function JpxImage_nextPacket() {
              for (; o < n; o++) {
                for (; c <= s; c++) {
                  for (; l < i; l++) {
                    const e = r.components[l];
                    if (c > e.codingStyleParameters.decompositionLevelsCount)
                      continue;
                    const t = e.resolutions[c],
                      a = t.precinctParameters.numprecincts;
                    for (; h < a; ) {
                      const e = createPacket(t, h, o);
                      h++;
                      return e;
                    }
                    h = 0;
                  }
                  l = 0;
                }
                c = 0;
              }
              throw new JpxError("Out of packets");
            };
          }
          function ResolutionLayerComponentPositionIterator(e) {
            const t = e.SIZ,
              a = e.currentTile.index,
              r = e.tiles[a],
              n = r.codingStyleDefaultParameters.layersCount,
              i = t.Csiz;
            let s = 0;
            for (let e = 0; e < i; e++)
              s = Math.max(
                s,
                r.components[e].codingStyleParameters.decompositionLevelsCount
              );
            let o = 0,
              c = 0,
              l = 0,
              h = 0;
            this.nextPacket = function JpxImage_nextPacket() {
              for (; o <= s; o++) {
                for (; c < n; c++) {
                  for (; l < i; l++) {
                    const e = r.components[l];
                    if (o > e.codingStyleParameters.decompositionLevelsCount)
                      continue;
                    const t = e.resolutions[o],
                      a = t.precinctParameters.numprecincts;
                    for (; h < a; ) {
                      const e = createPacket(t, h, c);
                      h++;
                      return e;
                    }
                    h = 0;
                  }
                  l = 0;
                }
                c = 0;
              }
              throw new JpxError("Out of packets");
            };
          }
          function ResolutionPositionComponentLayerIterator(e) {
            const t = e.SIZ,
              a = e.currentTile.index,
              r = e.tiles[a],
              n = r.codingStyleDefaultParameters.layersCount,
              i = t.Csiz;
            let s,
              o,
              c,
              l,
              h = 0;
            for (c = 0; c < i; c++) {
              const e = r.components[c];
              h = Math.max(h, e.codingStyleParameters.decompositionLevelsCount);
            }
            const u = new Int32Array(h + 1);
            for (o = 0; o <= h; ++o) {
              let e = 0;
              for (c = 0; c < i; ++c) {
                const t = r.components[c].resolutions;
                o < t.length &&
                  (e = Math.max(e, t[o].precinctParameters.numprecincts));
              }
              u[o] = e;
            }
            s = 0;
            o = 0;
            c = 0;
            l = 0;
            this.nextPacket = function JpxImage_nextPacket() {
              for (; o <= h; o++) {
                for (; l < u[o]; l++) {
                  for (; c < i; c++) {
                    const e = r.components[c];
                    if (o > e.codingStyleParameters.decompositionLevelsCount)
                      continue;
                    const t = e.resolutions[o],
                      a = t.precinctParameters.numprecincts;
                    if (!(l >= a)) {
                      for (; s < n; ) {
                        const e = createPacket(t, l, s);
                        s++;
                        return e;
                      }
                      s = 0;
                    }
                  }
                  c = 0;
                }
                l = 0;
              }
              throw new JpxError("Out of packets");
            };
          }
          function PositionComponentResolutionLayerIterator(e) {
            const t = e.SIZ,
              a = e.currentTile.index,
              r = e.tiles[a],
              n = r.codingStyleDefaultParameters.layersCount,
              i = t.Csiz,
              s = getPrecinctSizesInImageScale(r),
              o = s;
            let c = 0,
              l = 0,
              h = 0,
              u = 0,
              d = 0;
            this.nextPacket = function JpxImage_nextPacket() {
              for (; d < o.maxNumHigh; d++) {
                for (; u < o.maxNumWide; u++) {
                  for (; h < i; h++) {
                    const e = r.components[h],
                      t = e.codingStyleParameters.decompositionLevelsCount;
                    for (; l <= t; l++) {
                      const t = e.resolutions[l],
                        a = s.components[h].resolutions[l],
                        r = getPrecinctIndexIfExist(u, d, a, o, t);
                      if (null !== r) {
                        for (; c < n; ) {
                          const e = createPacket(t, r, c);
                          c++;
                          return e;
                        }
                        c = 0;
                      }
                    }
                    l = 0;
                  }
                  h = 0;
                }
                u = 0;
              }
              throw new JpxError("Out of packets");
            };
          }
          function ComponentPositionResolutionLayerIterator(e) {
            const t = e.SIZ,
              a = e.currentTile.index,
              r = e.tiles[a],
              n = r.codingStyleDefaultParameters.layersCount,
              i = t.Csiz,
              s = getPrecinctSizesInImageScale(r);
            let o = 0,
              c = 0,
              l = 0,
              h = 0,
              u = 0;
            this.nextPacket = function JpxImage_nextPacket() {
              for (; l < i; ++l) {
                const e = r.components[l],
                  t = s.components[l],
                  a = e.codingStyleParameters.decompositionLevelsCount;
                for (; u < t.maxNumHigh; u++) {
                  for (; h < t.maxNumWide; h++) {
                    for (; c <= a; c++) {
                      const a = e.resolutions[c],
                        r = t.resolutions[c],
                        i = getPrecinctIndexIfExist(h, u, r, t, a);
                      if (null !== i) {
                        for (; o < n; ) {
                          const e = createPacket(a, i, o);
                          o++;
                          return e;
                        }
                        o = 0;
                      }
                    }
                    c = 0;
                  }
                  h = 0;
                }
                u = 0;
              }
              throw new JpxError("Out of packets");
            };
          }
          function getPrecinctIndexIfExist(e, t, a, r, n) {
            const i = e * r.minWidth,
              s = t * r.minHeight;
            if (i % a.width != 0 || s % a.height != 0) return null;
            const o = (s / a.width) * n.precinctParameters.numprecinctswide;
            return i / a.height + o;
          }
          function getPrecinctSizesInImageScale(e) {
            const t = e.components.length;
            let a = Number.MAX_VALUE,
              r = Number.MAX_VALUE,
              n = 0,
              i = 0;
            const s = new Array(t);
            for (let o = 0; o < t; o++) {
              const t = e.components[o],
                c = t.codingStyleParameters.decompositionLevelsCount,
                l = new Array(c + 1);
              let h = Number.MAX_VALUE,
                u = Number.MAX_VALUE,
                d = 0,
                f = 0,
                g = 1;
              for (let e = c; e >= 0; --e) {
                const a = t.resolutions[e],
                  r = g * a.precinctParameters.precinctWidth,
                  n = g * a.precinctParameters.precinctHeight;
                h = Math.min(h, r);
                u = Math.min(u, n);
                d = Math.max(d, a.precinctParameters.numprecinctswide);
                f = Math.max(f, a.precinctParameters.numprecinctshigh);
                l[e] = { width: r, height: n };
                g <<= 1;
              }
              a = Math.min(a, h);
              r = Math.min(r, u);
              n = Math.max(n, d);
              i = Math.max(i, f);
              s[o] = {
                resolutions: l,
                minWidth: h,
                minHeight: u,
                maxNumWide: d,
                maxNumHigh: f,
              };
            }
            return {
              components: s,
              minWidth: a,
              minHeight: r,
              maxNumWide: n,
              maxNumHigh: i,
            };
          }
          function buildPackets(e) {
            const t = e.SIZ,
              a = e.currentTile.index,
              r = e.tiles[a],
              n = t.Csiz;
            for (let e = 0; e < n; e++) {
              const t = r.components[e],
                a = t.codingStyleParameters.decompositionLevelsCount,
                n = [],
                i = [];
              for (let e = 0; e <= a; e++) {
                const r = getBlocksDimensions(0, t, e),
                  s = {},
                  o = 1 << (a - e);
                s.trx0 = Math.ceil(t.tcx0 / o);
                s.try0 = Math.ceil(t.tcy0 / o);
                s.trx1 = Math.ceil(t.tcx1 / o);
                s.try1 = Math.ceil(t.tcy1 / o);
                s.resLevel = e;
                buildPrecincts(0, s, r);
                n.push(s);
                let c;
                if (0 === e) {
                  c = {};
                  c.type = "LL";
                  c.tbx0 = Math.ceil(t.tcx0 / o);
                  c.tby0 = Math.ceil(t.tcy0 / o);
                  c.tbx1 = Math.ceil(t.tcx1 / o);
                  c.tby1 = Math.ceil(t.tcy1 / o);
                  c.resolution = s;
                  buildCodeblocks(0, c, r);
                  i.push(c);
                  s.subbands = [c];
                } else {
                  const n = 1 << (a - e + 1),
                    o = [];
                  c = {};
                  c.type = "HL";
                  c.tbx0 = Math.ceil(t.tcx0 / n - 0.5);
                  c.tby0 = Math.ceil(t.tcy0 / n);
                  c.tbx1 = Math.ceil(t.tcx1 / n - 0.5);
                  c.tby1 = Math.ceil(t.tcy1 / n);
                  c.resolution = s;
                  buildCodeblocks(0, c, r);
                  i.push(c);
                  o.push(c);
                  c = {};
                  c.type = "LH";
                  c.tbx0 = Math.ceil(t.tcx0 / n);
                  c.tby0 = Math.ceil(t.tcy0 / n - 0.5);
                  c.tbx1 = Math.ceil(t.tcx1 / n);
                  c.tby1 = Math.ceil(t.tcy1 / n - 0.5);
                  c.resolution = s;
                  buildCodeblocks(0, c, r);
                  i.push(c);
                  o.push(c);
                  c = {};
                  c.type = "HH";
                  c.tbx0 = Math.ceil(t.tcx0 / n - 0.5);
                  c.tby0 = Math.ceil(t.tcy0 / n - 0.5);
                  c.tbx1 = Math.ceil(t.tcx1 / n - 0.5);
                  c.tby1 = Math.ceil(t.tcy1 / n - 0.5);
                  c.resolution = s;
                  buildCodeblocks(0, c, r);
                  i.push(c);
                  o.push(c);
                  s.subbands = o;
                }
              }
              t.resolutions = n;
              t.subbands = i;
            }
            const i = r.codingStyleDefaultParameters.progressionOrder;
            switch (i) {
              case 0:
                r.packetsIterator =
                  new LayerResolutionComponentPositionIterator(e);
                break;
              case 1:
                r.packetsIterator =
                  new ResolutionLayerComponentPositionIterator(e);
                break;
              case 2:
                r.packetsIterator =
                  new ResolutionPositionComponentLayerIterator(e);
                break;
              case 3:
                r.packetsIterator =
                  new PositionComponentResolutionLayerIterator(e);
                break;
              case 4:
                r.packetsIterator =
                  new ComponentPositionResolutionLayerIterator(e);
                break;
              default:
                throw new JpxError(`Unsupported progression order ${i}`);
            }
          }
          function parseTilePackets(e, t, a, r) {
            let i,
              s = 0,
              o = 0,
              c = !1;
            function readBits(e) {
              for (; o < e; ) {
                const e = t[a + s];
                s++;
                if (c) {
                  i = (i << 7) | e;
                  o += 7;
                  c = !1;
                } else {
                  i = (i << 8) | e;
                  o += 8;
                }
                255 === e && (c = !0);
              }
              o -= e;
              return (i >>> o) & ((1 << e) - 1);
            }
            function skipMarkerIfEqual(e) {
              if (255 === t[a + s - 1] && t[a + s] === e) {
                skipBytes(1);
                return !0;
              }
              if (255 === t[a + s] && t[a + s + 1] === e) {
                skipBytes(2);
                return !0;
              }
              return !1;
            }
            function skipBytes(e) {
              s += e;
            }
            function alignToByte() {
              o = 0;
              if (c) {
                s++;
                c = !1;
              }
            }
            function readCodingpasses() {
              if (0 === readBits(1)) return 1;
              if (0 === readBits(1)) return 2;
              let e = readBits(2);
              if (e < 3) return e + 3;
              e = readBits(5);
              if (e < 31) return e + 6;
              e = readBits(7);
              return e + 37;
            }
            const l = e.currentTile.index,
              h = e.tiles[l],
              u = e.COD.sopMarkerUsed,
              d = e.COD.ephMarkerUsed,
              f = h.packetsIterator;
            for (; s < r; ) {
              alignToByte();
              u && skipMarkerIfEqual(145) && skipBytes(4);
              const e = f.nextPacket();
              if (!readBits(1)) continue;
              const r = e.layerNumber,
                i = [];
              let o;
              for (let t = 0, a = e.codeblocks.length; t < a; t++) {
                o = e.codeblocks[t];
                let a = o.precinct;
                const s = o.cbx - a.cbxMin,
                  c = o.cby - a.cbyMin;
                let l,
                  h,
                  u = !1,
                  d = !1;
                if (void 0 !== o.included) u = !!readBits(1);
                else {
                  a = o.precinct;
                  let e;
                  if (void 0 !== a.inclusionTree) e = a.inclusionTree;
                  else {
                    const t = a.cbxMax - a.cbxMin + 1,
                      n = a.cbyMax - a.cbyMin + 1;
                    e = new InclusionTree(t, n, r);
                    h = new TagTree(t, n);
                    a.inclusionTree = e;
                    a.zeroBitPlanesTree = h;
                    for (let e = 0; e < r; e++)
                      if (0 !== readBits(1))
                        throw new JpxError("Invalid tag tree");
                  }
                  if (e.reset(s, c, r))
                    for (;;) {
                      if (!readBits(1)) {
                        e.incrementValue(r);
                        break;
                      }
                      l = !e.nextLevel();
                      if (l) {
                        o.included = !0;
                        u = d = !0;
                        break;
                      }
                    }
                }
                if (!u) continue;
                if (d) {
                  h = a.zeroBitPlanesTree;
                  h.reset(s, c);
                  for (;;)
                    if (readBits(1)) {
                      l = !h.nextLevel();
                      if (l) break;
                    } else h.incrementValue();
                  o.zeroBitPlanes = h.value;
                }
                const f = readCodingpasses();
                for (; readBits(1); ) o.Lblock++;
                const g = (0, n.log2)(f),
                  p = readBits((f < 1 << g ? g - 1 : g) + o.Lblock);
                i.push({ codeblock: o, codingpasses: f, dataLength: p });
              }
              alignToByte();
              d && skipMarkerIfEqual(146);
              for (; i.length > 0; ) {
                const e = i.shift();
                o = e.codeblock;
                void 0 === o.data && (o.data = []);
                o.data.push({
                  data: t,
                  start: a + s,
                  end: a + s + e.dataLength,
                  codingpasses: e.codingpasses,
                });
                s += e.dataLength;
              }
            }
            return s;
          }
          function copyCoefficients(e, t, a, r, n, s, c, l, h) {
            const u = r.tbx0,
              d = r.tby0,
              f = r.tbx1 - r.tbx0,
              g = r.codeblocks,
              p = "H" === r.type.charAt(0) ? 1 : 0,
              m = "H" === r.type.charAt(1) ? t : 0;
            for (let a = 0, b = g.length; a < b; ++a) {
              const b = g[a],
                y = b.tbx1_ - b.tbx0_,
                w = b.tby1_ - b.tby0_;
              if (0 === y || 0 === w) continue;
              if (void 0 === b.data) continue;
              const S = new o(y, w, b.subbandType, b.zeroBitPlanes, s);
              let x = 2;
              const C = b.data;
              let k,
                v,
                F,
                O = 0,
                T = 0;
              for (k = 0, v = C.length; k < v; k++) {
                F = C[k];
                O += F.end - F.start;
                T += F.codingpasses;
              }
              const M = new Uint8Array(O);
              let E = 0;
              for (k = 0, v = C.length; k < v; k++) {
                F = C[k];
                const e = F.data.subarray(F.start, F.end);
                M.set(e, E);
                E += e.length;
              }
              const D = new i.ArithmeticDecoder(M, 0, O);
              S.setDecoder(D);
              for (k = 0; k < T; k++) {
                switch (x) {
                  case 0:
                    S.runSignificancePropagationPass();
                    break;
                  case 1:
                    S.runMagnitudeRefinementPass();
                    break;
                  case 2:
                    S.runCleanupPass();
                    l && S.checkSegmentationSymbol();
                }
                h && S.reset();
                x = (x + 1) % 3;
              }
              let N = b.tbx0_ - u + (b.tby0_ - d) * f;
              const R = S.coefficentsSign,
                L = S.coefficentsMagnitude,
                j = S.bitsDecoded,
                $ = c ? 0 : 0.5;
              let _, U, X;
              E = 0;
              const H = "LL" !== r.type;
              for (k = 0; k < w; k++) {
                const a = 2 * ((N / f) | 0) * (t - f) + p + m;
                for (_ = 0; _ < y; _++) {
                  U = L[E];
                  if (0 !== U) {
                    U = (U + $) * n;
                    0 !== R[E] && (U = -U);
                    X = j[E];
                    const t = H ? a + (N << 1) : N;
                    e[t] = c && X >= s ? U : U * (1 << (s - X));
                  }
                  N++;
                  E++;
                }
                N += f - y;
              }
            }
          }
          function transformTile(e, t, a) {
            const r = t.components[a],
              n = r.codingStyleParameters,
              i = r.quantizationParameters,
              o = n.decompositionLevelsCount,
              c = i.SPqcds,
              l = i.scalarExpounded,
              h = i.guardBits,
              u = n.segmentationSymbolUsed,
              d = n.resetContextProbabilities,
              f = e.components[a].precision,
              g = n.reversibleTransformation,
              p = g ? new ReversibleTransform() : new IrreversibleTransform(),
              m = [];
            let b = 0;
            for (let e = 0; e <= o; e++) {
              const t = r.resolutions[e],
                a = t.trx1 - t.trx0,
                n = t.try1 - t.try0,
                i = new Float32Array(a * n);
              for (let r = 0, n = t.subbands.length; r < n; r++) {
                let n, o;
                if (l) {
                  n = c[b].mu;
                  o = c[b].epsilon;
                  b++;
                } else {
                  n = c[0].mu;
                  o = c[0].epsilon + (e > 0 ? 1 - e : 0);
                }
                const p = t.subbands[r],
                  m = s[p.type];
                copyCoefficients(
                  i,
                  a,
                  0,
                  p,
                  g ? 1 : 2 ** (f + m - o) * (1 + n / 2048),
                  h + o - 1,
                  g,
                  u,
                  d
                );
              }
              m.push({ width: a, height: n, items: i });
            }
            const y = p.calculate(m, r.tcx0, r.tcy0);
            return {
              left: r.tcx0,
              top: r.tcy0,
              width: y.width,
              height: y.height,
              items: y.items,
            };
          }
          function initializeTile(e, t) {
            const a = e.SIZ.Csiz,
              r = e.tiles[t];
            for (let t = 0; t < a; t++) {
              const a = r.components[t],
                n =
                  void 0 !== e.currentTile.QCC[t]
                    ? e.currentTile.QCC[t]
                    : e.currentTile.QCD;
              a.quantizationParameters = n;
              const i =
                void 0 !== e.currentTile.COC[t]
                  ? e.currentTile.COC[t]
                  : e.currentTile.COD;
              a.codingStyleParameters = i;
            }
            r.codingStyleDefaultParameters = e.currentTile.COD;
          }
          class TagTree {
            constructor(e, t) {
              const a = (0, n.log2)(Math.max(e, t)) + 1;
              this.levels = [];
              for (let r = 0; r < a; r++) {
                const a = { width: e, height: t, items: [] };
                this.levels.push(a);
                e = Math.ceil(e / 2);
                t = Math.ceil(t / 2);
              }
            }
            reset(e, t) {
              let a,
                r = 0,
                n = 0;
              for (; r < this.levels.length; ) {
                a = this.levels[r];
                const i = e + t * a.width;
                if (void 0 !== a.items[i]) {
                  n = a.items[i];
                  break;
                }
                a.index = i;
                e >>= 1;
                t >>= 1;
                r++;
              }
              r--;
              a = this.levels[r];
              a.items[a.index] = n;
              this.currentLevel = r;
              delete this.value;
            }
            incrementValue() {
              const e = this.levels[this.currentLevel];
              e.items[e.index]++;
            }
            nextLevel() {
              let e = this.currentLevel,
                t = this.levels[e];
              const a = t.items[t.index];
              e--;
              if (e < 0) {
                this.value = a;
                return !1;
              }
              this.currentLevel = e;
              t = this.levels[e];
              t.items[t.index] = a;
              return !0;
            }
          }
          class InclusionTree {
            constructor(e, t, a) {
              const r = (0, n.log2)(Math.max(e, t)) + 1;
              this.levels = [];
              for (let n = 0; n < r; n++) {
                const r = new Uint8Array(e * t);
                for (let e = 0, t = r.length; e < t; e++) r[e] = a;
                const n = { width: e, height: t, items: r };
                this.levels.push(n);
                e = Math.ceil(e / 2);
                t = Math.ceil(t / 2);
              }
            }
            reset(e, t, a) {
              let r = 0;
              for (; r < this.levels.length; ) {
                const n = this.levels[r],
                  i = e + t * n.width;
                n.index = i;
                const s = n.items[i];
                if (255 === s) break;
                if (s > a) {
                  this.currentLevel = r;
                  this.propagateValues();
                  return !1;
                }
                e >>= 1;
                t >>= 1;
                r++;
              }
              this.currentLevel = r - 1;
              return !0;
            }
            incrementValue(e) {
              const t = this.levels[this.currentLevel];
              t.items[t.index] = e + 1;
              this.propagateValues();
            }
            propagateValues() {
              let e = this.currentLevel,
                t = this.levels[e];
              const a = t.items[t.index];
              for (; --e >= 0; ) {
                t = this.levels[e];
                t.items[t.index] = a;
              }
            }
            nextLevel() {
              let e = this.currentLevel,
                t = this.levels[e];
              const a = t.items[t.index];
              t.items[t.index] = 255;
              e--;
              if (e < 0) return !1;
              this.currentLevel = e;
              t = this.levels[e];
              t.items[t.index] = a;
              return !0;
            }
          }
          const o = (function BitModelClosure() {
            const e = 17,
              t = new Uint8Array([
                0, 5, 8, 0, 3, 7, 8, 0, 4, 7, 8, 0, 0, 0, 0, 0, 1, 6, 8, 0, 3,
                7, 8, 0, 4, 7, 8, 0, 0, 0, 0, 0, 2, 6, 8, 0, 3, 7, 8, 0, 4, 7,
                8, 0, 0, 0, 0, 0, 2, 6, 8, 0, 3, 7, 8, 0, 4, 7, 8, 0, 0, 0, 0,
                0, 2, 6, 8, 0, 3, 7, 8, 0, 4, 7, 8,
              ]),
              a = new Uint8Array([
                0, 3, 4, 0, 5, 7, 7, 0, 8, 8, 8, 0, 0, 0, 0, 0, 1, 3, 4, 0, 6,
                7, 7, 0, 8, 8, 8, 0, 0, 0, 0, 0, 2, 3, 4, 0, 6, 7, 7, 0, 8, 8,
                8, 0, 0, 0, 0, 0, 2, 3, 4, 0, 6, 7, 7, 0, 8, 8, 8, 0, 0, 0, 0,
                0, 2, 3, 4, 0, 6, 7, 7, 0, 8, 8, 8,
              ]),
              r = new Uint8Array([
                0, 1, 2, 0, 1, 2, 2, 0, 2, 2, 2, 0, 0, 0, 0, 0, 3, 4, 5, 0, 4,
                5, 5, 0, 5, 5, 5, 0, 0, 0, 0, 0, 6, 7, 7, 0, 7, 7, 7, 0, 7, 7,
                7, 0, 0, 0, 0, 0, 8, 8, 8, 0, 8, 8, 8, 0, 8, 8, 8, 0, 0, 0, 0,
                0, 8, 8, 8, 0, 8, 8, 8, 0, 8, 8, 8,
              ]);
            return class BitModel {
              constructor(e, n, i, s, o) {
                this.width = e;
                this.height = n;
                let c;
                c = "HH" === i ? r : "HL" === i ? a : t;
                this.contextLabelTable = c;
                const l = e * n;
                this.neighborsSignificance = new Uint8Array(l);
                this.coefficentsSign = new Uint8Array(l);
                let h;
                h =
                  o > 14
                    ? new Uint32Array(l)
                    : o > 6
                    ? new Uint16Array(l)
                    : new Uint8Array(l);
                this.coefficentsMagnitude = h;
                this.processingFlags = new Uint8Array(l);
                const u = new Uint8Array(l);
                if (0 !== s) for (let e = 0; e < l; e++) u[e] = s;
                this.bitsDecoded = u;
                this.reset();
              }
              setDecoder(e) {
                this.decoder = e;
              }
              reset() {
                this.contexts = new Int8Array(19);
                this.contexts[0] = 8;
                this.contexts[17] = 92;
                this.contexts[18] = 6;
              }
              setNeighborsSignificance(e, t, a) {
                const r = this.neighborsSignificance,
                  n = this.width,
                  i = this.height,
                  s = t > 0,
                  o = t + 1 < n;
                let c;
                if (e > 0) {
                  c = a - n;
                  s && (r[c - 1] += 16);
                  o && (r[c + 1] += 16);
                  r[c] += 4;
                }
                if (e + 1 < i) {
                  c = a + n;
                  s && (r[c - 1] += 16);
                  o && (r[c + 1] += 16);
                  r[c] += 4;
                }
                s && (r[a - 1] += 1);
                o && (r[a + 1] += 1);
                r[a] |= 128;
              }
              runSignificancePropagationPass() {
                const e = this.decoder,
                  t = this.width,
                  a = this.height,
                  r = this.coefficentsMagnitude,
                  n = this.coefficentsSign,
                  i = this.neighborsSignificance,
                  s = this.processingFlags,
                  o = this.contexts,
                  c = this.contextLabelTable,
                  l = this.bitsDecoded;
                for (let h = 0; h < a; h += 4)
                  for (let u = 0; u < t; u++) {
                    let d = h * t + u;
                    for (let f = 0; f < 4; f++, d += t) {
                      const t = h + f;
                      if (t >= a) break;
                      s[d] &= -2;
                      if (r[d] || !i[d]) continue;
                      const g = c[i[d]];
                      if (e.readBit(o, g)) {
                        const e = this.decodeSignBit(t, u, d);
                        n[d] = e;
                        r[d] = 1;
                        this.setNeighborsSignificance(t, u, d);
                        s[d] |= 2;
                      }
                      l[d]++;
                      s[d] |= 1;
                    }
                  }
              }
              decodeSignBit(e, t, a) {
                const r = this.width,
                  n = this.height,
                  i = this.coefficentsMagnitude,
                  s = this.coefficentsSign;
                let o, c, l, h, u, d;
                h = t > 0 && 0 !== i[a - 1];
                if (t + 1 < r && 0 !== i[a + 1]) {
                  l = s[a + 1];
                  if (h) {
                    c = s[a - 1];
                    o = 1 - l - c;
                  } else o = 1 - l - l;
                } else if (h) {
                  c = s[a - 1];
                  o = 1 - c - c;
                } else o = 0;
                const f = 3 * o;
                h = e > 0 && 0 !== i[a - r];
                if (e + 1 < n && 0 !== i[a + r]) {
                  l = s[a + r];
                  if (h) {
                    c = s[a - r];
                    o = 1 - l - c + f;
                  } else o = 1 - l - l + f;
                } else if (h) {
                  c = s[a - r];
                  o = 1 - c - c + f;
                } else o = f;
                if (o >= 0) {
                  u = 9 + o;
                  d = this.decoder.readBit(this.contexts, u);
                } else {
                  u = 9 - o;
                  d = 1 ^ this.decoder.readBit(this.contexts, u);
                }
                return d;
              }
              runMagnitudeRefinementPass() {
                const e = this.decoder,
                  t = this.width,
                  a = this.height,
                  r = this.coefficentsMagnitude,
                  n = this.neighborsSignificance,
                  i = this.contexts,
                  s = this.bitsDecoded,
                  o = this.processingFlags,
                  c = t * a,
                  l = 4 * t;
                for (let a, h = 0; h < c; h = a) {
                  a = Math.min(c, h + l);
                  for (let c = 0; c < t; c++)
                    for (let l = h + c; l < a; l += t) {
                      if (!r[l] || 0 != (1 & o[l])) continue;
                      let t = 16;
                      if (0 != (2 & o[l])) {
                        o[l] ^= 2;
                        t = 0 === (127 & n[l]) ? 15 : 14;
                      }
                      const a = e.readBit(i, t);
                      r[l] = (r[l] << 1) | a;
                      s[l]++;
                      o[l] |= 1;
                    }
                }
              }
              runCleanupPass() {
                const t = this.decoder,
                  a = this.width,
                  r = this.height,
                  n = this.neighborsSignificance,
                  i = this.coefficentsMagnitude,
                  s = this.coefficentsSign,
                  o = this.contexts,
                  c = this.contextLabelTable,
                  l = this.bitsDecoded,
                  h = this.processingFlags,
                  u = a,
                  d = 2 * a,
                  f = 3 * a;
                let g;
                for (let p = 0; p < r; p = g) {
                  g = Math.min(p + 4, r);
                  const m = p * a,
                    b = p + 3 < r;
                  for (let r = 0; r < a; r++) {
                    const y = m + r;
                    let w,
                      S = 0,
                      x = y,
                      C = p;
                    if (
                      b &&
                      0 === h[y] &&
                      0 === h[y + u] &&
                      0 === h[y + d] &&
                      0 === h[y + f] &&
                      0 === n[y] &&
                      0 === n[y + u] &&
                      0 === n[y + d] &&
                      0 === n[y + f]
                    ) {
                      if (!t.readBit(o, 18)) {
                        l[y]++;
                        l[y + u]++;
                        l[y + d]++;
                        l[y + f]++;
                        continue;
                      }
                      S = (t.readBit(o, e) << 1) | t.readBit(o, e);
                      if (0 !== S) {
                        C = p + S;
                        x += S * a;
                      }
                      w = this.decodeSignBit(C, r, x);
                      s[x] = w;
                      i[x] = 1;
                      this.setNeighborsSignificance(C, r, x);
                      h[x] |= 2;
                      x = y;
                      for (let e = p; e <= C; e++, x += a) l[x]++;
                      S++;
                    }
                    for (C = p + S; C < g; C++, x += a) {
                      if (i[x] || 0 != (1 & h[x])) continue;
                      const e = c[n[x]];
                      if (1 === t.readBit(o, e)) {
                        w = this.decodeSignBit(C, r, x);
                        s[x] = w;
                        i[x] = 1;
                        this.setNeighborsSignificance(C, r, x);
                        h[x] |= 2;
                      }
                      l[x]++;
                    }
                  }
                }
              }
              checkSegmentationSymbol() {
                const t = this.decoder,
                  a = this.contexts;
                if (
                  10 !==
                  ((t.readBit(a, e) << 3) |
                    (t.readBit(a, e) << 2) |
                    (t.readBit(a, e) << 1) |
                    t.readBit(a, e))
                )
                  throw new JpxError("Invalid segmentation symbol");
              }
            };
          })();
          class Transform {
            constructor() {
              this.constructor === Transform &&
                (0, r.unreachable)("Cannot initialize Transform.");
            }
            calculate(e, t, a) {
              let r = e[0];
              for (let n = 1, i = e.length; n < i; n++)
                r = this.iterate(r, e[n], t, a);
              return r;
            }
            extend(e, t, a) {
              let r = t - 1,
                n = t + 1,
                i = t + a - 2,
                s = t + a;
              e[r--] = e[n++];
              e[s++] = e[i--];
              e[r--] = e[n++];
              e[s++] = e[i--];
              e[r--] = e[n++];
              e[s++] = e[i--];
              e[r] = e[n];
              e[s] = e[i];
            }
            filter(e, t, a) {
              (0, r.unreachable)("Abstract method `filter` called");
            }
            iterate(e, t, a, r) {
              const n = e.width,
                i = e.height;
              let s = e.items;
              const o = t.width,
                c = t.height,
                l = t.items;
              let h, u, d, f, g, p;
              for (d = 0, h = 0; h < i; h++) {
                f = 2 * h * o;
                for (u = 0; u < n; u++, d++, f += 2) l[f] = s[d];
              }
              s = e.items = null;
              const m = new Float32Array(o + 8);
              if (1 === o) {
                if (0 != (1 & a))
                  for (p = 0, d = 0; p < c; p++, d += o) l[d] *= 0.5;
              } else
                for (p = 0, d = 0; p < c; p++, d += o) {
                  m.set(l.subarray(d, d + o), 4);
                  this.extend(m, 4, o);
                  this.filter(m, 4, o);
                  l.set(m.subarray(4, 4 + o), d);
                }
              let b = 16;
              const y = [];
              for (h = 0; h < b; h++) y.push(new Float32Array(c + 8));
              let w,
                S = 0;
              e = 4 + c;
              if (1 === c) {
                if (0 != (1 & r)) for (g = 0; g < o; g++) l[g] *= 0.5;
              } else
                for (g = 0; g < o; g++) {
                  if (0 === S) {
                    b = Math.min(o - g, b);
                    for (d = g, f = 4; f < e; d += o, f++)
                      for (w = 0; w < b; w++) y[w][f] = l[d + w];
                    S = b;
                  }
                  S--;
                  const t = y[S];
                  this.extend(t, 4, c);
                  this.filter(t, 4, c);
                  if (0 === S) {
                    d = g - b + 1;
                    for (f = 4; f < e; d += o, f++)
                      for (w = 0; w < b; w++) l[d + w] = y[w][f];
                  }
                }
              return { width: o, height: c, items: l };
            }
          }
          class IrreversibleTransform extends Transform {
            filter(e, t, a) {
              const r = a >> 1;
              let n, i, s, o;
              const c = -1.586134342059924,
                l = -0.052980118572961,
                h = 0.882911075530934,
                u = 0.443506852043971,
                d = 1.230174104914001;
              n = (t |= 0) - 3;
              for (i = r + 4; i--; n += 2) e[n] *= 0.8128930661159609;
              n = t - 2;
              s = u * e[n - 1];
              for (i = r + 3; i--; n += 2) {
                o = u * e[n + 1];
                e[n] = d * e[n] - s - o;
                if (!i--) break;
                n += 2;
                s = u * e[n + 1];
                e[n] = d * e[n] - s - o;
              }
              n = t - 1;
              s = h * e[n - 1];
              for (i = r + 2; i--; n += 2) {
                o = h * e[n + 1];
                e[n] -= s + o;
                if (!i--) break;
                n += 2;
                s = h * e[n + 1];
                e[n] -= s + o;
              }
              n = t;
              s = l * e[n - 1];
              for (i = r + 1; i--; n += 2) {
                o = l * e[n + 1];
                e[n] -= s + o;
                if (!i--) break;
                n += 2;
                s = l * e[n + 1];
                e[n] -= s + o;
              }
              if (0 !== r) {
                n = t + 1;
                s = c * e[n - 1];
                for (i = r; i--; n += 2) {
                  o = c * e[n + 1];
                  e[n] -= s + o;
                  if (!i--) break;
                  n += 2;
                  s = c * e[n + 1];
                  e[n] -= s + o;
                }
              }
            }
          }
          class ReversibleTransform extends Transform {
            filter(e, t, a) {
              const r = a >> 1;
              let n, i;
              for (n = t |= 0, i = r + 1; i--; n += 2)
                e[n] -= (e[n - 1] + e[n + 1] + 2) >> 2;
              for (n = t + 1, i = r; i--; n += 2)
                e[n] += (e[n - 1] + e[n + 1]) >> 1;
            }
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.LZWStream = void 0;
          var r = a(17);
          class LZWStream extends r.DecodeStream {
            constructor(e, t, a) {
              super(t);
              this.str = e;
              this.dict = e.dict;
              this.cachedData = 0;
              this.bitsCached = 0;
              const r = 4096,
                n = {
                  earlyChange: a,
                  codeLength: 9,
                  nextCode: 258,
                  dictionaryValues: new Uint8Array(r),
                  dictionaryLengths: new Uint16Array(r),
                  dictionaryPrevCodes: new Uint16Array(r),
                  currentSequence: new Uint8Array(r),
                  currentSequenceLength: 0,
                };
              for (let e = 0; e < 256; ++e) {
                n.dictionaryValues[e] = e;
                n.dictionaryLengths[e] = 1;
              }
              this.lzwState = n;
            }
            readBits(e) {
              let t = this.bitsCached,
                a = this.cachedData;
              for (; t < e; ) {
                const e = this.str.getByte();
                if (-1 === e) {
                  this.eof = !0;
                  return null;
                }
                a = (a << 8) | e;
                t += 8;
              }
              this.bitsCached = t -= e;
              this.cachedData = a;
              this.lastCode = null;
              return (a >>> t) & ((1 << e) - 1);
            }
            readBlock() {
              let e,
                t,
                a,
                r = 1024;
              const n = this.lzwState;
              if (!n) return;
              const i = n.earlyChange;
              let s = n.nextCode;
              const o = n.dictionaryValues,
                c = n.dictionaryLengths,
                l = n.dictionaryPrevCodes;
              let h = n.codeLength,
                u = n.prevCode;
              const d = n.currentSequence;
              let f = n.currentSequenceLength,
                g = 0,
                p = this.bufferLength,
                m = this.ensureBuffer(this.bufferLength + r);
              for (e = 0; e < 512; e++) {
                const e = this.readBits(h),
                  n = f > 0;
                if (e < 256) {
                  d[0] = e;
                  f = 1;
                } else {
                  if (!(e >= 258)) {
                    if (256 === e) {
                      h = 9;
                      s = 258;
                      f = 0;
                      continue;
                    }
                    this.eof = !0;
                    delete this.lzwState;
                    break;
                  }
                  if (e < s) {
                    f = c[e];
                    for (t = f - 1, a = e; t >= 0; t--) {
                      d[t] = o[a];
                      a = l[a];
                    }
                  } else d[f++] = d[0];
                }
                if (n) {
                  l[s] = u;
                  c[s] = c[u] + 1;
                  o[s] = d[0];
                  s++;
                  h =
                    (s + i) & (s + i - 1)
                      ? h
                      : 0 |
                        Math.min(Math.log(s + i) / 0.6931471805599453 + 1, 12);
                }
                u = e;
                g += f;
                if (r < g) {
                  do {
                    r += 512;
                  } while (r < g);
                  m = this.ensureBuffer(this.bufferLength + r);
                }
                for (t = 0; t < f; t++) m[p++] = d[t];
              }
              n.nextCode = s;
              n.codeLength = h;
              n.prevCode = u;
              n.currentSequenceLength = f;
              this.bufferLength = p;
            }
          }
          t.LZWStream = LZWStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PredictorStream = void 0;
          var r = a(17),
            n = a(4),
            i = a(2);
          class PredictorStream extends r.DecodeStream {
            constructor(e, t, a) {
              super(t);
              if (!(a instanceof n.Dict)) return e;
              const r = (this.predictor = a.get("Predictor") || 1);
              if (r <= 1) return e;
              if (2 !== r && (r < 10 || r > 15))
                throw new i.FormatError(`Unsupported predictor: ${r}`);
              this.readBlock = 2 === r ? this.readBlockTiff : this.readBlockPng;
              this.str = e;
              this.dict = e.dict;
              const s = (this.colors = a.get("Colors") || 1),
                o = (this.bits = a.get("BPC", "BitsPerComponent") || 8),
                c = (this.columns = a.get("Columns") || 1);
              this.pixBytes = (s * o + 7) >> 3;
              this.rowBytes = (c * s * o + 7) >> 3;
              return this;
            }
            readBlockTiff() {
              const e = this.rowBytes,
                t = this.bufferLength,
                a = this.ensureBuffer(t + e),
                r = this.bits,
                n = this.colors,
                i = this.str.getBytes(e);
              this.eof = !i.length;
              if (this.eof) return;
              let s,
                o = 0,
                c = 0,
                l = 0,
                h = 0,
                u = t;
              if (1 === r && 1 === n)
                for (s = 0; s < e; ++s) {
                  let e = i[s] ^ o;
                  e ^= e >> 1;
                  e ^= e >> 2;
                  e ^= e >> 4;
                  o = (1 & e) << 7;
                  a[u++] = e;
                }
              else if (8 === r) {
                for (s = 0; s < n; ++s) a[u++] = i[s];
                for (; s < e; ++s) {
                  a[u] = a[u - n] + i[s];
                  u++;
                }
              } else if (16 === r) {
                const t = 2 * n;
                for (s = 0; s < t; ++s) a[u++] = i[s];
                for (; s < e; s += 2) {
                  const e =
                    ((255 & i[s]) << 8) +
                    (255 & i[s + 1]) +
                    ((255 & a[u - t]) << 8) +
                    (255 & a[u - t + 1]);
                  a[u++] = (e >> 8) & 255;
                  a[u++] = 255 & e;
                }
              } else {
                const e = new Uint8Array(n + 1),
                  u = (1 << r) - 1;
                let d = 0,
                  f = t;
                const g = this.columns;
                for (s = 0; s < g; ++s)
                  for (let t = 0; t < n; ++t) {
                    if (l < r) {
                      o = (o << 8) | (255 & i[d++]);
                      l += 8;
                    }
                    e[t] = (e[t] + (o >> (l - r))) & u;
                    l -= r;
                    c = (c << r) | e[t];
                    h += r;
                    if (h >= 8) {
                      a[f++] = (c >> (h - 8)) & 255;
                      h -= 8;
                    }
                  }
                h > 0 && (a[f++] = (c << (8 - h)) + (o & ((1 << (8 - h)) - 1)));
              }
              this.bufferLength += e;
            }
            readBlockPng() {
              const e = this.rowBytes,
                t = this.pixBytes,
                a = this.str.getByte(),
                r = this.str.getBytes(e);
              this.eof = !r.length;
              if (this.eof) return;
              const n = this.bufferLength,
                s = this.ensureBuffer(n + e);
              let o = s.subarray(n - e, n);
              0 === o.length && (o = new Uint8Array(e));
              let c,
                l,
                h,
                u = n;
              switch (a) {
                case 0:
                  for (c = 0; c < e; ++c) s[u++] = r[c];
                  break;
                case 1:
                  for (c = 0; c < t; ++c) s[u++] = r[c];
                  for (; c < e; ++c) {
                    s[u] = (s[u - t] + r[c]) & 255;
                    u++;
                  }
                  break;
                case 2:
                  for (c = 0; c < e; ++c) s[u++] = (o[c] + r[c]) & 255;
                  break;
                case 3:
                  for (c = 0; c < t; ++c) s[u++] = (o[c] >> 1) + r[c];
                  for (; c < e; ++c) {
                    s[u] = (((o[c] + s[u - t]) >> 1) + r[c]) & 255;
                    u++;
                  }
                  break;
                case 4:
                  for (c = 0; c < t; ++c) {
                    l = o[c];
                    h = r[c];
                    s[u++] = l + h;
                  }
                  for (; c < e; ++c) {
                    l = o[c];
                    const e = o[c - t],
                      a = s[u - t],
                      n = a + l - e;
                    let i = n - a;
                    i < 0 && (i = -i);
                    let d = n - l;
                    d < 0 && (d = -d);
                    let f = n - e;
                    f < 0 && (f = -f);
                    h = r[c];
                    s[u++] = i <= d && i <= f ? a + h : d <= f ? l + h : e + h;
                  }
                  break;
                default:
                  throw new i.FormatError(`Unsupported predictor: ${a}`);
              }
              this.bufferLength += e;
            }
          }
          t.PredictorStream = PredictorStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.RunLengthStream = void 0;
          var r = a(17);
          class RunLengthStream extends r.DecodeStream {
            constructor(e, t) {
              super(t);
              this.str = e;
              this.dict = e.dict;
            }
            readBlock() {
              const e = this.str.getBytes(2);
              if (!e || e.length < 2 || 128 === e[0]) {
                this.eof = !0;
                return;
              }
              let t,
                a = this.bufferLength,
                r = e[0];
              if (r < 128) {
                t = this.ensureBuffer(a + r + 1);
                t[a++] = e[1];
                if (r > 0) {
                  const e = this.str.getBytes(r);
                  t.set(e, a);
                  a += r;
                }
              } else {
                r = 257 - r;
                const n = e[1];
                t = this.ensureBuffer(a + r + 1);
                for (let e = 0; e < r; e++) t[a++] = n;
              }
              this.bufferLength = a;
            }
          }
          t.RunLengthStream = RunLengthStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Font = t.ErrorFont = void 0;
          var r = a(2),
            n = a(33),
            i = a(36),
            s = a(38),
            o = a(37),
            c = a(35),
            l = a(39),
            h = a(40),
            u = a(41),
            d = a(42),
            f = a(43),
            g = a(44),
            p = a(14),
            m = a(45),
            b = a(3),
            y = a(8),
            w = a(46);
          const S = [
              [57344, 63743],
              [1048576, 1114109],
            ],
            x = 1e3,
            C = [
              "ascent",
              "bbox",
              "black",
              "bold",
              "charProcOperatorList",
              "composite",
              "cssFontInfo",
              "data",
              "defaultVMetrics",
              "defaultWidth",
              "descent",
              "fallbackName",
              "fontMatrix",
              "isInvalidPDFjsFont",
              "isType3Font",
              "italic",
              "loadedName",
              "mimetype",
              "missingFile",
              "name",
              "remeasure",
              "subtype",
              "type",
              "vertical",
            ],
            k = [
              "cMap",
              "defaultEncoding",
              "differences",
              "isMonospace",
              "isSerifFont",
              "isSymbolicFont",
              "seacMap",
              "toFontChar",
              "toUnicode",
              "vmetrics",
              "widths",
            ];
          function adjustWidths(e) {
            if (!e.fontMatrix) return;
            if (e.fontMatrix[0] === r.FONT_IDENTITY_MATRIX[0]) return;
            const t = 0.001 / e.fontMatrix[0],
              a = e.widths;
            for (const e in a) a[e] *= t;
            e.defaultWidth *= t;
          }
          function amendFallbackToUnicode(e) {
            if (!e.fallbackToUnicode) return;
            if (e.toUnicode instanceof h.IdentityToUnicodeMap) return;
            const t = [];
            for (const a in e.fallbackToUnicode)
              e.toUnicode.has(a) || (t[a] = e.fallbackToUnicode[a]);
            t.length > 0 && e.toUnicode.amend(t);
          }
          class Glyph {
            constructor(e, t, a, r, n, i, s, o, c) {
              this.originalCharCode = e;
              this.fontChar = t;
              this.unicode = a;
              this.accent = r;
              this.width = n;
              this.vmetric = i;
              this.operatorListId = s;
              this.isSpace = o;
              this.isInFont = c;
            }
            get category() {
              return (0, r.shadow)(
                this,
                "category",
                (0, s.getCharUnicodeCategory)(this.unicode),
                !0
              );
            }
            get normalizedUnicode() {
              return (0, r.shadow)(
                this,
                "normalizedUnicode",
                (0, s.reverseIfRtl)(
                  Glyph._NormalizedUnicodes[this.unicode] || this.unicode
                ),
                !0
              );
            }
            static get _NormalizedUnicodes() {
              return (0, r.shadow)(
                this,
                "_NormalizedUnicodes",
                (0, s.getNormalizedUnicodes)()
              );
            }
          }
          function int16(e, t) {
            return (e << 8) + t;
          }
          function writeSignedInt16(e, t, a) {
            e[t + 1] = a;
            e[t] = a >>> 8;
          }
          function signedInt16(e, t) {
            const a = (e << 8) + t;
            return 32768 & a ? a - 65536 : a;
          }
          function string16(e) {
            return String.fromCharCode((e >> 8) & 255, 255 & e);
          }
          function safeString16(e) {
            e > 32767 ? (e = 32767) : e < -32768 && (e = -32768);
            return String.fromCharCode((e >> 8) & 255, 255 & e);
          }
          function isTrueTypeCollectionFile(e) {
            const t = e.peekBytes(4);
            return "ttcf" === (0, r.bytesToString)(t);
          }
          function getFontFileType(e, { type: t, subtype: a, composite: n }) {
            let i, s;
            if (
              (function isTrueTypeFile(e) {
                const t = e.peekBytes(4);
                return (
                  65536 === (0, b.readUint32)(t, 0) ||
                  "true" === (0, r.bytesToString)(t)
                );
              })(e) ||
              isTrueTypeCollectionFile(e)
            )
              i = n ? "CIDFontType2" : "TrueType";
            else if (
              (function isOpenTypeFile(e) {
                const t = e.peekBytes(4);
                return "OTTO" === (0, r.bytesToString)(t);
              })(e)
            )
              i = n ? "CIDFontType2" : "OpenType";
            else if (
              (function isType1File(e) {
                const t = e.peekBytes(2);
                return (
                  (37 === t[0] && 33 === t[1]) || (128 === t[0] && 1 === t[1])
                );
              })(e)
            )
              i = n ? "CIDFontType0" : "MMType1" === t ? "MMType1" : "Type1";
            else if (
              (function isCFFFile(e) {
                const t = e.peekBytes(4);
                return t[0] >= 1 && t[3] >= 1 && t[3] <= 4;
              })(e)
            )
              if (n) {
                i = "CIDFontType0";
                s = "CIDFontType0C";
              } else {
                i = "MMType1" === t ? "MMType1" : "Type1";
                s = "Type1C";
              }
            else {
              (0, r.warn)(
                "getFontFileType: Unable to detect correct font file Type/Subtype."
              );
              i = t;
              s = a;
            }
            return [i, s];
          }
          function applyStandardFontGlyphMap(e, t) {
            for (const a in t) e[+a] = t[a];
          }
          function buildToFontChar(e, t, a) {
            const r = [];
            let n;
            for (let a = 0, i = e.length; a < i; a++) {
              n = (0, s.getUnicodeForGlyph)(e[a], t);
              -1 !== n && (r[a] = n);
            }
            for (const e in a) {
              n = (0, s.getUnicodeForGlyph)(a[e], t);
              -1 !== n && (r[+e] = n);
            }
            return r;
          }
          function isMacNameRecord(e) {
            return 1 === e.platform && 0 === e.encoding && 0 === e.language;
          }
          function isWinNameRecord(e) {
            return 3 === e.platform && 1 === e.encoding && 1033 === e.language;
          }
          function convertCidString(e, t, a = !1) {
            switch (t.length) {
              case 1:
                return t.charCodeAt(0);
              case 2:
                return (t.charCodeAt(0) << 8) | t.charCodeAt(1);
            }
            const n = `Unsupported CID string (charCode ${e}): "${t}".`;
            if (a) throw new r.FormatError(n);
            (0, r.warn)(n);
            return t;
          }
          function adjustMapping(e, t, a, n) {
            const i = Object.create(null),
              s = new Map(),
              o = [],
              c = new Set();
            let l = 0;
            const h = S[l][0];
            let u = h,
              d = S[l][1];
            for (let f in e) {
              f |= 0;
              let g = e[f];
              if (!t(g)) continue;
              if (u > d) {
                l++;
                if (l >= S.length) {
                  (0, r.warn)("Ran out of space in font private use area.");
                  break;
                }
                u = S[l][0];
                d = S[l][1];
              }
              const p = u++;
              0 === g && (g = a);
              let m = n.get(f);
              "string" == typeof m && (m = m.codePointAt(0));
              if (m && m < h && !c.has(g)) {
                s.set(m, g);
                c.add(g);
              }
              i[p] = g;
              o[f] = p;
            }
            return {
              toFontChar: o,
              charCodeToGlyphId: i,
              toUnicodeExtraMap: s,
              nextAvailableFontCharCode: u,
            };
          }
          function createCmapTable(e, t, a) {
            const n = (function getRanges(e, t, a) {
                const r = [];
                for (const t in e)
                  e[t] >= a || r.push({ fontCharCode: 0 | t, glyphId: e[t] });
                if (t)
                  for (const [e, n] of t)
                    n >= a || r.push({ fontCharCode: e, glyphId: n });
                0 === r.length && r.push({ fontCharCode: 0, glyphId: 0 });
                r.sort(function fontGetRangesSort(e, t) {
                  return e.fontCharCode - t.fontCharCode;
                });
                const n = [],
                  i = r.length;
                for (let e = 0; e < i; ) {
                  const t = r[e].fontCharCode,
                    a = [r[e].glyphId];
                  ++e;
                  let s = t;
                  for (; e < i && s + 1 === r[e].fontCharCode; ) {
                    a.push(r[e].glyphId);
                    ++s;
                    ++e;
                    if (65535 === s) break;
                  }
                  n.push([t, s, a]);
                }
                return n;
              })(e, t, a),
              i = n.at(-1)[1] > 65535 ? 2 : 1;
            let s,
              o,
              c,
              l,
              h = "\0\0" + string16(i) + "\0\0" + (0, r.string32)(4 + 8 * i);
            for (s = n.length - 1; s >= 0 && !(n[s][0] <= 65535); --s);
            const u = s + 1;
            n[s][0] < 65535 && 65535 === n[s][1] && (n[s][1] = 65534);
            const d = n[s][1] < 65535 ? 1 : 0,
              f = u + d,
              g = m.OpenTypeFileBuilder.getSearchParams(f, 2);
            let p,
              b,
              y,
              w,
              S = "",
              x = "",
              C = "",
              k = "",
              v = "",
              F = 0;
            for (s = 0, o = u; s < o; s++) {
              p = n[s];
              b = p[0];
              y = p[1];
              S += string16(b);
              x += string16(y);
              w = p[2];
              let e = !0;
              for (c = 1, l = w.length; c < l; ++c)
                if (w[c] !== w[c - 1] + 1) {
                  e = !1;
                  break;
                }
              if (e) {
                C += string16((w[0] - b) & 65535);
                k += string16(0);
              } else {
                const e = 2 * (f - s) + 2 * F;
                F += y - b + 1;
                C += string16(0);
                k += string16(e);
                for (c = 0, l = w.length; c < l; ++c) v += string16(w[c]);
              }
            }
            if (d > 0) {
              x += "ÿÿ";
              S += "ÿÿ";
              C += "\0";
              k += "\0\0";
            }
            const O =
              "\0\0" +
              string16(2 * f) +
              string16(g.range) +
              string16(g.entry) +
              string16(g.rangeShift) +
              x +
              "\0\0" +
              S +
              C +
              k +
              v;
            let T = "",
              M = "";
            if (i > 1) {
              h += "\0\0\n" + (0, r.string32)(4 + 8 * i + 4 + O.length);
              T = "";
              for (s = 0, o = n.length; s < o; s++) {
                p = n[s];
                b = p[0];
                w = p[2];
                let e = w[0];
                for (c = 1, l = w.length; c < l; ++c)
                  if (w[c] !== w[c - 1] + 1) {
                    y = p[0] + c - 1;
                    T +=
                      (0, r.string32)(b) +
                      (0, r.string32)(y) +
                      (0, r.string32)(e);
                    b = y + 1;
                    e = w[c];
                  }
                T +=
                  (0, r.string32)(b) +
                  (0, r.string32)(p[1]) +
                  (0, r.string32)(e);
              }
              M =
                "\0\f\0\0" +
                (0, r.string32)(T.length + 16) +
                "\0\0\0\0" +
                (0, r.string32)(T.length / 12);
            }
            return h + "\0" + string16(O.length + 4) + O + M + T;
          }
          function createOS2Table(e, t, a) {
            a = a || { unitsPerEm: 0, yMax: 0, yMin: 0, ascent: 0, descent: 0 };
            let n = 0,
              i = 0,
              o = 0,
              c = 0,
              l = null,
              h = 0;
            if (t) {
              for (let e in t) {
                e |= 0;
                (l > e || !l) && (l = e);
                h < e && (h = e);
                const t = (0, s.getUnicodeRangeFor)(e);
                if (t < 32) n |= 1 << t;
                else if (t < 64) i |= 1 << (t - 32);
                else if (t < 96) o |= 1 << (t - 64);
                else {
                  if (!(t < 123))
                    throw new r.FormatError(
                      "Unicode ranges Bits > 123 are reserved for internal usage"
                    );
                  c |= 1 << (t - 96);
                }
              }
              h > 65535 && (h = 65535);
            } else {
              l = 0;
              h = 255;
            }
            const u = e.bbox || [0, 0, 0, 0],
              d =
                a.unitsPerEm || 1 / (e.fontMatrix || r.FONT_IDENTITY_MATRIX)[0],
              f = e.ascentScaled ? 1 : d / x,
              g = a.ascent || Math.round(f * (e.ascent || u[3]));
            let p = a.descent || Math.round(f * (e.descent || u[1]));
            p > 0 && e.descent > 0 && u[1] < 0 && (p = -p);
            const m = a.yMax || g,
              b = -a.yMin || -p;
            return (
              "\0$ô\0\0\0»\0\0\0»\0\0ß\x001\0\0\0\0" +
              String.fromCharCode(e.fixedPitch ? 9 : 0) +
              "\0\0\0\0\0\0" +
              (0, r.string32)(n) +
              (0, r.string32)(i) +
              (0, r.string32)(o) +
              (0, r.string32)(c) +
              "*21*" +
              string16(e.italicAngle ? 1 : 0) +
              string16(l || e.firstChar) +
              string16(h || e.lastChar) +
              string16(g) +
              string16(p) +
              "\0d" +
              string16(m) +
              string16(b) +
              "\0\0\0\0\0\0\0\0" +
              string16(e.xHeight) +
              string16(e.capHeight) +
              string16(0) +
              string16(l || e.firstChar) +
              "\0"
            );
          }
          function createPostTable(e) {
            const t = Math.floor(65536 * e.italicAngle);
            return (
              "\0\0\0" +
              (0, r.string32)(t) +
              "\0\0\0\0" +
              (0, r.string32)(e.fixedPitch ? 1 : 0) +
              "\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0"
            );
          }
          function createPostscriptName(e) {
            return e.replace(/[^\x21-\x7E]|[[\](){}<>/%]/g, "").slice(0, 63);
          }
          function createNameTable(e, t) {
            t || (t = [[], []]);
            const a = [
                t[0][0] || "Original licence",
                t[0][1] || e,
                t[0][2] || "Unknown",
                t[0][3] || "uniqueID",
                t[0][4] || e,
                t[0][5] || "Version 0.11",
                t[0][6] || createPostscriptName(e),
                t[0][7] || "Unknown",
                t[0][8] || "Unknown",
                t[0][9] || "Unknown",
              ],
              r = [];
            let n, i, s, o, c;
            for (n = 0, i = a.length; n < i; n++) {
              c = t[1][n] || a[n];
              const e = [];
              for (s = 0, o = c.length; s < o; s++)
                e.push(string16(c.charCodeAt(s)));
              r.push(e.join(""));
            }
            const l = [a, r],
              h = ["\0", "\0"],
              u = ["\0\0", "\0"],
              d = ["\0\0", "\t"],
              f = a.length * h.length;
            let g = "\0\0" + string16(f) + string16(12 * f + 6),
              p = 0;
            for (n = 0, i = h.length; n < i; n++) {
              const e = l[n];
              for (s = 0, o = e.length; s < o; s++) {
                c = e[s];
                g +=
                  h[n] +
                  u[n] +
                  d[n] +
                  string16(s) +
                  string16(c.length) +
                  string16(p);
                p += c.length;
              }
            }
            g += a.join("") + r.join("");
            return g;
          }
          t.Font = class Font {
            constructor(e, t, a) {
              this.name = e;
              this.psName = null;
              this.mimetype = null;
              this.disableFontFace = !1;
              this.loadedName = a.loadedName;
              this.isType3Font = a.isType3Font;
              this.missingFile = !1;
              this.cssFontInfo = a.cssFontInfo;
              this._charsCache = Object.create(null);
              this._glyphCache = Object.create(null);
              let n = !!(a.flags & i.FontFlags.Serif);
              if (!n && !a.isSimulatedFlags) {
                const t = e.replace(/[,_]/g, "-").split("-")[0],
                  a = (0, l.getSerifFonts)();
                for (const e of t.split("+"))
                  if (a[e]) {
                    n = !0;
                    break;
                  }
              }
              this.isSerifFont = n;
              this.isSymbolicFont = !!(a.flags & i.FontFlags.Symbolic);
              this.isMonospace = !!(a.flags & i.FontFlags.FixedPitch);
              let { type: s, subtype: o } = a;
              this.type = s;
              this.subtype = o;
              const c = e.match(/^InvalidPDFjsFont_(.*)_\d+$/);
              this.isInvalidPDFjsFont = !!c;
              this.isInvalidPDFjsFont
                ? (this.fallbackName = c[1])
                : this.isMonospace
                ? (this.fallbackName = "monospace")
                : this.isSerifFont
                ? (this.fallbackName = "serif")
                : (this.fallbackName = "sans-serif");
              this.differences = a.differences;
              this.widths = a.widths;
              this.defaultWidth = a.defaultWidth;
              this.composite = a.composite;
              this.cMap = a.cMap;
              this.capHeight = a.capHeight / x;
              this.ascent = a.ascent / x;
              this.descent = a.descent / x;
              this.lineHeight = this.ascent - this.descent;
              this.fontMatrix = a.fontMatrix;
              this.bbox = a.bbox;
              this.defaultEncoding = a.defaultEncoding;
              this.toUnicode = a.toUnicode;
              this.toFontChar = [];
              if ("Type3" === a.type) {
                for (let e = 0; e < 256; e++)
                  this.toFontChar[e] =
                    this.differences[e] || a.defaultEncoding[e];
                return;
              }
              this.cidEncoding = a.cidEncoding || "";
              this.vertical = !!a.vertical;
              if (this.vertical) {
                this.vmetrics = a.vmetrics;
                this.defaultVMetrics = a.defaultVMetrics;
              }
              if (!t || t.isEmpty) {
                t &&
                  (0, r.warn)(
                    'Font file is empty in "' +
                      e +
                      '" (' +
                      this.loadedName +
                      ")"
                  );
                this.fallbackToSystemFont(a);
                return;
              }
              [s, o] = getFontFileType(t, a);
              (s === this.type && o === this.subtype) ||
                (0, r.info)(
                  `Inconsistent font file Type/SubType, expected: ${this.type}/${this.subtype} but found: ${s}/${o}.`
                );
              let h;
              try {
                switch (s) {
                  case "MMType1":
                    (0, r.info)(
                      "MMType1 font (" + e + "), falling back to Type1."
                    );
                  case "Type1":
                  case "CIDFontType0":
                    this.mimetype = "font/opentype";
                    const n =
                      "Type1C" === o || "CIDFontType0C" === o
                        ? new u.CFFFont(t, a)
                        : new w.Type1Font(e, t, a);
                    adjustWidths(a);
                    h = this.convert(e, n, a);
                    break;
                  case "OpenType":
                  case "TrueType":
                  case "CIDFontType2":
                    this.mimetype = "font/opentype";
                    h = this.checkAndRepair(e, t, a);
                    if (this.isOpenType) {
                      adjustWidths(a);
                      s = "OpenType";
                    }
                    break;
                  default:
                    throw new r.FormatError(`Font ${s} is not supported`);
                }
              } catch (e) {
                (0, r.warn)(e);
                this.fallbackToSystemFont(a);
                return;
              }
              amendFallbackToUnicode(a);
              this.data = h;
              this.type = s;
              this.subtype = o;
              this.fontMatrix = a.fontMatrix;
              this.widths = a.widths;
              this.defaultWidth = a.defaultWidth;
              this.toUnicode = a.toUnicode;
              this.seacMap = a.seacMap;
            }
            get renderer() {
              const e = d.FontRendererFactory.create(
                this,
                i.SEAC_ANALYSIS_ENABLED
              );
              return (0, r.shadow)(this, "renderer", e);
            }
            exportData(e = !1) {
              const t = e ? [...C, ...k] : C,
                a = Object.create(null);
              let r, n;
              for (r of t) {
                n = this[r];
                void 0 !== n && (a[r] = n);
              }
              return a;
            }
            fallbackToSystemFont(e) {
              this.missingFile = !0;
              const { name: t, type: a } = this;
              let n = (0, i.normalizeFontName)(t);
              const u = (0, l.getStdFontMap)(),
                d = (0, l.getNonStdFontMap)(),
                g = !!u[n],
                p = !(!d[n] || !u[d[n]]);
              n = u[n] || d[n] || n;
              const m = (0, f.getFontBasicMetrics)()[n];
              if (m) {
                isNaN(this.ascent) && (this.ascent = m.ascent / x);
                isNaN(this.descent) && (this.descent = m.descent / x);
                isNaN(this.capHeight) && (this.capHeight = m.capHeight / x);
              }
              this.bold = /bold/gi.test(n);
              this.italic = /oblique|italic/gi.test(n);
              this.black = /Black/g.test(t);
              const b = /Narrow/g.test(t);
              this.remeasure = (!g || b) && Object.keys(this.widths).length > 0;
              if (
                (g || p) &&
                "CIDFontType2" === a &&
                this.cidEncoding.startsWith("Identity-")
              ) {
                const a = e.cidToGidMap,
                  r = [];
                applyStandardFontGlyphMap(
                  r,
                  (0, l.getGlyphMapForStandardFonts)()
                );
                /Arial-?Black/i.test(t)
                  ? applyStandardFontGlyphMap(
                      r,
                      (0, l.getSupplementalGlyphMapForArialBlack)()
                    )
                  : /Calibri/i.test(t) &&
                    applyStandardFontGlyphMap(
                      r,
                      (0, l.getSupplementalGlyphMapForCalibri)()
                    );
                if (a) {
                  for (const e in r) {
                    const t = r[e];
                    void 0 !== a[t] && (r[+e] = a[t]);
                  }
                  a.length !== this.toUnicode.length &&
                    e.hasIncludedToUnicodeMap &&
                    this.toUnicode instanceof h.IdentityToUnicodeMap &&
                    this.toUnicode.forEach(function (e, t) {
                      const n = r[e];
                      void 0 === a[n] && (r[+e] = t);
                    });
                }
                this.toUnicode instanceof h.IdentityToUnicodeMap ||
                  this.toUnicode.forEach(function (e, t) {
                    r[+e] = t;
                  });
                this.toFontChar = r;
                this.toUnicode = new h.ToUnicodeMap(r);
              } else if (/Symbol/i.test(n))
                this.toFontChar = buildToFontChar(
                  c.SymbolSetEncoding,
                  (0, o.getGlyphsUnicode)(),
                  this.differences
                );
              else if (/Dingbats/i.test(n)) {
                /Wingdings/i.test(t) &&
                  (0, r.warn)(
                    "Non-embedded Wingdings font, falling back to ZapfDingbats."
                  );
                this.toFontChar = buildToFontChar(
                  c.ZapfDingbatsEncoding,
                  (0, o.getDingbatsGlyphsUnicode)(),
                  this.differences
                );
              } else if (g) {
                const e = buildToFontChar(
                  this.defaultEncoding,
                  (0, o.getGlyphsUnicode)(),
                  this.differences
                );
                "CIDFontType2" !== a ||
                  this.cidEncoding.startsWith("Identity-") ||
                  this.toUnicode instanceof h.IdentityToUnicodeMap ||
                  this.toUnicode.forEach(function (t, a) {
                    e[+t] = a;
                  });
                this.toFontChar = e;
              } else {
                const e = (0, o.getGlyphsUnicode)(),
                  a = [];
                this.toUnicode.forEach((t, r) => {
                  if (!this.composite) {
                    const a = this.differences[t] || this.defaultEncoding[t],
                      n = (0, s.getUnicodeForGlyph)(a, e);
                    -1 !== n && (r = n);
                  }
                  a[+t] = r;
                });
                this.composite &&
                  this.toUnicode instanceof h.IdentityToUnicodeMap &&
                  /Tahoma|Verdana/i.test(t) &&
                  applyStandardFontGlyphMap(
                    a,
                    (0, l.getGlyphMapForStandardFonts)()
                  );
                this.toFontChar = a;
              }
              amendFallbackToUnicode(e);
              this.loadedName = n.split("-")[0];
            }
            checkAndRepair(e, t, a) {
              const s = [
                "OS/2",
                "cmap",
                "head",
                "hhea",
                "hmtx",
                "maxp",
                "name",
                "post",
                "loca",
                "glyf",
                "fpgm",
                "prep",
                "cvt ",
                "CFF ",
              ];
              function readTables(e, t) {
                const a = Object.create(null);
                a["OS/2"] = null;
                a.cmap = null;
                a.head = null;
                a.hhea = null;
                a.hmtx = null;
                a.maxp = null;
                a.name = null;
                a.post = null;
                for (let r = 0; r < t; r++) {
                  const t = readTableEntry(e);
                  s.includes(t.tag) && 0 !== t.length && (a[t.tag] = t);
                }
                return a;
              }
              function readTableEntry(e) {
                const t = e.getString(4),
                  a = e.getInt32() >>> 0,
                  r = e.getInt32() >>> 0,
                  n = e.getInt32() >>> 0,
                  i = e.pos;
                e.pos = e.start || 0;
                e.skip(r);
                const s = e.getBytes(n);
                e.pos = i;
                if ("head" === t) {
                  s[8] = s[9] = s[10] = s[11] = 0;
                  s[17] |= 32;
                }
                return { tag: t, checksum: a, length: n, offset: r, data: s };
              }
              function readOpenTypeHeader(e) {
                return {
                  version: e.getString(4),
                  numTables: e.getUint16(),
                  searchRange: e.getUint16(),
                  entrySelector: e.getUint16(),
                  rangeShift: e.getUint16(),
                };
              }
              function sanitizeGlyph(e, t, a, r, n, i) {
                const s = { length: 0, sizeOfInstructions: 0 };
                if (a - t <= 12) return s;
                const o = e.subarray(t, a);
                let c = signedInt16(o[0], o[1]);
                if (c < 0) {
                  c = -1;
                  writeSignedInt16(o, 0, c);
                  r.set(o, n);
                  s.length = o.length;
                  return s;
                }
                let l,
                  h = 10,
                  u = 0;
                for (l = 0; l < c; l++) {
                  u = ((o[h] << 8) | o[h + 1]) + 1;
                  h += 2;
                }
                const d = h,
                  f = (o[h] << 8) | o[h + 1];
                s.sizeOfInstructions = f;
                h += 2 + f;
                const g = h;
                let p = 0;
                for (l = 0; l < u; l++) {
                  const e = o[h++];
                  192 & e && (o[h - 1] = 63 & e);
                  let t = 2;
                  2 & e ? (t = 1) : 16 & e && (t = 0);
                  let a = 2;
                  4 & e ? (a = 1) : 32 & e && (a = 0);
                  const r = t + a;
                  p += r;
                  if (8 & e) {
                    const e = o[h++];
                    l += e;
                    p += e * r;
                  }
                }
                if (0 === p) return s;
                let m = h + p;
                if (m > o.length) return s;
                if (!i && f > 0) {
                  r.set(o.subarray(0, d), n);
                  r.set([0, 0], n + d);
                  r.set(o.subarray(g, m), n + d + 2);
                  m -= f;
                  o.length - m > 3 && (m = (m + 3) & -4);
                  s.length = m;
                  return s;
                }
                if (o.length - m > 3) {
                  m = (m + 3) & -4;
                  r.set(o.subarray(0, m), n);
                  s.length = m;
                  return s;
                }
                r.set(o, n);
                s.length = o.length;
                return s;
              }
              function readNameTable(e) {
                const a = (t.start || 0) + e.offset;
                t.pos = a;
                const r = [[], []],
                  n = [],
                  i = e.length,
                  s = a + i;
                if (0 !== t.getUint16() || i < 6) return [r, n];
                const o = t.getUint16(),
                  c = t.getUint16();
                let l, h;
                for (l = 0; l < o && t.pos + 12 <= s; l++) {
                  const e = {
                    platform: t.getUint16(),
                    encoding: t.getUint16(),
                    language: t.getUint16(),
                    name: t.getUint16(),
                    length: t.getUint16(),
                    offset: t.getUint16(),
                  };
                  (isMacNameRecord(e) || isWinNameRecord(e)) && n.push(e);
                }
                for (l = 0, h = n.length; l < h; l++) {
                  const e = n[l];
                  if (e.length <= 0) continue;
                  const i = a + c + e.offset;
                  if (i + e.length > s) continue;
                  t.pos = i;
                  const o = e.name;
                  if (e.encoding) {
                    let a = "";
                    for (let r = 0, n = e.length; r < n; r += 2)
                      a += String.fromCharCode(t.getUint16());
                    r[1][o] = a;
                  } else r[0][o] = t.getString(e.length);
                }
                return [r, n];
              }
              const l = [
                0, 0, 0, 0, 0, 0, 0, 0, -2, -2, -2, -2, 0, 0, -2, -5, -1, -1,
                -1, -1, -1, -1, -1, -1, 0, 0, -1, 0, -1, -1, -1, -1, 1, -1,
                -999, 0, 1, 0, -1, -2, 0, -1, -2, -1, -1, 0, -1, -1, 0, 0, -999,
                -999, -1, -1, -1, -1, -2, -999, -2, -2, -999, 0, -2, -2, 0, 0,
                -2, 0, -2, 0, 0, 0, -2, -1, -1, 1, 1, 0, 0, -1, -1, -1, -1, -1,
                -1, -1, 0, 0, -1, 0, -1, -1, 0, -999, -1, -1, -1, -1, -1, -1, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2, -999, -999, -999, -999,
                -999, -1, -1, -2, -2, 0, 0, 0, 0, -1, -1, -999, -2, -2, 0, 0,
                -1, -2, -2, 0, 0, 0, -1, -1, -1, -2,
              ];
              function sanitizeTTProgram(e, t) {
                let a,
                  n,
                  i,
                  s,
                  o,
                  c = e.data,
                  h = 0,
                  u = 0,
                  d = 0;
                const f = [],
                  g = [],
                  p = [];
                let m = t.tooComplexToFollowFunctions,
                  b = !1,
                  y = 0,
                  w = 0;
                for (let e = c.length; h < e; ) {
                  const e = c[h++];
                  if (64 === e) {
                    n = c[h++];
                    if (b || w) h += n;
                    else for (a = 0; a < n; a++) f.push(c[h++]);
                  } else if (65 === e) {
                    n = c[h++];
                    if (b || w) h += 2 * n;
                    else
                      for (a = 0; a < n; a++) {
                        i = c[h++];
                        f.push((i << 8) | c[h++]);
                      }
                  } else if (176 == (248 & e)) {
                    n = e - 176 + 1;
                    if (b || w) h += n;
                    else for (a = 0; a < n; a++) f.push(c[h++]);
                  } else if (184 == (248 & e)) {
                    n = e - 184 + 1;
                    if (b || w) h += 2 * n;
                    else
                      for (a = 0; a < n; a++) {
                        i = c[h++];
                        f.push((i << 8) | c[h++]);
                      }
                  } else if (43 !== e || m)
                    if (44 !== e || m) {
                      if (45 === e)
                        if (b) {
                          b = !1;
                          u = h;
                        } else {
                          o = g.pop();
                          if (!o) {
                            (0, r.warn)("TT: ENDF bad stack");
                            t.hintsValid = !1;
                            return;
                          }
                          s = p.pop();
                          c = o.data;
                          h = o.i;
                          t.functionsStackDeltas[s] = f.length - o.stackTop;
                        }
                      else if (137 === e) {
                        if (b || w) {
                          (0, r.warn)("TT: nested IDEFs not allowed");
                          m = !0;
                        }
                        b = !0;
                        d = h;
                      } else if (88 === e) ++y;
                      else if (27 === e) w = y;
                      else if (89 === e) {
                        w === y && (w = 0);
                        --y;
                      } else if (28 === e && !b && !w) {
                        const e = f.at(-1);
                        e > 0 && (h += e - 1);
                      }
                    } else {
                      if (b || w) {
                        (0, r.warn)("TT: nested FDEFs not allowed");
                        m = !0;
                      }
                      b = !0;
                      d = h;
                      s = f.pop();
                      t.functionsDefined[s] = { data: c, i: h };
                    }
                  else if (!b && !w) {
                    s = f.at(-1);
                    if (isNaN(s))
                      (0, r.info)("TT: CALL empty stack (or invalid entry).");
                    else {
                      t.functionsUsed[s] = !0;
                      if (s in t.functionsStackDeltas) {
                        const e = f.length + t.functionsStackDeltas[s];
                        if (e < 0) {
                          (0, r.warn)(
                            "TT: CALL invalid functions stack delta."
                          );
                          t.hintsValid = !1;
                          return;
                        }
                        f.length = e;
                      } else if (s in t.functionsDefined && !p.includes(s)) {
                        g.push({ data: c, i: h, stackTop: f.length - 1 });
                        p.push(s);
                        o = t.functionsDefined[s];
                        if (!o) {
                          (0, r.warn)("TT: CALL non-existent function");
                          t.hintsValid = !1;
                          return;
                        }
                        c = o.data;
                        h = o.i;
                      }
                    }
                  }
                  if (!b && !w) {
                    let t = 0;
                    e <= 142
                      ? (t = l[e])
                      : e >= 192 && e <= 223
                      ? (t = -1)
                      : e >= 224 && (t = -2);
                    if (e >= 113 && e <= 117) {
                      n = f.pop();
                      isNaN(n) || (t = 2 * -n);
                    }
                    for (; t < 0 && f.length > 0; ) {
                      f.pop();
                      t++;
                    }
                    for (; t > 0; ) {
                      f.push(NaN);
                      t--;
                    }
                  }
                }
                t.tooComplexToFollowFunctions = m;
                const S = [c];
                h > c.length && S.push(new Uint8Array(h - c.length));
                if (d > u) {
                  (0, r.warn)("TT: complementing a missing function tail");
                  S.push(new Uint8Array([34, 45]));
                }
                !(function foldTTTable(e, t) {
                  if (t.length > 1) {
                    let a,
                      r,
                      n = 0;
                    for (a = 0, r = t.length; a < r; a++) n += t[a].length;
                    n = (n + 3) & -4;
                    const i = new Uint8Array(n);
                    let s = 0;
                    for (a = 0, r = t.length; a < r; a++) {
                      i.set(t[a], s);
                      s += t[a].length;
                    }
                    e.data = i;
                    e.length = n;
                  }
                })(e, S);
              }
              let d, f, b, w;
              if (
                isTrueTypeCollectionFile(
                  (t = new y.Stream(new Uint8Array(t.getBytes())))
                )
              ) {
                const e = (function readTrueTypeCollectionData(e, t) {
                  const { numFonts: a, offsetTable: n } =
                      (function readTrueTypeCollectionHeader(e) {
                        const t = e.getString(4);
                        (0, r.assert)(
                          "ttcf" === t,
                          "Must be a TrueType Collection font."
                        );
                        const a = e.getUint16(),
                          n = e.getUint16(),
                          i = e.getInt32() >>> 0,
                          s = [];
                        for (let t = 0; t < i; t++) s.push(e.getInt32() >>> 0);
                        const o = {
                          ttcTag: t,
                          majorVersion: a,
                          minorVersion: n,
                          numFonts: i,
                          offsetTable: s,
                        };
                        switch (a) {
                          case 1:
                            return o;
                          case 2:
                            o.dsigTag = e.getInt32() >>> 0;
                            o.dsigLength = e.getInt32() >>> 0;
                            o.dsigOffset = e.getInt32() >>> 0;
                            return o;
                        }
                        throw new r.FormatError(
                          `Invalid TrueType Collection majorVersion: ${a}.`
                        );
                      })(e),
                    i = t.split("+");
                  let s;
                  for (let o = 0; o < a; o++) {
                    e.pos = (e.start || 0) + n[o];
                    const a = readOpenTypeHeader(e),
                      c = readTables(e, a.numTables);
                    if (!c.name)
                      throw new r.FormatError(
                        'TrueType Collection font must contain a "name" table.'
                      );
                    const [l] = readNameTable(c.name);
                    for (let e = 0, r = l.length; e < r; e++)
                      for (let r = 0, n = l[e].length; r < n; r++) {
                        const n = l[e][r] && l[e][r].replace(/\s/g, "");
                        if (n) {
                          if (n === t) return { header: a, tables: c };
                          if (!(i.length < 2))
                            for (const e of i)
                              n === e &&
                                (s = { name: e, header: a, tables: c });
                        }
                      }
                  }
                  if (s) {
                    (0, r.warn)(
                      `TrueType Collection does not contain "${t}" font, falling back to "${s.name}" font instead.`
                    );
                    return { header: s.header, tables: s.tables };
                  }
                  throw new r.FormatError(
                    `TrueType Collection does not contain "${t}" font.`
                  );
                })(t, this.name);
                d = e.header;
                f = e.tables;
              } else {
                d = readOpenTypeHeader(t);
                f = readTables(t, d.numTables);
              }
              const S = !f["CFF "];
              if (S) {
                if (!f.loca)
                  throw new r.FormatError('Required "loca" table is not found');
                if (!f.glyf) {
                  (0, r.warn)(
                    'Required "glyf" table is not found -- trying to recover.'
                  );
                  f.glyf = { tag: "glyf", data: new Uint8Array(0) };
                }
                this.isOpenType = !1;
              } else {
                const t =
                  a.composite &&
                  ((a.cidToGidMap || []).length > 0 ||
                    !(a.cMap instanceof p.IdentityCMap));
                if (
                  ("OTTO" === d.version && !t) ||
                  !f.head ||
                  !f.hhea ||
                  !f.maxp ||
                  !f.post
                ) {
                  w = new y.Stream(f["CFF "].data);
                  b = new u.CFFFont(w, a);
                  adjustWidths(a);
                  return this.convert(e, b, a);
                }
                delete f.glyf;
                delete f.loca;
                delete f.fpgm;
                delete f.prep;
                delete f["cvt "];
                this.isOpenType = !0;
              }
              if (!f.maxp)
                throw new r.FormatError('Required "maxp" table is not found');
              t.pos = (t.start || 0) + f.maxp.offset;
              const x = t.getInt32(),
                C = t.getUint16();
              if (a.scaleFactors && a.scaleFactors.length === C && S) {
                const { scaleFactors: e } = a,
                  t = int16(f.head.data[50], f.head.data[51]),
                  r = new g.GlyfTable({
                    glyfTable: f.glyf.data,
                    isGlyphLocationsLong: t,
                    locaTable: f.loca.data,
                    numGlyphs: C,
                  });
                r.scale(e);
                const { glyf: n, loca: i, isLocationLong: s } = r.write();
                f.glyf.data = n;
                f.loca.data = i;
                if (s !== !!t) {
                  f.head.data[50] = 0;
                  f.head.data[51] = s ? 1 : 0;
                }
                const o = f.hmtx.data;
                for (let t = 0; t < C; t++) {
                  const a = 4 * t,
                    r = Math.round(e[t] * int16(o[a], o[a + 1]));
                  o[a] = (r >> 8) & 255;
                  o[a + 1] = 255 & r;
                  writeSignedInt16(
                    o,
                    a + 2,
                    Math.round(e[t] * signedInt16(o[a + 2], o[a + 3]))
                  );
                }
              }
              let k = C + 1,
                v = !0;
              if (k > 65535) {
                v = !1;
                k = C;
                (0, r.warn)(
                  "Not enough space in glyfs to duplicate first glyph."
                );
              }
              let F = 0,
                O = 0;
              if (x >= 65536 && f.maxp.length >= 22) {
                t.pos += 8;
                if (t.getUint16() > 2) {
                  f.maxp.data[14] = 0;
                  f.maxp.data[15] = 2;
                }
                t.pos += 4;
                F = t.getUint16();
                t.pos += 4;
                O = t.getUint16();
              }
              f.maxp.data[4] = k >> 8;
              f.maxp.data[5] = 255 & k;
              const T = (function sanitizeTTPrograms(e, t, a, n) {
                const i = {
                  functionsDefined: [],
                  functionsUsed: [],
                  functionsStackDeltas: [],
                  tooComplexToFollowFunctions: !1,
                  hintsValid: !0,
                };
                e && sanitizeTTProgram(e, i);
                t && sanitizeTTProgram(t, i);
                e &&
                  (function checkInvalidFunctions(e, t) {
                    if (!e.tooComplexToFollowFunctions)
                      if (e.functionsDefined.length > t) {
                        (0, r.warn)("TT: more functions defined than expected");
                        e.hintsValid = !1;
                      } else
                        for (
                          let a = 0, n = e.functionsUsed.length;
                          a < n;
                          a++
                        ) {
                          if (a > t) {
                            (0, r.warn)("TT: invalid function id: " + a);
                            e.hintsValid = !1;
                            return;
                          }
                          if (e.functionsUsed[a] && !e.functionsDefined[a]) {
                            (0, r.warn)("TT: undefined function: " + a);
                            e.hintsValid = !1;
                            return;
                          }
                        }
                  })(i, n);
                if (a && 1 & a.length) {
                  const e = new Uint8Array(a.length + 1);
                  e.set(a.data);
                  a.data = e;
                }
                return i.hintsValid;
              })(f.fpgm, f.prep, f["cvt "], F);
              if (!T) {
                delete f.fpgm;
                delete f.prep;
                delete f["cvt "];
              }
              !(function sanitizeMetrics(e, t, a, n, i, s) {
                if (!t) {
                  a && (a.data = null);
                  return;
                }
                e.pos = (e.start || 0) + t.offset;
                e.pos += 4;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                e.pos += 2;
                const o = e.getUint16();
                e.pos += 8;
                e.pos += 2;
                let c = e.getUint16();
                if (0 !== o) {
                  if (!(2 & int16(n.data[44], n.data[45]))) {
                    t.data[22] = 0;
                    t.data[23] = 0;
                  }
                }
                if (c > i) {
                  (0, r.info)(
                    `The numOfMetrics (${c}) should not be greater than the numGlyphs (${i}).`
                  );
                  c = i;
                  t.data[34] = (65280 & c) >> 8;
                  t.data[35] = 255 & c;
                }
                const l = i - c - ((a.length - 4 * c) >> 1);
                if (l > 0) {
                  const e = new Uint8Array(a.length + 2 * l);
                  e.set(a.data);
                  if (s) {
                    e[a.length] = a.data[2];
                    e[a.length + 1] = a.data[3];
                  }
                  a.data = e;
                }
              })(t, f.hhea, f.hmtx, f.head, k, v);
              if (!f.head)
                throw new r.FormatError('Required "head" table is not found');
              !(function sanitizeHead(e, t, a) {
                const n = e.data,
                  i = (function int32(e, t, a, r) {
                    return (e << 24) + (t << 16) + (a << 8) + r;
                  })(n[0], n[1], n[2], n[3]);
                if (i >> 16 != 1) {
                  (0, r.info)(
                    "Attempting to fix invalid version in head table: " + i
                  );
                  n[0] = 0;
                  n[1] = 1;
                  n[2] = 0;
                  n[3] = 0;
                }
                const s = int16(n[50], n[51]);
                if (s < 0 || s > 1) {
                  (0, r.info)(
                    "Attempting to fix invalid indexToLocFormat in head table: " +
                      s
                  );
                  const e = t + 1;
                  if (a === e << 1) {
                    n[50] = 0;
                    n[51] = 0;
                  } else {
                    if (a !== e << 2)
                      throw new r.FormatError(
                        "Could not fix indexToLocFormat: " + s
                      );
                    n[50] = 0;
                    n[51] = 1;
                  }
                }
              })(f.head, C, S ? f.loca.length : 0);
              let M = Object.create(null);
              if (S) {
                const e = int16(f.head.data[50], f.head.data[51]),
                  t = (function sanitizeGlyphLocations(e, t, a, r, n, i, s) {
                    let o, c, l;
                    if (r) {
                      o = 4;
                      c = function fontItemDecodeLong(e, t) {
                        return (
                          (e[t] << 24) |
                          (e[t + 1] << 16) |
                          (e[t + 2] << 8) |
                          e[t + 3]
                        );
                      };
                      l = function fontItemEncodeLong(e, t, a) {
                        e[t] = (a >>> 24) & 255;
                        e[t + 1] = (a >> 16) & 255;
                        e[t + 2] = (a >> 8) & 255;
                        e[t + 3] = 255 & a;
                      };
                    } else {
                      o = 2;
                      c = function fontItemDecode(e, t) {
                        return (e[t] << 9) | (e[t + 1] << 1);
                      };
                      l = function fontItemEncode(e, t, a) {
                        e[t] = (a >> 9) & 255;
                        e[t + 1] = (a >> 1) & 255;
                      };
                    }
                    const h = i ? a + 1 : a,
                      u = o * (1 + h),
                      d = new Uint8Array(u);
                    d.set(e.data.subarray(0, u));
                    e.data = d;
                    const f = t.data,
                      g = f.length,
                      p = new Uint8Array(g);
                    let m, b;
                    const y = [];
                    for (m = 0, b = 0; m < a + 1; m++, b += o) {
                      let e = c(d, b);
                      e > g && (e = g);
                      y.push({ index: m, offset: e, endOffset: 0 });
                    }
                    y.sort((e, t) => e.offset - t.offset);
                    for (m = 0; m < a; m++) y[m].endOffset = y[m + 1].offset;
                    y.sort((e, t) => e.index - t.index);
                    for (m = 0; m < a; m++) {
                      const { offset: e, endOffset: t } = y[m];
                      if (0 !== e || 0 !== t) break;
                      const a = y[m + 1].offset;
                      if (0 !== a) {
                        y[m].endOffset = a;
                        break;
                      }
                    }
                    const w = Object.create(null);
                    let S = 0;
                    l(d, 0, S);
                    for (m = 0, b = o; m < a; m++, b += o) {
                      const e = sanitizeGlyph(
                          f,
                          y[m].offset,
                          y[m].endOffset,
                          p,
                          S,
                          n
                        ),
                        t = e.length;
                      0 === t && (w[m] = !0);
                      e.sizeOfInstructions > s && (s = e.sizeOfInstructions);
                      S += t;
                      l(d, b, S);
                    }
                    if (0 === S) {
                      const e = new Uint8Array([
                        0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 49, 0,
                      ]);
                      for (m = 0, b = o; m < h; m++, b += o) l(d, b, e.length);
                      t.data = e;
                    } else if (i) {
                      const a = c(d, o);
                      if (p.length > a + S) t.data = p.subarray(0, a + S);
                      else {
                        t.data = new Uint8Array(a + S);
                        t.data.set(p.subarray(0, S));
                      }
                      t.data.set(p.subarray(0, a), S);
                      l(e.data, d.length - o, S + a);
                    } else t.data = p.subarray(0, S);
                    return { missingGlyphs: w, maxSizeOfInstructions: s };
                  })(f.loca, f.glyf, C, e, T, v, O);
                M = t.missingGlyphs;
                if (x >= 65536 && f.maxp.length >= 22) {
                  f.maxp.data[26] = t.maxSizeOfInstructions >> 8;
                  f.maxp.data[27] = 255 & t.maxSizeOfInstructions;
                }
              }
              if (!f.hhea)
                throw new r.FormatError('Required "hhea" table is not found');
              if (0 === f.hhea.data[10] && 0 === f.hhea.data[11]) {
                f.hhea.data[10] = 255;
                f.hhea.data[11] = 255;
              }
              const E = {
                unitsPerEm: int16(f.head.data[18], f.head.data[19]),
                yMax: int16(f.head.data[42], f.head.data[43]),
                yMin: signedInt16(f.head.data[38], f.head.data[39]),
                ascent: signedInt16(f.hhea.data[4], f.hhea.data[5]),
                descent: signedInt16(f.hhea.data[6], f.hhea.data[7]),
                lineGap: signedInt16(f.hhea.data[8], f.hhea.data[9]),
              };
              this.ascent = E.ascent / E.unitsPerEm;
              this.descent = E.descent / E.unitsPerEm;
              this.lineGap = E.lineGap / E.unitsPerEm;
              if (this.cssFontInfo && this.cssFontInfo.lineHeight) {
                this.lineHeight = this.cssFontInfo.metrics.lineHeight;
                this.lineGap = this.cssFontInfo.metrics.lineGap;
              } else
                this.lineHeight = this.ascent - this.descent + this.lineGap;
              f.post &&
                (function readPostScriptTable(e, a, n) {
                  const s = (t.start || 0) + e.offset;
                  t.pos = s;
                  const o = s + e.length,
                    c = t.getInt32();
                  t.skip(28);
                  let l,
                    h,
                    u = !0;
                  switch (c) {
                    case 65536:
                      l = i.MacStandardGlyphOrdering;
                      break;
                    case 131072:
                      const e = t.getUint16();
                      if (e !== n) {
                        u = !1;
                        break;
                      }
                      const s = [];
                      for (h = 0; h < e; ++h) {
                        const e = t.getUint16();
                        if (e >= 32768) {
                          u = !1;
                          break;
                        }
                        s.push(e);
                      }
                      if (!u) break;
                      const d = [],
                        f = [];
                      for (; t.pos < o; ) {
                        const e = t.getByte();
                        f.length = e;
                        for (h = 0; h < e; ++h)
                          f[h] = String.fromCharCode(t.getByte());
                        d.push(f.join(""));
                      }
                      l = [];
                      for (h = 0; h < e; ++h) {
                        const e = s[h];
                        e < 258
                          ? l.push(i.MacStandardGlyphOrdering[e])
                          : l.push(d[e - 258]);
                      }
                      break;
                    case 196608:
                      break;
                    default:
                      (0, r.warn)(
                        "Unknown/unsupported post table version " + c
                      );
                      u = !1;
                      a.defaultEncoding && (l = a.defaultEncoding);
                  }
                  a.glyphNames = l;
                  return u;
                })(f.post, a, C);
              f.post = { tag: "post", data: createPostTable(a) };
              const D = [];
              function hasGlyph(e) {
                return !M[e];
              }
              if (a.composite) {
                const e = a.cidToGidMap || [],
                  t = 0 === e.length;
                a.cMap.forEach(function (a, n) {
                  "string" == typeof n && (n = convertCidString(a, n, !0));
                  if (n > 65535)
                    throw new r.FormatError("Max size of CID is 65,535");
                  let i = -1;
                  t ? (i = n) : void 0 !== e[n] && (i = e[n]);
                  i >= 0 && i < C && hasGlyph(i) && (D[a] = i);
                });
              } else {
                const e = (function readCmapTable(e, t, a, n) {
                    if (!e) {
                      (0, r.warn)("No cmap table available.");
                      return {
                        platformId: -1,
                        encodingId: -1,
                        mappings: [],
                        hasShortCmap: !1,
                      };
                    }
                    let i,
                      s = (t.start || 0) + e.offset;
                    t.pos = s;
                    t.skip(2);
                    const o = t.getUint16();
                    let c,
                      l = !1;
                    for (let e = 0; e < o; e++) {
                      const r = t.getUint16(),
                        i = t.getUint16(),
                        s = t.getInt32() >>> 0;
                      let h = !1;
                      if (!c || c.platformId !== r || c.encodingId !== i) {
                        if (0 !== r || (0 !== i && 1 !== i && 3 !== i))
                          if (1 === r && 0 === i) h = !0;
                          else if (3 !== r || 1 !== i || (!n && c)) {
                            if (a && 3 === r && 0 === i) {
                              h = !0;
                              let a = !0;
                              if (e < o - 1) {
                                const e = t.peekBytes(2);
                                int16(e[0], e[1]) < r && (a = !1);
                              }
                              a && (l = !0);
                            }
                          } else {
                            h = !0;
                            a || (l = !0);
                          }
                        else h = !0;
                        h && (c = { platformId: r, encodingId: i, offset: s });
                        if (l) break;
                      }
                    }
                    c && (t.pos = s + c.offset);
                    if (!c || -1 === t.peekByte()) {
                      (0, r.warn)("Could not find a preferred cmap table.");
                      return {
                        platformId: -1,
                        encodingId: -1,
                        mappings: [],
                        hasShortCmap: !1,
                      };
                    }
                    const h = t.getUint16();
                    let u = !1;
                    const d = [];
                    let f, g;
                    if (0 === h) {
                      t.skip(4);
                      for (f = 0; f < 256; f++) {
                        const e = t.getByte();
                        e && d.push({ charCode: f, glyphId: e });
                      }
                      u = !0;
                    } else if (2 === h) {
                      t.skip(4);
                      const e = [];
                      let a = 0;
                      for (let r = 0; r < 256; r++) {
                        const r = t.getUint16() >> 3;
                        e.push(r);
                        a = Math.max(r, a);
                      }
                      const r = [];
                      for (let e = 0; e <= a; e++)
                        r.push({
                          firstCode: t.getUint16(),
                          entryCount: t.getUint16(),
                          idDelta: signedInt16(t.getByte(), t.getByte()),
                          idRangePos: t.pos + t.getUint16(),
                        });
                      for (let a = 0; a < 256; a++)
                        if (0 === e[a]) {
                          t.pos = r[0].idRangePos + 2 * a;
                          g = t.getUint16();
                          d.push({ charCode: a, glyphId: g });
                        } else {
                          const n = r[e[a]];
                          for (f = 0; f < n.entryCount; f++) {
                            const e = (a << 8) + f + n.firstCode;
                            t.pos = n.idRangePos + 2 * f;
                            g = t.getUint16();
                            0 !== g && (g = (g + n.idDelta) % 65536);
                            d.push({ charCode: e, glyphId: g });
                          }
                        }
                    } else if (4 === h) {
                      t.skip(4);
                      const e = t.getUint16() >> 1;
                      t.skip(6);
                      const a = [];
                      let r;
                      for (r = 0; r < e; r++) a.push({ end: t.getUint16() });
                      t.skip(2);
                      for (r = 0; r < e; r++) a[r].start = t.getUint16();
                      for (r = 0; r < e; r++) a[r].delta = t.getUint16();
                      let n,
                        o = 0;
                      for (r = 0; r < e; r++) {
                        i = a[r];
                        const s = t.getUint16();
                        if (s) {
                          n = (s >> 1) - (e - r);
                          i.offsetIndex = n;
                          o = Math.max(o, n + i.end - i.start + 1);
                        } else i.offsetIndex = -1;
                      }
                      const c = [];
                      for (f = 0; f < o; f++) c.push(t.getUint16());
                      for (r = 0; r < e; r++) {
                        i = a[r];
                        s = i.start;
                        const e = i.end,
                          t = i.delta;
                        n = i.offsetIndex;
                        for (f = s; f <= e; f++)
                          if (65535 !== f) {
                            g = n < 0 ? f : c[n + f - s];
                            g = (g + t) & 65535;
                            d.push({ charCode: f, glyphId: g });
                          }
                      }
                    } else if (6 === h) {
                      t.skip(4);
                      const e = t.getUint16(),
                        a = t.getUint16();
                      for (f = 0; f < a; f++) {
                        g = t.getUint16();
                        const a = e + f;
                        d.push({ charCode: a, glyphId: g });
                      }
                    } else {
                      if (12 !== h) {
                        (0, r.warn)("cmap table has unsupported format: " + h);
                        return {
                          platformId: -1,
                          encodingId: -1,
                          mappings: [],
                          hasShortCmap: !1,
                        };
                      }
                      {
                        t.skip(10);
                        const e = t.getInt32() >>> 0;
                        for (f = 0; f < e; f++) {
                          const e = t.getInt32() >>> 0,
                            a = t.getInt32() >>> 0;
                          let r = t.getInt32() >>> 0;
                          for (let t = e; t <= a; t++)
                            d.push({ charCode: t, glyphId: r++ });
                        }
                      }
                    }
                    d.sort(function (e, t) {
                      return e.charCode - t.charCode;
                    });
                    for (let e = 1; e < d.length; e++)
                      if (d[e - 1].charCode === d[e].charCode) {
                        d.splice(e, 1);
                        e--;
                      }
                    return {
                      platformId: c.platformId,
                      encodingId: c.encodingId,
                      mappings: d,
                      hasShortCmap: u,
                    };
                  })(f.cmap, t, this.isSymbolicFont, a.hasEncoding),
                  n = e.platformId,
                  s = e.encodingId,
                  l = e.mappings;
                let u = [],
                  d = !1;
                !a.hasEncoding ||
                  ("MacRomanEncoding" !== a.baseEncodingName &&
                    "WinAnsiEncoding" !== a.baseEncodingName) ||
                  (u = (0, c.getEncoding)(a.baseEncodingName));
                if (
                  a.hasEncoding &&
                  !this.isSymbolicFont &&
                  ((3 === n && 1 === s) || (1 === n && 0 === s))
                ) {
                  const e = (0, o.getGlyphsUnicode)();
                  for (let t = 0; t < 256; t++) {
                    let r;
                    r =
                      void 0 !== this.differences[t]
                        ? this.differences[t]
                        : u.length && "" !== u[t]
                        ? u[t]
                        : c.StandardEncoding[t];
                    if (!r) continue;
                    const o = (0, i.recoverGlyphName)(r, e);
                    let d;
                    3 === n && 1 === s
                      ? (d = e[o])
                      : 1 === n &&
                        0 === s &&
                        (d = c.MacRomanEncoding.indexOf(o));
                    if (void 0 === d) {
                      if (
                        !a.glyphNames &&
                        a.hasIncludedToUnicodeMap &&
                        !(this.toUnicode instanceof h.IdentityToUnicodeMap)
                      ) {
                        const e = this.toUnicode.get(t);
                        e && (d = e.codePointAt(0));
                      }
                      if (void 0 === d) continue;
                    }
                    for (const e of l)
                      if (e.charCode === d) {
                        D[t] = e.glyphId;
                        break;
                      }
                  }
                } else if (0 === n) {
                  for (const e of l) D[e.charCode] = e.glyphId;
                  d = !0;
                } else
                  for (const e of l) {
                    let t = e.charCode;
                    3 === n && t >= 61440 && t <= 61695 && (t &= 255);
                    D[t] = e.glyphId;
                  }
                if (a.glyphNames && (u.length || this.differences.length))
                  for (let e = 0; e < 256; ++e) {
                    if (!d && void 0 !== D[e]) continue;
                    const t = this.differences[e] || u[e];
                    if (!t) continue;
                    const r = a.glyphNames.indexOf(t);
                    r > 0 && hasGlyph(r) && (D[e] = r);
                  }
              }
              0 === D.length && (D[0] = 0);
              let N = k - 1;
              v || (N = 0);
              if (!a.cssFontInfo) {
                const e = adjustMapping(D, hasGlyph, N, this.toUnicode);
                this.toFontChar = e.toFontChar;
                f.cmap = {
                  tag: "cmap",
                  data: createCmapTable(
                    e.charCodeToGlyphId,
                    e.toUnicodeExtraMap,
                    k
                  ),
                };
                (f["OS/2"] &&
                  (function validateOS2Table(e, t) {
                    t.pos = (t.start || 0) + e.offset;
                    const a = t.getUint16();
                    t.skip(60);
                    const r = t.getUint16();
                    if (a < 4 && 768 & r) return !1;
                    if (t.getUint16() > t.getUint16()) return !1;
                    t.skip(6);
                    if (0 === t.getUint16()) return !1;
                    e.data[8] = e.data[9] = 0;
                    return !0;
                  })(f["OS/2"], t)) ||
                  (f["OS/2"] = {
                    tag: "OS/2",
                    data: createOS2Table(a, e.charCodeToGlyphId, E),
                  });
              }
              if (!S)
                try {
                  w = new y.Stream(f["CFF "].data);
                  b = new n.CFFParser(w, a, i.SEAC_ANALYSIS_ENABLED).parse();
                  b.duplicateFirstGlyph();
                  const e = new n.CFFCompiler(b);
                  f["CFF "].data = e.compile();
                } catch (e) {
                  (0, r.warn)("Failed to compile font " + a.loadedName);
                }
              if (f.name) {
                const [t, r] = readNameTable(f.name);
                f.name.data = createNameTable(e, t);
                this.psName = t[0][6] || null;
                a.composite ||
                  (function adjustTrueTypeToUnicode(e, t, a) {
                    if (e.isInternalFont) return;
                    if (e.hasIncludedToUnicodeMap) return;
                    if (e.hasEncoding) return;
                    if (e.toUnicode instanceof h.IdentityToUnicodeMap) return;
                    if (!t) return;
                    if (0 === a.length) return;
                    if (e.defaultEncoding === c.WinAnsiEncoding) return;
                    for (const e of a) if (!isWinNameRecord(e)) return;
                    const r = c.WinAnsiEncoding,
                      n = [],
                      i = (0, o.getGlyphsUnicode)();
                    for (const e in r) {
                      const t = r[e];
                      if ("" === t) continue;
                      const a = i[t];
                      void 0 !== a && (n[e] = String.fromCharCode(a));
                    }
                    n.length > 0 && e.toUnicode.amend(n);
                  })(a, this.isSymbolicFont, r);
              } else f.name = { tag: "name", data: createNameTable(this.name) };
              const R = new m.OpenTypeFileBuilder(d.version);
              for (const e in f) R.addTable(e, f[e].data);
              return R.toArray();
            }
            convert(e, t, a) {
              a.fixedPitch = !1;
              a.builtInEncoding &&
                (function adjustType1ToUnicode(e, t) {
                  if (e.isInternalFont) return;
                  if (e.hasIncludedToUnicodeMap) return;
                  if (t === e.defaultEncoding) return;
                  if (e.toUnicode instanceof h.IdentityToUnicodeMap) return;
                  const a = [],
                    r = (0, o.getGlyphsUnicode)();
                  for (const n in t) {
                    if (
                      e.hasEncoding &&
                      (e.baseEncodingName || void 0 !== e.differences[n])
                    )
                      continue;
                    const i = t[n],
                      o = (0, s.getUnicodeForGlyph)(i, r);
                    -1 !== o && (a[n] = String.fromCharCode(o));
                  }
                  a.length > 0 && e.toUnicode.amend(a);
                })(a, a.builtInEncoding);
              let n = 1;
              t instanceof u.CFFFont && (n = t.numGlyphs - 1);
              const l = t.getGlyphMapping(a);
              let d = null,
                f = l,
                g = null;
              if (!a.cssFontInfo) {
                d = adjustMapping(l, t.hasGlyphId.bind(t), n, this.toUnicode);
                this.toFontChar = d.toFontChar;
                f = d.charCodeToGlyphId;
                g = d.toUnicodeExtraMap;
              }
              const p = t.numGlyphs;
              function getCharCodes(e, t) {
                let a = null;
                for (const r in e)
                  if (t === e[r]) {
                    a || (a = []);
                    a.push(0 | r);
                  }
                return a;
              }
              function createCharCode(e, t) {
                for (const a in e) if (t === e[a]) return 0 | a;
                d.charCodeToGlyphId[d.nextAvailableFontCharCode] = t;
                return d.nextAvailableFontCharCode++;
              }
              const b = t.seacs;
              if (d && i.SEAC_ANALYSIS_ENABLED && b && b.length) {
                const e = a.fontMatrix || r.FONT_IDENTITY_MATRIX,
                  n = t.getCharset(),
                  i = Object.create(null);
                for (let t in b) {
                  t |= 0;
                  const a = b[t],
                    r = c.StandardEncoding[a[2]],
                    s = c.StandardEncoding[a[3]],
                    o = n.indexOf(r),
                    h = n.indexOf(s);
                  if (o < 0 || h < 0) continue;
                  const u = {
                      x: a[0] * e[0] + a[1] * e[2] + e[4],
                      y: a[0] * e[1] + a[1] * e[3] + e[5],
                    },
                    f = getCharCodes(l, t);
                  if (f)
                    for (const e of f) {
                      const t = d.charCodeToGlyphId,
                        a = createCharCode(t, o),
                        r = createCharCode(t, h);
                      i[e] = {
                        baseFontCharCode: a,
                        accentFontCharCode: r,
                        accentOffset: u,
                      };
                    }
                }
                a.seacMap = i;
              }
              const y = 1 / (a.fontMatrix || r.FONT_IDENTITY_MATRIX)[0],
                w = new m.OpenTypeFileBuilder("OTTO");
              w.addTable("CFF ", t.data);
              w.addTable("OS/2", createOS2Table(a, f));
              w.addTable("cmap", createCmapTable(f, g, p));
              w.addTable(
                "head",
                "\0\0\0\0\0\0\0\0\0\0_<õ\0\0" +
                  safeString16(y) +
                  "\0\0\0\0\v~'\0\0\0\0\v~'\0\0" +
                  safeString16(a.descent) +
                  "ÿ" +
                  safeString16(a.ascent) +
                  string16(a.italicAngle ? 2 : 0) +
                  "\0\0\0\0\0\0\0"
              );
              w.addTable(
                "hhea",
                "\0\0\0" +
                  safeString16(a.ascent) +
                  safeString16(a.descent) +
                  "\0\0ÿÿ\0\0\0\0\0\0" +
                  safeString16(a.capHeight) +
                  safeString16(Math.tan(a.italicAngle) * a.xHeight) +
                  "\0\0\0\0\0\0\0\0\0\0\0\0" +
                  string16(p)
              );
              w.addTable(
                "hmtx",
                (function fontFieldsHmtx() {
                  const e = t.charstrings,
                    a = t.cff ? t.cff.widths : null;
                  let r = "\0\0\0\0";
                  for (let t = 1, n = p; t < n; t++) {
                    let n = 0;
                    if (e) {
                      const a = e[t - 1];
                      n = "width" in a ? a.width : 0;
                    } else a && (n = Math.ceil(a[t] || 0));
                    r += string16(n) + string16(0);
                  }
                  return r;
                })()
              );
              w.addTable("maxp", "\0\0P\0" + string16(p));
              w.addTable("name", createNameTable(e));
              w.addTable("post", createPostTable(a));
              return w.toArray();
            }
            get spaceWidth() {
              const e = ["space", "minus", "one", "i", "I"];
              let t;
              for (const a of e) {
                if (a in this.widths) {
                  t = this.widths[a];
                  break;
                }
                const e = (0, o.getGlyphsUnicode)()[a];
                let r = 0;
                if (this.composite && this.cMap.contains(e)) {
                  r = this.cMap.lookup(e);
                  "string" == typeof r && (r = convertCidString(e, r));
                }
                !r && this.toUnicode && (r = this.toUnicode.charCodeOf(e));
                r <= 0 && (r = e);
                t = this.widths[r];
                if (t) break;
              }
              t = t || this.defaultWidth;
              return (0, r.shadow)(this, "spaceWidth", t);
            }
            _charToGlyph(e, t = !1) {
              let a,
                n,
                i,
                o = this._glyphCache[e];
              if (o && o.isSpace === t) return o;
              let c = e;
              if (this.cMap && this.cMap.contains(e)) {
                c = this.cMap.lookup(e);
                "string" == typeof c && (c = convertCidString(e, c));
              }
              n = this.widths[c];
              "number" != typeof n && (n = this.defaultWidth);
              const l = this.vmetrics && this.vmetrics[c];
              let h = this.toUnicode.get(e) || e;
              "number" == typeof h && (h = String.fromCharCode(h));
              let u = void 0 !== this.toFontChar[e];
              a = this.toFontChar[e] || e;
              if (this.missingFile) {
                const t = this.differences[e] || this.defaultEncoding[e];
                (".notdef" !== t && "" !== t) ||
                  "Type1" !== this.type ||
                  (a = 32);
                a = (0, s.mapSpecialUnicodeValues)(a);
              }
              this.isType3Font && (i = a);
              let d = null;
              if (this.seacMap && this.seacMap[e]) {
                u = !0;
                const t = this.seacMap[e];
                a = t.baseFontCharCode;
                d = {
                  fontChar: String.fromCodePoint(t.accentFontCharCode),
                  offset: t.accentOffset,
                };
              }
              let f = "";
              "number" == typeof a &&
                (a <= 1114111
                  ? (f = String.fromCodePoint(a))
                  : (0, r.warn)(`charToGlyph - invalid fontCharCode: ${a}`));
              o = new Glyph(e, f, h, d, n, l, i, t, u);
              return (this._glyphCache[e] = o);
            }
            charsToGlyphs(e) {
              let t = this._charsCache[e];
              if (t) return t;
              t = [];
              if (this.cMap) {
                const a = Object.create(null),
                  r = e.length;
                let n = 0;
                for (; n < r; ) {
                  this.cMap.readCharCode(e, n, a);
                  const { charcode: r, length: i } = a;
                  n += i;
                  const s = this._charToGlyph(
                    r,
                    1 === i && 32 === e.charCodeAt(n - 1)
                  );
                  t.push(s);
                }
              } else
                for (let a = 0, r = e.length; a < r; ++a) {
                  const r = e.charCodeAt(a),
                    n = this._charToGlyph(r, 32 === r);
                  t.push(n);
                }
              return (this._charsCache[e] = t);
            }
            getCharPositions(e) {
              const t = [];
              if (this.cMap) {
                const a = Object.create(null);
                let r = 0;
                for (; r < e.length; ) {
                  this.cMap.readCharCode(e, r, a);
                  const n = a.length;
                  t.push([r, r + n]);
                  r += n;
                }
              } else
                for (let a = 0, r = e.length; a < r; ++a) t.push([a, a + 1]);
              return t;
            }
            get glyphCacheValues() {
              return Object.values(this._glyphCache);
            }
            encodeString(e) {
              const t = [],
                a = [],
                hasCurrentBufErrors = () => t.length % 2 == 1,
                r =
                  this.toUnicode instanceof h.IdentityToUnicodeMap
                    ? (e) => this.toUnicode.charCodeOf(e)
                    : (e) => this.toUnicode.charCodeOf(String.fromCodePoint(e));
              for (let n = 0, i = e.length; n < i; n++) {
                const i = e.codePointAt(n);
                i > 55295 && (i < 57344 || i > 65533) && n++;
                if (this.toUnicode) {
                  const e = r(i);
                  if (-1 !== e) {
                    if (hasCurrentBufErrors()) {
                      t.push(a.join(""));
                      a.length = 0;
                    }
                    for (
                      let t =
                        (this.cMap ? this.cMap.getCharCodeLength(e) : 1) - 1;
                      t >= 0;
                      t--
                    )
                      a.push(String.fromCharCode((e >> (8 * t)) & 255));
                    continue;
                  }
                }
                if (!hasCurrentBufErrors()) {
                  t.push(a.join(""));
                  a.length = 0;
                }
                a.push(String.fromCodePoint(i));
              }
              t.push(a.join(""));
              return t;
            }
          };
          t.ErrorFont = class ErrorFont {
            constructor(e) {
              this.error = e;
              this.loadedName = "g_font_error";
              this.missingFile = !0;
            }
            charsToGlyphs() {
              return [];
            }
            encodeString(e) {
              return [e];
            }
            exportData(e = !1) {
              return { error: this.error };
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.CFFTopDict =
            t.CFFStrings =
            t.CFFStandardStrings =
            t.CFFPrivateDict =
            t.CFFParser =
            t.CFFIndex =
            t.CFFHeader =
            t.CFFFDSelect =
            t.CFFCompiler =
            t.CFFCharset =
            t.CFF =
              void 0;
          var r = a(2),
            n = a(34),
            i = a(35);
          const s = [
            ".notdef",
            "space",
            "exclam",
            "quotedbl",
            "numbersign",
            "dollar",
            "percent",
            "ampersand",
            "quoteright",
            "parenleft",
            "parenright",
            "asterisk",
            "plus",
            "comma",
            "hyphen",
            "period",
            "slash",
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "colon",
            "semicolon",
            "less",
            "equal",
            "greater",
            "question",
            "at",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "bracketleft",
            "backslash",
            "bracketright",
            "asciicircum",
            "underscore",
            "quoteleft",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "braceleft",
            "bar",
            "braceright",
            "asciitilde",
            "exclamdown",
            "cent",
            "sterling",
            "fraction",
            "yen",
            "florin",
            "section",
            "currency",
            "quotesingle",
            "quotedblleft",
            "guillemotleft",
            "guilsinglleft",
            "guilsinglright",
            "fi",
            "fl",
            "endash",
            "dagger",
            "daggerdbl",
            "periodcentered",
            "paragraph",
            "bullet",
            "quotesinglbase",
            "quotedblbase",
            "quotedblright",
            "guillemotright",
            "ellipsis",
            "perthousand",
            "questiondown",
            "grave",
            "acute",
            "circumflex",
            "tilde",
            "macron",
            "breve",
            "dotaccent",
            "dieresis",
            "ring",
            "cedilla",
            "hungarumlaut",
            "ogonek",
            "caron",
            "emdash",
            "AE",
            "ordfeminine",
            "Lslash",
            "Oslash",
            "OE",
            "ordmasculine",
            "ae",
            "dotlessi",
            "lslash",
            "oslash",
            "oe",
            "germandbls",
            "onesuperior",
            "logicalnot",
            "mu",
            "trademark",
            "Eth",
            "onehalf",
            "plusminus",
            "Thorn",
            "onequarter",
            "divide",
            "brokenbar",
            "degree",
            "thorn",
            "threequarters",
            "twosuperior",
            "registered",
            "minus",
            "eth",
            "multiply",
            "threesuperior",
            "copyright",
            "Aacute",
            "Acircumflex",
            "Adieresis",
            "Agrave",
            "Aring",
            "Atilde",
            "Ccedilla",
            "Eacute",
            "Ecircumflex",
            "Edieresis",
            "Egrave",
            "Iacute",
            "Icircumflex",
            "Idieresis",
            "Igrave",
            "Ntilde",
            "Oacute",
            "Ocircumflex",
            "Odieresis",
            "Ograve",
            "Otilde",
            "Scaron",
            "Uacute",
            "Ucircumflex",
            "Udieresis",
            "Ugrave",
            "Yacute",
            "Ydieresis",
            "Zcaron",
            "aacute",
            "acircumflex",
            "adieresis",
            "agrave",
            "aring",
            "atilde",
            "ccedilla",
            "eacute",
            "ecircumflex",
            "edieresis",
            "egrave",
            "iacute",
            "icircumflex",
            "idieresis",
            "igrave",
            "ntilde",
            "oacute",
            "ocircumflex",
            "odieresis",
            "ograve",
            "otilde",
            "scaron",
            "uacute",
            "ucircumflex",
            "udieresis",
            "ugrave",
            "yacute",
            "ydieresis",
            "zcaron",
            "exclamsmall",
            "Hungarumlautsmall",
            "dollaroldstyle",
            "dollarsuperior",
            "ampersandsmall",
            "Acutesmall",
            "parenleftsuperior",
            "parenrightsuperior",
            "twodotenleader",
            "onedotenleader",
            "zerooldstyle",
            "oneoldstyle",
            "twooldstyle",
            "threeoldstyle",
            "fouroldstyle",
            "fiveoldstyle",
            "sixoldstyle",
            "sevenoldstyle",
            "eightoldstyle",
            "nineoldstyle",
            "commasuperior",
            "threequartersemdash",
            "periodsuperior",
            "questionsmall",
            "asuperior",
            "bsuperior",
            "centsuperior",
            "dsuperior",
            "esuperior",
            "isuperior",
            "lsuperior",
            "msuperior",
            "nsuperior",
            "osuperior",
            "rsuperior",
            "ssuperior",
            "tsuperior",
            "ff",
            "ffi",
            "ffl",
            "parenleftinferior",
            "parenrightinferior",
            "Circumflexsmall",
            "hyphensuperior",
            "Gravesmall",
            "Asmall",
            "Bsmall",
            "Csmall",
            "Dsmall",
            "Esmall",
            "Fsmall",
            "Gsmall",
            "Hsmall",
            "Ismall",
            "Jsmall",
            "Ksmall",
            "Lsmall",
            "Msmall",
            "Nsmall",
            "Osmall",
            "Psmall",
            "Qsmall",
            "Rsmall",
            "Ssmall",
            "Tsmall",
            "Usmall",
            "Vsmall",
            "Wsmall",
            "Xsmall",
            "Ysmall",
            "Zsmall",
            "colonmonetary",
            "onefitted",
            "rupiah",
            "Tildesmall",
            "exclamdownsmall",
            "centoldstyle",
            "Lslashsmall",
            "Scaronsmall",
            "Zcaronsmall",
            "Dieresissmall",
            "Brevesmall",
            "Caronsmall",
            "Dotaccentsmall",
            "Macronsmall",
            "figuredash",
            "hypheninferior",
            "Ogoneksmall",
            "Ringsmall",
            "Cedillasmall",
            "questiondownsmall",
            "oneeighth",
            "threeeighths",
            "fiveeighths",
            "seveneighths",
            "onethird",
            "twothirds",
            "zerosuperior",
            "foursuperior",
            "fivesuperior",
            "sixsuperior",
            "sevensuperior",
            "eightsuperior",
            "ninesuperior",
            "zeroinferior",
            "oneinferior",
            "twoinferior",
            "threeinferior",
            "fourinferior",
            "fiveinferior",
            "sixinferior",
            "seveninferior",
            "eightinferior",
            "nineinferior",
            "centinferior",
            "dollarinferior",
            "periodinferior",
            "commainferior",
            "Agravesmall",
            "Aacutesmall",
            "Acircumflexsmall",
            "Atildesmall",
            "Adieresissmall",
            "Aringsmall",
            "AEsmall",
            "Ccedillasmall",
            "Egravesmall",
            "Eacutesmall",
            "Ecircumflexsmall",
            "Edieresissmall",
            "Igravesmall",
            "Iacutesmall",
            "Icircumflexsmall",
            "Idieresissmall",
            "Ethsmall",
            "Ntildesmall",
            "Ogravesmall",
            "Oacutesmall",
            "Ocircumflexsmall",
            "Otildesmall",
            "Odieresissmall",
            "OEsmall",
            "Oslashsmall",
            "Ugravesmall",
            "Uacutesmall",
            "Ucircumflexsmall",
            "Udieresissmall",
            "Yacutesmall",
            "Thornsmall",
            "Ydieresissmall",
            "001.000",
            "001.001",
            "001.002",
            "001.003",
            "Black",
            "Bold",
            "Book",
            "Light",
            "Medium",
            "Regular",
            "Roman",
            "Semibold",
          ];
          t.CFFStandardStrings = s;
          const o = 391,
            c = [
              null,
              { id: "hstem", min: 2, stackClearing: !0, stem: !0 },
              null,
              { id: "vstem", min: 2, stackClearing: !0, stem: !0 },
              { id: "vmoveto", min: 1, stackClearing: !0 },
              { id: "rlineto", min: 2, resetStack: !0 },
              { id: "hlineto", min: 1, resetStack: !0 },
              { id: "vlineto", min: 1, resetStack: !0 },
              { id: "rrcurveto", min: 6, resetStack: !0 },
              null,
              { id: "callsubr", min: 1, undefStack: !0 },
              { id: "return", min: 0, undefStack: !0 },
              null,
              null,
              { id: "endchar", min: 0, stackClearing: !0 },
              null,
              null,
              null,
              { id: "hstemhm", min: 2, stackClearing: !0, stem: !0 },
              { id: "hintmask", min: 0, stackClearing: !0 },
              { id: "cntrmask", min: 0, stackClearing: !0 },
              { id: "rmoveto", min: 2, stackClearing: !0 },
              { id: "hmoveto", min: 1, stackClearing: !0 },
              { id: "vstemhm", min: 2, stackClearing: !0, stem: !0 },
              { id: "rcurveline", min: 8, resetStack: !0 },
              { id: "rlinecurve", min: 8, resetStack: !0 },
              { id: "vvcurveto", min: 4, resetStack: !0 },
              { id: "hhcurveto", min: 4, resetStack: !0 },
              null,
              { id: "callgsubr", min: 1, undefStack: !0 },
              { id: "vhcurveto", min: 4, resetStack: !0 },
              { id: "hvcurveto", min: 4, resetStack: !0 },
            ],
            l = [
              null,
              null,
              null,
              { id: "and", min: 2, stackDelta: -1 },
              { id: "or", min: 2, stackDelta: -1 },
              { id: "not", min: 1, stackDelta: 0 },
              null,
              null,
              null,
              { id: "abs", min: 1, stackDelta: 0 },
              {
                id: "add",
                min: 2,
                stackDelta: -1,
                stackFn(e, t) {
                  e[t - 2] = e[t - 2] + e[t - 1];
                },
              },
              {
                id: "sub",
                min: 2,
                stackDelta: -1,
                stackFn(e, t) {
                  e[t - 2] = e[t - 2] - e[t - 1];
                },
              },
              {
                id: "div",
                min: 2,
                stackDelta: -1,
                stackFn(e, t) {
                  e[t - 2] = e[t - 2] / e[t - 1];
                },
              },
              null,
              {
                id: "neg",
                min: 1,
                stackDelta: 0,
                stackFn(e, t) {
                  e[t - 1] = -e[t - 1];
                },
              },
              { id: "eq", min: 2, stackDelta: -1 },
              null,
              null,
              { id: "drop", min: 1, stackDelta: -1 },
              null,
              { id: "put", min: 2, stackDelta: -2 },
              { id: "get", min: 1, stackDelta: 0 },
              { id: "ifelse", min: 4, stackDelta: -3 },
              { id: "random", min: 0, stackDelta: 1 },
              {
                id: "mul",
                min: 2,
                stackDelta: -1,
                stackFn(e, t) {
                  e[t - 2] = e[t - 2] * e[t - 1];
                },
              },
              null,
              { id: "sqrt", min: 1, stackDelta: 0 },
              { id: "dup", min: 1, stackDelta: 1 },
              { id: "exch", min: 2, stackDelta: 0 },
              { id: "index", min: 2, stackDelta: 0 },
              { id: "roll", min: 3, stackDelta: -2 },
              null,
              null,
              null,
              { id: "hflex", min: 7, resetStack: !0 },
              { id: "flex", min: 13, resetStack: !0 },
              { id: "hflex1", min: 9, resetStack: !0 },
              { id: "flex1", min: 11, resetStack: !0 },
            ];
          t.CFFParser = class CFFParser {
            constructor(e, t, a) {
              this.bytes = e.getBytes();
              this.properties = t;
              this.seacAnalysisEnabled = !!a;
            }
            parse() {
              const e = this.properties,
                t = new CFF();
              this.cff = t;
              const a = this.parseHeader(),
                r = this.parseIndex(a.endPos),
                n = this.parseIndex(r.endPos),
                i = this.parseIndex(n.endPos),
                s = this.parseIndex(i.endPos),
                o = this.parseDict(n.obj.get(0)),
                c = this.createDict(CFFTopDict, o, t.strings);
              t.header = a.obj;
              t.names = this.parseNameIndex(r.obj);
              t.strings = this.parseStringIndex(i.obj);
              t.topDict = c;
              t.globalSubrIndex = s.obj;
              this.parsePrivateDict(t.topDict);
              t.isCIDFont = c.hasName("ROS");
              const l = c.getByName("CharStrings"),
                h = this.parseIndex(l).obj,
                u = c.getByName("FontMatrix");
              u && (e.fontMatrix = u);
              const d = c.getByName("FontBBox");
              if (d) {
                e.ascent = Math.max(d[3], d[1]);
                e.descent = Math.min(d[1], d[3]);
                e.ascentScaled = !0;
              }
              let f, g;
              if (t.isCIDFont) {
                const e = this.parseIndex(c.getByName("FDArray")).obj;
                for (let a = 0, r = e.count; a < r; ++a) {
                  const r = e.get(a),
                    n = this.createDict(
                      CFFTopDict,
                      this.parseDict(r),
                      t.strings
                    );
                  this.parsePrivateDict(n);
                  t.fdArray.push(n);
                }
                g = null;
                f = this.parseCharsets(
                  c.getByName("charset"),
                  h.count,
                  t.strings,
                  !0
                );
                t.fdSelect = this.parseFDSelect(
                  c.getByName("FDSelect"),
                  h.count
                );
              } else {
                f = this.parseCharsets(
                  c.getByName("charset"),
                  h.count,
                  t.strings,
                  !1
                );
                g = this.parseEncoding(
                  c.getByName("Encoding"),
                  e,
                  t.strings,
                  f.charset
                );
              }
              t.charset = f;
              t.encoding = g;
              const p = this.parseCharStrings({
                charStrings: h,
                localSubrIndex: c.privateDict.subrsIndex,
                globalSubrIndex: s.obj,
                fdSelect: t.fdSelect,
                fdArray: t.fdArray,
                privateDict: c.privateDict,
              });
              t.charStrings = p.charStrings;
              t.seacs = p.seacs;
              t.widths = p.widths;
              return t;
            }
            parseHeader() {
              let e = this.bytes;
              const t = e.length;
              let a = 0;
              for (; a < t && 1 !== e[a]; ) ++a;
              if (a >= t) throw new r.FormatError("Invalid CFF header");
              if (0 !== a) {
                (0, r.info)("cff data is shifted");
                e = e.subarray(a);
                this.bytes = e;
              }
              const n = e[0],
                i = e[1],
                s = e[2],
                o = e[3];
              return { obj: new CFFHeader(n, i, s, o), endPos: s };
            }
            parseDict(e) {
              let t = 0;
              function parseOperand() {
                let a = e[t++];
                if (30 === a)
                  return (function parseFloatOperand() {
                    let a = "";
                    const r = 15,
                      n = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        ".",
                        "E",
                        "E-",
                        null,
                        "-",
                      ],
                      i = e.length;
                    for (; t < i; ) {
                      const i = e[t++],
                        s = i >> 4,
                        o = 15 & i;
                      if (s === r) break;
                      a += n[s];
                      if (o === r) break;
                      a += n[o];
                    }
                    return parseFloat(a);
                  })();
                if (28 === a) {
                  a = e[t++];
                  a = ((a << 24) | (e[t++] << 16)) >> 16;
                  return a;
                }
                if (29 === a) {
                  a = e[t++];
                  a = (a << 8) | e[t++];
                  a = (a << 8) | e[t++];
                  a = (a << 8) | e[t++];
                  return a;
                }
                if (a >= 32 && a <= 246) return a - 139;
                if (a >= 247 && a <= 250) return 256 * (a - 247) + e[t++] + 108;
                if (a >= 251 && a <= 254)
                  return -256 * (a - 251) - e[t++] - 108;
                (0, r.warn)(
                  'CFFParser_parseDict: "' + a + '" is a reserved command.'
                );
                return NaN;
              }
              let a = [];
              const n = [];
              t = 0;
              const i = e.length;
              for (; t < i; ) {
                let r = e[t];
                if (r <= 21) {
                  12 === r && (r = (r << 8) | e[++t]);
                  n.push([r, a]);
                  a = [];
                  ++t;
                } else a.push(parseOperand());
              }
              return n;
            }
            parseIndex(e) {
              const t = new CFFIndex(),
                a = this.bytes,
                r = (a[e++] << 8) | a[e++],
                n = [];
              let i,
                s,
                o = e;
              if (0 !== r) {
                const t = a[e++],
                  c = e + (r + 1) * t - 1;
                for (i = 0, s = r + 1; i < s; ++i) {
                  let r = 0;
                  for (let n = 0; n < t; ++n) {
                    r <<= 8;
                    r += a[e++];
                  }
                  n.push(c + r);
                }
                o = n[r];
              }
              for (i = 0, s = n.length - 1; i < s; ++i) {
                const e = n[i],
                  r = n[i + 1];
                t.add(a.subarray(e, r));
              }
              return { obj: t, endPos: o };
            }
            parseNameIndex(e) {
              const t = [];
              for (let a = 0, n = e.count; a < n; ++a) {
                const n = e.get(a);
                t.push((0, r.bytesToString)(n));
              }
              return t;
            }
            parseStringIndex(e) {
              const t = new CFFStrings();
              for (let a = 0, n = e.count; a < n; ++a) {
                const n = e.get(a);
                t.add((0, r.bytesToString)(n));
              }
              return t;
            }
            createDict(e, t, a) {
              const r = new e(a);
              for (const [e, a] of t) r.setByKey(e, a);
              return r;
            }
            parseCharString(e, t, a, n) {
              if (!t || e.callDepth > 10) return !1;
              let i = e.stackSize;
              const s = e.stack;
              let o = t.length;
              for (let h = 0; h < o; ) {
                const u = t[h++];
                let d = null;
                if (12 === u) {
                  const e = t[h++];
                  if (0 === e) {
                    t[h - 2] = 139;
                    t[h - 1] = 22;
                    i = 0;
                  } else d = l[e];
                } else if (28 === u) {
                  s[i] = ((t[h] << 24) | (t[h + 1] << 16)) >> 16;
                  h += 2;
                  i++;
                } else if (14 === u) {
                  if (i >= 4) {
                    i -= 4;
                    if (this.seacAnalysisEnabled) {
                      e.seac = s.slice(i, i + 4);
                      return !1;
                    }
                  }
                  d = c[u];
                } else if (u >= 32 && u <= 246) {
                  s[i] = u - 139;
                  i++;
                } else if (u >= 247 && u <= 254) {
                  s[i] =
                    u < 251
                      ? ((u - 247) << 8) + t[h] + 108
                      : -((u - 251) << 8) - t[h] - 108;
                  h++;
                  i++;
                } else if (255 === u) {
                  s[i] =
                    ((t[h] << 24) |
                      (t[h + 1] << 16) |
                      (t[h + 2] << 8) |
                      t[h + 3]) /
                    65536;
                  h += 4;
                  i++;
                } else if (19 === u || 20 === u) {
                  e.hints += i >> 1;
                  h += (e.hints + 7) >> 3;
                  i %= 2;
                  d = c[u];
                } else {
                  if (10 === u || 29 === u) {
                    let t;
                    t = 10 === u ? a : n;
                    if (!t) {
                      d = c[u];
                      (0, r.warn)("Missing subrsIndex for " + d.id);
                      return !1;
                    }
                    let o = 32768;
                    t.count < 1240 ? (o = 107) : t.count < 33900 && (o = 1131);
                    const l = s[--i] + o;
                    if (l < 0 || l >= t.count || isNaN(l)) {
                      d = c[u];
                      (0, r.warn)("Out of bounds subrIndex for " + d.id);
                      return !1;
                    }
                    e.stackSize = i;
                    e.callDepth++;
                    if (!this.parseCharString(e, t.get(l), a, n)) return !1;
                    e.callDepth--;
                    i = e.stackSize;
                    continue;
                  }
                  if (11 === u) {
                    e.stackSize = i;
                    return !0;
                  }
                  if (0 === u && h === t.length) {
                    t[h - 1] = 14;
                    d = c[14];
                  } else {
                    if (9 === u) {
                      t.copyWithin(h - 1, h, -1);
                      h -= 1;
                      o -= 1;
                      continue;
                    }
                    d = c[u];
                  }
                }
                if (d) {
                  if (d.stem) {
                    e.hints += i >> 1;
                    if (3 === u || 23 === u) e.hasVStems = !0;
                    else if (e.hasVStems && (1 === u || 18 === u)) {
                      (0, r.warn)("CFF stem hints are in wrong order");
                      t[h - 1] = 1 === u ? 3 : 23;
                    }
                  }
                  if ("min" in d && !e.undefStack && i < d.min) {
                    (0, r.warn)(
                      "Not enough parameters for " +
                        d.id +
                        "; actual: " +
                        i +
                        ", expected: " +
                        d.min
                    );
                    if (0 === i) {
                      t[h - 1] = 14;
                      return !0;
                    }
                    return !1;
                  }
                  if (e.firstStackClearing && d.stackClearing) {
                    e.firstStackClearing = !1;
                    i -= d.min;
                    i >= 2 && d.stem
                      ? (i %= 2)
                      : i > 1 &&
                        (0, r.warn)(
                          "Found too many parameters for stack-clearing command"
                        );
                    i > 0 && (e.width = s[i - 1]);
                  }
                  if ("stackDelta" in d) {
                    "stackFn" in d && d.stackFn(s, i);
                    i += d.stackDelta;
                  } else if (d.stackClearing) i = 0;
                  else if (d.resetStack) {
                    i = 0;
                    e.undefStack = !1;
                  } else if (d.undefStack) {
                    i = 0;
                    e.undefStack = !0;
                    e.firstStackClearing = !1;
                  }
                }
              }
              o < t.length && t.fill(14, o);
              e.stackSize = i;
              return !0;
            }
            parseCharStrings({
              charStrings: e,
              localSubrIndex: t,
              globalSubrIndex: a,
              fdSelect: n,
              fdArray: i,
              privateDict: s,
            }) {
              const o = [],
                c = [],
                l = e.count;
              for (let h = 0; h < l; h++) {
                const l = e.get(h),
                  u = {
                    callDepth: 0,
                    stackSize: 0,
                    stack: [],
                    undefStack: !0,
                    hints: 0,
                    firstStackClearing: !0,
                    seac: null,
                    width: null,
                    hasVStems: !1,
                  };
                let d = !0,
                  f = null,
                  g = s;
                if (n && i.length) {
                  const e = n.getFDIndex(h);
                  if (-1 === e) {
                    (0, r.warn)("Glyph index is not in fd select.");
                    d = !1;
                  }
                  if (e >= i.length) {
                    (0, r.warn)("Invalid fd index for glyph index.");
                    d = !1;
                  }
                  if (d) {
                    g = i[e].privateDict;
                    f = g.subrsIndex;
                  }
                } else t && (f = t);
                d && (d = this.parseCharString(u, l, f, a));
                if (null !== u.width) {
                  const e = g.getByName("nominalWidthX");
                  c[h] = e + u.width;
                } else {
                  const e = g.getByName("defaultWidthX");
                  c[h] = e;
                }
                null !== u.seac && (o[h] = u.seac);
                d || e.set(h, new Uint8Array([14]));
              }
              return { charStrings: e, seacs: o, widths: c };
            }
            emptyPrivateDictionary(e) {
              const t = this.createDict(CFFPrivateDict, [], e.strings);
              e.setByKey(18, [0, 0]);
              e.privateDict = t;
            }
            parsePrivateDict(e) {
              if (!e.hasName("Private")) {
                this.emptyPrivateDictionary(e);
                return;
              }
              const t = e.getByName("Private");
              if (!Array.isArray(t) || 2 !== t.length) {
                e.removeByName("Private");
                return;
              }
              const a = t[0],
                r = t[1];
              if (0 === a || r >= this.bytes.length) {
                this.emptyPrivateDictionary(e);
                return;
              }
              const n = r + a,
                i = this.bytes.subarray(r, n),
                s = this.parseDict(i),
                o = this.createDict(CFFPrivateDict, s, e.strings);
              e.privateDict = o;
              0 === o.getByName("ExpansionFactor") &&
                o.setByName("ExpansionFactor", 0.06);
              if (!o.getByName("Subrs")) return;
              const c = o.getByName("Subrs"),
                l = r + c;
              if (0 === c || l >= this.bytes.length) {
                this.emptyPrivateDictionary(e);
                return;
              }
              const h = this.parseIndex(l);
              o.subrsIndex = h.obj;
            }
            parseCharsets(e, t, a, i) {
              if (0 === e)
                return new CFFCharset(!0, d.ISO_ADOBE, n.ISOAdobeCharset);
              if (1 === e) return new CFFCharset(!0, d.EXPERT, n.ExpertCharset);
              if (2 === e)
                return new CFFCharset(
                  !0,
                  d.EXPERT_SUBSET,
                  n.ExpertSubsetCharset
                );
              const s = this.bytes,
                o = e,
                c = s[e++],
                l = [i ? 0 : ".notdef"];
              let h, u, f;
              t -= 1;
              switch (c) {
                case 0:
                  for (f = 0; f < t; f++) {
                    h = (s[e++] << 8) | s[e++];
                    l.push(i ? h : a.get(h));
                  }
                  break;
                case 1:
                  for (; l.length <= t; ) {
                    h = (s[e++] << 8) | s[e++];
                    u = s[e++];
                    for (f = 0; f <= u; f++) l.push(i ? h++ : a.get(h++));
                  }
                  break;
                case 2:
                  for (; l.length <= t; ) {
                    h = (s[e++] << 8) | s[e++];
                    u = (s[e++] << 8) | s[e++];
                    for (f = 0; f <= u; f++) l.push(i ? h++ : a.get(h++));
                  }
                  break;
                default:
                  throw new r.FormatError("Unknown charset format");
              }
              const g = e,
                p = s.subarray(o, g);
              return new CFFCharset(!1, c, l, p);
            }
            parseEncoding(e, t, a, n) {
              const s = Object.create(null),
                o = this.bytes;
              let c,
                l,
                h,
                u = !1,
                d = null;
              if (0 === e || 1 === e) {
                u = !0;
                c = e;
                const t = e ? i.ExpertEncoding : i.StandardEncoding;
                for (l = 0, h = n.length; l < h; l++) {
                  const e = t.indexOf(n[l]);
                  -1 !== e && (s[e] = l);
                }
              } else {
                const t = e;
                c = o[e++];
                switch (127 & c) {
                  case 0:
                    const t = o[e++];
                    for (l = 1; l <= t; l++) s[o[e++]] = l;
                    break;
                  case 1:
                    const a = o[e++];
                    let n = 1;
                    for (l = 0; l < a; l++) {
                      const t = o[e++],
                        a = o[e++];
                      for (let e = t; e <= t + a; e++) s[e] = n++;
                    }
                    break;
                  default:
                    throw new r.FormatError(
                      `Unknown encoding format: ${c} in CFF`
                    );
                }
                const i = e;
                if (128 & c) {
                  o[t] &= 127;
                  !(function readSupplement() {
                    const t = o[e++];
                    for (l = 0; l < t; l++) {
                      const t = o[e++],
                        r = (o[e++] << 8) + (255 & o[e++]);
                      s[t] = n.indexOf(a.get(r));
                    }
                  })();
                }
                d = o.subarray(t, i);
              }
              c &= 127;
              return new CFFEncoding(u, c, s, d);
            }
            parseFDSelect(e, t) {
              const a = this.bytes,
                n = a[e++],
                i = [];
              let s;
              switch (n) {
                case 0:
                  for (s = 0; s < t; ++s) {
                    const t = a[e++];
                    i.push(t);
                  }
                  break;
                case 3:
                  const o = (a[e++] << 8) | a[e++];
                  for (s = 0; s < o; ++s) {
                    let t = (a[e++] << 8) | a[e++];
                    if (0 === s && 0 !== t) {
                      (0, r.warn)(
                        "parseFDSelect: The first range must have a first GID of 0 -- trying to recover."
                      );
                      t = 0;
                    }
                    const n = a[e++],
                      o = (a[e] << 8) | a[e + 1];
                    for (let e = t; e < o; ++e) i.push(n);
                  }
                  e += 2;
                  break;
                default:
                  throw new r.FormatError(
                    `parseFDSelect: Unknown format "${n}".`
                  );
              }
              if (i.length !== t)
                throw new r.FormatError("parseFDSelect: Invalid font data.");
              return new CFFFDSelect(n, i);
            }
          };
          class CFF {
            constructor() {
              this.header = null;
              this.names = [];
              this.topDict = null;
              this.strings = new CFFStrings();
              this.globalSubrIndex = null;
              this.encoding = null;
              this.charset = null;
              this.charStrings = null;
              this.fdArray = [];
              this.fdSelect = null;
              this.isCIDFont = !1;
            }
            duplicateFirstGlyph() {
              if (this.charStrings.count >= 65535) {
                (0, r.warn)(
                  "Not enough space in charstrings to duplicate first glyph."
                );
                return;
              }
              const e = this.charStrings.get(0);
              this.charStrings.add(e);
              this.isCIDFont &&
                this.fdSelect.fdSelect.push(this.fdSelect.fdSelect[0]);
            }
            hasGlyphId(e) {
              if (e < 0 || e >= this.charStrings.count) return !1;
              return this.charStrings.get(e).length > 0;
            }
          }
          t.CFF = CFF;
          class CFFHeader {
            constructor(e, t, a, r) {
              this.major = e;
              this.minor = t;
              this.hdrSize = a;
              this.offSize = r;
            }
          }
          t.CFFHeader = CFFHeader;
          class CFFStrings {
            constructor() {
              this.strings = [];
            }
            get(e) {
              return e >= 0 && e <= 390
                ? s[e]
                : e - o <= this.strings.length
                ? this.strings[e - o]
                : s[0];
            }
            getSID(e) {
              let t = s.indexOf(e);
              if (-1 !== t) return t;
              t = this.strings.indexOf(e);
              return -1 !== t ? t + o : -1;
            }
            add(e) {
              this.strings.push(e);
            }
            get count() {
              return this.strings.length;
            }
          }
          t.CFFStrings = CFFStrings;
          class CFFIndex {
            constructor() {
              this.objects = [];
              this.length = 0;
            }
            add(e) {
              this.length += e.length;
              this.objects.push(e);
            }
            set(e, t) {
              this.length += t.length - this.objects[e].length;
              this.objects[e] = t;
            }
            get(e) {
              return this.objects[e];
            }
            get count() {
              return this.objects.length;
            }
          }
          t.CFFIndex = CFFIndex;
          class CFFDict {
            constructor(e, t) {
              this.keyToNameMap = e.keyToNameMap;
              this.nameToKeyMap = e.nameToKeyMap;
              this.defaults = e.defaults;
              this.types = e.types;
              this.opcodes = e.opcodes;
              this.order = e.order;
              this.strings = t;
              this.values = Object.create(null);
            }
            setByKey(e, t) {
              if (!(e in this.keyToNameMap)) return !1;
              if (0 === t.length) return !0;
              for (const a of t)
                if (isNaN(a)) {
                  (0, r.warn)(`Invalid CFFDict value: "${t}" for key "${e}".`);
                  return !0;
                }
              const a = this.types[e];
              ("num" !== a && "sid" !== a && "offset" !== a) || (t = t[0]);
              this.values[e] = t;
              return !0;
            }
            setByName(e, t) {
              if (!(e in this.nameToKeyMap))
                throw new r.FormatError(`Invalid dictionary name "${e}"`);
              this.values[this.nameToKeyMap[e]] = t;
            }
            hasName(e) {
              return this.nameToKeyMap[e] in this.values;
            }
            getByName(e) {
              if (!(e in this.nameToKeyMap))
                throw new r.FormatError(`Invalid dictionary name ${e}"`);
              const t = this.nameToKeyMap[e];
              return t in this.values ? this.values[t] : this.defaults[t];
            }
            removeByName(e) {
              delete this.values[this.nameToKeyMap[e]];
            }
            static createTables(e) {
              const t = {
                keyToNameMap: {},
                nameToKeyMap: {},
                defaults: {},
                types: {},
                opcodes: {},
                order: [],
              };
              for (const a of e) {
                const e = Array.isArray(a[0]) ? (a[0][0] << 8) + a[0][1] : a[0];
                t.keyToNameMap[e] = a[1];
                t.nameToKeyMap[a[1]] = e;
                t.types[e] = a[2];
                t.defaults[e] = a[3];
                t.opcodes[e] = Array.isArray(a[0]) ? a[0] : [a[0]];
                t.order.push(e);
              }
              return t;
            }
          }
          const h = [
            [[12, 30], "ROS", ["sid", "sid", "num"], null],
            [[12, 20], "SyntheticBase", "num", null],
            [0, "version", "sid", null],
            [1, "Notice", "sid", null],
            [[12, 0], "Copyright", "sid", null],
            [2, "FullName", "sid", null],
            [3, "FamilyName", "sid", null],
            [4, "Weight", "sid", null],
            [[12, 1], "isFixedPitch", "num", 0],
            [[12, 2], "ItalicAngle", "num", 0],
            [[12, 3], "UnderlinePosition", "num", -100],
            [[12, 4], "UnderlineThickness", "num", 50],
            [[12, 5], "PaintType", "num", 0],
            [[12, 6], "CharstringType", "num", 2],
            [
              [12, 7],
              "FontMatrix",
              ["num", "num", "num", "num", "num", "num"],
              [0.001, 0, 0, 0.001, 0, 0],
            ],
            [13, "UniqueID", "num", null],
            [5, "FontBBox", ["num", "num", "num", "num"], [0, 0, 0, 0]],
            [[12, 8], "StrokeWidth", "num", 0],
            [14, "XUID", "array", null],
            [15, "charset", "offset", 0],
            [16, "Encoding", "offset", 0],
            [17, "CharStrings", "offset", 0],
            [18, "Private", ["offset", "offset"], null],
            [[12, 21], "PostScript", "sid", null],
            [[12, 22], "BaseFontName", "sid", null],
            [[12, 23], "BaseFontBlend", "delta", null],
            [[12, 31], "CIDFontVersion", "num", 0],
            [[12, 32], "CIDFontRevision", "num", 0],
            [[12, 33], "CIDFontType", "num", 0],
            [[12, 34], "CIDCount", "num", 8720],
            [[12, 35], "UIDBase", "num", null],
            [[12, 37], "FDSelect", "offset", null],
            [[12, 36], "FDArray", "offset", null],
            [[12, 38], "FontName", "sid", null],
          ];
          class CFFTopDict extends CFFDict {
            static get tables() {
              return (0, r.shadow)(this, "tables", this.createTables(h));
            }
            constructor(e) {
              super(CFFTopDict.tables, e);
              this.privateDict = null;
            }
          }
          t.CFFTopDict = CFFTopDict;
          const u = [
            [6, "BlueValues", "delta", null],
            [7, "OtherBlues", "delta", null],
            [8, "FamilyBlues", "delta", null],
            [9, "FamilyOtherBlues", "delta", null],
            [[12, 9], "BlueScale", "num", 0.039625],
            [[12, 10], "BlueShift", "num", 7],
            [[12, 11], "BlueFuzz", "num", 1],
            [10, "StdHW", "num", null],
            [11, "StdVW", "num", null],
            [[12, 12], "StemSnapH", "delta", null],
            [[12, 13], "StemSnapV", "delta", null],
            [[12, 14], "ForceBold", "num", 0],
            [[12, 17], "LanguageGroup", "num", 0],
            [[12, 18], "ExpansionFactor", "num", 0.06],
            [[12, 19], "initialRandomSeed", "num", 0],
            [20, "defaultWidthX", "num", 0],
            [21, "nominalWidthX", "num", 0],
            [19, "Subrs", "offset", null],
          ];
          class CFFPrivateDict extends CFFDict {
            static get tables() {
              return (0, r.shadow)(this, "tables", this.createTables(u));
            }
            constructor(e) {
              super(CFFPrivateDict.tables, e);
              this.subrsIndex = null;
            }
          }
          t.CFFPrivateDict = CFFPrivateDict;
          const d = { ISO_ADOBE: 0, EXPERT: 1, EXPERT_SUBSET: 2 };
          class CFFCharset {
            constructor(e, t, a, r) {
              this.predefined = e;
              this.format = t;
              this.charset = a;
              this.raw = r;
            }
          }
          t.CFFCharset = CFFCharset;
          class CFFEncoding {
            constructor(e, t, a, r) {
              this.predefined = e;
              this.format = t;
              this.encoding = a;
              this.raw = r;
            }
          }
          class CFFFDSelect {
            constructor(e, t) {
              this.format = e;
              this.fdSelect = t;
            }
            getFDIndex(e) {
              return e < 0 || e >= this.fdSelect.length ? -1 : this.fdSelect[e];
            }
          }
          t.CFFFDSelect = CFFFDSelect;
          class CFFOffsetTracker {
            constructor() {
              this.offsets = Object.create(null);
            }
            isTracking(e) {
              return e in this.offsets;
            }
            track(e, t) {
              if (e in this.offsets)
                throw new r.FormatError(`Already tracking location of ${e}`);
              this.offsets[e] = t;
            }
            offset(e) {
              for (const t in this.offsets) this.offsets[t] += e;
            }
            setEntryLocation(e, t, a) {
              if (!(e in this.offsets))
                throw new r.FormatError(`Not tracking location of ${e}`);
              const n = a.data,
                i = this.offsets[e];
              for (let e = 0, a = t.length; e < a; ++e) {
                const a = 5 * e + i,
                  s = a + 1,
                  o = a + 2,
                  c = a + 3,
                  l = a + 4;
                if (
                  29 !== n[a] ||
                  0 !== n[s] ||
                  0 !== n[o] ||
                  0 !== n[c] ||
                  0 !== n[l]
                )
                  throw new r.FormatError(
                    "writing to an offset that is not empty"
                  );
                const h = t[e];
                n[a] = 29;
                n[s] = (h >> 24) & 255;
                n[o] = (h >> 16) & 255;
                n[c] = (h >> 8) & 255;
                n[l] = 255 & h;
              }
            }
          }
          class CFFCompiler {
            constructor(e) {
              this.cff = e;
            }
            compile() {
              const e = this.cff,
                t = {
                  data: [],
                  length: 0,
                  add(e) {
                    this.data = this.data.concat(e);
                    this.length = this.data.length;
                  },
                },
                a = this.compileHeader(e.header);
              t.add(a);
              const n = this.compileNameIndex(e.names);
              t.add(n);
              if (e.isCIDFont && e.topDict.hasName("FontMatrix")) {
                const t = e.topDict.getByName("FontMatrix");
                e.topDict.removeByName("FontMatrix");
                for (const a of e.fdArray) {
                  let e = t.slice(0);
                  a.hasName("FontMatrix") &&
                    (e = r.Util.transform(e, a.getByName("FontMatrix")));
                  a.setByName("FontMatrix", e);
                }
              }
              const i = e.topDict.getByName("XUID");
              i && i.length > 16 && e.topDict.removeByName("XUID");
              e.topDict.setByName("charset", 0);
              let s = this.compileTopDicts([e.topDict], t.length, e.isCIDFont);
              t.add(s.output);
              const o = s.trackers[0],
                c = this.compileStringIndex(e.strings.strings);
              t.add(c);
              const l = this.compileIndex(e.globalSubrIndex);
              t.add(l);
              if (e.encoding && e.topDict.hasName("Encoding"))
                if (e.encoding.predefined)
                  o.setEntryLocation("Encoding", [e.encoding.format], t);
                else {
                  const a = this.compileEncoding(e.encoding);
                  o.setEntryLocation("Encoding", [t.length], t);
                  t.add(a);
                }
              const h = this.compileCharset(
                e.charset,
                e.charStrings.count,
                e.strings,
                e.isCIDFont
              );
              o.setEntryLocation("charset", [t.length], t);
              t.add(h);
              const u = this.compileCharStrings(e.charStrings);
              o.setEntryLocation("CharStrings", [t.length], t);
              t.add(u);
              if (e.isCIDFont) {
                o.setEntryLocation("FDSelect", [t.length], t);
                const a = this.compileFDSelect(e.fdSelect);
                t.add(a);
                s = this.compileTopDicts(e.fdArray, t.length, !0);
                o.setEntryLocation("FDArray", [t.length], t);
                t.add(s.output);
                const r = s.trackers;
                this.compilePrivateDicts(e.fdArray, r, t);
              }
              this.compilePrivateDicts([e.topDict], [o], t);
              t.add([0]);
              return t.data;
            }
            encodeNumber(e) {
              return Number.isInteger(e)
                ? this.encodeInteger(e)
                : this.encodeFloat(e);
            }
            static get EncodeFloatRegExp() {
              return (0, r.shadow)(
                this,
                "EncodeFloatRegExp",
                /\.(\d*?)(?:9{5,20}|0{5,20})\d{0,2}(?:e(.+)|$)/
              );
            }
            encodeFloat(e) {
              let t = e.toString();
              const a = CFFCompiler.EncodeFloatRegExp.exec(t);
              if (a) {
                const r = parseFloat("1e" + ((a[2] ? +a[2] : 0) + a[1].length));
                t = (Math.round(e * r) / r).toString();
              }
              let r,
                n,
                i = "";
              for (r = 0, n = t.length; r < n; ++r) {
                const e = t[r];
                i +=
                  "e" === e
                    ? "-" === t[++r]
                      ? "c"
                      : "b"
                    : "." === e
                    ? "a"
                    : "-" === e
                    ? "e"
                    : e;
              }
              i += 1 & i.length ? "f" : "ff";
              const s = [30];
              for (r = 0, n = i.length; r < n; r += 2)
                s.push(parseInt(i.substring(r, r + 2), 16));
              return s;
            }
            encodeInteger(e) {
              let t;
              t =
                e >= -107 && e <= 107
                  ? [e + 139]
                  : e >= 108 && e <= 1131
                  ? [247 + ((e -= 108) >> 8), 255 & e]
                  : e >= -1131 && e <= -108
                  ? [251 + ((e = -e - 108) >> 8), 255 & e]
                  : e >= -32768 && e <= 32767
                  ? [28, (e >> 8) & 255, 255 & e]
                  : [
                      29,
                      (e >> 24) & 255,
                      (e >> 16) & 255,
                      (e >> 8) & 255,
                      255 & e,
                    ];
              return t;
            }
            compileHeader(e) {
              return [e.major, e.minor, 4, e.offSize];
            }
            compileNameIndex(e) {
              const t = new CFFIndex();
              for (const a of e) {
                const e = Math.min(a.length, 127);
                let n = new Array(e);
                for (let t = 0; t < e; t++) {
                  let e = a[t];
                  (e < "!" ||
                    e > "~" ||
                    "[" === e ||
                    "]" === e ||
                    "(" === e ||
                    ")" === e ||
                    "{" === e ||
                    "}" === e ||
                    "<" === e ||
                    ">" === e ||
                    "/" === e ||
                    "%" === e) &&
                    (e = "_");
                  n[t] = e;
                }
                n = n.join("");
                "" === n && (n = "Bad_Font_Name");
                t.add((0, r.stringToBytes)(n));
              }
              return this.compileIndex(t);
            }
            compileTopDicts(e, t, a) {
              const r = [];
              let n = new CFFIndex();
              for (const i of e) {
                if (a) {
                  i.removeByName("CIDFontVersion");
                  i.removeByName("CIDFontRevision");
                  i.removeByName("CIDFontType");
                  i.removeByName("CIDCount");
                  i.removeByName("UIDBase");
                }
                const e = new CFFOffsetTracker(),
                  s = this.compileDict(i, e);
                r.push(e);
                n.add(s);
                e.offset(t);
              }
              n = this.compileIndex(n, r);
              return { trackers: r, output: n };
            }
            compilePrivateDicts(e, t, a) {
              for (let n = 0, i = e.length; n < i; ++n) {
                const i = e[n],
                  s = i.privateDict;
                if (!s || !i.hasName("Private"))
                  throw new r.FormatError(
                    "There must be a private dictionary."
                  );
                const o = new CFFOffsetTracker(),
                  c = this.compileDict(s, o);
                let l = a.length;
                o.offset(l);
                c.length || (l = 0);
                t[n].setEntryLocation("Private", [c.length, l], a);
                a.add(c);
                if (s.subrsIndex && s.hasName("Subrs")) {
                  const e = this.compileIndex(s.subrsIndex);
                  o.setEntryLocation("Subrs", [c.length], a);
                  a.add(e);
                }
              }
            }
            compileDict(e, t) {
              const a = [];
              for (const n of e.order) {
                if (!(n in e.values)) continue;
                let i = e.values[n],
                  s = e.types[n];
                Array.isArray(s) || (s = [s]);
                Array.isArray(i) || (i = [i]);
                if (0 !== i.length) {
                  for (let o = 0, c = s.length; o < c; ++o) {
                    const c = s[o],
                      l = i[o];
                    switch (c) {
                      case "num":
                      case "sid":
                        a.push(...this.encodeNumber(l));
                        break;
                      case "offset":
                        const s = e.keyToNameMap[n];
                        t.isTracking(s) || t.track(s, a.length);
                        a.push(29, 0, 0, 0, 0);
                        break;
                      case "array":
                      case "delta":
                        a.push(...this.encodeNumber(l));
                        for (let e = 1, t = i.length; e < t; ++e)
                          a.push(...this.encodeNumber(i[e]));
                        break;
                      default:
                        throw new r.FormatError(`Unknown data type of ${c}`);
                    }
                  }
                  a.push(...e.opcodes[n]);
                }
              }
              return a;
            }
            compileStringIndex(e) {
              const t = new CFFIndex();
              for (const a of e) t.add((0, r.stringToBytes)(a));
              return this.compileIndex(t);
            }
            compileGlobalSubrIndex() {
              const e = this.cff.globalSubrIndex;
              this.out.writeByteArray(this.compileIndex(e));
            }
            compileCharStrings(e) {
              const t = new CFFIndex();
              for (let a = 0; a < e.count; a++) {
                const r = e.get(a);
                0 !== r.length ? t.add(r) : t.add(new Uint8Array([139, 14]));
              }
              return this.compileIndex(t);
            }
            compileCharset(e, t, a, n) {
              let i;
              const s = t - 1;
              if (n) i = new Uint8Array([2, 0, 0, (s >> 8) & 255, 255 & s]);
              else {
                i = new Uint8Array(1 + 2 * s);
                i[0] = 0;
                let t = 0;
                const n = e.charset.length;
                let o = !1;
                for (let s = 1; s < i.length; s += 2) {
                  let c = 0;
                  if (t < n) {
                    const n = e.charset[t++];
                    c = a.getSID(n);
                    if (-1 === c) {
                      c = 0;
                      if (!o) {
                        o = !0;
                        (0, r.warn)(`Couldn't find ${n} in CFF strings`);
                      }
                    }
                  }
                  i[s] = (c >> 8) & 255;
                  i[s + 1] = 255 & c;
                }
              }
              return this.compileTypedArray(i);
            }
            compileEncoding(e) {
              return this.compileTypedArray(e.raw);
            }
            compileFDSelect(e) {
              const t = e.format;
              let a, r;
              switch (t) {
                case 0:
                  a = new Uint8Array(1 + e.fdSelect.length);
                  a[0] = t;
                  for (r = 0; r < e.fdSelect.length; r++)
                    a[r + 1] = e.fdSelect[r];
                  break;
                case 3:
                  const n = 0;
                  let i = e.fdSelect[0];
                  const s = [t, 0, 0, (n >> 8) & 255, 255 & n, i];
                  for (r = 1; r < e.fdSelect.length; r++) {
                    const t = e.fdSelect[r];
                    if (t !== i) {
                      s.push((r >> 8) & 255, 255 & r, t);
                      i = t;
                    }
                  }
                  const o = (s.length - 3) / 3;
                  s[1] = (o >> 8) & 255;
                  s[2] = 255 & o;
                  s.push((r >> 8) & 255, 255 & r);
                  a = new Uint8Array(s);
              }
              return this.compileTypedArray(a);
            }
            compileTypedArray(e) {
              const t = [];
              for (let a = 0, r = e.length; a < r; ++a) t[a] = e[a];
              return t;
            }
            compileIndex(e, t = []) {
              const a = e.objects,
                r = a.length;
              if (0 === r) return [0, 0];
              const n = [(r >> 8) & 255, 255 & r];
              let i,
                s,
                o = 1;
              for (i = 0; i < r; ++i) o += a[i].length;
              s = o < 256 ? 1 : o < 65536 ? 2 : o < 16777216 ? 3 : 4;
              n.push(s);
              let c = 1;
              for (i = 0; i < r + 1; i++) {
                1 === s
                  ? n.push(255 & c)
                  : 2 === s
                  ? n.push((c >> 8) & 255, 255 & c)
                  : 3 === s
                  ? n.push((c >> 16) & 255, (c >> 8) & 255, 255 & c)
                  : n.push(
                      (c >>> 24) & 255,
                      (c >> 16) & 255,
                      (c >> 8) & 255,
                      255 & c
                    );
                a[i] && (c += a[i].length);
              }
              for (i = 0; i < r; i++) {
                t[i] && t[i].offset(n.length);
                n.push(...a[i]);
              }
              return n;
            }
          }
          t.CFFCompiler = CFFCompiler;
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ISOAdobeCharset = t.ExpertSubsetCharset = t.ExpertCharset = void 0;
          t.ISOAdobeCharset = [
            ".notdef",
            "space",
            "exclam",
            "quotedbl",
            "numbersign",
            "dollar",
            "percent",
            "ampersand",
            "quoteright",
            "parenleft",
            "parenright",
            "asterisk",
            "plus",
            "comma",
            "hyphen",
            "period",
            "slash",
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "colon",
            "semicolon",
            "less",
            "equal",
            "greater",
            "question",
            "at",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "bracketleft",
            "backslash",
            "bracketright",
            "asciicircum",
            "underscore",
            "quoteleft",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "braceleft",
            "bar",
            "braceright",
            "asciitilde",
            "exclamdown",
            "cent",
            "sterling",
            "fraction",
            "yen",
            "florin",
            "section",
            "currency",
            "quotesingle",
            "quotedblleft",
            "guillemotleft",
            "guilsinglleft",
            "guilsinglright",
            "fi",
            "fl",
            "endash",
            "dagger",
            "daggerdbl",
            "periodcentered",
            "paragraph",
            "bullet",
            "quotesinglbase",
            "quotedblbase",
            "quotedblright",
            "guillemotright",
            "ellipsis",
            "perthousand",
            "questiondown",
            "grave",
            "acute",
            "circumflex",
            "tilde",
            "macron",
            "breve",
            "dotaccent",
            "dieresis",
            "ring",
            "cedilla",
            "hungarumlaut",
            "ogonek",
            "caron",
            "emdash",
            "AE",
            "ordfeminine",
            "Lslash",
            "Oslash",
            "OE",
            "ordmasculine",
            "ae",
            "dotlessi",
            "lslash",
            "oslash",
            "oe",
            "germandbls",
            "onesuperior",
            "logicalnot",
            "mu",
            "trademark",
            "Eth",
            "onehalf",
            "plusminus",
            "Thorn",
            "onequarter",
            "divide",
            "brokenbar",
            "degree",
            "thorn",
            "threequarters",
            "twosuperior",
            "registered",
            "minus",
            "eth",
            "multiply",
            "threesuperior",
            "copyright",
            "Aacute",
            "Acircumflex",
            "Adieresis",
            "Agrave",
            "Aring",
            "Atilde",
            "Ccedilla",
            "Eacute",
            "Ecircumflex",
            "Edieresis",
            "Egrave",
            "Iacute",
            "Icircumflex",
            "Idieresis",
            "Igrave",
            "Ntilde",
            "Oacute",
            "Ocircumflex",
            "Odieresis",
            "Ograve",
            "Otilde",
            "Scaron",
            "Uacute",
            "Ucircumflex",
            "Udieresis",
            "Ugrave",
            "Yacute",
            "Ydieresis",
            "Zcaron",
            "aacute",
            "acircumflex",
            "adieresis",
            "agrave",
            "aring",
            "atilde",
            "ccedilla",
            "eacute",
            "ecircumflex",
            "edieresis",
            "egrave",
            "iacute",
            "icircumflex",
            "idieresis",
            "igrave",
            "ntilde",
            "oacute",
            "ocircumflex",
            "odieresis",
            "ograve",
            "otilde",
            "scaron",
            "uacute",
            "ucircumflex",
            "udieresis",
            "ugrave",
            "yacute",
            "ydieresis",
            "zcaron",
          ];
          t.ExpertCharset = [
            ".notdef",
            "space",
            "exclamsmall",
            "Hungarumlautsmall",
            "dollaroldstyle",
            "dollarsuperior",
            "ampersandsmall",
            "Acutesmall",
            "parenleftsuperior",
            "parenrightsuperior",
            "twodotenleader",
            "onedotenleader",
            "comma",
            "hyphen",
            "period",
            "fraction",
            "zerooldstyle",
            "oneoldstyle",
            "twooldstyle",
            "threeoldstyle",
            "fouroldstyle",
            "fiveoldstyle",
            "sixoldstyle",
            "sevenoldstyle",
            "eightoldstyle",
            "nineoldstyle",
            "colon",
            "semicolon",
            "commasuperior",
            "threequartersemdash",
            "periodsuperior",
            "questionsmall",
            "asuperior",
            "bsuperior",
            "centsuperior",
            "dsuperior",
            "esuperior",
            "isuperior",
            "lsuperior",
            "msuperior",
            "nsuperior",
            "osuperior",
            "rsuperior",
            "ssuperior",
            "tsuperior",
            "ff",
            "fi",
            "fl",
            "ffi",
            "ffl",
            "parenleftinferior",
            "parenrightinferior",
            "Circumflexsmall",
            "hyphensuperior",
            "Gravesmall",
            "Asmall",
            "Bsmall",
            "Csmall",
            "Dsmall",
            "Esmall",
            "Fsmall",
            "Gsmall",
            "Hsmall",
            "Ismall",
            "Jsmall",
            "Ksmall",
            "Lsmall",
            "Msmall",
            "Nsmall",
            "Osmall",
            "Psmall",
            "Qsmall",
            "Rsmall",
            "Ssmall",
            "Tsmall",
            "Usmall",
            "Vsmall",
            "Wsmall",
            "Xsmall",
            "Ysmall",
            "Zsmall",
            "colonmonetary",
            "onefitted",
            "rupiah",
            "Tildesmall",
            "exclamdownsmall",
            "centoldstyle",
            "Lslashsmall",
            "Scaronsmall",
            "Zcaronsmall",
            "Dieresissmall",
            "Brevesmall",
            "Caronsmall",
            "Dotaccentsmall",
            "Macronsmall",
            "figuredash",
            "hypheninferior",
            "Ogoneksmall",
            "Ringsmall",
            "Cedillasmall",
            "onequarter",
            "onehalf",
            "threequarters",
            "questiondownsmall",
            "oneeighth",
            "threeeighths",
            "fiveeighths",
            "seveneighths",
            "onethird",
            "twothirds",
            "zerosuperior",
            "onesuperior",
            "twosuperior",
            "threesuperior",
            "foursuperior",
            "fivesuperior",
            "sixsuperior",
            "sevensuperior",
            "eightsuperior",
            "ninesuperior",
            "zeroinferior",
            "oneinferior",
            "twoinferior",
            "threeinferior",
            "fourinferior",
            "fiveinferior",
            "sixinferior",
            "seveninferior",
            "eightinferior",
            "nineinferior",
            "centinferior",
            "dollarinferior",
            "periodinferior",
            "commainferior",
            "Agravesmall",
            "Aacutesmall",
            "Acircumflexsmall",
            "Atildesmall",
            "Adieresissmall",
            "Aringsmall",
            "AEsmall",
            "Ccedillasmall",
            "Egravesmall",
            "Eacutesmall",
            "Ecircumflexsmall",
            "Edieresissmall",
            "Igravesmall",
            "Iacutesmall",
            "Icircumflexsmall",
            "Idieresissmall",
            "Ethsmall",
            "Ntildesmall",
            "Ogravesmall",
            "Oacutesmall",
            "Ocircumflexsmall",
            "Otildesmall",
            "Odieresissmall",
            "OEsmall",
            "Oslashsmall",
            "Ugravesmall",
            "Uacutesmall",
            "Ucircumflexsmall",
            "Udieresissmall",
            "Yacutesmall",
            "Thornsmall",
            "Ydieresissmall",
          ];
          t.ExpertSubsetCharset = [
            ".notdef",
            "space",
            "dollaroldstyle",
            "dollarsuperior",
            "parenleftsuperior",
            "parenrightsuperior",
            "twodotenleader",
            "onedotenleader",
            "comma",
            "hyphen",
            "period",
            "fraction",
            "zerooldstyle",
            "oneoldstyle",
            "twooldstyle",
            "threeoldstyle",
            "fouroldstyle",
            "fiveoldstyle",
            "sixoldstyle",
            "sevenoldstyle",
            "eightoldstyle",
            "nineoldstyle",
            "colon",
            "semicolon",
            "commasuperior",
            "threequartersemdash",
            "periodsuperior",
            "asuperior",
            "bsuperior",
            "centsuperior",
            "dsuperior",
            "esuperior",
            "isuperior",
            "lsuperior",
            "msuperior",
            "nsuperior",
            "osuperior",
            "rsuperior",
            "ssuperior",
            "tsuperior",
            "ff",
            "fi",
            "fl",
            "ffi",
            "ffl",
            "parenleftinferior",
            "parenrightinferior",
            "hyphensuperior",
            "colonmonetary",
            "onefitted",
            "rupiah",
            "centoldstyle",
            "figuredash",
            "hypheninferior",
            "onequarter",
            "onehalf",
            "threequarters",
            "oneeighth",
            "threeeighths",
            "fiveeighths",
            "seveneighths",
            "onethird",
            "twothirds",
            "zerosuperior",
            "onesuperior",
            "twosuperior",
            "threesuperior",
            "foursuperior",
            "fivesuperior",
            "sixsuperior",
            "sevensuperior",
            "eightsuperior",
            "ninesuperior",
            "zeroinferior",
            "oneinferior",
            "twoinferior",
            "threeinferior",
            "fourinferior",
            "fiveinferior",
            "sixinferior",
            "seveninferior",
            "eightinferior",
            "nineinferior",
            "centinferior",
            "dollarinferior",
            "periodinferior",
            "commainferior",
          ];
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ZapfDingbatsEncoding =
            t.WinAnsiEncoding =
            t.SymbolSetEncoding =
            t.StandardEncoding =
            t.MacRomanEncoding =
            t.ExpertEncoding =
              void 0;
          t.getEncoding = function getEncoding(e) {
            switch (e) {
              case "WinAnsiEncoding":
                return s;
              case "StandardEncoding":
                return i;
              case "MacRomanEncoding":
                return n;
              case "SymbolSetEncoding":
                return o;
              case "ZapfDingbatsEncoding":
                return c;
              case "ExpertEncoding":
                return a;
              case "MacExpertEncoding":
                return r;
              default:
                return null;
            }
          };
          const a = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "space",
            "exclamsmall",
            "Hungarumlautsmall",
            "",
            "dollaroldstyle",
            "dollarsuperior",
            "ampersandsmall",
            "Acutesmall",
            "parenleftsuperior",
            "parenrightsuperior",
            "twodotenleader",
            "onedotenleader",
            "comma",
            "hyphen",
            "period",
            "fraction",
            "zerooldstyle",
            "oneoldstyle",
            "twooldstyle",
            "threeoldstyle",
            "fouroldstyle",
            "fiveoldstyle",
            "sixoldstyle",
            "sevenoldstyle",
            "eightoldstyle",
            "nineoldstyle",
            "colon",
            "semicolon",
            "commasuperior",
            "threequartersemdash",
            "periodsuperior",
            "questionsmall",
            "",
            "asuperior",
            "bsuperior",
            "centsuperior",
            "dsuperior",
            "esuperior",
            "",
            "",
            "",
            "isuperior",
            "",
            "",
            "lsuperior",
            "msuperior",
            "nsuperior",
            "osuperior",
            "",
            "",
            "rsuperior",
            "ssuperior",
            "tsuperior",
            "",
            "ff",
            "fi",
            "fl",
            "ffi",
            "ffl",
            "parenleftinferior",
            "",
            "parenrightinferior",
            "Circumflexsmall",
            "hyphensuperior",
            "Gravesmall",
            "Asmall",
            "Bsmall",
            "Csmall",
            "Dsmall",
            "Esmall",
            "Fsmall",
            "Gsmall",
            "Hsmall",
            "Ismall",
            "Jsmall",
            "Ksmall",
            "Lsmall",
            "Msmall",
            "Nsmall",
            "Osmall",
            "Psmall",
            "Qsmall",
            "Rsmall",
            "Ssmall",
            "Tsmall",
            "Usmall",
            "Vsmall",
            "Wsmall",
            "Xsmall",
            "Ysmall",
            "Zsmall",
            "colonmonetary",
            "onefitted",
            "rupiah",
            "Tildesmall",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "exclamdownsmall",
            "centoldstyle",
            "Lslashsmall",
            "",
            "",
            "Scaronsmall",
            "Zcaronsmall",
            "Dieresissmall",
            "Brevesmall",
            "Caronsmall",
            "",
            "Dotaccentsmall",
            "",
            "",
            "Macronsmall",
            "",
            "",
            "figuredash",
            "hypheninferior",
            "",
            "",
            "Ogoneksmall",
            "Ringsmall",
            "Cedillasmall",
            "",
            "",
            "",
            "onequarter",
            "onehalf",
            "threequarters",
            "questiondownsmall",
            "oneeighth",
            "threeeighths",
            "fiveeighths",
            "seveneighths",
            "onethird",
            "twothirds",
            "",
            "",
            "zerosuperior",
            "onesuperior",
            "twosuperior",
            "threesuperior",
            "foursuperior",
            "fivesuperior",
            "sixsuperior",
            "sevensuperior",
            "eightsuperior",
            "ninesuperior",
            "zeroinferior",
            "oneinferior",
            "twoinferior",
            "threeinferior",
            "fourinferior",
            "fiveinferior",
            "sixinferior",
            "seveninferior",
            "eightinferior",
            "nineinferior",
            "centinferior",
            "dollarinferior",
            "periodinferior",
            "commainferior",
            "Agravesmall",
            "Aacutesmall",
            "Acircumflexsmall",
            "Atildesmall",
            "Adieresissmall",
            "Aringsmall",
            "AEsmall",
            "Ccedillasmall",
            "Egravesmall",
            "Eacutesmall",
            "Ecircumflexsmall",
            "Edieresissmall",
            "Igravesmall",
            "Iacutesmall",
            "Icircumflexsmall",
            "Idieresissmall",
            "Ethsmall",
            "Ntildesmall",
            "Ogravesmall",
            "Oacutesmall",
            "Ocircumflexsmall",
            "Otildesmall",
            "Odieresissmall",
            "OEsmall",
            "Oslashsmall",
            "Ugravesmall",
            "Uacutesmall",
            "Ucircumflexsmall",
            "Udieresissmall",
            "Yacutesmall",
            "Thornsmall",
            "Ydieresissmall",
          ];
          t.ExpertEncoding = a;
          const r = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "space",
              "exclamsmall",
              "Hungarumlautsmall",
              "centoldstyle",
              "dollaroldstyle",
              "dollarsuperior",
              "ampersandsmall",
              "Acutesmall",
              "parenleftsuperior",
              "parenrightsuperior",
              "twodotenleader",
              "onedotenleader",
              "comma",
              "hyphen",
              "period",
              "fraction",
              "zerooldstyle",
              "oneoldstyle",
              "twooldstyle",
              "threeoldstyle",
              "fouroldstyle",
              "fiveoldstyle",
              "sixoldstyle",
              "sevenoldstyle",
              "eightoldstyle",
              "nineoldstyle",
              "colon",
              "semicolon",
              "",
              "threequartersemdash",
              "",
              "questionsmall",
              "",
              "",
              "",
              "",
              "Ethsmall",
              "",
              "",
              "onequarter",
              "onehalf",
              "threequarters",
              "oneeighth",
              "threeeighths",
              "fiveeighths",
              "seveneighths",
              "onethird",
              "twothirds",
              "",
              "",
              "",
              "",
              "",
              "",
              "ff",
              "fi",
              "fl",
              "ffi",
              "ffl",
              "parenleftinferior",
              "",
              "parenrightinferior",
              "Circumflexsmall",
              "hypheninferior",
              "Gravesmall",
              "Asmall",
              "Bsmall",
              "Csmall",
              "Dsmall",
              "Esmall",
              "Fsmall",
              "Gsmall",
              "Hsmall",
              "Ismall",
              "Jsmall",
              "Ksmall",
              "Lsmall",
              "Msmall",
              "Nsmall",
              "Osmall",
              "Psmall",
              "Qsmall",
              "Rsmall",
              "Ssmall",
              "Tsmall",
              "Usmall",
              "Vsmall",
              "Wsmall",
              "Xsmall",
              "Ysmall",
              "Zsmall",
              "colonmonetary",
              "onefitted",
              "rupiah",
              "Tildesmall",
              "",
              "",
              "asuperior",
              "centsuperior",
              "",
              "",
              "",
              "",
              "Aacutesmall",
              "Agravesmall",
              "Acircumflexsmall",
              "Adieresissmall",
              "Atildesmall",
              "Aringsmall",
              "Ccedillasmall",
              "Eacutesmall",
              "Egravesmall",
              "Ecircumflexsmall",
              "Edieresissmall",
              "Iacutesmall",
              "Igravesmall",
              "Icircumflexsmall",
              "Idieresissmall",
              "Ntildesmall",
              "Oacutesmall",
              "Ogravesmall",
              "Ocircumflexsmall",
              "Odieresissmall",
              "Otildesmall",
              "Uacutesmall",
              "Ugravesmall",
              "Ucircumflexsmall",
              "Udieresissmall",
              "",
              "eightsuperior",
              "fourinferior",
              "threeinferior",
              "sixinferior",
              "eightinferior",
              "seveninferior",
              "Scaronsmall",
              "",
              "centinferior",
              "twoinferior",
              "",
              "Dieresissmall",
              "",
              "Caronsmall",
              "osuperior",
              "fiveinferior",
              "",
              "commainferior",
              "periodinferior",
              "Yacutesmall",
              "",
              "dollarinferior",
              "",
              "",
              "Thornsmall",
              "",
              "nineinferior",
              "zeroinferior",
              "Zcaronsmall",
              "AEsmall",
              "Oslashsmall",
              "questiondownsmall",
              "oneinferior",
              "Lslashsmall",
              "",
              "",
              "",
              "",
              "",
              "",
              "Cedillasmall",
              "",
              "",
              "",
              "",
              "",
              "OEsmall",
              "figuredash",
              "hyphensuperior",
              "",
              "",
              "",
              "",
              "exclamdownsmall",
              "",
              "Ydieresissmall",
              "",
              "onesuperior",
              "twosuperior",
              "threesuperior",
              "foursuperior",
              "fivesuperior",
              "sixsuperior",
              "sevensuperior",
              "ninesuperior",
              "zerosuperior",
              "",
              "esuperior",
              "rsuperior",
              "tsuperior",
              "",
              "",
              "isuperior",
              "ssuperior",
              "dsuperior",
              "",
              "",
              "",
              "",
              "",
              "lsuperior",
              "Ogoneksmall",
              "Brevesmall",
              "Macronsmall",
              "bsuperior",
              "nsuperior",
              "msuperior",
              "commasuperior",
              "periodsuperior",
              "Dotaccentsmall",
              "Ringsmall",
              "",
              "",
              "",
              "",
            ],
            n = [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "space",
              "exclam",
              "quotedbl",
              "numbersign",
              "dollar",
              "percent",
              "ampersand",
              "quotesingle",
              "parenleft",
              "parenright",
              "asterisk",
              "plus",
              "comma",
              "hyphen",
              "period",
              "slash",
              "zero",
              "one",
              "two",
              "three",
              "four",
              "five",
              "six",
              "seven",
              "eight",
              "nine",
              "colon",
              "semicolon",
              "less",
              "equal",
              "greater",
              "question",
              "at",
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              "bracketleft",
              "backslash",
              "bracketright",
              "asciicircum",
              "underscore",
              "grave",
              "a",
              "b",
              "c",
              "d",
              "e",
              "f",
              "g",
              "h",
              "i",
              "j",
              "k",
              "l",
              "m",
              "n",
              "o",
              "p",
              "q",
              "r",
              "s",
              "t",
              "u",
              "v",
              "w",
              "x",
              "y",
              "z",
              "braceleft",
              "bar",
              "braceright",
              "asciitilde",
              "",
              "Adieresis",
              "Aring",
              "Ccedilla",
              "Eacute",
              "Ntilde",
              "Odieresis",
              "Udieresis",
              "aacute",
              "agrave",
              "acircumflex",
              "adieresis",
              "atilde",
              "aring",
              "ccedilla",
              "eacute",
              "egrave",
              "ecircumflex",
              "edieresis",
              "iacute",
              "igrave",
              "icircumflex",
              "idieresis",
              "ntilde",
              "oacute",
              "ograve",
              "ocircumflex",
              "odieresis",
              "otilde",
              "uacute",
              "ugrave",
              "ucircumflex",
              "udieresis",
              "dagger",
              "degree",
              "cent",
              "sterling",
              "section",
              "bullet",
              "paragraph",
              "germandbls",
              "registered",
              "copyright",
              "trademark",
              "acute",
              "dieresis",
              "notequal",
              "AE",
              "Oslash",
              "infinity",
              "plusminus",
              "lessequal",
              "greaterequal",
              "yen",
              "mu",
              "partialdiff",
              "summation",
              "product",
              "pi",
              "integral",
              "ordfeminine",
              "ordmasculine",
              "Omega",
              "ae",
              "oslash",
              "questiondown",
              "exclamdown",
              "logicalnot",
              "radical",
              "florin",
              "approxequal",
              "Delta",
              "guillemotleft",
              "guillemotright",
              "ellipsis",
              "space",
              "Agrave",
              "Atilde",
              "Otilde",
              "OE",
              "oe",
              "endash",
              "emdash",
              "quotedblleft",
              "quotedblright",
              "quoteleft",
              "quoteright",
              "divide",
              "lozenge",
              "ydieresis",
              "Ydieresis",
              "fraction",
              "currency",
              "guilsinglleft",
              "guilsinglright",
              "fi",
              "fl",
              "daggerdbl",
              "periodcentered",
              "quotesinglbase",
              "quotedblbase",
              "perthousand",
              "Acircumflex",
              "Ecircumflex",
              "Aacute",
              "Edieresis",
              "Egrave",
              "Iacute",
              "Icircumflex",
              "Idieresis",
              "Igrave",
              "Oacute",
              "Ocircumflex",
              "apple",
              "Ograve",
              "Uacute",
              "Ucircumflex",
              "Ugrave",
              "dotlessi",
              "circumflex",
              "tilde",
              "macron",
              "breve",
              "dotaccent",
              "ring",
              "cedilla",
              "hungarumlaut",
              "ogonek",
              "caron",
            ];
          t.MacRomanEncoding = n;
          const i = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "space",
            "exclam",
            "quotedbl",
            "numbersign",
            "dollar",
            "percent",
            "ampersand",
            "quoteright",
            "parenleft",
            "parenright",
            "asterisk",
            "plus",
            "comma",
            "hyphen",
            "period",
            "slash",
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "colon",
            "semicolon",
            "less",
            "equal",
            "greater",
            "question",
            "at",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "bracketleft",
            "backslash",
            "bracketright",
            "asciicircum",
            "underscore",
            "quoteleft",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "braceleft",
            "bar",
            "braceright",
            "asciitilde",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "exclamdown",
            "cent",
            "sterling",
            "fraction",
            "yen",
            "florin",
            "section",
            "currency",
            "quotesingle",
            "quotedblleft",
            "guillemotleft",
            "guilsinglleft",
            "guilsinglright",
            "fi",
            "fl",
            "",
            "endash",
            "dagger",
            "daggerdbl",
            "periodcentered",
            "",
            "paragraph",
            "bullet",
            "quotesinglbase",
            "quotedblbase",
            "quotedblright",
            "guillemotright",
            "ellipsis",
            "perthousand",
            "",
            "questiondown",
            "",
            "grave",
            "acute",
            "circumflex",
            "tilde",
            "macron",
            "breve",
            "dotaccent",
            "dieresis",
            "",
            "ring",
            "cedilla",
            "",
            "hungarumlaut",
            "ogonek",
            "caron",
            "emdash",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "AE",
            "",
            "ordfeminine",
            "",
            "",
            "",
            "",
            "Lslash",
            "Oslash",
            "OE",
            "ordmasculine",
            "",
            "",
            "",
            "",
            "",
            "ae",
            "",
            "",
            "",
            "dotlessi",
            "",
            "",
            "lslash",
            "oslash",
            "oe",
            "germandbls",
            "",
            "",
            "",
            "",
          ];
          t.StandardEncoding = i;
          const s = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "space",
            "exclam",
            "quotedbl",
            "numbersign",
            "dollar",
            "percent",
            "ampersand",
            "quotesingle",
            "parenleft",
            "parenright",
            "asterisk",
            "plus",
            "comma",
            "hyphen",
            "period",
            "slash",
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "colon",
            "semicolon",
            "less",
            "equal",
            "greater",
            "question",
            "at",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "bracketleft",
            "backslash",
            "bracketright",
            "asciicircum",
            "underscore",
            "grave",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "braceleft",
            "bar",
            "braceright",
            "asciitilde",
            "bullet",
            "Euro",
            "bullet",
            "quotesinglbase",
            "florin",
            "quotedblbase",
            "ellipsis",
            "dagger",
            "daggerdbl",
            "circumflex",
            "perthousand",
            "Scaron",
            "guilsinglleft",
            "OE",
            "bullet",
            "Zcaron",
            "bullet",
            "bullet",
            "quoteleft",
            "quoteright",
            "quotedblleft",
            "quotedblright",
            "bullet",
            "endash",
            "emdash",
            "tilde",
            "trademark",
            "scaron",
            "guilsinglright",
            "oe",
            "bullet",
            "zcaron",
            "Ydieresis",
            "space",
            "exclamdown",
            "cent",
            "sterling",
            "currency",
            "yen",
            "brokenbar",
            "section",
            "dieresis",
            "copyright",
            "ordfeminine",
            "guillemotleft",
            "logicalnot",
            "hyphen",
            "registered",
            "macron",
            "degree",
            "plusminus",
            "twosuperior",
            "threesuperior",
            "acute",
            "mu",
            "paragraph",
            "periodcentered",
            "cedilla",
            "onesuperior",
            "ordmasculine",
            "guillemotright",
            "onequarter",
            "onehalf",
            "threequarters",
            "questiondown",
            "Agrave",
            "Aacute",
            "Acircumflex",
            "Atilde",
            "Adieresis",
            "Aring",
            "AE",
            "Ccedilla",
            "Egrave",
            "Eacute",
            "Ecircumflex",
            "Edieresis",
            "Igrave",
            "Iacute",
            "Icircumflex",
            "Idieresis",
            "Eth",
            "Ntilde",
            "Ograve",
            "Oacute",
            "Ocircumflex",
            "Otilde",
            "Odieresis",
            "multiply",
            "Oslash",
            "Ugrave",
            "Uacute",
            "Ucircumflex",
            "Udieresis",
            "Yacute",
            "Thorn",
            "germandbls",
            "agrave",
            "aacute",
            "acircumflex",
            "atilde",
            "adieresis",
            "aring",
            "ae",
            "ccedilla",
            "egrave",
            "eacute",
            "ecircumflex",
            "edieresis",
            "igrave",
            "iacute",
            "icircumflex",
            "idieresis",
            "eth",
            "ntilde",
            "ograve",
            "oacute",
            "ocircumflex",
            "otilde",
            "odieresis",
            "divide",
            "oslash",
            "ugrave",
            "uacute",
            "ucircumflex",
            "udieresis",
            "yacute",
            "thorn",
            "ydieresis",
          ];
          t.WinAnsiEncoding = s;
          const o = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "space",
            "exclam",
            "universal",
            "numbersign",
            "existential",
            "percent",
            "ampersand",
            "suchthat",
            "parenleft",
            "parenright",
            "asteriskmath",
            "plus",
            "comma",
            "minus",
            "period",
            "slash",
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "colon",
            "semicolon",
            "less",
            "equal",
            "greater",
            "question",
            "congruent",
            "Alpha",
            "Beta",
            "Chi",
            "Delta",
            "Epsilon",
            "Phi",
            "Gamma",
            "Eta",
            "Iota",
            "theta1",
            "Kappa",
            "Lambda",
            "Mu",
            "Nu",
            "Omicron",
            "Pi",
            "Theta",
            "Rho",
            "Sigma",
            "Tau",
            "Upsilon",
            "sigma1",
            "Omega",
            "Xi",
            "Psi",
            "Zeta",
            "bracketleft",
            "therefore",
            "bracketright",
            "perpendicular",
            "underscore",
            "radicalex",
            "alpha",
            "beta",
            "chi",
            "delta",
            "epsilon",
            "phi",
            "gamma",
            "eta",
            "iota",
            "phi1",
            "kappa",
            "lambda",
            "mu",
            "nu",
            "omicron",
            "pi",
            "theta",
            "rho",
            "sigma",
            "tau",
            "upsilon",
            "omega1",
            "omega",
            "xi",
            "psi",
            "zeta",
            "braceleft",
            "bar",
            "braceright",
            "similar",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Euro",
            "Upsilon1",
            "minute",
            "lessequal",
            "fraction",
            "infinity",
            "florin",
            "club",
            "diamond",
            "heart",
            "spade",
            "arrowboth",
            "arrowleft",
            "arrowup",
            "arrowright",
            "arrowdown",
            "degree",
            "plusminus",
            "second",
            "greaterequal",
            "multiply",
            "proportional",
            "partialdiff",
            "bullet",
            "divide",
            "notequal",
            "equivalence",
            "approxequal",
            "ellipsis",
            "arrowvertex",
            "arrowhorizex",
            "carriagereturn",
            "aleph",
            "Ifraktur",
            "Rfraktur",
            "weierstrass",
            "circlemultiply",
            "circleplus",
            "emptyset",
            "intersection",
            "union",
            "propersuperset",
            "reflexsuperset",
            "notsubset",
            "propersubset",
            "reflexsubset",
            "element",
            "notelement",
            "angle",
            "gradient",
            "registerserif",
            "copyrightserif",
            "trademarkserif",
            "product",
            "radical",
            "dotmath",
            "logicalnot",
            "logicaland",
            "logicalor",
            "arrowdblboth",
            "arrowdblleft",
            "arrowdblup",
            "arrowdblright",
            "arrowdbldown",
            "lozenge",
            "angleleft",
            "registersans",
            "copyrightsans",
            "trademarksans",
            "summation",
            "parenlefttp",
            "parenleftex",
            "parenleftbt",
            "bracketlefttp",
            "bracketleftex",
            "bracketleftbt",
            "bracelefttp",
            "braceleftmid",
            "braceleftbt",
            "braceex",
            "",
            "angleright",
            "integral",
            "integraltp",
            "integralex",
            "integralbt",
            "parenrighttp",
            "parenrightex",
            "parenrightbt",
            "bracketrighttp",
            "bracketrightex",
            "bracketrightbt",
            "bracerighttp",
            "bracerightmid",
            "bracerightbt",
            "",
          ];
          t.SymbolSetEncoding = o;
          const c = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "space",
            "a1",
            "a2",
            "a202",
            "a3",
            "a4",
            "a5",
            "a119",
            "a118",
            "a117",
            "a11",
            "a12",
            "a13",
            "a14",
            "a15",
            "a16",
            "a105",
            "a17",
            "a18",
            "a19",
            "a20",
            "a21",
            "a22",
            "a23",
            "a24",
            "a25",
            "a26",
            "a27",
            "a28",
            "a6",
            "a7",
            "a8",
            "a9",
            "a10",
            "a29",
            "a30",
            "a31",
            "a32",
            "a33",
            "a34",
            "a35",
            "a36",
            "a37",
            "a38",
            "a39",
            "a40",
            "a41",
            "a42",
            "a43",
            "a44",
            "a45",
            "a46",
            "a47",
            "a48",
            "a49",
            "a50",
            "a51",
            "a52",
            "a53",
            "a54",
            "a55",
            "a56",
            "a57",
            "a58",
            "a59",
            "a60",
            "a61",
            "a62",
            "a63",
            "a64",
            "a65",
            "a66",
            "a67",
            "a68",
            "a69",
            "a70",
            "a71",
            "a72",
            "a73",
            "a74",
            "a203",
            "a75",
            "a204",
            "a76",
            "a77",
            "a78",
            "a79",
            "a81",
            "a82",
            "a83",
            "a84",
            "a97",
            "a98",
            "a99",
            "a100",
            "",
            "a89",
            "a90",
            "a93",
            "a94",
            "a91",
            "a92",
            "a205",
            "a85",
            "a206",
            "a86",
            "a87",
            "a88",
            "a95",
            "a96",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "a101",
            "a102",
            "a103",
            "a104",
            "a106",
            "a107",
            "a108",
            "a112",
            "a111",
            "a110",
            "a109",
            "a120",
            "a121",
            "a122",
            "a123",
            "a124",
            "a125",
            "a126",
            "a127",
            "a128",
            "a129",
            "a130",
            "a131",
            "a132",
            "a133",
            "a134",
            "a135",
            "a136",
            "a137",
            "a138",
            "a139",
            "a140",
            "a141",
            "a142",
            "a143",
            "a144",
            "a145",
            "a146",
            "a147",
            "a148",
            "a149",
            "a150",
            "a151",
            "a152",
            "a153",
            "a154",
            "a155",
            "a156",
            "a157",
            "a158",
            "a159",
            "a160",
            "a161",
            "a163",
            "a164",
            "a196",
            "a165",
            "a192",
            "a166",
            "a167",
            "a168",
            "a169",
            "a170",
            "a171",
            "a172",
            "a173",
            "a162",
            "a174",
            "a175",
            "a176",
            "a177",
            "a178",
            "a179",
            "a193",
            "a180",
            "a199",
            "a181",
            "a200",
            "a182",
            "",
            "a201",
            "a183",
            "a184",
            "a197",
            "a185",
            "a194",
            "a198",
            "a186",
            "a195",
            "a187",
            "a188",
            "a189",
            "a190",
            "a191",
            "",
          ];
          t.ZapfDingbatsEncoding = c;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.SEAC_ANALYSIS_ENABLED =
            t.MacStandardGlyphOrdering =
            t.FontFlags =
              void 0;
          t.normalizeFontName = function normalizeFontName(e) {
            return e.replace(/[,_]/g, "-").replace(/\s/g, "");
          };
          t.recoverGlyphName = recoverGlyphName;
          t.type1FontGlyphMapping = function type1FontGlyphMapping(e, t, a) {
            const i = Object.create(null);
            let s, c, l;
            const h = !!(e.flags & o.Symbolic);
            if (e.isInternalFont) {
              l = t;
              for (c = 0; c < l.length; c++) {
                s = a.indexOf(l[c]);
                i[c] = s >= 0 ? s : 0;
              }
            } else if (e.baseEncodingName) {
              l = (0, r.getEncoding)(e.baseEncodingName);
              for (c = 0; c < l.length; c++) {
                s = a.indexOf(l[c]);
                i[c] = s >= 0 ? s : 0;
              }
            } else if (h) for (c in t) i[c] = t[c];
            else {
              l = r.StandardEncoding;
              for (c = 0; c < l.length; c++) {
                s = a.indexOf(l[c]);
                i[c] = s >= 0 ? s : 0;
              }
            }
            const u = e.differences;
            let d;
            if (u)
              for (c in u) {
                const e = u[c];
                s = a.indexOf(e);
                if (-1 === s) {
                  d || (d = (0, n.getGlyphsUnicode)());
                  const t = recoverGlyphName(e, d);
                  t !== e && (s = a.indexOf(t));
                }
                i[c] = s >= 0 ? s : 0;
              }
            return i;
          };
          var r = a(35),
            n = a(37),
            i = a(38),
            s = a(2);
          t.SEAC_ANALYSIS_ENABLED = !0;
          const o = {
            FixedPitch: 1,
            Serif: 2,
            Symbolic: 4,
            Script: 8,
            Nonsymbolic: 32,
            Italic: 64,
            AllCap: 65536,
            SmallCap: 131072,
            ForceBold: 262144,
          };
          t.FontFlags = o;
          t.MacStandardGlyphOrdering = [
            ".notdef",
            ".null",
            "nonmarkingreturn",
            "space",
            "exclam",
            "quotedbl",
            "numbersign",
            "dollar",
            "percent",
            "ampersand",
            "quotesingle",
            "parenleft",
            "parenright",
            "asterisk",
            "plus",
            "comma",
            "hyphen",
            "period",
            "slash",
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "colon",
            "semicolon",
            "less",
            "equal",
            "greater",
            "question",
            "at",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G",
            "H",
            "I",
            "J",
            "K",
            "L",
            "M",
            "N",
            "O",
            "P",
            "Q",
            "R",
            "S",
            "T",
            "U",
            "V",
            "W",
            "X",
            "Y",
            "Z",
            "bracketleft",
            "backslash",
            "bracketright",
            "asciicircum",
            "underscore",
            "grave",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
            "g",
            "h",
            "i",
            "j",
            "k",
            "l",
            "m",
            "n",
            "o",
            "p",
            "q",
            "r",
            "s",
            "t",
            "u",
            "v",
            "w",
            "x",
            "y",
            "z",
            "braceleft",
            "bar",
            "braceright",
            "asciitilde",
            "Adieresis",
            "Aring",
            "Ccedilla",
            "Eacute",
            "Ntilde",
            "Odieresis",
            "Udieresis",
            "aacute",
            "agrave",
            "acircumflex",
            "adieresis",
            "atilde",
            "aring",
            "ccedilla",
            "eacute",
            "egrave",
            "ecircumflex",
            "edieresis",
            "iacute",
            "igrave",
            "icircumflex",
            "idieresis",
            "ntilde",
            "oacute",
            "ograve",
            "ocircumflex",
            "odieresis",
            "otilde",
            "uacute",
            "ugrave",
            "ucircumflex",
            "udieresis",
            "dagger",
            "degree",
            "cent",
            "sterling",
            "section",
            "bullet",
            "paragraph",
            "germandbls",
            "registered",
            "copyright",
            "trademark",
            "acute",
            "dieresis",
            "notequal",
            "AE",
            "Oslash",
            "infinity",
            "plusminus",
            "lessequal",
            "greaterequal",
            "yen",
            "mu",
            "partialdiff",
            "summation",
            "product",
            "pi",
            "integral",
            "ordfeminine",
            "ordmasculine",
            "Omega",
            "ae",
            "oslash",
            "questiondown",
            "exclamdown",
            "logicalnot",
            "radical",
            "florin",
            "approxequal",
            "Delta",
            "guillemotleft",
            "guillemotright",
            "ellipsis",
            "nonbreakingspace",
            "Agrave",
            "Atilde",
            "Otilde",
            "OE",
            "oe",
            "endash",
            "emdash",
            "quotedblleft",
            "quotedblright",
            "quoteleft",
            "quoteright",
            "divide",
            "lozenge",
            "ydieresis",
            "Ydieresis",
            "fraction",
            "currency",
            "guilsinglleft",
            "guilsinglright",
            "fi",
            "fl",
            "daggerdbl",
            "periodcentered",
            "quotesinglbase",
            "quotedblbase",
            "perthousand",
            "Acircumflex",
            "Ecircumflex",
            "Aacute",
            "Edieresis",
            "Egrave",
            "Iacute",
            "Icircumflex",
            "Idieresis",
            "Igrave",
            "Oacute",
            "Ocircumflex",
            "apple",
            "Ograve",
            "Uacute",
            "Ucircumflex",
            "Ugrave",
            "dotlessi",
            "circumflex",
            "tilde",
            "macron",
            "breve",
            "dotaccent",
            "ring",
            "cedilla",
            "hungarumlaut",
            "ogonek",
            "caron",
            "Lslash",
            "lslash",
            "Scaron",
            "scaron",
            "Zcaron",
            "zcaron",
            "brokenbar",
            "Eth",
            "eth",
            "Yacute",
            "yacute",
            "Thorn",
            "thorn",
            "minus",
            "multiply",
            "onesuperior",
            "twosuperior",
            "threesuperior",
            "onehalf",
            "onequarter",
            "threequarters",
            "franc",
            "Gbreve",
            "gbreve",
            "Idotaccent",
            "Scedilla",
            "scedilla",
            "Cacute",
            "cacute",
            "Ccaron",
            "ccaron",
            "dcroat",
          ];
          function recoverGlyphName(e, t) {
            if (void 0 !== t[e]) return e;
            const a = (0, i.getUnicodeForGlyph)(e, t);
            if (-1 !== a) for (const e in t) if (t[e] === a) return e;
            (0, s.info)("Unable to recover a standard glyph name for: " + e);
            return e;
          }
        },
        (e, t, a) => {
          a.r(t);
          a.d(t, {
            getDingbatsGlyphsUnicode: () => i,
            getGlyphsUnicode: () => n,
          });
          var r = a(3);
          const n = (0, r.getArrayLookupTableFactory)(function () {
              return [
                "A",
                65,
                "AE",
                198,
                "AEacute",
                508,
                "AEmacron",
                482,
                "AEsmall",
                63462,
                "Aacute",
                193,
                "Aacutesmall",
                63457,
                "Abreve",
                258,
                "Abreveacute",
                7854,
                "Abrevecyrillic",
                1232,
                "Abrevedotbelow",
                7862,
                "Abrevegrave",
                7856,
                "Abrevehookabove",
                7858,
                "Abrevetilde",
                7860,
                "Acaron",
                461,
                "Acircle",
                9398,
                "Acircumflex",
                194,
                "Acircumflexacute",
                7844,
                "Acircumflexdotbelow",
                7852,
                "Acircumflexgrave",
                7846,
                "Acircumflexhookabove",
                7848,
                "Acircumflexsmall",
                63458,
                "Acircumflextilde",
                7850,
                "Acute",
                63177,
                "Acutesmall",
                63412,
                "Acyrillic",
                1040,
                "Adblgrave",
                512,
                "Adieresis",
                196,
                "Adieresiscyrillic",
                1234,
                "Adieresismacron",
                478,
                "Adieresissmall",
                63460,
                "Adotbelow",
                7840,
                "Adotmacron",
                480,
                "Agrave",
                192,
                "Agravesmall",
                63456,
                "Ahookabove",
                7842,
                "Aiecyrillic",
                1236,
                "Ainvertedbreve",
                514,
                "Alpha",
                913,
                "Alphatonos",
                902,
                "Amacron",
                256,
                "Amonospace",
                65313,
                "Aogonek",
                260,
                "Aring",
                197,
                "Aringacute",
                506,
                "Aringbelow",
                7680,
                "Aringsmall",
                63461,
                "Asmall",
                63329,
                "Atilde",
                195,
                "Atildesmall",
                63459,
                "Aybarmenian",
                1329,
                "B",
                66,
                "Bcircle",
                9399,
                "Bdotaccent",
                7682,
                "Bdotbelow",
                7684,
                "Becyrillic",
                1041,
                "Benarmenian",
                1330,
                "Beta",
                914,
                "Bhook",
                385,
                "Blinebelow",
                7686,
                "Bmonospace",
                65314,
                "Brevesmall",
                63220,
                "Bsmall",
                63330,
                "Btopbar",
                386,
                "C",
                67,
                "Caarmenian",
                1342,
                "Cacute",
                262,
                "Caron",
                63178,
                "Caronsmall",
                63221,
                "Ccaron",
                268,
                "Ccedilla",
                199,
                "Ccedillaacute",
                7688,
                "Ccedillasmall",
                63463,
                "Ccircle",
                9400,
                "Ccircumflex",
                264,
                "Cdot",
                266,
                "Cdotaccent",
                266,
                "Cedillasmall",
                63416,
                "Chaarmenian",
                1353,
                "Cheabkhasiancyrillic",
                1212,
                "Checyrillic",
                1063,
                "Chedescenderabkhasiancyrillic",
                1214,
                "Chedescendercyrillic",
                1206,
                "Chedieresiscyrillic",
                1268,
                "Cheharmenian",
                1347,
                "Chekhakassiancyrillic",
                1227,
                "Cheverticalstrokecyrillic",
                1208,
                "Chi",
                935,
                "Chook",
                391,
                "Circumflexsmall",
                63222,
                "Cmonospace",
                65315,
                "Coarmenian",
                1361,
                "Csmall",
                63331,
                "D",
                68,
                "DZ",
                497,
                "DZcaron",
                452,
                "Daarmenian",
                1332,
                "Dafrican",
                393,
                "Dcaron",
                270,
                "Dcedilla",
                7696,
                "Dcircle",
                9401,
                "Dcircumflexbelow",
                7698,
                "Dcroat",
                272,
                "Ddotaccent",
                7690,
                "Ddotbelow",
                7692,
                "Decyrillic",
                1044,
                "Deicoptic",
                1006,
                "Delta",
                8710,
                "Deltagreek",
                916,
                "Dhook",
                394,
                "Dieresis",
                63179,
                "DieresisAcute",
                63180,
                "DieresisGrave",
                63181,
                "Dieresissmall",
                63400,
                "Digammagreek",
                988,
                "Djecyrillic",
                1026,
                "Dlinebelow",
                7694,
                "Dmonospace",
                65316,
                "Dotaccentsmall",
                63223,
                "Dslash",
                272,
                "Dsmall",
                63332,
                "Dtopbar",
                395,
                "Dz",
                498,
                "Dzcaron",
                453,
                "Dzeabkhasiancyrillic",
                1248,
                "Dzecyrillic",
                1029,
                "Dzhecyrillic",
                1039,
                "E",
                69,
                "Eacute",
                201,
                "Eacutesmall",
                63465,
                "Ebreve",
                276,
                "Ecaron",
                282,
                "Ecedillabreve",
                7708,
                "Echarmenian",
                1333,
                "Ecircle",
                9402,
                "Ecircumflex",
                202,
                "Ecircumflexacute",
                7870,
                "Ecircumflexbelow",
                7704,
                "Ecircumflexdotbelow",
                7878,
                "Ecircumflexgrave",
                7872,
                "Ecircumflexhookabove",
                7874,
                "Ecircumflexsmall",
                63466,
                "Ecircumflextilde",
                7876,
                "Ecyrillic",
                1028,
                "Edblgrave",
                516,
                "Edieresis",
                203,
                "Edieresissmall",
                63467,
                "Edot",
                278,
                "Edotaccent",
                278,
                "Edotbelow",
                7864,
                "Efcyrillic",
                1060,
                "Egrave",
                200,
                "Egravesmall",
                63464,
                "Eharmenian",
                1335,
                "Ehookabove",
                7866,
                "Eightroman",
                8551,
                "Einvertedbreve",
                518,
                "Eiotifiedcyrillic",
                1124,
                "Elcyrillic",
                1051,
                "Elevenroman",
                8554,
                "Emacron",
                274,
                "Emacronacute",
                7702,
                "Emacrongrave",
                7700,
                "Emcyrillic",
                1052,
                "Emonospace",
                65317,
                "Encyrillic",
                1053,
                "Endescendercyrillic",
                1186,
                "Eng",
                330,
                "Enghecyrillic",
                1188,
                "Enhookcyrillic",
                1223,
                "Eogonek",
                280,
                "Eopen",
                400,
                "Epsilon",
                917,
                "Epsilontonos",
                904,
                "Ercyrillic",
                1056,
                "Ereversed",
                398,
                "Ereversedcyrillic",
                1069,
                "Escyrillic",
                1057,
                "Esdescendercyrillic",
                1194,
                "Esh",
                425,
                "Esmall",
                63333,
                "Eta",
                919,
                "Etarmenian",
                1336,
                "Etatonos",
                905,
                "Eth",
                208,
                "Ethsmall",
                63472,
                "Etilde",
                7868,
                "Etildebelow",
                7706,
                "Euro",
                8364,
                "Ezh",
                439,
                "Ezhcaron",
                494,
                "Ezhreversed",
                440,
                "F",
                70,
                "Fcircle",
                9403,
                "Fdotaccent",
                7710,
                "Feharmenian",
                1366,
                "Feicoptic",
                996,
                "Fhook",
                401,
                "Fitacyrillic",
                1138,
                "Fiveroman",
                8548,
                "Fmonospace",
                65318,
                "Fourroman",
                8547,
                "Fsmall",
                63334,
                "G",
                71,
                "GBsquare",
                13191,
                "Gacute",
                500,
                "Gamma",
                915,
                "Gammaafrican",
                404,
                "Gangiacoptic",
                1002,
                "Gbreve",
                286,
                "Gcaron",
                486,
                "Gcedilla",
                290,
                "Gcircle",
                9404,
                "Gcircumflex",
                284,
                "Gcommaaccent",
                290,
                "Gdot",
                288,
                "Gdotaccent",
                288,
                "Gecyrillic",
                1043,
                "Ghadarmenian",
                1346,
                "Ghemiddlehookcyrillic",
                1172,
                "Ghestrokecyrillic",
                1170,
                "Gheupturncyrillic",
                1168,
                "Ghook",
                403,
                "Gimarmenian",
                1331,
                "Gjecyrillic",
                1027,
                "Gmacron",
                7712,
                "Gmonospace",
                65319,
                "Grave",
                63182,
                "Gravesmall",
                63328,
                "Gsmall",
                63335,
                "Gsmallhook",
                667,
                "Gstroke",
                484,
                "H",
                72,
                "H18533",
                9679,
                "H18543",
                9642,
                "H18551",
                9643,
                "H22073",
                9633,
                "HPsquare",
                13259,
                "Haabkhasiancyrillic",
                1192,
                "Hadescendercyrillic",
                1202,
                "Hardsigncyrillic",
                1066,
                "Hbar",
                294,
                "Hbrevebelow",
                7722,
                "Hcedilla",
                7720,
                "Hcircle",
                9405,
                "Hcircumflex",
                292,
                "Hdieresis",
                7718,
                "Hdotaccent",
                7714,
                "Hdotbelow",
                7716,
                "Hmonospace",
                65320,
                "Hoarmenian",
                1344,
                "Horicoptic",
                1e3,
                "Hsmall",
                63336,
                "Hungarumlaut",
                63183,
                "Hungarumlautsmall",
                63224,
                "Hzsquare",
                13200,
                "I",
                73,
                "IAcyrillic",
                1071,
                "IJ",
                306,
                "IUcyrillic",
                1070,
                "Iacute",
                205,
                "Iacutesmall",
                63469,
                "Ibreve",
                300,
                "Icaron",
                463,
                "Icircle",
                9406,
                "Icircumflex",
                206,
                "Icircumflexsmall",
                63470,
                "Icyrillic",
                1030,
                "Idblgrave",
                520,
                "Idieresis",
                207,
                "Idieresisacute",
                7726,
                "Idieresiscyrillic",
                1252,
                "Idieresissmall",
                63471,
                "Idot",
                304,
                "Idotaccent",
                304,
                "Idotbelow",
                7882,
                "Iebrevecyrillic",
                1238,
                "Iecyrillic",
                1045,
                "Ifraktur",
                8465,
                "Igrave",
                204,
                "Igravesmall",
                63468,
                "Ihookabove",
                7880,
                "Iicyrillic",
                1048,
                "Iinvertedbreve",
                522,
                "Iishortcyrillic",
                1049,
                "Imacron",
                298,
                "Imacroncyrillic",
                1250,
                "Imonospace",
                65321,
                "Iniarmenian",
                1339,
                "Iocyrillic",
                1025,
                "Iogonek",
                302,
                "Iota",
                921,
                "Iotaafrican",
                406,
                "Iotadieresis",
                938,
                "Iotatonos",
                906,
                "Ismall",
                63337,
                "Istroke",
                407,
                "Itilde",
                296,
                "Itildebelow",
                7724,
                "Izhitsacyrillic",
                1140,
                "Izhitsadblgravecyrillic",
                1142,
                "J",
                74,
                "Jaarmenian",
                1345,
                "Jcircle",
                9407,
                "Jcircumflex",
                308,
                "Jecyrillic",
                1032,
                "Jheharmenian",
                1355,
                "Jmonospace",
                65322,
                "Jsmall",
                63338,
                "K",
                75,
                "KBsquare",
                13189,
                "KKsquare",
                13261,
                "Kabashkircyrillic",
                1184,
                "Kacute",
                7728,
                "Kacyrillic",
                1050,
                "Kadescendercyrillic",
                1178,
                "Kahookcyrillic",
                1219,
                "Kappa",
                922,
                "Kastrokecyrillic",
                1182,
                "Kaverticalstrokecyrillic",
                1180,
                "Kcaron",
                488,
                "Kcedilla",
                310,
                "Kcircle",
                9408,
                "Kcommaaccent",
                310,
                "Kdotbelow",
                7730,
                "Keharmenian",
                1364,
                "Kenarmenian",
                1343,
                "Khacyrillic",
                1061,
                "Kheicoptic",
                998,
                "Khook",
                408,
                "Kjecyrillic",
                1036,
                "Klinebelow",
                7732,
                "Kmonospace",
                65323,
                "Koppacyrillic",
                1152,
                "Koppagreek",
                990,
                "Ksicyrillic",
                1134,
                "Ksmall",
                63339,
                "L",
                76,
                "LJ",
                455,
                "LL",
                63167,
                "Lacute",
                313,
                "Lambda",
                923,
                "Lcaron",
                317,
                "Lcedilla",
                315,
                "Lcircle",
                9409,
                "Lcircumflexbelow",
                7740,
                "Lcommaaccent",
                315,
                "Ldot",
                319,
                "Ldotaccent",
                319,
                "Ldotbelow",
                7734,
                "Ldotbelowmacron",
                7736,
                "Liwnarmenian",
                1340,
                "Lj",
                456,
                "Ljecyrillic",
                1033,
                "Llinebelow",
                7738,
                "Lmonospace",
                65324,
                "Lslash",
                321,
                "Lslashsmall",
                63225,
                "Lsmall",
                63340,
                "M",
                77,
                "MBsquare",
                13190,
                "Macron",
                63184,
                "Macronsmall",
                63407,
                "Macute",
                7742,
                "Mcircle",
                9410,
                "Mdotaccent",
                7744,
                "Mdotbelow",
                7746,
                "Menarmenian",
                1348,
                "Mmonospace",
                65325,
                "Msmall",
                63341,
                "Mturned",
                412,
                "Mu",
                924,
                "N",
                78,
                "NJ",
                458,
                "Nacute",
                323,
                "Ncaron",
                327,
                "Ncedilla",
                325,
                "Ncircle",
                9411,
                "Ncircumflexbelow",
                7754,
                "Ncommaaccent",
                325,
                "Ndotaccent",
                7748,
                "Ndotbelow",
                7750,
                "Nhookleft",
                413,
                "Nineroman",
                8552,
                "Nj",
                459,
                "Njecyrillic",
                1034,
                "Nlinebelow",
                7752,
                "Nmonospace",
                65326,
                "Nowarmenian",
                1350,
                "Nsmall",
                63342,
                "Ntilde",
                209,
                "Ntildesmall",
                63473,
                "Nu",
                925,
                "O",
                79,
                "OE",
                338,
                "OEsmall",
                63226,
                "Oacute",
                211,
                "Oacutesmall",
                63475,
                "Obarredcyrillic",
                1256,
                "Obarreddieresiscyrillic",
                1258,
                "Obreve",
                334,
                "Ocaron",
                465,
                "Ocenteredtilde",
                415,
                "Ocircle",
                9412,
                "Ocircumflex",
                212,
                "Ocircumflexacute",
                7888,
                "Ocircumflexdotbelow",
                7896,
                "Ocircumflexgrave",
                7890,
                "Ocircumflexhookabove",
                7892,
                "Ocircumflexsmall",
                63476,
                "Ocircumflextilde",
                7894,
                "Ocyrillic",
                1054,
                "Odblacute",
                336,
                "Odblgrave",
                524,
                "Odieresis",
                214,
                "Odieresiscyrillic",
                1254,
                "Odieresissmall",
                63478,
                "Odotbelow",
                7884,
                "Ogoneksmall",
                63227,
                "Ograve",
                210,
                "Ogravesmall",
                63474,
                "Oharmenian",
                1365,
                "Ohm",
                8486,
                "Ohookabove",
                7886,
                "Ohorn",
                416,
                "Ohornacute",
                7898,
                "Ohorndotbelow",
                7906,
                "Ohorngrave",
                7900,
                "Ohornhookabove",
                7902,
                "Ohorntilde",
                7904,
                "Ohungarumlaut",
                336,
                "Oi",
                418,
                "Oinvertedbreve",
                526,
                "Omacron",
                332,
                "Omacronacute",
                7762,
                "Omacrongrave",
                7760,
                "Omega",
                8486,
                "Omegacyrillic",
                1120,
                "Omegagreek",
                937,
                "Omegaroundcyrillic",
                1146,
                "Omegatitlocyrillic",
                1148,
                "Omegatonos",
                911,
                "Omicron",
                927,
                "Omicrontonos",
                908,
                "Omonospace",
                65327,
                "Oneroman",
                8544,
                "Oogonek",
                490,
                "Oogonekmacron",
                492,
                "Oopen",
                390,
                "Oslash",
                216,
                "Oslashacute",
                510,
                "Oslashsmall",
                63480,
                "Osmall",
                63343,
                "Ostrokeacute",
                510,
                "Otcyrillic",
                1150,
                "Otilde",
                213,
                "Otildeacute",
                7756,
                "Otildedieresis",
                7758,
                "Otildesmall",
                63477,
                "P",
                80,
                "Pacute",
                7764,
                "Pcircle",
                9413,
                "Pdotaccent",
                7766,
                "Pecyrillic",
                1055,
                "Peharmenian",
                1354,
                "Pemiddlehookcyrillic",
                1190,
                "Phi",
                934,
                "Phook",
                420,
                "Pi",
                928,
                "Piwrarmenian",
                1363,
                "Pmonospace",
                65328,
                "Psi",
                936,
                "Psicyrillic",
                1136,
                "Psmall",
                63344,
                "Q",
                81,
                "Qcircle",
                9414,
                "Qmonospace",
                65329,
                "Qsmall",
                63345,
                "R",
                82,
                "Raarmenian",
                1356,
                "Racute",
                340,
                "Rcaron",
                344,
                "Rcedilla",
                342,
                "Rcircle",
                9415,
                "Rcommaaccent",
                342,
                "Rdblgrave",
                528,
                "Rdotaccent",
                7768,
                "Rdotbelow",
                7770,
                "Rdotbelowmacron",
                7772,
                "Reharmenian",
                1360,
                "Rfraktur",
                8476,
                "Rho",
                929,
                "Ringsmall",
                63228,
                "Rinvertedbreve",
                530,
                "Rlinebelow",
                7774,
                "Rmonospace",
                65330,
                "Rsmall",
                63346,
                "Rsmallinverted",
                641,
                "Rsmallinvertedsuperior",
                694,
                "S",
                83,
                "SF010000",
                9484,
                "SF020000",
                9492,
                "SF030000",
                9488,
                "SF040000",
                9496,
                "SF050000",
                9532,
                "SF060000",
                9516,
                "SF070000",
                9524,
                "SF080000",
                9500,
                "SF090000",
                9508,
                "SF100000",
                9472,
                "SF110000",
                9474,
                "SF190000",
                9569,
                "SF200000",
                9570,
                "SF210000",
                9558,
                "SF220000",
                9557,
                "SF230000",
                9571,
                "SF240000",
                9553,
                "SF250000",
                9559,
                "SF260000",
                9565,
                "SF270000",
                9564,
                "SF280000",
                9563,
                "SF360000",
                9566,
                "SF370000",
                9567,
                "SF380000",
                9562,
                "SF390000",
                9556,
                "SF400000",
                9577,
                "SF410000",
                9574,
                "SF420000",
                9568,
                "SF430000",
                9552,
                "SF440000",
                9580,
                "SF450000",
                9575,
                "SF460000",
                9576,
                "SF470000",
                9572,
                "SF480000",
                9573,
                "SF490000",
                9561,
                "SF500000",
                9560,
                "SF510000",
                9554,
                "SF520000",
                9555,
                "SF530000",
                9579,
                "SF540000",
                9578,
                "Sacute",
                346,
                "Sacutedotaccent",
                7780,
                "Sampigreek",
                992,
                "Scaron",
                352,
                "Scarondotaccent",
                7782,
                "Scaronsmall",
                63229,
                "Scedilla",
                350,
                "Schwa",
                399,
                "Schwacyrillic",
                1240,
                "Schwadieresiscyrillic",
                1242,
                "Scircle",
                9416,
                "Scircumflex",
                348,
                "Scommaaccent",
                536,
                "Sdotaccent",
                7776,
                "Sdotbelow",
                7778,
                "Sdotbelowdotaccent",
                7784,
                "Seharmenian",
                1357,
                "Sevenroman",
                8550,
                "Shaarmenian",
                1351,
                "Shacyrillic",
                1064,
                "Shchacyrillic",
                1065,
                "Sheicoptic",
                994,
                "Shhacyrillic",
                1210,
                "Shimacoptic",
                1004,
                "Sigma",
                931,
                "Sixroman",
                8549,
                "Smonospace",
                65331,
                "Softsigncyrillic",
                1068,
                "Ssmall",
                63347,
                "Stigmagreek",
                986,
                "T",
                84,
                "Tau",
                932,
                "Tbar",
                358,
                "Tcaron",
                356,
                "Tcedilla",
                354,
                "Tcircle",
                9417,
                "Tcircumflexbelow",
                7792,
                "Tcommaaccent",
                354,
                "Tdotaccent",
                7786,
                "Tdotbelow",
                7788,
                "Tecyrillic",
                1058,
                "Tedescendercyrillic",
                1196,
                "Tenroman",
                8553,
                "Tetsecyrillic",
                1204,
                "Theta",
                920,
                "Thook",
                428,
                "Thorn",
                222,
                "Thornsmall",
                63486,
                "Threeroman",
                8546,
                "Tildesmall",
                63230,
                "Tiwnarmenian",
                1359,
                "Tlinebelow",
                7790,
                "Tmonospace",
                65332,
                "Toarmenian",
                1337,
                "Tonefive",
                444,
                "Tonesix",
                388,
                "Tonetwo",
                423,
                "Tretroflexhook",
                430,
                "Tsecyrillic",
                1062,
                "Tshecyrillic",
                1035,
                "Tsmall",
                63348,
                "Twelveroman",
                8555,
                "Tworoman",
                8545,
                "U",
                85,
                "Uacute",
                218,
                "Uacutesmall",
                63482,
                "Ubreve",
                364,
                "Ucaron",
                467,
                "Ucircle",
                9418,
                "Ucircumflex",
                219,
                "Ucircumflexbelow",
                7798,
                "Ucircumflexsmall",
                63483,
                "Ucyrillic",
                1059,
                "Udblacute",
                368,
                "Udblgrave",
                532,
                "Udieresis",
                220,
                "Udieresisacute",
                471,
                "Udieresisbelow",
                7794,
                "Udieresiscaron",
                473,
                "Udieresiscyrillic",
                1264,
                "Udieresisgrave",
                475,
                "Udieresismacron",
                469,
                "Udieresissmall",
                63484,
                "Udotbelow",
                7908,
                "Ugrave",
                217,
                "Ugravesmall",
                63481,
                "Uhookabove",
                7910,
                "Uhorn",
                431,
                "Uhornacute",
                7912,
                "Uhorndotbelow",
                7920,
                "Uhorngrave",
                7914,
                "Uhornhookabove",
                7916,
                "Uhorntilde",
                7918,
                "Uhungarumlaut",
                368,
                "Uhungarumlautcyrillic",
                1266,
                "Uinvertedbreve",
                534,
                "Ukcyrillic",
                1144,
                "Umacron",
                362,
                "Umacroncyrillic",
                1262,
                "Umacrondieresis",
                7802,
                "Umonospace",
                65333,
                "Uogonek",
                370,
                "Upsilon",
                933,
                "Upsilon1",
                978,
                "Upsilonacutehooksymbolgreek",
                979,
                "Upsilonafrican",
                433,
                "Upsilondieresis",
                939,
                "Upsilondieresishooksymbolgreek",
                980,
                "Upsilonhooksymbol",
                978,
                "Upsilontonos",
                910,
                "Uring",
                366,
                "Ushortcyrillic",
                1038,
                "Usmall",
                63349,
                "Ustraightcyrillic",
                1198,
                "Ustraightstrokecyrillic",
                1200,
                "Utilde",
                360,
                "Utildeacute",
                7800,
                "Utildebelow",
                7796,
                "V",
                86,
                "Vcircle",
                9419,
                "Vdotbelow",
                7806,
                "Vecyrillic",
                1042,
                "Vewarmenian",
                1358,
                "Vhook",
                434,
                "Vmonospace",
                65334,
                "Voarmenian",
                1352,
                "Vsmall",
                63350,
                "Vtilde",
                7804,
                "W",
                87,
                "Wacute",
                7810,
                "Wcircle",
                9420,
                "Wcircumflex",
                372,
                "Wdieresis",
                7812,
                "Wdotaccent",
                7814,
                "Wdotbelow",
                7816,
                "Wgrave",
                7808,
                "Wmonospace",
                65335,
                "Wsmall",
                63351,
                "X",
                88,
                "Xcircle",
                9421,
                "Xdieresis",
                7820,
                "Xdotaccent",
                7818,
                "Xeharmenian",
                1341,
                "Xi",
                926,
                "Xmonospace",
                65336,
                "Xsmall",
                63352,
                "Y",
                89,
                "Yacute",
                221,
                "Yacutesmall",
                63485,
                "Yatcyrillic",
                1122,
                "Ycircle",
                9422,
                "Ycircumflex",
                374,
                "Ydieresis",
                376,
                "Ydieresissmall",
                63487,
                "Ydotaccent",
                7822,
                "Ydotbelow",
                7924,
                "Yericyrillic",
                1067,
                "Yerudieresiscyrillic",
                1272,
                "Ygrave",
                7922,
                "Yhook",
                435,
                "Yhookabove",
                7926,
                "Yiarmenian",
                1349,
                "Yicyrillic",
                1031,
                "Yiwnarmenian",
                1362,
                "Ymonospace",
                65337,
                "Ysmall",
                63353,
                "Ytilde",
                7928,
                "Yusbigcyrillic",
                1130,
                "Yusbigiotifiedcyrillic",
                1132,
                "Yuslittlecyrillic",
                1126,
                "Yuslittleiotifiedcyrillic",
                1128,
                "Z",
                90,
                "Zaarmenian",
                1334,
                "Zacute",
                377,
                "Zcaron",
                381,
                "Zcaronsmall",
                63231,
                "Zcircle",
                9423,
                "Zcircumflex",
                7824,
                "Zdot",
                379,
                "Zdotaccent",
                379,
                "Zdotbelow",
                7826,
                "Zecyrillic",
                1047,
                "Zedescendercyrillic",
                1176,
                "Zedieresiscyrillic",
                1246,
                "Zeta",
                918,
                "Zhearmenian",
                1338,
                "Zhebrevecyrillic",
                1217,
                "Zhecyrillic",
                1046,
                "Zhedescendercyrillic",
                1174,
                "Zhedieresiscyrillic",
                1244,
                "Zlinebelow",
                7828,
                "Zmonospace",
                65338,
                "Zsmall",
                63354,
                "Zstroke",
                437,
                "a",
                97,
                "aabengali",
                2438,
                "aacute",
                225,
                "aadeva",
                2310,
                "aagujarati",
                2694,
                "aagurmukhi",
                2566,
                "aamatragurmukhi",
                2622,
                "aarusquare",
                13059,
                "aavowelsignbengali",
                2494,
                "aavowelsigndeva",
                2366,
                "aavowelsigngujarati",
                2750,
                "abbreviationmarkarmenian",
                1375,
                "abbreviationsigndeva",
                2416,
                "abengali",
                2437,
                "abopomofo",
                12570,
                "abreve",
                259,
                "abreveacute",
                7855,
                "abrevecyrillic",
                1233,
                "abrevedotbelow",
                7863,
                "abrevegrave",
                7857,
                "abrevehookabove",
                7859,
                "abrevetilde",
                7861,
                "acaron",
                462,
                "acircle",
                9424,
                "acircumflex",
                226,
                "acircumflexacute",
                7845,
                "acircumflexdotbelow",
                7853,
                "acircumflexgrave",
                7847,
                "acircumflexhookabove",
                7849,
                "acircumflextilde",
                7851,
                "acute",
                180,
                "acutebelowcmb",
                791,
                "acutecmb",
                769,
                "acutecomb",
                769,
                "acutedeva",
                2388,
                "acutelowmod",
                719,
                "acutetonecmb",
                833,
                "acyrillic",
                1072,
                "adblgrave",
                513,
                "addakgurmukhi",
                2673,
                "adeva",
                2309,
                "adieresis",
                228,
                "adieresiscyrillic",
                1235,
                "adieresismacron",
                479,
                "adotbelow",
                7841,
                "adotmacron",
                481,
                "ae",
                230,
                "aeacute",
                509,
                "aekorean",
                12624,
                "aemacron",
                483,
                "afii00208",
                8213,
                "afii08941",
                8356,
                "afii10017",
                1040,
                "afii10018",
                1041,
                "afii10019",
                1042,
                "afii10020",
                1043,
                "afii10021",
                1044,
                "afii10022",
                1045,
                "afii10023",
                1025,
                "afii10024",
                1046,
                "afii10025",
                1047,
                "afii10026",
                1048,
                "afii10027",
                1049,
                "afii10028",
                1050,
                "afii10029",
                1051,
                "afii10030",
                1052,
                "afii10031",
                1053,
                "afii10032",
                1054,
                "afii10033",
                1055,
                "afii10034",
                1056,
                "afii10035",
                1057,
                "afii10036",
                1058,
                "afii10037",
                1059,
                "afii10038",
                1060,
                "afii10039",
                1061,
                "afii10040",
                1062,
                "afii10041",
                1063,
                "afii10042",
                1064,
                "afii10043",
                1065,
                "afii10044",
                1066,
                "afii10045",
                1067,
                "afii10046",
                1068,
                "afii10047",
                1069,
                "afii10048",
                1070,
                "afii10049",
                1071,
                "afii10050",
                1168,
                "afii10051",
                1026,
                "afii10052",
                1027,
                "afii10053",
                1028,
                "afii10054",
                1029,
                "afii10055",
                1030,
                "afii10056",
                1031,
                "afii10057",
                1032,
                "afii10058",
                1033,
                "afii10059",
                1034,
                "afii10060",
                1035,
                "afii10061",
                1036,
                "afii10062",
                1038,
                "afii10063",
                63172,
                "afii10064",
                63173,
                "afii10065",
                1072,
                "afii10066",
                1073,
                "afii10067",
                1074,
                "afii10068",
                1075,
                "afii10069",
                1076,
                "afii10070",
                1077,
                "afii10071",
                1105,
                "afii10072",
                1078,
                "afii10073",
                1079,
                "afii10074",
                1080,
                "afii10075",
                1081,
                "afii10076",
                1082,
                "afii10077",
                1083,
                "afii10078",
                1084,
                "afii10079",
                1085,
                "afii10080",
                1086,
                "afii10081",
                1087,
                "afii10082",
                1088,
                "afii10083",
                1089,
                "afii10084",
                1090,
                "afii10085",
                1091,
                "afii10086",
                1092,
                "afii10087",
                1093,
                "afii10088",
                1094,
                "afii10089",
                1095,
                "afii10090",
                1096,
                "afii10091",
                1097,
                "afii10092",
                1098,
                "afii10093",
                1099,
                "afii10094",
                1100,
                "afii10095",
                1101,
                "afii10096",
                1102,
                "afii10097",
                1103,
                "afii10098",
                1169,
                "afii10099",
                1106,
                "afii10100",
                1107,
                "afii10101",
                1108,
                "afii10102",
                1109,
                "afii10103",
                1110,
                "afii10104",
                1111,
                "afii10105",
                1112,
                "afii10106",
                1113,
                "afii10107",
                1114,
                "afii10108",
                1115,
                "afii10109",
                1116,
                "afii10110",
                1118,
                "afii10145",
                1039,
                "afii10146",
                1122,
                "afii10147",
                1138,
                "afii10148",
                1140,
                "afii10192",
                63174,
                "afii10193",
                1119,
                "afii10194",
                1123,
                "afii10195",
                1139,
                "afii10196",
                1141,
                "afii10831",
                63175,
                "afii10832",
                63176,
                "afii10846",
                1241,
                "afii299",
                8206,
                "afii300",
                8207,
                "afii301",
                8205,
                "afii57381",
                1642,
                "afii57388",
                1548,
                "afii57392",
                1632,
                "afii57393",
                1633,
                "afii57394",
                1634,
                "afii57395",
                1635,
                "afii57396",
                1636,
                "afii57397",
                1637,
                "afii57398",
                1638,
                "afii57399",
                1639,
                "afii57400",
                1640,
                "afii57401",
                1641,
                "afii57403",
                1563,
                "afii57407",
                1567,
                "afii57409",
                1569,
                "afii57410",
                1570,
                "afii57411",
                1571,
                "afii57412",
                1572,
                "afii57413",
                1573,
                "afii57414",
                1574,
                "afii57415",
                1575,
                "afii57416",
                1576,
                "afii57417",
                1577,
                "afii57418",
                1578,
                "afii57419",
                1579,
                "afii57420",
                1580,
                "afii57421",
                1581,
                "afii57422",
                1582,
                "afii57423",
                1583,
                "afii57424",
                1584,
                "afii57425",
                1585,
                "afii57426",
                1586,
                "afii57427",
                1587,
                "afii57428",
                1588,
                "afii57429",
                1589,
                "afii57430",
                1590,
                "afii57431",
                1591,
                "afii57432",
                1592,
                "afii57433",
                1593,
                "afii57434",
                1594,
                "afii57440",
                1600,
                "afii57441",
                1601,
                "afii57442",
                1602,
                "afii57443",
                1603,
                "afii57444",
                1604,
                "afii57445",
                1605,
                "afii57446",
                1606,
                "afii57448",
                1608,
                "afii57449",
                1609,
                "afii57450",
                1610,
                "afii57451",
                1611,
                "afii57452",
                1612,
                "afii57453",
                1613,
                "afii57454",
                1614,
                "afii57455",
                1615,
                "afii57456",
                1616,
                "afii57457",
                1617,
                "afii57458",
                1618,
                "afii57470",
                1607,
                "afii57505",
                1700,
                "afii57506",
                1662,
                "afii57507",
                1670,
                "afii57508",
                1688,
                "afii57509",
                1711,
                "afii57511",
                1657,
                "afii57512",
                1672,
                "afii57513",
                1681,
                "afii57514",
                1722,
                "afii57519",
                1746,
                "afii57534",
                1749,
                "afii57636",
                8362,
                "afii57645",
                1470,
                "afii57658",
                1475,
                "afii57664",
                1488,
                "afii57665",
                1489,
                "afii57666",
                1490,
                "afii57667",
                1491,
                "afii57668",
                1492,
                "afii57669",
                1493,
                "afii57670",
                1494,
                "afii57671",
                1495,
                "afii57672",
                1496,
                "afii57673",
                1497,
                "afii57674",
                1498,
                "afii57675",
                1499,
                "afii57676",
                1500,
                "afii57677",
                1501,
                "afii57678",
                1502,
                "afii57679",
                1503,
                "afii57680",
                1504,
                "afii57681",
                1505,
                "afii57682",
                1506,
                "afii57683",
                1507,
                "afii57684",
                1508,
                "afii57685",
                1509,
                "afii57686",
                1510,
                "afii57687",
                1511,
                "afii57688",
                1512,
                "afii57689",
                1513,
                "afii57690",
                1514,
                "afii57694",
                64298,
                "afii57695",
                64299,
                "afii57700",
                64331,
                "afii57705",
                64287,
                "afii57716",
                1520,
                "afii57717",
                1521,
                "afii57718",
                1522,
                "afii57723",
                64309,
                "afii57793",
                1460,
                "afii57794",
                1461,
                "afii57795",
                1462,
                "afii57796",
                1467,
                "afii57797",
                1464,
                "afii57798",
                1463,
                "afii57799",
                1456,
                "afii57800",
                1458,
                "afii57801",
                1457,
                "afii57802",
                1459,
                "afii57803",
                1474,
                "afii57804",
                1473,
                "afii57806",
                1465,
                "afii57807",
                1468,
                "afii57839",
                1469,
                "afii57841",
                1471,
                "afii57842",
                1472,
                "afii57929",
                700,
                "afii61248",
                8453,
                "afii61289",
                8467,
                "afii61352",
                8470,
                "afii61573",
                8236,
                "afii61574",
                8237,
                "afii61575",
                8238,
                "afii61664",
                8204,
                "afii63167",
                1645,
                "afii64937",
                701,
                "agrave",
                224,
                "agujarati",
                2693,
                "agurmukhi",
                2565,
                "ahiragana",
                12354,
                "ahookabove",
                7843,
                "aibengali",
                2448,
                "aibopomofo",
                12574,
                "aideva",
                2320,
                "aiecyrillic",
                1237,
                "aigujarati",
                2704,
                "aigurmukhi",
                2576,
                "aimatragurmukhi",
                2632,
                "ainarabic",
                1593,
                "ainfinalarabic",
                65226,
                "aininitialarabic",
                65227,
                "ainmedialarabic",
                65228,
                "ainvertedbreve",
                515,
                "aivowelsignbengali",
                2504,
                "aivowelsigndeva",
                2376,
                "aivowelsigngujarati",
                2760,
                "akatakana",
                12450,
                "akatakanahalfwidth",
                65393,
                "akorean",
                12623,
                "alef",
                1488,
                "alefarabic",
                1575,
                "alefdageshhebrew",
                64304,
                "aleffinalarabic",
                65166,
                "alefhamzaabovearabic",
                1571,
                "alefhamzaabovefinalarabic",
                65156,
                "alefhamzabelowarabic",
                1573,
                "alefhamzabelowfinalarabic",
                65160,
                "alefhebrew",
                1488,
                "aleflamedhebrew",
                64335,
                "alefmaddaabovearabic",
                1570,
                "alefmaddaabovefinalarabic",
                65154,
                "alefmaksuraarabic",
                1609,
                "alefmaksurafinalarabic",
                65264,
                "alefmaksurainitialarabic",
                65267,
                "alefmaksuramedialarabic",
                65268,
                "alefpatahhebrew",
                64302,
                "alefqamatshebrew",
                64303,
                "aleph",
                8501,
                "allequal",
                8780,
                "alpha",
                945,
                "alphatonos",
                940,
                "amacron",
                257,
                "amonospace",
                65345,
                "ampersand",
                38,
                "ampersandmonospace",
                65286,
                "ampersandsmall",
                63270,
                "amsquare",
                13250,
                "anbopomofo",
                12578,
                "angbopomofo",
                12580,
                "angbracketleft",
                12296,
                "angbracketright",
                12297,
                "angkhankhuthai",
                3674,
                "angle",
                8736,
                "anglebracketleft",
                12296,
                "anglebracketleftvertical",
                65087,
                "anglebracketright",
                12297,
                "anglebracketrightvertical",
                65088,
                "angleleft",
                9001,
                "angleright",
                9002,
                "angstrom",
                8491,
                "anoteleia",
                903,
                "anudattadeva",
                2386,
                "anusvarabengali",
                2434,
                "anusvaradeva",
                2306,
                "anusvaragujarati",
                2690,
                "aogonek",
                261,
                "apaatosquare",
                13056,
                "aparen",
                9372,
                "apostrophearmenian",
                1370,
                "apostrophemod",
                700,
                "apple",
                63743,
                "approaches",
                8784,
                "approxequal",
                8776,
                "approxequalorimage",
                8786,
                "approximatelyequal",
                8773,
                "araeaekorean",
                12686,
                "araeakorean",
                12685,
                "arc",
                8978,
                "arighthalfring",
                7834,
                "aring",
                229,
                "aringacute",
                507,
                "aringbelow",
                7681,
                "arrowboth",
                8596,
                "arrowdashdown",
                8675,
                "arrowdashleft",
                8672,
                "arrowdashright",
                8674,
                "arrowdashup",
                8673,
                "arrowdblboth",
                8660,
                "arrowdbldown",
                8659,
                "arrowdblleft",
                8656,
                "arrowdblright",
                8658,
                "arrowdblup",
                8657,
                "arrowdown",
                8595,
                "arrowdownleft",
                8601,
                "arrowdownright",
                8600,
                "arrowdownwhite",
                8681,
                "arrowheaddownmod",
                709,
                "arrowheadleftmod",
                706,
                "arrowheadrightmod",
                707,
                "arrowheadupmod",
                708,
                "arrowhorizex",
                63719,
                "arrowleft",
                8592,
                "arrowleftdbl",
                8656,
                "arrowleftdblstroke",
                8653,
                "arrowleftoverright",
                8646,
                "arrowleftwhite",
                8678,
                "arrowright",
                8594,
                "arrowrightdblstroke",
                8655,
                "arrowrightheavy",
                10142,
                "arrowrightoverleft",
                8644,
                "arrowrightwhite",
                8680,
                "arrowtableft",
                8676,
                "arrowtabright",
                8677,
                "arrowup",
                8593,
                "arrowupdn",
                8597,
                "arrowupdnbse",
                8616,
                "arrowupdownbase",
                8616,
                "arrowupleft",
                8598,
                "arrowupleftofdown",
                8645,
                "arrowupright",
                8599,
                "arrowupwhite",
                8679,
                "arrowvertex",
                63718,
                "asciicircum",
                94,
                "asciicircummonospace",
                65342,
                "asciitilde",
                126,
                "asciitildemonospace",
                65374,
                "ascript",
                593,
                "ascriptturned",
                594,
                "asmallhiragana",
                12353,
                "asmallkatakana",
                12449,
                "asmallkatakanahalfwidth",
                65383,
                "asterisk",
                42,
                "asteriskaltonearabic",
                1645,
                "asteriskarabic",
                1645,
                "asteriskmath",
                8727,
                "asteriskmonospace",
                65290,
                "asterisksmall",
                65121,
                "asterism",
                8258,
                "asuperior",
                63209,
                "asymptoticallyequal",
                8771,
                "at",
                64,
                "atilde",
                227,
                "atmonospace",
                65312,
                "atsmall",
                65131,
                "aturned",
                592,
                "aubengali",
                2452,
                "aubopomofo",
                12576,
                "audeva",
                2324,
                "augujarati",
                2708,
                "augurmukhi",
                2580,
                "aulengthmarkbengali",
                2519,
                "aumatragurmukhi",
                2636,
                "auvowelsignbengali",
                2508,
                "auvowelsigndeva",
                2380,
                "auvowelsigngujarati",
                2764,
                "avagrahadeva",
                2365,
                "aybarmenian",
                1377,
                "ayin",
                1506,
                "ayinaltonehebrew",
                64288,
                "ayinhebrew",
                1506,
                "b",
                98,
                "babengali",
                2476,
                "backslash",
                92,
                "backslashmonospace",
                65340,
                "badeva",
                2348,
                "bagujarati",
                2732,
                "bagurmukhi",
                2604,
                "bahiragana",
                12400,
                "bahtthai",
                3647,
                "bakatakana",
                12496,
                "bar",
                124,
                "barmonospace",
                65372,
                "bbopomofo",
                12549,
                "bcircle",
                9425,
                "bdotaccent",
                7683,
                "bdotbelow",
                7685,
                "beamedsixteenthnotes",
                9836,
                "because",
                8757,
                "becyrillic",
                1073,
                "beharabic",
                1576,
                "behfinalarabic",
                65168,
                "behinitialarabic",
                65169,
                "behiragana",
                12409,
                "behmedialarabic",
                65170,
                "behmeeminitialarabic",
                64671,
                "behmeemisolatedarabic",
                64520,
                "behnoonfinalarabic",
                64621,
                "bekatakana",
                12505,
                "benarmenian",
                1378,
                "bet",
                1489,
                "beta",
                946,
                "betasymbolgreek",
                976,
                "betdagesh",
                64305,
                "betdageshhebrew",
                64305,
                "bethebrew",
                1489,
                "betrafehebrew",
                64332,
                "bhabengali",
                2477,
                "bhadeva",
                2349,
                "bhagujarati",
                2733,
                "bhagurmukhi",
                2605,
                "bhook",
                595,
                "bihiragana",
                12403,
                "bikatakana",
                12499,
                "bilabialclick",
                664,
                "bindigurmukhi",
                2562,
                "birusquare",
                13105,
                "blackcircle",
                9679,
                "blackdiamond",
                9670,
                "blackdownpointingtriangle",
                9660,
                "blackleftpointingpointer",
                9668,
                "blackleftpointingtriangle",
                9664,
                "blacklenticularbracketleft",
                12304,
                "blacklenticularbracketleftvertical",
                65083,
                "blacklenticularbracketright",
                12305,
                "blacklenticularbracketrightvertical",
                65084,
                "blacklowerlefttriangle",
                9699,
                "blacklowerrighttriangle",
                9698,
                "blackrectangle",
                9644,
                "blackrightpointingpointer",
                9658,
                "blackrightpointingtriangle",
                9654,
                "blacksmallsquare",
                9642,
                "blacksmilingface",
                9787,
                "blacksquare",
                9632,
                "blackstar",
                9733,
                "blackupperlefttriangle",
                9700,
                "blackupperrighttriangle",
                9701,
                "blackuppointingsmalltriangle",
                9652,
                "blackuppointingtriangle",
                9650,
                "blank",
                9251,
                "blinebelow",
                7687,
                "block",
                9608,
                "bmonospace",
                65346,
                "bobaimaithai",
                3610,
                "bohiragana",
                12412,
                "bokatakana",
                12508,
                "bparen",
                9373,
                "bqsquare",
                13251,
                "braceex",
                63732,
                "braceleft",
                123,
                "braceleftbt",
                63731,
                "braceleftmid",
                63730,
                "braceleftmonospace",
                65371,
                "braceleftsmall",
                65115,
                "bracelefttp",
                63729,
                "braceleftvertical",
                65079,
                "braceright",
                125,
                "bracerightbt",
                63742,
                "bracerightmid",
                63741,
                "bracerightmonospace",
                65373,
                "bracerightsmall",
                65116,
                "bracerighttp",
                63740,
                "bracerightvertical",
                65080,
                "bracketleft",
                91,
                "bracketleftbt",
                63728,
                "bracketleftex",
                63727,
                "bracketleftmonospace",
                65339,
                "bracketlefttp",
                63726,
                "bracketright",
                93,
                "bracketrightbt",
                63739,
                "bracketrightex",
                63738,
                "bracketrightmonospace",
                65341,
                "bracketrighttp",
                63737,
                "breve",
                728,
                "brevebelowcmb",
                814,
                "brevecmb",
                774,
                "breveinvertedbelowcmb",
                815,
                "breveinvertedcmb",
                785,
                "breveinverteddoublecmb",
                865,
                "bridgebelowcmb",
                810,
                "bridgeinvertedbelowcmb",
                826,
                "brokenbar",
                166,
                "bstroke",
                384,
                "bsuperior",
                63210,
                "btopbar",
                387,
                "buhiragana",
                12406,
                "bukatakana",
                12502,
                "bullet",
                8226,
                "bulletinverse",
                9688,
                "bulletoperator",
                8729,
                "bullseye",
                9678,
                "c",
                99,
                "caarmenian",
                1390,
                "cabengali",
                2458,
                "cacute",
                263,
                "cadeva",
                2330,
                "cagujarati",
                2714,
                "cagurmukhi",
                2586,
                "calsquare",
                13192,
                "candrabindubengali",
                2433,
                "candrabinducmb",
                784,
                "candrabindudeva",
                2305,
                "candrabindugujarati",
                2689,
                "capslock",
                8682,
                "careof",
                8453,
                "caron",
                711,
                "caronbelowcmb",
                812,
                "caroncmb",
                780,
                "carriagereturn",
                8629,
                "cbopomofo",
                12568,
                "ccaron",
                269,
                "ccedilla",
                231,
                "ccedillaacute",
                7689,
                "ccircle",
                9426,
                "ccircumflex",
                265,
                "ccurl",
                597,
                "cdot",
                267,
                "cdotaccent",
                267,
                "cdsquare",
                13253,
                "cedilla",
                184,
                "cedillacmb",
                807,
                "cent",
                162,
                "centigrade",
                8451,
                "centinferior",
                63199,
                "centmonospace",
                65504,
                "centoldstyle",
                63394,
                "centsuperior",
                63200,
                "chaarmenian",
                1401,
                "chabengali",
                2459,
                "chadeva",
                2331,
                "chagujarati",
                2715,
                "chagurmukhi",
                2587,
                "chbopomofo",
                12564,
                "cheabkhasiancyrillic",
                1213,
                "checkmark",
                10003,
                "checyrillic",
                1095,
                "chedescenderabkhasiancyrillic",
                1215,
                "chedescendercyrillic",
                1207,
                "chedieresiscyrillic",
                1269,
                "cheharmenian",
                1395,
                "chekhakassiancyrillic",
                1228,
                "cheverticalstrokecyrillic",
                1209,
                "chi",
                967,
                "chieuchacirclekorean",
                12919,
                "chieuchaparenkorean",
                12823,
                "chieuchcirclekorean",
                12905,
                "chieuchkorean",
                12618,
                "chieuchparenkorean",
                12809,
                "chochangthai",
                3594,
                "chochanthai",
                3592,
                "chochingthai",
                3593,
                "chochoethai",
                3596,
                "chook",
                392,
                "cieucacirclekorean",
                12918,
                "cieucaparenkorean",
                12822,
                "cieuccirclekorean",
                12904,
                "cieuckorean",
                12616,
                "cieucparenkorean",
                12808,
                "cieucuparenkorean",
                12828,
                "circle",
                9675,
                "circlecopyrt",
                169,
                "circlemultiply",
                8855,
                "circleot",
                8857,
                "circleplus",
                8853,
                "circlepostalmark",
                12342,
                "circlewithlefthalfblack",
                9680,
                "circlewithrighthalfblack",
                9681,
                "circumflex",
                710,
                "circumflexbelowcmb",
                813,
                "circumflexcmb",
                770,
                "clear",
                8999,
                "clickalveolar",
                450,
                "clickdental",
                448,
                "clicklateral",
                449,
                "clickretroflex",
                451,
                "club",
                9827,
                "clubsuitblack",
                9827,
                "clubsuitwhite",
                9831,
                "cmcubedsquare",
                13220,
                "cmonospace",
                65347,
                "cmsquaredsquare",
                13216,
                "coarmenian",
                1409,
                "colon",
                58,
                "colonmonetary",
                8353,
                "colonmonospace",
                65306,
                "colonsign",
                8353,
                "colonsmall",
                65109,
                "colontriangularhalfmod",
                721,
                "colontriangularmod",
                720,
                "comma",
                44,
                "commaabovecmb",
                787,
                "commaaboverightcmb",
                789,
                "commaaccent",
                63171,
                "commaarabic",
                1548,
                "commaarmenian",
                1373,
                "commainferior",
                63201,
                "commamonospace",
                65292,
                "commareversedabovecmb",
                788,
                "commareversedmod",
                701,
                "commasmall",
                65104,
                "commasuperior",
                63202,
                "commaturnedabovecmb",
                786,
                "commaturnedmod",
                699,
                "compass",
                9788,
                "congruent",
                8773,
                "contourintegral",
                8750,
                "control",
                8963,
                "controlACK",
                6,
                "controlBEL",
                7,
                "controlBS",
                8,
                "controlCAN",
                24,
                "controlCR",
                13,
                "controlDC1",
                17,
                "controlDC2",
                18,
                "controlDC3",
                19,
                "controlDC4",
                20,
                "controlDEL",
                127,
                "controlDLE",
                16,
                "controlEM",
                25,
                "controlENQ",
                5,
                "controlEOT",
                4,
                "controlESC",
                27,
                "controlETB",
                23,
                "controlETX",
                3,
                "controlFF",
                12,
                "controlFS",
                28,
                "controlGS",
                29,
                "controlHT",
                9,
                "controlLF",
                10,
                "controlNAK",
                21,
                "controlNULL",
                0,
                "controlRS",
                30,
                "controlSI",
                15,
                "controlSO",
                14,
                "controlSOT",
                2,
                "controlSTX",
                1,
                "controlSUB",
                26,
                "controlSYN",
                22,
                "controlUS",
                31,
                "controlVT",
                11,
                "copyright",
                169,
                "copyrightsans",
                63721,
                "copyrightserif",
                63193,
                "cornerbracketleft",
                12300,
                "cornerbracketlefthalfwidth",
                65378,
                "cornerbracketleftvertical",
                65089,
                "cornerbracketright",
                12301,
                "cornerbracketrighthalfwidth",
                65379,
                "cornerbracketrightvertical",
                65090,
                "corporationsquare",
                13183,
                "cosquare",
                13255,
                "coverkgsquare",
                13254,
                "cparen",
                9374,
                "cruzeiro",
                8354,
                "cstretched",
                663,
                "curlyand",
                8911,
                "curlyor",
                8910,
                "currency",
                164,
                "cyrBreve",
                63185,
                "cyrFlex",
                63186,
                "cyrbreve",
                63188,
                "cyrflex",
                63189,
                "d",
                100,
                "daarmenian",
                1380,
                "dabengali",
                2470,
                "dadarabic",
                1590,
                "dadeva",
                2342,
                "dadfinalarabic",
                65214,
                "dadinitialarabic",
                65215,
                "dadmedialarabic",
                65216,
                "dagesh",
                1468,
                "dageshhebrew",
                1468,
                "dagger",
                8224,
                "daggerdbl",
                8225,
                "dagujarati",
                2726,
                "dagurmukhi",
                2598,
                "dahiragana",
                12384,
                "dakatakana",
                12480,
                "dalarabic",
                1583,
                "dalet",
                1491,
                "daletdagesh",
                64307,
                "daletdageshhebrew",
                64307,
                "dalethebrew",
                1491,
                "dalfinalarabic",
                65194,
                "dammaarabic",
                1615,
                "dammalowarabic",
                1615,
                "dammatanaltonearabic",
                1612,
                "dammatanarabic",
                1612,
                "danda",
                2404,
                "dargahebrew",
                1447,
                "dargalefthebrew",
                1447,
                "dasiapneumatacyrilliccmb",
                1157,
                "dblGrave",
                63187,
                "dblanglebracketleft",
                12298,
                "dblanglebracketleftvertical",
                65085,
                "dblanglebracketright",
                12299,
                "dblanglebracketrightvertical",
                65086,
                "dblarchinvertedbelowcmb",
                811,
                "dblarrowleft",
                8660,
                "dblarrowright",
                8658,
                "dbldanda",
                2405,
                "dblgrave",
                63190,
                "dblgravecmb",
                783,
                "dblintegral",
                8748,
                "dbllowline",
                8215,
                "dbllowlinecmb",
                819,
                "dbloverlinecmb",
                831,
                "dblprimemod",
                698,
                "dblverticalbar",
                8214,
                "dblverticallineabovecmb",
                782,
                "dbopomofo",
                12553,
                "dbsquare",
                13256,
                "dcaron",
                271,
                "dcedilla",
                7697,
                "dcircle",
                9427,
                "dcircumflexbelow",
                7699,
                "dcroat",
                273,
                "ddabengali",
                2465,
                "ddadeva",
                2337,
                "ddagujarati",
                2721,
                "ddagurmukhi",
                2593,
                "ddalarabic",
                1672,
                "ddalfinalarabic",
                64393,
                "dddhadeva",
                2396,
                "ddhabengali",
                2466,
                "ddhadeva",
                2338,
                "ddhagujarati",
                2722,
                "ddhagurmukhi",
                2594,
                "ddotaccent",
                7691,
                "ddotbelow",
                7693,
                "decimalseparatorarabic",
                1643,
                "decimalseparatorpersian",
                1643,
                "decyrillic",
                1076,
                "degree",
                176,
                "dehihebrew",
                1453,
                "dehiragana",
                12391,
                "deicoptic",
                1007,
                "dekatakana",
                12487,
                "deleteleft",
                9003,
                "deleteright",
                8998,
                "delta",
                948,
                "deltaturned",
                397,
                "denominatorminusonenumeratorbengali",
                2552,
                "dezh",
                676,
                "dhabengali",
                2471,
                "dhadeva",
                2343,
                "dhagujarati",
                2727,
                "dhagurmukhi",
                2599,
                "dhook",
                599,
                "dialytikatonos",
                901,
                "dialytikatonoscmb",
                836,
                "diamond",
                9830,
                "diamondsuitwhite",
                9826,
                "dieresis",
                168,
                "dieresisacute",
                63191,
                "dieresisbelowcmb",
                804,
                "dieresiscmb",
                776,
                "dieresisgrave",
                63192,
                "dieresistonos",
                901,
                "dihiragana",
                12386,
                "dikatakana",
                12482,
                "dittomark",
                12291,
                "divide",
                247,
                "divides",
                8739,
                "divisionslash",
                8725,
                "djecyrillic",
                1106,
                "dkshade",
                9619,
                "dlinebelow",
                7695,
                "dlsquare",
                13207,
                "dmacron",
                273,
                "dmonospace",
                65348,
                "dnblock",
                9604,
                "dochadathai",
                3598,
                "dodekthai",
                3604,
                "dohiragana",
                12393,
                "dokatakana",
                12489,
                "dollar",
                36,
                "dollarinferior",
                63203,
                "dollarmonospace",
                65284,
                "dollaroldstyle",
                63268,
                "dollarsmall",
                65129,
                "dollarsuperior",
                63204,
                "dong",
                8363,
                "dorusquare",
                13094,
                "dotaccent",
                729,
                "dotaccentcmb",
                775,
                "dotbelowcmb",
                803,
                "dotbelowcomb",
                803,
                "dotkatakana",
                12539,
                "dotlessi",
                305,
                "dotlessj",
                63166,
                "dotlessjstrokehook",
                644,
                "dotmath",
                8901,
                "dottedcircle",
                9676,
                "doubleyodpatah",
                64287,
                "doubleyodpatahhebrew",
                64287,
                "downtackbelowcmb",
                798,
                "downtackmod",
                725,
                "dparen",
                9375,
                "dsuperior",
                63211,
                "dtail",
                598,
                "dtopbar",
                396,
                "duhiragana",
                12389,
                "dukatakana",
                12485,
                "dz",
                499,
                "dzaltone",
                675,
                "dzcaron",
                454,
                "dzcurl",
                677,
                "dzeabkhasiancyrillic",
                1249,
                "dzecyrillic",
                1109,
                "dzhecyrillic",
                1119,
                "e",
                101,
                "eacute",
                233,
                "earth",
                9793,
                "ebengali",
                2447,
                "ebopomofo",
                12572,
                "ebreve",
                277,
                "ecandradeva",
                2317,
                "ecandragujarati",
                2701,
                "ecandravowelsigndeva",
                2373,
                "ecandravowelsigngujarati",
                2757,
                "ecaron",
                283,
                "ecedillabreve",
                7709,
                "echarmenian",
                1381,
                "echyiwnarmenian",
                1415,
                "ecircle",
                9428,
                "ecircumflex",
                234,
                "ecircumflexacute",
                7871,
                "ecircumflexbelow",
                7705,
                "ecircumflexdotbelow",
                7879,
                "ecircumflexgrave",
                7873,
                "ecircumflexhookabove",
                7875,
                "ecircumflextilde",
                7877,
                "ecyrillic",
                1108,
                "edblgrave",
                517,
                "edeva",
                2319,
                "edieresis",
                235,
                "edot",
                279,
                "edotaccent",
                279,
                "edotbelow",
                7865,
                "eegurmukhi",
                2575,
                "eematragurmukhi",
                2631,
                "efcyrillic",
                1092,
                "egrave",
                232,
                "egujarati",
                2703,
                "eharmenian",
                1383,
                "ehbopomofo",
                12573,
                "ehiragana",
                12360,
                "ehookabove",
                7867,
                "eibopomofo",
                12575,
                "eight",
                56,
                "eightarabic",
                1640,
                "eightbengali",
                2542,
                "eightcircle",
                9319,
                "eightcircleinversesansserif",
                10129,
                "eightdeva",
                2414,
                "eighteencircle",
                9329,
                "eighteenparen",
                9349,
                "eighteenperiod",
                9369,
                "eightgujarati",
                2798,
                "eightgurmukhi",
                2670,
                "eighthackarabic",
                1640,
                "eighthangzhou",
                12328,
                "eighthnotebeamed",
                9835,
                "eightideographicparen",
                12839,
                "eightinferior",
                8328,
                "eightmonospace",
                65304,
                "eightoldstyle",
                63288,
                "eightparen",
                9339,
                "eightperiod",
                9359,
                "eightpersian",
                1784,
                "eightroman",
                8567,
                "eightsuperior",
                8312,
                "eightthai",
                3672,
                "einvertedbreve",
                519,
                "eiotifiedcyrillic",
                1125,
                "ekatakana",
                12456,
                "ekatakanahalfwidth",
                65396,
                "ekonkargurmukhi",
                2676,
                "ekorean",
                12628,
                "elcyrillic",
                1083,
                "element",
                8712,
                "elevencircle",
                9322,
                "elevenparen",
                9342,
                "elevenperiod",
                9362,
                "elevenroman",
                8570,
                "ellipsis",
                8230,
                "ellipsisvertical",
                8942,
                "emacron",
                275,
                "emacronacute",
                7703,
                "emacrongrave",
                7701,
                "emcyrillic",
                1084,
                "emdash",
                8212,
                "emdashvertical",
                65073,
                "emonospace",
                65349,
                "emphasismarkarmenian",
                1371,
                "emptyset",
                8709,
                "enbopomofo",
                12579,
                "encyrillic",
                1085,
                "endash",
                8211,
                "endashvertical",
                65074,
                "endescendercyrillic",
                1187,
                "eng",
                331,
                "engbopomofo",
                12581,
                "enghecyrillic",
                1189,
                "enhookcyrillic",
                1224,
                "enspace",
                8194,
                "eogonek",
                281,
                "eokorean",
                12627,
                "eopen",
                603,
                "eopenclosed",
                666,
                "eopenreversed",
                604,
                "eopenreversedclosed",
                606,
                "eopenreversedhook",
                605,
                "eparen",
                9376,
                "epsilon",
                949,
                "epsilontonos",
                941,
                "equal",
                61,
                "equalmonospace",
                65309,
                "equalsmall",
                65126,
                "equalsuperior",
                8316,
                "equivalence",
                8801,
                "erbopomofo",
                12582,
                "ercyrillic",
                1088,
                "ereversed",
                600,
                "ereversedcyrillic",
                1101,
                "escyrillic",
                1089,
                "esdescendercyrillic",
                1195,
                "esh",
                643,
                "eshcurl",
                646,
                "eshortdeva",
                2318,
                "eshortvowelsigndeva",
                2374,
                "eshreversedloop",
                426,
                "eshsquatreversed",
                645,
                "esmallhiragana",
                12359,
                "esmallkatakana",
                12455,
                "esmallkatakanahalfwidth",
                65386,
                "estimated",
                8494,
                "esuperior",
                63212,
                "eta",
                951,
                "etarmenian",
                1384,
                "etatonos",
                942,
                "eth",
                240,
                "etilde",
                7869,
                "etildebelow",
                7707,
                "etnahtafoukhhebrew",
                1425,
                "etnahtafoukhlefthebrew",
                1425,
                "etnahtahebrew",
                1425,
                "etnahtalefthebrew",
                1425,
                "eturned",
                477,
                "eukorean",
                12641,
                "euro",
                8364,
                "evowelsignbengali",
                2503,
                "evowelsigndeva",
                2375,
                "evowelsigngujarati",
                2759,
                "exclam",
                33,
                "exclamarmenian",
                1372,
                "exclamdbl",
                8252,
                "exclamdown",
                161,
                "exclamdownsmall",
                63393,
                "exclammonospace",
                65281,
                "exclamsmall",
                63265,
                "existential",
                8707,
                "ezh",
                658,
                "ezhcaron",
                495,
                "ezhcurl",
                659,
                "ezhreversed",
                441,
                "ezhtail",
                442,
                "f",
                102,
                "fadeva",
                2398,
                "fagurmukhi",
                2654,
                "fahrenheit",
                8457,
                "fathaarabic",
                1614,
                "fathalowarabic",
                1614,
                "fathatanarabic",
                1611,
                "fbopomofo",
                12552,
                "fcircle",
                9429,
                "fdotaccent",
                7711,
                "feharabic",
                1601,
                "feharmenian",
                1414,
                "fehfinalarabic",
                65234,
                "fehinitialarabic",
                65235,
                "fehmedialarabic",
                65236,
                "feicoptic",
                997,
                "female",
                9792,
                "ff",
                64256,
                "f_f",
                64256,
                "ffi",
                64259,
                "f_f_i",
                64259,
                "ffl",
                64260,
                "f_f_l",
                64260,
                "fi",
                64257,
                "f_i",
                64257,
                "fifteencircle",
                9326,
                "fifteenparen",
                9346,
                "fifteenperiod",
                9366,
                "figuredash",
                8210,
                "filledbox",
                9632,
                "filledrect",
                9644,
                "finalkaf",
                1498,
                "finalkafdagesh",
                64314,
                "finalkafdageshhebrew",
                64314,
                "finalkafhebrew",
                1498,
                "finalmem",
                1501,
                "finalmemhebrew",
                1501,
                "finalnun",
                1503,
                "finalnunhebrew",
                1503,
                "finalpe",
                1507,
                "finalpehebrew",
                1507,
                "finaltsadi",
                1509,
                "finaltsadihebrew",
                1509,
                "firsttonechinese",
                713,
                "fisheye",
                9673,
                "fitacyrillic",
                1139,
                "five",
                53,
                "fivearabic",
                1637,
                "fivebengali",
                2539,
                "fivecircle",
                9316,
                "fivecircleinversesansserif",
                10126,
                "fivedeva",
                2411,
                "fiveeighths",
                8541,
                "fivegujarati",
                2795,
                "fivegurmukhi",
                2667,
                "fivehackarabic",
                1637,
                "fivehangzhou",
                12325,
                "fiveideographicparen",
                12836,
                "fiveinferior",
                8325,
                "fivemonospace",
                65301,
                "fiveoldstyle",
                63285,
                "fiveparen",
                9336,
                "fiveperiod",
                9356,
                "fivepersian",
                1781,
                "fiveroman",
                8564,
                "fivesuperior",
                8309,
                "fivethai",
                3669,
                "fl",
                64258,
                "f_l",
                64258,
                "florin",
                402,
                "fmonospace",
                65350,
                "fmsquare",
                13209,
                "fofanthai",
                3615,
                "fofathai",
                3613,
                "fongmanthai",
                3663,
                "forall",
                8704,
                "four",
                52,
                "fourarabic",
                1636,
                "fourbengali",
                2538,
                "fourcircle",
                9315,
                "fourcircleinversesansserif",
                10125,
                "fourdeva",
                2410,
                "fourgujarati",
                2794,
                "fourgurmukhi",
                2666,
                "fourhackarabic",
                1636,
                "fourhangzhou",
                12324,
                "fourideographicparen",
                12835,
                "fourinferior",
                8324,
                "fourmonospace",
                65300,
                "fournumeratorbengali",
                2551,
                "fouroldstyle",
                63284,
                "fourparen",
                9335,
                "fourperiod",
                9355,
                "fourpersian",
                1780,
                "fourroman",
                8563,
                "foursuperior",
                8308,
                "fourteencircle",
                9325,
                "fourteenparen",
                9345,
                "fourteenperiod",
                9365,
                "fourthai",
                3668,
                "fourthtonechinese",
                715,
                "fparen",
                9377,
                "fraction",
                8260,
                "franc",
                8355,
                "g",
                103,
                "gabengali",
                2455,
                "gacute",
                501,
                "gadeva",
                2327,
                "gafarabic",
                1711,
                "gaffinalarabic",
                64403,
                "gafinitialarabic",
                64404,
                "gafmedialarabic",
                64405,
                "gagujarati",
                2711,
                "gagurmukhi",
                2583,
                "gahiragana",
                12364,
                "gakatakana",
                12460,
                "gamma",
                947,
                "gammalatinsmall",
                611,
                "gammasuperior",
                736,
                "gangiacoptic",
                1003,
                "gbopomofo",
                12557,
                "gbreve",
                287,
                "gcaron",
                487,
                "gcedilla",
                291,
                "gcircle",
                9430,
                "gcircumflex",
                285,
                "gcommaaccent",
                291,
                "gdot",
                289,
                "gdotaccent",
                289,
                "gecyrillic",
                1075,
                "gehiragana",
                12370,
                "gekatakana",
                12466,
                "geometricallyequal",
                8785,
                "gereshaccenthebrew",
                1436,
                "gereshhebrew",
                1523,
                "gereshmuqdamhebrew",
                1437,
                "germandbls",
                223,
                "gershayimaccenthebrew",
                1438,
                "gershayimhebrew",
                1524,
                "getamark",
                12307,
                "ghabengali",
                2456,
                "ghadarmenian",
                1394,
                "ghadeva",
                2328,
                "ghagujarati",
                2712,
                "ghagurmukhi",
                2584,
                "ghainarabic",
                1594,
                "ghainfinalarabic",
                65230,
                "ghaininitialarabic",
                65231,
                "ghainmedialarabic",
                65232,
                "ghemiddlehookcyrillic",
                1173,
                "ghestrokecyrillic",
                1171,
                "gheupturncyrillic",
                1169,
                "ghhadeva",
                2394,
                "ghhagurmukhi",
                2650,
                "ghook",
                608,
                "ghzsquare",
                13203,
                "gihiragana",
                12366,
                "gikatakana",
                12462,
                "gimarmenian",
                1379,
                "gimel",
                1490,
                "gimeldagesh",
                64306,
                "gimeldageshhebrew",
                64306,
                "gimelhebrew",
                1490,
                "gjecyrillic",
                1107,
                "glottalinvertedstroke",
                446,
                "glottalstop",
                660,
                "glottalstopinverted",
                662,
                "glottalstopmod",
                704,
                "glottalstopreversed",
                661,
                "glottalstopreversedmod",
                705,
                "glottalstopreversedsuperior",
                740,
                "glottalstopstroke",
                673,
                "glottalstopstrokereversed",
                674,
                "gmacron",
                7713,
                "gmonospace",
                65351,
                "gohiragana",
                12372,
                "gokatakana",
                12468,
                "gparen",
                9378,
                "gpasquare",
                13228,
                "gradient",
                8711,
                "grave",
                96,
                "gravebelowcmb",
                790,
                "gravecmb",
                768,
                "gravecomb",
                768,
                "gravedeva",
                2387,
                "gravelowmod",
                718,
                "gravemonospace",
                65344,
                "gravetonecmb",
                832,
                "greater",
                62,
                "greaterequal",
                8805,
                "greaterequalorless",
                8923,
                "greatermonospace",
                65310,
                "greaterorequivalent",
                8819,
                "greaterorless",
                8823,
                "greateroverequal",
                8807,
                "greatersmall",
                65125,
                "gscript",
                609,
                "gstroke",
                485,
                "guhiragana",
                12368,
                "guillemotleft",
                171,
                "guillemotright",
                187,
                "guilsinglleft",
                8249,
                "guilsinglright",
                8250,
                "gukatakana",
                12464,
                "guramusquare",
                13080,
                "gysquare",
                13257,
                "h",
                104,
                "haabkhasiancyrillic",
                1193,
                "haaltonearabic",
                1729,
                "habengali",
                2489,
                "hadescendercyrillic",
                1203,
                "hadeva",
                2361,
                "hagujarati",
                2745,
                "hagurmukhi",
                2617,
                "haharabic",
                1581,
                "hahfinalarabic",
                65186,
                "hahinitialarabic",
                65187,
                "hahiragana",
                12399,
                "hahmedialarabic",
                65188,
                "haitusquare",
                13098,
                "hakatakana",
                12495,
                "hakatakanahalfwidth",
                65418,
                "halantgurmukhi",
                2637,
                "hamzaarabic",
                1569,
                "hamzalowarabic",
                1569,
                "hangulfiller",
                12644,
                "hardsigncyrillic",
                1098,
                "harpoonleftbarbup",
                8636,
                "harpoonrightbarbup",
                8640,
                "hasquare",
                13258,
                "hatafpatah",
                1458,
                "hatafpatah16",
                1458,
                "hatafpatah23",
                1458,
                "hatafpatah2f",
                1458,
                "hatafpatahhebrew",
                1458,
                "hatafpatahnarrowhebrew",
                1458,
                "hatafpatahquarterhebrew",
                1458,
                "hatafpatahwidehebrew",
                1458,
                "hatafqamats",
                1459,
                "hatafqamats1b",
                1459,
                "hatafqamats28",
                1459,
                "hatafqamats34",
                1459,
                "hatafqamatshebrew",
                1459,
                "hatafqamatsnarrowhebrew",
                1459,
                "hatafqamatsquarterhebrew",
                1459,
                "hatafqamatswidehebrew",
                1459,
                "hatafsegol",
                1457,
                "hatafsegol17",
                1457,
                "hatafsegol24",
                1457,
                "hatafsegol30",
                1457,
                "hatafsegolhebrew",
                1457,
                "hatafsegolnarrowhebrew",
                1457,
                "hatafsegolquarterhebrew",
                1457,
                "hatafsegolwidehebrew",
                1457,
                "hbar",
                295,
                "hbopomofo",
                12559,
                "hbrevebelow",
                7723,
                "hcedilla",
                7721,
                "hcircle",
                9431,
                "hcircumflex",
                293,
                "hdieresis",
                7719,
                "hdotaccent",
                7715,
                "hdotbelow",
                7717,
                "he",
                1492,
                "heart",
                9829,
                "heartsuitblack",
                9829,
                "heartsuitwhite",
                9825,
                "hedagesh",
                64308,
                "hedageshhebrew",
                64308,
                "hehaltonearabic",
                1729,
                "heharabic",
                1607,
                "hehebrew",
                1492,
                "hehfinalaltonearabic",
                64423,
                "hehfinalalttwoarabic",
                65258,
                "hehfinalarabic",
                65258,
                "hehhamzaabovefinalarabic",
                64421,
                "hehhamzaaboveisolatedarabic",
                64420,
                "hehinitialaltonearabic",
                64424,
                "hehinitialarabic",
                65259,
                "hehiragana",
                12408,
                "hehmedialaltonearabic",
                64425,
                "hehmedialarabic",
                65260,
                "heiseierasquare",
                13179,
                "hekatakana",
                12504,
                "hekatakanahalfwidth",
                65421,
                "hekutaarusquare",
                13110,
                "henghook",
                615,
                "herutusquare",
                13113,
                "het",
                1495,
                "hethebrew",
                1495,
                "hhook",
                614,
                "hhooksuperior",
                689,
                "hieuhacirclekorean",
                12923,
                "hieuhaparenkorean",
                12827,
                "hieuhcirclekorean",
                12909,
                "hieuhkorean",
                12622,
                "hieuhparenkorean",
                12813,
                "hihiragana",
                12402,
                "hikatakana",
                12498,
                "hikatakanahalfwidth",
                65419,
                "hiriq",
                1460,
                "hiriq14",
                1460,
                "hiriq21",
                1460,
                "hiriq2d",
                1460,
                "hiriqhebrew",
                1460,
                "hiriqnarrowhebrew",
                1460,
                "hiriqquarterhebrew",
                1460,
                "hiriqwidehebrew",
                1460,
                "hlinebelow",
                7830,
                "hmonospace",
                65352,
                "hoarmenian",
                1392,
                "hohipthai",
                3627,
                "hohiragana",
                12411,
                "hokatakana",
                12507,
                "hokatakanahalfwidth",
                65422,
                "holam",
                1465,
                "holam19",
                1465,
                "holam26",
                1465,
                "holam32",
                1465,
                "holamhebrew",
                1465,
                "holamnarrowhebrew",
                1465,
                "holamquarterhebrew",
                1465,
                "holamwidehebrew",
                1465,
                "honokhukthai",
                3630,
                "hookabovecomb",
                777,
                "hookcmb",
                777,
                "hookpalatalizedbelowcmb",
                801,
                "hookretroflexbelowcmb",
                802,
                "hoonsquare",
                13122,
                "horicoptic",
                1001,
                "horizontalbar",
                8213,
                "horncmb",
                795,
                "hotsprings",
                9832,
                "house",
                8962,
                "hparen",
                9379,
                "hsuperior",
                688,
                "hturned",
                613,
                "huhiragana",
                12405,
                "huiitosquare",
                13107,
                "hukatakana",
                12501,
                "hukatakanahalfwidth",
                65420,
                "hungarumlaut",
                733,
                "hungarumlautcmb",
                779,
                "hv",
                405,
                "hyphen",
                45,
                "hypheninferior",
                63205,
                "hyphenmonospace",
                65293,
                "hyphensmall",
                65123,
                "hyphensuperior",
                63206,
                "hyphentwo",
                8208,
                "i",
                105,
                "iacute",
                237,
                "iacyrillic",
                1103,
                "ibengali",
                2439,
                "ibopomofo",
                12583,
                "ibreve",
                301,
                "icaron",
                464,
                "icircle",
                9432,
                "icircumflex",
                238,
                "icyrillic",
                1110,
                "idblgrave",
                521,
                "ideographearthcircle",
                12943,
                "ideographfirecircle",
                12939,
                "ideographicallianceparen",
                12863,
                "ideographiccallparen",
                12858,
                "ideographiccentrecircle",
                12965,
                "ideographicclose",
                12294,
                "ideographiccomma",
                12289,
                "ideographiccommaleft",
                65380,
                "ideographiccongratulationparen",
                12855,
                "ideographiccorrectcircle",
                12963,
                "ideographicearthparen",
                12847,
                "ideographicenterpriseparen",
                12861,
                "ideographicexcellentcircle",
                12957,
                "ideographicfestivalparen",
                12864,
                "ideographicfinancialcircle",
                12950,
                "ideographicfinancialparen",
                12854,
                "ideographicfireparen",
                12843,
                "ideographichaveparen",
                12850,
                "ideographichighcircle",
                12964,
                "ideographiciterationmark",
                12293,
                "ideographiclaborcircle",
                12952,
                "ideographiclaborparen",
                12856,
                "ideographicleftcircle",
                12967,
                "ideographiclowcircle",
                12966,
                "ideographicmedicinecircle",
                12969,
                "ideographicmetalparen",
                12846,
                "ideographicmoonparen",
                12842,
                "ideographicnameparen",
                12852,
                "ideographicperiod",
                12290,
                "ideographicprintcircle",
                12958,
                "ideographicreachparen",
                12867,
                "ideographicrepresentparen",
                12857,
                "ideographicresourceparen",
                12862,
                "ideographicrightcircle",
                12968,
                "ideographicsecretcircle",
                12953,
                "ideographicselfparen",
                12866,
                "ideographicsocietyparen",
                12851,
                "ideographicspace",
                12288,
                "ideographicspecialparen",
                12853,
                "ideographicstockparen",
                12849,
                "ideographicstudyparen",
                12859,
                "ideographicsunparen",
                12848,
                "ideographicsuperviseparen",
                12860,
                "ideographicwaterparen",
                12844,
                "ideographicwoodparen",
                12845,
                "ideographiczero",
                12295,
                "ideographmetalcircle",
                12942,
                "ideographmooncircle",
                12938,
                "ideographnamecircle",
                12948,
                "ideographsuncircle",
                12944,
                "ideographwatercircle",
                12940,
                "ideographwoodcircle",
                12941,
                "ideva",
                2311,
                "idieresis",
                239,
                "idieresisacute",
                7727,
                "idieresiscyrillic",
                1253,
                "idotbelow",
                7883,
                "iebrevecyrillic",
                1239,
                "iecyrillic",
                1077,
                "ieungacirclekorean",
                12917,
                "ieungaparenkorean",
                12821,
                "ieungcirclekorean",
                12903,
                "ieungkorean",
                12615,
                "ieungparenkorean",
                12807,
                "igrave",
                236,
                "igujarati",
                2695,
                "igurmukhi",
                2567,
                "ihiragana",
                12356,
                "ihookabove",
                7881,
                "iibengali",
                2440,
                "iicyrillic",
                1080,
                "iideva",
                2312,
                "iigujarati",
                2696,
                "iigurmukhi",
                2568,
                "iimatragurmukhi",
                2624,
                "iinvertedbreve",
                523,
                "iishortcyrillic",
                1081,
                "iivowelsignbengali",
                2496,
                "iivowelsigndeva",
                2368,
                "iivowelsigngujarati",
                2752,
                "ij",
                307,
                "ikatakana",
                12452,
                "ikatakanahalfwidth",
                65394,
                "ikorean",
                12643,
                "ilde",
                732,
                "iluyhebrew",
                1452,
                "imacron",
                299,
                "imacroncyrillic",
                1251,
                "imageorapproximatelyequal",
                8787,
                "imatragurmukhi",
                2623,
                "imonospace",
                65353,
                "increment",
                8710,
                "infinity",
                8734,
                "iniarmenian",
                1387,
                "integral",
                8747,
                "integralbottom",
                8993,
                "integralbt",
                8993,
                "integralex",
                63733,
                "integraltop",
                8992,
                "integraltp",
                8992,
                "intersection",
                8745,
                "intisquare",
                13061,
                "invbullet",
                9688,
                "invcircle",
                9689,
                "invsmileface",
                9787,
                "iocyrillic",
                1105,
                "iogonek",
                303,
                "iota",
                953,
                "iotadieresis",
                970,
                "iotadieresistonos",
                912,
                "iotalatin",
                617,
                "iotatonos",
                943,
                "iparen",
                9380,
                "irigurmukhi",
                2674,
                "ismallhiragana",
                12355,
                "ismallkatakana",
                12451,
                "ismallkatakanahalfwidth",
                65384,
                "issharbengali",
                2554,
                "istroke",
                616,
                "isuperior",
                63213,
                "iterationhiragana",
                12445,
                "iterationkatakana",
                12541,
                "itilde",
                297,
                "itildebelow",
                7725,
                "iubopomofo",
                12585,
                "iucyrillic",
                1102,
                "ivowelsignbengali",
                2495,
                "ivowelsigndeva",
                2367,
                "ivowelsigngujarati",
                2751,
                "izhitsacyrillic",
                1141,
                "izhitsadblgravecyrillic",
                1143,
                "j",
                106,
                "jaarmenian",
                1393,
                "jabengali",
                2460,
                "jadeva",
                2332,
                "jagujarati",
                2716,
                "jagurmukhi",
                2588,
                "jbopomofo",
                12560,
                "jcaron",
                496,
                "jcircle",
                9433,
                "jcircumflex",
                309,
                "jcrossedtail",
                669,
                "jdotlessstroke",
                607,
                "jecyrillic",
                1112,
                "jeemarabic",
                1580,
                "jeemfinalarabic",
                65182,
                "jeeminitialarabic",
                65183,
                "jeemmedialarabic",
                65184,
                "jeharabic",
                1688,
                "jehfinalarabic",
                64395,
                "jhabengali",
                2461,
                "jhadeva",
                2333,
                "jhagujarati",
                2717,
                "jhagurmukhi",
                2589,
                "jheharmenian",
                1403,
                "jis",
                12292,
                "jmonospace",
                65354,
                "jparen",
                9381,
                "jsuperior",
                690,
                "k",
                107,
                "kabashkircyrillic",
                1185,
                "kabengali",
                2453,
                "kacute",
                7729,
                "kacyrillic",
                1082,
                "kadescendercyrillic",
                1179,
                "kadeva",
                2325,
                "kaf",
                1499,
                "kafarabic",
                1603,
                "kafdagesh",
                64315,
                "kafdageshhebrew",
                64315,
                "kaffinalarabic",
                65242,
                "kafhebrew",
                1499,
                "kafinitialarabic",
                65243,
                "kafmedialarabic",
                65244,
                "kafrafehebrew",
                64333,
                "kagujarati",
                2709,
                "kagurmukhi",
                2581,
                "kahiragana",
                12363,
                "kahookcyrillic",
                1220,
                "kakatakana",
                12459,
                "kakatakanahalfwidth",
                65398,
                "kappa",
                954,
                "kappasymbolgreek",
                1008,
                "kapyeounmieumkorean",
                12657,
                "kapyeounphieuphkorean",
                12676,
                "kapyeounpieupkorean",
                12664,
                "kapyeounssangpieupkorean",
                12665,
                "karoriisquare",
                13069,
                "kashidaautoarabic",
                1600,
                "kashidaautonosidebearingarabic",
                1600,
                "kasmallkatakana",
                12533,
                "kasquare",
                13188,
                "kasraarabic",
                1616,
                "kasratanarabic",
                1613,
                "kastrokecyrillic",
                1183,
                "katahiraprolongmarkhalfwidth",
                65392,
                "kaverticalstrokecyrillic",
                1181,
                "kbopomofo",
                12558,
                "kcalsquare",
                13193,
                "kcaron",
                489,
                "kcedilla",
                311,
                "kcircle",
                9434,
                "kcommaaccent",
                311,
                "kdotbelow",
                7731,
                "keharmenian",
                1412,
                "kehiragana",
                12369,
                "kekatakana",
                12465,
                "kekatakanahalfwidth",
                65401,
                "kenarmenian",
                1391,
                "kesmallkatakana",
                12534,
                "kgreenlandic",
                312,
                "khabengali",
                2454,
                "khacyrillic",
                1093,
                "khadeva",
                2326,
                "khagujarati",
                2710,
                "khagurmukhi",
                2582,
                "khaharabic",
                1582,
                "khahfinalarabic",
                65190,
                "khahinitialarabic",
                65191,
                "khahmedialarabic",
                65192,
                "kheicoptic",
                999,
                "khhadeva",
                2393,
                "khhagurmukhi",
                2649,
                "khieukhacirclekorean",
                12920,
                "khieukhaparenkorean",
                12824,
                "khieukhcirclekorean",
                12906,
                "khieukhkorean",
                12619,
                "khieukhparenkorean",
                12810,
                "khokhaithai",
                3586,
                "khokhonthai",
                3589,
                "khokhuatthai",
                3587,
                "khokhwaithai",
                3588,
                "khomutthai",
                3675,
                "khook",
                409,
                "khorakhangthai",
                3590,
                "khzsquare",
                13201,
                "kihiragana",
                12365,
                "kikatakana",
                12461,
                "kikatakanahalfwidth",
                65399,
                "kiroguramusquare",
                13077,
                "kiromeetorusquare",
                13078,
                "kirosquare",
                13076,
                "kiyeokacirclekorean",
                12910,
                "kiyeokaparenkorean",
                12814,
                "kiyeokcirclekorean",
                12896,
                "kiyeokkorean",
                12593,
                "kiyeokparenkorean",
                12800,
                "kiyeoksioskorean",
                12595,
                "kjecyrillic",
                1116,
                "klinebelow",
                7733,
                "klsquare",
                13208,
                "kmcubedsquare",
                13222,
                "kmonospace",
                65355,
                "kmsquaredsquare",
                13218,
                "kohiragana",
                12371,
                "kohmsquare",
                13248,
                "kokaithai",
                3585,
                "kokatakana",
                12467,
                "kokatakanahalfwidth",
                65402,
                "kooposquare",
                13086,
                "koppacyrillic",
                1153,
                "koreanstandardsymbol",
                12927,
                "koroniscmb",
                835,
                "kparen",
                9382,
                "kpasquare",
                13226,
                "ksicyrillic",
                1135,
                "ktsquare",
                13263,
                "kturned",
                670,
                "kuhiragana",
                12367,
                "kukatakana",
                12463,
                "kukatakanahalfwidth",
                65400,
                "kvsquare",
                13240,
                "kwsquare",
                13246,
                "l",
                108,
                "labengali",
                2482,
                "lacute",
                314,
                "ladeva",
                2354,
                "lagujarati",
                2738,
                "lagurmukhi",
                2610,
                "lakkhangyaothai",
                3653,
                "lamaleffinalarabic",
                65276,
                "lamalefhamzaabovefinalarabic",
                65272,
                "lamalefhamzaaboveisolatedarabic",
                65271,
                "lamalefhamzabelowfinalarabic",
                65274,
                "lamalefhamzabelowisolatedarabic",
                65273,
                "lamalefisolatedarabic",
                65275,
                "lamalefmaddaabovefinalarabic",
                65270,
                "lamalefmaddaaboveisolatedarabic",
                65269,
                "lamarabic",
                1604,
                "lambda",
                955,
                "lambdastroke",
                411,
                "lamed",
                1500,
                "lameddagesh",
                64316,
                "lameddageshhebrew",
                64316,
                "lamedhebrew",
                1500,
                "lamfinalarabic",
                65246,
                "lamhahinitialarabic",
                64714,
                "laminitialarabic",
                65247,
                "lamjeeminitialarabic",
                64713,
                "lamkhahinitialarabic",
                64715,
                "lamlamhehisolatedarabic",
                65010,
                "lammedialarabic",
                65248,
                "lammeemhahinitialarabic",
                64904,
                "lammeeminitialarabic",
                64716,
                "largecircle",
                9711,
                "lbar",
                410,
                "lbelt",
                620,
                "lbopomofo",
                12556,
                "lcaron",
                318,
                "lcedilla",
                316,
                "lcircle",
                9435,
                "lcircumflexbelow",
                7741,
                "lcommaaccent",
                316,
                "ldot",
                320,
                "ldotaccent",
                320,
                "ldotbelow",
                7735,
                "ldotbelowmacron",
                7737,
                "leftangleabovecmb",
                794,
                "lefttackbelowcmb",
                792,
                "less",
                60,
                "lessequal",
                8804,
                "lessequalorgreater",
                8922,
                "lessmonospace",
                65308,
                "lessorequivalent",
                8818,
                "lessorgreater",
                8822,
                "lessoverequal",
                8806,
                "lesssmall",
                65124,
                "lezh",
                622,
                "lfblock",
                9612,
                "lhookretroflex",
                621,
                "lira",
                8356,
                "liwnarmenian",
                1388,
                "lj",
                457,
                "ljecyrillic",
                1113,
                "ll",
                63168,
                "lladeva",
                2355,
                "llagujarati",
                2739,
                "llinebelow",
                7739,
                "llladeva",
                2356,
                "llvocalicbengali",
                2529,
                "llvocalicdeva",
                2401,
                "llvocalicvowelsignbengali",
                2531,
                "llvocalicvowelsigndeva",
                2403,
                "lmiddletilde",
                619,
                "lmonospace",
                65356,
                "lmsquare",
                13264,
                "lochulathai",
                3628,
                "logicaland",
                8743,
                "logicalnot",
                172,
                "logicalnotreversed",
                8976,
                "logicalor",
                8744,
                "lolingthai",
                3621,
                "longs",
                383,
                "lowlinecenterline",
                65102,
                "lowlinecmb",
                818,
                "lowlinedashed",
                65101,
                "lozenge",
                9674,
                "lparen",
                9383,
                "lslash",
                322,
                "lsquare",
                8467,
                "lsuperior",
                63214,
                "ltshade",
                9617,
                "luthai",
                3622,
                "lvocalicbengali",
                2444,
                "lvocalicdeva",
                2316,
                "lvocalicvowelsignbengali",
                2530,
                "lvocalicvowelsigndeva",
                2402,
                "lxsquare",
                13267,
                "m",
                109,
                "mabengali",
                2478,
                "macron",
                175,
                "macronbelowcmb",
                817,
                "macroncmb",
                772,
                "macronlowmod",
                717,
                "macronmonospace",
                65507,
                "macute",
                7743,
                "madeva",
                2350,
                "magujarati",
                2734,
                "magurmukhi",
                2606,
                "mahapakhhebrew",
                1444,
                "mahapakhlefthebrew",
                1444,
                "mahiragana",
                12414,
                "maichattawalowleftthai",
                63637,
                "maichattawalowrightthai",
                63636,
                "maichattawathai",
                3659,
                "maichattawaupperleftthai",
                63635,
                "maieklowleftthai",
                63628,
                "maieklowrightthai",
                63627,
                "maiekthai",
                3656,
                "maiekupperleftthai",
                63626,
                "maihanakatleftthai",
                63620,
                "maihanakatthai",
                3633,
                "maitaikhuleftthai",
                63625,
                "maitaikhuthai",
                3655,
                "maitholowleftthai",
                63631,
                "maitholowrightthai",
                63630,
                "maithothai",
                3657,
                "maithoupperleftthai",
                63629,
                "maitrilowleftthai",
                63634,
                "maitrilowrightthai",
                63633,
                "maitrithai",
                3658,
                "maitriupperleftthai",
                63632,
                "maiyamokthai",
                3654,
                "makatakana",
                12510,
                "makatakanahalfwidth",
                65423,
                "male",
                9794,
                "mansyonsquare",
                13127,
                "maqafhebrew",
                1470,
                "mars",
                9794,
                "masoracirclehebrew",
                1455,
                "masquare",
                13187,
                "mbopomofo",
                12551,
                "mbsquare",
                13268,
                "mcircle",
                9436,
                "mcubedsquare",
                13221,
                "mdotaccent",
                7745,
                "mdotbelow",
                7747,
                "meemarabic",
                1605,
                "meemfinalarabic",
                65250,
                "meeminitialarabic",
                65251,
                "meemmedialarabic",
                65252,
                "meemmeeminitialarabic",
                64721,
                "meemmeemisolatedarabic",
                64584,
                "meetorusquare",
                13133,
                "mehiragana",
                12417,
                "meizierasquare",
                13182,
                "mekatakana",
                12513,
                "mekatakanahalfwidth",
                65426,
                "mem",
                1502,
                "memdagesh",
                64318,
                "memdageshhebrew",
                64318,
                "memhebrew",
                1502,
                "menarmenian",
                1396,
                "merkhahebrew",
                1445,
                "merkhakefulahebrew",
                1446,
                "merkhakefulalefthebrew",
                1446,
                "merkhalefthebrew",
                1445,
                "mhook",
                625,
                "mhzsquare",
                13202,
                "middledotkatakanahalfwidth",
                65381,
                "middot",
                183,
                "mieumacirclekorean",
                12914,
                "mieumaparenkorean",
                12818,
                "mieumcirclekorean",
                12900,
                "mieumkorean",
                12609,
                "mieumpansioskorean",
                12656,
                "mieumparenkorean",
                12804,
                "mieumpieupkorean",
                12654,
                "mieumsioskorean",
                12655,
                "mihiragana",
                12415,
                "mikatakana",
                12511,
                "mikatakanahalfwidth",
                65424,
                "minus",
                8722,
                "minusbelowcmb",
                800,
                "minuscircle",
                8854,
                "minusmod",
                727,
                "minusplus",
                8723,
                "minute",
                8242,
                "miribaarusquare",
                13130,
                "mirisquare",
                13129,
                "mlonglegturned",
                624,
                "mlsquare",
                13206,
                "mmcubedsquare",
                13219,
                "mmonospace",
                65357,
                "mmsquaredsquare",
                13215,
                "mohiragana",
                12418,
                "mohmsquare",
                13249,
                "mokatakana",
                12514,
                "mokatakanahalfwidth",
                65427,
                "molsquare",
                13270,
                "momathai",
                3617,
                "moverssquare",
                13223,
                "moverssquaredsquare",
                13224,
                "mparen",
                9384,
                "mpasquare",
                13227,
                "mssquare",
                13235,
                "msuperior",
                63215,
                "mturned",
                623,
                "mu",
                181,
                "mu1",
                181,
                "muasquare",
                13186,
                "muchgreater",
                8811,
                "muchless",
                8810,
                "mufsquare",
                13196,
                "mugreek",
                956,
                "mugsquare",
                13197,
                "muhiragana",
                12416,
                "mukatakana",
                12512,
                "mukatakanahalfwidth",
                65425,
                "mulsquare",
                13205,
                "multiply",
                215,
                "mumsquare",
                13211,
                "munahhebrew",
                1443,
                "munahlefthebrew",
                1443,
                "musicalnote",
                9834,
                "musicalnotedbl",
                9835,
                "musicflatsign",
                9837,
                "musicsharpsign",
                9839,
                "mussquare",
                13234,
                "muvsquare",
                13238,
                "muwsquare",
                13244,
                "mvmegasquare",
                13241,
                "mvsquare",
                13239,
                "mwmegasquare",
                13247,
                "mwsquare",
                13245,
                "n",
                110,
                "nabengali",
                2472,
                "nabla",
                8711,
                "nacute",
                324,
                "nadeva",
                2344,
                "nagujarati",
                2728,
                "nagurmukhi",
                2600,
                "nahiragana",
                12394,
                "nakatakana",
                12490,
                "nakatakanahalfwidth",
                65413,
                "napostrophe",
                329,
                "nasquare",
                13185,
                "nbopomofo",
                12555,
                "nbspace",
                160,
                "ncaron",
                328,
                "ncedilla",
                326,
                "ncircle",
                9437,
                "ncircumflexbelow",
                7755,
                "ncommaaccent",
                326,
                "ndotaccent",
                7749,
                "ndotbelow",
                7751,
                "nehiragana",
                12397,
                "nekatakana",
                12493,
                "nekatakanahalfwidth",
                65416,
                "newsheqelsign",
                8362,
                "nfsquare",
                13195,
                "ngabengali",
                2457,
                "ngadeva",
                2329,
                "ngagujarati",
                2713,
                "ngagurmukhi",
                2585,
                "ngonguthai",
                3591,
                "nhiragana",
                12435,
                "nhookleft",
                626,
                "nhookretroflex",
                627,
                "nieunacirclekorean",
                12911,
                "nieunaparenkorean",
                12815,
                "nieuncieuckorean",
                12597,
                "nieuncirclekorean",
                12897,
                "nieunhieuhkorean",
                12598,
                "nieunkorean",
                12596,
                "nieunpansioskorean",
                12648,
                "nieunparenkorean",
                12801,
                "nieunsioskorean",
                12647,
                "nieuntikeutkorean",
                12646,
                "nihiragana",
                12395,
                "nikatakana",
                12491,
                "nikatakanahalfwidth",
                65414,
                "nikhahitleftthai",
                63641,
                "nikhahitthai",
                3661,
                "nine",
                57,
                "ninearabic",
                1641,
                "ninebengali",
                2543,
                "ninecircle",
                9320,
                "ninecircleinversesansserif",
                10130,
                "ninedeva",
                2415,
                "ninegujarati",
                2799,
                "ninegurmukhi",
                2671,
                "ninehackarabic",
                1641,
                "ninehangzhou",
                12329,
                "nineideographicparen",
                12840,
                "nineinferior",
                8329,
                "ninemonospace",
                65305,
                "nineoldstyle",
                63289,
                "nineparen",
                9340,
                "nineperiod",
                9360,
                "ninepersian",
                1785,
                "nineroman",
                8568,
                "ninesuperior",
                8313,
                "nineteencircle",
                9330,
                "nineteenparen",
                9350,
                "nineteenperiod",
                9370,
                "ninethai",
                3673,
                "nj",
                460,
                "njecyrillic",
                1114,
                "nkatakana",
                12531,
                "nkatakanahalfwidth",
                65437,
                "nlegrightlong",
                414,
                "nlinebelow",
                7753,
                "nmonospace",
                65358,
                "nmsquare",
                13210,
                "nnabengali",
                2467,
                "nnadeva",
                2339,
                "nnagujarati",
                2723,
                "nnagurmukhi",
                2595,
                "nnnadeva",
                2345,
                "nohiragana",
                12398,
                "nokatakana",
                12494,
                "nokatakanahalfwidth",
                65417,
                "nonbreakingspace",
                160,
                "nonenthai",
                3603,
                "nonuthai",
                3609,
                "noonarabic",
                1606,
                "noonfinalarabic",
                65254,
                "noonghunnaarabic",
                1722,
                "noonghunnafinalarabic",
                64415,
                "nooninitialarabic",
                65255,
                "noonjeeminitialarabic",
                64722,
                "noonjeemisolatedarabic",
                64587,
                "noonmedialarabic",
                65256,
                "noonmeeminitialarabic",
                64725,
                "noonmeemisolatedarabic",
                64590,
                "noonnoonfinalarabic",
                64653,
                "notcontains",
                8716,
                "notelement",
                8713,
                "notelementof",
                8713,
                "notequal",
                8800,
                "notgreater",
                8815,
                "notgreaternorequal",
                8817,
                "notgreaternorless",
                8825,
                "notidentical",
                8802,
                "notless",
                8814,
                "notlessnorequal",
                8816,
                "notparallel",
                8742,
                "notprecedes",
                8832,
                "notsubset",
                8836,
                "notsucceeds",
                8833,
                "notsuperset",
                8837,
                "nowarmenian",
                1398,
                "nparen",
                9385,
                "nssquare",
                13233,
                "nsuperior",
                8319,
                "ntilde",
                241,
                "nu",
                957,
                "nuhiragana",
                12396,
                "nukatakana",
                12492,
                "nukatakanahalfwidth",
                65415,
                "nuktabengali",
                2492,
                "nuktadeva",
                2364,
                "nuktagujarati",
                2748,
                "nuktagurmukhi",
                2620,
                "numbersign",
                35,
                "numbersignmonospace",
                65283,
                "numbersignsmall",
                65119,
                "numeralsigngreek",
                884,
                "numeralsignlowergreek",
                885,
                "numero",
                8470,
                "nun",
                1504,
                "nundagesh",
                64320,
                "nundageshhebrew",
                64320,
                "nunhebrew",
                1504,
                "nvsquare",
                13237,
                "nwsquare",
                13243,
                "nyabengali",
                2462,
                "nyadeva",
                2334,
                "nyagujarati",
                2718,
                "nyagurmukhi",
                2590,
                "o",
                111,
                "oacute",
                243,
                "oangthai",
                3629,
                "obarred",
                629,
                "obarredcyrillic",
                1257,
                "obarreddieresiscyrillic",
                1259,
                "obengali",
                2451,
                "obopomofo",
                12571,
                "obreve",
                335,
                "ocandradeva",
                2321,
                "ocandragujarati",
                2705,
                "ocandravowelsigndeva",
                2377,
                "ocandravowelsigngujarati",
                2761,
                "ocaron",
                466,
                "ocircle",
                9438,
                "ocircumflex",
                244,
                "ocircumflexacute",
                7889,
                "ocircumflexdotbelow",
                7897,
                "ocircumflexgrave",
                7891,
                "ocircumflexhookabove",
                7893,
                "ocircumflextilde",
                7895,
                "ocyrillic",
                1086,
                "odblacute",
                337,
                "odblgrave",
                525,
                "odeva",
                2323,
                "odieresis",
                246,
                "odieresiscyrillic",
                1255,
                "odotbelow",
                7885,
                "oe",
                339,
                "oekorean",
                12634,
                "ogonek",
                731,
                "ogonekcmb",
                808,
                "ograve",
                242,
                "ogujarati",
                2707,
                "oharmenian",
                1413,
                "ohiragana",
                12362,
                "ohookabove",
                7887,
                "ohorn",
                417,
                "ohornacute",
                7899,
                "ohorndotbelow",
                7907,
                "ohorngrave",
                7901,
                "ohornhookabove",
                7903,
                "ohorntilde",
                7905,
                "ohungarumlaut",
                337,
                "oi",
                419,
                "oinvertedbreve",
                527,
                "okatakana",
                12458,
                "okatakanahalfwidth",
                65397,
                "okorean",
                12631,
                "olehebrew",
                1451,
                "omacron",
                333,
                "omacronacute",
                7763,
                "omacrongrave",
                7761,
                "omdeva",
                2384,
                "omega",
                969,
                "omega1",
                982,
                "omegacyrillic",
                1121,
                "omegalatinclosed",
                631,
                "omegaroundcyrillic",
                1147,
                "omegatitlocyrillic",
                1149,
                "omegatonos",
                974,
                "omgujarati",
                2768,
                "omicron",
                959,
                "omicrontonos",
                972,
                "omonospace",
                65359,
                "one",
                49,
                "onearabic",
                1633,
                "onebengali",
                2535,
                "onecircle",
                9312,
                "onecircleinversesansserif",
                10122,
                "onedeva",
                2407,
                "onedotenleader",
                8228,
                "oneeighth",
                8539,
                "onefitted",
                63196,
                "onegujarati",
                2791,
                "onegurmukhi",
                2663,
                "onehackarabic",
                1633,
                "onehalf",
                189,
                "onehangzhou",
                12321,
                "oneideographicparen",
                12832,
                "oneinferior",
                8321,
                "onemonospace",
                65297,
                "onenumeratorbengali",
                2548,
                "oneoldstyle",
                63281,
                "oneparen",
                9332,
                "oneperiod",
                9352,
                "onepersian",
                1777,
                "onequarter",
                188,
                "oneroman",
                8560,
                "onesuperior",
                185,
                "onethai",
                3665,
                "onethird",
                8531,
                "oogonek",
                491,
                "oogonekmacron",
                493,
                "oogurmukhi",
                2579,
                "oomatragurmukhi",
                2635,
                "oopen",
                596,
                "oparen",
                9386,
                "openbullet",
                9702,
                "option",
                8997,
                "ordfeminine",
                170,
                "ordmasculine",
                186,
                "orthogonal",
                8735,
                "oshortdeva",
                2322,
                "oshortvowelsigndeva",
                2378,
                "oslash",
                248,
                "oslashacute",
                511,
                "osmallhiragana",
                12361,
                "osmallkatakana",
                12457,
                "osmallkatakanahalfwidth",
                65387,
                "ostrokeacute",
                511,
                "osuperior",
                63216,
                "otcyrillic",
                1151,
                "otilde",
                245,
                "otildeacute",
                7757,
                "otildedieresis",
                7759,
                "oubopomofo",
                12577,
                "overline",
                8254,
                "overlinecenterline",
                65098,
                "overlinecmb",
                773,
                "overlinedashed",
                65097,
                "overlinedblwavy",
                65100,
                "overlinewavy",
                65099,
                "overscore",
                175,
                "ovowelsignbengali",
                2507,
                "ovowelsigndeva",
                2379,
                "ovowelsigngujarati",
                2763,
                "p",
                112,
                "paampssquare",
                13184,
                "paasentosquare",
                13099,
                "pabengali",
                2474,
                "pacute",
                7765,
                "padeva",
                2346,
                "pagedown",
                8671,
                "pageup",
                8670,
                "pagujarati",
                2730,
                "pagurmukhi",
                2602,
                "pahiragana",
                12401,
                "paiyannoithai",
                3631,
                "pakatakana",
                12497,
                "palatalizationcyrilliccmb",
                1156,
                "palochkacyrillic",
                1216,
                "pansioskorean",
                12671,
                "paragraph",
                182,
                "parallel",
                8741,
                "parenleft",
                40,
                "parenleftaltonearabic",
                64830,
                "parenleftbt",
                63725,
                "parenleftex",
                63724,
                "parenleftinferior",
                8333,
                "parenleftmonospace",
                65288,
                "parenleftsmall",
                65113,
                "parenleftsuperior",
                8317,
                "parenlefttp",
                63723,
                "parenleftvertical",
                65077,
                "parenright",
                41,
                "parenrightaltonearabic",
                64831,
                "parenrightbt",
                63736,
                "parenrightex",
                63735,
                "parenrightinferior",
                8334,
                "parenrightmonospace",
                65289,
                "parenrightsmall",
                65114,
                "parenrightsuperior",
                8318,
                "parenrighttp",
                63734,
                "parenrightvertical",
                65078,
                "partialdiff",
                8706,
                "paseqhebrew",
                1472,
                "pashtahebrew",
                1433,
                "pasquare",
                13225,
                "patah",
                1463,
                "patah11",
                1463,
                "patah1d",
                1463,
                "patah2a",
                1463,
                "patahhebrew",
                1463,
                "patahnarrowhebrew",
                1463,
                "patahquarterhebrew",
                1463,
                "patahwidehebrew",
                1463,
                "pazerhebrew",
                1441,
                "pbopomofo",
                12550,
                "pcircle",
                9439,
                "pdotaccent",
                7767,
                "pe",
                1508,
                "pecyrillic",
                1087,
                "pedagesh",
                64324,
                "pedageshhebrew",
                64324,
                "peezisquare",
                13115,
                "pefinaldageshhebrew",
                64323,
                "peharabic",
                1662,
                "peharmenian",
                1402,
                "pehebrew",
                1508,
                "pehfinalarabic",
                64343,
                "pehinitialarabic",
                64344,
                "pehiragana",
                12410,
                "pehmedialarabic",
                64345,
                "pekatakana",
                12506,
                "pemiddlehookcyrillic",
                1191,
                "perafehebrew",
                64334,
                "percent",
                37,
                "percentarabic",
                1642,
                "percentmonospace",
                65285,
                "percentsmall",
                65130,
                "period",
                46,
                "periodarmenian",
                1417,
                "periodcentered",
                183,
                "periodhalfwidth",
                65377,
                "periodinferior",
                63207,
                "periodmonospace",
                65294,
                "periodsmall",
                65106,
                "periodsuperior",
                63208,
                "perispomenigreekcmb",
                834,
                "perpendicular",
                8869,
                "perthousand",
                8240,
                "peseta",
                8359,
                "pfsquare",
                13194,
                "phabengali",
                2475,
                "phadeva",
                2347,
                "phagujarati",
                2731,
                "phagurmukhi",
                2603,
                "phi",
                966,
                "phi1",
                981,
                "phieuphacirclekorean",
                12922,
                "phieuphaparenkorean",
                12826,
                "phieuphcirclekorean",
                12908,
                "phieuphkorean",
                12621,
                "phieuphparenkorean",
                12812,
                "philatin",
                632,
                "phinthuthai",
                3642,
                "phisymbolgreek",
                981,
                "phook",
                421,
                "phophanthai",
                3614,
                "phophungthai",
                3612,
                "phosamphaothai",
                3616,
                "pi",
                960,
                "pieupacirclekorean",
                12915,
                "pieupaparenkorean",
                12819,
                "pieupcieuckorean",
                12662,
                "pieupcirclekorean",
                12901,
                "pieupkiyeokkorean",
                12658,
                "pieupkorean",
                12610,
                "pieupparenkorean",
                12805,
                "pieupsioskiyeokkorean",
                12660,
                "pieupsioskorean",
                12612,
                "pieupsiostikeutkorean",
                12661,
                "pieupthieuthkorean",
                12663,
                "pieuptikeutkorean",
                12659,
                "pihiragana",
                12404,
                "pikatakana",
                12500,
                "pisymbolgreek",
                982,
                "piwrarmenian",
                1411,
                "plus",
                43,
                "plusbelowcmb",
                799,
                "pluscircle",
                8853,
                "plusminus",
                177,
                "plusmod",
                726,
                "plusmonospace",
                65291,
                "plussmall",
                65122,
                "plussuperior",
                8314,
                "pmonospace",
                65360,
                "pmsquare",
                13272,
                "pohiragana",
                12413,
                "pointingindexdownwhite",
                9759,
                "pointingindexleftwhite",
                9756,
                "pointingindexrightwhite",
                9758,
                "pointingindexupwhite",
                9757,
                "pokatakana",
                12509,
                "poplathai",
                3611,
                "postalmark",
                12306,
                "postalmarkface",
                12320,
                "pparen",
                9387,
                "precedes",
                8826,
                "prescription",
                8478,
                "primemod",
                697,
                "primereversed",
                8245,
                "product",
                8719,
                "projective",
                8965,
                "prolongedkana",
                12540,
                "propellor",
                8984,
                "propersubset",
                8834,
                "propersuperset",
                8835,
                "proportion",
                8759,
                "proportional",
                8733,
                "psi",
                968,
                "psicyrillic",
                1137,
                "psilipneumatacyrilliccmb",
                1158,
                "pssquare",
                13232,
                "puhiragana",
                12407,
                "pukatakana",
                12503,
                "pvsquare",
                13236,
                "pwsquare",
                13242,
                "q",
                113,
                "qadeva",
                2392,
                "qadmahebrew",
                1448,
                "qafarabic",
                1602,
                "qaffinalarabic",
                65238,
                "qafinitialarabic",
                65239,
                "qafmedialarabic",
                65240,
                "qamats",
                1464,
                "qamats10",
                1464,
                "qamats1a",
                1464,
                "qamats1c",
                1464,
                "qamats27",
                1464,
                "qamats29",
                1464,
                "qamats33",
                1464,
                "qamatsde",
                1464,
                "qamatshebrew",
                1464,
                "qamatsnarrowhebrew",
                1464,
                "qamatsqatanhebrew",
                1464,
                "qamatsqatannarrowhebrew",
                1464,
                "qamatsqatanquarterhebrew",
                1464,
                "qamatsqatanwidehebrew",
                1464,
                "qamatsquarterhebrew",
                1464,
                "qamatswidehebrew",
                1464,
                "qarneyparahebrew",
                1439,
                "qbopomofo",
                12561,
                "qcircle",
                9440,
                "qhook",
                672,
                "qmonospace",
                65361,
                "qof",
                1511,
                "qofdagesh",
                64327,
                "qofdageshhebrew",
                64327,
                "qofhebrew",
                1511,
                "qparen",
                9388,
                "quarternote",
                9833,
                "qubuts",
                1467,
                "qubuts18",
                1467,
                "qubuts25",
                1467,
                "qubuts31",
                1467,
                "qubutshebrew",
                1467,
                "qubutsnarrowhebrew",
                1467,
                "qubutsquarterhebrew",
                1467,
                "qubutswidehebrew",
                1467,
                "question",
                63,
                "questionarabic",
                1567,
                "questionarmenian",
                1374,
                "questiondown",
                191,
                "questiondownsmall",
                63423,
                "questiongreek",
                894,
                "questionmonospace",
                65311,
                "questionsmall",
                63295,
                "quotedbl",
                34,
                "quotedblbase",
                8222,
                "quotedblleft",
                8220,
                "quotedblmonospace",
                65282,
                "quotedblprime",
                12318,
                "quotedblprimereversed",
                12317,
                "quotedblright",
                8221,
                "quoteleft",
                8216,
                "quoteleftreversed",
                8219,
                "quotereversed",
                8219,
                "quoteright",
                8217,
                "quoterightn",
                329,
                "quotesinglbase",
                8218,
                "quotesingle",
                39,
                "quotesinglemonospace",
                65287,
                "r",
                114,
                "raarmenian",
                1404,
                "rabengali",
                2480,
                "racute",
                341,
                "radeva",
                2352,
                "radical",
                8730,
                "radicalex",
                63717,
                "radoverssquare",
                13230,
                "radoverssquaredsquare",
                13231,
                "radsquare",
                13229,
                "rafe",
                1471,
                "rafehebrew",
                1471,
                "ragujarati",
                2736,
                "ragurmukhi",
                2608,
                "rahiragana",
                12425,
                "rakatakana",
                12521,
                "rakatakanahalfwidth",
                65431,
                "ralowerdiagonalbengali",
                2545,
                "ramiddlediagonalbengali",
                2544,
                "ramshorn",
                612,
                "ratio",
                8758,
                "rbopomofo",
                12566,
                "rcaron",
                345,
                "rcedilla",
                343,
                "rcircle",
                9441,
                "rcommaaccent",
                343,
                "rdblgrave",
                529,
                "rdotaccent",
                7769,
                "rdotbelow",
                7771,
                "rdotbelowmacron",
                7773,
                "referencemark",
                8251,
                "reflexsubset",
                8838,
                "reflexsuperset",
                8839,
                "registered",
                174,
                "registersans",
                63720,
                "registerserif",
                63194,
                "reharabic",
                1585,
                "reharmenian",
                1408,
                "rehfinalarabic",
                65198,
                "rehiragana",
                12428,
                "rekatakana",
                12524,
                "rekatakanahalfwidth",
                65434,
                "resh",
                1512,
                "reshdageshhebrew",
                64328,
                "reshhebrew",
                1512,
                "reversedtilde",
                8765,
                "reviahebrew",
                1431,
                "reviamugrashhebrew",
                1431,
                "revlogicalnot",
                8976,
                "rfishhook",
                638,
                "rfishhookreversed",
                639,
                "rhabengali",
                2525,
                "rhadeva",
                2397,
                "rho",
                961,
                "rhook",
                637,
                "rhookturned",
                635,
                "rhookturnedsuperior",
                693,
                "rhosymbolgreek",
                1009,
                "rhotichookmod",
                734,
                "rieulacirclekorean",
                12913,
                "rieulaparenkorean",
                12817,
                "rieulcirclekorean",
                12899,
                "rieulhieuhkorean",
                12608,
                "rieulkiyeokkorean",
                12602,
                "rieulkiyeoksioskorean",
                12649,
                "rieulkorean",
                12601,
                "rieulmieumkorean",
                12603,
                "rieulpansioskorean",
                12652,
                "rieulparenkorean",
                12803,
                "rieulphieuphkorean",
                12607,
                "rieulpieupkorean",
                12604,
                "rieulpieupsioskorean",
                12651,
                "rieulsioskorean",
                12605,
                "rieulthieuthkorean",
                12606,
                "rieultikeutkorean",
                12650,
                "rieulyeorinhieuhkorean",
                12653,
                "rightangle",
                8735,
                "righttackbelowcmb",
                793,
                "righttriangle",
                8895,
                "rihiragana",
                12426,
                "rikatakana",
                12522,
                "rikatakanahalfwidth",
                65432,
                "ring",
                730,
                "ringbelowcmb",
                805,
                "ringcmb",
                778,
                "ringhalfleft",
                703,
                "ringhalfleftarmenian",
                1369,
                "ringhalfleftbelowcmb",
                796,
                "ringhalfleftcentered",
                723,
                "ringhalfright",
                702,
                "ringhalfrightbelowcmb",
                825,
                "ringhalfrightcentered",
                722,
                "rinvertedbreve",
                531,
                "rittorusquare",
                13137,
                "rlinebelow",
                7775,
                "rlongleg",
                636,
                "rlonglegturned",
                634,
                "rmonospace",
                65362,
                "rohiragana",
                12429,
                "rokatakana",
                12525,
                "rokatakanahalfwidth",
                65435,
                "roruathai",
                3619,
                "rparen",
                9389,
                "rrabengali",
                2524,
                "rradeva",
                2353,
                "rragurmukhi",
                2652,
                "rreharabic",
                1681,
                "rrehfinalarabic",
                64397,
                "rrvocalicbengali",
                2528,
                "rrvocalicdeva",
                2400,
                "rrvocalicgujarati",
                2784,
                "rrvocalicvowelsignbengali",
                2500,
                "rrvocalicvowelsigndeva",
                2372,
                "rrvocalicvowelsigngujarati",
                2756,
                "rsuperior",
                63217,
                "rtblock",
                9616,
                "rturned",
                633,
                "rturnedsuperior",
                692,
                "ruhiragana",
                12427,
                "rukatakana",
                12523,
                "rukatakanahalfwidth",
                65433,
                "rupeemarkbengali",
                2546,
                "rupeesignbengali",
                2547,
                "rupiah",
                63197,
                "ruthai",
                3620,
                "rvocalicbengali",
                2443,
                "rvocalicdeva",
                2315,
                "rvocalicgujarati",
                2699,
                "rvocalicvowelsignbengali",
                2499,
                "rvocalicvowelsigndeva",
                2371,
                "rvocalicvowelsigngujarati",
                2755,
                "s",
                115,
                "sabengali",
                2488,
                "sacute",
                347,
                "sacutedotaccent",
                7781,
                "sadarabic",
                1589,
                "sadeva",
                2360,
                "sadfinalarabic",
                65210,
                "sadinitialarabic",
                65211,
                "sadmedialarabic",
                65212,
                "sagujarati",
                2744,
                "sagurmukhi",
                2616,
                "sahiragana",
                12373,
                "sakatakana",
                12469,
                "sakatakanahalfwidth",
                65403,
                "sallallahoualayhewasallamarabic",
                65018,
                "samekh",
                1505,
                "samekhdagesh",
                64321,
                "samekhdageshhebrew",
                64321,
                "samekhhebrew",
                1505,
                "saraaathai",
                3634,
                "saraaethai",
                3649,
                "saraaimaimalaithai",
                3652,
                "saraaimaimuanthai",
                3651,
                "saraamthai",
                3635,
                "saraathai",
                3632,
                "saraethai",
                3648,
                "saraiileftthai",
                63622,
                "saraiithai",
                3637,
                "saraileftthai",
                63621,
                "saraithai",
                3636,
                "saraothai",
                3650,
                "saraueeleftthai",
                63624,
                "saraueethai",
                3639,
                "saraueleftthai",
                63623,
                "sarauethai",
                3638,
                "sarauthai",
                3640,
                "sarauuthai",
                3641,
                "sbopomofo",
                12569,
                "scaron",
                353,
                "scarondotaccent",
                7783,
                "scedilla",
                351,
                "schwa",
                601,
                "schwacyrillic",
                1241,
                "schwadieresiscyrillic",
                1243,
                "schwahook",
                602,
                "scircle",
                9442,
                "scircumflex",
                349,
                "scommaaccent",
                537,
                "sdotaccent",
                7777,
                "sdotbelow",
                7779,
                "sdotbelowdotaccent",
                7785,
                "seagullbelowcmb",
                828,
                "second",
                8243,
                "secondtonechinese",
                714,
                "section",
                167,
                "seenarabic",
                1587,
                "seenfinalarabic",
                65202,
                "seeninitialarabic",
                65203,
                "seenmedialarabic",
                65204,
                "segol",
                1462,
                "segol13",
                1462,
                "segol1f",
                1462,
                "segol2c",
                1462,
                "segolhebrew",
                1462,
                "segolnarrowhebrew",
                1462,
                "segolquarterhebrew",
                1462,
                "segoltahebrew",
                1426,
                "segolwidehebrew",
                1462,
                "seharmenian",
                1405,
                "sehiragana",
                12379,
                "sekatakana",
                12475,
                "sekatakanahalfwidth",
                65406,
                "semicolon",
                59,
                "semicolonarabic",
                1563,
                "semicolonmonospace",
                65307,
                "semicolonsmall",
                65108,
                "semivoicedmarkkana",
                12444,
                "semivoicedmarkkanahalfwidth",
                65439,
                "sentisquare",
                13090,
                "sentosquare",
                13091,
                "seven",
                55,
                "sevenarabic",
                1639,
                "sevenbengali",
                2541,
                "sevencircle",
                9318,
                "sevencircleinversesansserif",
                10128,
                "sevendeva",
                2413,
                "seveneighths",
                8542,
                "sevengujarati",
                2797,
                "sevengurmukhi",
                2669,
                "sevenhackarabic",
                1639,
                "sevenhangzhou",
                12327,
                "sevenideographicparen",
                12838,
                "seveninferior",
                8327,
                "sevenmonospace",
                65303,
                "sevenoldstyle",
                63287,
                "sevenparen",
                9338,
                "sevenperiod",
                9358,
                "sevenpersian",
                1783,
                "sevenroman",
                8566,
                "sevensuperior",
                8311,
                "seventeencircle",
                9328,
                "seventeenparen",
                9348,
                "seventeenperiod",
                9368,
                "seventhai",
                3671,
                "sfthyphen",
                173,
                "shaarmenian",
                1399,
                "shabengali",
                2486,
                "shacyrillic",
                1096,
                "shaddaarabic",
                1617,
                "shaddadammaarabic",
                64609,
                "shaddadammatanarabic",
                64606,
                "shaddafathaarabic",
                64608,
                "shaddakasraarabic",
                64610,
                "shaddakasratanarabic",
                64607,
                "shade",
                9618,
                "shadedark",
                9619,
                "shadelight",
                9617,
                "shademedium",
                9618,
                "shadeva",
                2358,
                "shagujarati",
                2742,
                "shagurmukhi",
                2614,
                "shalshelethebrew",
                1427,
                "shbopomofo",
                12565,
                "shchacyrillic",
                1097,
                "sheenarabic",
                1588,
                "sheenfinalarabic",
                65206,
                "sheeninitialarabic",
                65207,
                "sheenmedialarabic",
                65208,
                "sheicoptic",
                995,
                "sheqel",
                8362,
                "sheqelhebrew",
                8362,
                "sheva",
                1456,
                "sheva115",
                1456,
                "sheva15",
                1456,
                "sheva22",
                1456,
                "sheva2e",
                1456,
                "shevahebrew",
                1456,
                "shevanarrowhebrew",
                1456,
                "shevaquarterhebrew",
                1456,
                "shevawidehebrew",
                1456,
                "shhacyrillic",
                1211,
                "shimacoptic",
                1005,
                "shin",
                1513,
                "shindagesh",
                64329,
                "shindageshhebrew",
                64329,
                "shindageshshindot",
                64300,
                "shindageshshindothebrew",
                64300,
                "shindageshsindot",
                64301,
                "shindageshsindothebrew",
                64301,
                "shindothebrew",
                1473,
                "shinhebrew",
                1513,
                "shinshindot",
                64298,
                "shinshindothebrew",
                64298,
                "shinsindot",
                64299,
                "shinsindothebrew",
                64299,
                "shook",
                642,
                "sigma",
                963,
                "sigma1",
                962,
                "sigmafinal",
                962,
                "sigmalunatesymbolgreek",
                1010,
                "sihiragana",
                12375,
                "sikatakana",
                12471,
                "sikatakanahalfwidth",
                65404,
                "siluqhebrew",
                1469,
                "siluqlefthebrew",
                1469,
                "similar",
                8764,
                "sindothebrew",
                1474,
                "siosacirclekorean",
                12916,
                "siosaparenkorean",
                12820,
                "sioscieuckorean",
                12670,
                "sioscirclekorean",
                12902,
                "sioskiyeokkorean",
                12666,
                "sioskorean",
                12613,
                "siosnieunkorean",
                12667,
                "siosparenkorean",
                12806,
                "siospieupkorean",
                12669,
                "siostikeutkorean",
                12668,
                "six",
                54,
                "sixarabic",
                1638,
                "sixbengali",
                2540,
                "sixcircle",
                9317,
                "sixcircleinversesansserif",
                10127,
                "sixdeva",
                2412,
                "sixgujarati",
                2796,
                "sixgurmukhi",
                2668,
                "sixhackarabic",
                1638,
                "sixhangzhou",
                12326,
                "sixideographicparen",
                12837,
                "sixinferior",
                8326,
                "sixmonospace",
                65302,
                "sixoldstyle",
                63286,
                "sixparen",
                9337,
                "sixperiod",
                9357,
                "sixpersian",
                1782,
                "sixroman",
                8565,
                "sixsuperior",
                8310,
                "sixteencircle",
                9327,
                "sixteencurrencydenominatorbengali",
                2553,
                "sixteenparen",
                9347,
                "sixteenperiod",
                9367,
                "sixthai",
                3670,
                "slash",
                47,
                "slashmonospace",
                65295,
                "slong",
                383,
                "slongdotaccent",
                7835,
                "smileface",
                9786,
                "smonospace",
                65363,
                "sofpasuqhebrew",
                1475,
                "softhyphen",
                173,
                "softsigncyrillic",
                1100,
                "sohiragana",
                12381,
                "sokatakana",
                12477,
                "sokatakanahalfwidth",
                65407,
                "soliduslongoverlaycmb",
                824,
                "solidusshortoverlaycmb",
                823,
                "sorusithai",
                3625,
                "sosalathai",
                3624,
                "sosothai",
                3595,
                "sosuathai",
                3626,
                "space",
                32,
                "spacehackarabic",
                32,
                "spade",
                9824,
                "spadesuitblack",
                9824,
                "spadesuitwhite",
                9828,
                "sparen",
                9390,
                "squarebelowcmb",
                827,
                "squarecc",
                13252,
                "squarecm",
                13213,
                "squarediagonalcrosshatchfill",
                9641,
                "squarehorizontalfill",
                9636,
                "squarekg",
                13199,
                "squarekm",
                13214,
                "squarekmcapital",
                13262,
                "squareln",
                13265,
                "squarelog",
                13266,
                "squaremg",
                13198,
                "squaremil",
                13269,
                "squaremm",
                13212,
                "squaremsquared",
                13217,
                "squareorthogonalcrosshatchfill",
                9638,
                "squareupperlefttolowerrightfill",
                9639,
                "squareupperrighttolowerleftfill",
                9640,
                "squareverticalfill",
                9637,
                "squarewhitewithsmallblack",
                9635,
                "srsquare",
                13275,
                "ssabengali",
                2487,
                "ssadeva",
                2359,
                "ssagujarati",
                2743,
                "ssangcieuckorean",
                12617,
                "ssanghieuhkorean",
                12677,
                "ssangieungkorean",
                12672,
                "ssangkiyeokkorean",
                12594,
                "ssangnieunkorean",
                12645,
                "ssangpieupkorean",
                12611,
                "ssangsioskorean",
                12614,
                "ssangtikeutkorean",
                12600,
                "ssuperior",
                63218,
                "sterling",
                163,
                "sterlingmonospace",
                65505,
                "strokelongoverlaycmb",
                822,
                "strokeshortoverlaycmb",
                821,
                "subset",
                8834,
                "subsetnotequal",
                8842,
                "subsetorequal",
                8838,
                "succeeds",
                8827,
                "suchthat",
                8715,
                "suhiragana",
                12377,
                "sukatakana",
                12473,
                "sukatakanahalfwidth",
                65405,
                "sukunarabic",
                1618,
                "summation",
                8721,
                "sun",
                9788,
                "superset",
                8835,
                "supersetnotequal",
                8843,
                "supersetorequal",
                8839,
                "svsquare",
                13276,
                "syouwaerasquare",
                13180,
                "t",
                116,
                "tabengali",
                2468,
                "tackdown",
                8868,
                "tackleft",
                8867,
                "tadeva",
                2340,
                "tagujarati",
                2724,
                "tagurmukhi",
                2596,
                "taharabic",
                1591,
                "tahfinalarabic",
                65218,
                "tahinitialarabic",
                65219,
                "tahiragana",
                12383,
                "tahmedialarabic",
                65220,
                "taisyouerasquare",
                13181,
                "takatakana",
                12479,
                "takatakanahalfwidth",
                65408,
                "tatweelarabic",
                1600,
                "tau",
                964,
                "tav",
                1514,
                "tavdages",
                64330,
                "tavdagesh",
                64330,
                "tavdageshhebrew",
                64330,
                "tavhebrew",
                1514,
                "tbar",
                359,
                "tbopomofo",
                12554,
                "tcaron",
                357,
                "tccurl",
                680,
                "tcedilla",
                355,
                "tcheharabic",
                1670,
                "tchehfinalarabic",
                64379,
                "tchehinitialarabic",
                64380,
                "tchehmedialarabic",
                64381,
                "tcircle",
                9443,
                "tcircumflexbelow",
                7793,
                "tcommaaccent",
                355,
                "tdieresis",
                7831,
                "tdotaccent",
                7787,
                "tdotbelow",
                7789,
                "tecyrillic",
                1090,
                "tedescendercyrillic",
                1197,
                "teharabic",
                1578,
                "tehfinalarabic",
                65174,
                "tehhahinitialarabic",
                64674,
                "tehhahisolatedarabic",
                64524,
                "tehinitialarabic",
                65175,
                "tehiragana",
                12390,
                "tehjeeminitialarabic",
                64673,
                "tehjeemisolatedarabic",
                64523,
                "tehmarbutaarabic",
                1577,
                "tehmarbutafinalarabic",
                65172,
                "tehmedialarabic",
                65176,
                "tehmeeminitialarabic",
                64676,
                "tehmeemisolatedarabic",
                64526,
                "tehnoonfinalarabic",
                64627,
                "tekatakana",
                12486,
                "tekatakanahalfwidth",
                65411,
                "telephone",
                8481,
                "telephoneblack",
                9742,
                "telishagedolahebrew",
                1440,
                "telishaqetanahebrew",
                1449,
                "tencircle",
                9321,
                "tenideographicparen",
                12841,
                "tenparen",
                9341,
                "tenperiod",
                9361,
                "tenroman",
                8569,
                "tesh",
                679,
                "tet",
                1496,
                "tetdagesh",
                64312,
                "tetdageshhebrew",
                64312,
                "tethebrew",
                1496,
                "tetsecyrillic",
                1205,
                "tevirhebrew",
                1435,
                "tevirlefthebrew",
                1435,
                "thabengali",
                2469,
                "thadeva",
                2341,
                "thagujarati",
                2725,
                "thagurmukhi",
                2597,
                "thalarabic",
                1584,
                "thalfinalarabic",
                65196,
                "thanthakhatlowleftthai",
                63640,
                "thanthakhatlowrightthai",
                63639,
                "thanthakhatthai",
                3660,
                "thanthakhatupperleftthai",
                63638,
                "theharabic",
                1579,
                "thehfinalarabic",
                65178,
                "thehinitialarabic",
                65179,
                "thehmedialarabic",
                65180,
                "thereexists",
                8707,
                "therefore",
                8756,
                "theta",
                952,
                "theta1",
                977,
                "thetasymbolgreek",
                977,
                "thieuthacirclekorean",
                12921,
                "thieuthaparenkorean",
                12825,
                "thieuthcirclekorean",
                12907,
                "thieuthkorean",
                12620,
                "thieuthparenkorean",
                12811,
                "thirteencircle",
                9324,
                "thirteenparen",
                9344,
                "thirteenperiod",
                9364,
                "thonangmonthothai",
                3601,
                "thook",
                429,
                "thophuthaothai",
                3602,
                "thorn",
                254,
                "thothahanthai",
                3607,
                "thothanthai",
                3600,
                "thothongthai",
                3608,
                "thothungthai",
                3606,
                "thousandcyrillic",
                1154,
                "thousandsseparatorarabic",
                1644,
                "thousandsseparatorpersian",
                1644,
                "three",
                51,
                "threearabic",
                1635,
                "threebengali",
                2537,
                "threecircle",
                9314,
                "threecircleinversesansserif",
                10124,
                "threedeva",
                2409,
                "threeeighths",
                8540,
                "threegujarati",
                2793,
                "threegurmukhi",
                2665,
                "threehackarabic",
                1635,
                "threehangzhou",
                12323,
                "threeideographicparen",
                12834,
                "threeinferior",
                8323,
                "threemonospace",
                65299,
                "threenumeratorbengali",
                2550,
                "threeoldstyle",
                63283,
                "threeparen",
                9334,
                "threeperiod",
                9354,
                "threepersian",
                1779,
                "threequarters",
                190,
                "threequartersemdash",
                63198,
                "threeroman",
                8562,
                "threesuperior",
                179,
                "threethai",
                3667,
                "thzsquare",
                13204,
                "tihiragana",
                12385,
                "tikatakana",
                12481,
                "tikatakanahalfwidth",
                65409,
                "tikeutacirclekorean",
                12912,
                "tikeutaparenkorean",
                12816,
                "tikeutcirclekorean",
                12898,
                "tikeutkorean",
                12599,
                "tikeutparenkorean",
                12802,
                "tilde",
                732,
                "tildebelowcmb",
                816,
                "tildecmb",
                771,
                "tildecomb",
                771,
                "tildedoublecmb",
                864,
                "tildeoperator",
                8764,
                "tildeoverlaycmb",
                820,
                "tildeverticalcmb",
                830,
                "timescircle",
                8855,
                "tipehahebrew",
                1430,
                "tipehalefthebrew",
                1430,
                "tippigurmukhi",
                2672,
                "titlocyrilliccmb",
                1155,
                "tiwnarmenian",
                1407,
                "tlinebelow",
                7791,
                "tmonospace",
                65364,
                "toarmenian",
                1385,
                "tohiragana",
                12392,
                "tokatakana",
                12488,
                "tokatakanahalfwidth",
                65412,
                "tonebarextrahighmod",
                741,
                "tonebarextralowmod",
                745,
                "tonebarhighmod",
                742,
                "tonebarlowmod",
                744,
                "tonebarmidmod",
                743,
                "tonefive",
                445,
                "tonesix",
                389,
                "tonetwo",
                424,
                "tonos",
                900,
                "tonsquare",
                13095,
                "topatakthai",
                3599,
                "tortoiseshellbracketleft",
                12308,
                "tortoiseshellbracketleftsmall",
                65117,
                "tortoiseshellbracketleftvertical",
                65081,
                "tortoiseshellbracketright",
                12309,
                "tortoiseshellbracketrightsmall",
                65118,
                "tortoiseshellbracketrightvertical",
                65082,
                "totaothai",
                3605,
                "tpalatalhook",
                427,
                "tparen",
                9391,
                "trademark",
                8482,
                "trademarksans",
                63722,
                "trademarkserif",
                63195,
                "tretroflexhook",
                648,
                "triagdn",
                9660,
                "triaglf",
                9668,
                "triagrt",
                9658,
                "triagup",
                9650,
                "ts",
                678,
                "tsadi",
                1510,
                "tsadidagesh",
                64326,
                "tsadidageshhebrew",
                64326,
                "tsadihebrew",
                1510,
                "tsecyrillic",
                1094,
                "tsere",
                1461,
                "tsere12",
                1461,
                "tsere1e",
                1461,
                "tsere2b",
                1461,
                "tserehebrew",
                1461,
                "tserenarrowhebrew",
                1461,
                "tserequarterhebrew",
                1461,
                "tserewidehebrew",
                1461,
                "tshecyrillic",
                1115,
                "tsuperior",
                63219,
                "ttabengali",
                2463,
                "ttadeva",
                2335,
                "ttagujarati",
                2719,
                "ttagurmukhi",
                2591,
                "tteharabic",
                1657,
                "ttehfinalarabic",
                64359,
                "ttehinitialarabic",
                64360,
                "ttehmedialarabic",
                64361,
                "tthabengali",
                2464,
                "tthadeva",
                2336,
                "tthagujarati",
                2720,
                "tthagurmukhi",
                2592,
                "tturned",
                647,
                "tuhiragana",
                12388,
                "tukatakana",
                12484,
                "tukatakanahalfwidth",
                65410,
                "tusmallhiragana",
                12387,
                "tusmallkatakana",
                12483,
                "tusmallkatakanahalfwidth",
                65391,
                "twelvecircle",
                9323,
                "twelveparen",
                9343,
                "twelveperiod",
                9363,
                "twelveroman",
                8571,
                "twentycircle",
                9331,
                "twentyhangzhou",
                21316,
                "twentyparen",
                9351,
                "twentyperiod",
                9371,
                "two",
                50,
                "twoarabic",
                1634,
                "twobengali",
                2536,
                "twocircle",
                9313,
                "twocircleinversesansserif",
                10123,
                "twodeva",
                2408,
                "twodotenleader",
                8229,
                "twodotleader",
                8229,
                "twodotleadervertical",
                65072,
                "twogujarati",
                2792,
                "twogurmukhi",
                2664,
                "twohackarabic",
                1634,
                "twohangzhou",
                12322,
                "twoideographicparen",
                12833,
                "twoinferior",
                8322,
                "twomonospace",
                65298,
                "twonumeratorbengali",
                2549,
                "twooldstyle",
                63282,
                "twoparen",
                9333,
                "twoperiod",
                9353,
                "twopersian",
                1778,
                "tworoman",
                8561,
                "twostroke",
                443,
                "twosuperior",
                178,
                "twothai",
                3666,
                "twothirds",
                8532,
                "u",
                117,
                "uacute",
                250,
                "ubar",
                649,
                "ubengali",
                2441,
                "ubopomofo",
                12584,
                "ubreve",
                365,
                "ucaron",
                468,
                "ucircle",
                9444,
                "ucircumflex",
                251,
                "ucircumflexbelow",
                7799,
                "ucyrillic",
                1091,
                "udattadeva",
                2385,
                "udblacute",
                369,
                "udblgrave",
                533,
                "udeva",
                2313,
                "udieresis",
                252,
                "udieresisacute",
                472,
                "udieresisbelow",
                7795,
                "udieresiscaron",
                474,
                "udieresiscyrillic",
                1265,
                "udieresisgrave",
                476,
                "udieresismacron",
                470,
                "udotbelow",
                7909,
                "ugrave",
                249,
                "ugujarati",
                2697,
                "ugurmukhi",
                2569,
                "uhiragana",
                12358,
                "uhookabove",
                7911,
                "uhorn",
                432,
                "uhornacute",
                7913,
                "uhorndotbelow",
                7921,
                "uhorngrave",
                7915,
                "uhornhookabove",
                7917,
                "uhorntilde",
                7919,
                "uhungarumlaut",
                369,
                "uhungarumlautcyrillic",
                1267,
                "uinvertedbreve",
                535,
                "ukatakana",
                12454,
                "ukatakanahalfwidth",
                65395,
                "ukcyrillic",
                1145,
                "ukorean",
                12636,
                "umacron",
                363,
                "umacroncyrillic",
                1263,
                "umacrondieresis",
                7803,
                "umatragurmukhi",
                2625,
                "umonospace",
                65365,
                "underscore",
                95,
                "underscoredbl",
                8215,
                "underscoremonospace",
                65343,
                "underscorevertical",
                65075,
                "underscorewavy",
                65103,
                "union",
                8746,
                "universal",
                8704,
                "uogonek",
                371,
                "uparen",
                9392,
                "upblock",
                9600,
                "upperdothebrew",
                1476,
                "upsilon",
                965,
                "upsilondieresis",
                971,
                "upsilondieresistonos",
                944,
                "upsilonlatin",
                650,
                "upsilontonos",
                973,
                "uptackbelowcmb",
                797,
                "uptackmod",
                724,
                "uragurmukhi",
                2675,
                "uring",
                367,
                "ushortcyrillic",
                1118,
                "usmallhiragana",
                12357,
                "usmallkatakana",
                12453,
                "usmallkatakanahalfwidth",
                65385,
                "ustraightcyrillic",
                1199,
                "ustraightstrokecyrillic",
                1201,
                "utilde",
                361,
                "utildeacute",
                7801,
                "utildebelow",
                7797,
                "uubengali",
                2442,
                "uudeva",
                2314,
                "uugujarati",
                2698,
                "uugurmukhi",
                2570,
                "uumatragurmukhi",
                2626,
                "uuvowelsignbengali",
                2498,
                "uuvowelsigndeva",
                2370,
                "uuvowelsigngujarati",
                2754,
                "uvowelsignbengali",
                2497,
                "uvowelsigndeva",
                2369,
                "uvowelsigngujarati",
                2753,
                "v",
                118,
                "vadeva",
                2357,
                "vagujarati",
                2741,
                "vagurmukhi",
                2613,
                "vakatakana",
                12535,
                "vav",
                1493,
                "vavdagesh",
                64309,
                "vavdagesh65",
                64309,
                "vavdageshhebrew",
                64309,
                "vavhebrew",
                1493,
                "vavholam",
                64331,
                "vavholamhebrew",
                64331,
                "vavvavhebrew",
                1520,
                "vavyodhebrew",
                1521,
                "vcircle",
                9445,
                "vdotbelow",
                7807,
                "vecyrillic",
                1074,
                "veharabic",
                1700,
                "vehfinalarabic",
                64363,
                "vehinitialarabic",
                64364,
                "vehmedialarabic",
                64365,
                "vekatakana",
                12537,
                "venus",
                9792,
                "verticalbar",
                124,
                "verticallineabovecmb",
                781,
                "verticallinebelowcmb",
                809,
                "verticallinelowmod",
                716,
                "verticallinemod",
                712,
                "vewarmenian",
                1406,
                "vhook",
                651,
                "vikatakana",
                12536,
                "viramabengali",
                2509,
                "viramadeva",
                2381,
                "viramagujarati",
                2765,
                "visargabengali",
                2435,
                "visargadeva",
                2307,
                "visargagujarati",
                2691,
                "vmonospace",
                65366,
                "voarmenian",
                1400,
                "voicediterationhiragana",
                12446,
                "voicediterationkatakana",
                12542,
                "voicedmarkkana",
                12443,
                "voicedmarkkanahalfwidth",
                65438,
                "vokatakana",
                12538,
                "vparen",
                9393,
                "vtilde",
                7805,
                "vturned",
                652,
                "vuhiragana",
                12436,
                "vukatakana",
                12532,
                "w",
                119,
                "wacute",
                7811,
                "waekorean",
                12633,
                "wahiragana",
                12431,
                "wakatakana",
                12527,
                "wakatakanahalfwidth",
                65436,
                "wakorean",
                12632,
                "wasmallhiragana",
                12430,
                "wasmallkatakana",
                12526,
                "wattosquare",
                13143,
                "wavedash",
                12316,
                "wavyunderscorevertical",
                65076,
                "wawarabic",
                1608,
                "wawfinalarabic",
                65262,
                "wawhamzaabovearabic",
                1572,
                "wawhamzaabovefinalarabic",
                65158,
                "wbsquare",
                13277,
                "wcircle",
                9446,
                "wcircumflex",
                373,
                "wdieresis",
                7813,
                "wdotaccent",
                7815,
                "wdotbelow",
                7817,
                "wehiragana",
                12433,
                "weierstrass",
                8472,
                "wekatakana",
                12529,
                "wekorean",
                12638,
                "weokorean",
                12637,
                "wgrave",
                7809,
                "whitebullet",
                9702,
                "whitecircle",
                9675,
                "whitecircleinverse",
                9689,
                "whitecornerbracketleft",
                12302,
                "whitecornerbracketleftvertical",
                65091,
                "whitecornerbracketright",
                12303,
                "whitecornerbracketrightvertical",
                65092,
                "whitediamond",
                9671,
                "whitediamondcontainingblacksmalldiamond",
                9672,
                "whitedownpointingsmalltriangle",
                9663,
                "whitedownpointingtriangle",
                9661,
                "whiteleftpointingsmalltriangle",
                9667,
                "whiteleftpointingtriangle",
                9665,
                "whitelenticularbracketleft",
                12310,
                "whitelenticularbracketright",
                12311,
                "whiterightpointingsmalltriangle",
                9657,
                "whiterightpointingtriangle",
                9655,
                "whitesmallsquare",
                9643,
                "whitesmilingface",
                9786,
                "whitesquare",
                9633,
                "whitestar",
                9734,
                "whitetelephone",
                9743,
                "whitetortoiseshellbracketleft",
                12312,
                "whitetortoiseshellbracketright",
                12313,
                "whiteuppointingsmalltriangle",
                9653,
                "whiteuppointingtriangle",
                9651,
                "wihiragana",
                12432,
                "wikatakana",
                12528,
                "wikorean",
                12639,
                "wmonospace",
                65367,
                "wohiragana",
                12434,
                "wokatakana",
                12530,
                "wokatakanahalfwidth",
                65382,
                "won",
                8361,
                "wonmonospace",
                65510,
                "wowaenthai",
                3623,
                "wparen",
                9394,
                "wring",
                7832,
                "wsuperior",
                695,
                "wturned",
                653,
                "wynn",
                447,
                "x",
                120,
                "xabovecmb",
                829,
                "xbopomofo",
                12562,
                "xcircle",
                9447,
                "xdieresis",
                7821,
                "xdotaccent",
                7819,
                "xeharmenian",
                1389,
                "xi",
                958,
                "xmonospace",
                65368,
                "xparen",
                9395,
                "xsuperior",
                739,
                "y",
                121,
                "yaadosquare",
                13134,
                "yabengali",
                2479,
                "yacute",
                253,
                "yadeva",
                2351,
                "yaekorean",
                12626,
                "yagujarati",
                2735,
                "yagurmukhi",
                2607,
                "yahiragana",
                12420,
                "yakatakana",
                12516,
                "yakatakanahalfwidth",
                65428,
                "yakorean",
                12625,
                "yamakkanthai",
                3662,
                "yasmallhiragana",
                12419,
                "yasmallkatakana",
                12515,
                "yasmallkatakanahalfwidth",
                65388,
                "yatcyrillic",
                1123,
                "ycircle",
                9448,
                "ycircumflex",
                375,
                "ydieresis",
                255,
                "ydotaccent",
                7823,
                "ydotbelow",
                7925,
                "yeharabic",
                1610,
                "yehbarreearabic",
                1746,
                "yehbarreefinalarabic",
                64431,
                "yehfinalarabic",
                65266,
                "yehhamzaabovearabic",
                1574,
                "yehhamzaabovefinalarabic",
                65162,
                "yehhamzaaboveinitialarabic",
                65163,
                "yehhamzaabovemedialarabic",
                65164,
                "yehinitialarabic",
                65267,
                "yehmedialarabic",
                65268,
                "yehmeeminitialarabic",
                64733,
                "yehmeemisolatedarabic",
                64600,
                "yehnoonfinalarabic",
                64660,
                "yehthreedotsbelowarabic",
                1745,
                "yekorean",
                12630,
                "yen",
                165,
                "yenmonospace",
                65509,
                "yeokorean",
                12629,
                "yeorinhieuhkorean",
                12678,
                "yerahbenyomohebrew",
                1450,
                "yerahbenyomolefthebrew",
                1450,
                "yericyrillic",
                1099,
                "yerudieresiscyrillic",
                1273,
                "yesieungkorean",
                12673,
                "yesieungpansioskorean",
                12675,
                "yesieungsioskorean",
                12674,
                "yetivhebrew",
                1434,
                "ygrave",
                7923,
                "yhook",
                436,
                "yhookabove",
                7927,
                "yiarmenian",
                1397,
                "yicyrillic",
                1111,
                "yikorean",
                12642,
                "yinyang",
                9775,
                "yiwnarmenian",
                1410,
                "ymonospace",
                65369,
                "yod",
                1497,
                "yoddagesh",
                64313,
                "yoddageshhebrew",
                64313,
                "yodhebrew",
                1497,
                "yodyodhebrew",
                1522,
                "yodyodpatahhebrew",
                64287,
                "yohiragana",
                12424,
                "yoikorean",
                12681,
                "yokatakana",
                12520,
                "yokatakanahalfwidth",
                65430,
                "yokorean",
                12635,
                "yosmallhiragana",
                12423,
                "yosmallkatakana",
                12519,
                "yosmallkatakanahalfwidth",
                65390,
                "yotgreek",
                1011,
                "yoyaekorean",
                12680,
                "yoyakorean",
                12679,
                "yoyakthai",
                3618,
                "yoyingthai",
                3597,
                "yparen",
                9396,
                "ypogegrammeni",
                890,
                "ypogegrammenigreekcmb",
                837,
                "yr",
                422,
                "yring",
                7833,
                "ysuperior",
                696,
                "ytilde",
                7929,
                "yturned",
                654,
                "yuhiragana",
                12422,
                "yuikorean",
                12684,
                "yukatakana",
                12518,
                "yukatakanahalfwidth",
                65429,
                "yukorean",
                12640,
                "yusbigcyrillic",
                1131,
                "yusbigiotifiedcyrillic",
                1133,
                "yuslittlecyrillic",
                1127,
                "yuslittleiotifiedcyrillic",
                1129,
                "yusmallhiragana",
                12421,
                "yusmallkatakana",
                12517,
                "yusmallkatakanahalfwidth",
                65389,
                "yuyekorean",
                12683,
                "yuyeokorean",
                12682,
                "yyabengali",
                2527,
                "yyadeva",
                2399,
                "z",
                122,
                "zaarmenian",
                1382,
                "zacute",
                378,
                "zadeva",
                2395,
                "zagurmukhi",
                2651,
                "zaharabic",
                1592,
                "zahfinalarabic",
                65222,
                "zahinitialarabic",
                65223,
                "zahiragana",
                12374,
                "zahmedialarabic",
                65224,
                "zainarabic",
                1586,
                "zainfinalarabic",
                65200,
                "zakatakana",
                12470,
                "zaqefgadolhebrew",
                1429,
                "zaqefqatanhebrew",
                1428,
                "zarqahebrew",
                1432,
                "zayin",
                1494,
                "zayindagesh",
                64310,
                "zayindageshhebrew",
                64310,
                "zayinhebrew",
                1494,
                "zbopomofo",
                12567,
                "zcaron",
                382,
                "zcircle",
                9449,
                "zcircumflex",
                7825,
                "zcurl",
                657,
                "zdot",
                380,
                "zdotaccent",
                380,
                "zdotbelow",
                7827,
                "zecyrillic",
                1079,
                "zedescendercyrillic",
                1177,
                "zedieresiscyrillic",
                1247,
                "zehiragana",
                12380,
                "zekatakana",
                12476,
                "zero",
                48,
                "zeroarabic",
                1632,
                "zerobengali",
                2534,
                "zerodeva",
                2406,
                "zerogujarati",
                2790,
                "zerogurmukhi",
                2662,
                "zerohackarabic",
                1632,
                "zeroinferior",
                8320,
                "zeromonospace",
                65296,
                "zerooldstyle",
                63280,
                "zeropersian",
                1776,
                "zerosuperior",
                8304,
                "zerothai",
                3664,
                "zerowidthjoiner",
                65279,
                "zerowidthnonjoiner",
                8204,
                "zerowidthspace",
                8203,
                "zeta",
                950,
                "zhbopomofo",
                12563,
                "zhearmenian",
                1386,
                "zhebrevecyrillic",
                1218,
                "zhecyrillic",
                1078,
                "zhedescendercyrillic",
                1175,
                "zhedieresiscyrillic",
                1245,
                "zihiragana",
                12376,
                "zikatakana",
                12472,
                "zinorhebrew",
                1454,
                "zlinebelow",
                7829,
                "zmonospace",
                65370,
                "zohiragana",
                12382,
                "zokatakana",
                12478,
                "zparen",
                9397,
                "zretroflexhook",
                656,
                "zstroke",
                438,
                "zuhiragana",
                12378,
                "zukatakana",
                12474,
                ".notdef",
                0,
                "angbracketleftbig",
                9001,
                "angbracketleftBig",
                9001,
                "angbracketleftbigg",
                9001,
                "angbracketleftBigg",
                9001,
                "angbracketrightBig",
                9002,
                "angbracketrightbig",
                9002,
                "angbracketrightBigg",
                9002,
                "angbracketrightbigg",
                9002,
                "arrowhookleft",
                8618,
                "arrowhookright",
                8617,
                "arrowlefttophalf",
                8636,
                "arrowleftbothalf",
                8637,
                "arrownortheast",
                8599,
                "arrownorthwest",
                8598,
                "arrowrighttophalf",
                8640,
                "arrowrightbothalf",
                8641,
                "arrowsoutheast",
                8600,
                "arrowsouthwest",
                8601,
                "backslashbig",
                8726,
                "backslashBig",
                8726,
                "backslashBigg",
                8726,
                "backslashbigg",
                8726,
                "bardbl",
                8214,
                "bracehtipdownleft",
                65079,
                "bracehtipdownright",
                65079,
                "bracehtipupleft",
                65080,
                "bracehtipupright",
                65080,
                "braceleftBig",
                123,
                "braceleftbig",
                123,
                "braceleftbigg",
                123,
                "braceleftBigg",
                123,
                "bracerightBig",
                125,
                "bracerightbig",
                125,
                "bracerightbigg",
                125,
                "bracerightBigg",
                125,
                "bracketleftbig",
                91,
                "bracketleftBig",
                91,
                "bracketleftbigg",
                91,
                "bracketleftBigg",
                91,
                "bracketrightBig",
                93,
                "bracketrightbig",
                93,
                "bracketrightbigg",
                93,
                "bracketrightBigg",
                93,
                "ceilingleftbig",
                8968,
                "ceilingleftBig",
                8968,
                "ceilingleftBigg",
                8968,
                "ceilingleftbigg",
                8968,
                "ceilingrightbig",
                8969,
                "ceilingrightBig",
                8969,
                "ceilingrightbigg",
                8969,
                "ceilingrightBigg",
                8969,
                "circledotdisplay",
                8857,
                "circledottext",
                8857,
                "circlemultiplydisplay",
                8855,
                "circlemultiplytext",
                8855,
                "circleplusdisplay",
                8853,
                "circleplustext",
                8853,
                "contintegraldisplay",
                8750,
                "contintegraltext",
                8750,
                "coproductdisplay",
                8720,
                "coproducttext",
                8720,
                "floorleftBig",
                8970,
                "floorleftbig",
                8970,
                "floorleftbigg",
                8970,
                "floorleftBigg",
                8970,
                "floorrightbig",
                8971,
                "floorrightBig",
                8971,
                "floorrightBigg",
                8971,
                "floorrightbigg",
                8971,
                "hatwide",
                770,
                "hatwider",
                770,
                "hatwidest",
                770,
                "intercal",
                7488,
                "integraldisplay",
                8747,
                "integraltext",
                8747,
                "intersectiondisplay",
                8898,
                "intersectiontext",
                8898,
                "logicalanddisplay",
                8743,
                "logicalandtext",
                8743,
                "logicalordisplay",
                8744,
                "logicalortext",
                8744,
                "parenleftBig",
                40,
                "parenleftbig",
                40,
                "parenleftBigg",
                40,
                "parenleftbigg",
                40,
                "parenrightBig",
                41,
                "parenrightbig",
                41,
                "parenrightBigg",
                41,
                "parenrightbigg",
                41,
                "prime",
                8242,
                "productdisplay",
                8719,
                "producttext",
                8719,
                "radicalbig",
                8730,
                "radicalBig",
                8730,
                "radicalBigg",
                8730,
                "radicalbigg",
                8730,
                "radicalbt",
                8730,
                "radicaltp",
                8730,
                "radicalvertex",
                8730,
                "slashbig",
                47,
                "slashBig",
                47,
                "slashBigg",
                47,
                "slashbigg",
                47,
                "summationdisplay",
                8721,
                "summationtext",
                8721,
                "tildewide",
                732,
                "tildewider",
                732,
                "tildewidest",
                732,
                "uniondisplay",
                8899,
                "unionmultidisplay",
                8846,
                "unionmultitext",
                8846,
                "unionsqdisplay",
                8852,
                "unionsqtext",
                8852,
                "uniontext",
                8899,
                "vextenddouble",
                8741,
                "vextendsingle",
                8739,
              ];
            }),
            i = (0, r.getArrayLookupTableFactory)(function () {
              return [
                "space",
                32,
                "a1",
                9985,
                "a2",
                9986,
                "a202",
                9987,
                "a3",
                9988,
                "a4",
                9742,
                "a5",
                9990,
                "a119",
                9991,
                "a118",
                9992,
                "a117",
                9993,
                "a11",
                9755,
                "a12",
                9758,
                "a13",
                9996,
                "a14",
                9997,
                "a15",
                9998,
                "a16",
                9999,
                "a105",
                1e4,
                "a17",
                10001,
                "a18",
                10002,
                "a19",
                10003,
                "a20",
                10004,
                "a21",
                10005,
                "a22",
                10006,
                "a23",
                10007,
                "a24",
                10008,
                "a25",
                10009,
                "a26",
                10010,
                "a27",
                10011,
                "a28",
                10012,
                "a6",
                10013,
                "a7",
                10014,
                "a8",
                10015,
                "a9",
                10016,
                "a10",
                10017,
                "a29",
                10018,
                "a30",
                10019,
                "a31",
                10020,
                "a32",
                10021,
                "a33",
                10022,
                "a34",
                10023,
                "a35",
                9733,
                "a36",
                10025,
                "a37",
                10026,
                "a38",
                10027,
                "a39",
                10028,
                "a40",
                10029,
                "a41",
                10030,
                "a42",
                10031,
                "a43",
                10032,
                "a44",
                10033,
                "a45",
                10034,
                "a46",
                10035,
                "a47",
                10036,
                "a48",
                10037,
                "a49",
                10038,
                "a50",
                10039,
                "a51",
                10040,
                "a52",
                10041,
                "a53",
                10042,
                "a54",
                10043,
                "a55",
                10044,
                "a56",
                10045,
                "a57",
                10046,
                "a58",
                10047,
                "a59",
                10048,
                "a60",
                10049,
                "a61",
                10050,
                "a62",
                10051,
                "a63",
                10052,
                "a64",
                10053,
                "a65",
                10054,
                "a66",
                10055,
                "a67",
                10056,
                "a68",
                10057,
                "a69",
                10058,
                "a70",
                10059,
                "a71",
                9679,
                "a72",
                10061,
                "a73",
                9632,
                "a74",
                10063,
                "a203",
                10064,
                "a75",
                10065,
                "a204",
                10066,
                "a76",
                9650,
                "a77",
                9660,
                "a78",
                9670,
                "a79",
                10070,
                "a81",
                9687,
                "a82",
                10072,
                "a83",
                10073,
                "a84",
                10074,
                "a97",
                10075,
                "a98",
                10076,
                "a99",
                10077,
                "a100",
                10078,
                "a101",
                10081,
                "a102",
                10082,
                "a103",
                10083,
                "a104",
                10084,
                "a106",
                10085,
                "a107",
                10086,
                "a108",
                10087,
                "a112",
                9827,
                "a111",
                9830,
                "a110",
                9829,
                "a109",
                9824,
                "a120",
                9312,
                "a121",
                9313,
                "a122",
                9314,
                "a123",
                9315,
                "a124",
                9316,
                "a125",
                9317,
                "a126",
                9318,
                "a127",
                9319,
                "a128",
                9320,
                "a129",
                9321,
                "a130",
                10102,
                "a131",
                10103,
                "a132",
                10104,
                "a133",
                10105,
                "a134",
                10106,
                "a135",
                10107,
                "a136",
                10108,
                "a137",
                10109,
                "a138",
                10110,
                "a139",
                10111,
                "a140",
                10112,
                "a141",
                10113,
                "a142",
                10114,
                "a143",
                10115,
                "a144",
                10116,
                "a145",
                10117,
                "a146",
                10118,
                "a147",
                10119,
                "a148",
                10120,
                "a149",
                10121,
                "a150",
                10122,
                "a151",
                10123,
                "a152",
                10124,
                "a153",
                10125,
                "a154",
                10126,
                "a155",
                10127,
                "a156",
                10128,
                "a157",
                10129,
                "a158",
                10130,
                "a159",
                10131,
                "a160",
                10132,
                "a161",
                8594,
                "a163",
                8596,
                "a164",
                8597,
                "a196",
                10136,
                "a165",
                10137,
                "a192",
                10138,
                "a166",
                10139,
                "a167",
                10140,
                "a168",
                10141,
                "a169",
                10142,
                "a170",
                10143,
                "a171",
                10144,
                "a172",
                10145,
                "a173",
                10146,
                "a162",
                10147,
                "a174",
                10148,
                "a175",
                10149,
                "a176",
                10150,
                "a177",
                10151,
                "a178",
                10152,
                "a179",
                10153,
                "a193",
                10154,
                "a180",
                10155,
                "a199",
                10156,
                "a181",
                10157,
                "a200",
                10158,
                "a182",
                10159,
                "a201",
                10161,
                "a183",
                10162,
                "a184",
                10163,
                "a197",
                10164,
                "a185",
                10165,
                "a194",
                10166,
                "a198",
                10167,
                "a186",
                10168,
                "a195",
                10169,
                "a187",
                10170,
                "a188",
                10171,
                "a189",
                10172,
                "a190",
                10173,
                "a191",
                10174,
                "a89",
                10088,
                "a90",
                10089,
                "a93",
                10090,
                "a94",
                10091,
                "a91",
                10092,
                "a92",
                10093,
                "a205",
                10094,
                "a85",
                10095,
                "a206",
                10096,
                "a86",
                10097,
                "a87",
                10098,
                "a88",
                10099,
                "a95",
                10100,
                "a96",
                10101,
                ".notdef",
                0,
              ];
            });
        },
        (e, t, a) => {
          a.r(t);
          a.d(t, {
            clearUnicodeCaches: () => clearUnicodeCaches,
            getCharUnicodeCategory: () => getCharUnicodeCategory,
            getNormalizedUnicodes: () => s,
            getUnicodeForGlyph: () => getUnicodeForGlyph,
            getUnicodeRangeFor: () => getUnicodeRangeFor,
            mapSpecialUnicodeValues: () => mapSpecialUnicodeValues,
            reverseIfRtl: () => reverseIfRtl,
          });
          var r = a(3);
          const n = (0, r.getLookupTableFactory)(function (e) {
            e[63721] = 169;
            e[63193] = 169;
            e[63720] = 174;
            e[63194] = 174;
            e[63722] = 8482;
            e[63195] = 8482;
            e[63729] = 9127;
            e[63730] = 9128;
            e[63731] = 9129;
            e[63740] = 9131;
            e[63741] = 9132;
            e[63742] = 9133;
            e[63726] = 9121;
            e[63727] = 9122;
            e[63728] = 9123;
            e[63737] = 9124;
            e[63738] = 9125;
            e[63739] = 9126;
            e[63723] = 9115;
            e[63724] = 9116;
            e[63725] = 9117;
            e[63734] = 9118;
            e[63735] = 9119;
            e[63736] = 9120;
          });
          function mapSpecialUnicodeValues(e) {
            return e >= 65520 && e <= 65535
              ? 0
              : e >= 62976 && e <= 63743
              ? n()[e] || e
              : 173 === e
              ? 45
              : e;
          }
          function getUnicodeForGlyph(e, t) {
            let a = t[e];
            if (void 0 !== a) return a;
            if (!e) return -1;
            if ("u" === e[0]) {
              const t = e.length;
              let r;
              if (7 === t && "n" === e[1] && "i" === e[2]) r = e.substring(3);
              else {
                if (!(t >= 5 && t <= 7)) return -1;
                r = e.substring(1);
              }
              if (r === r.toUpperCase()) {
                a = parseInt(r, 16);
                if (a >= 0) return a;
              }
            }
            return -1;
          }
          const i = [
            { begin: 0, end: 127 },
            { begin: 128, end: 255 },
            { begin: 256, end: 383 },
            { begin: 384, end: 591 },
            { begin: 592, end: 687 },
            { begin: 688, end: 767 },
            { begin: 768, end: 879 },
            { begin: 880, end: 1023 },
            { begin: 11392, end: 11519 },
            { begin: 1024, end: 1279 },
            { begin: 1328, end: 1423 },
            { begin: 1424, end: 1535 },
            { begin: 42240, end: 42559 },
            { begin: 1536, end: 1791 },
            { begin: 1984, end: 2047 },
            { begin: 2304, end: 2431 },
            { begin: 2432, end: 2559 },
            { begin: 2560, end: 2687 },
            { begin: 2688, end: 2815 },
            { begin: 2816, end: 2943 },
            { begin: 2944, end: 3071 },
            { begin: 3072, end: 3199 },
            { begin: 3200, end: 3327 },
            { begin: 3328, end: 3455 },
            { begin: 3584, end: 3711 },
            { begin: 3712, end: 3839 },
            { begin: 4256, end: 4351 },
            { begin: 6912, end: 7039 },
            { begin: 4352, end: 4607 },
            { begin: 7680, end: 7935 },
            { begin: 7936, end: 8191 },
            { begin: 8192, end: 8303 },
            { begin: 8304, end: 8351 },
            { begin: 8352, end: 8399 },
            { begin: 8400, end: 8447 },
            { begin: 8448, end: 8527 },
            { begin: 8528, end: 8591 },
            { begin: 8592, end: 8703 },
            { begin: 8704, end: 8959 },
            { begin: 8960, end: 9215 },
            { begin: 9216, end: 9279 },
            { begin: 9280, end: 9311 },
            { begin: 9312, end: 9471 },
            { begin: 9472, end: 9599 },
            { begin: 9600, end: 9631 },
            { begin: 9632, end: 9727 },
            { begin: 9728, end: 9983 },
            { begin: 9984, end: 10175 },
            { begin: 12288, end: 12351 },
            { begin: 12352, end: 12447 },
            { begin: 12448, end: 12543 },
            { begin: 12544, end: 12591 },
            { begin: 12592, end: 12687 },
            { begin: 43072, end: 43135 },
            { begin: 12800, end: 13055 },
            { begin: 13056, end: 13311 },
            { begin: 44032, end: 55215 },
            { begin: 55296, end: 57343 },
            { begin: 67840, end: 67871 },
            { begin: 19968, end: 40959 },
            { begin: 57344, end: 63743 },
            { begin: 12736, end: 12783 },
            { begin: 64256, end: 64335 },
            { begin: 64336, end: 65023 },
            { begin: 65056, end: 65071 },
            { begin: 65040, end: 65055 },
            { begin: 65104, end: 65135 },
            { begin: 65136, end: 65279 },
            { begin: 65280, end: 65519 },
            { begin: 65520, end: 65535 },
            { begin: 3840, end: 4095 },
            { begin: 1792, end: 1871 },
            { begin: 1920, end: 1983 },
            { begin: 3456, end: 3583 },
            { begin: 4096, end: 4255 },
            { begin: 4608, end: 4991 },
            { begin: 5024, end: 5119 },
            { begin: 5120, end: 5759 },
            { begin: 5760, end: 5791 },
            { begin: 5792, end: 5887 },
            { begin: 6016, end: 6143 },
            { begin: 6144, end: 6319 },
            { begin: 10240, end: 10495 },
            { begin: 40960, end: 42127 },
            { begin: 5888, end: 5919 },
            { begin: 66304, end: 66351 },
            { begin: 66352, end: 66383 },
            { begin: 66560, end: 66639 },
            { begin: 118784, end: 119039 },
            { begin: 119808, end: 120831 },
            { begin: 1044480, end: 1048573 },
            { begin: 65024, end: 65039 },
            { begin: 917504, end: 917631 },
            { begin: 6400, end: 6479 },
            { begin: 6480, end: 6527 },
            { begin: 6528, end: 6623 },
            { begin: 6656, end: 6687 },
            { begin: 11264, end: 11359 },
            { begin: 11568, end: 11647 },
            { begin: 19904, end: 19967 },
            { begin: 43008, end: 43055 },
            { begin: 65536, end: 65663 },
            { begin: 65856, end: 65935 },
            { begin: 66432, end: 66463 },
            { begin: 66464, end: 66527 },
            { begin: 66640, end: 66687 },
            { begin: 66688, end: 66735 },
            { begin: 67584, end: 67647 },
            { begin: 68096, end: 68191 },
            { begin: 119552, end: 119647 },
            { begin: 73728, end: 74751 },
            { begin: 119648, end: 119679 },
            { begin: 7040, end: 7103 },
            { begin: 7168, end: 7247 },
            { begin: 7248, end: 7295 },
            { begin: 43136, end: 43231 },
            { begin: 43264, end: 43311 },
            { begin: 43312, end: 43359 },
            { begin: 43520, end: 43615 },
            { begin: 65936, end: 65999 },
            { begin: 66e3, end: 66047 },
            { begin: 66208, end: 66271 },
            { begin: 127024, end: 127135 },
          ];
          function getUnicodeRangeFor(e) {
            for (let t = 0, a = i.length; t < a; t++) {
              const a = i[t];
              if (e >= a.begin && e < a.end) return t;
            }
            return -1;
          }
          const s = (0, r.getArrayLookupTableFactory)(function () {
            return [
              "¨",
              " ̈",
              "¯",
              " ̄",
              "´",
              " ́",
              "µ",
              "μ",
              "¸",
              " ̧",
              "Ĳ",
              "IJ",
              "ĳ",
              "ij",
              "Ŀ",
              "L·",
              "ŀ",
              "l·",
              "ŉ",
              "ʼn",
              "ſ",
              "s",
              "Ǆ",
              "DŽ",
              "ǅ",
              "Dž",
              "ǆ",
              "dž",
              "Ǉ",
              "LJ",
              "ǈ",
              "Lj",
              "ǉ",
              "lj",
              "Ǌ",
              "NJ",
              "ǋ",
              "Nj",
              "ǌ",
              "nj",
              "Ǳ",
              "DZ",
              "ǲ",
              "Dz",
              "ǳ",
              "dz",
              "˘",
              " ̆",
              "˙",
              " ̇",
              "˚",
              " ̊",
              "˛",
              " ̨",
              "˜",
              " ̃",
              "˝",
              " ̋",
              "ͺ",
              " ͅ",
              "΄",
              " ́",
              "ϐ",
              "β",
              "ϑ",
              "θ",
              "ϒ",
              "Υ",
              "ϕ",
              "φ",
              "ϖ",
              "π",
              "ϰ",
              "κ",
              "ϱ",
              "ρ",
              "ϲ",
              "ς",
              "ϴ",
              "Θ",
              "ϵ",
              "ε",
              "Ϲ",
              "Σ",
              "և",
              "եւ",
              "ٵ",
              "اٴ",
              "ٶ",
              "وٴ",
              "ٷ",
              "ۇٴ",
              "ٸ",
              "يٴ",
              "ำ",
              "ํา",
              "ຳ",
              "ໍາ",
              "ໜ",
              "ຫນ",
              "ໝ",
              "ຫມ",
              "ཷ",
              "ྲཱྀ",
              "ཹ",
              "ླཱྀ",
              "ẚ",
              "aʾ",
              "᾽",
              " ̓",
              "᾿",
              " ̓",
              "῀",
              " ͂",
              "῾",
              " ̔",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              "‗",
              " ̳",
              "․",
              ".",
              "‥",
              "..",
              "…",
              "...",
              "″",
              "′′",
              "‴",
              "′′′",
              "‶",
              "‵‵",
              "‷",
              "‵‵‵",
              "‼",
              "!!",
              "‾",
              " ̅",
              "⁇",
              "??",
              "⁈",
              "?!",
              "⁉",
              "!?",
              "⁗",
              "′′′′",
              " ",
              " ",
              "₨",
              "Rs",
              "℀",
              "a/c",
              "℁",
              "a/s",
              "℃",
              "°C",
              "℅",
              "c/o",
              "℆",
              "c/u",
              "ℇ",
              "Ɛ",
              "℉",
              "°F",
              "№",
              "No",
              "℡",
              "TEL",
              "ℵ",
              "א",
              "ℶ",
              "ב",
              "ℷ",
              "ג",
              "ℸ",
              "ד",
              "℻",
              "FAX",
              "Ⅰ",
              "I",
              "Ⅱ",
              "II",
              "Ⅲ",
              "III",
              "Ⅳ",
              "IV",
              "Ⅴ",
              "V",
              "Ⅵ",
              "VI",
              "Ⅶ",
              "VII",
              "Ⅷ",
              "VIII",
              "Ⅸ",
              "IX",
              "Ⅹ",
              "X",
              "Ⅺ",
              "XI",
              "Ⅻ",
              "XII",
              "Ⅼ",
              "L",
              "Ⅽ",
              "C",
              "Ⅾ",
              "D",
              "Ⅿ",
              "M",
              "ⅰ",
              "i",
              "ⅱ",
              "ii",
              "ⅲ",
              "iii",
              "ⅳ",
              "iv",
              "ⅴ",
              "v",
              "ⅵ",
              "vi",
              "ⅶ",
              "vii",
              "ⅷ",
              "viii",
              "ⅸ",
              "ix",
              "ⅹ",
              "x",
              "ⅺ",
              "xi",
              "ⅻ",
              "xii",
              "ⅼ",
              "l",
              "ⅽ",
              "c",
              "ⅾ",
              "d",
              "ⅿ",
              "m",
              "∬",
              "∫∫",
              "∭",
              "∫∫∫",
              "∯",
              "∮∮",
              "∰",
              "∮∮∮",
              "⑴",
              "(1)",
              "⑵",
              "(2)",
              "⑶",
              "(3)",
              "⑷",
              "(4)",
              "⑸",
              "(5)",
              "⑹",
              "(6)",
              "⑺",
              "(7)",
              "⑻",
              "(8)",
              "⑼",
              "(9)",
              "⑽",
              "(10)",
              "⑾",
              "(11)",
              "⑿",
              "(12)",
              "⒀",
              "(13)",
              "⒁",
              "(14)",
              "⒂",
              "(15)",
              "⒃",
              "(16)",
              "⒄",
              "(17)",
              "⒅",
              "(18)",
              "⒆",
              "(19)",
              "⒇",
              "(20)",
              "⒈",
              "1.",
              "⒉",
              "2.",
              "⒊",
              "3.",
              "⒋",
              "4.",
              "⒌",
              "5.",
              "⒍",
              "6.",
              "⒎",
              "7.",
              "⒏",
              "8.",
              "⒐",
              "9.",
              "⒑",
              "10.",
              "⒒",
              "11.",
              "⒓",
              "12.",
              "⒔",
              "13.",
              "⒕",
              "14.",
              "⒖",
              "15.",
              "⒗",
              "16.",
              "⒘",
              "17.",
              "⒙",
              "18.",
              "⒚",
              "19.",
              "⒛",
              "20.",
              "⒜",
              "(a)",
              "⒝",
              "(b)",
              "⒞",
              "(c)",
              "⒟",
              "(d)",
              "⒠",
              "(e)",
              "⒡",
              "(f)",
              "⒢",
              "(g)",
              "⒣",
              "(h)",
              "⒤",
              "(i)",
              "⒥",
              "(j)",
              "⒦",
              "(k)",
              "⒧",
              "(l)",
              "⒨",
              "(m)",
              "⒩",
              "(n)",
              "⒪",
              "(o)",
              "⒫",
              "(p)",
              "⒬",
              "(q)",
              "⒭",
              "(r)",
              "⒮",
              "(s)",
              "⒯",
              "(t)",
              "⒰",
              "(u)",
              "⒱",
              "(v)",
              "⒲",
              "(w)",
              "⒳",
              "(x)",
              "⒴",
              "(y)",
              "⒵",
              "(z)",
              "⨌",
              "∫∫∫∫",
              "⩴",
              "::=",
              "⩵",
              "==",
              "⩶",
              "===",
              "⺟",
              "母",
              "⻳",
              "龟",
              "⼀",
              "一",
              "⼁",
              "丨",
              "⼂",
              "丶",
              "⼃",
              "丿",
              "⼄",
              "乙",
              "⼅",
              "亅",
              "⼆",
              "二",
              "⼇",
              "亠",
              "⼈",
              "人",
              "⼉",
              "儿",
              "⼊",
              "入",
              "⼋",
              "八",
              "⼌",
              "冂",
              "⼍",
              "冖",
              "⼎",
              "冫",
              "⼏",
              "几",
              "⼐",
              "凵",
              "⼑",
              "刀",
              "⼒",
              "力",
              "⼓",
              "勹",
              "⼔",
              "匕",
              "⼕",
              "匚",
              "⼖",
              "匸",
              "⼗",
              "十",
              "⼘",
              "卜",
              "⼙",
              "卩",
              "⼚",
              "厂",
              "⼛",
              "厶",
              "⼜",
              "又",
              "⼝",
              "口",
              "⼞",
              "囗",
              "⼟",
              "土",
              "⼠",
              "士",
              "⼡",
              "夂",
              "⼢",
              "夊",
              "⼣",
              "夕",
              "⼤",
              "大",
              "⼥",
              "女",
              "⼦",
              "子",
              "⼧",
              "宀",
              "⼨",
              "寸",
              "⼩",
              "小",
              "⼪",
              "尢",
              "⼫",
              "尸",
              "⼬",
              "屮",
              "⼭",
              "山",
              "⼮",
              "巛",
              "⼯",
              "工",
              "⼰",
              "己",
              "⼱",
              "巾",
              "⼲",
              "干",
              "⼳",
              "幺",
              "⼴",
              "广",
              "⼵",
              "廴",
              "⼶",
              "廾",
              "⼷",
              "弋",
              "⼸",
              "弓",
              "⼹",
              "彐",
              "⼺",
              "彡",
              "⼻",
              "彳",
              "⼼",
              "心",
              "⼽",
              "戈",
              "⼾",
              "戶",
              "⼿",
              "手",
              "⽀",
              "支",
              "⽁",
              "攴",
              "⽂",
              "文",
              "⽃",
              "斗",
              "⽄",
              "斤",
              "⽅",
              "方",
              "⽆",
              "无",
              "⽇",
              "日",
              "⽈",
              "曰",
              "⽉",
              "月",
              "⽊",
              "木",
              "⽋",
              "欠",
              "⽌",
              "止",
              "⽍",
              "歹",
              "⽎",
              "殳",
              "⽏",
              "毋",
              "⽐",
              "比",
              "⽑",
              "毛",
              "⽒",
              "氏",
              "⽓",
              "气",
              "⽔",
              "水",
              "⽕",
              "火",
              "⽖",
              "爪",
              "⽗",
              "父",
              "⽘",
              "爻",
              "⽙",
              "爿",
              "⽚",
              "片",
              "⽛",
              "牙",
              "⽜",
              "牛",
              "⽝",
              "犬",
              "⽞",
              "玄",
              "⽟",
              "玉",
              "⽠",
              "瓜",
              "⽡",
              "瓦",
              "⽢",
              "甘",
              "⽣",
              "生",
              "⽤",
              "用",
              "⽥",
              "田",
              "⽦",
              "疋",
              "⽧",
              "疒",
              "⽨",
              "癶",
              "⽩",
              "白",
              "⽪",
              "皮",
              "⽫",
              "皿",
              "⽬",
              "目",
              "⽭",
              "矛",
              "⽮",
              "矢",
              "⽯",
              "石",
              "⽰",
              "示",
              "⽱",
              "禸",
              "⽲",
              "禾",
              "⽳",
              "穴",
              "⽴",
              "立",
              "⽵",
              "竹",
              "⽶",
              "米",
              "⽷",
              "糸",
              "⽸",
              "缶",
              "⽹",
              "网",
              "⽺",
              "羊",
              "⽻",
              "羽",
              "⽼",
              "老",
              "⽽",
              "而",
              "⽾",
              "耒",
              "⽿",
              "耳",
              "⾀",
              "聿",
              "⾁",
              "肉",
              "⾂",
              "臣",
              "⾃",
              "自",
              "⾄",
              "至",
              "⾅",
              "臼",
              "⾆",
              "舌",
              "⾇",
              "舛",
              "⾈",
              "舟",
              "⾉",
              "艮",
              "⾊",
              "色",
              "⾋",
              "艸",
              "⾌",
              "虍",
              "⾍",
              "虫",
              "⾎",
              "血",
              "⾏",
              "行",
              "⾐",
              "衣",
              "⾑",
              "襾",
              "⾒",
              "見",
              "⾓",
              "角",
              "⾔",
              "言",
              "⾕",
              "谷",
              "⾖",
              "豆",
              "⾗",
              "豕",
              "⾘",
              "豸",
              "⾙",
              "貝",
              "⾚",
              "赤",
              "⾛",
              "走",
              "⾜",
              "足",
              "⾝",
              "身",
              "⾞",
              "車",
              "⾟",
              "辛",
              "⾠",
              "辰",
              "⾡",
              "辵",
              "⾢",
              "邑",
              "⾣",
              "酉",
              "⾤",
              "釆",
              "⾥",
              "里",
              "⾦",
              "金",
              "⾧",
              "長",
              "⾨",
              "門",
              "⾩",
              "阜",
              "⾪",
              "隶",
              "⾫",
              "隹",
              "⾬",
              "雨",
              "⾭",
              "靑",
              "⾮",
              "非",
              "⾯",
              "面",
              "⾰",
              "革",
              "⾱",
              "韋",
              "⾲",
              "韭",
              "⾳",
              "音",
              "⾴",
              "頁",
              "⾵",
              "風",
              "⾶",
              "飛",
              "⾷",
              "食",
              "⾸",
              "首",
              "⾹",
              "香",
              "⾺",
              "馬",
              "⾻",
              "骨",
              "⾼",
              "高",
              "⾽",
              "髟",
              "⾾",
              "鬥",
              "⾿",
              "鬯",
              "⿀",
              "鬲",
              "⿁",
              "鬼",
              "⿂",
              "魚",
              "⿃",
              "鳥",
              "⿄",
              "鹵",
              "⿅",
              "鹿",
              "⿆",
              "麥",
              "⿇",
              "麻",
              "⿈",
              "黃",
              "⿉",
              "黍",
              "⿊",
              "黑",
              "⿋",
              "黹",
              "⿌",
              "黽",
              "⿍",
              "鼎",
              "⿎",
              "鼓",
              "⿏",
              "鼠",
              "⿐",
              "鼻",
              "⿑",
              "齊",
              "⿒",
              "齒",
              "⿓",
              "龍",
              "⿔",
              "龜",
              "⿕",
              "龠",
              "〶",
              "〒",
              "〸",
              "十",
              "〹",
              "卄",
              "〺",
              "卅",
              "゛",
              " ゙",
              "゜",
              " ゚",
              "ㄱ",
              "ᄀ",
              "ㄲ",
              "ᄁ",
              "ㄳ",
              "ᆪ",
              "ㄴ",
              "ᄂ",
              "ㄵ",
              "ᆬ",
              "ㄶ",
              "ᆭ",
              "ㄷ",
              "ᄃ",
              "ㄸ",
              "ᄄ",
              "ㄹ",
              "ᄅ",
              "ㄺ",
              "ᆰ",
              "ㄻ",
              "ᆱ",
              "ㄼ",
              "ᆲ",
              "ㄽ",
              "ᆳ",
              "ㄾ",
              "ᆴ",
              "ㄿ",
              "ᆵ",
              "ㅀ",
              "ᄚ",
              "ㅁ",
              "ᄆ",
              "ㅂ",
              "ᄇ",
              "ㅃ",
              "ᄈ",
              "ㅄ",
              "ᄡ",
              "ㅅ",
              "ᄉ",
              "ㅆ",
              "ᄊ",
              "ㅇ",
              "ᄋ",
              "ㅈ",
              "ᄌ",
              "ㅉ",
              "ᄍ",
              "ㅊ",
              "ᄎ",
              "ㅋ",
              "ᄏ",
              "ㅌ",
              "ᄐ",
              "ㅍ",
              "ᄑ",
              "ㅎ",
              "ᄒ",
              "ㅏ",
              "ᅡ",
              "ㅐ",
              "ᅢ",
              "ㅑ",
              "ᅣ",
              "ㅒ",
              "ᅤ",
              "ㅓ",
              "ᅥ",
              "ㅔ",
              "ᅦ",
              "ㅕ",
              "ᅧ",
              "ㅖ",
              "ᅨ",
              "ㅗ",
              "ᅩ",
              "ㅘ",
              "ᅪ",
              "ㅙ",
              "ᅫ",
              "ㅚ",
              "ᅬ",
              "ㅛ",
              "ᅭ",
              "ㅜ",
              "ᅮ",
              "ㅝ",
              "ᅯ",
              "ㅞ",
              "ᅰ",
              "ㅟ",
              "ᅱ",
              "ㅠ",
              "ᅲ",
              "ㅡ",
              "ᅳ",
              "ㅢ",
              "ᅴ",
              "ㅣ",
              "ᅵ",
              "ㅤ",
              "ᅠ",
              "ㅥ",
              "ᄔ",
              "ㅦ",
              "ᄕ",
              "ㅧ",
              "ᇇ",
              "ㅨ",
              "ᇈ",
              "ㅩ",
              "ᇌ",
              "ㅪ",
              "ᇎ",
              "ㅫ",
              "ᇓ",
              "ㅬ",
              "ᇗ",
              "ㅭ",
              "ᇙ",
              "ㅮ",
              "ᄜ",
              "ㅯ",
              "ᇝ",
              "ㅰ",
              "ᇟ",
              "ㅱ",
              "ᄝ",
              "ㅲ",
              "ᄞ",
              "ㅳ",
              "ᄠ",
              "ㅴ",
              "ᄢ",
              "ㅵ",
              "ᄣ",
              "ㅶ",
              "ᄧ",
              "ㅷ",
              "ᄩ",
              "ㅸ",
              "ᄫ",
              "ㅹ",
              "ᄬ",
              "ㅺ",
              "ᄭ",
              "ㅻ",
              "ᄮ",
              "ㅼ",
              "ᄯ",
              "ㅽ",
              "ᄲ",
              "ㅾ",
              "ᄶ",
              "ㅿ",
              "ᅀ",
              "ㆀ",
              "ᅇ",
              "ㆁ",
              "ᅌ",
              "ㆂ",
              "ᇱ",
              "ㆃ",
              "ᇲ",
              "ㆄ",
              "ᅗ",
              "ㆅ",
              "ᅘ",
              "ㆆ",
              "ᅙ",
              "ㆇ",
              "ᆄ",
              "ㆈ",
              "ᆅ",
              "ㆉ",
              "ᆈ",
              "ㆊ",
              "ᆑ",
              "ㆋ",
              "ᆒ",
              "ㆌ",
              "ᆔ",
              "ㆍ",
              "ᆞ",
              "ㆎ",
              "ᆡ",
              "㈀",
              "(ᄀ)",
              "㈁",
              "(ᄂ)",
              "㈂",
              "(ᄃ)",
              "㈃",
              "(ᄅ)",
              "㈄",
              "(ᄆ)",
              "㈅",
              "(ᄇ)",
              "㈆",
              "(ᄉ)",
              "㈇",
              "(ᄋ)",
              "㈈",
              "(ᄌ)",
              "㈉",
              "(ᄎ)",
              "㈊",
              "(ᄏ)",
              "㈋",
              "(ᄐ)",
              "㈌",
              "(ᄑ)",
              "㈍",
              "(ᄒ)",
              "㈎",
              "(가)",
              "㈏",
              "(나)",
              "㈐",
              "(다)",
              "㈑",
              "(라)",
              "㈒",
              "(마)",
              "㈓",
              "(바)",
              "㈔",
              "(사)",
              "㈕",
              "(아)",
              "㈖",
              "(자)",
              "㈗",
              "(차)",
              "㈘",
              "(카)",
              "㈙",
              "(타)",
              "㈚",
              "(파)",
              "㈛",
              "(하)",
              "㈜",
              "(주)",
              "㈝",
              "(오전)",
              "㈞",
              "(오후)",
              "㈠",
              "(一)",
              "㈡",
              "(二)",
              "㈢",
              "(三)",
              "㈣",
              "(四)",
              "㈤",
              "(五)",
              "㈥",
              "(六)",
              "㈦",
              "(七)",
              "㈧",
              "(八)",
              "㈨",
              "(九)",
              "㈩",
              "(十)",
              "㈪",
              "(月)",
              "㈫",
              "(火)",
              "㈬",
              "(水)",
              "㈭",
              "(木)",
              "㈮",
              "(金)",
              "㈯",
              "(土)",
              "㈰",
              "(日)",
              "㈱",
              "(株)",
              "㈲",
              "(有)",
              "㈳",
              "(社)",
              "㈴",
              "(名)",
              "㈵",
              "(特)",
              "㈶",
              "(財)",
              "㈷",
              "(祝)",
              "㈸",
              "(労)",
              "㈹",
              "(代)",
              "㈺",
              "(呼)",
              "㈻",
              "(学)",
              "㈼",
              "(監)",
              "㈽",
              "(企)",
              "㈾",
              "(資)",
              "㈿",
              "(協)",
              "㉀",
              "(祭)",
              "㉁",
              "(休)",
              "㉂",
              "(自)",
              "㉃",
              "(至)",
              "㋀",
              "1月",
              "㋁",
              "2月",
              "㋂",
              "3月",
              "㋃",
              "4月",
              "㋄",
              "5月",
              "㋅",
              "6月",
              "㋆",
              "7月",
              "㋇",
              "8月",
              "㋈",
              "9月",
              "㋉",
              "10月",
              "㋊",
              "11月",
              "㋋",
              "12月",
              "㍘",
              "0点",
              "㍙",
              "1点",
              "㍚",
              "2点",
              "㍛",
              "3点",
              "㍜",
              "4点",
              "㍝",
              "5点",
              "㍞",
              "6点",
              "㍟",
              "7点",
              "㍠",
              "8点",
              "㍡",
              "9点",
              "㍢",
              "10点",
              "㍣",
              "11点",
              "㍤",
              "12点",
              "㍥",
              "13点",
              "㍦",
              "14点",
              "㍧",
              "15点",
              "㍨",
              "16点",
              "㍩",
              "17点",
              "㍪",
              "18点",
              "㍫",
              "19点",
              "㍬",
              "20点",
              "㍭",
              "21点",
              "㍮",
              "22点",
              "㍯",
              "23点",
              "㍰",
              "24点",
              "㏠",
              "1日",
              "㏡",
              "2日",
              "㏢",
              "3日",
              "㏣",
              "4日",
              "㏤",
              "5日",
              "㏥",
              "6日",
              "㏦",
              "7日",
              "㏧",
              "8日",
              "㏨",
              "9日",
              "㏩",
              "10日",
              "㏪",
              "11日",
              "㏫",
              "12日",
              "㏬",
              "13日",
              "㏭",
              "14日",
              "㏮",
              "15日",
              "㏯",
              "16日",
              "㏰",
              "17日",
              "㏱",
              "18日",
              "㏲",
              "19日",
              "㏳",
              "20日",
              "㏴",
              "21日",
              "㏵",
              "22日",
              "㏶",
              "23日",
              "㏷",
              "24日",
              "㏸",
              "25日",
              "㏹",
              "26日",
              "㏺",
              "27日",
              "㏻",
              "28日",
              "㏼",
              "29日",
              "㏽",
              "30日",
              "㏾",
              "31日",
              "ﬀ",
              "ff",
              "ﬁ",
              "fi",
              "ﬂ",
              "fl",
              "ﬃ",
              "ffi",
              "ﬄ",
              "ffl",
              "ﬅ",
              "ſt",
              "ﬆ",
              "st",
              "ﬓ",
              "մն",
              "ﬔ",
              "մե",
              "ﬕ",
              "մի",
              "ﬖ",
              "վն",
              "ﬗ",
              "մխ",
              "ﭏ",
              "אל",
              "ﭐ",
              "ٱ",
              "ﭑ",
              "ٱ",
              "ﭒ",
              "ٻ",
              "ﭓ",
              "ٻ",
              "ﭔ",
              "ٻ",
              "ﭕ",
              "ٻ",
              "ﭖ",
              "پ",
              "ﭗ",
              "پ",
              "ﭘ",
              "پ",
              "ﭙ",
              "پ",
              "ﭚ",
              "ڀ",
              "ﭛ",
              "ڀ",
              "ﭜ",
              "ڀ",
              "ﭝ",
              "ڀ",
              "ﭞ",
              "ٺ",
              "ﭟ",
              "ٺ",
              "ﭠ",
              "ٺ",
              "ﭡ",
              "ٺ",
              "ﭢ",
              "ٿ",
              "ﭣ",
              "ٿ",
              "ﭤ",
              "ٿ",
              "ﭥ",
              "ٿ",
              "ﭦ",
              "ٹ",
              "ﭧ",
              "ٹ",
              "ﭨ",
              "ٹ",
              "ﭩ",
              "ٹ",
              "ﭪ",
              "ڤ",
              "ﭫ",
              "ڤ",
              "ﭬ",
              "ڤ",
              "ﭭ",
              "ڤ",
              "ﭮ",
              "ڦ",
              "ﭯ",
              "ڦ",
              "ﭰ",
              "ڦ",
              "ﭱ",
              "ڦ",
              "ﭲ",
              "ڄ",
              "ﭳ",
              "ڄ",
              "ﭴ",
              "ڄ",
              "ﭵ",
              "ڄ",
              "ﭶ",
              "ڃ",
              "ﭷ",
              "ڃ",
              "ﭸ",
              "ڃ",
              "ﭹ",
              "ڃ",
              "ﭺ",
              "چ",
              "ﭻ",
              "چ",
              "ﭼ",
              "چ",
              "ﭽ",
              "چ",
              "ﭾ",
              "ڇ",
              "ﭿ",
              "ڇ",
              "ﮀ",
              "ڇ",
              "ﮁ",
              "ڇ",
              "ﮂ",
              "ڍ",
              "ﮃ",
              "ڍ",
              "ﮄ",
              "ڌ",
              "ﮅ",
              "ڌ",
              "ﮆ",
              "ڎ",
              "ﮇ",
              "ڎ",
              "ﮈ",
              "ڈ",
              "ﮉ",
              "ڈ",
              "ﮊ",
              "ژ",
              "ﮋ",
              "ژ",
              "ﮌ",
              "ڑ",
              "ﮍ",
              "ڑ",
              "ﮎ",
              "ک",
              "ﮏ",
              "ک",
              "ﮐ",
              "ک",
              "ﮑ",
              "ک",
              "ﮒ",
              "گ",
              "ﮓ",
              "گ",
              "ﮔ",
              "گ",
              "ﮕ",
              "گ",
              "ﮖ",
              "ڳ",
              "ﮗ",
              "ڳ",
              "ﮘ",
              "ڳ",
              "ﮙ",
              "ڳ",
              "ﮚ",
              "ڱ",
              "ﮛ",
              "ڱ",
              "ﮜ",
              "ڱ",
              "ﮝ",
              "ڱ",
              "ﮞ",
              "ں",
              "ﮟ",
              "ں",
              "ﮠ",
              "ڻ",
              "ﮡ",
              "ڻ",
              "ﮢ",
              "ڻ",
              "ﮣ",
              "ڻ",
              "ﮤ",
              "ۀ",
              "ﮥ",
              "ۀ",
              "ﮦ",
              "ہ",
              "ﮧ",
              "ہ",
              "ﮨ",
              "ہ",
              "ﮩ",
              "ہ",
              "ﮪ",
              "ھ",
              "ﮫ",
              "ھ",
              "ﮬ",
              "ھ",
              "ﮭ",
              "ھ",
              "ﮮ",
              "ے",
              "ﮯ",
              "ے",
              "ﮰ",
              "ۓ",
              "ﮱ",
              "ۓ",
              "ﯓ",
              "ڭ",
              "ﯔ",
              "ڭ",
              "ﯕ",
              "ڭ",
              "ﯖ",
              "ڭ",
              "ﯗ",
              "ۇ",
              "ﯘ",
              "ۇ",
              "ﯙ",
              "ۆ",
              "ﯚ",
              "ۆ",
              "ﯛ",
              "ۈ",
              "ﯜ",
              "ۈ",
              "ﯝ",
              "ٷ",
              "ﯞ",
              "ۋ",
              "ﯟ",
              "ۋ",
              "ﯠ",
              "ۅ",
              "ﯡ",
              "ۅ",
              "ﯢ",
              "ۉ",
              "ﯣ",
              "ۉ",
              "ﯤ",
              "ې",
              "ﯥ",
              "ې",
              "ﯦ",
              "ې",
              "ﯧ",
              "ې",
              "ﯨ",
              "ى",
              "ﯩ",
              "ى",
              "ﯪ",
              "ئا",
              "ﯫ",
              "ئا",
              "ﯬ",
              "ئە",
              "ﯭ",
              "ئە",
              "ﯮ",
              "ئو",
              "ﯯ",
              "ئو",
              "ﯰ",
              "ئۇ",
              "ﯱ",
              "ئۇ",
              "ﯲ",
              "ئۆ",
              "ﯳ",
              "ئۆ",
              "ﯴ",
              "ئۈ",
              "ﯵ",
              "ئۈ",
              "ﯶ",
              "ئې",
              "ﯷ",
              "ئې",
              "ﯸ",
              "ئې",
              "ﯹ",
              "ئى",
              "ﯺ",
              "ئى",
              "ﯻ",
              "ئى",
              "ﯼ",
              "ی",
              "ﯽ",
              "ی",
              "ﯾ",
              "ی",
              "ﯿ",
              "ی",
              "ﰀ",
              "ئج",
              "ﰁ",
              "ئح",
              "ﰂ",
              "ئم",
              "ﰃ",
              "ئى",
              "ﰄ",
              "ئي",
              "ﰅ",
              "بج",
              "ﰆ",
              "بح",
              "ﰇ",
              "بخ",
              "ﰈ",
              "بم",
              "ﰉ",
              "بى",
              "ﰊ",
              "بي",
              "ﰋ",
              "تج",
              "ﰌ",
              "تح",
              "ﰍ",
              "تخ",
              "ﰎ",
              "تم",
              "ﰏ",
              "تى",
              "ﰐ",
              "تي",
              "ﰑ",
              "ثج",
              "ﰒ",
              "ثم",
              "ﰓ",
              "ثى",
              "ﰔ",
              "ثي",
              "ﰕ",
              "جح",
              "ﰖ",
              "جم",
              "ﰗ",
              "حج",
              "ﰘ",
              "حم",
              "ﰙ",
              "خج",
              "ﰚ",
              "خح",
              "ﰛ",
              "خم",
              "ﰜ",
              "سج",
              "ﰝ",
              "سح",
              "ﰞ",
              "سخ",
              "ﰟ",
              "سم",
              "ﰠ",
              "صح",
              "ﰡ",
              "صم",
              "ﰢ",
              "ضج",
              "ﰣ",
              "ضح",
              "ﰤ",
              "ضخ",
              "ﰥ",
              "ضم",
              "ﰦ",
              "طح",
              "ﰧ",
              "طم",
              "ﰨ",
              "ظم",
              "ﰩ",
              "عج",
              "ﰪ",
              "عم",
              "ﰫ",
              "غج",
              "ﰬ",
              "غم",
              "ﰭ",
              "فج",
              "ﰮ",
              "فح",
              "ﰯ",
              "فخ",
              "ﰰ",
              "فم",
              "ﰱ",
              "فى",
              "ﰲ",
              "في",
              "ﰳ",
              "قح",
              "ﰴ",
              "قم",
              "ﰵ",
              "قى",
              "ﰶ",
              "قي",
              "ﰷ",
              "كا",
              "ﰸ",
              "كج",
              "ﰹ",
              "كح",
              "ﰺ",
              "كخ",
              "ﰻ",
              "كل",
              "ﰼ",
              "كم",
              "ﰽ",
              "كى",
              "ﰾ",
              "كي",
              "ﰿ",
              "لج",
              "ﱀ",
              "لح",
              "ﱁ",
              "لخ",
              "ﱂ",
              "لم",
              "ﱃ",
              "لى",
              "ﱄ",
              "لي",
              "ﱅ",
              "مج",
              "ﱆ",
              "مح",
              "ﱇ",
              "مخ",
              "ﱈ",
              "مم",
              "ﱉ",
              "مى",
              "ﱊ",
              "مي",
              "ﱋ",
              "نج",
              "ﱌ",
              "نح",
              "ﱍ",
              "نخ",
              "ﱎ",
              "نم",
              "ﱏ",
              "نى",
              "ﱐ",
              "ني",
              "ﱑ",
              "هج",
              "ﱒ",
              "هم",
              "ﱓ",
              "هى",
              "ﱔ",
              "هي",
              "ﱕ",
              "يج",
              "ﱖ",
              "يح",
              "ﱗ",
              "يخ",
              "ﱘ",
              "يم",
              "ﱙ",
              "يى",
              "ﱚ",
              "يي",
              "ﱛ",
              "ذٰ",
              "ﱜ",
              "رٰ",
              "ﱝ",
              "ىٰ",
              "ﱞ",
              " ٌّ",
              "ﱟ",
              " ٍّ",
              "ﱠ",
              " َّ",
              "ﱡ",
              " ُّ",
              "ﱢ",
              " ِّ",
              "ﱣ",
              " ّٰ",
              "ﱤ",
              "ئر",
              "ﱥ",
              "ئز",
              "ﱦ",
              "ئم",
              "ﱧ",
              "ئن",
              "ﱨ",
              "ئى",
              "ﱩ",
              "ئي",
              "ﱪ",
              "بر",
              "ﱫ",
              "بز",
              "ﱬ",
              "بم",
              "ﱭ",
              "بن",
              "ﱮ",
              "بى",
              "ﱯ",
              "بي",
              "ﱰ",
              "تر",
              "ﱱ",
              "تز",
              "ﱲ",
              "تم",
              "ﱳ",
              "تن",
              "ﱴ",
              "تى",
              "ﱵ",
              "تي",
              "ﱶ",
              "ثر",
              "ﱷ",
              "ثز",
              "ﱸ",
              "ثم",
              "ﱹ",
              "ثن",
              "ﱺ",
              "ثى",
              "ﱻ",
              "ثي",
              "ﱼ",
              "فى",
              "ﱽ",
              "في",
              "ﱾ",
              "قى",
              "ﱿ",
              "قي",
              "ﲀ",
              "كا",
              "ﲁ",
              "كل",
              "ﲂ",
              "كم",
              "ﲃ",
              "كى",
              "ﲄ",
              "كي",
              "ﲅ",
              "لم",
              "ﲆ",
              "لى",
              "ﲇ",
              "لي",
              "ﲈ",
              "ما",
              "ﲉ",
              "مم",
              "ﲊ",
              "نر",
              "ﲋ",
              "نز",
              "ﲌ",
              "نم",
              "ﲍ",
              "نن",
              "ﲎ",
              "نى",
              "ﲏ",
              "ني",
              "ﲐ",
              "ىٰ",
              "ﲑ",
              "ير",
              "ﲒ",
              "يز",
              "ﲓ",
              "يم",
              "ﲔ",
              "ين",
              "ﲕ",
              "يى",
              "ﲖ",
              "يي",
              "ﲗ",
              "ئج",
              "ﲘ",
              "ئح",
              "ﲙ",
              "ئخ",
              "ﲚ",
              "ئم",
              "ﲛ",
              "ئه",
              "ﲜ",
              "بج",
              "ﲝ",
              "بح",
              "ﲞ",
              "بخ",
              "ﲟ",
              "بم",
              "ﲠ",
              "به",
              "ﲡ",
              "تج",
              "ﲢ",
              "تح",
              "ﲣ",
              "تخ",
              "ﲤ",
              "تم",
              "ﲥ",
              "ته",
              "ﲦ",
              "ثم",
              "ﲧ",
              "جح",
              "ﲨ",
              "جم",
              "ﲩ",
              "حج",
              "ﲪ",
              "حم",
              "ﲫ",
              "خج",
              "ﲬ",
              "خم",
              "ﲭ",
              "سج",
              "ﲮ",
              "سح",
              "ﲯ",
              "سخ",
              "ﲰ",
              "سم",
              "ﲱ",
              "صح",
              "ﲲ",
              "صخ",
              "ﲳ",
              "صم",
              "ﲴ",
              "ضج",
              "ﲵ",
              "ضح",
              "ﲶ",
              "ضخ",
              "ﲷ",
              "ضم",
              "ﲸ",
              "طح",
              "ﲹ",
              "ظم",
              "ﲺ",
              "عج",
              "ﲻ",
              "عم",
              "ﲼ",
              "غج",
              "ﲽ",
              "غم",
              "ﲾ",
              "فج",
              "ﲿ",
              "فح",
              "ﳀ",
              "فخ",
              "ﳁ",
              "فم",
              "ﳂ",
              "قح",
              "ﳃ",
              "قم",
              "ﳄ",
              "كج",
              "ﳅ",
              "كح",
              "ﳆ",
              "كخ",
              "ﳇ",
              "كل",
              "ﳈ",
              "كم",
              "ﳉ",
              "لج",
              "ﳊ",
              "لح",
              "ﳋ",
              "لخ",
              "ﳌ",
              "لم",
              "ﳍ",
              "له",
              "ﳎ",
              "مج",
              "ﳏ",
              "مح",
              "ﳐ",
              "مخ",
              "ﳑ",
              "مم",
              "ﳒ",
              "نج",
              "ﳓ",
              "نح",
              "ﳔ",
              "نخ",
              "ﳕ",
              "نم",
              "ﳖ",
              "نه",
              "ﳗ",
              "هج",
              "ﳘ",
              "هم",
              "ﳙ",
              "هٰ",
              "ﳚ",
              "يج",
              "ﳛ",
              "يح",
              "ﳜ",
              "يخ",
              "ﳝ",
              "يم",
              "ﳞ",
              "يه",
              "ﳟ",
              "ئم",
              "ﳠ",
              "ئه",
              "ﳡ",
              "بم",
              "ﳢ",
              "به",
              "ﳣ",
              "تم",
              "ﳤ",
              "ته",
              "ﳥ",
              "ثم",
              "ﳦ",
              "ثه",
              "ﳧ",
              "سم",
              "ﳨ",
              "سه",
              "ﳩ",
              "شم",
              "ﳪ",
              "شه",
              "ﳫ",
              "كل",
              "ﳬ",
              "كم",
              "ﳭ",
              "لم",
              "ﳮ",
              "نم",
              "ﳯ",
              "نه",
              "ﳰ",
              "يم",
              "ﳱ",
              "يه",
              "ﳲ",
              "ـَّ",
              "ﳳ",
              "ـُّ",
              "ﳴ",
              "ـِّ",
              "ﳵ",
              "طى",
              "ﳶ",
              "طي",
              "ﳷ",
              "عى",
              "ﳸ",
              "عي",
              "ﳹ",
              "غى",
              "ﳺ",
              "غي",
              "ﳻ",
              "سى",
              "ﳼ",
              "سي",
              "ﳽ",
              "شى",
              "ﳾ",
              "شي",
              "ﳿ",
              "حى",
              "ﴀ",
              "حي",
              "ﴁ",
              "جى",
              "ﴂ",
              "جي",
              "ﴃ",
              "خى",
              "ﴄ",
              "خي",
              "ﴅ",
              "صى",
              "ﴆ",
              "صي",
              "ﴇ",
              "ضى",
              "ﴈ",
              "ضي",
              "ﴉ",
              "شج",
              "ﴊ",
              "شح",
              "ﴋ",
              "شخ",
              "ﴌ",
              "شم",
              "ﴍ",
              "شر",
              "ﴎ",
              "سر",
              "ﴏ",
              "صر",
              "ﴐ",
              "ضر",
              "ﴑ",
              "طى",
              "ﴒ",
              "طي",
              "ﴓ",
              "عى",
              "ﴔ",
              "عي",
              "ﴕ",
              "غى",
              "ﴖ",
              "غي",
              "ﴗ",
              "سى",
              "ﴘ",
              "سي",
              "ﴙ",
              "شى",
              "ﴚ",
              "شي",
              "ﴛ",
              "حى",
              "ﴜ",
              "حي",
              "ﴝ",
              "جى",
              "ﴞ",
              "جي",
              "ﴟ",
              "خى",
              "ﴠ",
              "خي",
              "ﴡ",
              "صى",
              "ﴢ",
              "صي",
              "ﴣ",
              "ضى",
              "ﴤ",
              "ضي",
              "ﴥ",
              "شج",
              "ﴦ",
              "شح",
              "ﴧ",
              "شخ",
              "ﴨ",
              "شم",
              "ﴩ",
              "شر",
              "ﴪ",
              "سر",
              "ﴫ",
              "صر",
              "ﴬ",
              "ضر",
              "ﴭ",
              "شج",
              "ﴮ",
              "شح",
              "ﴯ",
              "شخ",
              "ﴰ",
              "شم",
              "ﴱ",
              "سه",
              "ﴲ",
              "شه",
              "ﴳ",
              "طم",
              "ﴴ",
              "سج",
              "ﴵ",
              "سح",
              "ﴶ",
              "سخ",
              "ﴷ",
              "شج",
              "ﴸ",
              "شح",
              "ﴹ",
              "شخ",
              "ﴺ",
              "طم",
              "ﴻ",
              "ظم",
              "ﴼ",
              "اً",
              "ﴽ",
              "اً",
              "ﵐ",
              "تجم",
              "ﵑ",
              "تحج",
              "ﵒ",
              "تحج",
              "ﵓ",
              "تحم",
              "ﵔ",
              "تخم",
              "ﵕ",
              "تمج",
              "ﵖ",
              "تمح",
              "ﵗ",
              "تمخ",
              "ﵘ",
              "جمح",
              "ﵙ",
              "جمح",
              "ﵚ",
              "حمي",
              "ﵛ",
              "حمى",
              "ﵜ",
              "سحج",
              "ﵝ",
              "سجح",
              "ﵞ",
              "سجى",
              "ﵟ",
              "سمح",
              "ﵠ",
              "سمح",
              "ﵡ",
              "سمج",
              "ﵢ",
              "سمم",
              "ﵣ",
              "سمم",
              "ﵤ",
              "صحح",
              "ﵥ",
              "صحح",
              "ﵦ",
              "صمم",
              "ﵧ",
              "شحم",
              "ﵨ",
              "شحم",
              "ﵩ",
              "شجي",
              "ﵪ",
              "شمخ",
              "ﵫ",
              "شمخ",
              "ﵬ",
              "شمم",
              "ﵭ",
              "شمم",
              "ﵮ",
              "ضحى",
              "ﵯ",
              "ضخم",
              "ﵰ",
              "ضخم",
              "ﵱ",
              "طمح",
              "ﵲ",
              "طمح",
              "ﵳ",
              "طمم",
              "ﵴ",
              "طمي",
              "ﵵ",
              "عجم",
              "ﵶ",
              "عمم",
              "ﵷ",
              "عمم",
              "ﵸ",
              "عمى",
              "ﵹ",
              "غمم",
              "ﵺ",
              "غمي",
              "ﵻ",
              "غمى",
              "ﵼ",
              "فخم",
              "ﵽ",
              "فخم",
              "ﵾ",
              "قمح",
              "ﵿ",
              "قمم",
              "ﶀ",
              "لحم",
              "ﶁ",
              "لحي",
              "ﶂ",
              "لحى",
              "ﶃ",
              "لجج",
              "ﶄ",
              "لجج",
              "ﶅ",
              "لخم",
              "ﶆ",
              "لخم",
              "ﶇ",
              "لمح",
              "ﶈ",
              "لمح",
              "ﶉ",
              "محج",
              "ﶊ",
              "محم",
              "ﶋ",
              "محي",
              "ﶌ",
              "مجح",
              "ﶍ",
              "مجم",
              "ﶎ",
              "مخج",
              "ﶏ",
              "مخم",
              "ﶒ",
              "مجخ",
              "ﶓ",
              "همج",
              "ﶔ",
              "همم",
              "ﶕ",
              "نحم",
              "ﶖ",
              "نحى",
              "ﶗ",
              "نجم",
              "ﶘ",
              "نجم",
              "ﶙ",
              "نجى",
              "ﶚ",
              "نمي",
              "ﶛ",
              "نمى",
              "ﶜ",
              "يمم",
              "ﶝ",
              "يمم",
              "ﶞ",
              "بخي",
              "ﶟ",
              "تجي",
              "ﶠ",
              "تجى",
              "ﶡ",
              "تخي",
              "ﶢ",
              "تخى",
              "ﶣ",
              "تمي",
              "ﶤ",
              "تمى",
              "ﶥ",
              "جمي",
              "ﶦ",
              "جحى",
              "ﶧ",
              "جمى",
              "ﶨ",
              "سخى",
              "ﶩ",
              "صحي",
              "ﶪ",
              "شحي",
              "ﶫ",
              "ضحي",
              "ﶬ",
              "لجي",
              "ﶭ",
              "لمي",
              "ﶮ",
              "يحي",
              "ﶯ",
              "يجي",
              "ﶰ",
              "يمي",
              "ﶱ",
              "ممي",
              "ﶲ",
              "قمي",
              "ﶳ",
              "نحي",
              "ﶴ",
              "قمح",
              "ﶵ",
              "لحم",
              "ﶶ",
              "عمي",
              "ﶷ",
              "كمي",
              "ﶸ",
              "نجح",
              "ﶹ",
              "مخي",
              "ﶺ",
              "لجم",
              "ﶻ",
              "كمم",
              "ﶼ",
              "لجم",
              "ﶽ",
              "نجح",
              "ﶾ",
              "جحي",
              "ﶿ",
              "حجي",
              "ﷀ",
              "مجي",
              "ﷁ",
              "فمي",
              "ﷂ",
              "بحي",
              "ﷃ",
              "كمم",
              "ﷄ",
              "عجم",
              "ﷅ",
              "صمم",
              "ﷆ",
              "سخي",
              "ﷇ",
              "نجي",
              "﹉",
              "‾",
              "﹊",
              "‾",
              "﹋",
              "‾",
              "﹌",
              "‾",
              "﹍",
              "_",
              "﹎",
              "_",
              "﹏",
              "_",
              "ﺀ",
              "ء",
              "ﺁ",
              "آ",
              "ﺂ",
              "آ",
              "ﺃ",
              "أ",
              "ﺄ",
              "أ",
              "ﺅ",
              "ؤ",
              "ﺆ",
              "ؤ",
              "ﺇ",
              "إ",
              "ﺈ",
              "إ",
              "ﺉ",
              "ئ",
              "ﺊ",
              "ئ",
              "ﺋ",
              "ئ",
              "ﺌ",
              "ئ",
              "ﺍ",
              "ا",
              "ﺎ",
              "ا",
              "ﺏ",
              "ب",
              "ﺐ",
              "ب",
              "ﺑ",
              "ب",
              "ﺒ",
              "ب",
              "ﺓ",
              "ة",
              "ﺔ",
              "ة",
              "ﺕ",
              "ت",
              "ﺖ",
              "ت",
              "ﺗ",
              "ت",
              "ﺘ",
              "ت",
              "ﺙ",
              "ث",
              "ﺚ",
              "ث",
              "ﺛ",
              "ث",
              "ﺜ",
              "ث",
              "ﺝ",
              "ج",
              "ﺞ",
              "ج",
              "ﺟ",
              "ج",
              "ﺠ",
              "ج",
              "ﺡ",
              "ح",
              "ﺢ",
              "ح",
              "ﺣ",
              "ح",
              "ﺤ",
              "ح",
              "ﺥ",
              "خ",
              "ﺦ",
              "خ",
              "ﺧ",
              "خ",
              "ﺨ",
              "خ",
              "ﺩ",
              "د",
              "ﺪ",
              "د",
              "ﺫ",
              "ذ",
              "ﺬ",
              "ذ",
              "ﺭ",
              "ر",
              "ﺮ",
              "ر",
              "ﺯ",
              "ز",
              "ﺰ",
              "ز",
              "ﺱ",
              "س",
              "ﺲ",
              "س",
              "ﺳ",
              "س",
              "ﺴ",
              "س",
              "ﺵ",
              "ش",
              "ﺶ",
              "ش",
              "ﺷ",
              "ش",
              "ﺸ",
              "ش",
              "ﺹ",
              "ص",
              "ﺺ",
              "ص",
              "ﺻ",
              "ص",
              "ﺼ",
              "ص",
              "ﺽ",
              "ض",
              "ﺾ",
              "ض",
              "ﺿ",
              "ض",
              "ﻀ",
              "ض",
              "ﻁ",
              "ط",
              "ﻂ",
              "ط",
              "ﻃ",
              "ط",
              "ﻄ",
              "ط",
              "ﻅ",
              "ظ",
              "ﻆ",
              "ظ",
              "ﻇ",
              "ظ",
              "ﻈ",
              "ظ",
              "ﻉ",
              "ع",
              "ﻊ",
              "ع",
              "ﻋ",
              "ع",
              "ﻌ",
              "ع",
              "ﻍ",
              "غ",
              "ﻎ",
              "غ",
              "ﻏ",
              "غ",
              "ﻐ",
              "غ",
              "ﻑ",
              "ف",
              "ﻒ",
              "ف",
              "ﻓ",
              "ف",
              "ﻔ",
              "ف",
              "ﻕ",
              "ق",
              "ﻖ",
              "ق",
              "ﻗ",
              "ق",
              "ﻘ",
              "ق",
              "ﻙ",
              "ك",
              "ﻚ",
              "ك",
              "ﻛ",
              "ك",
              "ﻜ",
              "ك",
              "ﻝ",
              "ل",
              "ﻞ",
              "ل",
              "ﻟ",
              "ل",
              "ﻠ",
              "ل",
              "ﻡ",
              "م",
              "ﻢ",
              "م",
              "ﻣ",
              "م",
              "ﻤ",
              "م",
              "ﻥ",
              "ن",
              "ﻦ",
              "ن",
              "ﻧ",
              "ن",
              "ﻨ",
              "ن",
              "ﻩ",
              "ه",
              "ﻪ",
              "ه",
              "ﻫ",
              "ه",
              "ﻬ",
              "ه",
              "ﻭ",
              "و",
              "ﻮ",
              "و",
              "ﻯ",
              "ى",
              "ﻰ",
              "ى",
              "ﻱ",
              "ي",
              "ﻲ",
              "ي",
              "ﻳ",
              "ي",
              "ﻴ",
              "ي",
              "ﻵ",
              "لآ",
              "ﻶ",
              "لآ",
              "ﻷ",
              "لأ",
              "ﻸ",
              "لأ",
              "ﻹ",
              "لإ",
              "ﻺ",
              "لإ",
              "ﻻ",
              "لا",
              "ﻼ",
              "لا",
            ];
          });
          function reverseIfRtl(e) {
            const t = e.length;
            if (
              t <= 1 ||
              !(function isRTLRangeFor(e) {
                let t = i[13];
                if (e >= t.begin && e < t.end) return !0;
                t = i[11];
                return e >= t.begin && e < t.end;
              })(e.charCodeAt(0))
            )
              return e;
            const a = [];
            for (let r = t - 1; r >= 0; r--) a.push(e[r]);
            return a.join("");
          }
          const o = new RegExp("^(\\s)|(\\p{Mn})|(\\p{Cf})$", "u"),
            c = new Map();
          function getCharUnicodeCategory(e) {
            const t = c.get(e);
            if (t) return t;
            const a = e.match(o),
              r = {
                isWhitespace: !(!a || !a[1]),
                isZeroWidthDiacritic: !(!a || !a[2]),
                isInvisibleFormatMark: !(!a || !a[3]),
              };
            c.set(e, r);
            return r;
          }
          function clearUnicodeCaches() {
            c.clear();
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.getSerifFonts =
            t.getNonStdFontMap =
            t.getGlyphMapForStandardFonts =
            t.getFontNameToFileMap =
              void 0;
          t.getStandardFontName = function getStandardFontName(e) {
            const t = (0, n.normalizeFontName)(e);
            return i()[t];
          };
          t.getSymbolsFonts =
            t.getSupplementalGlyphMapForCalibri =
            t.getSupplementalGlyphMapForArialBlack =
            t.getStdFontMap =
              void 0;
          var r = a(3),
            n = a(36);
          const i = (0, r.getLookupTableFactory)(function (e) {
            e["Times-Roman"] = "Times-Roman";
            e.Helvetica = "Helvetica";
            e.Courier = "Courier";
            e.Symbol = "Symbol";
            e["Times-Bold"] = "Times-Bold";
            e["Helvetica-Bold"] = "Helvetica-Bold";
            e["Courier-Bold"] = "Courier-Bold";
            e.ZapfDingbats = "ZapfDingbats";
            e["Times-Italic"] = "Times-Italic";
            e["Helvetica-Oblique"] = "Helvetica-Oblique";
            e["Courier-Oblique"] = "Courier-Oblique";
            e["Times-BoldItalic"] = "Times-BoldItalic";
            e["Helvetica-BoldOblique"] = "Helvetica-BoldOblique";
            e["Courier-BoldOblique"] = "Courier-BoldOblique";
            e.ArialNarrow = "Helvetica";
            e["ArialNarrow-Bold"] = "Helvetica-Bold";
            e["ArialNarrow-BoldItalic"] = "Helvetica-BoldOblique";
            e["ArialNarrow-Italic"] = "Helvetica-Oblique";
            e.ArialBlack = "Helvetica";
            e["ArialBlack-Bold"] = "Helvetica-Bold";
            e["ArialBlack-BoldItalic"] = "Helvetica-BoldOblique";
            e["ArialBlack-Italic"] = "Helvetica-Oblique";
            e["Arial-Black"] = "Helvetica";
            e["Arial-Black-Bold"] = "Helvetica-Bold";
            e["Arial-Black-BoldItalic"] = "Helvetica-BoldOblique";
            e["Arial-Black-Italic"] = "Helvetica-Oblique";
            e.Arial = "Helvetica";
            e["Arial-Bold"] = "Helvetica-Bold";
            e["Arial-BoldItalic"] = "Helvetica-BoldOblique";
            e["Arial-Italic"] = "Helvetica-Oblique";
            e.ArialMT = "Helvetica";
            e["Arial-BoldItalicMT"] = "Helvetica-BoldOblique";
            e["Arial-BoldMT"] = "Helvetica-Bold";
            e["Arial-ItalicMT"] = "Helvetica-Oblique";
            e.ArialUnicodeMS = "Helvetica";
            e["ArialUnicodeMS-Bold"] = "Helvetica-Bold";
            e["ArialUnicodeMS-BoldItalic"] = "Helvetica-BoldOblique";
            e["ArialUnicodeMS-Italic"] = "Helvetica-Oblique";
            e["Courier-BoldItalic"] = "Courier-BoldOblique";
            e["Courier-Italic"] = "Courier-Oblique";
            e.CourierNew = "Courier";
            e["CourierNew-Bold"] = "Courier-Bold";
            e["CourierNew-BoldItalic"] = "Courier-BoldOblique";
            e["CourierNew-Italic"] = "Courier-Oblique";
            e["CourierNewPS-BoldItalicMT"] = "Courier-BoldOblique";
            e["CourierNewPS-BoldMT"] = "Courier-Bold";
            e["CourierNewPS-ItalicMT"] = "Courier-Oblique";
            e.CourierNewPSMT = "Courier";
            e["Helvetica-BoldItalic"] = "Helvetica-BoldOblique";
            e["Helvetica-Italic"] = "Helvetica-Oblique";
            e["Symbol-Bold"] = "Symbol";
            e["Symbol-BoldItalic"] = "Symbol";
            e["Symbol-Italic"] = "Symbol";
            e.TimesNewRoman = "Times-Roman";
            e["TimesNewRoman-Bold"] = "Times-Bold";
            e["TimesNewRoman-BoldItalic"] = "Times-BoldItalic";
            e["TimesNewRoman-Italic"] = "Times-Italic";
            e.TimesNewRomanPS = "Times-Roman";
            e["TimesNewRomanPS-Bold"] = "Times-Bold";
            e["TimesNewRomanPS-BoldItalic"] = "Times-BoldItalic";
            e["TimesNewRomanPS-BoldItalicMT"] = "Times-BoldItalic";
            e["TimesNewRomanPS-BoldMT"] = "Times-Bold";
            e["TimesNewRomanPS-Italic"] = "Times-Italic";
            e["TimesNewRomanPS-ItalicMT"] = "Times-Italic";
            e.TimesNewRomanPSMT = "Times-Roman";
            e["TimesNewRomanPSMT-Bold"] = "Times-Bold";
            e["TimesNewRomanPSMT-BoldItalic"] = "Times-BoldItalic";
            e["TimesNewRomanPSMT-Italic"] = "Times-Italic";
          });
          t.getStdFontMap = i;
          const s = (0, r.getLookupTableFactory)(function (e) {
            e.Courier = "FoxitFixed.pfb";
            e["Courier-Bold"] = "FoxitFixedBold.pfb";
            e["Courier-BoldOblique"] = "FoxitFixedBoldItalic.pfb";
            e["Courier-Oblique"] = "FoxitFixedItalic.pfb";
            e.Helvetica = "FoxitSans.pfb";
            e["Helvetica-Bold"] = "FoxitSansBold.pfb";
            e["Helvetica-BoldOblique"] = "FoxitSansBoldItalic.pfb";
            e["Helvetica-Oblique"] = "FoxitSansItalic.pfb";
            e["Times-Roman"] = "FoxitSerif.pfb";
            e["Times-Bold"] = "FoxitSerifBold.pfb";
            e["Times-BoldItalic"] = "FoxitSerifBoldItalic.pfb";
            e["Times-Italic"] = "FoxitSerifItalic.pfb";
            e.Symbol = "FoxitSymbol.pfb";
            e.ZapfDingbats = "FoxitDingbats.pfb";
            e["LiberationSans-Regular"] = "LiberationSans-Regular.ttf";
            e["LiberationSans-Bold"] = "LiberationSans-Bold.ttf";
            e["LiberationSans-Italic"] = "LiberationSans-Italic.ttf";
            e["LiberationSans-BoldItalic"] = "LiberationSans-BoldItalic.ttf";
          });
          t.getFontNameToFileMap = s;
          const o = (0, r.getLookupTableFactory)(function (e) {
            e.Calibri = "Helvetica";
            e["Calibri-Bold"] = "Helvetica-Bold";
            e["Calibri-BoldItalic"] = "Helvetica-BoldOblique";
            e["Calibri-Italic"] = "Helvetica-Oblique";
            e.CenturyGothic = "Helvetica";
            e["CenturyGothic-Bold"] = "Helvetica-Bold";
            e["CenturyGothic-BoldItalic"] = "Helvetica-BoldOblique";
            e["CenturyGothic-Italic"] = "Helvetica-Oblique";
            e.ComicSansMS = "Comic Sans MS";
            e["ComicSansMS-Bold"] = "Comic Sans MS-Bold";
            e["ComicSansMS-BoldItalic"] = "Comic Sans MS-BoldItalic";
            e["ComicSansMS-Italic"] = "Comic Sans MS-Italic";
            e["ItcSymbol-Bold"] = "Helvetica-Bold";
            e["ItcSymbol-BoldItalic"] = "Helvetica-BoldOblique";
            e["ItcSymbol-Book"] = "Helvetica";
            e["ItcSymbol-BookItalic"] = "Helvetica-Oblique";
            e["ItcSymbol-Medium"] = "Helvetica";
            e["ItcSymbol-MediumItalic"] = "Helvetica-Oblique";
            e.LucidaConsole = "Courier";
            e["LucidaConsole-Bold"] = "Courier-Bold";
            e["LucidaConsole-BoldItalic"] = "Courier-BoldOblique";
            e["LucidaConsole-Italic"] = "Courier-Oblique";
            e["LucidaSans-Demi"] = "Helvetica-Bold";
            e["MS-Gothic"] = "MS Gothic";
            e["MS-Gothic-Bold"] = "MS Gothic-Bold";
            e["MS-Gothic-BoldItalic"] = "MS Gothic-BoldItalic";
            e["MS-Gothic-Italic"] = "MS Gothic-Italic";
            e["MS-Mincho"] = "MS Mincho";
            e["MS-Mincho-Bold"] = "MS Mincho-Bold";
            e["MS-Mincho-BoldItalic"] = "MS Mincho-BoldItalic";
            e["MS-Mincho-Italic"] = "MS Mincho-Italic";
            e["MS-PGothic"] = "MS PGothic";
            e["MS-PGothic-Bold"] = "MS PGothic-Bold";
            e["MS-PGothic-BoldItalic"] = "MS PGothic-BoldItalic";
            e["MS-PGothic-Italic"] = "MS PGothic-Italic";
            e["MS-PMincho"] = "MS PMincho";
            e["MS-PMincho-Bold"] = "MS PMincho-Bold";
            e["MS-PMincho-BoldItalic"] = "MS PMincho-BoldItalic";
            e["MS-PMincho-Italic"] = "MS PMincho-Italic";
            e.NuptialScript = "Times-Italic";
            e.SegoeUISymbol = "Helvetica";
            e.Wingdings = "ZapfDingbats";
            e["Wingdings-Regular"] = "ZapfDingbats";
          });
          t.getNonStdFontMap = o;
          const c = (0, r.getLookupTableFactory)(function (e) {
            e["Adobe Jenson"] = !0;
            e["Adobe Text"] = !0;
            e.Albertus = !0;
            e.Aldus = !0;
            e.Alexandria = !0;
            e.Algerian = !0;
            e["American Typewriter"] = !0;
            e.Antiqua = !0;
            e.Apex = !0;
            e.Arno = !0;
            e.Aster = !0;
            e.Aurora = !0;
            e.Baskerville = !0;
            e.Bell = !0;
            e.Bembo = !0;
            e["Bembo Schoolbook"] = !0;
            e.Benguiat = !0;
            e["Berkeley Old Style"] = !0;
            e["Bernhard Modern"] = !0;
            e["Berthold City"] = !0;
            e.Bodoni = !0;
            e["Bauer Bodoni"] = !0;
            e["Book Antiqua"] = !0;
            e.Bookman = !0;
            e["Bordeaux Roman"] = !0;
            e["Californian FB"] = !0;
            e.Calisto = !0;
            e.Calvert = !0;
            e.Capitals = !0;
            e.Cambria = !0;
            e.Cartier = !0;
            e.Caslon = !0;
            e.Catull = !0;
            e.Centaur = !0;
            e["Century Old Style"] = !0;
            e["Century Schoolbook"] = !0;
            e.Chaparral = !0;
            e["Charis SIL"] = !0;
            e.Cheltenham = !0;
            e["Cholla Slab"] = !0;
            e.Clarendon = !0;
            e.Clearface = !0;
            e.Cochin = !0;
            e.Colonna = !0;
            e["Computer Modern"] = !0;
            e["Concrete Roman"] = !0;
            e.Constantia = !0;
            e["Cooper Black"] = !0;
            e.Corona = !0;
            e.Ecotype = !0;
            e.Egyptienne = !0;
            e.Elephant = !0;
            e.Excelsior = !0;
            e.Fairfield = !0;
            e["FF Scala"] = !0;
            e.Folkard = !0;
            e.Footlight = !0;
            e.FreeSerif = !0;
            e["Friz Quadrata"] = !0;
            e.Garamond = !0;
            e.Gentium = !0;
            e.Georgia = !0;
            e.Gloucester = !0;
            e["Goudy Old Style"] = !0;
            e["Goudy Schoolbook"] = !0;
            e["Goudy Pro Font"] = !0;
            e.Granjon = !0;
            e["Guardian Egyptian"] = !0;
            e.Heather = !0;
            e.Hercules = !0;
            e["High Tower Text"] = !0;
            e.Hiroshige = !0;
            e["Hoefler Text"] = !0;
            e["Humana Serif"] = !0;
            e.Imprint = !0;
            e["Ionic No. 5"] = !0;
            e.Janson = !0;
            e.Joanna = !0;
            e.Korinna = !0;
            e.Lexicon = !0;
            e.LiberationSerif = !0;
            e["Liberation Serif"] = !0;
            e["Linux Libertine"] = !0;
            e.Literaturnaya = !0;
            e.Lucida = !0;
            e["Lucida Bright"] = !0;
            e.Melior = !0;
            e.Memphis = !0;
            e.Miller = !0;
            e.Minion = !0;
            e.Modern = !0;
            e["Mona Lisa"] = !0;
            e["Mrs Eaves"] = !0;
            e["MS Serif"] = !0;
            e["Museo Slab"] = !0;
            e["New York"] = !0;
            e["Nimbus Roman"] = !0;
            e["NPS Rawlinson Roadway"] = !0;
            e.NuptialScript = !0;
            e.Palatino = !0;
            e.Perpetua = !0;
            e.Plantin = !0;
            e["Plantin Schoolbook"] = !0;
            e.Playbill = !0;
            e["Poor Richard"] = !0;
            e["Rawlinson Roadway"] = !0;
            e.Renault = !0;
            e.Requiem = !0;
            e.Rockwell = !0;
            e.Roman = !0;
            e["Rotis Serif"] = !0;
            e.Sabon = !0;
            e.Scala = !0;
            e.Seagull = !0;
            e.Sistina = !0;
            e.Souvenir = !0;
            e.STIX = !0;
            e["Stone Informal"] = !0;
            e["Stone Serif"] = !0;
            e.Sylfaen = !0;
            e.Times = !0;
            e.Trajan = !0;
            e["Trinité"] = !0;
            e["Trump Mediaeval"] = !0;
            e.Utopia = !0;
            e["Vale Type"] = !0;
            e["Bitstream Vera"] = !0;
            e["Vera Serif"] = !0;
            e.Versailles = !0;
            e.Wanted = !0;
            e.Weiss = !0;
            e["Wide Latin"] = !0;
            e.Windsor = !0;
            e.XITS = !0;
          });
          t.getSerifFonts = c;
          const l = (0, r.getLookupTableFactory)(function (e) {
            e.Dingbats = !0;
            e.Symbol = !0;
            e.ZapfDingbats = !0;
          });
          t.getSymbolsFonts = l;
          const h = (0, r.getLookupTableFactory)(function (e) {
            e[2] = 10;
            e[3] = 32;
            e[4] = 33;
            e[5] = 34;
            e[6] = 35;
            e[7] = 36;
            e[8] = 37;
            e[9] = 38;
            e[10] = 39;
            e[11] = 40;
            e[12] = 41;
            e[13] = 42;
            e[14] = 43;
            e[15] = 44;
            e[16] = 45;
            e[17] = 46;
            e[18] = 47;
            e[19] = 48;
            e[20] = 49;
            e[21] = 50;
            e[22] = 51;
            e[23] = 52;
            e[24] = 53;
            e[25] = 54;
            e[26] = 55;
            e[27] = 56;
            e[28] = 57;
            e[29] = 58;
            e[30] = 894;
            e[31] = 60;
            e[32] = 61;
            e[33] = 62;
            e[34] = 63;
            e[35] = 64;
            e[36] = 65;
            e[37] = 66;
            e[38] = 67;
            e[39] = 68;
            e[40] = 69;
            e[41] = 70;
            e[42] = 71;
            e[43] = 72;
            e[44] = 73;
            e[45] = 74;
            e[46] = 75;
            e[47] = 76;
            e[48] = 77;
            e[49] = 78;
            e[50] = 79;
            e[51] = 80;
            e[52] = 81;
            e[53] = 82;
            e[54] = 83;
            e[55] = 84;
            e[56] = 85;
            e[57] = 86;
            e[58] = 87;
            e[59] = 88;
            e[60] = 89;
            e[61] = 90;
            e[62] = 91;
            e[63] = 92;
            e[64] = 93;
            e[65] = 94;
            e[66] = 95;
            e[67] = 96;
            e[68] = 97;
            e[69] = 98;
            e[70] = 99;
            e[71] = 100;
            e[72] = 101;
            e[73] = 102;
            e[74] = 103;
            e[75] = 104;
            e[76] = 105;
            e[77] = 106;
            e[78] = 107;
            e[79] = 108;
            e[80] = 109;
            e[81] = 110;
            e[82] = 111;
            e[83] = 112;
            e[84] = 113;
            e[85] = 114;
            e[86] = 115;
            e[87] = 116;
            e[88] = 117;
            e[89] = 118;
            e[90] = 119;
            e[91] = 120;
            e[92] = 121;
            e[93] = 122;
            e[94] = 123;
            e[95] = 124;
            e[96] = 125;
            e[97] = 126;
            e[98] = 196;
            e[99] = 197;
            e[100] = 199;
            e[101] = 201;
            e[102] = 209;
            e[103] = 214;
            e[104] = 220;
            e[105] = 225;
            e[106] = 224;
            e[107] = 226;
            e[108] = 228;
            e[109] = 227;
            e[110] = 229;
            e[111] = 231;
            e[112] = 233;
            e[113] = 232;
            e[114] = 234;
            e[115] = 235;
            e[116] = 237;
            e[117] = 236;
            e[118] = 238;
            e[119] = 239;
            e[120] = 241;
            e[121] = 243;
            e[122] = 242;
            e[123] = 244;
            e[124] = 246;
            e[125] = 245;
            e[126] = 250;
            e[127] = 249;
            e[128] = 251;
            e[129] = 252;
            e[130] = 8224;
            e[131] = 176;
            e[132] = 162;
            e[133] = 163;
            e[134] = 167;
            e[135] = 8226;
            e[136] = 182;
            e[137] = 223;
            e[138] = 174;
            e[139] = 169;
            e[140] = 8482;
            e[141] = 180;
            e[142] = 168;
            e[143] = 8800;
            e[144] = 198;
            e[145] = 216;
            e[146] = 8734;
            e[147] = 177;
            e[148] = 8804;
            e[149] = 8805;
            e[150] = 165;
            e[151] = 181;
            e[152] = 8706;
            e[153] = 8721;
            e[154] = 8719;
            e[156] = 8747;
            e[157] = 170;
            e[158] = 186;
            e[159] = 8486;
            e[160] = 230;
            e[161] = 248;
            e[162] = 191;
            e[163] = 161;
            e[164] = 172;
            e[165] = 8730;
            e[166] = 402;
            e[167] = 8776;
            e[168] = 8710;
            e[169] = 171;
            e[170] = 187;
            e[171] = 8230;
            e[179] = 8220;
            e[180] = 8221;
            e[181] = 8216;
            e[182] = 8217;
            e[200] = 193;
            e[203] = 205;
            e[207] = 211;
            e[210] = 218;
            e[223] = 711;
            e[224] = 321;
            e[225] = 322;
            e[226] = 352;
            e[227] = 353;
            e[228] = 381;
            e[229] = 382;
            e[233] = 221;
            e[234] = 253;
            e[252] = 263;
            e[253] = 268;
            e[254] = 269;
            e[258] = 258;
            e[260] = 260;
            e[261] = 261;
            e[265] = 280;
            e[266] = 281;
            e[267] = 282;
            e[268] = 283;
            e[269] = 313;
            e[275] = 323;
            e[276] = 324;
            e[278] = 328;
            e[283] = 344;
            e[284] = 345;
            e[285] = 346;
            e[286] = 347;
            e[292] = 367;
            e[295] = 377;
            e[296] = 378;
            e[298] = 380;
            e[305] = 963;
            e[306] = 964;
            e[307] = 966;
            e[308] = 8215;
            e[309] = 8252;
            e[310] = 8319;
            e[311] = 8359;
            e[312] = 8592;
            e[313] = 8593;
            e[337] = 9552;
            e[493] = 1039;
            e[494] = 1040;
            e[672] = 1488;
            e[673] = 1489;
            e[674] = 1490;
            e[675] = 1491;
            e[676] = 1492;
            e[677] = 1493;
            e[678] = 1494;
            e[679] = 1495;
            e[680] = 1496;
            e[681] = 1497;
            e[682] = 1498;
            e[683] = 1499;
            e[684] = 1500;
            e[685] = 1501;
            e[686] = 1502;
            e[687] = 1503;
            e[688] = 1504;
            e[689] = 1505;
            e[690] = 1506;
            e[691] = 1507;
            e[692] = 1508;
            e[693] = 1509;
            e[694] = 1510;
            e[695] = 1511;
            e[696] = 1512;
            e[697] = 1513;
            e[698] = 1514;
            e[705] = 1524;
            e[706] = 8362;
            e[710] = 64288;
            e[711] = 64298;
            e[759] = 1617;
            e[761] = 1776;
            e[763] = 1778;
            e[775] = 1652;
            e[777] = 1764;
            e[778] = 1780;
            e[779] = 1781;
            e[780] = 1782;
            e[782] = 771;
            e[783] = 64726;
            e[786] = 8363;
            e[788] = 8532;
            e[790] = 768;
            e[791] = 769;
            e[792] = 768;
            e[795] = 803;
            e[797] = 64336;
            e[798] = 64337;
            e[799] = 64342;
            e[800] = 64343;
            e[801] = 64344;
            e[802] = 64345;
            e[803] = 64362;
            e[804] = 64363;
            e[805] = 64364;
            e[2424] = 7821;
            e[2425] = 7822;
            e[2426] = 7823;
            e[2427] = 7824;
            e[2428] = 7825;
            e[2429] = 7826;
            e[2430] = 7827;
            e[2433] = 7682;
            e[2678] = 8045;
            e[2679] = 8046;
            e[2830] = 1552;
            e[2838] = 686;
            e[2840] = 751;
            e[2842] = 753;
            e[2843] = 754;
            e[2844] = 755;
            e[2846] = 757;
            e[2856] = 767;
            e[2857] = 848;
            e[2858] = 849;
            e[2862] = 853;
            e[2863] = 854;
            e[2864] = 855;
            e[2865] = 861;
            e[2866] = 862;
            e[2906] = 7460;
            e[2908] = 7462;
            e[2909] = 7463;
            e[2910] = 7464;
            e[2912] = 7466;
            e[2913] = 7467;
            e[2914] = 7468;
            e[2916] = 7470;
            e[2917] = 7471;
            e[2918] = 7472;
            e[2920] = 7474;
            e[2921] = 7475;
            e[2922] = 7476;
            e[2924] = 7478;
            e[2925] = 7479;
            e[2926] = 7480;
            e[2928] = 7482;
            e[2929] = 7483;
            e[2930] = 7484;
            e[2932] = 7486;
            e[2933] = 7487;
            e[2934] = 7488;
            e[2936] = 7490;
            e[2937] = 7491;
            e[2938] = 7492;
            e[2940] = 7494;
            e[2941] = 7495;
            e[2942] = 7496;
            e[2944] = 7498;
            e[2946] = 7500;
            e[2948] = 7502;
            e[2950] = 7504;
            e[2951] = 7505;
            e[2952] = 7506;
            e[2954] = 7508;
            e[2955] = 7509;
            e[2956] = 7510;
            e[2958] = 7512;
            e[2959] = 7513;
            e[2960] = 7514;
            e[2962] = 7516;
            e[2963] = 7517;
            e[2964] = 7518;
            e[2966] = 7520;
            e[2967] = 7521;
            e[2968] = 7522;
            e[2970] = 7524;
            e[2971] = 7525;
            e[2972] = 7526;
            e[2974] = 7528;
            e[2975] = 7529;
            e[2976] = 7530;
            e[2978] = 1537;
            e[2979] = 1538;
            e[2980] = 1539;
            e[2982] = 1549;
            e[2983] = 1551;
            e[2984] = 1552;
            e[2986] = 1554;
            e[2987] = 1555;
            e[2988] = 1556;
            e[2990] = 1623;
            e[2991] = 1624;
            e[2995] = 1775;
            e[2999] = 1791;
            e[3002] = 64290;
            e[3003] = 64291;
            e[3004] = 64292;
            e[3006] = 64294;
            e[3007] = 64295;
            e[3008] = 64296;
            e[3011] = 1900;
            e[3014] = 8223;
            e[3015] = 8244;
            e[3017] = 7532;
            e[3018] = 7533;
            e[3019] = 7534;
            e[3075] = 7590;
            e[3076] = 7591;
            e[3079] = 7594;
            e[3080] = 7595;
            e[3083] = 7598;
            e[3084] = 7599;
            e[3087] = 7602;
            e[3088] = 7603;
            e[3091] = 7606;
            e[3092] = 7607;
            e[3095] = 7610;
            e[3096] = 7611;
            e[3099] = 7614;
            e[3100] = 7615;
            e[3103] = 7618;
            e[3104] = 7619;
            e[3107] = 8337;
            e[3108] = 8338;
            e[3116] = 1884;
            e[3119] = 1885;
            e[3120] = 1885;
            e[3123] = 1886;
            e[3124] = 1886;
            e[3127] = 1887;
            e[3128] = 1887;
            e[3131] = 1888;
            e[3132] = 1888;
            e[3135] = 1889;
            e[3136] = 1889;
            e[3139] = 1890;
            e[3140] = 1890;
            e[3143] = 1891;
            e[3144] = 1891;
            e[3147] = 1892;
            e[3148] = 1892;
            e[3153] = 580;
            e[3154] = 581;
            e[3157] = 584;
            e[3158] = 585;
            e[3161] = 588;
            e[3162] = 589;
            e[3165] = 891;
            e[3166] = 892;
            e[3169] = 1274;
            e[3170] = 1275;
            e[3173] = 1278;
            e[3174] = 1279;
            e[3181] = 7622;
            e[3182] = 7623;
            e[3282] = 11799;
            e[3316] = 578;
            e[3379] = 42785;
            e[3393] = 1159;
            e[3416] = 8377;
          });
          t.getGlyphMapForStandardFonts = h;
          const u = (0, r.getLookupTableFactory)(function (e) {
            e[227] = 322;
            e[264] = 261;
            e[291] = 346;
          });
          t.getSupplementalGlyphMapForArialBlack = u;
          const d = (0, r.getLookupTableFactory)(function (e) {
            e[1] = 32;
            e[4] = 65;
            e[5] = 192;
            e[6] = 193;
            e[9] = 196;
            e[17] = 66;
            e[18] = 67;
            e[21] = 268;
            e[24] = 68;
            e[28] = 69;
            e[29] = 200;
            e[30] = 201;
            e[32] = 282;
            e[38] = 70;
            e[39] = 71;
            e[44] = 72;
            e[47] = 73;
            e[48] = 204;
            e[49] = 205;
            e[58] = 74;
            e[60] = 75;
            e[62] = 76;
            e[68] = 77;
            e[69] = 78;
            e[75] = 79;
            e[76] = 210;
            e[80] = 214;
            e[87] = 80;
            e[89] = 81;
            e[90] = 82;
            e[92] = 344;
            e[94] = 83;
            e[97] = 352;
            e[100] = 84;
            e[104] = 85;
            e[109] = 220;
            e[115] = 86;
            e[116] = 87;
            e[121] = 88;
            e[122] = 89;
            e[124] = 221;
            e[127] = 90;
            e[129] = 381;
            e[258] = 97;
            e[259] = 224;
            e[260] = 225;
            e[263] = 228;
            e[268] = 261;
            e[271] = 98;
            e[272] = 99;
            e[273] = 263;
            e[275] = 269;
            e[282] = 100;
            e[286] = 101;
            e[287] = 232;
            e[288] = 233;
            e[290] = 283;
            e[295] = 281;
            e[296] = 102;
            e[336] = 103;
            e[346] = 104;
            e[349] = 105;
            e[350] = 236;
            e[351] = 237;
            e[361] = 106;
            e[364] = 107;
            e[367] = 108;
            e[371] = 322;
            e[373] = 109;
            e[374] = 110;
            e[381] = 111;
            e[382] = 242;
            e[383] = 243;
            e[386] = 246;
            e[393] = 112;
            e[395] = 113;
            e[396] = 114;
            e[398] = 345;
            e[400] = 115;
            e[401] = 347;
            e[403] = 353;
            e[410] = 116;
            e[437] = 117;
            e[442] = 252;
            e[448] = 118;
            e[449] = 119;
            e[454] = 120;
            e[455] = 121;
            e[457] = 253;
            e[460] = 122;
            e[462] = 382;
            e[463] = 380;
            e[853] = 44;
            e[855] = 58;
            e[856] = 46;
            e[876] = 47;
            e[878] = 45;
            e[882] = 45;
            e[894] = 40;
            e[895] = 41;
            e[896] = 91;
            e[897] = 93;
            e[923] = 64;
            e[1004] = 48;
            e[1005] = 49;
            e[1006] = 50;
            e[1007] = 51;
            e[1008] = 52;
            e[1009] = 53;
            e[1010] = 54;
            e[1011] = 55;
            e[1012] = 56;
            e[1013] = 57;
            e[1081] = 37;
            e[1085] = 43;
            e[1086] = 45;
          });
          t.getSupplementalGlyphMapForCalibri = d;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ToUnicodeMap = t.IdentityToUnicodeMap = void 0;
          var r = a(2);
          t.ToUnicodeMap = class ToUnicodeMap {
            constructor(e = []) {
              this._map = e;
            }
            get length() {
              return this._map.length;
            }
            forEach(e) {
              for (const t in this._map) e(t, this._map[t].charCodeAt(0));
            }
            has(e) {
              return void 0 !== this._map[e];
            }
            get(e) {
              return this._map[e];
            }
            charCodeOf(e) {
              const t = this._map;
              if (t.length <= 65536) return t.indexOf(e);
              for (const a in t) if (t[a] === e) return 0 | a;
              return -1;
            }
            amend(e) {
              for (const t in e) this._map[t] = e[t];
            }
          };
          t.IdentityToUnicodeMap = class IdentityToUnicodeMap {
            constructor(e, t) {
              this.firstChar = e;
              this.lastChar = t;
            }
            get length() {
              return this.lastChar + 1 - this.firstChar;
            }
            forEach(e) {
              for (let t = this.firstChar, a = this.lastChar; t <= a; t++)
                e(t, t);
            }
            has(e) {
              return this.firstChar <= e && e <= this.lastChar;
            }
            get(e) {
              if (this.firstChar <= e && e <= this.lastChar)
                return String.fromCharCode(e);
            }
            charCodeOf(e) {
              return Number.isInteger(e) &&
                e >= this.firstChar &&
                e <= this.lastChar
                ? e
                : -1;
            }
            amend(e) {
              (0, r.unreachable)("Should not call amend()");
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.CFFFont = void 0;
          var r = a(33),
            n = a(36),
            i = a(2);
          t.CFFFont = class CFFFont {
            constructor(e, t) {
              this.properties = t;
              const a = new r.CFFParser(e, t, n.SEAC_ANALYSIS_ENABLED);
              this.cff = a.parse();
              this.cff.duplicateFirstGlyph();
              const s = new r.CFFCompiler(this.cff);
              this.seacs = this.cff.seacs;
              try {
                this.data = s.compile();
              } catch (a) {
                (0, i.warn)("Failed to compile font " + t.loadedName);
                this.data = e;
              }
              this._createBuiltInEncoding();
            }
            get numGlyphs() {
              return this.cff.charStrings.count;
            }
            getCharset() {
              return this.cff.charset.charset;
            }
            getGlyphMapping() {
              const e = this.cff,
                t = this.properties,
                { cidToGidMap: a, cMap: r } = t,
                i = e.charset.charset;
              let s, o;
              if (t.composite) {
                let t, n;
                if (a && a.length > 0) {
                  t = Object.create(null);
                  for (let e = 0, r = a.length; e < r; e++) {
                    const r = a[e];
                    void 0 !== r && (t[r] = e);
                  }
                }
                s = Object.create(null);
                if (e.isCIDFont)
                  for (o = 0; o < i.length; o++) {
                    const e = i[o];
                    n = r.charCodeOf(e);
                    t && void 0 !== t[n] && (n = t[n]);
                    s[n] = o;
                  }
                else
                  for (o = 0; o < e.charStrings.count; o++) {
                    n = r.charCodeOf(o);
                    s[n] = o;
                  }
                return s;
              }
              let c = e.encoding ? e.encoding.encoding : null;
              t.isInternalFont && (c = t.defaultEncoding);
              s = (0, n.type1FontGlyphMapping)(t, c, i);
              return s;
            }
            hasGlyphId(e) {
              return this.cff.hasGlyphId(e);
            }
            _createBuiltInEncoding() {
              const { charset: e, encoding: t } = this.cff;
              if (!e || !t) return;
              const a = e.charset,
                r = t.encoding,
                n = [];
              for (const e in r) {
                const t = r[e];
                if (t >= 0) {
                  const r = a[t];
                  r && (n[e] = r);
                }
              }
              n.length > 0 && (this.properties.builtInEncoding = n);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.FontRendererFactory = void 0;
          var r = a(2),
            n = a(33),
            i = a(37),
            s = a(35),
            o = a(8);
          function getUint32(e, t) {
            return (
              ((e[t] << 24) | (e[t + 1] << 16) | (e[t + 2] << 8) | e[t + 3]) >>>
              0
            );
          }
          function getUint16(e, t) {
            return (e[t] << 8) | e[t + 1];
          }
          function getInt16(e, t) {
            return ((e[t] << 24) | (e[t + 1] << 16)) >> 16;
          }
          function getInt8(e, t) {
            return (e[t] << 24) >> 24;
          }
          function getFloat214(e, t) {
            return getInt16(e, t) / 16384;
          }
          function getSubroutineBias(e) {
            const t = e.length;
            let a = 32768;
            t < 1240 ? (a = 107) : t < 33900 && (a = 1131);
            return a;
          }
          function parseCmap(e, t, a) {
            const n =
                1 === getUint16(e, t + 2)
                  ? getUint32(e, t + 8)
                  : getUint32(e, t + 16),
              i = getUint16(e, t + n);
            let s, o, c;
            if (4 === i) {
              getUint16(e, t + n + 2);
              const a = getUint16(e, t + n + 6) >> 1;
              o = t + n + 14;
              s = [];
              for (c = 0; c < a; c++, o += 2) s[c] = { end: getUint16(e, o) };
              o += 2;
              for (c = 0; c < a; c++, o += 2) s[c].start = getUint16(e, o);
              for (c = 0; c < a; c++, o += 2) s[c].idDelta = getUint16(e, o);
              for (c = 0; c < a; c++, o += 2) {
                let t = getUint16(e, o);
                if (0 !== t) {
                  s[c].ids = [];
                  for (let a = 0, r = s[c].end - s[c].start + 1; a < r; a++) {
                    s[c].ids[a] = getUint16(e, o + t);
                    t += 2;
                  }
                }
              }
              return s;
            }
            if (12 === i) {
              const a = getUint32(e, t + n + 12);
              o = t + n + 16;
              s = [];
              for (c = 0; c < a; c++) {
                t = getUint32(e, o);
                s.push({
                  start: t,
                  end: getUint32(e, o + 4),
                  idDelta: getUint32(e, o + 8) - t,
                });
                o += 12;
              }
              return s;
            }
            throw new r.FormatError(`unsupported cmap: ${i}`);
          }
          function parseCff(e, t, a, r) {
            const i = new n.CFFParser(new o.Stream(e, t, a - t), {}, r).parse();
            return {
              glyphs: i.charStrings.objects,
              subrs:
                i.topDict.privateDict &&
                i.topDict.privateDict.subrsIndex &&
                i.topDict.privateDict.subrsIndex.objects,
              gsubrs: i.globalSubrIndex && i.globalSubrIndex.objects,
              isCFFCIDFont: i.isCIDFont,
              fdSelect: i.fdSelect,
              fdArray: i.fdArray,
            };
          }
          function lookupCmap(e, t) {
            const a = t.codePointAt(0);
            let r = 0,
              n = 0,
              i = e.length - 1;
            for (; n < i; ) {
              const t = (n + i + 1) >> 1;
              a < e[t].start ? (i = t - 1) : (n = t);
            }
            e[n].start <= a &&
              a <= e[n].end &&
              (r =
                (e[n].idDelta + (e[n].ids ? e[n].ids[a - e[n].start] : a)) &
                65535);
            return { charCode: a, glyphId: r };
          }
          function compileGlyf(e, t, a) {
            function moveTo(e, a) {
              t.push({ cmd: "moveTo", args: [e, a] });
            }
            function lineTo(e, a) {
              t.push({ cmd: "lineTo", args: [e, a] });
            }
            function quadraticCurveTo(e, a, r, n) {
              t.push({ cmd: "quadraticCurveTo", args: [e, a, r, n] });
            }
            let r = 0;
            const n = getInt16(e, r);
            let i,
              s = 0,
              o = 0;
            r += 10;
            if (n < 0)
              do {
                i = getUint16(e, r);
                const n = getUint16(e, r + 2);
                r += 4;
                let c, l;
                if (1 & i) {
                  if (2 & i) {
                    c = getInt16(e, r);
                    l = getInt16(e, r + 2);
                  } else {
                    c = getUint16(e, r);
                    l = getUint16(e, r + 2);
                  }
                  r += 4;
                } else if (2 & i) {
                  c = getInt8(e, r++);
                  l = getInt8(e, r++);
                } else {
                  c = e[r++];
                  l = e[r++];
                }
                if (2 & i) {
                  s = c;
                  o = l;
                } else {
                  s = 0;
                  o = 0;
                }
                let h = 1,
                  u = 1,
                  d = 0,
                  f = 0;
                if (8 & i) {
                  h = u = getFloat214(e, r);
                  r += 2;
                } else if (64 & i) {
                  h = getFloat214(e, r);
                  u = getFloat214(e, r + 2);
                  r += 4;
                } else if (128 & i) {
                  h = getFloat214(e, r);
                  d = getFloat214(e, r + 2);
                  f = getFloat214(e, r + 4);
                  u = getFloat214(e, r + 6);
                  r += 8;
                }
                const g = a.glyphs[n];
                if (g) {
                  t.push(
                    { cmd: "save" },
                    { cmd: "transform", args: [h, d, f, u, s, o] }
                  );
                  compileGlyf(g, t, a);
                  t.push({ cmd: "restore" });
                }
              } while (32 & i);
            else {
              const t = [];
              let a, c;
              for (a = 0; a < n; a++) {
                t.push(getUint16(e, r));
                r += 2;
              }
              r += 2 + getUint16(e, r);
              const l = t.at(-1) + 1,
                h = [];
              for (; h.length < l; ) {
                i = e[r++];
                let t = 1;
                8 & i && (t += e[r++]);
                for (; t-- > 0; ) h.push({ flags: i });
              }
              for (a = 0; a < l; a++) {
                switch (18 & h[a].flags) {
                  case 0:
                    s += getInt16(e, r);
                    r += 2;
                    break;
                  case 2:
                    s -= e[r++];
                    break;
                  case 18:
                    s += e[r++];
                }
                h[a].x = s;
              }
              for (a = 0; a < l; a++) {
                switch (36 & h[a].flags) {
                  case 0:
                    o += getInt16(e, r);
                    r += 2;
                    break;
                  case 4:
                    o -= e[r++];
                    break;
                  case 36:
                    o += e[r++];
                }
                h[a].y = o;
              }
              let u = 0;
              for (r = 0; r < n; r++) {
                const e = t[r],
                  n = h.slice(u, e + 1);
                if (1 & n[0].flags) n.push(n[0]);
                else if (1 & n.at(-1).flags) n.unshift(n.at(-1));
                else {
                  const e = {
                    flags: 1,
                    x: (n[0].x + n.at(-1).x) / 2,
                    y: (n[0].y + n.at(-1).y) / 2,
                  };
                  n.unshift(e);
                  n.push(e);
                }
                moveTo(n[0].x, n[0].y);
                for (a = 1, c = n.length; a < c; a++)
                  if (1 & n[a].flags) lineTo(n[a].x, n[a].y);
                  else if (1 & n[a + 1].flags) {
                    quadraticCurveTo(n[a].x, n[a].y, n[a + 1].x, n[a + 1].y);
                    a++;
                  } else
                    quadraticCurveTo(
                      n[a].x,
                      n[a].y,
                      (n[a].x + n[a + 1].x) / 2,
                      (n[a].y + n[a + 1].y) / 2
                    );
                u = e + 1;
              }
            }
          }
          function compileCharString(e, t, a, n) {
            function moveTo(e, a) {
              t.push({ cmd: "moveTo", args: [e, a] });
            }
            function lineTo(e, a) {
              t.push({ cmd: "lineTo", args: [e, a] });
            }
            function bezierCurveTo(e, a, r, n, i, s) {
              t.push({ cmd: "bezierCurveTo", args: [e, a, r, n, i, s] });
            }
            const i = [];
            let o = 0,
              c = 0,
              l = 0;
            !(function parse(e) {
              let h = 0;
              for (; h < e.length; ) {
                let u,
                  d,
                  f,
                  g,
                  p,
                  m,
                  b,
                  y,
                  w,
                  S = !1,
                  x = e[h++];
                switch (x) {
                  case 1:
                  case 3:
                  case 18:
                  case 23:
                    l += i.length >> 1;
                    S = !0;
                    break;
                  case 4:
                    c += i.pop();
                    moveTo(o, c);
                    S = !0;
                    break;
                  case 5:
                    for (; i.length > 0; ) {
                      o += i.shift();
                      c += i.shift();
                      lineTo(o, c);
                    }
                    break;
                  case 6:
                    for (; i.length > 0; ) {
                      o += i.shift();
                      lineTo(o, c);
                      if (0 === i.length) break;
                      c += i.shift();
                      lineTo(o, c);
                    }
                    break;
                  case 7:
                    for (; i.length > 0; ) {
                      c += i.shift();
                      lineTo(o, c);
                      if (0 === i.length) break;
                      o += i.shift();
                      lineTo(o, c);
                    }
                    break;
                  case 8:
                    for (; i.length > 0; ) {
                      u = o + i.shift();
                      f = c + i.shift();
                      d = u + i.shift();
                      g = f + i.shift();
                      o = d + i.shift();
                      c = g + i.shift();
                      bezierCurveTo(u, f, d, g, o, c);
                    }
                    break;
                  case 10:
                    y = i.pop();
                    w = null;
                    if (a.isCFFCIDFont) {
                      const e = a.fdSelect.getFDIndex(n);
                      if (e >= 0 && e < a.fdArray.length) {
                        const t = a.fdArray[e];
                        let r;
                        t.privateDict &&
                          t.privateDict.subrsIndex &&
                          (r = t.privateDict.subrsIndex.objects);
                        if (r) {
                          y += getSubroutineBias(r);
                          w = r[y];
                        }
                      } else (0, r.warn)("Invalid fd index for glyph index.");
                    } else w = a.subrs[y + a.subrsBias];
                    w && parse(w);
                    break;
                  case 11:
                    return;
                  case 12:
                    x = e[h++];
                    switch (x) {
                      case 34:
                        u = o + i.shift();
                        d = u + i.shift();
                        p = c + i.shift();
                        o = d + i.shift();
                        bezierCurveTo(u, c, d, p, o, p);
                        u = o + i.shift();
                        d = u + i.shift();
                        o = d + i.shift();
                        bezierCurveTo(u, p, d, c, o, c);
                        break;
                      case 35:
                        u = o + i.shift();
                        f = c + i.shift();
                        d = u + i.shift();
                        g = f + i.shift();
                        o = d + i.shift();
                        c = g + i.shift();
                        bezierCurveTo(u, f, d, g, o, c);
                        u = o + i.shift();
                        f = c + i.shift();
                        d = u + i.shift();
                        g = f + i.shift();
                        o = d + i.shift();
                        c = g + i.shift();
                        bezierCurveTo(u, f, d, g, o, c);
                        i.pop();
                        break;
                      case 36:
                        u = o + i.shift();
                        p = c + i.shift();
                        d = u + i.shift();
                        m = p + i.shift();
                        o = d + i.shift();
                        bezierCurveTo(u, p, d, m, o, m);
                        u = o + i.shift();
                        d = u + i.shift();
                        b = m + i.shift();
                        o = d + i.shift();
                        bezierCurveTo(u, m, d, b, o, c);
                        break;
                      case 37:
                        const e = o,
                          t = c;
                        u = o + i.shift();
                        f = c + i.shift();
                        d = u + i.shift();
                        g = f + i.shift();
                        o = d + i.shift();
                        c = g + i.shift();
                        bezierCurveTo(u, f, d, g, o, c);
                        u = o + i.shift();
                        f = c + i.shift();
                        d = u + i.shift();
                        g = f + i.shift();
                        o = d;
                        c = g;
                        Math.abs(o - e) > Math.abs(c - t)
                          ? (o += i.shift())
                          : (c += i.shift());
                        bezierCurveTo(u, f, d, g, o, c);
                        break;
                      default:
                        throw new r.FormatError(`unknown operator: 12 ${x}`);
                    }
                    break;
                  case 14:
                    if (i.length >= 4) {
                      const e = i.pop(),
                        r = i.pop();
                      c = i.pop();
                      o = i.pop();
                      t.push(
                        { cmd: "save" },
                        { cmd: "translate", args: [o, c] }
                      );
                      let n = lookupCmap(
                        a.cmap,
                        String.fromCharCode(
                          a.glyphNameMap[s.StandardEncoding[e]]
                        )
                      );
                      compileCharString(a.glyphs[n.glyphId], t, a, n.glyphId);
                      t.push({ cmd: "restore" });
                      n = lookupCmap(
                        a.cmap,
                        String.fromCharCode(
                          a.glyphNameMap[s.StandardEncoding[r]]
                        )
                      );
                      compileCharString(a.glyphs[n.glyphId], t, a, n.glyphId);
                    }
                    return;
                  case 19:
                  case 20:
                    l += i.length >> 1;
                    h += (l + 7) >> 3;
                    S = !0;
                    break;
                  case 21:
                    c += i.pop();
                    o += i.pop();
                    moveTo(o, c);
                    S = !0;
                    break;
                  case 22:
                    o += i.pop();
                    moveTo(o, c);
                    S = !0;
                    break;
                  case 24:
                    for (; i.length > 2; ) {
                      u = o + i.shift();
                      f = c + i.shift();
                      d = u + i.shift();
                      g = f + i.shift();
                      o = d + i.shift();
                      c = g + i.shift();
                      bezierCurveTo(u, f, d, g, o, c);
                    }
                    o += i.shift();
                    c += i.shift();
                    lineTo(o, c);
                    break;
                  case 25:
                    for (; i.length > 6; ) {
                      o += i.shift();
                      c += i.shift();
                      lineTo(o, c);
                    }
                    u = o + i.shift();
                    f = c + i.shift();
                    d = u + i.shift();
                    g = f + i.shift();
                    o = d + i.shift();
                    c = g + i.shift();
                    bezierCurveTo(u, f, d, g, o, c);
                    break;
                  case 26:
                    i.length % 2 && (o += i.shift());
                    for (; i.length > 0; ) {
                      u = o;
                      f = c + i.shift();
                      d = u + i.shift();
                      g = f + i.shift();
                      o = d;
                      c = g + i.shift();
                      bezierCurveTo(u, f, d, g, o, c);
                    }
                    break;
                  case 27:
                    i.length % 2 && (c += i.shift());
                    for (; i.length > 0; ) {
                      u = o + i.shift();
                      f = c;
                      d = u + i.shift();
                      g = f + i.shift();
                      o = d + i.shift();
                      c = g;
                      bezierCurveTo(u, f, d, g, o, c);
                    }
                    break;
                  case 28:
                    i.push(((e[h] << 24) | (e[h + 1] << 16)) >> 16);
                    h += 2;
                    break;
                  case 29:
                    y = i.pop() + a.gsubrsBias;
                    w = a.gsubrs[y];
                    w && parse(w);
                    break;
                  case 30:
                    for (; i.length > 0; ) {
                      u = o;
                      f = c + i.shift();
                      d = u + i.shift();
                      g = f + i.shift();
                      o = d + i.shift();
                      c = g + (1 === i.length ? i.shift() : 0);
                      bezierCurveTo(u, f, d, g, o, c);
                      if (0 === i.length) break;
                      u = o + i.shift();
                      f = c;
                      d = u + i.shift();
                      g = f + i.shift();
                      c = g + i.shift();
                      o = d + (1 === i.length ? i.shift() : 0);
                      bezierCurveTo(u, f, d, g, o, c);
                    }
                    break;
                  case 31:
                    for (; i.length > 0; ) {
                      u = o + i.shift();
                      f = c;
                      d = u + i.shift();
                      g = f + i.shift();
                      c = g + i.shift();
                      o = d + (1 === i.length ? i.shift() : 0);
                      bezierCurveTo(u, f, d, g, o, c);
                      if (0 === i.length) break;
                      u = o;
                      f = c + i.shift();
                      d = u + i.shift();
                      g = f + i.shift();
                      o = d + i.shift();
                      c = g + (1 === i.length ? i.shift() : 0);
                      bezierCurveTo(u, f, d, g, o, c);
                    }
                    break;
                  default:
                    if (x < 32)
                      throw new r.FormatError(`unknown operator: ${x}`);
                    if (x < 247) i.push(x - 139);
                    else if (x < 251) i.push(256 * (x - 247) + e[h++] + 108);
                    else if (x < 255) i.push(256 * -(x - 251) - e[h++] - 108);
                    else {
                      i.push(
                        ((e[h] << 24) |
                          (e[h + 1] << 16) |
                          (e[h + 2] << 8) |
                          e[h + 3]) /
                          65536
                      );
                      h += 4;
                    }
                }
                S && (i.length = 0);
              }
            })(e);
          }
          const c = [];
          class CompiledFont {
            constructor(e) {
              this.constructor === CompiledFont &&
                (0, r.unreachable)("Cannot initialize CompiledFont.");
              this.fontMatrix = e;
              this.compiledGlyphs = Object.create(null);
              this.compiledCharCodeToGlyphId = Object.create(null);
            }
            getPathJs(e) {
              const { charCode: t, glyphId: a } = lookupCmap(this.cmap, e);
              let r = this.compiledGlyphs[a];
              if (!r)
                try {
                  r = this.compileGlyph(this.glyphs[a], a);
                  this.compiledGlyphs[a] = r;
                } catch (e) {
                  this.compiledGlyphs[a] = c;
                  void 0 === this.compiledCharCodeToGlyphId[t] &&
                    (this.compiledCharCodeToGlyphId[t] = a);
                  throw e;
                }
              void 0 === this.compiledCharCodeToGlyphId[t] &&
                (this.compiledCharCodeToGlyphId[t] = a);
              return r;
            }
            compileGlyph(e, t) {
              if (!e || 0 === e.length || 14 === e[0]) return c;
              let a = this.fontMatrix;
              if (this.isCFFCIDFont) {
                const e = this.fdSelect.getFDIndex(t);
                if (e >= 0 && e < this.fdArray.length) {
                  a =
                    this.fdArray[e].getByName("FontMatrix") ||
                    r.FONT_IDENTITY_MATRIX;
                } else (0, r.warn)("Invalid fd index for glyph index.");
              }
              const n = [
                { cmd: "save" },
                { cmd: "transform", args: a.slice() },
                { cmd: "scale", args: ["size", "-size"] },
              ];
              this.compileGlyphImpl(e, n, t);
              n.push({ cmd: "restore" });
              return n;
            }
            compileGlyphImpl() {
              (0, r.unreachable)("Children classes should implement this.");
            }
            hasBuiltPath(e) {
              const { charCode: t, glyphId: a } = lookupCmap(this.cmap, e);
              return (
                void 0 !== this.compiledGlyphs[a] &&
                void 0 !== this.compiledCharCodeToGlyphId[t]
              );
            }
          }
          class TrueTypeCompiled extends CompiledFont {
            constructor(e, t, a) {
              super(a || [488e-6, 0, 0, 488e-6, 0, 0]);
              this.glyphs = e;
              this.cmap = t;
            }
            compileGlyphImpl(e, t) {
              compileGlyf(e, t, this);
            }
          }
          class Type2Compiled extends CompiledFont {
            constructor(e, t, a, r) {
              super(a || [0.001, 0, 0, 0.001, 0, 0]);
              this.glyphs = e.glyphs;
              this.gsubrs = e.gsubrs || [];
              this.subrs = e.subrs || [];
              this.cmap = t;
              this.glyphNameMap = r || (0, i.getGlyphsUnicode)();
              this.gsubrsBias = getSubroutineBias(this.gsubrs);
              this.subrsBias = getSubroutineBias(this.subrs);
              this.isCFFCIDFont = e.isCFFCIDFont;
              this.fdSelect = e.fdSelect;
              this.fdArray = e.fdArray;
            }
            compileGlyphImpl(e, t, a) {
              compileCharString(e, t, this, a);
            }
          }
          t.FontRendererFactory = class FontRendererFactory {
            static create(e, t) {
              const a = new Uint8Array(e.data);
              let n, i, s, o, c, l;
              const h = getUint16(a, 4);
              for (let e = 0, u = 12; e < h; e++, u += 16) {
                const e = (0, r.bytesToString)(a.subarray(u, u + 4)),
                  h = getUint32(a, u + 8),
                  d = getUint32(a, u + 12);
                switch (e) {
                  case "cmap":
                    n = parseCmap(a, h);
                    break;
                  case "glyf":
                    i = a.subarray(h, h + d);
                    break;
                  case "loca":
                    s = a.subarray(h, h + d);
                    break;
                  case "head":
                    l = getUint16(a, h + 18);
                    c = getUint16(a, h + 50);
                    break;
                  case "CFF ":
                    o = parseCff(a, h, h + d, t);
                }
              }
              if (i) {
                const t = l ? [1 / l, 0, 0, 1 / l, 0, 0] : e.fontMatrix;
                return new TrueTypeCompiled(
                  (function parseGlyfTable(e, t, a) {
                    let r, n;
                    if (a) {
                      r = 4;
                      n = getUint32;
                    } else {
                      r = 2;
                      n = (e, t) => 2 * getUint16(e, t);
                    }
                    const i = [];
                    let s = n(t, 0);
                    for (let a = r; a < t.length; a += r) {
                      const r = n(t, a);
                      i.push(e.subarray(s, r));
                      s = r;
                    }
                    return i;
                  })(i, s, c),
                  n,
                  t
                );
              }
              return new Type2Compiled(o, n, e.fontMatrix, e.glyphNameMap);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.getMetrics = t.getFontBasicMetrics = void 0;
          var r = a(3);
          const n = (0, r.getLookupTableFactory)(function (e) {
            e.Courier = 600;
            e["Courier-Bold"] = 600;
            e["Courier-BoldOblique"] = 600;
            e["Courier-Oblique"] = 600;
            e.Helvetica = (0, r.getLookupTableFactory)(function (e) {
              e.space = 278;
              e.exclam = 278;
              e.quotedbl = 355;
              e.numbersign = 556;
              e.dollar = 556;
              e.percent = 889;
              e.ampersand = 667;
              e.quoteright = 222;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 389;
              e.plus = 584;
              e.comma = 278;
              e.hyphen = 333;
              e.period = 278;
              e.slash = 278;
              e.zero = 556;
              e.one = 556;
              e.two = 556;
              e.three = 556;
              e.four = 556;
              e.five = 556;
              e.six = 556;
              e.seven = 556;
              e.eight = 556;
              e.nine = 556;
              e.colon = 278;
              e.semicolon = 278;
              e.less = 584;
              e.equal = 584;
              e.greater = 584;
              e.question = 556;
              e.at = 1015;
              e.A = 667;
              e.B = 667;
              e.C = 722;
              e.D = 722;
              e.E = 667;
              e.F = 611;
              e.G = 778;
              e.H = 722;
              e.I = 278;
              e.J = 500;
              e.K = 667;
              e.L = 556;
              e.M = 833;
              e.N = 722;
              e.O = 778;
              e.P = 667;
              e.Q = 778;
              e.R = 722;
              e.S = 667;
              e.T = 611;
              e.U = 722;
              e.V = 667;
              e.W = 944;
              e.X = 667;
              e.Y = 667;
              e.Z = 611;
              e.bracketleft = 278;
              e.backslash = 278;
              e.bracketright = 278;
              e.asciicircum = 469;
              e.underscore = 556;
              e.quoteleft = 222;
              e.a = 556;
              e.b = 556;
              e.c = 500;
              e.d = 556;
              e.e = 556;
              e.f = 278;
              e.g = 556;
              e.h = 556;
              e.i = 222;
              e.j = 222;
              e.k = 500;
              e.l = 222;
              e.m = 833;
              e.n = 556;
              e.o = 556;
              e.p = 556;
              e.q = 556;
              e.r = 333;
              e.s = 500;
              e.t = 278;
              e.u = 556;
              e.v = 500;
              e.w = 722;
              e.x = 500;
              e.y = 500;
              e.z = 500;
              e.braceleft = 334;
              e.bar = 260;
              e.braceright = 334;
              e.asciitilde = 584;
              e.exclamdown = 333;
              e.cent = 556;
              e.sterling = 556;
              e.fraction = 167;
              e.yen = 556;
              e.florin = 556;
              e.section = 556;
              e.currency = 556;
              e.quotesingle = 191;
              e.quotedblleft = 333;
              e.guillemotleft = 556;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 500;
              e.fl = 500;
              e.endash = 556;
              e.dagger = 556;
              e.daggerdbl = 556;
              e.periodcentered = 278;
              e.paragraph = 537;
              e.bullet = 350;
              e.quotesinglbase = 222;
              e.quotedblbase = 333;
              e.quotedblright = 333;
              e.guillemotright = 556;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 611;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 1e3;
              e.ordfeminine = 370;
              e.Lslash = 556;
              e.Oslash = 778;
              e.OE = 1e3;
              e.ordmasculine = 365;
              e.ae = 889;
              e.dotlessi = 278;
              e.lslash = 222;
              e.oslash = 611;
              e.oe = 944;
              e.germandbls = 611;
              e.Idieresis = 278;
              e.eacute = 556;
              e.abreve = 556;
              e.uhungarumlaut = 556;
              e.ecaron = 556;
              e.Ydieresis = 667;
              e.divide = 584;
              e.Yacute = 667;
              e.Acircumflex = 667;
              e.aacute = 556;
              e.Ucircumflex = 722;
              e.yacute = 500;
              e.scommaaccent = 500;
              e.ecircumflex = 556;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 556;
              e.Uacute = 722;
              e.uogonek = 556;
              e.Edieresis = 667;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 737;
              e.Emacron = 667;
              e.ccaron = 500;
              e.aring = 556;
              e.Ncommaaccent = 722;
              e.lacute = 222;
              e.agrave = 556;
              e.Tcommaaccent = 611;
              e.Cacute = 722;
              e.atilde = 556;
              e.Edotaccent = 667;
              e.scaron = 500;
              e.scedilla = 500;
              e.iacute = 278;
              e.lozenge = 471;
              e.Rcaron = 722;
              e.Gcommaaccent = 778;
              e.ucircumflex = 556;
              e.acircumflex = 556;
              e.Amacron = 667;
              e.rcaron = 333;
              e.ccedilla = 500;
              e.Zdotaccent = 611;
              e.Thorn = 667;
              e.Omacron = 778;
              e.Racute = 722;
              e.Sacute = 667;
              e.dcaron = 643;
              e.Umacron = 722;
              e.uring = 556;
              e.threesuperior = 333;
              e.Ograve = 778;
              e.Agrave = 667;
              e.Abreve = 667;
              e.multiply = 584;
              e.uacute = 556;
              e.Tcaron = 611;
              e.partialdiff = 476;
              e.ydieresis = 500;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 667;
              e.adieresis = 556;
              e.edieresis = 556;
              e.cacute = 500;
              e.nacute = 556;
              e.umacron = 556;
              e.Ncaron = 722;
              e.Iacute = 278;
              e.plusminus = 584;
              e.brokenbar = 260;
              e.registered = 737;
              e.Gbreve = 778;
              e.Idotaccent = 278;
              e.summation = 600;
              e.Egrave = 667;
              e.racute = 333;
              e.omacron = 556;
              e.Zacute = 611;
              e.Zcaron = 611;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 722;
              e.lcommaaccent = 222;
              e.tcaron = 317;
              e.eogonek = 556;
              e.Uogonek = 722;
              e.Aacute = 667;
              e.Adieresis = 667;
              e.egrave = 556;
              e.zacute = 500;
              e.iogonek = 222;
              e.Oacute = 778;
              e.oacute = 556;
              e.amacron = 556;
              e.sacute = 500;
              e.idieresis = 278;
              e.Ocircumflex = 778;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 556;
              e.twosuperior = 333;
              e.Odieresis = 778;
              e.mu = 556;
              e.igrave = 278;
              e.ohungarumlaut = 556;
              e.Eogonek = 667;
              e.dcroat = 556;
              e.threequarters = 834;
              e.Scedilla = 667;
              e.lcaron = 299;
              e.Kcommaaccent = 667;
              e.Lacute = 556;
              e.trademark = 1e3;
              e.edotaccent = 556;
              e.Igrave = 278;
              e.Imacron = 278;
              e.Lcaron = 556;
              e.onehalf = 834;
              e.lessequal = 549;
              e.ocircumflex = 556;
              e.ntilde = 556;
              e.Uhungarumlaut = 722;
              e.Eacute = 667;
              e.emacron = 556;
              e.gbreve = 556;
              e.onequarter = 834;
              e.Scaron = 667;
              e.Scommaaccent = 667;
              e.Ohungarumlaut = 778;
              e.degree = 400;
              e.ograve = 556;
              e.Ccaron = 722;
              e.ugrave = 556;
              e.radical = 453;
              e.Dcaron = 722;
              e.rcommaaccent = 333;
              e.Ntilde = 722;
              e.otilde = 556;
              e.Rcommaaccent = 722;
              e.Lcommaaccent = 556;
              e.Atilde = 667;
              e.Aogonek = 667;
              e.Aring = 667;
              e.Otilde = 778;
              e.zdotaccent = 500;
              e.Ecaron = 667;
              e.Iogonek = 278;
              e.kcommaaccent = 500;
              e.minus = 584;
              e.Icircumflex = 278;
              e.ncaron = 556;
              e.tcommaaccent = 278;
              e.logicalnot = 584;
              e.odieresis = 556;
              e.udieresis = 556;
              e.notequal = 549;
              e.gcommaaccent = 556;
              e.eth = 556;
              e.zcaron = 500;
              e.ncommaaccent = 556;
              e.onesuperior = 333;
              e.imacron = 278;
              e.Euro = 556;
            });
            e["Helvetica-Bold"] = (0, r.getLookupTableFactory)(function (e) {
              e.space = 278;
              e.exclam = 333;
              e.quotedbl = 474;
              e.numbersign = 556;
              e.dollar = 556;
              e.percent = 889;
              e.ampersand = 722;
              e.quoteright = 278;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 389;
              e.plus = 584;
              e.comma = 278;
              e.hyphen = 333;
              e.period = 278;
              e.slash = 278;
              e.zero = 556;
              e.one = 556;
              e.two = 556;
              e.three = 556;
              e.four = 556;
              e.five = 556;
              e.six = 556;
              e.seven = 556;
              e.eight = 556;
              e.nine = 556;
              e.colon = 333;
              e.semicolon = 333;
              e.less = 584;
              e.equal = 584;
              e.greater = 584;
              e.question = 611;
              e.at = 975;
              e.A = 722;
              e.B = 722;
              e.C = 722;
              e.D = 722;
              e.E = 667;
              e.F = 611;
              e.G = 778;
              e.H = 722;
              e.I = 278;
              e.J = 556;
              e.K = 722;
              e.L = 611;
              e.M = 833;
              e.N = 722;
              e.O = 778;
              e.P = 667;
              e.Q = 778;
              e.R = 722;
              e.S = 667;
              e.T = 611;
              e.U = 722;
              e.V = 667;
              e.W = 944;
              e.X = 667;
              e.Y = 667;
              e.Z = 611;
              e.bracketleft = 333;
              e.backslash = 278;
              e.bracketright = 333;
              e.asciicircum = 584;
              e.underscore = 556;
              e.quoteleft = 278;
              e.a = 556;
              e.b = 611;
              e.c = 556;
              e.d = 611;
              e.e = 556;
              e.f = 333;
              e.g = 611;
              e.h = 611;
              e.i = 278;
              e.j = 278;
              e.k = 556;
              e.l = 278;
              e.m = 889;
              e.n = 611;
              e.o = 611;
              e.p = 611;
              e.q = 611;
              e.r = 389;
              e.s = 556;
              e.t = 333;
              e.u = 611;
              e.v = 556;
              e.w = 778;
              e.x = 556;
              e.y = 556;
              e.z = 500;
              e.braceleft = 389;
              e.bar = 280;
              e.braceright = 389;
              e.asciitilde = 584;
              e.exclamdown = 333;
              e.cent = 556;
              e.sterling = 556;
              e.fraction = 167;
              e.yen = 556;
              e.florin = 556;
              e.section = 556;
              e.currency = 556;
              e.quotesingle = 238;
              e.quotedblleft = 500;
              e.guillemotleft = 556;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 611;
              e.fl = 611;
              e.endash = 556;
              e.dagger = 556;
              e.daggerdbl = 556;
              e.periodcentered = 278;
              e.paragraph = 556;
              e.bullet = 350;
              e.quotesinglbase = 278;
              e.quotedblbase = 500;
              e.quotedblright = 500;
              e.guillemotright = 556;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 611;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 1e3;
              e.ordfeminine = 370;
              e.Lslash = 611;
              e.Oslash = 778;
              e.OE = 1e3;
              e.ordmasculine = 365;
              e.ae = 889;
              e.dotlessi = 278;
              e.lslash = 278;
              e.oslash = 611;
              e.oe = 944;
              e.germandbls = 611;
              e.Idieresis = 278;
              e.eacute = 556;
              e.abreve = 556;
              e.uhungarumlaut = 611;
              e.ecaron = 556;
              e.Ydieresis = 667;
              e.divide = 584;
              e.Yacute = 667;
              e.Acircumflex = 722;
              e.aacute = 556;
              e.Ucircumflex = 722;
              e.yacute = 556;
              e.scommaaccent = 556;
              e.ecircumflex = 556;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 556;
              e.Uacute = 722;
              e.uogonek = 611;
              e.Edieresis = 667;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 737;
              e.Emacron = 667;
              e.ccaron = 556;
              e.aring = 556;
              e.Ncommaaccent = 722;
              e.lacute = 278;
              e.agrave = 556;
              e.Tcommaaccent = 611;
              e.Cacute = 722;
              e.atilde = 556;
              e.Edotaccent = 667;
              e.scaron = 556;
              e.scedilla = 556;
              e.iacute = 278;
              e.lozenge = 494;
              e.Rcaron = 722;
              e.Gcommaaccent = 778;
              e.ucircumflex = 611;
              e.acircumflex = 556;
              e.Amacron = 722;
              e.rcaron = 389;
              e.ccedilla = 556;
              e.Zdotaccent = 611;
              e.Thorn = 667;
              e.Omacron = 778;
              e.Racute = 722;
              e.Sacute = 667;
              e.dcaron = 743;
              e.Umacron = 722;
              e.uring = 611;
              e.threesuperior = 333;
              e.Ograve = 778;
              e.Agrave = 722;
              e.Abreve = 722;
              e.multiply = 584;
              e.uacute = 611;
              e.Tcaron = 611;
              e.partialdiff = 494;
              e.ydieresis = 556;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 667;
              e.adieresis = 556;
              e.edieresis = 556;
              e.cacute = 556;
              e.nacute = 611;
              e.umacron = 611;
              e.Ncaron = 722;
              e.Iacute = 278;
              e.plusminus = 584;
              e.brokenbar = 280;
              e.registered = 737;
              e.Gbreve = 778;
              e.Idotaccent = 278;
              e.summation = 600;
              e.Egrave = 667;
              e.racute = 389;
              e.omacron = 611;
              e.Zacute = 611;
              e.Zcaron = 611;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 722;
              e.lcommaaccent = 278;
              e.tcaron = 389;
              e.eogonek = 556;
              e.Uogonek = 722;
              e.Aacute = 722;
              e.Adieresis = 722;
              e.egrave = 556;
              e.zacute = 500;
              e.iogonek = 278;
              e.Oacute = 778;
              e.oacute = 611;
              e.amacron = 556;
              e.sacute = 556;
              e.idieresis = 278;
              e.Ocircumflex = 778;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 611;
              e.twosuperior = 333;
              e.Odieresis = 778;
              e.mu = 611;
              e.igrave = 278;
              e.ohungarumlaut = 611;
              e.Eogonek = 667;
              e.dcroat = 611;
              e.threequarters = 834;
              e.Scedilla = 667;
              e.lcaron = 400;
              e.Kcommaaccent = 722;
              e.Lacute = 611;
              e.trademark = 1e3;
              e.edotaccent = 556;
              e.Igrave = 278;
              e.Imacron = 278;
              e.Lcaron = 611;
              e.onehalf = 834;
              e.lessequal = 549;
              e.ocircumflex = 611;
              e.ntilde = 611;
              e.Uhungarumlaut = 722;
              e.Eacute = 667;
              e.emacron = 556;
              e.gbreve = 611;
              e.onequarter = 834;
              e.Scaron = 667;
              e.Scommaaccent = 667;
              e.Ohungarumlaut = 778;
              e.degree = 400;
              e.ograve = 611;
              e.Ccaron = 722;
              e.ugrave = 611;
              e.radical = 549;
              e.Dcaron = 722;
              e.rcommaaccent = 389;
              e.Ntilde = 722;
              e.otilde = 611;
              e.Rcommaaccent = 722;
              e.Lcommaaccent = 611;
              e.Atilde = 722;
              e.Aogonek = 722;
              e.Aring = 722;
              e.Otilde = 778;
              e.zdotaccent = 500;
              e.Ecaron = 667;
              e.Iogonek = 278;
              e.kcommaaccent = 556;
              e.minus = 584;
              e.Icircumflex = 278;
              e.ncaron = 611;
              e.tcommaaccent = 333;
              e.logicalnot = 584;
              e.odieresis = 611;
              e.udieresis = 611;
              e.notequal = 549;
              e.gcommaaccent = 611;
              e.eth = 611;
              e.zcaron = 500;
              e.ncommaaccent = 611;
              e.onesuperior = 333;
              e.imacron = 278;
              e.Euro = 556;
            });
            e["Helvetica-BoldOblique"] = (0, r.getLookupTableFactory)(function (
              e
            ) {
              e.space = 278;
              e.exclam = 333;
              e.quotedbl = 474;
              e.numbersign = 556;
              e.dollar = 556;
              e.percent = 889;
              e.ampersand = 722;
              e.quoteright = 278;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 389;
              e.plus = 584;
              e.comma = 278;
              e.hyphen = 333;
              e.period = 278;
              e.slash = 278;
              e.zero = 556;
              e.one = 556;
              e.two = 556;
              e.three = 556;
              e.four = 556;
              e.five = 556;
              e.six = 556;
              e.seven = 556;
              e.eight = 556;
              e.nine = 556;
              e.colon = 333;
              e.semicolon = 333;
              e.less = 584;
              e.equal = 584;
              e.greater = 584;
              e.question = 611;
              e.at = 975;
              e.A = 722;
              e.B = 722;
              e.C = 722;
              e.D = 722;
              e.E = 667;
              e.F = 611;
              e.G = 778;
              e.H = 722;
              e.I = 278;
              e.J = 556;
              e.K = 722;
              e.L = 611;
              e.M = 833;
              e.N = 722;
              e.O = 778;
              e.P = 667;
              e.Q = 778;
              e.R = 722;
              e.S = 667;
              e.T = 611;
              e.U = 722;
              e.V = 667;
              e.W = 944;
              e.X = 667;
              e.Y = 667;
              e.Z = 611;
              e.bracketleft = 333;
              e.backslash = 278;
              e.bracketright = 333;
              e.asciicircum = 584;
              e.underscore = 556;
              e.quoteleft = 278;
              e.a = 556;
              e.b = 611;
              e.c = 556;
              e.d = 611;
              e.e = 556;
              e.f = 333;
              e.g = 611;
              e.h = 611;
              e.i = 278;
              e.j = 278;
              e.k = 556;
              e.l = 278;
              e.m = 889;
              e.n = 611;
              e.o = 611;
              e.p = 611;
              e.q = 611;
              e.r = 389;
              e.s = 556;
              e.t = 333;
              e.u = 611;
              e.v = 556;
              e.w = 778;
              e.x = 556;
              e.y = 556;
              e.z = 500;
              e.braceleft = 389;
              e.bar = 280;
              e.braceright = 389;
              e.asciitilde = 584;
              e.exclamdown = 333;
              e.cent = 556;
              e.sterling = 556;
              e.fraction = 167;
              e.yen = 556;
              e.florin = 556;
              e.section = 556;
              e.currency = 556;
              e.quotesingle = 238;
              e.quotedblleft = 500;
              e.guillemotleft = 556;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 611;
              e.fl = 611;
              e.endash = 556;
              e.dagger = 556;
              e.daggerdbl = 556;
              e.periodcentered = 278;
              e.paragraph = 556;
              e.bullet = 350;
              e.quotesinglbase = 278;
              e.quotedblbase = 500;
              e.quotedblright = 500;
              e.guillemotright = 556;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 611;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 1e3;
              e.ordfeminine = 370;
              e.Lslash = 611;
              e.Oslash = 778;
              e.OE = 1e3;
              e.ordmasculine = 365;
              e.ae = 889;
              e.dotlessi = 278;
              e.lslash = 278;
              e.oslash = 611;
              e.oe = 944;
              e.germandbls = 611;
              e.Idieresis = 278;
              e.eacute = 556;
              e.abreve = 556;
              e.uhungarumlaut = 611;
              e.ecaron = 556;
              e.Ydieresis = 667;
              e.divide = 584;
              e.Yacute = 667;
              e.Acircumflex = 722;
              e.aacute = 556;
              e.Ucircumflex = 722;
              e.yacute = 556;
              e.scommaaccent = 556;
              e.ecircumflex = 556;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 556;
              e.Uacute = 722;
              e.uogonek = 611;
              e.Edieresis = 667;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 737;
              e.Emacron = 667;
              e.ccaron = 556;
              e.aring = 556;
              e.Ncommaaccent = 722;
              e.lacute = 278;
              e.agrave = 556;
              e.Tcommaaccent = 611;
              e.Cacute = 722;
              e.atilde = 556;
              e.Edotaccent = 667;
              e.scaron = 556;
              e.scedilla = 556;
              e.iacute = 278;
              e.lozenge = 494;
              e.Rcaron = 722;
              e.Gcommaaccent = 778;
              e.ucircumflex = 611;
              e.acircumflex = 556;
              e.Amacron = 722;
              e.rcaron = 389;
              e.ccedilla = 556;
              e.Zdotaccent = 611;
              e.Thorn = 667;
              e.Omacron = 778;
              e.Racute = 722;
              e.Sacute = 667;
              e.dcaron = 743;
              e.Umacron = 722;
              e.uring = 611;
              e.threesuperior = 333;
              e.Ograve = 778;
              e.Agrave = 722;
              e.Abreve = 722;
              e.multiply = 584;
              e.uacute = 611;
              e.Tcaron = 611;
              e.partialdiff = 494;
              e.ydieresis = 556;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 667;
              e.adieresis = 556;
              e.edieresis = 556;
              e.cacute = 556;
              e.nacute = 611;
              e.umacron = 611;
              e.Ncaron = 722;
              e.Iacute = 278;
              e.plusminus = 584;
              e.brokenbar = 280;
              e.registered = 737;
              e.Gbreve = 778;
              e.Idotaccent = 278;
              e.summation = 600;
              e.Egrave = 667;
              e.racute = 389;
              e.omacron = 611;
              e.Zacute = 611;
              e.Zcaron = 611;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 722;
              e.lcommaaccent = 278;
              e.tcaron = 389;
              e.eogonek = 556;
              e.Uogonek = 722;
              e.Aacute = 722;
              e.Adieresis = 722;
              e.egrave = 556;
              e.zacute = 500;
              e.iogonek = 278;
              e.Oacute = 778;
              e.oacute = 611;
              e.amacron = 556;
              e.sacute = 556;
              e.idieresis = 278;
              e.Ocircumflex = 778;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 611;
              e.twosuperior = 333;
              e.Odieresis = 778;
              e.mu = 611;
              e.igrave = 278;
              e.ohungarumlaut = 611;
              e.Eogonek = 667;
              e.dcroat = 611;
              e.threequarters = 834;
              e.Scedilla = 667;
              e.lcaron = 400;
              e.Kcommaaccent = 722;
              e.Lacute = 611;
              e.trademark = 1e3;
              e.edotaccent = 556;
              e.Igrave = 278;
              e.Imacron = 278;
              e.Lcaron = 611;
              e.onehalf = 834;
              e.lessequal = 549;
              e.ocircumflex = 611;
              e.ntilde = 611;
              e.Uhungarumlaut = 722;
              e.Eacute = 667;
              e.emacron = 556;
              e.gbreve = 611;
              e.onequarter = 834;
              e.Scaron = 667;
              e.Scommaaccent = 667;
              e.Ohungarumlaut = 778;
              e.degree = 400;
              e.ograve = 611;
              e.Ccaron = 722;
              e.ugrave = 611;
              e.radical = 549;
              e.Dcaron = 722;
              e.rcommaaccent = 389;
              e.Ntilde = 722;
              e.otilde = 611;
              e.Rcommaaccent = 722;
              e.Lcommaaccent = 611;
              e.Atilde = 722;
              e.Aogonek = 722;
              e.Aring = 722;
              e.Otilde = 778;
              e.zdotaccent = 500;
              e.Ecaron = 667;
              e.Iogonek = 278;
              e.kcommaaccent = 556;
              e.minus = 584;
              e.Icircumflex = 278;
              e.ncaron = 611;
              e.tcommaaccent = 333;
              e.logicalnot = 584;
              e.odieresis = 611;
              e.udieresis = 611;
              e.notequal = 549;
              e.gcommaaccent = 611;
              e.eth = 611;
              e.zcaron = 500;
              e.ncommaaccent = 611;
              e.onesuperior = 333;
              e.imacron = 278;
              e.Euro = 556;
            });
            e["Helvetica-Oblique"] = (0, r.getLookupTableFactory)(function (e) {
              e.space = 278;
              e.exclam = 278;
              e.quotedbl = 355;
              e.numbersign = 556;
              e.dollar = 556;
              e.percent = 889;
              e.ampersand = 667;
              e.quoteright = 222;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 389;
              e.plus = 584;
              e.comma = 278;
              e.hyphen = 333;
              e.period = 278;
              e.slash = 278;
              e.zero = 556;
              e.one = 556;
              e.two = 556;
              e.three = 556;
              e.four = 556;
              e.five = 556;
              e.six = 556;
              e.seven = 556;
              e.eight = 556;
              e.nine = 556;
              e.colon = 278;
              e.semicolon = 278;
              e.less = 584;
              e.equal = 584;
              e.greater = 584;
              e.question = 556;
              e.at = 1015;
              e.A = 667;
              e.B = 667;
              e.C = 722;
              e.D = 722;
              e.E = 667;
              e.F = 611;
              e.G = 778;
              e.H = 722;
              e.I = 278;
              e.J = 500;
              e.K = 667;
              e.L = 556;
              e.M = 833;
              e.N = 722;
              e.O = 778;
              e.P = 667;
              e.Q = 778;
              e.R = 722;
              e.S = 667;
              e.T = 611;
              e.U = 722;
              e.V = 667;
              e.W = 944;
              e.X = 667;
              e.Y = 667;
              e.Z = 611;
              e.bracketleft = 278;
              e.backslash = 278;
              e.bracketright = 278;
              e.asciicircum = 469;
              e.underscore = 556;
              e.quoteleft = 222;
              e.a = 556;
              e.b = 556;
              e.c = 500;
              e.d = 556;
              e.e = 556;
              e.f = 278;
              e.g = 556;
              e.h = 556;
              e.i = 222;
              e.j = 222;
              e.k = 500;
              e.l = 222;
              e.m = 833;
              e.n = 556;
              e.o = 556;
              e.p = 556;
              e.q = 556;
              e.r = 333;
              e.s = 500;
              e.t = 278;
              e.u = 556;
              e.v = 500;
              e.w = 722;
              e.x = 500;
              e.y = 500;
              e.z = 500;
              e.braceleft = 334;
              e.bar = 260;
              e.braceright = 334;
              e.asciitilde = 584;
              e.exclamdown = 333;
              e.cent = 556;
              e.sterling = 556;
              e.fraction = 167;
              e.yen = 556;
              e.florin = 556;
              e.section = 556;
              e.currency = 556;
              e.quotesingle = 191;
              e.quotedblleft = 333;
              e.guillemotleft = 556;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 500;
              e.fl = 500;
              e.endash = 556;
              e.dagger = 556;
              e.daggerdbl = 556;
              e.periodcentered = 278;
              e.paragraph = 537;
              e.bullet = 350;
              e.quotesinglbase = 222;
              e.quotedblbase = 333;
              e.quotedblright = 333;
              e.guillemotright = 556;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 611;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 1e3;
              e.ordfeminine = 370;
              e.Lslash = 556;
              e.Oslash = 778;
              e.OE = 1e3;
              e.ordmasculine = 365;
              e.ae = 889;
              e.dotlessi = 278;
              e.lslash = 222;
              e.oslash = 611;
              e.oe = 944;
              e.germandbls = 611;
              e.Idieresis = 278;
              e.eacute = 556;
              e.abreve = 556;
              e.uhungarumlaut = 556;
              e.ecaron = 556;
              e.Ydieresis = 667;
              e.divide = 584;
              e.Yacute = 667;
              e.Acircumflex = 667;
              e.aacute = 556;
              e.Ucircumflex = 722;
              e.yacute = 500;
              e.scommaaccent = 500;
              e.ecircumflex = 556;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 556;
              e.Uacute = 722;
              e.uogonek = 556;
              e.Edieresis = 667;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 737;
              e.Emacron = 667;
              e.ccaron = 500;
              e.aring = 556;
              e.Ncommaaccent = 722;
              e.lacute = 222;
              e.agrave = 556;
              e.Tcommaaccent = 611;
              e.Cacute = 722;
              e.atilde = 556;
              e.Edotaccent = 667;
              e.scaron = 500;
              e.scedilla = 500;
              e.iacute = 278;
              e.lozenge = 471;
              e.Rcaron = 722;
              e.Gcommaaccent = 778;
              e.ucircumflex = 556;
              e.acircumflex = 556;
              e.Amacron = 667;
              e.rcaron = 333;
              e.ccedilla = 500;
              e.Zdotaccent = 611;
              e.Thorn = 667;
              e.Omacron = 778;
              e.Racute = 722;
              e.Sacute = 667;
              e.dcaron = 643;
              e.Umacron = 722;
              e.uring = 556;
              e.threesuperior = 333;
              e.Ograve = 778;
              e.Agrave = 667;
              e.Abreve = 667;
              e.multiply = 584;
              e.uacute = 556;
              e.Tcaron = 611;
              e.partialdiff = 476;
              e.ydieresis = 500;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 667;
              e.adieresis = 556;
              e.edieresis = 556;
              e.cacute = 500;
              e.nacute = 556;
              e.umacron = 556;
              e.Ncaron = 722;
              e.Iacute = 278;
              e.plusminus = 584;
              e.brokenbar = 260;
              e.registered = 737;
              e.Gbreve = 778;
              e.Idotaccent = 278;
              e.summation = 600;
              e.Egrave = 667;
              e.racute = 333;
              e.omacron = 556;
              e.Zacute = 611;
              e.Zcaron = 611;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 722;
              e.lcommaaccent = 222;
              e.tcaron = 317;
              e.eogonek = 556;
              e.Uogonek = 722;
              e.Aacute = 667;
              e.Adieresis = 667;
              e.egrave = 556;
              e.zacute = 500;
              e.iogonek = 222;
              e.Oacute = 778;
              e.oacute = 556;
              e.amacron = 556;
              e.sacute = 500;
              e.idieresis = 278;
              e.Ocircumflex = 778;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 556;
              e.twosuperior = 333;
              e.Odieresis = 778;
              e.mu = 556;
              e.igrave = 278;
              e.ohungarumlaut = 556;
              e.Eogonek = 667;
              e.dcroat = 556;
              e.threequarters = 834;
              e.Scedilla = 667;
              e.lcaron = 299;
              e.Kcommaaccent = 667;
              e.Lacute = 556;
              e.trademark = 1e3;
              e.edotaccent = 556;
              e.Igrave = 278;
              e.Imacron = 278;
              e.Lcaron = 556;
              e.onehalf = 834;
              e.lessequal = 549;
              e.ocircumflex = 556;
              e.ntilde = 556;
              e.Uhungarumlaut = 722;
              e.Eacute = 667;
              e.emacron = 556;
              e.gbreve = 556;
              e.onequarter = 834;
              e.Scaron = 667;
              e.Scommaaccent = 667;
              e.Ohungarumlaut = 778;
              e.degree = 400;
              e.ograve = 556;
              e.Ccaron = 722;
              e.ugrave = 556;
              e.radical = 453;
              e.Dcaron = 722;
              e.rcommaaccent = 333;
              e.Ntilde = 722;
              e.otilde = 556;
              e.Rcommaaccent = 722;
              e.Lcommaaccent = 556;
              e.Atilde = 667;
              e.Aogonek = 667;
              e.Aring = 667;
              e.Otilde = 778;
              e.zdotaccent = 500;
              e.Ecaron = 667;
              e.Iogonek = 278;
              e.kcommaaccent = 500;
              e.minus = 584;
              e.Icircumflex = 278;
              e.ncaron = 556;
              e.tcommaaccent = 278;
              e.logicalnot = 584;
              e.odieresis = 556;
              e.udieresis = 556;
              e.notequal = 549;
              e.gcommaaccent = 556;
              e.eth = 556;
              e.zcaron = 500;
              e.ncommaaccent = 556;
              e.onesuperior = 333;
              e.imacron = 278;
              e.Euro = 556;
            });
            e.Symbol = (0, r.getLookupTableFactory)(function (e) {
              e.space = 250;
              e.exclam = 333;
              e.universal = 713;
              e.numbersign = 500;
              e.existential = 549;
              e.percent = 833;
              e.ampersand = 778;
              e.suchthat = 439;
              e.parenleft = 333;
              e.parenright = 333;
              e.asteriskmath = 500;
              e.plus = 549;
              e.comma = 250;
              e.minus = 549;
              e.period = 250;
              e.slash = 278;
              e.zero = 500;
              e.one = 500;
              e.two = 500;
              e.three = 500;
              e.four = 500;
              e.five = 500;
              e.six = 500;
              e.seven = 500;
              e.eight = 500;
              e.nine = 500;
              e.colon = 278;
              e.semicolon = 278;
              e.less = 549;
              e.equal = 549;
              e.greater = 549;
              e.question = 444;
              e.congruent = 549;
              e.Alpha = 722;
              e.Beta = 667;
              e.Chi = 722;
              e.Delta = 612;
              e.Epsilon = 611;
              e.Phi = 763;
              e.Gamma = 603;
              e.Eta = 722;
              e.Iota = 333;
              e.theta1 = 631;
              e.Kappa = 722;
              e.Lambda = 686;
              e.Mu = 889;
              e.Nu = 722;
              e.Omicron = 722;
              e.Pi = 768;
              e.Theta = 741;
              e.Rho = 556;
              e.Sigma = 592;
              e.Tau = 611;
              e.Upsilon = 690;
              e.sigma1 = 439;
              e.Omega = 768;
              e.Xi = 645;
              e.Psi = 795;
              e.Zeta = 611;
              e.bracketleft = 333;
              e.therefore = 863;
              e.bracketright = 333;
              e.perpendicular = 658;
              e.underscore = 500;
              e.radicalex = 500;
              e.alpha = 631;
              e.beta = 549;
              e.chi = 549;
              e.delta = 494;
              e.epsilon = 439;
              e.phi = 521;
              e.gamma = 411;
              e.eta = 603;
              e.iota = 329;
              e.phi1 = 603;
              e.kappa = 549;
              e.lambda = 549;
              e.mu = 576;
              e.nu = 521;
              e.omicron = 549;
              e.pi = 549;
              e.theta = 521;
              e.rho = 549;
              e.sigma = 603;
              e.tau = 439;
              e.upsilon = 576;
              e.omega1 = 713;
              e.omega = 686;
              e.xi = 493;
              e.psi = 686;
              e.zeta = 494;
              e.braceleft = 480;
              e.bar = 200;
              e.braceright = 480;
              e.similar = 549;
              e.Euro = 750;
              e.Upsilon1 = 620;
              e.minute = 247;
              e.lessequal = 549;
              e.fraction = 167;
              e.infinity = 713;
              e.florin = 500;
              e.club = 753;
              e.diamond = 753;
              e.heart = 753;
              e.spade = 753;
              e.arrowboth = 1042;
              e.arrowleft = 987;
              e.arrowup = 603;
              e.arrowright = 987;
              e.arrowdown = 603;
              e.degree = 400;
              e.plusminus = 549;
              e.second = 411;
              e.greaterequal = 549;
              e.multiply = 549;
              e.proportional = 713;
              e.partialdiff = 494;
              e.bullet = 460;
              e.divide = 549;
              e.notequal = 549;
              e.equivalence = 549;
              e.approxequal = 549;
              e.ellipsis = 1e3;
              e.arrowvertex = 603;
              e.arrowhorizex = 1e3;
              e.carriagereturn = 658;
              e.aleph = 823;
              e.Ifraktur = 686;
              e.Rfraktur = 795;
              e.weierstrass = 987;
              e.circlemultiply = 768;
              e.circleplus = 768;
              e.emptyset = 823;
              e.intersection = 768;
              e.union = 768;
              e.propersuperset = 713;
              e.reflexsuperset = 713;
              e.notsubset = 713;
              e.propersubset = 713;
              e.reflexsubset = 713;
              e.element = 713;
              e.notelement = 713;
              e.angle = 768;
              e.gradient = 713;
              e.registerserif = 790;
              e.copyrightserif = 790;
              e.trademarkserif = 890;
              e.product = 823;
              e.radical = 549;
              e.dotmath = 250;
              e.logicalnot = 713;
              e.logicaland = 603;
              e.logicalor = 603;
              e.arrowdblboth = 1042;
              e.arrowdblleft = 987;
              e.arrowdblup = 603;
              e.arrowdblright = 987;
              e.arrowdbldown = 603;
              e.lozenge = 494;
              e.angleleft = 329;
              e.registersans = 790;
              e.copyrightsans = 790;
              e.trademarksans = 786;
              e.summation = 713;
              e.parenlefttp = 384;
              e.parenleftex = 384;
              e.parenleftbt = 384;
              e.bracketlefttp = 384;
              e.bracketleftex = 384;
              e.bracketleftbt = 384;
              e.bracelefttp = 494;
              e.braceleftmid = 494;
              e.braceleftbt = 494;
              e.braceex = 494;
              e.angleright = 329;
              e.integral = 274;
              e.integraltp = 686;
              e.integralex = 686;
              e.integralbt = 686;
              e.parenrighttp = 384;
              e.parenrightex = 384;
              e.parenrightbt = 384;
              e.bracketrighttp = 384;
              e.bracketrightex = 384;
              e.bracketrightbt = 384;
              e.bracerighttp = 494;
              e.bracerightmid = 494;
              e.bracerightbt = 494;
              e.apple = 790;
            });
            e["Times-Roman"] = (0, r.getLookupTableFactory)(function (e) {
              e.space = 250;
              e.exclam = 333;
              e.quotedbl = 408;
              e.numbersign = 500;
              e.dollar = 500;
              e.percent = 833;
              e.ampersand = 778;
              e.quoteright = 333;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 500;
              e.plus = 564;
              e.comma = 250;
              e.hyphen = 333;
              e.period = 250;
              e.slash = 278;
              e.zero = 500;
              e.one = 500;
              e.two = 500;
              e.three = 500;
              e.four = 500;
              e.five = 500;
              e.six = 500;
              e.seven = 500;
              e.eight = 500;
              e.nine = 500;
              e.colon = 278;
              e.semicolon = 278;
              e.less = 564;
              e.equal = 564;
              e.greater = 564;
              e.question = 444;
              e.at = 921;
              e.A = 722;
              e.B = 667;
              e.C = 667;
              e.D = 722;
              e.E = 611;
              e.F = 556;
              e.G = 722;
              e.H = 722;
              e.I = 333;
              e.J = 389;
              e.K = 722;
              e.L = 611;
              e.M = 889;
              e.N = 722;
              e.O = 722;
              e.P = 556;
              e.Q = 722;
              e.R = 667;
              e.S = 556;
              e.T = 611;
              e.U = 722;
              e.V = 722;
              e.W = 944;
              e.X = 722;
              e.Y = 722;
              e.Z = 611;
              e.bracketleft = 333;
              e.backslash = 278;
              e.bracketright = 333;
              e.asciicircum = 469;
              e.underscore = 500;
              e.quoteleft = 333;
              e.a = 444;
              e.b = 500;
              e.c = 444;
              e.d = 500;
              e.e = 444;
              e.f = 333;
              e.g = 500;
              e.h = 500;
              e.i = 278;
              e.j = 278;
              e.k = 500;
              e.l = 278;
              e.m = 778;
              e.n = 500;
              e.o = 500;
              e.p = 500;
              e.q = 500;
              e.r = 333;
              e.s = 389;
              e.t = 278;
              e.u = 500;
              e.v = 500;
              e.w = 722;
              e.x = 500;
              e.y = 500;
              e.z = 444;
              e.braceleft = 480;
              e.bar = 200;
              e.braceright = 480;
              e.asciitilde = 541;
              e.exclamdown = 333;
              e.cent = 500;
              e.sterling = 500;
              e.fraction = 167;
              e.yen = 500;
              e.florin = 500;
              e.section = 500;
              e.currency = 500;
              e.quotesingle = 180;
              e.quotedblleft = 444;
              e.guillemotleft = 500;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 556;
              e.fl = 556;
              e.endash = 500;
              e.dagger = 500;
              e.daggerdbl = 500;
              e.periodcentered = 250;
              e.paragraph = 453;
              e.bullet = 350;
              e.quotesinglbase = 333;
              e.quotedblbase = 444;
              e.quotedblright = 444;
              e.guillemotright = 500;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 444;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 889;
              e.ordfeminine = 276;
              e.Lslash = 611;
              e.Oslash = 722;
              e.OE = 889;
              e.ordmasculine = 310;
              e.ae = 667;
              e.dotlessi = 278;
              e.lslash = 278;
              e.oslash = 500;
              e.oe = 722;
              e.germandbls = 500;
              e.Idieresis = 333;
              e.eacute = 444;
              e.abreve = 444;
              e.uhungarumlaut = 500;
              e.ecaron = 444;
              e.Ydieresis = 722;
              e.divide = 564;
              e.Yacute = 722;
              e.Acircumflex = 722;
              e.aacute = 444;
              e.Ucircumflex = 722;
              e.yacute = 500;
              e.scommaaccent = 389;
              e.ecircumflex = 444;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 444;
              e.Uacute = 722;
              e.uogonek = 500;
              e.Edieresis = 611;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 760;
              e.Emacron = 611;
              e.ccaron = 444;
              e.aring = 444;
              e.Ncommaaccent = 722;
              e.lacute = 278;
              e.agrave = 444;
              e.Tcommaaccent = 611;
              e.Cacute = 667;
              e.atilde = 444;
              e.Edotaccent = 611;
              e.scaron = 389;
              e.scedilla = 389;
              e.iacute = 278;
              e.lozenge = 471;
              e.Rcaron = 667;
              e.Gcommaaccent = 722;
              e.ucircumflex = 500;
              e.acircumflex = 444;
              e.Amacron = 722;
              e.rcaron = 333;
              e.ccedilla = 444;
              e.Zdotaccent = 611;
              e.Thorn = 556;
              e.Omacron = 722;
              e.Racute = 667;
              e.Sacute = 556;
              e.dcaron = 588;
              e.Umacron = 722;
              e.uring = 500;
              e.threesuperior = 300;
              e.Ograve = 722;
              e.Agrave = 722;
              e.Abreve = 722;
              e.multiply = 564;
              e.uacute = 500;
              e.Tcaron = 611;
              e.partialdiff = 476;
              e.ydieresis = 500;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 611;
              e.adieresis = 444;
              e.edieresis = 444;
              e.cacute = 444;
              e.nacute = 500;
              e.umacron = 500;
              e.Ncaron = 722;
              e.Iacute = 333;
              e.plusminus = 564;
              e.brokenbar = 200;
              e.registered = 760;
              e.Gbreve = 722;
              e.Idotaccent = 333;
              e.summation = 600;
              e.Egrave = 611;
              e.racute = 333;
              e.omacron = 500;
              e.Zacute = 611;
              e.Zcaron = 611;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 667;
              e.lcommaaccent = 278;
              e.tcaron = 326;
              e.eogonek = 444;
              e.Uogonek = 722;
              e.Aacute = 722;
              e.Adieresis = 722;
              e.egrave = 444;
              e.zacute = 444;
              e.iogonek = 278;
              e.Oacute = 722;
              e.oacute = 500;
              e.amacron = 444;
              e.sacute = 389;
              e.idieresis = 278;
              e.Ocircumflex = 722;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 500;
              e.twosuperior = 300;
              e.Odieresis = 722;
              e.mu = 500;
              e.igrave = 278;
              e.ohungarumlaut = 500;
              e.Eogonek = 611;
              e.dcroat = 500;
              e.threequarters = 750;
              e.Scedilla = 556;
              e.lcaron = 344;
              e.Kcommaaccent = 722;
              e.Lacute = 611;
              e.trademark = 980;
              e.edotaccent = 444;
              e.Igrave = 333;
              e.Imacron = 333;
              e.Lcaron = 611;
              e.onehalf = 750;
              e.lessequal = 549;
              e.ocircumflex = 500;
              e.ntilde = 500;
              e.Uhungarumlaut = 722;
              e.Eacute = 611;
              e.emacron = 444;
              e.gbreve = 500;
              e.onequarter = 750;
              e.Scaron = 556;
              e.Scommaaccent = 556;
              e.Ohungarumlaut = 722;
              e.degree = 400;
              e.ograve = 500;
              e.Ccaron = 667;
              e.ugrave = 500;
              e.radical = 453;
              e.Dcaron = 722;
              e.rcommaaccent = 333;
              e.Ntilde = 722;
              e.otilde = 500;
              e.Rcommaaccent = 667;
              e.Lcommaaccent = 611;
              e.Atilde = 722;
              e.Aogonek = 722;
              e.Aring = 722;
              e.Otilde = 722;
              e.zdotaccent = 444;
              e.Ecaron = 611;
              e.Iogonek = 333;
              e.kcommaaccent = 500;
              e.minus = 564;
              e.Icircumflex = 333;
              e.ncaron = 500;
              e.tcommaaccent = 278;
              e.logicalnot = 564;
              e.odieresis = 500;
              e.udieresis = 500;
              e.notequal = 549;
              e.gcommaaccent = 500;
              e.eth = 500;
              e.zcaron = 444;
              e.ncommaaccent = 500;
              e.onesuperior = 300;
              e.imacron = 278;
              e.Euro = 500;
            });
            e["Times-Bold"] = (0, r.getLookupTableFactory)(function (e) {
              e.space = 250;
              e.exclam = 333;
              e.quotedbl = 555;
              e.numbersign = 500;
              e.dollar = 500;
              e.percent = 1e3;
              e.ampersand = 833;
              e.quoteright = 333;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 500;
              e.plus = 570;
              e.comma = 250;
              e.hyphen = 333;
              e.period = 250;
              e.slash = 278;
              e.zero = 500;
              e.one = 500;
              e.two = 500;
              e.three = 500;
              e.four = 500;
              e.five = 500;
              e.six = 500;
              e.seven = 500;
              e.eight = 500;
              e.nine = 500;
              e.colon = 333;
              e.semicolon = 333;
              e.less = 570;
              e.equal = 570;
              e.greater = 570;
              e.question = 500;
              e.at = 930;
              e.A = 722;
              e.B = 667;
              e.C = 722;
              e.D = 722;
              e.E = 667;
              e.F = 611;
              e.G = 778;
              e.H = 778;
              e.I = 389;
              e.J = 500;
              e.K = 778;
              e.L = 667;
              e.M = 944;
              e.N = 722;
              e.O = 778;
              e.P = 611;
              e.Q = 778;
              e.R = 722;
              e.S = 556;
              e.T = 667;
              e.U = 722;
              e.V = 722;
              e.W = 1e3;
              e.X = 722;
              e.Y = 722;
              e.Z = 667;
              e.bracketleft = 333;
              e.backslash = 278;
              e.bracketright = 333;
              e.asciicircum = 581;
              e.underscore = 500;
              e.quoteleft = 333;
              e.a = 500;
              e.b = 556;
              e.c = 444;
              e.d = 556;
              e.e = 444;
              e.f = 333;
              e.g = 500;
              e.h = 556;
              e.i = 278;
              e.j = 333;
              e.k = 556;
              e.l = 278;
              e.m = 833;
              e.n = 556;
              e.o = 500;
              e.p = 556;
              e.q = 556;
              e.r = 444;
              e.s = 389;
              e.t = 333;
              e.u = 556;
              e.v = 500;
              e.w = 722;
              e.x = 500;
              e.y = 500;
              e.z = 444;
              e.braceleft = 394;
              e.bar = 220;
              e.braceright = 394;
              e.asciitilde = 520;
              e.exclamdown = 333;
              e.cent = 500;
              e.sterling = 500;
              e.fraction = 167;
              e.yen = 500;
              e.florin = 500;
              e.section = 500;
              e.currency = 500;
              e.quotesingle = 278;
              e.quotedblleft = 500;
              e.guillemotleft = 500;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 556;
              e.fl = 556;
              e.endash = 500;
              e.dagger = 500;
              e.daggerdbl = 500;
              e.periodcentered = 250;
              e.paragraph = 540;
              e.bullet = 350;
              e.quotesinglbase = 333;
              e.quotedblbase = 500;
              e.quotedblright = 500;
              e.guillemotright = 500;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 500;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 1e3;
              e.ordfeminine = 300;
              e.Lslash = 667;
              e.Oslash = 778;
              e.OE = 1e3;
              e.ordmasculine = 330;
              e.ae = 722;
              e.dotlessi = 278;
              e.lslash = 278;
              e.oslash = 500;
              e.oe = 722;
              e.germandbls = 556;
              e.Idieresis = 389;
              e.eacute = 444;
              e.abreve = 500;
              e.uhungarumlaut = 556;
              e.ecaron = 444;
              e.Ydieresis = 722;
              e.divide = 570;
              e.Yacute = 722;
              e.Acircumflex = 722;
              e.aacute = 500;
              e.Ucircumflex = 722;
              e.yacute = 500;
              e.scommaaccent = 389;
              e.ecircumflex = 444;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 500;
              e.Uacute = 722;
              e.uogonek = 556;
              e.Edieresis = 667;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 747;
              e.Emacron = 667;
              e.ccaron = 444;
              e.aring = 500;
              e.Ncommaaccent = 722;
              e.lacute = 278;
              e.agrave = 500;
              e.Tcommaaccent = 667;
              e.Cacute = 722;
              e.atilde = 500;
              e.Edotaccent = 667;
              e.scaron = 389;
              e.scedilla = 389;
              e.iacute = 278;
              e.lozenge = 494;
              e.Rcaron = 722;
              e.Gcommaaccent = 778;
              e.ucircumflex = 556;
              e.acircumflex = 500;
              e.Amacron = 722;
              e.rcaron = 444;
              e.ccedilla = 444;
              e.Zdotaccent = 667;
              e.Thorn = 611;
              e.Omacron = 778;
              e.Racute = 722;
              e.Sacute = 556;
              e.dcaron = 672;
              e.Umacron = 722;
              e.uring = 556;
              e.threesuperior = 300;
              e.Ograve = 778;
              e.Agrave = 722;
              e.Abreve = 722;
              e.multiply = 570;
              e.uacute = 556;
              e.Tcaron = 667;
              e.partialdiff = 494;
              e.ydieresis = 500;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 667;
              e.adieresis = 500;
              e.edieresis = 444;
              e.cacute = 444;
              e.nacute = 556;
              e.umacron = 556;
              e.Ncaron = 722;
              e.Iacute = 389;
              e.plusminus = 570;
              e.brokenbar = 220;
              e.registered = 747;
              e.Gbreve = 778;
              e.Idotaccent = 389;
              e.summation = 600;
              e.Egrave = 667;
              e.racute = 444;
              e.omacron = 500;
              e.Zacute = 667;
              e.Zcaron = 667;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 722;
              e.lcommaaccent = 278;
              e.tcaron = 416;
              e.eogonek = 444;
              e.Uogonek = 722;
              e.Aacute = 722;
              e.Adieresis = 722;
              e.egrave = 444;
              e.zacute = 444;
              e.iogonek = 278;
              e.Oacute = 778;
              e.oacute = 500;
              e.amacron = 500;
              e.sacute = 389;
              e.idieresis = 278;
              e.Ocircumflex = 778;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 556;
              e.twosuperior = 300;
              e.Odieresis = 778;
              e.mu = 556;
              e.igrave = 278;
              e.ohungarumlaut = 500;
              e.Eogonek = 667;
              e.dcroat = 556;
              e.threequarters = 750;
              e.Scedilla = 556;
              e.lcaron = 394;
              e.Kcommaaccent = 778;
              e.Lacute = 667;
              e.trademark = 1e3;
              e.edotaccent = 444;
              e.Igrave = 389;
              e.Imacron = 389;
              e.Lcaron = 667;
              e.onehalf = 750;
              e.lessequal = 549;
              e.ocircumflex = 500;
              e.ntilde = 556;
              e.Uhungarumlaut = 722;
              e.Eacute = 667;
              e.emacron = 444;
              e.gbreve = 500;
              e.onequarter = 750;
              e.Scaron = 556;
              e.Scommaaccent = 556;
              e.Ohungarumlaut = 778;
              e.degree = 400;
              e.ograve = 500;
              e.Ccaron = 722;
              e.ugrave = 556;
              e.radical = 549;
              e.Dcaron = 722;
              e.rcommaaccent = 444;
              e.Ntilde = 722;
              e.otilde = 500;
              e.Rcommaaccent = 722;
              e.Lcommaaccent = 667;
              e.Atilde = 722;
              e.Aogonek = 722;
              e.Aring = 722;
              e.Otilde = 778;
              e.zdotaccent = 444;
              e.Ecaron = 667;
              e.Iogonek = 389;
              e.kcommaaccent = 556;
              e.minus = 570;
              e.Icircumflex = 389;
              e.ncaron = 556;
              e.tcommaaccent = 333;
              e.logicalnot = 570;
              e.odieresis = 500;
              e.udieresis = 556;
              e.notequal = 549;
              e.gcommaaccent = 500;
              e.eth = 500;
              e.zcaron = 444;
              e.ncommaaccent = 556;
              e.onesuperior = 300;
              e.imacron = 278;
              e.Euro = 500;
            });
            e["Times-BoldItalic"] = (0, r.getLookupTableFactory)(function (e) {
              e.space = 250;
              e.exclam = 389;
              e.quotedbl = 555;
              e.numbersign = 500;
              e.dollar = 500;
              e.percent = 833;
              e.ampersand = 778;
              e.quoteright = 333;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 500;
              e.plus = 570;
              e.comma = 250;
              e.hyphen = 333;
              e.period = 250;
              e.slash = 278;
              e.zero = 500;
              e.one = 500;
              e.two = 500;
              e.three = 500;
              e.four = 500;
              e.five = 500;
              e.six = 500;
              e.seven = 500;
              e.eight = 500;
              e.nine = 500;
              e.colon = 333;
              e.semicolon = 333;
              e.less = 570;
              e.equal = 570;
              e.greater = 570;
              e.question = 500;
              e.at = 832;
              e.A = 667;
              e.B = 667;
              e.C = 667;
              e.D = 722;
              e.E = 667;
              e.F = 667;
              e.G = 722;
              e.H = 778;
              e.I = 389;
              e.J = 500;
              e.K = 667;
              e.L = 611;
              e.M = 889;
              e.N = 722;
              e.O = 722;
              e.P = 611;
              e.Q = 722;
              e.R = 667;
              e.S = 556;
              e.T = 611;
              e.U = 722;
              e.V = 667;
              e.W = 889;
              e.X = 667;
              e.Y = 611;
              e.Z = 611;
              e.bracketleft = 333;
              e.backslash = 278;
              e.bracketright = 333;
              e.asciicircum = 570;
              e.underscore = 500;
              e.quoteleft = 333;
              e.a = 500;
              e.b = 500;
              e.c = 444;
              e.d = 500;
              e.e = 444;
              e.f = 333;
              e.g = 500;
              e.h = 556;
              e.i = 278;
              e.j = 278;
              e.k = 500;
              e.l = 278;
              e.m = 778;
              e.n = 556;
              e.o = 500;
              e.p = 500;
              e.q = 500;
              e.r = 389;
              e.s = 389;
              e.t = 278;
              e.u = 556;
              e.v = 444;
              e.w = 667;
              e.x = 500;
              e.y = 444;
              e.z = 389;
              e.braceleft = 348;
              e.bar = 220;
              e.braceright = 348;
              e.asciitilde = 570;
              e.exclamdown = 389;
              e.cent = 500;
              e.sterling = 500;
              e.fraction = 167;
              e.yen = 500;
              e.florin = 500;
              e.section = 500;
              e.currency = 500;
              e.quotesingle = 278;
              e.quotedblleft = 500;
              e.guillemotleft = 500;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 556;
              e.fl = 556;
              e.endash = 500;
              e.dagger = 500;
              e.daggerdbl = 500;
              e.periodcentered = 250;
              e.paragraph = 500;
              e.bullet = 350;
              e.quotesinglbase = 333;
              e.quotedblbase = 500;
              e.quotedblright = 500;
              e.guillemotright = 500;
              e.ellipsis = 1e3;
              e.perthousand = 1e3;
              e.questiondown = 500;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 1e3;
              e.AE = 944;
              e.ordfeminine = 266;
              e.Lslash = 611;
              e.Oslash = 722;
              e.OE = 944;
              e.ordmasculine = 300;
              e.ae = 722;
              e.dotlessi = 278;
              e.lslash = 278;
              e.oslash = 500;
              e.oe = 722;
              e.germandbls = 500;
              e.Idieresis = 389;
              e.eacute = 444;
              e.abreve = 500;
              e.uhungarumlaut = 556;
              e.ecaron = 444;
              e.Ydieresis = 611;
              e.divide = 570;
              e.Yacute = 611;
              e.Acircumflex = 667;
              e.aacute = 500;
              e.Ucircumflex = 722;
              e.yacute = 444;
              e.scommaaccent = 389;
              e.ecircumflex = 444;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 500;
              e.Uacute = 722;
              e.uogonek = 556;
              e.Edieresis = 667;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 747;
              e.Emacron = 667;
              e.ccaron = 444;
              e.aring = 500;
              e.Ncommaaccent = 722;
              e.lacute = 278;
              e.agrave = 500;
              e.Tcommaaccent = 611;
              e.Cacute = 667;
              e.atilde = 500;
              e.Edotaccent = 667;
              e.scaron = 389;
              e.scedilla = 389;
              e.iacute = 278;
              e.lozenge = 494;
              e.Rcaron = 667;
              e.Gcommaaccent = 722;
              e.ucircumflex = 556;
              e.acircumflex = 500;
              e.Amacron = 667;
              e.rcaron = 389;
              e.ccedilla = 444;
              e.Zdotaccent = 611;
              e.Thorn = 611;
              e.Omacron = 722;
              e.Racute = 667;
              e.Sacute = 556;
              e.dcaron = 608;
              e.Umacron = 722;
              e.uring = 556;
              e.threesuperior = 300;
              e.Ograve = 722;
              e.Agrave = 667;
              e.Abreve = 667;
              e.multiply = 570;
              e.uacute = 556;
              e.Tcaron = 611;
              e.partialdiff = 494;
              e.ydieresis = 444;
              e.Nacute = 722;
              e.icircumflex = 278;
              e.Ecircumflex = 667;
              e.adieresis = 500;
              e.edieresis = 444;
              e.cacute = 444;
              e.nacute = 556;
              e.umacron = 556;
              e.Ncaron = 722;
              e.Iacute = 389;
              e.plusminus = 570;
              e.brokenbar = 220;
              e.registered = 747;
              e.Gbreve = 722;
              e.Idotaccent = 389;
              e.summation = 600;
              e.Egrave = 667;
              e.racute = 389;
              e.omacron = 500;
              e.Zacute = 611;
              e.Zcaron = 611;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 667;
              e.lcommaaccent = 278;
              e.tcaron = 366;
              e.eogonek = 444;
              e.Uogonek = 722;
              e.Aacute = 667;
              e.Adieresis = 667;
              e.egrave = 444;
              e.zacute = 389;
              e.iogonek = 278;
              e.Oacute = 722;
              e.oacute = 500;
              e.amacron = 500;
              e.sacute = 389;
              e.idieresis = 278;
              e.Ocircumflex = 722;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 500;
              e.twosuperior = 300;
              e.Odieresis = 722;
              e.mu = 576;
              e.igrave = 278;
              e.ohungarumlaut = 500;
              e.Eogonek = 667;
              e.dcroat = 500;
              e.threequarters = 750;
              e.Scedilla = 556;
              e.lcaron = 382;
              e.Kcommaaccent = 667;
              e.Lacute = 611;
              e.trademark = 1e3;
              e.edotaccent = 444;
              e.Igrave = 389;
              e.Imacron = 389;
              e.Lcaron = 611;
              e.onehalf = 750;
              e.lessequal = 549;
              e.ocircumflex = 500;
              e.ntilde = 556;
              e.Uhungarumlaut = 722;
              e.Eacute = 667;
              e.emacron = 444;
              e.gbreve = 500;
              e.onequarter = 750;
              e.Scaron = 556;
              e.Scommaaccent = 556;
              e.Ohungarumlaut = 722;
              e.degree = 400;
              e.ograve = 500;
              e.Ccaron = 667;
              e.ugrave = 556;
              e.radical = 549;
              e.Dcaron = 722;
              e.rcommaaccent = 389;
              e.Ntilde = 722;
              e.otilde = 500;
              e.Rcommaaccent = 667;
              e.Lcommaaccent = 611;
              e.Atilde = 667;
              e.Aogonek = 667;
              e.Aring = 667;
              e.Otilde = 722;
              e.zdotaccent = 389;
              e.Ecaron = 667;
              e.Iogonek = 389;
              e.kcommaaccent = 500;
              e.minus = 606;
              e.Icircumflex = 389;
              e.ncaron = 556;
              e.tcommaaccent = 278;
              e.logicalnot = 606;
              e.odieresis = 500;
              e.udieresis = 556;
              e.notequal = 549;
              e.gcommaaccent = 500;
              e.eth = 500;
              e.zcaron = 389;
              e.ncommaaccent = 556;
              e.onesuperior = 300;
              e.imacron = 278;
              e.Euro = 500;
            });
            e["Times-Italic"] = (0, r.getLookupTableFactory)(function (e) {
              e.space = 250;
              e.exclam = 333;
              e.quotedbl = 420;
              e.numbersign = 500;
              e.dollar = 500;
              e.percent = 833;
              e.ampersand = 778;
              e.quoteright = 333;
              e.parenleft = 333;
              e.parenright = 333;
              e.asterisk = 500;
              e.plus = 675;
              e.comma = 250;
              e.hyphen = 333;
              e.period = 250;
              e.slash = 278;
              e.zero = 500;
              e.one = 500;
              e.two = 500;
              e.three = 500;
              e.four = 500;
              e.five = 500;
              e.six = 500;
              e.seven = 500;
              e.eight = 500;
              e.nine = 500;
              e.colon = 333;
              e.semicolon = 333;
              e.less = 675;
              e.equal = 675;
              e.greater = 675;
              e.question = 500;
              e.at = 920;
              e.A = 611;
              e.B = 611;
              e.C = 667;
              e.D = 722;
              e.E = 611;
              e.F = 611;
              e.G = 722;
              e.H = 722;
              e.I = 333;
              e.J = 444;
              e.K = 667;
              e.L = 556;
              e.M = 833;
              e.N = 667;
              e.O = 722;
              e.P = 611;
              e.Q = 722;
              e.R = 611;
              e.S = 500;
              e.T = 556;
              e.U = 722;
              e.V = 611;
              e.W = 833;
              e.X = 611;
              e.Y = 556;
              e.Z = 556;
              e.bracketleft = 389;
              e.backslash = 278;
              e.bracketright = 389;
              e.asciicircum = 422;
              e.underscore = 500;
              e.quoteleft = 333;
              e.a = 500;
              e.b = 500;
              e.c = 444;
              e.d = 500;
              e.e = 444;
              e.f = 278;
              e.g = 500;
              e.h = 500;
              e.i = 278;
              e.j = 278;
              e.k = 444;
              e.l = 278;
              e.m = 722;
              e.n = 500;
              e.o = 500;
              e.p = 500;
              e.q = 500;
              e.r = 389;
              e.s = 389;
              e.t = 278;
              e.u = 500;
              e.v = 444;
              e.w = 667;
              e.x = 444;
              e.y = 444;
              e.z = 389;
              e.braceleft = 400;
              e.bar = 275;
              e.braceright = 400;
              e.asciitilde = 541;
              e.exclamdown = 389;
              e.cent = 500;
              e.sterling = 500;
              e.fraction = 167;
              e.yen = 500;
              e.florin = 500;
              e.section = 500;
              e.currency = 500;
              e.quotesingle = 214;
              e.quotedblleft = 556;
              e.guillemotleft = 500;
              e.guilsinglleft = 333;
              e.guilsinglright = 333;
              e.fi = 500;
              e.fl = 500;
              e.endash = 500;
              e.dagger = 500;
              e.daggerdbl = 500;
              e.periodcentered = 250;
              e.paragraph = 523;
              e.bullet = 350;
              e.quotesinglbase = 333;
              e.quotedblbase = 556;
              e.quotedblright = 556;
              e.guillemotright = 500;
              e.ellipsis = 889;
              e.perthousand = 1e3;
              e.questiondown = 500;
              e.grave = 333;
              e.acute = 333;
              e.circumflex = 333;
              e.tilde = 333;
              e.macron = 333;
              e.breve = 333;
              e.dotaccent = 333;
              e.dieresis = 333;
              e.ring = 333;
              e.cedilla = 333;
              e.hungarumlaut = 333;
              e.ogonek = 333;
              e.caron = 333;
              e.emdash = 889;
              e.AE = 889;
              e.ordfeminine = 276;
              e.Lslash = 556;
              e.Oslash = 722;
              e.OE = 944;
              e.ordmasculine = 310;
              e.ae = 667;
              e.dotlessi = 278;
              e.lslash = 278;
              e.oslash = 500;
              e.oe = 667;
              e.germandbls = 500;
              e.Idieresis = 333;
              e.eacute = 444;
              e.abreve = 500;
              e.uhungarumlaut = 500;
              e.ecaron = 444;
              e.Ydieresis = 556;
              e.divide = 675;
              e.Yacute = 556;
              e.Acircumflex = 611;
              e.aacute = 500;
              e.Ucircumflex = 722;
              e.yacute = 444;
              e.scommaaccent = 389;
              e.ecircumflex = 444;
              e.Uring = 722;
              e.Udieresis = 722;
              e.aogonek = 500;
              e.Uacute = 722;
              e.uogonek = 500;
              e.Edieresis = 611;
              e.Dcroat = 722;
              e.commaaccent = 250;
              e.copyright = 760;
              e.Emacron = 611;
              e.ccaron = 444;
              e.aring = 500;
              e.Ncommaaccent = 667;
              e.lacute = 278;
              e.agrave = 500;
              e.Tcommaaccent = 556;
              e.Cacute = 667;
              e.atilde = 500;
              e.Edotaccent = 611;
              e.scaron = 389;
              e.scedilla = 389;
              e.iacute = 278;
              e.lozenge = 471;
              e.Rcaron = 611;
              e.Gcommaaccent = 722;
              e.ucircumflex = 500;
              e.acircumflex = 500;
              e.Amacron = 611;
              e.rcaron = 389;
              e.ccedilla = 444;
              e.Zdotaccent = 556;
              e.Thorn = 611;
              e.Omacron = 722;
              e.Racute = 611;
              e.Sacute = 500;
              e.dcaron = 544;
              e.Umacron = 722;
              e.uring = 500;
              e.threesuperior = 300;
              e.Ograve = 722;
              e.Agrave = 611;
              e.Abreve = 611;
              e.multiply = 675;
              e.uacute = 500;
              e.Tcaron = 556;
              e.partialdiff = 476;
              e.ydieresis = 444;
              e.Nacute = 667;
              e.icircumflex = 278;
              e.Ecircumflex = 611;
              e.adieresis = 500;
              e.edieresis = 444;
              e.cacute = 444;
              e.nacute = 500;
              e.umacron = 500;
              e.Ncaron = 667;
              e.Iacute = 333;
              e.plusminus = 675;
              e.brokenbar = 275;
              e.registered = 760;
              e.Gbreve = 722;
              e.Idotaccent = 333;
              e.summation = 600;
              e.Egrave = 611;
              e.racute = 389;
              e.omacron = 500;
              e.Zacute = 556;
              e.Zcaron = 556;
              e.greaterequal = 549;
              e.Eth = 722;
              e.Ccedilla = 667;
              e.lcommaaccent = 278;
              e.tcaron = 300;
              e.eogonek = 444;
              e.Uogonek = 722;
              e.Aacute = 611;
              e.Adieresis = 611;
              e.egrave = 444;
              e.zacute = 389;
              e.iogonek = 278;
              e.Oacute = 722;
              e.oacute = 500;
              e.amacron = 500;
              e.sacute = 389;
              e.idieresis = 278;
              e.Ocircumflex = 722;
              e.Ugrave = 722;
              e.Delta = 612;
              e.thorn = 500;
              e.twosuperior = 300;
              e.Odieresis = 722;
              e.mu = 500;
              e.igrave = 278;
              e.ohungarumlaut = 500;
              e.Eogonek = 611;
              e.dcroat = 500;
              e.threequarters = 750;
              e.Scedilla = 500;
              e.lcaron = 300;
              e.Kcommaaccent = 667;
              e.Lacute = 556;
              e.trademark = 980;
              e.edotaccent = 444;
              e.Igrave = 333;
              e.Imacron = 333;
              e.Lcaron = 611;
              e.onehalf = 750;
              e.lessequal = 549;
              e.ocircumflex = 500;
              e.ntilde = 500;
              e.Uhungarumlaut = 722;
              e.Eacute = 611;
              e.emacron = 444;
              e.gbreve = 500;
              e.onequarter = 750;
              e.Scaron = 500;
              e.Scommaaccent = 500;
              e.Ohungarumlaut = 722;
              e.degree = 400;
              e.ograve = 500;
              e.Ccaron = 667;
              e.ugrave = 500;
              e.radical = 453;
              e.Dcaron = 722;
              e.rcommaaccent = 389;
              e.Ntilde = 667;
              e.otilde = 500;
              e.Rcommaaccent = 611;
              e.Lcommaaccent = 556;
              e.Atilde = 611;
              e.Aogonek = 611;
              e.Aring = 611;
              e.Otilde = 722;
              e.zdotaccent = 389;
              e.Ecaron = 611;
              e.Iogonek = 333;
              e.kcommaaccent = 444;
              e.minus = 675;
              e.Icircumflex = 333;
              e.ncaron = 500;
              e.tcommaaccent = 278;
              e.logicalnot = 675;
              e.odieresis = 500;
              e.udieresis = 500;
              e.notequal = 549;
              e.gcommaaccent = 500;
              e.eth = 500;
              e.zcaron = 389;
              e.ncommaaccent = 500;
              e.onesuperior = 300;
              e.imacron = 278;
              e.Euro = 500;
            });
            e.ZapfDingbats = (0, r.getLookupTableFactory)(function (e) {
              e.space = 278;
              e.a1 = 974;
              e.a2 = 961;
              e.a202 = 974;
              e.a3 = 980;
              e.a4 = 719;
              e.a5 = 789;
              e.a119 = 790;
              e.a118 = 791;
              e.a117 = 690;
              e.a11 = 960;
              e.a12 = 939;
              e.a13 = 549;
              e.a14 = 855;
              e.a15 = 911;
              e.a16 = 933;
              e.a105 = 911;
              e.a17 = 945;
              e.a18 = 974;
              e.a19 = 755;
              e.a20 = 846;
              e.a21 = 762;
              e.a22 = 761;
              e.a23 = 571;
              e.a24 = 677;
              e.a25 = 763;
              e.a26 = 760;
              e.a27 = 759;
              e.a28 = 754;
              e.a6 = 494;
              e.a7 = 552;
              e.a8 = 537;
              e.a9 = 577;
              e.a10 = 692;
              e.a29 = 786;
              e.a30 = 788;
              e.a31 = 788;
              e.a32 = 790;
              e.a33 = 793;
              e.a34 = 794;
              e.a35 = 816;
              e.a36 = 823;
              e.a37 = 789;
              e.a38 = 841;
              e.a39 = 823;
              e.a40 = 833;
              e.a41 = 816;
              e.a42 = 831;
              e.a43 = 923;
              e.a44 = 744;
              e.a45 = 723;
              e.a46 = 749;
              e.a47 = 790;
              e.a48 = 792;
              e.a49 = 695;
              e.a50 = 776;
              e.a51 = 768;
              e.a52 = 792;
              e.a53 = 759;
              e.a54 = 707;
              e.a55 = 708;
              e.a56 = 682;
              e.a57 = 701;
              e.a58 = 826;
              e.a59 = 815;
              e.a60 = 789;
              e.a61 = 789;
              e.a62 = 707;
              e.a63 = 687;
              e.a64 = 696;
              e.a65 = 689;
              e.a66 = 786;
              e.a67 = 787;
              e.a68 = 713;
              e.a69 = 791;
              e.a70 = 785;
              e.a71 = 791;
              e.a72 = 873;
              e.a73 = 761;
              e.a74 = 762;
              e.a203 = 762;
              e.a75 = 759;
              e.a204 = 759;
              e.a76 = 892;
              e.a77 = 892;
              e.a78 = 788;
              e.a79 = 784;
              e.a81 = 438;
              e.a82 = 138;
              e.a83 = 277;
              e.a84 = 415;
              e.a97 = 392;
              e.a98 = 392;
              e.a99 = 668;
              e.a100 = 668;
              e.a89 = 390;
              e.a90 = 390;
              e.a93 = 317;
              e.a94 = 317;
              e.a91 = 276;
              e.a92 = 276;
              e.a205 = 509;
              e.a85 = 509;
              e.a206 = 410;
              e.a86 = 410;
              e.a87 = 234;
              e.a88 = 234;
              e.a95 = 334;
              e.a96 = 334;
              e.a101 = 732;
              e.a102 = 544;
              e.a103 = 544;
              e.a104 = 910;
              e.a106 = 667;
              e.a107 = 760;
              e.a108 = 760;
              e.a112 = 776;
              e.a111 = 595;
              e.a110 = 694;
              e.a109 = 626;
              e.a120 = 788;
              e.a121 = 788;
              e.a122 = 788;
              e.a123 = 788;
              e.a124 = 788;
              e.a125 = 788;
              e.a126 = 788;
              e.a127 = 788;
              e.a128 = 788;
              e.a129 = 788;
              e.a130 = 788;
              e.a131 = 788;
              e.a132 = 788;
              e.a133 = 788;
              e.a134 = 788;
              e.a135 = 788;
              e.a136 = 788;
              e.a137 = 788;
              e.a138 = 788;
              e.a139 = 788;
              e.a140 = 788;
              e.a141 = 788;
              e.a142 = 788;
              e.a143 = 788;
              e.a144 = 788;
              e.a145 = 788;
              e.a146 = 788;
              e.a147 = 788;
              e.a148 = 788;
              e.a149 = 788;
              e.a150 = 788;
              e.a151 = 788;
              e.a152 = 788;
              e.a153 = 788;
              e.a154 = 788;
              e.a155 = 788;
              e.a156 = 788;
              e.a157 = 788;
              e.a158 = 788;
              e.a159 = 788;
              e.a160 = 894;
              e.a161 = 838;
              e.a163 = 1016;
              e.a164 = 458;
              e.a196 = 748;
              e.a165 = 924;
              e.a192 = 748;
              e.a166 = 918;
              e.a167 = 927;
              e.a168 = 928;
              e.a169 = 928;
              e.a170 = 834;
              e.a171 = 873;
              e.a172 = 828;
              e.a173 = 924;
              e.a162 = 924;
              e.a174 = 917;
              e.a175 = 930;
              e.a176 = 931;
              e.a177 = 463;
              e.a178 = 883;
              e.a179 = 836;
              e.a193 = 836;
              e.a180 = 867;
              e.a199 = 867;
              e.a181 = 696;
              e.a200 = 696;
              e.a182 = 874;
              e.a201 = 874;
              e.a183 = 760;
              e.a184 = 946;
              e.a197 = 771;
              e.a185 = 865;
              e.a194 = 771;
              e.a198 = 888;
              e.a186 = 967;
              e.a195 = 888;
              e.a187 = 831;
              e.a188 = 873;
              e.a189 = 927;
              e.a190 = 970;
              e.a191 = 918;
            });
          });
          t.getMetrics = n;
          const i = (0, r.getLookupTableFactory)(function (e) {
            e.Courier = {
              ascent: 629,
              descent: -157,
              capHeight: 562,
              xHeight: -426,
            };
            e["Courier-Bold"] = {
              ascent: 629,
              descent: -157,
              capHeight: 562,
              xHeight: 439,
            };
            e["Courier-Oblique"] = {
              ascent: 629,
              descent: -157,
              capHeight: 562,
              xHeight: 426,
            };
            e["Courier-BoldOblique"] = {
              ascent: 629,
              descent: -157,
              capHeight: 562,
              xHeight: 426,
            };
            e.Helvetica = {
              ascent: 718,
              descent: -207,
              capHeight: 718,
              xHeight: 523,
            };
            e["Helvetica-Bold"] = {
              ascent: 718,
              descent: -207,
              capHeight: 718,
              xHeight: 532,
            };
            e["Helvetica-Oblique"] = {
              ascent: 718,
              descent: -207,
              capHeight: 718,
              xHeight: 523,
            };
            e["Helvetica-BoldOblique"] = {
              ascent: 718,
              descent: -207,
              capHeight: 718,
              xHeight: 532,
            };
            e["Times-Roman"] = {
              ascent: 683,
              descent: -217,
              capHeight: 662,
              xHeight: 450,
            };
            e["Times-Bold"] = {
              ascent: 683,
              descent: -217,
              capHeight: 676,
              xHeight: 461,
            };
            e["Times-Italic"] = {
              ascent: 683,
              descent: -217,
              capHeight: 653,
              xHeight: 441,
            };
            e["Times-BoldItalic"] = {
              ascent: 683,
              descent: -217,
              capHeight: 669,
              xHeight: 462,
            };
            e.Symbol = {
              ascent: Math.NaN,
              descent: Math.NaN,
              capHeight: Math.NaN,
              xHeight: Math.NaN,
            };
            e.ZapfDingbats = {
              ascent: Math.NaN,
              descent: Math.NaN,
              capHeight: Math.NaN,
              xHeight: Math.NaN,
            };
          });
          t.getFontBasicMetrics = i;
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.GlyfTable = void 0;
          t.GlyfTable = class GlyfTable {
            constructor({
              glyfTable: e,
              isGlyphLocationsLong: t,
              locaTable: a,
              numGlyphs: r,
            }) {
              this.glyphs = [];
              const n = new DataView(a.buffer, a.byteOffset, a.byteLength),
                i = new DataView(e.buffer, e.byteOffset, e.byteLength),
                s = t ? 4 : 2;
              let o = t ? n.getUint32(0) : 2 * n.getUint16(0),
                c = 0;
              for (let e = 0; e < r; e++) {
                c += s;
                const e = t ? n.getUint32(c) : 2 * n.getUint16(c);
                if (e === o) {
                  this.glyphs.push(new Glyph({}));
                  continue;
                }
                const a = Glyph.parse(o, i);
                this.glyphs.push(a);
                o = e;
              }
            }
            getSize() {
              return this.glyphs.reduce(
                (e, t) => e + ((t.getSize() + 3) & -4),
                0
              );
            }
            write() {
              const e = this.getSize(),
                t = new DataView(new ArrayBuffer(e)),
                a = e > 131070,
                r = a ? 4 : 2,
                n = new DataView(new ArrayBuffer((this.glyphs.length + 1) * r));
              a ? n.setUint32(0, 0) : n.setUint16(0, 0);
              let i = 0,
                s = 0;
              for (const e of this.glyphs) {
                i += e.write(i, t);
                i = (i + 3) & -4;
                s += r;
                a ? n.setUint32(s, i) : n.setUint16(s, i >> 1);
              }
              return {
                isLocationLong: a,
                loca: new Uint8Array(n.buffer),
                glyf: new Uint8Array(t.buffer),
              };
            }
            scale(e) {
              for (let t = 0, a = this.glyphs.length; t < a; t++)
                this.glyphs[t].scale(e[t]);
            }
          };
          class Glyph {
            constructor({
              header: e = null,
              simple: t = null,
              composites: a = null,
            }) {
              this.header = e;
              this.simple = t;
              this.composites = a;
            }
            static parse(e, t) {
              const [a, r] = GlyphHeader.parse(e, t);
              e += a;
              if (r.numberOfContours < 0) {
                const a = [];
                for (;;) {
                  const [r, n] = CompositeGlyph.parse(e, t);
                  e += r;
                  a.push(n);
                  if (!(32 & n.flags)) break;
                }
                return new Glyph({ header: r, composites: a });
              }
              const n = SimpleGlyph.parse(e, t, r.numberOfContours);
              return new Glyph({ header: r, simple: n });
            }
            getSize() {
              if (!this.header) return 0;
              const e = this.simple
                ? this.simple.getSize()
                : this.composites.reduce((e, t) => e + t.getSize(), 0);
              return this.header.getSize() + e;
            }
            write(e, t) {
              if (!this.header) return 0;
              const a = e;
              e += this.header.write(e, t);
              if (this.simple) e += this.simple.write(e, t);
              else for (const a of this.composites) e += a.write(e, t);
              return e - a;
            }
            scale(e) {
              if (!this.header) return;
              const t = (this.header.xMin + this.header.xMax) / 2;
              this.header.scale(t, e);
              if (this.simple) this.simple.scale(t, e);
              else for (const a of this.composites) a.scale(t, e);
            }
          }
          class GlyphHeader {
            constructor({
              numberOfContours: e,
              xMin: t,
              yMin: a,
              xMax: r,
              yMax: n,
            }) {
              this.numberOfContours = e;
              this.xMin = t;
              this.yMin = a;
              this.xMax = r;
              this.yMax = n;
            }
            static parse(e, t) {
              return [
                10,
                new GlyphHeader({
                  numberOfContours: t.getInt16(e),
                  xMin: t.getInt16(e + 2),
                  yMin: t.getInt16(e + 4),
                  xMax: t.getInt16(e + 6),
                  yMax: t.getInt16(e + 8),
                }),
              ];
            }
            getSize() {
              return 10;
            }
            write(e, t) {
              t.setInt16(e, this.numberOfContours);
              t.setInt16(e + 2, this.xMin);
              t.setInt16(e + 4, this.yMin);
              t.setInt16(e + 6, this.xMax);
              t.setInt16(e + 8, this.yMax);
              return 10;
            }
            scale(e, t) {
              this.xMin = Math.round(e + (this.xMin - e) * t);
              this.xMax = Math.round(e + (this.xMax - e) * t);
            }
          }
          class Contour {
            constructor({ flags: e, xCoordinates: t, yCoordinates: a }) {
              this.xCoordinates = t;
              this.yCoordinates = a;
              this.flags = e;
            }
          }
          class SimpleGlyph {
            constructor({ contours: e, instructions: t }) {
              this.contours = e;
              this.instructions = t;
            }
            static parse(e, t, a) {
              const r = [];
              for (let n = 0; n < a; n++) {
                const a = t.getUint16(e);
                e += 2;
                r.push(a);
              }
              const n = r[a - 1] + 1,
                i = t.getUint16(e);
              e += 2;
              const s = new Uint8Array(t).slice(e, e + i);
              e += i;
              const o = [];
              for (let a = 0; a < n; e++, a++) {
                let r = t.getUint8(e);
                o.push(r);
                if (8 & r) {
                  const n = t.getUint8(++e);
                  r ^= 8;
                  for (let e = 0; e < n; e++) o.push(r);
                  a += n;
                }
              }
              const c = [];
              let l = [],
                h = [],
                u = [];
              const d = [];
              let f = 0,
                g = 0;
              for (let a = 0; a < n; a++) {
                const n = o[a];
                if (2 & n) {
                  const a = t.getUint8(e++);
                  g += 16 & n ? a : -a;
                  l.push(g);
                } else if (16 & n) l.push(g);
                else {
                  g += t.getInt16(e);
                  e += 2;
                  l.push(g);
                }
                if (r[f] === a) {
                  f++;
                  c.push(l);
                  l = [];
                }
              }
              g = 0;
              f = 0;
              for (let a = 0; a < n; a++) {
                const n = o[a];
                if (4 & n) {
                  const a = t.getUint8(e++);
                  g += 32 & n ? a : -a;
                  h.push(g);
                } else if (32 & n) h.push(g);
                else {
                  g += t.getInt16(e);
                  e += 2;
                  h.push(g);
                }
                u.push((1 & n) | (64 & n));
                if (r[f] === a) {
                  l = c[f];
                  f++;
                  d.push(
                    new Contour({ flags: u, xCoordinates: l, yCoordinates: h })
                  );
                  h = [];
                  u = [];
                }
              }
              return new SimpleGlyph({ contours: d, instructions: s });
            }
            getSize() {
              let e = 2 * this.contours.length + 2 + this.instructions.length,
                t = 0,
                a = 0;
              for (const r of this.contours) {
                e += r.flags.length;
                for (let n = 0, i = r.xCoordinates.length; n < i; n++) {
                  const i = r.xCoordinates[n],
                    s = r.yCoordinates[n];
                  let o = Math.abs(i - t);
                  o > 255 ? (e += 2) : o > 0 && (e += 1);
                  t = i;
                  o = Math.abs(s - a);
                  o > 255 ? (e += 2) : o > 0 && (e += 1);
                  a = s;
                }
              }
              return e;
            }
            write(e, t) {
              const a = e,
                r = [],
                n = [],
                i = [];
              let s = 0,
                o = 0;
              for (const a of this.contours) {
                for (let e = 0, t = a.xCoordinates.length; e < t; e++) {
                  let t = a.flags[e];
                  const c = a.xCoordinates[e];
                  let l = c - s;
                  if (0 === l) {
                    t |= 16;
                    r.push(0);
                  } else {
                    const e = Math.abs(l);
                    if (e <= 255) {
                      t |= l >= 0 ? 18 : 2;
                      r.push(e);
                    } else r.push(l);
                  }
                  s = c;
                  const h = a.yCoordinates[e];
                  l = h - o;
                  if (0 === l) {
                    t |= 32;
                    n.push(0);
                  } else {
                    const e = Math.abs(l);
                    if (e <= 255) {
                      t |= l >= 0 ? 36 : 4;
                      n.push(e);
                    } else n.push(l);
                  }
                  o = h;
                  i.push(t);
                }
                t.setUint16(e, r.length - 1);
                e += 2;
              }
              t.setUint16(e, this.instructions.length);
              e += 2;
              if (this.instructions.length) {
                new Uint8Array(t.buffer, 0, t.buffer.byteLength).set(
                  this.instructions,
                  e
                );
                e += this.instructions.length;
              }
              for (const a of i) t.setUint8(e++, a);
              for (let a = 0, n = r.length; a < n; a++) {
                const n = r[a],
                  s = i[a];
                if (2 & s) t.setUint8(e++, n);
                else if (!(16 & s)) {
                  t.setInt16(e, n);
                  e += 2;
                }
              }
              for (let a = 0, r = n.length; a < r; a++) {
                const r = n[a],
                  s = i[a];
                if (4 & s) t.setUint8(e++, r);
                else if (!(32 & s)) {
                  t.setInt16(e, r);
                  e += 2;
                }
              }
              return e - a;
            }
            scale(e, t) {
              for (const a of this.contours)
                if (0 !== a.xCoordinates.length)
                  for (let r = 0, n = a.xCoordinates.length; r < n; r++)
                    a.xCoordinates[r] = Math.round(
                      e + (a.xCoordinates[r] - e) * t
                    );
            }
          }
          class CompositeGlyph {
            constructor({
              flags: e,
              glyphIndex: t,
              argument1: a,
              argument2: r,
              transf: n,
              instructions: i,
            }) {
              this.flags = e;
              this.glyphIndex = t;
              this.argument1 = a;
              this.argument2 = r;
              this.transf = n;
              this.instructions = i;
            }
            static parse(e, t) {
              const a = e,
                r = [];
              let n = t.getUint16(e);
              const i = t.getUint16(e + 2);
              e += 4;
              let s, o;
              if (1 & n) {
                if (2 & n) {
                  s = t.getInt16(e);
                  o = t.getInt16(e + 2);
                } else {
                  s = t.getUint16(e);
                  o = t.getUint16(e + 2);
                }
                e += 4;
                n ^= 1;
              } else {
                if (2 & n) {
                  s = t.getInt8(e);
                  o = t.getInt8(e + 1);
                } else {
                  s = t.getUint8(e);
                  o = t.getUint8(e + 1);
                }
                e += 2;
              }
              if (8 & n) {
                r.push(t.getUint16(e));
                e += 2;
              } else if (64 & n) {
                r.push(t.getUint16(e), t.getUint16(e + 2));
                e += 4;
              } else if (128 & n) {
                r.push(
                  t.getUint16(e),
                  t.getUint16(e + 2),
                  t.getUint16(e + 4),
                  t.getUint16(e + 6)
                );
                e += 8;
              }
              let c = null;
              if (256 & n) {
                const a = t.getUint16(e);
                e += 2;
                c = new Uint8Array(t).slice(e, e + a);
                e += a;
              }
              return [
                e - a,
                new CompositeGlyph({
                  flags: n,
                  glyphIndex: i,
                  argument1: s,
                  argument2: o,
                  transf: r,
                  instructions: c,
                }),
              ];
            }
            getSize() {
              let e = 4 + 2 * this.transf.length;
              256 & this.flags && (e += 2 + this.instructions.length);
              e += 2;
              2 & this.flags
                ? (this.argument1 >= -128 &&
                    this.argument1 <= 127 &&
                    this.argument2 >= -128 &&
                    this.argument2 <= 127) ||
                  (e += 2)
                : (this.argument1 >= 0 &&
                    this.argument1 <= 255 &&
                    this.argument2 >= 0 &&
                    this.argument2 <= 255) ||
                  (e += 2);
              return e;
            }
            write(e, t) {
              const a = e;
              2 & this.flags
                ? (this.argument1 >= -128 &&
                    this.argument1 <= 127 &&
                    this.argument2 >= -128 &&
                    this.argument2 <= 127) ||
                  (this.flags |= 1)
                : (this.argument1 >= 0 &&
                    this.argument1 <= 255 &&
                    this.argument2 >= 0 &&
                    this.argument2 <= 255) ||
                  (this.flags |= 1);
              t.setUint16(e, this.flags);
              t.setUint16(e + 2, this.glyphIndex);
              e += 4;
              if (1 & this.flags) {
                if (2 & this.flags) {
                  t.setInt16(e, this.argument1);
                  t.setInt16(e + 2, this.argument2);
                } else {
                  t.setUint16(e, this.argument1);
                  t.setUint16(e + 2, this.argument2);
                }
                e += 4;
              } else {
                t.setUint8(e, this.argument1);
                t.setUint8(e + 1, this.argument2);
                e += 2;
              }
              if (256 & this.flags) {
                t.setUint16(e, this.instructions.length);
                e += 2;
                if (this.instructions.length) {
                  new Uint8Array(t.buffer, 0, t.buffer.byteLength).set(
                    this.instructions,
                    e
                  );
                  e += this.instructions.length;
                }
              }
              return e - a;
            }
            scale(e, t) {}
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.OpenTypeFileBuilder = void 0;
          var r = a(3),
            n = a(2);
          function writeInt16(e, t, a) {
            e[t] = (a >> 8) & 255;
            e[t + 1] = 255 & a;
          }
          function writeInt32(e, t, a) {
            e[t] = (a >> 24) & 255;
            e[t + 1] = (a >> 16) & 255;
            e[t + 2] = (a >> 8) & 255;
            e[t + 3] = 255 & a;
          }
          function writeData(e, t, a) {
            if (a instanceof Uint8Array) e.set(a, t);
            else if ("string" == typeof a)
              for (let r = 0, n = a.length; r < n; r++)
                e[t++] = 255 & a.charCodeAt(r);
            else for (const r of a) e[t++] = 255 & r;
          }
          class OpenTypeFileBuilder {
            constructor(e) {
              this.sfnt = e;
              this.tables = Object.create(null);
            }
            static getSearchParams(e, t) {
              let a = 1,
                r = 0;
              for (; (a ^ e) > a; ) {
                a <<= 1;
                r++;
              }
              const n = a * t;
              return { range: n, entry: r, rangeShift: t * e - n };
            }
            toArray() {
              let e = this.sfnt;
              const t = this.tables,
                a = Object.keys(t);
              a.sort();
              const i = a.length;
              let s,
                o,
                c,
                l,
                h,
                u = 12 + 16 * i;
              const d = [u];
              for (s = 0; s < i; s++) {
                l = t[a[s]];
                u += ((l.length + 3) & -4) >>> 0;
                d.push(u);
              }
              const f = new Uint8Array(u);
              for (s = 0; s < i; s++) {
                l = t[a[s]];
                writeData(f, d[s], l);
              }
              "true" === e && (e = (0, n.string32)(65536));
              f[0] = 255 & e.charCodeAt(0);
              f[1] = 255 & e.charCodeAt(1);
              f[2] = 255 & e.charCodeAt(2);
              f[3] = 255 & e.charCodeAt(3);
              writeInt16(f, 4, i);
              const g = OpenTypeFileBuilder.getSearchParams(i, 16);
              writeInt16(f, 6, g.range);
              writeInt16(f, 8, g.entry);
              writeInt16(f, 10, g.rangeShift);
              u = 12;
              for (s = 0; s < i; s++) {
                h = a[s];
                f[u] = 255 & h.charCodeAt(0);
                f[u + 1] = 255 & h.charCodeAt(1);
                f[u + 2] = 255 & h.charCodeAt(2);
                f[u + 3] = 255 & h.charCodeAt(3);
                let e = 0;
                for (o = d[s], c = d[s + 1]; o < c; o += 4) {
                  e = (e + (0, r.readUint32)(f, o)) >>> 0;
                }
                writeInt32(f, u + 4, e);
                writeInt32(f, u + 8, d[s]);
                writeInt32(f, u + 12, t[h].length);
                u += 16;
              }
              return f;
            }
            addTable(e, t) {
              if (e in this.tables)
                throw new Error("Table " + e + " already exists");
              this.tables[e] = t;
            }
          }
          t.OpenTypeFileBuilder = OpenTypeFileBuilder;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Type1Font = void 0;
          var r = a(33),
            n = a(2),
            i = a(36),
            s = a(3),
            o = a(8),
            c = a(47);
          function findBlock(e, t, a) {
            const r = e.length,
              n = t.length,
              i = r - n;
            let o = a,
              c = !1;
            for (; o < i; ) {
              let a = 0;
              for (; a < n && e[o + a] === t[a]; ) a++;
              if (a >= n) {
                o += a;
                for (; o < r && (0, s.isWhiteSpace)(e[o]); ) o++;
                c = !0;
                break;
              }
              o++;
            }
            return { found: c, length: o };
          }
          t.Type1Font = class Type1Font {
            constructor(e, t, a) {
              let r = a.length1,
                s = a.length2,
                l = t.peekBytes(6);
              const h = 128 === l[0] && 1 === l[1];
              if (h) {
                t.skip(6);
                r = (l[5] << 24) | (l[4] << 16) | (l[3] << 8) | l[2];
              }
              const u = (function getHeaderBlock(e, t) {
                const a = [101, 101, 120, 101, 99],
                  r = e.pos;
                let i, s, c, l;
                try {
                  i = e.getBytes(t);
                  s = i.length;
                } catch (e) {}
                if (s === t) {
                  c = findBlock(i, a, t - 2 * a.length);
                  if (c.found && c.length === t)
                    return { stream: new o.Stream(i), length: t };
                }
                (0, n.warn)(
                  'Invalid "Length1" property in Type1 font -- trying to recover.'
                );
                e.pos = r;
                for (;;) {
                  c = findBlock(e.peekBytes(2048), a, 0);
                  if (0 === c.length) break;
                  e.pos += c.length;
                  if (c.found) {
                    l = e.pos - r;
                    break;
                  }
                }
                e.pos = r;
                if (l)
                  return { stream: new o.Stream(e.getBytes(l)), length: l };
                (0, n.warn)(
                  'Unable to recover "Length1" property in Type1 font -- using as is.'
                );
                return { stream: new o.Stream(e.getBytes(t)), length: t };
              })(t, r);
              new c.Type1Parser(
                u.stream,
                !1,
                i.SEAC_ANALYSIS_ENABLED
              ).extractFontHeader(a);
              if (h) {
                l = t.getBytes(6);
                s = (l[5] << 24) | (l[4] << 16) | (l[3] << 8) | l[2];
              }
              const d = (function getEexecBlock(e, t) {
                  const a = e.getBytes();
                  if (0 === a.length)
                    throw new n.FormatError(
                      "getEexecBlock - no font program found."
                    );
                  return { stream: new o.Stream(a), length: a.length };
                })(t),
                f = new c.Type1Parser(
                  d.stream,
                  !0,
                  i.SEAC_ANALYSIS_ENABLED
                ).extractFontProgram(a);
              for (const e in f.properties) a[e] = f.properties[e];
              const g = f.charstrings,
                p = this.getType2Charstrings(g),
                m = this.getType2Subrs(f.subrs);
              this.charstrings = g;
              this.data = this.wrap(e, p, this.charstrings, m, a);
              this.seacs = this.getSeacs(f.charstrings);
            }
            get numGlyphs() {
              return this.charstrings.length + 1;
            }
            getCharset() {
              const e = [".notdef"];
              for (const { glyphName: t } of this.charstrings) e.push(t);
              return e;
            }
            getGlyphMapping(e) {
              const t = this.charstrings;
              if (e.composite) {
                const a = Object.create(null);
                for (let r = 0, n = t.length; r < n; r++) {
                  a[e.cMap.charCodeOf(r)] = r + 1;
                }
                return a;
              }
              const a = [".notdef"];
              let r, n;
              for (n = 0; n < t.length; n++) a.push(t[n].glyphName);
              const s = e.builtInEncoding;
              if (s) {
                r = Object.create(null);
                for (const e in s) {
                  n = a.indexOf(s[e]);
                  n >= 0 && (r[e] = n);
                }
              }
              return (0, i.type1FontGlyphMapping)(e, r, a);
            }
            hasGlyphId(e) {
              if (e < 0 || e >= this.numGlyphs) return !1;
              if (0 === e) return !0;
              return this.charstrings[e - 1].charstring.length > 0;
            }
            getSeacs(e) {
              const t = [];
              for (let a = 0, r = e.length; a < r; a++) {
                const r = e[a];
                r.seac && (t[a + 1] = r.seac);
              }
              return t;
            }
            getType2Charstrings(e) {
              const t = [];
              for (const a of e) t.push(a.charstring);
              return t;
            }
            getType2Subrs(e) {
              let t = 0;
              const a = e.length;
              t = a < 1133 ? 107 : a < 33769 ? 1131 : 32768;
              const r = [];
              let n;
              for (n = 0; n < t; n++) r.push([11]);
              for (n = 0; n < a; n++) r.push(e[n]);
              return r;
            }
            wrap(e, t, a, n, i) {
              const s = new r.CFF();
              s.header = new r.CFFHeader(1, 0, 4, 4);
              s.names = [e];
              const o = new r.CFFTopDict();
              o.setByName("version", 391);
              o.setByName("Notice", 392);
              o.setByName("FullName", 393);
              o.setByName("FamilyName", 394);
              o.setByName("Weight", 395);
              o.setByName("Encoding", null);
              o.setByName("FontMatrix", i.fontMatrix);
              o.setByName("FontBBox", i.bbox);
              o.setByName("charset", null);
              o.setByName("CharStrings", null);
              o.setByName("Private", null);
              s.topDict = o;
              const c = new r.CFFStrings();
              c.add("Version 0.11");
              c.add("See original notice");
              c.add(e);
              c.add(e);
              c.add("Medium");
              s.strings = c;
              s.globalSubrIndex = new r.CFFIndex();
              const l = t.length,
                h = [".notdef"];
              let u, d;
              for (u = 0; u < l; u++) {
                const e = a[u].glyphName;
                -1 === r.CFFStandardStrings.indexOf(e) && c.add(e);
                h.push(e);
              }
              s.charset = new r.CFFCharset(!1, 0, h);
              const f = new r.CFFIndex();
              f.add([139, 14]);
              for (u = 0; u < l; u++) f.add(t[u]);
              s.charStrings = f;
              const g = new r.CFFPrivateDict();
              g.setByName("Subrs", null);
              const p = [
                "BlueValues",
                "OtherBlues",
                "FamilyBlues",
                "FamilyOtherBlues",
                "StemSnapH",
                "StemSnapV",
                "BlueShift",
                "BlueFuzz",
                "BlueScale",
                "LanguageGroup",
                "ExpansionFactor",
                "ForceBold",
                "StdHW",
                "StdVW",
              ];
              for (u = 0, d = p.length; u < d; u++) {
                const e = p[u];
                if (!(e in i.privateData)) continue;
                const t = i.privateData[e];
                if (Array.isArray(t))
                  for (let e = t.length - 1; e > 0; e--) t[e] -= t[e - 1];
                g.setByName(e, t);
              }
              s.topDict.privateDict = g;
              const m = new r.CFFIndex();
              for (u = 0, d = n.length; u < d; u++) m.add(n[u]);
              g.subrsIndex = m;
              return new r.CFFCompiler(s).compile();
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Type1Parser = void 0;
          var r = a(35),
            n = a(3),
            i = a(8),
            s = a(2);
          const o = [4],
            c = [5],
            l = [6],
            h = [7],
            u = [8],
            d = [12, 35],
            f = [14],
            g = [21],
            p = [22],
            m = [30],
            b = [31];
          class Type1CharString {
            constructor() {
              this.width = 0;
              this.lsb = 0;
              this.flexing = !1;
              this.output = [];
              this.stack = [];
            }
            convert(e, t, a) {
              const r = e.length;
              let n,
                i,
                y,
                w = !1;
              for (let S = 0; S < r; S++) {
                let r = e[S];
                if (r < 32) {
                  12 === r && (r = (r << 8) + e[++S]);
                  switch (r) {
                    case 1:
                    case 3:
                    case 9:
                    case 3072:
                    case 3073:
                    case 3074:
                    case 3105:
                      this.stack = [];
                      break;
                    case 4:
                      if (this.flexing) {
                        if (this.stack.length < 1) {
                          w = !0;
                          break;
                        }
                        const e = this.stack.pop();
                        this.stack.push(0, e);
                        break;
                      }
                      w = this.executeCommand(1, o);
                      break;
                    case 5:
                      w = this.executeCommand(2, c);
                      break;
                    case 6:
                      w = this.executeCommand(1, l);
                      break;
                    case 7:
                      w = this.executeCommand(1, h);
                      break;
                    case 8:
                      w = this.executeCommand(6, u);
                      break;
                    case 10:
                      if (this.stack.length < 1) {
                        w = !0;
                        break;
                      }
                      y = this.stack.pop();
                      if (!t[y]) {
                        w = !0;
                        break;
                      }
                      w = this.convert(t[y], t, a);
                      break;
                    case 11:
                      return w;
                    case 13:
                      if (this.stack.length < 2) {
                        w = !0;
                        break;
                      }
                      n = this.stack.pop();
                      i = this.stack.pop();
                      this.lsb = i;
                      this.width = n;
                      this.stack.push(n, i);
                      w = this.executeCommand(2, p);
                      break;
                    case 14:
                      this.output.push(f[0]);
                      break;
                    case 21:
                      if (this.flexing) break;
                      w = this.executeCommand(2, g);
                      break;
                    case 22:
                      if (this.flexing) {
                        this.stack.push(0);
                        break;
                      }
                      w = this.executeCommand(1, p);
                      break;
                    case 30:
                      w = this.executeCommand(4, m);
                      break;
                    case 31:
                      w = this.executeCommand(4, b);
                      break;
                    case 3078:
                      if (a) {
                        const e = this.stack.at(-5);
                        this.seac = this.stack.splice(-4, 4);
                        this.seac[0] += this.lsb - e;
                        w = this.executeCommand(0, f);
                      } else w = this.executeCommand(4, f);
                      break;
                    case 3079:
                      if (this.stack.length < 4) {
                        w = !0;
                        break;
                      }
                      this.stack.pop();
                      n = this.stack.pop();
                      const e = this.stack.pop();
                      i = this.stack.pop();
                      this.lsb = i;
                      this.width = n;
                      this.stack.push(n, i, e);
                      w = this.executeCommand(3, g);
                      break;
                    case 3084:
                      if (this.stack.length < 2) {
                        w = !0;
                        break;
                      }
                      const S = this.stack.pop(),
                        x = this.stack.pop();
                      this.stack.push(x / S);
                      break;
                    case 3088:
                      if (this.stack.length < 2) {
                        w = !0;
                        break;
                      }
                      y = this.stack.pop();
                      const C = this.stack.pop();
                      if (0 === y && 3 === C) {
                        const e = this.stack.splice(this.stack.length - 17, 17);
                        this.stack.push(
                          e[2] + e[0],
                          e[3] + e[1],
                          e[4],
                          e[5],
                          e[6],
                          e[7],
                          e[8],
                          e[9],
                          e[10],
                          e[11],
                          e[12],
                          e[13],
                          e[14]
                        );
                        w = this.executeCommand(13, d, !0);
                        this.flexing = !1;
                        this.stack.push(e[15], e[16]);
                      } else 1 === y && 0 === C && (this.flexing = !0);
                      break;
                    case 3089:
                      break;
                    default:
                      (0, s.warn)(
                        'Unknown type 1 charstring command of "' + r + '"'
                      );
                  }
                  if (w) break;
                } else {
                  r <= 246
                    ? (r -= 139)
                    : (r =
                        r <= 250
                          ? 256 * (r - 247) + e[++S] + 108
                          : r <= 254
                          ? -256 * (r - 251) - e[++S] - 108
                          : ((255 & e[++S]) << 24) |
                            ((255 & e[++S]) << 16) |
                            ((255 & e[++S]) << 8) |
                            ((255 & e[++S]) << 0));
                  this.stack.push(r);
                }
              }
              return w;
            }
            executeCommand(e, t, a) {
              const r = this.stack.length;
              if (e > r) return !0;
              const n = r - e;
              for (let e = n; e < r; e++) {
                let t = this.stack[e];
                if (Number.isInteger(t))
                  this.output.push(28, (t >> 8) & 255, 255 & t);
                else {
                  t = (65536 * t) | 0;
                  this.output.push(
                    255,
                    (t >> 24) & 255,
                    (t >> 16) & 255,
                    (t >> 8) & 255,
                    255 & t
                  );
                }
              }
              this.output.push(...t);
              a ? this.stack.splice(n, e) : (this.stack.length = 0);
              return !1;
            }
          }
          function isHexDigit(e) {
            return (
              (e >= 48 && e <= 57) ||
              (e >= 65 && e <= 70) ||
              (e >= 97 && e <= 102)
            );
          }
          function decrypt(e, t, a) {
            if (a >= e.length) return new Uint8Array(0);
            let r,
              n,
              i = 0 | t;
            for (r = 0; r < a; r++) i = (52845 * (e[r] + i) + 22719) & 65535;
            const s = e.length - a,
              o = new Uint8Array(s);
            for (r = a, n = 0; n < s; r++, n++) {
              const t = e[r];
              o[n] = t ^ (i >> 8);
              i = (52845 * (t + i) + 22719) & 65535;
            }
            return o;
          }
          function isSpecial(e) {
            return (
              47 === e ||
              91 === e ||
              93 === e ||
              123 === e ||
              125 === e ||
              40 === e ||
              41 === e
            );
          }
          t.Type1Parser = class Type1Parser {
            constructor(e, t, a) {
              if (t) {
                const t = e.getBytes(),
                  a = !(
                    (isHexDigit(t[0]) || (0, n.isWhiteSpace)(t[0])) &&
                    isHexDigit(t[1]) &&
                    isHexDigit(t[2]) &&
                    isHexDigit(t[3]) &&
                    isHexDigit(t[4]) &&
                    isHexDigit(t[5]) &&
                    isHexDigit(t[6]) &&
                    isHexDigit(t[7])
                  );
                e = new i.Stream(
                  a
                    ? decrypt(t, 55665, 4)
                    : (function decryptAscii(e, t, a) {
                        let r = 0 | t;
                        const n = e.length,
                          i = new Uint8Array(n >>> 1);
                        let s, o;
                        for (s = 0, o = 0; s < n; s++) {
                          const t = e[s];
                          if (!isHexDigit(t)) continue;
                          s++;
                          let a;
                          for (; s < n && !isHexDigit((a = e[s])); ) s++;
                          if (s < n) {
                            const e = parseInt(String.fromCharCode(t, a), 16);
                            i[o++] = e ^ (r >> 8);
                            r = (52845 * (e + r) + 22719) & 65535;
                          }
                        }
                        return i.slice(a, o);
                      })(t, 55665, 4)
                );
              }
              this.seacAnalysisEnabled = !!a;
              this.stream = e;
              this.nextChar();
            }
            readNumberArray() {
              this.getToken();
              const e = [];
              for (;;) {
                const t = this.getToken();
                if (null === t || "]" === t || "}" === t) break;
                e.push(parseFloat(t || 0));
              }
              return e;
            }
            readNumber() {
              const e = this.getToken();
              return parseFloat(e || 0);
            }
            readInt() {
              const e = this.getToken();
              return 0 | parseInt(e || 0, 10);
            }
            readBoolean() {
              return "true" === this.getToken() ? 1 : 0;
            }
            nextChar() {
              return (this.currentChar = this.stream.getByte());
            }
            prevChar() {
              this.stream.skip(-2);
              return (this.currentChar = this.stream.getByte());
            }
            getToken() {
              let e = !1,
                t = this.currentChar;
              for (;;) {
                if (-1 === t) return null;
                if (e) (10 !== t && 13 !== t) || (e = !1);
                else if (37 === t) e = !0;
                else if (!(0, n.isWhiteSpace)(t)) break;
                t = this.nextChar();
              }
              if (isSpecial(t)) {
                this.nextChar();
                return String.fromCharCode(t);
              }
              let a = "";
              do {
                a += String.fromCharCode(t);
                t = this.nextChar();
              } while (t >= 0 && !(0, n.isWhiteSpace)(t) && !isSpecial(t));
              return a;
            }
            readCharStrings(e, t) {
              return -1 === t ? e : decrypt(e, 4330, t);
            }
            extractFontProgram(e) {
              const t = this.stream,
                a = [],
                r = [],
                n = Object.create(null);
              n.lenIV = 4;
              const i = {
                subrs: [],
                charstrings: [],
                properties: { privateData: n },
              };
              let s, o, c, l;
              for (; null !== (s = this.getToken()); )
                if ("/" === s) {
                  s = this.getToken();
                  switch (s) {
                    case "CharStrings":
                      this.getToken();
                      this.getToken();
                      this.getToken();
                      this.getToken();
                      for (;;) {
                        s = this.getToken();
                        if (null === s || "end" === s) break;
                        if ("/" !== s) continue;
                        const e = this.getToken();
                        o = this.readInt();
                        this.getToken();
                        c = o > 0 ? t.getBytes(o) : new Uint8Array(0);
                        l = i.properties.privateData.lenIV;
                        const a = this.readCharStrings(c, l);
                        this.nextChar();
                        s = this.getToken();
                        "noaccess" === s
                          ? this.getToken()
                          : "/" === s && this.prevChar();
                        r.push({ glyph: e, encoded: a });
                      }
                      break;
                    case "Subrs":
                      this.readInt();
                      this.getToken();
                      for (; "dup" === this.getToken(); ) {
                        const e = this.readInt();
                        o = this.readInt();
                        this.getToken();
                        c = o > 0 ? t.getBytes(o) : new Uint8Array(0);
                        l = i.properties.privateData.lenIV;
                        const r = this.readCharStrings(c, l);
                        this.nextChar();
                        s = this.getToken();
                        "noaccess" === s && this.getToken();
                        a[e] = r;
                      }
                      break;
                    case "BlueValues":
                    case "OtherBlues":
                    case "FamilyBlues":
                    case "FamilyOtherBlues":
                      const e = this.readNumberArray();
                      e.length > 0 && e.length, 0;
                      break;
                    case "StemSnapH":
                    case "StemSnapV":
                      i.properties.privateData[s] = this.readNumberArray();
                      break;
                    case "StdHW":
                    case "StdVW":
                      i.properties.privateData[s] = this.readNumberArray()[0];
                      break;
                    case "BlueShift":
                    case "lenIV":
                    case "BlueFuzz":
                    case "BlueScale":
                    case "LanguageGroup":
                      i.properties.privateData[s] = this.readNumber();
                      break;
                    case "ExpansionFactor":
                      i.properties.privateData[s] = this.readNumber() || 0.06;
                      break;
                    case "ForceBold":
                      i.properties.privateData[s] = this.readBoolean();
                  }
                }
              for (const { encoded: t, glyph: n } of r) {
                const r = new Type1CharString(),
                  s = r.convert(t, a, this.seacAnalysisEnabled);
                let o = r.output;
                s && (o = [14]);
                const c = {
                  glyphName: n,
                  charstring: o,
                  width: r.width,
                  lsb: r.lsb,
                  seac: r.seac,
                };
                ".notdef" === n
                  ? i.charstrings.unshift(c)
                  : i.charstrings.push(c);
                if (e.builtInEncoding) {
                  const t = e.builtInEncoding.indexOf(n);
                  t > -1 &&
                    void 0 === e.widths[t] &&
                    t >= e.firstChar &&
                    t <= e.lastChar &&
                    (e.widths[t] = r.width);
                }
              }
              return i;
            }
            extractFontHeader(e) {
              let t;
              for (; null !== (t = this.getToken()); )
                if ("/" === t) {
                  t = this.getToken();
                  switch (t) {
                    case "FontMatrix":
                      const a = this.readNumberArray();
                      e.fontMatrix = a;
                      break;
                    case "Encoding":
                      const n = this.getToken();
                      let i;
                      if (/^\d+$/.test(n)) {
                        i = [];
                        const e = 0 | parseInt(n, 10);
                        this.getToken();
                        for (let a = 0; a < e; a++) {
                          t = this.getToken();
                          for (; "dup" !== t && "def" !== t; ) {
                            t = this.getToken();
                            if (null === t) return;
                          }
                          if ("def" === t) break;
                          const e = this.readInt();
                          this.getToken();
                          const a = this.getToken();
                          i[e] = a;
                          this.getToken();
                        }
                      } else i = (0, r.getEncoding)(n);
                      e.builtInEncoding = i;
                      break;
                    case "FontBBox":
                      const s = this.readNumberArray();
                      e.ascent = Math.max(s[3], s[1]);
                      e.descent = Math.min(s[1], s[3]);
                      e.ascentScaled = !0;
                  }
                }
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Pattern = void 0;
          t.getTilingPatternIR = function getTilingPatternIR(e, t, a) {
            const n = t.getArray("Matrix"),
              i = r.Util.normalizeRect(t.getArray("BBox")),
              s = t.get("XStep"),
              o = t.get("YStep"),
              c = t.get("PaintType"),
              l = t.get("TilingType");
            if (i[2] - i[0] == 0 || i[3] - i[1] == 0)
              throw new r.FormatError(
                `Invalid getTilingPatternIR /BBox array: [${i}].`
              );
            return ["TilingPattern", a, e, n, i, s, o, c, l];
          };
          var r = a(2),
            n = a(5),
            i = a(12),
            s = a(3);
          const o = 2,
            c = 3,
            l = 4,
            h = 5,
            u = 6,
            d = 7;
          t.Pattern = class Pattern {
            constructor() {
              (0, r.unreachable)("Cannot initialize Pattern.");
            }
            static parseShading(e, t, a, i, f, g) {
              const p = e instanceof n.BaseStream ? e.dict : e,
                m = p.get("ShadingType");
              try {
                switch (m) {
                  case o:
                  case c:
                    return new RadialAxialShading(p, t, a, f, g);
                  case l:
                  case h:
                  case u:
                  case d:
                    return new MeshShading(e, t, a, f, g);
                  default:
                    throw new r.FormatError("Unsupported ShadingType: " + m);
                }
              } catch (e) {
                if (e instanceof s.MissingDataException) throw e;
                i.send("UnsupportedFeature", {
                  featureId: r.UNSUPPORTED_FEATURES.shadingPattern,
                });
                (0, r.warn)(e);
                return new DummyShading();
              }
            }
          };
          class BaseShading {
            static get SMALL_NUMBER() {
              return (0, r.shadow)(this, "SMALL_NUMBER", 1e-6);
            }
            constructor() {
              this.constructor === BaseShading &&
                (0, r.unreachable)("Cannot initialize BaseShading.");
            }
            getIR() {
              (0, r.unreachable)("Abstract method `getIR` called.");
            }
          }
          class RadialAxialShading extends BaseShading {
            constructor(e, t, a, n, s) {
              super();
              this.coordsArr = e.getArray("Coords");
              this.shadingType = e.get("ShadingType");
              const o = i.ColorSpace.parse({
                  cs: e.getRaw("CS") || e.getRaw("ColorSpace"),
                  xref: t,
                  resources: a,
                  pdfFunctionFactory: n,
                  localColorSpaceCache: s,
                }),
                l = e.getArray("BBox");
              Array.isArray(l) && 4 === l.length
                ? (this.bbox = r.Util.normalizeRect(l))
                : (this.bbox = null);
              let h = 0,
                u = 1;
              if (e.has("Domain")) {
                const t = e.getArray("Domain");
                h = t[0];
                u = t[1];
              }
              let d = !1,
                f = !1;
              if (e.has("Extend")) {
                const t = e.getArray("Extend");
                d = t[0];
                f = t[1];
              }
              if (!(this.shadingType !== c || (d && f))) {
                const [e, t, a, n, i, s] = this.coordsArr,
                  o = Math.hypot(e - n, t - i);
                a <= s + o &&
                  s <= a + o &&
                  (0, r.warn)("Unsupported radial gradient.");
              }
              this.extendStart = d;
              this.extendEnd = f;
              const g = e.getRaw("Function"),
                p = n.createFromArray(g),
                m = (u - h) / 10,
                b = (this.colorStops = []);
              if (h >= u || m <= 0) {
                (0, r.info)("Bad shading domain.");
                return;
              }
              const y = new Float32Array(o.numComps),
                w = new Float32Array(1);
              let S;
              for (let e = 0; e <= 10; e++) {
                w[0] = h + e * m;
                p(w, 0, y, 0);
                S = o.getRgb(y, 0);
                const t = r.Util.makeHexColor(S[0], S[1], S[2]);
                b.push([e / 10, t]);
              }
              let x = "transparent";
              if (e.has("Background")) {
                S = o.getRgb(e.get("Background"), 0);
                x = r.Util.makeHexColor(S[0], S[1], S[2]);
              }
              if (!d) {
                b.unshift([0, x]);
                b[1][0] += BaseShading.SMALL_NUMBER;
              }
              if (!f) {
                b.at(-1)[0] -= BaseShading.SMALL_NUMBER;
                b.push([1, x]);
              }
              this.colorStops = b;
            }
            getIR() {
              const e = this.coordsArr,
                t = this.shadingType;
              let a, n, i, s, l;
              if (t === o) {
                n = [e[0], e[1]];
                i = [e[2], e[3]];
                s = null;
                l = null;
                a = "axial";
              } else if (t === c) {
                n = [e[0], e[1]];
                i = [e[3], e[4]];
                s = e[2];
                l = e[5];
                a = "radial";
              } else (0, r.unreachable)(`getPattern type unknown: ${t}`);
              return ["RadialAxial", a, this.bbox, this.colorStops, n, i, s, l];
            }
          }
          class MeshStreamReader {
            constructor(e, t) {
              this.stream = e;
              this.context = t;
              this.buffer = 0;
              this.bufferLength = 0;
              const a = t.numComps;
              this.tmpCompsBuf = new Float32Array(a);
              const r = t.colorSpace.numComps;
              this.tmpCsCompsBuf = t.colorFn
                ? new Float32Array(r)
                : this.tmpCompsBuf;
            }
            get hasData() {
              if (this.stream.end) return this.stream.pos < this.stream.end;
              if (this.bufferLength > 0) return !0;
              const e = this.stream.getByte();
              if (e < 0) return !1;
              this.buffer = e;
              this.bufferLength = 8;
              return !0;
            }
            readBits(e) {
              let t = this.buffer,
                a = this.bufferLength;
              if (32 === e) {
                if (0 === a)
                  return (
                    ((this.stream.getByte() << 24) |
                      (this.stream.getByte() << 16) |
                      (this.stream.getByte() << 8) |
                      this.stream.getByte()) >>>
                    0
                  );
                t =
                  (t << 24) |
                  (this.stream.getByte() << 16) |
                  (this.stream.getByte() << 8) |
                  this.stream.getByte();
                const e = this.stream.getByte();
                this.buffer = e & ((1 << a) - 1);
                return ((t << (8 - a)) | ((255 & e) >> a)) >>> 0;
              }
              if (8 === e && 0 === a) return this.stream.getByte();
              for (; a < e; ) {
                t = (t << 8) | this.stream.getByte();
                a += 8;
              }
              a -= e;
              this.bufferLength = a;
              this.buffer = t & ((1 << a) - 1);
              return t >> a;
            }
            align() {
              this.buffer = 0;
              this.bufferLength = 0;
            }
            readFlag() {
              return this.readBits(this.context.bitsPerFlag);
            }
            readCoordinate() {
              const e = this.context.bitsPerCoordinate,
                t = this.readBits(e),
                a = this.readBits(e),
                r = this.context.decode,
                n = e < 32 ? 1 / ((1 << e) - 1) : 2.3283064365386963e-10;
              return [
                t * n * (r[1] - r[0]) + r[0],
                a * n * (r[3] - r[2]) + r[2],
              ];
            }
            readComponents() {
              const e = this.context.numComps,
                t = this.context.bitsPerComponent,
                a = t < 32 ? 1 / ((1 << t) - 1) : 2.3283064365386963e-10,
                r = this.context.decode,
                n = this.tmpCompsBuf;
              for (let i = 0, s = 4; i < e; i++, s += 2) {
                const e = this.readBits(t);
                n[i] = e * a * (r[s + 1] - r[s]) + r[s];
              }
              const i = this.tmpCsCompsBuf;
              this.context.colorFn && this.context.colorFn(n, 0, i, 0);
              return this.context.colorSpace.getRgb(i, 0);
            }
          }
          const f = (function getBClosure() {
            const e = [];
            return function (t) {
              e[t] ||
                (e[t] = (function buildB(e) {
                  const t = [];
                  for (let a = 0; a <= e; a++) {
                    const r = a / e,
                      n = 1 - r;
                    t.push(
                      new Float32Array([
                        n * n * n,
                        3 * r * n * n,
                        3 * r * r * n,
                        r * r * r,
                      ])
                    );
                  }
                  return t;
                })(t));
              return e[t];
            };
          })();
          class MeshShading extends BaseShading {
            static get MIN_SPLIT_PATCH_CHUNKS_AMOUNT() {
              return (0, r.shadow)(this, "MIN_SPLIT_PATCH_CHUNKS_AMOUNT", 3);
            }
            static get MAX_SPLIT_PATCH_CHUNKS_AMOUNT() {
              return (0, r.shadow)(this, "MAX_SPLIT_PATCH_CHUNKS_AMOUNT", 20);
            }
            static get TRIANGLE_DENSITY() {
              return (0, r.shadow)(this, "TRIANGLE_DENSITY", 20);
            }
            constructor(e, t, a, s, o) {
              super();
              if (!(e instanceof n.BaseStream))
                throw new r.FormatError("Mesh data is not a stream");
              const c = e.dict;
              this.shadingType = c.get("ShadingType");
              const f = c.getArray("BBox");
              Array.isArray(f) && 4 === f.length
                ? (this.bbox = r.Util.normalizeRect(f))
                : (this.bbox = null);
              const g = i.ColorSpace.parse({
                cs: c.getRaw("CS") || c.getRaw("ColorSpace"),
                xref: t,
                resources: a,
                pdfFunctionFactory: s,
                localColorSpaceCache: o,
              });
              this.background = c.has("Background")
                ? g.getRgb(c.get("Background"), 0)
                : null;
              const p = c.getRaw("Function"),
                m = p ? s.createFromArray(p) : null;
              this.coords = [];
              this.colors = [];
              this.figures = [];
              const b = {
                  bitsPerCoordinate: c.get("BitsPerCoordinate"),
                  bitsPerComponent: c.get("BitsPerComponent"),
                  bitsPerFlag: c.get("BitsPerFlag"),
                  decode: c.getArray("Decode"),
                  colorFn: m,
                  colorSpace: g,
                  numComps: m ? 1 : g.numComps,
                },
                y = new MeshStreamReader(e, b);
              let w = !1;
              switch (this.shadingType) {
                case l:
                  this._decodeType4Shading(y);
                  break;
                case h:
                  const e = 0 | c.get("VerticesPerRow");
                  if (e < 2) throw new r.FormatError("Invalid VerticesPerRow");
                  this._decodeType5Shading(y, e);
                  break;
                case u:
                  this._decodeType6Shading(y);
                  w = !0;
                  break;
                case d:
                  this._decodeType7Shading(y);
                  w = !0;
                  break;
                default:
                  (0, r.unreachable)("Unsupported mesh type.");
              }
              if (w) {
                this._updateBounds();
                for (let e = 0, t = this.figures.length; e < t; e++)
                  this._buildFigureFromPatch(e);
              }
              this._updateBounds();
              this._packData();
            }
            _decodeType4Shading(e) {
              const t = this.coords,
                a = this.colors,
                n = [],
                i = [];
              let s = 0;
              for (; e.hasData; ) {
                const o = e.readFlag(),
                  c = e.readCoordinate(),
                  l = e.readComponents();
                if (0 === s) {
                  if (!(0 <= o && o <= 2))
                    throw new r.FormatError("Unknown type4 flag");
                  switch (o) {
                    case 0:
                      s = 3;
                      break;
                    case 1:
                      i.push(i.at(-2), i.at(-1));
                      s = 1;
                      break;
                    case 2:
                      i.push(i.at(-3), i.at(-1));
                      s = 1;
                  }
                  n.push(o);
                }
                i.push(t.length);
                t.push(c);
                a.push(l);
                s--;
                e.align();
              }
              this.figures.push({
                type: "triangles",
                coords: new Int32Array(i),
                colors: new Int32Array(i),
              });
            }
            _decodeType5Shading(e, t) {
              const a = this.coords,
                r = this.colors,
                n = [];
              for (; e.hasData; ) {
                const t = e.readCoordinate(),
                  i = e.readComponents();
                n.push(a.length);
                a.push(t);
                r.push(i);
              }
              this.figures.push({
                type: "lattice",
                coords: new Int32Array(n),
                colors: new Int32Array(n),
                verticesPerRow: t,
              });
            }
            _decodeType6Shading(e) {
              const t = this.coords,
                a = this.colors,
                n = new Int32Array(16),
                i = new Int32Array(4);
              for (; e.hasData; ) {
                const s = e.readFlag();
                if (!(0 <= s && s <= 3))
                  throw new r.FormatError("Unknown type6 flag");
                const o = t.length;
                for (let a = 0, r = 0 !== s ? 8 : 12; a < r; a++)
                  t.push(e.readCoordinate());
                const c = a.length;
                for (let t = 0, r = 0 !== s ? 2 : 4; t < r; t++)
                  a.push(e.readComponents());
                let l, h, u, d;
                switch (s) {
                  case 0:
                    n[12] = o + 3;
                    n[13] = o + 4;
                    n[14] = o + 5;
                    n[15] = o + 6;
                    n[8] = o + 2;
                    n[11] = o + 7;
                    n[4] = o + 1;
                    n[7] = o + 8;
                    n[0] = o;
                    n[1] = o + 11;
                    n[2] = o + 10;
                    n[3] = o + 9;
                    i[2] = c + 1;
                    i[3] = c + 2;
                    i[0] = c;
                    i[1] = c + 3;
                    break;
                  case 1:
                    l = n[12];
                    h = n[13];
                    u = n[14];
                    d = n[15];
                    n[12] = d;
                    n[13] = o + 0;
                    n[14] = o + 1;
                    n[15] = o + 2;
                    n[8] = u;
                    n[11] = o + 3;
                    n[4] = h;
                    n[7] = o + 4;
                    n[0] = l;
                    n[1] = o + 7;
                    n[2] = o + 6;
                    n[3] = o + 5;
                    l = i[2];
                    h = i[3];
                    i[2] = h;
                    i[3] = c;
                    i[0] = l;
                    i[1] = c + 1;
                    break;
                  case 2:
                    l = n[15];
                    h = n[11];
                    n[12] = n[3];
                    n[13] = o + 0;
                    n[14] = o + 1;
                    n[15] = o + 2;
                    n[8] = n[7];
                    n[11] = o + 3;
                    n[4] = h;
                    n[7] = o + 4;
                    n[0] = l;
                    n[1] = o + 7;
                    n[2] = o + 6;
                    n[3] = o + 5;
                    l = i[3];
                    i[2] = i[1];
                    i[3] = c;
                    i[0] = l;
                    i[1] = c + 1;
                    break;
                  case 3:
                    n[12] = n[0];
                    n[13] = o + 0;
                    n[14] = o + 1;
                    n[15] = o + 2;
                    n[8] = n[1];
                    n[11] = o + 3;
                    n[4] = n[2];
                    n[7] = o + 4;
                    n[0] = n[3];
                    n[1] = o + 7;
                    n[2] = o + 6;
                    n[3] = o + 5;
                    i[2] = i[0];
                    i[3] = c;
                    i[0] = i[1];
                    i[1] = c + 1;
                }
                n[5] = t.length;
                t.push([
                  (-4 * t[n[0]][0] -
                    t[n[15]][0] +
                    6 * (t[n[4]][0] + t[n[1]][0]) -
                    2 * (t[n[12]][0] + t[n[3]][0]) +
                    3 * (t[n[13]][0] + t[n[7]][0])) /
                    9,
                  (-4 * t[n[0]][1] -
                    t[n[15]][1] +
                    6 * (t[n[4]][1] + t[n[1]][1]) -
                    2 * (t[n[12]][1] + t[n[3]][1]) +
                    3 * (t[n[13]][1] + t[n[7]][1])) /
                    9,
                ]);
                n[6] = t.length;
                t.push([
                  (-4 * t[n[3]][0] -
                    t[n[12]][0] +
                    6 * (t[n[2]][0] + t[n[7]][0]) -
                    2 * (t[n[0]][0] + t[n[15]][0]) +
                    3 * (t[n[4]][0] + t[n[14]][0])) /
                    9,
                  (-4 * t[n[3]][1] -
                    t[n[12]][1] +
                    6 * (t[n[2]][1] + t[n[7]][1]) -
                    2 * (t[n[0]][1] + t[n[15]][1]) +
                    3 * (t[n[4]][1] + t[n[14]][1])) /
                    9,
                ]);
                n[9] = t.length;
                t.push([
                  (-4 * t[n[12]][0] -
                    t[n[3]][0] +
                    6 * (t[n[8]][0] + t[n[13]][0]) -
                    2 * (t[n[0]][0] + t[n[15]][0]) +
                    3 * (t[n[11]][0] + t[n[1]][0])) /
                    9,
                  (-4 * t[n[12]][1] -
                    t[n[3]][1] +
                    6 * (t[n[8]][1] + t[n[13]][1]) -
                    2 * (t[n[0]][1] + t[n[15]][1]) +
                    3 * (t[n[11]][1] + t[n[1]][1])) /
                    9,
                ]);
                n[10] = t.length;
                t.push([
                  (-4 * t[n[15]][0] -
                    t[n[0]][0] +
                    6 * (t[n[11]][0] + t[n[14]][0]) -
                    2 * (t[n[12]][0] + t[n[3]][0]) +
                    3 * (t[n[2]][0] + t[n[8]][0])) /
                    9,
                  (-4 * t[n[15]][1] -
                    t[n[0]][1] +
                    6 * (t[n[11]][1] + t[n[14]][1]) -
                    2 * (t[n[12]][1] + t[n[3]][1]) +
                    3 * (t[n[2]][1] + t[n[8]][1])) /
                    9,
                ]);
                this.figures.push({
                  type: "patch",
                  coords: new Int32Array(n),
                  colors: new Int32Array(i),
                });
              }
            }
            _decodeType7Shading(e) {
              const t = this.coords,
                a = this.colors,
                n = new Int32Array(16),
                i = new Int32Array(4);
              for (; e.hasData; ) {
                const s = e.readFlag();
                if (!(0 <= s && s <= 3))
                  throw new r.FormatError("Unknown type7 flag");
                const o = t.length;
                for (let a = 0, r = 0 !== s ? 12 : 16; a < r; a++)
                  t.push(e.readCoordinate());
                const c = a.length;
                for (let t = 0, r = 0 !== s ? 2 : 4; t < r; t++)
                  a.push(e.readComponents());
                let l, h, u, d;
                switch (s) {
                  case 0:
                    n[12] = o + 3;
                    n[13] = o + 4;
                    n[14] = o + 5;
                    n[15] = o + 6;
                    n[8] = o + 2;
                    n[9] = o + 13;
                    n[10] = o + 14;
                    n[11] = o + 7;
                    n[4] = o + 1;
                    n[5] = o + 12;
                    n[6] = o + 15;
                    n[7] = o + 8;
                    n[0] = o;
                    n[1] = o + 11;
                    n[2] = o + 10;
                    n[3] = o + 9;
                    i[2] = c + 1;
                    i[3] = c + 2;
                    i[0] = c;
                    i[1] = c + 3;
                    break;
                  case 1:
                    l = n[12];
                    h = n[13];
                    u = n[14];
                    d = n[15];
                    n[12] = d;
                    n[13] = o + 0;
                    n[14] = o + 1;
                    n[15] = o + 2;
                    n[8] = u;
                    n[9] = o + 9;
                    n[10] = o + 10;
                    n[11] = o + 3;
                    n[4] = h;
                    n[5] = o + 8;
                    n[6] = o + 11;
                    n[7] = o + 4;
                    n[0] = l;
                    n[1] = o + 7;
                    n[2] = o + 6;
                    n[3] = o + 5;
                    l = i[2];
                    h = i[3];
                    i[2] = h;
                    i[3] = c;
                    i[0] = l;
                    i[1] = c + 1;
                    break;
                  case 2:
                    l = n[15];
                    h = n[11];
                    n[12] = n[3];
                    n[13] = o + 0;
                    n[14] = o + 1;
                    n[15] = o + 2;
                    n[8] = n[7];
                    n[9] = o + 9;
                    n[10] = o + 10;
                    n[11] = o + 3;
                    n[4] = h;
                    n[5] = o + 8;
                    n[6] = o + 11;
                    n[7] = o + 4;
                    n[0] = l;
                    n[1] = o + 7;
                    n[2] = o + 6;
                    n[3] = o + 5;
                    l = i[3];
                    i[2] = i[1];
                    i[3] = c;
                    i[0] = l;
                    i[1] = c + 1;
                    break;
                  case 3:
                    n[12] = n[0];
                    n[13] = o + 0;
                    n[14] = o + 1;
                    n[15] = o + 2;
                    n[8] = n[1];
                    n[9] = o + 9;
                    n[10] = o + 10;
                    n[11] = o + 3;
                    n[4] = n[2];
                    n[5] = o + 8;
                    n[6] = o + 11;
                    n[7] = o + 4;
                    n[0] = n[3];
                    n[1] = o + 7;
                    n[2] = o + 6;
                    n[3] = o + 5;
                    i[2] = i[0];
                    i[3] = c;
                    i[0] = i[1];
                    i[1] = c + 1;
                }
                this.figures.push({
                  type: "patch",
                  coords: new Int32Array(n),
                  colors: new Int32Array(i),
                });
              }
            }
            _buildFigureFromPatch(e) {
              const t = this.figures[e];
              (0, r.assert)("patch" === t.type, "Unexpected patch mesh figure");
              const a = this.coords,
                n = this.colors,
                i = t.coords,
                s = t.colors,
                o = Math.min(a[i[0]][0], a[i[3]][0], a[i[12]][0], a[i[15]][0]),
                c = Math.min(a[i[0]][1], a[i[3]][1], a[i[12]][1], a[i[15]][1]),
                l = Math.max(a[i[0]][0], a[i[3]][0], a[i[12]][0], a[i[15]][0]),
                h = Math.max(a[i[0]][1], a[i[3]][1], a[i[12]][1], a[i[15]][1]);
              let u = Math.ceil(
                ((l - o) * MeshShading.TRIANGLE_DENSITY) /
                  (this.bounds[2] - this.bounds[0])
              );
              u = Math.max(
                MeshShading.MIN_SPLIT_PATCH_CHUNKS_AMOUNT,
                Math.min(MeshShading.MAX_SPLIT_PATCH_CHUNKS_AMOUNT, u)
              );
              let d = Math.ceil(
                ((h - c) * MeshShading.TRIANGLE_DENSITY) /
                  (this.bounds[3] - this.bounds[1])
              );
              d = Math.max(
                MeshShading.MIN_SPLIT_PATCH_CHUNKS_AMOUNT,
                Math.min(MeshShading.MAX_SPLIT_PATCH_CHUNKS_AMOUNT, d)
              );
              const g = u + 1,
                p = new Int32Array((d + 1) * g),
                m = new Int32Array((d + 1) * g);
              let b = 0;
              const y = new Uint8Array(3),
                w = new Uint8Array(3),
                S = n[s[0]],
                x = n[s[1]],
                C = n[s[2]],
                k = n[s[3]],
                v = f(d),
                F = f(u);
              for (let e = 0; e <= d; e++) {
                y[0] = ((S[0] * (d - e) + C[0] * e) / d) | 0;
                y[1] = ((S[1] * (d - e) + C[1] * e) / d) | 0;
                y[2] = ((S[2] * (d - e) + C[2] * e) / d) | 0;
                w[0] = ((x[0] * (d - e) + k[0] * e) / d) | 0;
                w[1] = ((x[1] * (d - e) + k[1] * e) / d) | 0;
                w[2] = ((x[2] * (d - e) + k[2] * e) / d) | 0;
                for (let t = 0; t <= u; t++, b++) {
                  if (!((0 !== e && e !== d) || (0 !== t && t !== u))) continue;
                  let r = 0,
                    s = 0,
                    o = 0;
                  for (let n = 0; n <= 3; n++)
                    for (let c = 0; c <= 3; c++, o++) {
                      const l = v[e][n] * F[t][c];
                      r += a[i[o]][0] * l;
                      s += a[i[o]][1] * l;
                    }
                  p[b] = a.length;
                  a.push([r, s]);
                  m[b] = n.length;
                  const c = new Uint8Array(3);
                  c[0] = ((y[0] * (u - t) + w[0] * t) / u) | 0;
                  c[1] = ((y[1] * (u - t) + w[1] * t) / u) | 0;
                  c[2] = ((y[2] * (u - t) + w[2] * t) / u) | 0;
                  n.push(c);
                }
              }
              p[0] = i[0];
              m[0] = s[0];
              p[u] = i[3];
              m[u] = s[1];
              p[g * d] = i[12];
              m[g * d] = s[2];
              p[g * d + u] = i[15];
              m[g * d + u] = s[3];
              this.figures[e] = {
                type: "lattice",
                coords: p,
                colors: m,
                verticesPerRow: g,
              };
            }
            _updateBounds() {
              let e = this.coords[0][0],
                t = this.coords[0][1],
                a = e,
                r = t;
              for (let n = 1, i = this.coords.length; n < i; n++) {
                const i = this.coords[n][0],
                  s = this.coords[n][1];
                e = e > i ? i : e;
                t = t > s ? s : t;
                a = a < i ? i : a;
                r = r < s ? s : r;
              }
              this.bounds = [e, t, a, r];
            }
            _packData() {
              let e, t, a, r;
              const n = this.coords,
                i = new Float32Array(2 * n.length);
              for (e = 0, a = 0, t = n.length; e < t; e++) {
                const t = n[e];
                i[a++] = t[0];
                i[a++] = t[1];
              }
              this.coords = i;
              const s = this.colors,
                o = new Uint8Array(3 * s.length);
              for (e = 0, a = 0, t = s.length; e < t; e++) {
                const t = s[e];
                o[a++] = t[0];
                o[a++] = t[1];
                o[a++] = t[2];
              }
              this.colors = o;
              const c = this.figures;
              for (e = 0, t = c.length; e < t; e++) {
                const t = c[e],
                  n = t.coords,
                  i = t.colors;
                for (a = 0, r = n.length; a < r; a++) {
                  n[a] *= 2;
                  i[a] *= 3;
                }
              }
            }
            getIR() {
              return [
                "Mesh",
                this.shadingType,
                this.coords,
                this.colors,
                this.figures,
                this.bounds,
                this.bbox,
                this.background,
              ];
            }
          }
          class DummyShading extends BaseShading {
            getIR() {
              return ["Dummy"];
            }
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.getXfaFontDict = function getXfaFontDict(e) {
            const t = (function getXfaFontWidths(e) {
                const t = getXfaFontName(e);
                if (!t) return null;
                const { baseWidths: a, baseMapping: r, factors: n } = t;
                let i;
                i = n ? a.map((e, t) => e * n[t]) : a;
                let s,
                  o = -2;
                const c = [];
                for (const [e, t] of r
                  .map((e, t) => [e, t])
                  .sort(([e], [t]) => e - t))
                  if (-1 !== e)
                    if (e === o + 1) {
                      s.push(i[t]);
                      o += 1;
                    } else {
                      o = e;
                      s = [i[t]];
                      c.push(e, s);
                    }
                return c;
              })(e),
              a = new n.Dict(null);
            a.set("BaseFont", n.Name.get(e));
            a.set("Type", n.Name.get("Font"));
            a.set("Subtype", n.Name.get("CIDFontType2"));
            a.set("Encoding", n.Name.get("Identity-H"));
            a.set("CIDToGIDMap", n.Name.get("Identity"));
            a.set("W", t);
            a.set("FirstChar", t[0]);
            a.set("LastChar", t.at(-2) + t.at(-1).length - 1);
            const r = new n.Dict(null);
            a.set("FontDescriptor", r);
            const i = new n.Dict(null);
            i.set("Ordering", "Identity");
            i.set("Registry", "Adobe");
            i.set("Supplement", 0);
            a.set("CIDSystemInfo", i);
            return a;
          };
          t.getXfaFontName = getXfaFontName;
          var r = a(50),
            n = a(4),
            i = a(51),
            s = a(52),
            o = a(53),
            c = a(54),
            l = a(3),
            h = a(36);
          const u = (0, l.getLookupTableFactory)(function (e) {
            e["MyriadPro-Regular"] = e["PdfJS-Fallback-Regular"] = {
              name: "LiberationSans-Regular",
              factors: o.MyriadProRegularFactors,
              baseWidths: s.LiberationSansRegularWidths,
              baseMapping: s.LiberationSansRegularMapping,
              metrics: o.MyriadProRegularMetrics,
            };
            e["MyriadPro-Bold"] = e["PdfJS-Fallback-Bold"] = {
              name: "LiberationSans-Bold",
              factors: o.MyriadProBoldFactors,
              baseWidths: s.LiberationSansBoldWidths,
              baseMapping: s.LiberationSansBoldMapping,
              metrics: o.MyriadProBoldMetrics,
            };
            e["MyriadPro-It"] =
              e["MyriadPro-Italic"] =
              e["PdfJS-Fallback-Italic"] =
                {
                  name: "LiberationSans-Italic",
                  factors: o.MyriadProItalicFactors,
                  baseWidths: s.LiberationSansItalicWidths,
                  baseMapping: s.LiberationSansItalicMapping,
                  metrics: o.MyriadProItalicMetrics,
                };
            e["MyriadPro-BoldIt"] =
              e["MyriadPro-BoldItalic"] =
              e["PdfJS-Fallback-BoldItalic"] =
                {
                  name: "LiberationSans-BoldItalic",
                  factors: o.MyriadProBoldItalicFactors,
                  baseWidths: s.LiberationSansBoldItalicWidths,
                  baseMapping: s.LiberationSansBoldItalicMapping,
                  metrics: o.MyriadProBoldItalicMetrics,
                };
            e.ArialMT =
              e.Arial =
              e["Arial-Regular"] =
                {
                  name: "LiberationSans-Regular",
                  baseWidths: s.LiberationSansRegularWidths,
                  baseMapping: s.LiberationSansRegularMapping,
                };
            e["Arial-BoldMT"] = e["Arial-Bold"] = {
              name: "LiberationSans-Bold",
              baseWidths: s.LiberationSansBoldWidths,
              baseMapping: s.LiberationSansBoldMapping,
            };
            e["Arial-ItalicMT"] = e["Arial-Italic"] = {
              name: "LiberationSans-Italic",
              baseWidths: s.LiberationSansItalicWidths,
              baseMapping: s.LiberationSansItalicMapping,
            };
            e["Arial-BoldItalicMT"] = e["Arial-BoldItalic"] = {
              name: "LiberationSans-BoldItalic",
              baseWidths: s.LiberationSansBoldItalicWidths,
              baseMapping: s.LiberationSansBoldItalicMapping,
            };
            e["Calibri-Regular"] = {
              name: "LiberationSans-Regular",
              factors: r.CalibriRegularFactors,
              baseWidths: s.LiberationSansRegularWidths,
              baseMapping: s.LiberationSansRegularMapping,
              metrics: r.CalibriRegularMetrics,
            };
            e["Calibri-Bold"] = {
              name: "LiberationSans-Bold",
              factors: r.CalibriBoldFactors,
              baseWidths: s.LiberationSansBoldWidths,
              baseMapping: s.LiberationSansBoldMapping,
              metrics: r.CalibriBoldMetrics,
            };
            e["Calibri-Italic"] = {
              name: "LiberationSans-Italic",
              factors: r.CalibriItalicFactors,
              baseWidths: s.LiberationSansItalicWidths,
              baseMapping: s.LiberationSansItalicMapping,
              metrics: r.CalibriItalicMetrics,
            };
            e["Calibri-BoldItalic"] = {
              name: "LiberationSans-BoldItalic",
              factors: r.CalibriBoldItalicFactors,
              baseWidths: s.LiberationSansBoldItalicWidths,
              baseMapping: s.LiberationSansBoldItalicMapping,
              metrics: r.CalibriBoldItalicMetrics,
            };
            e["Segoeui-Regular"] = {
              name: "LiberationSans-Regular",
              factors: c.SegoeuiRegularFactors,
              baseWidths: s.LiberationSansRegularWidths,
              baseMapping: s.LiberationSansRegularMapping,
              metrics: c.SegoeuiRegularMetrics,
            };
            e["Segoeui-Bold"] = {
              name: "LiberationSans-Bold",
              factors: c.SegoeuiBoldFactors,
              baseWidths: s.LiberationSansBoldWidths,
              baseMapping: s.LiberationSansBoldMapping,
              metrics: c.SegoeuiBoldMetrics,
            };
            e["Segoeui-Italic"] = {
              name: "LiberationSans-Italic",
              factors: c.SegoeuiItalicFactors,
              baseWidths: s.LiberationSansItalicWidths,
              baseMapping: s.LiberationSansItalicMapping,
              metrics: c.SegoeuiItalicMetrics,
            };
            e["Segoeui-BoldItalic"] = {
              name: "LiberationSans-BoldItalic",
              factors: c.SegoeuiBoldItalicFactors,
              baseWidths: s.LiberationSansBoldItalicWidths,
              baseMapping: s.LiberationSansBoldItalicMapping,
              metrics: c.SegoeuiBoldItalicMetrics,
            };
            e["Helvetica-Regular"] = e.Helvetica = {
              name: "LiberationSans-Regular",
              factors: i.HelveticaRegularFactors,
              baseWidths: s.LiberationSansRegularWidths,
              baseMapping: s.LiberationSansRegularMapping,
              metrics: i.HelveticaRegularMetrics,
            };
            e["Helvetica-Bold"] = {
              name: "LiberationSans-Bold",
              factors: i.HelveticaBoldFactors,
              baseWidths: s.LiberationSansBoldWidths,
              baseMapping: s.LiberationSansBoldMapping,
              metrics: i.HelveticaBoldMetrics,
            };
            e["Helvetica-Italic"] = {
              name: "LiberationSans-Italic",
              factors: i.HelveticaItalicFactors,
              baseWidths: s.LiberationSansItalicWidths,
              baseMapping: s.LiberationSansItalicMapping,
              metrics: i.HelveticaItalicMetrics,
            };
            e["Helvetica-BoldItalic"] = {
              name: "LiberationSans-BoldItalic",
              factors: i.HelveticaBoldItalicFactors,
              baseWidths: s.LiberationSansBoldItalicWidths,
              baseMapping: s.LiberationSansBoldItalicMapping,
              metrics: i.HelveticaBoldItalicMetrics,
            };
          });
          function getXfaFontName(e) {
            const t = (0, h.normalizeFontName)(e);
            return u()[t];
          }
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.CalibriRegularMetrics =
            t.CalibriRegularFactors =
            t.CalibriItalicMetrics =
            t.CalibriItalicFactors =
            t.CalibriBoldMetrics =
            t.CalibriBoldItalicMetrics =
            t.CalibriBoldItalicFactors =
            t.CalibriBoldFactors =
              void 0;
          t.CalibriBoldFactors = [
            1.3877, 1, 1, 1, 0.97801, 0.92482, 0.89552, 0.91133, 0.81988,
            0.97566, 0.98152, 0.93548, 0.93548, 1.2798, 0.85284, 0.92794, 1,
            0.96134, 1.54657, 0.91133, 0.91133, 0.91133, 0.91133, 0.91133,
            0.91133, 0.91133, 0.91133, 0.91133, 0.91133, 0.82845, 0.82845,
            0.85284, 0.85284, 0.85284, 0.75859, 0.92138, 0.83908, 0.7762,
            0.73293, 0.87289, 0.73133, 0.7514, 0.81921, 0.87356, 0.95958,
            0.59526, 0.75727, 0.69225, 1.04924, 0.9121, 0.86943, 0.79795,
            0.88198, 0.77958, 0.70864, 0.81055, 0.90399, 0.88653, 0.96017,
            0.82577, 0.77892, 0.78257, 0.97507, 1.54657, 0.97507, 0.85284,
            0.89552, 0.90176, 0.88762, 0.8785, 0.75241, 0.8785, 0.90518,
            0.95015, 0.77618, 0.8785, 0.88401, 0.91916, 0.86304, 0.88401,
            0.91488, 0.8785, 0.8801, 0.8785, 0.8785, 0.91343, 0.7173, 1.04106,
            0.8785, 0.85075, 0.95794, 0.82616, 0.85162, 0.79492, 0.88331,
            1.69808, 0.88331, 0.85284, 0.97801, 0.89552, 0.91133, 0.89552,
            0.91133, 1.7801, 0.89552, 1.24487, 1.13254, 1.12401, 0.96839,
            0.85284, 0.68787, 0.70645, 0.85592, 0.90747, 1.01466, 1.0088,
            0.90323, 1, 1.07463, 1, 0.91056, 0.75806, 1.19118, 0.96839, 0.78864,
            0.82845, 0.84133, 0.75859, 0.83908, 0.83908, 0.83908, 0.83908,
            0.83908, 0.83908, 0.77539, 0.73293, 0.73133, 0.73133, 0.73133,
            0.73133, 0.95958, 0.95958, 0.95958, 0.95958, 0.88506, 0.9121,
            0.86943, 0.86943, 0.86943, 0.86943, 0.86943, 0.85284, 0.87508,
            0.90399, 0.90399, 0.90399, 0.90399, 0.77892, 0.79795, 0.90807,
            0.88762, 0.88762, 0.88762, 0.88762, 0.88762, 0.88762, 0.8715,
            0.75241, 0.90518, 0.90518, 0.90518, 0.90518, 0.88401, 0.88401,
            0.88401, 0.88401, 0.8785, 0.8785, 0.8801, 0.8801, 0.8801, 0.8801,
            0.8801, 0.90747, 0.89049, 0.8785, 0.8785, 0.8785, 0.8785, 0.85162,
            0.8785, 0.85162, 0.83908, 0.88762, 0.83908, 0.88762, 0.83908,
            0.88762, 0.73293, 0.75241, 0.73293, 0.75241, 0.73293, 0.75241,
            0.73293, 0.75241, 0.87289, 0.83016, 0.88506, 0.93125, 0.73133,
            0.90518, 0.73133, 0.90518, 0.73133, 0.90518, 0.73133, 0.90518,
            0.73133, 0.90518, 0.81921, 0.77618, 0.81921, 0.77618, 0.81921,
            0.77618, 1, 1, 0.87356, 0.8785, 0.91075, 0.89608, 0.95958, 0.88401,
            0.95958, 0.88401, 0.95958, 0.88401, 0.95958, 0.88401, 0.95958,
            0.88401, 0.76229, 0.90167, 0.59526, 0.91916, 1, 1, 0.86304, 0.69225,
            0.88401, 1, 1, 0.70424, 0.79468, 0.91926, 0.88175, 0.70823, 0.94903,
            0.9121, 0.8785, 1, 1, 0.9121, 0.8785, 0.87802, 0.88656, 0.8785,
            0.86943, 0.8801, 0.86943, 0.8801, 0.86943, 0.8801, 0.87402, 0.89291,
            0.77958, 0.91343, 1, 1, 0.77958, 0.91343, 0.70864, 0.7173, 0.70864,
            0.7173, 0.70864, 0.7173, 0.70864, 0.7173, 1, 1, 0.81055, 0.75841,
            0.81055, 1.06452, 0.90399, 0.8785, 0.90399, 0.8785, 0.90399, 0.8785,
            0.90399, 0.8785, 0.90399, 0.8785, 0.90399, 0.8785, 0.96017, 0.95794,
            0.77892, 0.85162, 0.77892, 0.78257, 0.79492, 0.78257, 0.79492,
            0.78257, 0.79492, 0.9297, 0.56892, 0.83908, 0.88762, 0.77539,
            0.8715, 0.87508, 0.89049, 1, 1, 0.81055, 1.04106, 1.20528, 1.20528,
            1, 1.15543, 0.70674, 0.98387, 0.94721, 1.33431, 1.45894, 0.95161,
            1.06303, 0.83908, 0.80352, 0.57184, 0.6965, 0.56289, 0.82001,
            0.56029, 0.81235, 1.02988, 0.83908, 0.7762, 0.68156, 0.80367,
            0.73133, 0.78257, 0.87356, 0.86943, 0.95958, 0.75727, 0.89019,
            1.04924, 0.9121, 0.7648, 0.86943, 0.87356, 0.79795, 0.78275,
            0.81055, 0.77892, 0.9762, 0.82577, 0.99819, 0.84896, 0.95958,
            0.77892, 0.96108, 1.01407, 0.89049, 1.02988, 0.94211, 0.96108,
            0.8936, 0.84021, 0.87842, 0.96399, 0.79109, 0.89049, 1.00813,
            1.02988, 0.86077, 0.87445, 0.92099, 0.84723, 0.86513, 0.8801,
            0.75638, 0.85714, 0.78216, 0.79586, 0.87965, 0.94211, 0.97747,
            0.78287, 0.97926, 0.84971, 1.02988, 0.94211, 0.8801, 0.94211,
            0.84971, 0.73133, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90264, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90518, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90548, 1, 1, 1, 1, 1, 1, 0.96017,
            0.95794, 0.96017, 0.95794, 0.96017, 0.95794, 0.77892, 0.85162, 1, 1,
            0.89552, 0.90527, 1, 0.90363, 0.92794, 0.92794, 0.92794, 0.92794,
            0.87012, 0.87012, 0.87012, 0.89552, 0.89552, 1.42259, 0.71143,
            1.06152, 1, 1, 1.03372, 1.03372, 0.97171, 1.4956, 2.2807, 0.93835,
            0.83406, 0.91133, 0.84107, 0.91133, 1, 1, 1, 0.72021, 1, 1.23108,
            0.83489, 0.88525, 0.88525, 0.81499, 0.90527, 1.81055, 0.90527,
            1.81055, 1.31006, 1.53711, 0.94434, 1.08696, 1, 0.95018, 0.77192,
            0.85284, 0.90747, 1.17534, 0.69825, 0.9716, 1.37077, 0.90747,
            0.90747, 0.85356, 0.90747, 0.90747, 1.44947, 0.85284, 0.8941,
            0.8941, 0.70572, 0.8, 0.70572, 0.70572, 0.70572, 0.70572, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.99862,
            0.99862, 1, 1, 1, 1, 1, 1.08004, 0.91027, 1, 1, 1, 0.99862, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90727, 0.90727, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.CalibriBoldMetrics = { lineHeight: 1.2207, lineGap: 0.2207 };
          t.CalibriBoldItalicFactors = [
            1.3877, 1, 1, 1, 0.97801, 0.92482, 0.89552, 0.91133, 0.81988,
            0.97566, 0.98152, 0.93548, 0.93548, 1.2798, 0.85284, 0.92794, 1,
            0.96134, 1.56239, 0.91133, 0.91133, 0.91133, 0.91133, 0.91133,
            0.91133, 0.91133, 0.91133, 0.91133, 0.91133, 0.82845, 0.82845,
            0.85284, 0.85284, 0.85284, 0.75859, 0.92138, 0.83908, 0.7762,
            0.71805, 0.87289, 0.73133, 0.7514, 0.81921, 0.87356, 0.95958,
            0.59526, 0.75727, 0.69225, 1.04924, 0.90872, 0.85938, 0.79795,
            0.87068, 0.77958, 0.69766, 0.81055, 0.90399, 0.88653, 0.96068,
            0.82577, 0.77892, 0.78257, 0.97507, 1.529, 0.97507, 0.85284,
            0.89552, 0.90176, 0.94908, 0.86411, 0.74012, 0.86411, 0.88323,
            0.95015, 0.86411, 0.86331, 0.88401, 0.91916, 0.86304, 0.88401,
            0.9039, 0.86331, 0.86331, 0.86411, 0.86411, 0.90464, 0.70852,
            1.04106, 0.86331, 0.84372, 0.95794, 0.82616, 0.84548, 0.79492,
            0.88331, 1.69808, 0.88331, 0.85284, 0.97801, 0.89552, 0.91133,
            0.89552, 0.91133, 1.7801, 0.89552, 1.24487, 1.13254, 1.19129,
            0.96839, 0.85284, 0.68787, 0.70645, 0.85592, 0.90747, 1.01466,
            1.0088, 0.90323, 1, 1.07463, 1, 0.91056, 0.75806, 1.19118, 0.96839,
            0.78864, 0.82845, 0.84133, 0.75859, 0.83908, 0.83908, 0.83908,
            0.83908, 0.83908, 0.83908, 0.77539, 0.71805, 0.73133, 0.73133,
            0.73133, 0.73133, 0.95958, 0.95958, 0.95958, 0.95958, 0.88506,
            0.90872, 0.85938, 0.85938, 0.85938, 0.85938, 0.85938, 0.85284,
            0.87068, 0.90399, 0.90399, 0.90399, 0.90399, 0.77892, 0.79795,
            0.90807, 0.94908, 0.94908, 0.94908, 0.94908, 0.94908, 0.94908,
            0.85887, 0.74012, 0.88323, 0.88323, 0.88323, 0.88323, 0.88401,
            0.88401, 0.88401, 0.88401, 0.8785, 0.86331, 0.86331, 0.86331,
            0.86331, 0.86331, 0.86331, 0.90747, 0.89049, 0.86331, 0.86331,
            0.86331, 0.86331, 0.84548, 0.86411, 0.84548, 0.83908, 0.94908,
            0.83908, 0.94908, 0.83908, 0.94908, 0.71805, 0.74012, 0.71805,
            0.74012, 0.71805, 0.74012, 0.71805, 0.74012, 0.87289, 0.79538,
            0.88506, 0.92726, 0.73133, 0.88323, 0.73133, 0.88323, 0.73133,
            0.88323, 0.73133, 0.88323, 0.73133, 0.88323, 0.81921, 0.86411,
            0.81921, 0.86411, 0.81921, 0.86411, 1, 1, 0.87356, 0.86331, 0.91075,
            0.8777, 0.95958, 0.88401, 0.95958, 0.88401, 0.95958, 0.88401,
            0.95958, 0.88401, 0.95958, 0.88401, 0.76467, 0.90167, 0.59526,
            0.91916, 1, 1, 0.86304, 0.69225, 0.88401, 1, 1, 0.70424, 0.77312,
            0.91926, 0.88175, 0.70823, 0.94903, 0.90872, 0.86331, 1, 1, 0.90872,
            0.86331, 0.86906, 0.88116, 0.86331, 0.85938, 0.86331, 0.85938,
            0.86331, 0.85938, 0.86331, 0.87402, 0.86549, 0.77958, 0.90464, 1, 1,
            0.77958, 0.90464, 0.69766, 0.70852, 0.69766, 0.70852, 0.69766,
            0.70852, 0.69766, 0.70852, 1, 1, 0.81055, 0.75841, 0.81055, 1.06452,
            0.90399, 0.86331, 0.90399, 0.86331, 0.90399, 0.86331, 0.90399,
            0.86331, 0.90399, 0.86331, 0.90399, 0.86331, 0.96068, 0.95794,
            0.77892, 0.84548, 0.77892, 0.78257, 0.79492, 0.78257, 0.79492,
            0.78257, 0.79492, 0.9297, 0.56892, 0.83908, 0.94908, 0.77539,
            0.85887, 0.87068, 0.89049, 1, 1, 0.81055, 1.04106, 1.20528, 1.20528,
            1, 1.15543, 0.70088, 0.98387, 0.94721, 1.33431, 1.45894, 0.95161,
            1.48387, 0.83908, 0.80352, 0.57118, 0.6965, 0.56347, 0.79179,
            0.55853, 0.80346, 1.02988, 0.83908, 0.7762, 0.67174, 0.86036,
            0.73133, 0.78257, 0.87356, 0.86441, 0.95958, 0.75727, 0.89019,
            1.04924, 0.90872, 0.74889, 0.85938, 0.87891, 0.79795, 0.7957,
            0.81055, 0.77892, 0.97447, 0.82577, 0.97466, 0.87179, 0.95958,
            0.77892, 0.94252, 0.95612, 0.8753, 1.02988, 0.92733, 0.94252,
            0.87411, 0.84021, 0.8728, 0.95612, 0.74081, 0.8753, 1.02189,
            1.02988, 0.84814, 0.87445, 0.91822, 0.84723, 0.85668, 0.86331,
            0.81344, 0.87581, 0.76422, 0.82046, 0.96057, 0.92733, 0.99375,
            0.78022, 0.95452, 0.86015, 1.02988, 0.92733, 0.86331, 0.92733,
            0.86015, 0.73133, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90631, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.88323, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.85174, 1, 1, 1, 1, 1, 1, 0.96068,
            0.95794, 0.96068, 0.95794, 0.96068, 0.95794, 0.77892, 0.84548, 1, 1,
            0.89552, 0.90527, 1, 0.90363, 0.92794, 0.92794, 0.92794, 0.89807,
            0.87012, 0.87012, 0.87012, 0.89552, 0.89552, 1.42259, 0.71094,
            1.06152, 1, 1, 1.03372, 1.03372, 0.97171, 1.4956, 2.2807, 0.92972,
            0.83406, 0.91133, 0.83326, 0.91133, 1, 1, 1, 0.72021, 1, 1.23108,
            0.83489, 0.88525, 0.88525, 0.81499, 0.90616, 1.81055, 0.90527,
            1.81055, 1.3107, 1.53711, 0.94434, 1.08696, 1, 0.95018, 0.77192,
            0.85284, 0.90747, 1.17534, 0.69825, 0.9716, 1.37077, 0.90747,
            0.90747, 0.85356, 0.90747, 0.90747, 1.44947, 0.85284, 0.8941,
            0.8941, 0.70572, 0.8, 0.70572, 0.70572, 0.70572, 0.70572, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.99862,
            0.99862, 1, 1, 1, 1, 1, 1.08004, 0.91027, 1, 1, 1, 0.99862, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90727, 0.90727, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.CalibriBoldItalicMetrics = { lineHeight: 1.2207, lineGap: 0.2207 };
          t.CalibriItalicFactors = [
            1.3877, 1, 1, 1, 1.17223, 1.1293, 0.89552, 0.91133, 0.80395,
            1.02269, 1.15601, 0.91056, 0.91056, 1.2798, 0.85284, 0.89807, 1,
            0.90861, 1.39543, 0.91133, 0.91133, 0.91133, 0.91133, 0.91133,
            0.91133, 0.91133, 0.91133, 0.91133, 0.91133, 0.96309, 0.96309,
            0.85284, 0.85284, 0.85284, 0.83319, 0.88071, 0.8675, 0.81552,
            0.72346, 0.85193, 0.73206, 0.7522, 0.81105, 0.86275, 0.90685,
            0.6377, 0.77892, 0.75593, 1.02638, 0.89249, 0.84118, 0.77452,
            0.85374, 0.75186, 0.67789, 0.79776, 0.88844, 0.85066, 0.94309,
            0.77818, 0.7306, 0.76659, 1.10369, 1.38313, 1.10369, 1.06139,
            0.89552, 0.8739, 0.9245, 0.9245, 0.83203, 0.9245, 0.85865, 1.09842,
            0.9245, 0.9245, 1.03297, 1.07692, 0.90918, 1.03297, 0.94959, 0.9245,
            0.92274, 0.9245, 0.9245, 1.02933, 0.77832, 1.20562, 0.9245, 0.8916,
            0.98986, 0.86621, 0.89453, 0.79004, 0.94152, 1.77256, 0.94152,
            0.85284, 0.97801, 0.89552, 0.91133, 0.89552, 0.91133, 1.91729,
            0.89552, 1.17889, 1.13254, 1.16359, 0.92098, 0.85284, 0.68787,
            0.71353, 0.84737, 0.90747, 1.0088, 1.0044, 0.87683, 1, 1.09091, 1,
            0.92229, 0.739, 1.15642, 0.92098, 0.76288, 0.80504, 0.80972,
            0.75859, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675, 0.8675, 0.76318,
            0.72346, 0.73206, 0.73206, 0.73206, 0.73206, 0.90685, 0.90685,
            0.90685, 0.90685, 0.86477, 0.89249, 0.84118, 0.84118, 0.84118,
            0.84118, 0.84118, 0.85284, 0.84557, 0.88844, 0.88844, 0.88844,
            0.88844, 0.7306, 0.77452, 0.86331, 0.9245, 0.9245, 0.9245, 0.9245,
            0.9245, 0.9245, 0.84843, 0.83203, 0.85865, 0.85865, 0.85865,
            0.85865, 0.82601, 0.82601, 0.82601, 0.82601, 0.94469, 0.9245,
            0.92274, 0.92274, 0.92274, 0.92274, 0.92274, 0.90747, 0.86651,
            0.9245, 0.9245, 0.9245, 0.9245, 0.89453, 0.9245, 0.89453, 0.8675,
            0.9245, 0.8675, 0.9245, 0.8675, 0.9245, 0.72346, 0.83203, 0.72346,
            0.83203, 0.72346, 0.83203, 0.72346, 0.83203, 0.85193, 0.8875,
            0.86477, 0.99034, 0.73206, 0.85865, 0.73206, 0.85865, 0.73206,
            0.85865, 0.73206, 0.85865, 0.73206, 0.85865, 0.81105, 0.9245,
            0.81105, 0.9245, 0.81105, 0.9245, 1, 1, 0.86275, 0.9245, 0.90872,
            0.93591, 0.90685, 0.82601, 0.90685, 0.82601, 0.90685, 0.82601,
            0.90685, 1.03297, 0.90685, 0.82601, 0.77896, 1.05611, 0.6377,
            1.07692, 1, 1, 0.90918, 0.75593, 1.03297, 1, 1, 0.76032, 0.9375,
            0.98156, 0.93407, 0.77261, 1.11429, 0.89249, 0.9245, 1, 1, 0.89249,
            0.9245, 0.92534, 0.86698, 0.9245, 0.84118, 0.92274, 0.84118,
            0.92274, 0.84118, 0.92274, 0.8667, 0.86291, 0.75186, 1.02933, 1, 1,
            0.75186, 1.02933, 0.67789, 0.77832, 0.67789, 0.77832, 0.67789,
            0.77832, 0.67789, 0.77832, 1, 1, 0.79776, 0.97655, 0.79776, 1.23023,
            0.88844, 0.9245, 0.88844, 0.9245, 0.88844, 0.9245, 0.88844, 0.9245,
            0.88844, 0.9245, 0.88844, 0.9245, 0.94309, 0.98986, 0.7306, 0.89453,
            0.7306, 0.76659, 0.79004, 0.76659, 0.79004, 0.76659, 0.79004,
            1.09231, 0.54873, 0.8675, 0.9245, 0.76318, 0.84843, 0.84557,
            0.86651, 1, 1, 0.79776, 1.20562, 1.18622, 1.18622, 1, 1.1437,
            0.67009, 0.96334, 0.93695, 1.35191, 1.40909, 0.95161, 1.48387,
            0.8675, 0.90861, 0.6192, 0.7363, 0.64824, 0.82411, 0.56321, 0.85696,
            1.23516, 0.8675, 0.81552, 0.7286, 0.84134, 0.73206, 0.76659,
            0.86275, 0.84369, 0.90685, 0.77892, 0.85871, 1.02638, 0.89249,
            0.75828, 0.84118, 0.85984, 0.77452, 0.76466, 0.79776, 0.7306,
            0.90782, 0.77818, 0.903, 0.87291, 0.90685, 0.7306, 0.99058, 1.03667,
            0.94635, 1.23516, 0.9849, 0.99058, 0.92393, 0.8916, 0.942, 1.03667,
            0.75026, 0.94635, 1.0297, 1.23516, 0.90918, 0.94048, 0.98217,
            0.89746, 0.84153, 0.92274, 0.82507, 0.88832, 0.84438, 0.88178,
            1.03525, 0.9849, 1.00225, 0.78086, 0.97248, 0.89404, 1.23516,
            0.9849, 0.92274, 0.9849, 0.89404, 0.73206, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 0.89693, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 0.85865, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.90933, 1,
            1, 1, 1, 1, 1, 0.94309, 0.98986, 0.94309, 0.98986, 0.94309, 0.98986,
            0.7306, 0.89453, 1, 1, 0.89552, 0.90527, 1, 0.90186, 1.12308,
            1.12308, 1.12308, 1.12308, 1.2566, 1.2566, 1.2566, 0.89552, 0.89552,
            1.42259, 0.68994, 1.03809, 1, 1, 1.0176, 1.0176, 1.11523, 1.4956,
            2.01462, 0.97858, 0.82616, 0.91133, 0.83437, 0.91133, 1, 1, 1,
            0.70508, 1, 1.23108, 0.79801, 0.84426, 0.84426, 0.774, 0.90572,
            1.81055, 0.90749, 1.81055, 1.28809, 1.55469, 0.94434, 1.07806, 1,
            0.97094, 0.7589, 0.85284, 0.90747, 1.19658, 0.69825, 0.97622,
            1.33512, 0.90747, 0.90747, 0.85284, 0.90747, 0.90747, 1.44947,
            0.85284, 0.8941, 0.8941, 0.70572, 0.8, 0.70572, 0.70572, 0.70572,
            0.70572, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 0.99862, 0.99862, 1, 1, 1, 1, 1, 1.0336, 0.91027, 1, 1, 1,
            0.99862, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.05859, 1.05859, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.CalibriItalicMetrics = { lineHeight: 1.2207, lineGap: 0.2207 };
          t.CalibriRegularFactors = [
            1.3877, 1, 1, 1, 1.17223, 1.1293, 0.89552, 0.91133, 0.80395,
            1.02269, 1.15601, 0.91056, 0.91056, 1.2798, 0.85284, 0.89807, 1,
            0.90861, 1.39016, 0.91133, 0.91133, 0.91133, 0.91133, 0.91133,
            0.91133, 0.91133, 0.91133, 0.91133, 0.91133, 0.96309, 0.96309,
            0.85284, 0.85284, 0.85284, 0.83319, 0.88071, 0.8675, 0.81552,
            0.73834, 0.85193, 0.73206, 0.7522, 0.81105, 0.86275, 0.90685,
            0.6377, 0.77892, 0.75593, 1.02638, 0.89385, 0.85122, 0.77452,
            0.86503, 0.75186, 0.68887, 0.79776, 0.88844, 0.85066, 0.94258,
            0.77818, 0.7306, 0.76659, 1.10369, 1.39016, 1.10369, 1.06139,
            0.89552, 0.8739, 0.86128, 0.94469, 0.8457, 0.94469, 0.89464,
            1.09842, 0.84636, 0.94469, 1.03297, 1.07692, 0.90918, 1.03297,
            0.95897, 0.94469, 0.9482, 0.94469, 0.94469, 1.04692, 0.78223,
            1.20562, 0.94469, 0.90332, 0.98986, 0.86621, 0.90527, 0.79004,
            0.94152, 1.77256, 0.94152, 0.85284, 0.97801, 0.89552, 0.91133,
            0.89552, 0.91133, 1.91729, 0.89552, 1.17889, 1.13254, 1.08707,
            0.92098, 0.85284, 0.68787, 0.71353, 0.84737, 0.90747, 1.0088,
            1.0044, 0.87683, 1, 1.09091, 1, 0.92229, 0.739, 1.15642, 0.92098,
            0.76288, 0.80504, 0.80972, 0.75859, 0.8675, 0.8675, 0.8675, 0.8675,
            0.8675, 0.8675, 0.76318, 0.73834, 0.73206, 0.73206, 0.73206,
            0.73206, 0.90685, 0.90685, 0.90685, 0.90685, 0.86477, 0.89385,
            0.85122, 0.85122, 0.85122, 0.85122, 0.85122, 0.85284, 0.85311,
            0.88844, 0.88844, 0.88844, 0.88844, 0.7306, 0.77452, 0.86331,
            0.86128, 0.86128, 0.86128, 0.86128, 0.86128, 0.86128, 0.8693,
            0.8457, 0.89464, 0.89464, 0.89464, 0.89464, 0.82601, 0.82601,
            0.82601, 0.82601, 0.94469, 0.94469, 0.9482, 0.9482, 0.9482, 0.9482,
            0.9482, 0.90747, 0.86651, 0.94469, 0.94469, 0.94469, 0.94469,
            0.90527, 0.94469, 0.90527, 0.8675, 0.86128, 0.8675, 0.86128, 0.8675,
            0.86128, 0.73834, 0.8457, 0.73834, 0.8457, 0.73834, 0.8457, 0.73834,
            0.8457, 0.85193, 0.92454, 0.86477, 0.9921, 0.73206, 0.89464,
            0.73206, 0.89464, 0.73206, 0.89464, 0.73206, 0.89464, 0.73206,
            0.89464, 0.81105, 0.84636, 0.81105, 0.84636, 0.81105, 0.84636, 1, 1,
            0.86275, 0.94469, 0.90872, 0.95786, 0.90685, 0.82601, 0.90685,
            0.82601, 0.90685, 0.82601, 0.90685, 1.03297, 0.90685, 0.82601,
            0.77741, 1.05611, 0.6377, 1.07692, 1, 1, 0.90918, 0.75593, 1.03297,
            1, 1, 0.76032, 0.90452, 0.98156, 1.11842, 0.77261, 1.11429, 0.89385,
            0.94469, 1, 1, 0.89385, 0.94469, 0.95877, 0.86901, 0.94469, 0.85122,
            0.9482, 0.85122, 0.9482, 0.85122, 0.9482, 0.8667, 0.90016, 0.75186,
            1.04692, 1, 1, 0.75186, 1.04692, 0.68887, 0.78223, 0.68887, 0.78223,
            0.68887, 0.78223, 0.68887, 0.78223, 1, 1, 0.79776, 0.92188, 0.79776,
            1.23023, 0.88844, 0.94469, 0.88844, 0.94469, 0.88844, 0.94469,
            0.88844, 0.94469, 0.88844, 0.94469, 0.88844, 0.94469, 0.94258,
            0.98986, 0.7306, 0.90527, 0.7306, 0.76659, 0.79004, 0.76659,
            0.79004, 0.76659, 0.79004, 1.09231, 0.54873, 0.8675, 0.86128,
            0.76318, 0.8693, 0.85311, 0.86651, 1, 1, 0.79776, 1.20562, 1.18622,
            1.18622, 1, 1.1437, 0.67742, 0.96334, 0.93695, 1.35191, 1.40909,
            0.95161, 1.48387, 0.86686, 0.90861, 0.62267, 0.74359, 0.65649,
            0.85498, 0.56963, 0.88254, 1.23516, 0.8675, 0.81552, 0.75443,
            0.84503, 0.73206, 0.76659, 0.86275, 0.85122, 0.90685, 0.77892,
            0.85746, 1.02638, 0.89385, 0.75657, 0.85122, 0.86275, 0.77452,
            0.74171, 0.79776, 0.7306, 0.95165, 0.77818, 0.89772, 0.88831,
            0.90685, 0.7306, 0.98142, 1.02191, 0.96576, 1.23516, 0.99018,
            0.98142, 0.9236, 0.89258, 0.94035, 1.02191, 0.78848, 0.96576,
            0.9561, 1.23516, 0.90918, 0.92578, 0.95424, 0.89746, 0.83969,
            0.9482, 0.80113, 0.89442, 0.85208, 0.86155, 0.98022, 0.99018,
            1.00452, 0.81209, 0.99247, 0.89181, 1.23516, 0.99018, 0.9482,
            0.99018, 0.89181, 0.73206, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            0.88844, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            0.89464, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.96766, 1, 1, 1, 1, 1,
            1, 0.94258, 0.98986, 0.94258, 0.98986, 0.94258, 0.98986, 0.7306,
            0.90527, 1, 1, 0.89552, 0.90527, 1, 0.90186, 1.12308, 1.12308,
            1.12308, 1.12308, 1.2566, 1.2566, 1.2566, 0.89552, 0.89552, 1.42259,
            0.69043, 1.03809, 1, 1, 1.0176, 1.0176, 1.11523, 1.4956, 2.01462,
            0.99331, 0.82616, 0.91133, 0.84286, 0.91133, 1, 1, 1, 0.70508, 1,
            1.23108, 0.79801, 0.84426, 0.84426, 0.774, 0.90527, 1.81055,
            0.90527, 1.81055, 1.28809, 1.55469, 0.94434, 1.07806, 1, 0.97094,
            0.7589, 0.85284, 0.90747, 1.19658, 0.69825, 0.97622, 1.33512,
            0.90747, 0.90747, 0.85356, 0.90747, 0.90747, 1.44947, 0.85284,
            0.8941, 0.8941, 0.70572, 0.8, 0.70572, 0.70572, 0.70572, 0.70572, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.99862,
            0.99862, 1, 1, 1, 1, 1, 1.0336, 0.91027, 1, 1, 1, 0.99862, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1.05859, 1.05859, 1, 1, 1, 1.07185,
            0.99413, 0.96334, 1.08065, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.CalibriRegularMetrics = { lineHeight: 1.2207, lineGap: 0.2207 };
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.HelveticaRegularMetrics =
            t.HelveticaRegularFactors =
            t.HelveticaItalicMetrics =
            t.HelveticaItalicFactors =
            t.HelveticaBoldMetrics =
            t.HelveticaBoldItalicMetrics =
            t.HelveticaBoldItalicFactors =
            t.HelveticaBoldFactors =
              void 0;
          t.HelveticaBoldFactors = [
            0.76116, 1, 1, 1.0006, 0.99998, 0.99974, 0.99973, 0.99973, 0.99982,
            0.99977, 1.00087, 0.99998, 0.99998, 0.99959, 1.00003, 1.0006,
            0.99998, 1.0006, 1.0006, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99998, 1,
            1.00003, 1.00003, 1.00003, 1.00026, 0.9999, 0.99977, 0.99977,
            0.99977, 0.99977, 1.00001, 1.00026, 1.00022, 0.99977, 1.0006,
            0.99973, 0.99977, 1.00026, 0.99999, 0.99977, 1.00022, 1.00001,
            1.00022, 0.99977, 1.00001, 1.00026, 0.99977, 1.00001, 1.00016,
            1.00001, 1.00001, 1.00026, 0.99998, 1.0006, 0.99998, 1.00003,
            0.99973, 0.99998, 0.99973, 1.00026, 0.99973, 1.00026, 0.99973,
            0.99998, 1.00026, 1.00026, 1.0006, 1.0006, 0.99973, 1.0006, 0.99982,
            1.00026, 1.00026, 1.00026, 1.00026, 0.99959, 0.99973, 0.99998,
            1.00026, 0.99973, 1.00022, 0.99973, 0.99973, 1, 0.99959, 1.00077,
            0.99959, 1.00003, 0.99998, 0.99973, 0.99973, 0.99973, 0.99973,
            1.00077, 0.99973, 0.99998, 1.00025, 0.99968, 0.99973, 1.00003,
            1.00025, 0.60299, 1.00024, 1.06409, 1, 1, 0.99998, 1, 0.99973,
            1.0006, 0.99998, 1, 0.99936, 0.99973, 1.00002, 1.00002, 1.00002,
            1.00026, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1,
            0.99977, 1.00001, 1.00001, 1.00001, 1.00001, 1.0006, 1.0006, 1.0006,
            1.0006, 0.99977, 0.99977, 1.00022, 1.00022, 1.00022, 1.00022,
            1.00022, 1.00003, 1.00022, 0.99977, 0.99977, 0.99977, 0.99977,
            1.00001, 1.00001, 1.00026, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99982, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 1.0006, 1.0006, 1.0006, 1.0006, 1.00026, 1.00026, 1.00026,
            1.00026, 1.00026, 1.00026, 1.00026, 1.06409, 1.00026, 1.00026,
            1.00026, 1.00026, 1.00026, 0.99973, 1.00026, 0.99973, 0.99977,
            0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973,
            0.99977, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977,
            1.03374, 0.99977, 1.00026, 1.00001, 0.99973, 1.00001, 0.99973,
            1.00001, 0.99973, 1.00001, 0.99973, 1.00001, 0.99973, 1.00022,
            1.00026, 1.00022, 1.00026, 1.00022, 1.00026, 1.00022, 1.00026,
            0.99977, 1.00026, 0.99977, 1.00026, 1.0006, 1.0006, 1.0006, 1.0006,
            1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.00042, 0.99973,
            0.99973, 1.0006, 0.99977, 0.99973, 0.99973, 1.00026, 1.0006,
            1.00026, 1.0006, 1.00026, 1.03828, 1.00026, 0.99999, 1.00026,
            1.0006, 0.99977, 1.00026, 0.99977, 1.00026, 0.99977, 1.00026,
            0.9993, 0.9998, 1.00026, 1.00022, 1.00026, 1.00022, 1.00026,
            1.00022, 1.00026, 1, 1.00016, 0.99977, 0.99959, 0.99977, 0.99959,
            0.99977, 0.99959, 1.00001, 0.99973, 1.00001, 0.99973, 1.00001,
            0.99973, 1.00001, 0.99973, 1.00026, 0.99998, 1.00026, 0.8121,
            1.00026, 0.99998, 0.99977, 1.00026, 0.99977, 1.00026, 0.99977,
            1.00026, 0.99977, 1.00026, 0.99977, 1.00026, 0.99977, 1.00026,
            1.00016, 1.00022, 1.00001, 0.99973, 1.00001, 1.00026, 1, 1.00026, 1,
            1.00026, 1, 1.0006, 0.99973, 0.99977, 0.99973, 1, 0.99982, 1.00022,
            1.00026, 1.00001, 0.99973, 1.00026, 0.99998, 0.99998, 0.99998,
            0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998,
            0.99998, 1.00034, 0.99977, 1, 0.99997, 1.00026, 1.00078, 1.00036,
            0.99973, 1.00013, 1.0006, 0.99977, 0.99977, 0.99988, 0.85148,
            1.00001, 1.00026, 0.99977, 1.00022, 1.0006, 0.99977, 1.00001,
            0.99999, 0.99977, 1.00069, 1.00022, 0.99977, 1.00001, 0.99984,
            1.00026, 1.00001, 1.00024, 1.00001, 0.9999, 1, 1.0006, 1.00001,
            1.00041, 0.99962, 1.00026, 1.0006, 0.99995, 1.00041, 0.99942,
            0.99973, 0.99927, 1.00082, 0.99902, 1.00026, 1.00087, 1.0006,
            1.00069, 0.99973, 0.99867, 0.99973, 0.9993, 1.00026, 1.00049,
            1.00056, 1, 0.99988, 0.99935, 0.99995, 0.99954, 1.00055, 0.99945,
            1.00032, 1.0006, 0.99995, 1.00026, 0.99995, 1.00032, 1.00001,
            1.00008, 0.99971, 1.00019, 0.9994, 1.00001, 1.0006, 1.00044,
            0.99973, 1.00023, 1.00047, 1, 0.99942, 0.99561, 0.99989, 1.00035,
            0.99977, 1.00035, 0.99977, 1.00019, 0.99944, 1.00001, 1.00021,
            0.99926, 1.00035, 1.00035, 0.99942, 1.00048, 0.99999, 0.99977,
            1.00022, 1.00035, 1.00001, 0.99977, 1.00026, 0.99989, 1.00057,
            1.00001, 0.99936, 1.00052, 1.00012, 0.99996, 1.00043, 1, 1.00035,
            0.9994, 0.99976, 1.00035, 0.99973, 1.00052, 1.00041, 1.00119,
            1.00037, 0.99973, 1.00002, 0.99986, 1.00041, 1.00041, 0.99902,
            0.9996, 1.00034, 0.99999, 1.00026, 0.99999, 1.00026, 0.99973,
            1.00052, 0.99973, 1, 0.99973, 1.00041, 1.00075, 0.9994, 1.0003,
            0.99999, 1, 1.00041, 0.99955, 1, 0.99915, 0.99973, 0.99973, 1.00026,
            1.00119, 0.99955, 0.99973, 1.0006, 0.99911, 1.0006, 1.00026,
            0.99972, 1.00026, 0.99902, 1.00041, 0.99973, 0.99999, 1, 1, 1.00038,
            1.0005, 1.00016, 1.00022, 1.00016, 1.00022, 1.00016, 1.00022,
            1.00001, 0.99973, 1, 1, 0.99973, 1, 1, 0.99955, 1.0006, 1.0006,
            1.0006, 1.0006, 1, 1, 1, 0.99973, 0.99973, 0.99972, 1, 1, 1.00106,
            0.99999, 0.99998, 0.99998, 0.99999, 0.99998, 1.66475, 1, 0.99973,
            0.99973, 1.00023, 0.99973, 0.99971, 1.00047, 1.00023, 1, 0.99991,
            0.99984, 1.00002, 1.00002, 1.00002, 1.00002, 1, 1, 1, 1, 1, 1, 1,
            0.99972, 1, 1.20985, 1.39713, 1.00003, 1.00031, 1.00015, 1, 0.99561,
            1.00027, 1.00031, 1.00031, 0.99915, 1.00031, 1.00031, 0.99999,
            1.00003, 0.99999, 0.99999, 1.41144, 1.6, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.40579, 1.40579,
            1.36625, 0.99999, 1, 0.99861, 0.99861, 1, 1.00026, 1.00026, 1.00026,
            1.00026, 0.99972, 0.99999, 0.99999, 0.99999, 0.99999, 1.40483, 1,
            0.99977, 1.00054, 1, 1, 0.99953, 0.99962, 1.00042, 0.9995, 1, 1, 1,
            1, 1, 1, 1, 1, 0.99998, 0.99998, 0.99998, 0.99998, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1,
          ];
          t.HelveticaBoldMetrics = { lineHeight: 1.2, lineGap: 0.2 };
          t.HelveticaBoldItalicFactors = [
            0.76116, 1, 1, 1.0006, 0.99998, 0.99974, 0.99973, 0.99973, 0.99982,
            0.99977, 1.00087, 0.99998, 0.99998, 0.99959, 1.00003, 1.0006,
            0.99998, 1.0006, 1.0006, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99998, 1,
            1.00003, 1.00003, 1.00003, 1.00026, 0.9999, 0.99977, 0.99977,
            0.99977, 0.99977, 1.00001, 1.00026, 1.00022, 0.99977, 1.0006,
            0.99973, 0.99977, 1.00026, 0.99999, 0.99977, 1.00022, 1.00001,
            1.00022, 0.99977, 1.00001, 1.00026, 0.99977, 1.00001, 1.00016,
            1.00001, 1.00001, 1.00026, 0.99998, 1.0006, 0.99998, 1.00003,
            0.99973, 0.99998, 0.99973, 1.00026, 0.99973, 1.00026, 0.99973,
            0.99998, 1.00026, 1.00026, 1.0006, 1.0006, 0.99973, 1.0006, 0.99982,
            1.00026, 1.00026, 1.00026, 1.00026, 0.99959, 0.99973, 0.99998,
            1.00026, 0.99973, 1.00022, 0.99973, 0.99973, 1, 0.99959, 1.00077,
            0.99959, 1.00003, 0.99998, 0.99973, 0.99973, 0.99973, 0.99973,
            1.00077, 0.99973, 0.99998, 1.00025, 0.99968, 0.99973, 1.00003,
            1.00025, 0.60299, 1.00024, 1.06409, 1, 1, 0.99998, 1, 0.99973,
            1.0006, 0.99998, 1, 0.99936, 0.99973, 1.00002, 1.00002, 1.00002,
            1.00026, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 0.99977, 1,
            0.99977, 1.00001, 1.00001, 1.00001, 1.00001, 1.0006, 1.0006, 1.0006,
            1.0006, 0.99977, 0.99977, 1.00022, 1.00022, 1.00022, 1.00022,
            1.00022, 1.00003, 1.00022, 0.99977, 0.99977, 0.99977, 0.99977,
            1.00001, 1.00001, 1.00026, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99982, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 1.0006, 1.0006, 1.0006, 1.0006, 1.00026, 1.00026, 1.00026,
            1.00026, 1.00026, 1.00026, 1.00026, 1.06409, 1.00026, 1.00026,
            1.00026, 1.00026, 1.00026, 0.99973, 1.00026, 0.99973, 0.99977,
            0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973,
            0.99977, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977,
            1.0044, 0.99977, 1.00026, 1.00001, 0.99973, 1.00001, 0.99973,
            1.00001, 0.99973, 1.00001, 0.99973, 1.00001, 0.99973, 1.00022,
            1.00026, 1.00022, 1.00026, 1.00022, 1.00026, 1.00022, 1.00026,
            0.99977, 1.00026, 0.99977, 1.00026, 1.0006, 1.0006, 1.0006, 1.0006,
            1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 0.99971, 0.99973,
            0.99973, 1.0006, 0.99977, 0.99973, 0.99973, 1.00026, 1.0006,
            1.00026, 1.0006, 1.00026, 1.01011, 1.00026, 0.99999, 1.00026,
            1.0006, 0.99977, 1.00026, 0.99977, 1.00026, 0.99977, 1.00026,
            0.9993, 0.9998, 1.00026, 1.00022, 1.00026, 1.00022, 1.00026,
            1.00022, 1.00026, 1, 1.00016, 0.99977, 0.99959, 0.99977, 0.99959,
            0.99977, 0.99959, 1.00001, 0.99973, 1.00001, 0.99973, 1.00001,
            0.99973, 1.00001, 0.99973, 1.00026, 0.99998, 1.00026, 0.8121,
            1.00026, 0.99998, 0.99977, 1.00026, 0.99977, 1.00026, 0.99977,
            1.00026, 0.99977, 1.00026, 0.99977, 1.00026, 0.99977, 1.00026,
            1.00016, 1.00022, 1.00001, 0.99973, 1.00001, 1.00026, 1, 1.00026, 1,
            1.00026, 1, 1.0006, 0.99973, 0.99977, 0.99973, 1, 0.99982, 1.00022,
            1.00026, 1.00001, 0.99973, 1.00026, 0.99998, 0.99998, 0.99998,
            0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998,
            0.99998, 0.99998, 0.99977, 1, 1, 1.00026, 0.99969, 0.99972, 0.99981,
            0.9998, 1.0006, 0.99977, 0.99977, 1.00022, 0.91155, 1.00001,
            1.00026, 0.99977, 1.00022, 1.0006, 0.99977, 1.00001, 0.99999,
            0.99977, 0.99966, 1.00022, 1.00032, 1.00001, 0.99944, 1.00026,
            1.00001, 0.99968, 1.00001, 1.00047, 1, 1.0006, 1.00001, 0.99981,
            1.00101, 1.00026, 1.0006, 0.99948, 0.99981, 1.00064, 0.99973,
            0.99942, 1.00101, 1.00061, 1.00026, 1.00069, 1.0006, 1.00014,
            0.99973, 1.01322, 0.99973, 1.00065, 1.00026, 1.00012, 0.99923, 1,
            1.00064, 1.00076, 0.99948, 1.00055, 1.00063, 1.00007, 0.99943,
            1.0006, 0.99948, 1.00026, 0.99948, 0.99943, 1.00001, 1.00001,
            1.00029, 1.00038, 1.00035, 1.00001, 1.0006, 1.0006, 0.99973,
            0.99978, 1.00001, 1.00057, 0.99989, 0.99967, 0.99964, 0.99967,
            0.99977, 0.99999, 0.99977, 1.00038, 0.99977, 1.00001, 0.99973,
            1.00066, 0.99967, 0.99967, 1.00041, 0.99998, 0.99999, 0.99977,
            1.00022, 0.99967, 1.00001, 0.99977, 1.00026, 0.99964, 1.00031,
            1.00001, 0.99999, 0.99999, 1, 1.00023, 1, 1, 0.99999, 1.00035,
            1.00001, 0.99999, 0.99973, 0.99977, 0.99999, 1.00058, 0.99973,
            0.99973, 0.99955, 0.9995, 1.00026, 1.00026, 1.00032, 0.99989,
            1.00034, 0.99999, 1.00026, 1.00026, 1.00026, 0.99973, 0.45998,
            0.99973, 1.00026, 0.99973, 1.00001, 0.99999, 0.99982, 0.99994,
            0.99996, 1, 1.00042, 1.00044, 1.00029, 1.00023, 0.99973, 0.99973,
            1.00026, 0.99949, 1.00002, 0.99973, 1.0006, 1.0006, 1.0006, 0.99975,
            1.00026, 1.00026, 1.00032, 0.98685, 0.99973, 1.00026, 1, 1, 0.99966,
            1.00044, 1.00016, 1.00022, 1.00016, 1.00022, 1.00016, 1.00022,
            1.00001, 0.99973, 1, 1, 0.99973, 1, 1, 0.99955, 1.0006, 1.0006,
            1.0006, 1.0006, 1, 1, 1, 0.99973, 0.99973, 0.99972, 1, 1, 1.00106,
            0.99999, 0.99998, 0.99998, 0.99999, 0.99998, 1.66475, 1, 0.99973,
            0.99973, 1, 0.99973, 0.99971, 0.99978, 1, 1, 0.99991, 0.99984,
            1.00002, 1.00002, 1.00002, 1.00002, 1.00098, 1, 1, 1, 1.00049, 1, 1,
            0.99972, 1, 1.20985, 1.39713, 1.00003, 1.00031, 1.00015, 1, 0.99561,
            1.00027, 1.00031, 1.00031, 0.99915, 1.00031, 1.00031, 0.99999,
            1.00003, 0.99999, 0.99999, 1.41144, 1.6, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.40579, 1.40579,
            1.36625, 0.99999, 1, 0.99861, 0.99861, 1, 1.00026, 1.00026, 1.00026,
            1.00026, 0.99972, 0.99999, 0.99999, 0.99999, 0.99999, 1.40483, 1,
            0.99977, 1.00054, 1, 1, 0.99953, 0.99962, 1.00042, 0.9995, 1, 1, 1,
            1, 1, 1, 1, 1, 0.99998, 0.99998, 0.99998, 0.99998, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1,
          ];
          t.HelveticaBoldItalicMetrics = { lineHeight: 1.35, lineGap: 0.2 };
          t.HelveticaItalicFactors = [
            0.76116, 1, 1, 1.0006, 1.0006, 1.00006, 0.99973, 0.99973, 0.99982,
            1.00001, 1.00043, 0.99998, 0.99998, 0.99959, 1.00003, 1.0006,
            0.99998, 1.0006, 1.0006, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 1.0006, 1,
            1.00003, 1.00003, 1.00003, 0.99973, 0.99987, 1.00001, 1.00001,
            0.99977, 0.99977, 1.00001, 1.00026, 1.00022, 0.99977, 1.0006, 1,
            1.00001, 0.99973, 0.99999, 0.99977, 1.00022, 1.00001, 1.00022,
            0.99977, 1.00001, 1.00026, 0.99977, 1.00001, 1.00016, 1.00001,
            1.00001, 1.00026, 1.0006, 1.0006, 1.0006, 0.99949, 0.99973, 0.99998,
            0.99973, 0.99973, 1, 0.99973, 0.99973, 1.0006, 0.99973, 0.99973,
            0.99924, 0.99924, 1, 0.99924, 0.99999, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99998, 1, 1.0006, 0.99973, 1, 0.99977, 1, 1, 1, 1.00005,
            1.0009, 1.00005, 1.00003, 0.99998, 0.99973, 0.99973, 0.99973,
            0.99973, 1.0009, 0.99973, 0.99998, 1.00025, 0.99968, 0.99973,
            1.00003, 1.00025, 0.60299, 1.00024, 1.06409, 1, 1, 0.99998, 1,
            0.9998, 1.0006, 0.99998, 1, 0.99936, 0.99973, 1.00002, 1.00002,
            1.00002, 1.00026, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001,
            1.00001, 1, 0.99977, 1.00001, 1.00001, 1.00001, 1.00001, 1.0006,
            1.0006, 1.0006, 1.0006, 0.99977, 0.99977, 1.00022, 1.00022, 1.00022,
            1.00022, 1.00022, 1.00003, 1.00022, 0.99977, 0.99977, 0.99977,
            0.99977, 1.00001, 1.00001, 1.00026, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99982, 1, 0.99973, 0.99973, 0.99973,
            0.99973, 1.0006, 1.0006, 1.0006, 1.0006, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99973, 1.06409, 1.00026, 0.99973,
            0.99973, 0.99973, 0.99973, 1, 0.99973, 1, 1.00001, 0.99973, 1.00001,
            0.99973, 1.00001, 0.99973, 0.99977, 1, 0.99977, 1, 0.99977, 1,
            0.99977, 1, 0.99977, 1.0288, 0.99977, 0.99973, 1.00001, 0.99973,
            1.00001, 0.99973, 1.00001, 0.99973, 1.00001, 0.99973, 1.00001,
            0.99973, 1.00022, 0.99973, 1.00022, 0.99973, 1.00022, 0.99973,
            1.00022, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 1.0006,
            1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 0.99924, 1.0006,
            1.0006, 0.99946, 1.00034, 1, 0.99924, 1.00001, 1, 1, 0.99973,
            0.99924, 0.99973, 0.99924, 0.99973, 1.06311, 0.99973, 1.00024,
            0.99973, 0.99924, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977,
            0.99973, 1.00041, 0.9998, 0.99973, 1.00022, 0.99973, 1.00022,
            0.99973, 1.00022, 0.99973, 1, 1.00016, 0.99977, 0.99998, 0.99977,
            0.99998, 0.99977, 0.99998, 1.00001, 1, 1.00001, 1, 1.00001, 1,
            1.00001, 1, 1.00026, 1.0006, 1.00026, 0.89547, 1.00026, 1.0006,
            0.99977, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977,
            0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 1.00016, 0.99977,
            1.00001, 1, 1.00001, 1.00026, 1, 1.00026, 1, 1.00026, 1, 0.99924,
            0.99973, 1.00001, 0.99973, 1, 0.99982, 1.00022, 1.00026, 1.00001, 1,
            1.00026, 1.0006, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998,
            0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 1.00001, 1,
            1.00054, 0.99977, 1.00084, 1.00007, 0.99973, 1.00013, 0.99924,
            1.00001, 1.00001, 0.99945, 0.91221, 1.00001, 1.00026, 0.99977,
            1.00022, 1.0006, 1.00001, 1.00001, 0.99999, 0.99977, 0.99933,
            1.00022, 1.00054, 1.00001, 1.00065, 1.00026, 1.00001, 1.0001,
            1.00001, 1.00052, 1, 1.0006, 1.00001, 0.99945, 0.99897, 0.99968,
            0.99924, 1.00036, 0.99945, 0.99949, 1, 1.0006, 0.99897, 0.99918,
            0.99968, 0.99911, 0.99924, 1, 0.99962, 1.01487, 1, 1.0005, 0.99973,
            1.00012, 1.00043, 1, 0.99995, 0.99994, 1.00036, 0.99947, 1.00019,
            1.00063, 1.00025, 0.99924, 1.00036, 0.99973, 1.00036, 1.00025,
            1.00001, 1.00001, 1.00027, 1.0001, 1.00068, 1.00001, 1.0006, 1.0006,
            1, 1.00008, 0.99957, 0.99972, 0.9994, 0.99954, 0.99975, 1.00051,
            1.00001, 1.00019, 1.00001, 1.0001, 0.99986, 1.00001, 1.00001,
            1.00038, 0.99954, 0.99954, 0.9994, 1.00066, 0.99999, 0.99977,
            1.00022, 1.00054, 1.00001, 0.99977, 1.00026, 0.99975, 1.0001,
            1.00001, 0.99993, 0.9995, 0.99955, 1.00016, 0.99978, 0.99974,
            1.00019, 1.00022, 0.99955, 1.00053, 0.99973, 1.00089, 1.00005,
            0.99967, 1.00048, 0.99973, 1.00002, 1.00034, 0.99973, 0.99973,
            0.99964, 1.00006, 1.00066, 0.99947, 0.99973, 0.98894, 0.99973, 1,
            0.44898, 1, 0.99946, 1, 1.00039, 1.00082, 0.99991, 0.99991, 0.99985,
            1.00022, 1.00023, 1.00061, 1.00006, 0.99966, 0.99973, 0.99973,
            0.99973, 1.00019, 1.0008, 1, 0.99924, 0.99924, 0.99924, 0.99983,
            1.00044, 0.99973, 0.99964, 0.98332, 1, 0.99973, 1, 1, 0.99962,
            0.99895, 1.00016, 0.99977, 1.00016, 0.99977, 1.00016, 0.99977,
            1.00001, 1, 1, 1, 0.99973, 1, 1, 0.99955, 0.99924, 0.99924, 0.99924,
            0.99924, 0.99998, 0.99998, 0.99998, 0.99973, 0.99973, 0.99972, 1, 1,
            1.00267, 0.99999, 0.99998, 0.99998, 1, 0.99998, 1.66475, 1, 0.99973,
            0.99973, 1.00023, 0.99973, 1.00423, 0.99925, 0.99999, 1, 0.99991,
            0.99984, 1.00002, 1.00002, 1.00002, 1.00002, 1.00049, 1, 1.00245, 1,
            1, 1, 1, 0.96329, 1, 1.20985, 1.39713, 1.00003, 0.8254, 1.00015, 1,
            1.00035, 1.00027, 1.00031, 1.00031, 1.00003, 1.00031, 1.00031,
            0.99999, 1.00003, 0.99999, 0.99999, 1.41144, 1.6, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.40579,
            1.40579, 1.36625, 0.99999, 1, 0.99861, 0.99861, 1, 1.00026, 1.00026,
            1.00026, 1.00026, 0.95317, 0.99999, 0.99999, 0.99999, 0.99999,
            1.40483, 1, 0.99977, 1.00054, 1, 1, 0.99953, 0.99962, 1.00042,
            0.9995, 1, 1, 1, 1, 1, 1, 1, 1, 0.99998, 0.99998, 0.99998, 0.99998,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.HelveticaItalicMetrics = { lineHeight: 1.35, lineGap: 0.2 };
          t.HelveticaRegularFactors = [
            0.76116, 1, 1, 1.0006, 1.0006, 1.00006, 0.99973, 0.99973, 0.99982,
            1.00001, 1.00043, 0.99998, 0.99998, 0.99959, 1.00003, 1.0006,
            0.99998, 1.0006, 1.0006, 0.99973, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 0.99973, 1.0006, 1,
            1.00003, 1.00003, 1.00003, 0.99973, 0.99987, 1.00001, 1.00001,
            0.99977, 0.99977, 1.00001, 1.00026, 1.00022, 0.99977, 1.0006, 1,
            1.00001, 0.99973, 0.99999, 0.99977, 1.00022, 1.00001, 1.00022,
            0.99977, 1.00001, 1.00026, 0.99977, 1.00001, 1.00016, 1.00001,
            1.00001, 1.00026, 1.0006, 1.0006, 1.0006, 0.99949, 0.99973, 0.99998,
            0.99973, 0.99973, 1, 0.99973, 0.99973, 1.0006, 0.99973, 0.99973,
            0.99924, 0.99924, 1, 0.99924, 0.99999, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99998, 1, 1.0006, 0.99973, 1, 0.99977, 1, 1, 1, 1.00005,
            1.0009, 1.00005, 1.00003, 0.99998, 0.99973, 0.99973, 0.99973,
            0.99973, 1.0009, 0.99973, 0.99998, 1.00025, 0.99968, 0.99973,
            1.00003, 1.00025, 0.60299, 1.00024, 1.06409, 1, 1, 0.99998, 1,
            0.9998, 1.0006, 0.99998, 1, 0.99936, 0.99973, 1.00002, 1.00002,
            1.00002, 1.00026, 1.00001, 1.00001, 1.00001, 1.00001, 1.00001,
            1.00001, 1, 0.99977, 1.00001, 1.00001, 1.00001, 1.00001, 1.0006,
            1.0006, 1.0006, 1.0006, 0.99977, 0.99977, 1.00022, 1.00022, 1.00022,
            1.00022, 1.00022, 1.00003, 1.00022, 0.99977, 0.99977, 0.99977,
            0.99977, 1.00001, 1.00001, 1.00026, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99982, 1, 0.99973, 0.99973, 0.99973,
            0.99973, 1.0006, 1.0006, 1.0006, 1.0006, 0.99973, 0.99973, 0.99973,
            0.99973, 0.99973, 0.99973, 0.99973, 1.06409, 1.00026, 0.99973,
            0.99973, 0.99973, 0.99973, 1, 0.99973, 1, 1.00001, 0.99973, 1.00001,
            0.99973, 1.00001, 0.99973, 0.99977, 1, 0.99977, 1, 0.99977, 1,
            0.99977, 1, 0.99977, 1.04596, 0.99977, 0.99973, 1.00001, 0.99973,
            1.00001, 0.99973, 1.00001, 0.99973, 1.00001, 0.99973, 1.00001,
            0.99973, 1.00022, 0.99973, 1.00022, 0.99973, 1.00022, 0.99973,
            1.00022, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 1.0006,
            1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 1.0006, 0.99924, 1.0006,
            1.0006, 1.00019, 1.00034, 1, 0.99924, 1.00001, 1, 1, 0.99973,
            0.99924, 0.99973, 0.99924, 0.99973, 1.02572, 0.99973, 1.00005,
            0.99973, 0.99924, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977,
            0.99973, 0.99999, 0.9998, 0.99973, 1.00022, 0.99973, 1.00022,
            0.99973, 1.00022, 0.99973, 1, 1.00016, 0.99977, 0.99998, 0.99977,
            0.99998, 0.99977, 0.99998, 1.00001, 1, 1.00001, 1, 1.00001, 1,
            1.00001, 1, 1.00026, 1.0006, 1.00026, 0.84533, 1.00026, 1.0006,
            0.99977, 0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 0.99977,
            0.99973, 0.99977, 0.99973, 0.99977, 0.99973, 1.00016, 0.99977,
            1.00001, 1, 1.00001, 1.00026, 1, 1.00026, 1, 1.00026, 1, 0.99924,
            0.99973, 1.00001, 0.99973, 1, 0.99982, 1.00022, 1.00026, 1.00001, 1,
            1.00026, 1.0006, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998,
            0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99998, 0.99928, 1,
            0.99977, 1.00013, 1.00055, 0.99947, 0.99945, 0.99941, 0.99924,
            1.00001, 1.00001, 1.0004, 0.91621, 1.00001, 1.00026, 0.99977,
            1.00022, 1.0006, 1.00001, 1.00005, 0.99999, 0.99977, 1.00015,
            1.00022, 0.99977, 1.00001, 0.99973, 1.00026, 1.00001, 1.00019,
            1.00001, 0.99946, 1, 1.0006, 1.00001, 0.99978, 1.00045, 0.99973,
            0.99924, 1.00023, 0.99978, 0.99966, 1, 1.00065, 1.00045, 1.00019,
            0.99973, 0.99973, 0.99924, 1, 1, 0.96499, 1, 1.00055, 0.99973,
            1.00008, 1.00027, 1, 0.9997, 0.99995, 1.00023, 0.99933, 1.00019,
            1.00015, 1.00031, 0.99924, 1.00023, 0.99973, 1.00023, 1.00031,
            1.00001, 0.99928, 1.00029, 1.00092, 1.00035, 1.00001, 1.0006,
            1.0006, 1, 0.99988, 0.99975, 1, 1.00082, 0.99561, 0.9996, 1.00035,
            1.00001, 0.99962, 1.00001, 1.00092, 0.99964, 1.00001, 0.99963,
            0.99999, 1.00035, 1.00035, 1.00082, 0.99962, 0.99999, 0.99977,
            1.00022, 1.00035, 1.00001, 0.99977, 1.00026, 0.9996, 0.99967,
            1.00001, 1.00034, 1.00074, 1.00054, 1.00053, 1.00063, 0.99971,
            0.99962, 1.00035, 0.99975, 0.99977, 0.99973, 1.00043, 0.99953,
            1.0007, 0.99915, 0.99973, 1.00008, 0.99892, 1.00073, 1.00073,
            1.00114, 0.99915, 1.00073, 0.99955, 0.99973, 1.00092, 0.99973, 1,
            0.99998, 1, 1.0003, 1, 1.00043, 1.00001, 0.99969, 1.0003, 1,
            1.00035, 1.00001, 0.9995, 1, 1.00092, 0.99973, 0.99973, 0.99973,
            1.0007, 0.9995, 1, 0.99924, 1.0006, 0.99924, 0.99972, 1.00062,
            0.99973, 1.00114, 1.00073, 1, 0.99955, 1, 1, 1.00047, 0.99968,
            1.00016, 0.99977, 1.00016, 0.99977, 1.00016, 0.99977, 1.00001, 1, 1,
            1, 0.99973, 1, 1, 0.99955, 0.99924, 0.99924, 0.99924, 0.99924,
            0.99998, 0.99998, 0.99998, 0.99973, 0.99973, 0.99972, 1, 1, 1.00267,
            0.99999, 0.99998, 0.99998, 1, 0.99998, 1.66475, 1, 0.99973, 0.99973,
            1.00023, 0.99973, 0.99971, 0.99925, 1.00023, 1, 0.99991, 0.99984,
            1.00002, 1.00002, 1.00002, 1.00002, 1, 1, 1, 1, 1, 1, 1, 0.96329, 1,
            1.20985, 1.39713, 1.00003, 0.8254, 1.00015, 1, 1.00035, 1.00027,
            1.00031, 1.00031, 0.99915, 1.00031, 1.00031, 0.99999, 1.00003,
            0.99999, 0.99999, 1.41144, 1.6, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144, 1.41144,
            1.41144, 1.41144, 1.41144, 1.41144, 1.40579, 1.40579, 1.36625,
            0.99999, 1, 0.99861, 0.99861, 1, 1.00026, 1.00026, 1.00026, 1.00026,
            0.95317, 0.99999, 0.99999, 0.99999, 0.99999, 1.40483, 1, 0.99977,
            1.00054, 1, 1, 0.99953, 0.99962, 1.00042, 0.9995, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.HelveticaRegularMetrics = { lineHeight: 1.2, lineGap: 0.2 };
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.LiberationSansRegularWidths =
            t.LiberationSansRegularMapping =
            t.LiberationSansItalicWidths =
            t.LiberationSansItalicMapping =
            t.LiberationSansBoldWidths =
            t.LiberationSansBoldMapping =
            t.LiberationSansBoldItalicWidths =
            t.LiberationSansBoldItalicMapping =
              void 0;
          t.LiberationSansBoldWidths = [
            365, 0, 333, 278, 333, 474, 556, 556, 889, 722, 238, 333, 333, 389,
            584, 278, 333, 278, 278, 556, 556, 556, 556, 556, 556, 556, 556,
            556, 556, 333, 333, 584, 584, 584, 611, 975, 722, 722, 722, 722,
            667, 611, 778, 722, 278, 556, 722, 611, 833, 722, 778, 667, 778,
            722, 667, 611, 722, 667, 944, 667, 667, 611, 333, 278, 333, 584,
            556, 333, 556, 611, 556, 611, 556, 333, 611, 611, 278, 278, 556,
            278, 889, 611, 611, 611, 611, 389, 556, 333, 611, 556, 778, 556,
            556, 500, 389, 280, 389, 584, 333, 556, 556, 556, 556, 280, 556,
            333, 737, 370, 556, 584, 737, 552, 400, 549, 333, 333, 333, 576,
            556, 278, 333, 333, 365, 556, 834, 834, 834, 611, 722, 722, 722,
            722, 722, 722, 1e3, 722, 667, 667, 667, 667, 278, 278, 278, 278,
            722, 722, 778, 778, 778, 778, 778, 584, 778, 722, 722, 722, 722,
            667, 667, 611, 556, 556, 556, 556, 556, 556, 889, 556, 556, 556,
            556, 556, 278, 278, 278, 278, 611, 611, 611, 611, 611, 611, 611,
            549, 611, 611, 611, 611, 611, 556, 611, 556, 722, 556, 722, 556,
            722, 556, 722, 556, 722, 556, 722, 556, 722, 556, 722, 719, 722,
            611, 667, 556, 667, 556, 667, 556, 667, 556, 667, 556, 778, 611,
            778, 611, 778, 611, 778, 611, 722, 611, 722, 611, 278, 278, 278,
            278, 278, 278, 278, 278, 278, 278, 785, 556, 556, 278, 722, 556,
            556, 611, 278, 611, 278, 611, 385, 611, 479, 611, 278, 722, 611,
            722, 611, 722, 611, 708, 723, 611, 778, 611, 778, 611, 778, 611,
            1e3, 944, 722, 389, 722, 389, 722, 389, 667, 556, 667, 556, 667,
            556, 667, 556, 611, 333, 611, 479, 611, 333, 722, 611, 722, 611,
            722, 611, 722, 611, 722, 611, 722, 611, 944, 778, 667, 556, 667,
            611, 500, 611, 500, 611, 500, 278, 556, 722, 556, 1e3, 889, 778,
            611, 667, 556, 611, 333, 333, 333, 333, 333, 333, 333, 333, 333,
            333, 333, 465, 722, 333, 853, 906, 474, 825, 927, 838, 278, 722,
            722, 601, 719, 667, 611, 722, 778, 278, 722, 667, 833, 722, 644,
            778, 722, 667, 600, 611, 667, 821, 667, 809, 802, 278, 667, 615,
            451, 611, 278, 582, 615, 610, 556, 606, 475, 460, 611, 541, 278,
            558, 556, 612, 556, 445, 611, 766, 619, 520, 684, 446, 582, 715,
            576, 753, 845, 278, 582, 611, 582, 845, 667, 669, 885, 567, 711,
            667, 278, 276, 556, 1094, 1062, 875, 610, 722, 622, 719, 722, 719,
            722, 567, 712, 667, 904, 626, 719, 719, 610, 702, 833, 722, 778,
            719, 667, 722, 611, 622, 854, 667, 730, 703, 1005, 1019, 870, 979,
            719, 711, 1031, 719, 556, 618, 615, 417, 635, 556, 709, 497, 615,
            615, 500, 635, 740, 604, 611, 604, 611, 556, 490, 556, 875, 556,
            615, 581, 833, 844, 729, 854, 615, 552, 854, 583, 556, 556, 611,
            417, 552, 556, 278, 281, 278, 969, 906, 611, 500, 615, 556, 604,
            778, 611, 487, 447, 944, 778, 944, 778, 944, 778, 667, 556, 333,
            333, 556, 1e3, 1e3, 552, 278, 278, 278, 278, 500, 500, 500, 556,
            556, 350, 1e3, 1e3, 240, 479, 333, 333, 604, 333, 167, 396, 556,
            556, 1094, 556, 885, 489, 1115, 1e3, 768, 600, 834, 834, 834, 834,
            1e3, 500, 1e3, 500, 1e3, 500, 500, 494, 612, 823, 713, 584, 549,
            713, 979, 722, 274, 549, 549, 583, 549, 549, 604, 584, 604, 604,
            708, 625, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 729, 604, 604, 354, 354,
            1e3, 990, 990, 990, 990, 494, 604, 604, 604, 604, 354, 1021, 1052,
            917, 750, 750, 531, 656, 594, 510, 500, 750, 750, 611, 611, 333,
            333, 333, 333, 333, 333, 333, 333, 222, 222, 333, 333, 333, 333,
            333, 333, 333, 333,
          ];
          t.LiberationSansBoldMapping = [
            -1, -1, -1, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
            46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
            63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
            80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
            97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
            111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
            124, 125, 126, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
            171, 172, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184,
            185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197,
            198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
            211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
            224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236,
            237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249,
            250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
            263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275,
            276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288,
            289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301,
            302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327,
            328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340,
            341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353,
            354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366,
            367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379,
            380, 381, 382, 383, 402, 506, 507, 508, 509, 510, 511, 536, 537,
            538, 539, 710, 711, 713, 728, 729, 730, 731, 732, 733, 900, 901,
            902, 903, 904, 905, 906, 908, 910, 911, 912, 913, 914, 915, 916,
            917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927, 928, 929,
            931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943,
            944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956,
            957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967, 968, 969,
            970, 971, 972, 973, 974, 1024, 1025, 1026, 1027, 1028, 1029, 1030,
            1031, 1032, 1033, 1034, 1035, 1036, 1037, 1038, 1039, 1040, 1041,
            1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052,
            1053, 1054, 1055, 1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063,
            1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071, 1072, 1073, 1074,
            1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085,
            1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096,
            1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107,
            1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
            1119, 1138, 1139, 1168, 1169, 7808, 7809, 7810, 7811, 7812, 7813,
            7922, 7923, 8208, 8209, 8211, 8212, 8213, 8215, 8216, 8217, 8218,
            8219, 8220, 8221, 8222, 8224, 8225, 8226, 8230, 8240, 8242, 8243,
            8249, 8250, 8252, 8254, 8260, 8319, 8355, 8356, 8359, 8364, 8453,
            8467, 8470, 8482, 8486, 8494, 8539, 8540, 8541, 8542, 8592, 8593,
            8594, 8595, 8596, 8597, 8616, 8706, 8710, 8719, 8721, 8722, 8730,
            8734, 8735, 8745, 8747, 8776, 8800, 8801, 8804, 8805, 8962, 8976,
            8992, 8993, 9472, 9474, 9484, 9488, 9492, 9496, 9500, 9508, 9516,
            9524, 9532, 9552, 9553, 9554, 9555, 9556, 9557, 9558, 9559, 9560,
            9561, 9562, 9563, 9564, 9565, 9566, 9567, 9568, 9569, 9570, 9571,
            9572, 9573, 9574, 9575, 9576, 9577, 9578, 9579, 9580, 9600, 9604,
            9608, 9612, 9616, 9617, 9618, 9619, 9632, 9633, 9642, 9643, 9644,
            9650, 9658, 9660, 9668, 9674, 9675, 9679, 9688, 9689, 9702, 9786,
            9787, 9788, 9792, 9794, 9824, 9827, 9829, 9830, 9834, 9835, 9836,
            61441, 61442, 61445, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
            -1, -1, -1, -1, -1,
          ];
          t.LiberationSansBoldItalicWidths = [
            365, 0, 333, 278, 333, 474, 556, 556, 889, 722, 238, 333, 333, 389,
            584, 278, 333, 278, 278, 556, 556, 556, 556, 556, 556, 556, 556,
            556, 556, 333, 333, 584, 584, 584, 611, 975, 722, 722, 722, 722,
            667, 611, 778, 722, 278, 556, 722, 611, 833, 722, 778, 667, 778,
            722, 667, 611, 722, 667, 944, 667, 667, 611, 333, 278, 333, 584,
            556, 333, 556, 611, 556, 611, 556, 333, 611, 611, 278, 278, 556,
            278, 889, 611, 611, 611, 611, 389, 556, 333, 611, 556, 778, 556,
            556, 500, 389, 280, 389, 584, 333, 556, 556, 556, 556, 280, 556,
            333, 737, 370, 556, 584, 737, 552, 400, 549, 333, 333, 333, 576,
            556, 278, 333, 333, 365, 556, 834, 834, 834, 611, 722, 722, 722,
            722, 722, 722, 1e3, 722, 667, 667, 667, 667, 278, 278, 278, 278,
            722, 722, 778, 778, 778, 778, 778, 584, 778, 722, 722, 722, 722,
            667, 667, 611, 556, 556, 556, 556, 556, 556, 889, 556, 556, 556,
            556, 556, 278, 278, 278, 278, 611, 611, 611, 611, 611, 611, 611,
            549, 611, 611, 611, 611, 611, 556, 611, 556, 722, 556, 722, 556,
            722, 556, 722, 556, 722, 556, 722, 556, 722, 556, 722, 740, 722,
            611, 667, 556, 667, 556, 667, 556, 667, 556, 667, 556, 778, 611,
            778, 611, 778, 611, 778, 611, 722, 611, 722, 611, 278, 278, 278,
            278, 278, 278, 278, 278, 278, 278, 782, 556, 556, 278, 722, 556,
            556, 611, 278, 611, 278, 611, 396, 611, 479, 611, 278, 722, 611,
            722, 611, 722, 611, 708, 723, 611, 778, 611, 778, 611, 778, 611,
            1e3, 944, 722, 389, 722, 389, 722, 389, 667, 556, 667, 556, 667,
            556, 667, 556, 611, 333, 611, 479, 611, 333, 722, 611, 722, 611,
            722, 611, 722, 611, 722, 611, 722, 611, 944, 778, 667, 556, 667,
            611, 500, 611, 500, 611, 500, 278, 556, 722, 556, 1e3, 889, 778,
            611, 667, 556, 611, 333, 333, 333, 333, 333, 333, 333, 333, 333,
            333, 333, 333, 722, 333, 854, 906, 473, 844, 930, 847, 278, 722,
            722, 610, 671, 667, 611, 722, 778, 278, 722, 667, 833, 722, 657,
            778, 718, 667, 590, 611, 667, 822, 667, 829, 781, 278, 667, 620,
            479, 611, 278, 591, 620, 621, 556, 610, 479, 492, 611, 558, 278,
            566, 556, 603, 556, 450, 611, 712, 605, 532, 664, 409, 591, 704,
            578, 773, 834, 278, 591, 611, 591, 834, 667, 667, 886, 614, 719,
            667, 278, 278, 556, 1094, 1042, 854, 622, 719, 677, 719, 722, 708,
            722, 614, 722, 667, 927, 643, 719, 719, 615, 687, 833, 722, 778,
            719, 667, 722, 611, 677, 781, 667, 729, 708, 979, 989, 854, 1e3,
            708, 719, 1042, 729, 556, 619, 604, 534, 618, 556, 736, 510, 611,
            611, 507, 622, 740, 604, 611, 611, 611, 556, 889, 556, 885, 556,
            646, 583, 889, 935, 707, 854, 594, 552, 865, 589, 556, 556, 611,
            469, 563, 556, 278, 278, 278, 969, 906, 611, 507, 619, 556, 611,
            778, 611, 575, 467, 944, 778, 944, 778, 944, 778, 667, 556, 333,
            333, 556, 1e3, 1e3, 552, 278, 278, 278, 278, 500, 500, 500, 556,
            556, 350, 1e3, 1e3, 240, 479, 333, 333, 604, 333, 167, 396, 556,
            556, 1104, 556, 885, 516, 1146, 1e3, 768, 600, 834, 834, 834, 834,
            999, 500, 1e3, 500, 1e3, 500, 500, 494, 612, 823, 713, 584, 549,
            713, 979, 722, 274, 549, 549, 583, 549, 549, 604, 584, 604, 604,
            708, 625, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 729, 604, 604, 354, 354,
            1e3, 990, 990, 990, 990, 494, 604, 604, 604, 604, 354, 1021, 1052,
            917, 750, 750, 531, 656, 594, 510, 500, 750, 750, 611, 611, 333,
            333, 333, 333, 333, 333, 333, 333, 222, 222, 333, 333, 333, 333,
            333, 333, 333, 333,
          ];
          t.LiberationSansBoldItalicMapping = [
            -1, -1, -1, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
            46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
            63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
            80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
            97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
            111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
            124, 125, 126, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
            171, 172, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184,
            185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197,
            198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
            211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
            224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236,
            237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249,
            250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
            263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275,
            276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288,
            289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301,
            302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327,
            328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340,
            341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353,
            354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366,
            367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379,
            380, 381, 382, 383, 402, 506, 507, 508, 509, 510, 511, 536, 537,
            538, 539, 710, 711, 713, 728, 729, 730, 731, 732, 733, 900, 901,
            902, 903, 904, 905, 906, 908, 910, 911, 912, 913, 914, 915, 916,
            917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927, 928, 929,
            931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943,
            944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956,
            957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967, 968, 969,
            970, 971, 972, 973, 974, 1024, 1025, 1026, 1027, 1028, 1029, 1030,
            1031, 1032, 1033, 1034, 1035, 1036, 1037, 1038, 1039, 1040, 1041,
            1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052,
            1053, 1054, 1055, 1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063,
            1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071, 1072, 1073, 1074,
            1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085,
            1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096,
            1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107,
            1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
            1119, 1138, 1139, 1168, 1169, 7808, 7809, 7810, 7811, 7812, 7813,
            7922, 7923, 8208, 8209, 8211, 8212, 8213, 8215, 8216, 8217, 8218,
            8219, 8220, 8221, 8222, 8224, 8225, 8226, 8230, 8240, 8242, 8243,
            8249, 8250, 8252, 8254, 8260, 8319, 8355, 8356, 8359, 8364, 8453,
            8467, 8470, 8482, 8486, 8494, 8539, 8540, 8541, 8542, 8592, 8593,
            8594, 8595, 8596, 8597, 8616, 8706, 8710, 8719, 8721, 8722, 8730,
            8734, 8735, 8745, 8747, 8776, 8800, 8801, 8804, 8805, 8962, 8976,
            8992, 8993, 9472, 9474, 9484, 9488, 9492, 9496, 9500, 9508, 9516,
            9524, 9532, 9552, 9553, 9554, 9555, 9556, 9557, 9558, 9559, 9560,
            9561, 9562, 9563, 9564, 9565, 9566, 9567, 9568, 9569, 9570, 9571,
            9572, 9573, 9574, 9575, 9576, 9577, 9578, 9579, 9580, 9600, 9604,
            9608, 9612, 9616, 9617, 9618, 9619, 9632, 9633, 9642, 9643, 9644,
            9650, 9658, 9660, 9668, 9674, 9675, 9679, 9688, 9689, 9702, 9786,
            9787, 9788, 9792, 9794, 9824, 9827, 9829, 9830, 9834, 9835, 9836,
            61441, 61442, 61445, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
            -1, -1, -1, -1, -1,
          ];
          t.LiberationSansItalicWidths = [
            365, 0, 333, 278, 278, 355, 556, 556, 889, 667, 191, 333, 333, 389,
            584, 278, 333, 278, 278, 556, 556, 556, 556, 556, 556, 556, 556,
            556, 556, 278, 278, 584, 584, 584, 556, 1015, 667, 667, 722, 722,
            667, 611, 778, 722, 278, 500, 667, 556, 833, 722, 778, 667, 778,
            722, 667, 611, 722, 667, 944, 667, 667, 611, 278, 278, 278, 469,
            556, 333, 556, 556, 500, 556, 556, 278, 556, 556, 222, 222, 500,
            222, 833, 556, 556, 556, 556, 333, 500, 278, 556, 500, 722, 500,
            500, 500, 334, 260, 334, 584, 333, 556, 556, 556, 556, 260, 556,
            333, 737, 370, 556, 584, 737, 552, 400, 549, 333, 333, 333, 576,
            537, 278, 333, 333, 365, 556, 834, 834, 834, 611, 667, 667, 667,
            667, 667, 667, 1e3, 722, 667, 667, 667, 667, 278, 278, 278, 278,
            722, 722, 778, 778, 778, 778, 778, 584, 778, 722, 722, 722, 722,
            667, 667, 611, 556, 556, 556, 556, 556, 556, 889, 500, 556, 556,
            556, 556, 278, 278, 278, 278, 556, 556, 556, 556, 556, 556, 556,
            549, 611, 556, 556, 556, 556, 500, 556, 500, 667, 556, 667, 556,
            667, 556, 722, 500, 722, 500, 722, 500, 722, 500, 722, 625, 722,
            556, 667, 556, 667, 556, 667, 556, 667, 556, 667, 556, 778, 556,
            778, 556, 778, 556, 778, 556, 722, 556, 722, 556, 278, 278, 278,
            278, 278, 278, 278, 222, 278, 278, 733, 444, 500, 222, 667, 500,
            500, 556, 222, 556, 222, 556, 281, 556, 400, 556, 222, 722, 556,
            722, 556, 722, 556, 615, 723, 556, 778, 556, 778, 556, 778, 556,
            1e3, 944, 722, 333, 722, 333, 722, 333, 667, 500, 667, 500, 667,
            500, 667, 500, 611, 278, 611, 354, 611, 278, 722, 556, 722, 556,
            722, 556, 722, 556, 722, 556, 722, 556, 944, 722, 667, 500, 667,
            611, 500, 611, 500, 611, 500, 222, 556, 667, 556, 1e3, 889, 778,
            611, 667, 500, 611, 278, 333, 333, 333, 333, 333, 333, 333, 333,
            333, 333, 333, 667, 278, 789, 846, 389, 794, 865, 775, 222, 667,
            667, 570, 671, 667, 611, 722, 778, 278, 667, 667, 833, 722, 648,
            778, 725, 667, 600, 611, 667, 837, 667, 831, 761, 278, 667, 570,
            439, 555, 222, 550, 570, 571, 500, 556, 439, 463, 555, 542, 222,
            500, 492, 548, 500, 447, 556, 670, 573, 486, 603, 374, 550, 652,
            546, 728, 779, 222, 550, 556, 550, 779, 667, 667, 843, 544, 708,
            667, 278, 278, 500, 1066, 982, 844, 589, 715, 639, 724, 667, 651,
            667, 544, 704, 667, 917, 614, 715, 715, 589, 686, 833, 722, 778,
            725, 667, 722, 611, 639, 795, 667, 727, 673, 920, 923, 805, 886,
            651, 694, 1022, 682, 556, 562, 522, 493, 553, 556, 688, 465, 556,
            556, 472, 564, 686, 550, 556, 556, 556, 500, 833, 500, 835, 500,
            572, 518, 830, 851, 621, 736, 526, 492, 752, 534, 556, 556, 556,
            378, 496, 500, 222, 222, 222, 910, 828, 556, 472, 565, 500, 556,
            778, 556, 492, 339, 944, 722, 944, 722, 944, 722, 667, 500, 333,
            333, 556, 1e3, 1e3, 552, 222, 222, 222, 222, 333, 333, 333, 556,
            556, 350, 1e3, 1e3, 188, 354, 333, 333, 500, 333, 167, 365, 556,
            556, 1094, 556, 885, 323, 1083, 1e3, 768, 600, 834, 834, 834, 834,
            1e3, 500, 998, 500, 1e3, 500, 500, 494, 612, 823, 713, 584, 549,
            713, 979, 719, 274, 549, 549, 584, 549, 549, 604, 584, 604, 604,
            708, 625, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 729, 604, 604, 354, 354,
            1e3, 990, 990, 990, 990, 494, 604, 604, 604, 604, 354, 1021, 1052,
            917, 750, 750, 531, 656, 594, 510, 500, 750, 750, 500, 500, 333,
            333, 333, 333, 333, 333, 333, 333, 222, 222, 294, 294, 324, 324,
            316, 328, 398, 285,
          ];
          t.LiberationSansItalicMapping = [
            -1, -1, -1, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
            46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
            63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
            80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
            97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
            111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
            124, 125, 126, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
            171, 172, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184,
            185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197,
            198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
            211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
            224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236,
            237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249,
            250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
            263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275,
            276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288,
            289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301,
            302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327,
            328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340,
            341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353,
            354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366,
            367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379,
            380, 381, 382, 383, 402, 506, 507, 508, 509, 510, 511, 536, 537,
            538, 539, 710, 711, 713, 728, 729, 730, 731, 732, 733, 900, 901,
            902, 903, 904, 905, 906, 908, 910, 911, 912, 913, 914, 915, 916,
            917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927, 928, 929,
            931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943,
            944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956,
            957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967, 968, 969,
            970, 971, 972, 973, 974, 1024, 1025, 1026, 1027, 1028, 1029, 1030,
            1031, 1032, 1033, 1034, 1035, 1036, 1037, 1038, 1039, 1040, 1041,
            1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052,
            1053, 1054, 1055, 1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063,
            1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071, 1072, 1073, 1074,
            1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085,
            1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096,
            1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107,
            1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
            1119, 1138, 1139, 1168, 1169, 7808, 7809, 7810, 7811, 7812, 7813,
            7922, 7923, 8208, 8209, 8211, 8212, 8213, 8215, 8216, 8217, 8218,
            8219, 8220, 8221, 8222, 8224, 8225, 8226, 8230, 8240, 8242, 8243,
            8249, 8250, 8252, 8254, 8260, 8319, 8355, 8356, 8359, 8364, 8453,
            8467, 8470, 8482, 8486, 8494, 8539, 8540, 8541, 8542, 8592, 8593,
            8594, 8595, 8596, 8597, 8616, 8706, 8710, 8719, 8721, 8722, 8730,
            8734, 8735, 8745, 8747, 8776, 8800, 8801, 8804, 8805, 8962, 8976,
            8992, 8993, 9472, 9474, 9484, 9488, 9492, 9496, 9500, 9508, 9516,
            9524, 9532, 9552, 9553, 9554, 9555, 9556, 9557, 9558, 9559, 9560,
            9561, 9562, 9563, 9564, 9565, 9566, 9567, 9568, 9569, 9570, 9571,
            9572, 9573, 9574, 9575, 9576, 9577, 9578, 9579, 9580, 9600, 9604,
            9608, 9612, 9616, 9617, 9618, 9619, 9632, 9633, 9642, 9643, 9644,
            9650, 9658, 9660, 9668, 9674, 9675, 9679, 9688, 9689, 9702, 9786,
            9787, 9788, 9792, 9794, 9824, 9827, 9829, 9830, 9834, 9835, 9836,
            61441, 61442, 61445, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
            -1, -1, -1, -1, -1,
          ];
          t.LiberationSansRegularWidths = [
            365, 0, 333, 278, 278, 355, 556, 556, 889, 667, 191, 333, 333, 389,
            584, 278, 333, 278, 278, 556, 556, 556, 556, 556, 556, 556, 556,
            556, 556, 278, 278, 584, 584, 584, 556, 1015, 667, 667, 722, 722,
            667, 611, 778, 722, 278, 500, 667, 556, 833, 722, 778, 667, 778,
            722, 667, 611, 722, 667, 944, 667, 667, 611, 278, 278, 278, 469,
            556, 333, 556, 556, 500, 556, 556, 278, 556, 556, 222, 222, 500,
            222, 833, 556, 556, 556, 556, 333, 500, 278, 556, 500, 722, 500,
            500, 500, 334, 260, 334, 584, 333, 556, 556, 556, 556, 260, 556,
            333, 737, 370, 556, 584, 737, 552, 400, 549, 333, 333, 333, 576,
            537, 278, 333, 333, 365, 556, 834, 834, 834, 611, 667, 667, 667,
            667, 667, 667, 1e3, 722, 667, 667, 667, 667, 278, 278, 278, 278,
            722, 722, 778, 778, 778, 778, 778, 584, 778, 722, 722, 722, 722,
            667, 667, 611, 556, 556, 556, 556, 556, 556, 889, 500, 556, 556,
            556, 556, 278, 278, 278, 278, 556, 556, 556, 556, 556, 556, 556,
            549, 611, 556, 556, 556, 556, 500, 556, 500, 667, 556, 667, 556,
            667, 556, 722, 500, 722, 500, 722, 500, 722, 500, 722, 615, 722,
            556, 667, 556, 667, 556, 667, 556, 667, 556, 667, 556, 778, 556,
            778, 556, 778, 556, 778, 556, 722, 556, 722, 556, 278, 278, 278,
            278, 278, 278, 278, 222, 278, 278, 735, 444, 500, 222, 667, 500,
            500, 556, 222, 556, 222, 556, 292, 556, 334, 556, 222, 722, 556,
            722, 556, 722, 556, 604, 723, 556, 778, 556, 778, 556, 778, 556,
            1e3, 944, 722, 333, 722, 333, 722, 333, 667, 500, 667, 500, 667,
            500, 667, 500, 611, 278, 611, 375, 611, 278, 722, 556, 722, 556,
            722, 556, 722, 556, 722, 556, 722, 556, 944, 722, 667, 500, 667,
            611, 500, 611, 500, 611, 500, 222, 556, 667, 556, 1e3, 889, 778,
            611, 667, 500, 611, 278, 333, 333, 333, 333, 333, 333, 333, 333,
            333, 333, 333, 667, 278, 784, 838, 384, 774, 855, 752, 222, 667,
            667, 551, 668, 667, 611, 722, 778, 278, 667, 668, 833, 722, 650,
            778, 722, 667, 618, 611, 667, 798, 667, 835, 748, 278, 667, 578,
            446, 556, 222, 547, 578, 575, 500, 557, 446, 441, 556, 556, 222,
            500, 500, 576, 500, 448, 556, 690, 569, 482, 617, 395, 547, 648,
            525, 713, 781, 222, 547, 556, 547, 781, 667, 667, 865, 542, 719,
            667, 278, 278, 500, 1057, 1010, 854, 583, 722, 635, 719, 667, 656,
            667, 542, 677, 667, 923, 604, 719, 719, 583, 656, 833, 722, 778,
            719, 667, 722, 611, 635, 760, 667, 740, 667, 917, 938, 792, 885,
            656, 719, 1010, 722, 556, 573, 531, 365, 583, 556, 669, 458, 559,
            559, 438, 583, 688, 552, 556, 542, 556, 500, 458, 500, 823, 500,
            573, 521, 802, 823, 625, 719, 521, 510, 750, 542, 556, 556, 556,
            365, 510, 500, 222, 278, 222, 906, 812, 556, 438, 559, 500, 552,
            778, 556, 489, 411, 944, 722, 944, 722, 944, 722, 667, 500, 333,
            333, 556, 1e3, 1e3, 552, 222, 222, 222, 222, 333, 333, 333, 556,
            556, 350, 1e3, 1e3, 188, 354, 333, 333, 500, 333, 167, 365, 556,
            556, 1094, 556, 885, 323, 1073, 1e3, 768, 600, 834, 834, 834, 834,
            1e3, 500, 1e3, 500, 1e3, 500, 500, 494, 612, 823, 713, 584, 549,
            713, 979, 719, 274, 549, 549, 583, 549, 549, 604, 584, 604, 604,
            708, 625, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708, 708,
            708, 708, 708, 708, 708, 708, 708, 708, 729, 604, 604, 354, 354,
            1e3, 990, 990, 990, 990, 494, 604, 604, 604, 604, 354, 1021, 1052,
            917, 750, 750, 531, 656, 594, 510, 500, 750, 750, 500, 500, 333,
            333, 333, 333, 333, 333, 333, 333, 222, 222, 294, 294, 324, 324,
            316, 328, 398, 285,
          ];
          t.LiberationSansRegularMapping = [
            -1, -1, -1, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45,
            46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62,
            63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
            80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96,
            97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
            111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
            124, 125, 126, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
            171, 172, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184,
            185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197,
            198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210,
            211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223,
            224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236,
            237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249,
            250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 262,
            263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275,
            276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288,
            289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301,
            302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314,
            315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327,
            328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340,
            341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353,
            354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366,
            367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379,
            380, 381, 382, 383, 402, 506, 507, 508, 509, 510, 511, 536, 537,
            538, 539, 710, 711, 713, 728, 729, 730, 731, 732, 733, 900, 901,
            902, 903, 904, 905, 906, 908, 910, 911, 912, 913, 914, 915, 916,
            917, 918, 919, 920, 921, 922, 923, 924, 925, 926, 927, 928, 929,
            931, 932, 933, 934, 935, 936, 937, 938, 939, 940, 941, 942, 943,
            944, 945, 946, 947, 948, 949, 950, 951, 952, 953, 954, 955, 956,
            957, 958, 959, 960, 961, 962, 963, 964, 965, 966, 967, 968, 969,
            970, 971, 972, 973, 974, 1024, 1025, 1026, 1027, 1028, 1029, 1030,
            1031, 1032, 1033, 1034, 1035, 1036, 1037, 1038, 1039, 1040, 1041,
            1042, 1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051, 1052,
            1053, 1054, 1055, 1056, 1057, 1058, 1059, 1060, 1061, 1062, 1063,
            1064, 1065, 1066, 1067, 1068, 1069, 1070, 1071, 1072, 1073, 1074,
            1075, 1076, 1077, 1078, 1079, 1080, 1081, 1082, 1083, 1084, 1085,
            1086, 1087, 1088, 1089, 1090, 1091, 1092, 1093, 1094, 1095, 1096,
            1097, 1098, 1099, 1100, 1101, 1102, 1103, 1104, 1105, 1106, 1107,
            1108, 1109, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1118,
            1119, 1138, 1139, 1168, 1169, 7808, 7809, 7810, 7811, 7812, 7813,
            7922, 7923, 8208, 8209, 8211, 8212, 8213, 8215, 8216, 8217, 8218,
            8219, 8220, 8221, 8222, 8224, 8225, 8226, 8230, 8240, 8242, 8243,
            8249, 8250, 8252, 8254, 8260, 8319, 8355, 8356, 8359, 8364, 8453,
            8467, 8470, 8482, 8486, 8494, 8539, 8540, 8541, 8542, 8592, 8593,
            8594, 8595, 8596, 8597, 8616, 8706, 8710, 8719, 8721, 8722, 8730,
            8734, 8735, 8745, 8747, 8776, 8800, 8801, 8804, 8805, 8962, 8976,
            8992, 8993, 9472, 9474, 9484, 9488, 9492, 9496, 9500, 9508, 9516,
            9524, 9532, 9552, 9553, 9554, 9555, 9556, 9557, 9558, 9559, 9560,
            9561, 9562, 9563, 9564, 9565, 9566, 9567, 9568, 9569, 9570, 9571,
            9572, 9573, 9574, 9575, 9576, 9577, 9578, 9579, 9580, 9600, 9604,
            9608, 9612, 9616, 9617, 9618, 9619, 9632, 9633, 9642, 9643, 9644,
            9650, 9658, 9660, 9668, 9674, 9675, 9679, 9688, 9689, 9702, 9786,
            9787, 9788, 9792, 9794, 9824, 9827, 9829, 9830, 9834, 9835, 9836,
            61441, 61442, 61445, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
            -1, -1, -1, -1, -1,
          ];
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.MyriadProRegularMetrics =
            t.MyriadProRegularFactors =
            t.MyriadProItalicMetrics =
            t.MyriadProItalicFactors =
            t.MyriadProBoldMetrics =
            t.MyriadProBoldItalicMetrics =
            t.MyriadProBoldItalicFactors =
            t.MyriadProBoldFactors =
              void 0;
          t.MyriadProBoldFactors = [
            1.36898, 1, 1, 0.72706, 0.80479, 0.83734, 0.98894, 0.99793, 0.9897,
            0.93884, 0.86209, 0.94292, 0.94292, 1.16661, 1.02058, 0.93582,
            0.96694, 0.93582, 1.19137, 0.99793, 0.99793, 0.99793, 0.99793,
            0.99793, 0.99793, 0.99793, 0.99793, 0.99793, 0.99793, 0.78076,
            0.78076, 1.02058, 1.02058, 1.02058, 0.72851, 0.78966, 0.90838,
            0.83637, 0.82391, 0.96376, 0.80061, 0.86275, 0.8768, 0.95407,
            1.0258, 0.73901, 0.85022, 0.83655, 1.0156, 0.95546, 0.92179,
            0.87107, 0.92179, 0.82114, 0.8096, 0.89713, 0.94438, 0.95353,
            0.94083, 0.91905, 0.90406, 0.9446, 0.94292, 1.18777, 0.94292,
            1.02058, 0.89903, 0.90088, 0.94938, 0.97898, 0.81093, 0.97571,
            0.94938, 1.024, 0.9577, 0.95933, 0.98621, 1.0474, 0.97455, 0.98981,
            0.9672, 0.95933, 0.9446, 0.97898, 0.97407, 0.97646, 0.78036,
            1.10208, 0.95442, 0.95298, 0.97579, 0.9332, 0.94039, 0.938, 0.80687,
            1.01149, 0.80687, 1.02058, 0.80479, 0.99793, 0.99793, 0.99793,
            0.99793, 1.01149, 1.00872, 0.90088, 0.91882, 1.0213, 0.8361,
            1.02058, 0.62295, 0.54324, 0.89022, 1.08595, 1, 1, 0.90088, 1,
            0.97455, 0.93582, 0.90088, 1, 1.05686, 0.8361, 0.99642, 0.99642,
            0.99642, 0.72851, 0.90838, 0.90838, 0.90838, 0.90838, 0.90838,
            0.90838, 0.868, 0.82391, 0.80061, 0.80061, 0.80061, 0.80061, 1.0258,
            1.0258, 1.0258, 1.0258, 0.97484, 0.95546, 0.92179, 0.92179, 0.92179,
            0.92179, 0.92179, 1.02058, 0.92179, 0.94438, 0.94438, 0.94438,
            0.94438, 0.90406, 0.86958, 0.98225, 0.94938, 0.94938, 0.94938,
            0.94938, 0.94938, 0.94938, 0.9031, 0.81093, 0.94938, 0.94938,
            0.94938, 0.94938, 0.98621, 0.98621, 0.98621, 0.98621, 0.93969,
            0.95933, 0.9446, 0.9446, 0.9446, 0.9446, 0.9446, 1.08595, 0.9446,
            0.95442, 0.95442, 0.95442, 0.95442, 0.94039, 0.97898, 0.94039,
            0.90838, 0.94938, 0.90838, 0.94938, 0.90838, 0.94938, 0.82391,
            0.81093, 0.82391, 0.81093, 0.82391, 0.81093, 0.82391, 0.81093,
            0.96376, 0.84313, 0.97484, 0.97571, 0.80061, 0.94938, 0.80061,
            0.94938, 0.80061, 0.94938, 0.80061, 0.94938, 0.80061, 0.94938,
            0.8768, 0.9577, 0.8768, 0.9577, 0.8768, 0.9577, 1, 1, 0.95407,
            0.95933, 0.97069, 0.95933, 1.0258, 0.98621, 1.0258, 0.98621, 1.0258,
            0.98621, 1.0258, 0.98621, 1.0258, 0.98621, 0.887, 1.01591, 0.73901,
            1.0474, 1, 1, 0.97455, 0.83655, 0.98981, 1, 1, 0.83655, 0.73977,
            0.83655, 0.73903, 0.84638, 1.033, 0.95546, 0.95933, 1, 1, 0.95546,
            0.95933, 0.8271, 0.95417, 0.95933, 0.92179, 0.9446, 0.92179, 0.9446,
            0.92179, 0.9446, 0.936, 0.91964, 0.82114, 0.97646, 1, 1, 0.82114,
            0.97646, 0.8096, 0.78036, 0.8096, 0.78036, 1, 1, 0.8096, 0.78036, 1,
            1, 0.89713, 0.77452, 0.89713, 1.10208, 0.94438, 0.95442, 0.94438,
            0.95442, 0.94438, 0.95442, 0.94438, 0.95442, 0.94438, 0.95442,
            0.94438, 0.95442, 0.94083, 0.97579, 0.90406, 0.94039, 0.90406,
            0.9446, 0.938, 0.9446, 0.938, 0.9446, 0.938, 1, 0.99793, 0.90838,
            0.94938, 0.868, 0.9031, 0.92179, 0.9446, 1, 1, 0.89713, 1.10208,
            0.90088, 0.90088, 0.90088, 0.90088, 0.90088, 0.90088, 0.90088,
            0.90088, 0.90088, 0.90989, 0.9358, 0.91945, 0.83181, 0.75261,
            0.87992, 0.82976, 0.96034, 0.83689, 0.97268, 1.0078, 0.90838,
            0.83637, 0.8019, 0.90157, 0.80061, 0.9446, 0.95407, 0.92436, 1.0258,
            0.85022, 0.97153, 1.0156, 0.95546, 0.89192, 0.92179, 0.92361,
            0.87107, 0.96318, 0.89713, 0.93704, 0.95638, 0.91905, 0.91709,
            0.92796, 1.0258, 0.93704, 0.94836, 1.0373, 0.95933, 1.0078, 0.95871,
            0.94836, 0.96174, 0.92601, 0.9498, 0.98607, 0.95776, 0.95933,
            1.05453, 1.0078, 0.98275, 0.9314, 0.95617, 0.91701, 1.05993, 0.9446,
            0.78367, 0.9553, 1, 0.86832, 1.0128, 0.95871, 0.99394, 0.87548,
            0.96361, 0.86774, 1.0078, 0.95871, 0.9446, 0.95871, 0.86774, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 0.94083, 0.97579, 0.94083, 0.97579, 0.94083,
            0.97579, 0.90406, 0.94039, 0.96694, 1, 0.89903, 1, 1, 1, 0.93582,
            0.93582, 0.93582, 1, 0.908, 0.908, 0.918, 0.94219, 0.94219, 0.96544,
            1, 1.285, 1, 1, 0.81079, 0.81079, 1, 1, 0.74854, 1, 1, 1, 1,
            0.99793, 1, 1, 1, 0.65, 1, 1.36145, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1.17173, 1, 0.80535, 0.76169, 1.02058, 1.0732, 1.05486, 1, 1,
            1.30692, 1.08595, 1.08595, 1, 1.08595, 1.08595, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1.16161, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1,
          ];
          t.MyriadProBoldMetrics = { lineHeight: 1.2, lineGap: 0.2 };
          t.MyriadProBoldItalicFactors = [
            1.36898, 1, 1, 0.66227, 0.80779, 0.81625, 0.97276, 0.97276, 0.97733,
            0.92222, 0.83266, 0.94292, 0.94292, 1.16148, 1.02058, 0.93582,
            0.96694, 0.93582, 1.17337, 0.97276, 0.97276, 0.97276, 0.97276,
            0.97276, 0.97276, 0.97276, 0.97276, 0.97276, 0.97276, 0.78076,
            0.78076, 1.02058, 1.02058, 1.02058, 0.71541, 0.76813, 0.85576,
            0.80591, 0.80729, 0.94299, 0.77512, 0.83655, 0.86523, 0.92222,
            0.98621, 0.71743, 0.81698, 0.79726, 0.98558, 0.92222, 0.90637,
            0.83809, 0.90637, 0.80729, 0.76463, 0.86275, 0.90699, 0.91605,
            0.9154, 0.85308, 0.85458, 0.90531, 0.94292, 1.21296, 0.94292,
            1.02058, 0.89903, 1.18616, 0.99613, 0.91677, 0.78216, 0.91677,
            0.90083, 0.98796, 0.9135, 0.92168, 0.95381, 0.98981, 0.95298,
            0.95381, 0.93459, 0.92168, 0.91513, 0.92004, 0.91677, 0.95077,
            0.748, 1.04502, 0.91677, 0.92061, 0.94236, 0.89544, 0.89364, 0.9,
            0.80687, 0.8578, 0.80687, 1.02058, 0.80779, 0.97276, 0.97276,
            0.97276, 0.97276, 0.8578, 0.99973, 1.18616, 0.91339, 1.08074,
            0.82891, 1.02058, 0.55509, 0.71526, 0.89022, 1.08595, 1, 1, 1.18616,
            1, 0.96736, 0.93582, 1.18616, 1, 1.04864, 0.82711, 0.99043, 0.99043,
            0.99043, 0.71541, 0.85576, 0.85576, 0.85576, 0.85576, 0.85576,
            0.85576, 0.845, 0.80729, 0.77512, 0.77512, 0.77512, 0.77512,
            0.98621, 0.98621, 0.98621, 0.98621, 0.95961, 0.92222, 0.90637,
            0.90637, 0.90637, 0.90637, 0.90637, 1.02058, 0.90251, 0.90699,
            0.90699, 0.90699, 0.90699, 0.85458, 0.83659, 0.94951, 0.99613,
            0.99613, 0.99613, 0.99613, 0.99613, 0.99613, 0.85811, 0.78216,
            0.90083, 0.90083, 0.90083, 0.90083, 0.95381, 0.95381, 0.95381,
            0.95381, 0.9135, 0.92168, 0.91513, 0.91513, 0.91513, 0.91513,
            0.91513, 1.08595, 0.91677, 0.91677, 0.91677, 0.91677, 0.91677,
            0.89364, 0.92332, 0.89364, 0.85576, 0.99613, 0.85576, 0.99613,
            0.85576, 0.99613, 0.80729, 0.78216, 0.80729, 0.78216, 0.80729,
            0.78216, 0.80729, 0.78216, 0.94299, 0.76783, 0.95961, 0.91677,
            0.77512, 0.90083, 0.77512, 0.90083, 0.77512, 0.90083, 0.77512,
            0.90083, 0.77512, 0.90083, 0.86523, 0.9135, 0.86523, 0.9135,
            0.86523, 0.9135, 1, 1, 0.92222, 0.92168, 0.92222, 0.92168, 0.98621,
            0.95381, 0.98621, 0.95381, 0.98621, 0.95381, 0.98621, 0.95381,
            0.98621, 0.95381, 0.86036, 0.97096, 0.71743, 0.98981, 1, 1, 0.95298,
            0.79726, 0.95381, 1, 1, 0.79726, 0.6894, 0.79726, 0.74321, 0.81691,
            1.0006, 0.92222, 0.92168, 1, 1, 0.92222, 0.92168, 0.79464, 0.92098,
            0.92168, 0.90637, 0.91513, 0.90637, 0.91513, 0.90637, 0.91513,
            0.909, 0.87514, 0.80729, 0.95077, 1, 1, 0.80729, 0.95077, 0.76463,
            0.748, 0.76463, 0.748, 1, 1, 0.76463, 0.748, 1, 1, 0.86275, 0.72651,
            0.86275, 1.04502, 0.90699, 0.91677, 0.90699, 0.91677, 0.90699,
            0.91677, 0.90699, 0.91677, 0.90699, 0.91677, 0.90699, 0.91677,
            0.9154, 0.94236, 0.85458, 0.89364, 0.85458, 0.90531, 0.9, 0.90531,
            0.9, 0.90531, 0.9, 1, 0.97276, 0.85576, 0.99613, 0.845, 0.85811,
            0.90251, 0.91677, 1, 1, 0.86275, 1.04502, 1.18616, 1.18616, 1.18616,
            1.18616, 1.18616, 1.18616, 1.18616, 1.18616, 1.18616, 1.00899,
            1.30628, 0.85576, 0.80178, 0.66862, 0.7927, 0.69323, 0.88127,
            0.72459, 0.89711, 0.95381, 0.85576, 0.80591, 0.7805, 0.94729,
            0.77512, 0.90531, 0.92222, 0.90637, 0.98621, 0.81698, 0.92655,
            0.98558, 0.92222, 0.85359, 0.90637, 0.90976, 0.83809, 0.94523,
            0.86275, 0.83509, 0.93157, 0.85308, 0.83392, 0.92346, 0.98621,
            0.83509, 0.92886, 0.91324, 0.92168, 0.95381, 0.90646, 0.92886,
            0.90557, 0.86847, 0.90276, 0.91324, 0.86842, 0.92168, 0.99531,
            0.95381, 0.9224, 0.85408, 0.92699, 0.86847, 1.0051, 0.91513,
            0.80487, 0.93481, 1, 0.88159, 1.05214, 0.90646, 0.97355, 0.81539,
            0.89398, 0.85923, 0.95381, 0.90646, 0.91513, 0.90646, 0.85923, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 0.9154, 0.94236, 0.9154, 0.94236, 0.9154, 0.94236,
            0.85458, 0.89364, 0.96694, 1, 0.89903, 1, 1, 1, 0.91782, 0.91782,
            0.91782, 1, 0.896, 0.896, 0.896, 0.9332, 0.9332, 0.95973, 1, 1.26,
            1, 1, 0.80479, 0.80178, 1, 1, 0.85633, 1, 1, 1, 1, 0.97276, 1, 1, 1,
            0.698, 1, 1.36145, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.14542, 1,
            0.79199, 0.78694, 1.02058, 1.03493, 1.05486, 1, 1, 1.23026, 1.08595,
            1.08595, 1, 1.08595, 1.08595, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1.20006, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.MyriadProBoldItalicMetrics = { lineHeight: 1.2, lineGap: 0.2 };
          t.MyriadProItalicFactors = [
            1.36898, 1, 1, 0.65507, 0.84943, 0.85639, 0.88465, 0.88465, 0.86936,
            0.88307, 0.86948, 0.85283, 0.85283, 1.06383, 1.02058, 0.75945,
            0.9219, 0.75945, 1.17337, 0.88465, 0.88465, 0.88465, 0.88465,
            0.88465, 0.88465, 0.88465, 0.88465, 0.88465, 0.88465, 0.75945,
            0.75945, 1.02058, 1.02058, 1.02058, 0.69046, 0.70926, 0.85158,
            0.77812, 0.76852, 0.89591, 0.70466, 0.76125, 0.80094, 0.86822,
            0.83864, 0.728, 0.77212, 0.79475, 0.93637, 0.87514, 0.8588, 0.76013,
            0.8588, 0.72421, 0.69866, 0.77598, 0.85991, 0.80811, 0.87832,
            0.78112, 0.77512, 0.8562, 1.0222, 1.18417, 1.0222, 1.27014, 0.89903,
            1.15012, 0.93859, 0.94399, 0.846, 0.94399, 0.81453, 1.0186, 0.94219,
            0.96017, 1.03075, 1.02175, 0.912, 1.03075, 0.96998, 0.96017,
            0.93859, 0.94399, 0.94399, 0.95493, 0.746, 1.12658, 0.94578, 0.91,
            0.979, 0.882, 0.882, 0.83, 0.85034, 0.83537, 0.85034, 1.02058,
            0.70869, 0.88465, 0.88465, 0.88465, 0.88465, 0.83537, 0.90083,
            1.15012, 0.9161, 0.94565, 0.73541, 1.02058, 0.53609, 0.69353,
            0.79519, 1.08595, 1, 1, 1.15012, 1, 0.91974, 0.75945, 1.15012, 1,
            0.9446, 0.73361, 0.9005, 0.9005, 0.9005, 0.62864, 0.85158, 0.85158,
            0.85158, 0.85158, 0.85158, 0.85158, 0.773, 0.76852, 0.70466,
            0.70466, 0.70466, 0.70466, 0.83864, 0.83864, 0.83864, 0.83864,
            0.90561, 0.87514, 0.8588, 0.8588, 0.8588, 0.8588, 0.8588, 1.02058,
            0.85751, 0.85991, 0.85991, 0.85991, 0.85991, 0.77512, 0.76013,
            0.88075, 0.93859, 0.93859, 0.93859, 0.93859, 0.93859, 0.93859,
            0.8075, 0.846, 0.81453, 0.81453, 0.81453, 0.81453, 0.82424, 0.82424,
            0.82424, 0.82424, 0.9278, 0.96017, 0.93859, 0.93859, 0.93859,
            0.93859, 0.93859, 1.08595, 0.8562, 0.94578, 0.94578, 0.94578,
            0.94578, 0.882, 0.94578, 0.882, 0.85158, 0.93859, 0.85158, 0.93859,
            0.85158, 0.93859, 0.76852, 0.846, 0.76852, 0.846, 0.76852, 0.846,
            0.76852, 0.846, 0.89591, 0.8544, 0.90561, 0.94399, 0.70466, 0.81453,
            0.70466, 0.81453, 0.70466, 0.81453, 0.70466, 0.81453, 0.70466,
            0.81453, 0.80094, 0.94219, 0.80094, 0.94219, 0.80094, 0.94219, 1, 1,
            0.86822, 0.96017, 0.86822, 0.96017, 0.83864, 0.82424, 0.83864,
            0.82424, 0.83864, 0.82424, 0.83864, 1.03075, 0.83864, 0.82424,
            0.81402, 1.02738, 0.728, 1.02175, 1, 1, 0.912, 0.79475, 1.03075, 1,
            1, 0.79475, 0.83911, 0.79475, 0.66266, 0.80553, 1.06676, 0.87514,
            0.96017, 1, 1, 0.87514, 0.96017, 0.86865, 0.87396, 0.96017, 0.8588,
            0.93859, 0.8588, 0.93859, 0.8588, 0.93859, 0.867, 0.84759, 0.72421,
            0.95493, 1, 1, 0.72421, 0.95493, 0.69866, 0.746, 0.69866, 0.746, 1,
            1, 0.69866, 0.746, 1, 1, 0.77598, 0.88417, 0.77598, 1.12658,
            0.85991, 0.94578, 0.85991, 0.94578, 0.85991, 0.94578, 0.85991,
            0.94578, 0.85991, 0.94578, 0.85991, 0.94578, 0.87832, 0.979,
            0.77512, 0.882, 0.77512, 0.8562, 0.83, 0.8562, 0.83, 0.8562, 0.83,
            1, 0.88465, 0.85158, 0.93859, 0.773, 0.8075, 0.85751, 0.8562, 1, 1,
            0.77598, 1.12658, 1.15012, 1.15012, 1.15012, 1.15012, 1.15012,
            1.15313, 1.15012, 1.15012, 1.15012, 1.08106, 1.03901, 0.85158,
            0.77025, 0.62264, 0.7646, 0.65351, 0.86026, 0.69461, 0.89947,
            1.03075, 0.85158, 0.77812, 0.76449, 0.88836, 0.70466, 0.8562,
            0.86822, 0.8588, 0.83864, 0.77212, 0.85308, 0.93637, 0.87514,
            0.82352, 0.8588, 0.85701, 0.76013, 0.89058, 0.77598, 0.8156,
            0.82565, 0.78112, 0.77899, 0.89386, 0.83864, 0.8156, 0.9486,
            0.92388, 0.96186, 1.03075, 0.91123, 0.9486, 0.93298, 0.878, 0.93942,
            0.92388, 0.84596, 0.96186, 0.95119, 1.03075, 0.922, 0.88787,
            0.95829, 0.88, 0.93559, 0.93859, 0.78815, 0.93758, 1, 0.89217,
            1.03737, 0.91123, 0.93969, 0.77487, 0.85769, 0.86799, 1.03075,
            0.91123, 0.93859, 0.91123, 0.86799, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.87832,
            0.979, 0.87832, 0.979, 0.87832, 0.979, 0.77512, 0.882, 0.9219, 1,
            0.89903, 1, 1, 1, 0.87321, 0.87321, 0.87321, 1, 1.027, 1.027, 1.027,
            0.86847, 0.86847, 0.79121, 1, 1.124, 1, 1, 0.73572, 0.73572, 1, 1,
            0.85034, 1, 1, 1, 1, 0.88465, 1, 1, 1, 0.669, 1, 1.36145, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1.04828, 1, 0.74948, 0.75187, 1.02058,
            0.98391, 1.02119, 1, 1, 1.06233, 1.08595, 1.08595, 1, 1.08595,
            1.08595, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.05233, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.MyriadProItalicMetrics = { lineHeight: 1.2, lineGap: 0.2 };
          t.MyriadProRegularFactors = [
            1.36898, 1, 1, 0.76305, 0.82784, 0.94935, 0.89364, 0.92241, 0.89073,
            0.90706, 0.98472, 0.85283, 0.85283, 1.0664, 1.02058, 0.74505,
            0.9219, 0.74505, 1.23456, 0.92241, 0.92241, 0.92241, 0.92241,
            0.92241, 0.92241, 0.92241, 0.92241, 0.92241, 0.92241, 0.74505,
            0.74505, 1.02058, 1.02058, 1.02058, 0.73002, 0.72601, 0.91755,
            0.8126, 0.80314, 0.92222, 0.73764, 0.79726, 0.83051, 0.90284,
            0.86023, 0.74, 0.8126, 0.84869, 0.96518, 0.91115, 0.8858, 0.79761,
            0.8858, 0.74498, 0.73914, 0.81363, 0.89591, 0.83659, 0.89633,
            0.85608, 0.8111, 0.90531, 1.0222, 1.22736, 1.0222, 1.27014, 0.89903,
            0.90088, 0.86667, 1.0231, 0.896, 1.01411, 0.90083, 1.05099, 1.00512,
            0.99793, 1.05326, 1.09377, 0.938, 1.06226, 1.00119, 0.99793,
            0.98714, 1.0231, 1.01231, 0.98196, 0.792, 1.19137, 0.99074, 0.962,
            1.01915, 0.926, 0.942, 0.856, 0.85034, 0.92006, 0.85034, 1.02058,
            0.69067, 0.92241, 0.92241, 0.92241, 0.92241, 0.92006, 0.9332,
            0.90088, 0.91882, 0.93484, 0.75339, 1.02058, 0.56866, 0.54324,
            0.79519, 1.08595, 1, 1, 0.90088, 1, 0.95325, 0.74505, 0.90088, 1,
            0.97198, 0.75339, 0.91009, 0.91009, 0.91009, 0.66466, 0.91755,
            0.91755, 0.91755, 0.91755, 0.91755, 0.91755, 0.788, 0.80314,
            0.73764, 0.73764, 0.73764, 0.73764, 0.86023, 0.86023, 0.86023,
            0.86023, 0.92915, 0.91115, 0.8858, 0.8858, 0.8858, 0.8858, 0.8858,
            1.02058, 0.8858, 0.89591, 0.89591, 0.89591, 0.89591, 0.8111,
            0.79611, 0.89713, 0.86667, 0.86667, 0.86667, 0.86667, 0.86667,
            0.86667, 0.86936, 0.896, 0.90083, 0.90083, 0.90083, 0.90083,
            0.84224, 0.84224, 0.84224, 0.84224, 0.97276, 0.99793, 0.98714,
            0.98714, 0.98714, 0.98714, 0.98714, 1.08595, 0.89876, 0.99074,
            0.99074, 0.99074, 0.99074, 0.942, 1.0231, 0.942, 0.91755, 0.86667,
            0.91755, 0.86667, 0.91755, 0.86667, 0.80314, 0.896, 0.80314, 0.896,
            0.80314, 0.896, 0.80314, 0.896, 0.92222, 0.93372, 0.92915, 1.01411,
            0.73764, 0.90083, 0.73764, 0.90083, 0.73764, 0.90083, 0.73764,
            0.90083, 0.73764, 0.90083, 0.83051, 1.00512, 0.83051, 1.00512,
            0.83051, 1.00512, 1, 1, 0.90284, 0.99793, 0.90976, 0.99793, 0.86023,
            0.84224, 0.86023, 0.84224, 0.86023, 0.84224, 0.86023, 1.05326,
            0.86023, 0.84224, 0.82873, 1.07469, 0.74, 1.09377, 1, 1, 0.938,
            0.84869, 1.06226, 1, 1, 0.84869, 0.83704, 0.84869, 0.81441, 0.85588,
            1.08927, 0.91115, 0.99793, 1, 1, 0.91115, 0.99793, 0.91887, 0.90991,
            0.99793, 0.8858, 0.98714, 0.8858, 0.98714, 0.8858, 0.98714, 0.894,
            0.91434, 0.74498, 0.98196, 1, 1, 0.74498, 0.98196, 0.73914, 0.792,
            0.73914, 0.792, 1, 1, 0.73914, 0.792, 1, 1, 0.81363, 0.904, 0.81363,
            1.19137, 0.89591, 0.99074, 0.89591, 0.99074, 0.89591, 0.99074,
            0.89591, 0.99074, 0.89591, 0.99074, 0.89591, 0.99074, 0.89633,
            1.01915, 0.8111, 0.942, 0.8111, 0.90531, 0.856, 0.90531, 0.856,
            0.90531, 0.856, 1, 0.92241, 0.91755, 0.86667, 0.788, 0.86936,
            0.8858, 0.89876, 1, 1, 0.81363, 1.19137, 0.90088, 0.90088, 0.90088,
            0.90088, 0.90088, 0.90088, 0.90088, 0.90088, 0.90088, 0.90388,
            1.03901, 0.92138, 0.78105, 0.7154, 0.86169, 0.80513, 0.94007,
            0.82528, 0.98612, 1.06226, 0.91755, 0.8126, 0.81884, 0.92819,
            0.73764, 0.90531, 0.90284, 0.8858, 0.86023, 0.8126, 0.91172,
            0.96518, 0.91115, 0.83089, 0.8858, 0.87791, 0.79761, 0.89297,
            0.81363, 0.88157, 0.89992, 0.85608, 0.81992, 0.94307, 0.86023,
            0.88157, 0.95308, 0.98699, 0.99793, 1.06226, 0.95817, 0.95308,
            0.97358, 0.928, 0.98088, 0.98699, 0.92761, 0.99793, 0.96017,
            1.06226, 0.986, 0.944, 0.95978, 0.938, 0.96705, 0.98714, 0.80442,
            0.98972, 1, 0.89762, 1.04552, 0.95817, 0.99007, 0.87064, 0.91879,
            0.88888, 1.06226, 0.95817, 0.98714, 0.95817, 0.88888, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 0.89633, 1.01915, 0.89633, 1.01915, 0.89633, 1.01915,
            0.8111, 0.942, 0.9219, 1, 0.89903, 1, 1, 1, 0.93173, 0.93173,
            0.93173, 1, 1.06304, 1.06304, 1.06904, 0.89903, 0.89903, 0.80549, 1,
            1.156, 1, 1, 0.76575, 0.76575, 1, 1, 0.72458, 1, 1, 1, 1, 0.92241,
            1, 1, 1, 0.619, 1, 1.36145, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1.07257, 1, 0.74705, 0.71119, 1.02058, 1.024, 1.02119, 1, 1, 1.1536,
            1.08595, 1.08595, 1, 1.08595, 1.08595, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1.05638, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.MyriadProRegularMetrics = { lineHeight: 1.2, lineGap: 0.2 };
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.SegoeuiRegularMetrics =
            t.SegoeuiRegularFactors =
            t.SegoeuiItalicMetrics =
            t.SegoeuiItalicFactors =
            t.SegoeuiBoldMetrics =
            t.SegoeuiBoldItalicMetrics =
            t.SegoeuiBoldItalicFactors =
            t.SegoeuiBoldFactors =
              void 0;
          t.SegoeuiBoldFactors = [
            1.76738, 1, 1, 0.99297, 0.9824, 1.04016, 1.06497, 1.03424, 0.97529,
            1.17647, 1.23203, 1.1085, 1.1085, 1.16939, 1.2107, 0.9754, 1.21408,
            0.9754, 1.59578, 1.03424, 1.03424, 1.03424, 1.03424, 1.03424,
            1.03424, 1.03424, 1.03424, 1.03424, 1.03424, 0.81378, 0.81378,
            1.2107, 1.2107, 1.2107, 0.71703, 0.97847, 0.97363, 0.88776, 0.8641,
            1.02096, 0.79795, 0.85132, 0.914, 1.06085, 1.1406, 0.8007, 0.89858,
            0.83693, 1.14889, 1.09398, 0.97489, 0.92094, 0.97489, 0.90399,
            0.84041, 0.95923, 1.00135, 1, 1.06467, 0.98243, 0.90996, 0.99361,
            1.1085, 1.56942, 1.1085, 1.2107, 0.74627, 0.94282, 0.96752, 1.01519,
            0.86304, 1.01359, 0.97278, 1.15103, 1.01359, 0.98561, 1.02285,
            1.02285, 1.00527, 1.02285, 1.0302, 0.99041, 1.0008, 1.01519,
            1.01359, 1.02258, 0.79104, 1.16862, 0.99041, 0.97454, 1.02511,
            0.99298, 0.96752, 0.95801, 0.94856, 1.16579, 0.94856, 1.2107,
            0.9824, 1.03424, 1.03424, 1, 1.03424, 1.16579, 0.8727, 1.3871,
            1.18622, 1.10818, 1.04478, 1.2107, 1.18622, 0.75155, 0.94994,
            1.28826, 1.21408, 1.21408, 0.91056, 1, 0.91572, 0.9754, 0.64663,
            1.18328, 1.24866, 1.04478, 1.14169, 1.15749, 1.17389, 0.71703,
            0.97363, 0.97363, 0.97363, 0.97363, 0.97363, 0.97363, 0.93506,
            0.8641, 0.79795, 0.79795, 0.79795, 0.79795, 1.1406, 1.1406, 1.1406,
            1.1406, 1.02096, 1.09398, 0.97426, 0.97426, 0.97426, 0.97426,
            0.97426, 1.2107, 0.97489, 1.00135, 1.00135, 1.00135, 1.00135,
            0.90996, 0.92094, 1.02798, 0.96752, 0.96752, 0.96752, 0.96752,
            0.96752, 0.96752, 0.93136, 0.86304, 0.97278, 0.97278, 0.97278,
            0.97278, 1.02285, 1.02285, 1.02285, 1.02285, 0.97122, 0.99041, 1, 1,
            1, 1, 1, 1.28826, 1.0008, 0.99041, 0.99041, 0.99041, 0.99041,
            0.96752, 1.01519, 0.96752, 0.97363, 0.96752, 0.97363, 0.96752,
            0.97363, 0.96752, 0.8641, 0.86304, 0.8641, 0.86304, 0.8641, 0.86304,
            0.8641, 0.86304, 1.02096, 1.03057, 1.02096, 1.03517, 0.79795,
            0.97278, 0.79795, 0.97278, 0.79795, 0.97278, 0.79795, 0.97278,
            0.79795, 0.97278, 0.914, 1.01359, 0.914, 1.01359, 0.914, 1.01359, 1,
            1, 1.06085, 0.98561, 1.06085, 1.00879, 1.1406, 1.02285, 1.1406,
            1.02285, 1.1406, 1.02285, 1.1406, 1.02285, 1.1406, 1.02285, 0.97138,
            1.08692, 0.8007, 1.02285, 1, 1, 1.00527, 0.83693, 1.02285, 1, 1,
            0.83693, 0.9455, 0.83693, 0.90418, 0.83693, 1.13005, 1.09398,
            0.99041, 1, 1, 1.09398, 0.99041, 0.96692, 1.09251, 0.99041, 0.97489,
            1.0008, 0.97489, 1.0008, 0.97489, 1.0008, 0.93994, 0.97931, 0.90399,
            1.02258, 1, 1, 0.90399, 1.02258, 0.84041, 0.79104, 0.84041, 0.79104,
            0.84041, 0.79104, 0.84041, 0.79104, 1, 1, 0.95923, 1.07034, 0.95923,
            1.16862, 1.00135, 0.99041, 1.00135, 0.99041, 1.00135, 0.99041,
            1.00135, 0.99041, 1.00135, 0.99041, 1.00135, 0.99041, 1.06467,
            1.02511, 0.90996, 0.96752, 0.90996, 0.99361, 0.95801, 0.99361,
            0.95801, 0.99361, 0.95801, 1.07733, 1.03424, 0.97363, 0.96752,
            0.93506, 0.93136, 0.97489, 1.0008, 1, 1, 0.95923, 1.16862, 1.15103,
            1.15103, 1.01173, 1.03959, 0.75953, 0.81378, 0.79912, 1.15103,
            1.21994, 0.95161, 0.87815, 1.01149, 0.81525, 0.7676, 0.98167,
            1.01134, 1.02546, 0.84097, 1.03089, 1.18102, 0.97363, 0.88776,
            0.85134, 0.97826, 0.79795, 0.99361, 1.06085, 0.97489, 1.1406,
            0.89858, 1.0388, 1.14889, 1.09398, 0.86039, 0.97489, 1.0595,
            0.92094, 0.94793, 0.95923, 0.90996, 0.99346, 0.98243, 1.02112,
            0.95493, 1.1406, 0.90996, 1.03574, 1.02597, 1.0008, 1.18102,
            1.06628, 1.03574, 1.0192, 1.01932, 1.00886, 0.97531, 1.0106, 1.0008,
            1.13189, 1.18102, 1.02277, 0.98683, 1.0016, 0.99561, 1.07237,
            1.0008, 0.90434, 0.99921, 0.93803, 0.8965, 1.23085, 1.06628,
            1.04983, 0.96268, 1.0499, 0.98439, 1.18102, 1.06628, 1.0008,
            1.06628, 0.98439, 0.79795, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1.09466, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            0.97278, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.02065, 1, 1, 1, 1, 1,
            1, 1.06467, 1.02511, 1.06467, 1.02511, 1.06467, 1.02511, 0.90996,
            0.96752, 1, 1.21408, 0.89903, 1, 1, 0.75155, 1.04394, 1.04394,
            1.04394, 1.04394, 0.98633, 0.98633, 0.98633, 0.73047, 0.73047,
            1.20642, 0.91211, 1.25635, 1.222, 1.02956, 1.03372, 1.03372,
            0.96039, 1.24633, 1, 1.12454, 0.93503, 1.03424, 1.19687, 1.03424, 1,
            1, 1, 0.771, 1, 1, 1.15749, 1.15749, 1.15749, 1.10948, 0.86279,
            0.94434, 0.86279, 0.94434, 0.86182, 1, 1, 1.16897, 1, 0.96085,
            0.90137, 1.2107, 1.18416, 1.13973, 0.69825, 0.9716, 2.10339,
            1.29004, 1.29004, 1.21172, 1.29004, 1.29004, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1.42603, 1, 0.99862, 0.99862, 1, 0.87025, 0.87025, 0.87025, 0.87025,
            1.18874, 1.42603, 1, 1.42603, 1.42603, 0.99862, 1, 1, 1, 1, 1,
            1.2886, 1.04315, 1.15296, 1.34163, 1, 1, 1, 1.09193, 1.09193, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.SegoeuiBoldMetrics = { lineHeight: 1.33008, lineGap: 0 };
          t.SegoeuiBoldItalicFactors = [
            1.76738, 1, 1, 0.98946, 1.03959, 1.04016, 1.02809, 1.036, 0.97639,
            1.10953, 1.23203, 1.11144, 1.11144, 1.16939, 1.21237, 0.9754,
            1.21261, 0.9754, 1.59754, 1.036, 1.036, 1.036, 1.036, 1.036, 1.036,
            1.036, 1.036, 1.036, 1.036, 0.81378, 0.81378, 1.21237, 1.21237,
            1.21237, 0.73541, 0.97847, 0.97363, 0.89723, 0.87897, 1.0426,
            0.79429, 0.85292, 0.91149, 1.05815, 1.1406, 0.79631, 0.90128,
            0.83853, 1.04396, 1.10615, 0.97552, 0.94436, 0.97552, 0.88641,
            0.80527, 0.96083, 1.00135, 1, 1.06777, 0.9817, 0.91142, 0.99361,
            1.11144, 1.57293, 1.11144, 1.21237, 0.74627, 1.31818, 1.06585,
            0.97042, 0.83055, 0.97042, 0.93503, 1.1261, 0.97042, 0.97922,
            1.14236, 0.94552, 1.01054, 1.14236, 1.02471, 0.97922, 0.94165,
            0.97042, 0.97042, 1.0276, 0.78929, 1.1261, 0.97922, 0.95874,
            1.02197, 0.98507, 0.96752, 0.97168, 0.95107, 1.16579, 0.95107,
            1.21237, 1.03959, 1.036, 1.036, 1, 1.036, 1.16579, 0.87357, 1.31818,
            1.18754, 1.26781, 1.05356, 1.21237, 1.18622, 0.79487, 0.94994,
            1.29004, 1.24047, 1.24047, 1.31818, 1, 0.91484, 0.9754, 1.31818,
            1.1349, 1.24866, 1.05356, 1.13934, 1.15574, 1.17389, 0.73541,
            0.97363, 0.97363, 0.97363, 0.97363, 0.97363, 0.97363, 0.94385,
            0.87897, 0.79429, 0.79429, 0.79429, 0.79429, 1.1406, 1.1406, 1.1406,
            1.1406, 1.0426, 1.10615, 0.97552, 0.97552, 0.97552, 0.97552,
            0.97552, 1.21237, 0.97552, 1.00135, 1.00135, 1.00135, 1.00135,
            0.91142, 0.94436, 0.98721, 1.06585, 1.06585, 1.06585, 1.06585,
            1.06585, 1.06585, 0.96705, 0.83055, 0.93503, 0.93503, 0.93503,
            0.93503, 1.14236, 1.14236, 1.14236, 1.14236, 0.93125, 0.97922,
            0.94165, 0.94165, 0.94165, 0.94165, 0.94165, 1.29004, 0.94165,
            0.97922, 0.97922, 0.97922, 0.97922, 0.96752, 0.97042, 0.96752,
            0.97363, 1.06585, 0.97363, 1.06585, 0.97363, 1.06585, 0.87897,
            0.83055, 0.87897, 0.83055, 0.87897, 0.83055, 0.87897, 0.83055,
            1.0426, 1.0033, 1.0426, 0.97042, 0.79429, 0.93503, 0.79429, 0.93503,
            0.79429, 0.93503, 0.79429, 0.93503, 0.79429, 0.93503, 0.91149,
            0.97042, 0.91149, 0.97042, 0.91149, 0.97042, 1, 1, 1.05815, 0.97922,
            1.05815, 0.97922, 1.1406, 1.14236, 1.1406, 1.14236, 1.1406, 1.14236,
            1.1406, 1.14236, 1.1406, 1.14236, 0.97441, 1.04302, 0.79631,
            1.01582, 1, 1, 1.01054, 0.83853, 1.14236, 1, 1, 0.83853, 1.09125,
            0.83853, 0.90418, 0.83853, 1.19508, 1.10615, 0.97922, 1, 1, 1.10615,
            0.97922, 1.01034, 1.10466, 0.97922, 0.97552, 0.94165, 0.97552,
            0.94165, 0.97552, 0.94165, 0.91602, 0.91981, 0.88641, 1.0276, 1, 1,
            0.88641, 1.0276, 0.80527, 0.78929, 0.80527, 0.78929, 0.80527,
            0.78929, 0.80527, 0.78929, 1, 1, 0.96083, 1.05403, 0.95923, 1.16862,
            1.00135, 0.97922, 1.00135, 0.97922, 1.00135, 0.97922, 1.00135,
            0.97922, 1.00135, 0.97922, 1.00135, 0.97922, 1.06777, 1.02197,
            0.91142, 0.96752, 0.91142, 0.99361, 0.97168, 0.99361, 0.97168,
            0.99361, 0.97168, 1.23199, 1.036, 0.97363, 1.06585, 0.94385,
            0.96705, 0.97552, 0.94165, 1, 1, 0.96083, 1.1261, 1.31818, 1.31818,
            1.31818, 1.31818, 1.31818, 1.31818, 1.31818, 1.31818, 1.31818,
            0.95161, 1.27126, 1.00811, 0.83284, 0.77702, 0.99137, 0.95253,
            1.0347, 0.86142, 1.07205, 1.14236, 0.97363, 0.89723, 0.86869,
            1.09818, 0.79429, 0.99361, 1.05815, 0.97552, 1.1406, 0.90128,
            1.06662, 1.04396, 1.10615, 0.84918, 0.97552, 1.04694, 0.94436,
            0.98015, 0.96083, 0.91142, 1.00356, 0.9817, 1.01945, 0.98999,
            1.1406, 0.91142, 1.04961, 0.9898, 1.00639, 1.14236, 1.07514,
            1.04961, 0.99607, 1.02897, 1.008, 0.9898, 0.95134, 1.00639, 1.11121,
            1.14236, 1.00518, 0.97981, 1.02186, 1, 1.08578, 0.94165, 0.99314,
            0.98387, 0.93028, 0.93377, 1.35125, 1.07514, 1.10687, 0.93491,
            1.04232, 1.00351, 1.14236, 1.07514, 0.94165, 1.07514, 1.00351,
            0.79429, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.09097, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.93503, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 0.96609, 1, 1, 1, 1, 1, 1, 1.06777, 1.02197,
            1.06777, 1.02197, 1.06777, 1.02197, 0.91142, 0.96752, 1, 1.21261,
            0.89903, 1, 1, 0.75155, 1.04745, 1.04745, 1.04745, 1.04394, 0.98633,
            0.98633, 0.98633, 0.72959, 0.72959, 1.20502, 0.91406, 1.26514,
            1.222, 1.02956, 1.03372, 1.03372, 0.96039, 1.24633, 1, 1.09125,
            0.93327, 1.03336, 1.16541, 1.036, 1, 1, 1, 0.771, 1, 1, 1.15574,
            1.15574, 1.15574, 1.15574, 0.86364, 0.94434, 0.86279, 0.94434,
            0.86224, 1, 1, 1.16798, 1, 0.96085, 0.90068, 1.21237, 1.18416,
            1.13904, 0.69825, 0.9716, 2.10339, 1.29004, 1.29004, 1.21339,
            1.29004, 1.29004, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.42603, 1, 0.99862, 0.99862, 1,
            0.87025, 0.87025, 0.87025, 0.87025, 1.18775, 1.42603, 1, 1.42603,
            1.42603, 0.99862, 1, 1, 1, 1, 1, 1.2886, 1.04315, 1.15296, 1.34163,
            1, 1, 1, 1.13269, 1.13269, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1,
          ];
          t.SegoeuiBoldItalicMetrics = { lineHeight: 1.33008, lineGap: 0 };
          t.SegoeuiItalicFactors = [
            1.76738, 1, 1, 0.98946, 1.14763, 1.05365, 1.06234, 0.96927, 0.92586,
            1.15373, 1.18414, 0.91349, 0.91349, 1.07403, 1.17308, 0.78383,
            1.20088, 0.78383, 1.42531, 0.96927, 0.96927, 0.96927, 0.96927,
            0.96927, 0.96927, 0.96927, 0.96927, 0.96927, 0.96927, 0.78383,
            0.78383, 1.17308, 1.17308, 1.17308, 0.77349, 0.94565, 0.94729,
            0.85944, 0.88506, 0.9858, 0.74817, 0.80016, 0.88449, 0.98039,
            0.95782, 0.69238, 0.89898, 0.83231, 0.98183, 1.03989, 0.96924,
            0.86237, 0.96924, 0.80595, 0.74524, 0.86091, 0.95402, 0.94143,
            0.98448, 0.8858, 0.83089, 0.93285, 1.0949, 1.39016, 1.0949, 1.45994,
            0.74627, 1.04839, 0.97454, 0.97454, 0.87207, 0.97454, 0.87533,
            1.06151, 0.97454, 1.00176, 1.16484, 1.08132, 0.98047, 1.16484,
            1.02989, 1.01054, 0.96225, 0.97454, 0.97454, 1.06598, 0.79004,
            1.16344, 1.00351, 0.94629, 0.9973, 0.91016, 0.96777, 0.9043,
            0.91082, 0.92481, 0.91082, 1.17308, 0.95748, 0.96927, 0.96927, 1,
            0.96927, 0.92481, 0.80597, 1.04839, 1.23393, 1.1781, 0.9245,
            1.17308, 1.20808, 0.63218, 0.94261, 1.24822, 1.09971, 1.09971,
            1.04839, 1, 0.85273, 0.78032, 1.04839, 1.09971, 1.22326, 0.9245,
            1.09836, 1.13525, 1.15222, 0.70424, 0.94729, 0.94729, 0.94729,
            0.94729, 0.94729, 0.94729, 0.85498, 0.88506, 0.74817, 0.74817,
            0.74817, 0.74817, 0.95782, 0.95782, 0.95782, 0.95782, 0.9858,
            1.03989, 0.96924, 0.96924, 0.96924, 0.96924, 0.96924, 1.17308,
            0.96924, 0.95402, 0.95402, 0.95402, 0.95402, 0.83089, 0.86237,
            0.88409, 0.97454, 0.97454, 0.97454, 0.97454, 0.97454, 0.97454,
            0.92916, 0.87207, 0.87533, 0.87533, 0.87533, 0.87533, 0.93146,
            0.93146, 0.93146, 0.93146, 0.93854, 1.01054, 0.96225, 0.96225,
            0.96225, 0.96225, 0.96225, 1.24822, 0.8761, 1.00351, 1.00351,
            1.00351, 1.00351, 0.96777, 0.97454, 0.96777, 0.94729, 0.97454,
            0.94729, 0.97454, 0.94729, 0.97454, 0.88506, 0.87207, 0.88506,
            0.87207, 0.88506, 0.87207, 0.88506, 0.87207, 0.9858, 0.95391,
            0.9858, 0.97454, 0.74817, 0.87533, 0.74817, 0.87533, 0.74817,
            0.87533, 0.74817, 0.87533, 0.74817, 0.87533, 0.88449, 0.97454,
            0.88449, 0.97454, 0.88449, 0.97454, 1, 1, 0.98039, 1.00176, 0.98039,
            1.00176, 0.95782, 0.93146, 0.95782, 0.93146, 0.95782, 0.93146,
            0.95782, 1.16484, 0.95782, 0.93146, 0.84421, 1.12761, 0.69238,
            1.08132, 1, 1, 0.98047, 0.83231, 1.16484, 1, 1, 0.84723, 1.04861,
            0.84723, 0.78755, 0.83231, 1.23736, 1.03989, 1.01054, 1, 1, 1.03989,
            1.01054, 0.9857, 1.03849, 1.01054, 0.96924, 0.96225, 0.96924,
            0.96225, 0.96924, 0.96225, 0.92383, 0.90171, 0.80595, 1.06598, 1, 1,
            0.80595, 1.06598, 0.74524, 0.79004, 0.74524, 0.79004, 0.74524,
            0.79004, 0.74524, 0.79004, 1, 1, 0.86091, 1.02759, 0.85771, 1.16344,
            0.95402, 1.00351, 0.95402, 1.00351, 0.95402, 1.00351, 0.95402,
            1.00351, 0.95402, 1.00351, 0.95402, 1.00351, 0.98448, 0.9973,
            0.83089, 0.96777, 0.83089, 0.93285, 0.9043, 0.93285, 0.9043,
            0.93285, 0.9043, 1.31868, 0.96927, 0.94729, 0.97454, 0.85498,
            0.92916, 0.96924, 0.8761, 1, 1, 0.86091, 1.16344, 1.04839, 1.04839,
            1.04839, 1.04839, 1.04839, 1.04839, 1.04839, 1.04839, 1.04839,
            0.81965, 0.81965, 0.94729, 0.78032, 0.71022, 0.90883, 0.84171,
            0.99877, 0.77596, 1.05734, 1.2, 0.94729, 0.85944, 0.82791, 0.9607,
            0.74817, 0.93285, 0.98039, 0.96924, 0.95782, 0.89898, 0.98316,
            0.98183, 1.03989, 0.78614, 0.96924, 0.97642, 0.86237, 0.86075,
            0.86091, 0.83089, 0.90082, 0.8858, 0.97296, 1.01284, 0.95782,
            0.83089, 1.0976, 1.04, 1.03342, 1.2, 1.0675, 1.0976, 0.98205,
            1.03809, 1.05097, 1.04, 0.95364, 1.03342, 1.05401, 1.2, 1.02148,
            1.0119, 1.04724, 1.0127, 1.02732, 0.96225, 0.8965, 0.97783, 0.93574,
            0.94818, 1.30679, 1.0675, 1.11826, 0.99821, 1.0557, 1.0326, 1.2,
            1.0675, 0.96225, 1.0675, 1.0326, 0.74817, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1.03754, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 0.87533, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.98705, 1, 1,
            1, 1, 1, 1, 0.98448, 0.9973, 0.98448, 0.9973, 0.98448, 0.9973,
            0.83089, 0.96777, 1, 1.20088, 0.89903, 1, 1, 0.75155, 0.94945,
            0.94945, 0.94945, 0.94945, 1.12317, 1.12317, 1.12317, 0.67603,
            0.67603, 1.15621, 0.73584, 1.21191, 1.22135, 1.06483, 0.94868,
            0.94868, 0.95996, 1.24633, 1, 1.07497, 0.87709, 0.96927, 1.01473,
            0.96927, 1, 1, 1, 0.77295, 1, 1, 1.09836, 1.09836, 1.09836, 1.01522,
            0.86321, 0.94434, 0.8649, 0.94434, 0.86182, 1, 1, 1.083, 1, 0.91578,
            0.86438, 1.17308, 1.18416, 1.14589, 0.69825, 0.97622, 1.96791,
            1.24822, 1.24822, 1.17308, 1.24822, 1.24822, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1.42603, 1, 0.99862, 0.99862, 1, 0.87025, 0.87025, 0.87025, 0.87025,
            1.17984, 1.42603, 1, 1.42603, 1.42603, 0.99862, 1, 1, 1, 1, 1,
            1.2886, 1.04315, 1.15296, 1.34163, 1, 1, 1, 1.10742, 1.10742, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          ];
          t.SegoeuiItalicMetrics = { lineHeight: 1.33008, lineGap: 0 };
          t.SegoeuiRegularFactors = [
            1.76738, 1, 1, 0.98594, 1.02285, 1.10454, 1.06234, 0.96927, 0.92037,
            1.19985, 1.2046, 0.90616, 0.90616, 1.07152, 1.1714, 0.78032,
            1.20088, 0.78032, 1.40246, 0.96927, 0.96927, 0.96927, 0.96927,
            0.96927, 0.96927, 0.96927, 0.96927, 0.96927, 0.96927, 0.78032,
            0.78032, 1.1714, 1.1714, 1.1714, 0.80597, 0.94084, 0.96706, 0.85944,
            0.85734, 0.97093, 0.75842, 0.79936, 0.88198, 0.9831, 0.95782,
            0.71387, 0.86969, 0.84636, 1.07796, 1.03584, 0.96924, 0.83968,
            0.96924, 0.82826, 0.79649, 0.85771, 0.95132, 0.93119, 0.98965,
            0.88433, 0.8287, 0.93365, 1.08612, 1.3638, 1.08612, 1.45786,
            0.74627, 0.80499, 0.91484, 1.05707, 0.92383, 1.05882, 0.9403,
            1.12654, 1.05882, 1.01756, 1.09011, 1.09011, 0.99414, 1.09011,
            1.034, 1.01756, 1.05356, 1.05707, 1.05882, 1.04399, 0.84863,
            1.21968, 1.01756, 0.95801, 1.00068, 0.91797, 0.96777, 0.9043,
            0.90351, 0.92105, 0.90351, 1.1714, 0.85337, 0.96927, 0.96927,
            0.99912, 0.96927, 0.92105, 0.80597, 1.2434, 1.20808, 1.05937,
            0.90957, 1.1714, 1.20808, 0.75155, 0.94261, 1.24644, 1.09971,
            1.09971, 0.84751, 1, 0.85273, 0.78032, 0.61584, 1.05425, 1.17914,
            0.90957, 1.08665, 1.11593, 1.14169, 0.73381, 0.96706, 0.96706,
            0.96706, 0.96706, 0.96706, 0.96706, 0.86035, 0.85734, 0.75842,
            0.75842, 0.75842, 0.75842, 0.95782, 0.95782, 0.95782, 0.95782,
            0.97093, 1.03584, 0.96924, 0.96924, 0.96924, 0.96924, 0.96924,
            1.1714, 0.96924, 0.95132, 0.95132, 0.95132, 0.95132, 0.8287,
            0.83968, 0.89049, 0.91484, 0.91484, 0.91484, 0.91484, 0.91484,
            0.91484, 0.93575, 0.92383, 0.9403, 0.9403, 0.9403, 0.9403, 0.8717,
            0.8717, 0.8717, 0.8717, 1.00527, 1.01756, 1.05356, 1.05356, 1.05356,
            1.05356, 1.05356, 1.24644, 0.95923, 1.01756, 1.01756, 1.01756,
            1.01756, 0.96777, 1.05707, 0.96777, 0.96706, 0.91484, 0.96706,
            0.91484, 0.96706, 0.91484, 0.85734, 0.92383, 0.85734, 0.92383,
            0.85734, 0.92383, 0.85734, 0.92383, 0.97093, 1.0969, 0.97093,
            1.05882, 0.75842, 0.9403, 0.75842, 0.9403, 0.75842, 0.9403, 0.75842,
            0.9403, 0.75842, 0.9403, 0.88198, 1.05882, 0.88198, 1.05882,
            0.88198, 1.05882, 1, 1, 0.9831, 1.01756, 0.9831, 1.01756, 0.95782,
            0.8717, 0.95782, 0.8717, 0.95782, 0.8717, 0.95782, 1.09011, 0.95782,
            0.8717, 0.84784, 1.11551, 0.71387, 1.09011, 1, 1, 0.99414, 0.84636,
            1.09011, 1, 1, 0.84636, 1.0536, 0.84636, 0.94298, 0.84636, 1.23297,
            1.03584, 1.01756, 1, 1, 1.03584, 1.01756, 1.00323, 1.03444, 1.01756,
            0.96924, 1.05356, 0.96924, 1.05356, 0.96924, 1.05356, 0.93066,
            0.98293, 0.82826, 1.04399, 1, 1, 0.82826, 1.04399, 0.79649, 0.84863,
            0.79649, 0.84863, 0.79649, 0.84863, 0.79649, 0.84863, 1, 1, 0.85771,
            1.17318, 0.85771, 1.21968, 0.95132, 1.01756, 0.95132, 1.01756,
            0.95132, 1.01756, 0.95132, 1.01756, 0.95132, 1.01756, 0.95132,
            1.01756, 0.98965, 1.00068, 0.8287, 0.96777, 0.8287, 0.93365, 0.9043,
            0.93365, 0.9043, 0.93365, 0.9043, 1.08571, 0.96927, 0.96706,
            0.91484, 0.86035, 0.93575, 0.96924, 0.95923, 1, 1, 0.85771, 1.21968,
            1.11437, 1.11437, 0.93109, 0.91202, 0.60411, 0.84164, 0.55572,
            1.01173, 0.97361, 0.81818, 0.81818, 0.96635, 0.78032, 0.72727,
            0.92366, 0.98601, 1.03405, 0.77968, 1.09799, 1.2, 0.96706, 0.85944,
            0.85638, 0.96491, 0.75842, 0.93365, 0.9831, 0.96924, 0.95782,
            0.86969, 0.94152, 1.07796, 1.03584, 0.78437, 0.96924, 0.98715,
            0.83968, 0.83491, 0.85771, 0.8287, 0.94492, 0.88433, 0.9287, 1.0098,
            0.95782, 0.8287, 1.0625, 0.98248, 1.03424, 1.2, 1.01071, 1.0625,
            0.95246, 1.03809, 1.04912, 0.98248, 1.00221, 1.03424, 1.05443, 1.2,
            1.04785, 0.99609, 1.00169, 1.05176, 0.99346, 1.05356, 0.9087,
            1.03004, 0.95542, 0.93117, 1.23362, 1.01071, 1.07831, 1.02512,
            1.05205, 1.03502, 1.2, 1.01071, 1.05356, 1.01071, 1.03502, 0.75842,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1.03719, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0.9403, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1.04021, 1, 1, 1, 1, 1, 1, 0.98965, 1.00068, 0.98965,
            1.00068, 0.98965, 1.00068, 0.8287, 0.96777, 1, 1.20088, 0.89903, 1,
            1, 0.75155, 1.03077, 1.03077, 1.03077, 1.03077, 1.13196, 1.13196,
            1.13196, 0.67428, 0.67428, 1.16039, 0.73291, 1.20996, 1.22135,
            1.06483, 0.94868, 0.94868, 0.95996, 1.24633, 1, 1.07497, 0.87796,
            0.96927, 1.01518, 0.96927, 1, 1, 1, 0.77295, 1, 1, 1.10539, 1.10539,
            1.11358, 1.06967, 0.86279, 0.94434, 0.86279, 0.94434, 0.86182, 1, 1,
            1.083, 1, 0.91578, 0.86507, 1.1714, 1.18416, 1.14589, 0.69825,
            0.97622, 1.9697, 1.24822, 1.24822, 1.17238, 1.24822, 1.24822, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1.42603, 1, 0.99862, 0.99862, 1, 0.87025, 0.87025,
            0.87025, 0.87025, 1.18083, 1.42603, 1, 1.42603, 1.42603, 0.99862, 1,
            1, 1, 1, 1, 1.2886, 1.04315, 1.15296, 1.34163, 1, 1, 1, 1.10938,
            1.10938, 1, 1, 1, 1.05425, 1.09971, 1.09971, 1.09971, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1, 1,
          ];
          t.SegoeuiRegularMetrics = { lineHeight: 1.33008, lineGap: 0 };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PostScriptEvaluator =
            t.PostScriptCompiler =
            t.PDFFunctionFactory =
              void 0;
          t.isPDFFunction = function isPDFFunction(e) {
            let t;
            if ("object" != typeof e) return !1;
            if (e instanceof r.Dict) t = e;
            else {
              if (!(e instanceof s.BaseStream)) return !1;
              t = e.dict;
            }
            return t.has("FunctionType");
          };
          var r = a(4),
            n = a(2),
            i = a(56),
            s = a(5),
            o = a(57);
          t.PDFFunctionFactory = class PDFFunctionFactory {
            constructor({ xref: e, isEvalSupported: t = !0 }) {
              this.xref = e;
              this.isEvalSupported = !1 !== t;
            }
            create(e) {
              const t = this.getCached(e);
              if (t) return t;
              const a = PDFFunction.parse({
                xref: this.xref,
                isEvalSupported: this.isEvalSupported,
                fn: e instanceof r.Ref ? this.xref.fetch(e) : e,
              });
              this._cache(e, a);
              return a;
            }
            createFromArray(e) {
              const t = this.getCached(e);
              if (t) return t;
              const a = PDFFunction.parseArray({
                xref: this.xref,
                isEvalSupported: this.isEvalSupported,
                fnObj: e instanceof r.Ref ? this.xref.fetch(e) : e,
              });
              this._cache(e, a);
              return a;
            }
            getCached(e) {
              let t;
              e instanceof r.Ref
                ? (t = e)
                : e instanceof r.Dict
                ? (t = e.objId)
                : e instanceof s.BaseStream && (t = e.dict && e.dict.objId);
              if (t) {
                const e = this._localFunctionCache.getByRef(t);
                if (e) return e;
              }
              return null;
            }
            _cache(e, t) {
              if (!t)
                throw new Error(
                  'PDFFunctionFactory._cache - expected "parsedFunction" argument.'
                );
              let a;
              e instanceof r.Ref
                ? (a = e)
                : e instanceof r.Dict
                ? (a = e.objId)
                : e instanceof s.BaseStream && (a = e.dict && e.dict.objId);
              a && this._localFunctionCache.set(null, a, t);
            }
            get _localFunctionCache() {
              return (0, n.shadow)(
                this,
                "_localFunctionCache",
                new o.LocalFunctionCache()
              );
            }
          };
          function toNumberArray(e) {
            if (!Array.isArray(e)) return null;
            const t = e.length;
            for (let a = 0; a < t; a++)
              if ("number" != typeof e[a]) {
                const a = new Array(t);
                for (let r = 0; r < t; r++) a[r] = +e[r];
                return a;
              }
            return e;
          }
          class PDFFunction {
            static getSampleArray(e, t, a, r) {
              let n,
                i,
                s = 1;
              for (n = 0, i = e.length; n < i; n++) s *= e[n];
              s *= t;
              const o = new Array(s);
              let c = 0,
                l = 0;
              const h = 1 / (2 ** a - 1),
                u = r.getBytes((s * a + 7) / 8);
              let d = 0;
              for (n = 0; n < s; n++) {
                for (; c < a; ) {
                  l <<= 8;
                  l |= u[d++];
                  c += 8;
                }
                c -= a;
                o[n] = (l >> c) * h;
                l &= (1 << c) - 1;
              }
              return o;
            }
            static parse({ xref: e, isEvalSupported: t, fn: a }) {
              const r = a.dict || a;
              switch (r.get("FunctionType")) {
                case 0:
                  return this.constructSampled({
                    xref: e,
                    isEvalSupported: t,
                    fn: a,
                    dict: r,
                  });
                case 1:
                  break;
                case 2:
                  return this.constructInterpolated({
                    xref: e,
                    isEvalSupported: t,
                    dict: r,
                  });
                case 3:
                  return this.constructStiched({
                    xref: e,
                    isEvalSupported: t,
                    dict: r,
                  });
                case 4:
                  return this.constructPostScript({
                    xref: e,
                    isEvalSupported: t,
                    fn: a,
                    dict: r,
                  });
              }
              throw new n.FormatError("Unknown type of function");
            }
            static parseArray({ xref: e, isEvalSupported: t, fnObj: a }) {
              if (!Array.isArray(a))
                return this.parse({ xref: e, isEvalSupported: t, fn: a });
              const r = [];
              for (const n of a)
                r.push(
                  this.parse({
                    xref: e,
                    isEvalSupported: t,
                    fn: e.fetchIfRef(n),
                  })
                );
              return function (e, t, a, n) {
                for (let i = 0, s = r.length; i < s; i++) r[i](e, t, a, n + i);
              };
            }
            static constructSampled({
              xref: e,
              isEvalSupported: t,
              fn: a,
              dict: r,
            }) {
              function toMultiArray(e) {
                const t = e.length,
                  a = [];
                let r = 0;
                for (let n = 0; n < t; n += 2) a[r++] = [e[n], e[n + 1]];
                return a;
              }
              function interpolate(e, t, a, r, n) {
                return r + ((n - r) / (a - t)) * (e - t);
              }
              let i = toNumberArray(r.getArray("Domain")),
                s = toNumberArray(r.getArray("Range"));
              if (!i || !s) throw new n.FormatError("No domain or range");
              const o = i.length / 2,
                c = s.length / 2;
              i = toMultiArray(i);
              s = toMultiArray(s);
              const l = toNumberArray(r.getArray("Size")),
                h = r.get("BitsPerSample"),
                u = r.get("Order") || 1;
              1 !== u &&
                (0, n.info)("No support for cubic spline interpolation: " + u);
              let d = toNumberArray(r.getArray("Encode"));
              if (d) d = toMultiArray(d);
              else {
                d = [];
                for (let e = 0; e < o; ++e) d.push([0, l[e] - 1]);
              }
              let f = toNumberArray(r.getArray("Decode"));
              f = f ? toMultiArray(f) : s;
              const g = this.getSampleArray(l, c, h, a);
              return function constructSampledFn(e, t, a, r) {
                const n = 1 << o,
                  h = new Float64Array(n),
                  u = new Uint32Array(n);
                let p, m;
                for (m = 0; m < n; m++) h[m] = 1;
                let b = c,
                  y = 1;
                for (p = 0; p < o; ++p) {
                  const a = i[p][0],
                    r = i[p][1];
                  let s = interpolate(
                    Math.min(Math.max(e[t + p], a), r),
                    a,
                    r,
                    d[p][0],
                    d[p][1]
                  );
                  const o = l[p];
                  s = Math.min(Math.max(s, 0), o - 1);
                  const c = s < o - 1 ? Math.floor(s) : s - 1,
                    f = c + 1 - s,
                    g = s - c,
                    w = c * b,
                    S = w + b;
                  for (m = 0; m < n; m++)
                    if (m & y) {
                      h[m] *= g;
                      u[m] += S;
                    } else {
                      h[m] *= f;
                      u[m] += w;
                    }
                  b *= o;
                  y <<= 1;
                }
                for (m = 0; m < c; ++m) {
                  let e = 0;
                  for (p = 0; p < n; p++) e += g[u[p] + m] * h[p];
                  e = interpolate(e, 0, 1, f[m][0], f[m][1]);
                  a[r + m] = Math.min(Math.max(e, s[m][0]), s[m][1]);
                }
              };
            }
            static constructInterpolated({
              xref: e,
              isEvalSupported: t,
              dict: a,
            }) {
              const r = toNumberArray(a.getArray("C0")) || [0],
                n = toNumberArray(a.getArray("C1")) || [1],
                i = a.get("N"),
                s = [];
              for (let e = 0, t = r.length; e < t; ++e) s.push(n[e] - r[e]);
              const o = s.length;
              return function constructInterpolatedFn(e, t, a, n) {
                const c = 1 === i ? e[t] : e[t] ** i;
                for (let e = 0; e < o; ++e) a[n + e] = r[e] + c * s[e];
              };
            }
            static constructStiched({ xref: e, isEvalSupported: t, dict: a }) {
              const r = toNumberArray(a.getArray("Domain"));
              if (!r) throw new n.FormatError("No domain");
              if (1 !== r.length / 2)
                throw new n.FormatError("Bad domain for stiched function");
              const i = [];
              for (const r of a.get("Functions"))
                i.push(
                  this.parse({
                    xref: e,
                    isEvalSupported: t,
                    fn: e.fetchIfRef(r),
                  })
                );
              const s = toNumberArray(a.getArray("Bounds")),
                o = toNumberArray(a.getArray("Encode")),
                c = new Float32Array(1);
              return function constructStichedFn(e, t, a, n) {
                const l = (function constructStichedFromIRClip(e, t, a) {
                    e > a ? (e = a) : e < t && (e = t);
                    return e;
                  })(e[t], r[0], r[1]),
                  h = s.length;
                let u;
                for (u = 0; u < h && !(l < s[u]); ++u);
                let d = r[0];
                u > 0 && (d = s[u - 1]);
                let f = r[1];
                u < s.length && (f = s[u]);
                const g = o[2 * u],
                  p = o[2 * u + 1];
                c[0] = d === f ? g : g + ((l - d) * (p - g)) / (f - d);
                i[u](c, 0, a, n);
              };
            }
            static constructPostScript({
              xref: e,
              isEvalSupported: t,
              fn: a,
              dict: r,
            }) {
              const s = toNumberArray(r.getArray("Domain")),
                o = toNumberArray(r.getArray("Range"));
              if (!s) throw new n.FormatError("No domain.");
              if (!o) throw new n.FormatError("No range.");
              const c = new i.PostScriptLexer(a),
                l = new i.PostScriptParser(c).parse();
              if (t && n.FeatureTest.isEvalSupported) {
                const e = new PostScriptCompiler().compile(l, s, o);
                if (e)
                  return new Function(
                    "src",
                    "srcOffset",
                    "dest",
                    "destOffset",
                    e
                  );
              }
              (0, n.info)("Unable to compile PS function");
              const h = o.length >> 1,
                u = s.length >> 1,
                d = new PostScriptEvaluator(l),
                f = Object.create(null);
              let g = 8192;
              const p = new Float32Array(u);
              return function constructPostScriptFn(e, t, a, r) {
                let n,
                  i,
                  s = "";
                const c = p;
                for (n = 0; n < u; n++) {
                  i = e[t + n];
                  c[n] = i;
                  s += i + "_";
                }
                const l = f[s];
                if (void 0 !== l) {
                  a.set(l, r);
                  return;
                }
                const m = new Float32Array(h),
                  b = d.execute(c),
                  y = b.length - h;
                for (n = 0; n < h; n++) {
                  i = b[y + n];
                  let e = o[2 * n];
                  if (i < e) i = e;
                  else {
                    e = o[2 * n + 1];
                    i > e && (i = e);
                  }
                  m[n] = i;
                }
                if (g > 0) {
                  g--;
                  f[s] = m;
                }
                a.set(m, r);
              };
            }
          }
          class PostScriptStack {
            static get MAX_STACK_SIZE() {
              return (0, n.shadow)(this, "MAX_STACK_SIZE", 100);
            }
            constructor(e) {
              this.stack = e ? Array.from(e) : [];
            }
            push(e) {
              if (this.stack.length >= PostScriptStack.MAX_STACK_SIZE)
                throw new Error("PostScript function stack overflow.");
              this.stack.push(e);
            }
            pop() {
              if (this.stack.length <= 0)
                throw new Error("PostScript function stack underflow.");
              return this.stack.pop();
            }
            copy(e) {
              if (this.stack.length + e >= PostScriptStack.MAX_STACK_SIZE)
                throw new Error("PostScript function stack overflow.");
              const t = this.stack;
              for (let a = t.length - e, r = e - 1; r >= 0; r--, a++)
                t.push(t[a]);
            }
            index(e) {
              this.push(this.stack[this.stack.length - e - 1]);
            }
            roll(e, t) {
              const a = this.stack,
                r = a.length - e,
                n = a.length - 1,
                i = r + (t - Math.floor(t / e) * e);
              for (let e = r, t = n; e < t; e++, t--) {
                const r = a[e];
                a[e] = a[t];
                a[t] = r;
              }
              for (let e = r, t = i - 1; e < t; e++, t--) {
                const r = a[e];
                a[e] = a[t];
                a[t] = r;
              }
              for (let e = i, t = n; e < t; e++, t--) {
                const r = a[e];
                a[e] = a[t];
                a[t] = r;
              }
            }
          }
          class PostScriptEvaluator {
            constructor(e) {
              this.operators = e;
            }
            execute(e) {
              const t = new PostScriptStack(e);
              let a = 0;
              const r = this.operators,
                i = r.length;
              let s, o, c;
              for (; a < i; ) {
                s = r[a++];
                if ("number" != typeof s)
                  switch (s) {
                    case "jz":
                      c = t.pop();
                      o = t.pop();
                      o || (a = c);
                      break;
                    case "j":
                      o = t.pop();
                      a = o;
                      break;
                    case "abs":
                      o = t.pop();
                      t.push(Math.abs(o));
                      break;
                    case "add":
                      c = t.pop();
                      o = t.pop();
                      t.push(o + c);
                      break;
                    case "and":
                      c = t.pop();
                      o = t.pop();
                      "boolean" == typeof o && "boolean" == typeof c
                        ? t.push(o && c)
                        : t.push(o & c);
                      break;
                    case "atan":
                      o = t.pop();
                      t.push(Math.atan(o));
                      break;
                    case "bitshift":
                      c = t.pop();
                      o = t.pop();
                      o > 0 ? t.push(o << c) : t.push(o >> c);
                      break;
                    case "ceiling":
                      o = t.pop();
                      t.push(Math.ceil(o));
                      break;
                    case "copy":
                      o = t.pop();
                      t.copy(o);
                      break;
                    case "cos":
                      o = t.pop();
                      t.push(Math.cos(o));
                      break;
                    case "cvi":
                      o = 0 | t.pop();
                      t.push(o);
                      break;
                    case "cvr":
                      break;
                    case "div":
                      c = t.pop();
                      o = t.pop();
                      t.push(o / c);
                      break;
                    case "dup":
                      t.copy(1);
                      break;
                    case "eq":
                      c = t.pop();
                      o = t.pop();
                      t.push(o === c);
                      break;
                    case "exch":
                      t.roll(2, 1);
                      break;
                    case "exp":
                      c = t.pop();
                      o = t.pop();
                      t.push(o ** c);
                      break;
                    case "false":
                      t.push(!1);
                      break;
                    case "floor":
                      o = t.pop();
                      t.push(Math.floor(o));
                      break;
                    case "ge":
                      c = t.pop();
                      o = t.pop();
                      t.push(o >= c);
                      break;
                    case "gt":
                      c = t.pop();
                      o = t.pop();
                      t.push(o > c);
                      break;
                    case "idiv":
                      c = t.pop();
                      o = t.pop();
                      t.push((o / c) | 0);
                      break;
                    case "index":
                      o = t.pop();
                      t.index(o);
                      break;
                    case "le":
                      c = t.pop();
                      o = t.pop();
                      t.push(o <= c);
                      break;
                    case "ln":
                      o = t.pop();
                      t.push(Math.log(o));
                      break;
                    case "log":
                      o = t.pop();
                      t.push(Math.log(o) / Math.LN10);
                      break;
                    case "lt":
                      c = t.pop();
                      o = t.pop();
                      t.push(o < c);
                      break;
                    case "mod":
                      c = t.pop();
                      o = t.pop();
                      t.push(o % c);
                      break;
                    case "mul":
                      c = t.pop();
                      o = t.pop();
                      t.push(o * c);
                      break;
                    case "ne":
                      c = t.pop();
                      o = t.pop();
                      t.push(o !== c);
                      break;
                    case "neg":
                      o = t.pop();
                      t.push(-o);
                      break;
                    case "not":
                      o = t.pop();
                      "boolean" == typeof o ? t.push(!o) : t.push(~o);
                      break;
                    case "or":
                      c = t.pop();
                      o = t.pop();
                      "boolean" == typeof o && "boolean" == typeof c
                        ? t.push(o || c)
                        : t.push(o | c);
                      break;
                    case "pop":
                      t.pop();
                      break;
                    case "roll":
                      c = t.pop();
                      o = t.pop();
                      t.roll(o, c);
                      break;
                    case "round":
                      o = t.pop();
                      t.push(Math.round(o));
                      break;
                    case "sin":
                      o = t.pop();
                      t.push(Math.sin(o));
                      break;
                    case "sqrt":
                      o = t.pop();
                      t.push(Math.sqrt(o));
                      break;
                    case "sub":
                      c = t.pop();
                      o = t.pop();
                      t.push(o - c);
                      break;
                    case "true":
                      t.push(!0);
                      break;
                    case "truncate":
                      o = t.pop();
                      o = o < 0 ? Math.ceil(o) : Math.floor(o);
                      t.push(o);
                      break;
                    case "xor":
                      c = t.pop();
                      o = t.pop();
                      "boolean" == typeof o && "boolean" == typeof c
                        ? t.push(o !== c)
                        : t.push(o ^ c);
                      break;
                    default:
                      throw new n.FormatError(`Unknown operator ${s}`);
                  }
                else t.push(s);
              }
              return t.stack;
            }
          }
          t.PostScriptEvaluator = PostScriptEvaluator;
          class AstNode {
            constructor(e) {
              this.type = e;
            }
            visit(e) {
              (0, n.unreachable)("abstract method");
            }
          }
          class AstArgument extends AstNode {
            constructor(e, t, a) {
              super("args");
              this.index = e;
              this.min = t;
              this.max = a;
            }
            visit(e) {
              e.visitArgument(this);
            }
          }
          class AstLiteral extends AstNode {
            constructor(e) {
              super("literal");
              this.number = e;
              this.min = e;
              this.max = e;
            }
            visit(e) {
              e.visitLiteral(this);
            }
          }
          class AstBinaryOperation extends AstNode {
            constructor(e, t, a, r, n) {
              super("binary");
              this.op = e;
              this.arg1 = t;
              this.arg2 = a;
              this.min = r;
              this.max = n;
            }
            visit(e) {
              e.visitBinaryOperation(this);
            }
          }
          class AstMin extends AstNode {
            constructor(e, t) {
              super("max");
              this.arg = e;
              this.min = e.min;
              this.max = t;
            }
            visit(e) {
              e.visitMin(this);
            }
          }
          class AstVariable extends AstNode {
            constructor(e, t, a) {
              super("var");
              this.index = e;
              this.min = t;
              this.max = a;
            }
            visit(e) {
              e.visitVariable(this);
            }
          }
          class AstVariableDefinition extends AstNode {
            constructor(e, t) {
              super("definition");
              this.variable = e;
              this.arg = t;
            }
            visit(e) {
              e.visitVariableDefinition(this);
            }
          }
          class ExpressionBuilderVisitor {
            constructor() {
              this.parts = [];
            }
            visitArgument(e) {
              this.parts.push(
                "Math.max(",
                e.min,
                ", Math.min(",
                e.max,
                ", src[srcOffset + ",
                e.index,
                "]))"
              );
            }
            visitVariable(e) {
              this.parts.push("v", e.index);
            }
            visitLiteral(e) {
              this.parts.push(e.number);
            }
            visitBinaryOperation(e) {
              this.parts.push("(");
              e.arg1.visit(this);
              this.parts.push(" ", e.op, " ");
              e.arg2.visit(this);
              this.parts.push(")");
            }
            visitVariableDefinition(e) {
              this.parts.push("var ");
              e.variable.visit(this);
              this.parts.push(" = ");
              e.arg.visit(this);
              this.parts.push(";");
            }
            visitMin(e) {
              this.parts.push("Math.min(");
              e.arg.visit(this);
              this.parts.push(", ", e.max, ")");
            }
            toString() {
              return this.parts.join("");
            }
          }
          function buildAddOperation(e, t) {
            return "literal" === t.type && 0 === t.number
              ? e
              : "literal" === e.type && 0 === e.number
              ? t
              : "literal" === t.type && "literal" === e.type
              ? new AstLiteral(e.number + t.number)
              : new AstBinaryOperation("+", e, t, e.min + t.min, e.max + t.max);
          }
          function buildMulOperation(e, t) {
            if ("literal" === t.type) {
              if (0 === t.number) return new AstLiteral(0);
              if (1 === t.number) return e;
              if ("literal" === e.type)
                return new AstLiteral(e.number * t.number);
            }
            if ("literal" === e.type) {
              if (0 === e.number) return new AstLiteral(0);
              if (1 === e.number) return t;
            }
            const a = Math.min(
                e.min * t.min,
                e.min * t.max,
                e.max * t.min,
                e.max * t.max
              ),
              r = Math.max(
                e.min * t.min,
                e.min * t.max,
                e.max * t.min,
                e.max * t.max
              );
            return new AstBinaryOperation("*", e, t, a, r);
          }
          function buildSubOperation(e, t) {
            if ("literal" === t.type) {
              if (0 === t.number) return e;
              if ("literal" === e.type)
                return new AstLiteral(e.number - t.number);
            }
            return "binary" === t.type &&
              "-" === t.op &&
              "literal" === e.type &&
              1 === e.number &&
              "literal" === t.arg1.type &&
              1 === t.arg1.number
              ? t.arg2
              : new AstBinaryOperation("-", e, t, e.min - t.max, e.max - t.min);
          }
          function buildMinOperation(e, t) {
            return e.min >= t
              ? new AstLiteral(t)
              : e.max <= t
              ? e
              : new AstMin(e, t);
          }
          class PostScriptCompiler {
            compile(e, t, a) {
              const r = [],
                n = [],
                i = t.length >> 1,
                s = a.length >> 1;
              let o,
                c,
                l,
                h,
                u,
                d,
                f,
                g,
                p = 0;
              for (let e = 0; e < i; e++)
                r.push(new AstArgument(e, t[2 * e], t[2 * e + 1]));
              for (let t = 0, a = e.length; t < a; t++) {
                g = e[t];
                if ("number" != typeof g)
                  switch (g) {
                    case "add":
                      if (r.length < 2) return null;
                      h = r.pop();
                      l = r.pop();
                      r.push(buildAddOperation(l, h));
                      break;
                    case "cvr":
                      if (r.length < 1) return null;
                      break;
                    case "mul":
                      if (r.length < 2) return null;
                      h = r.pop();
                      l = r.pop();
                      r.push(buildMulOperation(l, h));
                      break;
                    case "sub":
                      if (r.length < 2) return null;
                      h = r.pop();
                      l = r.pop();
                      r.push(buildSubOperation(l, h));
                      break;
                    case "exch":
                      if (r.length < 2) return null;
                      u = r.pop();
                      d = r.pop();
                      r.push(u, d);
                      break;
                    case "pop":
                      if (r.length < 1) return null;
                      r.pop();
                      break;
                    case "index":
                      if (r.length < 1) return null;
                      l = r.pop();
                      if ("literal" !== l.type) return null;
                      o = l.number;
                      if (o < 0 || !Number.isInteger(o) || r.length < o)
                        return null;
                      u = r[r.length - o - 1];
                      if ("literal" === u.type || "var" === u.type) {
                        r.push(u);
                        break;
                      }
                      f = new AstVariable(p++, u.min, u.max);
                      r[r.length - o - 1] = f;
                      r.push(f);
                      n.push(new AstVariableDefinition(f, u));
                      break;
                    case "dup":
                      if (r.length < 1) return null;
                      if (
                        "number" == typeof e[t + 1] &&
                        "gt" === e[t + 2] &&
                        e[t + 3] === t + 7 &&
                        "jz" === e[t + 4] &&
                        "pop" === e[t + 5] &&
                        e[t + 6] === e[t + 1]
                      ) {
                        l = r.pop();
                        r.push(buildMinOperation(l, e[t + 1]));
                        t += 6;
                        break;
                      }
                      u = r.at(-1);
                      if ("literal" === u.type || "var" === u.type) {
                        r.push(u);
                        break;
                      }
                      f = new AstVariable(p++, u.min, u.max);
                      r[r.length - 1] = f;
                      r.push(f);
                      n.push(new AstVariableDefinition(f, u));
                      break;
                    case "roll":
                      if (r.length < 2) return null;
                      h = r.pop();
                      l = r.pop();
                      if ("literal" !== h.type || "literal" !== l.type)
                        return null;
                      c = h.number;
                      o = l.number;
                      if (
                        o <= 0 ||
                        !Number.isInteger(o) ||
                        !Number.isInteger(c) ||
                        r.length < o
                      )
                        return null;
                      c = ((c % o) + o) % o;
                      if (0 === c) break;
                      r.push(...r.splice(r.length - o, o - c));
                      break;
                    default:
                      return null;
                  }
                else r.push(new AstLiteral(g));
              }
              if (r.length !== s) return null;
              const m = [];
              for (const e of n) {
                const t = new ExpressionBuilderVisitor();
                e.visit(t);
                m.push(t.toString());
              }
              for (let e = 0, t = r.length; e < t; e++) {
                const t = r[e],
                  n = new ExpressionBuilderVisitor();
                t.visit(n);
                const i = a[2 * e],
                  s = a[2 * e + 1],
                  o = [n.toString()];
                if (i > t.min) {
                  o.unshift("Math.max(", i, ", ");
                  o.push(")");
                }
                if (s < t.max) {
                  o.unshift("Math.min(", s, ", ");
                  o.push(")");
                }
                o.unshift("dest[destOffset + ", e, "] = ");
                o.push(";");
                m.push(o.join(""));
              }
              return m.join("\n");
            }
          }
          t.PostScriptCompiler = PostScriptCompiler;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PostScriptParser = t.PostScriptLexer = void 0;
          var r = a(2),
            n = a(4),
            i = a(3);
          t.PostScriptParser = class PostScriptParser {
            constructor(e) {
              this.lexer = e;
              this.operators = [];
              this.token = null;
              this.prev = null;
            }
            nextToken() {
              this.prev = this.token;
              this.token = this.lexer.getToken();
            }
            accept(e) {
              if (this.token.type === e) {
                this.nextToken();
                return !0;
              }
              return !1;
            }
            expect(e) {
              if (this.accept(e)) return !0;
              throw new r.FormatError(
                `Unexpected symbol: found ${this.token.type} expected ${e}.`
              );
            }
            parse() {
              this.nextToken();
              this.expect(s.LBRACE);
              this.parseBlock();
              this.expect(s.RBRACE);
              return this.operators;
            }
            parseBlock() {
              for (;;)
                if (this.accept(s.NUMBER)) this.operators.push(this.prev.value);
                else if (this.accept(s.OPERATOR))
                  this.operators.push(this.prev.value);
                else {
                  if (!this.accept(s.LBRACE)) return;
                  this.parseCondition();
                }
            }
            parseCondition() {
              const e = this.operators.length;
              this.operators.push(null, null);
              this.parseBlock();
              this.expect(s.RBRACE);
              if (this.accept(s.IF)) {
                this.operators[e] = this.operators.length;
                this.operators[e + 1] = "jz";
              } else {
                if (!this.accept(s.LBRACE))
                  throw new r.FormatError(
                    "PS Function: error parsing conditional."
                  );
                {
                  const t = this.operators.length;
                  this.operators.push(null, null);
                  const a = this.operators.length;
                  this.parseBlock();
                  this.expect(s.RBRACE);
                  this.expect(s.IFELSE);
                  this.operators[t] = this.operators.length;
                  this.operators[t + 1] = "j";
                  this.operators[e] = a;
                  this.operators[e + 1] = "jz";
                }
              }
            }
          };
          const s = {
            LBRACE: 0,
            RBRACE: 1,
            NUMBER: 2,
            OPERATOR: 3,
            IF: 4,
            IFELSE: 5,
          };
          class PostScriptToken {
            static get opCache() {
              return (0, r.shadow)(this, "opCache", Object.create(null));
            }
            constructor(e, t) {
              this.type = e;
              this.value = t;
            }
            static getOperator(e) {
              const t = PostScriptToken.opCache[e];
              return (
                t ||
                (PostScriptToken.opCache[e] = new PostScriptToken(
                  s.OPERATOR,
                  e
                ))
              );
            }
            static get LBRACE() {
              return (0, r.shadow)(
                this,
                "LBRACE",
                new PostScriptToken(s.LBRACE, "{")
              );
            }
            static get RBRACE() {
              return (0, r.shadow)(
                this,
                "RBRACE",
                new PostScriptToken(s.RBRACE, "}")
              );
            }
            static get IF() {
              return (0, r.shadow)(this, "IF", new PostScriptToken(s.IF, "IF"));
            }
            static get IFELSE() {
              return (0, r.shadow)(
                this,
                "IFELSE",
                new PostScriptToken(s.IFELSE, "IFELSE")
              );
            }
          }
          t.PostScriptLexer = class PostScriptLexer {
            constructor(e) {
              this.stream = e;
              this.nextChar();
              this.strBuf = [];
            }
            nextChar() {
              return (this.currentChar = this.stream.getByte());
            }
            getToken() {
              let e = !1,
                t = this.currentChar;
              for (;;) {
                if (t < 0) return n.EOF;
                if (e) (10 !== t && 13 !== t) || (e = !1);
                else if (37 === t) e = !0;
                else if (!(0, i.isWhiteSpace)(t)) break;
                t = this.nextChar();
              }
              switch (0 | t) {
                case 48:
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                case 43:
                case 45:
                case 46:
                  return new PostScriptToken(s.NUMBER, this.getNumber());
                case 123:
                  this.nextChar();
                  return PostScriptToken.LBRACE;
                case 125:
                  this.nextChar();
                  return PostScriptToken.RBRACE;
              }
              const a = this.strBuf;
              a.length = 0;
              a[0] = String.fromCharCode(t);
              for (
                ;
                (t = this.nextChar()) >= 0 &&
                ((t >= 65 && t <= 90) || (t >= 97 && t <= 122));

              )
                a.push(String.fromCharCode(t));
              const r = a.join("");
              switch (r.toLowerCase()) {
                case "if":
                  return PostScriptToken.IF;
                case "ifelse":
                  return PostScriptToken.IFELSE;
                default:
                  return PostScriptToken.getOperator(r);
              }
            }
            getNumber() {
              let e = this.currentChar;
              const t = this.strBuf;
              t.length = 0;
              t[0] = String.fromCharCode(e);
              for (
                ;
                (e = this.nextChar()) >= 0 &&
                ((e >= 48 && e <= 57) || 45 === e || 46 === e);

              )
                t.push(String.fromCharCode(e));
              const a = parseFloat(t.join(""));
              if (isNaN(a))
                throw new r.FormatError(`Invalid floating point number: ${a}`);
              return a;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.LocalTilingPatternCache =
            t.LocalImageCache =
            t.LocalGStateCache =
            t.LocalFunctionCache =
            t.LocalColorSpaceCache =
            t.GlobalImageCache =
              void 0;
          var r = a(2),
            n = a(4);
          class BaseLocalCache {
            constructor(e) {
              this.constructor === BaseLocalCache &&
                (0, r.unreachable)("Cannot initialize BaseLocalCache.");
              this._onlyRefs = !0 === (e && e.onlyRefs);
              if (!this._onlyRefs) {
                this._nameRefMap = new Map();
                this._imageMap = new Map();
              }
              this._imageCache = new n.RefSetCache();
            }
            getByName(e) {
              this._onlyRefs &&
                (0, r.unreachable)("Should not call `getByName` method.");
              const t = this._nameRefMap.get(e);
              return t ? this.getByRef(t) : this._imageMap.get(e) || null;
            }
            getByRef(e) {
              return this._imageCache.get(e) || null;
            }
            set(e, t, a) {
              (0, r.unreachable)("Abstract method `set` called.");
            }
          }
          t.LocalImageCache = class LocalImageCache extends BaseLocalCache {
            set(e, t = null, a) {
              if ("string" != typeof e)
                throw new Error(
                  'LocalImageCache.set - expected "name" argument.'
                );
              if (t) {
                if (this._imageCache.has(t)) return;
                this._nameRefMap.set(e, t);
                this._imageCache.put(t, a);
              } else this._imageMap.has(e) || this._imageMap.set(e, a);
            }
          };
          t.LocalColorSpaceCache = class LocalColorSpaceCache extends (
            BaseLocalCache
          ) {
            set(e = null, t = null, a) {
              if ("string" != typeof e && !t)
                throw new Error(
                  'LocalColorSpaceCache.set - expected "name" and/or "ref" argument.'
                );
              if (t) {
                if (this._imageCache.has(t)) return;
                null !== e && this._nameRefMap.set(e, t);
                this._imageCache.put(t, a);
              } else this._imageMap.has(e) || this._imageMap.set(e, a);
            }
          };
          t.LocalFunctionCache = class LocalFunctionCache extends (
            BaseLocalCache
          ) {
            constructor(e) {
              super({ onlyRefs: !0 });
            }
            set(e = null, t, a) {
              if (!t)
                throw new Error(
                  'LocalFunctionCache.set - expected "ref" argument.'
                );
              this._imageCache.has(t) || this._imageCache.put(t, a);
            }
          };
          t.LocalGStateCache = class LocalGStateCache extends BaseLocalCache {
            set(e, t = null, a) {
              if ("string" != typeof e)
                throw new Error(
                  'LocalGStateCache.set - expected "name" argument.'
                );
              if (t) {
                if (this._imageCache.has(t)) return;
                this._nameRefMap.set(e, t);
                this._imageCache.put(t, a);
              } else this._imageMap.has(e) || this._imageMap.set(e, a);
            }
          };
          t.LocalTilingPatternCache = class LocalTilingPatternCache extends (
            BaseLocalCache
          ) {
            constructor(e) {
              super({ onlyRefs: !0 });
            }
            set(e = null, t, a) {
              if (!t)
                throw new Error(
                  'LocalTilingPatternCache.set - expected "ref" argument.'
                );
              this._imageCache.has(t) || this._imageCache.put(t, a);
            }
          };
          class GlobalImageCache {
            static get NUM_PAGES_THRESHOLD() {
              return (0, r.shadow)(this, "NUM_PAGES_THRESHOLD", 2);
            }
            static get MIN_IMAGES_TO_CACHE() {
              return (0, r.shadow)(this, "MIN_IMAGES_TO_CACHE", 10);
            }
            static get MAX_BYTE_SIZE() {
              return (0, r.shadow)(this, "MAX_BYTE_SIZE", 4e7);
            }
            constructor() {
              this._refCache = new n.RefSetCache();
              this._imageCache = new n.RefSetCache();
            }
            get _byteSize() {
              let e = 0;
              for (const t of this._imageCache) e += t.byteSize;
              return e;
            }
            get _cacheLimitReached() {
              return (
                !(
                  this._imageCache.size < GlobalImageCache.MIN_IMAGES_TO_CACHE
                ) && !(this._byteSize < GlobalImageCache.MAX_BYTE_SIZE)
              );
            }
            shouldCache(e, t) {
              const a = this._refCache.get(e);
              return (
                !(
                  (a ? a.size + (a.has(t) ? 0 : 1) : 1) <
                  GlobalImageCache.NUM_PAGES_THRESHOLD
                ) && !(!this._imageCache.has(e) && this._cacheLimitReached)
              );
            }
            addPageIndex(e, t) {
              let a = this._refCache.get(e);
              if (!a) {
                a = new Set();
                this._refCache.put(e, a);
              }
              a.add(t);
            }
            addByteSize(e, t) {
              const a = this._imageCache.get(e);
              a && (a.byteSize || (a.byteSize = t));
            }
            getData(e, t) {
              const a = this._refCache.get(e);
              if (!a) return null;
              if (a.size < GlobalImageCache.NUM_PAGES_THRESHOLD) return null;
              const r = this._imageCache.get(e);
              if (!r) return null;
              a.add(t);
              return r;
            }
            setData(e, t) {
              if (!this._refCache.has(e))
                throw new Error(
                  'GlobalImageCache.setData - expected "addPageIndex" to have been called.'
                );
              this._imageCache.has(e) ||
                (this._cacheLimitReached
                  ? (0, r.warn)(
                      "GlobalImageCache.setData - cache limit reached."
                    )
                  : this._imageCache.put(e, t));
            }
            clear(e = !1) {
              e || this._refCache.clear();
              this._imageCache.clear();
            }
          }
          t.GlobalImageCache = GlobalImageCache;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.bidi = function bidi(e, t = -1, a = !1) {
            let c = !0;
            const l = e.length;
            if (0 === l || a) return createBidiText(e, c, a);
            s.length = l;
            o.length = l;
            let h,
              u,
              d = 0;
            for (h = 0; h < l; ++h) {
              s[h] = e.charAt(h);
              const t = e.charCodeAt(h);
              let a = "L";
              if (t <= 255) a = n[t];
              else if (1424 <= t && t <= 1524) a = "R";
              else if (1536 <= t && t <= 1791) {
                a = i[255 & t];
                a ||
                  (0, r.warn)(
                    "Bidi: invalid Unicode character " + t.toString(16)
                  );
              } else 1792 <= t && t <= 2220 && (a = "AL");
              ("R" !== a && "AL" !== a && "AN" !== a) || d++;
              o[h] = a;
            }
            if (0 === d) {
              c = !0;
              return createBidiText(e, c);
            }
            if (-1 === t)
              if (d / l < 0.3 && l > 4) {
                c = !0;
                t = 0;
              } else {
                c = !1;
                t = 1;
              }
            const f = [];
            for (h = 0; h < l; ++h) f[h] = t;
            const g = isOdd(t) ? "R" : "L",
              p = g,
              m = p;
            let b,
              y = p;
            for (h = 0; h < l; ++h) "NSM" === o[h] ? (o[h] = y) : (y = o[h]);
            y = p;
            for (h = 0; h < l; ++h) {
              b = o[h];
              "EN" === b
                ? (o[h] = "AL" === y ? "AN" : "EN")
                : ("R" !== b && "L" !== b && "AL" !== b) || (y = b);
            }
            for (h = 0; h < l; ++h) {
              b = o[h];
              "AL" === b && (o[h] = "R");
            }
            for (h = 1; h < l - 1; ++h) {
              "ES" === o[h] &&
                "EN" === o[h - 1] &&
                "EN" === o[h + 1] &&
                (o[h] = "EN");
              "CS" !== o[h] ||
                ("EN" !== o[h - 1] && "AN" !== o[h - 1]) ||
                o[h + 1] !== o[h - 1] ||
                (o[h] = o[h - 1]);
            }
            for (h = 0; h < l; ++h)
              if ("EN" === o[h]) {
                for (let e = h - 1; e >= 0 && "ET" === o[e]; --e) o[e] = "EN";
                for (let e = h + 1; e < l && "ET" === o[e]; ++e) o[e] = "EN";
              }
            for (h = 0; h < l; ++h) {
              b = o[h];
              ("WS" !== b && "ES" !== b && "ET" !== b && "CS" !== b) ||
                (o[h] = "ON");
            }
            y = p;
            for (h = 0; h < l; ++h) {
              b = o[h];
              "EN" === b
                ? (o[h] = "L" === y ? "L" : "EN")
                : ("R" !== b && "L" !== b) || (y = b);
            }
            for (h = 0; h < l; ++h)
              if ("ON" === o[h]) {
                const e = findUnequal(o, h + 1, "ON");
                let t = p;
                h > 0 && (t = o[h - 1]);
                let a = m;
                e + 1 < l && (a = o[e + 1]);
                "L" !== t && (t = "R");
                "L" !== a && (a = "R");
                t === a && setValues(o, h, e, t);
                h = e - 1;
              }
            for (h = 0; h < l; ++h) "ON" === o[h] && (o[h] = g);
            for (h = 0; h < l; ++h) {
              b = o[h];
              isEven(f[h])
                ? "R" === b
                  ? (f[h] += 1)
                  : ("AN" !== b && "EN" !== b) || (f[h] += 2)
                : ("L" !== b && "AN" !== b && "EN" !== b) || (f[h] += 1);
            }
            let w,
              S = -1,
              x = 99;
            for (h = 0, u = f.length; h < u; ++h) {
              w = f[h];
              S < w && (S = w);
              x > w && isOdd(w) && (x = w);
            }
            for (w = S; w >= x; --w) {
              let e = -1;
              for (h = 0, u = f.length; h < u; ++h)
                if (f[h] < w) {
                  if (e >= 0) {
                    reverseValues(s, e, h);
                    e = -1;
                  }
                } else e < 0 && (e = h);
              e >= 0 && reverseValues(s, e, f.length);
            }
            for (h = 0, u = s.length; h < u; ++h) {
              const e = s[h];
              ("<" !== e && ">" !== e) || (s[h] = "");
            }
            return createBidiText(s.join(""), c);
          };
          var r = a(2);
          const n = [
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "S",
              "B",
              "S",
              "WS",
              "B",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "B",
              "B",
              "B",
              "S",
              "WS",
              "ON",
              "ON",
              "ET",
              "ET",
              "ET",
              "ON",
              "ON",
              "ON",
              "ON",
              "ON",
              "ES",
              "CS",
              "ES",
              "CS",
              "CS",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "CS",
              "ON",
              "ON",
              "ON",
              "ON",
              "ON",
              "ON",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "ON",
              "ON",
              "ON",
              "ON",
              "ON",
              "ON",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "ON",
              "ON",
              "ON",
              "ON",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "B",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "BN",
              "CS",
              "ON",
              "ET",
              "ET",
              "ET",
              "ET",
              "ON",
              "ON",
              "ON",
              "ON",
              "L",
              "ON",
              "ON",
              "BN",
              "ON",
              "ON",
              "ET",
              "ET",
              "EN",
              "EN",
              "ON",
              "L",
              "ON",
              "ON",
              "ON",
              "EN",
              "L",
              "ON",
              "ON",
              "ON",
              "ON",
              "ON",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "ON",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "ON",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
              "L",
            ],
            i = [
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "ON",
              "ON",
              "AL",
              "ET",
              "ET",
              "AL",
              "CS",
              "AL",
              "ON",
              "ON",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "AL",
              "AL",
              "",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "AN",
              "ET",
              "AN",
              "AN",
              "AL",
              "AL",
              "AL",
              "NSM",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "AN",
              "ON",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "AL",
              "AL",
              "NSM",
              "NSM",
              "ON",
              "NSM",
              "NSM",
              "NSM",
              "NSM",
              "AL",
              "AL",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "EN",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
              "AL",
            ];
          function isOdd(e) {
            return 0 != (1 & e);
          }
          function isEven(e) {
            return 0 == (1 & e);
          }
          function findUnequal(e, t, a) {
            let r, n;
            for (r = t, n = e.length; r < n; ++r) if (e[r] !== a) return r;
            return r;
          }
          function setValues(e, t, a, r) {
            for (let n = t; n < a; ++n) e[n] = r;
          }
          function reverseValues(e, t, a) {
            for (let r = t, n = a - 1; r < n; ++r, --n) {
              const t = e[r];
              e[r] = e[n];
              e[n] = t;
            }
          }
          function createBidiText(e, t, a = !1) {
            let r = "ltr";
            a ? (r = "ttb") : t || (r = "rtl");
            return { str: e, dir: r };
          }
          const s = [],
            o = [];
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.MurmurHash3_64 = void 0;
          var r = a(2);
          const n = 3285377520,
            i = 4294901760,
            s = 65535;
          t.MurmurHash3_64 = class MurmurHash3_64 {
            constructor(e) {
              this.h1 = e ? 4294967295 & e : n;
              this.h2 = e ? 4294967295 & e : n;
            }
            update(e) {
              let t, a;
              if ("string" == typeof e) {
                t = new Uint8Array(2 * e.length);
                a = 0;
                for (let r = 0, n = e.length; r < n; r++) {
                  const n = e.charCodeAt(r);
                  if (n <= 255) t[a++] = n;
                  else {
                    t[a++] = n >>> 8;
                    t[a++] = 255 & n;
                  }
                }
              } else {
                if (!(0, r.isArrayBuffer)(e))
                  throw new Error(
                    "Wrong data format in MurmurHash3_64_update. Input must be a string or array."
                  );
                t = e.slice();
                a = t.byteLength;
              }
              const n = a >> 2,
                o = a - 4 * n,
                c = new Uint32Array(t.buffer, 0, n);
              let l = 0,
                h = 0,
                u = this.h1,
                d = this.h2;
              const f = 3432918353,
                g = 461845907,
                p = 11601,
                m = 13715;
              for (let e = 0; e < n; e++)
                if (1 & e) {
                  l = c[e];
                  l = ((l * f) & i) | ((l * p) & s);
                  l = (l << 15) | (l >>> 17);
                  l = ((l * g) & i) | ((l * m) & s);
                  u ^= l;
                  u = (u << 13) | (u >>> 19);
                  u = 5 * u + 3864292196;
                } else {
                  h = c[e];
                  h = ((h * f) & i) | ((h * p) & s);
                  h = (h << 15) | (h >>> 17);
                  h = ((h * g) & i) | ((h * m) & s);
                  d ^= h;
                  d = (d << 13) | (d >>> 19);
                  d = 5 * d + 3864292196;
                }
              l = 0;
              switch (o) {
                case 3:
                  l ^= t[4 * n + 2] << 16;
                case 2:
                  l ^= t[4 * n + 1] << 8;
                case 1:
                  l ^= t[4 * n];
                  l = ((l * f) & i) | ((l * p) & s);
                  l = (l << 15) | (l >>> 17);
                  l = ((l * g) & i) | ((l * m) & s);
                  1 & n ? (u ^= l) : (d ^= l);
              }
              this.h1 = u;
              this.h2 = d;
            }
            hexdigest() {
              let e = this.h1,
                t = this.h2;
              e ^= t >>> 1;
              e = ((3981806797 * e) & i) | ((36045 * e) & s);
              t =
                ((4283543511 * t) & i) |
                (((2950163797 * ((t << 16) | (e >>> 16))) & i) >>> 16);
              e ^= t >>> 1;
              e = ((444984403 * e) & i) | ((60499 * e) & s);
              t =
                ((3301882366 * t) & i) |
                (((3120437893 * ((t << 16) | (e >>> 16))) & i) >>> 16);
              e ^= t >>> 1;
              return (
                (e >>> 0).toString(16).padStart(8, "0") +
                (t >>> 0).toString(16).padStart(8, "0")
              );
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.OperatorList = void 0;
          var r = a(2);
          function addState(e, t, a, r, n) {
            let i = e;
            for (let e = 0, a = t.length - 1; e < a; e++) {
              const a = t[e];
              i = i[a] || (i[a] = []);
            }
            i[t.at(-1)] = { checkFn: a, iterateFn: r, processFn: n };
          }
          const n = [];
          addState(
            n,
            [
              r.OPS.save,
              r.OPS.transform,
              r.OPS.paintInlineImageXObject,
              r.OPS.restore,
            ],
            null,
            function iterateInlineImageGroup(e, t) {
              const a = e.fnArray,
                n = (t - (e.iCurr - 3)) % 4;
              switch (n) {
                case 0:
                  return a[t] === r.OPS.save;
                case 1:
                  return a[t] === r.OPS.transform;
                case 2:
                  return a[t] === r.OPS.paintInlineImageXObject;
                case 3:
                  return a[t] === r.OPS.restore;
              }
              throw new Error(`iterateInlineImageGroup - invalid pos: ${n}`);
            },
            function foundInlineImageGroup(e, t) {
              const a = e.fnArray,
                n = e.argsArray,
                i = e.iCurr,
                s = i - 3,
                o = i - 2,
                c = i - 1,
                l = Math.min(Math.floor((t - s) / 4), 200);
              if (l < 10) return t - ((t - s) % 4);
              let h = 0;
              const u = [];
              let d = 0,
                f = 1,
                g = 1;
              for (let e = 0; e < l; e++) {
                const t = n[o + (e << 2)],
                  a = n[c + (e << 2)][0];
                if (f + a.width > 1e3) {
                  h = Math.max(h, f);
                  g += d + 2;
                  f = 0;
                  d = 0;
                }
                u.push({ transform: t, x: f, y: g, w: a.width, h: a.height });
                f += a.width + 2;
                d = Math.max(d, a.height);
              }
              const p = Math.max(h, f) + 1,
                m = g + d + 1,
                b = new Uint8Array(p * m * 4),
                y = p << 2;
              for (let e = 0; e < l; e++) {
                const t = n[c + (e << 2)][0].data,
                  a = u[e].w << 2;
                let r = 0,
                  i = (u[e].x + u[e].y * p) << 2;
                b.set(t.subarray(0, a), i - y);
                for (let n = 0, s = u[e].h; n < s; n++) {
                  b.set(t.subarray(r, r + a), i);
                  r += a;
                  i += y;
                }
                b.set(t.subarray(r - a, r), i);
                for (; i >= 0; ) {
                  t[i - 4] = t[i];
                  t[i - 3] = t[i + 1];
                  t[i - 2] = t[i + 2];
                  t[i - 1] = t[i + 3];
                  t[i + a] = t[i + a - 4];
                  t[i + a + 1] = t[i + a - 3];
                  t[i + a + 2] = t[i + a - 2];
                  t[i + a + 3] = t[i + a - 1];
                  i -= y;
                }
              }
              a.splice(s, 4 * l, r.OPS.paintInlineImageXObjectGroup);
              n.splice(s, 4 * l, [
                { width: p, height: m, kind: r.ImageKind.RGBA_32BPP, data: b },
                u,
              ]);
              return s + 1;
            }
          );
          addState(
            n,
            [
              r.OPS.save,
              r.OPS.transform,
              r.OPS.paintImageMaskXObject,
              r.OPS.restore,
            ],
            null,
            function iterateImageMaskGroup(e, t) {
              const a = e.fnArray,
                n = (t - (e.iCurr - 3)) % 4;
              switch (n) {
                case 0:
                  return a[t] === r.OPS.save;
                case 1:
                  return a[t] === r.OPS.transform;
                case 2:
                  return a[t] === r.OPS.paintImageMaskXObject;
                case 3:
                  return a[t] === r.OPS.restore;
              }
              throw new Error(`iterateImageMaskGroup - invalid pos: ${n}`);
            },
            function foundImageMaskGroup(e, t) {
              const a = e.fnArray,
                n = e.argsArray,
                i = e.iCurr,
                s = i - 3,
                o = i - 2,
                c = i - 1;
              let l = Math.floor((t - s) / 4);
              if (l < 10) return t - ((t - s) % 4);
              let h,
                u,
                d = !1;
              const f = n[c][0],
                g = n[o][0],
                p = n[o][1],
                m = n[o][2],
                b = n[o][3];
              if (p === m) {
                d = !0;
                h = o + 4;
                let e = c + 4;
                for (let t = 1; t < l; t++, h += 4, e += 4) {
                  u = n[h];
                  if (
                    n[e][0] !== f ||
                    u[0] !== g ||
                    u[1] !== p ||
                    u[2] !== m ||
                    u[3] !== b
                  ) {
                    t < 10 ? (d = !1) : (l = t);
                    break;
                  }
                }
              }
              if (d) {
                l = Math.min(l, 1e3);
                const e = new Float32Array(2 * l);
                h = o;
                for (let t = 0; t < l; t++, h += 4) {
                  u = n[h];
                  e[t << 1] = u[4];
                  e[1 + (t << 1)] = u[5];
                }
                a.splice(s, 4 * l, r.OPS.paintImageMaskXObjectRepeat);
                n.splice(s, 4 * l, [f, g, p, m, b, e]);
              } else {
                l = Math.min(l, 100);
                const e = [];
                for (let t = 0; t < l; t++) {
                  u = n[o + (t << 2)];
                  const a = n[c + (t << 2)][0];
                  e.push({
                    data: a.data,
                    width: a.width,
                    height: a.height,
                    interpolate: a.interpolate,
                    count: a.count,
                    transform: u,
                  });
                }
                a.splice(s, 4 * l, r.OPS.paintImageMaskXObjectGroup);
                n.splice(s, 4 * l, [e]);
              }
              return s + 1;
            }
          );
          addState(
            n,
            [
              r.OPS.save,
              r.OPS.transform,
              r.OPS.paintImageXObject,
              r.OPS.restore,
            ],
            function (e) {
              const t = e.argsArray,
                a = e.iCurr - 2;
              return 0 === t[a][1] && 0 === t[a][2];
            },
            function iterateImageGroup(e, t) {
              const a = e.fnArray,
                n = e.argsArray,
                i = (t - (e.iCurr - 3)) % 4;
              switch (i) {
                case 0:
                  return a[t] === r.OPS.save;
                case 1:
                  if (a[t] !== r.OPS.transform) return !1;
                  const i = e.iCurr - 2,
                    s = n[i][0],
                    o = n[i][3];
                  return (
                    n[t][0] === s &&
                    0 === n[t][1] &&
                    0 === n[t][2] &&
                    n[t][3] === o
                  );
                case 2:
                  if (a[t] !== r.OPS.paintImageXObject) return !1;
                  const c = n[e.iCurr - 1][0];
                  return n[t][0] === c;
                case 3:
                  return a[t] === r.OPS.restore;
              }
              throw new Error(`iterateImageGroup - invalid pos: ${i}`);
            },
            function (e, t) {
              const a = e.fnArray,
                n = e.argsArray,
                i = e.iCurr,
                s = i - 3,
                o = i - 2,
                c = n[i - 1][0],
                l = n[o][0],
                h = n[o][3],
                u = Math.min(Math.floor((t - s) / 4), 1e3);
              if (u < 3) return t - ((t - s) % 4);
              const d = new Float32Array(2 * u);
              let f = o;
              for (let e = 0; e < u; e++, f += 4) {
                const t = n[f];
                d[e << 1] = t[4];
                d[1 + (e << 1)] = t[5];
              }
              const g = [c, l, h, d];
              a.splice(s, 4 * u, r.OPS.paintImageXObjectRepeat);
              n.splice(s, 4 * u, g);
              return s + 1;
            }
          );
          addState(
            n,
            [
              r.OPS.beginText,
              r.OPS.setFont,
              r.OPS.setTextMatrix,
              r.OPS.showText,
              r.OPS.endText,
            ],
            null,
            function iterateShowTextGroup(e, t) {
              const a = e.fnArray,
                n = e.argsArray,
                i = (t - (e.iCurr - 4)) % 5;
              switch (i) {
                case 0:
                  return a[t] === r.OPS.beginText;
                case 1:
                  return a[t] === r.OPS.setFont;
                case 2:
                  return a[t] === r.OPS.setTextMatrix;
                case 3:
                  if (a[t] !== r.OPS.showText) return !1;
                  const i = e.iCurr - 3,
                    s = n[i][0],
                    o = n[i][1];
                  return n[t][0] === s && n[t][1] === o;
                case 4:
                  return a[t] === r.OPS.endText;
              }
              throw new Error(`iterateShowTextGroup - invalid pos: ${i}`);
            },
            function (e, t) {
              const a = e.fnArray,
                r = e.argsArray,
                n = e.iCurr,
                i = n - 4,
                s = n - 3,
                o = n - 2,
                c = n - 1,
                l = n,
                h = r[s][0],
                u = r[s][1];
              let d = Math.min(Math.floor((t - i) / 5), 1e3);
              if (d < 3) return t - ((t - i) % 5);
              let f = i;
              if (
                i >= 4 &&
                a[i - 4] === a[s] &&
                a[i - 3] === a[o] &&
                a[i - 2] === a[c] &&
                a[i - 1] === a[l] &&
                r[i - 4][0] === h &&
                r[i - 4][1] === u
              ) {
                d++;
                f -= 5;
              }
              let g = f + 4;
              for (let e = 1; e < d; e++) {
                a.splice(g, 3);
                r.splice(g, 3);
                g += 2;
              }
              return g + 1;
            }
          );
          class NullOptimizer {
            constructor(e) {
              this.queue = e;
            }
            _optimize() {}
            push(e, t) {
              this.queue.fnArray.push(e);
              this.queue.argsArray.push(t);
              this._optimize();
            }
            flush() {}
            reset() {}
          }
          class QueueOptimizer extends NullOptimizer {
            constructor(e) {
              super(e);
              this.state = null;
              this.context = {
                iCurr: 0,
                fnArray: e.fnArray,
                argsArray: e.argsArray,
              };
              this.match = null;
              this.lastProcessed = 0;
            }
            _optimize() {
              const e = this.queue.fnArray;
              let t = this.lastProcessed,
                a = e.length,
                r = this.state,
                i = this.match;
              if (!r && !i && t + 1 === a && !n[e[t]]) {
                this.lastProcessed = a;
                return;
              }
              const s = this.context;
              for (; t < a; ) {
                if (i) {
                  if ((0, i.iterateFn)(s, t)) {
                    t++;
                    continue;
                  }
                  t = (0, i.processFn)(s, t + 1);
                  a = e.length;
                  i = null;
                  r = null;
                  if (t >= a) break;
                }
                r = (r || n)[e[t]];
                if (r && !Array.isArray(r)) {
                  s.iCurr = t;
                  t++;
                  if (!r.checkFn || (0, r.checkFn)(s)) {
                    i = r;
                    r = null;
                  } else r = null;
                } else t++;
              }
              this.state = r;
              this.match = i;
              this.lastProcessed = t;
            }
            flush() {
              for (; this.match; ) {
                const e = this.queue.fnArray.length;
                this.lastProcessed = (0, this.match.processFn)(this.context, e);
                this.match = null;
                this.state = null;
                this._optimize();
              }
            }
            reset() {
              this.state = null;
              this.match = null;
              this.lastProcessed = 0;
            }
          }
          class OperatorList {
            static get CHUNK_SIZE() {
              return (0, r.shadow)(this, "CHUNK_SIZE", 1e3);
            }
            static get CHUNK_SIZE_ABOUT() {
              return (0, r.shadow)(
                this,
                "CHUNK_SIZE_ABOUT",
                this.CHUNK_SIZE - 5
              );
            }
            constructor(e = 0, t) {
              this._streamSink = t;
              this.fnArray = [];
              this.argsArray = [];
              !t || e & r.RenderingIntentFlag.OPLIST
                ? (this.optimizer = new NullOptimizer(this))
                : (this.optimizer = new QueueOptimizer(this));
              this.dependencies = new Set();
              this._totalLength = 0;
              this.weight = 0;
              this._resolved = t ? null : Promise.resolve();
            }
            get length() {
              return this.argsArray.length;
            }
            get ready() {
              return this._resolved || this._streamSink.ready;
            }
            get totalLength() {
              return this._totalLength + this.length;
            }
            addOp(e, t) {
              this.optimizer.push(e, t);
              this.weight++;
              this._streamSink &&
                (this.weight >= OperatorList.CHUNK_SIZE ||
                  (this.weight >= OperatorList.CHUNK_SIZE_ABOUT &&
                    (e === r.OPS.restore || e === r.OPS.endText))) &&
                this.flush();
            }
            addImageOps(e, t, a) {
              void 0 !== a &&
                this.addOp(r.OPS.beginMarkedContentProps, ["OC", a]);
              this.addOp(e, t);
              void 0 !== a && this.addOp(r.OPS.endMarkedContent, []);
            }
            addDependency(e) {
              if (!this.dependencies.has(e)) {
                this.dependencies.add(e);
                this.addOp(r.OPS.dependency, [e]);
              }
            }
            addDependencies(e) {
              for (const t of e) this.addDependency(t);
            }
            addOpList(e) {
              if (e instanceof OperatorList) {
                for (const t of e.dependencies) this.dependencies.add(t);
                for (let t = 0, a = e.length; t < a; t++)
                  this.addOp(e.fnArray[t], e.argsArray[t]);
              } else
                (0, r.warn)('addOpList - ignoring invalid "opList" parameter.');
            }
            getIR() {
              return {
                fnArray: this.fnArray,
                argsArray: this.argsArray,
                length: this.length,
              };
            }
            get _transfers() {
              const e = [],
                { fnArray: t, argsArray: a, length: n } = this;
              for (let i = 0; i < n; i++)
                switch (t[i]) {
                  case r.OPS.paintInlineImageXObject:
                  case r.OPS.paintInlineImageXObjectGroup:
                  case r.OPS.paintImageMaskXObject:
                    const t = a[i][0];
                    !t.cached &&
                      t.data &&
                      t.data.buffer instanceof ArrayBuffer &&
                      e.push(t.data.buffer);
                }
              return e;
            }
            flush(e = !1, t = null) {
              this.optimizer.flush();
              const a = this.length;
              this._totalLength += a;
              this._streamSink.enqueue(
                {
                  fnArray: this.fnArray,
                  argsArray: this.argsArray,
                  lastChunk: e,
                  separateAnnots: t,
                  length: a,
                },
                1,
                this._transfers
              );
              this.dependencies.clear();
              this.fnArray.length = 0;
              this.argsArray.length = 0;
              this.weight = 0;
              this.optimizer.reset();
            }
          }
          t.OperatorList = OperatorList;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PDFImage = void 0;
          var r = a(2),
            n = a(62),
            i = a(5),
            s = a(12),
            o = a(17),
            c = a(25),
            l = a(28),
            h = a(4);
          function decodeAndClamp(e, t, a, r) {
            (e = t + e * a) < 0 ? (e = 0) : e > r && (e = r);
            return e;
          }
          function resizeImageMask(e, t, a, r, n, i) {
            const s = n * i;
            let o;
            o =
              t <= 8
                ? new Uint8Array(s)
                : t <= 16
                ? new Uint16Array(s)
                : new Uint32Array(s);
            const c = a / n,
              l = r / i;
            let h,
              u,
              d,
              f,
              g = 0;
            const p = new Uint16Array(n),
              m = a;
            for (h = 0; h < n; h++) p[h] = Math.floor(h * c);
            for (h = 0; h < i; h++) {
              d = Math.floor(h * l) * m;
              for (u = 0; u < n; u++) {
                f = d + p[u];
                o[g++] = e[f];
              }
            }
            return o;
          }
          class PDFImage {
            constructor({
              xref: e,
              res: t,
              image: a,
              isInline: n = !1,
              smask: o = null,
              mask: c = null,
              isMask: u = !1,
              pdfFunctionFactory: d,
              localColorSpaceCache: f,
            }) {
              this.image = a;
              const g = a.dict,
                p = g.get("F", "Filter");
              let m;
              if (p instanceof h.Name) m = p.name;
              else if (Array.isArray(p)) {
                const t = e.fetchIfRef(p[0]);
                t instanceof h.Name && (m = t.name);
              }
              switch (m) {
                case "JPXDecode":
                  const e = new l.JpxImage();
                  e.parseImageProperties(a.stream);
                  a.stream.reset();
                  a.width = e.width;
                  a.height = e.height;
                  a.bitsPerComponent = e.bitsPerComponent;
                  a.numComps = e.componentsCount;
                  break;
                case "JBIG2Decode":
                  a.bitsPerComponent = 1;
                  a.numComps = 1;
              }
              let b = g.get("W", "Width"),
                y = g.get("H", "Height");
              if (
                Number.isInteger(a.width) &&
                a.width > 0 &&
                Number.isInteger(a.height) &&
                a.height > 0 &&
                (a.width !== b || a.height !== y)
              ) {
                (0, r.warn)(
                  "PDFImage - using the Width/Height of the image data, rather than the image dictionary."
                );
                b = a.width;
                y = a.height;
              }
              if (b < 1 || y < 1)
                throw new r.FormatError(
                  `Invalid image width: ${b} or height: ${y}`
                );
              this.width = b;
              this.height = y;
              this.interpolate = g.get("I", "Interpolate");
              this.imageMask = g.get("IM", "ImageMask") || !1;
              this.matte = g.get("Matte") || !1;
              let w = a.bitsPerComponent;
              if (!w) {
                w = g.get("BPC", "BitsPerComponent");
                if (!w) {
                  if (!this.imageMask)
                    throw new r.FormatError(
                      `Bits per component missing in image: ${this.imageMask}`
                    );
                  w = 1;
                }
              }
              this.bpc = w;
              if (!this.imageMask) {
                let i = g.getRaw("CS") || g.getRaw("ColorSpace");
                if (!i) {
                  (0, r.info)("JPX images (which do not require color spaces)");
                  switch (a.numComps) {
                    case 1:
                      i = h.Name.get("DeviceGray");
                      break;
                    case 3:
                      i = h.Name.get("DeviceRGB");
                      break;
                    case 4:
                      i = h.Name.get("DeviceCMYK");
                      break;
                    default:
                      throw new Error(
                        `JPX images with ${a.numComps} color components not supported.`
                      );
                  }
                }
                this.colorSpace = s.ColorSpace.parse({
                  cs: i,
                  xref: e,
                  resources: n ? t : null,
                  pdfFunctionFactory: d,
                  localColorSpaceCache: f,
                });
                this.numComps = this.colorSpace.numComps;
              }
              this.decode = g.getArray("D", "Decode");
              this.needsDecode = !1;
              if (
                this.decode &&
                ((this.colorSpace &&
                  !this.colorSpace.isDefaultDecode(this.decode, w)) ||
                  (u && !s.ColorSpace.isDefaultDecode(this.decode, 1)))
              ) {
                this.needsDecode = !0;
                const e = (1 << w) - 1;
                this.decodeCoefficients = [];
                this.decodeAddends = [];
                const t = this.colorSpace && "Indexed" === this.colorSpace.name;
                for (let a = 0, r = 0; a < this.decode.length; a += 2, ++r) {
                  const n = this.decode[a],
                    i = this.decode[a + 1];
                  this.decodeCoefficients[r] = t ? (i - n) / e : i - n;
                  this.decodeAddends[r] = t ? n : e * n;
                }
              }
              if (o)
                this.smask = new PDFImage({
                  xref: e,
                  res: t,
                  image: o,
                  isInline: n,
                  pdfFunctionFactory: d,
                  localColorSpaceCache: f,
                });
              else if (c)
                if (c instanceof i.BaseStream) {
                  c.dict.get("IM", "ImageMask")
                    ? (this.mask = new PDFImage({
                        xref: e,
                        res: t,
                        image: c,
                        isInline: n,
                        isMask: !0,
                        pdfFunctionFactory: d,
                        localColorSpaceCache: f,
                      }))
                    : (0, r.warn)(
                        "Ignoring /Mask in image without /ImageMask."
                      );
                } else this.mask = c;
            }
            static async buildImage({
              xref: e,
              res: t,
              image: a,
              isInline: n = !1,
              pdfFunctionFactory: s,
              localColorSpaceCache: o,
            }) {
              const c = a;
              let l = null,
                h = null;
              const u = a.dict.get("SMask"),
                d = a.dict.get("Mask");
              u
                ? u instanceof i.BaseStream
                  ? (l = u)
                  : (0, r.warn)("Unsupported /SMask format.")
                : d &&
                  (d instanceof i.BaseStream || Array.isArray(d)
                    ? (h = d)
                    : (0, r.warn)("Unsupported /Mask format."));
              return new PDFImage({
                xref: e,
                res: t,
                image: c,
                isInline: n,
                smask: l,
                mask: h,
                pdfFunctionFactory: s,
                localColorSpaceCache: o,
              });
            }
            static createRawMask({
              imgArray: e,
              width: t,
              height: a,
              imageIsFromDecodeStream: r,
              inverseDecode: n,
              interpolate: i,
            }) {
              const s = ((t + 7) >> 3) * a,
                o = e.byteLength;
              let c, l;
              if (!r || (n && !(s === o)))
                if (n) {
                  c = new Uint8Array(s);
                  c.set(e);
                  c.fill(255, o);
                } else c = new Uint8Array(e);
              else c = e;
              if (n) for (l = 0; l < o; l++) c[l] ^= 255;
              return { data: c, width: t, height: a, interpolate: i };
            }
            static createMask({
              imgArray: e,
              width: t,
              height: a,
              imageIsFromDecodeStream: i,
              inverseDecode: s,
              interpolate: o,
              isOffscreenCanvasSupported: c = !0,
            }) {
              const l =
                1 === t && 1 === a && s === (0 === e.length || !!(128 & e[0]));
              if (l) return { isSingleOpaquePixel: l };
              if (c && r.FeatureTest.isOffscreenCanvasSupported) {
                const r = new OffscreenCanvas(t, a),
                  i = r.getContext("2d"),
                  c = i.createImageData(t, a);
                (0, n.applyMaskImageData)({
                  src: e,
                  dest: c.data,
                  width: t,
                  height: a,
                  inverseDecode: s,
                });
                i.putImageData(c, 0, 0);
                return {
                  data: null,
                  width: t,
                  height: a,
                  interpolate: o,
                  bitmap: r.transferToImageBitmap(),
                };
              }
              return this.createRawMask({
                imgArray: e,
                width: t,
                height: a,
                inverseDecode: s,
                imageIsFromDecodeStream: i,
                interpolate: o,
              });
            }
            get drawWidth() {
              return Math.max(
                this.width,
                (this.smask && this.smask.width) || 0,
                (this.mask && this.mask.width) || 0
              );
            }
            get drawHeight() {
              return Math.max(
                this.height,
                (this.smask && this.smask.height) || 0,
                (this.mask && this.mask.height) || 0
              );
            }
            decodeBuffer(e) {
              const t = this.bpc,
                a = this.numComps,
                r = this.decodeAddends,
                n = this.decodeCoefficients,
                i = (1 << t) - 1;
              let s, o;
              if (1 === t) {
                for (s = 0, o = e.length; s < o; s++) e[s] = +!e[s];
                return;
              }
              let c = 0;
              for (s = 0, o = this.width * this.height; s < o; s++)
                for (let t = 0; t < a; t++) {
                  e[c] = decodeAndClamp(e[c], r[t], n[t], i);
                  c++;
                }
            }
            getComponents(e) {
              const t = this.bpc;
              if (8 === t) return e;
              const a = this.width,
                r = this.height,
                n = this.numComps,
                i = a * r * n;
              let s,
                o = 0;
              s =
                t <= 8
                  ? new Uint8Array(i)
                  : t <= 16
                  ? new Uint16Array(i)
                  : new Uint32Array(i);
              const c = a * n,
                l = (1 << t) - 1;
              let h,
                u,
                d = 0;
              if (1 === t) {
                let t, a, n;
                for (let i = 0; i < r; i++) {
                  a = d + (-8 & c);
                  n = d + c;
                  for (; d < a; ) {
                    u = e[o++];
                    s[d] = (u >> 7) & 1;
                    s[d + 1] = (u >> 6) & 1;
                    s[d + 2] = (u >> 5) & 1;
                    s[d + 3] = (u >> 4) & 1;
                    s[d + 4] = (u >> 3) & 1;
                    s[d + 5] = (u >> 2) & 1;
                    s[d + 6] = (u >> 1) & 1;
                    s[d + 7] = 1 & u;
                    d += 8;
                  }
                  if (d < n) {
                    u = e[o++];
                    t = 128;
                    for (; d < n; ) {
                      s[d++] = +!!(u & t);
                      t >>= 1;
                    }
                  }
                }
              } else {
                let a = 0;
                u = 0;
                for (d = 0, h = i; d < h; ++d) {
                  if (d % c == 0) {
                    u = 0;
                    a = 0;
                  }
                  for (; a < t; ) {
                    u = (u << 8) | e[o++];
                    a += 8;
                  }
                  const r = a - t;
                  let n = u >> r;
                  n < 0 ? (n = 0) : n > l && (n = l);
                  s[d] = n;
                  u &= (1 << r) - 1;
                  a = r;
                }
              }
              return s;
            }
            fillOpacity(e, t, a, n, i) {
              const s = this.smask,
                o = this.mask;
              let c, l, h, u, d, f;
              if (s) {
                l = s.width;
                h = s.height;
                c = new Uint8ClampedArray(l * h);
                s.fillGrayBuffer(c);
                (l === t && h === a) ||
                  (c = resizeImageMask(c, s.bpc, l, h, t, a));
              } else if (o)
                if (o instanceof PDFImage) {
                  l = o.width;
                  h = o.height;
                  c = new Uint8ClampedArray(l * h);
                  o.numComps = 1;
                  o.fillGrayBuffer(c);
                  for (u = 0, d = l * h; u < d; ++u) c[u] = 255 - c[u];
                  (l === t && h === a) ||
                    (c = resizeImageMask(c, o.bpc, l, h, t, a));
                } else {
                  if (!Array.isArray(o))
                    throw new r.FormatError("Unknown mask format.");
                  {
                    c = new Uint8ClampedArray(t * a);
                    const e = this.numComps;
                    for (u = 0, d = t * a; u < d; ++u) {
                      let t = 0;
                      const a = u * e;
                      for (f = 0; f < e; ++f) {
                        const e = i[a + f],
                          r = 2 * f;
                        if (e < o[r] || e > o[r + 1]) {
                          t = 255;
                          break;
                        }
                      }
                      c[u] = t;
                    }
                  }
                }
              if (c)
                for (u = 0, f = 3, d = t * n; u < d; ++u, f += 4) e[f] = c[u];
              else for (u = 0, f = 3, d = t * n; u < d; ++u, f += 4) e[f] = 255;
            }
            undoPreblend(e, t, a) {
              const r = this.smask && this.smask.matte;
              if (!r) return;
              const n = this.colorSpace.getRgb(r, 0),
                i = n[0],
                s = n[1],
                o = n[2],
                c = t * a * 4;
              for (let t = 0; t < c; t += 4) {
                const a = e[t + 3];
                if (0 === a) {
                  e[t] = 255;
                  e[t + 1] = 255;
                  e[t + 2] = 255;
                  continue;
                }
                const r = 255 / a;
                e[t] = (e[t] - i) * r + i;
                e[t + 1] = (e[t + 1] - s) * r + s;
                e[t + 2] = (e[t + 2] - o) * r + o;
              }
            }
            createImageData(e = !1) {
              const t = this.drawWidth,
                a = this.drawHeight,
                n = {
                  width: t,
                  height: a,
                  interpolate: this.interpolate,
                  kind: 0,
                  data: null,
                },
                i = this.numComps,
                s = this.width,
                o = this.height,
                l = this.bpc,
                h = (s * i * l + 7) >> 3;
              if (!e) {
                let e;
                "DeviceGray" === this.colorSpace.name && 1 === l
                  ? (e = r.ImageKind.GRAYSCALE_1BPP)
                  : "DeviceRGB" !== this.colorSpace.name ||
                    8 !== l ||
                    this.needsDecode ||
                    (e = r.ImageKind.RGB_24BPP);
                if (e && !this.smask && !this.mask && t === s && a === o) {
                  n.kind = e;
                  n.data = this.getImageBytes(o * h, {});
                  if (this.needsDecode) {
                    (0, r.assert)(
                      e === r.ImageKind.GRAYSCALE_1BPP,
                      "PDFImage.createImageData: The image must be grayscale."
                    );
                    const t = n.data;
                    for (let e = 0, a = t.length; e < a; e++) t[e] ^= 255;
                  }
                  return n;
                }
                if (
                  this.image instanceof c.JpegStream &&
                  !this.smask &&
                  !this.mask
                ) {
                  let e = o * h;
                  switch (this.colorSpace.name) {
                    case "DeviceGray":
                      e *= 3;
                    case "DeviceRGB":
                    case "DeviceCMYK":
                      n.kind = r.ImageKind.RGB_24BPP;
                      n.data = this.getImageBytes(e, {
                        drawWidth: t,
                        drawHeight: a,
                        forceRGB: !0,
                      });
                      return n;
                  }
                }
              }
              const u = this.getImageBytes(o * h, { internal: !0 }),
                d = 0 | (((u.length / h) * a) / o),
                f = this.getComponents(u);
              let g, p;
              if (e || this.smask || this.mask) {
                n.kind = r.ImageKind.RGBA_32BPP;
                n.data = new Uint8ClampedArray(t * a * 4);
                g = 1;
                p = !0;
                this.fillOpacity(n.data, t, a, d, f);
              } else {
                n.kind = r.ImageKind.RGB_24BPP;
                n.data = new Uint8ClampedArray(t * a * 3);
                g = 0;
                p = !1;
              }
              this.needsDecode && this.decodeBuffer(f);
              this.colorSpace.fillRgb(n.data, s, o, t, a, d, l, f, g);
              p && this.undoPreblend(n.data, t, d);
              return n;
            }
            fillGrayBuffer(e) {
              const t = this.numComps;
              if (1 !== t)
                throw new r.FormatError(
                  `Reading gray scale from a color image: ${t}`
                );
              const a = this.width,
                n = this.height,
                i = this.bpc,
                s = (a * t * i + 7) >> 3,
                o = this.getImageBytes(n * s, { internal: !0 }),
                c = this.getComponents(o);
              let l, h;
              if (1 === i) {
                h = a * n;
                if (this.needsDecode)
                  for (l = 0; l < h; ++l) e[l] = (c[l] - 1) & 255;
                else for (l = 0; l < h; ++l) e[l] = 255 & -c[l];
                return;
              }
              this.needsDecode && this.decodeBuffer(c);
              h = a * n;
              const u = 255 / ((1 << i) - 1);
              for (l = 0; l < h; ++l) e[l] = u * c[l];
            }
            getImageBytes(
              e,
              {
                drawWidth: t,
                drawHeight: a,
                forceRGB: n = !1,
                internal: i = !1,
              }
            ) {
              this.image.reset();
              this.image.drawWidth = t || this.width;
              this.image.drawHeight = a || this.height;
              this.image.forceRGB = !!n;
              const s = this.image.getBytes(e);
              if (i || this.image instanceof o.DecodeStream) return s;
              (0, r.assert)(
                s instanceof Uint8Array,
                'PDFImage.getImageBytes: Unsupported "imageBytes" type.'
              );
              return new Uint8Array(s);
            }
          }
          t.PDFImage = PDFImage;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.applyMaskImageData = function applyMaskImageData({
            src: e,
            srcPos: t = 0,
            dest: a,
            destPos: n = 0,
            width: i,
            height: s,
            inverseDecode: o = !1,
          }) {
            const c = r.FeatureTest.isLittleEndian ? 4278190080 : 255,
              [l, h] = o ? [0, c] : [c, 0],
              u = i >> 3,
              d = 7 & i,
              f = e.length;
            a = new Uint32Array(a.buffer);
            for (let r = 0; r < s; r++) {
              for (const r = t + u; t < r; t++) {
                const r = t < f ? e[t] : 255;
                a[n++] = 128 & r ? h : l;
                a[n++] = 64 & r ? h : l;
                a[n++] = 32 & r ? h : l;
                a[n++] = 16 & r ? h : l;
                a[n++] = 8 & r ? h : l;
                a[n++] = 4 & r ? h : l;
                a[n++] = 2 & r ? h : l;
                a[n++] = 1 & r ? h : l;
              }
              if (0 === d) continue;
              const r = t < f ? e[t++] : 255;
              for (let e = 0; e < d; e++) a[n++] = r & (1 << (7 - e)) ? h : l;
            }
            return { srcPos: t, destPos: n };
          };
          var r = a(2);
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.incrementalUpdate = function incrementalUpdate({
            originalData: e,
            xrefInfo: t,
            newRefs: a,
            xref: o = null,
            hasXfa: l = !1,
            xfaDatasetsRef: h = null,
            hasXfaDatasetsEntry: u = !1,
            needAppearances: d,
            acroFormRef: f = null,
            acroForm: g = null,
            xfaData: p = null,
          }) {
            !(function updateAcroform({
              xref: e,
              acroForm: t,
              acroFormRef: a,
              hasXfa: i,
              hasXfaDatasetsEntry: s,
              xfaDatasetsRef: o,
              needAppearances: c,
              newRefs: l,
            }) {
              !i || s || o || (0, r.warn)("XFA - Cannot save it");
              if (!(c || (i && o))) return;
              const h = new n.Dict(e);
              for (const e of t.getKeys()) h.set(e, t.getRaw(e));
              if (i && !s) {
                const e = t.get("XFA").slice();
                e.splice(2, 0, "datasets");
                e.splice(3, 0, o);
                h.set("XFA", e);
              }
              c && h.set("NeedAppearances", !0);
              const u = e.encrypt;
              let d = null;
              u && (d = u.createCipherTransform(a.num, a.gen));
              const f = [];
              writeObject(a, h, f, d);
              l.push({ ref: a, data: f.join("") });
            })({
              xref: o,
              acroForm: g,
              acroFormRef: f,
              hasXfa: l,
              hasXfaDatasetsEntry: u,
              xfaDatasetsRef: h,
              needAppearances: d,
              newRefs: a,
            });
            l &&
              (function updateXFA({
                xfaData: e,
                xfaDatasetsRef: t,
                newRefs: a,
                xref: n,
              }) {
                if (null === e) {
                  e = (function writeXFADataForAcroform(e, t) {
                    const a = new s.SimpleXMLParser({
                      hasAttributes: !0,
                    }).parseFromString(e);
                    for (const { xfa: e } of t) {
                      if (!e) continue;
                      const { path: t, value: n } = e;
                      if (!t) continue;
                      const o = (0, i.parseXFAPath)(t);
                      let c = a.documentElement.searchNode(o, 0);
                      !c &&
                        o.length > 1 &&
                        (c = a.documentElement.searchNode([o.at(-1)], 0));
                      c
                        ? Array.isArray(n)
                          ? (c.childNodes = n.map(
                              (e) => new s.SimpleDOMNode("value", e)
                            ))
                          : (c.childNodes = [new s.SimpleDOMNode("#text", n)])
                        : (0, r.warn)(`Node not found for path: ${t}`);
                    }
                    const n = [];
                    a.documentElement.dump(n);
                    return n.join("");
                  })(n.fetchIfRef(t).getString(), a);
                }
                const o = n.encrypt;
                if (o) {
                  e = o.createCipherTransform(t.num, t.gen).encryptString(e);
                }
                const c =
                  `${t.num} ${t.gen} obj\n<< /Type /EmbeddedFile /Length ${e.length}>>\nstream\n` +
                  e +
                  "\nendstream\nendobj\n";
                a.push({ ref: t, data: c });
              })({ xfaData: p, xfaDatasetsRef: h, newRefs: a, xref: o });
            const m = new n.Dict(null),
              b = t.newRef;
            let y, w;
            const S = e.at(-1);
            if (10 === S || 13 === S) {
              y = [];
              w = e.length;
            } else {
              y = ["\n"];
              w = e.length + 1;
            }
            m.set("Size", b.num + 1);
            m.set("Prev", t.startXRef);
            m.set("Type", n.Name.get("XRef"));
            null !== t.rootRef && m.set("Root", t.rootRef);
            null !== t.infoRef && m.set("Info", t.infoRef);
            null !== t.encryptRef && m.set("Encrypt", t.encryptRef);
            a.push({ ref: b, data: "" });
            a = a.sort((e, t) => e.ref.num - t.ref.num);
            const x = [[0, 1, 65535]],
              C = [0, 1];
            let k = 0;
            for (const { ref: e, data: t } of a) {
              k = Math.max(k, w);
              x.push([1, w, Math.min(e.gen, 65535)]);
              w += t.length;
              C.push(e.num, 1);
              y.push(t);
            }
            m.set("Index", C);
            if (Array.isArray(t.fileIds) && t.fileIds.length > 0) {
              const e = (function computeMD5(e, t) {
                const a = Math.floor(Date.now() / 1e3),
                  n = t.filename || "",
                  i = [a.toString(), n, e.toString()];
                let s = i.reduce((e, t) => e + t.length, 0);
                for (const e of Object.values(t.info)) {
                  i.push(e);
                  s += e.length;
                }
                const o = new Uint8Array(s);
                let l = 0;
                for (const e of i) {
                  writeString(e, l, o);
                  l += e.length;
                }
                return (0, r.bytesToString)((0, c.calculateMD5)(o));
              })(w, t);
              m.set("ID", [t.fileIds[0], e]);
            }
            const v = [1, Math.ceil(Math.log2(k) / 8), 2],
              F = (v[0] + v[1] + v[2]) * x.length;
            m.set("W", v);
            m.set("Length", F);
            y.push(`${b.num} ${b.gen} obj\n`);
            writeDict(m, y, null);
            y.push(" stream\n");
            const O = y.reduce((e, t) => e + t.length, 0),
              T = `\nendstream\nendobj\nstartxref\n${w}\n%%EOF\n`,
              M = new Uint8Array(e.length + O + F + T.length);
            M.set(e);
            let E = e.length;
            for (const e of y) {
              writeString(e, E, M);
              E += e.length;
            }
            for (const [e, t, a] of x) {
              E = writeInt(e, v[0], E, M);
              E = writeInt(t, v[1], E, M);
              E = writeInt(a, v[2], E, M);
            }
            writeString(T, E, M);
            return M;
          };
          t.writeDict = writeDict;
          t.writeObject = writeObject;
          var r = a(2),
            n = a(4),
            i = a(3),
            s = a(64),
            o = a(5),
            c = a(65);
          function writeObject(e, t, a, r) {
            a.push(`${e.num} ${e.gen} obj\n`);
            t instanceof n.Dict
              ? writeDict(t, a, r)
              : t instanceof o.BaseStream && writeStream(t, a, r);
            a.push("\nendobj\n");
          }
          function writeDict(e, t, a) {
            t.push("<<");
            for (const r of e.getKeys()) {
              t.push(` /${(0, i.escapePDFName)(r)} `);
              writeValue(e.getRaw(r), t, a);
            }
            t.push(">>");
          }
          function writeStream(e, t, a) {
            let r = e.getString();
            null !== a && (r = a.encryptString(r));
            e.dict.set("Length", r.length);
            writeDict(e.dict, t, a);
            t.push(" stream\n", r, "\nendstream");
          }
          function writeValue(e, t, a) {
            if (e instanceof n.Name) t.push(`/${(0, i.escapePDFName)(e.name)}`);
            else if (e instanceof n.Ref) t.push(`${e.num} ${e.gen} R`);
            else if (Array.isArray(e))
              !(function writeArray(e, t, a) {
                t.push("[");
                let r = !0;
                for (const n of e) {
                  r ? (r = !1) : t.push(" ");
                  writeValue(n, t, a);
                }
                t.push("]");
              })(e, t, a);
            else if ("string" == typeof e) {
              null !== a && (e = a.encryptString(e));
              t.push(`(${(0, i.escapeString)(e)})`);
            } else
              "number" == typeof e
                ? t.push((0, i.numberToString)(e))
                : "boolean" == typeof e
                ? t.push(e.toString())
                : e instanceof n.Dict
                ? writeDict(e, t, a)
                : e instanceof o.BaseStream
                ? writeStream(e, t, a)
                : null === e
                ? t.push("null")
                : (0, r.warn)(
                    `Unhandled value in writer: ${typeof e}, please file a bug.`
                  );
          }
          function writeInt(e, t, a, r) {
            for (let n = t + a - 1; n > a - 1; n--) {
              r[n] = 255 & e;
              e >>= 8;
            }
            return a + t;
          }
          function writeString(e, t, a) {
            for (let r = 0, n = e.length; r < n; r++)
              a[t + r] = 255 & e.charCodeAt(r);
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XMLParserErrorCode =
            t.XMLParserBase =
            t.SimpleXMLParser =
            t.SimpleDOMNode =
              void 0;
          var r = a(3);
          const n = {
            NoError: 0,
            EndOfDocument: -1,
            UnterminatedCdat: -2,
            UnterminatedXmlDeclaration: -3,
            UnterminatedDoctypeDeclaration: -4,
            UnterminatedComment: -5,
            MalformedElement: -6,
            OutOfMemory: -7,
            UnterminatedAttributeValue: -8,
            UnterminatedElement: -9,
            ElementNeverBegun: -10,
          };
          t.XMLParserErrorCode = n;
          function isWhitespace(e, t) {
            const a = e[t];
            return " " === a || "\n" === a || "\r" === a || "\t" === a;
          }
          class XMLParserBase {
            _resolveEntities(e) {
              return e.replace(/&([^;]+);/g, (e, t) => {
                if ("#x" === t.substring(0, 2))
                  return String.fromCodePoint(parseInt(t.substring(2), 16));
                if ("#" === t.substring(0, 1))
                  return String.fromCodePoint(parseInt(t.substring(1), 10));
                switch (t) {
                  case "lt":
                    return "<";
                  case "gt":
                    return ">";
                  case "amp":
                    return "&";
                  case "quot":
                    return '"';
                  case "apos":
                    return "'";
                }
                return this.onResolveEntity(t);
              });
            }
            _parseContent(e, t) {
              const a = [];
              let r = t;
              function skipWs() {
                for (; r < e.length && isWhitespace(e, r); ) ++r;
              }
              for (
                ;
                r < e.length &&
                !isWhitespace(e, r) &&
                ">" !== e[r] &&
                "/" !== e[r];

              )
                ++r;
              const n = e.substring(t, r);
              skipWs();
              for (
                ;
                r < e.length && ">" !== e[r] && "/" !== e[r] && "?" !== e[r];

              ) {
                skipWs();
                let t = "",
                  n = "";
                for (; r < e.length && !isWhitespace(e, r) && "=" !== e[r]; ) {
                  t += e[r];
                  ++r;
                }
                skipWs();
                if ("=" !== e[r]) return null;
                ++r;
                skipWs();
                const i = e[r];
                if ('"' !== i && "'" !== i) return null;
                const s = e.indexOf(i, ++r);
                if (s < 0) return null;
                n = e.substring(r, s);
                a.push({ name: t, value: this._resolveEntities(n) });
                r = s + 1;
                skipWs();
              }
              return { name: n, attributes: a, parsed: r - t };
            }
            _parseProcessingInstruction(e, t) {
              let a = t;
              for (
                ;
                a < e.length &&
                !isWhitespace(e, a) &&
                ">" !== e[a] &&
                "?" !== e[a] &&
                "/" !== e[a];

              )
                ++a;
              const r = e.substring(t, a);
              !(function skipWs() {
                for (; a < e.length && isWhitespace(e, a); ) ++a;
              })();
              const n = a;
              for (; a < e.length && ("?" !== e[a] || ">" !== e[a + 1]); ) ++a;
              return { name: r, value: e.substring(n, a), parsed: a - t };
            }
            parseXml(e) {
              let t = 0;
              for (; t < e.length; ) {
                let a = t;
                if ("<" === e[t]) {
                  ++a;
                  let t;
                  switch (e[a]) {
                    case "/":
                      ++a;
                      t = e.indexOf(">", a);
                      if (t < 0) {
                        this.onError(n.UnterminatedElement);
                        return;
                      }
                      this.onEndElement(e.substring(a, t));
                      a = t + 1;
                      break;
                    case "?":
                      ++a;
                      const r = this._parseProcessingInstruction(e, a);
                      if (
                        "?>" !== e.substring(a + r.parsed, a + r.parsed + 2)
                      ) {
                        this.onError(n.UnterminatedXmlDeclaration);
                        return;
                      }
                      this.onPi(r.name, r.value);
                      a += r.parsed + 2;
                      break;
                    case "!":
                      if ("--" === e.substring(a + 1, a + 3)) {
                        t = e.indexOf("--\x3e", a + 3);
                        if (t < 0) {
                          this.onError(n.UnterminatedComment);
                          return;
                        }
                        this.onComment(e.substring(a + 3, t));
                        a = t + 3;
                      } else if ("[CDATA[" === e.substring(a + 1, a + 8)) {
                        t = e.indexOf("]]>", a + 8);
                        if (t < 0) {
                          this.onError(n.UnterminatedCdat);
                          return;
                        }
                        this.onCdata(e.substring(a + 8, t));
                        a = t + 3;
                      } else {
                        if ("DOCTYPE" !== e.substring(a + 1, a + 8)) {
                          this.onError(n.MalformedElement);
                          return;
                        }
                        {
                          const r = e.indexOf("[", a + 8);
                          let i = !1;
                          t = e.indexOf(">", a + 8);
                          if (t < 0) {
                            this.onError(n.UnterminatedDoctypeDeclaration);
                            return;
                          }
                          if (r > 0 && t > r) {
                            t = e.indexOf("]>", a + 8);
                            if (t < 0) {
                              this.onError(n.UnterminatedDoctypeDeclaration);
                              return;
                            }
                            i = !0;
                          }
                          const s = e.substring(a + 8, t + (i ? 1 : 0));
                          this.onDoctype(s);
                          a = t + (i ? 2 : 1);
                        }
                      }
                      break;
                    default:
                      const i = this._parseContent(e, a);
                      if (null === i) {
                        this.onError(n.MalformedElement);
                        return;
                      }
                      let s = !1;
                      if ("/>" === e.substring(a + i.parsed, a + i.parsed + 2))
                        s = !0;
                      else if (
                        ">" !== e.substring(a + i.parsed, a + i.parsed + 1)
                      ) {
                        this.onError(n.UnterminatedElement);
                        return;
                      }
                      this.onBeginElement(i.name, i.attributes, s);
                      a += i.parsed + (s ? 2 : 1);
                  }
                } else {
                  for (; a < e.length && "<" !== e[a]; ) a++;
                  const r = e.substring(t, a);
                  this.onText(this._resolveEntities(r));
                }
                t = a;
              }
            }
            onResolveEntity(e) {
              return `&${e};`;
            }
            onPi(e, t) {}
            onComment(e) {}
            onCdata(e) {}
            onDoctype(e) {}
            onText(e) {}
            onBeginElement(e, t, a) {}
            onEndElement(e) {}
            onError(e) {}
          }
          t.XMLParserBase = XMLParserBase;
          class SimpleDOMNode {
            constructor(e, t) {
              this.nodeName = e;
              this.nodeValue = t;
              Object.defineProperty(this, "parentNode", {
                value: null,
                writable: !0,
              });
            }
            get firstChild() {
              return this.childNodes && this.childNodes[0];
            }
            get nextSibling() {
              const e = this.parentNode.childNodes;
              if (!e) return;
              const t = e.indexOf(this);
              return -1 !== t ? e[t + 1] : void 0;
            }
            get textContent() {
              return this.childNodes
                ? this.childNodes
                    .map(function (e) {
                      return e.textContent;
                    })
                    .join("")
                : this.nodeValue || "";
            }
            get children() {
              return this.childNodes || [];
            }
            hasChildNodes() {
              return this.childNodes && this.childNodes.length > 0;
            }
            searchNode(e, t) {
              if (t >= e.length) return this;
              const a = e[t],
                r = [];
              let n = this;
              for (;;) {
                if (a.name === n.nodeName) {
                  if (0 !== a.pos) {
                    if (0 === r.length) return null;
                    {
                      const [i] = r.pop();
                      let s = 0;
                      for (const r of i.childNodes)
                        if (a.name === r.nodeName) {
                          if (s === a.pos) return r.searchNode(e, t + 1);
                          s++;
                        }
                      return n.searchNode(e, t + 1);
                    }
                  }
                  {
                    const a = n.searchNode(e, t + 1);
                    if (null !== a) return a;
                  }
                }
                if (n.childNodes && 0 !== n.childNodes.length) {
                  r.push([n, 0]);
                  n = n.childNodes[0];
                } else {
                  if (0 === r.length) return null;
                  for (; 0 !== r.length; ) {
                    const [e, t] = r.pop(),
                      a = t + 1;
                    if (a < e.childNodes.length) {
                      r.push([e, a]);
                      n = e.childNodes[a];
                      break;
                    }
                  }
                  if (0 === r.length) return null;
                }
              }
            }
            dump(e) {
              if ("#text" !== this.nodeName) {
                e.push(`<${this.nodeName}`);
                if (this.attributes)
                  for (const t of this.attributes)
                    e.push(` ${t.name}="${(0, r.encodeToXmlString)(t.value)}"`);
                if (this.hasChildNodes()) {
                  e.push(">");
                  for (const t of this.childNodes) t.dump(e);
                  e.push(`</${this.nodeName}>`);
                } else
                  this.nodeValue
                    ? e.push(
                        `>${(0, r.encodeToXmlString)(this.nodeValue)}</${
                          this.nodeName
                        }>`
                      )
                    : e.push("/>");
              } else e.push((0, r.encodeToXmlString)(this.nodeValue));
            }
          }
          t.SimpleDOMNode = SimpleDOMNode;
          t.SimpleXMLParser = class SimpleXMLParser extends XMLParserBase {
            constructor({ hasAttributes: e = !1, lowerCaseName: t = !1 }) {
              super();
              this._currentFragment = null;
              this._stack = null;
              this._errorCode = n.NoError;
              this._hasAttributes = e;
              this._lowerCaseName = t;
            }
            parseFromString(e) {
              this._currentFragment = [];
              this._stack = [];
              this._errorCode = n.NoError;
              this.parseXml(e);
              if (this._errorCode !== n.NoError) return;
              const [t] = this._currentFragment;
              return t ? { documentElement: t } : void 0;
            }
            onText(e) {
              if (
                (function isWhitespaceString(e) {
                  for (let t = 0, a = e.length; t < a; t++)
                    if (!isWhitespace(e, t)) return !1;
                  return !0;
                })(e)
              )
                return;
              const t = new SimpleDOMNode("#text", e);
              this._currentFragment.push(t);
            }
            onCdata(e) {
              const t = new SimpleDOMNode("#text", e);
              this._currentFragment.push(t);
            }
            onBeginElement(e, t, a) {
              this._lowerCaseName && (e = e.toLowerCase());
              const r = new SimpleDOMNode(e);
              r.childNodes = [];
              this._hasAttributes && (r.attributes = t);
              this._currentFragment.push(r);
              if (!a) {
                this._stack.push(this._currentFragment);
                this._currentFragment = r.childNodes;
              }
            }
            onEndElement(e) {
              this._currentFragment = this._stack.pop() || [];
              const t = this._currentFragment.at(-1);
              if (!t) return null;
              for (const e of t.childNodes) e.parentNode = t;
              return t;
            }
            onError(e) {
              this._errorCode = e;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.calculateSHA256 =
            t.calculateMD5 =
            t.PDF20 =
            t.PDF17 =
            t.CipherTransformFactory =
            t.ARCFourCipher =
            t.AES256Cipher =
            t.AES128Cipher =
              void 0;
          t.calculateSHA384 = calculateSHA384;
          t.calculateSHA512 = void 0;
          var r = a(2),
            n = a(4),
            i = a(66);
          class ARCFourCipher {
            constructor(e) {
              this.a = 0;
              this.b = 0;
              const t = new Uint8Array(256),
                a = e.length;
              for (let e = 0; e < 256; ++e) t[e] = e;
              for (let r = 0, n = 0; r < 256; ++r) {
                const i = t[r];
                n = (n + i + e[r % a]) & 255;
                t[r] = t[n];
                t[n] = i;
              }
              this.s = t;
            }
            encryptBlock(e) {
              let t = this.a,
                a = this.b;
              const r = this.s,
                n = e.length,
                i = new Uint8Array(n);
              for (let s = 0; s < n; ++s) {
                t = (t + 1) & 255;
                const n = r[t];
                a = (a + n) & 255;
                const o = r[a];
                r[t] = o;
                r[a] = n;
                i[s] = e[s] ^ r[(n + o) & 255];
              }
              this.a = t;
              this.b = a;
              return i;
            }
            decryptBlock(e) {
              return this.encryptBlock(e);
            }
            encrypt(e) {
              return this.encryptBlock(e);
            }
          }
          t.ARCFourCipher = ARCFourCipher;
          const s = (function calculateMD5Closure() {
            const e = new Uint8Array([
                7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 7, 12, 17, 22, 5,
                9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20, 5, 9, 14, 20, 4, 11, 16,
                23, 4, 11, 16, 23, 4, 11, 16, 23, 4, 11, 16, 23, 6, 10, 15, 21,
                6, 10, 15, 21, 6, 10, 15, 21, 6, 10, 15, 21,
              ]),
              t = new Int32Array([
                -680876936, -389564586, 606105819, -1044525330, -176418897,
                1200080426, -1473231341, -45705983, 1770035416, -1958414417,
                -42063, -1990404162, 1804603682, -40341101, -1502002290,
                1236535329, -165796510, -1069501632, 643717713, -373897302,
                -701558691, 38016083, -660478335, -405537848, 568446438,
                -1019803690, -187363961, 1163531501, -1444681467, -51403784,
                1735328473, -1926607734, -378558, -2022574463, 1839030562,
                -35309556, -1530992060, 1272893353, -155497632, -1094730640,
                681279174, -358537222, -722521979, 76029189, -640364487,
                -421815835, 530742520, -995338651, -198630844, 1126891415,
                -1416354905, -57434055, 1700485571, -1894986606, -1051523,
                -2054922799, 1873313359, -30611744, -1560198380, 1309151649,
                -145523070, -1120210379, 718787259, -343485551,
              ]);
            return function hash(a, r, n) {
              let i = 1732584193,
                s = -271733879,
                o = -1732584194,
                c = 271733878;
              const l = (n + 72) & -64,
                h = new Uint8Array(l);
              let u, d;
              for (u = 0; u < n; ++u) h[u] = a[r++];
              h[u++] = 128;
              const f = l - 8;
              for (; u < f; ) h[u++] = 0;
              h[u++] = (n << 3) & 255;
              h[u++] = (n >> 5) & 255;
              h[u++] = (n >> 13) & 255;
              h[u++] = (n >> 21) & 255;
              h[u++] = (n >>> 29) & 255;
              h[u++] = 0;
              h[u++] = 0;
              h[u++] = 0;
              const g = new Int32Array(16);
              for (u = 0; u < l; ) {
                for (d = 0; d < 16; ++d, u += 4)
                  g[d] =
                    h[u] |
                    (h[u + 1] << 8) |
                    (h[u + 2] << 16) |
                    (h[u + 3] << 24);
                let a,
                  r,
                  n = i,
                  l = s,
                  f = o,
                  p = c;
                for (d = 0; d < 64; ++d) {
                  if (d < 16) {
                    a = (l & f) | (~l & p);
                    r = d;
                  } else if (d < 32) {
                    a = (p & l) | (~p & f);
                    r = (5 * d + 1) & 15;
                  } else if (d < 48) {
                    a = l ^ f ^ p;
                    r = (3 * d + 5) & 15;
                  } else {
                    a = f ^ (l | ~p);
                    r = (7 * d) & 15;
                  }
                  const i = p,
                    s = (n + a + t[d] + g[r]) | 0,
                    o = e[d];
                  p = f;
                  f = l;
                  l = (l + ((s << o) | (s >>> (32 - o)))) | 0;
                  n = i;
                }
                i = (i + n) | 0;
                s = (s + l) | 0;
                o = (o + f) | 0;
                c = (c + p) | 0;
              }
              return new Uint8Array([
                255 & i,
                (i >> 8) & 255,
                (i >> 16) & 255,
                (i >>> 24) & 255,
                255 & s,
                (s >> 8) & 255,
                (s >> 16) & 255,
                (s >>> 24) & 255,
                255 & o,
                (o >> 8) & 255,
                (o >> 16) & 255,
                (o >>> 24) & 255,
                255 & c,
                (c >> 8) & 255,
                (c >> 16) & 255,
                (c >>> 24) & 255,
              ]);
            };
          })();
          t.calculateMD5 = s;
          class Word64 {
            constructor(e, t) {
              this.high = 0 | e;
              this.low = 0 | t;
            }
            and(e) {
              this.high &= e.high;
              this.low &= e.low;
            }
            xor(e) {
              this.high ^= e.high;
              this.low ^= e.low;
            }
            or(e) {
              this.high |= e.high;
              this.low |= e.low;
            }
            shiftRight(e) {
              if (e >= 32) {
                this.low = (this.high >>> (e - 32)) | 0;
                this.high = 0;
              } else {
                this.low = (this.low >>> e) | (this.high << (32 - e));
                this.high = (this.high >>> e) | 0;
              }
            }
            shiftLeft(e) {
              if (e >= 32) {
                this.high = this.low << (e - 32);
                this.low = 0;
              } else {
                this.high = (this.high << e) | (this.low >>> (32 - e));
                this.low <<= e;
              }
            }
            rotateRight(e) {
              let t, a;
              if (32 & e) {
                a = this.low;
                t = this.high;
              } else {
                t = this.low;
                a = this.high;
              }
              e &= 31;
              this.low = (t >>> e) | (a << (32 - e));
              this.high = (a >>> e) | (t << (32 - e));
            }
            not() {
              this.high = ~this.high;
              this.low = ~this.low;
            }
            add(e) {
              const t = (this.low >>> 0) + (e.low >>> 0);
              let a = (this.high >>> 0) + (e.high >>> 0);
              t > 4294967295 && (a += 1);
              this.low = 0 | t;
              this.high = 0 | a;
            }
            copyTo(e, t) {
              e[t] = (this.high >>> 24) & 255;
              e[t + 1] = (this.high >> 16) & 255;
              e[t + 2] = (this.high >> 8) & 255;
              e[t + 3] = 255 & this.high;
              e[t + 4] = (this.low >>> 24) & 255;
              e[t + 5] = (this.low >> 16) & 255;
              e[t + 6] = (this.low >> 8) & 255;
              e[t + 7] = 255 & this.low;
            }
            assign(e) {
              this.high = e.high;
              this.low = e.low;
            }
          }
          const o = (function calculateSHA256Closure() {
            function rotr(e, t) {
              return (e >>> t) | (e << (32 - t));
            }
            function ch(e, t, a) {
              return (e & t) ^ (~e & a);
            }
            function maj(e, t, a) {
              return (e & t) ^ (e & a) ^ (t & a);
            }
            function sigma(e) {
              return rotr(e, 2) ^ rotr(e, 13) ^ rotr(e, 22);
            }
            function sigmaPrime(e) {
              return rotr(e, 6) ^ rotr(e, 11) ^ rotr(e, 25);
            }
            function littleSigma(e) {
              return rotr(e, 7) ^ rotr(e, 18) ^ (e >>> 3);
            }
            const e = [
              1116352408, 1899447441, 3049323471, 3921009573, 961987163,
              1508970993, 2453635748, 2870763221, 3624381080, 310598401,
              607225278, 1426881987, 1925078388, 2162078206, 2614888103,
              3248222580, 3835390401, 4022224774, 264347078, 604807628,
              770255983, 1249150122, 1555081692, 1996064986, 2554220882,
              2821834349, 2952996808, 3210313671, 3336571891, 3584528711,
              113926993, 338241895, 666307205, 773529912, 1294757372,
              1396182291, 1695183700, 1986661051, 2177026350, 2456956037,
              2730485921, 2820302411, 3259730800, 3345764771, 3516065817,
              3600352804, 4094571909, 275423344, 430227734, 506948616,
              659060556, 883997877, 958139571, 1322822218, 1537002063,
              1747873779, 1955562222, 2024104815, 2227730452, 2361852424,
              2428436474, 2756734187, 3204031479, 3329325298,
            ];
            return function hash(t, a, r) {
              let n = 1779033703,
                i = 3144134277,
                s = 1013904242,
                o = 2773480762,
                c = 1359893119,
                l = 2600822924,
                h = 528734635,
                u = 1541459225;
              const d = 64 * Math.ceil((r + 9) / 64),
                f = new Uint8Array(d);
              let g, p;
              for (g = 0; g < r; ++g) f[g] = t[a++];
              f[g++] = 128;
              const m = d - 8;
              for (; g < m; ) f[g++] = 0;
              f[g++] = 0;
              f[g++] = 0;
              f[g++] = 0;
              f[g++] = (r >>> 29) & 255;
              f[g++] = (r >> 21) & 255;
              f[g++] = (r >> 13) & 255;
              f[g++] = (r >> 5) & 255;
              f[g++] = (r << 3) & 255;
              const b = new Uint32Array(64);
              for (g = 0; g < d; ) {
                for (p = 0; p < 16; ++p) {
                  b[p] =
                    (f[g] << 24) |
                    (f[g + 1] << 16) |
                    (f[g + 2] << 8) |
                    f[g + 3];
                  g += 4;
                }
                for (p = 16; p < 64; ++p)
                  b[p] =
                    ((rotr((y = b[p - 2]), 17) ^ rotr(y, 19) ^ (y >>> 10)) +
                      b[p - 7] +
                      littleSigma(b[p - 15]) +
                      b[p - 16]) |
                    0;
                let t,
                  a,
                  r = n,
                  d = i,
                  m = s,
                  w = o,
                  S = c,
                  x = l,
                  C = h,
                  k = u;
                for (p = 0; p < 64; ++p) {
                  t = k + sigmaPrime(S) + ch(S, x, C) + e[p] + b[p];
                  a = sigma(r) + maj(r, d, m);
                  k = C;
                  C = x;
                  x = S;
                  S = (w + t) | 0;
                  w = m;
                  m = d;
                  d = r;
                  r = (t + a) | 0;
                }
                n = (n + r) | 0;
                i = (i + d) | 0;
                s = (s + m) | 0;
                o = (o + w) | 0;
                c = (c + S) | 0;
                l = (l + x) | 0;
                h = (h + C) | 0;
                u = (u + k) | 0;
              }
              var y;
              return new Uint8Array([
                (n >> 24) & 255,
                (n >> 16) & 255,
                (n >> 8) & 255,
                255 & n,
                (i >> 24) & 255,
                (i >> 16) & 255,
                (i >> 8) & 255,
                255 & i,
                (s >> 24) & 255,
                (s >> 16) & 255,
                (s >> 8) & 255,
                255 & s,
                (o >> 24) & 255,
                (o >> 16) & 255,
                (o >> 8) & 255,
                255 & o,
                (c >> 24) & 255,
                (c >> 16) & 255,
                (c >> 8) & 255,
                255 & c,
                (l >> 24) & 255,
                (l >> 16) & 255,
                (l >> 8) & 255,
                255 & l,
                (h >> 24) & 255,
                (h >> 16) & 255,
                (h >> 8) & 255,
                255 & h,
                (u >> 24) & 255,
                (u >> 16) & 255,
                (u >> 8) & 255,
                255 & u,
              ]);
            };
          })();
          t.calculateSHA256 = o;
          const c = (function calculateSHA512Closure() {
            function ch(e, t, a, r, n) {
              e.assign(t);
              e.and(a);
              n.assign(t);
              n.not();
              n.and(r);
              e.xor(n);
            }
            function maj(e, t, a, r, n) {
              e.assign(t);
              e.and(a);
              n.assign(t);
              n.and(r);
              e.xor(n);
              n.assign(a);
              n.and(r);
              e.xor(n);
            }
            function sigma(e, t, a) {
              e.assign(t);
              e.rotateRight(28);
              a.assign(t);
              a.rotateRight(34);
              e.xor(a);
              a.assign(t);
              a.rotateRight(39);
              e.xor(a);
            }
            function sigmaPrime(e, t, a) {
              e.assign(t);
              e.rotateRight(14);
              a.assign(t);
              a.rotateRight(18);
              e.xor(a);
              a.assign(t);
              a.rotateRight(41);
              e.xor(a);
            }
            function littleSigma(e, t, a) {
              e.assign(t);
              e.rotateRight(1);
              a.assign(t);
              a.rotateRight(8);
              e.xor(a);
              a.assign(t);
              a.shiftRight(7);
              e.xor(a);
            }
            function littleSigmaPrime(e, t, a) {
              e.assign(t);
              e.rotateRight(19);
              a.assign(t);
              a.rotateRight(61);
              e.xor(a);
              a.assign(t);
              a.shiftRight(6);
              e.xor(a);
            }
            const e = [
              new Word64(1116352408, 3609767458),
              new Word64(1899447441, 602891725),
              new Word64(3049323471, 3964484399),
              new Word64(3921009573, 2173295548),
              new Word64(961987163, 4081628472),
              new Word64(1508970993, 3053834265),
              new Word64(2453635748, 2937671579),
              new Word64(2870763221, 3664609560),
              new Word64(3624381080, 2734883394),
              new Word64(310598401, 1164996542),
              new Word64(607225278, 1323610764),
              new Word64(1426881987, 3590304994),
              new Word64(1925078388, 4068182383),
              new Word64(2162078206, 991336113),
              new Word64(2614888103, 633803317),
              new Word64(3248222580, 3479774868),
              new Word64(3835390401, 2666613458),
              new Word64(4022224774, 944711139),
              new Word64(264347078, 2341262773),
              new Word64(604807628, 2007800933),
              new Word64(770255983, 1495990901),
              new Word64(1249150122, 1856431235),
              new Word64(1555081692, 3175218132),
              new Word64(1996064986, 2198950837),
              new Word64(2554220882, 3999719339),
              new Word64(2821834349, 766784016),
              new Word64(2952996808, 2566594879),
              new Word64(3210313671, 3203337956),
              new Word64(3336571891, 1034457026),
              new Word64(3584528711, 2466948901),
              new Word64(113926993, 3758326383),
              new Word64(338241895, 168717936),
              new Word64(666307205, 1188179964),
              new Word64(773529912, 1546045734),
              new Word64(1294757372, 1522805485),
              new Word64(1396182291, 2643833823),
              new Word64(1695183700, 2343527390),
              new Word64(1986661051, 1014477480),
              new Word64(2177026350, 1206759142),
              new Word64(2456956037, 344077627),
              new Word64(2730485921, 1290863460),
              new Word64(2820302411, 3158454273),
              new Word64(3259730800, 3505952657),
              new Word64(3345764771, 106217008),
              new Word64(3516065817, 3606008344),
              new Word64(3600352804, 1432725776),
              new Word64(4094571909, 1467031594),
              new Word64(275423344, 851169720),
              new Word64(430227734, 3100823752),
              new Word64(506948616, 1363258195),
              new Word64(659060556, 3750685593),
              new Word64(883997877, 3785050280),
              new Word64(958139571, 3318307427),
              new Word64(1322822218, 3812723403),
              new Word64(1537002063, 2003034995),
              new Word64(1747873779, 3602036899),
              new Word64(1955562222, 1575990012),
              new Word64(2024104815, 1125592928),
              new Word64(2227730452, 2716904306),
              new Word64(2361852424, 442776044),
              new Word64(2428436474, 593698344),
              new Word64(2756734187, 3733110249),
              new Word64(3204031479, 2999351573),
              new Word64(3329325298, 3815920427),
              new Word64(3391569614, 3928383900),
              new Word64(3515267271, 566280711),
              new Word64(3940187606, 3454069534),
              new Word64(4118630271, 4000239992),
              new Word64(116418474, 1914138554),
              new Word64(174292421, 2731055270),
              new Word64(289380356, 3203993006),
              new Word64(460393269, 320620315),
              new Word64(685471733, 587496836),
              new Word64(852142971, 1086792851),
              new Word64(1017036298, 365543100),
              new Word64(1126000580, 2618297676),
              new Word64(1288033470, 3409855158),
              new Word64(1501505948, 4234509866),
              new Word64(1607167915, 987167468),
              new Word64(1816402316, 1246189591),
            ];
            return function hash(t, a, r, n = !1) {
              let i, s, o, c, l, h, u, d;
              if (n) {
                i = new Word64(3418070365, 3238371032);
                s = new Word64(1654270250, 914150663);
                o = new Word64(2438529370, 812702999);
                c = new Word64(355462360, 4144912697);
                l = new Word64(1731405415, 4290775857);
                h = new Word64(2394180231, 1750603025);
                u = new Word64(3675008525, 1694076839);
                d = new Word64(1203062813, 3204075428);
              } else {
                i = new Word64(1779033703, 4089235720);
                s = new Word64(3144134277, 2227873595);
                o = new Word64(1013904242, 4271175723);
                c = new Word64(2773480762, 1595750129);
                l = new Word64(1359893119, 2917565137);
                h = new Word64(2600822924, 725511199);
                u = new Word64(528734635, 4215389547);
                d = new Word64(1541459225, 327033209);
              }
              const f = 128 * Math.ceil((r + 17) / 128),
                g = new Uint8Array(f);
              let p, m;
              for (p = 0; p < r; ++p) g[p] = t[a++];
              g[p++] = 128;
              const b = f - 16;
              for (; p < b; ) g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = 0;
              g[p++] = (r >>> 29) & 255;
              g[p++] = (r >> 21) & 255;
              g[p++] = (r >> 13) & 255;
              g[p++] = (r >> 5) & 255;
              g[p++] = (r << 3) & 255;
              const y = new Array(80);
              for (p = 0; p < 80; p++) y[p] = new Word64(0, 0);
              let w = new Word64(0, 0),
                S = new Word64(0, 0),
                x = new Word64(0, 0),
                C = new Word64(0, 0),
                k = new Word64(0, 0),
                v = new Word64(0, 0),
                F = new Word64(0, 0),
                O = new Word64(0, 0);
              const T = new Word64(0, 0),
                M = new Word64(0, 0),
                E = new Word64(0, 0),
                D = new Word64(0, 0);
              let N, R;
              for (p = 0; p < f; ) {
                for (m = 0; m < 16; ++m) {
                  y[m].high =
                    (g[p] << 24) |
                    (g[p + 1] << 16) |
                    (g[p + 2] << 8) |
                    g[p + 3];
                  y[m].low =
                    (g[p + 4] << 24) |
                    (g[p + 5] << 16) |
                    (g[p + 6] << 8) |
                    g[p + 7];
                  p += 8;
                }
                for (m = 16; m < 80; ++m) {
                  N = y[m];
                  littleSigmaPrime(N, y[m - 2], D);
                  N.add(y[m - 7]);
                  littleSigma(E, y[m - 15], D);
                  N.add(E);
                  N.add(y[m - 16]);
                }
                w.assign(i);
                S.assign(s);
                x.assign(o);
                C.assign(c);
                k.assign(l);
                v.assign(h);
                F.assign(u);
                O.assign(d);
                for (m = 0; m < 80; ++m) {
                  T.assign(O);
                  sigmaPrime(E, k, D);
                  T.add(E);
                  ch(E, k, v, F, D);
                  T.add(E);
                  T.add(e[m]);
                  T.add(y[m]);
                  sigma(M, w, D);
                  maj(E, w, S, x, D);
                  M.add(E);
                  N = O;
                  O = F;
                  F = v;
                  v = k;
                  C.add(T);
                  k = C;
                  C = x;
                  x = S;
                  S = w;
                  N.assign(T);
                  N.add(M);
                  w = N;
                }
                i.add(w);
                s.add(S);
                o.add(x);
                c.add(C);
                l.add(k);
                h.add(v);
                u.add(F);
                d.add(O);
              }
              if (n) {
                R = new Uint8Array(48);
                i.copyTo(R, 0);
                s.copyTo(R, 8);
                o.copyTo(R, 16);
                c.copyTo(R, 24);
                l.copyTo(R, 32);
                h.copyTo(R, 40);
              } else {
                R = new Uint8Array(64);
                i.copyTo(R, 0);
                s.copyTo(R, 8);
                o.copyTo(R, 16);
                c.copyTo(R, 24);
                l.copyTo(R, 32);
                h.copyTo(R, 40);
                u.copyTo(R, 48);
                d.copyTo(R, 56);
              }
              return R;
            };
          })();
          t.calculateSHA512 = c;
          function calculateSHA384(e, t, a) {
            return c(e, t, a, !0);
          }
          class NullCipher {
            decryptBlock(e) {
              return e;
            }
            encrypt(e) {
              return e;
            }
          }
          class AESBaseCipher {
            constructor() {
              this.constructor === AESBaseCipher &&
                (0, r.unreachable)("Cannot initialize AESBaseCipher.");
              this._s = new Uint8Array([
                99, 124, 119, 123, 242, 107, 111, 197, 48, 1, 103, 43, 254, 215,
                171, 118, 202, 130, 201, 125, 250, 89, 71, 240, 173, 212, 162,
                175, 156, 164, 114, 192, 183, 253, 147, 38, 54, 63, 247, 204,
                52, 165, 229, 241, 113, 216, 49, 21, 4, 199, 35, 195, 24, 150,
                5, 154, 7, 18, 128, 226, 235, 39, 178, 117, 9, 131, 44, 26, 27,
                110, 90, 160, 82, 59, 214, 179, 41, 227, 47, 132, 83, 209, 0,
                237, 32, 252, 177, 91, 106, 203, 190, 57, 74, 76, 88, 207, 208,
                239, 170, 251, 67, 77, 51, 133, 69, 249, 2, 127, 80, 60, 159,
                168, 81, 163, 64, 143, 146, 157, 56, 245, 188, 182, 218, 33, 16,
                255, 243, 210, 205, 12, 19, 236, 95, 151, 68, 23, 196, 167, 126,
                61, 100, 93, 25, 115, 96, 129, 79, 220, 34, 42, 144, 136, 70,
                238, 184, 20, 222, 94, 11, 219, 224, 50, 58, 10, 73, 6, 36, 92,
                194, 211, 172, 98, 145, 149, 228, 121, 231, 200, 55, 109, 141,
                213, 78, 169, 108, 86, 244, 234, 101, 122, 174, 8, 186, 120, 37,
                46, 28, 166, 180, 198, 232, 221, 116, 31, 75, 189, 139, 138,
                112, 62, 181, 102, 72, 3, 246, 14, 97, 53, 87, 185, 134, 193,
                29, 158, 225, 248, 152, 17, 105, 217, 142, 148, 155, 30, 135,
                233, 206, 85, 40, 223, 140, 161, 137, 13, 191, 230, 66, 104, 65,
                153, 45, 15, 176, 84, 187, 22,
              ]);
              this._inv_s = new Uint8Array([
                82, 9, 106, 213, 48, 54, 165, 56, 191, 64, 163, 158, 129, 243,
                215, 251, 124, 227, 57, 130, 155, 47, 255, 135, 52, 142, 67, 68,
                196, 222, 233, 203, 84, 123, 148, 50, 166, 194, 35, 61, 238, 76,
                149, 11, 66, 250, 195, 78, 8, 46, 161, 102, 40, 217, 36, 178,
                118, 91, 162, 73, 109, 139, 209, 37, 114, 248, 246, 100, 134,
                104, 152, 22, 212, 164, 92, 204, 93, 101, 182, 146, 108, 112,
                72, 80, 253, 237, 185, 218, 94, 21, 70, 87, 167, 141, 157, 132,
                144, 216, 171, 0, 140, 188, 211, 10, 247, 228, 88, 5, 184, 179,
                69, 6, 208, 44, 30, 143, 202, 63, 15, 2, 193, 175, 189, 3, 1,
                19, 138, 107, 58, 145, 17, 65, 79, 103, 220, 234, 151, 242, 207,
                206, 240, 180, 230, 115, 150, 172, 116, 34, 231, 173, 53, 133,
                226, 249, 55, 232, 28, 117, 223, 110, 71, 241, 26, 113, 29, 41,
                197, 137, 111, 183, 98, 14, 170, 24, 190, 27, 252, 86, 62, 75,
                198, 210, 121, 32, 154, 219, 192, 254, 120, 205, 90, 244, 31,
                221, 168, 51, 136, 7, 199, 49, 177, 18, 16, 89, 39, 128, 236,
                95, 96, 81, 127, 169, 25, 181, 74, 13, 45, 229, 122, 159, 147,
                201, 156, 239, 160, 224, 59, 77, 174, 42, 245, 176, 200, 235,
                187, 60, 131, 83, 153, 97, 23, 43, 4, 126, 186, 119, 214, 38,
                225, 105, 20, 99, 85, 33, 12, 125,
              ]);
              this._mix = new Uint32Array([
                0, 235474187, 470948374, 303765277, 941896748, 908933415,
                607530554, 708780849, 1883793496, 2118214995, 1817866830,
                1649639237, 1215061108, 1181045119, 1417561698, 1517767529,
                3767586992, 4003061179, 4236429990, 4069246893, 3635733660,
                3602770327, 3299278474, 3400528769, 2430122216, 2664543715,
                2362090238, 2193862645, 2835123396, 2801107407, 3035535058,
                3135740889, 3678124923, 3576870512, 3341394285, 3374361702,
                3810496343, 3977675356, 4279080257, 4043610186, 2876494627,
                2776292904, 3076639029, 3110650942, 2472011535, 2640243204,
                2403728665, 2169303058, 1001089995, 899835584, 666464733,
                699432150, 59727847, 226906860, 530400753, 294930682,
                1273168787, 1172967064, 1475418501, 1509430414, 1942435775,
                2110667444, 1876241833, 1641816226, 2910219766, 2743034109,
                2976151520, 3211623147, 2505202138, 2606453969, 2302690252,
                2269728455, 3711829422, 3543599269, 3240894392, 3475313331,
                3843699074, 3943906441, 4178062228, 4144047775, 1306967366,
                1139781709, 1374988112, 1610459739, 1975683434, 2076935265,
                1775276924, 1742315127, 1034867998, 866637845, 566021896,
                800440835, 92987698, 193195065, 429456164, 395441711,
                1984812685, 2017778566, 1784663195, 1683407248, 1315562145,
                1080094634, 1383856311, 1551037884, 101039829, 135050206,
                437757123, 337553864, 1042385657, 807962610, 573804783,
                742039012, 2531067453, 2564033334, 2328828971, 2227573024,
                2935566865, 2700099354, 3001755655, 3168937228, 3868552805,
                3902563182, 4203181171, 4102977912, 3736164937, 3501741890,
                3265478751, 3433712980, 1106041591, 1340463100, 1576976609,
                1408749034, 2043211483, 2009195472, 1708848333, 1809054150,
                832877231, 1068351396, 766945465, 599762354, 159417987,
                126454664, 361929877, 463180190, 2709260871, 2943682380,
                3178106961, 3009879386, 2572697195, 2538681184, 2236228733,
                2336434550, 3509871135, 3745345300, 3441850377, 3274667266,
                3910161971, 3877198648, 4110568485, 4211818798, 2597806476,
                2497604743, 2261089178, 2295101073, 2733856160, 2902087851,
                3202437046, 2968011453, 3936291284, 3835036895, 4136440770,
                4169408201, 3535486456, 3702665459, 3467192302, 3231722213,
                2051518780, 1951317047, 1716890410, 1750902305, 1113818384,
                1282050075, 1584504582, 1350078989, 168810852, 67556463,
                371049330, 404016761, 841739592, 1008918595, 775550814,
                540080725, 3969562369, 3801332234, 4035489047, 4269907996,
                3569255213, 3669462566, 3366754619, 3332740144, 2631065433,
                2463879762, 2160117071, 2395588676, 2767645557, 2868897406,
                3102011747, 3069049960, 202008497, 33778362, 270040487,
                504459436, 875451293, 975658646, 675039627, 641025152,
                2084704233, 1917518562, 1615861247, 1851332852, 1147550661,
                1248802510, 1484005843, 1451044056, 933301370, 967311729,
                733156972, 632953703, 260388950, 25965917, 328671808, 496906059,
                1206477858, 1239443753, 1543208500, 1441952575, 2144161806,
                1908694277, 1675577880, 1842759443, 3610369226, 3644379585,
                3408119516, 3307916247, 4011190502, 3776767469, 4077384432,
                4245618683, 2809771154, 2842737049, 3144396420, 3043140495,
                2673705150, 2438237621, 2203032232, 2370213795,
              ]);
              this._mixCol = new Uint8Array(256);
              for (let e = 0; e < 256; e++)
                this._mixCol[e] = e < 128 ? e << 1 : (e << 1) ^ 27;
              this.buffer = new Uint8Array(16);
              this.bufferPosition = 0;
            }
            _expandKey(e) {
              (0, r.unreachable)("Cannot call `_expandKey` on the base class");
            }
            _decrypt(e, t) {
              let a, r, n;
              const i = new Uint8Array(16);
              i.set(e);
              for (let e = 0, a = this._keySize; e < 16; ++e, ++a) i[e] ^= t[a];
              for (let e = this._cyclesOfRepetition - 1; e >= 1; --e) {
                a = i[13];
                i[13] = i[9];
                i[9] = i[5];
                i[5] = i[1];
                i[1] = a;
                a = i[14];
                r = i[10];
                i[14] = i[6];
                i[10] = i[2];
                i[6] = a;
                i[2] = r;
                a = i[15];
                r = i[11];
                n = i[7];
                i[15] = i[3];
                i[11] = a;
                i[7] = r;
                i[3] = n;
                for (let e = 0; e < 16; ++e) i[e] = this._inv_s[i[e]];
                for (let a = 0, r = 16 * e; a < 16; ++a, ++r) i[a] ^= t[r];
                for (let e = 0; e < 16; e += 4) {
                  const t = this._mix[i[e]],
                    r = this._mix[i[e + 1]],
                    n = this._mix[i[e + 2]],
                    s = this._mix[i[e + 3]];
                  a =
                    t ^
                    (r >>> 8) ^
                    (r << 24) ^
                    (n >>> 16) ^
                    (n << 16) ^
                    (s >>> 24) ^
                    (s << 8);
                  i[e] = (a >>> 24) & 255;
                  i[e + 1] = (a >> 16) & 255;
                  i[e + 2] = (a >> 8) & 255;
                  i[e + 3] = 255 & a;
                }
              }
              a = i[13];
              i[13] = i[9];
              i[9] = i[5];
              i[5] = i[1];
              i[1] = a;
              a = i[14];
              r = i[10];
              i[14] = i[6];
              i[10] = i[2];
              i[6] = a;
              i[2] = r;
              a = i[15];
              r = i[11];
              n = i[7];
              i[15] = i[3];
              i[11] = a;
              i[7] = r;
              i[3] = n;
              for (let e = 0; e < 16; ++e) {
                i[e] = this._inv_s[i[e]];
                i[e] ^= t[e];
              }
              return i;
            }
            _encrypt(e, t) {
              const a = this._s;
              let r, n, i;
              const s = new Uint8Array(16);
              s.set(e);
              for (let e = 0; e < 16; ++e) s[e] ^= t[e];
              for (let e = 1; e < this._cyclesOfRepetition; e++) {
                for (let e = 0; e < 16; ++e) s[e] = a[s[e]];
                i = s[1];
                s[1] = s[5];
                s[5] = s[9];
                s[9] = s[13];
                s[13] = i;
                i = s[2];
                n = s[6];
                s[2] = s[10];
                s[6] = s[14];
                s[10] = i;
                s[14] = n;
                i = s[3];
                n = s[7];
                r = s[11];
                s[3] = s[15];
                s[7] = i;
                s[11] = n;
                s[15] = r;
                for (let e = 0; e < 16; e += 4) {
                  const t = s[e + 0],
                    a = s[e + 1],
                    n = s[e + 2],
                    i = s[e + 3];
                  r = t ^ a ^ n ^ i;
                  s[e + 0] ^= r ^ this._mixCol[t ^ a];
                  s[e + 1] ^= r ^ this._mixCol[a ^ n];
                  s[e + 2] ^= r ^ this._mixCol[n ^ i];
                  s[e + 3] ^= r ^ this._mixCol[i ^ t];
                }
                for (let a = 0, r = 16 * e; a < 16; ++a, ++r) s[a] ^= t[r];
              }
              for (let e = 0; e < 16; ++e) s[e] = a[s[e]];
              i = s[1];
              s[1] = s[5];
              s[5] = s[9];
              s[9] = s[13];
              s[13] = i;
              i = s[2];
              n = s[6];
              s[2] = s[10];
              s[6] = s[14];
              s[10] = i;
              s[14] = n;
              i = s[3];
              n = s[7];
              r = s[11];
              s[3] = s[15];
              s[7] = i;
              s[11] = n;
              s[15] = r;
              for (let e = 0, a = this._keySize; e < 16; ++e, ++a) s[e] ^= t[a];
              return s;
            }
            _decryptBlock2(e, t) {
              const a = e.length;
              let r = this.buffer,
                n = this.bufferPosition;
              const i = [];
              let s = this.iv;
              for (let t = 0; t < a; ++t) {
                r[n] = e[t];
                ++n;
                if (n < 16) continue;
                const a = this._decrypt(r, this._key);
                for (let e = 0; e < 16; ++e) a[e] ^= s[e];
                s = r;
                i.push(a);
                r = new Uint8Array(16);
                n = 0;
              }
              this.buffer = r;
              this.bufferLength = n;
              this.iv = s;
              if (0 === i.length) return new Uint8Array(0);
              let o = 16 * i.length;
              if (t) {
                const e = i.at(-1);
                let t = e[15];
                if (t <= 16) {
                  for (let a = 15, r = 16 - t; a >= r; --a)
                    if (e[a] !== t) {
                      t = 0;
                      break;
                    }
                  o -= t;
                  i[i.length - 1] = e.subarray(0, 16 - t);
                }
              }
              const c = new Uint8Array(o);
              for (let e = 0, t = 0, a = i.length; e < a; ++e, t += 16)
                c.set(i[e], t);
              return c;
            }
            decryptBlock(e, t, a = null) {
              const r = e.length,
                n = this.buffer;
              let i = this.bufferPosition;
              if (a) this.iv = a;
              else {
                for (let t = 0; i < 16 && t < r; ++t, ++i) n[i] = e[t];
                if (i < 16) {
                  this.bufferLength = i;
                  return new Uint8Array(0);
                }
                this.iv = n;
                e = e.subarray(16);
              }
              this.buffer = new Uint8Array(16);
              this.bufferLength = 0;
              this.decryptBlock = this._decryptBlock2;
              return this.decryptBlock(e, t);
            }
            encrypt(e, t) {
              const a = e.length;
              let r = this.buffer,
                n = this.bufferPosition;
              const i = [];
              t || (t = new Uint8Array(16));
              for (let s = 0; s < a; ++s) {
                r[n] = e[s];
                ++n;
                if (n < 16) continue;
                for (let e = 0; e < 16; ++e) r[e] ^= t[e];
                const a = this._encrypt(r, this._key);
                t = a;
                i.push(a);
                r = new Uint8Array(16);
                n = 0;
              }
              this.buffer = r;
              this.bufferLength = n;
              this.iv = t;
              if (0 === i.length) return new Uint8Array(0);
              const s = 16 * i.length,
                o = new Uint8Array(s);
              for (let e = 0, t = 0, a = i.length; e < a; ++e, t += 16)
                o.set(i[e], t);
              return o;
            }
          }
          class AES128Cipher extends AESBaseCipher {
            constructor(e) {
              super();
              this._cyclesOfRepetition = 10;
              this._keySize = 160;
              this._rcon = new Uint8Array([
                141, 1, 2, 4, 8, 16, 32, 64, 128, 27, 54, 108, 216, 171, 77,
                154, 47, 94, 188, 99, 198, 151, 53, 106, 212, 179, 125, 250,
                239, 197, 145, 57, 114, 228, 211, 189, 97, 194, 159, 37, 74,
                148, 51, 102, 204, 131, 29, 58, 116, 232, 203, 141, 1, 2, 4, 8,
                16, 32, 64, 128, 27, 54, 108, 216, 171, 77, 154, 47, 94, 188,
                99, 198, 151, 53, 106, 212, 179, 125, 250, 239, 197, 145, 57,
                114, 228, 211, 189, 97, 194, 159, 37, 74, 148, 51, 102, 204,
                131, 29, 58, 116, 232, 203, 141, 1, 2, 4, 8, 16, 32, 64, 128,
                27, 54, 108, 216, 171, 77, 154, 47, 94, 188, 99, 198, 151, 53,
                106, 212, 179, 125, 250, 239, 197, 145, 57, 114, 228, 211, 189,
                97, 194, 159, 37, 74, 148, 51, 102, 204, 131, 29, 58, 116, 232,
                203, 141, 1, 2, 4, 8, 16, 32, 64, 128, 27, 54, 108, 216, 171,
                77, 154, 47, 94, 188, 99, 198, 151, 53, 106, 212, 179, 125, 250,
                239, 197, 145, 57, 114, 228, 211, 189, 97, 194, 159, 37, 74,
                148, 51, 102, 204, 131, 29, 58, 116, 232, 203, 141, 1, 2, 4, 8,
                16, 32, 64, 128, 27, 54, 108, 216, 171, 77, 154, 47, 94, 188,
                99, 198, 151, 53, 106, 212, 179, 125, 250, 239, 197, 145, 57,
                114, 228, 211, 189, 97, 194, 159, 37, 74, 148, 51, 102, 204,
                131, 29, 58, 116, 232, 203, 141,
              ]);
              this._key = this._expandKey(e);
            }
            _expandKey(e) {
              const t = this._s,
                a = this._rcon,
                r = new Uint8Array(176);
              r.set(e);
              for (let e = 16, n = 1; e < 176; ++n) {
                let i = r[e - 3],
                  s = r[e - 2],
                  o = r[e - 1],
                  c = r[e - 4];
                i = t[i];
                s = t[s];
                o = t[o];
                c = t[c];
                i ^= a[n];
                for (let t = 0; t < 4; ++t) {
                  r[e] = i ^= r[e - 16];
                  e++;
                  r[e] = s ^= r[e - 16];
                  e++;
                  r[e] = o ^= r[e - 16];
                  e++;
                  r[e] = c ^= r[e - 16];
                  e++;
                }
              }
              return r;
            }
          }
          t.AES128Cipher = AES128Cipher;
          class AES256Cipher extends AESBaseCipher {
            constructor(e) {
              super();
              this._cyclesOfRepetition = 14;
              this._keySize = 224;
              this._key = this._expandKey(e);
            }
            _expandKey(e) {
              const t = this._s,
                a = new Uint8Array(240);
              a.set(e);
              let r,
                n,
                i,
                s,
                o = 1;
              for (let e = 32, c = 1; e < 240; ++c) {
                if (e % 32 == 16) {
                  r = t[r];
                  n = t[n];
                  i = t[i];
                  s = t[s];
                } else if (e % 32 == 0) {
                  r = a[e - 3];
                  n = a[e - 2];
                  i = a[e - 1];
                  s = a[e - 4];
                  r = t[r];
                  n = t[n];
                  i = t[i];
                  s = t[s];
                  r ^= o;
                  (o <<= 1) >= 256 && (o = 255 & (27 ^ o));
                }
                for (let t = 0; t < 4; ++t) {
                  a[e] = r ^= a[e - 32];
                  e++;
                  a[e] = n ^= a[e - 32];
                  e++;
                  a[e] = i ^= a[e - 32];
                  e++;
                  a[e] = s ^= a[e - 32];
                  e++;
                }
              }
              return a;
            }
          }
          t.AES256Cipher = AES256Cipher;
          class PDF17 {
            checkOwnerPassword(e, t, a, n) {
              const i = new Uint8Array(e.length + 56);
              i.set(e, 0);
              i.set(t, e.length);
              i.set(a, e.length + t.length);
              const s = o(i, 0, i.length);
              return (0, r.isArrayEqual)(s, n);
            }
            checkUserPassword(e, t, a) {
              const n = new Uint8Array(e.length + 8);
              n.set(e, 0);
              n.set(t, e.length);
              const i = o(n, 0, n.length);
              return (0, r.isArrayEqual)(i, a);
            }
            getOwnerKey(e, t, a, r) {
              const n = new Uint8Array(e.length + 56);
              n.set(e, 0);
              n.set(t, e.length);
              n.set(a, e.length + t.length);
              const i = o(n, 0, n.length);
              return new AES256Cipher(i).decryptBlock(
                r,
                !1,
                new Uint8Array(16)
              );
            }
            getUserKey(e, t, a) {
              const r = new Uint8Array(e.length + 8);
              r.set(e, 0);
              r.set(t, e.length);
              const n = o(r, 0, r.length);
              return new AES256Cipher(n).decryptBlock(
                a,
                !1,
                new Uint8Array(16)
              );
            }
          }
          t.PDF17 = PDF17;
          const l = (function PDF20Closure() {
            function calculatePDF20Hash(e, t, a) {
              let r = o(t, 0, t.length).subarray(0, 32),
                n = [0],
                i = 0;
              for (; i < 64 || n.at(-1) > i - 32; ) {
                const t = e.length + r.length + a.length,
                  s = new Uint8Array(t);
                let l = 0;
                s.set(e, l);
                l += e.length;
                s.set(r, l);
                l += r.length;
                s.set(a, l);
                const h = new Uint8Array(64 * t);
                for (let e = 0, a = 0; e < 64; e++, a += t) h.set(s, a);
                n = new AES128Cipher(r.subarray(0, 16)).encrypt(
                  h,
                  r.subarray(16, 32)
                );
                const u = n.slice(0, 16).reduce((e, t) => e + t, 0) % 3;
                0 === u
                  ? (r = o(n, 0, n.length))
                  : 1 === u
                  ? (r = calculateSHA384(n, 0, n.length))
                  : 2 === u && (r = c(n, 0, n.length));
                i++;
              }
              return r.subarray(0, 32);
            }
            return class PDF20 {
              hash(e, t, a) {
                return calculatePDF20Hash(e, t, a);
              }
              checkOwnerPassword(e, t, a, n) {
                const i = new Uint8Array(e.length + 56);
                i.set(e, 0);
                i.set(t, e.length);
                i.set(a, e.length + t.length);
                const s = calculatePDF20Hash(e, i, a);
                return (0, r.isArrayEqual)(s, n);
              }
              checkUserPassword(e, t, a) {
                const n = new Uint8Array(e.length + 8);
                n.set(e, 0);
                n.set(t, e.length);
                const i = calculatePDF20Hash(e, n, []);
                return (0, r.isArrayEqual)(i, a);
              }
              getOwnerKey(e, t, a, r) {
                const n = new Uint8Array(e.length + 56);
                n.set(e, 0);
                n.set(t, e.length);
                n.set(a, e.length + t.length);
                const i = calculatePDF20Hash(e, n, a);
                return new AES256Cipher(i).decryptBlock(
                  r,
                  !1,
                  new Uint8Array(16)
                );
              }
              getUserKey(e, t, a) {
                const r = new Uint8Array(e.length + 8);
                r.set(e, 0);
                r.set(t, e.length);
                const n = calculatePDF20Hash(e, r, []);
                return new AES256Cipher(n).decryptBlock(
                  a,
                  !1,
                  new Uint8Array(16)
                );
              }
            };
          })();
          t.PDF20 = l;
          class CipherTransform {
            constructor(e, t) {
              this.StringCipherConstructor = e;
              this.StreamCipherConstructor = t;
            }
            createStream(e, t) {
              const a = new this.StreamCipherConstructor();
              return new i.DecryptStream(
                e,
                t,
                function cipherTransformDecryptStream(e, t) {
                  return a.decryptBlock(e, t);
                }
              );
            }
            decryptString(e) {
              const t = new this.StringCipherConstructor();
              let a = (0, r.stringToBytes)(e);
              a = t.decryptBlock(a, !0);
              return (0, r.bytesToString)(a);
            }
            encryptString(e) {
              const t = new this.StringCipherConstructor();
              if (t instanceof AESBaseCipher) {
                const a = 16 - (e.length % 16);
                e += String.fromCharCode(a).repeat(a);
                const n = new Uint8Array(16);
                if ("undefined" != typeof crypto) crypto.getRandomValues(n);
                else
                  for (let e = 0; e < 16; e++)
                    n[e] = Math.floor(256 * Math.random());
                let i = (0, r.stringToBytes)(e);
                i = t.encrypt(i, n);
                const s = new Uint8Array(16 + i.length);
                s.set(n);
                s.set(i, 16);
                return (0, r.bytesToString)(s);
              }
              let a = (0, r.stringToBytes)(e);
              a = t.encrypt(a);
              return (0, r.bytesToString)(a);
            }
          }
          const h = (function CipherTransformFactoryClosure() {
            const e = new Uint8Array([
              40, 191, 78, 94, 78, 117, 138, 65, 100, 0, 78, 86, 255, 250, 1, 8,
              46, 46, 0, 182, 208, 104, 62, 128, 47, 12, 169, 254, 100, 83, 105,
              122,
            ]);
            function prepareKeyData(t, a, r, n, i, o, c, l) {
              const h = 40 + r.length + t.length,
                u = new Uint8Array(h);
              let d,
                f,
                g = 0;
              if (a) {
                f = Math.min(32, a.length);
                for (; g < f; ++g) u[g] = a[g];
              }
              d = 0;
              for (; g < 32; ) u[g++] = e[d++];
              for (d = 0, f = r.length; d < f; ++d) u[g++] = r[d];
              u[g++] = 255 & i;
              u[g++] = (i >> 8) & 255;
              u[g++] = (i >> 16) & 255;
              u[g++] = (i >>> 24) & 255;
              for (d = 0, f = t.length; d < f; ++d) u[g++] = t[d];
              if (o >= 4 && !l) {
                u[g++] = 255;
                u[g++] = 255;
                u[g++] = 255;
                u[g++] = 255;
              }
              let p = s(u, 0, g);
              const m = c >> 3;
              if (o >= 3) for (d = 0; d < 50; ++d) p = s(p, 0, m);
              const b = p.subarray(0, m);
              let y, w;
              if (o >= 3) {
                for (g = 0; g < 32; ++g) u[g] = e[g];
                for (d = 0, f = t.length; d < f; ++d) u[g++] = t[d];
                y = new ARCFourCipher(b);
                w = y.encryptBlock(s(u, 0, g));
                f = b.length;
                const a = new Uint8Array(f);
                for (d = 1; d <= 19; ++d) {
                  for (let e = 0; e < f; ++e) a[e] = b[e] ^ d;
                  y = new ARCFourCipher(a);
                  w = y.encryptBlock(w);
                }
                for (d = 0, f = w.length; d < f; ++d)
                  if (n[d] !== w[d]) return null;
              } else {
                y = new ARCFourCipher(b);
                w = y.encryptBlock(e);
                for (d = 0, f = w.length; d < f; ++d)
                  if (n[d] !== w[d]) return null;
              }
              return b;
            }
            const t = n.Name.get("Identity");
            function buildObjectKey(e, t, a, r = !1) {
              const n = new Uint8Array(a.length + 9),
                i = a.length;
              let o;
              for (o = 0; o < i; ++o) n[o] = a[o];
              n[o++] = 255 & e;
              n[o++] = (e >> 8) & 255;
              n[o++] = (e >> 16) & 255;
              n[o++] = 255 & t;
              n[o++] = (t >> 8) & 255;
              if (r) {
                n[o++] = 115;
                n[o++] = 65;
                n[o++] = 108;
                n[o++] = 84;
              }
              return s(n, 0, o).subarray(0, Math.min(a.length + 5, 16));
            }
            function buildCipherConstructor(e, t, a, i, s) {
              if (!(t instanceof n.Name))
                throw new r.FormatError("Invalid crypt filter name.");
              const o = e.get(t.name);
              let c;
              null != o && (c = o.get("CFM"));
              if (!c || "None" === c.name)
                return function cipherTransformFactoryBuildCipherConstructorNone() {
                  return new NullCipher();
                };
              if ("V2" === c.name)
                return function cipherTransformFactoryBuildCipherConstructorV2() {
                  return new ARCFourCipher(buildObjectKey(a, i, s, !1));
                };
              if ("AESV2" === c.name)
                return function cipherTransformFactoryBuildCipherConstructorAESV2() {
                  return new AES128Cipher(buildObjectKey(a, i, s, !0));
                };
              if ("AESV3" === c.name)
                return function cipherTransformFactoryBuildCipherConstructorAESV3() {
                  return new AES256Cipher(s);
                };
              throw new r.FormatError("Unknown crypto method");
            }
            return class CipherTransformFactory {
              constructor(a, i, o) {
                const c = a.get("Filter");
                if (!(0, n.isName)(c, "Standard"))
                  throw new r.FormatError("unknown encryption method");
                this.filterName = c.name;
                this.dict = a;
                const h = a.get("V");
                if (
                  !Number.isInteger(h) ||
                  (1 !== h && 2 !== h && 4 !== h && 5 !== h)
                )
                  throw new r.FormatError("unsupported encryption algorithm");
                this.algorithm = h;
                let u = a.get("Length");
                if (!u)
                  if (h <= 3) u = 40;
                  else {
                    const e = a.get("CF"),
                      t = a.get("StmF");
                    if (e instanceof n.Dict && t instanceof n.Name) {
                      e.suppressEncryption = !0;
                      const a = e.get(t.name);
                      u = (a && a.get("Length")) || 128;
                      u < 40 && (u <<= 3);
                    }
                  }
                if (!Number.isInteger(u) || u < 40 || u % 8 != 0)
                  throw new r.FormatError("invalid key length");
                const d = (0, r.stringToBytes)(a.get("O")).subarray(0, 32),
                  f = (0, r.stringToBytes)(a.get("U")).subarray(0, 32),
                  g = a.get("P"),
                  p = a.get("R"),
                  m = (4 === h || 5 === h) && !1 !== a.get("EncryptMetadata");
                this.encryptMetadata = m;
                const b = (0, r.stringToBytes)(i);
                let y, w;
                if (o) {
                  if (6 === p)
                    try {
                      o = (0, r.utf8StringToString)(o);
                    } catch (e) {
                      (0, r.warn)(
                        "CipherTransformFactory: Unable to convert UTF8 encoded password."
                      );
                    }
                  y = (0, r.stringToBytes)(o);
                }
                if (5 !== h) w = prepareKeyData(b, y, d, f, g, p, u, m);
                else {
                  const e = (0, r.stringToBytes)(a.get("O")).subarray(32, 40),
                    t = (0, r.stringToBytes)(a.get("O")).subarray(40, 48),
                    n = (0, r.stringToBytes)(a.get("U")).subarray(0, 48),
                    i = (0, r.stringToBytes)(a.get("U")).subarray(32, 40),
                    s = (0, r.stringToBytes)(a.get("U")).subarray(40, 48),
                    o = (0, r.stringToBytes)(a.get("OE")),
                    c = (0, r.stringToBytes)(a.get("UE"));
                  (0, r.stringToBytes)(a.get("Perms"));
                  w = (function createEncryptionKey20(
                    e,
                    t,
                    a,
                    r,
                    n,
                    i,
                    s,
                    o,
                    c,
                    h,
                    u,
                    d
                  ) {
                    if (t) {
                      const e = Math.min(127, t.length);
                      t = t.subarray(0, e);
                    } else t = [];
                    let f;
                    f = 6 === e ? new l() : new PDF17();
                    return f.checkUserPassword(t, o, s)
                      ? f.getUserKey(t, c, u)
                      : t.length && f.checkOwnerPassword(t, r, i, a)
                      ? f.getOwnerKey(t, n, i, h)
                      : null;
                  })(p, y, d, e, t, n, f, i, s, o, c);
                }
                if (!w && !o)
                  throw new r.PasswordException(
                    "No password given",
                    r.PasswordResponses.NEED_PASSWORD
                  );
                if (!w && o) {
                  const t = (function decodeUserPassword(t, a, r, n) {
                    const i = new Uint8Array(32);
                    let o = 0;
                    const c = Math.min(32, t.length);
                    for (; o < c; ++o) i[o] = t[o];
                    let l = 0;
                    for (; o < 32; ) i[o++] = e[l++];
                    let h = s(i, 0, o);
                    const u = n >> 3;
                    if (r >= 3) for (l = 0; l < 50; ++l) h = s(h, 0, h.length);
                    let d, f;
                    if (r >= 3) {
                      f = a;
                      const e = new Uint8Array(u);
                      for (l = 19; l >= 0; l--) {
                        for (let t = 0; t < u; ++t) e[t] = h[t] ^ l;
                        d = new ARCFourCipher(e);
                        f = d.encryptBlock(f);
                      }
                    } else {
                      d = new ARCFourCipher(h.subarray(0, u));
                      f = d.encryptBlock(a);
                    }
                    return f;
                  })(y, d, p, u);
                  w = prepareKeyData(b, t, d, f, g, p, u, m);
                }
                if (!w)
                  throw new r.PasswordException(
                    "Incorrect Password",
                    r.PasswordResponses.INCORRECT_PASSWORD
                  );
                this.encryptionKey = w;
                if (h >= 4) {
                  const e = a.get("CF");
                  e instanceof n.Dict && (e.suppressEncryption = !0);
                  this.cf = e;
                  this.stmf = a.get("StmF") || t;
                  this.strf = a.get("StrF") || t;
                  this.eff = a.get("EFF") || this.stmf;
                }
              }
              createCipherTransform(e, t) {
                if (4 === this.algorithm || 5 === this.algorithm)
                  return new CipherTransform(
                    buildCipherConstructor(
                      this.cf,
                      this.strf,
                      e,
                      t,
                      this.encryptionKey
                    ),
                    buildCipherConstructor(
                      this.cf,
                      this.stmf,
                      e,
                      t,
                      this.encryptionKey
                    )
                  );
                const a = buildObjectKey(e, t, this.encryptionKey, !1),
                  r = function buildCipherCipherConstructor() {
                    return new ARCFourCipher(a);
                  };
                return new CipherTransform(r, r);
              }
            };
          })();
          t.CipherTransformFactory = h;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.DecryptStream = void 0;
          var r = a(17);
          class DecryptStream extends r.DecodeStream {
            constructor(e, t, a) {
              super(t);
              this.str = e;
              this.dict = e.dict;
              this.decrypt = a;
              this.nextChunk = null;
              this.initialized = !1;
            }
            readBlock() {
              let e;
              if (this.initialized) e = this.nextChunk;
              else {
                e = this.str.getBytes(512);
                this.initialized = !0;
              }
              if (!e || 0 === e.length) {
                this.eof = !0;
                return;
              }
              this.nextChunk = this.str.getBytes(512);
              const t = this.nextChunk && this.nextChunk.length > 0;
              e = (0, this.decrypt)(e, !t);
              const a = this.bufferLength,
                r = a + e.length;
              this.ensureBuffer(r).set(e, a);
              this.bufferLength = r;
            }
          }
          t.DecryptStream = DecryptStream;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Catalog = void 0;
          var r = a(3),
            n = a(2),
            i = a(4),
            s = a(68),
            o = a(5),
            c = a(69),
            l = a(12),
            h = a(70),
            u = a(57),
            d = a(71),
            f = a(72);
          function fetchDestination(e) {
            e instanceof i.Dict && (e = e.get("D"));
            return Array.isArray(e) ? e : null;
          }
          class Catalog {
            constructor(e, t) {
              this.pdfManager = e;
              this.xref = t;
              this._catDict = t.getCatalogObj();
              if (!(this._catDict instanceof i.Dict))
                throw new n.FormatError("Catalog object is not a dictionary.");
              this.toplevelPagesDict;
              this._actualNumPages = null;
              this.fontCache = new i.RefSetCache();
              this.builtInCMapCache = new Map();
              this.standardFontDataCache = new Map();
              this.globalImageCache = new u.GlobalImageCache();
              this.pageKidsCountCache = new i.RefSetCache();
              this.pageIndexCache = new i.RefSetCache();
              this.nonBlendModesSet = new i.RefSet();
            }
            get version() {
              const e = this._catDict.get("Version");
              if (e instanceof i.Name) {
                if (r.PDF_VERSION_REGEXP.test(e.name))
                  return (0, n.shadow)(this, "version", e.name);
                (0, n.warn)(`Invalid PDF catalog version: ${e.name}`);
              }
              return (0, n.shadow)(this, "version", null);
            }
            get lang() {
              const e = this._catDict.get("Lang");
              return (0, n.shadow)(
                this,
                "lang",
                "string" == typeof e ? (0, n.stringToPDFString)(e) : null
              );
            }
            get needsRendering() {
              const e = this._catDict.get("NeedsRendering");
              return (0, n.shadow)(
                this,
                "needsRendering",
                "boolean" == typeof e && e
              );
            }
            get collection() {
              let e = null;
              try {
                const t = this._catDict.get("Collection");
                t instanceof i.Dict && t.size > 0 && (e = t);
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.info)(
                  "Cannot fetch Collection entry; assuming no collection is present."
                );
              }
              return (0, n.shadow)(this, "collection", e);
            }
            get acroForm() {
              let e = null;
              try {
                const t = this._catDict.get("AcroForm");
                t instanceof i.Dict && t.size > 0 && (e = t);
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.info)(
                  "Cannot fetch AcroForm entry; assuming no forms are present."
                );
              }
              return (0, n.shadow)(this, "acroForm", e);
            }
            get acroFormRef() {
              const e = this._catDict.getRaw("AcroForm");
              return (0, n.shadow)(
                this,
                "acroFormRef",
                e instanceof i.Ref ? e : null
              );
            }
            get metadata() {
              const e = this._catDict.getRaw("Metadata");
              if (!(e instanceof i.Ref))
                return (0, n.shadow)(this, "metadata", null);
              let t = null;
              try {
                const a = !(
                    this.xref.encrypt && this.xref.encrypt.encryptMetadata
                  ),
                  r = this.xref.fetch(e, a);
                if (r instanceof o.BaseStream && r.dict instanceof i.Dict) {
                  const e = r.dict.get("Type"),
                    a = r.dict.get("Subtype");
                  if ((0, i.isName)(e, "Metadata") && (0, i.isName)(a, "XML")) {
                    const e = (0, n.stringToUTF8String)(r.getString());
                    e && (t = new d.MetadataParser(e).serializable);
                  }
                }
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.info)(`Skipping invalid Metadata: "${e}".`);
              }
              return (0, n.shadow)(this, "metadata", t);
            }
            get markInfo() {
              let e = null;
              try {
                e = this._readMarkInfo();
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.warn)("Unable to read mark info.");
              }
              return (0, n.shadow)(this, "markInfo", e);
            }
            _readMarkInfo() {
              const e = this._catDict.get("MarkInfo");
              if (!(e instanceof i.Dict)) return null;
              const t = { Marked: !1, UserProperties: !1, Suspects: !1 };
              for (const a in t) {
                const r = e.get(a);
                "boolean" == typeof r && (t[a] = r);
              }
              return t;
            }
            get structTreeRoot() {
              let e = null;
              try {
                e = this._readStructTreeRoot();
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.warn)("Unable read to structTreeRoot info.");
              }
              return (0, n.shadow)(this, "structTreeRoot", e);
            }
            _readStructTreeRoot() {
              const e = this._catDict.get("StructTreeRoot");
              if (!(e instanceof i.Dict)) return null;
              const t = new f.StructTreeRoot(e);
              t.init();
              return t;
            }
            get toplevelPagesDict() {
              const e = this._catDict.get("Pages");
              if (!(e instanceof i.Dict))
                throw new n.FormatError("Invalid top-level pages dictionary.");
              return (0, n.shadow)(this, "toplevelPagesDict", e);
            }
            get documentOutline() {
              let e = null;
              try {
                e = this._readDocumentOutline();
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.warn)("Unable to read document outline.");
              }
              return (0, n.shadow)(this, "documentOutline", e);
            }
            _readDocumentOutline() {
              let e = this._catDict.get("Outlines");
              if (!(e instanceof i.Dict)) return null;
              e = e.getRaw("First");
              if (!(e instanceof i.Ref)) return null;
              const t = { items: [] },
                a = [{ obj: e, parent: t }],
                r = new i.RefSet();
              r.put(e);
              const s = this.xref,
                o = new Uint8ClampedArray(3);
              for (; a.length > 0; ) {
                const t = a.shift(),
                  c = s.fetchIfRef(t.obj);
                if (null === c) continue;
                if (!c.has("Title"))
                  throw new n.FormatError("Invalid outline item encountered.");
                const h = { url: null, dest: null, action: null };
                Catalog.parseDestDictionary({
                  destDict: c,
                  resultObj: h,
                  docBaseUrl: this.pdfManager.docBaseUrl,
                  docAttachments: this.attachments,
                });
                const u = c.get("Title"),
                  d = c.get("F") || 0,
                  f = c.getArray("C"),
                  g = c.get("Count");
                let p = o;
                !Array.isArray(f) ||
                  3 !== f.length ||
                  (0 === f[0] && 0 === f[1] && 0 === f[2]) ||
                  (p = l.ColorSpace.singletons.rgb.getRgb(f, 0));
                const m = {
                  action: h.action,
                  attachment: h.attachment,
                  dest: h.dest,
                  url: h.url,
                  unsafeUrl: h.unsafeUrl,
                  newWindow: h.newWindow,
                  setOCGState: h.setOCGState,
                  title: (0, n.stringToPDFString)(u),
                  color: p,
                  count: Number.isInteger(g) ? g : void 0,
                  bold: !!(2 & d),
                  italic: !!(1 & d),
                  items: [],
                };
                t.parent.items.push(m);
                e = c.getRaw("First");
                if (e instanceof i.Ref && !r.has(e)) {
                  a.push({ obj: e, parent: m });
                  r.put(e);
                }
                e = c.getRaw("Next");
                if (e instanceof i.Ref && !r.has(e)) {
                  a.push({ obj: e, parent: t.parent });
                  r.put(e);
                }
              }
              return t.items.length > 0 ? t.items : null;
            }
            get permissions() {
              let e = null;
              try {
                e = this._readPermissions();
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.warn)("Unable to read permissions.");
              }
              return (0, n.shadow)(this, "permissions", e);
            }
            _readPermissions() {
              const e = this.xref.trailer.get("Encrypt");
              if (!(e instanceof i.Dict)) return null;
              let t = e.get("P");
              if ("number" != typeof t) return null;
              t += 2 ** 32;
              const a = [];
              for (const e in n.PermissionFlag) {
                const r = n.PermissionFlag[e];
                t & r && a.push(r);
              }
              return a;
            }
            get optionalContentConfig() {
              let e = null;
              try {
                const t = this._catDict.get("OCProperties");
                if (!t)
                  return (0, n.shadow)(this, "optionalContentConfig", null);
                const a = t.get("D");
                if (!a)
                  return (0, n.shadow)(this, "optionalContentConfig", null);
                const r = t.get("OCGs");
                if (!Array.isArray(r))
                  return (0, n.shadow)(this, "optionalContentConfig", null);
                const s = [],
                  o = [];
                for (const e of r) {
                  if (!(e instanceof i.Ref)) continue;
                  o.push(e);
                  const t = this.xref.fetchIfRef(e);
                  s.push({
                    id: e.toString(),
                    name:
                      "string" == typeof t.get("Name")
                        ? (0, n.stringToPDFString)(t.get("Name"))
                        : null,
                    intent:
                      "string" == typeof t.get("Intent")
                        ? (0, n.stringToPDFString)(t.get("Intent"))
                        : null,
                  });
                }
                e = this._readOptionalContentConfig(a, o);
                e.groups = s;
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.warn)(`Unable to read optional content config: ${e}`);
              }
              return (0, n.shadow)(this, "optionalContentConfig", e);
            }
            _readOptionalContentConfig(e, t) {
              function parseOnOff(e) {
                const a = [];
                if (Array.isArray(e))
                  for (const r of e)
                    r instanceof i.Ref && t.includes(r) && a.push(r.toString());
                return a;
              }
              function parseOrder(e, a = 0) {
                if (!Array.isArray(e)) return null;
                const n = [];
                for (const s of e) {
                  if (s instanceof i.Ref && t.includes(s)) {
                    r.put(s);
                    n.push(s.toString());
                    continue;
                  }
                  const e = parseNestedOrder(s, a);
                  e && n.push(e);
                }
                if (a > 0) return n;
                const s = [];
                for (const e of t) r.has(e) || s.push(e.toString());
                s.length && n.push({ name: null, order: s });
                return n;
              }
              function parseNestedOrder(e, t) {
                if (++t > s) {
                  (0, n.warn)("parseNestedOrder - reached MAX_NESTED_LEVELS.");
                  return null;
                }
                const r = a.fetchIfRef(e);
                if (!Array.isArray(r)) return null;
                const i = a.fetchIfRef(r[0]);
                if ("string" != typeof i) return null;
                const o = parseOrder(r.slice(1), t);
                return o && o.length
                  ? { name: (0, n.stringToPDFString)(i), order: o }
                  : null;
              }
              const a = this.xref,
                r = new i.RefSet(),
                s = 10;
              return {
                name:
                  "string" == typeof e.get("Name")
                    ? (0, n.stringToPDFString)(e.get("Name"))
                    : null,
                creator:
                  "string" == typeof e.get("Creator")
                    ? (0, n.stringToPDFString)(e.get("Creator"))
                    : null,
                baseState:
                  e.get("BaseState") instanceof i.Name
                    ? e.get("BaseState").name
                    : null,
                on: parseOnOff(e.get("ON")),
                off: parseOnOff(e.get("OFF")),
                order: parseOrder(e.get("Order")),
                groups: null,
              };
            }
            setActualNumPages(e = null) {
              this._actualNumPages = e;
            }
            get hasActualNumPages() {
              return null !== this._actualNumPages;
            }
            get _pagesCount() {
              const e = this.toplevelPagesDict.get("Count");
              if (!Number.isInteger(e))
                throw new n.FormatError(
                  "Page count in top-level pages dictionary is not an integer."
                );
              return (0, n.shadow)(this, "_pagesCount", e);
            }
            get numPages() {
              return this.hasActualNumPages
                ? this._actualNumPages
                : this._pagesCount;
            }
            get destinations() {
              const e = this._readDests(),
                t = Object.create(null);
              if (e instanceof s.NameTree)
                for (const [a, r] of e.getAll()) {
                  const e = fetchDestination(r);
                  e && (t[(0, n.stringToPDFString)(a)] = e);
                }
              else
                e instanceof i.Dict &&
                  e.forEach(function (e, a) {
                    const r = fetchDestination(a);
                    r && (t[e] = r);
                  });
              return (0, n.shadow)(this, "destinations", t);
            }
            getDestination(e) {
              const t = this._readDests();
              if (t instanceof s.NameTree) {
                const a = fetchDestination(t.get(e));
                if (a) return a;
                const r = this.destinations[e];
                if (r) {
                  (0, n.warn)(
                    `Found "${e}" at an incorrect position in the NameTree.`
                  );
                  return r;
                }
              } else if (t instanceof i.Dict) {
                const a = fetchDestination(t.get(e));
                if (a) return a;
              }
              return null;
            }
            _readDests() {
              const e = this._catDict.get("Names");
              return e && e.has("Dests")
                ? new s.NameTree(e.getRaw("Dests"), this.xref)
                : this._catDict.has("Dests")
                ? this._catDict.get("Dests")
                : void 0;
            }
            get pageLabels() {
              let e = null;
              try {
                e = this._readPageLabels();
              } catch (e) {
                if (e instanceof r.MissingDataException) throw e;
                (0, n.warn)("Unable to read page labels.");
              }
              return (0, n.shadow)(this, "pageLabels", e);
            }
            _readPageLabels() {
              const e = this._catDict.getRaw("PageLabels");
              if (!e) return null;
              const t = new Array(this.numPages);
              let a = null,
                o = "";
              const c = new s.NumberTree(e, this.xref).getAll();
              let l = "",
                h = 1;
              for (let e = 0, s = this.numPages; e < s; e++) {
                const s = c.get(e);
                if (void 0 !== s) {
                  if (!(s instanceof i.Dict))
                    throw new n.FormatError("PageLabel is not a dictionary.");
                  if (
                    s.has("Type") &&
                    !(0, i.isName)(s.get("Type"), "PageLabel")
                  )
                    throw new n.FormatError(
                      "Invalid type in PageLabel dictionary."
                    );
                  if (s.has("S")) {
                    const e = s.get("S");
                    if (!(e instanceof i.Name))
                      throw new n.FormatError(
                        "Invalid style in PageLabel dictionary."
                      );
                    a = e.name;
                  } else a = null;
                  if (s.has("P")) {
                    const e = s.get("P");
                    if ("string" != typeof e)
                      throw new n.FormatError(
                        "Invalid prefix in PageLabel dictionary."
                      );
                    o = (0, n.stringToPDFString)(e);
                  } else o = "";
                  if (s.has("St")) {
                    const e = s.get("St");
                    if (!(Number.isInteger(e) && e >= 1))
                      throw new n.FormatError(
                        "Invalid start in PageLabel dictionary."
                      );
                    h = e;
                  } else h = 1;
                }
                switch (a) {
                  case "D":
                    l = h;
                    break;
                  case "R":
                  case "r":
                    l = (0, r.toRomanNumerals)(h, "r" === a);
                    break;
                  case "A":
                  case "a":
                    const e = 26,
                      t = "a" === a ? 97 : 65,
                      i = h - 1;
                    l = String.fromCharCode(t + (i % e)).repeat(
                      Math.floor(i / e) + 1
                    );
                    break;
                  default:
                    if (a)
                      throw new n.FormatError(
                        `Invalid style "${a}" in PageLabel dictionary.`
                      );
                    l = "";
                }
                t[e] = o + l;
                h++;
              }
              return t;
            }
            get pageLayout() {
              const e = this._catDict.get("PageLayout");
              let t = "";
              if (e instanceof i.Name)
                switch (e.name) {
                  case "SinglePage":
                  case "OneColumn":
                  case "TwoColumnLeft":
                  case "TwoColumnRight":
                  case "TwoPageLeft":
                  case "TwoPageRight":
                    t = e.name;
                }
              return (0, n.shadow)(this, "pageLayout", t);
            }
            get pageMode() {
              const e = this._catDict.get("PageMode");
              let t = "UseNone";
              if (e instanceof i.Name)
                switch (e.name) {
                  case "UseNone":
                  case "UseOutlines":
                  case "UseThumbs":
                  case "FullScreen":
                  case "UseOC":
                  case "UseAttachments":
                    t = e.name;
                }
              return (0, n.shadow)(this, "pageMode", t);
            }
            get viewerPreferences() {
              const e = this._catDict.get("ViewerPreferences");
              if (!(e instanceof i.Dict))
                return (0, n.shadow)(this, "viewerPreferences", null);
              let t = null;
              for (const a of e.getKeys()) {
                const r = e.get(a);
                let s;
                switch (a) {
                  case "HideToolbar":
                  case "HideMenubar":
                  case "HideWindowUI":
                  case "FitWindow":
                  case "CenterWindow":
                  case "DisplayDocTitle":
                  case "PickTrayByPDFSize":
                    "boolean" == typeof r && (s = r);
                    break;
                  case "NonFullScreenPageMode":
                    if (r instanceof i.Name)
                      switch (r.name) {
                        case "UseNone":
                        case "UseOutlines":
                        case "UseThumbs":
                        case "UseOC":
                          s = r.name;
                          break;
                        default:
                          s = "UseNone";
                      }
                    break;
                  case "Direction":
                    if (r instanceof i.Name)
                      switch (r.name) {
                        case "L2R":
                        case "R2L":
                          s = r.name;
                          break;
                        default:
                          s = "L2R";
                      }
                    break;
                  case "ViewArea":
                  case "ViewClip":
                  case "PrintArea":
                  case "PrintClip":
                    if (r instanceof i.Name)
                      switch (r.name) {
                        case "MediaBox":
                        case "CropBox":
                        case "BleedBox":
                        case "TrimBox":
                        case "ArtBox":
                          s = r.name;
                          break;
                        default:
                          s = "CropBox";
                      }
                    break;
                  case "PrintScaling":
                    if (r instanceof i.Name)
                      switch (r.name) {
                        case "None":
                        case "AppDefault":
                          s = r.name;
                          break;
                        default:
                          s = "AppDefault";
                      }
                    break;
                  case "Duplex":
                    if (r instanceof i.Name)
                      switch (r.name) {
                        case "Simplex":
                        case "DuplexFlipShortEdge":
                        case "DuplexFlipLongEdge":
                          s = r.name;
                          break;
                        default:
                          s = "None";
                      }
                    break;
                  case "PrintPageRange":
                    if (Array.isArray(r) && r.length % 2 == 0) {
                      r.every(
                        (e, t, a) =>
                          Number.isInteger(e) &&
                          e > 0 &&
                          (0 === t || e >= a[t - 1]) &&
                          e <= this.numPages
                      ) && (s = r);
                    }
                    break;
                  case "NumCopies":
                    Number.isInteger(r) && r > 0 && (s = r);
                    break;
                  default:
                    (0, n.warn)(
                      `Ignoring non-standard key in ViewerPreferences: ${a}.`
                    );
                    continue;
                }
                if (void 0 !== s) {
                  t || (t = Object.create(null));
                  t[a] = s;
                } else
                  (0, n.warn)(
                    `Bad value, for key "${a}", in ViewerPreferences: ${r}.`
                  );
              }
              return (0, n.shadow)(this, "viewerPreferences", t);
            }
            get openAction() {
              const e = this._catDict.get("OpenAction"),
                t = Object.create(null);
              if (e instanceof i.Dict) {
                const a = new i.Dict(this.xref);
                a.set("A", e);
                const r = { url: null, dest: null, action: null };
                Catalog.parseDestDictionary({ destDict: a, resultObj: r });
                Array.isArray(r.dest)
                  ? (t.dest = r.dest)
                  : r.action && (t.action = r.action);
              } else Array.isArray(e) && (t.dest = e);
              return (0, n.shadow)(
                this,
                "openAction",
                (0, n.objectSize)(t) > 0 ? t : null
              );
            }
            get attachments() {
              const e = this._catDict.get("Names");
              let t = null;
              if (e instanceof i.Dict && e.has("EmbeddedFiles")) {
                const a = new s.NameTree(e.getRaw("EmbeddedFiles"), this.xref);
                for (const [e, r] of a.getAll()) {
                  const a = new h.FileSpec(r, this.xref);
                  t || (t = Object.create(null));
                  t[(0, n.stringToPDFString)(e)] = a.serializable;
                }
              }
              return (0, n.shadow)(this, "attachments", t);
            }
            get xfaImages() {
              const e = this._catDict.get("Names");
              let t = null;
              if (e instanceof i.Dict && e.has("XFAImages")) {
                const a = new s.NameTree(e.getRaw("XFAImages"), this.xref);
                for (const [e, r] of a.getAll()) {
                  t || (t = new i.Dict(this.xref));
                  t.set((0, n.stringToPDFString)(e), r);
                }
              }
              return (0, n.shadow)(this, "xfaImages", t);
            }
            _collectJavaScript() {
              const e = this._catDict.get("Names");
              let t = null;
              function appendIfJavaScriptDict(e, a) {
                if (!(a instanceof i.Dict)) return;
                if (!(0, i.isName)(a.get("S"), "JavaScript")) return;
                let r = a.get("JS");
                if (r instanceof o.BaseStream) r = r.getString();
                else if ("string" != typeof r) return;
                null === t && (t = new Map());
                r = (0, n.stringToPDFString)(r).replace(/\u0000/g, "");
                t.set(e, r);
              }
              if (e instanceof i.Dict && e.has("JavaScript")) {
                const t = new s.NameTree(e.getRaw("JavaScript"), this.xref);
                for (const [e, a] of t.getAll())
                  appendIfJavaScriptDict((0, n.stringToPDFString)(e), a);
              }
              const a = this._catDict.get("OpenAction");
              a && appendIfJavaScriptDict("OpenAction", a);
              return t;
            }
            get javaScript() {
              const e = this._collectJavaScript();
              return (0, n.shadow)(
                this,
                "javaScript",
                e ? [...e.values()] : null
              );
            }
            get jsActions() {
              const e = this._collectJavaScript();
              let t = (0, r.collectActions)(
                this.xref,
                this._catDict,
                n.DocumentActionEventType
              );
              if (e) {
                t || (t = Object.create(null));
                for (const [a, r] of e) a in t ? t[a].push(r) : (t[a] = [r]);
              }
              return (0, n.shadow)(this, "jsActions", t);
            }
            async fontFallback(e, t) {
              const a = await Promise.all(this.fontCache);
              for (const r of a)
                if (r.loadedName === e) {
                  r.fallback(t);
                  return;
                }
            }
            async cleanup(e = !1) {
              (0, c.clearGlobalCaches)();
              this.globalImageCache.clear(e);
              this.pageKidsCountCache.clear();
              this.pageIndexCache.clear();
              this.nonBlendModesSet.clear();
              const t = await Promise.all(this.fontCache);
              for (const { dict: e } of t) delete e.cacheKey;
              this.fontCache.clear();
              this.builtInCMapCache.clear();
              this.standardFontDataCache.clear();
            }
            async getPageDict(e) {
              const t = [this.toplevelPagesDict],
                a = new i.RefSet(),
                r = this._catDict.getRaw("Pages");
              r instanceof i.Ref && a.put(r);
              const s = this.xref,
                o = this.pageKidsCountCache,
                c = this.pageIndexCache;
              let l = 0;
              for (; t.length; ) {
                const r = t.pop();
                if (r instanceof i.Ref) {
                  const h = o.get(r);
                  if (h >= 0 && l + h <= e) {
                    l += h;
                    continue;
                  }
                  if (a.has(r))
                    throw new n.FormatError(
                      "Pages tree contains circular reference."
                    );
                  a.put(r);
                  const u = await s.fetchAsync(r);
                  if (u instanceof i.Dict) {
                    let t = u.getRaw("Type");
                    t instanceof i.Ref && (t = await s.fetchAsync(t));
                    if ((0, i.isName)(t, "Page") || !u.has("Kids")) {
                      o.has(r) || o.put(r, 1);
                      c.has(r) || c.put(r, l);
                      if (l === e) return [u, r];
                      l++;
                      continue;
                    }
                  }
                  t.push(u);
                  continue;
                }
                if (!(r instanceof i.Dict))
                  throw new n.FormatError(
                    "Page dictionary kid reference points to wrong type of object."
                  );
                const { objId: h } = r;
                let u = r.getRaw("Count");
                u instanceof i.Ref && (u = await s.fetchAsync(u));
                if (Number.isInteger(u) && u >= 0) {
                  h && !o.has(h) && o.put(h, u);
                  if (l + u <= e) {
                    l += u;
                    continue;
                  }
                }
                let d = r.getRaw("Kids");
                d instanceof i.Ref && (d = await s.fetchAsync(d));
                if (!Array.isArray(d)) {
                  let t = r.getRaw("Type");
                  t instanceof i.Ref && (t = await s.fetchAsync(t));
                  if ((0, i.isName)(t, "Page") || !r.has("Kids")) {
                    if (l === e) return [r, null];
                    l++;
                    continue;
                  }
                  throw new n.FormatError(
                    "Page dictionary kids object is not an array."
                  );
                }
                for (let e = d.length - 1; e >= 0; e--) t.push(d[e]);
              }
              throw new Error(`Page index ${e} not found.`);
            }
            async getAllPageDicts(e = !1) {
              const { ignoreErrors: t } = this.pdfManager.evaluatorOptions,
                a = [{ currentNode: this.toplevelPagesDict, posInKids: 0 }],
                s = new i.RefSet(),
                o = this._catDict.getRaw("Pages");
              o instanceof i.Ref && s.put(o);
              const c = new Map(),
                l = this.xref,
                h = this.pageIndexCache;
              let u = 0;
              function addPageDict(e, t) {
                t && !h.has(t) && h.put(t, u);
                c.set(u++, [e, t]);
              }
              function addPageError(a) {
                if (a instanceof r.XRefEntryException && !e) throw a;
                if (e && t && 0 === u) {
                  (0, n.warn)(
                    `getAllPageDicts - Skipping invalid first page: "${a}".`
                  );
                  a = i.Dict.empty;
                }
                c.set(u++, [a, null]);
              }
              for (; a.length > 0; ) {
                const e = a.at(-1),
                  { currentNode: t, posInKids: r } = e;
                let o = t.getRaw("Kids");
                if (o instanceof i.Ref)
                  try {
                    o = await l.fetchAsync(o);
                  } catch (e) {
                    addPageError(e);
                    break;
                  }
                if (!Array.isArray(o)) {
                  addPageError(
                    new n.FormatError(
                      "Page dictionary kids object is not an array."
                    )
                  );
                  break;
                }
                if (r >= o.length) {
                  a.pop();
                  continue;
                }
                const c = o[r];
                let h;
                if (c instanceof i.Ref) {
                  if (s.has(c)) {
                    addPageError(
                      new n.FormatError(
                        "Pages tree contains circular reference."
                      )
                    );
                    break;
                  }
                  s.put(c);
                  try {
                    h = await l.fetchAsync(c);
                  } catch (e) {
                    addPageError(e);
                    break;
                  }
                } else h = c;
                if (!(h instanceof i.Dict)) {
                  addPageError(
                    new n.FormatError(
                      "Page dictionary kid reference points to wrong type of object."
                    )
                  );
                  break;
                }
                let u = h.getRaw("Type");
                if (u instanceof i.Ref)
                  try {
                    u = await l.fetchAsync(u);
                  } catch (e) {
                    addPageError(e);
                    break;
                  }
                (0, i.isName)(u, "Page") || !h.has("Kids")
                  ? addPageDict(h, c instanceof i.Ref ? c : null)
                  : a.push({ currentNode: h, posInKids: 0 });
                e.posInKids++;
              }
              return c;
            }
            getPageIndex(e) {
              const t = this.pageIndexCache.get(e);
              if (void 0 !== t) return Promise.resolve(t);
              const a = this.xref;
              let r = 0;
              const next = (t) =>
                (function pagesBeforeRef(t) {
                  let r,
                    s = 0;
                  return a
                    .fetchAsync(t)
                    .then(function (a) {
                      if (
                        (0, i.isRefsEqual)(t, e) &&
                        !(0, i.isDict)(a, "Page") &&
                        !(
                          a instanceof i.Dict &&
                          !a.has("Type") &&
                          a.has("Contents")
                        )
                      )
                        throw new n.FormatError(
                          "The reference does not point to a /Page dictionary."
                        );
                      if (!a) return null;
                      if (!(a instanceof i.Dict))
                        throw new n.FormatError("Node must be a dictionary.");
                      r = a.getRaw("Parent");
                      return a.getAsync("Parent");
                    })
                    .then(function (e) {
                      if (!e) return null;
                      if (!(e instanceof i.Dict))
                        throw new n.FormatError("Parent must be a dictionary.");
                      return e.getAsync("Kids");
                    })
                    .then(function (e) {
                      if (!e) return null;
                      const o = [];
                      let c = !1;
                      for (const r of e) {
                        if (!(r instanceof i.Ref))
                          throw new n.FormatError("Kid must be a reference.");
                        if ((0, i.isRefsEqual)(r, t)) {
                          c = !0;
                          break;
                        }
                        o.push(
                          a.fetchAsync(r).then(function (e) {
                            if (!(e instanceof i.Dict))
                              throw new n.FormatError(
                                "Kid node must be a dictionary."
                              );
                            e.has("Count") ? (s += e.get("Count")) : s++;
                          })
                        );
                      }
                      if (!c)
                        throw new n.FormatError(
                          "Kid reference not found in parent's kids."
                        );
                      return Promise.all(o).then(function () {
                        return [s, r];
                      });
                    });
                })(t).then((t) => {
                  if (!t) {
                    this.pageIndexCache.put(e, r);
                    return r;
                  }
                  const [a, n] = t;
                  r += a;
                  return next(n);
                });
              return next(e);
            }
            get baseUrl() {
              const e = this._catDict.get("URI");
              if (e instanceof i.Dict) {
                const t = e.get("Base");
                if ("string" == typeof t) {
                  const e = (0, n.createValidAbsoluteUrl)(t, null, {
                    tryConvertEncoding: !0,
                  });
                  if (e) return (0, n.shadow)(this, "baseUrl", e.href);
                }
              }
              return (0, n.shadow)(this, "baseUrl", null);
            }
            static parseDestDictionary(e) {
              const t = e.destDict;
              if (!(t instanceof i.Dict)) {
                (0, n.warn)(
                  "parseDestDictionary: `destDict` must be a dictionary."
                );
                return;
              }
              const a = e.resultObj;
              if ("object" != typeof a) {
                (0, n.warn)(
                  "parseDestDictionary: `resultObj` must be an object."
                );
                return;
              }
              const s = e.docBaseUrl || null,
                c = e.docAttachments || null;
              let l,
                h,
                u = t.get("A");
              if (!(u instanceof i.Dict))
                if (t.has("Dest")) u = t.get("Dest");
                else {
                  u = t.get("AA");
                  u instanceof i.Dict &&
                    (u.has("D")
                      ? (u = u.get("D"))
                      : u.has("U") && (u = u.get("U")));
                }
              if (u instanceof i.Dict) {
                const e = u.get("S");
                if (!(e instanceof i.Name)) {
                  (0, n.warn)(
                    "parseDestDictionary: Invalid type in Action dictionary."
                  );
                  return;
                }
                const t = e.name;
                switch (t) {
                  case "ResetForm":
                    const e = u.get("Flags"),
                      s = 0 == (1 & ("number" == typeof e ? e : 0)),
                      d = [],
                      f = [];
                    for (const e of u.get("Fields") || [])
                      e instanceof i.Ref
                        ? f.push(e.toString())
                        : "string" == typeof e &&
                          d.push((0, n.stringToPDFString)(e));
                    a.resetForm = { fields: d, refs: f, include: s };
                    break;
                  case "URI":
                    l = u.get("URI");
                    l instanceof i.Name && (l = "/" + l.name);
                    break;
                  case "GoTo":
                    h = u.get("D");
                    break;
                  case "Launch":
                  case "GoToR":
                    const g = u.get("F");
                    g instanceof i.Dict
                      ? (l = g.get("F") || null)
                      : "string" == typeof g && (l = g);
                    let p = u.get("D");
                    if (p) {
                      p instanceof i.Name && (p = p.name);
                      if ("string" == typeof l) {
                        const e = l.split("#")[0];
                        "string" == typeof p
                          ? (l = e + "#" + p)
                          : Array.isArray(p) &&
                            (l = e + "#" + JSON.stringify(p));
                      }
                    }
                    const m = u.get("NewWindow");
                    "boolean" == typeof m && (a.newWindow = m);
                    break;
                  case "GoToE":
                    const b = u.get("T");
                    let y;
                    if (c && b instanceof i.Dict) {
                      const e = b.get("R"),
                        t = b.get("N");
                      (0, i.isName)(e, "C") &&
                        "string" == typeof t &&
                        (y = c[(0, n.stringToPDFString)(t)]);
                    }
                    y
                      ? (a.attachment = y)
                      : (0, n.warn)(
                          'parseDestDictionary - unimplemented "GoToE" action.'
                        );
                    break;
                  case "Named":
                    const w = u.get("N");
                    w instanceof i.Name && (a.action = w.name);
                    break;
                  case "SetOCGState":
                    const S = u.get("State"),
                      x = u.get("PreserveRB");
                    if (!Array.isArray(S) || 0 === S.length) break;
                    const C = [];
                    for (const e of S)
                      if (e instanceof i.Name)
                        switch (e.name) {
                          case "ON":
                          case "OFF":
                          case "Toggle":
                            C.push(e.name);
                        }
                      else e instanceof i.Ref && C.push(e.toString());
                    if (C.length !== S.length) break;
                    a.setOCGState = {
                      state: C,
                      preserveRB: "boolean" != typeof x || x,
                    };
                    break;
                  case "JavaScript":
                    const k = u.get("JS");
                    let v;
                    k instanceof o.BaseStream
                      ? (v = k.getString())
                      : "string" == typeof k && (v = k);
                    const F =
                      v && (0, r.recoverJsURL)((0, n.stringToPDFString)(v));
                    if (F) {
                      l = F.url;
                      a.newWindow = F.newWindow;
                      break;
                    }
                  default:
                    if ("JavaScript" === t || "SubmitForm" === t) break;
                    (0, n.warn)(
                      `parseDestDictionary - unsupported action: "${t}".`
                    );
                }
              } else t.has("Dest") && (h = t.get("Dest"));
              if ("string" == typeof l) {
                const e = (0, n.createValidAbsoluteUrl)(l, s, {
                  addDefaultProtocol: !0,
                  tryConvertEncoding: !0,
                });
                e && (a.url = e.href);
                a.unsafeUrl = l;
              }
              if (h) {
                h instanceof i.Name && (h = h.name);
                "string" == typeof h
                  ? (a.dest = (0, n.stringToPDFString)(h))
                  : Array.isArray(h) && (a.dest = h);
              }
            }
          }
          t.Catalog = Catalog;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.NumberTree = t.NameTree = void 0;
          var r = a(4),
            n = a(2);
          class NameOrNumberTree {
            constructor(e, t, a) {
              this.constructor === NameOrNumberTree &&
                (0, n.unreachable)("Cannot initialize NameOrNumberTree.");
              this.root = e;
              this.xref = t;
              this._type = a;
            }
            getAll() {
              const e = new Map();
              if (!this.root) return e;
              const t = this.xref,
                a = new r.RefSet();
              a.put(this.root);
              const i = [this.root];
              for (; i.length > 0; ) {
                const s = t.fetchIfRef(i.shift());
                if (!(s instanceof r.Dict)) continue;
                if (s.has("Kids")) {
                  const e = s.get("Kids");
                  if (!Array.isArray(e)) continue;
                  for (const t of e) {
                    if (a.has(t))
                      throw new n.FormatError(
                        `Duplicate entry in "${this._type}" tree.`
                      );
                    i.push(t);
                    a.put(t);
                  }
                  continue;
                }
                const o = s.get(this._type);
                if (Array.isArray(o))
                  for (let a = 0, r = o.length; a < r; a += 2)
                    e.set(t.fetchIfRef(o[a]), t.fetchIfRef(o[a + 1]));
              }
              return e;
            }
            get(e) {
              if (!this.root) return null;
              const t = this.xref;
              let a = t.fetchIfRef(this.root),
                r = 0;
              for (; a.has("Kids"); ) {
                if (++r > 10) {
                  (0, n.warn)(
                    `Search depth limit reached for "${this._type}" tree.`
                  );
                  return null;
                }
                const i = a.get("Kids");
                if (!Array.isArray(i)) return null;
                let s = 0,
                  o = i.length - 1;
                for (; s <= o; ) {
                  const r = (s + o) >> 1,
                    n = t.fetchIfRef(i[r]),
                    c = n.get("Limits");
                  if (e < t.fetchIfRef(c[0])) o = r - 1;
                  else {
                    if (!(e > t.fetchIfRef(c[1]))) {
                      a = n;
                      break;
                    }
                    s = r + 1;
                  }
                }
                if (s > o) return null;
              }
              const i = a.get(this._type);
              if (Array.isArray(i)) {
                let a = 0,
                  r = i.length - 2;
                for (; a <= r; ) {
                  const n = (a + r) >> 1,
                    s = n + (1 & n),
                    o = t.fetchIfRef(i[s]);
                  if (e < o) r = s - 2;
                  else {
                    if (!(e > o)) return t.fetchIfRef(i[s + 1]);
                    a = s + 2;
                  }
                }
              }
              return null;
            }
          }
          t.NameTree = class NameTree extends NameOrNumberTree {
            constructor(e, t) {
              super(e, t, "Names");
            }
          };
          t.NumberTree = class NumberTree extends NameOrNumberTree {
            constructor(e, t) {
              super(e, t, "Nums");
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.clearGlobalCaches = function clearGlobalCaches() {
            (0, r.clearPrimitiveCaches)();
            (0, n.clearUnicodeCaches)();
          };
          var r = a(4),
            n = a(38);
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.FileSpec = void 0;
          var r = a(2),
            n = a(5),
            i = a(4);
          function pickPlatformItem(e) {
            return e.has("UF")
              ? e.get("UF")
              : e.has("F")
              ? e.get("F")
              : e.has("Unix")
              ? e.get("Unix")
              : e.has("Mac")
              ? e.get("Mac")
              : e.has("DOS")
              ? e.get("DOS")
              : null;
          }
          t.FileSpec = class FileSpec {
            constructor(e, t) {
              if (e instanceof i.Dict) {
                this.xref = t;
                this.root = e;
                e.has("FS") && (this.fs = e.get("FS"));
                this.description = e.has("Desc")
                  ? (0, r.stringToPDFString)(e.get("Desc"))
                  : "";
                e.has("RF") &&
                  (0, r.warn)("Related file specifications are not supported");
                this.contentAvailable = !0;
                if (!e.has("EF")) {
                  this.contentAvailable = !1;
                  (0, r.warn)(
                    "Non-embedded file specifications are not supported"
                  );
                }
              }
            }
            get filename() {
              if (!this._filename && this.root) {
                const e = pickPlatformItem(this.root) || "unnamed";
                this._filename = (0, r.stringToPDFString)(e)
                  .replace(/\\\\/g, "\\")
                  .replace(/\\\//g, "/")
                  .replace(/\\/g, "/");
              }
              return this._filename;
            }
            get content() {
              if (!this.contentAvailable) return null;
              !this.contentRef &&
                this.root &&
                (this.contentRef = pickPlatformItem(this.root.get("EF")));
              let e = null;
              if (this.contentRef) {
                const t = this.xref.fetchIfRef(this.contentRef);
                t instanceof n.BaseStream
                  ? (e = t.getBytes())
                  : (0, r.warn)(
                      "Embedded file specification points to non-existing/invalid content"
                    );
              } else
                (0, r.warn)(
                  "Embedded file specification does not have a content"
                );
              return e;
            }
            get serializable() {
              return { filename: this.filename, content: this.content };
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.MetadataParser = void 0;
          var r = a(64);
          t.MetadataParser = class MetadataParser {
            constructor(e) {
              e = this._repair(e);
              const t = new r.SimpleXMLParser({
                lowerCaseName: !0,
              }).parseFromString(e);
              this._metadataMap = new Map();
              this._data = e;
              t && this._parse(t);
            }
            _repair(e) {
              return e
                .replace(/^[^<]+/, "")
                .replace(/>\\376\\377([^<]+)/g, function (e, t) {
                  const a = t
                      .replace(
                        /\\([0-3])([0-7])([0-7])/g,
                        function (e, t, a, r) {
                          return String.fromCharCode(64 * t + 8 * a + 1 * r);
                        }
                      )
                      .replace(/&(amp|apos|gt|lt|quot);/g, function (e, t) {
                        switch (t) {
                          case "amp":
                            return "&";
                          case "apos":
                            return "'";
                          case "gt":
                            return ">";
                          case "lt":
                            return "<";
                          case "quot":
                            return '"';
                        }
                        throw new Error(`_repair: ${t} isn't defined.`);
                      }),
                    r = [">"];
                  for (let e = 0, t = a.length; e < t; e += 2) {
                    const t = 256 * a.charCodeAt(e) + a.charCodeAt(e + 1);
                    t >= 32 && t < 127 && 60 !== t && 62 !== t && 38 !== t
                      ? r.push(String.fromCharCode(t))
                      : r.push(
                          "&#x" + (65536 + t).toString(16).substring(1) + ";"
                        );
                  }
                  return r.join("");
                });
            }
            _getSequence(e) {
              const t = e.nodeName;
              return "rdf:bag" !== t && "rdf:seq" !== t && "rdf:alt" !== t
                ? null
                : e.childNodes.filter((e) => "rdf:li" === e.nodeName);
            }
            _parseArray(e) {
              if (!e.hasChildNodes()) return;
              const [t] = e.childNodes,
                a = this._getSequence(t) || [];
              this._metadataMap.set(
                e.nodeName,
                a.map((e) => e.textContent.trim())
              );
            }
            _parse(e) {
              let t = e.documentElement;
              if ("rdf:rdf" !== t.nodeName) {
                t = t.firstChild;
                for (; t && "rdf:rdf" !== t.nodeName; ) t = t.nextSibling;
              }
              if (t && "rdf:rdf" === t.nodeName && t.hasChildNodes())
                for (const e of t.childNodes)
                  if ("rdf:description" === e.nodeName)
                    for (const t of e.childNodes) {
                      const e = t.nodeName;
                      switch (e) {
                        case "#text":
                          continue;
                        case "dc:creator":
                        case "dc:subject":
                          this._parseArray(t);
                          continue;
                      }
                      this._metadataMap.set(e, t.textContent.trim());
                    }
            }
            get serializable() {
              return { parsedData: this._metadataMap, rawData: this._data };
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.StructTreeRoot = t.StructTreePage = void 0;
          var r = a(4),
            n = a(2),
            i = a(68);
          const s = 40,
            o = {
              PAGE_CONTENT: "PAGE_CONTENT",
              STREAM_CONTENT: "STREAM_CONTENT",
              OBJECT: "OBJECT",
              ELEMENT: "ELEMENT",
            };
          t.StructTreeRoot = class StructTreeRoot {
            constructor(e) {
              this.dict = e;
              this.roleMap = new Map();
            }
            init() {
              this.readRoleMap();
            }
            readRoleMap() {
              const e = this.dict.get("RoleMap");
              e instanceof r.Dict &&
                e.forEach((e, t) => {
                  t instanceof r.Name && this.roleMap.set(e, t.name);
                });
            }
          };
          class StructElementNode {
            constructor(e, t) {
              this.tree = e;
              this.dict = t;
              this.kids = [];
              this.parseKids();
            }
            get role() {
              const e = this.dict.get("S"),
                t = e instanceof r.Name ? e.name : "",
                { root: a } = this.tree;
              return a.roleMap.has(t) ? a.roleMap.get(t) : t;
            }
            parseKids() {
              let e = null;
              const t = this.dict.getRaw("Pg");
              t instanceof r.Ref && (e = t.toString());
              const a = this.dict.get("K");
              if (Array.isArray(a))
                for (const t of a) {
                  const a = this.parseKid(e, t);
                  a && this.kids.push(a);
                }
              else {
                const t = this.parseKid(e, a);
                t && this.kids.push(t);
              }
            }
            parseKid(e, t) {
              if (Number.isInteger(t))
                return this.tree.pageDict.objId !== e
                  ? null
                  : new StructElement({
                      type: o.PAGE_CONTENT,
                      mcid: t,
                      pageObjId: e,
                    });
              let a = null;
              t instanceof r.Ref
                ? (a = this.dict.xref.fetch(t))
                : t instanceof r.Dict && (a = t);
              if (!a) return null;
              const n = a.getRaw("Pg");
              n instanceof r.Ref && (e = n.toString());
              const i =
                a.get("Type") instanceof r.Name ? a.get("Type").name : null;
              return "MCR" === i
                ? this.tree.pageDict.objId !== e
                  ? null
                  : new StructElement({
                      type: o.STREAM_CONTENT,
                      refObjId:
                        a.getRaw("Stm") instanceof r.Ref
                          ? a.getRaw("Stm").toString()
                          : null,
                      pageObjId: e,
                      mcid: a.get("MCID"),
                    })
                : "OBJR" === i
                ? this.tree.pageDict.objId !== e
                  ? null
                  : new StructElement({
                      type: o.OBJECT,
                      refObjId:
                        a.getRaw("Obj") instanceof r.Ref
                          ? a.getRaw("Obj").toString()
                          : null,
                      pageObjId: e,
                    })
                : new StructElement({ type: o.ELEMENT, dict: a });
            }
          }
          class StructElement {
            constructor({
              type: e,
              dict: t = null,
              mcid: a = null,
              pageObjId: r = null,
              refObjId: n = null,
            }) {
              this.type = e;
              this.dict = t;
              this.mcid = a;
              this.pageObjId = r;
              this.refObjId = n;
              this.parentNode = null;
            }
          }
          t.StructTreePage = class StructTreePage {
            constructor(e, t) {
              this.root = e;
              this.rootDict = e ? e.dict : null;
              this.pageDict = t;
              this.nodes = [];
            }
            parse() {
              if (!this.root || !this.rootDict) return;
              const e = this.rootDict.get("ParentTree");
              if (!e) return;
              const t = this.pageDict.get("StructParents");
              if (!Number.isInteger(t)) return;
              const a = new i.NumberTree(e, this.rootDict.xref).get(t);
              if (!Array.isArray(a)) return;
              const n = new Map();
              for (const e of a)
                e instanceof r.Ref &&
                  this.addNode(this.rootDict.xref.fetch(e), n);
            }
            addNode(e, t, a = 0) {
              if (a > s) {
                (0, n.warn)("StructTree MAX_DEPTH reached.");
                return null;
              }
              if (t.has(e)) return t.get(e);
              const i = new StructElementNode(this, e);
              t.set(e, i);
              const c = e.get("P");
              if (!c || (0, r.isName)(c.get("Type"), "StructTreeRoot")) {
                this.addTopLevelNode(e, i) || t.delete(e);
                return i;
              }
              const l = this.addNode(c, t, a + 1);
              if (!l) return i;
              let h = !1;
              for (const t of l.kids)
                if (t.type === o.ELEMENT && t.dict === e) {
                  t.parentNode = i;
                  h = !0;
                }
              h || t.delete(e);
              return i;
            }
            addTopLevelNode(e, t) {
              const a = this.rootDict.get("K");
              if (!a) return !1;
              if (a instanceof r.Dict) {
                if (a.objId !== e.objId) return !1;
                this.nodes[0] = t;
                return !0;
              }
              if (!Array.isArray(a)) return !0;
              let n = !1;
              for (let r = 0; r < a.length; r++) {
                const i = a[r];
                if (i && i.toString() === e.objId) {
                  this.nodes[r] = t;
                  n = !0;
                }
              }
              return n;
            }
            get serializable() {
              function nodeToSerializable(e, t, a = 0) {
                if (a > s) {
                  (0, n.warn)("StructTree too deep to be fully serialized.");
                  return;
                }
                const r = Object.create(null);
                r.role = e.role;
                r.children = [];
                t.children.push(r);
                const i = e.dict.get("Alt");
                "string" == typeof i && (r.alt = (0, n.stringToPDFString)(i));
                const c = e.dict.get("Lang");
                "string" == typeof c && (r.lang = (0, n.stringToPDFString)(c));
                for (const t of e.kids) {
                  const e = t.type === o.ELEMENT ? t.parentNode : null;
                  e
                    ? nodeToSerializable(e, r, a + 1)
                    : t.type === o.PAGE_CONTENT || t.type === o.STREAM_CONTENT
                    ? r.children.push({
                        type: "content",
                        id: `page${t.pageObjId}_mcid${t.mcid}`,
                      })
                    : t.type === o.OBJECT &&
                      r.children.push({ type: "object", id: t.refObjId });
                }
              }
              const e = Object.create(null);
              e.children = [];
              e.role = "Root";
              for (const t of this.nodes) t && nodeToSerializable(t, e);
              return e;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ObjectLoader = void 0;
          var r = a(4),
            n = a(5),
            i = a(3),
            s = a(2);
          function addChildren(e, t) {
            if (e instanceof r.Dict) e = e.getRawValues();
            else if (e instanceof n.BaseStream) e = e.dict.getRawValues();
            else if (!Array.isArray(e)) return;
            for (const i of e)
              ((a = i) instanceof r.Ref ||
                a instanceof r.Dict ||
                a instanceof n.BaseStream ||
                Array.isArray(a)) &&
                t.push(i);
            var a;
          }
          t.ObjectLoader = class ObjectLoader {
            constructor(e, t, a) {
              this.dict = e;
              this.keys = t;
              this.xref = a;
              this.refSet = null;
            }
            async load() {
              if (this.xref.stream.isDataLoaded) return;
              const { keys: e, dict: t } = this;
              this.refSet = new r.RefSet();
              const a = [];
              for (const r of e) {
                const e = t.getRaw(r);
                void 0 !== e && a.push(e);
              }
              return this._walk(a);
            }
            async _walk(e) {
              const t = [],
                a = [];
              for (; e.length; ) {
                let o = e.pop();
                if (o instanceof r.Ref) {
                  if (this.refSet.has(o)) continue;
                  try {
                    this.refSet.put(o);
                    o = this.xref.fetch(o);
                  } catch (e) {
                    if (!(e instanceof i.MissingDataException)) {
                      (0, s.warn)(
                        `ObjectLoader._walk - requesting all data: "${e}".`
                      );
                      this.refSet = null;
                      const { manager: t } = this.xref.stream;
                      return t.requestAllChunks();
                    }
                    t.push(o);
                    a.push({ begin: e.begin, end: e.end });
                  }
                }
                if (o instanceof n.BaseStream) {
                  const e = o.getBaseStreams();
                  if (e) {
                    let r = !1;
                    for (const t of e)
                      if (!t.isDataLoaded) {
                        r = !0;
                        a.push({ begin: t.start, end: t.end });
                      }
                    r && t.push(o);
                  }
                }
                addChildren(o, e);
              }
              if (a.length) {
                await this.xref.stream.manager.requestRanges(a);
                for (const e of t) e instanceof r.Ref && this.refSet.remove(e);
                return this._walk(t);
              }
              this.refSet = null;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XFAFactory = void 0;
          var r = a(75),
            n = a(79),
            i = a(85),
            s = a(83),
            o = a(76),
            c = a(2),
            l = a(86),
            h = a(96);
          class XFAFactory {
            constructor(e) {
              try {
                this.root = new l.XFAParser().parse(
                  XFAFactory._createDocument(e)
                );
                const t = new n.Binder(this.root);
                this.form = t.bind();
                this.dataHandler = new i.DataHandler(this.root, t.getData());
                this.form[r.$globalData].template = this.form;
              } catch (e) {
                (0, c.warn)(
                  `XFA - an error occurred during parsing and binding: ${e}`
                );
              }
            }
            isValid() {
              return this.root && this.form;
            }
            _createPagesHelper() {
              const e = this.form[r.$toPages]();
              return new Promise((t, a) => {
                const nextIteration = () => {
                  try {
                    const a = e.next();
                    a.done ? t(a.value) : setTimeout(nextIteration, 0);
                  } catch (e) {
                    a(e);
                  }
                };
                setTimeout(nextIteration, 0);
              });
            }
            async _createPages() {
              try {
                this.pages = await this._createPagesHelper();
                this.dims = this.pages.children.map((e) => {
                  const { width: t, height: a } = e.attributes.style;
                  return [0, 0, parseInt(t), parseInt(a)];
                });
              } catch (e) {
                (0, c.warn)(`XFA - an error occurred during layout: ${e}`);
              }
            }
            getBoundingBox(e) {
              return this.dims[e];
            }
            async getNumPages() {
              this.pages || (await this._createPages());
              return this.dims.length;
            }
            setImages(e) {
              this.form[r.$globalData].images = e;
            }
            setFonts(e) {
              this.form[r.$globalData].fontFinder = new s.FontFinder(e);
              const t = [];
              for (let e of this.form[r.$globalData].usedTypefaces) {
                e = (0, o.stripQuotes)(e);
                this.form[r.$globalData].fontFinder.find(e) || t.push(e);
              }
              return t.length > 0 ? t : null;
            }
            appendFonts(e, t) {
              this.form[r.$globalData].fontFinder.add(e, t);
            }
            async getPages() {
              this.pages || (await this._createPages());
              const e = this.pages;
              this.pages = null;
              return e;
            }
            serializeData(e) {
              return this.dataHandler.serialize(e);
            }
            static _createDocument(e) {
              return e["/xdp:xdp"] ? Object.values(e).join("") : e["xdp:xdp"];
            }
            static getRichTextAsHtml(e) {
              if (!e || "string" != typeof e) return null;
              try {
                let t = new l.XFAParser(h.XhtmlNamespace, !0).parse(e);
                if (!["body", "xhtml"].includes(t[r.$nodeName])) {
                  const e = h.XhtmlNamespace.body({});
                  e[r.$appendChild](t);
                  t = e;
                }
                const a = t[r.$toHTML]();
                if (!a.success) return null;
                const { html: n } = a,
                  { attributes: i } = n;
                if (i) {
                  i.class &&
                    (i.class = i.class.filter((e) => !e.startsWith("xfa")));
                  i.dir = "auto";
                }
                return { html: n, str: t[r.$text]() };
              } catch (e) {
                (0, c.warn)(
                  `XFA - an error occurred during parsing of rich text: ${e}`
                );
              }
              return null;
            }
          }
          t.XFAFactory = XFAFactory;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XmlObject =
            t.XFAObjectArray =
            t.XFAObject =
            t.XFAAttribute =
            t.StringObject =
            t.OptionObject =
            t.Option10 =
            t.Option01 =
            t.IntegerObject =
            t.ContentObject =
            t.$uid =
            t.$toStyle =
            t.$toString =
            t.$toPages =
            t.$toHTML =
            t.$text =
            t.$tabIndex =
            t.$setValue =
            t.$setSetAttributes =
            t.$setId =
            t.$searchNode =
            t.$root =
            t.$resolvePrototypes =
            t.$removeChild =
            t.$pushPara =
            t.$pushGlyphs =
            t.$popPara =
            t.$onText =
            t.$onChildCheck =
            t.$onChild =
            t.$nsAttributes =
            t.$nodeName =
            t.$namespaceId =
            t.$isUsable =
            t.$isTransparent =
            t.$isThereMoreWidth =
            t.$isSplittable =
            t.$isNsAgnostic =
            t.$isDescendent =
            t.$isDataValue =
            t.$isCDATAXml =
            t.$isBindable =
            t.$insertAt =
            t.$indexOf =
            t.$ids =
            t.$hasSettableValue =
            t.$globalData =
            t.$getTemplateRoot =
            t.$getSubformParent =
            t.$getRealChildrenByNameIt =
            t.$getParent =
            t.$getNextPage =
            t.$getExtra =
            t.$getDataValue =
            t.$getContainedChildren =
            t.$getChildrenByNameIt =
            t.$getChildrenByName =
            t.$getChildrenByClass =
            t.$getChildren =
            t.$getAvailableSpace =
            t.$getAttributes =
            t.$getAttributeIt =
            t.$flushHTML =
            t.$finalize =
            t.$extra =
            t.$dump =
            t.$data =
            t.$content =
            t.$consumed =
            t.$clone =
            t.$cleanup =
            t.$cleanPage =
            t.$clean =
            t.$childrenToHTML =
            t.$appendChild =
            t.$addHTML =
            t.$acceptWhitespace =
              void 0;
          var r = a(76),
            n = a(2),
            i = a(3),
            s = a(77),
            o = a(78);
          const c = Symbol();
          t.$acceptWhitespace = c;
          const l = Symbol();
          t.$addHTML = l;
          const h = Symbol();
          t.$appendChild = h;
          const u = Symbol();
          t.$childrenToHTML = u;
          const d = Symbol();
          t.$clean = d;
          const f = Symbol();
          t.$cleanPage = f;
          const g = Symbol();
          t.$cleanup = g;
          const p = Symbol();
          t.$clone = p;
          const m = Symbol();
          t.$consumed = m;
          const b = Symbol("content");
          t.$content = b;
          const y = Symbol("data");
          t.$data = y;
          const w = Symbol();
          t.$dump = w;
          const S = Symbol("extra");
          t.$extra = S;
          const x = Symbol();
          t.$finalize = x;
          const C = Symbol();
          t.$flushHTML = C;
          const k = Symbol();
          t.$getAttributeIt = k;
          const v = Symbol();
          t.$getAttributes = v;
          const F = Symbol();
          t.$getAvailableSpace = F;
          const O = Symbol();
          t.$getChildrenByClass = O;
          const T = Symbol();
          t.$getChildrenByName = T;
          const M = Symbol();
          t.$getChildrenByNameIt = M;
          const E = Symbol();
          t.$getDataValue = E;
          const D = Symbol();
          t.$getExtra = D;
          const N = Symbol();
          t.$getRealChildrenByNameIt = N;
          const R = Symbol();
          t.$getChildren = R;
          const L = Symbol();
          t.$getContainedChildren = L;
          const j = Symbol();
          t.$getNextPage = j;
          const $ = Symbol();
          t.$getSubformParent = $;
          const _ = Symbol();
          t.$getParent = _;
          const U = Symbol();
          t.$getTemplateRoot = U;
          const X = Symbol();
          t.$globalData = X;
          const H = Symbol();
          t.$hasSettableValue = H;
          const q = Symbol();
          t.$ids = q;
          const z = Symbol();
          t.$indexOf = z;
          const W = Symbol();
          t.$insertAt = W;
          const G = Symbol();
          t.$isCDATAXml = G;
          const V = Symbol();
          t.$isBindable = V;
          const K = Symbol();
          t.$isDataValue = K;
          const J = Symbol();
          t.$isDescendent = J;
          const Y = Symbol();
          t.$isNsAgnostic = Y;
          const Z = Symbol();
          t.$isSplittable = Z;
          const Q = Symbol();
          t.$isThereMoreWidth = Q;
          const ee = Symbol();
          t.$isTransparent = ee;
          const te = Symbol();
          t.$isUsable = te;
          const ae = Symbol(),
            re = Symbol("namespaceId");
          t.$namespaceId = re;
          const ne = Symbol("nodeName");
          t.$nodeName = ne;
          const ie = Symbol();
          t.$nsAttributes = ie;
          const se = Symbol();
          t.$onChild = se;
          const oe = Symbol();
          t.$onChildCheck = oe;
          const ce = Symbol();
          t.$onText = ce;
          const le = Symbol();
          t.$pushGlyphs = le;
          const he = Symbol();
          t.$popPara = he;
          const ue = Symbol();
          t.$pushPara = ue;
          const de = Symbol();
          t.$removeChild = de;
          const fe = Symbol("root");
          t.$root = fe;
          const ge = Symbol();
          t.$resolvePrototypes = ge;
          const pe = Symbol();
          t.$searchNode = pe;
          const me = Symbol();
          t.$setId = me;
          const be = Symbol();
          t.$setSetAttributes = be;
          const ye = Symbol();
          t.$setValue = ye;
          const we = Symbol();
          t.$tabIndex = we;
          const Se = Symbol();
          t.$text = Se;
          const xe = Symbol();
          t.$toPages = xe;
          const Ae = Symbol();
          t.$toHTML = Ae;
          const Ce = Symbol();
          t.$toString = Ce;
          const ke = Symbol();
          t.$toStyle = ke;
          const ve = Symbol("uid");
          t.$uid = ve;
          const Fe = Symbol(),
            Oe = Symbol(),
            Te = Symbol(),
            Ie = Symbol("_children"),
            Me = Symbol(),
            Pe = Symbol(),
            Ee = Symbol(),
            De = Symbol(),
            Ne = Symbol(),
            Be = Symbol(),
            Re = Symbol(),
            Le = Symbol(),
            je = Symbol(),
            $e = Symbol("parent"),
            _e = Symbol(),
            Ue = Symbol(),
            Xe = Symbol();
          let He = 0;
          const qe = s.NamespaceIds.datasets.id;
          class XFAObject {
            constructor(e, t, a = !1) {
              this[re] = e;
              this[ne] = t;
              this[Re] = a;
              this[$e] = null;
              this[Ie] = [];
              this[ve] = `${t}${He++}`;
              this[X] = null;
            }
            [se](e) {
              if (!this[Re] || !this[oe](e)) return !1;
              const t = e[ne],
                a = this[t];
              if (!(a instanceof XFAObjectArray)) {
                null !== a && this[de](a);
                this[t] = e;
                this[h](e);
                return !0;
              }
              if (a.push(e)) {
                this[h](e);
                return !0;
              }
              let r = "";
              this.id
                ? (r = ` (id: ${this.id})`)
                : this.name && (r = ` (name: ${this.name} ${this.h.value})`);
              (0, n.warn)(
                `XFA - node "${this[ne]}"${r} has already enough "${t}"!`
              );
              return !1;
            }
            [oe](e) {
              return this.hasOwnProperty(e[ne]) && e[re] === this[re];
            }
            [Y]() {
              return !1;
            }
            [c]() {
              return !1;
            }
            [G]() {
              return !1;
            }
            [V]() {
              return !1;
            }
            [he]() {
              this.para && this[U]()[S].paraStack.pop();
            }
            [ue]() {
              this[U]()[S].paraStack.push(this.para);
            }
            [me](e) {
              this.id &&
                this[re] === s.NamespaceIds.template.id &&
                e.set(this.id, this);
            }
            [U]() {
              return this[X].template;
            }
            [Z]() {
              return !1;
            }
            [Q]() {
              return !1;
            }
            [h](e) {
              e[$e] = this;
              this[Ie].push(e);
              !e[X] && this[X] && (e[X] = this[X]);
            }
            [de](e) {
              const t = this[Ie].indexOf(e);
              this[Ie].splice(t, 1);
            }
            [H]() {
              return this.hasOwnProperty("value");
            }
            [ye](e) {}
            [ce](e) {}
            [x]() {}
            [d](e) {
              delete this[Re];
              if (this[g]) {
                e.clean(this[g]);
                delete this[g];
              }
            }
            [z](e) {
              return this[Ie].indexOf(e);
            }
            [W](e, t) {
              t[$e] = this;
              this[Ie].splice(e, 0, t);
              !t[X] && this[X] && (t[X] = this[X]);
            }
            [ee]() {
              return !this.name;
            }
            [ae]() {
              return "";
            }
            [Se]() {
              return 0 === this[Ie].length
                ? this[b]
                : this[Ie].map((e) => e[Se]()).join("");
            }
            get [Te]() {
              const e = Object.getPrototypeOf(this);
              if (!e._attributes) {
                const t = (e._attributes = new Set());
                for (const e of Object.getOwnPropertyNames(this)) {
                  if (
                    null === this[e] ||
                    this[e] instanceof XFAObject ||
                    this[e] instanceof XFAObjectArray
                  )
                    break;
                  t.add(e);
                }
              }
              return (0, n.shadow)(this, Te, e._attributes);
            }
            [J](e) {
              let t = this;
              for (; t; ) {
                if (t === e) return !0;
                t = t[_]();
              }
              return !1;
            }
            [_]() {
              return this[$e];
            }
            [$]() {
              return this[_]();
            }
            [R](e = null) {
              return e ? this[e] : this[Ie];
            }
            [w]() {
              const e = Object.create(null);
              this[b] && (e.$content = this[b]);
              for (const t of Object.getOwnPropertyNames(this)) {
                const a = this[t];
                null !== a &&
                  (a instanceof XFAObject
                    ? (e[t] = a[w]())
                    : a instanceof XFAObjectArray
                    ? a.isEmpty() || (e[t] = a.dump())
                    : (e[t] = a));
              }
              return e;
            }
            [ke]() {
              return null;
            }
            [Ae]() {
              return r.HTMLResult.EMPTY;
            }
            *[L]() {
              for (const e of this[R]()) yield e;
            }
            *[De](e, t) {
              for (const a of this[L]())
                if (!e || t === e.has(a[ne])) {
                  const e = this[F](),
                    t = a[Ae](e);
                  t.success || (this[S].failingNode = a);
                  yield t;
                }
            }
            [C]() {
              return null;
            }
            [l](e, t) {
              this[S].children.push(e);
            }
            [F]() {}
            [u]({ filter: e = null, include: t = !0 }) {
              if (this[S].generator) {
                const e = this[F](),
                  t = this[S].failingNode[Ae](e);
                if (!t.success) return t;
                t.html && this[l](t.html, t.bbox);
                delete this[S].failingNode;
              } else this[S].generator = this[De](e, t);
              for (;;) {
                const e = this[S].generator.next();
                if (e.done) break;
                const t = e.value;
                if (!t.success) return t;
                t.html && this[l](t.html, t.bbox);
              }
              this[S].generator = null;
              return r.HTMLResult.EMPTY;
            }
            [be](e) {
              this[Ue] = new Set(Object.keys(e));
            }
            [Be](e) {
              const t = this[Te],
                a = this[Ue];
              return [...e].filter((e) => t.has(e) && !a.has(e));
            }
            [ge](e, t = new Set()) {
              for (const a of this[Ie]) a[_e](e, t);
            }
            [_e](e, t) {
              const a = this[Ne](e, t);
              a ? this[Fe](a, e, t) : this[ge](e, t);
            }
            [Ne](e, t) {
              const { use: a, usehref: r } = this;
              if (!a && !r) return null;
              let i = null,
                s = null,
                c = null,
                l = a;
              if (r) {
                l = r;
                r.startsWith("#som(") && r.endsWith(")")
                  ? (s = r.slice("#som(".length, r.length - 1))
                  : r.startsWith(".#som(") && r.endsWith(")")
                  ? (s = r.slice(".#som(".length, r.length - 1))
                  : r.startsWith("#")
                  ? (c = r.slice(1))
                  : r.startsWith(".#") && (c = r.slice(2));
              } else a.startsWith("#") ? (c = a.slice(1)) : (s = a);
              this.use = this.usehref = "";
              if (c) i = e.get(c);
              else {
                i = (0, o.searchNode)(e.get(fe), this, s, !0, !1);
                i && (i = i[0]);
              }
              if (!i) {
                (0, n.warn)(`XFA - Invalid prototype reference: ${l}.`);
                return null;
              }
              if (i[ne] !== this[ne]) {
                (0, n.warn)(
                  `XFA - Incompatible prototype: ${i[ne]} !== ${this[ne]}.`
                );
                return null;
              }
              if (t.has(i)) {
                (0, n.warn)("XFA - Cycle detected in prototypes use.");
                return null;
              }
              t.add(i);
              const h = i[Ne](e, t);
              h && i[Fe](h, e, t);
              i[ge](e, t);
              t.delete(i);
              return i;
            }
            [Fe](e, t, a) {
              if (a.has(e)) {
                (0, n.warn)("XFA - Cycle detected in prototypes use.");
                return;
              }
              !this[b] && e[b] && (this[b] = e[b]);
              new Set(a).add(e);
              for (const t of this[Be](e[Ue])) {
                this[t] = e[t];
                this[Ue] && this[Ue].add(t);
              }
              for (const r of Object.getOwnPropertyNames(this)) {
                if (this[Te].has(r)) continue;
                const n = this[r],
                  i = e[r];
                if (n instanceof XFAObjectArray) {
                  for (const e of n[Ie]) e[_e](t, a);
                  for (let r = n[Ie].length, s = i[Ie].length; r < s; r++) {
                    const i = e[Ie][r][p]();
                    if (!n.push(i)) break;
                    i[$e] = this;
                    this[Ie].push(i);
                    i[_e](t, a);
                  }
                } else if (null === n) {
                  if (null !== i) {
                    const e = i[p]();
                    e[$e] = this;
                    this[r] = e;
                    this[Ie].push(e);
                    e[_e](t, a);
                  }
                } else {
                  n[ge](t, a);
                  i && n[Fe](i, t, a);
                }
              }
            }
            static [Me](e) {
              return Array.isArray(e)
                ? e.map((e) => XFAObject[Me](e))
                : "object" == typeof e && null !== e
                ? Object.assign({}, e)
                : e;
            }
            [p]() {
              const e = Object.create(Object.getPrototypeOf(this));
              for (const t of Object.getOwnPropertySymbols(this))
                try {
                  e[t] = this[t];
                } catch (a) {
                  (0, n.shadow)(e, t, this[t]);
                }
              e[ve] = `${e[ne]}${He++}`;
              e[Ie] = [];
              for (const t of Object.getOwnPropertyNames(this)) {
                if (this[Te].has(t)) {
                  e[t] = XFAObject[Me](this[t]);
                  continue;
                }
                const a = this[t];
                e[t] =
                  a instanceof XFAObjectArray
                    ? new XFAObjectArray(a[Le])
                    : null;
              }
              for (const t of this[Ie]) {
                const a = t[ne],
                  r = t[p]();
                e[Ie].push(r);
                r[$e] = e;
                null === e[a] ? (e[a] = r) : e[a][Ie].push(r);
              }
              return e;
            }
            [R](e = null) {
              return e ? this[Ie].filter((t) => t[ne] === e) : this[Ie];
            }
            [O](e) {
              return this[e];
            }
            [T](e, t, a = !0) {
              return Array.from(this[M](e, t, a));
            }
            *[M](e, t, a = !0) {
              if ("parent" !== e) {
                for (const a of this[Ie]) {
                  a[ne] === e && (yield a);
                  a.name === e && (yield a);
                  (t || a[ee]()) && (yield* a[M](e, t, !1));
                }
                a &&
                  this[Te].has(e) &&
                  (yield new XFAAttribute(this, e, this[e]));
              } else yield this[$e];
            }
          }
          t.XFAObject = XFAObject;
          class XFAObjectArray {
            constructor(e = 1 / 0) {
              this[Le] = e;
              this[Ie] = [];
            }
            push(e) {
              if (this[Ie].length <= this[Le]) {
                this[Ie].push(e);
                return !0;
              }
              (0, n.warn)(
                `XFA - node "${e[ne]}" accepts no more than ${this[Le]} children`
              );
              return !1;
            }
            isEmpty() {
              return 0 === this[Ie].length;
            }
            dump() {
              return 1 === this[Ie].length
                ? this[Ie][0][w]()
                : this[Ie].map((e) => e[w]());
            }
            [p]() {
              const e = new XFAObjectArray(this[Le]);
              e[Ie] = this[Ie].map((e) => e[p]());
              return e;
            }
            get children() {
              return this[Ie];
            }
            clear() {
              this[Ie].length = 0;
            }
          }
          t.XFAObjectArray = XFAObjectArray;
          class XFAAttribute {
            constructor(e, t, a) {
              this[$e] = e;
              this[ne] = t;
              this[b] = a;
              this[m] = !1;
              this[ve] = "attribute" + He++;
            }
            [_]() {
              return this[$e];
            }
            [K]() {
              return !0;
            }
            [E]() {
              return this[b].trim();
            }
            [ye](e) {
              e = e.value || "";
              this[b] = e.toString();
            }
            [Se]() {
              return this[b];
            }
            [J](e) {
              return this[$e] === e || this[$e][J](e);
            }
          }
          t.XFAAttribute = XFAAttribute;
          class XmlObject extends XFAObject {
            constructor(e, t, a = {}) {
              super(e, t);
              this[b] = "";
              this[Pe] = null;
              if ("#text" !== t) {
                const e = new Map();
                this[Oe] = e;
                for (const [t, r] of Object.entries(a))
                  e.set(t, new XFAAttribute(this, t, r));
                if (a.hasOwnProperty(ie)) {
                  const e = a[ie].xfa.dataNode;
                  void 0 !== e &&
                    ("dataGroup" === e
                      ? (this[Pe] = !1)
                      : "dataValue" === e && (this[Pe] = !0));
                }
              }
              this[m] = !1;
            }
            [Ce](e) {
              const t = this[ne];
              if ("#text" === t) {
                e.push((0, i.encodeToXmlString)(this[b]));
                return;
              }
              const a = (0, n.utf8StringToString)(t),
                r = this[re] === qe ? "xfa:" : "";
              e.push(`<${r}${a}`);
              for (const [t, a] of this[Oe].entries()) {
                const r = (0, n.utf8StringToString)(t);
                e.push(` ${r}="${(0, i.encodeToXmlString)(a[b])}"`);
              }
              null !== this[Pe] &&
                (this[Pe]
                  ? e.push(' xfa:dataNode="dataValue"')
                  : e.push(' xfa:dataNode="dataGroup"'));
              if (this[b] || 0 !== this[Ie].length) {
                e.push(">");
                if (this[b])
                  "string" == typeof this[b]
                    ? e.push((0, i.encodeToXmlString)(this[b]))
                    : this[b][Ce](e);
                else for (const t of this[Ie]) t[Ce](e);
                e.push(`</${r}${a}>`);
              } else e.push("/>");
            }
            [se](e) {
              if (this[b]) {
                const e = new XmlObject(this[re], "#text");
                this[h](e);
                e[b] = this[b];
                this[b] = "";
              }
              this[h](e);
              return !0;
            }
            [ce](e) {
              this[b] += e;
            }
            [x]() {
              if (this[b] && this[Ie].length > 0) {
                const e = new XmlObject(this[re], "#text");
                this[h](e);
                e[b] = this[b];
                delete this[b];
              }
            }
            [Ae]() {
              return "#text" === this[ne]
                ? r.HTMLResult.success({ name: "#text", value: this[b] })
                : r.HTMLResult.EMPTY;
            }
            [R](e = null) {
              return e ? this[Ie].filter((t) => t[ne] === e) : this[Ie];
            }
            [v]() {
              return this[Oe];
            }
            [O](e) {
              const t = this[Oe].get(e);
              return void 0 !== t ? t : this[R](e);
            }
            *[M](e, t) {
              const a = this[Oe].get(e);
              a && (yield a);
              for (const a of this[Ie]) {
                a[ne] === e && (yield a);
                t && (yield* a[M](e, t));
              }
            }
            *[k](e, t) {
              const a = this[Oe].get(e);
              !a || (t && a[m]) || (yield a);
              for (const a of this[Ie]) yield* a[k](e, t);
            }
            *[N](e, t, a) {
              for (const r of this[Ie]) {
                r[ne] !== e || (a && r[m]) || (yield r);
                t && (yield* r[N](e, t, a));
              }
            }
            [K]() {
              return null === this[Pe]
                ? 0 === this[Ie].length ||
                    this[Ie][0][re] === s.NamespaceIds.xhtml.id
                : this[Pe];
            }
            [E]() {
              return null === this[Pe]
                ? 0 === this[Ie].length
                  ? this[b].trim()
                  : this[Ie][0][re] === s.NamespaceIds.xhtml.id
                  ? this[Ie][0][Se]().trim()
                  : null
                : this[b].trim();
            }
            [ye](e) {
              e = e.value || "";
              this[b] = e.toString();
            }
            [w](e = !1) {
              const t = Object.create(null);
              e && (t.$ns = this[re]);
              this[b] && (t.$content = this[b]);
              t.$name = this[ne];
              t.children = [];
              for (const a of this[Ie]) t.children.push(a[w](e));
              t.attributes = Object.create(null);
              for (const [e, a] of this[Oe]) t.attributes[e] = a[b];
              return t;
            }
          }
          t.XmlObject = XmlObject;
          class ContentObject extends XFAObject {
            constructor(e, t) {
              super(e, t);
              this[b] = "";
            }
            [ce](e) {
              this[b] += e;
            }
            [x]() {}
          }
          t.ContentObject = ContentObject;
          t.OptionObject = class OptionObject extends ContentObject {
            constructor(e, t, a) {
              super(e, t);
              this[je] = a;
            }
            [x]() {
              this[b] = (0, r.getKeyword)({
                data: this[b],
                defaultValue: this[je][0],
                validate: (e) => this[je].includes(e),
              });
            }
            [d](e) {
              super[d](e);
              delete this[je];
            }
          };
          t.StringObject = class StringObject extends ContentObject {
            [x]() {
              this[b] = this[b].trim();
            }
          };
          class IntegerObject extends ContentObject {
            constructor(e, t, a, r) {
              super(e, t);
              this[Ee] = a;
              this[Xe] = r;
            }
            [x]() {
              this[b] = (0, r.getInteger)({
                data: this[b],
                defaultValue: this[Ee],
                validate: this[Xe],
              });
            }
            [d](e) {
              super[d](e);
              delete this[Ee];
              delete this[Xe];
            }
          }
          t.IntegerObject = IntegerObject;
          t.Option01 = class Option01 extends IntegerObject {
            constructor(e, t) {
              super(e, t, 0, (e) => 1 === e);
            }
          };
          t.Option10 = class Option10 extends IntegerObject {
            constructor(e, t) {
              super(e, t, 1, (e) => 0 === e);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.HTMLResult = void 0;
          t.getBBox = function getBBox(e) {
            const t = -1;
            if (!e) return { x: t, y: t, width: t, height: t };
            const a = e
              .trim()
              .split(/\s*,\s*/)
              .map((e) => getMeasurement(e, "-1"));
            if (a.length < 4 || a[2] < 0 || a[3] < 0)
              return { x: t, y: t, width: t, height: t };
            const [r, n, i, s] = a;
            return { x: r, y: n, width: i, height: s };
          };
          t.getColor = function getColor(e, t = [0, 0, 0]) {
            let [a, r, n] = t;
            if (!e) return { r: a, g: r, b: n };
            const i = e
              .trim()
              .split(/\s*,\s*/)
              .map((e) => Math.min(Math.max(0, parseInt(e.trim(), 10)), 255))
              .map((e) => (isNaN(e) ? 0 : e));
            if (i.length < 3) return { r: a, g: r, b: n };
            [a, r, n] = i;
            return { r: a, g: r, b: n };
          };
          t.getFloat = function getFloat({
            data: e,
            defaultValue: t,
            validate: a,
          }) {
            if (!e) return t;
            e = e.trim();
            const r = parseFloat(e);
            if (!isNaN(r) && a(r)) return r;
            return t;
          };
          t.getInteger = function getInteger({
            data: e,
            defaultValue: t,
            validate: a,
          }) {
            if (!e) return t;
            e = e.trim();
            const r = parseInt(e, 10);
            if (!isNaN(r) && a(r)) return r;
            return t;
          };
          t.getKeyword = getKeyword;
          t.getMeasurement = getMeasurement;
          t.getRatio = function getRatio(e) {
            if (!e) return { num: 1, den: 1 };
            const t = e
              .trim()
              .split(/\s*:\s*/)
              .map((e) => parseFloat(e))
              .filter((e) => !isNaN(e));
            1 === t.length && t.push(1);
            if (0 === t.length) return { num: 1, den: 1 };
            const [a, r] = t;
            return { num: a, den: r };
          };
          t.getRelevant = function getRelevant(e) {
            if (!e) return [];
            return e
              .trim()
              .split(/\s+/)
              .map((e) => ({
                excluded: "-" === e[0],
                viewname: e.substring(1),
              }));
          };
          t.getStringOption = function getStringOption(e, t) {
            return getKeyword({
              data: e,
              defaultValue: t[0],
              validate: (e) => t.includes(e),
            });
          };
          t.stripQuotes = function stripQuotes(e) {
            if (e.startsWith("'") || e.startsWith('"'))
              return e.slice(1, e.length - 1);
            return e;
          };
          var r = a(2);
          const n = {
              pt: (e) => e,
              cm: (e) => (e / 2.54) * 72,
              mm: (e) => (e / 25.4) * 72,
              in: (e) => 72 * e,
              px: (e) => e,
            },
            i = /([+-]?\d+\.?\d*)(.*)/;
          function getKeyword({ data: e, defaultValue: t, validate: a }) {
            return e && a((e = e.trim())) ? e : t;
          }
          function getMeasurement(e, t = "0") {
            t = t || "0";
            if (!e) return getMeasurement(t);
            const a = e.trim().match(i);
            if (!a) return getMeasurement(t);
            const [, r, s] = a,
              o = parseFloat(r);
            if (isNaN(o)) return getMeasurement(t);
            if (0 === o) return 0;
            const c = n[s];
            return c ? c(o) : o;
          }
          class HTMLResult {
            static get FAILURE() {
              return (0, r.shadow)(
                this,
                "FAILURE",
                new HTMLResult(!1, null, null, null)
              );
            }
            static get EMPTY() {
              return (0, r.shadow)(
                this,
                "EMPTY",
                new HTMLResult(!0, null, null, null)
              );
            }
            constructor(e, t, a, r) {
              this.success = e;
              this.html = t;
              this.bbox = a;
              this.breakNode = r;
            }
            isBreak() {
              return !!this.breakNode;
            }
            static breakNode(e) {
              return new HTMLResult(!1, null, null, e);
            }
            static success(e, t = null) {
              return new HTMLResult(!0, e, t, null);
            }
          }
          t.HTMLResult = HTMLResult;
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.NamespaceIds = t.$buildXFAObject = void 0;
          const a = Symbol();
          t.$buildXFAObject = a;
          t.NamespaceIds = {
            config: {
              id: 0,
              check: (e) => e.startsWith("http://www.xfa.org/schema/xci/"),
            },
            connectionSet: {
              id: 1,
              check: (e) =>
                e.startsWith("http://www.xfa.org/schema/xfa-connection-set/"),
            },
            datasets: {
              id: 2,
              check: (e) => e.startsWith("http://www.xfa.org/schema/xfa-data/"),
            },
            form: {
              id: 3,
              check: (e) => e.startsWith("http://www.xfa.org/schema/xfa-form/"),
            },
            localeSet: {
              id: 4,
              check: (e) =>
                e.startsWith("http://www.xfa.org/schema/xfa-locale-set/"),
            },
            pdf: { id: 5, check: (e) => "http://ns.adobe.com/xdp/pdf/" === e },
            signature: {
              id: 6,
              check: (e) => "http://www.w3.org/2000/09/xmldsig#" === e,
            },
            sourceSet: {
              id: 7,
              check: (e) =>
                e.startsWith("http://www.xfa.org/schema/xfa-source-set/"),
            },
            stylesheet: {
              id: 8,
              check: (e) => "http://www.w3.org/1999/XSL/Transform" === e,
            },
            template: {
              id: 9,
              check: (e) =>
                e.startsWith("http://www.xfa.org/schema/xfa-template/"),
            },
            xdc: {
              id: 10,
              check: (e) => e.startsWith("http://www.xfa.org/schema/xdc/"),
            },
            xdp: { id: 11, check: (e) => "http://ns.adobe.com/xdp/" === e },
            xfdf: { id: 12, check: (e) => "http://ns.adobe.com/xfdf/" === e },
            xhtml: {
              id: 13,
              check: (e) => "http://www.w3.org/1999/xhtml" === e,
            },
            xmpmeta: {
              id: 14,
              check: (e) => "http://ns.adobe.com/xmpmeta/" === e,
            },
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.createDataNode = function createDataNode(e, t, a) {
            const n = parseExpression(a);
            if (!n) return null;
            if (n.some((e) => e.operator === c.dotDot)) return null;
            const s = l.get(n[0].name);
            let o = 0;
            if (s) {
              e = s(e, t);
              o = 1;
            } else e = t || e;
            for (let t = n.length; o < t; o++) {
              const { name: t, operator: a, index: s } = n[o];
              if (!isFinite(s)) {
                n[o].index = 0;
                return createNodes(e, n.slice(o));
              }
              let l;
              switch (a) {
                case c.dot:
                  l = e[r.$getChildrenByName](t, !1);
                  break;
                case c.dotDot:
                  l = e[r.$getChildrenByName](t, !0);
                  break;
                case c.dotHash:
                  l = e[r.$getChildrenByClass](t);
                  l = l instanceof r.XFAObjectArray ? l.children : [l];
              }
              if (0 === l.length) return createNodes(e, n.slice(o));
              if (!(s < l.length)) {
                n[o].index = s - l.length;
                return createNodes(e, n.slice(o));
              }
              {
                const t = l[s];
                if (!(t instanceof r.XFAObject)) {
                  (0, i.warn)("XFA - Cannot create a node.");
                  return null;
                }
                e = t;
              }
            }
            return null;
          };
          t.searchNode = function searchNode(e, t, a, n = !0, i = !0) {
            const s = parseExpression(a, n);
            if (!s) return null;
            const o = l.get(s[0].name);
            let u,
              d = 0;
            if (o) {
              u = !0;
              e = [o(e, t)];
              d = 1;
            } else {
              u = null === t;
              e = [t || e];
            }
            for (let a = s.length; d < a; d++) {
              const { name: a, cacheName: n, operator: o, index: l } = s[d],
                f = [];
              for (const t of e) {
                if (!(t instanceof r.XFAObject)) continue;
                let e, s;
                if (i) {
                  s = h.get(t);
                  if (!s) {
                    s = new Map();
                    h.set(t, s);
                  }
                  e = s.get(n);
                }
                if (!e) {
                  switch (o) {
                    case c.dot:
                      e = t[r.$getChildrenByName](a, !1);
                      break;
                    case c.dotDot:
                      e = t[r.$getChildrenByName](a, !0);
                      break;
                    case c.dotHash:
                      e = t[r.$getChildrenByClass](a);
                      e = e instanceof r.XFAObjectArray ? e.children : [e];
                  }
                  i && s.set(n, e);
                }
                e.length > 0 && f.push(e);
              }
              if (0 !== f.length || u || 0 !== d)
                e = isFinite(l)
                  ? f.filter((e) => l < e.length).map((e) => e[l])
                  : f.flat();
              else {
                const a = t[r.$getParent]();
                if (!(t = a)) return null;
                d = -1;
                e = [t];
              }
            }
            if (0 === e.length) return null;
            return e;
          };
          var r = a(75),
            n = a(77),
            i = a(2);
          const s = /^[^.[]+/,
            o = /^[^\]]+/,
            c = { dot: 0, dotDot: 1, dotHash: 2, dotBracket: 3, dotParen: 4 },
            l = new Map([
              ["$data", (e, t) => (e.datasets ? e.datasets.data : e)],
              [
                "$record",
                (e, t) =>
                  (e.datasets ? e.datasets.data : e)[r.$getChildren]()[0],
              ],
              ["$template", (e, t) => e.template],
              ["$connectionSet", (e, t) => e.connectionSet],
              ["$form", (e, t) => e.form],
              ["$layout", (e, t) => e.layout],
              ["$host", (e, t) => e.host],
              ["$dataWindow", (e, t) => e.dataWindow],
              ["$event", (e, t) => e.event],
              ["!", (e, t) => e.datasets],
              ["$xfa", (e, t) => e],
              ["xfa", (e, t) => e],
              ["$", (e, t) => t],
            ]),
            h = new WeakMap(),
            u = n.NamespaceIds.datasets.id;
          function parseExpression(e, t, a = !0) {
            let r = e.match(s);
            if (!r) return null;
            let [n] = r;
            const l = [
              {
                name: n,
                cacheName: "." + n,
                index: 0,
                js: null,
                formCalc: null,
                operator: c.dot,
              },
            ];
            let h = n.length;
            for (; h < e.length; ) {
              const d = h;
              if ("[" === e.charAt(h++)) {
                r = e.slice(h).match(o);
                if (!r) {
                  (0, i.warn)("XFA - Invalid index in SOM expression");
                  return null;
                }
                l.at(-1).index =
                  "*" === (u = (u = r[0]).trim())
                    ? 1 / 0
                    : parseInt(u, 10) || 0;
                h += r[0].length + 1;
                continue;
              }
              let f;
              switch (e.charAt(h)) {
                case ".":
                  if (!t) return null;
                  h++;
                  f = c.dotDot;
                  break;
                case "#":
                  h++;
                  f = c.dotHash;
                  break;
                case "[":
                  if (a) {
                    (0, i.warn)(
                      "XFA - SOM expression contains a FormCalc subexpression which is not supported for now."
                    );
                    return null;
                  }
                  f = c.dotBracket;
                  break;
                case "(":
                  if (a) {
                    (0, i.warn)(
                      "XFA - SOM expression contains a JavaScript subexpression which is not supported for now."
                    );
                    return null;
                  }
                  f = c.dotParen;
                  break;
                default:
                  f = c.dot;
              }
              r = e.slice(h).match(s);
              if (!r) break;
              [n] = r;
              h += n.length;
              l.push({
                name: n,
                cacheName: e.slice(d, h),
                operator: f,
                index: 0,
                js: null,
                formCalc: null,
              });
            }
            var u;
            return l;
          }
          function createNodes(e, t) {
            let a = null;
            for (const { name: n, index: i } of t) {
              for (let t = 0, s = isFinite(i) ? i : 0; t <= s; t++) {
                const t = e[r.$namespaceId] === u ? -1 : e[r.$namespaceId];
                a = new r.XmlObject(t, n);
                e[r.$appendChild](a);
              }
              e = a;
            }
            return a;
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Binder = void 0;
          var r = a(75),
            n = a(80),
            i = a(78),
            s = a(77),
            o = a(2);
          const c = s.NamespaceIds.datasets.id;
          function createText(e) {
            const t = new n.Text({});
            t[r.$content] = e;
            return t;
          }
          t.Binder = class Binder {
            constructor(e) {
              this.root = e;
              this.datasets = e.datasets;
              e.datasets && e.datasets.data
                ? (this.data = e.datasets.data)
                : (this.data = new r.XmlObject(
                    s.NamespaceIds.datasets.id,
                    "data"
                  ));
              this.emptyMerge = 0 === this.data[r.$getChildren]().length;
              this.root.form = this.form = e.template[r.$clone]();
            }
            _isConsumeData() {
              return !this.emptyMerge && this._mergeMode;
            }
            _isMatchTemplate() {
              return !this._isConsumeData();
            }
            bind() {
              this._bindElement(this.form, this.data);
              return this.form;
            }
            getData() {
              return this.data;
            }
            _bindValue(e, t, a) {
              e[r.$data] = t;
              if (e[r.$hasSettableValue]())
                if (t[r.$isDataValue]()) {
                  const a = t[r.$getDataValue]();
                  e[r.$setValue](createText(a));
                } else if (
                  e instanceof n.Field &&
                  e.ui &&
                  e.ui.choiceList &&
                  "multiSelect" === e.ui.choiceList.open
                ) {
                  const a = t[r.$getChildren]()
                    .map((e) => e[r.$content].trim())
                    .join("\n");
                  e[r.$setValue](createText(a));
                } else
                  this._isConsumeData() &&
                    (0, o.warn)("XFA - Nodes haven't the same type.");
              else
                !t[r.$isDataValue]() || this._isMatchTemplate()
                  ? this._bindElement(e, t)
                  : (0, o.warn)("XFA - Nodes haven't the same type.");
            }
            _findDataByNameToConsume(e, t, a, n) {
              if (!e) return null;
              let i, o;
              for (let n = 0; n < 3; n++) {
                i = a[r.$getRealChildrenByNameIt](e, !1, !0);
                for (;;) {
                  o = i.next().value;
                  if (!o) break;
                  if (t === o[r.$isDataValue]()) return o;
                }
                if (
                  a[r.$namespaceId] === s.NamespaceIds.datasets.id &&
                  "data" === a[r.$nodeName]
                )
                  break;
                a = a[r.$getParent]();
              }
              if (!n) return null;
              i = this.data[r.$getRealChildrenByNameIt](e, !0, !1);
              o = i.next().value;
              if (o) return o;
              i = this.data[r.$getAttributeIt](e, !0);
              o = i.next().value;
              return o && o[r.$isDataValue]() ? o : null;
            }
            _setProperties(e, t) {
              if (e.hasOwnProperty("setProperty"))
                for (const { ref: a, target: s, connection: c } of e.setProperty
                  .children) {
                  if (c) continue;
                  if (!a) continue;
                  const l = (0, i.searchNode)(this.root, t, a, !1, !1);
                  if (!l) {
                    (0, o.warn)(`XFA - Invalid reference: ${a}.`);
                    continue;
                  }
                  const [h] = l;
                  if (!h[r.$isDescendent](this.data)) {
                    (0, o.warn)("XFA - Invalid node: must be a data node.");
                    continue;
                  }
                  const u = (0, i.searchNode)(this.root, e, s, !1, !1);
                  if (!u) {
                    (0, o.warn)(`XFA - Invalid target: ${s}.`);
                    continue;
                  }
                  const [d] = u;
                  if (!d[r.$isDescendent](e)) {
                    (0, o.warn)(
                      "XFA - Invalid target: must be a property or subproperty."
                    );
                    continue;
                  }
                  const f = d[r.$getParent]();
                  if (
                    d instanceof n.SetProperty ||
                    f instanceof n.SetProperty
                  ) {
                    (0, o.warn)(
                      "XFA - Invalid target: cannot be a setProperty or one of its properties."
                    );
                    continue;
                  }
                  if (d instanceof n.BindItems || f instanceof n.BindItems) {
                    (0, o.warn)(
                      "XFA - Invalid target: cannot be a bindItems or one of its properties."
                    );
                    continue;
                  }
                  const g = h[r.$text](),
                    p = d[r.$nodeName];
                  if (d instanceof r.XFAAttribute) {
                    const e = Object.create(null);
                    e[p] = g;
                    const t = Reflect.construct(
                      Object.getPrototypeOf(f).constructor,
                      [e]
                    );
                    f[p] = t[p];
                  } else if (d.hasOwnProperty(r.$content)) {
                    d[r.$data] = h;
                    d[r.$content] = g;
                    d[r.$finalize]();
                  } else
                    (0, o.warn)("XFA - Invalid node to use in setProperty");
                }
            }
            _bindItems(e, t) {
              if (
                !e.hasOwnProperty("items") ||
                !e.hasOwnProperty("bindItems") ||
                e.bindItems.isEmpty()
              )
                return;
              for (const t of e.items.children) e[r.$removeChild](t);
              e.items.clear();
              const a = new n.Items({}),
                s = new n.Items({});
              e[r.$appendChild](a);
              e.items.push(a);
              e[r.$appendChild](s);
              e.items.push(s);
              for (const {
                ref: n,
                labelRef: c,
                valueRef: l,
                connection: h,
              } of e.bindItems.children) {
                if (h) continue;
                if (!n) continue;
                const e = (0, i.searchNode)(this.root, t, n, !1, !1);
                if (e)
                  for (const t of e) {
                    if (!t[r.$isDescendent](this.datasets)) {
                      (0, o.warn)(
                        `XFA - Invalid ref (${n}): must be a datasets child.`
                      );
                      continue;
                    }
                    const e = (0, i.searchNode)(this.root, t, c, !0, !1);
                    if (!e) {
                      (0, o.warn)(`XFA - Invalid label: ${c}.`);
                      continue;
                    }
                    const [h] = e;
                    if (!h[r.$isDescendent](this.datasets)) {
                      (0, o.warn)(
                        "XFA - Invalid label: must be a datasets child."
                      );
                      continue;
                    }
                    const u = (0, i.searchNode)(this.root, t, l, !0, !1);
                    if (!u) {
                      (0, o.warn)(`XFA - Invalid value: ${l}.`);
                      continue;
                    }
                    const [d] = u;
                    if (!d[r.$isDescendent](this.datasets)) {
                      (0, o.warn)(
                        "XFA - Invalid value: must be a datasets child."
                      );
                      continue;
                    }
                    const f = createText(h[r.$text]()),
                      g = createText(d[r.$text]());
                    a[r.$appendChild](f);
                    a.text.push(f);
                    s[r.$appendChild](g);
                    s.text.push(g);
                  }
                else (0, o.warn)(`XFA - Invalid reference: ${n}.`);
              }
            }
            _bindOccurrences(e, t, a) {
              let n;
              if (t.length > 1) {
                n = e[r.$clone]();
                n[r.$removeChild](n.occur);
                n.occur = null;
              }
              this._bindValue(e, t[0], a);
              this._setProperties(e, t[0]);
              this._bindItems(e, t[0]);
              if (1 === t.length) return;
              const i = e[r.$getParent](),
                s = e[r.$nodeName],
                o = i[r.$indexOf](e);
              for (let e = 1, c = t.length; e < c; e++) {
                const c = t[e],
                  l = n[r.$clone]();
                i[s].push(l);
                i[r.$insertAt](o + e, l);
                this._bindValue(l, c, a);
                this._setProperties(l, c);
                this._bindItems(l, c);
              }
            }
            _createOccurrences(e) {
              if (!this.emptyMerge) return;
              const { occur: t } = e;
              if (!t || t.initial <= 1) return;
              const a = e[r.$getParent](),
                n = e[r.$nodeName];
              if (!(a[n] instanceof r.XFAObjectArray)) return;
              let i;
              i = e.name
                ? a[n].children.filter((t) => t.name === e.name).length
                : a[n].children.length;
              const s = a[r.$indexOf](e) + 1,
                o = t.initial - i;
              if (o) {
                const t = e[r.$clone]();
                t[r.$removeChild](t.occur);
                t.occur = null;
                a[n].push(t);
                a[r.$insertAt](s, t);
                for (let e = 1; e < o; e++) {
                  const i = t[r.$clone]();
                  a[n].push(i);
                  a[r.$insertAt](s + e, i);
                }
              }
            }
            _getOccurInfo(e) {
              const { name: t, occur: a } = e;
              if (!a || !t) return [1, 1];
              const r = -1 === a.max ? 1 / 0 : a.max;
              return [a.min, r];
            }
            _setAndBind(e, t) {
              this._setProperties(e, t);
              this._bindItems(e, t);
              this._bindElement(e, t);
            }
            _bindElement(e, t) {
              const a = [];
              this._createOccurrences(e);
              for (const n of e[r.$getChildren]()) {
                if (n[r.$data]) continue;
                if (
                  void 0 === this._mergeMode &&
                  "subform" === n[r.$nodeName]
                ) {
                  this._mergeMode = "consumeData" === n.mergeMode;
                  const e = t[r.$getChildren]();
                  if (e.length > 0) this._bindOccurrences(n, [e[0]], null);
                  else if (this.emptyMerge) {
                    const e = t[r.$namespaceId] === c ? -1 : t[r.$namespaceId],
                      a = (n[r.$data] = new r.XmlObject(e, n.name || "root"));
                    t[r.$appendChild](a);
                    this._bindElement(n, a);
                  }
                  continue;
                }
                if (!n[r.$isBindable]()) continue;
                let e = !1,
                  s = null,
                  l = null,
                  h = null;
                if (n.bind) {
                  switch (n.bind.match) {
                    case "none":
                      this._setAndBind(n, t);
                      continue;
                    case "global":
                      e = !0;
                      break;
                    case "dataRef":
                      if (!n.bind.ref) {
                        (0, o.warn)(
                          `XFA - ref is empty in node ${n[r.$nodeName]}.`
                        );
                        this._setAndBind(n, t);
                        continue;
                      }
                      l = n.bind.ref;
                  }
                  n.bind.picture && (s = n.bind.picture[r.$content]);
                }
                const [u, d] = this._getOccurInfo(n);
                if (l) {
                  h = (0, i.searchNode)(this.root, t, l, !0, !1);
                  if (null === h) {
                    h = (0, i.createDataNode)(this.data, t, l);
                    if (!h) continue;
                    this._isConsumeData() && (h[r.$consumed] = !0);
                    this._setAndBind(n, h);
                    continue;
                  }
                  this._isConsumeData() &&
                    (h = h.filter((e) => !e[r.$consumed]));
                  h.length > d
                    ? (h = h.slice(0, d))
                    : 0 === h.length && (h = null);
                  h &&
                    this._isConsumeData() &&
                    h.forEach((e) => {
                      e[r.$consumed] = !0;
                    });
                } else {
                  if (!n.name) {
                    this._setAndBind(n, t);
                    continue;
                  }
                  if (this._isConsumeData()) {
                    const a = [];
                    for (; a.length < d; ) {
                      const i = this._findDataByNameToConsume(
                        n.name,
                        n[r.$hasSettableValue](),
                        t,
                        e
                      );
                      if (!i) break;
                      i[r.$consumed] = !0;
                      a.push(i);
                    }
                    h = a.length > 0 ? a : null;
                  } else {
                    h = t[r.$getRealChildrenByNameIt](
                      n.name,
                      !1,
                      this.emptyMerge
                    ).next().value;
                    if (!h) {
                      if (0 === u) {
                        a.push(n);
                        continue;
                      }
                      const e =
                        t[r.$namespaceId] === c ? -1 : t[r.$namespaceId];
                      h = n[r.$data] = new r.XmlObject(e, n.name);
                      this.emptyMerge && (h[r.$consumed] = !0);
                      t[r.$appendChild](h);
                      this._setAndBind(n, h);
                      continue;
                    }
                    this.emptyMerge && (h[r.$consumed] = !0);
                    h = [h];
                  }
                }
                h
                  ? this._bindOccurrences(n, h, s)
                  : u > 0
                  ? this._setAndBind(n, t)
                  : a.push(n);
              }
              a.forEach((e) => e[r.$getParent]()[r.$removeChild](e));
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Value =
            t.Text =
            t.TemplateNamespace =
            t.Template =
            t.SetProperty =
            t.Items =
            t.Field =
            t.BindItems =
              void 0;
          var r = a(75),
            n = a(77),
            i = a(81),
            s = a(82),
            o = a(76),
            c = a(2),
            l = a(83),
            h = a(3),
            u = a(78);
          const d = n.NamespaceIds.template.id,
            f = "http://www.w3.org/2000/svg",
            g = /^H(\d+)$/,
            p = new Set([
              "image/gif",
              "image/jpeg",
              "image/jpg",
              "image/pjpeg",
              "image/png",
              "image/apng",
              "image/x-png",
              "image/bmp",
              "image/x-ms-bmp",
              "image/tiff",
              "image/tif",
              "application/octet-stream",
            ]),
            m = [
              [[66, 77], "image/bmp"],
              [[255, 216, 255], "image/jpeg"],
              [[73, 73, 42, 0], "image/tiff"],
              [[77, 77, 0, 42], "image/tiff"],
              [[71, 73, 70, 56, 57, 97], "image/gif"],
              [[137, 80, 78, 71, 13, 10, 26, 10], "image/png"],
            ];
          function getBorderDims(e) {
            if (!e || !e.border) return { w: 0, h: 0 };
            const t = e.border[r.$getExtra]();
            return t
              ? {
                  w: t.widths[0] + t.widths[2] + t.insets[0] + t.insets[2],
                  h: t.widths[1] + t.widths[3] + t.insets[1] + t.insets[3],
                }
              : { w: 0, h: 0 };
          }
          function hasMargin(e) {
            return (
              e.margin &&
              (e.margin.topInset ||
                e.margin.rightInset ||
                e.margin.bottomInset ||
                e.margin.leftInset)
            );
          }
          function _setValue(e, t) {
            if (!e.value) {
              const t = new Value({});
              e[r.$appendChild](t);
              e.value = t;
            }
            e.value[r.$setValue](t);
          }
          function* getContainedChildren(e) {
            for (const t of e[r.$getChildren]())
              t instanceof SubformSet
                ? yield* t[r.$getContainedChildren]()
                : yield t;
          }
          function isRequired(e) {
            return e.validate && "error" === e.validate.nullTest;
          }
          function setTabIndex(e) {
            for (; e; ) {
              if (!e.traversal) {
                e[r.$tabIndex] = e[r.$getParent]()[r.$tabIndex];
                return;
              }
              if (e[r.$tabIndex]) return;
              let t = null;
              for (const a of e.traversal[r.$getChildren]())
                if ("next" === a.operation) {
                  t = a;
                  break;
                }
              if (!t || !t.ref) {
                e[r.$tabIndex] = e[r.$getParent]()[r.$tabIndex];
                return;
              }
              const a = e[r.$getTemplateRoot]();
              e[r.$tabIndex] = ++a[r.$tabIndex];
              const n = a[r.$searchNode](t.ref, e);
              if (!n) return;
              e = n[0];
            }
          }
          function applyAssist(e, t) {
            const a = e.assist;
            if (a) {
              const e = a[r.$toHTML]();
              e && (t.title = e);
              const n = a.role.match(g);
              if (n) {
                const e = "heading",
                  a = n[1];
                t.role = e;
                t["aria-level"] = a;
              }
            }
            if ("table" === e.layout) t.role = "table";
            else if ("row" === e.layout) t.role = "row";
            else {
              const a = e[r.$getParent]();
              "row" === a.layout &&
                (a.assist && "TH" === a.assist.role
                  ? (t.role = "columnheader")
                  : (t.role = "cell"));
            }
          }
          function ariaLabel(e) {
            if (!e.assist) return null;
            const t = e.assist;
            return t.speak && "" !== t.speak[r.$content]
              ? t.speak[r.$content]
              : t.toolTip
              ? t.toolTip[r.$content]
              : null;
          }
          function valueToHtml(e) {
            return o.HTMLResult.success({
              name: "div",
              attributes: { class: ["xfaRich"], style: Object.create(null) },
              children: [
                {
                  name: "span",
                  attributes: { style: Object.create(null) },
                  value: e,
                },
              ],
            });
          }
          function setFirstUnsplittable(e) {
            const t = e[r.$getTemplateRoot]();
            if (null === t[r.$extra].firstUnsplittable) {
              t[r.$extra].firstUnsplittable = e;
              t[r.$extra].noLayoutFailure = !0;
            }
          }
          function unsetFirstUnsplittable(e) {
            const t = e[r.$getTemplateRoot]();
            t[r.$extra].firstUnsplittable === e &&
              (t[r.$extra].noLayoutFailure = !1);
          }
          function handleBreak(e) {
            if (e[r.$extra]) return !1;
            e[r.$extra] = Object.create(null);
            if ("auto" === e.targetType) return !1;
            const t = e[r.$getTemplateRoot]();
            let a = null;
            if (e.target) {
              a = t[r.$searchNode](e.target, e[r.$getParent]());
              if (!a) return !1;
              a = a[0];
            }
            const { currentPageArea: n, currentContentArea: i } = t[r.$extra];
            if ("pageArea" === e.targetType) {
              a instanceof PageArea || (a = null);
              if (e.startNew) {
                e[r.$extra].target = a || n;
                return !0;
              }
              if (a && a !== n) {
                e[r.$extra].target = a;
                return !0;
              }
              return !1;
            }
            a instanceof ContentArea || (a = null);
            const s = a && a[r.$getParent]();
            let o,
              c = s;
            if (e.startNew)
              if (a) {
                const e = s.contentArea.children,
                  t = e.indexOf(i),
                  r = e.indexOf(a);
                -1 !== t && t < r && (c = null);
                o = r - 1;
              } else o = n.contentArea.children.indexOf(i);
            else {
              if (!a || a === i) return !1;
              o = s.contentArea.children.indexOf(a) - 1;
              c = s === n ? null : s;
            }
            e[r.$extra].target = c;
            e[r.$extra].index = o;
            return !0;
          }
          function handleOverflow(e, t, a) {
            const n = e[r.$getTemplateRoot](),
              i = n[r.$extra].noLayoutFailure,
              s = t[r.$getSubformParent];
            t[r.$getSubformParent] = () => e;
            n[r.$extra].noLayoutFailure = !0;
            const o = t[r.$toHTML](a);
            e[r.$addHTML](o.html, o.bbox);
            n[r.$extra].noLayoutFailure = i;
            t[r.$getSubformParent] = s;
          }
          class AppearanceFilter extends r.StringObject {
            constructor(e) {
              super(d, "appearanceFilter");
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Arc extends r.XFAObject {
            constructor(e) {
              super(d, "arc", !0);
              this.circular = (0, o.getInteger)({
                data: e.circular,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.hand = (0, o.getStringOption)(e.hand, [
                "even",
                "left",
                "right",
              ]);
              this.id = e.id || "";
              this.startAngle = (0, o.getFloat)({
                data: e.startAngle,
                defaultValue: 0,
                validate: (e) => !0,
              });
              this.sweepAngle = (0, o.getFloat)({
                data: e.sweepAngle,
                defaultValue: 360,
                validate: (e) => !0,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.edge = null;
              this.fill = null;
            }
            [r.$toHTML]() {
              const e = this.edge || new Edge({}),
                t = e[r.$toStyle](),
                a = Object.create(null);
              this.fill && "visible" === this.fill.presence
                ? Object.assign(a, this.fill[r.$toStyle]())
                : (a.fill = "transparent");
              a.strokeWidth = (0, s.measureToString)(
                "visible" === e.presence ? e.thickness : 0
              );
              a.stroke = t.color;
              let n;
              const i = {
                xmlns: f,
                style: { width: "100%", height: "100%", overflow: "visible" },
              };
              if (360 === this.sweepAngle)
                n = {
                  name: "ellipse",
                  attributes: {
                    xmlns: f,
                    cx: "50%",
                    cy: "50%",
                    rx: "50%",
                    ry: "50%",
                    style: a,
                  },
                };
              else {
                const e = (this.startAngle * Math.PI) / 180,
                  t = (this.sweepAngle * Math.PI) / 180,
                  r = this.sweepAngle > 180 ? 1 : 0,
                  [s, o, c, l] = [
                    50 * (1 + Math.cos(e)),
                    50 * (1 - Math.sin(e)),
                    50 * (1 + Math.cos(e + t)),
                    50 * (1 - Math.sin(e + t)),
                  ];
                n = {
                  name: "path",
                  attributes: {
                    xmlns: f,
                    d: `M ${s} ${o} A 50 50 0 ${r} 0 ${c} ${l}`,
                    vectorEffect: "non-scaling-stroke",
                    style: a,
                  },
                };
                Object.assign(i, {
                  viewBox: "0 0 100 100",
                  preserveAspectRatio: "none",
                });
              }
              const c = { name: "svg", children: [n], attributes: i };
              if (hasMargin(this[r.$getParent]()[r.$getParent]()))
                return o.HTMLResult.success({
                  name: "div",
                  attributes: {
                    style: { display: "inline", width: "100%", height: "100%" },
                  },
                  children: [c],
                });
              c.attributes.style.position = "absolute";
              return o.HTMLResult.success(c);
            }
          }
          class Area extends r.XFAObject {
            constructor(e) {
              super(d, "area", !0);
              this.colSpan = (0, o.getInteger)({
                data: e.colSpan,
                defaultValue: 1,
                validate: (e) => e >= 1 || -1 === e,
              });
              this.id = e.id || "";
              this.name = e.name || "";
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.x = (0, o.getMeasurement)(e.x, "0pt");
              this.y = (0, o.getMeasurement)(e.y, "0pt");
              this.desc = null;
              this.extras = null;
              this.area = new r.XFAObjectArray();
              this.draw = new r.XFAObjectArray();
              this.exObject = new r.XFAObjectArray();
              this.exclGroup = new r.XFAObjectArray();
              this.field = new r.XFAObjectArray();
              this.subform = new r.XFAObjectArray();
              this.subformSet = new r.XFAObjectArray();
            }
            *[r.$getContainedChildren]() {
              yield* getContainedChildren(this);
            }
            [r.$isTransparent]() {
              return !0;
            }
            [r.$isBindable]() {
              return !0;
            }
            [r.$addHTML](e, t) {
              const [a, n, i, s] = t;
              this[r.$extra].width = Math.max(this[r.$extra].width, a + i);
              this[r.$extra].height = Math.max(this[r.$extra].height, n + s);
              this[r.$extra].children.push(e);
            }
            [r.$getAvailableSpace]() {
              return this[r.$extra].availableSpace;
            }
            [r.$toHTML](e) {
              const t = (0, s.toStyle)(this, "position"),
                a = { style: t, id: this[r.$uid], class: ["xfaArea"] };
              (0, s.isPrintOnly)(this) && a.class.push("xfaPrintOnly");
              this.name && (a.xfaName = this.name);
              const n = [];
              this[r.$extra] = {
                children: n,
                width: 0,
                height: 0,
                availableSpace: e,
              };
              const i = this[r.$childrenToHTML]({
                filter: new Set([
                  "area",
                  "draw",
                  "field",
                  "exclGroup",
                  "subform",
                  "subformSet",
                ]),
                include: !0,
              });
              if (!i.success) {
                if (i.isBreak()) return i;
                delete this[r.$extra];
                return o.HTMLResult.FAILURE;
              }
              t.width = (0, s.measureToString)(this[r.$extra].width);
              t.height = (0, s.measureToString)(this[r.$extra].height);
              const c = { name: "div", attributes: a, children: n },
                l = [
                  this.x,
                  this.y,
                  this[r.$extra].width,
                  this[r.$extra].height,
                ];
              delete this[r.$extra];
              return o.HTMLResult.success(c, l);
            }
          }
          class Assist extends r.XFAObject {
            constructor(e) {
              super(d, "assist", !0);
              this.id = e.id || "";
              this.role = e.role || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.speak = null;
              this.toolTip = null;
            }
            [r.$toHTML]() {
              return this.toolTip && this.toolTip[r.$content]
                ? this.toolTip[r.$content]
                : null;
            }
          }
          class Barcode extends r.XFAObject {
            constructor(e) {
              super(d, "barcode", !0);
              this.charEncoding = (0, o.getKeyword)({
                data: e.charEncoding ? e.charEncoding.toLowerCase() : "",
                defaultValue: "",
                validate: (e) =>
                  [
                    "utf-8",
                    "big-five",
                    "fontspecific",
                    "gbk",
                    "gb-18030",
                    "gb-2312",
                    "ksc-5601",
                    "none",
                    "shift-jis",
                    "ucs-2",
                    "utf-16",
                  ].includes(e) || e.match(/iso-8859-\d{2}/),
              });
              this.checksum = (0, o.getStringOption)(e.checksum, [
                "none",
                "1mod10",
                "1mod10_1mod11",
                "2mod10",
                "auto",
              ]);
              this.dataColumnCount = (0, o.getInteger)({
                data: e.dataColumnCount,
                defaultValue: -1,
                validate: (e) => e >= 0,
              });
              this.dataLength = (0, o.getInteger)({
                data: e.dataLength,
                defaultValue: -1,
                validate: (e) => e >= 0,
              });
              this.dataPrep = (0, o.getStringOption)(e.dataPrep, [
                "none",
                "flateCompress",
              ]);
              this.dataRowCount = (0, o.getInteger)({
                data: e.dataRowCount,
                defaultValue: -1,
                validate: (e) => e >= 0,
              });
              this.endChar = e.endChar || "";
              this.errorCorrectionLevel = (0, o.getInteger)({
                data: e.errorCorrectionLevel,
                defaultValue: -1,
                validate: (e) => e >= 0 && e <= 8,
              });
              this.id = e.id || "";
              this.moduleHeight = (0, o.getMeasurement)(e.moduleHeight, "5mm");
              this.moduleWidth = (0, o.getMeasurement)(e.moduleWidth, "0.25mm");
              this.printCheckDigit = (0, o.getInteger)({
                data: e.printCheckDigit,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.rowColumnRatio = (0, o.getRatio)(e.rowColumnRatio);
              this.startChar = e.startChar || "";
              this.textLocation = (0, o.getStringOption)(e.textLocation, [
                "below",
                "above",
                "aboveEmbedded",
                "belowEmbedded",
                "none",
              ]);
              this.truncate = (0, o.getInteger)({
                data: e.truncate,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.type = (0, o.getStringOption)(
                e.type ? e.type.toLowerCase() : "",
                [
                  "aztec",
                  "codabar",
                  "code2of5industrial",
                  "code2of5interleaved",
                  "code2of5matrix",
                  "code2of5standard",
                  "code3of9",
                  "code3of9extended",
                  "code11",
                  "code49",
                  "code93",
                  "code128",
                  "code128a",
                  "code128b",
                  "code128c",
                  "code128sscc",
                  "datamatrix",
                  "ean8",
                  "ean8add2",
                  "ean8add5",
                  "ean13",
                  "ean13add2",
                  "ean13add5",
                  "ean13pwcd",
                  "fim",
                  "logmars",
                  "maxicode",
                  "msi",
                  "pdf417",
                  "pdf417macro",
                  "plessey",
                  "postauscust2",
                  "postauscust3",
                  "postausreplypaid",
                  "postausstandard",
                  "postukrm4scc",
                  "postusdpbc",
                  "postusimb",
                  "postusstandard",
                  "postus5zip",
                  "qrcode",
                  "rfid",
                  "rss14",
                  "rss14expanded",
                  "rss14limited",
                  "rss14stacked",
                  "rss14stackedomni",
                  "rss14truncated",
                  "telepen",
                  "ucc128",
                  "ucc128random",
                  "ucc128sscc",
                  "upca",
                  "upcaadd2",
                  "upcaadd5",
                  "upcapwcd",
                  "upce",
                  "upceadd2",
                  "upceadd5",
                  "upcean2",
                  "upcean5",
                  "upsmaxicode",
                ]
              );
              this.upsMode = (0, o.getStringOption)(e.upsMode, [
                "usCarrier",
                "internationalCarrier",
                "secureSymbol",
                "standardSymbol",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.wideNarrowRatio = (0, o.getRatio)(e.wideNarrowRatio);
              this.encrypt = null;
              this.extras = null;
            }
          }
          class Bind extends r.XFAObject {
            constructor(e) {
              super(d, "bind", !0);
              this.match = (0, o.getStringOption)(e.match, [
                "once",
                "dataRef",
                "global",
                "none",
              ]);
              this.ref = e.ref || "";
              this.picture = null;
            }
          }
          class BindItems extends r.XFAObject {
            constructor(e) {
              super(d, "bindItems");
              this.connection = e.connection || "";
              this.labelRef = e.labelRef || "";
              this.ref = e.ref || "";
              this.valueRef = e.valueRef || "";
            }
          }
          t.BindItems = BindItems;
          class Bookend extends r.XFAObject {
            constructor(e) {
              super(d, "bookend");
              this.id = e.id || "";
              this.leader = e.leader || "";
              this.trailer = e.trailer || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class BooleanElement extends r.Option01 {
            constructor(e) {
              super(d, "boolean");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$toHTML](e) {
              return valueToHtml(1 === this[r.$content] ? "1" : "0");
            }
          }
          class Border extends r.XFAObject {
            constructor(e) {
              super(d, "border", !0);
              this.break = (0, o.getStringOption)(e.break, ["close", "open"]);
              this.hand = (0, o.getStringOption)(e.hand, [
                "even",
                "left",
                "right",
              ]);
              this.id = e.id || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.corner = new r.XFAObjectArray(4);
              this.edge = new r.XFAObjectArray(4);
              this.extras = null;
              this.fill = null;
              this.margin = null;
            }
            [r.$getExtra]() {
              if (!this[r.$extra]) {
                const e = this.edge.children.slice();
                if (e.length < 4) {
                  const t = e.at(-1) || new Edge({});
                  for (let a = e.length; a < 4; a++) e.push(t);
                }
                const t = e.map((e) => e.thickness),
                  a = [0, 0, 0, 0];
                if (this.margin) {
                  a[0] = this.margin.topInset;
                  a[1] = this.margin.rightInset;
                  a[2] = this.margin.bottomInset;
                  a[3] = this.margin.leftInset;
                }
                this[r.$extra] = { widths: t, insets: a, edges: e };
              }
              return this[r.$extra];
            }
            [r.$toStyle]() {
              const { edges: e } = this[r.$getExtra](),
                t = e.map((e) => {
                  const t = e[r.$toStyle]();
                  t.color = t.color || "#000000";
                  return t;
                }),
                a = Object.create(null);
              this.margin && Object.assign(a, this.margin[r.$toStyle]());
              this.fill &&
                "visible" === this.fill.presence &&
                Object.assign(a, this.fill[r.$toStyle]());
              if (this.corner.children.some((e) => 0 !== e.radius)) {
                const e = this.corner.children.map((e) => e[r.$toStyle]());
                if (2 === e.length || 3 === e.length) {
                  const t = e.at(-1);
                  for (let a = e.length; a < 4; a++) e.push(t);
                }
                a.borderRadius = e.map((e) => e.radius).join(" ");
              }
              switch (this.presence) {
                case "invisible":
                case "hidden":
                  a.borderStyle = "";
                  break;
                case "inactive":
                  a.borderStyle = "none";
                  break;
                default:
                  a.borderStyle = t.map((e) => e.style).join(" ");
              }
              a.borderWidth = t.map((e) => e.width).join(" ");
              a.borderColor = t.map((e) => e.color).join(" ");
              return a;
            }
          }
          class Break extends r.XFAObject {
            constructor(e) {
              super(d, "break", !0);
              this.after = (0, o.getStringOption)(e.after, [
                "auto",
                "contentArea",
                "pageArea",
                "pageEven",
                "pageOdd",
              ]);
              this.afterTarget = e.afterTarget || "";
              this.before = (0, o.getStringOption)(e.before, [
                "auto",
                "contentArea",
                "pageArea",
                "pageEven",
                "pageOdd",
              ]);
              this.beforeTarget = e.beforeTarget || "";
              this.bookendLeader = e.bookendLeader || "";
              this.bookendTrailer = e.bookendTrailer || "";
              this.id = e.id || "";
              this.overflowLeader = e.overflowLeader || "";
              this.overflowTarget = e.overflowTarget || "";
              this.overflowTrailer = e.overflowTrailer || "";
              this.startNew = (0, o.getInteger)({
                data: e.startNew,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
          }
          class BreakAfter extends r.XFAObject {
            constructor(e) {
              super(d, "breakAfter", !0);
              this.id = e.id || "";
              this.leader = e.leader || "";
              this.startNew = (0, o.getInteger)({
                data: e.startNew,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.target = e.target || "";
              this.targetType = (0, o.getStringOption)(e.targetType, [
                "auto",
                "contentArea",
                "pageArea",
              ]);
              this.trailer = e.trailer || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.script = null;
            }
          }
          class BreakBefore extends r.XFAObject {
            constructor(e) {
              super(d, "breakBefore", !0);
              this.id = e.id || "";
              this.leader = e.leader || "";
              this.startNew = (0, o.getInteger)({
                data: e.startNew,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.target = e.target || "";
              this.targetType = (0, o.getStringOption)(e.targetType, [
                "auto",
                "contentArea",
                "pageArea",
              ]);
              this.trailer = e.trailer || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.script = null;
            }
            [r.$toHTML](e) {
              this[r.$extra] = {};
              return o.HTMLResult.FAILURE;
            }
          }
          class Button extends r.XFAObject {
            constructor(e) {
              super(d, "button", !0);
              this.highlight = (0, o.getStringOption)(e.highlight, [
                "inverted",
                "none",
                "outline",
                "push",
              ]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
            [r.$toHTML](e) {
              const t = this[r.$getParent]()[r.$getParent](),
                a = {
                  name: "button",
                  attributes: {
                    id: this[r.$uid],
                    class: ["xfaButton"],
                    style: {},
                  },
                  children: [],
                };
              for (const e of t.event.children) {
                if ("click" !== e.activity || !e.script) continue;
                const t = (0, h.recoverJsURL)(e.script[r.$content]);
                if (!t) continue;
                const n = (0, s.fixURL)(t.url);
                n &&
                  a.children.push({
                    name: "a",
                    attributes: {
                      id: "link" + this[r.$uid],
                      href: n,
                      newWindow: t.newWindow,
                      class: ["xfaLink"],
                      style: {},
                    },
                    children: [],
                  });
              }
              return o.HTMLResult.success(a);
            }
          }
          class Calculate extends r.XFAObject {
            constructor(e) {
              super(d, "calculate", !0);
              this.id = e.id || "";
              this.override = (0, o.getStringOption)(e.override, [
                "disabled",
                "error",
                "ignore",
                "warning",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.message = null;
              this.script = null;
            }
          }
          class Caption extends r.XFAObject {
            constructor(e) {
              super(d, "caption", !0);
              this.id = e.id || "";
              this.placement = (0, o.getStringOption)(e.placement, [
                "left",
                "bottom",
                "inline",
                "right",
                "top",
              ]);
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.reserve = Math.ceil((0, o.getMeasurement)(e.reserve));
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.font = null;
              this.margin = null;
              this.para = null;
              this.value = null;
            }
            [r.$setValue](e) {
              _setValue(this, e);
            }
            [r.$getExtra](e) {
              if (!this[r.$extra]) {
                let { width: t, height: a } = e;
                switch (this.placement) {
                  case "left":
                  case "right":
                  case "inline":
                    t = this.reserve <= 0 ? t : this.reserve;
                    break;
                  case "top":
                  case "bottom":
                    a = this.reserve <= 0 ? a : this.reserve;
                }
                this[r.$extra] = (0, s.layoutNode)(this, {
                  width: t,
                  height: a,
                });
              }
              return this[r.$extra];
            }
            [r.$toHTML](e) {
              if (!this.value) return o.HTMLResult.EMPTY;
              this[r.$pushPara]();
              const t = this.value[r.$toHTML](e).html;
              if (!t) {
                this[r.$popPara]();
                return o.HTMLResult.EMPTY;
              }
              const a = this.reserve;
              if (this.reserve <= 0) {
                const { w: t, h: a } = this[r.$getExtra](e);
                switch (this.placement) {
                  case "left":
                  case "right":
                  case "inline":
                    this.reserve = t;
                    break;
                  case "top":
                  case "bottom":
                    this.reserve = a;
                }
              }
              const n = [];
              "string" == typeof t
                ? n.push({ name: "#text", value: t })
                : n.push(t);
              const i = (0, s.toStyle)(this, "font", "margin", "visibility");
              switch (this.placement) {
                case "left":
                case "right":
                  this.reserve > 0 &&
                    (i.width = (0, s.measureToString)(this.reserve));
                  break;
                case "top":
                case "bottom":
                  this.reserve > 0 &&
                    (i.height = (0, s.measureToString)(this.reserve));
              }
              (0, s.setPara)(this, null, t);
              this[r.$popPara]();
              this.reserve = a;
              return o.HTMLResult.success({
                name: "div",
                attributes: { style: i, class: ["xfaCaption"] },
                children: n,
              });
            }
          }
          class Certificate extends r.StringObject {
            constructor(e) {
              super(d, "certificate");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Certificates extends r.XFAObject {
            constructor(e) {
              super(d, "certificates", !0);
              this.credentialServerPolicy = (0, o.getStringOption)(
                e.credentialServerPolicy,
                ["optional", "required"]
              );
              this.id = e.id || "";
              this.url = e.url || "";
              this.urlPolicy = e.urlPolicy || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.encryption = null;
              this.issuers = null;
              this.keyUsage = null;
              this.oids = null;
              this.signing = null;
              this.subjectDNs = null;
            }
          }
          class CheckButton extends r.XFAObject {
            constructor(e) {
              super(d, "checkButton", !0);
              this.id = e.id || "";
              this.mark = (0, o.getStringOption)(e.mark, [
                "default",
                "check",
                "circle",
                "cross",
                "diamond",
                "square",
                "star",
              ]);
              this.shape = (0, o.getStringOption)(e.shape, ["square", "round"]);
              this.size = (0, o.getMeasurement)(e.size, "10pt");
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.extras = null;
              this.margin = null;
            }
            [r.$toHTML](e) {
              const t = (0, s.toStyle)("margin"),
                a = (0, s.measureToString)(this.size);
              t.width = t.height = a;
              let n, i, c;
              const l = this[r.$getParent]()[r.$getParent](),
                h =
                  (l.items.children.length &&
                    l.items.children[0][r.$toHTML]().html) ||
                  [],
                u = {
                  on: (void 0 !== h[0] ? h[0] : "on").toString(),
                  off: (void 0 !== h[1] ? h[1] : "off").toString(),
                },
                d =
                  ((l.value && l.value[r.$text]()) || "off") === u.on || void 0,
                f = l[r.$getSubformParent](),
                g = l[r.$uid];
              let p;
              if (f instanceof ExclGroup) {
                c = f[r.$uid];
                n = "radio";
                i = "xfaRadio";
                p = (f[r.$data] && f[r.$data][r.$uid]) || f[r.$uid];
              } else {
                n = "checkbox";
                i = "xfaCheckbox";
                p = (l[r.$data] && l[r.$data][r.$uid]) || l[r.$uid];
              }
              const m = {
                name: "input",
                attributes: {
                  class: [i],
                  style: t,
                  fieldId: g,
                  dataId: p,
                  type: n,
                  checked: d,
                  xfaOn: u.on,
                  xfaOff: u.off,
                  "aria-label": ariaLabel(l),
                  "aria-required": !1,
                },
              };
              c && (m.attributes.name = c);
              if (isRequired(l)) {
                m.attributes["aria-required"] = !0;
                m.attributes.required = !0;
              }
              return o.HTMLResult.success({
                name: "label",
                attributes: { class: ["xfaLabel"] },
                children: [m],
              });
            }
          }
          class ChoiceList extends r.XFAObject {
            constructor(e) {
              super(d, "choiceList", !0);
              this.commitOn = (0, o.getStringOption)(e.commitOn, [
                "select",
                "exit",
              ]);
              this.id = e.id || "";
              this.open = (0, o.getStringOption)(e.open, [
                "userControl",
                "always",
                "multiSelect",
                "onEntry",
              ]);
              this.textEntry = (0, o.getInteger)({
                data: e.textEntry,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.extras = null;
              this.margin = null;
            }
            [r.$toHTML](e) {
              const t = (0, s.toStyle)(this, "border", "margin"),
                a = this[r.$getParent]()[r.$getParent](),
                n = {
                  fontSize: `calc(${
                    (a.font && a.font.size) || 10
                  }px * var(--scale-factor))`,
                },
                i = [];
              if (a.items.children.length > 0) {
                const e = a.items;
                let t = 0,
                  s = 0;
                if (2 === e.children.length) {
                  t = e.children[0].save;
                  s = 1 - t;
                }
                const o = e.children[t][r.$toHTML]().html,
                  c = e.children[s][r.$toHTML]().html;
                let l = !1;
                const h = (a.value && a.value[r.$text]()) || "";
                for (let e = 0, t = o.length; e < t; e++) {
                  const t = {
                    name: "option",
                    attributes: { value: c[e] || o[e], style: n },
                    value: o[e],
                  };
                  c[e] === h && (t.attributes.selected = l = !0);
                  i.push(t);
                }
                l ||
                  i.splice(0, 0, {
                    name: "option",
                    attributes: { hidden: !0, selected: !0 },
                    value: " ",
                  });
              }
              const c = {
                class: ["xfaSelect"],
                fieldId: a[r.$uid],
                dataId: (a[r.$data] && a[r.$data][r.$uid]) || a[r.$uid],
                style: t,
                "aria-label": ariaLabel(a),
                "aria-required": !1,
              };
              if (isRequired(a)) {
                c["aria-required"] = !0;
                c.required = !0;
              }
              "multiSelect" === this.open && (c.multiple = !0);
              return o.HTMLResult.success({
                name: "label",
                attributes: { class: ["xfaLabel"] },
                children: [{ name: "select", children: i, attributes: c }],
              });
            }
          }
          class Color extends r.XFAObject {
            constructor(e) {
              super(d, "color", !0);
              this.cSpace = (0, o.getStringOption)(e.cSpace, ["SRGB"]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.value = e.value ? (0, o.getColor)(e.value) : "";
              this.extras = null;
            }
            [r.$hasSettableValue]() {
              return !1;
            }
            [r.$toStyle]() {
              return this.value
                ? c.Util.makeHexColor(this.value.r, this.value.g, this.value.b)
                : null;
            }
          }
          class Comb extends r.XFAObject {
            constructor(e) {
              super(d, "comb");
              this.id = e.id || "";
              this.numberOfCells = (0, o.getInteger)({
                data: e.numberOfCells,
                defaultValue: 0,
                validate: (e) => e >= 0,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Connect extends r.XFAObject {
            constructor(e) {
              super(d, "connect", !0);
              this.connection = e.connection || "";
              this.id = e.id || "";
              this.ref = e.ref || "";
              this.usage = (0, o.getStringOption)(e.usage, [
                "exportAndImport",
                "exportOnly",
                "importOnly",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.picture = null;
            }
          }
          class ContentArea extends r.XFAObject {
            constructor(e) {
              super(d, "contentArea", !0);
              this.h = (0, o.getMeasurement)(e.h);
              this.id = e.id || "";
              this.name = e.name || "";
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.w = (0, o.getMeasurement)(e.w);
              this.x = (0, o.getMeasurement)(e.x, "0pt");
              this.y = (0, o.getMeasurement)(e.y, "0pt");
              this.desc = null;
              this.extras = null;
            }
            [r.$toHTML](e) {
              const t = {
                  left: (0, s.measureToString)(this.x),
                  top: (0, s.measureToString)(this.y),
                  width: (0, s.measureToString)(this.w),
                  height: (0, s.measureToString)(this.h),
                },
                a = ["xfaContentarea"];
              (0, s.isPrintOnly)(this) && a.push("xfaPrintOnly");
              return o.HTMLResult.success({
                name: "div",
                children: [],
                attributes: { style: t, class: a, id: this[r.$uid] },
              });
            }
          }
          class Corner extends r.XFAObject {
            constructor(e) {
              super(d, "corner", !0);
              this.id = e.id || "";
              this.inverted = (0, o.getInteger)({
                data: e.inverted,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.join = (0, o.getStringOption)(e.join, ["square", "round"]);
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.radius = (0, o.getMeasurement)(e.radius);
              this.stroke = (0, o.getStringOption)(e.stroke, [
                "solid",
                "dashDot",
                "dashDotDot",
                "dashed",
                "dotted",
                "embossed",
                "etched",
                "lowered",
                "raised",
              ]);
              this.thickness = (0, o.getMeasurement)(e.thickness, "0.5pt");
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
            }
            [r.$toStyle]() {
              const e = (0, s.toStyle)(this, "visibility");
              e.radius = (0, s.measureToString)(
                "square" === this.join ? 0 : this.radius
              );
              return e;
            }
          }
          class DateElement extends r.ContentObject {
            constructor(e) {
              super(d, "date");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              const e = this[r.$content].trim();
              this[r.$content] = e ? new Date(e) : null;
            }
            [r.$toHTML](e) {
              return valueToHtml(
                this[r.$content] ? this[r.$content].toString() : ""
              );
            }
          }
          class DateTime extends r.ContentObject {
            constructor(e) {
              super(d, "dateTime");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              const e = this[r.$content].trim();
              this[r.$content] = e ? new Date(e) : null;
            }
            [r.$toHTML](e) {
              return valueToHtml(
                this[r.$content] ? this[r.$content].toString() : ""
              );
            }
          }
          class DateTimeEdit extends r.XFAObject {
            constructor(e) {
              super(d, "dateTimeEdit", !0);
              this.hScrollPolicy = (0, o.getStringOption)(e.hScrollPolicy, [
                "auto",
                "off",
                "on",
              ]);
              this.id = e.id || "";
              this.picker = (0, o.getStringOption)(e.picker, ["host", "none"]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.comb = null;
              this.extras = null;
              this.margin = null;
            }
            [r.$toHTML](e) {
              const t = (0, s.toStyle)(this, "border", "font", "margin"),
                a = this[r.$getParent]()[r.$getParent](),
                n = {
                  name: "input",
                  attributes: {
                    type: "text",
                    fieldId: a[r.$uid],
                    dataId: (a[r.$data] && a[r.$data][r.$uid]) || a[r.$uid],
                    class: ["xfaTextfield"],
                    style: t,
                    "aria-label": ariaLabel(a),
                    "aria-required": !1,
                  },
                };
              if (isRequired(a)) {
                n.attributes["aria-required"] = !0;
                n.attributes.required = !0;
              }
              return o.HTMLResult.success({
                name: "label",
                attributes: { class: ["xfaLabel"] },
                children: [n],
              });
            }
          }
          class Decimal extends r.ContentObject {
            constructor(e) {
              super(d, "decimal");
              this.fracDigits = (0, o.getInteger)({
                data: e.fracDigits,
                defaultValue: 2,
                validate: (e) => !0,
              });
              this.id = e.id || "";
              this.leadDigits = (0, o.getInteger)({
                data: e.leadDigits,
                defaultValue: -1,
                validate: (e) => !0,
              });
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              const e = parseFloat(this[r.$content].trim());
              this[r.$content] = isNaN(e) ? null : e;
            }
            [r.$toHTML](e) {
              return valueToHtml(
                null !== this[r.$content] ? this[r.$content].toString() : ""
              );
            }
          }
          class DefaultUi extends r.XFAObject {
            constructor(e) {
              super(d, "defaultUi", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
          }
          class Desc extends r.XFAObject {
            constructor(e) {
              super(d, "desc", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.boolean = new r.XFAObjectArray();
              this.date = new r.XFAObjectArray();
              this.dateTime = new r.XFAObjectArray();
              this.decimal = new r.XFAObjectArray();
              this.exData = new r.XFAObjectArray();
              this.float = new r.XFAObjectArray();
              this.image = new r.XFAObjectArray();
              this.integer = new r.XFAObjectArray();
              this.text = new r.XFAObjectArray();
              this.time = new r.XFAObjectArray();
            }
          }
          class DigestMethod extends r.OptionObject {
            constructor(e) {
              super(d, "digestMethod", [
                "",
                "SHA1",
                "SHA256",
                "SHA512",
                "RIPEMD160",
              ]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class DigestMethods extends r.XFAObject {
            constructor(e) {
              super(d, "digestMethods", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.digestMethod = new r.XFAObjectArray();
            }
          }
          class Draw extends r.XFAObject {
            constructor(e) {
              super(d, "draw", !0);
              this.anchorType = (0, o.getStringOption)(e.anchorType, [
                "topLeft",
                "bottomCenter",
                "bottomLeft",
                "bottomRight",
                "middleCenter",
                "middleLeft",
                "middleRight",
                "topCenter",
                "topRight",
              ]);
              this.colSpan = (0, o.getInteger)({
                data: e.colSpan,
                defaultValue: 1,
                validate: (e) => e >= 1 || -1 === e,
              });
              this.h = e.h ? (0, o.getMeasurement)(e.h) : "";
              this.hAlign = (0, o.getStringOption)(e.hAlign, [
                "left",
                "center",
                "justify",
                "justifyAll",
                "radix",
                "right",
              ]);
              this.id = e.id || "";
              this.locale = e.locale || "";
              this.maxH = (0, o.getMeasurement)(e.maxH, "0pt");
              this.maxW = (0, o.getMeasurement)(e.maxW, "0pt");
              this.minH = (0, o.getMeasurement)(e.minH, "0pt");
              this.minW = (0, o.getMeasurement)(e.minW, "0pt");
              this.name = e.name || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.rotate = (0, o.getInteger)({
                data: e.rotate,
                defaultValue: 0,
                validate: (e) => e % 90 == 0,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.w = e.w ? (0, o.getMeasurement)(e.w) : "";
              this.x = (0, o.getMeasurement)(e.x, "0pt");
              this.y = (0, o.getMeasurement)(e.y, "0pt");
              this.assist = null;
              this.border = null;
              this.caption = null;
              this.desc = null;
              this.extras = null;
              this.font = null;
              this.keep = null;
              this.margin = null;
              this.para = null;
              this.traversal = null;
              this.ui = null;
              this.value = null;
              this.setProperty = new r.XFAObjectArray();
            }
            [r.$setValue](e) {
              _setValue(this, e);
            }
            [r.$toHTML](e) {
              setTabIndex(this);
              if ("hidden" === this.presence || "inactive" === this.presence)
                return o.HTMLResult.EMPTY;
              (0, s.fixDimensions)(this);
              this[r.$pushPara]();
              const t = this.w,
                a = this.h,
                { w: n, h: c, isBroken: l } = (0, s.layoutNode)(this, e);
              if (n && "" === this.w) {
                if (l && this[r.$getSubformParent]()[r.$isThereMoreWidth]()) {
                  this[r.$popPara]();
                  return o.HTMLResult.FAILURE;
                }
                this.w = n;
              }
              c && "" === this.h && (this.h = c);
              setFirstUnsplittable(this);
              if (!(0, i.checkDimensions)(this, e)) {
                this.w = t;
                this.h = a;
                this[r.$popPara]();
                return o.HTMLResult.FAILURE;
              }
              unsetFirstUnsplittable(this);
              const h = (0, s.toStyle)(
                this,
                "font",
                "hAlign",
                "dimensions",
                "position",
                "presence",
                "rotate",
                "anchorType",
                "border",
                "margin"
              );
              (0, s.setMinMaxDimensions)(this, h);
              if (h.margin) {
                h.padding = h.margin;
                delete h.margin;
              }
              const u = ["xfaDraw"];
              this.font && u.push("xfaFont");
              (0, s.isPrintOnly)(this) && u.push("xfaPrintOnly");
              const d = { style: h, id: this[r.$uid], class: u };
              this.name && (d.xfaName = this.name);
              const f = { name: "div", attributes: d, children: [] };
              applyAssist(this, d);
              const g = (0, s.computeBbox)(this, f, e),
                p = this.value ? this.value[r.$toHTML](e).html : null;
              if (null === p) {
                this.w = t;
                this.h = a;
                this[r.$popPara]();
                return o.HTMLResult.success((0, s.createWrapper)(this, f), g);
              }
              f.children.push(p);
              (0, s.setPara)(this, h, p);
              this.w = t;
              this.h = a;
              this[r.$popPara]();
              return o.HTMLResult.success((0, s.createWrapper)(this, f), g);
            }
          }
          class Edge extends r.XFAObject {
            constructor(e) {
              super(d, "edge", !0);
              this.cap = (0, o.getStringOption)(e.cap, [
                "square",
                "butt",
                "round",
              ]);
              this.id = e.id || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.stroke = (0, o.getStringOption)(e.stroke, [
                "solid",
                "dashDot",
                "dashDotDot",
                "dashed",
                "dotted",
                "embossed",
                "etched",
                "lowered",
                "raised",
              ]);
              this.thickness = (0, o.getMeasurement)(e.thickness, "0.5pt");
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
            }
            [r.$toStyle]() {
              const e = (0, s.toStyle)(this, "visibility");
              Object.assign(e, {
                linecap: this.cap,
                width: (0, s.measureToString)(this.thickness),
                color: this.color ? this.color[r.$toStyle]() : "#000000",
                style: "",
              });
              if ("visible" !== this.presence) e.style = "none";
              else
                switch (this.stroke) {
                  case "solid":
                    e.style = "solid";
                    break;
                  case "dashDot":
                  case "dashDotDot":
                  case "dashed":
                    e.style = "dashed";
                    break;
                  case "dotted":
                    e.style = "dotted";
                    break;
                  case "embossed":
                    e.style = "ridge";
                    break;
                  case "etched":
                    e.style = "groove";
                    break;
                  case "lowered":
                    e.style = "inset";
                    break;
                  case "raised":
                    e.style = "outset";
                }
              return e;
            }
          }
          class Encoding extends r.OptionObject {
            constructor(e) {
              super(d, "encoding", [
                "adbe.x509.rsa_sha1",
                "adbe.pkcs7.detached",
                "adbe.pkcs7.sha1",
              ]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Encodings extends r.XFAObject {
            constructor(e) {
              super(d, "encodings", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.encoding = new r.XFAObjectArray();
            }
          }
          class Encrypt extends r.XFAObject {
            constructor(e) {
              super(d, "encrypt", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.certificate = null;
            }
          }
          class EncryptData extends r.XFAObject {
            constructor(e) {
              super(d, "encryptData", !0);
              this.id = e.id || "";
              this.operation = (0, o.getStringOption)(e.operation, [
                "encrypt",
                "decrypt",
              ]);
              this.target = e.target || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.filter = null;
              this.manifest = null;
            }
          }
          class Encryption extends r.XFAObject {
            constructor(e) {
              super(d, "encryption", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.certificate = new r.XFAObjectArray();
            }
          }
          class EncryptionMethod extends r.OptionObject {
            constructor(e) {
              super(d, "encryptionMethod", [
                "",
                "AES256-CBC",
                "TRIPLEDES-CBC",
                "AES128-CBC",
                "AES192-CBC",
              ]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class EncryptionMethods extends r.XFAObject {
            constructor(e) {
              super(d, "encryptionMethods", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.encryptionMethod = new r.XFAObjectArray();
            }
          }
          class Event extends r.XFAObject {
            constructor(e) {
              super(d, "event", !0);
              this.activity = (0, o.getStringOption)(e.activity, [
                "click",
                "change",
                "docClose",
                "docReady",
                "enter",
                "exit",
                "full",
                "indexChange",
                "initialize",
                "mouseDown",
                "mouseEnter",
                "mouseExit",
                "mouseUp",
                "postExecute",
                "postOpen",
                "postPrint",
                "postSave",
                "postSign",
                "postSubmit",
                "preExecute",
                "preOpen",
                "prePrint",
                "preSave",
                "preSign",
                "preSubmit",
                "ready",
                "validationState",
              ]);
              this.id = e.id || "";
              this.listen = (0, o.getStringOption)(e.listen, [
                "refOnly",
                "refAndDescendents",
              ]);
              this.name = e.name || "";
              this.ref = e.ref || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.encryptData = null;
              this.execute = null;
              this.script = null;
              this.signData = null;
              this.submit = null;
            }
          }
          class ExData extends r.ContentObject {
            constructor(e) {
              super(d, "exData");
              this.contentType = e.contentType || "";
              this.href = e.href || "";
              this.id = e.id || "";
              this.maxLength = (0, o.getInteger)({
                data: e.maxLength,
                defaultValue: -1,
                validate: (e) => e >= -1,
              });
              this.name = e.name || "";
              this.rid = e.rid || "";
              this.transferEncoding = (0, o.getStringOption)(
                e.transferEncoding,
                ["none", "base64", "package"]
              );
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$isCDATAXml]() {
              return "text/html" === this.contentType;
            }
            [r.$onChild](e) {
              if (
                "text/html" === this.contentType &&
                e[r.$namespaceId] === n.NamespaceIds.xhtml.id
              ) {
                this[r.$content] = e;
                return !0;
              }
              if ("text/xml" === this.contentType) {
                this[r.$content] = e;
                return !0;
              }
              return !1;
            }
            [r.$toHTML](e) {
              return "text/html" === this.contentType && this[r.$content]
                ? this[r.$content][r.$toHTML](e)
                : o.HTMLResult.EMPTY;
            }
          }
          class ExObject extends r.XFAObject {
            constructor(e) {
              super(d, "exObject", !0);
              this.archive = e.archive || "";
              this.classId = e.classId || "";
              this.codeBase = e.codeBase || "";
              this.codeType = e.codeType || "";
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.boolean = new r.XFAObjectArray();
              this.date = new r.XFAObjectArray();
              this.dateTime = new r.XFAObjectArray();
              this.decimal = new r.XFAObjectArray();
              this.exData = new r.XFAObjectArray();
              this.exObject = new r.XFAObjectArray();
              this.float = new r.XFAObjectArray();
              this.image = new r.XFAObjectArray();
              this.integer = new r.XFAObjectArray();
              this.text = new r.XFAObjectArray();
              this.time = new r.XFAObjectArray();
            }
          }
          class ExclGroup extends r.XFAObject {
            constructor(e) {
              super(d, "exclGroup", !0);
              this.access = (0, o.getStringOption)(e.access, [
                "open",
                "nonInteractive",
                "protected",
                "readOnly",
              ]);
              this.accessKey = e.accessKey || "";
              this.anchorType = (0, o.getStringOption)(e.anchorType, [
                "topLeft",
                "bottomCenter",
                "bottomLeft",
                "bottomRight",
                "middleCenter",
                "middleLeft",
                "middleRight",
                "topCenter",
                "topRight",
              ]);
              this.colSpan = (0, o.getInteger)({
                data: e.colSpan,
                defaultValue: 1,
                validate: (e) => e >= 1 || -1 === e,
              });
              this.h = e.h ? (0, o.getMeasurement)(e.h) : "";
              this.hAlign = (0, o.getStringOption)(e.hAlign, [
                "left",
                "center",
                "justify",
                "justifyAll",
                "radix",
                "right",
              ]);
              this.id = e.id || "";
              this.layout = (0, o.getStringOption)(e.layout, [
                "position",
                "lr-tb",
                "rl-row",
                "rl-tb",
                "row",
                "table",
                "tb",
              ]);
              this.maxH = (0, o.getMeasurement)(e.maxH, "0pt");
              this.maxW = (0, o.getMeasurement)(e.maxW, "0pt");
              this.minH = (0, o.getMeasurement)(e.minH, "0pt");
              this.minW = (0, o.getMeasurement)(e.minW, "0pt");
              this.name = e.name || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.w = e.w ? (0, o.getMeasurement)(e.w) : "";
              this.x = (0, o.getMeasurement)(e.x, "0pt");
              this.y = (0, o.getMeasurement)(e.y, "0pt");
              this.assist = null;
              this.bind = null;
              this.border = null;
              this.calculate = null;
              this.caption = null;
              this.desc = null;
              this.extras = null;
              this.margin = null;
              this.para = null;
              this.traversal = null;
              this.validate = null;
              this.connect = new r.XFAObjectArray();
              this.event = new r.XFAObjectArray();
              this.field = new r.XFAObjectArray();
              this.setProperty = new r.XFAObjectArray();
            }
            [r.$isBindable]() {
              return !0;
            }
            [r.$hasSettableValue]() {
              return !0;
            }
            [r.$setValue](e) {
              for (const t of this.field.children) {
                if (!t.value) {
                  const e = new Value({});
                  t[r.$appendChild](e);
                  t.value = e;
                }
                t.value[r.$setValue](e);
              }
            }
            [r.$isThereMoreWidth]() {
              return (
                (this.layout.endsWith("-tb") &&
                  0 === this[r.$extra].attempt &&
                  this[r.$extra].numberInLine > 0) ||
                this[r.$getParent]()[r.$isThereMoreWidth]()
              );
            }
            [r.$isSplittable]() {
              const e = this[r.$getSubformParent]();
              if (!e[r.$isSplittable]()) return !1;
              if (void 0 !== this[r.$extra]._isSplittable)
                return this[r.$extra]._isSplittable;
              if ("position" === this.layout || this.layout.includes("row")) {
                this[r.$extra]._isSplittable = !1;
                return !1;
              }
              if (
                e.layout &&
                e.layout.endsWith("-tb") &&
                0 !== e[r.$extra].numberInLine
              )
                return !1;
              this[r.$extra]._isSplittable = !0;
              return !0;
            }
            [r.$flushHTML]() {
              return (0, i.flushHTML)(this);
            }
            [r.$addHTML](e, t) {
              (0, i.addHTML)(this, e, t);
            }
            [r.$getAvailableSpace]() {
              return (0, i.getAvailableSpace)(this);
            }
            [r.$toHTML](e) {
              setTabIndex(this);
              if (
                "hidden" === this.presence ||
                "inactive" === this.presence ||
                0 === this.h ||
                0 === this.w
              )
                return o.HTMLResult.EMPTY;
              (0, s.fixDimensions)(this);
              const t = [],
                a = { id: this[r.$uid], class: [] };
              (0, s.setAccess)(this, a.class);
              this[r.$extra] || (this[r.$extra] = Object.create(null));
              Object.assign(this[r.$extra], {
                children: t,
                attributes: a,
                attempt: 0,
                line: null,
                numberInLine: 0,
                availableSpace: {
                  width: Math.min(this.w || 1 / 0, e.width),
                  height: Math.min(this.h || 1 / 0, e.height),
                },
                width: 0,
                height: 0,
                prevHeight: 0,
                currentWidth: 0,
              });
              const n = this[r.$isSplittable]();
              n || setFirstUnsplittable(this);
              if (!(0, i.checkDimensions)(this, e)) return o.HTMLResult.FAILURE;
              const c = new Set(["field"]);
              if (this.layout.includes("row")) {
                const e = this[r.$getSubformParent]().columnWidths;
                if (Array.isArray(e) && e.length > 0) {
                  this[r.$extra].columnWidths = e;
                  this[r.$extra].currentColumn = 0;
                }
              }
              const l = (0, s.toStyle)(
                  this,
                  "anchorType",
                  "dimensions",
                  "position",
                  "presence",
                  "border",
                  "margin",
                  "hAlign"
                ),
                h = ["xfaExclgroup"],
                u = (0, s.layoutClass)(this);
              u && h.push(u);
              (0, s.isPrintOnly)(this) && h.push("xfaPrintOnly");
              a.style = l;
              a.class = h;
              this.name && (a.xfaName = this.name);
              this[r.$pushPara]();
              const d = "lr-tb" === this.layout || "rl-tb" === this.layout,
                f = d ? 2 : 1;
              for (; this[r.$extra].attempt < f; this[r.$extra].attempt++) {
                d &&
                  1 === this[r.$extra].attempt &&
                  (this[r.$extra].numberInLine = 0);
                const e = this[r.$childrenToHTML]({ filter: c, include: !0 });
                if (e.success) break;
                if (e.isBreak()) {
                  this[r.$popPara]();
                  return e;
                }
                if (
                  d &&
                  0 === this[r.$extra].attempt &&
                  0 === this[r.$extra].numberInLine &&
                  !this[r.$getTemplateRoot]()[r.$extra].noLayoutFailure
                ) {
                  this[r.$extra].attempt = f;
                  break;
                }
              }
              this[r.$popPara]();
              n || unsetFirstUnsplittable(this);
              if (this[r.$extra].attempt === f) {
                n || delete this[r.$extra];
                return o.HTMLResult.FAILURE;
              }
              let g = 0,
                p = 0;
              if (this.margin) {
                g = this.margin.leftInset + this.margin.rightInset;
                p = this.margin.topInset + this.margin.bottomInset;
              }
              const m = Math.max(this[r.$extra].width + g, this.w || 0),
                b = Math.max(this[r.$extra].height + p, this.h || 0),
                y = [this.x, this.y, m, b];
              "" === this.w && (l.width = (0, s.measureToString)(m));
              "" === this.h && (l.height = (0, s.measureToString)(b));
              const w = { name: "div", attributes: a, children: t };
              applyAssist(this, a);
              delete this[r.$extra];
              return o.HTMLResult.success((0, s.createWrapper)(this, w), y);
            }
          }
          class Execute extends r.XFAObject {
            constructor(e) {
              super(d, "execute");
              this.connection = e.connection || "";
              this.executeType = (0, o.getStringOption)(e.executeType, [
                "import",
                "remerge",
              ]);
              this.id = e.id || "";
              this.runAt = (0, o.getStringOption)(e.runAt, [
                "client",
                "both",
                "server",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Extras extends r.XFAObject {
            constructor(e) {
              super(d, "extras", !0);
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.boolean = new r.XFAObjectArray();
              this.date = new r.XFAObjectArray();
              this.dateTime = new r.XFAObjectArray();
              this.decimal = new r.XFAObjectArray();
              this.exData = new r.XFAObjectArray();
              this.extras = new r.XFAObjectArray();
              this.float = new r.XFAObjectArray();
              this.image = new r.XFAObjectArray();
              this.integer = new r.XFAObjectArray();
              this.text = new r.XFAObjectArray();
              this.time = new r.XFAObjectArray();
            }
          }
          class Field extends r.XFAObject {
            constructor(e) {
              super(d, "field", !0);
              this.access = (0, o.getStringOption)(e.access, [
                "open",
                "nonInteractive",
                "protected",
                "readOnly",
              ]);
              this.accessKey = e.accessKey || "";
              this.anchorType = (0, o.getStringOption)(e.anchorType, [
                "topLeft",
                "bottomCenter",
                "bottomLeft",
                "bottomRight",
                "middleCenter",
                "middleLeft",
                "middleRight",
                "topCenter",
                "topRight",
              ]);
              this.colSpan = (0, o.getInteger)({
                data: e.colSpan,
                defaultValue: 1,
                validate: (e) => e >= 1 || -1 === e,
              });
              this.h = e.h ? (0, o.getMeasurement)(e.h) : "";
              this.hAlign = (0, o.getStringOption)(e.hAlign, [
                "left",
                "center",
                "justify",
                "justifyAll",
                "radix",
                "right",
              ]);
              this.id = e.id || "";
              this.locale = e.locale || "";
              this.maxH = (0, o.getMeasurement)(e.maxH, "0pt");
              this.maxW = (0, o.getMeasurement)(e.maxW, "0pt");
              this.minH = (0, o.getMeasurement)(e.minH, "0pt");
              this.minW = (0, o.getMeasurement)(e.minW, "0pt");
              this.name = e.name || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.rotate = (0, o.getInteger)({
                data: e.rotate,
                defaultValue: 0,
                validate: (e) => e % 90 == 0,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.w = e.w ? (0, o.getMeasurement)(e.w) : "";
              this.x = (0, o.getMeasurement)(e.x, "0pt");
              this.y = (0, o.getMeasurement)(e.y, "0pt");
              this.assist = null;
              this.bind = null;
              this.border = null;
              this.calculate = null;
              this.caption = null;
              this.desc = null;
              this.extras = null;
              this.font = null;
              this.format = null;
              this.items = new r.XFAObjectArray(2);
              this.keep = null;
              this.margin = null;
              this.para = null;
              this.traversal = null;
              this.ui = null;
              this.validate = null;
              this.value = null;
              this.bindItems = new r.XFAObjectArray();
              this.connect = new r.XFAObjectArray();
              this.event = new r.XFAObjectArray();
              this.setProperty = new r.XFAObjectArray();
            }
            [r.$isBindable]() {
              return !0;
            }
            [r.$setValue](e) {
              _setValue(this, e);
            }
            [r.$toHTML](e) {
              setTabIndex(this);
              if (!this.ui) {
                this.ui = new Ui({});
                this.ui[r.$globalData] = this[r.$globalData];
                this[r.$appendChild](this.ui);
                let e;
                switch (this.items.children.length) {
                  case 0:
                    e = new TextEdit({});
                    this.ui.textEdit = e;
                    break;
                  case 1:
                    e = new CheckButton({});
                    this.ui.checkButton = e;
                    break;
                  case 2:
                    e = new ChoiceList({});
                    this.ui.choiceList = e;
                }
                this.ui[r.$appendChild](e);
              }
              if (
                !this.ui ||
                "hidden" === this.presence ||
                "inactive" === this.presence ||
                0 === this.h ||
                0 === this.w
              )
                return o.HTMLResult.EMPTY;
              this.caption && delete this.caption[r.$extra];
              this[r.$pushPara]();
              const t = this.caption ? this.caption[r.$toHTML](e).html : null,
                a = this.w,
                n = this.h;
              let c = 0,
                h = 0;
              if (this.margin) {
                c = this.margin.leftInset + this.margin.rightInset;
                h = this.margin.topInset + this.margin.bottomInset;
              }
              let u = null;
              if ("" === this.w || "" === this.h) {
                let t = null,
                  a = null,
                  n = 0,
                  i = 0;
                if (this.ui.checkButton) n = i = this.ui.checkButton.size;
                else {
                  const { w: t, h: a } = (0, s.layoutNode)(this, e);
                  if (null !== t) {
                    n = t;
                    i = a;
                  } else i = (0, l.getMetrics)(this.font, !0).lineNoGap;
                }
                u = getBorderDims(this.ui[r.$getExtra]());
                n += u.w;
                i += u.h;
                if (this.caption) {
                  const {
                    w: s,
                    h: c,
                    isBroken: l,
                  } = this.caption[r.$getExtra](e);
                  if (l && this[r.$getSubformParent]()[r.$isThereMoreWidth]()) {
                    this[r.$popPara]();
                    return o.HTMLResult.FAILURE;
                  }
                  t = s;
                  a = c;
                  switch (this.caption.placement) {
                    case "left":
                    case "right":
                    case "inline":
                      t += n;
                      break;
                    case "top":
                    case "bottom":
                      a += i;
                  }
                } else {
                  t = n;
                  a = i;
                }
                if (t && "" === this.w) {
                  t += c;
                  this.w = Math.min(
                    this.maxW <= 0 ? 1 / 0 : this.maxW,
                    this.minW + 1 < t ? t : this.minW
                  );
                }
                if (a && "" === this.h) {
                  a += h;
                  this.h = Math.min(
                    this.maxH <= 0 ? 1 / 0 : this.maxH,
                    this.minH + 1 < a ? a : this.minH
                  );
                }
              }
              this[r.$popPara]();
              (0, s.fixDimensions)(this);
              setFirstUnsplittable(this);
              if (!(0, i.checkDimensions)(this, e)) {
                this.w = a;
                this.h = n;
                this[r.$popPara]();
                return o.HTMLResult.FAILURE;
              }
              unsetFirstUnsplittable(this);
              const d = (0, s.toStyle)(
                this,
                "font",
                "dimensions",
                "position",
                "rotate",
                "anchorType",
                "presence",
                "margin",
                "hAlign"
              );
              (0, s.setMinMaxDimensions)(this, d);
              const f = ["xfaField"];
              this.font && f.push("xfaFont");
              (0, s.isPrintOnly)(this) && f.push("xfaPrintOnly");
              const g = { style: d, id: this[r.$uid], class: f };
              if (d.margin) {
                d.padding = d.margin;
                delete d.margin;
              }
              (0, s.setAccess)(this, f);
              this.name && (g.xfaName = this.name);
              const p = [],
                m = { name: "div", attributes: g, children: p };
              applyAssist(this, g);
              const b = this.border ? this.border[r.$toStyle]() : null,
                y = (0, s.computeBbox)(this, m, e),
                w = this.ui[r.$toHTML]().html;
              if (!w) {
                Object.assign(d, b);
                return o.HTMLResult.success((0, s.createWrapper)(this, m), y);
              }
              this[r.$tabIndex] &&
                (w.children && w.children[0]
                  ? (w.children[0].attributes.tabindex = this[r.$tabIndex])
                  : (w.attributes.tabindex = this[r.$tabIndex]));
              w.attributes.style || (w.attributes.style = Object.create(null));
              let S = null;
              if (this.ui.button) {
                1 === w.children.length && ([S] = w.children.splice(0, 1));
                Object.assign(w.attributes.style, b);
              } else Object.assign(d, b);
              p.push(w);
              if (this.value)
                if (this.ui.imageEdit)
                  w.children.push(this.value[r.$toHTML]().html);
                else if (!this.ui.button) {
                  let e = "";
                  if (this.value.exData) e = this.value.exData[r.$text]();
                  else if (this.value.text) e = this.value.text[r.$getExtra]();
                  else {
                    const t = this.value[r.$toHTML]().html;
                    null !== t && (e = t.children[0].value);
                  }
                  this.ui.textEdit &&
                    this.value.text &&
                    this.value.text.maxChars &&
                    (w.children[0].attributes.maxLength =
                      this.value.text.maxChars);
                  if (e) {
                    if (this.ui.numericEdit) {
                      e = parseFloat(e);
                      e = isNaN(e) ? "" : e.toString();
                    }
                    "textarea" === w.children[0].name
                      ? (w.children[0].attributes.textContent = e)
                      : (w.children[0].attributes.value = e);
                  }
                }
              if (!this.ui.imageEdit && w.children && w.children[0] && this.h) {
                u = u || getBorderDims(this.ui[r.$getExtra]());
                let t = 0;
                if (
                  this.caption &&
                  ["top", "bottom"].includes(this.caption.placement)
                ) {
                  t = this.caption.reserve;
                  t <= 0 && (t = this.caption[r.$getExtra](e).h);
                  const a = this.h - t - h - u.h;
                  w.children[0].attributes.style.height = (0,
                  s.measureToString)(a);
                } else w.children[0].attributes.style.height = "100%";
              }
              S && w.children.push(S);
              if (!t) {
                w.attributes.class && w.attributes.class.push("xfaLeft");
                this.w = a;
                this.h = n;
                return o.HTMLResult.success((0, s.createWrapper)(this, m), y);
              }
              if (this.ui.button) {
                d.padding && delete d.padding;
                "div" === t.name && (t.name = "span");
                w.children.push(t);
                return o.HTMLResult.success(m, y);
              }
              this.ui.checkButton &&
                (t.attributes.class[0] = "xfaCaptionForCheckButton");
              w.attributes.class || (w.attributes.class = []);
              w.children.splice(0, 0, t);
              switch (this.caption.placement) {
                case "left":
                case "inline":
                  w.attributes.class.push("xfaLeft");
                  break;
                case "right":
                  w.attributes.class.push("xfaRight");
                  break;
                case "top":
                  w.attributes.class.push("xfaTop");
                  break;
                case "bottom":
                  w.attributes.class.push("xfaBottom");
              }
              this.w = a;
              this.h = n;
              return o.HTMLResult.success((0, s.createWrapper)(this, m), y);
            }
          }
          t.Field = Field;
          class Fill extends r.XFAObject {
            constructor(e) {
              super(d, "fill", !0);
              this.id = e.id || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
              this.linear = null;
              this.pattern = null;
              this.radial = null;
              this.solid = null;
              this.stipple = null;
            }
            [r.$toStyle]() {
              const e = this[r.$getParent](),
                t = e[r.$getParent]()[r.$getParent](),
                a = Object.create(null);
              let n = "color",
                i = n;
              if (e instanceof Border) {
                n = "background-color";
                i = "background";
                t instanceof Ui && (a.backgroundColor = "white");
              }
              if (e instanceof Rectangle || e instanceof Arc) {
                n = i = "fill";
                a.fill = "white";
              }
              for (const e of Object.getOwnPropertyNames(this)) {
                if ("extras" === e || "color" === e) continue;
                const t = this[e];
                if (!(t instanceof r.XFAObject)) continue;
                const s = t[r.$toStyle](this.color);
                s && (a[s.startsWith("#") ? n : i] = s);
                return a;
              }
              if (this.color && this.color.value) {
                const e = this.color[r.$toStyle]();
                a[e.startsWith("#") ? n : i] = e;
              }
              return a;
            }
          }
          class Filter extends r.XFAObject {
            constructor(e) {
              super(d, "filter", !0);
              this.addRevocationInfo = (0, o.getStringOption)(
                e.addRevocationInfo,
                ["", "required", "optional", "none"]
              );
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.version = (0, o.getInteger)({
                data: this.version,
                defaultValue: 5,
                validate: (e) => e >= 1 && e <= 5,
              });
              this.appearanceFilter = null;
              this.certificates = null;
              this.digestMethods = null;
              this.encodings = null;
              this.encryptionMethods = null;
              this.handler = null;
              this.lockDocument = null;
              this.mdp = null;
              this.reasons = null;
              this.timeStamp = null;
            }
          }
          class Float extends r.ContentObject {
            constructor(e) {
              super(d, "float");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              const e = parseFloat(this[r.$content].trim());
              this[r.$content] = isNaN(e) ? null : e;
            }
            [r.$toHTML](e) {
              return valueToHtml(
                null !== this[r.$content] ? this[r.$content].toString() : ""
              );
            }
          }
          class Font extends r.XFAObject {
            constructor(e) {
              super(d, "font", !0);
              this.baselineShift = (0, o.getMeasurement)(e.baselineShift);
              this.fontHorizontalScale = (0, o.getFloat)({
                data: e.fontHorizontalScale,
                defaultValue: 100,
                validate: (e) => e >= 0,
              });
              this.fontVerticalScale = (0, o.getFloat)({
                data: e.fontVerticalScale,
                defaultValue: 100,
                validate: (e) => e >= 0,
              });
              this.id = e.id || "";
              this.kerningMode = (0, o.getStringOption)(e.kerningMode, [
                "none",
                "pair",
              ]);
              this.letterSpacing = (0, o.getMeasurement)(e.letterSpacing, "0");
              this.lineThrough = (0, o.getInteger)({
                data: e.lineThrough,
                defaultValue: 0,
                validate: (e) => 1 === e || 2 === e,
              });
              this.lineThroughPeriod = (0, o.getStringOption)(
                e.lineThroughPeriod,
                ["all", "word"]
              );
              this.overline = (0, o.getInteger)({
                data: e.overline,
                defaultValue: 0,
                validate: (e) => 1 === e || 2 === e,
              });
              this.overlinePeriod = (0, o.getStringOption)(e.overlinePeriod, [
                "all",
                "word",
              ]);
              this.posture = (0, o.getStringOption)(e.posture, [
                "normal",
                "italic",
              ]);
              this.size = (0, o.getMeasurement)(e.size, "10pt");
              this.typeface = e.typeface || "Courier";
              this.underline = (0, o.getInteger)({
                data: e.underline,
                defaultValue: 0,
                validate: (e) => 1 === e || 2 === e,
              });
              this.underlinePeriod = (0, o.getStringOption)(e.underlinePeriod, [
                "all",
                "word",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.weight = (0, o.getStringOption)(e.weight, [
                "normal",
                "bold",
              ]);
              this.extras = null;
              this.fill = null;
            }
            [r.$clean](e) {
              super[r.$clean](e);
              this[r.$globalData].usedTypefaces.add(this.typeface);
            }
            [r.$toStyle]() {
              const e = (0, s.toStyle)(this, "fill"),
                t = e.color;
              if (t)
                if ("#000000" === t) delete e.color;
                else if (!t.startsWith("#")) {
                  e.background = t;
                  e.backgroundClip = "text";
                  e.color = "transparent";
                }
              this.baselineShift &&
                (e.verticalAlign = (0, s.measureToString)(this.baselineShift));
              e.fontKerning = "none" === this.kerningMode ? "none" : "normal";
              e.letterSpacing = (0, s.measureToString)(this.letterSpacing);
              if (0 !== this.lineThrough) {
                e.textDecoration = "line-through";
                2 === this.lineThrough && (e.textDecorationStyle = "double");
              }
              if (0 !== this.overline) {
                e.textDecoration = "overline";
                2 === this.overline && (e.textDecorationStyle = "double");
              }
              e.fontStyle = this.posture;
              e.fontSize = (0, s.measureToString)(0.99 * this.size);
              (0, s.setFontFamily)(
                this,
                this,
                this[r.$globalData].fontFinder,
                e
              );
              if (0 !== this.underline) {
                e.textDecoration = "underline";
                2 === this.underline && (e.textDecorationStyle = "double");
              }
              e.fontWeight = this.weight;
              return e;
            }
          }
          class Format extends r.XFAObject {
            constructor(e) {
              super(d, "format", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.picture = null;
            }
          }
          class Handler extends r.StringObject {
            constructor(e) {
              super(d, "handler");
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Hyphenation extends r.XFAObject {
            constructor(e) {
              super(d, "hyphenation");
              this.excludeAllCaps = (0, o.getInteger)({
                data: e.excludeAllCaps,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.excludeInitialCap = (0, o.getInteger)({
                data: e.excludeInitialCap,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.hyphenate = (0, o.getInteger)({
                data: e.hyphenate,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.id = e.id || "";
              this.pushCharacterCount = (0, o.getInteger)({
                data: e.pushCharacterCount,
                defaultValue: 3,
                validate: (e) => e >= 0,
              });
              this.remainCharacterCount = (0, o.getInteger)({
                data: e.remainCharacterCount,
                defaultValue: 3,
                validate: (e) => e >= 0,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.wordCharacterCount = (0, o.getInteger)({
                data: e.wordCharacterCount,
                defaultValue: 7,
                validate: (e) => e >= 0,
              });
            }
          }
          class Image extends r.StringObject {
            constructor(e) {
              super(d, "image");
              this.aspect = (0, o.getStringOption)(e.aspect, [
                "fit",
                "actual",
                "height",
                "none",
                "width",
              ]);
              this.contentType = e.contentType || "";
              this.href = e.href || "";
              this.id = e.id || "";
              this.name = e.name || "";
              this.transferEncoding = (0, o.getStringOption)(
                e.transferEncoding,
                ["base64", "none", "package"]
              );
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$toHTML]() {
              if (this.contentType && !p.has(this.contentType.toLowerCase()))
                return o.HTMLResult.EMPTY;
              let e =
                this[r.$globalData].images &&
                this[r.$globalData].images.get(this.href);
              if (!e && (this.href || !this[r.$content]))
                return o.HTMLResult.EMPTY;
              e ||
                "base64" !== this.transferEncoding ||
                (e = (0, c.stringToBytes)(atob(this[r.$content])));
              if (!e) return o.HTMLResult.EMPTY;
              if (!this.contentType) {
                for (const [t, a] of m)
                  if (e.length > t.length && t.every((t, a) => t === e[a])) {
                    this.contentType = a;
                    break;
                  }
                if (!this.contentType) return o.HTMLResult.EMPTY;
              }
              const t = new Blob([e], { type: this.contentType });
              let a;
              switch (this.aspect) {
                case "fit":
                case "actual":
                  break;
                case "height":
                  a = { height: "100%", objectFit: "fill" };
                  break;
                case "none":
                  a = { width: "100%", height: "100%", objectFit: "fill" };
                  break;
                case "width":
                  a = { width: "100%", objectFit: "fill" };
              }
              const n = this[r.$getParent]();
              return o.HTMLResult.success({
                name: "img",
                attributes: {
                  class: ["xfaImage"],
                  style: a,
                  src: URL.createObjectURL(t),
                  alt: n ? ariaLabel(n[r.$getParent]()) : null,
                },
              });
            }
          }
          class ImageEdit extends r.XFAObject {
            constructor(e) {
              super(d, "imageEdit", !0);
              this.data = (0, o.getStringOption)(e.data, ["link", "embed"]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.extras = null;
              this.margin = null;
            }
            [r.$toHTML](e) {
              return "embed" === this.data
                ? o.HTMLResult.success({
                    name: "div",
                    children: [],
                    attributes: {},
                  })
                : o.HTMLResult.EMPTY;
            }
          }
          class Integer extends r.ContentObject {
            constructor(e) {
              super(d, "integer");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              const e = parseInt(this[r.$content].trim(), 10);
              this[r.$content] = isNaN(e) ? null : e;
            }
            [r.$toHTML](e) {
              return valueToHtml(
                null !== this[r.$content] ? this[r.$content].toString() : ""
              );
            }
          }
          class Issuers extends r.XFAObject {
            constructor(e) {
              super(d, "issuers", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.certificate = new r.XFAObjectArray();
            }
          }
          class Items extends r.XFAObject {
            constructor(e) {
              super(d, "items", !0);
              this.id = e.id || "";
              this.name = e.name || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.ref = e.ref || "";
              this.save = (0, o.getInteger)({
                data: e.save,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.boolean = new r.XFAObjectArray();
              this.date = new r.XFAObjectArray();
              this.dateTime = new r.XFAObjectArray();
              this.decimal = new r.XFAObjectArray();
              this.exData = new r.XFAObjectArray();
              this.float = new r.XFAObjectArray();
              this.image = new r.XFAObjectArray();
              this.integer = new r.XFAObjectArray();
              this.text = new r.XFAObjectArray();
              this.time = new r.XFAObjectArray();
            }
            [r.$toHTML]() {
              const e = [];
              for (const t of this[r.$getChildren]()) e.push(t[r.$text]());
              return o.HTMLResult.success(e);
            }
          }
          t.Items = Items;
          class Keep extends r.XFAObject {
            constructor(e) {
              super(d, "keep", !0);
              this.id = e.id || "";
              const t = ["none", "contentArea", "pageArea"];
              this.intact = (0, o.getStringOption)(e.intact, t);
              this.next = (0, o.getStringOption)(e.next, t);
              this.previous = (0, o.getStringOption)(e.previous, t);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
          }
          class KeyUsage extends r.XFAObject {
            constructor(e) {
              super(d, "keyUsage");
              const t = ["", "yes", "no"];
              this.crlSign = (0, o.getStringOption)(e.crlSign, t);
              this.dataEncipherment = (0, o.getStringOption)(
                e.dataEncipherment,
                t
              );
              this.decipherOnly = (0, o.getStringOption)(e.decipherOnly, t);
              this.digitalSignature = (0, o.getStringOption)(
                e.digitalSignature,
                t
              );
              this.encipherOnly = (0, o.getStringOption)(e.encipherOnly, t);
              this.id = e.id || "";
              this.keyAgreement = (0, o.getStringOption)(e.keyAgreement, t);
              this.keyCertSign = (0, o.getStringOption)(e.keyCertSign, t);
              this.keyEncipherment = (0, o.getStringOption)(
                e.keyEncipherment,
                t
              );
              this.nonRepudiation = (0, o.getStringOption)(e.nonRepudiation, t);
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Line extends r.XFAObject {
            constructor(e) {
              super(d, "line", !0);
              this.hand = (0, o.getStringOption)(e.hand, [
                "even",
                "left",
                "right",
              ]);
              this.id = e.id || "";
              this.slope = (0, o.getStringOption)(e.slope, ["\\", "/"]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.edge = null;
            }
            [r.$toHTML]() {
              const e = this[r.$getParent]()[r.$getParent](),
                t = this.edge || new Edge({}),
                a = t[r.$toStyle](),
                n = Object.create(null),
                i = "visible" === t.presence ? t.thickness : 0;
              n.strokeWidth = (0, s.measureToString)(i);
              n.stroke = a.color;
              let c,
                l,
                h,
                u,
                d = "100%",
                g = "100%";
              if (e.w <= i) {
                [c, l, h, u] = ["50%", 0, "50%", "100%"];
                d = n.strokeWidth;
              } else if (e.h <= i) {
                [c, l, h, u] = [0, "50%", "100%", "50%"];
                g = n.strokeWidth;
              } else
                "\\" === this.slope
                  ? ([c, l, h, u] = [0, 0, "100%", "100%"])
                  : ([c, l, h, u] = [0, "100%", "100%", 0]);
              const p = {
                name: "svg",
                children: [
                  {
                    name: "line",
                    attributes: {
                      xmlns: f,
                      x1: c,
                      y1: l,
                      x2: h,
                      y2: u,
                      style: n,
                    },
                  },
                ],
                attributes: {
                  xmlns: f,
                  width: d,
                  height: g,
                  style: { overflow: "visible" },
                },
              };
              if (hasMargin(e))
                return o.HTMLResult.success({
                  name: "div",
                  attributes: {
                    style: { display: "inline", width: "100%", height: "100%" },
                  },
                  children: [p],
                });
              p.attributes.style.position = "absolute";
              return o.HTMLResult.success(p);
            }
          }
          class Linear extends r.XFAObject {
            constructor(e) {
              super(d, "linear", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "toRight",
                "toBottom",
                "toLeft",
                "toTop",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
            }
            [r.$toStyle](e) {
              e = e ? e[r.$toStyle]() : "#FFFFFF";
              return `linear-gradient(${this.type
                .replace(/([RBLT])/, " $1")
                .toLowerCase()}, ${e}, ${
                this.color ? this.color[r.$toStyle]() : "#000000"
              })`;
            }
          }
          class LockDocument extends r.ContentObject {
            constructor(e) {
              super(d, "lockDocument");
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              this[r.$content] = (0, o.getStringOption)(this[r.$content], [
                "auto",
                "0",
                "1",
              ]);
            }
          }
          class Manifest extends r.XFAObject {
            constructor(e) {
              super(d, "manifest", !0);
              this.action = (0, o.getStringOption)(e.action, [
                "include",
                "all",
                "exclude",
              ]);
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.ref = new r.XFAObjectArray();
            }
          }
          class Margin extends r.XFAObject {
            constructor(e) {
              super(d, "margin", !0);
              this.bottomInset = (0, o.getMeasurement)(e.bottomInset, "0");
              this.id = e.id || "";
              this.leftInset = (0, o.getMeasurement)(e.leftInset, "0");
              this.rightInset = (0, o.getMeasurement)(e.rightInset, "0");
              this.topInset = (0, o.getMeasurement)(e.topInset, "0");
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
            [r.$toStyle]() {
              return {
                margin:
                  (0, s.measureToString)(this.topInset) +
                  " " +
                  (0, s.measureToString)(this.rightInset) +
                  " " +
                  (0, s.measureToString)(this.bottomInset) +
                  " " +
                  (0, s.measureToString)(this.leftInset),
              };
            }
          }
          class Mdp extends r.XFAObject {
            constructor(e) {
              super(d, "mdp");
              this.id = e.id || "";
              this.permissions = (0, o.getInteger)({
                data: e.permissions,
                defaultValue: 2,
                validate: (e) => 1 === e || 3 === e,
              });
              this.signatureType = (0, o.getStringOption)(e.signatureType, [
                "filler",
                "author",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Medium extends r.XFAObject {
            constructor(e) {
              super(d, "medium");
              this.id = e.id || "";
              this.imagingBBox = (0, o.getBBox)(e.imagingBBox);
              this.long = (0, o.getMeasurement)(e.long);
              this.orientation = (0, o.getStringOption)(e.orientation, [
                "portrait",
                "landscape",
              ]);
              this.short = (0, o.getMeasurement)(e.short);
              this.stock = e.stock || "";
              this.trayIn = (0, o.getStringOption)(e.trayIn, [
                "auto",
                "delegate",
                "pageFront",
              ]);
              this.trayOut = (0, o.getStringOption)(e.trayOut, [
                "auto",
                "delegate",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Message extends r.XFAObject {
            constructor(e) {
              super(d, "message", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.text = new r.XFAObjectArray();
            }
          }
          class NumericEdit extends r.XFAObject {
            constructor(e) {
              super(d, "numericEdit", !0);
              this.hScrollPolicy = (0, o.getStringOption)(e.hScrollPolicy, [
                "auto",
                "off",
                "on",
              ]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.comb = null;
              this.extras = null;
              this.margin = null;
            }
            [r.$toHTML](e) {
              const t = (0, s.toStyle)(this, "border", "font", "margin"),
                a = this[r.$getParent]()[r.$getParent](),
                n = {
                  name: "input",
                  attributes: {
                    type: "text",
                    fieldId: a[r.$uid],
                    dataId: (a[r.$data] && a[r.$data][r.$uid]) || a[r.$uid],
                    class: ["xfaTextfield"],
                    style: t,
                    "aria-label": ariaLabel(a),
                    "aria-required": !1,
                  },
                };
              if (isRequired(a)) {
                n.attributes["aria-required"] = !0;
                n.attributes.required = !0;
              }
              return o.HTMLResult.success({
                name: "label",
                attributes: { class: ["xfaLabel"] },
                children: [n],
              });
            }
          }
          class Occur extends r.XFAObject {
            constructor(e) {
              super(d, "occur", !0);
              this.id = e.id || "";
              this.initial =
                "" !== e.initial
                  ? (0, o.getInteger)({
                      data: e.initial,
                      defaultValue: "",
                      validate: (e) => !0,
                    })
                  : "";
              this.max =
                "" !== e.max
                  ? (0, o.getInteger)({
                      data: e.max,
                      defaultValue: 1,
                      validate: (e) => !0,
                    })
                  : "";
              this.min =
                "" !== e.min
                  ? (0, o.getInteger)({
                      data: e.min,
                      defaultValue: 1,
                      validate: (e) => !0,
                    })
                  : "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
            [r.$clean]() {
              const e = this[r.$getParent](),
                t = this.min;
              "" === this.min &&
                (this.min =
                  e instanceof PageArea || e instanceof PageSet ? 0 : 1);
              "" === this.max &&
                (this.max =
                  "" === t
                    ? e instanceof PageArea || e instanceof PageSet
                      ? -1
                      : 1
                    : this.min);
              -1 !== this.max && this.max < this.min && (this.max = this.min);
              "" === this.initial &&
                (this.initial = e instanceof Template ? 1 : this.min);
            }
          }
          class Oid extends r.StringObject {
            constructor(e) {
              super(d, "oid");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Oids extends r.XFAObject {
            constructor(e) {
              super(d, "oids", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.oid = new r.XFAObjectArray();
            }
          }
          class Overflow extends r.XFAObject {
            constructor(e) {
              super(d, "overflow");
              this.id = e.id || "";
              this.leader = e.leader || "";
              this.target = e.target || "";
              this.trailer = e.trailer || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$getExtra]() {
              if (!this[r.$extra]) {
                const e = this[r.$getParent](),
                  t = this[r.$getTemplateRoot](),
                  a = t[r.$searchNode](this.target, e),
                  n = t[r.$searchNode](this.leader, e),
                  i = t[r.$searchNode](this.trailer, e);
                this[r.$extra] = {
                  target: (a && a[0]) || null,
                  leader: (n && n[0]) || null,
                  trailer: (i && i[0]) || null,
                  addLeader: !1,
                  addTrailer: !1,
                };
              }
              return this[r.$extra];
            }
          }
          class PageArea extends r.XFAObject {
            constructor(e) {
              super(d, "pageArea", !0);
              this.blankOrNotBlank = (0, o.getStringOption)(e.blankOrNotBlank, [
                "any",
                "blank",
                "notBlank",
              ]);
              this.id = e.id || "";
              this.initialNumber = (0, o.getInteger)({
                data: e.initialNumber,
                defaultValue: 1,
                validate: (e) => !0,
              });
              this.name = e.name || "";
              this.numbered = (0, o.getInteger)({
                data: e.numbered,
                defaultValue: 1,
                validate: (e) => !0,
              });
              this.oddOrEven = (0, o.getStringOption)(e.oddOrEven, [
                "any",
                "even",
                "odd",
              ]);
              this.pagePosition = (0, o.getStringOption)(e.pagePosition, [
                "any",
                "first",
                "last",
                "only",
                "rest",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.desc = null;
              this.extras = null;
              this.medium = null;
              this.occur = null;
              this.area = new r.XFAObjectArray();
              this.contentArea = new r.XFAObjectArray();
              this.draw = new r.XFAObjectArray();
              this.exclGroup = new r.XFAObjectArray();
              this.field = new r.XFAObjectArray();
              this.subform = new r.XFAObjectArray();
            }
            [r.$isUsable]() {
              if (!this[r.$extra]) {
                this[r.$extra] = { numberOfUse: 0 };
                return !0;
              }
              return (
                !this.occur ||
                -1 === this.occur.max ||
                this[r.$extra].numberOfUse < this.occur.max
              );
            }
            [r.$cleanPage]() {
              delete this[r.$extra];
            }
            [r.$getNextPage]() {
              this[r.$extra] || (this[r.$extra] = { numberOfUse: 0 });
              const e = this[r.$getParent]();
              if ("orderedOccurrence" === e.relation && this[r.$isUsable]()) {
                this[r.$extra].numberOfUse += 1;
                return this;
              }
              return e[r.$getNextPage]();
            }
            [r.$getAvailableSpace]() {
              return this[r.$extra].space || { width: 0, height: 0 };
            }
            [r.$toHTML]() {
              this[r.$extra] || (this[r.$extra] = { numberOfUse: 1 });
              const e = [];
              this[r.$extra].children = e;
              const t = Object.create(null);
              if (this.medium && this.medium.short && this.medium.long) {
                t.width = (0, s.measureToString)(this.medium.short);
                t.height = (0, s.measureToString)(this.medium.long);
                this[r.$extra].space = {
                  width: this.medium.short,
                  height: this.medium.long,
                };
                if ("landscape" === this.medium.orientation) {
                  const e = t.width;
                  t.width = t.height;
                  t.height = e;
                  this[r.$extra].space = {
                    width: this.medium.long,
                    height: this.medium.short,
                  };
                }
              } else
                (0, c.warn)(
                  "XFA - No medium specified in pageArea: please file a bug."
                );
              this[r.$childrenToHTML]({
                filter: new Set(["area", "draw", "field", "subform"]),
                include: !0,
              });
              this[r.$childrenToHTML]({
                filter: new Set(["contentArea"]),
                include: !0,
              });
              return o.HTMLResult.success({
                name: "div",
                children: e,
                attributes: {
                  class: ["xfaPage"],
                  id: this[r.$uid],
                  style: t,
                  xfaName: this.name,
                },
              });
            }
          }
          class PageSet extends r.XFAObject {
            constructor(e) {
              super(d, "pageSet", !0);
              this.duplexImposition = (0, o.getStringOption)(
                e.duplexImposition,
                ["longEdge", "shortEdge"]
              );
              this.id = e.id || "";
              this.name = e.name || "";
              this.relation = (0, o.getStringOption)(e.relation, [
                "orderedOccurrence",
                "duplexPaginated",
                "simplexPaginated",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.occur = null;
              this.pageArea = new r.XFAObjectArray();
              this.pageSet = new r.XFAObjectArray();
            }
            [r.$cleanPage]() {
              for (const e of this.pageArea.children) e[r.$cleanPage]();
              for (const e of this.pageSet.children) e[r.$cleanPage]();
            }
            [r.$isUsable]() {
              return (
                !this.occur ||
                -1 === this.occur.max ||
                this[r.$extra].numberOfUse < this.occur.max
              );
            }
            [r.$getNextPage]() {
              this[r.$extra] ||
                (this[r.$extra] = {
                  numberOfUse: 1,
                  pageIndex: -1,
                  pageSetIndex: -1,
                });
              if ("orderedOccurrence" === this.relation) {
                if (
                  this[r.$extra].pageIndex + 1 <
                  this.pageArea.children.length
                ) {
                  this[r.$extra].pageIndex += 1;
                  return this.pageArea.children[this[r.$extra].pageIndex][
                    r.$getNextPage
                  ]();
                }
                if (
                  this[r.$extra].pageSetIndex + 1 <
                  this.pageSet.children.length
                ) {
                  this[r.$extra].pageSetIndex += 1;
                  return this.pageSet.children[this[r.$extra].pageSetIndex][
                    r.$getNextPage
                  ]();
                }
                if (this[r.$isUsable]()) {
                  this[r.$extra].numberOfUse += 1;
                  this[r.$extra].pageIndex = -1;
                  this[r.$extra].pageSetIndex = -1;
                  return this[r.$getNextPage]();
                }
                const e = this[r.$getParent]();
                if (e instanceof PageSet) return e[r.$getNextPage]();
                this[r.$cleanPage]();
                return this[r.$getNextPage]();
              }
              const e = this[r.$getTemplateRoot]()[r.$extra].pageNumber,
                t = e % 2 == 0 ? "even" : "odd",
                a = 0 === e ? "first" : "rest";
              let n = this.pageArea.children.find(
                (e) => e.oddOrEven === t && e.pagePosition === a
              );
              if (n) return n;
              n = this.pageArea.children.find(
                (e) => "any" === e.oddOrEven && e.pagePosition === a
              );
              if (n) return n;
              n = this.pageArea.children.find(
                (e) => "any" === e.oddOrEven && "any" === e.pagePosition
              );
              return n || this.pageArea.children[0];
            }
          }
          class Para extends r.XFAObject {
            constructor(e) {
              super(d, "para", !0);
              this.hAlign = (0, o.getStringOption)(e.hAlign, [
                "left",
                "center",
                "justify",
                "justifyAll",
                "radix",
                "right",
              ]);
              this.id = e.id || "";
              this.lineHeight = e.lineHeight
                ? (0, o.getMeasurement)(e.lineHeight, "0pt")
                : "";
              this.marginLeft = e.marginLeft
                ? (0, o.getMeasurement)(e.marginLeft, "0pt")
                : "";
              this.marginRight = e.marginRight
                ? (0, o.getMeasurement)(e.marginRight, "0pt")
                : "";
              this.orphans = (0, o.getInteger)({
                data: e.orphans,
                defaultValue: 0,
                validate: (e) => e >= 0,
              });
              this.preserve = e.preserve || "";
              this.radixOffset = e.radixOffset
                ? (0, o.getMeasurement)(e.radixOffset, "0pt")
                : "";
              this.spaceAbove = e.spaceAbove
                ? (0, o.getMeasurement)(e.spaceAbove, "0pt")
                : "";
              this.spaceBelow = e.spaceBelow
                ? (0, o.getMeasurement)(e.spaceBelow, "0pt")
                : "";
              this.tabDefault = e.tabDefault
                ? (0, o.getMeasurement)(this.tabDefault)
                : "";
              this.tabStops = (e.tabStops || "")
                .trim()
                .split(/\s+/)
                .map((e, t) => (t % 2 == 1 ? (0, o.getMeasurement)(e) : e));
              this.textIndent = e.textIndent
                ? (0, o.getMeasurement)(e.textIndent, "0pt")
                : "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.vAlign = (0, o.getStringOption)(e.vAlign, [
                "top",
                "bottom",
                "middle",
              ]);
              this.widows = (0, o.getInteger)({
                data: e.widows,
                defaultValue: 0,
                validate: (e) => e >= 0,
              });
              this.hyphenation = null;
            }
            [r.$toStyle]() {
              const e = (0, s.toStyle)(this, "hAlign");
              "" !== this.marginLeft &&
                (e.paddingLeft = (0, s.measureToString)(this.marginLeft));
              "" !== this.marginRight &&
                (e.paddingight = (0, s.measureToString)(this.marginRight));
              "" !== this.spaceAbove &&
                (e.paddingTop = (0, s.measureToString)(this.spaceAbove));
              "" !== this.spaceBelow &&
                (e.paddingBottom = (0, s.measureToString)(this.spaceBelow));
              if ("" !== this.textIndent) {
                e.textIndent = (0, s.measureToString)(this.textIndent);
                (0, s.fixTextIndent)(e);
              }
              this.lineHeight > 0 &&
                (e.lineHeight = (0, s.measureToString)(this.lineHeight));
              "" !== this.tabDefault &&
                (e.tabSize = (0, s.measureToString)(this.tabDefault));
              this.tabStops.length;
              this.hyphenatation &&
                Object.assign(e, this.hyphenatation[r.$toStyle]());
              return e;
            }
          }
          class PasswordEdit extends r.XFAObject {
            constructor(e) {
              super(d, "passwordEdit", !0);
              this.hScrollPolicy = (0, o.getStringOption)(e.hScrollPolicy, [
                "auto",
                "off",
                "on",
              ]);
              this.id = e.id || "";
              this.passwordChar = e.passwordChar || "*";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.extras = null;
              this.margin = null;
            }
          }
          class Pattern extends r.XFAObject {
            constructor(e) {
              super(d, "pattern", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "crossHatch",
                "crossDiagonal",
                "diagonalLeft",
                "diagonalRight",
                "horizontal",
                "vertical",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
            }
            [r.$toStyle](e) {
              e = e ? e[r.$toStyle]() : "#FFFFFF";
              const t = this.color ? this.color[r.$toStyle]() : "#000000",
                a = "repeating-linear-gradient",
                n = `${e},${e} 5px,${t} 5px,${t} 10px`;
              switch (this.type) {
                case "crossHatch":
                  return `${a}(to top,${n}) ${a}(to right,${n})`;
                case "crossDiagonal":
                  return `${a}(45deg,${n}) ${a}(-45deg,${n})`;
                case "diagonalLeft":
                  return `${a}(45deg,${n})`;
                case "diagonalRight":
                  return `${a}(-45deg,${n})`;
                case "horizontal":
                  return `${a}(to top,${n})`;
                case "vertical":
                  return `${a}(to right,${n})`;
              }
              return "";
            }
          }
          class Picture extends r.StringObject {
            constructor(e) {
              super(d, "picture");
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Proto extends r.XFAObject {
            constructor(e) {
              super(d, "proto", !0);
              this.appearanceFilter = new r.XFAObjectArray();
              this.arc = new r.XFAObjectArray();
              this.area = new r.XFAObjectArray();
              this.assist = new r.XFAObjectArray();
              this.barcode = new r.XFAObjectArray();
              this.bindItems = new r.XFAObjectArray();
              this.bookend = new r.XFAObjectArray();
              this.boolean = new r.XFAObjectArray();
              this.border = new r.XFAObjectArray();
              this.break = new r.XFAObjectArray();
              this.breakAfter = new r.XFAObjectArray();
              this.breakBefore = new r.XFAObjectArray();
              this.button = new r.XFAObjectArray();
              this.calculate = new r.XFAObjectArray();
              this.caption = new r.XFAObjectArray();
              this.certificate = new r.XFAObjectArray();
              this.certificates = new r.XFAObjectArray();
              this.checkButton = new r.XFAObjectArray();
              this.choiceList = new r.XFAObjectArray();
              this.color = new r.XFAObjectArray();
              this.comb = new r.XFAObjectArray();
              this.connect = new r.XFAObjectArray();
              this.contentArea = new r.XFAObjectArray();
              this.corner = new r.XFAObjectArray();
              this.date = new r.XFAObjectArray();
              this.dateTime = new r.XFAObjectArray();
              this.dateTimeEdit = new r.XFAObjectArray();
              this.decimal = new r.XFAObjectArray();
              this.defaultUi = new r.XFAObjectArray();
              this.desc = new r.XFAObjectArray();
              this.digestMethod = new r.XFAObjectArray();
              this.digestMethods = new r.XFAObjectArray();
              this.draw = new r.XFAObjectArray();
              this.edge = new r.XFAObjectArray();
              this.encoding = new r.XFAObjectArray();
              this.encodings = new r.XFAObjectArray();
              this.encrypt = new r.XFAObjectArray();
              this.encryptData = new r.XFAObjectArray();
              this.encryption = new r.XFAObjectArray();
              this.encryptionMethod = new r.XFAObjectArray();
              this.encryptionMethods = new r.XFAObjectArray();
              this.event = new r.XFAObjectArray();
              this.exData = new r.XFAObjectArray();
              this.exObject = new r.XFAObjectArray();
              this.exclGroup = new r.XFAObjectArray();
              this.execute = new r.XFAObjectArray();
              this.extras = new r.XFAObjectArray();
              this.field = new r.XFAObjectArray();
              this.fill = new r.XFAObjectArray();
              this.filter = new r.XFAObjectArray();
              this.float = new r.XFAObjectArray();
              this.font = new r.XFAObjectArray();
              this.format = new r.XFAObjectArray();
              this.handler = new r.XFAObjectArray();
              this.hyphenation = new r.XFAObjectArray();
              this.image = new r.XFAObjectArray();
              this.imageEdit = new r.XFAObjectArray();
              this.integer = new r.XFAObjectArray();
              this.issuers = new r.XFAObjectArray();
              this.items = new r.XFAObjectArray();
              this.keep = new r.XFAObjectArray();
              this.keyUsage = new r.XFAObjectArray();
              this.line = new r.XFAObjectArray();
              this.linear = new r.XFAObjectArray();
              this.lockDocument = new r.XFAObjectArray();
              this.manifest = new r.XFAObjectArray();
              this.margin = new r.XFAObjectArray();
              this.mdp = new r.XFAObjectArray();
              this.medium = new r.XFAObjectArray();
              this.message = new r.XFAObjectArray();
              this.numericEdit = new r.XFAObjectArray();
              this.occur = new r.XFAObjectArray();
              this.oid = new r.XFAObjectArray();
              this.oids = new r.XFAObjectArray();
              this.overflow = new r.XFAObjectArray();
              this.pageArea = new r.XFAObjectArray();
              this.pageSet = new r.XFAObjectArray();
              this.para = new r.XFAObjectArray();
              this.passwordEdit = new r.XFAObjectArray();
              this.pattern = new r.XFAObjectArray();
              this.picture = new r.XFAObjectArray();
              this.radial = new r.XFAObjectArray();
              this.reason = new r.XFAObjectArray();
              this.reasons = new r.XFAObjectArray();
              this.rectangle = new r.XFAObjectArray();
              this.ref = new r.XFAObjectArray();
              this.script = new r.XFAObjectArray();
              this.setProperty = new r.XFAObjectArray();
              this.signData = new r.XFAObjectArray();
              this.signature = new r.XFAObjectArray();
              this.signing = new r.XFAObjectArray();
              this.solid = new r.XFAObjectArray();
              this.speak = new r.XFAObjectArray();
              this.stipple = new r.XFAObjectArray();
              this.subform = new r.XFAObjectArray();
              this.subformSet = new r.XFAObjectArray();
              this.subjectDN = new r.XFAObjectArray();
              this.subjectDNs = new r.XFAObjectArray();
              this.submit = new r.XFAObjectArray();
              this.text = new r.XFAObjectArray();
              this.textEdit = new r.XFAObjectArray();
              this.time = new r.XFAObjectArray();
              this.timeStamp = new r.XFAObjectArray();
              this.toolTip = new r.XFAObjectArray();
              this.traversal = new r.XFAObjectArray();
              this.traverse = new r.XFAObjectArray();
              this.ui = new r.XFAObjectArray();
              this.validate = new r.XFAObjectArray();
              this.value = new r.XFAObjectArray();
              this.variables = new r.XFAObjectArray();
            }
          }
          class Radial extends r.XFAObject {
            constructor(e) {
              super(d, "radial", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "toEdge",
                "toCenter",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
            }
            [r.$toStyle](e) {
              e = e ? e[r.$toStyle]() : "#FFFFFF";
              const t = this.color ? this.color[r.$toStyle]() : "#000000";
              return `radial-gradient(circle at center, ${
                "toEdge" === this.type ? `${e},${t}` : `${t},${e}`
              })`;
            }
          }
          class Reason extends r.StringObject {
            constructor(e) {
              super(d, "reason");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Reasons extends r.XFAObject {
            constructor(e) {
              super(d, "reasons", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.reason = new r.XFAObjectArray();
            }
          }
          class Rectangle extends r.XFAObject {
            constructor(e) {
              super(d, "rectangle", !0);
              this.hand = (0, o.getStringOption)(e.hand, [
                "even",
                "left",
                "right",
              ]);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.corner = new r.XFAObjectArray(4);
              this.edge = new r.XFAObjectArray(4);
              this.fill = null;
            }
            [r.$toHTML]() {
              const e = this.edge.children.length
                  ? this.edge.children[0]
                  : new Edge({}),
                t = e[r.$toStyle](),
                a = Object.create(null);
              this.fill && "visible" === this.fill.presence
                ? Object.assign(a, this.fill[r.$toStyle]())
                : (a.fill = "transparent");
              a.strokeWidth = (0, s.measureToString)(
                "visible" === e.presence ? e.thickness : 0
              );
              a.stroke = t.color;
              const n = (
                  this.corner.children.length
                    ? this.corner.children[0]
                    : new Corner({})
                )[r.$toStyle](),
                i = {
                  name: "svg",
                  children: [
                    {
                      name: "rect",
                      attributes: {
                        xmlns: f,
                        width: "100%",
                        height: "100%",
                        x: 0,
                        y: 0,
                        rx: n.radius,
                        ry: n.radius,
                        style: a,
                      },
                    },
                  ],
                  attributes: {
                    xmlns: f,
                    style: { overflow: "visible" },
                    width: "100%",
                    height: "100%",
                  },
                };
              if (hasMargin(this[r.$getParent]()[r.$getParent]()))
                return o.HTMLResult.success({
                  name: "div",
                  attributes: {
                    style: { display: "inline", width: "100%", height: "100%" },
                  },
                  children: [i],
                });
              i.attributes.style.position = "absolute";
              return o.HTMLResult.success(i);
            }
          }
          class RefElement extends r.StringObject {
            constructor(e) {
              super(d, "ref");
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Script extends r.StringObject {
            constructor(e) {
              super(d, "script");
              this.binding = e.binding || "";
              this.contentType = e.contentType || "";
              this.id = e.id || "";
              this.name = e.name || "";
              this.runAt = (0, o.getStringOption)(e.runAt, [
                "client",
                "both",
                "server",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class SetProperty extends r.XFAObject {
            constructor(e) {
              super(d, "setProperty");
              this.connection = e.connection || "";
              this.ref = e.ref || "";
              this.target = e.target || "";
            }
          }
          t.SetProperty = SetProperty;
          class SignData extends r.XFAObject {
            constructor(e) {
              super(d, "signData", !0);
              this.id = e.id || "";
              this.operation = (0, o.getStringOption)(e.operation, [
                "sign",
                "clear",
                "verify",
              ]);
              this.ref = e.ref || "";
              this.target = e.target || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.filter = null;
              this.manifest = null;
            }
          }
          class Signature extends r.XFAObject {
            constructor(e) {
              super(d, "signature", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, ["PDF1.3", "PDF1.6"]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.border = null;
              this.extras = null;
              this.filter = null;
              this.manifest = null;
              this.margin = null;
            }
          }
          class Signing extends r.XFAObject {
            constructor(e) {
              super(d, "signing", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.certificate = new r.XFAObjectArray();
            }
          }
          class Solid extends r.XFAObject {
            constructor(e) {
              super(d, "solid", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
            }
            [r.$toStyle](e) {
              return e ? e[r.$toStyle]() : "#FFFFFF";
            }
          }
          class Speak extends r.StringObject {
            constructor(e) {
              super(d, "speak");
              this.disable = (0, o.getInteger)({
                data: e.disable,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.id = e.id || "";
              this.priority = (0, o.getStringOption)(e.priority, [
                "custom",
                "caption",
                "name",
                "toolTip",
              ]);
              this.rid = e.rid || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Stipple extends r.XFAObject {
            constructor(e) {
              super(d, "stipple", !0);
              this.id = e.id || "";
              this.rate = (0, o.getInteger)({
                data: e.rate,
                defaultValue: 50,
                validate: (e) => e >= 0 && e <= 100,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.color = null;
              this.extras = null;
            }
            [r.$toStyle](e) {
              const t = this.rate / 100;
              return c.Util.makeHexColor(
                Math.round(e.value.r * (1 - t) + this.value.r * t),
                Math.round(e.value.g * (1 - t) + this.value.g * t),
                Math.round(e.value.b * (1 - t) + this.value.b * t)
              );
            }
          }
          class Subform extends r.XFAObject {
            constructor(e) {
              super(d, "subform", !0);
              this.access = (0, o.getStringOption)(e.access, [
                "open",
                "nonInteractive",
                "protected",
                "readOnly",
              ]);
              this.allowMacro = (0, o.getInteger)({
                data: e.allowMacro,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.anchorType = (0, o.getStringOption)(e.anchorType, [
                "topLeft",
                "bottomCenter",
                "bottomLeft",
                "bottomRight",
                "middleCenter",
                "middleLeft",
                "middleRight",
                "topCenter",
                "topRight",
              ]);
              this.colSpan = (0, o.getInteger)({
                data: e.colSpan,
                defaultValue: 1,
                validate: (e) => e >= 1 || -1 === e,
              });
              this.columnWidths = (e.columnWidths || "")
                .trim()
                .split(/\s+/)
                .map((e) => ("-1" === e ? -1 : (0, o.getMeasurement)(e)));
              this.h = e.h ? (0, o.getMeasurement)(e.h) : "";
              this.hAlign = (0, o.getStringOption)(e.hAlign, [
                "left",
                "center",
                "justify",
                "justifyAll",
                "radix",
                "right",
              ]);
              this.id = e.id || "";
              this.layout = (0, o.getStringOption)(e.layout, [
                "position",
                "lr-tb",
                "rl-row",
                "rl-tb",
                "row",
                "table",
                "tb",
              ]);
              this.locale = e.locale || "";
              this.maxH = (0, o.getMeasurement)(e.maxH, "0pt");
              this.maxW = (0, o.getMeasurement)(e.maxW, "0pt");
              this.mergeMode = (0, o.getStringOption)(e.mergeMode, [
                "consumeData",
                "matchTemplate",
              ]);
              this.minH = (0, o.getMeasurement)(e.minH, "0pt");
              this.minW = (0, o.getMeasurement)(e.minW, "0pt");
              this.name = e.name || "";
              this.presence = (0, o.getStringOption)(e.presence, [
                "visible",
                "hidden",
                "inactive",
                "invisible",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.restoreState = (0, o.getStringOption)(e.restoreState, [
                "manual",
                "auto",
              ]);
              this.scope = (0, o.getStringOption)(e.scope, ["name", "none"]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.w = e.w ? (0, o.getMeasurement)(e.w) : "";
              this.x = (0, o.getMeasurement)(e.x, "0pt");
              this.y = (0, o.getMeasurement)(e.y, "0pt");
              this.assist = null;
              this.bind = null;
              this.bookend = null;
              this.border = null;
              this.break = null;
              this.calculate = null;
              this.desc = null;
              this.extras = null;
              this.keep = null;
              this.margin = null;
              this.occur = null;
              this.overflow = null;
              this.pageSet = null;
              this.para = null;
              this.traversal = null;
              this.validate = null;
              this.variables = null;
              this.area = new r.XFAObjectArray();
              this.breakAfter = new r.XFAObjectArray();
              this.breakBefore = new r.XFAObjectArray();
              this.connect = new r.XFAObjectArray();
              this.draw = new r.XFAObjectArray();
              this.event = new r.XFAObjectArray();
              this.exObject = new r.XFAObjectArray();
              this.exclGroup = new r.XFAObjectArray();
              this.field = new r.XFAObjectArray();
              this.proto = new r.XFAObjectArray();
              this.setProperty = new r.XFAObjectArray();
              this.subform = new r.XFAObjectArray();
              this.subformSet = new r.XFAObjectArray();
            }
            [r.$getSubformParent]() {
              const e = this[r.$getParent]();
              return e instanceof SubformSet ? e[r.$getSubformParent]() : e;
            }
            [r.$isBindable]() {
              return !0;
            }
            [r.$isThereMoreWidth]() {
              return (
                (this.layout.endsWith("-tb") &&
                  0 === this[r.$extra].attempt &&
                  this[r.$extra].numberInLine > 0) ||
                this[r.$getParent]()[r.$isThereMoreWidth]()
              );
            }
            *[r.$getContainedChildren]() {
              yield* getContainedChildren(this);
            }
            [r.$flushHTML]() {
              return (0, i.flushHTML)(this);
            }
            [r.$addHTML](e, t) {
              (0, i.addHTML)(this, e, t);
            }
            [r.$getAvailableSpace]() {
              return (0, i.getAvailableSpace)(this);
            }
            [r.$isSplittable]() {
              const e = this[r.$getSubformParent]();
              if (!e[r.$isSplittable]()) return !1;
              if (void 0 !== this[r.$extra]._isSplittable)
                return this[r.$extra]._isSplittable;
              if ("position" === this.layout || this.layout.includes("row")) {
                this[r.$extra]._isSplittable = !1;
                return !1;
              }
              if (this.keep && "none" !== this.keep.intact) {
                this[r.$extra]._isSplittable = !1;
                return !1;
              }
              if (
                e.layout &&
                e.layout.endsWith("-tb") &&
                0 !== e[r.$extra].numberInLine
              )
                return !1;
              this[r.$extra]._isSplittable = !0;
              return !0;
            }
            [r.$toHTML](e) {
              setTabIndex(this);
              if (this.break) {
                if (
                  "auto" !== this.break.after ||
                  "" !== this.break.afterTarget
                ) {
                  const e = new BreakAfter({
                    targetType: this.break.after,
                    target: this.break.afterTarget,
                    startNew: this.break.startNew.toString(),
                  });
                  e[r.$globalData] = this[r.$globalData];
                  this[r.$appendChild](e);
                  this.breakAfter.push(e);
                }
                if (
                  "auto" !== this.break.before ||
                  "" !== this.break.beforeTarget
                ) {
                  const e = new BreakBefore({
                    targetType: this.break.before,
                    target: this.break.beforeTarget,
                    startNew: this.break.startNew.toString(),
                  });
                  e[r.$globalData] = this[r.$globalData];
                  this[r.$appendChild](e);
                  this.breakBefore.push(e);
                }
                if ("" !== this.break.overflowTarget) {
                  const e = new Overflow({
                    target: this.break.overflowTarget,
                    leader: this.break.overflowLeader,
                    trailer: this.break.overflowTrailer,
                  });
                  e[r.$globalData] = this[r.$globalData];
                  this[r.$appendChild](e);
                  this.overflow.push(e);
                }
                this[r.$removeChild](this.break);
                this.break = null;
              }
              if ("hidden" === this.presence || "inactive" === this.presence)
                return o.HTMLResult.EMPTY;
              (this.breakBefore.children.length > 1 ||
                this.breakAfter.children.length > 1) &&
                (0, c.warn)(
                  "XFA - Several breakBefore or breakAfter in subforms: please file a bug."
                );
              if (this.breakBefore.children.length >= 1) {
                const e = this.breakBefore.children[0];
                if (handleBreak(e)) return o.HTMLResult.breakNode(e);
              }
              if (this[r.$extra] && this[r.$extra].afterBreakAfter)
                return o.HTMLResult.EMPTY;
              (0, s.fixDimensions)(this);
              const t = [],
                a = { id: this[r.$uid], class: [] };
              (0, s.setAccess)(this, a.class);
              this[r.$extra] || (this[r.$extra] = Object.create(null));
              Object.assign(this[r.$extra], {
                children: t,
                line: null,
                attributes: a,
                attempt: 0,
                numberInLine: 0,
                availableSpace: {
                  width: Math.min(this.w || 1 / 0, e.width),
                  height: Math.min(this.h || 1 / 0, e.height),
                },
                width: 0,
                height: 0,
                prevHeight: 0,
                currentWidth: 0,
              });
              const n = this[r.$getTemplateRoot](),
                l = n[r.$extra].noLayoutFailure,
                h = this[r.$isSplittable]();
              h || setFirstUnsplittable(this);
              if (!(0, i.checkDimensions)(this, e)) return o.HTMLResult.FAILURE;
              const u = new Set([
                "area",
                "draw",
                "exclGroup",
                "field",
                "subform",
                "subformSet",
              ]);
              if (this.layout.includes("row")) {
                const e = this[r.$getSubformParent]().columnWidths;
                if (Array.isArray(e) && e.length > 0) {
                  this[r.$extra].columnWidths = e;
                  this[r.$extra].currentColumn = 0;
                }
              }
              const d = (0, s.toStyle)(
                  this,
                  "anchorType",
                  "dimensions",
                  "position",
                  "presence",
                  "border",
                  "margin",
                  "hAlign"
                ),
                f = ["xfaSubform"],
                g = (0, s.layoutClass)(this);
              g && f.push(g);
              a.style = d;
              a.class = f;
              this.name && (a.xfaName = this.name);
              if (this.overflow) {
                const t = this.overflow[r.$getExtra]();
                if (t.addLeader) {
                  t.addLeader = !1;
                  handleOverflow(this, t.leader, e);
                }
              }
              this[r.$pushPara]();
              const p = "lr-tb" === this.layout || "rl-tb" === this.layout,
                m = p ? 2 : 1;
              for (; this[r.$extra].attempt < m; this[r.$extra].attempt++) {
                p &&
                  1 === this[r.$extra].attempt &&
                  (this[r.$extra].numberInLine = 0);
                const e = this[r.$childrenToHTML]({ filter: u, include: !0 });
                if (e.success) break;
                if (e.isBreak()) {
                  this[r.$popPara]();
                  return e;
                }
                if (
                  p &&
                  0 === this[r.$extra].attempt &&
                  0 === this[r.$extra].numberInLine &&
                  !n[r.$extra].noLayoutFailure
                ) {
                  this[r.$extra].attempt = m;
                  break;
                }
              }
              this[r.$popPara]();
              h || unsetFirstUnsplittable(this);
              n[r.$extra].noLayoutFailure = l;
              if (this[r.$extra].attempt === m) {
                this.overflow &&
                  (this[r.$getTemplateRoot]()[r.$extra].overflowNode =
                    this.overflow);
                h || delete this[r.$extra];
                return o.HTMLResult.FAILURE;
              }
              if (this.overflow) {
                const t = this.overflow[r.$getExtra]();
                if (t.addTrailer) {
                  t.addTrailer = !1;
                  handleOverflow(this, t.trailer, e);
                }
              }
              let b = 0,
                y = 0;
              if (this.margin) {
                b = this.margin.leftInset + this.margin.rightInset;
                y = this.margin.topInset + this.margin.bottomInset;
              }
              const w = Math.max(this[r.$extra].width + b, this.w || 0),
                S = Math.max(this[r.$extra].height + y, this.h || 0),
                x = [this.x, this.y, w, S];
              "" === this.w && (d.width = (0, s.measureToString)(w));
              "" === this.h && (d.height = (0, s.measureToString)(S));
              if (("0px" === d.width || "0px" === d.height) && 0 === t.length)
                return o.HTMLResult.EMPTY;
              const C = { name: "div", attributes: a, children: t };
              applyAssist(this, a);
              const k = o.HTMLResult.success((0, s.createWrapper)(this, C), x);
              if (this.breakAfter.children.length >= 1) {
                const e = this.breakAfter.children[0];
                if (handleBreak(e)) {
                  this[r.$extra].afterBreakAfter = k;
                  return o.HTMLResult.breakNode(e);
                }
              }
              delete this[r.$extra];
              return k;
            }
          }
          class SubformSet extends r.XFAObject {
            constructor(e) {
              super(d, "subformSet", !0);
              this.id = e.id || "";
              this.name = e.name || "";
              this.relation = (0, o.getStringOption)(e.relation, [
                "ordered",
                "choice",
                "unordered",
              ]);
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.bookend = null;
              this.break = null;
              this.desc = null;
              this.extras = null;
              this.occur = null;
              this.overflow = null;
              this.breakAfter = new r.XFAObjectArray();
              this.breakBefore = new r.XFAObjectArray();
              this.subform = new r.XFAObjectArray();
              this.subformSet = new r.XFAObjectArray();
            }
            *[r.$getContainedChildren]() {
              yield* getContainedChildren(this);
            }
            [r.$getSubformParent]() {
              let e = this[r.$getParent]();
              for (; !(e instanceof Subform); ) e = e[r.$getParent]();
              return e;
            }
            [r.$isBindable]() {
              return !0;
            }
          }
          class SubjectDN extends r.ContentObject {
            constructor(e) {
              super(d, "subjectDN");
              this.delimiter = e.delimiter || ",";
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              this[r.$content] = new Map(
                this[r.$content].split(this.delimiter).map((e) => {
                  (e = e.split("=", 2))[0] = e[0].trim();
                  return e;
                })
              );
            }
          }
          class SubjectDNs extends r.XFAObject {
            constructor(e) {
              super(d, "subjectDNs", !0);
              this.id = e.id || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.subjectDN = new r.XFAObjectArray();
            }
          }
          class Submit extends r.XFAObject {
            constructor(e) {
              super(d, "submit", !0);
              this.embedPDF = (0, o.getInteger)({
                data: e.embedPDF,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.format = (0, o.getStringOption)(e.format, [
                "xdp",
                "formdata",
                "pdf",
                "urlencoded",
                "xfd",
                "xml",
              ]);
              this.id = e.id || "";
              this.target = e.target || "";
              this.textEncoding = (0, o.getKeyword)({
                data: e.textEncoding ? e.textEncoding.toLowerCase() : "",
                defaultValue: "",
                validate: (e) =>
                  [
                    "utf-8",
                    "big-five",
                    "fontspecific",
                    "gbk",
                    "gb-18030",
                    "gb-2312",
                    "ksc-5601",
                    "none",
                    "shift-jis",
                    "ucs-2",
                    "utf-16",
                  ].includes(e) || e.match(/iso-8859-\d{2}/),
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.xdpContent = e.xdpContent || "";
              this.encrypt = null;
              this.encryptData = new r.XFAObjectArray();
              this.signData = new r.XFAObjectArray();
            }
          }
          class Template extends r.XFAObject {
            constructor(e) {
              super(d, "template", !0);
              this.baseProfile = (0, o.getStringOption)(e.baseProfile, [
                "full",
                "interactiveForms",
              ]);
              this.extras = null;
              this.subform = new r.XFAObjectArray();
            }
            [r.$finalize]() {
              0 === this.subform.children.length &&
                (0, c.warn)("XFA - No subforms in template node.");
              this.subform.children.length >= 2 &&
                (0, c.warn)(
                  "XFA - Several subforms in template node: please file a bug."
                );
              this[r.$tabIndex] = 5e3;
            }
            [r.$isSplittable]() {
              return !0;
            }
            [r.$searchNode](e, t) {
              return e.startsWith("#")
                ? [this[r.$ids].get(e.slice(1))]
                : (0, u.searchNode)(this, t, e, !0, !0);
            }
            *[r.$toPages]() {
              if (!this.subform.children.length)
                return o.HTMLResult.success({ name: "div", children: [] });
              this[r.$extra] = {
                overflowNode: null,
                firstUnsplittable: null,
                currentContentArea: null,
                currentPageArea: null,
                noLayoutFailure: !1,
                pageNumber: 1,
                pagePosition: "first",
                oddOrEven: "odd",
                blankOrNotBlank: "nonBlank",
                paraStack: [],
              };
              const e = this.subform.children[0];
              e.pageSet[r.$cleanPage]();
              const t = e.pageSet.pageArea.children,
                a = { name: "div", children: [] };
              let n = null,
                i = null,
                s = null;
              if (e.breakBefore.children.length >= 1) {
                i = e.breakBefore.children[0];
                s = i.target;
              } else if (
                e.subform.children.length >= 1 &&
                e.subform.children[0].breakBefore.children.length >= 1
              ) {
                i = e.subform.children[0].breakBefore.children[0];
                s = i.target;
              } else if (e.break && e.break.beforeTarget) {
                i = e.break;
                s = i.beforeTarget;
              } else if (
                e.subform.children.length >= 1 &&
                e.subform.children[0].break &&
                e.subform.children[0].break.beforeTarget
              ) {
                i = e.subform.children[0].break;
                s = i.beforeTarget;
              }
              if (i) {
                const e = this[r.$searchNode](s, i[r.$getParent]());
                if (e instanceof PageArea) {
                  n = e;
                  i[r.$extra] = {};
                }
              }
              n || (n = t[0]);
              n[r.$extra] = { numberOfUse: 1 };
              const l = n[r.$getParent]();
              l[r.$extra] = {
                numberOfUse: 1,
                pageIndex: l.pageArea.children.indexOf(n),
                pageSetIndex: 0,
              };
              let h,
                u = null,
                d = null,
                f = !0,
                g = 0,
                p = 0;
              for (;;) {
                if (f) g = 0;
                else {
                  a.children.pop();
                  if (3 == ++g) {
                    (0, c.warn)(
                      "XFA - Something goes wrong: please file a bug."
                    );
                    return a;
                  }
                }
                h = null;
                this[r.$extra].currentPageArea = n;
                const t = n[r.$toHTML]().html;
                a.children.push(t);
                if (u) {
                  this[r.$extra].noLayoutFailure = !0;
                  t.children.push(u[r.$toHTML](n[r.$extra].space).html);
                  u = null;
                }
                if (d) {
                  this[r.$extra].noLayoutFailure = !0;
                  t.children.push(d[r.$toHTML](n[r.$extra].space).html);
                  d = null;
                }
                const i = n.contentArea.children,
                  s = t.children.filter((e) =>
                    e.attributes.class.includes("xfaContentarea")
                  );
                f = !1;
                this[r.$extra].firstUnsplittable = null;
                this[r.$extra].noLayoutFailure = !1;
                const flush = (t) => {
                  const a = e[r.$flushHTML]();
                  if (a) {
                    f = f || (a.children && 0 !== a.children.length);
                    s[t].children.push(a);
                  }
                };
                for (let t = p, n = i.length; t < n; t++) {
                  const n = (this[r.$extra].currentContentArea = i[t]),
                    o = { width: n.w, height: n.h };
                  p = 0;
                  if (u) {
                    s[t].children.push(u[r.$toHTML](o).html);
                    u = null;
                  }
                  if (d) {
                    s[t].children.push(d[r.$toHTML](o).html);
                    d = null;
                  }
                  const c = e[r.$toHTML](o);
                  if (c.success) {
                    if (c.html) {
                      f =
                        f || (c.html.children && 0 !== c.html.children.length);
                      s[t].children.push(c.html);
                    } else !f && a.children.length > 1 && a.children.pop();
                    return a;
                  }
                  if (c.isBreak()) {
                    const e = c.breakNode;
                    flush(t);
                    if ("auto" === e.targetType) continue;
                    if (e.leader) {
                      u = this[r.$searchNode](e.leader, e[r.$getParent]());
                      u = u ? u[0] : null;
                    }
                    if (e.trailer) {
                      d = this[r.$searchNode](e.trailer, e[r.$getParent]());
                      d = d ? d[0] : null;
                    }
                    if ("pageArea" === e.targetType) {
                      h = e[r.$extra].target;
                      t = 1 / 0;
                    } else if (e[r.$extra].target) {
                      h = e[r.$extra].target;
                      p = e[r.$extra].index + 1;
                      t = 1 / 0;
                    } else t = e[r.$extra].index;
                  } else if (this[r.$extra].overflowNode) {
                    const e = this[r.$extra].overflowNode;
                    this[r.$extra].overflowNode = null;
                    const a = e[r.$getExtra](),
                      n = a.target;
                    a.addLeader = null !== a.leader;
                    a.addTrailer = null !== a.trailer;
                    flush(t);
                    const s = t;
                    t = 1 / 0;
                    if (n instanceof PageArea) h = n;
                    else if (n instanceof ContentArea) {
                      const e = i.indexOf(n);
                      if (-1 !== e) e > s ? (t = e - 1) : (p = e);
                      else {
                        h = n[r.$getParent]();
                        p = h.contentArea.children.indexOf(n);
                      }
                    }
                  } else flush(t);
                }
                this[r.$extra].pageNumber += 1;
                h &&
                  (h[r.$isUsable]()
                    ? (h[r.$extra].numberOfUse += 1)
                    : (h = null));
                n = h || n[r.$getNextPage]();
                yield null;
              }
            }
          }
          t.Template = Template;
          class Text extends r.ContentObject {
            constructor(e) {
              super(d, "text");
              this.id = e.id || "";
              this.maxChars = (0, o.getInteger)({
                data: e.maxChars,
                defaultValue: 0,
                validate: (e) => e >= 0,
              });
              this.name = e.name || "";
              this.rid = e.rid || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$acceptWhitespace]() {
              return !0;
            }
            [r.$onChild](e) {
              if (e[r.$namespaceId] === n.NamespaceIds.xhtml.id) {
                this[r.$content] = e;
                return !0;
              }
              (0, c.warn)(`XFA - Invalid content in Text: ${e[r.$nodeName]}.`);
              return !1;
            }
            [r.$onText](e) {
              this[r.$content] instanceof r.XFAObject || super[r.$onText](e);
            }
            [r.$finalize]() {
              "string" == typeof this[r.$content] &&
                (this[r.$content] = this[r.$content].replace(/\r\n/g, "\n"));
            }
            [r.$getExtra]() {
              return "string" == typeof this[r.$content]
                ? this[r.$content]
                    .split(/[\u2029\u2028\n]/)
                    .reduce((e, t) => {
                      t && e.push(t);
                      return e;
                    }, [])
                    .join("\n")
                : this[r.$content][r.$text]();
            }
            [r.$toHTML](e) {
              if ("string" == typeof this[r.$content]) {
                const e = valueToHtml(this[r.$content]).html;
                if (this[r.$content].includes("\u2029")) {
                  e.name = "div";
                  e.children = [];
                  this[r.$content]
                    .split("\u2029")
                    .map((e) =>
                      e.split(/[\u2028\n]/).reduce((e, t) => {
                        e.push({ name: "span", value: t }, { name: "br" });
                        return e;
                      }, [])
                    )
                    .forEach((t) => {
                      e.children.push({ name: "p", children: t });
                    });
                } else if (/[\u2028\n]/.test(this[r.$content])) {
                  e.name = "div";
                  e.children = [];
                  this[r.$content].split(/[\u2028\n]/).forEach((t) => {
                    e.children.push({ name: "span", value: t }, { name: "br" });
                  });
                }
                return o.HTMLResult.success(e);
              }
              return this[r.$content][r.$toHTML](e);
            }
          }
          t.Text = Text;
          class TextEdit extends r.XFAObject {
            constructor(e) {
              super(d, "textEdit", !0);
              this.allowRichText = (0, o.getInteger)({
                data: e.allowRichText,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.hScrollPolicy = (0, o.getStringOption)(e.hScrollPolicy, [
                "auto",
                "off",
                "on",
              ]);
              this.id = e.id || "";
              this.multiLine = (0, o.getInteger)({
                data: e.multiLine,
                defaultValue: "",
                validate: (e) => 0 === e || 1 === e,
              });
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.vScrollPolicy = (0, o.getStringOption)(e.vScrollPolicy, [
                "auto",
                "off",
                "on",
              ]);
              this.border = null;
              this.comb = null;
              this.extras = null;
              this.margin = null;
            }
            [r.$toHTML](e) {
              const t = (0, s.toStyle)(this, "border", "font", "margin");
              let a;
              const n = this[r.$getParent]()[r.$getParent]();
              "" === this.multiLine &&
                (this.multiLine = n instanceof Draw ? 1 : 0);
              a =
                1 === this.multiLine
                  ? {
                      name: "textarea",
                      attributes: {
                        dataId: (n[r.$data] && n[r.$data][r.$uid]) || n[r.$uid],
                        fieldId: n[r.$uid],
                        class: ["xfaTextfield"],
                        style: t,
                        "aria-label": ariaLabel(n),
                        "aria-required": !1,
                      },
                    }
                  : {
                      name: "input",
                      attributes: {
                        type: "text",
                        dataId: (n[r.$data] && n[r.$data][r.$uid]) || n[r.$uid],
                        fieldId: n[r.$uid],
                        class: ["xfaTextfield"],
                        style: t,
                        "aria-label": ariaLabel(n),
                        "aria-required": !1,
                      },
                    };
              if (isRequired(n)) {
                a.attributes["aria-required"] = !0;
                a.attributes.required = !0;
              }
              return o.HTMLResult.success({
                name: "label",
                attributes: { class: ["xfaLabel"] },
                children: [a],
              });
            }
          }
          class Time extends r.StringObject {
            constructor(e) {
              super(d, "time");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
            [r.$finalize]() {
              const e = this[r.$content].trim();
              this[r.$content] = e ? new Date(e) : null;
            }
            [r.$toHTML](e) {
              return valueToHtml(
                this[r.$content] ? this[r.$content].toString() : ""
              );
            }
          }
          class TimeStamp extends r.XFAObject {
            constructor(e) {
              super(d, "timeStamp");
              this.id = e.id || "";
              this.server = e.server || "";
              this.type = (0, o.getStringOption)(e.type, [
                "optional",
                "required",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class ToolTip extends r.StringObject {
            constructor(e) {
              super(d, "toolTip");
              this.id = e.id || "";
              this.rid = e.rid || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Traversal extends r.XFAObject {
            constructor(e) {
              super(d, "traversal", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.traverse = new r.XFAObjectArray();
            }
          }
          class Traverse extends r.XFAObject {
            constructor(e) {
              super(d, "traverse", !0);
              this.id = e.id || "";
              this.operation = (0, o.getStringOption)(e.operation, [
                "next",
                "back",
                "down",
                "first",
                "left",
                "right",
                "up",
              ]);
              this.ref = e.ref || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.script = null;
            }
            get name() {
              return this.operation;
            }
            [r.$isTransparent]() {
              return !1;
            }
          }
          class Ui extends r.XFAObject {
            constructor(e) {
              super(d, "ui", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.picture = null;
              this.barcode = null;
              this.button = null;
              this.checkButton = null;
              this.choiceList = null;
              this.dateTimeEdit = null;
              this.defaultUi = null;
              this.imageEdit = null;
              this.numericEdit = null;
              this.passwordEdit = null;
              this.signature = null;
              this.textEdit = null;
            }
            [r.$getExtra]() {
              if (void 0 === this[r.$extra]) {
                for (const e of Object.getOwnPropertyNames(this)) {
                  if ("extras" === e || "picture" === e) continue;
                  const t = this[e];
                  if (t instanceof r.XFAObject) {
                    this[r.$extra] = t;
                    return t;
                  }
                }
                this[r.$extra] = null;
              }
              return this[r.$extra];
            }
            [r.$toHTML](e) {
              const t = this[r.$getExtra]();
              return t ? t[r.$toHTML](e) : o.HTMLResult.EMPTY;
            }
          }
          class Validate extends r.XFAObject {
            constructor(e) {
              super(d, "validate", !0);
              this.formatTest = (0, o.getStringOption)(e.formatTest, [
                "warning",
                "disabled",
                "error",
              ]);
              this.id = e.id || "";
              this.nullTest = (0, o.getStringOption)(e.nullTest, [
                "disabled",
                "error",
                "warning",
              ]);
              this.scriptTest = (0, o.getStringOption)(e.scriptTest, [
                "error",
                "disabled",
                "warning",
              ]);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.extras = null;
              this.message = null;
              this.picture = null;
              this.script = null;
            }
          }
          class Value extends r.XFAObject {
            constructor(e) {
              super(d, "value", !0);
              this.id = e.id || "";
              this.override = (0, o.getInteger)({
                data: e.override,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.relevant = (0, o.getRelevant)(e.relevant);
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.arc = null;
              this.boolean = null;
              this.date = null;
              this.dateTime = null;
              this.decimal = null;
              this.exData = null;
              this.float = null;
              this.image = null;
              this.integer = null;
              this.line = null;
              this.rectangle = null;
              this.text = null;
              this.time = null;
            }
            [r.$setValue](e) {
              const t = this[r.$getParent]();
              if (t instanceof Field && t.ui && t.ui.imageEdit) {
                if (!this.image) {
                  this.image = new Image({});
                  this[r.$appendChild](this.image);
                }
                this.image[r.$content] = e[r.$content];
                return;
              }
              const a = e[r.$nodeName];
              if (null === this[a]) {
                for (const e of Object.getOwnPropertyNames(this)) {
                  const t = this[e];
                  if (t instanceof r.XFAObject) {
                    this[e] = null;
                    this[r.$removeChild](t);
                  }
                }
                this[e[r.$nodeName]] = e;
                this[r.$appendChild](e);
              } else this[a][r.$content] = e[r.$content];
            }
            [r.$text]() {
              if (this.exData)
                return "string" == typeof this.exData[r.$content]
                  ? this.exData[r.$content].trim()
                  : this.exData[r.$content][r.$text]().trim();
              for (const e of Object.getOwnPropertyNames(this)) {
                if ("image" === e) continue;
                const t = this[e];
                if (t instanceof r.XFAObject)
                  return (t[r.$content] || "").toString().trim();
              }
              return null;
            }
            [r.$toHTML](e) {
              for (const t of Object.getOwnPropertyNames(this)) {
                const a = this[t];
                if (a instanceof r.XFAObject) return a[r.$toHTML](e);
              }
              return o.HTMLResult.EMPTY;
            }
          }
          t.Value = Value;
          class Variables extends r.XFAObject {
            constructor(e) {
              super(d, "variables", !0);
              this.id = e.id || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
              this.boolean = new r.XFAObjectArray();
              this.date = new r.XFAObjectArray();
              this.dateTime = new r.XFAObjectArray();
              this.decimal = new r.XFAObjectArray();
              this.exData = new r.XFAObjectArray();
              this.float = new r.XFAObjectArray();
              this.image = new r.XFAObjectArray();
              this.integer = new r.XFAObjectArray();
              this.manifest = new r.XFAObjectArray();
              this.script = new r.XFAObjectArray();
              this.text = new r.XFAObjectArray();
              this.time = new r.XFAObjectArray();
            }
            [r.$isTransparent]() {
              return !0;
            }
          }
          class TemplateNamespace {
            static [n.$buildXFAObject](e, t) {
              if (TemplateNamespace.hasOwnProperty(e)) {
                const a = TemplateNamespace[e](t);
                a[r.$setSetAttributes](t);
                return a;
              }
            }
            static appearanceFilter(e) {
              return new AppearanceFilter(e);
            }
            static arc(e) {
              return new Arc(e);
            }
            static area(e) {
              return new Area(e);
            }
            static assist(e) {
              return new Assist(e);
            }
            static barcode(e) {
              return new Barcode(e);
            }
            static bind(e) {
              return new Bind(e);
            }
            static bindItems(e) {
              return new BindItems(e);
            }
            static bookend(e) {
              return new Bookend(e);
            }
            static boolean(e) {
              return new BooleanElement(e);
            }
            static border(e) {
              return new Border(e);
            }
            static break(e) {
              return new Break(e);
            }
            static breakAfter(e) {
              return new BreakAfter(e);
            }
            static breakBefore(e) {
              return new BreakBefore(e);
            }
            static button(e) {
              return new Button(e);
            }
            static calculate(e) {
              return new Calculate(e);
            }
            static caption(e) {
              return new Caption(e);
            }
            static certificate(e) {
              return new Certificate(e);
            }
            static certificates(e) {
              return new Certificates(e);
            }
            static checkButton(e) {
              return new CheckButton(e);
            }
            static choiceList(e) {
              return new ChoiceList(e);
            }
            static color(e) {
              return new Color(e);
            }
            static comb(e) {
              return new Comb(e);
            }
            static connect(e) {
              return new Connect(e);
            }
            static contentArea(e) {
              return new ContentArea(e);
            }
            static corner(e) {
              return new Corner(e);
            }
            static date(e) {
              return new DateElement(e);
            }
            static dateTime(e) {
              return new DateTime(e);
            }
            static dateTimeEdit(e) {
              return new DateTimeEdit(e);
            }
            static decimal(e) {
              return new Decimal(e);
            }
            static defaultUi(e) {
              return new DefaultUi(e);
            }
            static desc(e) {
              return new Desc(e);
            }
            static digestMethod(e) {
              return new DigestMethod(e);
            }
            static digestMethods(e) {
              return new DigestMethods(e);
            }
            static draw(e) {
              return new Draw(e);
            }
            static edge(e) {
              return new Edge(e);
            }
            static encoding(e) {
              return new Encoding(e);
            }
            static encodings(e) {
              return new Encodings(e);
            }
            static encrypt(e) {
              return new Encrypt(e);
            }
            static encryptData(e) {
              return new EncryptData(e);
            }
            static encryption(e) {
              return new Encryption(e);
            }
            static encryptionMethod(e) {
              return new EncryptionMethod(e);
            }
            static encryptionMethods(e) {
              return new EncryptionMethods(e);
            }
            static event(e) {
              return new Event(e);
            }
            static exData(e) {
              return new ExData(e);
            }
            static exObject(e) {
              return new ExObject(e);
            }
            static exclGroup(e) {
              return new ExclGroup(e);
            }
            static execute(e) {
              return new Execute(e);
            }
            static extras(e) {
              return new Extras(e);
            }
            static field(e) {
              return new Field(e);
            }
            static fill(e) {
              return new Fill(e);
            }
            static filter(e) {
              return new Filter(e);
            }
            static float(e) {
              return new Float(e);
            }
            static font(e) {
              return new Font(e);
            }
            static format(e) {
              return new Format(e);
            }
            static handler(e) {
              return new Handler(e);
            }
            static hyphenation(e) {
              return new Hyphenation(e);
            }
            static image(e) {
              return new Image(e);
            }
            static imageEdit(e) {
              return new ImageEdit(e);
            }
            static integer(e) {
              return new Integer(e);
            }
            static issuers(e) {
              return new Issuers(e);
            }
            static items(e) {
              return new Items(e);
            }
            static keep(e) {
              return new Keep(e);
            }
            static keyUsage(e) {
              return new KeyUsage(e);
            }
            static line(e) {
              return new Line(e);
            }
            static linear(e) {
              return new Linear(e);
            }
            static lockDocument(e) {
              return new LockDocument(e);
            }
            static manifest(e) {
              return new Manifest(e);
            }
            static margin(e) {
              return new Margin(e);
            }
            static mdp(e) {
              return new Mdp(e);
            }
            static medium(e) {
              return new Medium(e);
            }
            static message(e) {
              return new Message(e);
            }
            static numericEdit(e) {
              return new NumericEdit(e);
            }
            static occur(e) {
              return new Occur(e);
            }
            static oid(e) {
              return new Oid(e);
            }
            static oids(e) {
              return new Oids(e);
            }
            static overflow(e) {
              return new Overflow(e);
            }
            static pageArea(e) {
              return new PageArea(e);
            }
            static pageSet(e) {
              return new PageSet(e);
            }
            static para(e) {
              return new Para(e);
            }
            static passwordEdit(e) {
              return new PasswordEdit(e);
            }
            static pattern(e) {
              return new Pattern(e);
            }
            static picture(e) {
              return new Picture(e);
            }
            static proto(e) {
              return new Proto(e);
            }
            static radial(e) {
              return new Radial(e);
            }
            static reason(e) {
              return new Reason(e);
            }
            static reasons(e) {
              return new Reasons(e);
            }
            static rectangle(e) {
              return new Rectangle(e);
            }
            static ref(e) {
              return new RefElement(e);
            }
            static script(e) {
              return new Script(e);
            }
            static setProperty(e) {
              return new SetProperty(e);
            }
            static signData(e) {
              return new SignData(e);
            }
            static signature(e) {
              return new Signature(e);
            }
            static signing(e) {
              return new Signing(e);
            }
            static solid(e) {
              return new Solid(e);
            }
            static speak(e) {
              return new Speak(e);
            }
            static stipple(e) {
              return new Stipple(e);
            }
            static subform(e) {
              return new Subform(e);
            }
            static subformSet(e) {
              return new SubformSet(e);
            }
            static subjectDN(e) {
              return new SubjectDN(e);
            }
            static subjectDNs(e) {
              return new SubjectDNs(e);
            }
            static submit(e) {
              return new Submit(e);
            }
            static template(e) {
              return new Template(e);
            }
            static text(e) {
              return new Text(e);
            }
            static textEdit(e) {
              return new TextEdit(e);
            }
            static time(e) {
              return new Time(e);
            }
            static timeStamp(e) {
              return new TimeStamp(e);
            }
            static toolTip(e) {
              return new ToolTip(e);
            }
            static traversal(e) {
              return new Traversal(e);
            }
            static traverse(e) {
              return new Traverse(e);
            }
            static ui(e) {
              return new Ui(e);
            }
            static validate(e) {
              return new Validate(e);
            }
            static value(e) {
              return new Value(e);
            }
            static variables(e) {
              return new Variables(e);
            }
          }
          t.TemplateNamespace = TemplateNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.addHTML = function addHTML(e, t, a) {
            const i = e[r.$extra],
              s = i.availableSpace,
              [o, c, l, h] = a;
            switch (e.layout) {
              case "position":
                i.width = Math.max(i.width, o + l);
                i.height = Math.max(i.height, c + h);
                i.children.push(t);
                break;
              case "lr-tb":
              case "rl-tb":
                if (!i.line || 1 === i.attempt) {
                  i.line = createLine(e, []);
                  i.children.push(i.line);
                  i.numberInLine = 0;
                }
                i.numberInLine += 1;
                i.line.children.push(t);
                if (0 === i.attempt) {
                  i.currentWidth += l;
                  i.height = Math.max(i.height, i.prevHeight + h);
                } else {
                  i.currentWidth = l;
                  i.prevHeight = i.height;
                  i.height += h;
                  i.attempt = 0;
                }
                i.width = Math.max(i.width, i.currentWidth);
                break;
              case "rl-row":
              case "row": {
                i.children.push(t);
                i.width += l;
                i.height = Math.max(i.height, h);
                const e = (0, n.measureToString)(i.height);
                for (const t of i.children) t.attributes.style.height = e;
                break;
              }
              case "table":
              case "tb":
                i.width = Math.min(s.width, Math.max(i.width, l));
                i.height += h;
                i.children.push(t);
            }
          };
          t.checkDimensions = function checkDimensions(e, t) {
            if (null === e[r.$getTemplateRoot]()[r.$extra].firstUnsplittable)
              return !0;
            if (0 === e.w || 0 === e.h) return !0;
            const a = e[r.$getSubformParent](),
              n = (a[r.$extra] && a[r.$extra].attempt) || 0,
              [, i, s, o] = (function getTransformedBBox(e) {
                let t,
                  a,
                  r = "" === e.w ? NaN : e.w,
                  n = "" === e.h ? NaN : e.h,
                  [i, s] = [0, 0];
                switch (e.anchorType || "") {
                  case "bottomCenter":
                    [i, s] = [r / 2, n];
                    break;
                  case "bottomLeft":
                    [i, s] = [0, n];
                    break;
                  case "bottomRight":
                    [i, s] = [r, n];
                    break;
                  case "middleCenter":
                    [i, s] = [r / 2, n / 2];
                    break;
                  case "middleLeft":
                    [i, s] = [0, n / 2];
                    break;
                  case "middleRight":
                    [i, s] = [r, n / 2];
                    break;
                  case "topCenter":
                    [i, s] = [r / 2, 0];
                    break;
                  case "topRight":
                    [i, s] = [r, 0];
                }
                switch (e.rotate || 0) {
                  case 0:
                    [t, a] = [-i, -s];
                    break;
                  case 90:
                    [t, a] = [-s, i];
                    [r, n] = [n, -r];
                    break;
                  case 180:
                    [t, a] = [i, s];
                    [r, n] = [-r, -n];
                    break;
                  case 270:
                    [t, a] = [s, -i];
                    [r, n] = [-n, r];
                }
                return [
                  e.x + t + Math.min(0, r),
                  e.y + a + Math.min(0, n),
                  Math.abs(r),
                  Math.abs(n),
                ];
              })(e);
            switch (a.layout) {
              case "lr-tb":
              case "rl-tb":
                return 0 === n
                  ? e[r.$getTemplateRoot]()[r.$extra].noLayoutFailure
                    ? "" !== e.w
                      ? Math.round(s - t.width) <= 2
                      : t.width > 2
                    : !("" !== e.h && Math.round(o - t.height) > 2) &&
                      ("" !== e.w
                        ? Math.round(s - t.width) <= 2 ||
                          (0 === a[r.$extra].numberInLine && t.height > 2)
                        : t.width > 2)
                  : !!e[r.$getTemplateRoot]()[r.$extra].noLayoutFailure ||
                      (!("" !== e.h && Math.round(o - t.height) > 2) &&
                        ("" === e.w ||
                          Math.round(s - t.width) <= 2 ||
                          !a[r.$isThereMoreWidth]()) &&
                        t.height > 2);
              case "table":
              case "tb":
                return (
                  !!e[r.$getTemplateRoot]()[r.$extra].noLayoutFailure ||
                  ("" === e.h || e[r.$isSplittable]()
                    ? ("" === e.w ||
                        Math.round(s - t.width) <= 2 ||
                        !a[r.$isThereMoreWidth]()) &&
                      t.height > 2
                    : Math.round(o - t.height) <= 2)
                );
              case "position":
                if (e[r.$getTemplateRoot]()[r.$extra].noLayoutFailure)
                  return !0;
                if ("" === e.h || Math.round(o + i - t.height) <= 2) return !0;
                return (
                  o + i > e[r.$getTemplateRoot]()[r.$extra].currentContentArea.h
                );
              case "rl-row":
              case "row":
                return (
                  !!e[r.$getTemplateRoot]()[r.$extra].noLayoutFailure ||
                  "" === e.h ||
                  Math.round(o - t.height) <= 2
                );
              default:
                return !0;
            }
          };
          t.flushHTML = function flushHTML(e) {
            if (!e[r.$extra]) return null;
            const t = {
              name: "div",
              attributes: e[r.$extra].attributes,
              children: e[r.$extra].children,
            };
            if (e[r.$extra].failingNode) {
              const a = e[r.$extra].failingNode[r.$flushHTML]();
              a &&
                (e.layout.endsWith("-tb")
                  ? t.children.push(createLine(e, [a]))
                  : t.children.push(a));
            }
            if (0 === t.children.length) return null;
            return t;
          };
          t.getAvailableSpace = function getAvailableSpace(e) {
            const t = e[r.$extra].availableSpace,
              a = e.margin ? e.margin.topInset + e.margin.bottomInset : 0,
              n = e.margin ? e.margin.leftInset + e.margin.rightInset : 0;
            switch (e.layout) {
              case "lr-tb":
              case "rl-tb":
                return 0 === e[r.$extra].attempt
                  ? {
                      width: t.width - n - e[r.$extra].currentWidth,
                      height: t.height - a - e[r.$extra].prevHeight,
                    }
                  : {
                      width: t.width - n,
                      height: t.height - a - e[r.$extra].height,
                    };
              case "rl-row":
              case "row":
                return {
                  width: e[r.$extra].columnWidths
                    .slice(e[r.$extra].currentColumn)
                    .reduce((e, t) => e + t),
                  height: t.height - n,
                };
              case "table":
              case "tb":
                return {
                  width: t.width - n,
                  height: t.height - a - e[r.$extra].height,
                };
              default:
                return t;
            }
          };
          var r = a(75),
            n = a(82);
          function createLine(e, t) {
            return {
              name: "div",
              attributes: { class: ["lr-tb" === e.layout ? "xfaLr" : "xfaRl"] },
              children: t,
            };
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.computeBbox = function computeBbox(e, t, a) {
            let n;
            if ("" !== e.w && "" !== e.h) n = [e.x, e.y, e.w, e.h];
            else {
              if (!a) return null;
              let i = e.w;
              if ("" === i) {
                if (0 === e.maxW) {
                  const t = e[r.$getSubformParent]();
                  i = "position" === t.layout && "" !== t.w ? 0 : e.minW;
                } else i = Math.min(e.maxW, a.width);
                t.attributes.style.width = measureToString(i);
              }
              let s = e.h;
              if ("" === s) {
                if (0 === e.maxH) {
                  const t = e[r.$getSubformParent]();
                  s = "position" === t.layout && "" !== t.h ? 0 : e.minH;
                } else s = Math.min(e.maxH, a.height);
                t.attributes.style.height = measureToString(s);
              }
              n = [e.x, e.y, i, s];
            }
            return n;
          };
          t.createWrapper = function createWrapper(e, t) {
            const { attributes: a } = t,
              { style: n } = a,
              i = {
                name: "div",
                attributes: {
                  class: ["xfaWrapper"],
                  style: Object.create(null),
                },
                children: [],
              };
            a.class.push("xfaWrapped");
            if (e.border) {
              const { widths: a, insets: s } = e.border[r.$extra];
              let o,
                c,
                l = s[0],
                h = s[3];
              const u = s[0] + s[2],
                d = s[1] + s[3];
              switch (e.border.hand) {
                case "even":
                  l -= a[0] / 2;
                  h -= a[3] / 2;
                  o = `calc(100% + ${(a[1] + a[3]) / 2 - d}px)`;
                  c = `calc(100% + ${(a[0] + a[2]) / 2 - u}px)`;
                  break;
                case "left":
                  l -= a[0];
                  h -= a[3];
                  o = `calc(100% + ${a[1] + a[3] - d}px)`;
                  c = `calc(100% + ${a[0] + a[2] - u}px)`;
                  break;
                case "right":
                  o = d ? `calc(100% - ${d}px)` : "100%";
                  c = u ? `calc(100% - ${u}px)` : "100%";
              }
              const f = ["xfaBorder"];
              isPrintOnly(e.border) && f.push("xfaPrintOnly");
              const g = {
                name: "div",
                attributes: {
                  class: f,
                  style: { top: `${l}px`, left: `${h}px`, width: o, height: c },
                },
                children: [],
              };
              for (const e of [
                "border",
                "borderWidth",
                "borderColor",
                "borderRadius",
                "borderStyle",
              ])
                if (void 0 !== n[e]) {
                  g.attributes.style[e] = n[e];
                  delete n[e];
                }
              i.children.push(g, t);
            } else i.children.push(t);
            for (const e of [
              "background",
              "backgroundClip",
              "top",
              "left",
              "width",
              "height",
              "minWidth",
              "minHeight",
              "maxWidth",
              "maxHeight",
              "transform",
              "transformOrigin",
              "visibility",
            ])
              if (void 0 !== n[e]) {
                i.attributes.style[e] = n[e];
                delete n[e];
              }
            "absolute" === n.position
              ? (i.attributes.style.position = "absolute")
              : (i.attributes.style.position = "relative");
            delete n.position;
            if (n.alignSelf) {
              i.attributes.style.alignSelf = n.alignSelf;
              delete n.alignSelf;
            }
            return i;
          };
          t.fixDimensions = function fixDimensions(e) {
            const t = e[r.$getSubformParent]();
            if (t.layout && t.layout.includes("row")) {
              const a = t[r.$extra],
                n = e.colSpan;
              let i;
              i =
                -1 === n
                  ? a.columnWidths
                      .slice(a.currentColumn)
                      .reduce((e, t) => e + t, 0)
                  : a.columnWidths
                      .slice(a.currentColumn, a.currentColumn + n)
                      .reduce((e, t) => e + t, 0);
              isNaN(i) || (e.w = i);
            }
            t.layout && "position" !== t.layout && (e.x = e.y = 0);
            "table" === e.layout &&
              "" === e.w &&
              Array.isArray(e.columnWidths) &&
              (e.w = e.columnWidths.reduce((e, t) => e + t, 0));
          };
          t.fixTextIndent = function fixTextIndent(e) {
            const t = (0, i.getMeasurement)(e.textIndent, "0px");
            if (t >= 0) return;
            const a =
                "padding" +
                ("left" == ("right" === e.textAlign ? "right" : "left")
                  ? "Left"
                  : "Right"),
              r = (0, i.getMeasurement)(e[a], "0px");
            e[a] = r - t + "px";
          };
          t.fixURL = function fixURL(e) {
            const t = (0, n.createValidAbsoluteUrl)(e, null, {
              addDefaultProtocol: !0,
              tryConvertEncoding: !0,
            });
            return t ? t.href : null;
          };
          t.isPrintOnly = isPrintOnly;
          t.layoutClass = function layoutClass(e) {
            switch (e.layout) {
              case "position":
              default:
                return "xfaPosition";
              case "lr-tb":
                return "xfaLrTb";
              case "rl-row":
                return "xfaRlRow";
              case "rl-tb":
                return "xfaRlTb";
              case "row":
                return "xfaRow";
              case "table":
                return "xfaTable";
              case "tb":
                return "xfaTb";
            }
          };
          t.layoutNode = function layoutNode(e, t) {
            let a = null,
              n = null,
              i = !1;
            if ((!e.w || !e.h) && e.value) {
              let s = 0,
                o = 0;
              if (e.margin) {
                s = e.margin.leftInset + e.margin.rightInset;
                o = e.margin.topInset + e.margin.bottomInset;
              }
              let c = null,
                l = null;
              if (e.para) {
                l = Object.create(null);
                c = "" === e.para.lineHeight ? null : e.para.lineHeight;
                l.top = "" === e.para.spaceAbove ? 0 : e.para.spaceAbove;
                l.bottom = "" === e.para.spaceBelow ? 0 : e.para.spaceBelow;
                l.left = "" === e.para.marginLeft ? 0 : e.para.marginLeft;
                l.right = "" === e.para.marginRight ? 0 : e.para.marginRight;
              }
              let h = e.font;
              if (!h) {
                const t = e[r.$getTemplateRoot]();
                let a = e[r.$getParent]();
                for (; a && a !== t; ) {
                  if (a.font) {
                    h = a.font;
                    break;
                  }
                  a = a[r.$getParent]();
                }
              }
              const u = (e.w || t.width) - s,
                d = e[r.$globalData].fontFinder;
              if (
                e.value.exData &&
                e.value.exData[r.$content] &&
                "text/html" === e.value.exData.contentType
              ) {
                const t = layoutText(e.value.exData[r.$content], h, l, c, d, u);
                n = t.width;
                a = t.height;
                i = t.isBroken;
              } else {
                const t = e.value[r.$text]();
                if (t) {
                  const e = layoutText(t, h, l, c, d, u);
                  n = e.width;
                  a = e.height;
                  i = e.isBroken;
                }
              }
              null === n || e.w || (n += s);
              null === a || e.h || (a += o);
            }
            return { w: n, h: a, isBroken: i };
          };
          t.measureToString = measureToString;
          t.setAccess = function setAccess(e, t) {
            switch (e.access) {
              case "nonInteractive":
                t.push("xfaNonInteractive");
                break;
              case "readOnly":
                t.push("xfaReadOnly");
                break;
              case "protected":
                t.push("xfaDisabled");
            }
          };
          t.setFontFamily = function setFontFamily(e, t, a, r) {
            if (!a) {
              delete r.fontFamily;
              return;
            }
            const n = (0, i.stripQuotes)(e.typeface);
            r.fontFamily = `"${n}"`;
            const o = a.find(n);
            if (o) {
              const { fontFamily: a } = o.regular.cssFontInfo;
              a !== n && (r.fontFamily = `"${a}"`);
              const i = getCurrentPara(t);
              if (i && "" !== i.lineHeight) return;
              if (r.lineHeight) return;
              const c = (0, s.selectFont)(e, o);
              c && (r.lineHeight = Math.max(1.2, c.lineHeight));
            }
          };
          t.setMinMaxDimensions = function setMinMaxDimensions(e, t) {
            if ("position" === e[r.$getSubformParent]().layout) {
              e.minW > 0 && (t.minWidth = measureToString(e.minW));
              e.maxW > 0 && (t.maxWidth = measureToString(e.maxW));
              e.minH > 0 && (t.minHeight = measureToString(e.minH));
              e.maxH > 0 && (t.maxHeight = measureToString(e.maxH));
            }
          };
          t.setPara = function setPara(e, t, a) {
            if (a.attributes.class && a.attributes.class.includes("xfaRich")) {
              if (t) {
                "" === e.h && (t.height = "auto");
                "" === e.w && (t.width = "auto");
              }
              const n = getCurrentPara(e);
              if (n) {
                const e = a.attributes.style;
                e.display = "flex";
                e.flexDirection = "column";
                switch (n.vAlign) {
                  case "top":
                    e.justifyContent = "start";
                    break;
                  case "bottom":
                    e.justifyContent = "end";
                    break;
                  case "middle":
                    e.justifyContent = "center";
                }
                const t = n[r.$toStyle]();
                for (const [a, r] of Object.entries(t)) a in e || (e[a] = r);
              }
            }
          };
          t.toStyle = function toStyle(e, ...t) {
            const a = Object.create(null);
            for (const i of t) {
              const t = e[i];
              if (null !== t)
                if (c.hasOwnProperty(i)) c[i](e, a);
                else if (t instanceof r.XFAObject) {
                  const e = t[r.$toStyle]();
                  e
                    ? Object.assign(a, e)
                    : (0, n.warn)(
                        `(DEBUG) - XFA - style for ${i} not implemented yet`
                      );
                }
            }
            return a;
          };
          var r = a(75),
            n = a(2),
            i = a(76),
            s = a(83),
            o = a(84);
          function measureToString(e) {
            return "string" == typeof e
              ? "0px"
              : Number.isInteger(e)
              ? `${e}px`
              : `${e.toFixed(2)}px`;
          }
          const c = {
            anchorType(e, t) {
              const a = e[r.$getSubformParent]();
              if (a && (!a.layout || "position" === a.layout)) {
                "transform" in t || (t.transform = "");
                switch (e.anchorType) {
                  case "bottomCenter":
                    t.transform += "translate(-50%, -100%)";
                    break;
                  case "bottomLeft":
                    t.transform += "translate(0,-100%)";
                    break;
                  case "bottomRight":
                    t.transform += "translate(-100%,-100%)";
                    break;
                  case "middleCenter":
                    t.transform += "translate(-50%,-50%)";
                    break;
                  case "middleLeft":
                    t.transform += "translate(0,-50%)";
                    break;
                  case "middleRight":
                    t.transform += "translate(-100%,-50%)";
                    break;
                  case "topCenter":
                    t.transform += "translate(-50%,0)";
                    break;
                  case "topRight":
                    t.transform += "translate(-100%,0)";
                }
              }
            },
            dimensions(e, t) {
              const a = e[r.$getSubformParent]();
              let n = e.w;
              const i = e.h;
              if (a.layout && a.layout.includes("row")) {
                const t = a[r.$extra],
                  i = e.colSpan;
                let s;
                if (-1 === i) {
                  s = t.columnWidths
                    .slice(t.currentColumn)
                    .reduce((e, t) => e + t, 0);
                  t.currentColumn = 0;
                } else {
                  s = t.columnWidths
                    .slice(t.currentColumn, t.currentColumn + i)
                    .reduce((e, t) => e + t, 0);
                  t.currentColumn =
                    (t.currentColumn + e.colSpan) % t.columnWidths.length;
                }
                isNaN(s) || (n = e.w = s);
              }
              t.width = "" !== n ? measureToString(n) : "auto";
              t.height = "" !== i ? measureToString(i) : "auto";
            },
            position(e, t) {
              const a = e[r.$getSubformParent]();
              if (!a || !a.layout || "position" === a.layout) {
                t.position = "absolute";
                t.left = measureToString(e.x);
                t.top = measureToString(e.y);
              }
            },
            rotate(e, t) {
              if (e.rotate) {
                "transform" in t || (t.transform = "");
                t.transform += `rotate(-${e.rotate}deg)`;
                t.transformOrigin = "top left";
              }
            },
            presence(e, t) {
              switch (e.presence) {
                case "invisible":
                  t.visibility = "hidden";
                  break;
                case "hidden":
                case "inactive":
                  t.display = "none";
              }
            },
            hAlign(e, t) {
              if ("para" === e[r.$nodeName])
                switch (e.hAlign) {
                  case "justifyAll":
                    t.textAlign = "justify-all";
                    break;
                  case "radix":
                    t.textAlign = "left";
                    break;
                  default:
                    t.textAlign = e.hAlign;
                }
              else
                switch (e.hAlign) {
                  case "left":
                    t.alignSelf = "start";
                    break;
                  case "center":
                    t.alignSelf = "center";
                    break;
                  case "right":
                    t.alignSelf = "end";
                }
            },
            margin(e, t) {
              e.margin && (t.margin = e.margin[r.$toStyle]().margin);
            },
          };
          function layoutText(e, t, a, n, i, s) {
            const c = new o.TextMeasure(t, a, n, i);
            "string" == typeof e ? c.addString(e) : e[r.$pushGlyphs](c);
            return c.compute(s);
          }
          function isPrintOnly(e) {
            return (
              e.relevant.length > 0 &&
              !e.relevant[0].excluded &&
              "print" === e.relevant[0].viewname
            );
          }
          function getCurrentPara(e) {
            const t = e[r.$getTemplateRoot]()[r.$extra].paraStack;
            return t.length ? t.at(-1) : null;
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.FontFinder = void 0;
          t.getMetrics = function getMetrics(e, t = !1) {
            let a = null;
            if (e) {
              const t = (0, n.stripQuotes)(e.typeface),
                i = e[r.$globalData].fontFinder.find(t);
              a = selectFont(e, i);
            }
            if (!a) return { lineHeight: 12, lineGap: 2, lineNoGap: 10 };
            const i = e.size || 10,
              s = a.lineHeight ? Math.max(t ? 0 : 1.2, a.lineHeight) : 1.2,
              o = void 0 === a.lineGap ? 0.2 : a.lineGap;
            return {
              lineHeight: s * i,
              lineGap: o * i,
              lineNoGap: Math.max(1, s - o) * i,
            };
          };
          t.selectFont = selectFont;
          var r = a(75),
            n = a(76),
            i = a(2);
          t.FontFinder = class FontFinder {
            constructor(e) {
              this.fonts = new Map();
              this.cache = new Map();
              this.warned = new Set();
              this.defaultFont = null;
              this.add(e);
            }
            add(e, t = null) {
              for (const t of e) this.addPdfFont(t);
              for (const e of this.fonts.values())
                e.regular || (e.regular = e.italic || e.bold || e.bolditalic);
              if (!t || 0 === t.size) return;
              const a = this.fonts.get("PdfJS-Fallback-PdfJS-XFA");
              for (const e of t) this.fonts.set(e, a);
            }
            addPdfFont(e) {
              const t = e.cssFontInfo,
                a = t.fontFamily;
              let r = this.fonts.get(a);
              if (!r) {
                r = Object.create(null);
                this.fonts.set(a, r);
                this.defaultFont || (this.defaultFont = r);
              }
              let n = "";
              const i = parseFloat(t.fontWeight);
              0 !== parseFloat(t.italicAngle)
                ? (n = i >= 700 ? "bolditalic" : "italic")
                : i >= 700 && (n = "bold");
              if (!n) {
                (e.name.includes("Bold") ||
                  (e.psName && e.psName.includes("Bold"))) &&
                  (n = "bold");
                (e.name.includes("Italic") ||
                  e.name.endsWith("It") ||
                  (e.psName &&
                    (e.psName.includes("Italic") ||
                      e.psName.endsWith("It")))) &&
                  (n += "italic");
              }
              n || (n = "regular");
              r[n] = e;
            }
            getDefault() {
              return this.defaultFont;
            }
            find(e, t = !0) {
              let a = this.fonts.get(e) || this.cache.get(e);
              if (a) return a;
              const r = /,|-|_| |bolditalic|bold|italic|regular|it/gi;
              let n = e.replace(r, "");
              a = this.fonts.get(n);
              if (a) {
                this.cache.set(e, a);
                return a;
              }
              n = n.toLowerCase();
              const s = [];
              for (const [e, t] of this.fonts.entries())
                e.replace(r, "").toLowerCase().startsWith(n) && s.push(t);
              if (0 === s.length)
                for (const [, e] of this.fonts.entries())
                  e.regular.name &&
                    e.regular.name.replace(r, "").toLowerCase().startsWith(n) &&
                    s.push(e);
              if (0 === s.length) {
                n = n.replace(/psmt|mt/gi, "");
                for (const [e, t] of this.fonts.entries())
                  e.replace(r, "").toLowerCase().startsWith(n) && s.push(t);
              }
              if (0 === s.length)
                for (const e of this.fonts.values())
                  e.regular.name &&
                    e.regular.name.replace(r, "").toLowerCase().startsWith(n) &&
                    s.push(e);
              if (s.length >= 1) {
                1 !== s.length &&
                  t &&
                  (0, i.warn)(
                    `XFA - Too many choices to guess the correct font: ${e}`
                  );
                this.cache.set(e, s[0]);
                return s[0];
              }
              if (t && !this.warned.has(e)) {
                this.warned.add(e);
                (0, i.warn)(`XFA - Cannot find the font: ${e}`);
              }
              return null;
            }
          };
          function selectFont(e, t) {
            return "italic" === e.posture
              ? "bold" === e.weight
                ? t.bolditalic
                : t.italic
              : "bold" === e.weight
              ? t.bold
              : t.regular;
          }
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.TextMeasure = void 0;
          var r = a(83);
          class FontInfo {
            constructor(e, t, a, n) {
              this.lineHeight = a;
              this.paraMargin = t || { top: 0, bottom: 0, left: 0, right: 0 };
              if (!e) {
                [this.pdfFont, this.xfaFont] = this.defaultFont(n);
                return;
              }
              this.xfaFont = {
                typeface: e.typeface,
                posture: e.posture,
                weight: e.weight,
                size: e.size,
                letterSpacing: e.letterSpacing,
              };
              const i = n.find(e.typeface);
              if (i) {
                this.pdfFont = (0, r.selectFont)(e, i);
                this.pdfFont ||
                  ([this.pdfFont, this.xfaFont] = this.defaultFont(n));
              } else [this.pdfFont, this.xfaFont] = this.defaultFont(n);
            }
            defaultFont(e) {
              const t =
                e.find("Helvetica", !1) ||
                e.find("Myriad Pro", !1) ||
                e.find("Arial", !1) ||
                e.getDefault();
              if (t && t.regular) {
                const e = t.regular;
                return [
                  e,
                  {
                    typeface: e.cssFontInfo.fontFamily,
                    posture: "normal",
                    weight: "normal",
                    size: 10,
                    letterSpacing: 0,
                  },
                ];
              }
              return [
                null,
                {
                  typeface: "Courier",
                  posture: "normal",
                  weight: "normal",
                  size: 10,
                  letterSpacing: 0,
                },
              ];
            }
          }
          class FontSelector {
            constructor(e, t, a, r) {
              this.fontFinder = r;
              this.stack = [new FontInfo(e, t, a, r)];
            }
            pushData(e, t, a) {
              const r = this.stack.at(-1);
              for (const t of [
                "typeface",
                "posture",
                "weight",
                "size",
                "letterSpacing",
              ])
                e[t] || (e[t] = r.xfaFont[t]);
              for (const e of ["top", "bottom", "left", "right"])
                isNaN(t[e]) && (t[e] = r.paraMargin[e]);
              const n = new FontInfo(e, t, a || r.lineHeight, this.fontFinder);
              n.pdfFont || (n.pdfFont = r.pdfFont);
              this.stack.push(n);
            }
            popFont() {
              this.stack.pop();
            }
            topFont() {
              return this.stack.at(-1);
            }
          }
          t.TextMeasure = class TextMeasure {
            constructor(e, t, a, r) {
              this.glyphs = [];
              this.fontSelector = new FontSelector(e, t, a, r);
              this.extraHeight = 0;
            }
            pushData(e, t, a) {
              this.fontSelector.pushData(e, t, a);
            }
            popFont(e) {
              return this.fontSelector.popFont();
            }
            addPara() {
              const e = this.fontSelector.topFont();
              this.extraHeight += e.paraMargin.top + e.paraMargin.bottom;
            }
            addString(e) {
              if (!e) return;
              const t = this.fontSelector.topFont(),
                a = t.xfaFont.size;
              if (t.pdfFont) {
                const r = t.xfaFont.letterSpacing,
                  n = t.pdfFont,
                  i = n.lineHeight || 1.2,
                  s = t.lineHeight || Math.max(1.2, i) * a,
                  o = i - (void 0 === n.lineGap ? 0.2 : n.lineGap),
                  c = Math.max(1, o) * a,
                  l = a / 1e3,
                  h = n.defaultWidth || n.charsToGlyphs(" ")[0].width;
                for (const t of e.split(/[\u2029\n]/)) {
                  const e = n.encodeString(t).join(""),
                    a = n.charsToGlyphs(e);
                  for (const e of a) {
                    const t = e.width || h;
                    this.glyphs.push([t * l + r, s, c, e.unicode, !1]);
                  }
                  this.glyphs.push([0, 0, 0, "\n", !0]);
                }
                this.glyphs.pop();
              } else {
                for (const t of e.split(/[\u2029\n]/)) {
                  for (const e of t.split(""))
                    this.glyphs.push([a, 1.2 * a, a, e, !1]);
                  this.glyphs.push([0, 0, 0, "\n", !0]);
                }
                this.glyphs.pop();
              }
            }
            compute(e) {
              let t = -1,
                a = 0,
                r = 0,
                n = 0,
                i = 0,
                s = 0,
                o = !1,
                c = !0;
              for (let l = 0, h = this.glyphs.length; l < h; l++) {
                const [h, u, d, f, g] = this.glyphs[l],
                  p = " " === f,
                  m = c ? d : u;
                if (g) {
                  r = Math.max(r, i);
                  i = 0;
                  n += s;
                  s = m;
                  t = -1;
                  a = 0;
                  c = !1;
                } else if (p)
                  if (i + h > e) {
                    r = Math.max(r, i);
                    i = 0;
                    n += s;
                    s = m;
                    t = -1;
                    a = 0;
                    o = !0;
                    c = !1;
                  } else {
                    s = Math.max(m, s);
                    a = i;
                    i += h;
                    t = l;
                  }
                else if (i + h > e) {
                  n += s;
                  s = m;
                  if (-1 !== t) {
                    l = t;
                    r = Math.max(r, a);
                    i = 0;
                    t = -1;
                    a = 0;
                  } else {
                    r = Math.max(r, i);
                    i = h;
                  }
                  o = !0;
                  c = !1;
                } else {
                  i += h;
                  s = Math.max(m, s);
                }
              }
              r = Math.max(r, i);
              n += s + this.extraHeight;
              return { width: 1.02 * r, height: n, isBroken: o };
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.DataHandler = void 0;
          var r = a(75);
          t.DataHandler = class DataHandler {
            constructor(e, t) {
              this.data = t;
              this.dataset = e.datasets || null;
            }
            serialize(e) {
              const t = [[-1, this.data[r.$getChildren]()]];
              for (; t.length > 0; ) {
                const a = t.at(-1),
                  [n, i] = a;
                if (n + 1 === i.length) {
                  t.pop();
                  continue;
                }
                const s = i[++a[0]],
                  o = e.get(s[r.$uid]);
                if (o) s[r.$setValue](o);
                else {
                  const t = s[r.$getAttributes]();
                  for (const a of t.values()) {
                    const t = e.get(a[r.$uid]);
                    if (t) {
                      a[r.$setValue](t);
                      break;
                    }
                  }
                }
                const c = s[r.$getChildren]();
                c.length > 0 && t.push([-1, c]);
              }
              const a = [
                '<xfa:datasets xmlns:xfa="http://www.xfa.org/schema/xfa-data/1.0/">',
              ];
              if (this.dataset)
                for (const e of this.dataset[r.$getChildren]())
                  "data" !== e[r.$nodeName] && e[r.$toString](a);
              this.data[r.$toString](a);
              a.push("</xfa:datasets>");
              return a.join("");
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XFAParser = void 0;
          var r = a(75),
            n = a(64),
            i = a(87),
            s = a(2);
          class XFAParser extends n.XMLParserBase {
            constructor(e = null, t = !1) {
              super();
              this._builder = new i.Builder(e);
              this._stack = [];
              this._globalData = { usedTypefaces: new Set() };
              this._ids = new Map();
              this._current = this._builder.buildRoot(this._ids);
              this._errorCode = n.XMLParserErrorCode.NoError;
              this._whiteRegex = /^\s+$/;
              this._nbsps = /\xa0+/g;
              this._richText = t;
            }
            parse(e) {
              this.parseXml(e);
              if (this._errorCode === n.XMLParserErrorCode.NoError) {
                this._current[r.$finalize]();
                return this._current.element;
              }
            }
            onText(e) {
              e = e.replace(this._nbsps, (e) => e.slice(1) + " ");
              this._richText || this._current[r.$acceptWhitespace]()
                ? this._current[r.$onText](e, this._richText)
                : this._whiteRegex.test(e) ||
                  this._current[r.$onText](e.trim());
            }
            onCdata(e) {
              this._current[r.$onText](e);
            }
            _mkAttributes(e, t) {
              let a = null,
                n = null;
              const i = Object.create({});
              for (const { name: o, value: c } of e)
                if ("xmlns" === o)
                  a
                    ? (0, s.warn)(
                        `XFA - multiple namespace definition in <${t}>`
                      )
                    : (a = c);
                else if (o.startsWith("xmlns:")) {
                  const e = o.substring("xmlns:".length);
                  n || (n = []);
                  n.push({ prefix: e, value: c });
                } else {
                  const e = o.indexOf(":");
                  if (-1 === e) i[o] = c;
                  else {
                    let t = i[r.$nsAttributes];
                    t || (t = i[r.$nsAttributes] = Object.create(null));
                    const [a, n] = [o.slice(0, e), o.slice(e + 1)];
                    let s = t[a];
                    s || (s = t[a] = Object.create(null));
                    s[n] = c;
                  }
                }
              return [a, n, i];
            }
            _getNameAndPrefix(e, t) {
              const a = e.indexOf(":");
              return -1 === a
                ? [e, null]
                : [e.substring(a + 1), t ? "" : e.substring(0, a)];
            }
            onBeginElement(e, t, a) {
              const [n, i, s] = this._mkAttributes(t, e),
                [o, c] = this._getNameAndPrefix(
                  e,
                  this._builder.isNsAgnostic()
                ),
                l = this._builder.build({
                  nsPrefix: c,
                  name: o,
                  attributes: s,
                  namespace: n,
                  prefixes: i,
                });
              l[r.$globalData] = this._globalData;
              if (a) {
                l[r.$finalize]();
                this._current[r.$onChild](l) && l[r.$setId](this._ids);
                l[r.$clean](this._builder);
              } else {
                this._stack.push(this._current);
                this._current = l;
              }
            }
            onEndElement(e) {
              const t = this._current;
              if (t[r.$isCDATAXml]() && "string" == typeof t[r.$content]) {
                const e = new XFAParser();
                e._globalData = this._globalData;
                const a = e.parse(t[r.$content]);
                t[r.$content] = null;
                t[r.$onChild](a);
              }
              t[r.$finalize]();
              this._current = this._stack.pop();
              this._current[r.$onChild](t) && t[r.$setId](this._ids);
              t[r.$clean](this._builder);
            }
            onError(e) {
              this._errorCode = e;
            }
          }
          t.XFAParser = XFAParser;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.Builder = void 0;
          var r = a(77),
            n = a(75),
            i = a(88),
            s = a(80),
            o = a(97),
            c = a(2);
          class Root extends n.XFAObject {
            constructor(e) {
              super(-1, "root", Object.create(null));
              this.element = null;
              this[n.$ids] = e;
            }
            [n.$onChild](e) {
              this.element = e;
              return !0;
            }
            [n.$finalize]() {
              super[n.$finalize]();
              if (this.element.template instanceof s.Template) {
                this[n.$ids].set(n.$root, this.element);
                this.element.template[n.$resolvePrototypes](this[n.$ids]);
                this.element.template[n.$ids] = this[n.$ids];
              }
            }
          }
          class Empty extends n.XFAObject {
            constructor() {
              super(-1, "", Object.create(null));
            }
            [n.$onChild](e) {
              return !1;
            }
          }
          t.Builder = class Builder {
            constructor(e = null) {
              this._namespaceStack = [];
              this._nsAgnosticLevel = 0;
              this._namespacePrefixes = new Map();
              this._namespaces = new Map();
              this._nextNsId = Math.max(
                ...Object.values(r.NamespaceIds).map(({ id: e }) => e)
              );
              this._currentNamespace =
                e || new o.UnknownNamespace(++this._nextNsId);
            }
            buildRoot(e) {
              return new Root(e);
            }
            build({
              nsPrefix: e,
              name: t,
              attributes: a,
              namespace: s,
              prefixes: o,
            }) {
              const c = null !== s;
              if (c) {
                this._namespaceStack.push(this._currentNamespace);
                this._currentNamespace = this._searchNamespace(s);
              }
              o && this._addNamespacePrefix(o);
              if (a.hasOwnProperty(n.$nsAttributes)) {
                const e = i.NamespaceSetUp.datasets,
                  t = a[n.$nsAttributes];
                let r = null;
                for (const [a, n] of Object.entries(t)) {
                  if (this._getNamespaceToUse(a) === e) {
                    r = { xfa: n };
                    break;
                  }
                }
                r ? (a[n.$nsAttributes] = r) : delete a[n.$nsAttributes];
              }
              const l = this._getNamespaceToUse(e),
                h = (l && l[r.$buildXFAObject](t, a)) || new Empty();
              h[n.$isNsAgnostic]() && this._nsAgnosticLevel++;
              (c || o || h[n.$isNsAgnostic]()) &&
                (h[n.$cleanup] = {
                  hasNamespace: c,
                  prefixes: o,
                  nsAgnostic: h[n.$isNsAgnostic](),
                });
              return h;
            }
            isNsAgnostic() {
              return this._nsAgnosticLevel > 0;
            }
            _searchNamespace(e) {
              let t = this._namespaces.get(e);
              if (t) return t;
              for (const [a, { check: n }] of Object.entries(r.NamespaceIds))
                if (n(e)) {
                  t = i.NamespaceSetUp[a];
                  if (t) {
                    this._namespaces.set(e, t);
                    return t;
                  }
                  break;
                }
              t = new o.UnknownNamespace(++this._nextNsId);
              this._namespaces.set(e, t);
              return t;
            }
            _addNamespacePrefix(e) {
              for (const { prefix: t, value: a } of e) {
                const e = this._searchNamespace(a);
                let r = this._namespacePrefixes.get(t);
                if (!r) {
                  r = [];
                  this._namespacePrefixes.set(t, r);
                }
                r.push(e);
              }
            }
            _getNamespaceToUse(e) {
              if (!e) return this._currentNamespace;
              const t = this._namespacePrefixes.get(e);
              if (t && t.length > 0) return t.at(-1);
              (0, c.warn)(`Unknown namespace prefix: ${e}.`);
              return null;
            }
            clean(e) {
              const { hasNamespace: t, prefixes: a, nsAgnostic: r } = e;
              t && (this._currentNamespace = this._namespaceStack.pop());
              a &&
                a.forEach(({ prefix: e }) => {
                  this._namespacePrefixes.get(e).pop();
                });
              r && this._nsAgnosticLevel--;
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.NamespaceSetUp = void 0;
          var r = a(89),
            n = a(90),
            i = a(91),
            s = a(92),
            o = a(93),
            c = a(94),
            l = a(80),
            h = a(95),
            u = a(96);
          const d = {
            config: r.ConfigNamespace,
            connection: n.ConnectionSetNamespace,
            datasets: i.DatasetsNamespace,
            localeSet: s.LocaleSetNamespace,
            signature: o.SignatureNamespace,
            stylesheet: c.StylesheetNamespace,
            template: l.TemplateNamespace,
            xdp: h.XdpNamespace,
            xhtml: u.XhtmlNamespace,
          };
          t.NamespaceSetUp = d;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ConfigNamespace = void 0;
          var r = a(77),
            n = a(75),
            i = a(76),
            s = a(2);
          const o = r.NamespaceIds.config.id;
          class Acrobat extends n.XFAObject {
            constructor(e) {
              super(o, "acrobat", !0);
              this.acrobat7 = null;
              this.autoSave = null;
              this.common = null;
              this.validate = null;
              this.validateApprovalSignatures = null;
              this.submitUrl = new n.XFAObjectArray();
            }
          }
          class Acrobat7 extends n.XFAObject {
            constructor(e) {
              super(o, "acrobat7", !0);
              this.dynamicRender = null;
            }
          }
          class ADBE_JSConsole extends n.OptionObject {
            constructor(e) {
              super(o, "ADBE_JSConsole", ["delegate", "Enable", "Disable"]);
            }
          }
          class ADBE_JSDebugger extends n.OptionObject {
            constructor(e) {
              super(o, "ADBE_JSDebugger", ["delegate", "Enable", "Disable"]);
            }
          }
          class AddSilentPrint extends n.Option01 {
            constructor(e) {
              super(o, "addSilentPrint");
            }
          }
          class AddViewerPreferences extends n.Option01 {
            constructor(e) {
              super(o, "addViewerPreferences");
            }
          }
          class AdjustData extends n.Option10 {
            constructor(e) {
              super(o, "adjustData");
            }
          }
          class AdobeExtensionLevel extends n.IntegerObject {
            constructor(e) {
              super(o, "adobeExtensionLevel", 0, (e) => e >= 1 && e <= 8);
            }
          }
          class Agent extends n.XFAObject {
            constructor(e) {
              super(o, "agent", !0);
              this.name = e.name ? e.name.trim() : "";
              this.common = new n.XFAObjectArray();
            }
          }
          class AlwaysEmbed extends n.ContentObject {
            constructor(e) {
              super(o, "alwaysEmbed");
            }
          }
          class Amd extends n.StringObject {
            constructor(e) {
              super(o, "amd");
            }
          }
          class Area extends n.XFAObject {
            constructor(e) {
              super(o, "area");
              this.level = (0, i.getInteger)({
                data: e.level,
                defaultValue: 0,
                validate: (e) => e >= 1 && e <= 3,
              });
              this.name = (0, i.getStringOption)(e.name, [
                "",
                "barcode",
                "coreinit",
                "deviceDriver",
                "font",
                "general",
                "layout",
                "merge",
                "script",
                "signature",
                "sourceSet",
                "templateCache",
              ]);
            }
          }
          class Attributes extends n.OptionObject {
            constructor(e) {
              super(o, "attributes", ["preserve", "delegate", "ignore"]);
            }
          }
          class AutoSave extends n.OptionObject {
            constructor(e) {
              super(o, "autoSave", ["disabled", "enabled"]);
            }
          }
          class Base extends n.StringObject {
            constructor(e) {
              super(o, "base");
            }
          }
          class BatchOutput extends n.XFAObject {
            constructor(e) {
              super(o, "batchOutput");
              this.format = (0, i.getStringOption)(e.format, [
                "none",
                "concat",
                "zip",
                "zipCompress",
              ]);
            }
          }
          class BehaviorOverride extends n.ContentObject {
            constructor(e) {
              super(o, "behaviorOverride");
            }
            [n.$finalize]() {
              this[n.$content] = new Map(
                this[n.$content]
                  .trim()
                  .split(/\s+/)
                  .filter((e) => e.includes(":"))
                  .map((e) => e.split(":", 2))
              );
            }
          }
          class Cache extends n.XFAObject {
            constructor(e) {
              super(o, "cache", !0);
              this.templateCache = null;
            }
          }
          class Change extends n.Option01 {
            constructor(e) {
              super(o, "change");
            }
          }
          class Common extends n.XFAObject {
            constructor(e) {
              super(o, "common", !0);
              this.data = null;
              this.locale = null;
              this.localeSet = null;
              this.messaging = null;
              this.suppressBanner = null;
              this.template = null;
              this.validationMessaging = null;
              this.versionControl = null;
              this.log = new n.XFAObjectArray();
            }
          }
          class Compress extends n.XFAObject {
            constructor(e) {
              super(o, "compress");
              this.scope = (0, i.getStringOption)(e.scope, [
                "imageOnly",
                "document",
              ]);
            }
          }
          class CompressLogicalStructure extends n.Option01 {
            constructor(e) {
              super(o, "compressLogicalStructure");
            }
          }
          class CompressObjectStream extends n.Option10 {
            constructor(e) {
              super(o, "compressObjectStream");
            }
          }
          class Compression extends n.XFAObject {
            constructor(e) {
              super(o, "compression", !0);
              this.compressLogicalStructure = null;
              this.compressObjectStream = null;
              this.level = null;
              this.type = null;
            }
          }
          class Config extends n.XFAObject {
            constructor(e) {
              super(o, "config", !0);
              this.acrobat = null;
              this.present = null;
              this.trace = null;
              this.agent = new n.XFAObjectArray();
            }
          }
          class Conformance extends n.OptionObject {
            constructor(e) {
              super(o, "conformance", ["A", "B"]);
            }
          }
          class ContentCopy extends n.Option01 {
            constructor(e) {
              super(o, "contentCopy");
            }
          }
          class Copies extends n.IntegerObject {
            constructor(e) {
              super(o, "copies", 1, (e) => e >= 1);
            }
          }
          class Creator extends n.StringObject {
            constructor(e) {
              super(o, "creator");
            }
          }
          class CurrentPage extends n.IntegerObject {
            constructor(e) {
              super(o, "currentPage", 0, (e) => e >= 0);
            }
          }
          class Data extends n.XFAObject {
            constructor(e) {
              super(o, "data", !0);
              this.adjustData = null;
              this.attributes = null;
              this.incrementalLoad = null;
              this.outputXSL = null;
              this.range = null;
              this.record = null;
              this.startNode = null;
              this.uri = null;
              this.window = null;
              this.xsl = null;
              this.excludeNS = new n.XFAObjectArray();
              this.transform = new n.XFAObjectArray();
            }
          }
          class Debug extends n.XFAObject {
            constructor(e) {
              super(o, "debug", !0);
              this.uri = null;
            }
          }
          class DefaultTypeface extends n.ContentObject {
            constructor(e) {
              super(o, "defaultTypeface");
              this.writingScript = (0, i.getStringOption)(e.writingScript, [
                "*",
                "Arabic",
                "Cyrillic",
                "EastEuropeanRoman",
                "Greek",
                "Hebrew",
                "Japanese",
                "Korean",
                "Roman",
                "SimplifiedChinese",
                "Thai",
                "TraditionalChinese",
                "Vietnamese",
              ]);
            }
          }
          class Destination extends n.OptionObject {
            constructor(e) {
              super(o, "destination", ["pdf", "pcl", "ps", "webClient", "zpl"]);
            }
          }
          class DocumentAssembly extends n.Option01 {
            constructor(e) {
              super(o, "documentAssembly");
            }
          }
          class Driver extends n.XFAObject {
            constructor(e) {
              super(o, "driver", !0);
              this.name = e.name ? e.name.trim() : "";
              this.fontInfo = null;
              this.xdc = null;
            }
          }
          class DuplexOption extends n.OptionObject {
            constructor(e) {
              super(o, "duplexOption", [
                "simplex",
                "duplexFlipLongEdge",
                "duplexFlipShortEdge",
              ]);
            }
          }
          class DynamicRender extends n.OptionObject {
            constructor(e) {
              super(o, "dynamicRender", ["forbidden", "required"]);
            }
          }
          class Embed extends n.Option01 {
            constructor(e) {
              super(o, "embed");
            }
          }
          class Encrypt extends n.Option01 {
            constructor(e) {
              super(o, "encrypt");
            }
          }
          class Encryption extends n.XFAObject {
            constructor(e) {
              super(o, "encryption", !0);
              this.encrypt = null;
              this.encryptionLevel = null;
              this.permissions = null;
            }
          }
          class EncryptionLevel extends n.OptionObject {
            constructor(e) {
              super(o, "encryptionLevel", ["40bit", "128bit"]);
            }
          }
          class Enforce extends n.StringObject {
            constructor(e) {
              super(o, "enforce");
            }
          }
          class Equate extends n.XFAObject {
            constructor(e) {
              super(o, "equate");
              this.force = (0, i.getInteger)({
                data: e.force,
                defaultValue: 1,
                validate: (e) => 0 === e,
              });
              this.from = e.from || "";
              this.to = e.to || "";
            }
          }
          class EquateRange extends n.XFAObject {
            constructor(e) {
              super(o, "equateRange");
              this.from = e.from || "";
              this.to = e.to || "";
              this._unicodeRange = e.unicodeRange || "";
            }
            get unicodeRange() {
              const e = [],
                t = /U\+([0-9a-fA-F]+)/,
                a = this._unicodeRange;
              for (let r of a
                .split(",")
                .map((e) => e.trim())
                .filter((e) => !!e)) {
                r = r.split("-", 2).map((e) => {
                  const a = e.match(t);
                  return a ? parseInt(a[1], 16) : 0;
                });
                1 === r.length && r.push(r[0]);
                e.push(r);
              }
              return (0, s.shadow)(this, "unicodeRange", e);
            }
          }
          class Exclude extends n.ContentObject {
            constructor(e) {
              super(o, "exclude");
            }
            [n.$finalize]() {
              this[n.$content] = this[n.$content]
                .trim()
                .split(/\s+/)
                .filter(
                  (e) =>
                    e &&
                    [
                      "calculate",
                      "close",
                      "enter",
                      "exit",
                      "initialize",
                      "ready",
                      "validate",
                    ].includes(e)
                );
            }
          }
          class ExcludeNS extends n.StringObject {
            constructor(e) {
              super(o, "excludeNS");
            }
          }
          class FlipLabel extends n.OptionObject {
            constructor(e) {
              super(o, "flipLabel", ["usePrinterSetting", "on", "off"]);
            }
          }
          class FontInfo extends n.XFAObject {
            constructor(e) {
              super(o, "fontInfo", !0);
              this.embed = null;
              this.map = null;
              this.subsetBelow = null;
              this.alwaysEmbed = new n.XFAObjectArray();
              this.defaultTypeface = new n.XFAObjectArray();
              this.neverEmbed = new n.XFAObjectArray();
            }
          }
          class FormFieldFilling extends n.Option01 {
            constructor(e) {
              super(o, "formFieldFilling");
            }
          }
          class GroupParent extends n.StringObject {
            constructor(e) {
              super(o, "groupParent");
            }
          }
          class IfEmpty extends n.OptionObject {
            constructor(e) {
              super(o, "ifEmpty", [
                "dataValue",
                "dataGroup",
                "ignore",
                "remove",
              ]);
            }
          }
          class IncludeXDPContent extends n.StringObject {
            constructor(e) {
              super(o, "includeXDPContent");
            }
          }
          class IncrementalLoad extends n.OptionObject {
            constructor(e) {
              super(o, "incrementalLoad", ["none", "forwardOnly"]);
            }
          }
          class IncrementalMerge extends n.Option01 {
            constructor(e) {
              super(o, "incrementalMerge");
            }
          }
          class Interactive extends n.Option01 {
            constructor(e) {
              super(o, "interactive");
            }
          }
          class Jog extends n.OptionObject {
            constructor(e) {
              super(o, "jog", ["usePrinterSetting", "none", "pageSet"]);
            }
          }
          class LabelPrinter extends n.XFAObject {
            constructor(e) {
              super(o, "labelPrinter", !0);
              this.name = (0, i.getStringOption)(e.name, [
                "zpl",
                "dpl",
                "ipl",
                "tcpl",
              ]);
              this.batchOutput = null;
              this.flipLabel = null;
              this.fontInfo = null;
              this.xdc = null;
            }
          }
          class Layout extends n.OptionObject {
            constructor(e) {
              super(o, "layout", ["paginate", "panel"]);
            }
          }
          class Level extends n.IntegerObject {
            constructor(e) {
              super(o, "level", 0, (e) => e > 0);
            }
          }
          class Linearized extends n.Option01 {
            constructor(e) {
              super(o, "linearized");
            }
          }
          class Locale extends n.StringObject {
            constructor(e) {
              super(o, "locale");
            }
          }
          class LocaleSet extends n.StringObject {
            constructor(e) {
              super(o, "localeSet");
            }
          }
          class Log extends n.XFAObject {
            constructor(e) {
              super(o, "log", !0);
              this.mode = null;
              this.threshold = null;
              this.to = null;
              this.uri = null;
            }
          }
          class MapElement extends n.XFAObject {
            constructor(e) {
              super(o, "map", !0);
              this.equate = new n.XFAObjectArray();
              this.equateRange = new n.XFAObjectArray();
            }
          }
          class MediumInfo extends n.XFAObject {
            constructor(e) {
              super(o, "mediumInfo", !0);
              this.map = null;
            }
          }
          class Message extends n.XFAObject {
            constructor(e) {
              super(o, "message", !0);
              this.msgId = null;
              this.severity = null;
            }
          }
          class Messaging extends n.XFAObject {
            constructor(e) {
              super(o, "messaging", !0);
              this.message = new n.XFAObjectArray();
            }
          }
          class Mode extends n.OptionObject {
            constructor(e) {
              super(o, "mode", ["append", "overwrite"]);
            }
          }
          class ModifyAnnots extends n.Option01 {
            constructor(e) {
              super(o, "modifyAnnots");
            }
          }
          class MsgId extends n.IntegerObject {
            constructor(e) {
              super(o, "msgId", 1, (e) => e >= 1);
            }
          }
          class NameAttr extends n.StringObject {
            constructor(e) {
              super(o, "nameAttr");
            }
          }
          class NeverEmbed extends n.ContentObject {
            constructor(e) {
              super(o, "neverEmbed");
            }
          }
          class NumberOfCopies extends n.IntegerObject {
            constructor(e) {
              super(o, "numberOfCopies", null, (e) => e >= 2 && e <= 5);
            }
          }
          class OpenAction extends n.XFAObject {
            constructor(e) {
              super(o, "openAction", !0);
              this.destination = null;
            }
          }
          class Output extends n.XFAObject {
            constructor(e) {
              super(o, "output", !0);
              this.to = null;
              this.type = null;
              this.uri = null;
            }
          }
          class OutputBin extends n.StringObject {
            constructor(e) {
              super(o, "outputBin");
            }
          }
          class OutputXSL extends n.XFAObject {
            constructor(e) {
              super(o, "outputXSL", !0);
              this.uri = null;
            }
          }
          class Overprint extends n.OptionObject {
            constructor(e) {
              super(o, "overprint", ["none", "both", "draw", "field"]);
            }
          }
          class Packets extends n.StringObject {
            constructor(e) {
              super(o, "packets");
            }
            [n.$finalize]() {
              "*" !== this[n.$content] &&
                (this[n.$content] = this[n.$content]
                  .trim()
                  .split(/\s+/)
                  .filter((e) =>
                    ["config", "datasets", "template", "xfdf", "xslt"].includes(
                      e
                    )
                  ));
            }
          }
          class PageOffset extends n.XFAObject {
            constructor(e) {
              super(o, "pageOffset");
              this.x = (0, i.getInteger)({
                data: e.x,
                defaultValue: "useXDCSetting",
                validate: (e) => !0,
              });
              this.y = (0, i.getInteger)({
                data: e.y,
                defaultValue: "useXDCSetting",
                validate: (e) => !0,
              });
            }
          }
          class PageRange extends n.StringObject {
            constructor(e) {
              super(o, "pageRange");
            }
            [n.$finalize]() {
              const e = this[n.$content]
                  .trim()
                  .split(/\s+/)
                  .map((e) => parseInt(e, 10)),
                t = [];
              for (let a = 0, r = e.length; a < r; a += 2)
                t.push(e.slice(a, a + 2));
              this[n.$content] = t;
            }
          }
          class Pagination extends n.OptionObject {
            constructor(e) {
              super(o, "pagination", [
                "simplex",
                "duplexShortEdge",
                "duplexLongEdge",
              ]);
            }
          }
          class PaginationOverride extends n.OptionObject {
            constructor(e) {
              super(o, "paginationOverride", [
                "none",
                "forceDuplex",
                "forceDuplexLongEdge",
                "forceDuplexShortEdge",
                "forceSimplex",
              ]);
            }
          }
          class Part extends n.IntegerObject {
            constructor(e) {
              super(o, "part", 1, (e) => !1);
            }
          }
          class Pcl extends n.XFAObject {
            constructor(e) {
              super(o, "pcl", !0);
              this.name = e.name || "";
              this.batchOutput = null;
              this.fontInfo = null;
              this.jog = null;
              this.mediumInfo = null;
              this.outputBin = null;
              this.pageOffset = null;
              this.staple = null;
              this.xdc = null;
            }
          }
          class Pdf extends n.XFAObject {
            constructor(e) {
              super(o, "pdf", !0);
              this.name = e.name || "";
              this.adobeExtensionLevel = null;
              this.batchOutput = null;
              this.compression = null;
              this.creator = null;
              this.encryption = null;
              this.fontInfo = null;
              this.interactive = null;
              this.linearized = null;
              this.openAction = null;
              this.pdfa = null;
              this.producer = null;
              this.renderPolicy = null;
              this.scriptModel = null;
              this.silentPrint = null;
              this.submitFormat = null;
              this.tagged = null;
              this.version = null;
              this.viewerPreferences = null;
              this.xdc = null;
            }
          }
          class Pdfa extends n.XFAObject {
            constructor(e) {
              super(o, "pdfa", !0);
              this.amd = null;
              this.conformance = null;
              this.includeXDPContent = null;
              this.part = null;
            }
          }
          class Permissions extends n.XFAObject {
            constructor(e) {
              super(o, "permissions", !0);
              this.accessibleContent = null;
              this.change = null;
              this.contentCopy = null;
              this.documentAssembly = null;
              this.formFieldFilling = null;
              this.modifyAnnots = null;
              this.plaintextMetadata = null;
              this.print = null;
              this.printHighQuality = null;
            }
          }
          class PickTrayByPDFSize extends n.Option01 {
            constructor(e) {
              super(o, "pickTrayByPDFSize");
            }
          }
          class Picture extends n.StringObject {
            constructor(e) {
              super(o, "picture");
            }
          }
          class PlaintextMetadata extends n.Option01 {
            constructor(e) {
              super(o, "plaintextMetadata");
            }
          }
          class Presence extends n.OptionObject {
            constructor(e) {
              super(o, "presence", [
                "preserve",
                "dissolve",
                "dissolveStructure",
                "ignore",
                "remove",
              ]);
            }
          }
          class Present extends n.XFAObject {
            constructor(e) {
              super(o, "present", !0);
              this.behaviorOverride = null;
              this.cache = null;
              this.common = null;
              this.copies = null;
              this.destination = null;
              this.incrementalMerge = null;
              this.layout = null;
              this.output = null;
              this.overprint = null;
              this.pagination = null;
              this.paginationOverride = null;
              this.script = null;
              this.validate = null;
              this.xdp = null;
              this.driver = new n.XFAObjectArray();
              this.labelPrinter = new n.XFAObjectArray();
              this.pcl = new n.XFAObjectArray();
              this.pdf = new n.XFAObjectArray();
              this.ps = new n.XFAObjectArray();
              this.submitUrl = new n.XFAObjectArray();
              this.webClient = new n.XFAObjectArray();
              this.zpl = new n.XFAObjectArray();
            }
          }
          class Print extends n.Option01 {
            constructor(e) {
              super(o, "print");
            }
          }
          class PrintHighQuality extends n.Option01 {
            constructor(e) {
              super(o, "printHighQuality");
            }
          }
          class PrintScaling extends n.OptionObject {
            constructor(e) {
              super(o, "printScaling", ["appdefault", "noScaling"]);
            }
          }
          class PrinterName extends n.StringObject {
            constructor(e) {
              super(o, "printerName");
            }
          }
          class Producer extends n.StringObject {
            constructor(e) {
              super(o, "producer");
            }
          }
          class Ps extends n.XFAObject {
            constructor(e) {
              super(o, "ps", !0);
              this.name = e.name || "";
              this.batchOutput = null;
              this.fontInfo = null;
              this.jog = null;
              this.mediumInfo = null;
              this.outputBin = null;
              this.staple = null;
              this.xdc = null;
            }
          }
          class Range extends n.ContentObject {
            constructor(e) {
              super(o, "range");
            }
            [n.$finalize]() {
              this[n.$content] = this[n.$content]
                .trim()
                .split(/\s*,\s*/, 2)
                .map((e) => e.split("-").map((e) => parseInt(e.trim(), 10)))
                .filter((e) => e.every((e) => !isNaN(e)))
                .map((e) => {
                  1 === e.length && e.push(e[0]);
                  return e;
                });
            }
          }
          class Record extends n.ContentObject {
            constructor(e) {
              super(o, "record");
            }
            [n.$finalize]() {
              this[n.$content] = this[n.$content].trim();
              const e = parseInt(this[n.$content], 10);
              !isNaN(e) && e >= 0 && (this[n.$content] = e);
            }
          }
          class Relevant extends n.ContentObject {
            constructor(e) {
              super(o, "relevant");
            }
            [n.$finalize]() {
              this[n.$content] = this[n.$content].trim().split(/\s+/);
            }
          }
          class Rename extends n.ContentObject {
            constructor(e) {
              super(o, "rename");
            }
            [n.$finalize]() {
              this[n.$content] = this[n.$content].trim();
              (this[n.$content].toLowerCase().startsWith("xml") ||
                new RegExp("[\\p{L}_][\\p{L}\\d._\\p{M}-]*", "u").test(
                  this[n.$content]
                )) &&
                (0, s.warn)("XFA - Rename: invalid XFA name");
            }
          }
          class RenderPolicy extends n.OptionObject {
            constructor(e) {
              super(o, "renderPolicy", ["server", "client"]);
            }
          }
          class RunScripts extends n.OptionObject {
            constructor(e) {
              super(o, "runScripts", ["both", "client", "none", "server"]);
            }
          }
          class Script extends n.XFAObject {
            constructor(e) {
              super(o, "script", !0);
              this.currentPage = null;
              this.exclude = null;
              this.runScripts = null;
            }
          }
          class ScriptModel extends n.OptionObject {
            constructor(e) {
              super(o, "scriptModel", ["XFA", "none"]);
            }
          }
          class Severity extends n.OptionObject {
            constructor(e) {
              super(o, "severity", [
                "ignore",
                "error",
                "information",
                "trace",
                "warning",
              ]);
            }
          }
          class SilentPrint extends n.XFAObject {
            constructor(e) {
              super(o, "silentPrint", !0);
              this.addSilentPrint = null;
              this.printerName = null;
            }
          }
          class Staple extends n.XFAObject {
            constructor(e) {
              super(o, "staple");
              this.mode = (0, i.getStringOption)(e.mode, [
                "usePrinterSetting",
                "on",
                "off",
              ]);
            }
          }
          class StartNode extends n.StringObject {
            constructor(e) {
              super(o, "startNode");
            }
          }
          class StartPage extends n.IntegerObject {
            constructor(e) {
              super(o, "startPage", 0, (e) => !0);
            }
          }
          class SubmitFormat extends n.OptionObject {
            constructor(e) {
              super(o, "submitFormat", [
                "html",
                "delegate",
                "fdf",
                "xml",
                "pdf",
              ]);
            }
          }
          class SubmitUrl extends n.StringObject {
            constructor(e) {
              super(o, "submitUrl");
            }
          }
          class SubsetBelow extends n.IntegerObject {
            constructor(e) {
              super(o, "subsetBelow", 100, (e) => e >= 0 && e <= 100);
            }
          }
          class SuppressBanner extends n.Option01 {
            constructor(e) {
              super(o, "suppressBanner");
            }
          }
          class Tagged extends n.Option01 {
            constructor(e) {
              super(o, "tagged");
            }
          }
          class Template extends n.XFAObject {
            constructor(e) {
              super(o, "template", !0);
              this.base = null;
              this.relevant = null;
              this.startPage = null;
              this.uri = null;
              this.xsl = null;
            }
          }
          class Threshold extends n.OptionObject {
            constructor(e) {
              super(o, "threshold", [
                "trace",
                "error",
                "information",
                "warning",
              ]);
            }
          }
          class To extends n.OptionObject {
            constructor(e) {
              super(o, "to", [
                "null",
                "memory",
                "stderr",
                "stdout",
                "system",
                "uri",
              ]);
            }
          }
          class TemplateCache extends n.XFAObject {
            constructor(e) {
              super(o, "templateCache");
              this.maxEntries = (0, i.getInteger)({
                data: e.maxEntries,
                defaultValue: 5,
                validate: (e) => e >= 0,
              });
            }
          }
          class Trace extends n.XFAObject {
            constructor(e) {
              super(o, "trace", !0);
              this.area = new n.XFAObjectArray();
            }
          }
          class Transform extends n.XFAObject {
            constructor(e) {
              super(o, "transform", !0);
              this.groupParent = null;
              this.ifEmpty = null;
              this.nameAttr = null;
              this.picture = null;
              this.presence = null;
              this.rename = null;
              this.whitespace = null;
            }
          }
          class Type extends n.OptionObject {
            constructor(e) {
              super(o, "type", [
                "none",
                "ascii85",
                "asciiHex",
                "ccittfax",
                "flate",
                "lzw",
                "runLength",
                "native",
                "xdp",
                "mergedXDP",
              ]);
            }
          }
          class Uri extends n.StringObject {
            constructor(e) {
              super(o, "uri");
            }
          }
          class Validate extends n.OptionObject {
            constructor(e) {
              super(o, "validate", [
                "preSubmit",
                "prePrint",
                "preExecute",
                "preSave",
              ]);
            }
          }
          class ValidateApprovalSignatures extends n.ContentObject {
            constructor(e) {
              super(o, "validateApprovalSignatures");
            }
            [n.$finalize]() {
              this[n.$content] = this[n.$content]
                .trim()
                .split(/\s+/)
                .filter((e) => ["docReady", "postSign"].includes(e));
            }
          }
          class ValidationMessaging extends n.OptionObject {
            constructor(e) {
              super(o, "validationMessaging", [
                "allMessagesIndividually",
                "allMessagesTogether",
                "firstMessageOnly",
                "noMessages",
              ]);
            }
          }
          class Version extends n.OptionObject {
            constructor(e) {
              super(o, "version", ["1.7", "1.6", "1.5", "1.4", "1.3", "1.2"]);
            }
          }
          class VersionControl extends n.XFAObject {
            constructor(e) {
              super(o, "VersionControl");
              this.outputBelow = (0, i.getStringOption)(e.outputBelow, [
                "warn",
                "error",
                "update",
              ]);
              this.sourceAbove = (0, i.getStringOption)(e.sourceAbove, [
                "warn",
                "error",
              ]);
              this.sourceBelow = (0, i.getStringOption)(e.sourceBelow, [
                "update",
                "maintain",
              ]);
            }
          }
          class ViewerPreferences extends n.XFAObject {
            constructor(e) {
              super(o, "viewerPreferences", !0);
              this.ADBE_JSConsole = null;
              this.ADBE_JSDebugger = null;
              this.addViewerPreferences = null;
              this.duplexOption = null;
              this.enforce = null;
              this.numberOfCopies = null;
              this.pageRange = null;
              this.pickTrayByPDFSize = null;
              this.printScaling = null;
            }
          }
          class WebClient extends n.XFAObject {
            constructor(e) {
              super(o, "webClient", !0);
              this.name = e.name ? e.name.trim() : "";
              this.fontInfo = null;
              this.xdc = null;
            }
          }
          class Whitespace extends n.OptionObject {
            constructor(e) {
              super(o, "whitespace", [
                "preserve",
                "ltrim",
                "normalize",
                "rtrim",
                "trim",
              ]);
            }
          }
          class Window extends n.ContentObject {
            constructor(e) {
              super(o, "window");
            }
            [n.$finalize]() {
              const e = this[n.$content]
                .trim()
                .split(/\s*,\s*/, 2)
                .map((e) => parseInt(e, 10));
              if (e.some((e) => isNaN(e))) this[n.$content] = [0, 0];
              else {
                1 === e.length && e.push(e[0]);
                this[n.$content] = e;
              }
            }
          }
          class Xdc extends n.XFAObject {
            constructor(e) {
              super(o, "xdc", !0);
              this.uri = new n.XFAObjectArray();
              this.xsl = new n.XFAObjectArray();
            }
          }
          class Xdp extends n.XFAObject {
            constructor(e) {
              super(o, "xdp", !0);
              this.packets = null;
            }
          }
          class Xsl extends n.XFAObject {
            constructor(e) {
              super(o, "xsl", !0);
              this.debug = null;
              this.uri = null;
            }
          }
          class Zpl extends n.XFAObject {
            constructor(e) {
              super(o, "zpl", !0);
              this.name = e.name ? e.name.trim() : "";
              this.batchOutput = null;
              this.flipLabel = null;
              this.fontInfo = null;
              this.xdc = null;
            }
          }
          class ConfigNamespace {
            static [r.$buildXFAObject](e, t) {
              if (ConfigNamespace.hasOwnProperty(e))
                return ConfigNamespace[e](t);
            }
            static acrobat(e) {
              return new Acrobat(e);
            }
            static acrobat7(e) {
              return new Acrobat7(e);
            }
            static ADBE_JSConsole(e) {
              return new ADBE_JSConsole(e);
            }
            static ADBE_JSDebugger(e) {
              return new ADBE_JSDebugger(e);
            }
            static addSilentPrint(e) {
              return new AddSilentPrint(e);
            }
            static addViewerPreferences(e) {
              return new AddViewerPreferences(e);
            }
            static adjustData(e) {
              return new AdjustData(e);
            }
            static adobeExtensionLevel(e) {
              return new AdobeExtensionLevel(e);
            }
            static agent(e) {
              return new Agent(e);
            }
            static alwaysEmbed(e) {
              return new AlwaysEmbed(e);
            }
            static amd(e) {
              return new Amd(e);
            }
            static area(e) {
              return new Area(e);
            }
            static attributes(e) {
              return new Attributes(e);
            }
            static autoSave(e) {
              return new AutoSave(e);
            }
            static base(e) {
              return new Base(e);
            }
            static batchOutput(e) {
              return new BatchOutput(e);
            }
            static behaviorOverride(e) {
              return new BehaviorOverride(e);
            }
            static cache(e) {
              return new Cache(e);
            }
            static change(e) {
              return new Change(e);
            }
            static common(e) {
              return new Common(e);
            }
            static compress(e) {
              return new Compress(e);
            }
            static compressLogicalStructure(e) {
              return new CompressLogicalStructure(e);
            }
            static compressObjectStream(e) {
              return new CompressObjectStream(e);
            }
            static compression(e) {
              return new Compression(e);
            }
            static config(e) {
              return new Config(e);
            }
            static conformance(e) {
              return new Conformance(e);
            }
            static contentCopy(e) {
              return new ContentCopy(e);
            }
            static copies(e) {
              return new Copies(e);
            }
            static creator(e) {
              return new Creator(e);
            }
            static currentPage(e) {
              return new CurrentPage(e);
            }
            static data(e) {
              return new Data(e);
            }
            static debug(e) {
              return new Debug(e);
            }
            static defaultTypeface(e) {
              return new DefaultTypeface(e);
            }
            static destination(e) {
              return new Destination(e);
            }
            static documentAssembly(e) {
              return new DocumentAssembly(e);
            }
            static driver(e) {
              return new Driver(e);
            }
            static duplexOption(e) {
              return new DuplexOption(e);
            }
            static dynamicRender(e) {
              return new DynamicRender(e);
            }
            static embed(e) {
              return new Embed(e);
            }
            static encrypt(e) {
              return new Encrypt(e);
            }
            static encryption(e) {
              return new Encryption(e);
            }
            static encryptionLevel(e) {
              return new EncryptionLevel(e);
            }
            static enforce(e) {
              return new Enforce(e);
            }
            static equate(e) {
              return new Equate(e);
            }
            static equateRange(e) {
              return new EquateRange(e);
            }
            static exclude(e) {
              return new Exclude(e);
            }
            static excludeNS(e) {
              return new ExcludeNS(e);
            }
            static flipLabel(e) {
              return new FlipLabel(e);
            }
            static fontInfo(e) {
              return new FontInfo(e);
            }
            static formFieldFilling(e) {
              return new FormFieldFilling(e);
            }
            static groupParent(e) {
              return new GroupParent(e);
            }
            static ifEmpty(e) {
              return new IfEmpty(e);
            }
            static includeXDPContent(e) {
              return new IncludeXDPContent(e);
            }
            static incrementalLoad(e) {
              return new IncrementalLoad(e);
            }
            static incrementalMerge(e) {
              return new IncrementalMerge(e);
            }
            static interactive(e) {
              return new Interactive(e);
            }
            static jog(e) {
              return new Jog(e);
            }
            static labelPrinter(e) {
              return new LabelPrinter(e);
            }
            static layout(e) {
              return new Layout(e);
            }
            static level(e) {
              return new Level(e);
            }
            static linearized(e) {
              return new Linearized(e);
            }
            static locale(e) {
              return new Locale(e);
            }
            static localeSet(e) {
              return new LocaleSet(e);
            }
            static log(e) {
              return new Log(e);
            }
            static map(e) {
              return new MapElement(e);
            }
            static mediumInfo(e) {
              return new MediumInfo(e);
            }
            static message(e) {
              return new Message(e);
            }
            static messaging(e) {
              return new Messaging(e);
            }
            static mode(e) {
              return new Mode(e);
            }
            static modifyAnnots(e) {
              return new ModifyAnnots(e);
            }
            static msgId(e) {
              return new MsgId(e);
            }
            static nameAttr(e) {
              return new NameAttr(e);
            }
            static neverEmbed(e) {
              return new NeverEmbed(e);
            }
            static numberOfCopies(e) {
              return new NumberOfCopies(e);
            }
            static openAction(e) {
              return new OpenAction(e);
            }
            static output(e) {
              return new Output(e);
            }
            static outputBin(e) {
              return new OutputBin(e);
            }
            static outputXSL(e) {
              return new OutputXSL(e);
            }
            static overprint(e) {
              return new Overprint(e);
            }
            static packets(e) {
              return new Packets(e);
            }
            static pageOffset(e) {
              return new PageOffset(e);
            }
            static pageRange(e) {
              return new PageRange(e);
            }
            static pagination(e) {
              return new Pagination(e);
            }
            static paginationOverride(e) {
              return new PaginationOverride(e);
            }
            static part(e) {
              return new Part(e);
            }
            static pcl(e) {
              return new Pcl(e);
            }
            static pdf(e) {
              return new Pdf(e);
            }
            static pdfa(e) {
              return new Pdfa(e);
            }
            static permissions(e) {
              return new Permissions(e);
            }
            static pickTrayByPDFSize(e) {
              return new PickTrayByPDFSize(e);
            }
            static picture(e) {
              return new Picture(e);
            }
            static plaintextMetadata(e) {
              return new PlaintextMetadata(e);
            }
            static presence(e) {
              return new Presence(e);
            }
            static present(e) {
              return new Present(e);
            }
            static print(e) {
              return new Print(e);
            }
            static printHighQuality(e) {
              return new PrintHighQuality(e);
            }
            static printScaling(e) {
              return new PrintScaling(e);
            }
            static printerName(e) {
              return new PrinterName(e);
            }
            static producer(e) {
              return new Producer(e);
            }
            static ps(e) {
              return new Ps(e);
            }
            static range(e) {
              return new Range(e);
            }
            static record(e) {
              return new Record(e);
            }
            static relevant(e) {
              return new Relevant(e);
            }
            static rename(e) {
              return new Rename(e);
            }
            static renderPolicy(e) {
              return new RenderPolicy(e);
            }
            static runScripts(e) {
              return new RunScripts(e);
            }
            static script(e) {
              return new Script(e);
            }
            static scriptModel(e) {
              return new ScriptModel(e);
            }
            static severity(e) {
              return new Severity(e);
            }
            static silentPrint(e) {
              return new SilentPrint(e);
            }
            static staple(e) {
              return new Staple(e);
            }
            static startNode(e) {
              return new StartNode(e);
            }
            static startPage(e) {
              return new StartPage(e);
            }
            static submitFormat(e) {
              return new SubmitFormat(e);
            }
            static submitUrl(e) {
              return new SubmitUrl(e);
            }
            static subsetBelow(e) {
              return new SubsetBelow(e);
            }
            static suppressBanner(e) {
              return new SuppressBanner(e);
            }
            static tagged(e) {
              return new Tagged(e);
            }
            static template(e) {
              return new Template(e);
            }
            static templateCache(e) {
              return new TemplateCache(e);
            }
            static threshold(e) {
              return new Threshold(e);
            }
            static to(e) {
              return new To(e);
            }
            static trace(e) {
              return new Trace(e);
            }
            static transform(e) {
              return new Transform(e);
            }
            static type(e) {
              return new Type(e);
            }
            static uri(e) {
              return new Uri(e);
            }
            static validate(e) {
              return new Validate(e);
            }
            static validateApprovalSignatures(e) {
              return new ValidateApprovalSignatures(e);
            }
            static validationMessaging(e) {
              return new ValidationMessaging(e);
            }
            static version(e) {
              return new Version(e);
            }
            static versionControl(e) {
              return new VersionControl(e);
            }
            static viewerPreferences(e) {
              return new ViewerPreferences(e);
            }
            static webClient(e) {
              return new WebClient(e);
            }
            static whitespace(e) {
              return new Whitespace(e);
            }
            static window(e) {
              return new Window(e);
            }
            static xdc(e) {
              return new Xdc(e);
            }
            static xdp(e) {
              return new Xdp(e);
            }
            static xsl(e) {
              return new Xsl(e);
            }
            static zpl(e) {
              return new Zpl(e);
            }
          }
          t.ConfigNamespace = ConfigNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.ConnectionSetNamespace = void 0;
          var r = a(77),
            n = a(75);
          const i = r.NamespaceIds.connectionSet.id;
          class ConnectionSet extends n.XFAObject {
            constructor(e) {
              super(i, "connectionSet", !0);
              this.wsdlConnection = new n.XFAObjectArray();
              this.xmlConnection = new n.XFAObjectArray();
              this.xsdConnection = new n.XFAObjectArray();
            }
          }
          class EffectiveInputPolicy extends n.XFAObject {
            constructor(e) {
              super(i, "effectiveInputPolicy");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class EffectiveOutputPolicy extends n.XFAObject {
            constructor(e) {
              super(i, "effectiveOutputPolicy");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Operation extends n.StringObject {
            constructor(e) {
              super(i, "operation");
              this.id = e.id || "";
              this.input = e.input || "";
              this.name = e.name || "";
              this.output = e.output || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class RootElement extends n.StringObject {
            constructor(e) {
              super(i, "rootElement");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class SoapAction extends n.StringObject {
            constructor(e) {
              super(i, "soapAction");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class SoapAddress extends n.StringObject {
            constructor(e) {
              super(i, "soapAddress");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class Uri extends n.StringObject {
            constructor(e) {
              super(i, "uri");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class WsdlAddress extends n.StringObject {
            constructor(e) {
              super(i, "wsdlAddress");
              this.id = e.id || "";
              this.name = e.name || "";
              this.use = e.use || "";
              this.usehref = e.usehref || "";
            }
          }
          class WsdlConnection extends n.XFAObject {
            constructor(e) {
              super(i, "wsdlConnection", !0);
              this.dataDescription = e.dataDescription || "";
              this.name = e.name || "";
              this.effectiveInputPolicy = null;
              this.effectiveOutputPolicy = null;
              this.operation = null;
              this.soapAction = null;
              this.soapAddress = null;
              this.wsdlAddress = null;
            }
          }
          class XmlConnection extends n.XFAObject {
            constructor(e) {
              super(i, "xmlConnection", !0);
              this.dataDescription = e.dataDescription || "";
              this.name = e.name || "";
              this.uri = null;
            }
          }
          class XsdConnection extends n.XFAObject {
            constructor(e) {
              super(i, "xsdConnection", !0);
              this.dataDescription = e.dataDescription || "";
              this.name = e.name || "";
              this.rootElement = null;
              this.uri = null;
            }
          }
          class ConnectionSetNamespace {
            static [r.$buildXFAObject](e, t) {
              if (ConnectionSetNamespace.hasOwnProperty(e))
                return ConnectionSetNamespace[e](t);
            }
            static connectionSet(e) {
              return new ConnectionSet(e);
            }
            static effectiveInputPolicy(e) {
              return new EffectiveInputPolicy(e);
            }
            static effectiveOutputPolicy(e) {
              return new EffectiveOutputPolicy(e);
            }
            static operation(e) {
              return new Operation(e);
            }
            static rootElement(e) {
              return new RootElement(e);
            }
            static soapAction(e) {
              return new SoapAction(e);
            }
            static soapAddress(e) {
              return new SoapAddress(e);
            }
            static uri(e) {
              return new Uri(e);
            }
            static wsdlAddress(e) {
              return new WsdlAddress(e);
            }
            static wsdlConnection(e) {
              return new WsdlConnection(e);
            }
            static xmlConnection(e) {
              return new XmlConnection(e);
            }
            static xsdConnection(e) {
              return new XsdConnection(e);
            }
          }
          t.ConnectionSetNamespace = ConnectionSetNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.DatasetsNamespace = void 0;
          var r = a(75),
            n = a(77);
          const i = n.NamespaceIds.datasets.id;
          class Data extends r.XmlObject {
            constructor(e) {
              super(i, "data", e);
            }
            [r.$isNsAgnostic]() {
              return !0;
            }
          }
          class Datasets extends r.XFAObject {
            constructor(e) {
              super(i, "datasets", !0);
              this.data = null;
              this.Signature = null;
            }
            [r.$onChild](e) {
              const t = e[r.$nodeName];
              (("data" === t && e[r.$namespaceId] === i) ||
                ("Signature" === t &&
                  e[r.$namespaceId] === n.NamespaceIds.signature.id)) &&
                (this[t] = e);
              this[r.$appendChild](e);
            }
          }
          class DatasetsNamespace {
            static [n.$buildXFAObject](e, t) {
              if (DatasetsNamespace.hasOwnProperty(e))
                return DatasetsNamespace[e](t);
            }
            static datasets(e) {
              return new Datasets(e);
            }
            static data(e) {
              return new Data(e);
            }
          }
          t.DatasetsNamespace = DatasetsNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.LocaleSetNamespace = void 0;
          var r = a(77),
            n = a(75),
            i = a(76);
          const s = r.NamespaceIds.localeSet.id;
          class CalendarSymbols extends n.XFAObject {
            constructor(e) {
              super(s, "calendarSymbols", !0);
              this.name = "gregorian";
              this.dayNames = new n.XFAObjectArray(2);
              this.eraNames = null;
              this.meridiemNames = null;
              this.monthNames = new n.XFAObjectArray(2);
            }
          }
          class CurrencySymbol extends n.StringObject {
            constructor(e) {
              super(s, "currencySymbol");
              this.name = (0, i.getStringOption)(e.name, [
                "symbol",
                "isoname",
                "decimal",
              ]);
            }
          }
          class CurrencySymbols extends n.XFAObject {
            constructor(e) {
              super(s, "currencySymbols", !0);
              this.currencySymbol = new n.XFAObjectArray(3);
            }
          }
          class DatePattern extends n.StringObject {
            constructor(e) {
              super(s, "datePattern");
              this.name = (0, i.getStringOption)(e.name, [
                "full",
                "long",
                "med",
                "short",
              ]);
            }
          }
          class DatePatterns extends n.XFAObject {
            constructor(e) {
              super(s, "datePatterns", !0);
              this.datePattern = new n.XFAObjectArray(4);
            }
          }
          class DateTimeSymbols extends n.ContentObject {
            constructor(e) {
              super(s, "dateTimeSymbols");
            }
          }
          class Day extends n.StringObject {
            constructor(e) {
              super(s, "day");
            }
          }
          class DayNames extends n.XFAObject {
            constructor(e) {
              super(s, "dayNames", !0);
              this.abbr = (0, i.getInteger)({
                data: e.abbr,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.day = new n.XFAObjectArray(7);
            }
          }
          class Era extends n.StringObject {
            constructor(e) {
              super(s, "era");
            }
          }
          class EraNames extends n.XFAObject {
            constructor(e) {
              super(s, "eraNames", !0);
              this.era = new n.XFAObjectArray(2);
            }
          }
          class Locale extends n.XFAObject {
            constructor(e) {
              super(s, "locale", !0);
              this.desc = e.desc || "";
              this.name = "isoname";
              this.calendarSymbols = null;
              this.currencySymbols = null;
              this.datePatterns = null;
              this.dateTimeSymbols = null;
              this.numberPatterns = null;
              this.numberSymbols = null;
              this.timePatterns = null;
              this.typeFaces = null;
            }
          }
          class LocaleSet extends n.XFAObject {
            constructor(e) {
              super(s, "localeSet", !0);
              this.locale = new n.XFAObjectArray();
            }
          }
          class Meridiem extends n.StringObject {
            constructor(e) {
              super(s, "meridiem");
            }
          }
          class MeridiemNames extends n.XFAObject {
            constructor(e) {
              super(s, "meridiemNames", !0);
              this.meridiem = new n.XFAObjectArray(2);
            }
          }
          class Month extends n.StringObject {
            constructor(e) {
              super(s, "month");
            }
          }
          class MonthNames extends n.XFAObject {
            constructor(e) {
              super(s, "monthNames", !0);
              this.abbr = (0, i.getInteger)({
                data: e.abbr,
                defaultValue: 0,
                validate: (e) => 1 === e,
              });
              this.month = new n.XFAObjectArray(12);
            }
          }
          class NumberPattern extends n.StringObject {
            constructor(e) {
              super(s, "numberPattern");
              this.name = (0, i.getStringOption)(e.name, [
                "full",
                "long",
                "med",
                "short",
              ]);
            }
          }
          class NumberPatterns extends n.XFAObject {
            constructor(e) {
              super(s, "numberPatterns", !0);
              this.numberPattern = new n.XFAObjectArray(4);
            }
          }
          class NumberSymbol extends n.StringObject {
            constructor(e) {
              super(s, "numberSymbol");
              this.name = (0, i.getStringOption)(e.name, [
                "decimal",
                "grouping",
                "percent",
                "minus",
                "zero",
              ]);
            }
          }
          class NumberSymbols extends n.XFAObject {
            constructor(e) {
              super(s, "numberSymbols", !0);
              this.numberSymbol = new n.XFAObjectArray(5);
            }
          }
          class TimePattern extends n.StringObject {
            constructor(e) {
              super(s, "timePattern");
              this.name = (0, i.getStringOption)(e.name, [
                "full",
                "long",
                "med",
                "short",
              ]);
            }
          }
          class TimePatterns extends n.XFAObject {
            constructor(e) {
              super(s, "timePatterns", !0);
              this.timePattern = new n.XFAObjectArray(4);
            }
          }
          class TypeFace extends n.XFAObject {
            constructor(e) {
              super(s, "typeFace", !0);
              this.name = "" | e.name;
            }
          }
          class TypeFaces extends n.XFAObject {
            constructor(e) {
              super(s, "typeFaces", !0);
              this.typeFace = new n.XFAObjectArray();
            }
          }
          class LocaleSetNamespace {
            static [r.$buildXFAObject](e, t) {
              if (LocaleSetNamespace.hasOwnProperty(e))
                return LocaleSetNamespace[e](t);
            }
            static calendarSymbols(e) {
              return new CalendarSymbols(e);
            }
            static currencySymbol(e) {
              return new CurrencySymbol(e);
            }
            static currencySymbols(e) {
              return new CurrencySymbols(e);
            }
            static datePattern(e) {
              return new DatePattern(e);
            }
            static datePatterns(e) {
              return new DatePatterns(e);
            }
            static dateTimeSymbols(e) {
              return new DateTimeSymbols(e);
            }
            static day(e) {
              return new Day(e);
            }
            static dayNames(e) {
              return new DayNames(e);
            }
            static era(e) {
              return new Era(e);
            }
            static eraNames(e) {
              return new EraNames(e);
            }
            static locale(e) {
              return new Locale(e);
            }
            static localeSet(e) {
              return new LocaleSet(e);
            }
            static meridiem(e) {
              return new Meridiem(e);
            }
            static meridiemNames(e) {
              return new MeridiemNames(e);
            }
            static month(e) {
              return new Month(e);
            }
            static monthNames(e) {
              return new MonthNames(e);
            }
            static numberPattern(e) {
              return new NumberPattern(e);
            }
            static numberPatterns(e) {
              return new NumberPatterns(e);
            }
            static numberSymbol(e) {
              return new NumberSymbol(e);
            }
            static numberSymbols(e) {
              return new NumberSymbols(e);
            }
            static timePattern(e) {
              return new TimePattern(e);
            }
            static timePatterns(e) {
              return new TimePatterns(e);
            }
            static typeFace(e) {
              return new TypeFace(e);
            }
            static typeFaces(e) {
              return new TypeFaces(e);
            }
          }
          t.LocaleSetNamespace = LocaleSetNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.SignatureNamespace = void 0;
          var r = a(77),
            n = a(75);
          const i = r.NamespaceIds.signature.id;
          class Signature extends n.XFAObject {
            constructor(e) {
              super(i, "signature", !0);
            }
          }
          class SignatureNamespace {
            static [r.$buildXFAObject](e, t) {
              if (SignatureNamespace.hasOwnProperty(e))
                return SignatureNamespace[e](t);
            }
            static signature(e) {
              return new Signature(e);
            }
          }
          t.SignatureNamespace = SignatureNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.StylesheetNamespace = void 0;
          var r = a(77),
            n = a(75);
          const i = r.NamespaceIds.stylesheet.id;
          class Stylesheet extends n.XFAObject {
            constructor(e) {
              super(i, "stylesheet", !0);
            }
          }
          class StylesheetNamespace {
            static [r.$buildXFAObject](e, t) {
              if (StylesheetNamespace.hasOwnProperty(e))
                return StylesheetNamespace[e](t);
            }
            static stylesheet(e) {
              return new Stylesheet(e);
            }
          }
          t.StylesheetNamespace = StylesheetNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XdpNamespace = void 0;
          var r = a(77),
            n = a(75);
          const i = r.NamespaceIds.xdp.id;
          class Xdp extends n.XFAObject {
            constructor(e) {
              super(i, "xdp", !0);
              this.uuid = e.uuid || "";
              this.timeStamp = e.timeStamp || "";
              this.config = null;
              this.connectionSet = null;
              this.datasets = null;
              this.localeSet = null;
              this.stylesheet = new n.XFAObjectArray();
              this.template = null;
            }
            [n.$onChildCheck](e) {
              const t = r.NamespaceIds[e[n.$nodeName]];
              return t && e[n.$namespaceId] === t.id;
            }
          }
          class XdpNamespace {
            static [r.$buildXFAObject](e, t) {
              if (XdpNamespace.hasOwnProperty(e)) return XdpNamespace[e](t);
            }
            static xdp(e) {
              return new Xdp(e);
            }
          }
          t.XdpNamespace = XdpNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XhtmlNamespace = void 0;
          var r = a(75),
            n = a(77),
            i = a(82),
            s = a(76);
          const o = n.NamespaceIds.xhtml.id,
            c = Symbol(),
            l = new Set([
              "color",
              "font",
              "font-family",
              "font-size",
              "font-stretch",
              "font-style",
              "font-weight",
              "margin",
              "margin-bottom",
              "margin-left",
              "margin-right",
              "margin-top",
              "letter-spacing",
              "line-height",
              "orphans",
              "page-break-after",
              "page-break-before",
              "page-break-inside",
              "tab-interval",
              "tab-stop",
              "text-align",
              "text-decoration",
              "text-indent",
              "vertical-align",
              "widows",
              "kerning-mode",
              "xfa-font-horizontal-scale",
              "xfa-font-vertical-scale",
              "xfa-spacerun",
              "xfa-tab-stops",
            ]),
            h = new Map([
              ["page-break-after", "breakAfter"],
              ["page-break-before", "breakBefore"],
              ["page-break-inside", "breakInside"],
              ["kerning-mode", (e) => ("none" === e ? "none" : "normal")],
              [
                "xfa-font-horizontal-scale",
                (e) =>
                  `scaleX(${Math.max(0, Math.min(parseInt(e) / 100)).toFixed(
                    2
                  )})`,
              ],
              [
                "xfa-font-vertical-scale",
                (e) =>
                  `scaleY(${Math.max(0, Math.min(parseInt(e) / 100)).toFixed(
                    2
                  )})`,
              ],
              ["xfa-spacerun", ""],
              ["xfa-tab-stops", ""],
              [
                "font-size",
                (e, t) => {
                  e = t.fontSize = (0, s.getMeasurement)(e);
                  return (0, i.measureToString)(0.99 * e);
                },
              ],
              [
                "letter-spacing",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "line-height",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "margin",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "margin-bottom",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "margin-left",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "margin-right",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "margin-top",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              [
                "text-indent",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
              ["font-family", (e) => e],
              [
                "vertical-align",
                (e) => (0, i.measureToString)((0, s.getMeasurement)(e)),
              ],
            ]),
            u = /\s+/g,
            d = /[\r\n]+/g,
            f = /\r\n?/g;
          function mapStyle(e, t, a) {
            const n = Object.create(null);
            if (!e) return n;
            const o = Object.create(null);
            for (const [t, a] of e.split(";").map((e) => e.split(":", 2))) {
              const e = h.get(t);
              if ("" === e) continue;
              let r = a;
              e && (r = "string" == typeof e ? e : e(a, o));
              t.endsWith("scale")
                ? n.transform
                  ? (n.transform = `${n[t]} ${r}`)
                  : (n.transform = r)
                : (n[t.replaceAll(/-([a-zA-Z])/g, (e, t) => t.toUpperCase())] =
                    r);
            }
            n.fontFamily &&
              (0, i.setFontFamily)(
                {
                  typeface: n.fontFamily,
                  weight: n.fontWeight || "normal",
                  posture: n.fontStyle || "normal",
                  size: o.fontSize || 0,
                },
                t,
                t[r.$globalData].fontFinder,
                n
              );
            if (
              a &&
              n.verticalAlign &&
              "0px" !== n.verticalAlign &&
              n.fontSize
            ) {
              const e = 0.583,
                t = 0.333,
                a = (0, s.getMeasurement)(n.fontSize);
              n.fontSize = (0, i.measureToString)(a * e);
              n.verticalAlign = (0, i.measureToString)(
                Math.sign((0, s.getMeasurement)(n.verticalAlign)) * a * t
              );
            }
            a &&
              n.fontSize &&
              (n.fontSize = `calc(${n.fontSize} * var(--scale-factor))`);
            (0, i.fixTextIndent)(n);
            return n;
          }
          const g = new Set(["body", "html"]);
          class XhtmlObject extends r.XmlObject {
            constructor(e, t) {
              super(o, t);
              this[c] = !1;
              this.style = e.style || "";
            }
            [r.$clean](e) {
              super[r.$clean](e);
              this.style = (function checkStyle(e) {
                return e.style
                  ? e.style
                      .trim()
                      .split(/\s*;\s*/)
                      .filter((e) => !!e)
                      .map((e) => e.split(/\s*:\s*/, 2))
                      .filter(([t, a]) => {
                        "font-family" === t &&
                          e[r.$globalData].usedTypefaces.add(a);
                        return l.has(t);
                      })
                      .map((e) => e.join(":"))
                      .join(";")
                  : "";
              })(this);
            }
            [r.$acceptWhitespace]() {
              return !g.has(this[r.$nodeName]);
            }
            [r.$onText](e, t = !1) {
              if (t) this[c] = !0;
              else {
                e = e.replace(d, "");
                this.style.includes("xfa-spacerun:yes") ||
                  (e = e.replace(u, " "));
              }
              e && (this[r.$content] += e);
            }
            [r.$pushGlyphs](e, t = !0) {
              const a = Object.create(null),
                n = { top: NaN, bottom: NaN, left: NaN, right: NaN };
              let i = null;
              for (const [e, t] of this.style
                .split(";")
                .map((e) => e.split(":", 2)))
                switch (e) {
                  case "font-family":
                    a.typeface = (0, s.stripQuotes)(t);
                    break;
                  case "font-size":
                    a.size = (0, s.getMeasurement)(t);
                    break;
                  case "font-weight":
                    a.weight = t;
                    break;
                  case "font-style":
                    a.posture = t;
                    break;
                  case "letter-spacing":
                    a.letterSpacing = (0, s.getMeasurement)(t);
                    break;
                  case "margin":
                    const e = t
                      .split(/ \t/)
                      .map((e) => (0, s.getMeasurement)(e));
                    switch (e.length) {
                      case 1:
                        n.top = n.bottom = n.left = n.right = e[0];
                        break;
                      case 2:
                        n.top = n.bottom = e[0];
                        n.left = n.right = e[1];
                        break;
                      case 3:
                        n.top = e[0];
                        n.bottom = e[2];
                        n.left = n.right = e[1];
                        break;
                      case 4:
                        n.top = e[0];
                        n.left = e[1];
                        n.bottom = e[2];
                        n.right = e[3];
                    }
                    break;
                  case "margin-top":
                    n.top = (0, s.getMeasurement)(t);
                    break;
                  case "margin-bottom":
                    n.bottom = (0, s.getMeasurement)(t);
                    break;
                  case "margin-left":
                    n.left = (0, s.getMeasurement)(t);
                    break;
                  case "margin-right":
                    n.right = (0, s.getMeasurement)(t);
                    break;
                  case "line-height":
                    i = (0, s.getMeasurement)(t);
                }
              e.pushData(a, n, i);
              if (this[r.$content]) e.addString(this[r.$content]);
              else
                for (const t of this[r.$getChildren]())
                  "#text" !== t[r.$nodeName]
                    ? t[r.$pushGlyphs](e)
                    : e.addString(t[r.$content]);
              t && e.popFont();
            }
            [r.$toHTML](e) {
              const t = [];
              this[r.$extra] = { children: t };
              this[r.$childrenToHTML]({});
              if (0 === t.length && !this[r.$content])
                return s.HTMLResult.EMPTY;
              let a;
              a = this[c]
                ? this[r.$content]
                  ? this[r.$content].replace(f, "\n")
                  : void 0
                : this[r.$content] || void 0;
              return s.HTMLResult.success({
                name: this[r.$nodeName],
                attributes: {
                  href: this.href,
                  style: mapStyle(this.style, this, this[c]),
                },
                children: t,
                value: a,
              });
            }
          }
          class A extends XhtmlObject {
            constructor(e) {
              super(e, "a");
              this.href = (0, i.fixURL)(e.href) || "";
            }
          }
          class B extends XhtmlObject {
            constructor(e) {
              super(e, "b");
            }
            [r.$pushGlyphs](e) {
              e.pushFont({ weight: "bold" });
              super[r.$pushGlyphs](e);
              e.popFont();
            }
          }
          class Body extends XhtmlObject {
            constructor(e) {
              super(e, "body");
            }
            [r.$toHTML](e) {
              const t = super[r.$toHTML](e),
                { html: a } = t;
              if (!a) return s.HTMLResult.EMPTY;
              a.name = "div";
              a.attributes.class = ["xfaRich"];
              return t;
            }
          }
          class Br extends XhtmlObject {
            constructor(e) {
              super(e, "br");
            }
            [r.$text]() {
              return "\n";
            }
            [r.$pushGlyphs](e) {
              e.addString("\n");
            }
            [r.$toHTML](e) {
              return s.HTMLResult.success({ name: "br" });
            }
          }
          class Html extends XhtmlObject {
            constructor(e) {
              super(e, "html");
            }
            [r.$toHTML](e) {
              const t = [];
              this[r.$extra] = { children: t };
              this[r.$childrenToHTML]({});
              if (0 === t.length)
                return s.HTMLResult.success({
                  name: "div",
                  attributes: { class: ["xfaRich"], style: {} },
                  value: this[r.$content] || "",
                });
              if (1 === t.length) {
                const e = t[0];
                if (e.attributes && e.attributes.class.includes("xfaRich"))
                  return s.HTMLResult.success(e);
              }
              return s.HTMLResult.success({
                name: "div",
                attributes: { class: ["xfaRich"], style: {} },
                children: t,
              });
            }
          }
          class I extends XhtmlObject {
            constructor(e) {
              super(e, "i");
            }
            [r.$pushGlyphs](e) {
              e.pushFont({ posture: "italic" });
              super[r.$pushGlyphs](e);
              e.popFont();
            }
          }
          class Li extends XhtmlObject {
            constructor(e) {
              super(e, "li");
            }
          }
          class Ol extends XhtmlObject {
            constructor(e) {
              super(e, "ol");
            }
          }
          class P extends XhtmlObject {
            constructor(e) {
              super(e, "p");
            }
            [r.$pushGlyphs](e) {
              super[r.$pushGlyphs](e, !1);
              e.addString("\n");
              e.addPara();
              e.popFont();
            }
            [r.$text]() {
              return this[r.$getParent]()[r.$getChildren]().at(-1) === this
                ? super[r.$text]()
                : super[r.$text]() + "\n";
            }
          }
          class Span extends XhtmlObject {
            constructor(e) {
              super(e, "span");
            }
          }
          class Sub extends XhtmlObject {
            constructor(e) {
              super(e, "sub");
            }
          }
          class Sup extends XhtmlObject {
            constructor(e) {
              super(e, "sup");
            }
          }
          class Ul extends XhtmlObject {
            constructor(e) {
              super(e, "ul");
            }
          }
          class XhtmlNamespace {
            static [n.$buildXFAObject](e, t) {
              if (XhtmlNamespace.hasOwnProperty(e)) return XhtmlNamespace[e](t);
            }
            static a(e) {
              return new A(e);
            }
            static b(e) {
              return new B(e);
            }
            static body(e) {
              return new Body(e);
            }
            static br(e) {
              return new Br(e);
            }
            static html(e) {
              return new Html(e);
            }
            static i(e) {
              return new I(e);
            }
            static li(e) {
              return new Li(e);
            }
            static ol(e) {
              return new Ol(e);
            }
            static p(e) {
              return new P(e);
            }
            static span(e) {
              return new Span(e);
            }
            static sub(e) {
              return new Sub(e);
            }
            static sup(e) {
              return new Sup(e);
            }
            static ul(e) {
              return new Ul(e);
            }
          }
          t.XhtmlNamespace = XhtmlNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.UnknownNamespace = void 0;
          var r = a(77),
            n = a(75);
          class UnknownNamespace {
            constructor(e) {
              this.namespaceId = e;
            }
            [r.$buildXFAObject](e, t) {
              return new n.XmlObject(this.namespaceId, e, t);
            }
          }
          t.UnknownNamespace = UnknownNamespace;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.DatasetReader = void 0;
          var r = a(2),
            n = a(3),
            i = a(64);
          function decodeString(e) {
            try {
              return (0, r.stringToUTF8String)(e);
            } catch (t) {
              (0, r.warn)(`UTF-8 decoding failed: "${t}".`);
              return e;
            }
          }
          class DatasetXMLParser extends i.SimpleXMLParser {
            constructor(e) {
              super(e);
              this.node = null;
            }
            onEndElement(e) {
              const t = super.onEndElement(e);
              if (t && "xfa:datasets" === e) {
                this.node = t;
                throw new Error("Aborting DatasetXMLParser.");
              }
            }
          }
          t.DatasetReader = class DatasetReader {
            constructor(e) {
              if (e.datasets)
                this.node = new i.SimpleXMLParser({
                  hasAttributes: !0,
                }).parseFromString(e.datasets).documentElement;
              else {
                const t = new DatasetXMLParser({ hasAttributes: !0 });
                try {
                  t.parseFromString(e["xdp:xdp"]);
                } catch (e) {}
                this.node = t.node;
              }
            }
            getValue(e) {
              if (!this.node || !e) return "";
              const t = this.node.searchNode((0, n.parseXFAPath)(e), 0);
              if (!t) return "";
              const a = t.firstChild;
              return a && "value" === a.nodeName
                ? t.children.map((e) => decodeString(e.textContent))
                : decodeString(t.textContent);
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.XRef = void 0;
          var r = a(2),
            n = a(4),
            i = a(15),
            s = a(3),
            o = a(5),
            c = a(65);
          t.XRef = class XRef {
            constructor(e, t) {
              this.stream = e;
              this.pdfManager = t;
              this.entries = [];
              this.xrefstms = Object.create(null);
              this._cacheMap = new Map();
              this._pendingRefs = new n.RefSet();
              this._newPersistentRefNum = null;
              this._newTemporaryRefNum = null;
            }
            getNewPersistentRef(e) {
              null === this._newPersistentRefNum &&
                (this._newPersistentRefNum = this.entries.length || 1);
              const t = this._newPersistentRefNum++;
              this._cacheMap.set(t, e);
              return n.Ref.get(t, 0);
            }
            getNewTemporaryRef() {
              null === this._newTemporaryRefNum &&
                (this._newTemporaryRefNum = this.entries.length || 1);
              return n.Ref.get(this._newTemporaryRefNum++, 0);
            }
            resetNewTemporaryRef() {
              this._newTemporaryRefNum = null;
            }
            setStartXRef(e) {
              this.startXRefQueue = [e];
            }
            parse(e = !1) {
              let t, a, i;
              if (e) {
                (0, r.warn)("Indexing all PDF objects");
                t = this.indexObjects();
              } else t = this.readXRef();
              t.assignXref(this);
              this.trailer = t;
              try {
                a = t.get("Encrypt");
              } catch (e) {
                if (e instanceof s.MissingDataException) throw e;
                (0, r.warn)(
                  `XRef.parse - Invalid "Encrypt" reference: "${e}".`
                );
              }
              if (a instanceof n.Dict) {
                const e = t.get("ID"),
                  r = e && e.length ? e[0] : "";
                a.suppressEncryption = !0;
                this.encrypt = new c.CipherTransformFactory(
                  a,
                  r,
                  this.pdfManager.password
                );
              }
              try {
                i = t.get("Root");
              } catch (e) {
                if (e instanceof s.MissingDataException) throw e;
                (0, r.warn)(`XRef.parse - Invalid "Root" reference: "${e}".`);
              }
              if (i instanceof n.Dict)
                try {
                  if (i.get("Pages") instanceof n.Dict) {
                    this.root = i;
                    return;
                  }
                } catch (e) {
                  if (e instanceof s.MissingDataException) throw e;
                  (0, r.warn)(
                    `XRef.parse - Invalid "Pages" reference: "${e}".`
                  );
                }
              if (!e) throw new s.XRefParseException();
              throw new r.InvalidPDFException("Invalid Root reference.");
            }
            processXRefTable(e) {
              "tableState" in this ||
                (this.tableState = {
                  entryNum: 0,
                  streamPos: e.lexer.stream.pos,
                  parserBuf1: e.buf1,
                  parserBuf2: e.buf2,
                });
              const t = this.readXRefTable(e);
              if (!(0, n.isCmd)(t, "trailer"))
                throw new r.FormatError(
                  "Invalid XRef table: could not find trailer dictionary"
                );
              let a = e.getObj();
              a instanceof n.Dict || !a.dict || (a = a.dict);
              if (!(a instanceof n.Dict))
                throw new r.FormatError(
                  "Invalid XRef table: could not parse trailer dictionary"
                );
              delete this.tableState;
              return a;
            }
            readXRefTable(e) {
              const t = e.lexer.stream,
                a = this.tableState;
              t.pos = a.streamPos;
              e.buf1 = a.parserBuf1;
              e.buf2 = a.parserBuf2;
              let i;
              for (;;) {
                if (!("firstEntryNum" in a) || !("entryCount" in a)) {
                  if ((0, n.isCmd)((i = e.getObj()), "trailer")) break;
                  a.firstEntryNum = i;
                  a.entryCount = e.getObj();
                }
                let s = a.firstEntryNum;
                const o = a.entryCount;
                if (!Number.isInteger(s) || !Number.isInteger(o))
                  throw new r.FormatError(
                    "Invalid XRef table: wrong types in subsection header"
                  );
                for (let i = a.entryNum; i < o; i++) {
                  a.streamPos = t.pos;
                  a.entryNum = i;
                  a.parserBuf1 = e.buf1;
                  a.parserBuf2 = e.buf2;
                  const c = {};
                  c.offset = e.getObj();
                  c.gen = e.getObj();
                  const l = e.getObj();
                  if (l instanceof n.Cmd)
                    switch (l.cmd) {
                      case "f":
                        c.free = !0;
                        break;
                      case "n":
                        c.uncompressed = !0;
                    }
                  if (
                    !Number.isInteger(c.offset) ||
                    !Number.isInteger(c.gen) ||
                    (!c.free && !c.uncompressed)
                  )
                    throw new r.FormatError(
                      `Invalid entry in XRef subsection: ${s}, ${o}`
                    );
                  0 === i && c.free && 1 === s && (s = 0);
                  this.entries[i + s] || (this.entries[i + s] = c);
                }
                a.entryNum = 0;
                a.streamPos = t.pos;
                a.parserBuf1 = e.buf1;
                a.parserBuf2 = e.buf2;
                delete a.firstEntryNum;
                delete a.entryCount;
              }
              if (this.entries[0] && !this.entries[0].free)
                throw new r.FormatError(
                  "Invalid XRef table: unexpected first object"
                );
              return i;
            }
            processXRefStream(e) {
              if (!("streamState" in this)) {
                const t = e.dict,
                  a = t.get("W");
                let r = t.get("Index");
                r || (r = [0, t.get("Size")]);
                this.streamState = {
                  entryRanges: r,
                  byteWidths: a,
                  entryNum: 0,
                  streamPos: e.pos,
                };
              }
              this.readXRefStream(e);
              delete this.streamState;
              return e.dict;
            }
            readXRefStream(e) {
              const t = this.streamState;
              e.pos = t.streamPos;
              const [a, n, i] = t.byteWidths,
                s = t.entryRanges;
              for (; s.length > 0; ) {
                const [o, c] = s;
                if (!Number.isInteger(o) || !Number.isInteger(c))
                  throw new r.FormatError(
                    `Invalid XRef range fields: ${o}, ${c}`
                  );
                if (
                  !Number.isInteger(a) ||
                  !Number.isInteger(n) ||
                  !Number.isInteger(i)
                )
                  throw new r.FormatError(
                    `Invalid XRef entry fields length: ${o}, ${c}`
                  );
                for (let s = t.entryNum; s < c; ++s) {
                  t.entryNum = s;
                  t.streamPos = e.pos;
                  let c = 0,
                    l = 0,
                    h = 0;
                  for (let t = 0; t < a; ++t) {
                    const t = e.getByte();
                    if (-1 === t)
                      throw new r.FormatError(
                        "Invalid XRef byteWidths 'type'."
                      );
                    c = (c << 8) | t;
                  }
                  0 === a && (c = 1);
                  for (let t = 0; t < n; ++t) {
                    const t = e.getByte();
                    if (-1 === t)
                      throw new r.FormatError(
                        "Invalid XRef byteWidths 'offset'."
                      );
                    l = (l << 8) | t;
                  }
                  for (let t = 0; t < i; ++t) {
                    const t = e.getByte();
                    if (-1 === t)
                      throw new r.FormatError(
                        "Invalid XRef byteWidths 'generation'."
                      );
                    h = (h << 8) | t;
                  }
                  const u = {};
                  u.offset = l;
                  u.gen = h;
                  switch (c) {
                    case 0:
                      u.free = !0;
                      break;
                    case 1:
                      u.uncompressed = !0;
                      break;
                    case 2:
                      break;
                    default:
                      throw new r.FormatError(`Invalid XRef entry type: ${c}`);
                  }
                  this.entries[o + s] || (this.entries[o + s] = u);
                }
                t.entryNum = 0;
                t.streamPos = e.pos;
                s.splice(0, 2);
              }
            }
            indexObjects() {
              const e = 10,
                t = 13,
                a = 60;
              function readToken(r, n) {
                let i = "",
                  s = r[n];
                for (; s !== e && s !== t && s !== a && !(++n >= r.length); ) {
                  i += String.fromCharCode(s);
                  s = r[n];
                }
                return i;
              }
              function skipUntil(e, t, a) {
                const r = a.length,
                  n = e.length;
                let i = 0;
                for (; t < n; ) {
                  let n = 0;
                  for (; n < r && e[t + n] === a[n]; ) ++n;
                  if (n >= r) break;
                  t++;
                  i++;
                }
                return i;
              }
              const o = /\b(endobj|\d+\s+\d+\s+obj|xref|trailer)\b/g,
                c = /\b(startxref|\d+\s+\d+\s+obj)\b/g,
                l = /^(\d+)\s+(\d+)\s+obj\b/,
                h = new Uint8Array([116, 114, 97, 105, 108, 101, 114]),
                u = new Uint8Array([
                  115, 116, 97, 114, 116, 120, 114, 101, 102,
                ]),
                d = new Uint8Array([47, 88, 82, 101, 102]);
              this.entries.length = 0;
              this._cacheMap.clear();
              const f = this.stream;
              f.pos = 0;
              const g = f.getBytes(),
                p = (0, r.bytesToString)(g),
                m = g.length;
              let b = f.start;
              const y = [],
                w = [];
              for (; b < m; ) {
                let a = g[b];
                if (9 === a || a === e || a === t || 32 === a) {
                  ++b;
                  continue;
                }
                if (37 === a) {
                  do {
                    ++b;
                    if (b >= m) break;
                    a = g[b];
                  } while (a !== e && a !== t);
                  continue;
                }
                const n = readToken(g, b);
                let S;
                if (
                  n.startsWith("xref") &&
                  (4 === n.length || /\s/.test(n[4]))
                ) {
                  b += skipUntil(g, b, h);
                  y.push(b);
                  b += skipUntil(g, b, u);
                } else if ((S = l.exec(n))) {
                  const e = 0 | S[1],
                    t = 0 | S[2],
                    a = b + n.length;
                  let c,
                    l = !1;
                  if (this.entries[e]) {
                    if (this.entries[e].gen === t)
                      try {
                        new i.Parser({
                          lexer: new i.Lexer(f.makeSubStream(a)),
                        }).getObj();
                        l = !0;
                      } catch (e) {
                        e instanceof s.ParserEOFException
                          ? (0, r.warn)(
                              `indexObjects -- checking object (${n}): "${e}".`
                            )
                          : (l = !0);
                      }
                  } else l = !0;
                  l &&
                    (this.entries[e] = {
                      offset: b - f.start,
                      gen: t,
                      uncompressed: !0,
                    });
                  o.lastIndex = a;
                  const h = o.exec(p);
                  if (h) {
                    c = o.lastIndex + 1 - b;
                    if ("endobj" !== h[1]) {
                      (0, r.warn)(
                        `indexObjects: Found "${h[1]}" inside of another "obj", caused by missing "endobj" -- trying to recover.`
                      );
                      c -= h[1].length + 1;
                    }
                  } else c = m - b;
                  const u = g.subarray(b, b + c),
                    y = skipUntil(u, 0, d);
                  if (y < c && u[y + 5] < 64) {
                    w.push(b - f.start);
                    this.xrefstms[b - f.start] = 1;
                  }
                  b += c;
                } else if (
                  n.startsWith("trailer") &&
                  (7 === n.length || /\s/.test(n[7]))
                ) {
                  y.push(b);
                  const e = b + n.length;
                  let t;
                  c.lastIndex = e;
                  const a = c.exec(p);
                  if (a) {
                    t = c.lastIndex + 1 - b;
                    if ("startxref" !== a[1]) {
                      (0, r.warn)(
                        `indexObjects: Found "${a[1]}" after "trailer", caused by missing "startxref" -- trying to recover.`
                      );
                      t -= a[1].length + 1;
                    }
                  } else t = m - b;
                  b += t;
                } else b += n.length + 1;
              }
              for (const e of w) {
                this.startXRefQueue.push(e);
                this.readXRef(!0);
              }
              const S = [];
              let x,
                C,
                k = !1;
              for (const e of y) {
                f.pos = e;
                const t = new i.Parser({
                    lexer: new i.Lexer(f),
                    xref: this,
                    allowStreams: !0,
                    recoveryMode: !0,
                  }),
                  a = t.getObj();
                if (!(0, n.isCmd)(a, "trailer")) continue;
                const r = t.getObj();
                if (r instanceof n.Dict) {
                  S.push(r);
                  r.has("Encrypt") && (k = !0);
                }
              }
              for (const e of [...S, "genFallback", ...S]) {
                if ("genFallback" === e) {
                  if (!C) break;
                  this._generationFallback = !0;
                  continue;
                }
                let t = !1;
                try {
                  const a = e.get("Root");
                  if (!(a instanceof n.Dict)) continue;
                  const r = a.get("Pages");
                  if (!(r instanceof n.Dict)) continue;
                  const i = r.get("Count");
                  Number.isInteger(i) && (t = !0);
                } catch (e) {
                  C = e;
                  continue;
                }
                if (t && (!k || e.has("Encrypt")) && e.has("ID")) return e;
                x = e;
              }
              if (x) return x;
              if (this.topDict) return this.topDict;
              throw new r.InvalidPDFException("Invalid PDF structure.");
            }
            readXRef(e = !1) {
              const t = this.stream,
                a = new Set();
              for (; this.startXRefQueue.length; ) {
                try {
                  const e = this.startXRefQueue[0];
                  if (a.has(e)) {
                    (0, r.warn)(
                      "readXRef - skipping XRef table since it was already parsed."
                    );
                    this.startXRefQueue.shift();
                    continue;
                  }
                  a.add(e);
                  t.pos = e + t.start;
                  const s = new i.Parser({
                    lexer: new i.Lexer(t),
                    xref: this,
                    allowStreams: !0,
                  });
                  let c,
                    l = s.getObj();
                  if ((0, n.isCmd)(l, "xref")) {
                    c = this.processXRefTable(s);
                    this.topDict || (this.topDict = c);
                    l = c.get("XRefStm");
                    if (Number.isInteger(l)) {
                      const e = l;
                      if (!(e in this.xrefstms)) {
                        this.xrefstms[e] = 1;
                        this.startXRefQueue.push(e);
                      }
                    }
                  } else {
                    if (!Number.isInteger(l))
                      throw new r.FormatError("Invalid XRef stream header");
                    if (
                      !(
                        Number.isInteger(s.getObj()) &&
                        (0, n.isCmd)(s.getObj(), "obj") &&
                        (l = s.getObj()) instanceof o.BaseStream
                      )
                    )
                      throw new r.FormatError("Invalid XRef stream");
                    c = this.processXRefStream(l);
                    this.topDict || (this.topDict = c);
                    if (!c)
                      throw new r.FormatError("Failed to read XRef stream");
                  }
                  l = c.get("Prev");
                  Number.isInteger(l)
                    ? this.startXRefQueue.push(l)
                    : l instanceof n.Ref && this.startXRefQueue.push(l.num);
                } catch (e) {
                  if (e instanceof s.MissingDataException) throw e;
                  (0, r.info)("(while reading XRef): " + e);
                }
                this.startXRefQueue.shift();
              }
              if (this.topDict) return this.topDict;
              if (!e) throw new s.XRefParseException();
            }
            getEntry(e) {
              const t = this.entries[e];
              return t && !t.free && t.offset ? t : null;
            }
            fetchIfRef(e, t = !1) {
              return e instanceof n.Ref ? this.fetch(e, t) : e;
            }
            fetch(e, t = !1) {
              if (!(e instanceof n.Ref))
                throw new Error("ref object is not a reference");
              const a = e.num,
                i = this._cacheMap.get(a);
              if (void 0 !== i) {
                i instanceof n.Dict && !i.objId && (i.objId = e.toString());
                return i;
              }
              let s = this.getEntry(a);
              if (null === s) {
                this._cacheMap.set(a, s);
                return s;
              }
              if (this._pendingRefs.has(e)) {
                this._pendingRefs.remove(e);
                (0, r.warn)(`Ignoring circular reference: ${e}.`);
                return n.CIRCULAR_REF;
              }
              this._pendingRefs.put(e);
              try {
                s = s.uncompressed
                  ? this.fetchUncompressed(e, s, t)
                  : this.fetchCompressed(e, s, t);
                this._pendingRefs.remove(e);
              } catch (t) {
                this._pendingRefs.remove(e);
                throw t;
              }
              s instanceof n.Dict
                ? (s.objId = e.toString())
                : s instanceof o.BaseStream && (s.dict.objId = e.toString());
              return s;
            }
            fetchUncompressed(e, t, a = !1) {
              const c = e.gen;
              let l = e.num;
              if (t.gen !== c) {
                const i = `Inconsistent generation in XRef: ${e}`;
                if (this._generationFallback && t.gen < c) {
                  (0, r.warn)(i);
                  return this.fetchUncompressed(n.Ref.get(l, t.gen), t, a);
                }
                throw new s.XRefEntryException(i);
              }
              const h = this.stream.makeSubStream(t.offset + this.stream.start),
                u = new i.Parser({
                  lexer: new i.Lexer(h),
                  xref: this,
                  allowStreams: !0,
                }),
                d = u.getObj(),
                f = u.getObj(),
                g = u.getObj();
              if (d !== l || f !== c || !(g instanceof n.Cmd))
                throw new s.XRefEntryException(
                  `Bad (uncompressed) XRef entry: ${e}`
                );
              if ("obj" !== g.cmd) {
                if (g.cmd.startsWith("obj")) {
                  l = parseInt(g.cmd.substring(3), 10);
                  if (!Number.isNaN(l)) return l;
                }
                throw new s.XRefEntryException(
                  `Bad (uncompressed) XRef entry: ${e}`
                );
              }
              (t =
                this.encrypt && !a
                  ? u.getObj(this.encrypt.createCipherTransform(l, c))
                  : u.getObj()) instanceof o.BaseStream ||
                this._cacheMap.set(l, t);
              return t;
            }
            fetchCompressed(e, t, a = !1) {
              const c = t.offset,
                l = this.fetch(n.Ref.get(c, 0));
              if (!(l instanceof o.BaseStream))
                throw new r.FormatError("bad ObjStm stream");
              const h = l.dict.get("First"),
                u = l.dict.get("N");
              if (!Number.isInteger(h) || !Number.isInteger(u))
                throw new r.FormatError(
                  "invalid first and n parameters for ObjStm stream"
                );
              let d = new i.Parser({
                lexer: new i.Lexer(l),
                xref: this,
                allowStreams: !0,
              });
              const f = new Array(u),
                g = new Array(u);
              for (let e = 0; e < u; ++e) {
                const t = d.getObj();
                if (!Number.isInteger(t))
                  throw new r.FormatError(
                    `invalid object number in the ObjStm stream: ${t}`
                  );
                const a = d.getObj();
                if (!Number.isInteger(a))
                  throw new r.FormatError(
                    `invalid object offset in the ObjStm stream: ${a}`
                  );
                f[e] = t;
                g[e] = a;
              }
              const p = (l.start || 0) + h,
                m = new Array(u);
              for (let e = 0; e < u; ++e) {
                const t = e < u - 1 ? g[e + 1] - g[e] : void 0;
                if (t < 0)
                  throw new r.FormatError(
                    "Invalid offset in the ObjStm stream."
                  );
                d = new i.Parser({
                  lexer: new i.Lexer(l.makeSubStream(p + g[e], t, l.dict)),
                  xref: this,
                  allowStreams: !0,
                });
                const a = d.getObj();
                m[e] = a;
                if (a instanceof o.BaseStream) continue;
                const n = f[e],
                  s = this.entries[n];
                s && s.offset === c && s.gen === e && this._cacheMap.set(n, a);
              }
              if (void 0 === (t = m[t.gen]))
                throw new s.XRefEntryException(
                  `Bad (compressed) XRef entry: ${e}`
                );
              return t;
            }
            async fetchIfRefAsync(e, t) {
              return e instanceof n.Ref ? this.fetchAsync(e, t) : e;
            }
            async fetchAsync(e, t) {
              try {
                return this.fetch(e, t);
              } catch (a) {
                if (!(a instanceof s.MissingDataException)) throw a;
                await this.pdfManager.requestRange(a.begin, a.end);
                return this.fetchAsync(e, t);
              }
            }
            getCatalogObj() {
              return this.root;
            }
          };
        },
        (e, t) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.isNodeJS = void 0;
          const a = !(
            "object" != typeof process ||
            process + "" != "[object process]" ||
            process.versions.nw ||
            (process.versions.electron &&
              process.type &&
              "browser" !== process.type)
          );
          t.isNodeJS = a;
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.MessageHandler = void 0;
          var r = a(2);
          const n = 1,
            i = 2,
            s = 1,
            o = 2,
            c = 3,
            l = 4,
            h = 5,
            u = 6,
            d = 7,
            f = 8;
          function wrapReason(e) {
            e instanceof Error ||
              ("object" == typeof e && null !== e) ||
              (0, r.unreachable)(
                'wrapReason: Expected "reason" to be a (possibly cloned) Error.'
              );
            switch (e.name) {
              case "AbortException":
                return new r.AbortException(e.message);
              case "MissingPDFException":
                return new r.MissingPDFException(e.message);
              case "PasswordException":
                return new r.PasswordException(e.message, e.code);
              case "UnexpectedResponseException":
                return new r.UnexpectedResponseException(e.message, e.status);
              case "UnknownErrorException":
                return new r.UnknownErrorException(e.message, e.details);
              default:
                return new r.UnknownErrorException(e.message, e.toString());
            }
          }
          t.MessageHandler = class MessageHandler {
            constructor(e, t, a) {
              this.sourceName = e;
              this.targetName = t;
              this.comObj = a;
              this.callbackId = 1;
              this.streamId = 1;
              this.streamSinks = Object.create(null);
              this.streamControllers = Object.create(null);
              this.callbackCapabilities = Object.create(null);
              this.actionHandler = Object.create(null);
              this._onComObjOnMessage = (e) => {
                const t = e.data;
                if (t.targetName !== this.sourceName) return;
                if (t.stream) {
                  this._processStreamMessage(t);
                  return;
                }
                if (t.callback) {
                  const e = t.callbackId,
                    a = this.callbackCapabilities[e];
                  if (!a) throw new Error(`Cannot resolve callback ${e}`);
                  delete this.callbackCapabilities[e];
                  if (t.callback === n) a.resolve(t.data);
                  else {
                    if (t.callback !== i)
                      throw new Error("Unexpected callback case");
                    a.reject(wrapReason(t.reason));
                  }
                  return;
                }
                const r = this.actionHandler[t.action];
                if (!r)
                  throw new Error(`Unknown action from worker: ${t.action}`);
                if (t.callbackId) {
                  const e = this.sourceName,
                    s = t.sourceName;
                  new Promise(function (e) {
                    e(r(t.data));
                  }).then(
                    function (r) {
                      a.postMessage({
                        sourceName: e,
                        targetName: s,
                        callback: n,
                        callbackId: t.callbackId,
                        data: r,
                      });
                    },
                    function (r) {
                      a.postMessage({
                        sourceName: e,
                        targetName: s,
                        callback: i,
                        callbackId: t.callbackId,
                        reason: wrapReason(r),
                      });
                    }
                  );
                } else t.streamId ? this._createStreamSink(t) : r(t.data);
              };
              a.addEventListener("message", this._onComObjOnMessage);
            }
            on(e, t) {
              const a = this.actionHandler;
              if (a[e])
                throw new Error(`There is already an actionName called "${e}"`);
              a[e] = t;
            }
            send(e, t, a) {
              this.comObj.postMessage(
                {
                  sourceName: this.sourceName,
                  targetName: this.targetName,
                  action: e,
                  data: t,
                },
                a
              );
            }
            sendWithPromise(e, t, a) {
              const n = this.callbackId++,
                i = (0, r.createPromiseCapability)();
              this.callbackCapabilities[n] = i;
              try {
                this.comObj.postMessage(
                  {
                    sourceName: this.sourceName,
                    targetName: this.targetName,
                    action: e,
                    callbackId: n,
                    data: t,
                  },
                  a
                );
              } catch (e) {
                i.reject(e);
              }
              return i.promise;
            }
            sendWithStream(e, t, a, n) {
              const i = this.streamId++,
                o = this.sourceName,
                c = this.targetName,
                l = this.comObj;
              return new ReadableStream(
                {
                  start: (a) => {
                    const s = (0, r.createPromiseCapability)();
                    this.streamControllers[i] = {
                      controller: a,
                      startCall: s,
                      pullCall: null,
                      cancelCall: null,
                      isClosed: !1,
                    };
                    l.postMessage(
                      {
                        sourceName: o,
                        targetName: c,
                        action: e,
                        streamId: i,
                        data: t,
                        desiredSize: a.desiredSize,
                      },
                      n
                    );
                    return s.promise;
                  },
                  pull: (e) => {
                    const t = (0, r.createPromiseCapability)();
                    this.streamControllers[i].pullCall = t;
                    l.postMessage({
                      sourceName: o,
                      targetName: c,
                      stream: u,
                      streamId: i,
                      desiredSize: e.desiredSize,
                    });
                    return t.promise;
                  },
                  cancel: (e) => {
                    (0, r.assert)(
                      e instanceof Error,
                      "cancel must have a valid reason"
                    );
                    const t = (0, r.createPromiseCapability)();
                    this.streamControllers[i].cancelCall = t;
                    this.streamControllers[i].isClosed = !0;
                    l.postMessage({
                      sourceName: o,
                      targetName: c,
                      stream: s,
                      streamId: i,
                      reason: wrapReason(e),
                    });
                    return t.promise;
                  },
                },
                a
              );
            }
            _createStreamSink(e) {
              const t = e.streamId,
                a = this.sourceName,
                n = e.sourceName,
                i = this.comObj,
                s = this,
                o = this.actionHandler[e.action],
                u = {
                  enqueue(e, s = 1, o) {
                    if (this.isCancelled) return;
                    const c = this.desiredSize;
                    this.desiredSize -= s;
                    if (c > 0 && this.desiredSize <= 0) {
                      this.sinkCapability = (0, r.createPromiseCapability)();
                      this.ready = this.sinkCapability.promise;
                    }
                    i.postMessage(
                      {
                        sourceName: a,
                        targetName: n,
                        stream: l,
                        streamId: t,
                        chunk: e,
                      },
                      o
                    );
                  },
                  close() {
                    if (!this.isCancelled) {
                      this.isCancelled = !0;
                      i.postMessage({
                        sourceName: a,
                        targetName: n,
                        stream: c,
                        streamId: t,
                      });
                      delete s.streamSinks[t];
                    }
                  },
                  error(e) {
                    (0, r.assert)(
                      e instanceof Error,
                      "error must have a valid reason"
                    );
                    if (!this.isCancelled) {
                      this.isCancelled = !0;
                      i.postMessage({
                        sourceName: a,
                        targetName: n,
                        stream: h,
                        streamId: t,
                        reason: wrapReason(e),
                      });
                    }
                  },
                  sinkCapability: (0, r.createPromiseCapability)(),
                  onPull: null,
                  onCancel: null,
                  isCancelled: !1,
                  desiredSize: e.desiredSize,
                  ready: null,
                };
              u.sinkCapability.resolve();
              u.ready = u.sinkCapability.promise;
              this.streamSinks[t] = u;
              new Promise(function (t) {
                t(o(e.data, u));
              }).then(
                function () {
                  i.postMessage({
                    sourceName: a,
                    targetName: n,
                    stream: f,
                    streamId: t,
                    success: !0,
                  });
                },
                function (e) {
                  i.postMessage({
                    sourceName: a,
                    targetName: n,
                    stream: f,
                    streamId: t,
                    reason: wrapReason(e),
                  });
                }
              );
            }
            _processStreamMessage(e) {
              const t = e.streamId,
                a = this.sourceName,
                n = e.sourceName,
                i = this.comObj,
                g = this.streamControllers[t],
                p = this.streamSinks[t];
              switch (e.stream) {
                case f:
                  e.success
                    ? g.startCall.resolve()
                    : g.startCall.reject(wrapReason(e.reason));
                  break;
                case d:
                  e.success
                    ? g.pullCall.resolve()
                    : g.pullCall.reject(wrapReason(e.reason));
                  break;
                case u:
                  if (!p) {
                    i.postMessage({
                      sourceName: a,
                      targetName: n,
                      stream: d,
                      streamId: t,
                      success: !0,
                    });
                    break;
                  }
                  p.desiredSize <= 0 &&
                    e.desiredSize > 0 &&
                    p.sinkCapability.resolve();
                  p.desiredSize = e.desiredSize;
                  new Promise(function (e) {
                    e(p.onPull && p.onPull());
                  }).then(
                    function () {
                      i.postMessage({
                        sourceName: a,
                        targetName: n,
                        stream: d,
                        streamId: t,
                        success: !0,
                      });
                    },
                    function (e) {
                      i.postMessage({
                        sourceName: a,
                        targetName: n,
                        stream: d,
                        streamId: t,
                        reason: wrapReason(e),
                      });
                    }
                  );
                  break;
                case l:
                  (0, r.assert)(g, "enqueue should have stream controller");
                  if (g.isClosed) break;
                  g.controller.enqueue(e.chunk);
                  break;
                case c:
                  (0, r.assert)(g, "close should have stream controller");
                  if (g.isClosed) break;
                  g.isClosed = !0;
                  g.controller.close();
                  this._deleteStreamController(g, t);
                  break;
                case h:
                  (0, r.assert)(g, "error should have stream controller");
                  g.controller.error(wrapReason(e.reason));
                  this._deleteStreamController(g, t);
                  break;
                case o:
                  e.success
                    ? g.cancelCall.resolve()
                    : g.cancelCall.reject(wrapReason(e.reason));
                  this._deleteStreamController(g, t);
                  break;
                case s:
                  if (!p) break;
                  new Promise(function (t) {
                    t(p.onCancel && p.onCancel(wrapReason(e.reason)));
                  }).then(
                    function () {
                      i.postMessage({
                        sourceName: a,
                        targetName: n,
                        stream: o,
                        streamId: t,
                        success: !0,
                      });
                    },
                    function (e) {
                      i.postMessage({
                        sourceName: a,
                        targetName: n,
                        stream: o,
                        streamId: t,
                        reason: wrapReason(e),
                      });
                    }
                  );
                  p.sinkCapability.reject(wrapReason(e.reason));
                  p.isCancelled = !0;
                  delete this.streamSinks[t];
                  break;
                default:
                  throw new Error("Unexpected stream case");
              }
            }
            async _deleteStreamController(e, t) {
              await Promise.allSettled([
                e.startCall && e.startCall.promise,
                e.pullCall && e.pullCall.promise,
                e.cancelCall && e.cancelCall.promise,
              ]);
              delete this.streamControllers[t];
            }
            destroy() {
              this.comObj.removeEventListener(
                "message",
                this._onComObjOnMessage
              );
            }
          };
        },
        (e, t, a) => {
          Object.defineProperty(t, "__esModule", { value: !0 });
          t.PDFWorkerStream = void 0;
          var r = a(2);
          t.PDFWorkerStream = class PDFWorkerStream {
            constructor(e) {
              this._msgHandler = e;
              this._contentLength = null;
              this._fullRequestReader = null;
              this._rangeRequestReaders = [];
            }
            getFullReader() {
              (0, r.assert)(
                !this._fullRequestReader,
                "PDFWorkerStream.getFullReader can only be called once."
              );
              this._fullRequestReader = new PDFWorkerStreamReader(
                this._msgHandler
              );
              return this._fullRequestReader;
            }
            getRangeReader(e, t) {
              const a = new PDFWorkerStreamRangeReader(e, t, this._msgHandler);
              this._rangeRequestReaders.push(a);
              return a;
            }
            cancelAllRequests(e) {
              this._fullRequestReader && this._fullRequestReader.cancel(e);
              for (const t of this._rangeRequestReaders.slice(0)) t.cancel(e);
            }
          };
          class PDFWorkerStreamReader {
            constructor(e) {
              this._msgHandler = e;
              this.onProgress = null;
              this._contentLength = null;
              this._isRangeSupported = !1;
              this._isStreamingSupported = !1;
              const t = this._msgHandler.sendWithStream("GetReader");
              this._reader = t.getReader();
              this._headersReady = this._msgHandler
                .sendWithPromise("ReaderHeadersReady")
                .then((e) => {
                  this._isStreamingSupported = e.isStreamingSupported;
                  this._isRangeSupported = e.isRangeSupported;
                  this._contentLength = e.contentLength;
                });
            }
            get headersReady() {
              return this._headersReady;
            }
            get contentLength() {
              return this._contentLength;
            }
            get isStreamingSupported() {
              return this._isStreamingSupported;
            }
            get isRangeSupported() {
              return this._isRangeSupported;
            }
            async read() {
              const { value: e, done: t } = await this._reader.read();
              return t
                ? { value: void 0, done: !0 }
                : { value: e.buffer, done: !1 };
            }
            cancel(e) {
              this._reader.cancel(e);
            }
          }
          class PDFWorkerStreamRangeReader {
            constructor(e, t, a) {
              this._msgHandler = a;
              this.onProgress = null;
              const r = this._msgHandler.sendWithStream("GetRangeReader", {
                begin: e,
                end: t,
              });
              this._reader = r.getReader();
            }
            get isStreamingSupported() {
              return !1;
            }
            async read() {
              const { value: e, done: t } = await this._reader.read();
              return t
                ? { value: void 0, done: !0 }
                : { value: e.buffer, done: !1 };
            }
            cancel(e) {
              this._reader.cancel(e);
            }
          }
        },
      ],
      t = {};
    function __w_pdfjs_require__(a) {
      var r = t[a];
      if (void 0 !== r) return r.exports;
      var n = (t[a] = { exports: {} });
      e[a](n, n.exports, __w_pdfjs_require__);
      return n.exports;
    }
    __w_pdfjs_require__.d = (e, t) => {
      for (var a in t)
        __w_pdfjs_require__.o(t, a) &&
          !__w_pdfjs_require__.o(e, a) &&
          Object.defineProperty(e, a, { enumerable: !0, get: t[a] });
    };
    __w_pdfjs_require__.o = (e, t) =>
      Object.prototype.hasOwnProperty.call(e, t);
    __w_pdfjs_require__.r = (e) => {
      "undefined" != typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(e, Symbol.toStringTag, { value: "Module" });
      Object.defineProperty(e, "__esModule", { value: !0 });
    };
    var a = {};
    (() => {
      var e = a;
      Object.defineProperty(e, "__esModule", { value: !0 });
      Object.defineProperty(e, "WorkerMessageHandler", {
        enumerable: !0,
        get: function () {
          return t.WorkerMessageHandler;
        },
      });
      var t = __w_pdfjs_require__(1);
    })();
    return a;
  })()
);
/*eslint-enable*/
