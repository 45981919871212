import React from "react";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker, Popup, useMap } from "react-leaflet";
import { offsetPosition } from "../utils/offsetPosition";
import "./CounterBadge.css";

const CounterBadge = ({ asset, value, label, position, popup }) => {
	const map = useMap();

	const styleBadge = (value) => {
		const badge = L.divIcon({
			className: "countMarker",
			html: ReactDOMServer.renderToString(
				<div
					className={`badge rounded-pill bg-primary border text-light p-0`}
					style={{
						backgroundColor: label && "transparent !important",
						border: label && "0 !important ",
					}}
				>
					<span
						style={{
							display: "inline-block",
							fontSize: ".8rem",
							padding: "3px 5.5px 3px 5.5px",
							marginLeft: label > 1 && "-18px",
							backgroundColor: label > 1 && "#1c9d5b",
							borderRadius: label > 1 && "50%",
						}}
					>
						{value && value}
					</span>
				</div>
			),
		});
		return badge;
	};

	return (
		<>
			{value && (
				<Marker
					id={asset.id}
					className='counter-badge'
					position={offsetPosition(
						map,
						asset.geometry,
						position[0],
						position[1]
					)}
					icon={styleBadge((value && value) || (label > 1 && label))}
				>
					{popup && (
						<Popup>
							<ul className='m-1 p-0'>
								{asset.groupedConnectionPoints.map((gcp, index) => (
									<li className='list-unstyled' key={{ index }}>
										<i className={`icon-${gcp.styles.class}`}></i>
										{gcp.styles.name} x{label > 1 ? label : gcp.count}
										<ul className='p-0 m-0'>
											{gcp.subGroupConnectionPoints.map((subGcp) => (
												<li className='list-unstyled'>
													<span className='text-muted'>↳</span>{" "}
													<i className={`icon-${subGcp.styles.class}`}></i>
													{subGcp.styles.name}
												</li>
											))}
										</ul>
									</li>
								))}
							</ul>
						</Popup>
					)}
				</Marker>
			)}
		</>
	);
};

export default CounterBadge;
