// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { Cable } from "./cable";
import { CableProperties } from "./cableProperties";
import { getLatLngArrayFromWktLineString } from "./geometryWktConverter";
import * as statusConstants from "../../constants/status";
import * as fusewayStatus from "../../constants/fusewayStatus";

export function createCableFromConductingEquipment(
  conductingEquipment: ConductingEquipment
): Cable {
  const cableProperties = new CableProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  cableProperties.geometry = getLatLngArrayFromWktLineString(geometryString);
  cableProperties.feederNumber =
    conductingEquipment.getPropertyValue("feederNumber");
  cableProperties.startAssetId =
    conductingEquipment.getPropertyValue("startAssetId");
  cableProperties.endAssetId =
    conductingEquipment.getPropertyValue("endAssetId");
  cableProperties.consumersDownstream = conductingEquipment.getPropertyValue(
    "downstreamConsumers"
  );
  cableProperties.consumersUpstream =
    conductingEquipment.getPropertyValue("upstreamConsumers");
  cableProperties.actualPhasesAvailable = conductingEquipment.getPropertyValue(
    "actualPhasesAvailable"
  );
  cableProperties.length = parseFloat(
    conductingEquipment.getPropertyValue("length")
  );
  cableProperties.cableType = conductingEquipment.getPropertyValue("cableType");
  cableProperties.cableTypeIsDefault =
    conductingEquipment.getPropertyValue("cableTypeIsDefault") === "true";
  cableProperties.cableTypeAssigned =
    conductingEquipment.getPropertyValue("cableTypeAssigned");
  cableProperties.maximumUtilisationPercent =
    conductingEquipment.getPropertyValue("maximumUtilisationPercent");
  cableProperties.maximumCurrentA =
    conductingEquipment.getPropertyValue("maximumCurrentA");
  cableProperties.maximumCurrentAPeriod = conductingEquipment.getPropertyValue(
    "maximumCurrentAPeriod"
  );
  cableProperties.earthLoopImpedance =
    conductingEquipment.getPropertyValue("earthLoopImpedance");
  cableProperties.cableGroup =
    conductingEquipment.getPropertyValue("cableGroup");
  cableProperties.cableGroupIsDefault =
    conductingEquipment.getPropertyValue("cableGroupIsDefault") === "true";
  cableProperties.numberOfPhases =
    conductingEquipment.getPropertyValue("numberOfPhases");
  cableProperties.numberOfPhasesIsDefault =
    conductingEquipment.getPropertyValue("numberOfPhasesIsDefault") === "true";
  cableProperties.autoSelect =
    conductingEquipment.getPropertyValue("autoSelect") === "true";
  cableProperties.overrideLength =
    conductingEquipment.getPropertyValue("overrideLength");
  cableProperties.styles = getAssetStyleInfo(
    conductingEquipment.getPropertyValue("class")
  );
  cableProperties.groupedConnectionPoints = [];
  cableProperties.isFirstLeg =
    conductingEquipment.getPropertyValue("isFirstLeg");
  cableProperties.hasFuseSize =
    conductingEquipment.getPropertyValue("hasFuseSize") === "true";
  cableProperties.feederFuseSize =
    conductingEquipment.getPropertyValue("feederFuseSize");
  cableProperties.hasFuseMaxCurrentWarning =
    conductingEquipment.getPropertyValue("hasFuseMaxCurrentWarning") === "true";
  cableProperties.ducting =
    conductingEquipment.getPropertyValue("ducting") === "true";
  cableProperties.ratingType =
    conductingEquipment.getPropertyValue("ratingType");
  cableProperties.AllmaximumUtilisationPercent =
    conductingEquipment.getPropertyValue("AllmaximumUtilisationPercent");
  cableProperties.AllmaximumCurrentPhase = conductingEquipment.getPropertyValue(
    "AllmaximumCurrentPhase"
  );
  cableProperties.AllmaximumCurrentA =
    conductingEquipment.getPropertyValue("AllmaximumCurrentA");
  cableProperties.AllmaximumCurrentAPeriod =
    conductingEquipment.getPropertyValue("AllmaximumCurrentAPeriod");
  cableProperties.AllminimumCurrentA =
    conductingEquipment.getPropertyValue("AllminimumCurrentA");
  cableProperties.AllmaximumCurrentAPhase1 =
    conductingEquipment.getPropertyValue("AllmaximumCurrentAPhase1");
  cableProperties.AllmaximumCurrentAPhase2 =
    conductingEquipment.getPropertyValue("AllmaximumCurrentAPhase2");
  cableProperties.AllmaximumCurrentAPhase3 =
    conductingEquipment.getPropertyValue("AllmaximumCurrentAPhase3");
  cableProperties.wintermaximumCurrentA = conductingEquipment.getPropertyValue(
    "wintermaximumCurrentA"
  );
  cableProperties.wintermaximumCurrentAPeriod =
    conductingEquipment.getPropertyValue("wintermaximumCurrentAPeriod");
  cableProperties.wintermaximumUtilisationPercent =
    conductingEquipment.getPropertyValue("wintermaximumUtilisationPercent");
  cableProperties.wintermaximumCurrentPhase =
    conductingEquipment.getPropertyValue("wintermaximumCurrentPhase");
  cableProperties.winterminimumCurrentA = conductingEquipment.getPropertyValue(
    "winterminimumCurrentA"
  );
  cableProperties.wintermaximumCurrentAPhase1 =
    conductingEquipment.getPropertyValue("wintermaximumCurrentAPhase1");
  cableProperties.wintermaximumCurrentAPhase2 =
    conductingEquipment.getPropertyValue("wintermaximumCurrentAPhase2");
  cableProperties.wintermaximumCurrentAPhase3 =
    conductingEquipment.getPropertyValue("wintermaximumCurrentAPhase3");
  cableProperties.summermaximumCurrentA = conductingEquipment.getPropertyValue(
    "summermaximumCurrentA"
  );
  cableProperties.summermaximumCurrentAPeriod =
    conductingEquipment.getPropertyValue("summermaximumCurrentAPeriod");
  cableProperties.summermaximumUtilisationPercent =
    conductingEquipment.getPropertyValue("summermaximumUtilisationPercent");
  cableProperties.summermaximumCurrentPhase =
    conductingEquipment.getPropertyValue("summermaximumCurrentPhase");
  cableProperties.summerminimumCurrentA = conductingEquipment.getPropertyValue(
    "summerminimumCurrentA"
  );
  cableProperties.summermaximumCurrentAPhase1 =
    conductingEquipment.getPropertyValue("summermaximumCurrentAPhase1");
  cableProperties.summermaximumCurrentAPhase2 =
    conductingEquipment.getPropertyValue("summermaximumCurrentAPhase2");
  cableProperties.summermaximumCurrentAPhase3 =
    conductingEquipment.getPropertyValue("summermaximumCurrentAPhase3");
  cableProperties.nearNodeNumber =
    conductingEquipment.getPropertyValue("nearNodeNumber");
  cableProperties.farNodeNumber =
    conductingEquipment.getPropertyValue("farNodeNumber");
  cableProperties.fuseSelection =
    conductingEquipment.getPropertyValue("fuseSelection");
  cableProperties.wpdId = conductingEquipment.getPropertyValue("wpdId");
  cableProperties.annotation =
    conductingEquipment.getPropertyValue("annotation");
  cableProperties.status =
    conductingEquipment.getPropertyValue("status") ?? statusConstants.EXISTING;
  cableProperties.cascadingRAG =
    conductingEquipment.getPropertyValue("cascadingRAG");
  cableProperties.nonCascadingRAG =
    conductingEquipment.getPropertyValue("nonCascadingRAG");
  cableProperties.groundTypeOverrides = JSON.parse(
    conductingEquipment.getPropertyValue("groundTypeOverrides")
  );
  cableProperties.overrideLength =
    conductingEquipment.getPropertyValue("overrideLength");
  cableProperties.overrideGroundType = arrayToDictionary(
    JSON.parse(conductingEquipment.getPropertyValue("groundTypeOverrides"))
  );
  cableProperties.wintermaximumServiceCableDemandKVA =
    conductingEquipment.getPropertyValue("wintermaximumServiceCableDemandKVA");
  cableProperties.summermaximumServiceCableDemandKVA =
    conductingEquipment.getPropertyValue("summermaximumServiceCableDemandKVA");
  cableProperties.AllmaximumServiceCableDemandKVA =
    conductingEquipment.getPropertyValue("AllmaximumServiceCableDemandKVA");
  cableProperties.fusewayStatus =
    conductingEquipment.getPropertyValue("fusewayStatus") ??
    fusewayStatus.CLOSED;
  cableProperties.rootTransformerId =
    conductingEquipment.getPropertyValue("rootTransformerId");

  return new Cable(conductingEquipment.id, cableProperties);
}

const arrayToDictionary = (overrides) => {
  if (overrides === null) return null;
  let dictionary = Object.assign(
    {},
    ...overrides.map((x) => ({
      [x.groundType]: { length: x.length, addApplied: x.addApplied },
    }))
  );
  return dictionary;
};
