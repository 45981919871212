import { v4 as uuid } from "uuid";
import { addGroupedConnectionChild, addTransformerChild } from "../../app/networkSlice";
import { transformerTypes } from "../../constants/groupUpdateTypes";

export const shouldShowConsumerForm = (ringfencedTypes, selected) => {
  const shouldShow = (type) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Connection":
      case "Grouped Connection":
      case "Nodes":
      case "Node":
      case "Service End Node":
      case "Transformers":
      case "Pole-mounted Transformer":
      case "Ground-mounted Transformer":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some(p => !shouldShow(p.name));
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const applyConsumerChange = (ringfencedFiltered, consumers, setConsumers, dispatchRedux) => {
  ringfencedFiltered.forEach(p => {
    consumers.forEach(child => {
      const newChildId = uuid();
      const newChild = { ...child, geometry: p.geometry, id: newChildId, subGroupConnectionPoints: child.subGroupConnectionPoints.map(q => ({ ...q, id: uuid(), geometry: p.geometry, parentId: newChildId })) };
      if (transformerTypes.includes(p.styles.name)) {
        dispatchRedux(addTransformerChild({ id: p.id, child: newChild }));
      } else {
        dispatchRedux(addGroupedConnectionChild({ id: p.id, child: newChild }));
      }
    });
  });

  setConsumers([]);
};