// @flow
import NameValueProperty from "./nameValueProperty";
import PropertyContainer from "./propertyContainer";

export type ConductingEquipmentType =
  | "ACLineSegment"
  | "EnergyConsumer"
  | "PowerTransformer"
  | "Junction"
  | "PointOfConnection";

interface IConductingEquipment {
  id: string;
  type: ConductingEquipmentType;
  properties: NameValueProperty[];
}

export default class ConductingEquipment
  extends PropertyContainer
  implements IConductingEquipment
{
  constructor(
    id: string,
    type: ConductingEquipmentType,
    properties: NameValueProperty[]
  ) {
    super(properties);
    this.id = id;
    this.type = type;
  }

  id: string;
  type: ConductingEquipmentType;
}
