export const PHASE1 = 1;
export const PHASE2 = 2;
export const PHASE3 = 3;
export const AUTO = "Auto";
export const SINGLE = "Single";
export const TWO = "Two";
export const THREE = "Three";
export const SPLIT = "Split";

export const CONSUMER_PHASES = {
    name: "phases",
    obj: [
        { id: "consumerSinglePhase", value: SINGLE, color: "light" },
        { id: "consumerTwoPhase", value: TWO, color: "light" },
        { id: "consumerThreePhase", value: THREE, color: "light" },
    ],
};

export const TRANSFORMER_PHASES = {
    name: "phases",
    obj: [
        { id: "transformerSinglePhase", value: SINGLE, color: "light", disabled: false },
        { id: "transformerSplitPhase", value: SPLIT, color: "light", disabled: false },
        { id: "transformerThreePhase", value: THREE, color: "light", disabled: false },
    ],
};