import React from "react";

const DataAlert = ({ updateDefault }) => {
  return (
    <span
      className="ml-2"
      style={{ cursor: "pointer" }}
      onClick={() => updateDefault()}
    >
      <i id="phases" className="text-warning icon-circle-solid"></i>
    </span>
  );
};

export default DataAlert;
