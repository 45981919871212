import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const SpinnerButton = ({
  id,
  showSpinner,
  text,
  color,
  classes,
  onClick,
  disabled,
  icon,
}) => {
  return (
    <Button
      id={id}
      color={color}
      size="sm"
      className={classes}
      disabled={disabled}
      onClick={onClick}
    >
      {showSpinner ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <>
          {icon && (
            <>
              <i className={`icon-${icon}-solid`}></i>{" "}
            </>
          )}
          <span className="d-none d-sm-inline">{text}</span>
        </>
      )}
    </Button>
  );
};

export default SpinnerButton;
