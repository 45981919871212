// @flow
import { LatLng } from "./simpleTypes";

export type ConsumptionType =
  | "MaximumDemand"
  | "MaximumDemandNoQ"
  | "AnnualConsumption";

export type Phase = 1 | 2 | 3 | -1; // Convention: -1 means 3-phase balanced

export interface IConnectionPointProperties {
  annotation: string;
  consumerType: string;
  consumerTypeIsDefault: Boolean;
  autoPhase: Boolean;
  phaseAutoConsumers: number;
  phase1Consumers: number;
  phase2Consumers: number;
  phase3Consumers: number;
  balancedLoad: Boolean;
  unbalancePercent: number;
  lowLoadedPhase: string;
  consumptionValue1: number;
  consumptionValue1IsDefault: Boolean;
  consumptionValue2: number;
  consumptionValue2IsDefault: Boolean;
  consumptionType: ConsumptionType;
  consumptionTypeIsDefault: Boolean;
  geometry: LatLng;
  maxVoltDropPercent: ?number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: ?number;
  maxVoltRiseGeneration: ?number;
  maxVoltRiseUnbalance: ?number;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  phaseMap: number[];
  hasFuseWarning: Boolean;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: ?number;
  AllmaxVoltDropPercentPeriod: ?number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: ?number;
  AllmaxVoltRiseTotal: ?number;
  AllmaxVoltRiseGeneration: ?number;
  AllmaxVoltRiseUnbalance: ?number;
  AllmaxVoltRisePercentPeriod: ?number;
  AllmaxVoltRisePhase: ?number;
  AllmaxVoltage: ?number;
  AllmaxVoltagePhase1: ?number;
  AllmaxVoltagePhase2: ?number;
  AllmaxVoltagePhase3: ?number;
  AllminVoltage: ?number;
  AllminVoltagePhase1: ?number;
  AllminVoltagePhase2: ?number;
  AllminVoltagePhase3: ?number;
  wintermaxVoltDropPercent: ?number;
  wintermaxVoltDropPercentPeriod: ?number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: ?number;
  wintermaxVoltRisePercent: ?number;
  wintermaxVoltRiseTotal: ?number;
  wintermaxVoltRiseGeneration: ?number;
  wintermaxVoltRiseUnbalance: ?number;
  wintermaxVoltRisePercentPeriod: ?number;
  wintermaxVoltRisePhase: ?number;
  wintermaxVoltage: ?number;
  wintermaxVoltagePhase1: ?number;
  wintermaxVoltagePhase2: ?number;
  wintermaxVoltagePhase3: ?number;
  winterminVoltage: ?number;
  winterminVoltagePhase1: ?number;
  winterminVoltagePhase2: ?number;
  winterminVoltagePhase3: ?number;
  summermaxVoltDropPercent: ?number;
  summermaxVoltDropPercentPeriod: ?number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: ?number;
  summermaxVoltRisePercent: ?number;
  summermaxVoltRiseTotal: ?number;
  summermaxVoltRiseGeneration: ?number;
  summermaxVoltRiseUnbalance: ?number;
  summermaxVoltRisePercentPeriod: ?number;
  summermaxVoltRisePhase: ?number;
  summermaxVoltage: ?number;
  summermaxVoltagePhase1: ?number;
  summermaxVoltagePhase2: ?number;
  summermaxVoltagePhase3: ?number;
  summerminVoltage: ?number;
  summerminVoltagePhase1: ?number;
  summerminVoltagePhase2: ?number;
  summerminVoltagePhase3: ?number;
  phaseImpedance: ?number;
  loopImpedance: ?number;
  nodeNumber: number;
  groupId: ?string;
  status: string;
  rootTransformerId: ?string;
}

export class ConnectionPointProperties implements IConnectionPointProperties {
  annotation: string;
  consumerType: string;
  consumerTypeIsDefault: Boolean;
  autoPhase: Boolean;
  phaseAutoConsumers: number;
  phase1Consumers: number;
  phase2Consumers: number;
  phase3Consumers: number;
  balancedLoad: Boolean;
  unbalancePercent: number;
  lowLoadedPhase: string;
  consumptionValue1: number;
  consumptionValue1IsDefault: Boolean;
  consumptionValue2: number;
  consumptionValue2IsDefault: Boolean;
  consumptionType: ConsumptionType;
  consumptionTypeIsDefault: Boolean;
  geometry: LatLng;
  maxVoltDropPercent: ?number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: ?number;
  maxVoltRiseGeneration: ?number;
  maxVoltRiseUnbalance: ?number;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  styles: any;
  parentId: ?string;
  isSubGroupConnection: Boolean;
  isGenerator: Boolean;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  phaseMap: number[];
  hasFuseWarning: Boolean;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: ?number;
  AllmaxVoltDropPercentPeriod: ?number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: ?number;
  AllmaxVoltRiseTotal: ?number;
  AllmaxVoltRiseGeneration: ?number;
  AllmaxVoltRiseUnbalance: ?number;
  AllmaxVoltRisePercentPeriod: ?number;
  AllmaxVoltRisePhase: ?number;
  AllmaxVoltage: ?number;
  AllmaxVoltagePhase1: ?number;
  AllmaxVoltagePhase2: ?number;
  AllmaxVoltagePhase3: ?number;
  AllminVoltage: ?number;
  AllminVoltagePhase1: ?number;
  AllminVoltagePhase2: ?number;
  AllminVoltagePhase3: ?number;
  wintermaxVoltDropPercent: ?number;
  wintermaxVoltDropPercentPeriod: ?number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: ?number;
  wintermaxVoltRisePercent: ?number;
  wintermaxVoltRiseTotal: ?number;
  wintermaxVoltRiseGeneration: ?number;
  wintermaxVoltRiseUnbalance: ?number;
  wintermaxVoltRisePercentPeriod: ?number;
  wintermaxVoltRisePhase: ?number;
  wintermaxVoltage: ?number;
  wintermaxVoltagePhase1: ?number;
  wintermaxVoltagePhase2: ?number;
  wintermaxVoltagePhase3: ?number;
  winterminVoltage: ?number;
  winterminVoltagePhase1: ?number;
  winterminVoltagePhase2: ?number;
  winterminVoltagePhase3: ?number;
  summermaxVoltDropPercent: ?number;
  summermaxVoltDropPercentPeriod: ?number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: ?number;
  summermaxVoltRisePercent: ?number;
  summermaxVoltRiseTotal: ?number;
  summermaxVoltRiseGeneration: ?number;
  summermaxVoltRiseUnbalance: ?number;
  summermaxVoltRisePercentPeriod: ?number;
  summermaxVoltRisePhase: ?number;
  summermaxVoltage: ?number;
  summermaxVoltagePhase1: ?number;
  summermaxVoltagePhase2: ?number;
  summermaxVoltagePhase3: ?number;
  summerminVoltage: ?number;
  summerminVoltagePhase1: ?number;
  summerminVoltagePhase2: ?number;
  summerminVoltagePhase3: ?number;
  phaseImpedance: ?number;
  loopImpedance: ?number;
  nodeNumber: number;
  groupId: ?string;
  status: string;
  cutOutSize: string;
  multiOccupancyContainer: any;
  multiOccupancy: Boolean;
  moSpareWays: number;
  linkBox: any;
  potEnds: any;
  overrideGroundType: string;
  pole: any;
  count: number;
  rootTransformerId: string;
}