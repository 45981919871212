// @flow
import Terminal from "./terminal";
import ConductingEquipment from "./conductingEquipment";
import ConnectivityNode from "./connectivityNode";

interface ICimNetwork {
  conductingEquipment: ConductingEquipment[];
  terminals: Terminal[];
  connectivityNodes: ConnectivityNode[];
}

export default class CimNetwork implements ICimNetwork {
  constructor() {
    this.conductingEquipment = [];
    this.terminals = [];
    this.connectivityNodes = [];
  }

  conductingEquipment: ConductingEquipment[];
  terminals: Terminal[];
  connectivityNodes: ConnectivityNode[];

  getConductingEquipment(id: string): ?ConductingEquipment {
    const conductingEquipment = this.conductingEquipment.find(
      ce => ce.id === id
    );

    return conductingEquipment;
  }
}
