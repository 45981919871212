import axios, { get, patch, post } from "axios";
import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";

const deleteAction = axios.delete;

const { REACT_APP_B2B, REACT_APP_B2C } = process.env;

const b2x = REACT_APP_B2B === "true" || REACT_APP_B2C === "true";
const clientSettingsApiUrl = process.env.REACT_APP_CLIENT_SETTINGS_API_URL;

let clientSettings;

const getBearerToken = async (account) => {
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  return response.accessToken;
};

const postHeaders = async (account) => {
  let headers = {};

  headers.headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (b2x) {
    const bearerToken = await getBearerToken(account);
    headers.headers = {
      ...headers.headers,
      Authorization: `Bearer ${bearerToken}`,
    };
  }

  return headers;
};

const getHeaders = async (account) => {
  let headers = {};

  if (b2x) {
    const bearerToken = await getBearerToken(account);
    headers.headers = {
      Authorization: `Bearer ${bearerToken}`,
    };
  }

  return headers;
};

export const authorise = async (account) => {
  const headers = await postHeaders(account);
  return post(`${clientSettings?.Urls.EndPoints.Authorised}`, {}, headers);
};

export const getReference = async (account) => {
  const headers = await getHeaders(account);
  return get(`${clientSettings?.Urls.EndPoints.Reference}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getStudyFilters = async (account) => {
  const headers = await getHeaders(account);

  return get(`${clientSettings?.Urls.EndPoints.StudyFilters}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const getClientSettings = async (account) => {
  const headers = await getHeaders(account);
  return get(`${clientSettingsApiUrl}`, headers)
    .then((result) => {
      clientSettings = result.data;
      return result.data;
    })
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const postAssessments = async (
  obj,
  account,
  { assessmentExporting },
) => {
  const options = await postHeaders(account);
  if (assessmentExporting) {
    options.headers["Accept"] = "application/vnd.ms-excel";
    options.responseType = "blob";
  }
  return post(`${clientSettings.Urls.EndPoints.Assessment}`, obj, options);
};

export const loadFile = async (studyId, account) => {
  const headers = await getHeaders(account);

  let url = clientSettings.Urls.EndPoints.StudyLoad;
  url = url.replace("{studyId}", encodeURIComponent(studyId));

  return get(`${url}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const loadFiles = async (account) => {
  const headers = await getHeaders(account);

  return get(`${clientSettings.Urls.EndPoints.StudyList}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};

export const translateWinDebutToStudy = async (obj, account) => {
  const headers = await postHeaders(account);
  return post(`${clientSettings.Urls.EndPoints.WinDebutToStudy}`, obj, headers);
};

export const saveNetwork = async (obj, account) => {
  const headers = await postHeaders(account);
  return post(`${clientSettings.Urls.EndPoints.StudySave}`, obj, headers);
};

export const deleteNetwork = async (studyId, account) => {
  const headers = await postHeaders(account);

  let url = clientSettings.Urls.EndPoints.StudyDelete;
  url = url.replace("{studyId}", studyId);

  return deleteAction(url, headers);
};

export const archiveNetwork = async (studyId, obj, account) => {
  const headers = await postHeaders(account);

  let url = clientSettings.Urls.EndPoints.StudyArchive;
  url = url.replace("{studyId}", studyId);

  return patch(url, obj, headers);
};

export const updateNetwork = async (studyId, obj, account) => {
  const headers = await postHeaders(account);

  let url = clientSettings.Urls.EndPoints.StudyUpdateContent;
  url = url.replace("{studyId}", studyId);

  return patch(url, obj, headers);
};

export const saveFeedback = async (obj, account) => {
  const headers = await postHeaders(account);
  return post(`${clientSettings.Urls.EndPoints.FeedbackSave}`, obj, headers)
    .then((result) => result.data)
    .catch((error) => error);
};

export const loadNetworkJson = async (
  substationNumber,
  feeders,
  mains,
  electricOffice,
  account,
  useAntData,
) => {
  const headers = await getHeaders(account);
  const feeders_ = feeders.length ? `${feeders.join()}` : "";
  let mains_ = "";
  let electricOffice_ = "";

  if (feeders.length) {
    mains_ = mains ? `${mains}` : "";
  } else {
    mains_ = mains ? `${mains}` : "";
  }

  if (feeders.length || mains.length) {
    electricOffice_ = electricOffice ? `${electricOffice}` : "";
  } else {
    electricOffice_ = electricOffice ? `${electricOffice}` : "";
  }

  let url = clientSettings.Urls.EndPoints.NetworkLoad;
  url = url.replace("{substationNumber}", substationNumber);
  url = url.replace("{feeders}", feeders_);
  url = url.replace("{mainsOnly}", mains_);
  url = url.replace("{electricOfficeOnly}", electricOffice_);
  url = url.replace(
    "{useGeometry}",
    !(useAntData && clientSettings.Features.DataBasedANTEnabled),
  );

  return get(`${url}`, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const loadFeederList = async (substationNumber, account, useAntData) => {
  const headers = await getHeaders(account);

  let url = clientSettings.Urls.EndPoints.NetworkLoadFeeders;
  url = url.replace("{substationNumber}", substationNumber);
  url = url.replace(
    "{useGeometry}",
    !(useAntData && clientSettings.Features.DataBasedANTEnabled),
  );

  return get(`${url}`, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
      let errorText = "There was a problem finding the network.";
      const errorDescription = error?.response?.data?.errors?.id[0];
      if (errorDescription) {
        errorText = errorText + " " + errorDescription;
      }

      return {
        data: {
          network: {
            messages: [
              {
                description: errorText,
                level: 4,
              },
            ],
          },
        },
      };
    });
};

export const getNearbySubstations = async (
  lat,
  lng,
  distanceInMeters,
  account,
) => {
  const headers = await getHeaders(account);

  let url = clientSettings.Urls.EndPoints.NetworkNearbySubstations;
  url = url.replace("{lat}", lat);
  url = url.replace("{lng}", lng);
  url = url.replace("{distanceInMeters}", distanceInMeters);

  return get(`${url}`, headers)
    .then((result) => {
      return result;
    })
    .catch((error) => console.log(error));
};

export const getLocation = async (obj, account) => {
  const headers = await postHeaders(account);
  return post(
    `${clientSettings.Urls.EndPoints.LocationSearch}`,
    { Location: obj },
    headers,
  )
    .then((result) => {
      return result;
    })
    .catch((error) => error);
};

export const getRagNetwork = async (substationNumber, account) => {
  const headers = await getHeaders(account);

  let url = clientSettings.Urls.EndPoints.RAGNetworkLoad;
  url = url.replace("{substationNumber}", substationNumber);

  return get(`${url}`, headers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const loadCrown = async (schemeId, schemeVersion, account) => {
  const headers = await getHeaders(account);

  let url = clientSettings.Urls.EndPoints.CrownLoad;
  url = url.replace("{schemeId}", schemeId);
  url = url.replace("{schemeVersion}", schemeVersion);

  return get(`${url}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.errors);
    });
};

export const exportCrown = async (
  schemeId,
  schemeVersion,
  exportData,
  account,
) => {
  const headers = await getHeaders(account);

  let url = clientSettings.Urls.EndPoints.CrownExport;
  url = url.replace("{schemeId}", schemeId);
  url = url.replace("{schemeVersion}", schemeVersion);

  return post(`${url}`, exportData, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.errors);
    });
};

export const getBillOfMaterials = async (bomData, account) => {
  const headers = await getHeaders(account);
  return post(`${clientSettings.Urls.EndPoints.Calculate}`, bomData, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error?.response?.data?.errors);
      return error?.response?.data;
    });
};

export const loadTransformers = async (account) => {
  const headers = await getHeaders(account);

  return get(`${clientSettings?.Urls.EndPoints.TransformerLookUp}`, headers)
    .then((result) => result.data)
    .catch((error) => {
      console.error(error.response.data.error);
    });
};
