import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";
import { IWelderProperties, WelderProperties } from "./welderProperties";

export class Welder extends Asset implements IWelderProperties {
  constructor(id: string, welderProperties: WelderProperties) {
    super(id);
    this.rating = welderProperties.rating;
    this.ratingIsDefault = welderProperties.ratingIsDefault;
    this.ratingUnit = welderProperties.ratingUnit;
    this.numberOfPhases = welderProperties.numberOfPhases;
    this.numberOfPhasesIsDefault = welderProperties.numberOfPhasesIsDefault;
    this.numberOfBursts = welderProperties.numberOfBursts;
    this.numberOfBurstsIsDefault = welderProperties.numberOfBurstsIsDefault;
    this.numberOfBurstsUnit = welderProperties.numberOfBurstsUnit;
    this.pointOfWaveControl = welderProperties.pointOfWaveControl;
    this.powerFactor = welderProperties.powerFactor;
    this.powerFactorIsDefault = welderProperties.powerFactorIsDefault;
    this.efficiency = welderProperties.efficiency;
    this.efficiencyIsDefault = welderProperties.efficiencyIsDefault;
    this.hvFaultLevel = welderProperties.hvFaultLevel;
    this.xrRatio = welderProperties.xrRatio;
    this.annotation = welderProperties.annotation;
    this.voc = welderProperties.voc;
    this.vocIsDefault = welderProperties.vocIsDefault;
    this.geometry = welderProperties.geometry;
    this.styles = welderProperties.styles;
    this.maxVoltDropPercent = welderProperties.maxVoltDropPercent;
    this.maxVoltDropPercentPassResult =
      welderProperties.maxVoltDropPercentPassResult;
    this.maxVoltRisePercent = welderProperties.maxVoltRisePercent;
    this.maxVoltRiseTotal = welderProperties.maxVoltRiseTotal;
    this.maxVoltRiseGeneration = welderProperties.maxVoltRiseGeneration;
    this.maxVoltRiseUnbalance = welderProperties.maxVoltRiseUnbalance;
    this.earthLoopImpedance = welderProperties.earthLoopImpedance;
    this.threePhaseFaultCurrent = welderProperties.threePhaseFaultCurrent;
    this.phaseToPhaseFaultCurrent = welderProperties.phaseToPhaseFaultCurrent;
    this.singlePhaseToEarthFaultCurrent =
      welderProperties.singlePhaseToEarthFaultCurrent;
    this.flickerPassResult = welderProperties.flickerPassResult;
    this.flickerDeltaVoltage = welderProperties.flickerDeltaVoltage;
    this.flickerP28Limit = welderProperties.flickerP28Limit;
    this.flickerStartingPassResult = welderProperties.flickerStartingPassResult;
    this.flickerStartingDeltaVoltage =
      welderProperties.flickerStartingDeltaVoltage;
    this.impedanceLoopOperating = welderProperties.impedanceLoopOperating;
    this.impedanceLoopOperatingPassResult =
      welderProperties.impedanceLoopOperatingPassResult;
    this.impedanceSourceLoopOperating =
      welderProperties.impedanceSourceLoopOperating;
    this.impedanceSourceLoopOperatingPassResult =
      welderProperties.impedanceSourceLoopOperatingPassResult;
    this.impedanceLoopFault = welderProperties.impedanceLoopFault;
    this.impedancePhaseOperating = welderProperties.impedancePhaseOperating;
    this.impedanceSourcePhaseOperating =
      welderProperties.impedanceSourcePhaseOperating;
    this.impedanceSourcePhasePhaseOperating =
      welderProperties.impedanceSourcePhasePhaseOperating;
    this.impedancePhaseFault = welderProperties.impedancePhaseFault;
    this.resistanceLoopOperating = welderProperties.resistanceLoopOperating;
    this.resistanceSourceLoopOperating =
      welderProperties.resistanceSourceLoopOperating;
    this.resistanceLoopFault = welderProperties.resistanceLoopFault;
    this.resistancePhaseOperating = welderProperties.resistancePhaseOperating;
    this.resistanceSourcePhaseOperating =
      welderProperties.resistanceSourcePhaseOperating;
    this.resistanceSourcePhasePhaseOperating =
      welderProperties.resistanceSourcePhasePhaseOperating;
    this.resistancePhaseFault = welderProperties.resistancePhaseFault;
    this.reactanceLoopOperating = welderProperties.reactanceLoopOperating;
    this.reactanceSourceLoopOperating =
      welderProperties.reactanceSourceLoopOperating;
    this.reactanceLoopFault = welderProperties.reactanceLoopFault;
    this.reactancePhaseOperating = welderProperties.reactancePhaseOperating;
    this.reactanceSourcePhaseOperating =
      welderProperties.reactanceSourcePhaseOperating;
    this.reactanceSourcePhasePhaseOperating =
      welderProperties.reactanceSourcePhasePhaseOperating;
    this.reactancePhaseFault = welderProperties.reactancePhaseFault;
    this.AllmaxVoltDropPercent = welderProperties.AllmaxVoltDropPercent;
    this.AllmaxVoltDropPercentPeriod =
      welderProperties.AllmaxVoltDropPercentPeriod;
    this.AllmaxVoltDropPercentPassResult =
      welderProperties.AllmaxVoltDropPercentPassResult;
    this.AllmaxVoltDropPhase = welderProperties.AllmaxVoltDropPhase;
    this.AllmaxVoltRisePercent = welderProperties.AllmaxVoltRisePercent;
    this.AllmaxVoltRiseTotal = welderProperties.AllmaxVoltRiseTotal;
    this.AllmaxVoltRiseGeneration = welderProperties.AllmaxVoltRiseGeneration;
    this.AllmaxVoltRiseUnbalance = welderProperties.AllmaxVoltRiseUnbalance;
    this.AllmaxVoltRisePercentPeriod =
      welderProperties.AllmaxVoltRisePercentPeriod;
    this.AllmaxVoltRisePhase = welderProperties.AllmaxVoltRisePhase;
    this.AllmaxVoltage = welderProperties.AllmaxVoltage;
    this.AllmaxVoltagePhase1 = welderProperties.AllmaxVoltagePhase1;
    this.AllmaxVoltagePhase2 = welderProperties.AllmaxVoltagePhase2;
    this.AllmaxVoltagePhase3 = welderProperties.AllmaxVoltagePhase3;
    this.AllminVoltage = welderProperties.AllminVoltage;
    this.AllminVoltagePhase1 = welderProperties.AllminVoltagePhase1;
    this.AllminVoltagePhase2 = welderProperties.AllminVoltagePhase2;
    this.AllminVoltagePhase3 = welderProperties.AllminVoltagePhase3;
    this.wintermaxVoltDropPercent = welderProperties.wintermaxVoltDropPercent;
    this.wintermaxVoltDropPercentPeriod =
      welderProperties.wintermaxVoltDropPercentPeriod;
    this.wintermaxVoltDropPercentPassResult =
      welderProperties.wintermaxVoltDropPercentPassResult;
    this.wintermaxVoltDropPhase = welderProperties.wintermaxVoltDropPhase;
    this.wintermaxVoltRisePercent = welderProperties.wintermaxVoltRisePercent;
    this.wintermaxVoltRiseTotal = welderProperties.wintermaxVoltRiseTotal;
    this.wintermaxVoltRiseGeneration =
      welderProperties.wintermaxVoltRiseGeneration;
    this.wintermaxVoltRiseUnbalance =
      welderProperties.wintermaxVoltRiseUnbalance;
    this.wintermaxVoltRisePercentPeriod =
      welderProperties.wintermaxVoltRisePercentPeriod;
    this.wintermaxVoltRisePhase = welderProperties.wintermaxVoltRisePhase;
    this.wintermaxVoltage = welderProperties.wintermaxVoltage;
    this.wintermaxVoltagePhase1 = welderProperties.wintermaxVoltagePhase1;
    this.wintermaxVoltagePhase2 = welderProperties.wintermaxVoltagePhase2;
    this.wintermaxVoltagePhase3 = welderProperties.wintermaxVoltagePhase3;
    this.winterminVoltage = welderProperties.winterminVoltage;
    this.winterminVoltagePhase1 = welderProperties.winterminVoltagePhase1;
    this.winterminVoltagePhase2 = welderProperties.winterminVoltagePhase2;
    this.winterminVoltagePhase3 = welderProperties.winterminVoltagePhase3;
    this.summermaxVoltDropPercent = welderProperties.summermaxVoltDropPercent;
    this.summermaxVoltDropPercentPeriod =
      welderProperties.summermaxVoltDropPercentPeriod;
    this.summermaxVoltDropPercentPassResult =
      welderProperties.summermaxVoltDropPercentPassResult;
    this.summermaxVoltDropPhase = welderProperties.summermaxVoltDropPhase;
    this.summermaxVoltRisePercent = welderProperties.summermaxVoltRisePercent;
    this.summermaxVoltRiseTotal = welderProperties.summermaxVoltRiseTotal;
    this.summermaxVoltRiseGeneration =
      welderProperties.summermaxVoltRiseGeneration;
    this.summermaxVoltRiseUnbalance =
      welderProperties.summermaxVoltRiseUnbalance;
    this.summermaxVoltRisePercentPeriod =
      welderProperties.summermaxVoltRisePercentPeriod;
    this.summermaxVoltRisePhase = welderProperties.summermaxVoltRisePhase;
    this.summermaxVoltage = welderProperties.summermaxVoltage;
    this.summermaxVoltagePhase1 = welderProperties.summermaxVoltagePhase1;
    this.summermaxVoltagePhase2 = welderProperties.summermaxVoltagePhase2;
    this.summermaxVoltagePhase3 = welderProperties.summermaxVoltagePhase3;
    this.summerminVoltage = welderProperties.summerminVoltage;
    this.summerminVoltagePhase1 = welderProperties.summerminVoltagePhase1;
    this.summerminVoltagePhase2 = welderProperties.summerminVoltagePhase2;
    this.summerminVoltagePhase3 = welderProperties.summerminVoltagePhase3;
    this.phaseImpedance = welderProperties.phaseImpedance;
    this.loopImpedance = welderProperties.loopImpedance;
    this.phaseAllocation = welderProperties.phaseAllocation;
    this.nodeNumber = welderProperties.nodeNumber;
    this.objectId = welderProperties.objectId;
  }
  [key: string]: any;
  rating: number;
  ratingIsDefault: Boolean;
  ratingUnit: string;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  numberOfBursts: number;
  numberOfBurstsIsDefault: Boolean;
  numberOfBurstsUnit: string;
  pointOfWaveControl: Boolean;
  powerFactor: number;
  powerFactorIsDefault: Boolean;
  efficiency: number;
  efficiencyIsDefault: Boolean;
  hvFaultLevel: number;
  xrRatio: number;
  annotation: string;
  voc: number;
  vocIsDefault: Boolean;
  geometry: LatLng;
  styles: any;
  maxVoltDropPercent: number | null;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRisePercent: number | null;
  maxVoltRiseTotal: number | null;
  maxVoltRiseGeneration: number | null;
  maxVoltRiseUnbalance: number | null;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: number | null;
  AllmaxVoltDropPercentPeriod: number | null;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: number | null;
  AllmaxVoltRisePercent: number | null;
  AllmaxVoltRiseTotal: number | null;
  AllmaxVoltRiseGeneration: number | null;
  AllmaxVoltRiseUnbalance: number | null;
  AllmaxVoltRisePercentPeriod: number | null;
  AllmaxVoltRisePhase: number | null;
  AllmaxVoltage: number | null;
  AllmaxVoltagePhase1: number | null;
  AllmaxVoltagePhase2: number | null;
  AllmaxVoltagePhase3: number | null;
  AllminVoltage: number | null;
  AllminVoltagePhase1: number | null;
  AllminVoltagePhase2: number | null;
  AllminVoltagePhase3: number | null;
  wintermaxVoltDropPercent: number | null;
  wintermaxVoltDropPercentPeriod: number | null;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: number | null;
  wintermaxVoltRisePercent: number | null;
  wintermaxVoltRiseTotal: number | null;
  wintermaxVoltRiseGeneration: number | null;
  wintermaxVoltRiseUnbalance: number | null;
  wintermaxVoltRisePercentPeriod: number | null;
  wintermaxVoltRisePhase: number | null;
  wintermaxVoltage: number | null;
  wintermaxVoltagePhase1: number | null;
  wintermaxVoltagePhase2: number | null;
  wintermaxVoltagePhase3: number | null;
  winterminVoltage: number | null;
  winterminVoltagePhase1: number | null;
  winterminVoltagePhase2: number | null;
  winterminVoltagePhase3: number | null;
  summermaxVoltDropPercent: number | null;
  summermaxVoltDropPercentPeriod: number | null;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: number | null;
  summermaxVoltRisePercent: number | null;
  summermaxVoltRiseTotal: number | null;
  summermaxVoltRiseGeneration: number | null;
  summermaxVoltRiseUnbalance: number | null;
  summermaxVoltRisePercentPeriod: number | null;
  summermaxVoltRisePhase: number | null;
  summermaxVoltage: number | null;
  summermaxVoltagePhase1: number | null;
  summermaxVoltagePhase2: number | null;
  summermaxVoltagePhase3: number | null;
  summerminVoltage: number | null;
  summerminVoltagePhase1: number | null;
  summerminVoltagePhase2: number | null;
  summerminVoltagePhase3: number | null;
  phaseImpedance: number | null;
  loopImpedance: number | null;
  phaseAllocation: string;
  nodeNumber: number;
  objectId: string;
}
