import L from "leaflet";
import { renderToString } from "react-dom/server";
import { IconLabel } from "../components";

export const iconMarker = (
  asset,
  mode,
  activeTool,
  iconSize,
  nodeZoomMultiplier = 1,
  ragNetwork,
  lctIsEnabled,
  colourBlindEnabled,
  costingStyling,
  groupedConnectionPoints,
  id,
) => {
  // if (ragNetwork) {
  //   console.log("ragNetwork & asset");
  //   console.log(asset.geometry);
  // }

  let smallIconMarkerOverride = `smallIconMarkerX${nodeZoomMultiplier}`;
  const { styles, name } = asset || {};

  let appendLct = "";
  let appendColor = "badge-dark";

  if (lctIsEnabled) {
    appendLct = "-with-lct-enabled";
  }

  if (costingStyling === false || asset.status === "existing") {
    appendColor = "badge-dark";
  } else {
    if (asset.status === "new") {
      appendColor =
        colourBlindEnabled === true
          ? "badge-colourblind-colour"
          : "badge-colour";
    }

    if (asset.status === "replacement") {
      appendColor =
        colourBlindEnabled === true
          ? "badge-replacement-colourblind-colour"
          : "badge-replacement-colour";
    }
  }

  const getIconMarkerStyle = () => {
    if (
      styles.bgColor === "newColourblindMarkerColour" ||
      styles.bgColor === "newMarkerColour" ||
      styles.bgColor === "replacementColourblindMarkerColour" ||
      styles.bgColor === "replacementMarkerColour"
    ) {
      return `iconMarker ${appendColor} iconMarker-sz-${iconSize}${appendLct}`;
    } else {
      return `iconMarker badge-${styles.bgColor} icon-${styles.borderColor} iconMarker-sz-${iconSize}${appendLct}`;
    }
  };

  const getSmallIconMarkerStyle = () => {
    switch (styles.bgColor) {
      case "newColourblindMarkerColour":
        return `smallIconMarker ${smallIconMarkerOverride} icon-colourblind-colour`;
      case "newMarkerColour":
        return `smallIconMarker ${smallIconMarkerOverride} icon-colour`;
      case "replacementColourblindMarkerColour":
        return `smallIconMarker ${smallIconMarkerOverride} icon-replacement-colourblind-colour`;
      case "replacementMarkerColour":
        return `smallIconMarker ${smallIconMarkerOverride} icon-replacement-colour`;
      default:
        return `smallIconMarker ${smallIconMarkerOverride} text-${styles.bgColor}`;
    }
  };

  const icon = L.divIcon({
    name: styles.name,
    className: `${styles.cssClass} ${
      styles.circleMarker ? getIconMarkerStyle() : getSmallIconMarkerStyle()
    } ${mode} ${activeTool.type === "cable" ? "connect" : ""}`,
    html: renderToString(
      <IconLabel
        data-id={id}
        data-name={name}
        name={name}
        bg={styles.bgColor}
        className={`icon-${styles.cssClass}`}
      />,
    ),
    iconSize: styles.circleMarker
      ? [iconSize * 16, iconSize * 16]
      : [iconSize, iconSize],
  });
  return icon;
};
