import React from "react";
import "./App.css";
import MsalSwitcher from "./components/MsalSwitcher";
import ToolProvider from "./context/ToolContext";
import FormProvider from "./context/FormContext";
import { store } from "./app/store";
import { Provider } from "react-redux";

const App = () => {
  return (
    <Provider store={store}>
      <ToolProvider>
        <FormProvider>
          <MsalSwitcher />
        </FormProvider>
      </ToolProvider>
    </Provider>
  );
};

export default App;
