import React from "react";
import { FormGroup, Label } from "reactstrap";
import ConsumerSelector from "../ConsumerSelector";
import { CONSUMER_LIST } from "../../constants/consumers";
import NestedConsumerForm from "../NestedConsumerForm";
import { consumerDefaults, getCutOutSizeDefaultValue } from "../../utils/consumerDefaults";
import { ConnectionPoint } from "../../model/viewModel/connectionPoint";
import { getConnectionPointProperties } from "../../states/connectionPoint";
import * as statusConstants from "../../constants/status";
import { v4 as uuid } from "uuid";
import { GroupedConnectionPoint } from "../../model/viewModel/groupedConnectionPoint";

const ConsumerUpdate = ({
  minimised,
  setMinimised,
  consumers,
  setConsumers,
  reference }) => {

  const removeConsumerItem = (_childConsumer, consumer) => {
    setConsumers(consumers.filter(p => p.id !== consumer.id));
  };

  const updateConsumerCount = (consumer) => {
    setConsumers([...consumers.map(p => p.id === consumer.id ? { ...p, count: consumer.count } : p)]);
  };

  const addConsumerItem = (_parentConsumer, formData) => {
    const itemType = formData.get("item");
    const count = Number(formData.get("count"));

    let _consumer = {
      ...CONSUMER_LIST.find((y) => y.class === itemType),
    };

    const consumer = consumerDefaults(reference.consumers, _consumer.class);

    const id = uuid();

    const groupedConnectionPoint = new GroupedConnectionPoint(
      id,
      getConnectionPointProperties(
        consumer["consumerType"],
        false,
        true,
        count,
        0,
        0,
        0,
        false,
        0,
        null,
        consumer["consumptionValue1"],
        false,
        consumer["consumptionValue2"],
        false,
        consumer["consumptionType"],
        false,
        [], // geometry, to fill in later
        "Single",
        false,
        [count, 0, 0, 0],
        _consumer,
        null,
        false,
        _consumer.name === "Generator",
        null,
        "",
        statusConstants.NEW,
        getCutOutSizeDefaultValue(_consumer.class)
      ),
      [],
      count
    );

    setConsumers([...consumers, groupedConnectionPoint]);
  };

  const addConsumerSubItem = (parentConsumer, formData) => {
    const itemType = formData.get("item");

    let _consumer = {
      ...CONSUMER_LIST.find((y) => y.class === itemType),
    };

    const consumer = consumerDefaults(reference.consumers, _consumer.class);

    const id = uuid();

    const groupedConnectionPoint = new ConnectionPoint(
      id,
      getConnectionPointProperties(
        consumer["consumerType"],
        false,
        true,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        consumer["consumptionValue1"],
        false,
        consumer["consumptionValue2"],
        false,
        consumer["consumptionType"],
        false,
        [], // geometry, to fill in later
        "Single",
        false,
        [],
        _consumer,
        parentConsumer.id, // parentId
        true,
        _consumer.name === "Generator",
        null,
        "",
        statusConstants.NEW
      )
    );

    groupedConnectionPoint.count = 1;

    const parent = consumers.find(p => p.id === parentConsumer.id);
    if (parent) {
      parent.subGroupConnectionPoints = [...parent.subGroupConnectionPoints, groupedConnectionPoint];
      setConsumers([...consumers.map(p => p.id === parent.id ? parent : p)]);
    }
  };

  const removeConsumerSubItem = (childConsumer, consumer) => {
    consumer.subGroupConnectionPoints = [...consumer.subGroupConnectionPoints.filter(p => p.id !== childConsumer.id)];
    setConsumers([...consumers.map(p => p.id === consumer.id ? consumer : p)]);
  };

  const updateConsumerSubItem = (updatedSubConsumer, childConsumerIndex, consumerIndex) => {
    setConsumers([...consumers.map((p, i) => i === consumerIndex ? {
      ...p, subGroupConnectionPoints: [...p.subGroupConnectionPoints.map((q, qi) =>
        qi === childConsumerIndex ? updatedSubConsumer : q)]
    } : p)]);
  };

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>Connection
      </Label>
      {!minimised && consumers.map((consumer, consumerIndex) => (
        <React.Fragment key={`NestedConsumerForm_Consumers_${consumerIndex}`}>
          <NestedConsumerForm
            consumer={consumer}
            consumerIndex={consumerIndex}
            removeItem={removeConsumerItem}
            updateConsumerCount={updateConsumerCount}
            groupUpdateAction={consumer => setConsumers([...consumers.map(p => p.id === consumer.Id ? consumer : p)])}></NestedConsumerForm>
          {consumer.subGroupConnectionPoints.map(
            (childConsumer, childConsumerIndex) => (
              <div
                key={`NestedConsumerForm_Consumers_Div_${consumerIndex}_${childConsumerIndex}`}
                style={{
                  padding: "0 .75rem .75rem",
                }}
              >
                <div className="bg-white-alpha">
                  <NestedConsumerForm
                    consumer={consumer}
                    consumerIndex={childConsumerIndex}
                    parentId={consumer.id}
                    childConsumer={childConsumer}
                    removeItem={removeConsumerSubItem}
                    groupUpdateAction={updatedSubConsumer => updateConsumerSubItem(updatedSubConsumer, childConsumerIndex, consumerIndex)}
                  ></NestedConsumerForm>
                </div>
              </div>
            )
          )}
          <div
            style={{
              padding: "0 .75rem .75rem",
              margin: ".75rem 0",
              marginTop:
                consumer.subGroupConnectionPoints?.length === 0
                  ? -10
                  : ".75rem 0",
            }}
          >
            <ConsumerSelector
              header="Add Sub Group"
              consumer={consumer}
              count={consumer.count}
              addItem={addConsumerSubItem}
              selectList={CONSUMER_LIST}
            />
          </div></React.Fragment>
      ))}
      {!minimised && <FormGroup>
        <ConsumerSelector
          header="Add Connection"
          addItem={addConsumerItem}
          selectList={CONSUMER_LIST}
        />
      </FormGroup>}
    </div>
  );
};

export default ConsumerUpdate;
