import { useState, useEffect } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FAULT_MARKER_COLORS } from "../constants/colors";

const ColorPalette = ({ selectedNetwork, handleSelectedColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(selectedNetwork.color);

  useEffect(() => {
    setSelectedColor(selectedNetwork.color);
  }, [selectedNetwork.color]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectColor = (e) => {
    setSelectedColor(e.target.id);
    handleSelectedColor(e.target.id, selectedNetwork);
    toggle();
  };

  return (
    <>
      <div className="text-right" style={containerStyle}>
        <div className="d-flex">
          <UncontrolledDropdown isOpen={isOpen} toggle={toggle} size="sm">
            <DropdownToggle
              id="colorToggle"
              tag="span"
              className="nav-link py-0 mt-n2 mb-n2"
            >
              <div
                className="d-flex justify-content-center text-light"
                style={{
                  height: 20,
                  width: 25,
                  background: selectedColor,
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="text-light smallIconMarkerX1"
                />
              </div>
            </DropdownToggle>
            <DropdownMenu
              className="mt-1 mr-3"
              right
              style={{
                background: "rgb(27,30,33)",
                padding: 10,
                border: "gray 1px solid",
                borderRadius: 5,
              }}
            >
              <div
                className="d-flex flex-row flex-wrap align-content-center"
                style={{ width: 145, marginLeft: 2 }}
              >
                {FAULT_MARKER_COLORS.map((color) => (
                  <div
                    key={color}
                    id={color}
                    onClick={(e) => handleSelectColor(e)}
                    style={{
                      height: 18,
                      width: 18,
                      background: color,
                      border:
                        selectedColor !== color
                          ? "1px solid grey"
                          : "2px solid white",
                      margin: 3,
                    }}
                  />
                ))}
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </>
  );
};

const containerStyle = {
  marginTop: 2,
  backgroundColor: "transparent",
};

const menuDarkStyle = {
  background: "rgb(27,30,33)",
  padding: 7,
  height: 63,
  borderRadius: 5,
};

export default ColorPalette;
