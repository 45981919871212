import React, { useContext } from "react";
import { Polyline, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { FormContext } from "../../context/FormContext";
import * as color from "../../constants/colors";

const CableGroup = ({ ragNetwork }) => {
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;
  const cableZoomTrigger = parseInt(clientSettings.General.CableZoomTrigger);
  const cableZoomWeightMultiplier = parseInt(
    clientSettings.General.CableZoomWeightMultiplier
  );
  const colourBlindEnabled = useSelector(
    (state) => state.settings.isColorBlindEnabled
  );
  const cascadingRAGColours = useSelector(
    (state) => state.settings.showCascadingRagColors
  );
  const map = useMap();

  const getLineColor = (asset) => {
    switch (
      cascadingRAGColours
        ? asset.cascadingRAG?.toLowerCase()
        : asset.nonCascadingRAG?.toLowerCase()
    ) {
      case "red":
        return colourBlindEnabled ? color.COLOURBLIND_RED : color.RED;
      case "amber":
        return colourBlindEnabled ? color.COLOURBLIND_AMBER : color.AMBER;
      case "green":
        return colourBlindEnabled ? color.COLOURBLIND_GREEN : color.GREEN;
      default:
        return color.THREE_PHASE;
    }
  };

  const cableWeight = (cable) => {
    let weight = 2;

    if (!cable || !cable.cableGroup) return weight;

    if (
      cable.cableGroup === "mains-underground" ||
      cable.cableGroup === "mains-overhead"
    ) {
      weight = 4;
    }

    // weight based on map zoom level
    if (map.getZoom() > cableZoomTrigger) {
      weight = weight * cableZoomWeightMultiplier;
    }

    return weight;
  };

  const cableDash = (cable, defaultDashLength, defaultSpacingLength) => {
    let dash = "";

    if (
      !cable ||
      !cable.cableGroup ||
      !defaultDashLength ||
      !defaultSpacingLength
    )
      return dash;

    let dashLength = defaultDashLength;
    let spacingLength = defaultSpacingLength;

    if (map.getZoom() > cableZoomTrigger) {
      dashLength = dashLength * cableZoomWeightMultiplier;
      spacingLength = spacingLength * cableZoomWeightMultiplier;
    }

    if (cable.cableGroup.endsWith("underground")) {
      return `${dashLength} ${spacingLength}`;
    }

    return dash;
  };

  const cableMap = {};

  ragNetwork.cables.forEach((cable) => {
    const color = getLineColor(cable);
    const weight = cableWeight(cable);
    const dashArray = cableDash(cable, 4, 6);
    const key = `${color}---${weight}---${dashArray}`;

    if (cableMap.hasOwnProperty(key)) {
      cableMap[key] = [...cableMap[key], cable.geometry];
    } else {
      cableMap[key] = [cable.geometry];
    }
  });

  const lineGroup = Object.keys(cableMap);

  // const test = (propList, e) => {
  //   const [color, weight, dashArray] = propList;
  //   console.log("TEST, color, weight, dashArray", color, weight, dashArray);
  //   const { x, y } = e.layerPoint;
  //   console.log("e", x, y);
  //   console.log(map.layerPointToLatLng([x, y]));
  //   // console.log("e", e.target._parts);
  //   const TOLERANCE = 13;
  //   const partList = e.target._parts;

  //   const section = partList.find((pList) => {
  //     // console.log(p);
  //     try {
  //       // check if in bounds
  //       // p is an array of points, with 2 or more values
  //       // console.log("yCalc", p);
  //       // 1035 884
  //       const segment = pList
  //         .slice(1)
  //         .map((x, k) => [x, pList[k]])
  //         .filter(([from, to], k) => {
  //           return (
  //             Math.min(from.x, to.x) < x &&
  //             x < Math.max(from.x, to.x) &&
  //             Math.min(from.y, to.y) < y &&
  //             y < Math.max(from.y, to.y)
  //           );
  //           // console.log(map.latLngToLayerPoint({ lat: point.x, lng: point.y }));
  //           // console.log(
  //           //   Math.min(point.x, p[k].x) <= x <= Math.max(point.x, p[k].x) &&
  //           //     Math.min(point.y, p[k].y) <= y <= Math.max(point.y, p[k].y)
  //           // );
  //         });
  //       // if (
  //       //   !(
  //       //     Math.min(point[0].x, point[1].x) <= x <= Math.max(point[0].x, point[1].x) &&
  //       //     Math.min(point[0].y, point[1].y) <= y <= Math.max(point[0].y, point[1].y)
  //       //   )
  //       // )
  //       //   return false;

  //       // const yCalc =
  //       //   ((x - p[0].x) * (p[0].y - p[1].y)) / (p[0].x - p[1].x) + p.y;
  //       // return Math.abs(y - yCalc) < TOLERANCE;
  //       console.log("segment");
  //       console.log(segment);
  //       return segment.length;
  //     } finally {
  //       // prev = p;
  //     }
  //   });

  //   console.log("section");
  //   console.log(section);
  // };

  return lineGroup.map((lineKey) => {
    const [color, weight, dashArray] = lineKey.split("---");
    return (
      <Polyline
        key={lineKey}
        positions={cableMap[lineKey]}
        pathOptions={{
          weight: weight,
          dashArray: dashArray,
          color: color,
        }}
        snapIgnore={true}
        // onClick={(e) => test(cableMap[lineKey], e)}
      />
    );
  });
};

export default CableGroup;
