import L from "leaflet";
import React, { useContext, useState, useRef } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import detectIt from "detect-it";
import { ToolContext } from "../context/ToolContext";
import { useSelector } from "react-redux";

const MarkerTool = ({ create }) => {
	const markerRef = useRef(null);
	const { toolState } = useContext(ToolContext);
	const { mode, activeTool } = toolState;
	const iconSize = useSelector((state) => state.settings.iconSize);
	const [newPosition, setNewPosition] = useState();

	const styleIcon = () => {
		return iconMarker(activeTool, mode, iconSize);
	};

	const iconMarker = (activeTool, mode, iconSize) => {
		const icon = L.divIcon({
			name: activeTool.name,
			className: `${activeTool.class} ${
				activeTool.circleMarker
					? `iconMarker badge-dark icon-${activeTool.borderColor} iconMarker-sz-${iconSize}`
					: "smallIconMarker"
			} ${activeTool.type === "cable" ? "connect" : ""}`,
			html: `<i class=icon-${activeTool.class}></i>`,
			iconSize: activeTool.circleMarker
				? [iconSize * 16, iconSize * 16]
				: [iconSize, iconSize],
		});
		return icon;
	};

	useMapEvents({
		mousemove(e) {
			setNewPosition(e.latlng);
		},
		click(e) {
			create(e.latlng);
		},
	});

	return (
		<>
			{newPosition && detectIt.hasMouse && (
				<Marker ref={markerRef} position={newPosition} icon={styleIcon()} />
			)}
		</>
	);
};

export default MarkerTool;
