import React, { createContext, useReducer } from "react";
import { formReducer } from "../reducers/formReducer";

const initialState = {
	constraints: [],
	reference: {},
	clientSettings: {},
	cableProperties: {},
	connectionPointProperties: {},
	transformerProperties: {},
	groupedConnectionProperties: {},
	motorProperties: {},
	welderProperties: {},
	pointOfConnectionProperties: {},
	network: {},
	ragNetwork: {
		transformers: [],
		cables: [],
	},
	ragNetworks: [],
	crown: {},
	detect: {
		impedances: [],
	},
	detectNetworks: [],
};

export const FormContext = createContext();
const FormProvider = ({ children }) => {
	const [formState, dispatch] = useReducer(formReducer, initialState);

	return (
		<FormContext.Provider value={{ formState, dispatch }}>
			{children}
		</FormContext.Provider>
	);
};

export default FormProvider;
