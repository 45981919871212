import { useEffect, useState } from "react";
import { Collapse, Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ConsumerForm from "./ConsumerForm";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";

const NestedConnectionForm = ({
  consumer,
  consumerIndex,
  childConsumer,
  parentId,
  removeItem,
  updateConsumerCount,
  selectedType,
  groupUpdateAction,
}) => {
  const [isOpen, setIsOpen] = useState(parentId == null ? consumerIndex : null);

  const [consumerCount, setConsumerCount] = useState(consumer.count);

  useEffect(() => {
    if (consumerCount !== consumer.count) {
      setConsumerCount(consumer.count);
    }
  }, [consumer]);

  const toggle = (i) => {
    if (isOpen === i) {
      setIsOpen(null);
      return;
    }
    setIsOpen(i);
  };

  const handleSetConsumerCount = (e) => {
    let value = e.target.value;

    // If value will be negative, return
    if (value < 0) return;

    // Prefixing 0 then slicing allows better usability of the input.
    setConsumerCount(value);
  };

  const handleUpdateConsumerCount = () => {
    if (consumerCount === consumer.count) return;
    consumer.count = consumerCount;
    const parsedConsumerCount = parseInt(consumerCount);
    if (
      !consumerCount ||
      !Number.isInteger(parsedConsumerCount) ||
      consumerCount === "0"
    ) {
      consumer.count = 1;
    }
    store.dispatch(startBatchAction());
    try {
      updateConsumerCount(consumer);
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const getUnit = (selectedConsumer) => {
    return selectedConsumer.consumptionType === "AnnualConsumption"
      ? "kWh"
      : "kW";
  };

  const getUnitValue = (selectedConsumer) => {
    let value1 = isNaN(parseInt(selectedConsumer.consumptionValue1))
      ? 0
      : parseInt(selectedConsumer.consumptionValue1);
    let value2 = isNaN(parseInt(selectedConsumer.consumptionValue2))
      ? 0
      : parseInt(selectedConsumer.consumptionValue2);

    return value1 > value2
      ? selectedConsumer.consumptionValue1
      : selectedConsumer.consumptionValue2;
  };

  const validateKeyPress = (event) => {
    const num = consumer.count ? /[0-9]/ : /[1,9]/;
    if (!num.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{ padding: ".75rem" }}
      >
        <h6
          className="border-bottom text-light py-2 w-100 mb-0 d-flex justify-content-between"
          style={{ cursor: "pointer" }}
        >
          <small>
            <i
              id="consumerCollapseIcon"
              onClick={() => toggle(consumerIndex)}
              className={`icon-chevron-${
                isOpen === consumerIndex ? "down" : "right"
              }`}
            ></i>
            <div
              className="d-flex flex-row"
              style={{
                marginLeft: 20,
                marginTop: -17,
              }}
            >
              <div
                className="d-flex flex-row align-items-center"
                onClick={() => toggle(consumerIndex)}
              >
                <p>
                  {childConsumer
                    ? childConsumer.styles.name
                    : consumer.styles.name}
                </p>
                {!parentId && (
                  <>
                    <p className="mr-2 ml-2">x</p>
                    <Input
                      style={{ maxWidth: 80, marginBottom: 10 }}
                      type="number"
                      id="count"
                      name="count"
                      placeholder="0"
                      min={1}
                      value={consumerCount}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={handleSetConsumerCount}
                      onBlur={handleUpdateConsumerCount}
                      onKeyPress={validateKeyPress}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="align-top d-flex flex-row">
              <div
                className="ml-3"
                style={{
                  fontSize: 11,
                  marginTop: 8,
                  paddingLeft: 1,
                }}
              >
                &nbsp;
                {childConsumer
                  ? `Max ${getUnit(
                      childConsumer,
                    )} (per customer): ${getUnitValue(childConsumer)}
                    `
                  : `Max ${getUnit(consumer)} (per customer): ${getUnitValue(
                      consumer,
                    )}`}
              </div>
            </div>
          </small>
          <i
            className={`icon-${
              childConsumer ? childConsumer.styles.class : consumer.styles.class
            } mr-1`}
          ></i>
        </h6>
        <Button
          size="sm"
          color="danger"
          className="ml-2"
          style={{ height: 29 }}
          onClick={() => removeItem(childConsumer, consumer)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <Collapse isOpen={isOpen === consumerIndex}>
        <ConsumerForm
          consumer={consumer}
          childConsumer={childConsumer}
          parentId={parentId}
          groupedConnection={true}
          subGroupedConnection={parentId ? true : false}
          selectedType={selectedType}
          groupUpdateAction={groupUpdateAction}
        />
      </Collapse>
    </>
  );
};

export default NestedConnectionForm;
