import { getTransformerPhase } from "../utils/networkFunctions";

const getConsumptionType2 = (consumerType) => {
  let _consumptionType;
  [
    { demandType: "demandtypeAC", consumptionType: "AnnualConsumption" },
    {
      demandType: "demandtypeMDD",
      consumptionType: "MaximumDemandDiversified",
    },
    { demandType: "demandtypeMDF", consumptionType: "MaximumDemand" },
  ].forEach((dt) => {
    if (consumerType[dt.demandType]) {
      _consumptionType = dt.consumptionType;
    }
  });
  return _consumptionType;
};

export const consumerDefaults = (
  consumers,
  consumerClass,
  consumerType = "",
  consumptionType = ""
) => {
  const { defaults, types } = consumers;

  const consumerType_ = consumerType ? consumerType : defaults[consumerClass];
  const consumer = types.find((ct) => ct.name === consumerType_.loadprofile);
  const consumptionType_ = consumptionType
    ? consumptionType
    : getConsumptionType2(consumer);
  const consumptionValue1 = consumer[`default${consumptionType_}Day`];
  const consumptionValue2 = consumer[`default${consumptionType_}Night`];
  const consumerPhases = consumerType_.phases;

  return {
    consumerType: consumerType_.loadprofile,
    consumptionType: consumptionType_,
    consumptionValue1: consumptionValue1,
    consumptionValue2: consumptionValue2,
    consumerPhases: consumerPhases,
  };
};

export const getCutOutSizeDefaultValue = (consumerType) => {
  if (
    ["domestic", "evChargePoint", "generator", "heatPump"].includes(
      consumerType
    )
  ) {
    return "100A";
  }
  return "600A";
};

export const getDefaultUnbalancedPercent = (formState, transformers, cables, groupedConnectionId) => {
  switch (getTransformerPhase(formState, transformers, cables, groupedConnectionId)) {
    case "Two":
      return 33.33;
    default:
      return 20;
  };
};

export const getDefaultLowLoadedPhase = (formState, transformers, cables, groupedConnectionId) => {
  switch (getTransformerPhase(formState, transformers, cables, groupedConnectionId)) {
    case "Two":
      return "L2";
    default:
      return "L3";
  };
};