import { FormGroup, Input, Label } from "reactstrap";
import * as statusConstants from "../../constants/status";

const CableTypeUpdate = ({ minimised, setMinimised, value, setValue, valueWarning, selected, status, reference, ohWhitelist, ugWhitelist, ringfencedTypes }) => {

  const getCableTypes = () => {
    const isUnderground = selected.includes("Underground") || (["All", "Conductors"].includes(selected) && ringfencedTypes.some(p => p.name.includes("Underground")));
    const isMains = selected.includes("Mains") || (["All", "Conductors"].includes(selected) && ringfencedTypes.some(p => p.name.includes("Mains")));
    const isNewOnly = status === statusConstants.NEW || status === statusConstants.REPLACEMENT;
    const allTypes = reference.cables.types.filter(p => (p.underground === isUnderground || p.overhead === !isUnderground) && (p.mains === isMains || p.service === !isMains));
    if (isNewOnly) {
      return allTypes.filter(p => isUnderground ? ugWhitelist.includes(p.name) : ohWhitelist.includes(p.name));
    }
    return allTypes;
  };

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label for="cableType" onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>Cable Type
      </Label>
      {!minimised && (
        <FormGroup>
          <Input
            type="select"
            name="cableType"
            id="cableType"
            value={value}
            onChange={(e) => setValue(e.value ?? e.target.value)}
          >
            <option value="auto">Auto</option>
            {getCableTypes().map((type) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
          </Input>
          {valueWarning && (<div className="text-warning py-1">
            <i className="icon-exclamation-solid"></i> This cable type is incompatible with the status of one or more of the selected cables</div>)}
        </FormGroup>)}
    </div>
  );
};

export default CableTypeUpdate;
