import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label, Input, FormGroup, Col, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import getAssetStyleInfo from "../context/AssetStyleInfo";
import { v4 as uuid } from "uuid";
import { GroupedConnection } from "../model/viewModel/groupedConnection";
import ConnectionForm from "./ConnectionForm";
import { clearResults, updatePointOfConnection, findPointOfConnection, deletePointOfConnection, addGroupedConnection } from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";

const PointOfConnectionForm = ({ pointOfConnection }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { reference, groupedConnectionProperties } = formState;
  const { clickedAsset, errors } = toolState;

  const dispatchRedux = useDispatch();

  const activePointOfConnection = useSelector((state) => findPointOfConnection(state, clickedAsset?.id));
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);

  const {
    geometry,
    voltDropPercent,
    loopResistanceMilliohm,
    includesTransformerResistance,
    annotation,
  } = clickedAsset ? activePointOfConnection : pointOfConnection;

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    _toolState.errors = {};

    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(reference)));
  };

  const handleToggle = () => {
    hideResults();
    handleChange({
      name: "includesTransformerResistance",
      value: !includesTransformerResistance,
    });
  };

  const handleChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;

    dispatch({
      form: "pointOfConnectionProperties",
      field: name,
      value: value,
      type: "UPDATE_FIELD",
    });

    if (clickedAsset) {
      const filteredErrors = errors.messages
        ? errors.messages.map((message) => message.link)
        : [];

      if (filteredErrors.includes(clickedAsset.id)) {
        let _errors = { ...errors };
        _errors.messages = _errors.messages
          ? _errors.messages.filter(
              (message) => message.link !== clickedAsset.id
            )
          : [];

        const _toolState = toolState;
        _toolState.errors = _errors;
        setToolState(_toolState);
      }

      dispatchRedux(updatePointOfConnection({ id: clickedAsset.id, name, value }));
    }
  };

  const removePointOfConnection = () => {
    hideResults();

    const _formState = { ...formState };
    _formState.network.existing = false;

    const groupedConnection = createGroupedConnection();

    store.dispatch(startBatchAction());
    try {
      dispatchRedux(addGroupedConnection(groupedConnection));
      dispatchRedux(deletePointOfConnection(clickedAsset.id));
    } finally {
      store.dispatch(endBatchAction());
    }

    dispatch({
      form: "groupedConnectionProperties",
      obj: new GroupedConnection(uuid(), "", [], "", reference.groundTypeOverrideDefaults.groundTypeOverride),
      type: "REPLACE_STATE",
    });

    const _toolState = { ...toolState };
    _toolState.activeTool = groupedConnection.styles;
    _toolState.clickedAsset = groupedConnection;
    setToolState(_toolState);
  };

  const createGroupedConnection = () => {
    const groupedConnection = groupedConnectionProperties;
    groupedConnection.id = clickedAsset.id;
    groupedConnection.geometry = geometry;
    groupedConnection.status = "Existing";
    groupedConnection.overrideGroundType = reference.groundTypeOverrideDefaults.groundTypeOverride;
    groupedConnection.styles = getAssetStyleInfo("node");
    groupedConnection.groupedConnectionPoints = [];
    return groupedConnection;
  };

  const setPointOfConnectionToNode = () => {
    hideResults();

    const _toolState = toolState;
    _toolState.activeTool = getAssetStyleInfo("node");
    setToolState(_toolState);

    dispatch({
      form: "groupedConnectionProperties",
      obj: new GroupedConnection(uuid(), "", [], getAssetStyleInfo("node"), reference.groundTypeOverrideDefaults.groundTypeOverride),
      type: "REPLACE_STATE",
    });
  };

  const isNode = () => {
    var node = groupedConnections.find(
      (gc) => gc.id === clickedAsset?.id
    );
    if (node) return true;
    return false;
  };

  return (
    <>
      {!isNode() && (
        <Form
          className="w-100 text-light"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FormGroup style={{ paddingTop: 10 }}>
            <Input
              id="annotation"
              name="annotation"
              className="w-100"
              placeholder="Enter label/annotation"
              value={annotation}
              onChange={handleChange}
              maxLength="50"
              autoFocus
            />
          </FormGroup>
          <div
            className="bg-white-alpha"
            style={{
              paddingLeft: 12,
              paddingTop: 10,
              paddingBottom: 2,
              paddingRight: 12,
              marginRight: -2,
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ paddingBottom: 20 }}
            >
              <h6
                className="border-bottom text-light py-2 w-100 mb-0 d-flex justify-content-between"
                style={{ cursor: "pointer" }}
              >
                <small>Point of Connection</small>
              </h6>
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                style={{ height: 29, width: 32 }}
                onClick={() =>
                  clickedAsset
                    ? removePointOfConnection()
                    : setPointOfConnectionToNode()
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>

            <FormGroup row className="mb-3">
              <Label for="voltDropPercent" sm={6}>
                PoC Volt Drop (%)​
              </Label>
              <Col sm={6}>
                <Input
                  type="number"
                  id="voltDropPercent"
                  name="voltDropPercent"
                  value={voltDropPercent}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="loopResistanceMilliohm" sm={6}>
                PoC Loop Resistance (mΩ)​
              </Label>
              <Col sm={6}>
                <Input
                  type="number"
                  id="loopResistanceMilliohm"
                  name="loopResistanceMilliohm"
                  value={loopResistanceMilliohm}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                color="dark"
                block
                onClick={() => handleToggle()}
                className={includesTransformerResistance ? "active" : ""}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span>Includes Transformer Resistance</span>
                  <i
                    className={`icon-${
                      includesTransformerResistance ? "check" : "cross"
                    }-solid`}
                  ></i>
                </div>
              </Button>
            </FormGroup>
          </div>
        </Form>
      )}
      {isNode() && <ConnectionForm />}
    </>
  );
};

export default PointOfConnectionForm;
