import { useContext } from "react";
import { Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { getLocation } from "../services/apiService";
import { ToolContext } from "../context/ToolContext";
import { useMsal } from "@azure/msal-react";

const Searchbox = () => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { locationSearchValue } = toolState;
  const { accounts } = useMsal();

  const setToolStateProp = (prop, value) => {
    const _toolState = toolState;
    _toolState[prop] = {
      type: "warning",
      className: "warning",
      messages: value,
    };
    setToolState(_toolState);
  };

  const postcodeToSetView = async () => {
    const account = accounts[0];
    const location = await getLocation(locationSearchValue, account);

    if (location?.data?.messages?.length > 0) {
      setToolStateProp("alert", location?.data?.messages);
      return;
    }

    if (!location.data || location.data.totalResults === 0) {
      return;
    }

    const x = location.data.locationValue_X;
    const y = location.data.locationValue_Y;
    const latLng = { lat: x, lng: y };

    setToolState({
      navigateToLatLng: latLng,
    });
  };

  return (
    <div className="p-3">
      <InputGroup>
        <Input
          type="text"
          id="location-search"
          placeholder="Search for a Location"
          bsSize="sm"
          value={locationSearchValue}
          onChange={(e) =>
            setToolState({
              locationSearchValue: e.target.value,
            })
          }
        />
        <InputGroupAddon addonType="append">
          <Button
            id="btnSearchSub"
            size="sm"
            color="primary"
            disabled={!locationSearchValue}
            onClick={() => postcodeToSetView()}
          >
            Search
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

export default Searchbox;
