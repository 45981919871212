import React, { useState, useEffect } from "react";
import { GeoJSON, useMap } from "react-leaflet";
import { getGeoJson } from "../services/geoServerService";
import { useDispatch } from "react-redux";
import { removeGeoJsonLoading } from "../app/settingsSlice";

const GeoJsonLoader = ({ id, layer, url }) => {
  const map = useMap();
  const [data, setData] = useState(null);
  const [bounds, setBounds] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    _getBounds();
  }, [id]);

  useEffect(() => {
    if (bounds) {
      loadAssetData();
    }
  }, [bounds]);

  const _getBounds = () => {
    setBounds(map.getBounds());
  };

  const loadAssetData = () => {
    getGeoJson(bounds, layer)
      .then((result) => {
        setData(result.data.features);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(removeGeoJsonLoading(layer.name));
        // const _toolState = toolState;
        // _toolState.geoJsonLoading = removeElement(
        //   _toolState.geoJsonLoading,
        //   layer.name
        // );
        // setToolState(_toolState);
      });
  };

  return (
    data &&
    data.length > 0 &&
    data.map((layer) => <GeoJSON data={layer}></GeoJSON>)
  );
};

export default GeoJsonLoader;
