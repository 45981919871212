// @flow
import NameValueProperty from "./nameValueProperty";
import PropertyContainer from "./propertyContainer";
import { v4 as uuid } from "uuid"; // generates GUIDs

interface IConnectivityNode {
  id: string;
}

export default class ConnectivityNode
  extends PropertyContainer
  implements IConnectivityNode
{
  constructor(id: string, properties: ?(NameValueProperty[])) {
    super(properties);
    this.id = id;
  }

  id: string;
  properties: NameValueProperty[];
}
