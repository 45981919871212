import React, { useEffect, useContext, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormGroup, Label, Input, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import RadioBtnGrp from "./RadioBtnGrp";
import CheckboxBtnGrp from "./CheckboxBtnGrp";
import CounterGroup from "./CounterGroup";
import DataAlert from "./DataAlert";
import { getFormName } from "../utils/formUtil";
import * as phase from "../constants/phase";
import * as statusConstants from "../constants/status";
import { getDefaultUnbalancedPercent } from "../utils/consumerDefaults";
import {
  clearResults,
  updateCableChild,
  updateConnectionPoint,
  updateGroupedConnection,
  updateGroupedConnectionChild,
  updateTransformerChild,
} from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { endBatchAction, startBatchAction } from "../app/undoable";
import { store } from "../app/store";

const assignedphasesObj = [
  {
    id: phase.PHASE1,
    value: "L1",
    field: [phase.PHASE1],
    phases: [phase.SINGLE],
  },
  {
    id: phase.PHASE2,
    value: "L2",
    field: [phase.PHASE2],
    phases: [phase.SINGLE],
  },
  {
    id: phase.PHASE3,
    value: "L3",
    field: [phase.PHASE3],
    phases: [phase.SINGLE],
  },
  {
    id: 1,
    value: "L1+L2",
    field: [phase.PHASE1, phase.PHASE2],
    phases: [phase.TWO],
  },
  {
    id: 2,
    value: "L1+L3",
    field: [phase.PHASE1, phase.PHASE3],
    phases: [phase.TWO],
  },
  {
    id: 3,
    value: "L2+L3",
    field: [phase.PHASE2, phase.PHASE3],
    phases: [phase.TWO],
  },
  {
    id: 1,
    value: "L1+L2+L3",
    field: [phase.PHASE1, phase.PHASE2, phase.PHASE3],
    phases: [phase.THREE],
  },
  {
    id: phase.AUTO,
    value: phase.AUTO,
    field: [phase.AUTO],
    phases: [phase.SINGLE, phase.TWO],
  },
];

const lowLoadedPhasesObj = {
  name: "lowLoadedPhases",
  obj: [
    { id: "lowLoadedPhaseL1", value: "L1", color: "light" },
    { id: "lowLoadedPhaseL2", value: "L2", color: "light" },
    { id: "lowLoadedPhaseL3", value: "L3", color: "light" },
  ],
};

const getAssignedPhasesValue = (val) => {
  return [
    { name: phase.AUTO, value: val.autoPhase ? val.phaseAutoConsumers : 0 },
    { name: phase.PHASE1, value: val.autoPhase ? 0 : val.phase1Consumers },
    { name: phase.PHASE2, value: val.autoPhase ? 0 : val.phase2Consumers },
    { name: phase.PHASE3, value: val.autoPhase ? 0 : val.phase3Consumers },
  ]
    .filter((f) => f.value)
    .map((m) => m.name);
};

const getAssignedPhasesObj = (val) => {
  return [
    { name: phase.AUTO, value: val.phaseMap[0] },
    { name: phase.PHASE1, value: val.phaseMap[phase.PHASE1] },
    { name: phase.PHASE2, value: val.phaseMap[phase.PHASE2] },
    { name: phase.PHASE3, value: val.phaseMap[phase.PHASE3] },
  ];
};

const ConsumerForm = ({
  consumer,
  childConsumer,
  parentId,
  groupedConnection = false,
  subGroupedConnection = false,
  selectedType,
  groupUpdateAction,
}) => {
  const componentUpdate = useRef(false);
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { groupedConnectionProperties, clientSettings, reference } = formState;
  const [resetAssignedPhasesWarning, setResetAssignedPhasesWarning] =
    useState(false);

  const dispatchRedux = useDispatch();

  const transformers = useSelector((state) => state.network.present.transformers);
  const cables = useSelector((state) => state.network.present.cables);

  const {
    consumerType,
    consumerTypeIsDefault,
    consumptionType,
    consumptionTypeIsDefault,
    consumptionValue1,
    consumptionValue1IsDefault,
    consumptionValue2,
    consumptionValue2IsDefault,
    numberOfPhases,
    numberOfPhasesIsDefault,
    balancedLoad,
    unbalancePercent,
    lowLoadedPhase,
    status,
    cutOutSize,
  } = childConsumer || consumer;

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  useEffect(() => {
    if (componentUpdate.current) setConsumptionValues(consumptionType);

    if (!componentUpdate.current) {
      componentUpdate.current = true;
    }
  }, [consumerType, consumptionType]);

  const defaultsArr = [
    { name: numberOfPhasesIsDefault, label: "numberOfPhases" },
    { name: consumerTypeIsDefault, label: "consumerType" },
    { name: consumptionTypeIsDefault, label: "consumptionType" },
    { name: consumptionValue1IsDefault, label: "consumptionValue1" },
    { name: consumptionValue2IsDefault, label: "consumptionValue2" },
  ];

  const getConsumerType = () => {
    return formState.reference.consumers.types.find(
      (f) => f.name === consumerType,
    );
  };

  const setConsumptionValues = (consumptionType) => {
    ["Day", "Night"].forEach((period) => {
      if (consumerType && consumptionType) {
        let consumerTypeValue =
          getConsumerType()[`default${consumptionType}${period}`];

        changeInputValue({
          name: period === "Day" ? "consumptionValue1" : "consumptionValue2",
          value: consumerTypeValue,
        });
      }
    });
  };

  const getConsumptionType = () => {
    let _consumptionType;
    [
      { demandType: "demandtypeAC", consumptionType: "AnnualConsumption" },
      {
        demandType: "demandtypeMDD",
        consumptionType: "MaximumDemandDiversified",
      },
      { demandType: "demandtypeMDF", consumptionType: "MaximumDemand" },
    ].forEach((dt) => {
      if (getConsumerType()[dt.demandType]) {
        _consumptionType = dt.consumptionType;
      }
    });
    return _consumptionType;
  };

  const {
    errors,
    activeTool,
    clickedAsset,
    drawCable,
    mode,
    ringfencedFiltered,
    consumerCountChanged,
  } = toolState;
  const { type } = toolState.activeTool;

  useEffect(() => {
    consumerCountChanged && setAssignedPhases();
    store.dispatch(endBatchAction());
  }, [consumerCountChanged]);

  const changeInputValue = (e) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;

    store.dispatch(startBatchAction());

    try {
      if (groupUpdateAction) {
        consumer[name] = value;
        groupUpdateAction(consumer);
        return;
      }

      handleDefault(name);

      if (groupedConnection) {
        dispatch({
          form: `${getFormName(type, selectedType)}Properties`,
          id: childConsumer ? childConsumer.id : consumer.id,
          parentId: parentId,
          field: name,
          value: value,
          type: "UPDATE_FIELD2",
        });
      } else {
        dispatch({
          form: `${type === "cable"
            ? "cable"
            : type === "transformer"
              ? "transformer"
              : "groupedConnection"
            }Properties`,
          field: name,
          value: value,
          type: "UPDATE_FIELD",
        });
      }
      if (clickedAsset && !drawCable) {
        const assetId = groupedConnection ? consumer.id : clickedAsset.id;

        if (name === "status")
          dispatchRedux(
            updateGroupedConnection({ id: clickedAsset.id, name, value }),
          );

        const filteredErrors = errors.messages
          ? errors.messages.map((message) => message.link)
          : [];

        if (filteredErrors.includes(assetId)) {
          let _errors = { ...errors };
          _errors.messages = _errors.messages
            ? _errors.messages.filter((message) => message.link !== assetId)
            : [];

          const _toolState = toolState;
          _toolState.errors = _errors;
          setToolState(_toolState);
        }

        if (groupedConnection) {
          if (getFormName(type, selectedType) === "transformer") {
            dispatchRedux(
              updateTransformerChild({
                id: clickedAsset.id,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name,
                  value,
                },
                isGroupUpdate: false,
                parentId,
              }),
            );
          } else if (getFormName(type, selectedType) === "groupedConnection") {
            dispatchRedux(
              updateGroupedConnectionChild({
                id: clickedAsset.id,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name,
                  value,
                },
                isGroupUpdate: false,
                parentId,
              }),
            );
          } else if (getFormName(type, selectedType) === "cable") {
            dispatchRedux(
              updateCableChild({
                id: clickedAsset.id,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name,
                  value,
                },
                isGroupUpdate: false,
                parentId,
              }),
            );
          } else {
            console.error(
              `Unexpected type found.  Current type: ${getFormName(
                type,
                selectedType,
              )}.  Expected: transformer, groupedConnection, cable`,
            );
          }
        } else {
          dispatchRedux(
            updateConnectionPoint({ id: clickedAsset.id, name, value }),
          );
        }
      }

      if (mode === "select-group") {
        ringfencedFiltered.forEach((r) => {
          if (getFormName(type, selectedType) === "transformer") {
            dispatchRedux(
              updateTransformerChild({
                id: r.id,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name,
                  value,
                },
                isGroupUpdate: true,
                parentId,
              }),
            );
          } else if (getFormName(type, selectedType) === "groupedConnection") {
            dispatchRedux(
              updateGroupedConnectionChild({
                id: r.id,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name,
                  value,
                },
                isGroupUpdate: true,
                parentId,
              }),
            );
          } else if (getFormName(type, selectedType) === "cable") {
            dispatchRedux(
              updateCableChild({
                id: r.id,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name,
                  value,
                },
                isGroupUpdate: true,
                parentId,
              }),
            );
          } else {
            console.error(
              `Unexpected type found.  Current type: ${getFormName(
                type,
                selectedType,
              )}.  Expected: transformer, groupedConnection, cable`,
            );
          }
        });
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(reference)));
  };

  const changeLowLoadedPhase = (e) => {
    handleChange({ name: "lowLoadedPhase", value: e.value });
  };

  const handleChange = (e) => {
    hideResults();

    store.dispatch(startBatchAction());

    try {
      changeInputValue(e);

      if (e.target?.name === "consumerType") {
        let _consumptionType = getConsumptionType();

        changeInputValue({
          name: "consumptionType",
          value: _consumptionType,
        });
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const mapValues = (e) => {
    hideResults();
    store.dispatch(startBatchAction());
    try {
      [phase.AUTO, phase.PHASE1, phase.PHASE2, phase.PHASE3].forEach((val) => {
        changeInputValue({
          name: `phase${[val]}Consumers`,
          value: e.find((f) => f === val)
            ? (childConsumer ? childConsumer.count : consumer.count) || 1
            : 0,
        });
      });

      changeInputValue({
        name: "autoPhase",
        value: e.find((f) => f === phase.AUTO) === phase.AUTO ? true : false,
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const updatePhaseMap = (e, value, value2, inc) => {
    consumer.phaseMap = [...consumer.phaseMap];
    consumer.phaseMap[e.id] = value + inc;
    consumer.phaseMap[0] = value2 - inc;

    changeInputValue({
      name: "phaseMap",
      value: consumer.phaseMap,
    });
  };

  const mapPhases = (e, value, value2, inc) => {
    setResetAssignedPhasesWarning(false);
    hideResults();

    store.dispatch(startBatchAction());
    try {
      updatePhaseMap(e, value, value2, inc);

      let assignedPhasesArray = [0, 0, 0, 0];

      consumer.phaseMap.forEach((connection, i) => {
        if (consumer.numberOfPhases === phase.SINGLE) {
          assignedPhasesArray[i] += connection;
        }
        if (consumer.numberOfPhases === phase.TWO) {
          if (i === 0) {
            assignedPhasesArray[0] += connection;
          }
          if (i === 1) {
            assignedPhasesArray[1] += connection;
            assignedPhasesArray[2] += connection;
          }
          if (i === 2) {
            assignedPhasesArray[1] += connection;
            assignedPhasesArray[3] += connection;
          }
          if (i === 3) {
            assignedPhasesArray[2] += connection;
            assignedPhasesArray[3] += connection;
          }
        }
      });

      [phase.AUTO, phase.PHASE1, phase.PHASE2, phase.PHASE3].forEach((val, i) => {
        changeInputValue({
          name: `phase${[val]}Consumers`,
          value: assignedPhasesArray[i],
        });
      });

      changeInputValue({
        name: "phaseAutoConsumers",
        value: value2 - inc,
      });

      changeInputValue({
        name: "autoPhase",
        value: false,
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const setAssignedPhases = () => {
    const _toolState = toolState;
    _toolState.consumerCountChanged = false;
    setToolState(_toolState);

    hideResults();

    let autoCount = consumer.phaseAutoConsumers,
      phase1Count = consumer.phase1Consumers,
      phase2Count = consumer.phase2Consumers,
      phase3Count = consumer.phase3Consumers;

    const newConsumerCount = consumer.count;
    const consumerCount = autoCount + phase1Count + phase2Count + phase3Count;

    if (newConsumerCount > consumerCount) {
      autoCount = newConsumerCount - (phase1Count + phase2Count + phase3Count);
    } else {
      autoCount = newConsumerCount;
      phase1Count = 0;
      phase2Count = 0;
      phase3Count = 0;
      setResetAssignedPhasesWarning(true);
    }

    changeInputValue({
      name: "phaseMap",
      value: [autoCount, phase1Count, phase2Count, phase3Count],
    });

    [phase.AUTO, phase.PHASE1, phase.PHASE2, phase.PHASE3].forEach((val) => {
      changeInputValue({
        name: `phase${[val]}Consumers`,
        value:
          val === phase.AUTO
            ? autoCount
            : val === phase.PHASE1
            ? phase1Count
            : val === phase.PHASE2
            ? phase2Count
            : phase3Count,
      });
    });

    changeInputValue({
      name: "autoPhase",
      value: autoCount > 0 ? true : false,
    });
  };

  const mapAutoPhase = () => {
    hideResults();

    store.dispatch(startBatchAction());
    try {
      changeInputValue({
        name: "phaseMap",
        value: [consumer.count, 0, 0, 0],
      });

      [phase.AUTO, phase.PHASE1, phase.PHASE2, phase.PHASE3].forEach((val) => {
        changeInputValue({
          name: `phase${[val]}Consumers`,
          value: val === phase.AUTO ? consumer.count : 0,
        });
      });

      changeInputValue({
        name: "autoPhase",
        value: true,
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const setSelectedPhase_ = (e) => {
    hideResults();

    const consumerCount = consumer.count;
    const previousPhases = consumer.numberOfPhases;

    store.dispatch(startBatchAction());
    try {
      changeInputValue({ name: "numberOfPhases", value: e.value });

      switch (e.value) {
        case phase.SINGLE:
          changeInputValue({
            name: "phaseMap",
            value: [consumerCount, 0, 0, 0],
          });
          changeInputValue({
            name: "balancedLoad",
            value: false,
          });
          mapValues([phase.AUTO]);
          break;
        case phase.TWO:
          changeInputValue({
            name: "phaseMap",
            value: [consumerCount, 0, 0, 0],
          });
          if (previousPhases !== phase.THREE) {
            changeInputValue({
              name: "balancedLoad",
              value: true,
            });
            changeInputValue({
              name: "unbalancePercent",
              value: 0,
            });
          }
          mapValues([phase.AUTO]);
          break;
        case phase.THREE:
          changeInputValue({
            name: "phaseMap",
            value: [0, consumerCount, consumerCount, consumerCount],
          });
          if (previousPhases !== phase.TWO) {
            changeInputValue({
              name: "balancedLoad",
              value: true,
            });
            changeInputValue({
              name: "unbalancePercent",
              value: 0,
            });
          }
          mapValues([phase.PHASE1, phase.PHASE2, phase.PHASE3]);
          break;
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const getConsumerClass = () => {
    return groupedConnection
      ? childConsumer
        ? childConsumer.styles.class
        : consumer.styles.class
      : activeTool.class;
  };

  const handleBalancedLoad = () => {
    hideResults();
    store.dispatch(startBatchAction());
    try {
      changeInputValue({
        name: "balancedLoad",
        value: !balancedLoad,
      });
      if (!balancedLoad) {
        changeInputValue({
          name: "unbalancePercent",
          value: 0,
        });
      } else {
        changeInputValue({
          name: "unbalancePercent",
          value: getDefaultUnbalancedPercent(
            formState,
            transformers,
            cables,
            clickedAsset?.id,
          ),
        });
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const handleDefault = (e) => {
    if (defaultsArr.some((d) => d.label === e)) {
      if (groupedConnection) {
        dispatch({
          form: `${getFormName(type, selectedType)}Properties`,
          id: childConsumer ? childConsumer.id : consumer.id,
          parentId: parentId,
          field: `${e}IsDefault`,
          value: false,
          type: "UPDATE_FIELD2",
        });
      } else {
        dispatch({
          form: `${
            type === "cable"
              ? "cable"
              : type === "transformer"
              ? "transformer"
              : "groupedConnection"
          }Properties`,
          field: `${e}IsDefault`,
          value: false,
          type: "UPDATE_FIELD",
        });
      }
      if (clickedAsset && !drawCable) {
        if (groupedConnection) {
          if (getFormName(type, selectedType) === "transformer") {
            dispatchRedux(
              updateTransformerChild({
                id: clickedAsset.id,
                isGroupUpdate: false,
                parentId,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name: `${e}IsDefault`,
                  value: false,
                },
              }),
            );
          } else if (getFormName(type, selectedType) === "groupedConnection") {
            dispatchRedux(
              updateGroupedConnectionChild({
                id: clickedAsset.id,
                isGroupUpdate: false,
                parentId,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name: `${e}IsDefault`,
                  value: false,
                },
              }),
            );
          } else if (getFormName(type, selectedType) === "cable") {
            dispatchRedux(
              updateCableChild({
                id: clickedAsset.id,
                isGroupUpdate: false,
                parentId,
                childUpdate: {
                  id: childConsumer ? childConsumer.id : consumer.id,
                  name: `${e}IsDefault`,
                  value: false,
                },
              }),
            );
          } else {
            console.error(
              `Unexpected type found.  Current type: ${getFormName(
                type,
                selectedType,
              )}.  Expected: transformer, groupedConnection, cable`,
            );
          }
        } else {
          dispatchRedux(
            updateConnectionPoint({
              id: clickedAsset.id,
              name: `${e}IsDefault`,
              value: false,
            }),
          );
        }
      }
    }
  };

  const handleAllDefaults = () => {
    store.dispatch(startBatchAction());
    try {
      defaultsArr.forEach((d) => {
        if (d.name) handleDefault(d.label);
      });
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const shouldShowCutOutSize = () => {
    if (groupedConnectionProperties.multiOccupancyContainer) {
      return (
        groupedConnectionProperties.multiOccupancyContainer.enabled === false
      );
    }

    return true;
  };

  const assignedPhaseTitle = () => {
    switch (numberOfPhases) {
      case phase.TWO:
        return " (L1+L2)";
      case phase.THREE:
        return " (L1+L2+L3)";
      default:
        return "";
    }
  };

  return (
    <Form
      className="w-100 text-light"
      style={{ padding: ".75rem" }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {defaultsArr.find((d) => d.name === true) && (
        <Button
          color="warning"
          outline
          block
          className="mb-3"
          onClick={() => handleAllDefaults()}
        >
          Resolve all default warnings
        </Button>
      )}
      {clickedAsset && activeTool.type !== "cable" && (
        <span
          className="consumerId"
          style={{ position: "absolute", visibility: "hidden" }}
        >
          {clickedAsset.id}
        </span>
      )}
      {clickedAsset && clickedAsset.nodeNumber && (
        <FormGroup>
          <Label className="id">ID: {clickedAsset.nodeNumber}</Label>
        </FormGroup>
      )}
      {clientSettings.Features.CostingEnabled && (
        <FormGroup>
          <Label for="status">New/Existing</Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={status}
            onChange={handleChange}
          >
            <option value={statusConstants.NEW}>New</option>
            <option value={statusConstants.EXISTING}>Existing</option>
            <option value={statusConstants.REPLACEMENT}>Replacement</option>
          </Input>
        </FormGroup>
      )}
      {activeTool.type === "cable" ||
        (!subGroupedConnection && (
          <FormGroup>
            <Label for="phases">
              No. of Phases
              {numberOfPhasesIsDefault && (
                <DataAlert
                  updateDefault={() => handleDefault("numberOfPhases")}
                />
              )}
            </Label>
            <RadioBtnGrp
              items={phase.CONSUMER_PHASES}
              changeValue={setSelectedPhase_}
              selectedValue={numberOfPhases}
            />
          </FormGroup>
        ))}
      {groupedConnection ? (
        activeTool.type === "cable" ||
        (!subGroupedConnection && (
          <>
            {numberOfPhases === phase.SINGLE ? (
              <FormGroup>
                <Label for="assignedphases2">Assigned Phases</Label>
                <CounterGroup
                  items={assignedphasesObj.filter((f) =>
                    f.phases.includes(numberOfPhases),
                  )}
                  balancedLoad={balancedLoad}
                  changeValue={(e, f, g, h) => mapPhases(e, f, g, h)}
                  setValue={() => mapAutoPhase()}
                  selectedValue={getAssignedPhasesObj(
                    childConsumer || consumer,
                  )}
                  count={consumer.count}
                  numberOfPhases={consumer.numberOfPhases}
                  handleBalancedLoad={handleBalancedLoad}
                />
                {clientSettings.Features.ConsumerPhaseChangedWarningEnabled &&
                  resetAssignedPhasesWarning && (
                    <div className="text-warning py-1">
                      <FontAwesomeIcon icon={faInfoCircle} /> Updating the
                      consumer count has reset the assigned phases
                    </div>
                  )}
              </FormGroup>
            ) : (
              <>
                <div className="d-flex flex-wrap">
                  <div
                    className="d-flex flex-column pr-4"
                    style={{ flexBasis: "70%" }}
                  >
                    <Label for="assignedphases2">
                      Assigned Phases{assignedPhaseTitle()}
                    </Label>
                    <CounterGroup
                      items={assignedphasesObj.filter((f) =>
                        f.phases.includes(numberOfPhases),
                      )}
                      balancedLoad={balancedLoad}
                      changeValue={(e, f, g, h) => mapPhases(e, f, g, h)}
                      setValue={() => mapAutoPhase()}
                      selectedValue={getAssignedPhasesObj(
                        childConsumer || consumer,
                      )}
                      count={consumer.count}
                      numberOfPhases={consumer.numberOfPhases}
                      handleBalancedLoad={handleBalancedLoad}
                    />
                    {clientSettings.Features
                      .ConsumerPhaseChangedWarningEnabled &&
                      resetAssignedPhasesWarning && (
                        <div className="text-warning py-1">
                          <FontAwesomeIcon icon={faInfoCircle} /> Updating the
                          consumer count has reset the assigned phases
                        </div>
                      )}
                  </div>
                  {numberOfPhases !== phase.SINGLE && (
                    <div
                      className="d-flex flex-column"
                      style={{ flexBasis: "30%" }}
                    >
                      <Label for="unbalancePercent">Unbalance (%)</Label>
                      <Input
                        type="number"
                        id="unbalancePercent"
                        name="unbalancePercent"
                        value={unbalancePercent}
                        onChange={handleChange}
                        disabled={balancedLoad}
                      />
                    </div>
                  )}
                </div>
                {numberOfPhases !== phase.SINGLE && !balancedLoad && (
                  <FormGroup>
                    <Label for="lowLoadedPhase">Low-loaded Phase</Label>
                    <RadioBtnGrp
                      items={lowLoadedPhasesObj}
                      changeValue={changeLowLoadedPhase}
                      selectedValue={lowLoadedPhase}
                    />
                  </FormGroup>
                )}
              </>
            )}
          </>
        ))
      ) : (
        <FormGroup>
          <Label for="assignedphases">
            Assigned Phases{assignedPhaseTitle()}
          </Label>
          <CheckboxBtnGrp
            items={assignedphasesObj.filter((f) =>
              f.phases.includes(numberOfPhases),
            )}
            balancedLoad={balancedLoad}
            changeValue={(e) => mapValues(e.field)}
            selectedValue={getAssignedPhasesValue(childConsumer || consumer)}
            handleBalancedLoad={handleBalancedLoad}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label for="consumerType">
          Load Profile
          {consumerTypeIsDefault && (
            <DataAlert updateDefault={() => handleDefault("consumerType")} />
          )}
        </Label>
        <Input
          type="select"
          name="consumerType"
          id="consumerType"
          value={consumerType}
          onChange={handleChange}
        >
          {formState.reference.consumers.types
            .filter((f) => f[getConsumerClass()])
            .map((type) => (
              <option key={type.name} value={type.name}>
                {type.name}
              </option>
            ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="consumptionType">
          Demand Type
          {consumptionTypeIsDefault && (
            <DataAlert updateDefault={() => handleDefault("consumptionType")} />
          )}
        </Label>
        <Input
          type="select"
          name="consumptionType"
          id="consumptionType"
          value={consumptionType}
          onChange={handleChange}
        >
          <option key="AnnualConsumption" value="AnnualConsumption">
            Annual Consumption
          </option>
          <option
            key="MaximumDemandDiversified"
            value="MaximumDemandDiversified"
          >
            Maximum Demand (Diversified)
          </option>
          <option key="MaximumDemand" value="MaximumDemand">
            Maximum Demand (Fixed)
          </option>
        </Input>
      </FormGroup>
      <FormGroup row>
        <Label for="consumptionValue1" sm={6}>
          {`Day (kW${
            consumptionType === "AnnualConsumption" ? "h" : ""
          } per customer)`}
          {consumptionValue1IsDefault && (
            <DataAlert
              updateDefault={() => handleDefault("consumptionValue1")}
            />
          )}
        </Label>
        <Col sm={6}>
          <Input
            type="number"
            id="consumptionValue1"
            name="consumptionValue1"
            value={consumptionValue1}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="consumptionValue2" sm={6}>
          {`Night (kW${
            consumptionType === "AnnualConsumption" ? "h" : ""
          } per customer)`}
          {consumptionValue2IsDefault && (
            <DataAlert
              updateDefault={() => handleDefault("consumptionValue2")}
            />
          )}
        </Label>
        <Col sm={6}>
          <Input
            type="number"
            id="consumptionValue2"
            name="consumptionValue2"
            value={consumptionValue2}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      {shouldShowCutOutSize() && clientSettings.Features.CostingEnabled && (
        <FormGroup>
          <Label for="cutOutSize">Cut-Out</Label>
          <Input
            type="select"
            name="cutOutSize"
            id="cutOutSize"
            value={cutOutSize}
            onChange={handleChange}
          >
            <option value="100A">100A</option>
            <option value="600A">600A</option>
          </Input>
        </FormGroup>
      )}
    </Form>
  );
};

export default ConsumerForm;
