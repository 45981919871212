import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { getAscDescSortItems } from "../utils/sortFunctions";
import sortIcon from "../images/sort.svg";
import { BLUE_TICK } from "../constants/colors";

const SortList = ({ customItems, sortFilteredStudyList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const ascDescItems = getAscDescSortItems();
  const [ascDescSortItems, setAscDescSortItems] = useState(ascDescItems);
  const [customSortItems, setCustomSortItems] = useState(customItems);

  useEffect(() => {
    setAscDescSortItems(ascDescItems);
    setCustomSortItems(customItems);
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value ? e.target.value : e.target.id;
    if (selectedValue === "ascending" || selectedValue === "descending") {
      const sortedItems = setSelectedSortItem(selectedValue, ascDescSortItems);
      const customSortValue = customSortItems.filter((c) => c.selected);
      sortFilteredStudyList(customSortValue[0].key, selectedValue);
      setAscDescSortItems(sortedItems);
    } else {
      const sortedItems = setSelectedSortItem(selectedValue, customSortItems);
      const ascDescSortValue = ascDescSortItems.filter((s) => s.selected);
      sortFilteredStudyList(selectedValue, ascDescSortValue[0].key);
      setCustomSortItems(sortedItems);
    }
  };

  const setSelectedSortItem = (selectedValue, menuItems) => {
    const sortedItems = menuItems.map((s) => {
      var menuItem = { ...s };
      menuItem.selected = s.key === selectedValue;
      return menuItem;
    });
    return sortedItems;
  };

  return (
    <>
      <div className="text-right" style={containerStyle}>
        <Dropdown isOpen={isOpen} toggle={toggle} size="md">
          <DropdownToggle
            color="dark"
            style={{
              fontSize: ".85rem",
              marginRight: 18,
              width: 40,
              border: isOpen ? `1px solid ${BLUE_TICK}` : null,
            }}
          >
            <div className="d-flex dark">
              <img
                src={sortIcon}
                alt=""
                style={{
                  height: 15,
                  width: 15,
                  marginTop: 4,
                }}
              />
            </div>
          </DropdownToggle>
          <DropdownMenu right style={menuDarkStyle}>
            {customSortItems &&
              customSortItems.map((item) => (
                <div key={item.key} id={item.key}>
                  <DropdownItem
                    value={item.key}
                    onClick={handleChange}
                    style={menuItemDarkStyle}
                  >
                    <i
                      id={item.key}
                      className="icon-check-solid mr-3"
                      style={{
                        color: item.selected ? BLUE_TICK : "transparent",
                      }}
                    ></i>
                    {item.value}
                  </DropdownItem>
                </div>
              ))}
            <DropdownItem divider style={menuDividerDarkStyle} />
            {ascDescSortItems &&
              ascDescSortItems.map((item) => (
                <div id={item.key} key={item.key}>
                  <DropdownItem
                    value={item.key}
                    onClick={handleChange}
                    style={menuItemDarkStyle}
                  >
                    <i
                      id={item.key}
                      className="icon-check-solid mr-3"
                      style={{
                        color: item.selected ? BLUE_TICK : "transparent",
                      }}
                    ></i>
                    {item.value}
                  </DropdownItem>
                </div>
              ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <hr className="bg-dark" />
    </>
  );
};

const containerStyle = {
  marginBottom: -31,
};

const menuDarkStyle = {
  background: "rgb(27,30,33)",
  borderRadius: 5,
};

const menuItemDarkStyle = {
  color: "white",
  marginLeft: -10,
  fontSize: 13,
  height: 25,
};

const menuDividerDarkStyle = {
  borderTop: "0.1rem solid gray",
  marginBottom: 2,
};

export default SortList;
