export const cableHasDistributedCustomer = (errors) => {
  var errorValues = Object.values(errors).flat().join();
  if (
    errorValues.startsWith(
      "Error converting value {null} to type 'System.Double'. Path 'cables["
    ) &&
    errorValues.includes(".groupedConnectionPoints[") &&
    errorValues.includes("].geometry")
  )
    return true;

  return false;
};
