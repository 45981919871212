import { get } from "axios";

const getBounds = (bounds) => {
  return {
    southLat: bounds.getSouth(),
    westLng: bounds.getWest(),
    northLat: bounds.getNorth(),
    eastLng: bounds.getEast(),
  };
};

export const getGeoJson = async (bounds, layer) => {
  const bb = getBounds(bounds);
  const response = await get(
    `${layer.url}&bbox=${bb.southLat},${bb.westLng},${bb.northLat},${bb.eastLng}`
  );
  const data = await response;
  return data;
};
