import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as buttonStatus from "../constants/buttonStatus";

const getColour = (status) => {
  switch (status) {
    case buttonStatus.RUNNING:
      return "warning";
    case buttonStatus.COMPLETED:
      return "success";
    case buttonStatus.FAILED:
      return "danger";
    default:
      return "info";
  }
};

const SecondaryButton = ({
  id,
  loadingText,
  failedText,
  completedText,
  disabled,
  status,
  onClick,
  icon,
}) => {
  return (
    <Button
      id={id}
      color={getColour(status)}
      size="sm"
      className="mr-2 d-flex align-items-center text-light"
      onClick={onClick}
      disabled={disabled || status === buttonStatus.RUNNING || status === buttonStatus.FAILED}
      title="Export Network"
    >
      {status === buttonStatus.RUNNING ? (
        <>
          <span className="mr-2">{loadingText}</span>
          <FontAwesomeIcon icon={faSpinner} spin fixedWidth />
        </>
      ) : status === buttonStatus.COMPLETED ? (
        <>
          <span className="mr-2">{completedText}</span>
          <FontAwesomeIcon icon={faCheck} fixedWidth />
        </>
        ) : status === buttonStatus.FAILED ? (
          <>
            <span className="mr-2">{failedText}</span>
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </>
      ) : (
        <FontAwesomeIcon icon={icon} fixedWidth />
      )}
    </Button>
  );
};

export default SecondaryButton;
