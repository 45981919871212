import React, { useState } from "react";
import { ButtonGroup, Button, Modal, ModalBody } from "reactstrap";

const WarningModal = ({
  item,
  msg,
  messages,
  action,
  dismissLabel = "No",
  yesLabel = "Yes",
  hideYesButton = false,
  dismissAction = false,
}) => {
  const [modal, setModal] = useState(true);

  const toggle = (value = false) => {
    setModal(!modal);
    action(value);
  };

  const refineMessages = () => {
    const codes = messages.map((o) => o.code);
    const duplicateCount = codes.reduce((a, c) => {
      a[c] = (a[c] || 0) + 1;
      return a;
    }, {});
    const deduplicatedMessages = messages.filter(
      ({ code }, index) => !codes.includes(code, index + 1)
    );
    return deduplicatedMessages.map((msg) => ({
      ...msg,
      count: duplicateCount[msg.code],
    }));
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
        <div className="bg-warning text-light">
          <ModalBody className="d-flex justify-content-between">
            <div>
              <span>{msg}</span>
              {messages && messages.length && (
                <div className="mt-2">
                  <ul>
                    {refineMessages().map((msg) => (
                      <li key={msg.code}>
                        {msg.description}
                        {msg.count > 1 ? ` x ${msg.count}` : ""}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <ButtonGroup style={{ marginLeft: 10, height: 35 }}>
              {!hideYesButton && (
                <Button
                  outline
                  color="light"
                  size="sm"
                  onClick={() => toggle(item)}
                  className="text-nowrap"
                >
                  {yesLabel}
                </Button>
              )}
              <Button
                outline
                color="light"
                size="sm"
                onClick={() => toggle(dismissAction && item)}
              >
                {dismissLabel}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default WarningModal;
