import * as alert from "../constants/alert";

const CODE_W105 = "W105";
const SYSTEM_FAULT_VOLTAGE = 260;

export const checkDesignVoltage = (t) => {
  let warning = {};

  if (t.designVoltage > SYSTEM_FAULT_VOLTAGE) {
    warning = {
      code: CODE_W105,
      level: alert.LEVEL_WARNING,
      link: t.id,
      description: `Design Voltage is greater than System Fault Voltage (${SYSTEM_FAULT_VOLTAGE}V).`,
    };
  }

  return warning;
};