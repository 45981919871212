import { useState, useContext } from "react";
import { DomEvent } from "leaflet";

import {
  Button,
  ButtonGroup,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Collapse,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faEraser,
  faMousePointer,
} from "@fortawesome/free-solid-svg-icons";

import { ToolContext } from "../context/ToolContext";

const NavMenu = ({ mode, activeCategory, selectItem }) => {
  const refContainer = (element) => {
    if (element) {
      DomEvent.disableClickPropagation(element).disableScrollPropagation(
        element
      );
    }
  };

  const { toolState } = useContext(ToolContext);
  const [cat, setCat] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const test = (category, categoryIndex) => {
    setCat({ cat2: category, categoryIndex: categoryIndex });
    !isOpen && setIsOpen(!isOpen);
    cat && cat.cat2 === category && setIsOpen(!isOpen);
  };

  const test2 = (thing, categoryIndex, tool, itemIndex) => {
    setIsOpen(!isOpen);
    selectItem(thing, categoryIndex, tool, itemIndex);
  };

  const { toolbar, selectedTool } = toolState;

  return (
    <div ref={refContainer} className="d-block d-sm-none">
      <Navbar color="dark" dark expand="md" fixed="bottom" className="p-0">
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            {cat &&
              cat.cat2.items.map((tool, i) => (
                <NavItem
                  key={tool.name}
                  onClick={() => test2("draw", cat.categoryIndex, tool, i)}
                >
                  <NavLink>
                    <i className={`icon-${tool.class}`}></i> {tool.name}
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
        </Collapse>
        <form className="form-inline w-100">
          <ButtonGroup className="w-100">
            <ButtonGroup vertical className="w-100">
              <Button
                className="mt-4"
                color="dark"
                active={mode === "select"}
                onClick={() => selectItem("", "", "")}
              >
                <FontAwesomeIcon icon={faMousePointer} />
              </Button>
            </ButtonGroup>
            <ButtonGroup vertical className="w-100">
              <Button
                className="mt-4"
                color="dark"
                active={mode === "remove"}
                onClick={() => selectItem("", "", "")}
              >
                <FontAwesomeIcon icon={faEraser} />
              </Button>
            </ButtonGroup>
            {toolbar.map((category, categoryIndex) => (
              <ButtonGroup vertical className="w-100" key={categoryIndex}>
                <Button
                  color="dark"
                  onClick={() => test(category, categoryIndex)}
                  style={{ padding: 0 }}
                >
                  <FontAwesomeIcon icon={faCaretUp} />
                </Button>
                <Button
                  id="caret"
                  active={activeCategory === categoryIndex}
                  color="dark"
                  onClick={() => selectItem(categoryIndex)}
                >
                  <i
                    className={`icon-${
                      category.items[selectedTool[categoryIndex]].class
                    }`}
                  ></i>
                </Button>
              </ButtonGroup>
            ))}
          </ButtonGroup>
        </form>
      </Navbar>
    </div>
  );
};

export default NavMenu;
