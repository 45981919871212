import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { formatLength } from "../utils/numberFunctions";
import WarningModal from "./WarningModal";
import { updateCable as updateReduxCable } from "../app/networkSlice";

const GroundTypeOverrideSelector = ({
  overrideLength,
  additionalLength,
  removePoleTermLength,
}) => {
  const { formState, dispatch } = useContext(FormContext);
  const { toolState } = useContext(ToolContext);
  const { clickedAsset } = toolState;
  const { clientSettings, cableProperties, reference } = formState;
  const { length, groundTypeOverrides } = cableProperties;
  const poleTermLengthEnabled = clientSettings.Features.PoleTermLengthEnabled;
  const [focusedInput, setFocusedInput] = useState(null);
  const [emptyInput, setEmptyInput] = useState(-1);
  const [groundType, setGroundType] = useState(null);
  const [isRemovingPoleTermLength, setIsRemovingPoleTermLength] =
    useState(false);

  const dispatchRedux = useDispatch();

  const unexcavatedName = "Unexcavated (pole term)";
  const unexcavatedValue = "Unexcavated";

  useEffect(() => {
    if (!groundTypeOverrides || !groundTypeOverrides.length) {
      const newValue = [
        {
          groundType: reference.groundTypeOverrideDefaults.groundTypeOverride,
          addApplied: false,
          length: (typeof length === "number"
            ? length
            : parseFloat(length)
          ).toFixed(2),
        },
      ];

      updateCable(newValue);
    }
  }, []);

  const fixedGroundTypeOverrides = () => {
    return groundTypeOverrides?.map((m) => ({
      ...m,
      fixedLength: parseFloat(m.length).toFixed(2),
    }));
  };

  const getSelectList = (currentSelection) => {
    const groundTypes = reference.groundTypeOverrides?.split(",").map((x) => {
      return { name: x, value: x };
    });

    groundTypes.push({ name: unexcavatedName, value: unexcavatedValue });

    const alreadySelectedGroundTypes = groundTypeOverrides?.map((x) => {
      return x.groundType;
    });

    const filteredGroundTypes = groundTypes.filter(
      (x) =>
        !alreadySelectedGroundTypes ||
        !alreadySelectedGroundTypes.includes(x.value),
    );

    if (currentSelection) {
      currentSelection = groundTypes.find((x) => x.value === currentSelection);

      return [currentSelection, ...filteredGroundTypes];
    }

    return filteredGroundTypes;
  };

  const toggleAddApplied = (e, index) => {
    const update = [
      ...groundTypeOverrides.map((p, i) =>
        i === index ? { ...p, addApplied: !p.addApplied } : p,
      ),
    ];
    updateCable(update);
  };

  const onButtonClick = (isFirstOverride, override) => {
    let newValue = [];
    if (isFirstOverride) {
      const selectList = getSelectList();
      const groundTypeOverridesLength =
        groundTypeOverrides
          ?.map((g) => g.length)
          ?.reduce((l1, l2) => parseFloat(l1 ?? 0) + parseFloat(l2 ?? 0), 0) ??
        0;

      let newLength = overrideLength - groundTypeOverridesLength;
      if (newLength < 0 || isNaN(newLength)) {
        newLength = 0;
      }

      newValue = [
        ...groundTypeOverrides,
        {
          groundType: selectList[0].value,
          length: parseFloat(newLength.toFixed(2)),
        },
      ];
    } else {
      newValue = groundTypeOverrides.filter(
        (x) =>
          x.length !== override.length && x.groundType !== override.groundType,
      );
    }

    updateCable(newValue);
  };

  const confirmRemovePoleTermLength = (e) => {
    if (e) {
      removePoleTermLength();
    }
    setIsRemovingPoleTermLength(false);
  };
  const removePoleTermLengthConfirmation = () => {
    setIsRemovingPoleTermLength(true);
  };

  const updateGroundType = (e, index) => {
    const value = e.target.value;
    const update = [
      ...groundTypeOverrides.map((p, i) =>
        i === index ? { ...p, groundType: value } : p,
      ),
    ];
    updateCable(update);
    setGroundType(value);
  };

  const updateLength = (e, index) => {
    const newLength = e.target.value === "" ? 0 : +e.target.value;
    const update = [
      ...groundTypeOverrides.map((p, i) =>
        i === index ? { ...p, length: newLength } : p,
      ),
    ];
    updateCable(update);
    setEmptyInput(e.target.value === "" ? index : -1);
  };

  const updateCable = (value) => {
    dispatch({
      form: "cableProperties",
      field: "groundTypeOverrides",
      value: value,
      type: "UPDATE_FIELD",
    });
    dispatchRedux(
      updateReduxCable({
        id: clickedAsset.id,
        name: "groundTypeOverrides",
        value,
      }),
    );
  };

  return (
    <>
      {isRemovingPoleTermLength && (
        <WarningModal
          item={true}
          action={confirmRemovePoleTermLength}
          msg={
            "Deleting this ground type will set Pole Term Length to 0.  Press OK to continue"
          }
          dismissLabel="Cancel"
          yesLabel="OK"
        />
      )}
      <div
        className="bg-white-alpha"
        style={{
          padding: ".75rem",
          margin: ".75rem 0",
        }}
      >
        <div className="d-flex mt-1 pl-1">
          <h6
            className="text-light"
            style={{
              width: "80%",
              fontSize: ".75rem",
              textTransform: "uppercase",
            }}
          >
            Ground Type Override
          </h6>
          <h6
            className="text-light"
            style={{
              width: "20%",
              fontSize: ".75rem",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            ADD Applied
          </h6>
          <Button
            size="sm"
            color="success"
            className="ml-1"
            style={{ width: "2rem" }}
            onClick={() => onButtonClick(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
        <div>
          <>
            {additionalLength > 0 && poleTermLengthEnabled && (
              <div key="pole-term" className="d-flex mt-1 pl-1">
                <Input
                  type="select"
                  name="groundType"
                  style={{ flex: 3 }}
                  defaultValue={unexcavatedValue}
                >
                  <option key={unexcavatedValue} value={unexcavatedValue}>
                    {unexcavatedName}
                  </option>
                </Input>
                <Input
                  name="length"
                  className="mx-1 no-spinner"
                  style={{ flex: 1 }}
                  placeholder="0"
                  value={formatLength(additionalLength)}
                  disabled
                />
                <Button
                  size="sm"
                  className="ml-2 mr-2 btn btn-dark btn-sm"
                  style={{ width: "2rem" }}
                  disabled
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  className="ml-1"
                  style={{ width: "2rem" }}
                  onClick={() => removePoleTermLengthConfirmation()}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              </div>
            )}
            {fixedGroundTypeOverrides() &&
              fixedGroundTypeOverrides().length !== 0 &&
              fixedGroundTypeOverrides().map((override, index) => (
                <div key={override.groundType} className="d-flex mt-1 pl-1">
                  <Input
                    type="select"
                    name="groundType"
                    style={{ flex: 3 }}
                    value={groundType || override.groundType}
                    onChange={(e) => updateGroundType(e, index)}
                  >
                    {getSelectList(override.groundType).map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                  <Input
                    type="number"
                    name="length"
                    className="mx-1 no-spinner"
                    style={{ flex: 1 }}
                    placeholder="0"
                    value={
                      emptyInput === index
                        ? ""
                        : focusedInput === index
                        ? override.length
                        : override.fixedLength
                    }
                    onChange={(e) => updateLength(e, index)}
                    onFocus={() => setFocusedInput(index)}
                    onBlur={() => {
                      setFocusedInput(null);
                      setEmptyInput(-1);
                    }}
                  />
                  <Button
                    size="sm"
                    className="ml-2 mr-2 btn btn-dark btn-sm"
                    style={{ width: "2rem" }}
                    onClick={(e) => toggleAddApplied(e, index)}
                  >
                    <FontAwesomeIcon
                      icon={override.addApplied ? faCheck : faTimes}
                    />
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    className="ml-1"
                    style={{ width: "2rem" }}
                    onClick={() => onButtonClick(false, override)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                </div>
              ))}
          </>
        </div>
      </div>
    </>
  );
};

export default GroundTypeOverrideSelector;
