import { List } from "react-virtualized";
import styled from "styled-components";

export const SpanEllipse = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ToggleMessage = styled.div`
  display: flex;
  gap: 4px;
  padding: 0.5rem 0.25rem 0 0.25rem;
  transition: 250ms;

  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: translateX(${({ show }) => (show ? 0 : -25)}%);
  grid-column: 1/-1;
  justify-content: end;
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0 1.4rem;
  justify-content: space-between;
`;

export const Grid = styled.div`
  ${({ noPadding }) => (noPadding ? "padding: 0;" : "padding: 0.5rem 0.7rem;")}
  display: grid;
  ${({ gap = 0 }) => `gap: ${gap / 16}rem`};
  grid-template-columns: 135px 1fr;
`;

export const Flex = styled.div`
  padding: ${({ padding = [0] }) =>
    padding.map((pad) => `${pad / 16}rem`).join(" ")};
  display: flex;
  ${({ gap = 0 }) => `gap: ${gap / 16}rem`};
  ${({ align = false }) =>
    `align-items: ${align === true ? "center" : align};`};
  ${({ justify = false }) =>
    `justify-content: ${justify === true ? "center" : justify};`};
`;

export const SmartSpan = styled.span`
  background-color: ${({ h }) => (h ? "#1c9d5b" : "transparent")};
  color: ${({ h }) =>
    h ? "rgba(255, 255, 255, 0.75)" : "rgba(255, 255, 255, 0.5)"};
`;

export const InfoMessage = styled.span`
  color: ${({ h }) =>
    h ? "rgba(255, 255, 255, 0.75)" : "rgba(255, 255, 255, 0.5)"};
`;

export const SearchFile = styled.div`
  display: flex;
  gap: 0;
  flex-direction: column;
`;

export const ListVirtualised = styled(List)`
  && {
    a {
      padding: 0.125rem 0 !important;
    }
  }
`;
