import { MotorProperties } from "../model/viewModel/motorProperties";

export const getMotorProperties = (
  rating,
  ratingIsDefault,
  ratingUnit,
  numberOfPhases,
  numberOfPhasesIsDefault,
  starter,
  starterIsDefault,
  startingPowerFactor,
  startingPowerFactorIsDefault,
  startingCurrentOverride,
  numberOfStarts,
  numberOfStartsIsDefault,
  numberOfStartsUnit,
  efficiency,
  efficiencyIsDefault,
  hvFaultLevel,
  xrRatio,
  geometry,
  annotation,
  styles
) => {
  let motorProperties: MotorProperties = new MotorProperties();
  motorProperties.rating = rating;
  motorProperties.ratingIsDefault = ratingIsDefault;
  motorProperties.ratingUnit = ratingUnit;
  motorProperties.numberOfPhases = numberOfPhases;
  motorProperties.numberOfPhasesIsDefault = numberOfPhasesIsDefault;
  motorProperties.starter = starter;
  motorProperties.starterIsDefault = starterIsDefault;
  motorProperties.startingPowerFactor = startingPowerFactor;
  motorProperties.startingPowerFactorIsDefault = startingPowerFactorIsDefault;
  motorProperties.startingCurrentOverride = startingCurrentOverride;
  motorProperties.numberOfStarts = numberOfStarts;
  motorProperties.numberOfStartsIsDefault = numberOfStartsIsDefault;
  motorProperties.numberOfStartsUnit = numberOfStartsUnit;
  motorProperties.efficiency = efficiency;
  motorProperties.efficiencyIsDefault = efficiencyIsDefault;
  motorProperties.hvFaultLevel = hvFaultLevel;
  motorProperties.xrRatio = xrRatio;
  motorProperties.geometry = geometry;
  motorProperties.annotation = annotation;
  motorProperties.styles = styles;
  return motorProperties;
};
