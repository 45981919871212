import React, { useContext, useState, useEffect, useMemo } from "react";
import L, { LatLng } from "leaflet";
import along from "@turf/along";
import length from "@turf/length";
import { lineString } from "@turf/helpers";
import ReactDOMServer from "react-dom/server";
import { Pane, Marker, Polyline, useMap } from "react-leaflet";
import TextPath from "react-leaflet-textpath";

import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { iconMarker } from "../utils/iconMarker";
import {
  doesAssetHaveStatus,
  doesAssetHaveNestedStatus,
} from "../utils/networkFunctions";
import { parseResults } from "../utils/parseResults";
import { checkDefaults } from "../utils/checkDefaults";
import * as status from "../constants/status";

import "./Asset.css";
import CounterBadge from "./CounterBadge";
import LctBadge from "./LctBadge";
import * as color from "../constants/colors";
import { useSelector } from "react-redux";
import { getAllGeometryAssets } from "../app/networkSlice";
import { calculateDistance, insertPointOnPath } from "../utils/lineFunctions";

const Asset = ({ asset, highlighted, onClick, label, onMove, reshapeCablePoints }) => {
  const { toolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);
  const [errorMatch, setErrorMatch] = useState(false);
  const [warningMatch, setWarningMatch] = useState(false);
  const [lctIsEnabled, setLctIsEnabled] = useState(false);
  const [offsetMarkers, setOffsetMarkers] = useState([]);
  const [offsetCables, setOffsetCables] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const cables = useSelector((state) => state.network.present.cables);
  const transformers = useSelector((state) => state.network.present.transformers);
  const allGeometryAssets = useSelector((state) => getAllGeometryAssets(state));

  const { reference, clientSettings, groupedConnectionProperties } = formState;
  const {
    errors,
    mode,
    activeTool,
    ringfenced,
    ringfencedFiltered,
    clickedAsset,
    matchedSearchIds,
  } = toolState;

  const canMoveObjects = () => {
    if (!clientSettings.Features.EditModeEnabled) {
      return false;
    }
    if (["select", "re-shape"].includes(mode) && !!onMove) {
      return true;
    }
    if (mode === "select-group" && ringfenced.some(p => p.id === asset.id)) {
      return true;
    }
    return false;
  };

  const cableLabels = useSelector((state) => state.settings.showCableLabels);
  const cableLabelIds = useSelector(
    (state) => state.settings.showCableLabelIds,
  );
  const colourBlindSettingEnabled = useSelector(
    (state) => state.settings.isColorBlindEnabled,
  );
  const colourBlindEnabled =
    clientSettings.Features.ColourBlindEnabled && colourBlindSettingEnabled;
  const costingSettingStyling = useSelector(
    (state) => state.settings.isCostingStylingEnabled,
  );
  const costingStyling =
    clientSettings.Features.CostingEnabled &&
    clientSettings.Features.CostingStylingEnabled &&
    costingSettingStyling;
  const iconSize = useSelector((state) => state.settings.iconSize);
  const lctIndicatorEnabled = useSelector(
    (state) => state.settings.isLCTIndicatorEnabled,
  );

  const cableZoomTrigger = parseInt(clientSettings.General.CableZoomTrigger);
  const cableZoomWeightMultiplier = parseInt(
    clientSettings.General.CableZoomWeightMultiplier,
  );

  const ringfencedIds = ringfencedFiltered.map((asset) => asset.id);

  let results = parseResults(
    asset,
    reference,
    cables,
    clickedAsset,
    groupedConnectionProperties,
  );

  const map = useMap();

  const lctMap = [
    { name: "Generator", position: [-20, 0], type: "generator" },
    { name: "EV Chargepoint", position: [-5, -10], type: "evChargePoint" },
    { name: "Heat Pump", position: [10, -0], type: "heatPump" },
  ];

  const validElements = ["heatPump", "generator", "evChargePoint"];

  useEffect(() => {
    const erroredAssetIds = errors.messages
      ? errors.messages
          .filter((message) => message.level === "Error")
          .map((message) => message.link)
      : [];

    const warningAssetIds = errors.messages
      ? errors.messages
          .filter((message) => message.level === "Warning")
          .map((message) => message.link)
      : [];

    if (
      asset.groupedConnectionPoints &&
      asset.groupedConnectionPoints.length > 0
    ) {
      setWarningMatch(
        asset.groupedConnectionPoints.find((a) =>
          warningAssetIds.includes(a.id),
        )
          ? true
          : warningAssetIds.includes(asset.id),
      );
      setErrorMatch(
        asset.groupedConnectionPoints.find((a) =>
          erroredAssetIds.includes(a.id),
        )
          ? true
          : erroredAssetIds.includes(asset.id),
      );
    } else {
      setWarningMatch(warningAssetIds.includes(asset.id));
      setErrorMatch(erroredAssetIds.includes(asset.id));
    }
  }, [errors]);

  useEffect(() => {
    asset?.groupedConnectionPoints?.[0]?.subGroupConnectionPoints.some(
      (element) => {
        return validElements.includes(element.styles.class);
      },
    ) &&
    iconSize === 3 &&
    clientSettings.Features.LCTIndicatorEnabled &&
    lctIndicatorEnabled &&
    asset?.groupedConnectionPoints?.[0]?.subGroupConnectionPoints?.length > 0
      ? setLctIsEnabled(true)
      : setLctIsEnabled(false);
  }, [
    iconSize,
    clientSettings,
    lctIndicatorEnabled,
    asset?.groupedConnectionPoints?.[0]?.subGroupConnectionPoints?.length,
    asset,
  ]);

  const resultMarker = (result) => {
    const icon = L.divIcon({
      className: "resultMarker",
      html: ReactDOMServer.renderToString(
        <div className={`badge bg-dark text-${result.class} p-0`}>
          {result.value && (
            <span
              id={asset.id + result.category}
              className={result.value}
              style={{
                display: "inline-block",
                fontSize: ".875rem",
                padding: ".125rem .25rem",
              }}
            >
              {result.value}
            </span>
          )}
          {result.unit && (
            <span
              style={{
                fontWeight: 300,
                fontSize: ".625rem",
                paddingRight: ".25rem",
              }}
            >
              {result.unit}
            </span>
          )}
          {result.constraint && (
            <span
              className="bg-danger text-white"
              style={{
                display: "inline-block",
                fontSize: ".875rem",
                padding: ".125rem .25rem",
              }}
            >
              !
            </span>
          )}
        </div>,
      ),
    });
    return icon;
  };

  const position = (i, arr) => {
    const _iconSize = asset.styles.name === "Node" ? 12 : iconSize * 12;
    let geometry;
    if (Array.isArray(asset.geometry)) {
      const line = lineString(asset.geometry.map((a) => [a.lat, a.lng]));
      const options = { units: "kilometers" };
      const lineLength = length(line, options);
      const halfwayPoint = along(line, lineLength / 2, options);
      geometry = halfwayPoint.geometry.coordinates;
    } else {
      geometry = asset.geometry;
    }

    const point = map.latLngToContainerPoint(geometry);

    const newPoint = L.point([
      point.x + (asset.styles.tool === "Marker" ? _iconSize : 0),
      point.y + (32 * i - 32 * (i / 2) - (32 / 4) * arr.length),
    ]);

    return map.containerPointToLatLng(newPoint);
  };

  const defaultChecker = (asset) => {
    let hasDefaults = false;
    if (asset.groupedConnectionPoints && asset.groupedConnectionPoints.length) {
      asset.groupedConnectionPoints.forEach((gcp) => {
        if (checkDefaults(gcp)) {
          hasDefaults = true;
          return;
        }
      });
    } else {
      hasDefaults = checkDefaults(asset);
    }
    return hasDefaults;
  };

  const getLineColour = (cable, defaultColour) => {
    if (costingStyling && cable.status === status.NEW) {
      return colourBlindEnabled
        ? clientSettings.General.CostingStylingNewAssetColourBlindColour
        : clientSettings.General.CostingStylingNewAssetColour;
    } else if (costingStyling && cable.status === status.REPLACEMENT) {
      return colourBlindEnabled
        ? clientSettings.General.CostingStylingReplacementAssetColourBlindColour
        : clientSettings.General.CostingStylingReplacementAssetColour;
    }
    return defaultColour;
  };

  const getMarkerColour = (asset, defaultColour) => {
    if (costingStyling) {
      if (
        asset.groupedConnectionPoints &&
        asset.groupedConnectionPoints.length > 0
      ) {
        if (
          asset.groupedConnectionPoints.some(
            (p) =>
              p.status === status.NEW ||
              doesAssetHaveNestedStatus(p, status.NEW),
          )
        ) {
          return colourBlindEnabled
            ? "newColourblindMarkerColour"
            : "newMarkerColour";
        } else if (
          asset.groupedConnectionPoints.some(
            (p) =>
              p.status === status.REPLACEMENT ||
              doesAssetHaveNestedStatus(p, status.REPLACEMENT),
          )
        ) {
          return colourBlindEnabled
            ? "replacementColourblindMarkerColour"
            : "replacementMarkerColour";
        }
      } else if (
        asset.styles.name === "Node" ||
        (asset.styles.name === "Grouped Connection" &&
          asset.groupedConnectionPoints?.length === 0)
      ) {
        if (doesAssetHaveStatus(asset, cables, status.NEW)) {
          return colourBlindEnabled
            ? "newColourblindMarkerColour"
            : "newMarkerColour";
        } else if (doesAssetHaveStatus(asset, cables, status.REPLACEMENT)) {
          return colourBlindEnabled
            ? "replacementColourblindMarkerColour"
            : "replacementMarkerColour";
        }
      }
    }
    return defaultColour;
  };

  const styleIcon = (asset) => {
    const hasDefaults = defaultChecker(asset);
    if (asset.styles.tool === "Line") {
      return highlighted || matchedSearchIds.includes(asset.id)
        ? color.BLUE_HIGHLIGHT
        : errorMatch
        ? "rgba(229, 30, 61, 1)"
        : warningMatch
        ? "rgba(250,183,33,1)"
        : hasDefaults
        ? "rgba(250,183,33,1)"
        : ringfencedIds.includes(asset.id)
        ? "rgba(38, 183, 232, 1)"
        : getLineColour(asset, asset.styles.color);
    }
    if (asset.styles.tool === "Marker") {
      const cssClass =
        asset.styles.type !== "transformer"
          ? asset.groupedConnectionPoints
            ? asset.groupedConnectionPoints.length < 1
              ? asset.pole && asset.pole.enabled
                ? "circle-dot"
                : asset.linkBox &&
                  clientSettings.Features.ConfigurableLinkBoxEnabled
                ? "square"
                : "node"
              : asset.groupedConnectionPoints.length === 1
              ? asset.groupedConnectionPoints[0].styles.class
              : "connection"
            : asset.styles.class
          : asset.styles.class;

      const circleMarker =
        asset.styles.type !== "transformer"
          ? asset.groupedConnectionPoints
            ? asset.groupedConnectionPoints.length
            : asset.styles.circleMarker
          : asset.styles.circleMarker;

      const bgColor =
        highlighted || matchedSearchIds.includes(asset.id)
          ? "primary"
          : errorMatch
          ? "danger"
          : warningMatch
          ? "warning"
          : hasDefaults
          ? "warning"
          : ringfencedIds.includes(asset.id)
          ? "primary"
          : getMarkerColour(asset, "dark");

      asset = {
        ...asset,
        styles: {
          ...asset.styles,
          cssClass: cssClass,
          circleMarker: circleMarker,
          bgColor: bgColor,
        },
      };

      let nodeZoomMultiplier =
        map.getZoom() > cableZoomTrigger ? cableZoomWeightMultiplier : 1;

      const id =
        asset.styles.type === "transformer" ? createTransformerId() : "";

      return iconMarker(
        asset,
        mode,
        activeTool,
        iconSize,
        nodeZoomMultiplier,
        true,
        lctIsEnabled,
        colourBlindEnabled,
        costingStyling,
        groupedConnectionProperties,
        id,
      );
    }
  };
  const createTransformerId = () => {
    const txId = transformers.findIndex((t) => t.id === asset.id);
    return asset.annotation || asset.substationId || `Transformer ${txId + 1}`;
  };

  const countGroupedConnections = (asset) => {
    if (!asset.groupedConnectionPoints || !asset.groupedConnectionPoints.length)
      return;
    return asset.groupedConnectionPoints.length > 1
      ? asset.groupedConnectionPoints.reduce((a, c) => a + Number(c.count), 0)
      : asset.groupedConnectionPoints[0].count;
  };

  const groupedConnectionsCount = countGroupedConnections(asset);

  const textPathText = (asset) => {
    const cableType = `${
      asset.autoSelect && asset.cableTypeAssigned !== undefined
        ? `${
            asset.cableTypeAssigned !== null ? asset.cableTypeAssigned : "AUTO"
          } - ${
            asset.overrideLength > 0
              ? asset.overrideLength
              : asset.length && Number(asset.length).toFixed(2)
          }m`
        : `${asset.cableType !== null ? asset.cableType : "AUTO"} - ${
            asset.overrideLength > 0
              ? asset.overrideLength
              : asset.length && Number(asset.length).toFixed(2)
          }m`
    } - `;

    return cableLabelIds ? `${asset.id} - ${cableType}` : `${cableType}`;
  };

  const zoomFilterResults = (result) => {
    const assetFilter = ["underground-cable", "overground-line", "node"];
    const zoomFilter = assetFilter.includes(asset.styles.class) ? 19 : 14;

    if (result.name === "Labels") {
      return true;
    } else {
      return map.getZoom() > zoomFilter;
    }
  };

  const cableWeight = (asset) => {
    let weight = 2;

    if (!asset || !asset.cableGroup) return weight;

    if (
      asset.cableGroup === "mains-underground" ||
      asset.cableGroup === "mains-overhead"
    ) {
      weight = 4;
    }

    if (map.getZoom() > cableZoomTrigger) {
      weight = weight * cableZoomWeightMultiplier;
    }

    return weight;
  };

  const cableDash = (
    asset,
    defaultDashLength,
    defaultSpacingLength,
    cableType,
  ) => {
    let dash = "";

    if (
      !asset ||
      !asset.cableGroup ||
      !defaultDashLength ||
      !defaultSpacingLength ||
      !cableType
    )
      return dash;

    let dashLength = defaultDashLength;
    let spacingLength = defaultSpacingLength;

    if (map.getZoom() > cableZoomTrigger) {
      dashLength = dashLength * cableZoomWeightMultiplier;
      spacingLength = spacingLength * cableZoomWeightMultiplier;
    }

    if (cableType === "label" && asset.cableGroup.endsWith("underground")) {
      return `${dashLength} ${spacingLength}`;
    }

    if (cableType === "line" && asset.cableGroup.endsWith("underground")) {
      return `${dashLength} ${spacingLength}`;
    }

    if (
      cableType === "grouped-connection-points" &&
      (asset.cableGroup === "mains-overhead" ||
        asset.cableGroup === "mains-underground")
    ) {
      return `${dashLength} ${spacingLength}`;
    }

    return dash;
  };

  const textPathOffset = (asset, defaultOffset) => {
    if (!asset) return defaultOffset;

    let offset = defaultOffset;

    if (map.getZoom() > cableZoomTrigger) {
      offset = offset + cableZoomWeightMultiplier * 2;
    }

    return offset;
  };

  const textPathFontSize = (asset, defaultFontSize) => {
    if (!asset) return defaultFontSize;

    let fontSize = defaultFontSize;

    if (map.getZoom() > cableZoomTrigger) {
      fontSize = fontSize + cableZoomWeightMultiplier;
    }

    return fontSize;
  };

  const subGroupConnectionPoints = (asset, lctType) => {
    let yo = 0;
    asset?.groupedConnectionPoints?.forEach((gcp) => {
      if (gcp?.subGroupConnectionPoints)
        yo += gcp?.subGroupConnectionPoints?.length;
    });
    return yo;
  };

  const hasLcts = (asset) => {
    return lctMap.some((lct) => hasLct(asset, lct.name));
  };

  const hasLct = (asset, lctType) => {
    return (
      asset.groupedConnectionPoints &&
      asset.groupedConnectionPoints.find((gcp) =>
        gcp?.subGroupConnectionPoints.some(
          (sgcp) => sgcp.styles.name === lctType,
        ),
      )
    );
  };

  const handleDrag = (event, movingAsset) => {
    const markerId = movingAsset.id;
    const { lat, lng } = event.target.getLatLng();

    const assets = ringfenced;

    const offsetMarkers = assets
      .filter((asset) => asset.id !== markerId && !(asset.geometry.length))
      .map((asset) => {
        const geometry = allGeometryAssets.find(p => p.asset.id === asset.id).geometry;
        const offsetLat = geometry.lat + (lat - event.oldLatLng.lat);
        const offsetLng = geometry.lng + (lng - event.oldLatLng.lng);
        return {
          id: asset.id,
          icon: styleIcon(asset),
          position: new LatLng(offsetLat, offsetLng)
        };
      });

    const offsetCables = assets
      .filter((asset) => asset.id !== markerId && asset.geometry.length > 0)
      .map((asset) => {
        const geometry = allGeometryAssets.find(p => p.asset.id === asset.id).geometry;
        return {
          id: asset.id,
          position: geometry.map(p => {
            return { lat: p.lat + (lat - event.oldLatLng.lat), lng: p.lng + (lng - event.oldLatLng.lng) };
          })
        };
      });

    const indirectReshapeCables = allGeometryAssets
      .filter((cable) => [cable.asset.startAssetId, cable.asset.endAssetId].includes(markerId))
      .filter((cable) =>
        !(assets.some(p => p.id === cable.asset.startAssetId) && assets.some(p => p.id === cable.asset.endAssetId))
      )
      .map((cable) => {
        const start = cable.geometry[0];
        const end = cable.geometry[cable.geometry.length - 1];
        const anchors = reshapeCablePoints.filter(p => p.assetId === cable.asset.id).map(p => p.position);

        if (anchors.length === 0) {
          if (calculateDistance(start, event.oldLatLng) < calculateDistance(end, event.oldLatLng)) {
            return {
              id: cable.asset.id,
              position: [{ lat, lng }, end]
            };
          }
          return {
            id: cable.asset.id,
            position: [start, { lat, lng }]
          };
        }
        if (calculateDistance(start, event.oldLatLng) < calculateDistance(end, event.oldLatLng)) {
          return {
            id: cable.asset.id,
            position: insertPointOnPath(cable.geometry, anchors, start, { lat, lng })
          };
        }
        return {
          id: cable.asset.id,
          position: insertPointOnPath(cable.geometry, anchors, end, { lat, lng })
        };
      });

    setOffsetMarkers(offsetMarkers);
    setOffsetCables(offsetCables.concat(indirectReshapeCables));
    setIsDragging(true);
  };

  const dragEnd = (e, asset) => {
    setOffsetMarkers([]);
    setOffsetCables([]);
    setIsDragging(false);
    onMove(e, asset)
  };

  const icon = useMemo(() => styleIcon(asset), [asset, highlighted, ringfencedFiltered, matchedSearchIds, iconSize, errorMatch, warningMatch, lctIsEnabled, colourBlindEnabled, costingStyling]);

  return (
    <>
      {asset.styles.tool === "Line" && (
        <>
          {cableLabels ? (
            <TextPath
              key={asset.id}
              id={asset.id}
              positions={asset.geometry}
              color={styleIcon(asset)}
              weight={cableWeight(asset)}
              dashArray={cableDash(asset, 4, 6, "label")}
              snapIgnore={true}
              text={mode === "remove" ? null : textPathText(asset)}
              repeat
              below
              center
              offset={textPathOffset(asset, 14)}
              attributes={{
                "font-size": textPathFontSize(asset, 10),
              }}
              styles={{}}
              eventHandlers={{
                click: (e) => {
                  onClick(asset, e);
                },
              }}
            />
          ) : (
            <Pane name={`network-cables${asset.id}`} style={{ zIndex: 596 }}>
              <Polyline
                key={asset.id}
                id={asset.id}
                positions={asset.geometry}
                snapIgnore={true}
                pathOptions={{
                  weight: cableWeight(asset),
                  dashArray: cableDash(asset, 4, 6, "line"),
                  color: styleIcon(asset),
                }}
                eventHandlers={{
                  click: (e) => {
                    onClick(asset, e);
                  },
                }}
              />
            </Pane>
          )}
          {asset.groupedConnectionPoints.length > 0 && (
            <Pane
              name={`grouped-connection-cables${asset.id}`}
              style={{ zIndex: 597 }}
            >
              <Polyline
                id={asset.id}
                positions={asset.geometry}
                pathOptions={{
                  weight: cableWeight(asset),
                  dashArray: cableDash(
                    asset,
                    4,
                    8,
                    "grouped-connection-points",
                  ),
                  color: "#ffffff",
                }}
                snapIgnore={true}
                eventHandlers={{
                  click: (e) => {
                    onClick(asset, e);
                  },
                }}
              />
            </Pane>
          )}
        </>
      )}
      {asset.styles.tool === "Marker" && (
        <>
          <Marker
            id={asset.id}
            position={asset.geometry}
            icon={icon}
            draggable={canMoveObjects()}
            eventHandlers={{
              click: () => {
                onClick(asset);
              },
              drag: (e) => handleDrag(e, asset),
              dragend: (e) => dragEnd(e, asset),
            }}
          />
          {asset.groupedConnectionPoints && groupedConnectionsCount > 1 && !isDragging && (
            <CounterBadge
              asset={asset}
              value={groupedConnectionsCount}
              position={[20, 20]}
              popup={true}
            />
          )}
          {!isDragging && lctIsEnabled && (
            <>
              {!hasLcts(asset) && (
                <>
                  <CounterBadge
                    asset={asset}
                    value={subGroupConnectionPoints(asset)}
                    position={[20, 20]}
                    popup={false}
                  />
                </>
              )}
              {lctMap.map((lct) => (
                <React.Fragment key={lct.name}>
                  {hasLct(asset, lct.name) && (
                    <>
                      <LctBadge
                        asset={asset}
                        value={groupedConnectionsCount}
                        position={lct.position}
                        lctType={lct.type}
                        onClick={onClick}
                      />
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {!isDragging && label && (
            <>
              <CounterBadge
                asset={asset}
                label={label}
                position={[20, 20]}
                popup={true}
              />
            </>
          )}
        </>
      )}
      {!isDragging && results
        .filter((f) => zoomFilterResults(f) && !f.hide)
        .map((result, i, arr) => (
          <Marker
            key={i}
            position={position(i, arr)}
            icon={resultMarker(result)}
            eventHandlers={{
              click: () => {
                onClick(asset);
              },
            }}
          />
        ))}
      {offsetMarkers.map((marker) => (
        <Marker key={`${marker.id}-drag`} icon={marker.icon} position={marker.position} opacity={0.5} zIndexOffset={4000} />
      ))}
      {offsetCables.map((cable) => (
        <Polyline
          key={`${cable.id}-drag`}
          positions={cable.position}
          pathOptions={{
            weight: 1,
            color: color.BLUE_HIGHLIGHT,
          }}
          opacity={0.5}
          zIndexOffset={4000}
        />
      ))}
    </>
  );
};

export default Asset;
