// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { ConnectionPoint } from "./connectionPoint";
import { ConnectionPointProperties } from "./connectionPointProperties";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import * as statusConstants from "../../constants/status";

export function createConnectionPointFromConductingEquipment(
  conductingEquipment: ConductingEquipment
): ConnectionPoint {
  const connectionPointProperties = new ConnectionPointProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  connectionPointProperties.annotation =
    conductingEquipment.getPropertyValue("annotation");
  connectionPointProperties.geometry = getLatLngFromWktPoint(geometryString);
  connectionPointProperties.consumerType =
    conductingEquipment.getPropertyValue("consumerType");
  connectionPointProperties.consumerTypeIsDefault =
    conductingEquipment.getPropertyValue("consumerTypeIsDefault") === "true";
  connectionPointProperties.autoPhase =
    conductingEquipment.getPropertyValue("autoPhase") === "true";
  connectionPointProperties.phaseAutoConsumers = Number(
    conductingEquipment.getPropertyValue("phaseAutoConsumers")
  );
  connectionPointProperties.phase1Consumers = Number(
    conductingEquipment.getPropertyValue("phase1Consumers")
  );
  connectionPointProperties.phase2Consumers = Number(
    conductingEquipment.getPropertyValue("phase2Consumers")
  );
  connectionPointProperties.phase3Consumers = Number(
    conductingEquipment.getPropertyValue("phase3Consumers")
  );
  connectionPointProperties.balancedLoad =
    conductingEquipment.getPropertyValue("balancedLoad") === "true";
  connectionPointProperties.unbalancePercent =
    conductingEquipment.getPropertyValue("unbalancePercent");
  connectionPointProperties.lowLoadedPhase =
    conductingEquipment.getPropertyValue("lowLoadedPhase");
  connectionPointProperties.consumptionValue1 =
    conductingEquipment.getPropertyValue("consumptionValue1");
  connectionPointProperties.consumptionValue1IsDefault =
    conductingEquipment.getPropertyValue("consumptionValue1IsDefault") ===
    "true";
  connectionPointProperties.consumptionValue2 =
    conductingEquipment.getPropertyValue("consumptionValue2");
  connectionPointProperties.consumptionValue2IsDefault =
    conductingEquipment.getPropertyValue("consumptionValue2IsDefault") ===
    "true";
  connectionPointProperties.consumptionType =
    conductingEquipment.getPropertyValue("consumptionType");
  connectionPointProperties.consumptionTypeIsDefault =
    conductingEquipment.getPropertyValue("consumptionTypeIsDefault") === "true";
  connectionPointProperties.maxVoltDropPercent =
    conductingEquipment.getPropertyValue("maxVoltDropPercent");
  connectionPointProperties.maxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("maxVoltDropPercentPassResult") ===
    "true";
  connectionPointProperties.maxVoltRiseTotal =
    conductingEquipment.getPropertyValue("maxVoltRiseTotal");
  connectionPointProperties.maxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("maxVoltRiseGeneration");
  connectionPointProperties.maxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("maxVoltRiseUnbalance");
  connectionPointProperties.numberOfPhases =
    conductingEquipment.getPropertyValue("numberOfPhases");
  connectionPointProperties.numberOfPhasesIsDefault =
    conductingEquipment.getPropertyValue("numberOfPhasesIsDefault") === "true";
  connectionPointProperties.parentId =
    conductingEquipment.getPropertyValue("parentId");
  connectionPointProperties.styles = getAssetStyleInfo(
    conductingEquipment.getPropertyValue("class")
  );

  connectionPointProperties.isSubGroupConnection =
    conductingEquipment.getPropertyValue("isSubGroupConnection") === "true";

  connectionPointProperties.isGenerator =
    conductingEquipment.getPropertyValue("isGenerator") === "true";

  connectionPointProperties.earthLoopImpedance =
    conductingEquipment.getPropertyValue("earthLoopImpedance") == null
      ? null
      : Number(conductingEquipment.getPropertyValue("earthLoopImpedance"));

  connectionPointProperties.threePhaseFaultCurrent = (
    parseFloat(conductingEquipment.getPropertyValue("threePhaseFaultCurrent")) /
    1000
  ).toFixed(3);

  connectionPointProperties.phaseToPhaseFaultCurrent = (
    parseFloat(
      conductingEquipment.getPropertyValue("phaseToPhaseFaultCurrent")
    ) / 1000
  ).toFixed(3);

  connectionPointProperties.singlePhaseToEarthFaultCurrent = (
    parseFloat(
      conductingEquipment.getPropertyValue("singlePhaseToEarthFaultCurrent")
    ) / 1000
  ).toFixed(3);

  connectionPointProperties.phaseMap = JSON.parse(
    conductingEquipment.getPropertyValue("phaseMap")
  );

  connectionPointProperties.hasFuseWarning =
    conductingEquipment.getPropertyValue("hasFuseWarning") === "true";

  connectionPointProperties.flickerPassResult =
    conductingEquipment.getPropertyValue("flickerPassResult") === "true";

  connectionPointProperties.flickerDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerDeltaVoltage")
  );

  connectionPointProperties.flickerP28Limit = Number(
    conductingEquipment.getPropertyValue("flickerP28Limit")
  );

  connectionPointProperties.flickerStartingPassResult =
    conductingEquipment.getPropertyValue("flickerStartingPassResult") ===
    "true";

  connectionPointProperties.flickerStartingDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerStartingDeltaVoltage")
  );

  connectionPointProperties.impedanceLoopOperating =
    conductingEquipment.getPropertyValue("impedanceLoopOperating");
  connectionPointProperties.impedanceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceLoopOperatingPassResult") ===
    "true";

  connectionPointProperties.impedanceSourceLoopOperating =
    conductingEquipment.getPropertyValue("impedanceSourceLoopOperating");
  connectionPointProperties.impedanceSourceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceSourceLoopOperatingPassResult") ===
    "true";
  connectionPointProperties.impedanceLoopFault =
    conductingEquipment.getPropertyValue("impedanceLoopFault");
  connectionPointProperties.impedancePhaseOperating =
    conductingEquipment.getPropertyValue("impedancePhaseOperating");
  connectionPointProperties.impedanceSourcePhaseOperating =
    conductingEquipment.getPropertyValue("impedanceSourcePhaseOperating");
  connectionPointProperties.impedanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("impedanceSourcePhasePhaseOperating");
  connectionPointProperties.impedancePhaseFault =
    conductingEquipment.getPropertyValue("impedancePhaseFault");
  connectionPointProperties.resistanceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceLoopOperating");
  connectionPointProperties.resistanceSourceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceSourceLoopOperating");
  connectionPointProperties.resistanceLoopFault =
    conductingEquipment.getPropertyValue("resistanceLoopFault");
  connectionPointProperties.resistancePhaseOperating =
    conductingEquipment.getPropertyValue("resistancePhaseOperating");
  connectionPointProperties.resistanceSourcePhaseOperating =
    conductingEquipment.getPropertyValue("resistanceSourcePhaseOperating");
  connectionPointProperties.resistanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("resistanceSourcePhasePhaseOperating");
  connectionPointProperties.resistancePhaseFault =
    conductingEquipment.getPropertyValue("resistancePhaseFault");
  connectionPointProperties.reactanceLoopOperating =
    conductingEquipment.getPropertyValue("reactanceLoopOperating");
  connectionPointProperties.reactanceSourceLoopOperating =
    conductingEquipment.getPropertyValue("reactanceSourceLoopOperating");
  connectionPointProperties.reactanceLoopFault =
    conductingEquipment.getPropertyValue("reactanceLoopFault");
  connectionPointProperties.reactancePhaseOperating =
    conductingEquipment.getPropertyValue("reactancePhaseOperating");
  connectionPointProperties.reactanceSourcePhaseOperating =
    conductingEquipment.getPropertyValue("reactanceSourcePhaseOperating");
  connectionPointProperties.reactanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("reactanceSourcePhasePhaseOperating");
  connectionPointProperties.reactancePhaseFault =
    conductingEquipment.getPropertyValue("reactancePhaseFault");

  connectionPointProperties.AllmaxVoltDropPercent =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercent");
  connectionPointProperties.AllmaxVoltDropPercentPeriod =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPeriod");
  connectionPointProperties.AllmaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPassResult") ===
    "true";
  connectionPointProperties.AllmaxVoltDropPhase =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPhase");
  connectionPointProperties.AllmaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseTotal");
  connectionPointProperties.AllmaxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseGeneration");
  connectionPointProperties.AllmaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseUnbalance");
  connectionPointProperties.AllmaxVoltRisePercentPeriod =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePercentPeriod");
  connectionPointProperties.AllmaxVoltRisePhase =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePhase");
  connectionPointProperties.AllmaxVoltage =
    conductingEquipment.getPropertyValue("AllmaxVoltage");
  connectionPointProperties.AllmaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase1");
  connectionPointProperties.AllmaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase2");
  connectionPointProperties.AllmaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase3");
  connectionPointProperties.AllminVoltage =
    conductingEquipment.getPropertyValue("AllminVoltage");
  connectionPointProperties.AllminVoltagePhase1 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase1");
  connectionPointProperties.AllminVoltagePhase2 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase2");
  connectionPointProperties.AllminVoltagePhase3 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase3");
  connectionPointProperties.wintermaxVoltDropPercent =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercent");
  connectionPointProperties.wintermaxVoltDropPercentPeriod =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercentPeriod");
  connectionPointProperties.wintermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue(
      "wintermaxVoltDropPercentPassResult"
    ) === "true";
  connectionPointProperties.wintermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPhase");
  connectionPointProperties.wintermaxVoltRisePercent =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePercent");
  connectionPointProperties.wintermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseTotal");
  connectionPointProperties.wintermaxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseGeneration");
  connectionPointProperties.wintermaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseUnbalance");
  connectionPointProperties.wintermaxVoltRisePercentPeriod =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePercentPeriod");
  connectionPointProperties.wintermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePhase");
  connectionPointProperties.wintermaxVoltage =
    conductingEquipment.getPropertyValue("wintermaxVoltage");
  connectionPointProperties.wintermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase1");
  connectionPointProperties.wintermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase2");
  connectionPointProperties.wintermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase3");
  connectionPointProperties.winterminVoltage =
    conductingEquipment.getPropertyValue("winterminVoltage");
  connectionPointProperties.winterminVoltagePhase1 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase1");
  connectionPointProperties.winterminVoltagePhase2 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase2");
  connectionPointProperties.winterminVoltagePhase3 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase3");
  connectionPointProperties.summermaxVoltDropPercent =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercent");
  connectionPointProperties.summermaxVoltDropPercentPeriod =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercentPeriod");
  connectionPointProperties.summermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue(
      "summermaxVoltDropPercentPassResult"
    ) === "true";
  connectionPointProperties.summermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("summermaxVoltDropPhase");
  connectionPointProperties.summermaxVoltRisePercent =
    conductingEquipment.getPropertyValue("summermaxVoltRisePercent");
  connectionPointProperties.summermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("summermaxVoltRiseTotal");
  connectionPointProperties.summermaxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("summermaxVoltRiseGeneration");
  connectionPointProperties.summermaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("summermaxVoltRiseUnbalance");
  connectionPointProperties.summermaxVoltRisePercentPeriod =
    conductingEquipment.getPropertyValue("summermaxVoltRisePercentPeriod");
  connectionPointProperties.summermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("summermaxVoltRisePhase");
  connectionPointProperties.summermaxVoltage =
    conductingEquipment.getPropertyValue("summermaxVoltage");
  connectionPointProperties.summermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase1");
  connectionPointProperties.summermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase2");
  connectionPointProperties.summermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase3");
  connectionPointProperties.summerminVoltage =
    conductingEquipment.getPropertyValue("summerminVoltage");
  connectionPointProperties.summerminVoltagePhase1 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase1");
  connectionPointProperties.summerminVoltagePhase2 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase2");
  connectionPointProperties.summerminVoltagePhase3 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase3");
  connectionPointProperties.phaseImpedance =
    conductingEquipment.getPropertyValue("phaseImpedance");
  connectionPointProperties.loopImpedance =
    conductingEquipment.getPropertyValue("loopImpedance");
  connectionPointProperties.nodeNumber =
    conductingEquipment.getPropertyValue("nodeNumber");
  connectionPointProperties.status =
    conductingEquipment.getPropertyValue("status") ?? statusConstants.EXISTING;
  connectionPointProperties.cutOutSize =
    conductingEquipment.getPropertyValue("cutOutSize") ?? "100A";
  connectionPointProperties.count =
    conductingEquipment.getPropertyValue("count");
  connectionPointProperties.multiOccupancyContainer = JSON.parse(
    conductingEquipment.getPropertyValue("multiOccupancyContainer")
  );
  connectionPointProperties.multiOccupancy =
    connectionPointProperties.multiOccupancyContainer
      ? connectionPointProperties.multiOccupancyContainer.enabled
      : undefined;
  connectionPointProperties.moSpareWays =
    connectionPointProperties.multiOccupancyContainer
      ? connectionPointProperties.multiOccupancyContainer.numSpareWays
      : undefined;
  connectionPointProperties.rootTransformerId =
    conductingEquipment.getPropertyValue("rootTransformerId");

  return new ConnectionPoint(conductingEquipment.id, connectionPointProperties);
}
