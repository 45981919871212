import L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import { Marker } from "react-leaflet";

import { updateCable, updateCableArray } from "../app/networkSlice";
import { calculateLength, insertPointOnPath } from "../utils/lineFunctions";
import { LatLng } from "../model/viewModel/simpleTypes";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";

const ReshapeTool = ({ points, replacePoint }) => {
  const cables = useSelector((state) => state.network.present.cables);

  const dispatchRedux = useDispatch();

  const styleIcon = () => {
    return iconMarker();
  };

  const iconMarker = () => {
    const icon = L.divIcon({
      name: "handle",
      className: "handle iconMarker iconMarker-sz-1-5",
      html: "<i class=icon-node></i>",
      iconSize: [8, 8],
    });
    return icon;
  };

  const handleMarkerDragEnd = (event, originalPosition, assetId) => {
    const newAsset = cables.find(p => p.id === assetId);

    const newGeometry = insertPointOnPath(newAsset.geometry, points.filter(p => p.assetId === assetId).map(p => p.position), originalPosition.position, { lat: event.target._latlng.lat, lng: event.target._latlng.lng });

    store.dispatch(startBatchAction());

    dispatchRedux(updateCable({ id: newAsset.id, name: "geometry", value: newGeometry }));
    const newLength = calculateLength(newGeometry);

    dispatchRedux(updateCable({ id: newAsset.id, name: "length", value: newLength }));

    if (!(newAsset.overrideLength > 0) && newAsset.groundTypeOverrides?.length === 1) {
      dispatchRedux(updateCableArray({ id: newAsset.id, arrayProperty: "groundTypeOverrides", index: 0, name: "length", value: newLength }));
    }

    store.dispatch(endBatchAction());
    replacePoint(originalPosition, { ...originalPosition, position: new LatLng(event.target._latlng.lat, event.target._latlng.lng) });
  };

  return (
    <>
      {points && (
        points.map((p, i) =>
          <Marker
            key={`anchor_${i}`}
            position={p.position}
            icon={styleIcon()}
            draggable={true}
            eventHandlers={{
              dragend: (e) => handleMarkerDragEnd(e, p, p.assetId)
            }}
          />)
      )}
    </>
  );
};

export default ReshapeTool;
