import React from "react";
import { Table } from "reactstrap";

const ReportTableSummary = ({ header, network }) => {

  const addConnection = (connections, con, count) => {
    const uniqueKey = con.consumerType + con.consumptionType;
    if (!connections[uniqueKey]) {
      connections[uniqueKey] = {
        count: 0,
        consumerType: con.consumerType,
        consumptionType: con.consumptionType,
      };
    }
    connections[uniqueKey].count += parseInt(count) || 1;
  }

  const getConnections = () => {
    let connections = {};
    Object.values(network).forEach((assets) => {
      if (Array.isArray(assets)) {
        assets.forEach((asset) => {
          if (asset.groupedConnectionPoints) {
            asset.groupedConnectionPoints.forEach((groupedConnectionPoint) => {
              addConnection(connections, groupedConnectionPoint, groupedConnectionPoint.count);
              if (groupedConnectionPoint.subGroupConnectionPoints) {
                groupedConnectionPoint.subGroupConnectionPoints.forEach(
                  (subGroupConnectionPoint) => {
                    addConnection(connections, subGroupConnectionPoint, groupedConnectionPoint.count);
                  }
                );
              }
            });
          }
        });
      }
    });

    return connections;
  };

  const convertType = (type) => {
    return type === "AnnualConsumption"
      ? "AC"
      : type === "MaximumDemandDiversified"
        ? "MDD"
        : type === "MaximumDemand"
          ? "MDF"
          : "";
  };

  return (
    <>
      <h5 style={{ paddingLeft: ".3rem" }}>{header}</h5>
      <Table
        style={{ marginBottom: "3rem" }}
        className="table-sm table-custom table-custom-ea w-auto"
      >
        <thead>
          <tr className="mb-4">
            <th>Profile</th>
            <th>Type</th>
            <th>No Consumers</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(getConnections()).map((m) => (
            <tr key={m.id}>
              <td>{m.consumerType}</td>
              <td>{convertType(m.consumptionType)}</td>
              <td>{m.count ? m.count : 1}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export default ReportTableSummary;
