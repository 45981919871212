import { useMsal } from "@azure/msal-react";
import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Searchbox.css";

import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { ToolContext } from "../context/ToolContext";
import { getLatLngFromWktPoint } from "../model/viewModel/geometryWktConverter";
import { loadFeederList } from "../services/apiService";
import WarningModal from "./WarningModal";
import { FormContext } from "../context/FormContext";
import { isRagNetworkPopulated } from "../utils/networkFunctions";
import { setBrowserTabTitle } from "../utils/browserFunctions";
import { setAsSaved, setTitle } from "../app/networkSlice";
import { store } from "../app/store";
import { clearHistoryAction } from "../app/undoable";

const Searchbox = ({ loadedTransformers }) => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { searchValue, useAntData } = toolState;
  const { formState, dispatch } = useContext(FormContext);
  const [showRAGWarning, setShowRAGWarning] = useState(false);
  const [showUnsavedNetworkWarning, setShowUnsavedNetworkWarning] =
    useState(false);
  const { network, ragNetworks, clientSettings } = formState;
  const networkChanged = useSelector(
    (state) => state.network.present.networkChanged,
  );

  const { TransformerNameSearchEnabled } = clientSettings.Features;

  const dispatchRedux = useDispatch();

  const { accounts } = useMsal();

  const getFeeders = async (canProcess) => {
    setShowRAGWarning(false);
    setShowUnsavedNetworkWarning(false);

    store.dispatch(clearHistoryAction());
    dispatchRedux(setAsSaved());

    if (canProcess !== true) {
      return;
    }

    isRagNetworkPopulated(formState.ragNetworks) && replaceRagNetworksState();

    clientSettings.Features.StudyBrowserTitleEnabled &&
      setBrowserTabTitle(clientSettings.General.DisplayName);
    dispatchRedux(setTitle(null));

    setToolState({
      isLoading: "Loading network",
    });

    const account = accounts[0];

    const parsedSearchValue = Array.isArray(searchValue)
      ? searchValue.length > 0
        ? searchValue[0].key
        : ""
      : searchValue;

    if (!parsedSearchValue) return;

    const response = await loadFeederList(
      parsedSearchValue,
      account,
      useAntData,
    );

    if (response?.data?.network?.messages?.length) {
      setToolState({
        alert: {
          type: "error",
          className: "danger",
          messages: response.data.network.messages,
        },
        isLoading: false,
      });
      return;
    } else {
      const transformer = getTransformer(response.data.network);

      setToolState({
        isLoading: false,
        ringfenced: [],
        ringfencedFiltered: [],
        ringfencedTypes: [],
        disableBoundary: false,
        filteredFeeders: [],
        feederList: response.data.network.feeders,
        navigateToLatLng: getTransformerLatLng(transformer),
        selectedAssetId: parseInt(getTransformerId(transformer)),
        errors: {},
      });
    }
  };

  const isUnsavedNetwork = () => {
    return networkChanged && !network.existing;
  };

  const replaceRagNetworksState = () => {
    const _formState = { ...formState };
    _formState.ragNetworks = [];
    dispatch({
      form: "ragNetworks",
      obj: _formState.ragNetworks,
      type: "REPLACE_STATE4",
    });
    if (networkChanged === false) {
      dispatch({
        obj: _formState.ragNetworks,
        type: "SAVE_NETWORK",
      });
    }
  };

  const getTransformer = (network) => {
    return network.conductingEquipment.find(
      (ce) => ce.type === "PowerTransformer",
    );
  };

  const getTransformerLatLng = (transformer) => {
    const geometry = transformer.properties.find((p) => p.name === "geometry");

    return getLatLngFromWktPoint(geometry.value);
  };

  const getTransformerId = (transformer) => {
    return transformer.properties.find((p) => p.name === "wpdId").value;
  };

  return (
    <>
      {showRAGWarning && (
        <WarningModal
          item={true}
          action={getFeeders}
          msg={"Clicking OK will clear the RAG network - are you sure?"}
          yesLabel="OK"
          dismissLabel="Cancel"
        />
      )}
      {showUnsavedNetworkWarning && (
        <WarningModal
          item={true}
          action={getFeeders}
          msg={`Discard unsaved changes - are you sure?`}
          dismissLabel="Cancel"
        />
      )}

      <div className="p-3">
        <InputGroup>
          {TransformerNameSearchEnabled ? (
            <>
              {loadedTransformers && loadedTransformers.length > 0 ? (
                <Typeahead
                  id="substation-typeahead-search"
                  size="sm"
                  align="left"
                  labelKey={(option) => `${option.key} ${option.value}`}
                  options={loadedTransformers}
                  placeholder="Choose a transformer"
                  selected={searchValue || []}
                  onChange={(value) =>
                    setToolState({
                      searchValue: value,
                    })
                  }
                />
              ) : (
                <div className="form-control form-control-sm">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              )}
            </>
          ) : (
            <Input
              type="text"
              maxLength={10}
              id="substation-search"
              placeholder="Search for a Network"
              bsSize="sm"
              value={searchValue}
              onChange={(e) =>
                setToolState({
                  searchValue: e.target.value,
                })
              }
            />
          )}
          <InputGroupAddon addonType="append">
            <Button
              id="btnSearchSub"
              size="sm"
              color="primary"
              disabled={
                Array.isArray(searchValue)
                  ? searchValue.length < 1
                  : !searchValue
              }
              onClick={() =>
                isRagNetworkPopulated(ragNetworks)
                  ? setShowRAGWarning(true)
                  : isUnsavedNetwork()
                  ? setShowUnsavedNetworkWarning(true)
                  : getFeeders(true)
              }
            >
              Search
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </>
  );
};

export default Searchbox;
