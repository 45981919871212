import { addGroupedConnectionSubChild } from "../../app/networkSlice";
import { v4 as uuid } from "uuid";

export const shouldShowSubGroupForm = (ringfencedTypes, selected) => {
  const shouldShow = (type) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Connection":
      case "Grouped Connection":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some(p => !shouldShow(p.name));
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const applySubGroupChange = (ringfencedFiltered, subGroups, setSubGroups, dispatchRedux) => {
  ringfencedFiltered.forEach(p => {
    subGroups.forEach(child => {
      p.groupedConnectionPoints.forEach(point => {
        const newChild = { ...child, geometry: p.geometry, parentId: point.id, id: uuid() };
        dispatchRedux(addGroupedConnectionSubChild({ id: p.id, child: newChild, groupedConnectionPointId: point.id, isGroupUpdate: false }));
      });
    });
  });

  setSubGroups([]);
}