export const getResultProperties = (reference) => {
  let results = [];

  reference.filterResults.forEach((category) => {
    category.info.forEach((res) => {
      if (res.season) {
        ["All", "summer", "winter"].forEach((season) => {
          if (res.options) {
            res.options.forEach((option) => {
              results.push(season + option.value);
            });
          } else {
            results.push(season + res.value);
          }
        });
      } else {
        if (res.options) {
          res.options.forEach((option) => {
            results.push(option.value);
          });
        } else {
          results.push(res.value);
        }
      }
    });
  });

  results = results.filter(p =>
    p !== "feederNumber" &&
    p !== "feederFuseSize" &&
    p !== "annotation");

  return results;
};
