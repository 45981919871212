// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { Motor } from "./motor";
import { MotorProperties } from "./motorProperties";
import { getLatLngFromWktPoint } from "./geometryWktConverter";

export function createMotorFromConductingEquipment(
  conductingEquipment: ConductingEquipment
): Motor {
  const motorProperties = new MotorProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  motorProperties.geometry = getLatLngFromWktPoint(geometryString);
  motorProperties.rating = conductingEquipment.getPropertyValue("rating");
  motorProperties.annotation =
    conductingEquipment.getPropertyValue("annotation");
  motorProperties.ratingIsDefault =
    conductingEquipment.getPropertyValue("ratingIsDefault") === "true";
  motorProperties.ratingUnit =
    conductingEquipment.getPropertyValue("ratingUnit");
  motorProperties.numberOfPhases =
    conductingEquipment.getPropertyValue("numberOfPhases");
  motorProperties.numberOfPhasesIsDefault =
    conductingEquipment.getPropertyValue("numberOfPhasesIsDefault") === "true";
  motorProperties.starter = conductingEquipment.getPropertyValue("starter");
  motorProperties.starterIsDefault =
    conductingEquipment.getPropertyValue("starterIsDefault") === "true";
  motorProperties.startingPowerFactor = Number(
    conductingEquipment.getPropertyValue("startingPowerFactor")
  );
  motorProperties.startingPowerFactorIsDefault =
    conductingEquipment.getPropertyValue("startingPowerFactorIsDefault") ===
    "true";
  motorProperties.startingCurrentOverride =
    conductingEquipment.getPropertyValue("startingCurrentOverride");
  motorProperties.numberOfStarts = Number(
    conductingEquipment.getPropertyValue("numberOfStarts")
  );
  motorProperties.numberOfStartsIsDefault =
    conductingEquipment.getPropertyValue("numberOfStartsIsDefault") === "true";
  motorProperties.numberOfStartsUnit =
    conductingEquipment.getPropertyValue("numberOfStartsUnit");
  motorProperties.efficiency = Number(
    conductingEquipment.getPropertyValue("efficiency")
  );
  motorProperties.efficiencyIsDefault =
    conductingEquipment.getPropertyValue("efficiencyIsDefault") === "true";
  motorProperties.hvFaultLevel = Number(
    conductingEquipment.getPropertyValue("hvFaultLevel")
  );
  motorProperties.xrRatio = Number(
    conductingEquipment.getPropertyValue("xrRatio")
  );
  motorProperties.styles = getAssetStyleInfo(
    conductingEquipment.getPropertyValue("class")
  );

  motorProperties.maxVoltDropPercent =
    conductingEquipment.getPropertyValue("maxVoltDropPercent");
  motorProperties.maxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("maxVoltDropPercentPassResult") ===
    "true";
  motorProperties.maxVoltRisePercent =
    conductingEquipment.getPropertyValue("maxVoltRisePercent");
  motorProperties.maxVoltRiseTotal =
    conductingEquipment.getPropertyValue("maxVoltRiseTotal");
  motorProperties.maxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "maxVoltRiseGeneration"
  );
  motorProperties.maxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "maxVoltRiseUnbalance"
  );

  motorProperties.earthLoopImpedance =
    conductingEquipment.getPropertyValue("earthLoopImpedance") == null
      ? null
      : Number(conductingEquipment.getPropertyValue("earthLoopImpedance"));

  motorProperties.threePhaseFaultCurrent = (
    parseFloat(conductingEquipment.getPropertyValue("threePhaseFaultCurrent")) /
    1000
  ).toFixed(3);

  motorProperties.phaseToPhaseFaultCurrent = (
    parseFloat(
      conductingEquipment.getPropertyValue("phaseToPhaseFaultCurrent")
    ) / 1000
  ).toFixed(3);

  motorProperties.singlePhaseToEarthFaultCurrent = (
    parseFloat(
      conductingEquipment.getPropertyValue("singlePhaseToEarthFaultCurrent")
    ) / 1000
  ).toFixed(3);

  motorProperties.flickerPassResult =
    conductingEquipment.getPropertyValue("flickerPassResult") === "true";

  motorProperties.flickerDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerDeltaVoltage")
  );

  motorProperties.flickerP28Limit = Number(
    conductingEquipment.getPropertyValue("flickerP28Limit")
  );

  motorProperties.flickerStartingPassResult =
    conductingEquipment.getPropertyValue("flickerStartingPassResult") ===
    "true";

  motorProperties.flickerStartingDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerStartingDeltaVoltage")
  );

  motorProperties.impedanceLoopOperating = conductingEquipment.getPropertyValue(
    "impedanceLoopOperating"
  );

  motorProperties.impedanceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceLoopOperatingPassResult") ===
    "true";

  motorProperties.impedanceSourceLoopOperating =
    conductingEquipment.getPropertyValue("impedanceSourceLoopOperating");

  motorProperties.impedanceSourceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue(
      "impedanceSourceLoopOperatingPassResult"
    ) === "true";

  motorProperties.impedanceLoopFault =
    conductingEquipment.getPropertyValue("impedanceLoopFault");
  motorProperties.impedancePhaseOperating =
    conductingEquipment.getPropertyValue("impedancePhaseOperating");
  motorProperties.impedanceSourcePhaseOperating =
    conductingEquipment.getPropertyValue("impedanceSourcePhaseOperating");
  motorProperties.impedanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("impedanceSourcePhasePhaseOperating");
  motorProperties.impedancePhaseFault = conductingEquipment.getPropertyValue(
    "impedancePhaseFault"
  );
  motorProperties.resistanceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceLoopOperating");
  motorProperties.resistanceSourceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceSourceLoopOperating");
  motorProperties.resistanceLoopFault = conductingEquipment.getPropertyValue(
    "resistanceLoopFault"
  );
  motorProperties.resistancePhaseOperating =
    conductingEquipment.getPropertyValue("resistancePhaseOperating");
  motorProperties.resistanceSourcePhaseOperating =
    conductingEquipment.getPropertyValue("resistanceSourcePhaseOperating");
  motorProperties.resistanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("resistanceSourcePhasePhaseOperating");
  motorProperties.resistancePhaseFault = conductingEquipment.getPropertyValue(
    "resistancePhaseFault"
  );
  motorProperties.reactanceLoopOperating = conductingEquipment.getPropertyValue(
    "reactanceLoopOperating"
  );
  motorProperties.reactanceSourceLoopOperating =
    conductingEquipment.getPropertyValue("reactanceSourceLoopOperating");
  motorProperties.reactanceLoopFault =
    conductingEquipment.getPropertyValue("reactanceLoopFault");
  motorProperties.reactancePhaseOperating =
    conductingEquipment.getPropertyValue("reactancePhaseOperating");
  motorProperties.reactanceSourcePhaseOperating =
    conductingEquipment.getPropertyValue("reactanceSourcePhaseOperating");
  motorProperties.reactanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("reactanceSourcePhasePhaseOperating");
  motorProperties.reactancePhaseFault = conductingEquipment.getPropertyValue(
    "reactancePhaseFault"
  );

  motorProperties.AllmaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "AllmaxVoltDropPercent"
  );
  motorProperties.AllmaxVoltDropPercentPeriod =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPeriod");
  motorProperties.AllmaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPassResult") ===
    "true";
  motorProperties.AllmaxVoltDropPhase = conductingEquipment.getPropertyValue(
    "AllmaxVoltDropPhase"
  );
  motorProperties.AllmaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "AllmaxVoltRisePercent"
  );
  motorProperties.AllmaxVoltRiseTotal = conductingEquipment.getPropertyValue(
    "AllmaxVoltRiseTotal"
  );
  motorProperties.AllmaxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseGeneration");
  motorProperties.AllmaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseUnbalance");
  motorProperties.AllmaxVoltRisePercentPeriod =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePercentPeriod");
  motorProperties.AllmaxVoltRisePhase = conductingEquipment.getPropertyValue(
    "AllmaxVoltRisePhase"
  );
  motorProperties.AllmaxVoltage =
    conductingEquipment.getPropertyValue("AllmaxVoltage");
  motorProperties.AllmaxVoltagePhase1 = conductingEquipment.getPropertyValue(
    "AllmaxVoltagePhase1"
  );
  motorProperties.AllmaxVoltagePhase2 = conductingEquipment.getPropertyValue(
    "AllmaxVoltagePhase2"
  );
  motorProperties.AllmaxVoltagePhase3 = conductingEquipment.getPropertyValue(
    "AllmaxVoltagePhase3"
  );
  motorProperties.AllminVoltage =
    conductingEquipment.getPropertyValue("AllminVoltage");
  motorProperties.AllminVoltagePhase1 = conductingEquipment.getPropertyValue(
    "AllminVoltagePhase1"
  );
  motorProperties.AllminVoltagePhase2 = conductingEquipment.getPropertyValue(
    "AllminVoltagePhase2"
  );
  motorProperties.AllminVoltagePhase3 = conductingEquipment.getPropertyValue(
    "AllminVoltagePhase3"
  );
  motorProperties.wintermaxVoltDropPercent =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercent");
  motorProperties.wintermaxVoltDropPercentPeriod =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercentPeriod");
  motorProperties.wintermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue(
      "wintermaxVoltDropPercentPassResult"
    ) === "true";
  motorProperties.wintermaxVoltDropPhase = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPhase"
  );
  motorProperties.wintermaxVoltRisePercent =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePercent");
  motorProperties.wintermaxVoltRiseTotal = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseTotal"
  );
  motorProperties.wintermaxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseGeneration");
  motorProperties.wintermaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseUnbalance");
  motorProperties.wintermaxVoltRisePercentPeriod =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePercentPeriod");
  motorProperties.wintermaxVoltRisePhase = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePhase"
  );
  motorProperties.wintermaxVoltage =
    conductingEquipment.getPropertyValue("wintermaxVoltage");
  motorProperties.wintermaxVoltagePhase1 = conductingEquipment.getPropertyValue(
    "wintermaxVoltagePhase1"
  );
  motorProperties.wintermaxVoltagePhase2 = conductingEquipment.getPropertyValue(
    "wintermaxVoltagePhase2"
  );
  motorProperties.wintermaxVoltagePhase3 = conductingEquipment.getPropertyValue(
    "wintermaxVoltagePhase3"
  );
  motorProperties.winterminVoltage =
    conductingEquipment.getPropertyValue("winterminVoltage");
  motorProperties.winterminVoltagePhase1 = conductingEquipment.getPropertyValue(
    "winterminVoltagePhase1"
  );
  motorProperties.winterminVoltagePhase2 = conductingEquipment.getPropertyValue(
    "winterminVoltagePhase2"
  );
  motorProperties.winterminVoltagePhase3 = conductingEquipment.getPropertyValue(
    "winterminVoltagePhase3"
  );
  motorProperties.summermaxVoltDropPercent =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercent");
  motorProperties.summermaxVoltDropPercentPeriod =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercentPeriod");
  motorProperties.summermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue(
      "summermaxVoltDropPercentPassResult"
    ) === "true";
  motorProperties.summermaxVoltDropPhase = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPhase"
  );
  motorProperties.summermaxVoltRisePercent =
    conductingEquipment.getPropertyValue("summermaxVoltRisePercent");
  motorProperties.summermaxVoltRiseTotal = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseTotal"
  );
  motorProperties.summermaxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("summermaxVoltRiseGeneration");
  motorProperties.summermaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("summermaxVoltRiseUnbalance");
  motorProperties.summermaxVoltRisePercentPeriod =
    conductingEquipment.getPropertyValue("summermaxVoltRisePercentPeriod");
  motorProperties.summermaxVoltRisePhase = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePhase"
  );
  motorProperties.summermaxVoltage =
    conductingEquipment.getPropertyValue("summermaxVoltage");
  motorProperties.summermaxVoltagePhase1 = conductingEquipment.getPropertyValue(
    "summermaxVoltagePhase1"
  );
  motorProperties.summermaxVoltagePhase2 = conductingEquipment.getPropertyValue(
    "summermaxVoltagePhase2"
  );
  motorProperties.summermaxVoltagePhase3 = conductingEquipment.getPropertyValue(
    "summermaxVoltagePhase3"
  );
  motorProperties.summerminVoltage =
    conductingEquipment.getPropertyValue("summerminVoltage");
  motorProperties.summerminVoltagePhase1 = conductingEquipment.getPropertyValue(
    "summerminVoltagePhase1"
  );
  motorProperties.summerminVoltagePhase2 = conductingEquipment.getPropertyValue(
    "summerminVoltagePhase2"
  );
  motorProperties.summerminVoltagePhase3 = conductingEquipment.getPropertyValue(
    "summerminVoltagePhase3"
  );
  motorProperties.phaseImpedance =
    conductingEquipment.getPropertyValue("phaseImpedance");
  motorProperties.loopImpedance =
    conductingEquipment.getPropertyValue("loopImpedance");
  motorProperties.nodeNumber =
    conductingEquipment.getPropertyValue("nodeNumber");

  if (
    parseInt(conductingEquipment.getPropertyValue("phase1AutoConsumers") ?? 0) >
    0
  ) {
    motorProperties.phaseAllocation = "L1 + ";
  } else {
    motorProperties.phaseAllocation = "";
  }

  if (
    parseInt(conductingEquipment.getPropertyValue("phase2AutoConsumers") ?? 0) >
    0
  ) {
    motorProperties.phaseAllocation = motorProperties.phaseAllocation + "L2 + ";
  }

  if (
    parseInt(conductingEquipment.getPropertyValue("phase3AutoConsumers") ?? 0) >
    0
  ) {
    motorProperties.phaseAllocation = motorProperties.phaseAllocation + "L3 + ";
  }

  if (motorProperties.phaseAllocation)
    motorProperties.phaseAllocation = motorProperties.phaseAllocation
      .trimRight()
      .replace(/\+\s*$/, "")
      .trimRight();

  return new Motor(conductingEquipment.id, motorProperties);
}
