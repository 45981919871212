import L from "leaflet";
import { renderToString } from "react-dom/server";
import { IconLabel } from "../components";
import shadowImage from "../images/marker-shadow.png";

export const pinMarker = (asset) => {
	const { styles } = asset || {};

	const icon = L.divIcon({
		name: styles.name,
		className: `${styles.cssClass} iconMarker-sz-${asset.styles.size}`,
		html: renderToString(
			<>
				<IconLabel
					className={`icon-${styles.cssClass} iconMarker-sz-${asset.styles.size}`}
					style={{ color: asset.color }}
				/>
				<div
					style={{
						background: "white",
						height: 15,
						width: 15,
						borderRadius: 30,
						marginTop: -45,
						marginLeft: 7,
					}}
				></div>
				<img
					src={shadowImage}
					alt='shadow'
					style={{ marginTop: -67, marginLeft: 3 }}
				/>
			</>
		),
		iconAnchor: [16, 50],
	});

	return icon;
};
