import { FormGroup, Label } from "reactstrap";
import ConsumerSelector from "../ConsumerSelector";
import { CONSUMER_LIST } from "../../constants/consumers";
import NestedConsumerForm from "../NestedConsumerForm";
import { consumerDefaults } from "../../utils/consumerDefaults";
import { ConnectionPoint } from "../../model/viewModel/connectionPoint";
import { getConnectionPointProperties } from "../../states/connectionPoint";
import * as statusConstants from "../../constants/status";
import { v4 as uuid } from "uuid";

const SubConsumerUpdate = ({
  minimised,
  setMinimised,
  subGroups,
  setSubGroups,
  reference }) => {

  const updateId = "group-update-id";

  const addSubGroupItem = (_parentConsumer, formData) => {
    const itemType = formData.get("item");

    let _consumer = {
      ...CONSUMER_LIST.find((y) => y.class === itemType),
    };

    const consumer = consumerDefaults(reference.consumers, _consumer.class);

    const id = uuid();

    const groupedConnectionPoint = new ConnectionPoint(
      id,
      getConnectionPointProperties(
        consumer["consumerType"],
        false,
        true,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        consumer["consumptionValue1"],
        false,
        consumer["consumptionValue2"],
        false,
        consumer["consumptionType"],
        false,
        [], // geometry, to fill in later
        "Single",
        false,
        [],
        _consumer,
        null, // parentId, to fill in later
        true,
        _consumer.name === "Generator",
        null,
        "",
        statusConstants.NEW
      )
    );

    groupedConnectionPoint.count = 1;

    setSubGroups([...subGroups, groupedConnectionPoint]);
  };

  const removeSubGroupItem = (_childConsumer, consumer) => {
    setSubGroups(subGroups.filter(p => p.id !== consumer.id));
  };

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>Sub Group
      </Label>
      {!minimised && subGroups.map((consumer, consumerIndex) => (
        <NestedConsumerForm
          key={`NestedConsumerForm_SubGroup_${consumerIndex}`}
          consumer={consumer}
          consumerIndex={consumerIndex}
          removeItem={removeSubGroupItem}
          updateConsumerCount={() => { }}
          parentId={updateId}
          groupUpdateAction={consumer => setSubGroups([...subGroups.map(p => p.id === consumer.Id ? consumer : p)])}></NestedConsumerForm>
      ))}
      {!minimised && <FormGroup>
        <ConsumerSelector
          header="Add Sub Group"
          count={1}
          addItem={addSubGroupItem}
          selectList={CONSUMER_LIST}
        />
      </FormGroup>}
    </div>
  );
};

export default SubConsumerUpdate;
