import L from "leaflet";
import { LatLng } from "../model/viewModel/simpleTypes";

export function calculateLength(path: LatLng[]) {
  const geometry = path.map((geo) => L.latLng(geo));
  const newLength = geometry.reduce((acc, val, idx, arr) => {
    if (idx > 0) return acc + arr[idx - 1].distanceTo(val);
    return acc;
  }, 0).toFixed(2);
  return newLength;
}

export function calculateDistance(point1: LatLng, point2: LatLng): number {
  const dx = point2.lat - point1.lat;
  const dy = point2.lng - point1.lng;
  return Math.sqrt(dx * dx + dy * dy);
}

export function insertPointBetween(originalArray: LatLng[], newPoint: LatLng): LatLng[] {

  if (originalArray.some(p => p.lat === newPoint.lat && p.lng === newPoint.lng)) {
    return [...originalArray];
  }

  if (originalArray.length < 2) {
    return [...originalArray, newPoint];
  }

  if (originalArray.length === 2) {
    return [originalArray[0], newPoint, originalArray[1]];
  }

  const resultArray = [...originalArray];

  let bestIndex = 0;
  let lowestDifference = null;

  for (let i = 0; i < originalArray.length - 1; i++) {
    const startPoint = originalArray[i];
    const endPoint = originalArray[i + 1];

    const wholeDistance = calculateDistance(startPoint, endPoint);
    const startToNew = calculateDistance(startPoint, newPoint);
    const newToEnd = calculateDistance(newPoint, endPoint);

    const difference = Math.abs((startToNew + newToEnd) - wholeDistance);

    if (lowestDifference === null || lowestDifference > difference) {
      bestIndex = i;
      lowestDifference = difference;
    }
  }

  resultArray.splice(bestIndex + 1, 0, newPoint);

  return resultArray;
}

export const insertPointOnPath = (originalPath: LatLng[], anchorPoints: LatLng[], originalPoint: LatLng, newPoint: LatLng) => {

  let originalArray = [...originalPath];

  if (originalArray.length < 2) {
    originalArray.splice(1, 0, newPoint);
    return originalArray;
  }

  anchorPoints.forEach(p => {
    originalArray = insertPointBetween(originalArray, p);
  });

  const indexOfOriginal = originalArray.findIndex(p => p.lat === originalPoint.lat && p.lng === originalPoint.lng);
  if (indexOfOriginal >= 0) {
    originalArray[indexOfOriginal] = newPoint;
  }

  const newPointIndex = originalArray.indexOf(newPoint);
  const anchorIndexes = anchorPoints.map(p => originalArray.findIndex(q => p.lat === q.lat && p.lng === q.lng)).sort();
  const previousIndex = Math.max(anchorIndexes.filter(p => p < newPointIndex).at(-1) ?? 0, 0);
  const nextIndex = anchorIndexes.filter(p => p > newPointIndex).at(0);

  originalArray = originalArray.filter((_, index) => {
    return index === newPointIndex || index <= (previousIndex ?? 0) || index >= (nextIndex ?? originalArray.length - 1);
  });

  return originalArray;
};