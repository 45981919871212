import React from "react";
import { Button, Input } from "reactstrap";

const SavedStudiesFilter = ({
  studyFilters,
  studyPeriod,
  onStudyPeriodChange,
  selectedGroup,
  selectedArea,
  onSelectedGroupChange,
  onSelectedAreaChange,
  canArchive,
}) => {
  const toggleStudiesPeriod = (e) => {
    onStudyPeriodChange(e.target.value);
  };
  const selectGroup = (e) => {
    onSelectedGroupChange(e.target.value);
  };
  const selectArea = (e) => {
    onSelectedAreaChange(e.target.value);
  };
  const getFilteredAreas = () => {
    if (
      !(
        studyFilters &&
        studyFilters.filters &&
        studyFilters.filters.Areas &&
        studyFilters.filters.Areas.length > 0
      ) ||
      isAreasDisabled()
    ) {
      return [];
    }

    if (hasSingleGroup()) {
      return studyFilters.filters.Areas.sort((area) => area.sortOrder);
    }

    return studyFilters.filters.Areas.filter(
      (area) => area.parentId === selectedGroup
    ).sort((area) => area.sortOrder);
  };
  const isAreasDisabled = () => {
    return selectedGroup === "" && !hasSingleGroup();
  };

  const hasGroups = () =>
    studyFilters &&
    studyFilters.filters &&
    studyFilters.filters.Groups &&
    studyFilters.filters.Groups.length > 0;
  const hasAreas = () =>
    studyFilters &&
    studyFilters.filters &&
    studyFilters.filters.Areas &&
    studyFilters.filters.Areas.length > 0;
  const hasSingleGroup = () =>
    hasGroups() && studyFilters.filters.Groups.length === 1;
  const hasSingleArea = () => hasAreas() && getFilteredAreas().length === 1;

  const GroupsDropdown = () => {
    if (hasGroups()) {
      return (
        <Input
          type="select"
          style={{ fontSize: 13, width: "60%" }}
          onChange={(e) => selectGroup(e)}
          value={selectedGroup}
        >
          <option key="" value="">
            All Folders
          </option>
          {studyFilters.filters.Groups.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      );
    }
    return (
      <div className="text-muted mb-2 ml-1" style={{ fontSize: 14 }}>
        No existing folders
      </div>
    );
  };

  const AreasDropdown = () => {
    const areas = getFilteredAreas();
    if (hasGroups() && (areas.length > 0)) {
      return (
        <Input
          type="select"
          style={{ fontSize: 13, width: "60%" }}
          onChange={(e) => selectArea(e)}
          value={selectedArea}
          disabled={isAreasDisabled()}
        >
          <option key="" value="">
            All Areas
          </option>
          {areas.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      );
    }
  };

  return (
    <>
      <div className="container mt-3">
        {!hasSingleGroup() && (
          <div className="row">
            <div className="mx-4" style={{ width: "25%", marginTop: 7 }}>
              Select Folder
            </div>
            {GroupsDropdown()}
          </div>
        )}
        {getFilteredAreas().length > 1 && (
          <div className={`row ${!hasSingleGroup() ? "pt-2" : ""}`}>
            <div className="mx-4" style={{ width: "25%", marginTop: 7 }}>
              Select Area
            </div>
            {AreasDropdown()}
          </div>
        )}
        {canArchive && (
          <div
            className={`row ${!hasSingleGroup() || !hasSingleArea() ? "pt-2" : ""
              }`}
          >
            <div className="mx-4 mb-1" style={{ width: "25%", marginTop: 6 }}>
              Select Type
            </div>
            <Button
              className={studyPeriod === "recent" ? "active" : null}
              color="dark"
              size="sm"
              value="recent"
              style={{ width: "30%" }}
              onClick={(e) => toggleStudiesPeriod(e)}
            >
              Recent Studies
            </Button>
            <Button
              className={studyPeriod === "archived" ? "active" : null}
              color="dark"
              size="sm"
              value="archived"
              style={{ width: "30%" }}
              onClick={(e) => toggleStudiesPeriod(e)}
            >
              Archived
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SavedStudiesFilter;
