import React from "react";
import { Table } from "reactstrap";

const ReportTable = ({ header, obj }) => {
  const getGroupedConnectionPoints = () => {
    return obj.find((o) => o.groupedConnectionPoints.length > 0) ? true : false;
  };

  const convertType = (type) => {
    return type === "AnnualConsumption"
      ? "AC"
      : type === "MaximumDemandDiversified"
      ? "MDD"
      : type === "MaximumDemand"
      ? "MDF"
      : "";
  };

  return (
    getGroupedConnectionPoints() && (
      <>
        <h5 style={{ paddingLeft: ".3rem" }}>{header}</h5>
        <Table
          style={{ marginBottom: "3rem" }}
          className="table-sm table-custom table-custom-ea"
        >
          <thead>
            <tr className="mb-4">
              <th>Node Number</th>
              <th>No Consumers</th>
              <th>No Phases</th>
              <th>Phasing</th>
              <th>Profile</th>
              <th>Type</th>
              <th>Day (kW/kWh)</th>
              <th>Night (kW/kWh)</th>
              <th>Child Profile</th>
              <th>Child Type</th>
              <th>Child Day (kW/kWh)</th>
              <th>Child Night (kW/kWh)</th>
            </tr>
          </thead>
          <tbody>
            {obj.map((groupedConnection) =>
              groupedConnection.groupedConnectionPoints.map(
                (groupedConnectionPoint) =>
                  groupedConnectionPoint.subGroupConnectionPoints.length > 0 ? (
                    groupedConnectionPoint.subGroupConnectionPoints.map(
                      (subGroupConnectionPoint, i, arr) => (
                        <tr key={groupedConnectionPoint.id}>
                          {i === 0 && (
                            <>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {header === "Distributed Connections"
                                  ? `${groupedConnection.nearNodeNumber} - ${groupedConnection.farNodeNumber}`
                                  : groupedConnectionPoint.nodeNumber}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {groupedConnectionPoint.count}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {groupedConnectionPoint.numberOfPhases}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {header === "Distributed Connections"
                                  ? groupedConnectionPoint.numberOfPhases
                                  : groupedConnectionPoint.phaseAllocation}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {groupedConnectionPoint.consumerType}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {convertType(
                                  groupedConnectionPoint.consumptionType
                                )}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {groupedConnectionPoint.consumptionValue1}
                              </td>
                              <td
                                rowSpan={arr.length}
                                style={{ verticalAlign: "middle" }}
                              >
                                {groupedConnectionPoint.consumptionValue2}
                              </td>
                            </>
                          )}
                          <td>{subGroupConnectionPoint.consumerType}</td>
                          <td>
                            {convertType(
                              subGroupConnectionPoint.consumptionType
                            )}
                          </td>
                          <td>{subGroupConnectionPoint.consumptionValue1}</td>
                          <td>{subGroupConnectionPoint.consumptionValue2}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>{groupedConnectionPoint.nodeNumber}</td>
                      <td>{groupedConnectionPoint.count}</td>
                      <td>{groupedConnectionPoint.numberOfPhases}</td>
                      <td>{groupedConnectionPoint.phaseAllocation}</td>
                      <td>{groupedConnectionPoint.consumerType}</td>
                      <td>
                        {convertType(groupedConnectionPoint.consumptionType)}
                      </td>
                      <td>{groupedConnectionPoint.consumptionValue1}</td>
                      <td>{groupedConnectionPoint.consumptionValue2}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )
              )
            )}
          </tbody>
        </Table>
      </>
    )
  );
};

export default ReportTable;
