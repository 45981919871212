import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import WarningModal from "./WarningModal";
import {
	resetNetworkElements,
	resetNetworkToolState,
} from "../utils/networkFunctions";
import { useSelector, useDispatch } from "react-redux";
import { checkReduxNetworkPopulated, clearReduxNetwork } from "../app/networkSlice";

const ShowNearbyAssetToggle = ({ type, name }) => {
  const SUBSTATION = "substation";

	const { formState } = useContext(FormContext);
	const { toolState, setToolState } = useContext(ToolContext);
	const [showWarningModal, setShowWarningModal] = useState(false);
	const { clientSettings } = formState;
	const { useAntData } = toolState;
	const dispatchRedux = useDispatch();
	const isReduxNetworkPopulated = useSelector((state) => checkReduxNetworkPopulated(state));

	const tryToggleVisibility = () => {
		if (!isReduxNetworkPopulated) {
			toggleVisibility(true);
			return;
		}

    setShowWarningModal(true);
  };

  const toggleVisibility = (shouldToggle) => {
    setShowWarningModal(false);

    if (shouldToggle !== true) {
      return;
    }

		if (isReduxNetworkPopulated) {
			resetNetwork();
		}

    if (type === SUBSTATION) {
      setToolState({
        showNearbyAssets: {
          ...toolState.showNearbyAssets,
          substations: !toolState.showNearbyAssets.substations,
        },
      });
    }
  };

	const resetNetwork = () => {
		const _toolState = toolState;

		dispatchRedux(clearReduxNetwork());
		resetNetworkToolState(_toolState);
		resetNetworkElements();

		setToolState(_toolState);
	};

  const isShown = () => {
    if (toolState.showNearbyAssets.disabled) {
      return false;
    }

    if (type === SUBSTATION) {
      return toolState.showNearbyAssets.substations;
    }
  };

  const toggleUseAntData = () => {
    setToolState({
      useAntData: !toolState.useAntData,
    });
  };

  return (
    <>
      {showWarningModal && (
        <WarningModal
          item={showWarningModal}
          action={toggleVisibility}
          msg={
            "Showing nearby substations will clear the network - are you sure?"
          }
        />
      )}
      <div className="d-flex">
        <div
          className="show-nearby-assets px-3 pb-2"
          disabled={toolState.showNearbyAssets.disabled}
        >
          <span onClick={() => tryToggleVisibility()}>
            <FontAwesomeIcon icon={isShown() ? faCheck : faTimes} fixedWidth />
            {`Show Nearby ${name}`}
          </span>
        </div>

        {clientSettings.Features.DataBasedANTEnabled &&
          clientSettings.Features.ImportNetworkUseGeometryOptionEnabled && (
            <div className="show-nearby-assets pb-2">
              <span onClick={toggleUseAntData}>
                <FontAwesomeIcon
                  icon={!useAntData ? faCheck : faTimes}
                  style={{ width: 20 }}
                />
                {"Use Geometry"}
              </span>
            </div>
          )}
      </div>
    </>
  );
};

export default ShowNearbyAssetToggle;
