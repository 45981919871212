import { FormGroup, Input, Label } from "reactstrap";
import * as statusConstants from "../../constants/status";

const StatusNodeUpdate = ({ title, type, minimised, setMinimised, value, setValue }) => {

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label for={type} onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>{title}
      </Label>
      {!minimised && (
        <FormGroup>
          <Input
            type="select"
            name={type}
            id={type}
            value={value}
            onChange={(e) => setValue(e.value ?? e.target.value)}
          >
            <option value={statusConstants.NEW}>New</option>
            <option value={statusConstants.EXISTING}>Existing</option>
            <option value={statusConstants.REPLACEMENT}>Replacement</option>
          </Input>
        </FormGroup>)}
    </div>
  );
};

export default StatusNodeUpdate;
