import { useContext } from "react";

import ConnectionForm from "./ConnectionForm";
import TransformerForm from "./TransformerForm";
import CableForm from "./CableForm";
import MotorForm from "./MotorForm";
import WelderForm from "./WelderForm";
import PointOfConnectionForm from "./PointOfConnectionForm";
import GroupUpdate from "./GroupUpdate";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

import { isEmpty } from "../utils/isEmpty";
import AssetToggler from "./AssetToggler";
import { ListGroupItem } from "reactstrap";
import SitePlanForm from "./SitePlanForm";

const FormSwitcher = () => {
  const { formState } = useContext(FormContext);
  const { toolState } = useContext(ToolContext);
  const { activeTool, clickedAsset, mode, sitePlans, selectedSitePlanId } =
    toolState;

  const textColor =
    clickedAsset && clickedAsset.styles.type === activeTool.type
      ? "primary"
      : "light";

  const {
    cableProperties,
    transformerProperties,
    motorProperties,
    welderProperties,
    pointOfConnectionProperties,
  } = formState;

  const groupedConnectionActive = () =>
    (activeTool.type === "connectionPoint" ||
      activeTool.type === "groupedConnection") &&
    activeTool.class !== "welder" &&
    activeTool.class !== "motor" &&
    activeTool.class !== "point-of-connection";

  const renderSwitch = () => {
    if (mode === "select" || mode === "draw") {
      switch (activeTool.type) {
        case "transformer":
          return (
            !isEmpty(transformerProperties) && (
              <TransformerForm transformer={transformerProperties} />
            )
          );
        case "cable":
          return (
            !isEmpty(cableProperties) && <CableForm cable={cableProperties} />
          );
        case "groupedConnection":
          return <ConnectionForm />;
        case "connectionPoint":
          switch (activeTool.class) {
            case "domestic":
            case "small-commercial":
            case "large-commercial":
            case "industrial":
            case "generator":
            case "evChargePoint":
            case "heatPump":
              return <ConnectionForm />;
            case "motor":
              return (
                !isEmpty(motorProperties) && (
                  <MotorForm motor={motorProperties} />
                )
              );
            case "welder":
              return (
                !isEmpty(welderProperties) && (
                  <WelderForm welder={welderProperties} />
                )
              );
            case "point-of-connection":
              return (
                !isEmpty(pointOfConnectionProperties) && (
                  <PointOfConnectionForm
                    pointOfConnection={pointOfConnectionProperties}
                  />
                )
              );
            default:
              return;
          }
        default:
          return;
      }
    }
  };

  return (
    <>
      {activeTool ? (
        <>
          {activeTool.type !== "transformer" &&
          activeTool.type !== "cable" &&
          activeTool.class !== "motor" &&
          activeTool.class !== "welder" &&
          activeTool.class !== "point-of-connection" ? (
            <ListGroupItem
              className="bg-dark"
              style={{ textTransform: "uppercase" }}
            >
              Connection(s)
            </ListGroupItem>
          ) : (
            !groupedConnectionActive() && (
              <header className={`form-header bg-dark text-${textColor}-alpha`}>
                {(activeTool.type === "transformer" ||
                  activeTool.type === "cable") &&
                clickedAsset ? (
                  <AssetToggler activeTool={activeTool} textColor={textColor} />
                ) : (
                  <h6
                    className="d-flex justify-content-between"
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      textTransform: "uppercase",
                    }}
                  >
                    <i className="d-block d-sm-none icon-grip-lines-solid"></i>
                    <span>{activeTool.name}</span>
                    <i className={`icon-${activeTool.class}`}></i>
                  </h6>
                )}
              </header>
            )
          )}

          <div className="form-body">{renderSwitch()}</div>
        </>
      ) : mode === "select-group" || mode === "remove-group" ? (
        <>
          <header className="form-header bg-dark text-white-alpha">
            <h6
              className="d-flex justify-content-between"
              style={{
                fontSize: "0.75rem",
                fontWeight: 400,
                textTransform: "uppercase",
              }}
            >
              Filter Selected Assets
            </h6>
          </header>
          <GroupUpdate />
        </>
      ) : sitePlans.length > 0 && selectedSitePlanId ? (
        <>
          <ListGroupItem
            className="bg-dark"
            style={{ textTransform: "uppercase" }}
          >
            Imported Site Plan
          </ListGroupItem>
          {sitePlans
            .filter((f) => f.id === selectedSitePlanId)
            .map((sitePlan) => (
              <SitePlanForm key={sitePlan.id} sitePlan={sitePlan} />
            ))}
        </>
      ) : (
        <span className="mx-4 my-2">No Selection</span>
      )}
    </>
  );
};

export default FormSwitcher;
