import React, { useState, useContext } from "react";
import { Polyline, useMapEvents } from "react-leaflet";
import { v4 as uuid } from "uuid";
import detectIt from "detect-it";
import { ToolContext } from "../context/ToolContext";
import { lineString } from "@turf/turf";
import length from "@turf/length";

const CableTool = ({ overrideLength }) => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { drawCable } = toolState;
  const [cableId, setCableId] = useState();
  const [newPosition, setNewPosition] = useState({});

  useMapEvents({
    mousemove(e) {
      setNewPosition(e.latlng);
    },
    click(e) {
      const _toolState = toolState;
      let _drawCable = drawCable;

      if (
        newPosition?.lat &&
        overrideLength > 0 &&
        Array.isArray(toolState?.drawCable?.geometry) &&
        toolState?.drawCable?.geometry.length
      ) {
        const posAsset = [
          toolState?.drawCable?.geometry[
            toolState?.drawCable?.geometry.length - 1
          ]?.lat || toolState?.clickedAsset?.geometry?.lat,
          toolState?.drawCable?.geometry[
            toolState?.drawCable?.geometry.length - 1
          ]?.lng || toolState?.clickedAsset?.geometry?.lng,
        ];

        const posCurrent = [newPosition?.lat, newPosition?.lng];
        const line = lineString([posAsset, posCurrent]);

        const opt = { units: "meters" };

        const distance = length(line, opt);

        const lat =
          posAsset[0] -
          (+overrideLength * (posAsset[0] - posCurrent[0])) / distance;
        const lng =
          posAsset[1] -
          (+overrideLength * (posAsset[1] - posCurrent[1])) / distance;

        _drawCable.geometry.push({ lat, lng });
        setToolState(_toolState);
      } else {
        _drawCable.geometry.push(newPosition);
        setToolState(_toolState);
      }

      setCableId(uuid());
      _toolState.drawCable = _drawCable;
    },
  });

  return (
    <>
      {drawCable && (
        <>
          <Polyline
            key={`line-${cableId}`}
            positions={drawCable.geometry}
          ></Polyline>
          {detectIt.hasMouse && (
            <Polyline
              key={`hintline-${cableId}`}
              positions={[
                drawCable.geometry[drawCable.geometry.length - 1],
                newPosition,
              ]}
              pathOptions={{
                dashArray: "5 10",
              }}
            ></Polyline>
          )}
        </>
      )}
    </>
  );
};

export default CableTool;
